import {
    FormControl,
    Input,
    MenuItem, Select
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { contactCMSList } from "../../api/clients";
import DlgCreateNewContract from "./createNewContract";
import baseStyles from '../../assets/styles';
import useStyles from "./styles";


const ContactSelectInput = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [searchText, setSearchText] = React.useState("");
    const [contactList, setContactList] = React.useState([]);
    const [filterContactList, setFilterContactList] = React.useState([]);
    const [inputMode, setInputMode] = React.useState("selectExisting");
    //   const [inputMode, setInputMode] = React.useState("createNewContact");

    useEffect(() => {
        contactCMSList().then((response) => {
            setContactList(response);
            setFilterContactList(response);
        });
    }, []);

    const handInputMode = (e) => {
        setInputMode(e.target.value);
    };

    const handleSelectRow = (contact) => {
        props.handleSelectRow(contact);
    };

    const handleSearchFilter = (e) => {
        const searchQuery = e.target.value;
        setSearchText(searchQuery);
        const filteredData = contactList.filter((item) => {
            const contactName = `${item.firstName} ${item.lastName}`;
            return contactName.toLowerCase().includes(searchQuery.toLowerCase());
        });
        setFilterContactList(filteredData);
    };

    const handleClose = () => {
        setSearchText("");
        setFilterContactList(contactList);
        props.handleClose();
    };

    const DlgSelectExisting = () => {
        return (
            <>
                <div className={clsx(baseClasses.fixHeight,baseClasses.overflowAuto )}>
                    <div>
                        <h5>Contact Search</h5>
                        <FormControl className={clsx(classes.fixbar, classes.formControl)}>
                            {/* <FormControl> */}

                            <Input
                                type="search"
                                name="search"
                                id="exampleSearch"
                                value={searchText}
                                onChange={handleSearchFilter}
                                placeholder="Search Contact"
                            />
                        </FormControl>
                        <div className={classes.contactRowWrapper}>
                            {!_.isEmpty(filterContactList) ? (
                                filterContactList.map((contact, index) => {
                                    if (
                                        !_.isEmpty(contact.firstName) &&
                                        contact.firstName !== null
                                    ) {
                                        return (
                                            <Card
                                                className={classes.contactRow}
                                                key={index}
                                                onClick={() => handleSelectRow(contact)}
                                            >
                                                <Col>{`${contact.firstName} ${contact.lastName}`}</Col>
                                            </Card>
                                        );
                                    }
                                    return null;
                                })
                            ) : (
                                <Row className={classes.textCenter}>No Data Found</Row>
                            )}
                        </div>
                    </div>
                </div>
                <div className="float-right">
                    <Button onClick={handleClose} color="primary" >
                        Close
          </Button>
                </div>
            </>
        );
    };

    return (
        <>
            <div style={{ width: 560 }}>
                <FormControl
                    className={clsx(classes.fixbar, classes.formControl, "mb-3")}
                >
                    <h6>Input Mode</h6>

                    <Select
                        id="schedule-specialty-title"
                        value={inputMode}
                        onChange={handInputMode}
                    >
                        <MenuItem value="selectExisting">Select Existing</MenuItem>
                        <MenuItem value="createNewContact">Create New Contact</MenuItem>
                    </Select>
                </FormControl>
                {inputMode == "selectExisting" ? (
                    <DlgSelectExisting></DlgSelectExisting>
                ) : (
                    <DlgCreateNewContract
                        handleClose={handleClose}
                    ></DlgCreateNewContract>
                )}
            </div>
        </>
    );
};

export default ContactSelectInput;
