import React from "react";
import useStylesHome from "./HomeStyles";
import { useStoreState, useStoreActions } from "easy-peasy";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import clsx from "clsx";
import DataTable from "../../components/DataTable/DataTable";
import { columnsEmployees } from "../../api/employees";
import ManageProfessionalPage from './ManageProfesionalPage';
import baseStyles from '../../assets/styles'
const ProfessionalView = (props) => {
    const baseClasses = baseStyles();
    const classesHome = useStylesHome();

    const [query, setQuery] = React.useState("");
    const [searchText, setSearchText] = React.useState("");

    const [popUp, showPopup] = React.useState(false);
    const [type, setType] = React.useState(null);

    const exploringMode = useStoreState((state) => state.exploringMode);
    const isSidebarOpened = useStoreState((state) => state.isSidebarOpened);
    const professionals = useStoreState((state) => state.professional.professionals);

    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const setExploringMode = useStoreActions((actions) => actions.setExploringMode);
    const setPreviousPath = useStoreActions((actions) => actions.setPreviousPath);
    const setExploringType = useStoreActions((actions) => actions.setExploringType);
    const setExploringName = useStoreActions((actions) => actions.setExploringName);
    const initDetail = useStoreActions((actions) => actions.professional.initDetail);

    React.useEffect(() => {
        props.onRefreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exploringMode]);

    //delay search query by 500 ms
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setQuery(searchText)
        }, 500)
        return () => { clearTimeout(timer) }
    }, [searchText])

    const columns = columnsEmployees;

    const _setRowAndColumn = () => {
        return {
            rows: setDataOfFilter(professionals),
            columns: columns,
            screen: "professionals",
        };
    };

    const setDataOfFilter = (rows) => {
        return rows.filter(
            (row) =>
                row?.firstName?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                row?.lastName?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                row?.address1?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                row?.city?.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };

    const onHandlerFilter = (event) => {
        setSearchText(event.target.value);
    };

    const isEditing = () => {
        return exploringMode === "edit";
    };

    const handleNewProfessional = () => {
        //setIsOpenEmployeeModal(true);
        setExploringType("professional");
        setExploringMode("edit");
        setExploringId(-1);
        setExploringName("New Professional");
        setPreviousPath("FROM EMPLOYEE LIST");
        initDetail();
    };

    const handlerOnEdit = (id) => {
        setExploringId(id);
        setExploringMode("edit");
        setPreviousPath("FROM EMPLOYEE LIST");
    }

    return (
        <div>
            <div className={baseClasses.row}>
                <div className={baseClasses.search}>
                    <div className={baseClasses.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search"
                        value={searchText}
                        classes={{
                            root: baseClasses.inputRoot,
                            input: baseClasses.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        onChange={onHandlerFilter}
                    />
                </div>
                <div className={baseClasses.grow}></div>
                <div>
                    <Button
                        variant="contained"
                        className={baseClasses.new_button}
                        color="primary"
                        onClick={handleNewProfessional}
                    >
                        New
                    </Button>
                </div>
            </div>
            <Paper>
                <>
                    <DataTable
                        data={_setRowAndColumn()}
                        onDelete={(params) => {
                            props.onChangeId(params.row.id);
                            showPopup(true);
                        }}
                        onEdit={(params) => { handlerOnEdit(params.row.id); }}
                        onOpen={() => setType(false)}
                        searchQuery={query}

                    />
                </>
            </Paper>
            <Drawer
                variant="permanent"
                className={clsx(classesHome.drawer, {
                    [classesHome.drawerOpen]: isEditing(),
                    [classesHome.drawerClose]: !isEditing(),
                })}
                classes={{
                    paper: clsx({
                        [classesHome.drawerOpen]: isEditing(),
                        [classesHome.appBarShift]: isSidebarOpened,
                        [classesHome.appBarShiftFullWidth]: !isSidebarOpened,
                        [classesHome.drawerClose]: !isEditing(),
                    }),
                }}
                anchor="right"
            >
                {isEditing() && <ManageProfessionalPage type={type} />}
            </Drawer>
        </div>
    );
};

export default ProfessionalView;
