import React, { useState, useEffect, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel, TextField, FormHelperText } from "@mui/material"
import {strings} from '../../_helpers/strings'
import _, { intersection } from "lodash";
import { accountService } from '../../_services/account.service';
import { toast } from 'react-toastify';
import { useStoreActions, useStoreState } from "easy-peasy";
import { PASSWORD_REGEX, PASSWORD_VALIDATION_MSG } from '../../constants';

const ChangePassword = ({ userId, onClose }) => {
    global.log(userId)
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordMatching, setIsPasswordMatching] = useState(false);
    const [passwordPerError, setPasswordPerError] = useState("");

    const handleClose = () => {
        onClose();
    }
    const updatePassword = () => {
        // if(!passwordIsValid()){
        //     return;
        // }
        global.log(userId);
        setLoading();
        accountService.updatePassword(userId, password, password).then(() => { endLoading(); global.AjaxSaved1("Password Updated!"); handleClose() })
    }

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    useEffect(() => {
        setIsPasswordValid(!strings.validatePassword(password))
    }, [password])

    useEffect(() => {
        setIsPasswordMatching(password === confirmPassword)
    }, [confirmPassword, password])

    useEffect(() => {
        setIsSaveDisabled(!!strings.validatePassword(password) || !isPasswordMatching || !isPasswordValid)
    }, [isPasswordValid, isPasswordMatching, password])

    return (<Dialog
        style={{ padding: 5 }}
        disableBackdropClick
        open={true}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}>
        <DialogTitle >
            Change Password
        </DialogTitle>
        <DialogContent>

            <Grid item xs={12}>
                <div style={{ margin: "10px 0" }}>
                    <FormControl fullWidth >
                        <InputLabel htmlFor="password">New Password</InputLabel>
                        <Input
                            value={password}
                            id="password"
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        {!isPasswordValid && password !== "" && <FormHelperText id="component-error-text" error>{strings.validatePassword(password)}</FormHelperText>}
                    </FormControl>
                </div>
                <div style={{ margin: "10px 0" }}>
                    <FormControl fullWidth >
                        <InputLabel htmlFor="password">Confirm Password</InputLabel>
                        <Input
                            value={confirmPassword}
                            id="confirmPassword"
                            onChange={(event) => setConfirmPassword(event.target.value)}
                        />
                        {!isPasswordMatching && confirmPassword !== "" && <FormHelperText id="component-error-text" error>Passwords doesn't match</FormHelperText>}
                    </FormControl>
                </div>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={updatePassword} color="primary" disabled={isSaveDisabled}>
                Save
            </Button>
            <Button onClick={handleClose} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>);
}

export default ChangePassword;