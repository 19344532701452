import {
    Dialog, FormControl,
    TextField
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Autocomplete } from "@mui/lab";
import { useStoreActions, useStoreState } from "easy-peasy";
// TODO: move React import to Top
import React, { useEffect } from "react";
import * as constant from "../../constants";
import DlgCreateNewContract from "./PanelNewContact";
import baseStyles from '../../assets/styles';
import useStyles from "./styles";
/**
 * editData: string: name of the editing contact
 * props: function: onChange
*/

const ContactSelectField = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [isOpenNewContact, setIsOpenNewContact] = React.useState(false);
    const contactsLoad = useStoreActions((actions) => actions.contactsLoad);
    const storeContacts = useStoreState((state) => state.contacts);
    const { editData, userRole, showUserRoles, label, allowNew } = props;
    const [contactList, setContactList] = React.useState([]);
    const isLoading = useStoreState((state) => state.isLoading);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    useEffect(() => {
    
        resetData();
    }, []);
    const resetData = () => {
        if (userRole == "") {
            if (allowNew) SelectCurrentItem(storeContacts);
            else {
                const arr = [];
                for (var i = 1; i < storeContacts.length; i++)
                    arr.push(storeContacts[i]);
                SelectCurrentItem(arr);
            }
        } else {
        /* admin permission (only admin can add new contact)*/
            global.contactAPI.getContactsByUserRole(userRole).then((response) => {
                if (showUserRoles) {
                    response = response.map(user => {
                        return {
                            id: user.id,
                            name: user.name + (user.hebrewName ? " | " + user.hebrewName : "") + " | " + user.roles,
                            address: user.address,
                            roles: user.roles,

                        };
                    });
                }
                SelectCurrentItem(global.isAdmin() ? global.getNewList2(response) : response);
            });
        }
    };
    const SelectCurrentItem = (list) => {
        setContactList(list);
        // TODO: replace var with modern let keyword to make it block level scope
        if (global.isEmpty(editData)) return;
        if (props.editDataType && props.editDataType == "id") {
            var item = list.find((item) => item.id == editData);
            setSelectedItem(item);
        } else {
            var item = list.find((item) => {global.log(
                'the name is "' + item.name + '" the other name is "' + editData + '"'
            );return item.name == editData});
            setSelectedItem(item);
        }
    };
    const handleSelectRow = (contact) => {
        // global.log(contact);
        if (!contact) {
            setSelectedItem(null);
            props.onChange(null);
            return;
        }
        // TODO: strict comparison
        if (contact.id == null) {
            setSelectedItem(null);
            setIsOpenNewContact(true);
            props.onChange(null);
            return;
        }
        setSelectedItem(contact);
        props.onChange(contact);
        // if (!contact) return;
        // setConatctName(`${contact.firstName} ${contact.lastName}`);
        // handleClo/se();
        // props.onSelectValue(contact.id);
    };

    return (
        <>
            {/* <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label" htmlFor="name-multiple">
          {props.name ? props.name : "Contact"}
        </InputLabel>
        <Input
          value={conatctName}
          id="demo-simple-select-label"
          onClick={(event) => {
            setOpenModal(true);
          }}
          aria-describedby="my-helper-text"
        />
      </FormControl> */}
            <FormControl fullWidth>
                <Autocomplete
                    options={contactList}
                    getOptionLabel={(option) => `${option.name}`}
                    // className={classes.selection__autocomplete}
                    autoComplete
                    value={selectedItem}
                    onChange={(event, newValue) => {
                        handleSelectRow(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={props.label} margin="normal" />
                    )}
                />
            </FormControl>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={isOpenNewContact}
                onClose={() => {
                    setIsOpenNewContact(false);
                }}
                aria-labelledby="max-width-dialog-title"
            // style={{padding:20}}
            >
                <div style={{ padding: 20 }}>
                    <DlgCreateNewContract
                        handleClose={() => {
                            setIsOpenNewContact(false);
                        }}
                        handleAdded={() => {
                            setIsOpenNewContact(false);
                            contactsLoad().then((list) => {
                                var length = list.length;
                                var addedContact = list[length - 1];
                                handleSelectRow(addedContact);
                                if (userRole !== "any" && userRole !== "") {
                                    global.fetchWrapper.post(
                                        constant.SAVE_CONTACT_ROLE +
                                        addedContact.id +
                                        "/" +
                                        userRole
                                    ).then((response) => {
                                        // TODO: why not storing response of the post ?
                                        resetData();
                                    });
                                } else resetData();
                            });
                        }}
                    ></DlgCreateNewContract>
                </div>
            </Dialog>
        </>
    );
};

export default ContactSelectField;

ContactSelectField.defaultProps = {
    label: "Select Contact",
    userRole: "",
    showUserRoles: false,
    allowNew: true,
};
