import React, { useState, useEffect } from 'react';
import { Card, Grid, List, ListItemButton, ListItemText, Button, Typography, ListItemSecondaryAction, CardHeader } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { useStoreState } from "easy-peasy";
import clsx from 'clsx';
import useUnsavedChangesWarn from '../../../../../hooks/useUnsavedChangesWarn';
import { ClientMissionType, MissionDetailServiceType, MissionObjectiveType, ClientMissionDetail } from '../../../../../assets/types';
import { removeClientMissionService, getClientMissionDetail, saveClientMission, markMissionCompleted, saveClientMissionObjective, markObjectiveComplete, deleteClientMission, getClientMissionService } from '../../../../../api/clients'
import AddMission from '../../Dialog/AddMission';
import AddMissionService from '../../Dialog/AddMissionService';
import DraggableGridTable from '../../../../common/DraggableGridTable';
import DynamicTooltipText from '../../../../common/DynamicTooltipText';
import useFetcher from '../../../../../hooks/useFetcher';
import * as constants from '../../../../../constants';
import AnimLoadingState from '../../../../common/AnimLoadingState';
import useBaseStyles from '../../../../../assets/styles';
import CustomMenu from '../../../../common/CustomMenu';
import useStyles, { CardHeaderAction, Title, FlexContainer, Text } from '../styles';
import { TextButton, FlexAlignCenter } from '../../../../../assets/styles/styledComponents';
import { theme } from '../../../../../assets/theme/theme';
import LoadingBar from '../../../../LoadingBar/LoadingBar';
import TextFormattingWrapper from '../../../../../utils/TextFormattingWrapper';
import EmptyComponent from '../../../../common/EmptyComponent';
import useConfirm from '../../../../../hooks/useConfirm';
import { result } from 'lodash';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import usePermission from '../../../../../hooks/usePermission';

type MissionOverviewCardProps = {
    missionData: ClientMissionDetail | null,
    loading: boolean,
    handleClickComplete: Function,
    handleEditMission: Function,
    handleDeleteMission: Function,
    canEdit: boolean,
};

const MissionOverviewCard = (props: MissionOverviewCardProps) => {
    const { missionData, handleClickComplete, handleEditMission, handleDeleteMission, loading, canEdit } = props;
    const classes: any = useStyles();
    const globalScope: any = global;
    return (
        <Card
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                position: 'relative',
                height: '200px'
            }}
        >
            <AnimLoadingState loading={loading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
            <CardHeaderAction>
                <Typography style={{ marginRight: 'auto', padding: '5px' }}>{<TextFormattingWrapper value={missionData?.title} />}</Typography>
                {canEdit &&
                    <>
                        {
                            !missionData?.completedOn &&
                            <TextButton disable={!missionData} onClick={() => handleClickComplete(missionData?.missionId)} color="primary">
                                Mark Completed
                            </TextButton>
                        }
                        <TextButton
                            onClick={(event: any) => {
                                handleEditMission(event, missionData)
                            }}
                            disable={!missionData}
                            color="primary"
                        >
                            <Edit />
                        </TextButton>
                        <TextButton
                            onClick={(event: any) => {
                                handleDeleteMission(event, missionData?.missionId)
                            }}
                            disable={!missionData}
                            color="primary"
                        >
                            <Delete />
                        </TextButton>
                    </>
                }
            </CardHeaderAction>

            <Grid container spacing={1} sx={{padding: '2px 10px'}}>
                <Grid item xs={6}>
                    <Text>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className={classes.textBold}>Problem:</div>
                            </Grid>
                            <Grid item xs={9}>
                            <TextFormattingWrapper
                                value={missionData?.problem}
                                tooltip
                                showInModal
                                multilineEllipsis
                                line={2}
                                />
                            </Grid>
                        </Grid>
                    </Text>
                    <Text>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className={classes.textBold}>More Details:</div>
                            </Grid>
                            <Grid item xs={9}>
                            <TextFormattingWrapper
                                    value={missionData?.problemDescription}
                                    tooltip
                                    showInModal
                                    multilineEllipsis
                                    line={2}
                                />
                            </Grid>
                        </Grid>
                    </Text>
                    <Text>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className={classes.textBold}>Long-Term Goal:</div>
                            </Grid>
                            <Grid item xs={9}>
                            <TextFormattingWrapper
                                value={missionData?.goal}
                                tooltip
                                showInModal
                                multilineEllipsis
                                line={3}
                                />
                            </Grid>
                        </Grid>
                    </Text>
                </Grid>
                <Grid item xs={6}>
                    <Text>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className={classes.textBold}>Date Created:</div>
                            </Grid>
                            <Grid item xs={9}>
                                {globalScope.dateTime.getUSAFormattedDateString(missionData?.createdOn)}
                            </Grid>
                        </Grid>
                    </Text>
                    <Text>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className={classes.textBold}>Concerns:</div>
                            </Grid>
                            <Grid item xs={9}>
                                <TextFormattingWrapper
                                    value={missionData?.concerns}
                                    tooltip
                                    showInModal
                                    multilineEllipsis
                                    line={2}
                                />
                            </Grid>
                        </Grid>
                    </Text>
                    <Text>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className={classes.textBold}>Plan:</div>
                            </Grid>
                            <Grid item xs={9}>
                                <TextFormattingWrapper
                                    value={missionData?.planForConcern}
                                    tooltip
                                    showInModal
                                    multilineEllipsis
                                    line={2}
                                />
                            </Grid>
                        </Grid>
                    </Text>
                    <Text>
                        <Grid container>
                            <Grid item xs={3}>
                                <div className={classes.textBold}>Date Completed:</div>
                            </Grid>
                            <Grid item xs={9}>
                                {globalScope.dateTime.getUSAFormattedDateString(missionData?.completedOn) || "NA"}
                            </Grid>
                        </Grid>
                    </Text>
                </Grid>
            </Grid>
        </Card>
    )
};

const ServiceCard = (props: any) => {
    const { title, handleAddingService, loading, handleServiceMenu, serviceData, canEdit } = props;
    const baseClasses: any = useBaseStyles();
    return (
        <Card
            sx={{
                position: 'relative',
                height: '200px'
            }}
        >
            <AnimLoadingState loading={loading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
            <CardHeaderAction justifyContent="space-between">
                <Title width={100} fontWeight={300} style={{ padding: '5px'} }>{title}</Title>
                {canEdit &&
                    <TextButton
                        disable={!serviceData}
                        onClick={() => {
                            handleAddingService()
                        }}
                        color="primary"
                    >
                        + Add
                    </TextButton>
                }

            </CardHeaderAction>
            {serviceData?.length > 0 ?
                <List component="nav" sx={{height: '100%', overflowY: 'scroll'}}>
                    {serviceData?.length > 0 && serviceData.map((service: any, index: number) => {
                        return (
                            <ListItemButton
                                key={service.clientServiceId || index}
                                divider
                                onClick={(event) => { }}
                                sx={{
                                    position: 'relative',
                                    padding: '0px 10px',
                                    fontSize: '14px'
                                }}
                            >
                                <ListItemText primary={service.providerName} />
                                {canEdit &&
                                    <MoreVertIcon fontSize='small' onClick={(event: any) => {
                                        handleServiceMenu(event, service)
                                    }} />
                                }

                            </ListItemButton>
                        )
                    })}
                </List> :
                <div className={baseClasses.no_data}>
                    <Typography className={baseClasses.no__data__typgoraphy}>
                        NO DATA
                    </Typography>
                </div>
            }
        </Card>
    )
};

const missionList = [
    {
        id: 0,
        createdOn: "2022-05-23T20:07:27.101Z",
        isDeleted: true,
        problem: "Lack of confidence",
        problemDescription: "Issue talking to friends... eating together...",
        goal: "Enjoying School",
        concerns: "He sleeps late so it will be hard to get him on time",
        planForConcern: "Speak to sleep therapist",
        completedOn: "2022-05-23T20:07:27.101Z",
        objectiveSortOrderNote: "First we need to get him wake up on time, then he should like going to school, then talking to his friends at breakfast, then he will enjoy school.",
    },
    {
        id: 2,
        createdOn: "2022-05-23T20:07:27.101Z",
        isDeleted: true,
        problem: "",
        problemDescription: "string",
        goal: "Get weekly status",
        concerns: "string",
        planForConcern: "string",
        completedOn: "2022-05-23T20:07:27.101Z",
        objectiveSortOrderNote: "string",
    },
    {
        id: 3,
        createdOn: "2022-05-23T20:07:27.101Z",
        isDeleted: true,
        problem: "",
        problemDescription: "string",
        goal: "Get weekly status",
        concerns: "string",
        planForConcern: "string",
        completedOn: "2022-05-23T20:07:27.101Z",
        objectiveSortOrderNote: "string",
    },
];

const Mission: React.FC = () => {
    const globalScope: any = global;
    const [missionDetail, setMissionDetail] = useState<any>(null);
    const [missionsData, setMissionsData] = useState<ClientMissionType[]>([]);
    const [objectives, setObjectives] = useState<MissionObjectiveType[]>([]);
    const [visibleObjectives, setVisibleObjectives] = useState<MissionObjectiveType[]>([]);
    const [serviceCardLoading, setServiceCardLoading] = useState<boolean>(false);
    const [overviewCardLoading, setOverviewCardLoading] = useState<boolean>(false);
    const [objectiveLoading, setObjectiveLoading] = useState<boolean>(false);
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [objectiveMode, setObjectiveMode] = useState<string>("read");
    const [isOpenCreateModal, setOpenCreateModal] = useState<any>({
        mission: false,
        service: false
    });
    const [clientServiceList, setClientServiceList] = useState([]);
    const [anchorElService, setAnchorELService] = useState<any>({
        elem: null,
        row: null
    });
    const [anchorElObjective, setAnchorELObjective] = useState<any>({
        elem: null,
        row: null
    });
    const [editData, setEditData] = useState<ClientMissionType | null>(null);
    const exploringId = useStoreState((state: any) => state.exploringId);
    const [objectiveError, setObjectiveError] = useState<string>("");
    // TODO: Commenting for now, because using fake data
    const [missions, missionLoading] = useFetcher(constants.GET_CLIENT_MISSION_LIST + exploringId);

    const [changesHelper] = useUnsavedChangesWarn();
    const { setDirty, setClean, wConfirm, hasChanges } = changesHelper;
    const {confirm} = useConfirm();
    const classes: any = useStyles();
    const baseClasses: any = useBaseStyles();
    var { canEdit } = usePermission(globalScope.permissions.clients.mission);

    const objectiveCol = [
        { field: "objectiveName", headerName: "Plan Item", width: 300, type: "text", tooltip: true, withRichText: true },
        { field: "assignedClientServices", headerName: "Assignee", width: 250, type: 'autocomplete', data: missionDetail?.clientServices ?? [], dataLabel: 'providerName', tooltip: true },
        { field: "month", headerName: "Month", width: 150, type: 'date', readOnly: true },
        { field: "status", headerName: "Status", width: 120, type: 'select', readOnly: true, options: [{ label: 'completed', value: 'completed' }, { label: 'in-progress', value: 'in-progress' }, { label: 'not-started', value: 'not-started' }] },
        { field: "completedOn", headerName: "Date Completed", width: 200, type: 'date' },
        { field: "moreIcon", type: 'icon', icon: "MoreVert", width: 30, show: ['read'], handleClick: (event: any, objective: any) => handleObjectiveMenu(event, objective) },
        { field: "dragIcon", type: 'icon', icon: "Drag", width: 30, show: ['edit'] },
        { field: "deleteIcon", type: 'icon', icon: "Delete", width: 30, show: ['edit'], handleClick: (event: any, objective: any) => handleDeleteObjective(event, objective) },
    ];

    const getStatusIcon = (date: string) => !!date ? 'check' : 'Warning';

    const handleDeleteObjective = async (event: any, row: MissionObjectiveType) => {
        const isConfirmed = await confirm(true, [], '', 'Plan Item', row.id as number, 'delete');
        if (isConfirmed) {
            if (row.id < 0) {
                setObjectives(
                    objectives.filter((obj: MissionObjectiveType) => obj.id !== row.id)
                );
                setVisibleObjectives(
                    visibleObjectives.filter((obj: MissionObjectiveType) => obj.id !== row.id)
                );
                return;
            } 
            setObjectives(
                objectives.map((obj: MissionObjectiveType) => {
                    if (obj.id === row.id) {
                        return { ...obj, cancelledOn: new Date() }
                    }
                    return obj
                }
                )
            );
            setVisibleObjectives(
                visibleObjectives.map((obj: MissionObjectiveType) => {
                    if (obj.id === row.id) {
                        return { ...obj, cancelledOn: new Date() }
                    }
                    return obj
                }
                )
            );
        }
    };

    const handleObjectiveMenu = (event: any, objective: MissionObjectiveType) => {
        setAnchorELObjective({
            elem: event.currentTarget,
            row: objective
        });
    };

    // useEffect(() => {
    //     if (!!selectedMission)
    //         getMissionDetail(selectedMission.id)
    // }, [selectedMission]);

    useEffect(() => {
        if (missions?.result?.length) {
            setMissionsData([...missions.result]);
            getMissionDetail(missions.result[0].id) // selecting 0 index by default
        }
        setLoading(missionLoading)
    }, [missions, missionLoading]);

    useEffect(() => {
        getClientMissionService(exploringId).then((data: any) => {
            setClientServiceList(data);
        })
    }, [])
    useEffect(() => {
        setVisibleObjectives(getVisibleObjectives(objectives, showCompleted))
    }, [showCompleted, objectives])

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, missionItem: ClientMissionType, index: number) => {
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        else {
            setObjectiveLoading(true);
            setClean();
            getMissionDetail(missionItem.id);
        }
    };

    const getMissionDetail = (id: number) => {
        setOverviewCardLoading(true);
        getClientMissionDetail(id).then((data: ClientMissionDetail) => {
            setOverviewCardLoading(false);
            setMissionDetail(data)
            setObjectives(data.objectives);
            setVisibleObjectives(getVisibleObjectives(data.objectives, showCompleted));
            setObjectiveLoading(false);
            setObjectiveMode('read');
            setObjectiveError("");

        });
    };
    const getVisibleObjectives = (objectives: MissionObjectiveType[], showCompletedObj: boolean) : MissionObjectiveType[] => {
        return objectives?.filter((obj: MissionObjectiveType) =>
            showCompletedObj ? globalScope.isEmpty(obj.cancelledOn) : globalScope.isEmpty(obj.cancelledOn) && globalScope.isEmpty(obj.completedOn)).sort((a,b) => a.sortVal - b.sortVal) || [];
    }
    const sortObjectivesBeforeSaving = (obj: MissionObjectiveType[], visObj: MissionObjectiveType[]) => {
        //find all objectives not in the visible list
        const visibleObjListIds = visObj.map(x => x.id);
        const invisibleObjs = obj.filter(x => visibleObjListIds.every(id => id !== x.id));
        //build new list
        let counter: number = visObj.length -1;
        let newObjList: MissionObjectiveType[] = [
            //spread visible list, update sort order
            ...visObj.map((item, index) => {
                return {
                    ...item,
                    sortVal: index
                }
            }),
            //then invisible list
            ...invisibleObjs.map((item, index) => {
                counter++;
                return {
                    ...item,
                    sortVal: counter,
                }
            })
        ]
        return newObjList;
    }

    const updateMissionData = (data: any) => {
        // Checking if data is edited
        if (missionsData.some((mission: any) => mission.id === data.id)) {
            setMissionsData(missionsData.map((mission: any) => {
                return mission.id === data.id ? data : mission;
            }))
        } else {
            setMissionsData([...missionsData, data]);
        }
        getMissionDetail(data.id);
    }

    const onCloseModal = (data: any, dialog: string) => {
        if (!!data) {
            switch (dialog) {
                case "mission":
                    updateMissionData(data);
                    getMissionDetail(data.id)
                    break;
                case 'service':
                    setMissionDetail({
                        ...missionDetail,
                        clientServices: [...data]
                    });
                    let missionTemp: any = missionsData.find((mission: any) => mission.id === missionDetail.missionId);
                    let serviceIds = data.map((item: any) => item.id);
                    missionTemp = {
                        ...missionTemp,
                        clientServices: serviceIds
                    }
                    updateMissionData(missionTemp);
                    break;
                default:
                    return null;
            }
        }
        setOpenCreateModal({
            mission: false,
            service: false
        });
    };

    const handleEditMission = (event: React.MouseEvent<SVGSVGElement>, mission: ClientMissionType) => {
        setEditData(mission);
        setOpenCreateModal({
            mission: true,
            service: false
        });
    };

    const handleAddingService = () => {
        setOpenCreateModal({
            mission: false,
            service: true
        });
    };

    const setMissionComplete = async (missionId: number) => {
        const isConfirmed = await confirm(true, [], '', 'mission', missionId , 'complete');
        if (isConfirmed) {
            setOverviewCardLoading(true);
            markMissionCompleted(missionId).then((data: any) => {
                updateMissionData(data);
                setOverviewCardLoading(false);
            });
        }
    };

    const handleServiceMenu = (event: any, service: any) => {
        setAnchorELService({
            elem: event.currentTarget,
            row: service
        })
    };


    const handleRemoveService = async (missionId: number, service: any) => {
        let isServiceAssignedToObjectives: boolean = false;
        missionDetail.objectives.forEach((element: any) => {
            if (element.assignedClientServices.some((e: any) => e.id === service.id)) {
                isServiceAssignedToObjectives = true;
            }
        });
        let warnings = isServiceAssignedToObjectives ? [{
            title: 'Assigned plan item',
            description: 'This will remove all the assigned plan item from this service provider'
        }] : [];
        const isConfirmed = await confirm(true, warnings, '', 'service', service.id as number, 'delete');
        if (isConfirmed) {
            setServiceCardLoading(true);
            removeClientMissionService(missionId, service.id).then((data) => {
                if (!!data) {
                    setMissionDetail({
                        ...missionDetail,
                        clientServices:data
                    })
                }
                getMissionDetail(missionId);
                setServiceCardLoading(false);
            });
        }
    };

    const handleEditObjective = () => {
        setObjectiveMode('edit');
    };

    const handleAddNewObjective = () => {
        let newRows = visibleObjectives.filter((obj) => obj.id < 0);
        let isEmptyField = visibleObjectives.some((obj) => obj.id < 0 && obj.objectiveName === "");
        setObjectiveMode("edit")
        isEmptyField ? 
        setObjectiveError("Objective Name should Not be empty"):
        setVisibleObjectives(
            [
                ...visibleObjectives,
                {
                    id: -1 - newRows.length,
                    createdOn: new Date(),
                    isDeleted: false,
                    objectiveName: "",
                    completedOn: "",
                    cancelledOn: '',
                    sortVal: -1,
                    assignedClientServices: [],
                }
            ]
        );
    };

    const handleSaveObjective = () => {
        let isEmptyField = visibleObjectives.some((obj) => obj.objectiveName === "")
        if (isEmptyField) {
            setObjectiveError("Please enter a name for all the plan items")
        } else {
            setObjectiveError("");
            setObjectiveLoading(true);
            let updateSort = sortObjectivesBeforeSaving(objectives, visibleObjectives);
            saveClientMissionObjective(missionDetail.missionId, {
                missionId: missionDetail.missionId,
                missionObjectives: updateSort
            })
                .then((data:MissionObjectiveType[]) => {
                    setObjectiveMode('read');
                    setObjectiveError("");
                    if (!!data) {
                        setObjectiveLoading(false)
                        setMissionDetail({
                            ...missionDetail,
                            objectives: data
                        })
                        setObjectives(data);
                        setVisibleObjectives(getVisibleObjectives(data, showCompleted));
                    }
                    setClean();
                })
        }
    };

    const handleCancelObjective = (event:React.MouseEvent<HTMLButtonElement>) => {
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        setObjectiveMode('read');
        setObjectives([
            ...missionDetail.objectives
        ]);
        setVisibleObjectives(getVisibleObjectives(missionDetail.objectives, showCompleted));
    };

    const handleChangeRow = (value: any, row: MissionObjectiveType, rowIndex: number, col: any) => {
        !!objectiveError && setObjectiveError("");
        setDirty();
        let updatedRows: MissionObjectiveType[] = visibleObjectives.map((obj: MissionObjectiveType) => {
            return obj.id === row.id ? row : obj
        });
        setVisibleObjectives(updatedRows);
    };

    const handleDragEnd = (result: any) => {
        let updatedArr = [
            ...visibleObjectives.slice(0, result.source.index),
            ...visibleObjectives.slice(result.source.index + 1),
        ]
        updatedArr.splice(result.destination.index, 0, visibleObjectives[result.source.index])
        setVisibleObjectives(updatedArr);
    };

    const handleCompleteObjective = async (objective: MissionObjectiveType) => {
        const isConfirmed = await confirm(true, [], '', 'objective', objective.id as number, 'complete');
        if (isConfirmed) {
            setObjectiveLoading(true);
            markObjectiveComplete(objective.id)
            .then((data) => {
                setObjectiveLoading(false);
                if (!!data) {
                    let updateObjectives = objectives.map((obj:any) => obj.id === data.id ? data: obj )
                    setMissionDetail({
                        ...missionDetail,
                        objectives: updateObjectives
                    })
                    setObjectives(updateObjectives);
                    setVisibleObjectives(getVisibleObjectives(updateObjectives, showCompleted));
                }
            })
        }
    };

    //TODO handle prepare objectives for saving
    const handleRemoveObjective = async (objective: MissionObjectiveType) => {
        const isConfirmed = await confirm(true, [], '', 'objective', objective.id as number, 'delete');
        if (isConfirmed) {
            let updatedList: MissionObjectiveType[] = visibleObjectives.map((obj: MissionObjectiveType) => {
                if (obj.id === objective.id) {
                    return { ...obj, cancelledOn: new Date() }
                }
                return obj
            })
            let saveObjective = sortObjectivesBeforeSaving(objectives, updatedList);
            setObjectiveLoading(true);
            saveClientMissionObjective(missionDetail.missionId, {
                missionId: missionDetail.missionId,
                missionObjectives: saveObjective
            }
            ).then((data) => {
                setObjectiveLoading(false)
                if (!!data) {
                     setMissionDetail({
                        ...missionDetail,
                        objectives: saveObjective
                    });
                    setObjectives(saveObjective)
                }
            })
        }
    };

    const handleDeleteMission = async (event: any, id: number) => {
        const isConfirmed = await confirm(true, [], '', 'mission', id , 'delete');
        isConfirmed && (
        deleteClientMission(id).then((data: any) => {
            setOverviewCardLoading(true);
            if (data) {
                setOverviewCardLoading(false);
                let missionTemp = missionsData.filter((mission: ClientMissionType) => mission.id !== data.id)
                setMissionsData(missionTemp);
                if (missionTemp.length) {
                    getMissionDetail(missionTemp[0].id)
                } else {
                    setMissionDetail(null);
                    setObjectives([]);
                    setVisibleObjectives([]);
                }
            }
        }))
    };

    const onAddMission = () => {
        setOpenCreateModal({
            mission: true,
            service: false
        });
        setEditData(null)
    };

    const closeMenu = () => {
        setAnchorELObjective({
            elem: null,
            row: null
        });
        setAnchorELService(null);
    }

    const handleShowCompleted = () => {
        setShowCompleted(!showCompleted);
    };
    const getTooltipTitle = (row: any) => {
        let obj = objectives.find((item) => item.id === row.id);
        return globalScope.dateTime.getUSAFormattedDateString(obj?.completedOn) ?? "in-progresss"
    };
    const getObjectiveOptions = (anchorElObjective: any) => {
        const disabled: boolean = anchorElObjective.row != null && anchorElObjective.row.completedOn != null ? true : false;
        const options = [
            {
                title: 'Complete', disabled: disabled, handleClick: (objective: any) => {
                    closeMenu()
                    handleCompleteObjective(anchorElObjective.row)
                }
            },
            {
                title: 'Delete', handleClick: (objective: any) => {
                    closeMenu()
                    handleRemoveObjective(anchorElObjective.row)
                }
            }
        ]
        return options;
    }

    return (
        <Card className={classes.cardContainer}>
            {loading ?
                <LoadingBar /> :
                <>
                    {missionsData.length ?
                        <Grid container spacing={1} sx={{
                            background: theme.palette.background.paper
                        }}>
                            <Grid item xs={2}>
                                <Card
                                    className={baseClasses.h100}
                                    
                                >
                                    <CardHeader
                                        subheader={"Missions List"}
                                    />
                                    <List component="nav">
                                        {missionsData.map((mission: ClientMissionType, index: number) => {
                                            return (
                                                <ListItemButton
                                                    key={mission.id || index}
                                                    divider
                                                    selected={missionDetail?.missionId === mission.id}
                                                    onClick={(event) => handleListItemClick(event, mission, index)}
                                                    style={{ position: "relative" }}
                                                    className={clsx(classes.missionItem, {
                                                        [classes.missionCompleted]: !!mission.completedOn
                                                    })}
                                                >
                                                    <DynamicTooltipText
                                                        title={<TextFormattingWrapper value={mission.title} />}
                                                        maxWidth={200}
                                                    >
                                                        <TextFormattingWrapper
                                                            value={mission.title}
                                                        />
                                                    </DynamicTooltipText>
                                                    <ListItemSecondaryAction>
                                                        {
                                                            !!mission.completedOn && <CheckOutlined />
                                                        }
                                                    </ListItemSecondaryAction>
                                                </ListItemButton>
                                            )
                                        })}
                                        {canEdit &&
                                            <div className={classes.addNew}>
                                                <Button
                                                    variant="text"
                                                    onClick={onAddMission}
                                                    color="primary"
                                                >
                                                    Add New
                                                    <AddRoundedIcon fontSize="small" style={{ color: "primary" }} />
                                                </Button>
                                            </div>
                                        }
                                    </List>
                                </Card>
                            </Grid>
                            <Grid container xs={10}>
                                <Grid item xs={12} lg={9} className={baseClasses.p10}>
                                    <MissionOverviewCard
                                        missionData={missionDetail}
                                        handleClickComplete={(missionId: number) => {
                                            setMissionComplete(missionId)
                                        }}
                                        handleDeleteMission={(event: any, id: number) => handleDeleteMission(event, id)}
                                        handleEditMission={handleEditMission}
                                        loading={overviewCardLoading || loading}
                                        canEdit={canEdit }
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3} className={baseClasses.p10}>
                                    <ServiceCard
                                        title="Services"
                                        handleAddingService={handleAddingService}
                                        handleServiceMenu={handleServiceMenu}
                                        serviceData={missionDetail?.clientServices}
                                        loading={serviceCardLoading || loading}
                                        canEdit={canEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} className={baseClasses.p10}>
                                    <DraggableGridTable
                                        data={{
                                            rows: visibleObjectives,
                                            columns: [...objectiveCol]
                                        }}
                                        title="Step-by-Step Plan"
                                        mode={objectiveMode}
                                        handleAddNewRow={handleAddNewObjective}
                                        handleCancelChanges={handleCancelObjective}
                                        handleSaveChanges={handleSaveObjective}
                                        handleEditRows={handleEditObjective}
                                        handleOnChange={handleChangeRow}
                                        handleDragEnd={handleDragEnd}
                                        loading={objectiveLoading}
                                        rowError={objectiveError}
                                        showCompleteFilter={true}
                                        showCompleted={showCompleted}
                                        handleShowCompleted={handleShowCompleted}
                                        getTooltipTitle={getTooltipTitle}
                                        canEdit={canEdit}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>:
                        <EmptyComponent
                            btnText='Add Mission'
                            displayText='No Missions Yet'
                            onClick={onAddMission}
                        />
                    }
            </>
            }
            <AddMission isOpenCreateModal={isOpenCreateModal.mission} onCloseModal={onCloseModal} editData={editData} />
            <AddMissionService missionData={missionDetail} isOpenCreateModal={isOpenCreateModal.service} onCloseModal={onCloseModal} />
            
            <CustomMenu
                anchorEl={anchorElService?.elem}
                open={!!anchorElService?.elem}
                options={[
                    { title: 'Remove', handleClick: (service: any) => {
                        closeMenu()
                        handleRemoveService(missionDetail?.missionId, anchorElService.row)}}
                ]}
                handleCloseMenu={() => {
                    setAnchorELObjective({
                        elem: null,
                        row: null
                    });
                    setAnchorELService(null)
                }}
            />
            <CustomMenu
                anchorEl={anchorElObjective?.elem}
                open={!!anchorElObjective?.elem}
                options={getObjectiveOptions(anchorElObjective)}
                handleCloseMenu={() => {
                    setAnchorELObjective({
                        elem: null,
                        row: null
                    });
                    setAnchorELService(null);
                }}
            />
        </Card>
    )
};

export default Mission;