import React, { useEffect, useState } from "react";
import {
    FormControl,
    FormHelperText,
    Grid,
    Button,
    Input,
    TextField,
    InputLabel,
    MenuItem, Select,
    Card, CardContent, Typography, Divider
} from "@mui/material";
import { SectionComponent, NamesToStringComp, CompSelectbox, CompTextField, CompTextMulti } from "./components";
import useStyles from "../../../screens/client/edit_client/postStyle";
import UploadEmployeeTemplateDocument from "../../UploadComponents/UploadEmployeeTemplateDocument";
import _ from "lodash";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import ProtectedComponent from '../../ProtectedComponent/ProtectedComponent'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import baseStyles from '../../../assets/styles';
import { DivContainer } from '../../../assets/styles/styledComponents';
import Loader from "../../common/Loader";

const CompUploadDocument = ({ label, viewMode, setIsLoading, onSuccessfulUpload }) => {
    const exploringId = useStoreState((state) => state.exploringId);
    return (
        <>
            {!global.isEmpty(exploringId) && <UploadEmployeeTemplateDocument
                employeeID={exploringId}
                label={label}
                viewMode={viewMode}
                setIsLoading={setIsLoading}
                setIsUploaded={(uploaded) => { }}
                onSuccessfulUpload={onSuccessfulUpload}
            />
            }
        </>
    );
};

const FinancialSection = ({ sectionState, positionValues, employee, yungermanId, professionalId, setSectionState, specialtyValues, paymentValues, professionalTypes, rollbackChanges, confirmUnsavedChanges, setDirty }) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [employeeDetail, setEmployeeDetail] = useState(employee);
    const [loading, setLoading] = useState(false);
    useEffect(() => { setEmployeeDetail(employee); }, [employee]);

    let sectionState2 = sectionState;





    return (
        <DivContainer position="relative">
            <Loader loading={loading} />
            <SectionComponent headerTitle="Financial Information" onSave={() => { }}
                employeeDetail={employeeDetail}
                state={sectionState2}
                onStateChange={setSectionState}
                rollbackChanges={rollbackChanges}
                confirmUnsavedChanges={confirmUnsavedChanges}
                readDOM={
                    <Grid
                        container
                        className={baseClasses.grid}
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Parsonage Limit ($) : </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['parsonageLimit']) ? "-" : parseFloat(employeeDetail['parsonageLimit']).toFixed(2)}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>ID : </div>
                            <div className={baseClasses.fieldvalue}>
                                {/* ID */}
                                <CompUploadDocument
                                    label={"ID"}
                                    viewMode={"read"}
                                    setIsLoading={(isLoading) => setLoading(isLoading)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Parsonage Form : </div>
                            <div className={baseClasses.fieldvalue}>
                                {/* Parsonage Form */}
                                <CompUploadDocument
                                    label={"ParsonageForm"}
                                    viewMode={"read"}
                                    setIsLoading={(isLoading) => setLoading(isLoading)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Parsonage Form Expiration: </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['parsonageFormExpiration']) ? "-" : new Date(employeeDetail['parsonageFormExpiration']).toLocaleDateString('en-US')}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>I-9 : </div>
                            <div className={baseClasses.fieldvalue}>
                                {/* I-9 */}
                                <CompUploadDocument
                                    label={"I9"}
                                    viewMode={"read"}
                                    setIsLoading={(isLoading) => setLoading(isLoading)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <ProtectedComponent
                                className={baseClasses.fieldtitle}
                                permissions={global.permissions.employees.financialNotes}
                            >Financial Notes : </ProtectedComponent>
                            <ProtectedComponent
                                className={baseClasses.fieldvalue}
                                permissions={global.permissions.employees.financialNotes}
                            >
                                {global.isEmpty(employeeDetail['financialNotes']) ? "-" : employeeDetail['financialNotes']}
                            </ProtectedComponent>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Payment Options : </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['paymentOptions']) ? "-" : employeeDetail['paymentOptions']}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Payment Delivery : </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['paymentDelivery']) ? "-" : employeeDetail['paymentDelivery']}
                            </div>
                        </Grid>
                    </Grid>
                }
                editDOM={
                    <div>
                        <Grid
                            container
                            className={clsx(baseClasses.grid)}
                        >
                            <Grid item xs={12}>
                                <CompTextField
                                    xs={12}
                                    label="Parsonage Limit ($)"
                                    name="parsonageLimit"
                                    employeeDetail={employeeDetail}
                                    setEmployeeDetail={setEmployeeDetail}
                                    variant={"standard"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* ID */}
                                {CompUploadDocument({
                                    label: "ID",
                                    viewMode: "edit",
                                })}
                            </Grid>
                            <Grid item xs={8}>
                                {/* Parsonage Form */}
                                {CompUploadDocument({
                                    label: "ParsonageForm",
                                    viewMode: "edit"
                                })}
                            </Grid>
                            <Grid item xs={4} className={baseClasses.paddingTop24}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label={"Parsonage Form Exp"}
                                        name="parsonageFormExpiration"
                                        value={employeeDetail["parsonageFormExpiration"]}
                                        onChange={(event) => {
                                            if (!event || event == "Invalid Date")
                                                return;
                                            setDirty();
                                            setEmployeeDetail({ ...employeeDetail, ["parsonageFormExpiration"]: event });
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                        minDate={new Date(2000, 1, 1)}
                                        maxDate={new Date(2100, 1, 1)}
                                        variant={"standard"}

                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={8}>
                                {/* I-9 */}
                                {CompUploadDocument({
                                    label: "I9",
                                    viewMode: "edit"
                                })}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className={clsx(baseClasses.grid)}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <CompTextMulti
                                xs={12}
                                label="Financial Notes"
                                name="financialNotes"
                                permissions={global.permissions.employees.financialNotes}
                                employeeDetail={employeeDetail}
                                setDetail={setEmployeeDetail}
                                variant={"standard"}
                            />
                            <CompSelectbox label="Payment Options" name={"paymentOptions"}
                                value={global.isEmpty(employeeDetail.paymentOptions) ? [] : employeeDetail.paymentOptions.split(",")}
                                employeeDetail={employeeDetail}
                                setEmployeeDetail={setEmployeeDetail}
                                listValues={paymentValues}
                                variant={"standard"}
                            >
                            </CompSelectbox>
                            <CompSelectbox label="Payment Delivery" name={"paymentDelivery"}
                                value={global.isEmpty(employeeDetail.paymentDelivery) ? '' : employeeDetail.paymentDelivery}
                                employeeDetail={employeeDetail}
                                setEmployeeDetail={setEmployeeDetail}
                                listValues={[{ id: 'mail', name: "Mail", }, { id: 'office', name: "Office", }]}
                                variant={"standard"}
                            >
                            </CompSelectbox>
                        </Grid>
                    </div>
                }
            ></ SectionComponent>
        </DivContainer>
    )
};

export default FinancialSection;