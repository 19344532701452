import {useStoreActions, useStoreState} from 'easy-peasy'

let resolveCallback: any;
function useConfirm() {
    const showConfirm = useStoreActions((actions: any) => actions.showConfirm);
    const hideConfirm = useStoreActions((actions: any) => actions.hideConfirm);
    const confirmState = useStoreState((state: any) => state.confirm);
    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };

    const confirm = (open: boolean, warnings: any, description: string, item: string, id: number, action: string) => {
        showConfirm({open, warnings, description , item , id , action})
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const closeConfirm = () => {
        hideConfirm()
    };

    return { confirm, onConfirm, onCancel, confirmState };
}

export default useConfirm