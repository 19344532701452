import React, { useState, useRef, useEffect }from 'react';
import { TextField } from '@mui/material';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import { createTheme } from '@mui/styles';
import { strings } from '../../_helpers/strings' //getRichTextObjFromString, getStringFromRichTextObj

const RichTextField = (props) => {
    const { value, setState } = props;
    const [richTextObj, setRichTextObj] = useState(strings.getRichTextObjFromString(value))
    const inputEl = useRef(null);

    //let keys = {
    //    ShiftRight: false,
    //    ShiftLeft: false,
    //    ControlRight: false,
    //    ControlLeft: false,
    //}
    //inputEl?.current?.addEventListener("keydown", (event) => {
    //    keys[event.code] = true;
    //    if (keys.ShiftRight && keys.ControlRight) {
    //        event.preventDefault()
    //        setRichTextObj({
    //            ...richTextObj,
    //            formatting: {
    //                ...richTextObj.formatting,
    //                textDirection: 'rtl'
    //            }
    //        })
    //    }

    //    if (keys.ShiftLeft && keys.ControlLeft) {
    //        event.preventDefault()
    //        setRichTextObj({
    //            ...richTextObj,
    //            formatting: {
    //                ...richTextObj.formatting,
    //                textDirection: 'rtl'
    //            }
    //        })
    //    }

    //});
    //inputEl?.current?.addEventListener("keyup", (event) => {
    //    keys[event.code] = true;
    //});

    useEffect(() => {
        setState(strings.getStringFromRichTextObj(richTextObj))
    }, [richTextObj.value, richTextObj.formatting.textDirection])

    return (
        <>
            <TextField
                {...props}
                dir={richTextObj.formatting.textDirection}
                inputRef={inputEl}
                value={richTextObj.value}
                onChange={(event) => {
                    setRichTextObj({
                        ...richTextObj,
                        value: event.target.value
                    })
                    if (typeof props.onChange === 'function')
                        props.onChange(event)
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Shift') {
                        // write your functionality here
                    }
                }}
            />
            <div
                style={{cursor: 'pointer'}}
            >
                <FormatTextdirectionLToRIcon
                    fontSize={'small'}
                    color={richTextObj?.formatting?.textDirection === 'rtl' ? 'action' : 'primary'}
                    onClick={() => {
                        setRichTextObj({
                            ...richTextObj,
                            formatting: {
                                ...richTextObj.formatting,
                                textDirection: 'ltr'
                            }
                        });
                        inputEl.current.focus();
                    }}
                />
                <FormatTextdirectionRToLIcon
                    fontSize={'small'}
                    color={richTextObj?.formatting?.textDirection === 'rtl' ? 'primary' : 'action'}
                    onClick={() => {
                        setRichTextObj({
                            ...richTextObj,
                            formatting: {
                                ...richTextObj.formatting,
                                textDirection: 'rtl'
                            }
                        });
                        inputEl.current.focus();
                    }}
                />

            </div>
        </>);
}


export default RichTextField;