import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getContactRelDetail } from "../../api/cms";
import { getClientDetails, getClientDetailProgram } from "../../api/clients";
import { getEmployeeDetails } from "../../api/employees";
import { getEmployeeApplicantDetails } from "../../api/applications";
import ContactRelSelect from "../ContactSelect/ContactRelSelect";
import { contactColumns } from "../data/clients";
import AddContact from "../explorer/Tabs/Dialog/AddContact";
import useStyles from "./styles";
import baseStyles from '../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";
import Employee from "./employee";
import Programs from "./programs";
import Summer from "./summer";
import UserInfo from '../../screens/users/UserInfo';
import * as constant from "../../constants";
import useUnsavedChangesWarn from "../../hooks/useUnsavedChangesWarn";
import { BoxContainer, DivContainer, FlexBetween, Title } from "../../assets/styles/styledComponents";
import FeaturedIcon from "../common/FeaturedIcon";
import { accountService } from "../../_services/account.service";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

export default function EditClientPage(props) {
    const navigate = useNavigate();
    const { employeeId } = useParams();
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [value, setValue] = React.useState("employee");
    // const [value, setValue] = React.useState("programs");
    // const [value, setValue] = React.useState("summerInformation");
    const [view, setView] = React.useState(true);
    const [parent_view, setParentView] = React.useState(true);
    const [education, setEducation] = React.useState(true);
    const [information, setInformation] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [userInfo, setUserInfo] = React.useState(null);
    const [tabvalue, setTabValue] = React.useState("client");
    const [type, setType] = React.useState(0);
    const [userInfoMode, setUserInfoMode] = React.useState('edit');
    const [showAddNew, setShowAddNew] = React.useState(false);
    const [relatedContactRows, setRelatedContactRows] = React.useState({
        rows: [{}],
        columns: contactColumns,
        screen: "client_contact",
    });

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const clientSetDetail = useStoreActions((actions) => actions.clients.setDetail);
    const setFields = useStoreActions((actions) => actions.clients.setFields);
    const exploringId = useStoreState((state) => state.exploringId);
    const exploringType = useStoreState((state) => state.exploringType);
    const setExploringType = useStoreActions(
        (actions) => actions.setExploringType
    );
    const previousPath = useStoreState((state) => state.previousPath);
    const fetchEmployees = useStoreActions((actions) => actions.employee.fetchEmployees)
    const employeeDetail = useStoreState((state) => state.employee.detail);
    const applicantDetail = useStoreState((state) => state.em);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);
    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );
    const exploringPath = useStoreState((state) => state);

    const [positionValues, setPositionValues] = React.useState([{ id: 0, name: "" }]);
    const [yungermanId, setYungermanId] = React.useState();

    const sectionChanged1 = useStoreState((state) => state.employee.sectionChanged1);
    const sectionState1 = useStoreState((state) => state.employee.sectionState1);
    const sectionState2 = useStoreState((state) => state.employee.sectionState2);
    const sectionState3 = useStoreState((state) => state.employee.sectionState3);
    const sectionState4 = useStoreState((state) => state.employee.sectionState4);
    const sectionState5 = useStoreState((state) => state.employee.sectionState5);
    const location = useLocation();
    const currentPath = location.pathname;

    const setFirstNameError = useStoreActions(
        (actions) => actions.employee.setFirstNameError
    );
    const setSecondNameError = useStoreActions(
        (actions) => actions.employee.setSecondNameError
    );

    const [rollbackAction_Programs, setRollbackAction_Programs] = React.useState(undefined);
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    useEffect(() => {
        global.fetchWrapper.get(constant.BASE_URL + 'api/hr/employeePositionValues').then(data => setPositionValues(data))
        setValue("employee");
    }, []);

    useEffect(() => {
        positionValues.forEach(position => {
            if (position.name === 'Yungerman') {
                setYungermanId(position.id);
            }
        })
    }, [positionValues]);

    useEffect(() => {
        console.log(currentPath)
        console.log(employeeId)
        setFirstNameError("");
        setSecondNameError("");
        if (employeeId < 0) { // if url is any negative value - this means it is a new record
            setView(false);
            setParentView(false);
            setEducation(false);
            setInformation(false);
            setType(0);
        } else {
            setView(true);
            setParentView(true);
            setEducation(true);
            setInformation(true);
            setType(1);
            if (exploringId != employeeId) {
                setLoading();
                getEmployeeDetails(employeeId).then((response) => {
                    if (!!response && response !== 404) { /* checking the url id is present in database and valid id */
                        setExploringName(response.name);
                        setExploringId(response.id);
                        setExploringMode("employee")
                        loadEmployeePersonalDetail(response.id)
                    } else {
                        global.AjaxFailToast(`Wrong id: ${employeeId}. Re-directing to this employess `);
                        history('employees');
                    }
                    endLoading();
                });
            } else {
                loadEmployeePersonalDetail(exploringId)
            }
            //var url = exploringType === "employee" ? constant.EMPLOYEE_DETAIL_PERSONAL : constant.PROFESSIONAL_DETAIL_PERSONAL;
            // getContactRelDetail("clients", exploringId)
            //     .then((response) => {
            //         global.log(response);
            //         setRelatedContactRows({
            //             rows: response,
            //             columns: contactColumns,
            //             screen: "client_contact",
            //         });
            //     })
            //     .then(() => {
            //         getClientDetails(exploringId)
            //             .then((response) => {
            //                 global.log(response);
            //                 clientSetDetail(response);
            //             })
            //     })
            //     .then(() => {
            //         getClientDetailProgram(id).then((value) => {
            //             global.log(value);
            //             setFields(value);
            //         })
            //     })
            //     .then(() => endLoading());
        }
    }, []);

    const loadEmployeePersonalDetail = (id) => {
        global.fetchWrapper.get(constant.EMPLOYEE_DETAIL_PERSONAL + id)
            .then(
                (data) => {
                    endLoading();
                    setEmployeeDetail(data);
                    if (data.contact === null)
                        setExploringName("undefined");
                    else
                        setExploringName(data.contact.firstName + " " + data.contact.lastName);
                },
                (rejects) => {
                    endLoading();
                    global.AjaxFailToast();
                }
            );
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        global.log("handleClose");
        setOpen(false);
    };
    const disagree = () => {
        setOpen(false);
    };

    const agree = () => {
        setOpen(false);
        setView(true);
        setParentView(true);
        setEducation(true);
        setInformation(true);
        setValue(tabvalue);
    };
    const handleChange = (event, newValue) => {
        if (newValue == value) {
            event.preventDefault();
            return;
        }
        if (hasChanges()) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                event.preventDefault();
                return;
            }
        }
        setClean();
        if (rollbackAction_Programs)
            rollbackAction_Programs();
        if (type == 1 && (!view || !parent_view || !education || !information)) {
            handleClickOpen();
            setTabValue(newValue);
        } else {
            setValue(newValue);
        }
    };

    const exploringMode = useStoreState((state) => state.exploringMode);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const setExploringId = useStoreActions(
        (actions) => actions.setExploringId
    );
    const history = useStoreState((state) => state.history);

    const handleCloseDrawer = () => {
        if (value !== "employee" ||
            (sectionState1 !== "read" || sectionState2 !== "read" || sectionState3 !== "read" || sectionState4 !== "read" || sectionState5 !== "read")) {
            if (!hasChanges() || wConfirm())
                goBack();
        }
        else
            goBack();
    };

    const goBack = () => {
        setExploringMode("detail");
        navigate(-1);

        // if (previousPath === "FROM EMPLOYEE DETAIL" && exploringId !== -1)
        //     history('/' + 'employee' + '/details/' + exploringId);
        // else {
        //     setExploringMode("detail");
        //     setExploringId(0);
        //     history('/' + 'employees');
        // }
        props.onClick();
    };

    const handleAddRelationshipClick = () => {
        setShowAddNew(true);
    };
    const handleModalClose = () => {
        setShowAddNew(false);
    };

    const handleAddedRelationshipSuccess = () => {
        alert("relatoinship added!");
        setShowAddNew(false);
    };

    const exploringName = useStoreState((state) => state.exploringName);

    const [isYungerman, setIsYungerman] = React.useState();
    const [isProfessional, setIsProfessional] = React.useState(false)
    const [isApplicant, setIsApplicant] = React.useState(false)
    useEffect(() => {
        setIsYungerman(props.exType == "yungerman" || (!global.isEmpty(employeeDetail) && employeeDetail.positionId === yungermanId));
        setIsProfessional(employeeDetail?.isProfessional)
        if (employeeDetail?.isProfessional) {
            getUserInfo(employeeDetail.contactId)
        }
    }, [employeeDetail, employeeDetail.positionId])

    const getUserInfo = (contactId) => {
        accountService.getUserByContactId(contactId).then((res) => {
            if (!!res && res.id > 0) {
                let data = {
                    ...res,
                    contactId: res?.contact?.id,
                    userId: res.id,
                    isActive: res.isActive,
                }
                setUserInfoMode("read")
                setUserInfo(data)
            } else {
                setUserInfo(null)
            }
        })
    }

    const onCloseUserInfo = (reload) => {
        if (reload) {
            getUserInfo(employeeDetail.contactId)
        } else {
            setUserInfoMode('read')
        }
    }

    return (
        <div className={classes.content}>
            <div className={baseClasses.toolbar} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleCloseDrawer}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                {exploringName}
            </div>
            <Card variant="outlined">
                <CardContent classes={{ root: baseClasses.card_view }}>
                    <div className={classes.root}>
                        <AppBar
                            className={baseClasses.appbarColor}
                            position="static"
                            color="primary"
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab
                                    className={baseClasses.tabClassSelected}
                                    value="employee"
                                    label="Employee"
                                    {...setRowAndTab(0)}
                                />
                                {exploringId !== -1 && isYungerman &&
                                    <Tab
                                        className={baseClasses.tabClassSelected}
                                        value="programs"
                                        label="Programs"
                                        {...setRowAndTab(1)}
                                    />

                                }
                                {exploringId !== -1 && isYungerman &&
                                    <Tab
                                        className={baseClasses.tabClassSelected}
                                        value="summerInformation"
                                        label="Summer Information"
                                        {...setRowAndTab(2)}
                                    />
                                }
                                {exploringId !== -1 && isProfessional &&
                                    <Tab
                                        className={baseClasses.tabClassSelected}
                                        value="user"
                                        label="User"
                                        {...setRowAndTab(3)}
                                    />
                                }

                            </Tabs>
                        </AppBar>
                        <div className={classes.panelContent}>
                            <TabPanel
                                value={value}
                                index="employee"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                <Employee
                                    view={view}
                                    onView={setView}
                                    parent_view={parent_view}
                                    onParentView={setParentView}
                                    type={type}
                                    addClientSuccess={props.addClientSuccess}
                                    onBackUrl={handleCloseDrawer}
                                ></Employee>
                                {/* <Programs clientId={exploringId} /> */}
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="programs"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                {exploringId !== -1 &&
                                    < Programs
                                        setRollbackAction={setRollbackAction_Programs}
                                    ></Programs>
                                }
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="summerInformation"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                {exploringId !== -1 &&
                                    <Summer></Summer>
                                }
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="user"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                <DivContainer padding="10px" bgColor="#fff">
                                    <FlexBetween border>
                                        <Title>User Info</Title>
                                        {userInfoMode === "read" ? (
                                            <EditIcon
                                                className={baseClasses.edit_icon}
                                                onClick={() => {
                                                    setUserInfoMode('edit')
                                                }}
                                            />
                                        ) : (
                                            <CloseIcon
                                                className={baseClasses.edit_icon}
                                                onClick={() => {
                                                    setUserInfoMode('read')
                                                }}
                                            />
                                        )}
                                    </FlexBetween>
                                    {exploringId !== -1 &&
                                        <UserInfo
                                            userModule='professional'
                                            defaultValues={!userInfo ? {
                                                firstName: employeeDetail.contact.firstName,
                                                lastName: employeeDetail.contact.lastName,
                                                email: employeeDetail.contact.email ?? employeeDetail.contact.email1 ?? employeeDetail.contact.email2,
                                                contactId: employeeDetail.contactId
                                            } : {}}
                                            editData={!!userInfo ? { ...userInfo } : null}
                                            onClose={onCloseUserInfo}
                                            mode={userInfoMode}
                                        />
                                    }
                                </DivContainer>
                            </TabPanel>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to cancel your changes?"}
                </DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={agree} color="primary">
                        Yes
                    </Button>
                    <Button onClick={disagree} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
