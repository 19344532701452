import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@mui/styles"
import { makeStyles } from "@mui/styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import baseStyles from '../../assets/styles'
import * as constant from "../../constants";

import CancelDialog from "./CancelDialog";
import ManageProfessionalView from "./ManageProfessionalView";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        margin: "12px 26px",
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        /*'& .MuiPaper-outlined': {
                border: '0px',
            }*/
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .MuiTab-root": {
            color: "#FFFFFF"
        },
    },
    
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

const ManageProfessionalPage = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const theme = useTheme();

    const [value, setValue] = React.useState("professional");
    const [view, setView] = React.useState(true);
    const [parent_view, setParentView] = React.useState(true);
    const [education, setEducation] = React.useState(true);
    const [information, setInformation] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [tabvalue, setTabValue] = React.useState("client");
    const [type, setType] = React.useState(0);
    const [showAddNew, setShowAddNew] = React.useState(false);

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const exploringId = useStoreState((state) => state.exploringId);
    const previousPath = useStoreState((state) => state.previousPath);
    const setProfessionalDetail = useStoreActions(
        (actions) => actions.professional.setDetail
    );
    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );

    const [positionValues, setPositionValues] = React.useState([
        { id: 0, name: "" },
    ]);

    const sectionState1 = useStoreState((state) => state.professional.sectionState1);
    const sectionState2 = useStoreState((state) => state.professional.sectionState2);
    const sectionState3 = useStoreState((state) => state.professional.sectionState3);
    const sectionState4 = useStoreState((state) => state.professional.sectionState4);
    const sectionState5 = useStoreState((state) => state.professional.sectionState5);

    const [backspaceConfirm, setBackspaceConfirm] = React.useState(false);
    const setFirstNameError = useStoreActions(
        (actions) => actions.professional.setFirstNameError
    );
    const setSecondNameError = useStoreActions(
        (actions) => actions.professional.setSecondNameError
    );

    useEffect(() => {
        global.fetchWrapper
            .get(constant.BASE_URL + "api/hr/employeePositionValues")
            .then((data) => setPositionValues(data));
        setValue("professional");
    }, []);

    useEffect(() => {
        setFirstNameError("");
        setSecondNameError("");
        if (global.isEmpty(exploringId) || exploringId === -1) {
            setView(false);
            setParentView(false);
            setEducation(false);
            setInformation(false);
            setType(0);
        } else {
            setView(true);
            setParentView(true);
            setEducation(true);
            setInformation(true);
            setType(1);

            setLoading();
            var id = exploringId;
            global.fetchWrapper.get(constant.PROFESSIONAL_DETAIL_PERSONAL + id).then(
                (data) => {
                    endLoading();
                    setProfessionalDetail(data);
                    if (data.contact === null) setExploringName("undefined");
                    else
                        setExploringName(
                            data.contact.firstName + " " + data.contact.lastName
                        );
                },
                (rejects) => {
                    endLoading();
                    global.AjaxFailToast();
                }
            );
        }
    }, [exploringId, showAddNew]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const disagree = () => {
        setOpen(false);
    };

    const agree = () => {
        setOpen(false);
        setView(true);
        setParentView(true);
        setEducation(true);
        setInformation(true);
        setValue(tabvalue);
    };
    const handleChange = (event, newValue) => {
        if (type == 1 && (!view || !parent_view || !education || !information)) {
            handleClickOpen();
            setTabValue(newValue);
        } else {
            setValue(newValue);
        }
    };

    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const history = useStoreState((state) => state.history);

    const handleCloseDrawer = () => {
        if (
            sectionState1 !== "read" ||
            sectionState2 !== "read" ||
            sectionState3 !== "read" ||
            sectionState4 !== "read" ||
            sectionState5 !== "read"
        ) {
            setBackspaceConfirm(true);
        } else goBack();
    };

    const goBack = () => {
        setExploringMode("detail");

        if (previousPath === "FROM EMPLOYEE DETAIL" && exploringId !== -1)
            history("/" + "professional" + "/details/" + exploringId);
        else {
            setExploringMode("detail");
            setExploringId(0);
            history("/" + "professionals");
        }
        //props.onClick();
    };

    return (
        <div className={classes.content}>
            <div className={baseClasses.toolbar} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleCloseDrawer}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                Professional
            </div>
            <Card variant="outlined">
                <CardContent classes={{ root: baseClasses.card_view }}>
                    <div className={baseClasses.root}>
                        <AppBar
                            className={baseClasses.appbarColor}
                            position="static"
                            color="primary"
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab
                                    className={baseClasses.tabClass}
                                    value="professional"
                                    label="Professional"
                                    {...setRowAndTab(0)}
                                />
                            </Tabs>
                        </AppBar>

                        <div className={baseClasses.panelContent}>
                            <TabPanel
                                value={value}
                                index="professional"
                                className={baseClasses.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                <ManageProfessionalView
                                    view={view}
                                    onView={setView}
                                    parent_view={parent_view}
                                    onParentView={setParentView}
                                    type={type}
                                    addClientSuccess={props.addClientSuccess}
                                    onBackUrl={handleCloseDrawer}
                                ></ManageProfessionalView>
                            </TabPanel>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <CancelDialog onOpen={open} onOk={agree} onCancel={disagree} />
        </div>
    );
};

export default ManageProfessionalPage;
