import React, { useEffect, useState } from "react";
import {
    FormControl,
    FormHelperText,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Grid,
    Button,
    Input,
    TextField,
    InputLabel,
    MenuItem, Select,
    Card, CardContent, Typography, Divider
} from "@mui/material";
import { toast } from "react-toastify";
import { useStoreActions, useStoreState } from "easy-peasy";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import useStyles from "../styles";
import baseStyles from '../../../assets/styles';
import * as constant from "../../../constants";
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";
import ProtectedComponent from '../../ProtectedComponent/ProtectedComponent'
import { useNavigate } from "react-router-dom";
import { saveContact } from '../../../api/contact'
import { updateHistory } from '../../../api/employees'


const boolToString = (value) => {
    return !value ? "No" : "Yes"
}

const NameToString = (nameList, contact) => {
    if (!contact) {
        return "";
    }

    let names = "";

    for (let i = 0; i < nameList.length; i++) {
        const contactValue = contact[nameList[i]];

        if (typeof contactValue === "boolean") {
            names += boolToString(contactValue);
        } else {
            names += global.isEmpty(contactValue) ? "" : " " + contactValue;
        }
    }

    return names.trim();
};

export const NamesToStringComp = ({ nameList, employeeDetail }) => {
    const classes = useStyles();
    const baseClasses = baseStyles();

    return (
        <div className={baseClasses.fieldvalue}>
            {NameToString(nameList, employeeDetail.contact) === "" ? "-" : NameToString(nameList, employeeDetail.contact)}
        </div>
    );
}
export const EmployeeTypesToString = ({ employeeDetail }) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    let employeeTypes = []
    if (employeeDetail.isYungerman)
        employeeTypes.push("Yungerman")
    if (employeeDetail.isProfessional)
        employeeTypes.push("Professional")
    if (employeeDetail.isEmployee)
        employeeTypes.push("Employee")
    if (employeeDetail.isLegal)
        employeeTypes.push("Legal")
    if (!employeeDetail.isActive)
        employeeTypes.push("Inactive")
    if (employeeDetail.isSummerOnly)
        employeeTypes.push("Summer Only")
    return (
        <div className={baseClasses.fieldvalue}>
            {employeeTypes.length > 0 ? employeeTypes.join(", ") : "-"}
        </div>
    )
}

export const CompSelectbox = ({ label, name, listValues, value, employeeDetail, setEmployeeDetail, variant, onChange }) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    return (
        <Grid item xs={12}>
            <FormControl
                fullWidth
                className={baseClasses.optionsContract}
            >
                <TextField
                    fullWidth={true}
                    label={label}
                    id={"employee detail label" + name + "select"}
                    select
                    variant={variant}
                    name={name}
                    size="small"
                    className={baseClasses.selection}
                    style={{ marginLeft: 0, marginRight: 0 }}
                    SelectProps={{
                        multiple: name === 'specialties' || name === 'paymentOptions' ? true : false,
                        value: name === 'specialties' || name === 'paymentOptions' ? value : global.isEmpty(value) ? 0 : value,
                        onChange: (event) => {
                            setDirty();
                            var value = event.target.value;
                            if (value === 0)
                                value = null;
                            if (name !== 'specialties' && name !== 'paymentOptions')
                                setEmployeeDetail({ ...employeeDetail, [name]: value });
                            else {
                                let idArray = []
                                let eventValuesToBeAdded = [];
                                let valueToBeRemoved = 0;
                                value.forEach(ev => {
                                    if (!idArray.includes(ev)) {
                                        idArray.push(ev)
                                    } else {
                                        valueToBeRemoved = ev
                                    }
                                })
                                value.forEach(ev => { if (ev !== valueToBeRemoved) eventValuesToBeAdded.push(ev); })
                                global.log("event value:", eventValuesToBeAdded)

                                setEmployeeDetail({ ...employeeDetail, [name]: eventValuesToBeAdded.join(",") });
                            }
                            if (typeof onChange === 'function')
                                onChange();
                        },
                    }}
                >
                    {listValues && listValues.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.name} >
                                {item.name}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </FormControl>
        </Grid>
    );
};

export const CompTextField = ({ xs, label, name, employeeDetail, setEmployeeDetail, variant, placeholder }) => {
    const classes = useStyles();
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    return (
        <Grid item xs={xs}>
            <div>
                <FormControl
                    fullWidth>
                    <TextField
                        variant={variant}
                        placeholder={placeholder != undefined && placeholder}
                        label={label}
                        type={name === "rate" || name === "parsonageLimit" ? "number" :
                            name === "parsonageFormExpiration" ? "date" : "text"}
                        step={name === "parsonageLimit" ? "0.01" : "any"}
                        value={
                            name === "parsonageLimit" ? (global.isEmpty(employeeDetail[name]) ? "0.00" : employeeDetail[name]) :
                                name === "rate" ? (global.isEmpty(employeeDetail[name]) ? "0" : employeeDetail[name]) :
                                    name === "parsonageFormExpiration" ? (global.isEmpty(employeeDetail[name]) ? null : employeeDetail[name].slice(0, 10)) :
                                        global.isEmpty(employeeDetail[name]) ? "" : employeeDetail[name]
                        }
                        onChange={(event) => {
                            setDirty();
                            var var1 = event.target.value;
                            if (name === "parsonageLimit") {
                                var1 = var1.toString();
                                if (var1.indexOf(".") > 0)
                                    var1 = var1.substr(0, var1.indexOf(".") + 3);
                                setEmployeeDetail({ ...employeeDetail, [name]: parseFloat(var1) });
                            }
                            else {
                                setEmployeeDetail({ ...employeeDetail, [name]: var1 });
                            }
                        }}
                    />
                </FormControl>
            </div>
        </Grid>
    );
};

export const CompTextMulti = ({ xs, label, name, permissions, employeeDetail, setDetail, variant }) => {
    const [changesHelper] = useUnsavedChangesWarn();
    const [value, setValue] = React.useState(employeeDetail[name]);
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    return (
        <Grid item xs={xs}>
            <ProtectedComponent
                permissions={permissions}
                canEdit={!permissions}
                allow={!permissions}
            >
                <FormControl className="w-100">
                    <TextField
                        variant={variant}
                        className="w-100"
                        label={label}
                        multiline
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        onBlur={(event) => {
                            setDirty();
                            setDetail({ ...employeeDetail, [name]: event.target.value })
                        }}
                    />
                </FormControl>
            </ProtectedComponent>
        </Grid>
    );
};


export const SectionComponent = ({ headerTitle, onSave, onBeforeSave, readDOM, editDOM, state, onStateChange, employeeDetail, rollbackChanges, confirmUnsavedChanges, onBackUrl = () => { } }) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const exploringId = useStoreState((state) => state.exploringId);
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const exploringType = useStoreState((state) => state.exploringType);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);
    const fetchEmployees = useStoreActions((actions) => actions.employee.fetchEmployees)
    const setProfessionalDetail = useStoreActions((actions) => actions.professional.setDetail);
    const setExploringName = useStoreActions((actions) => actions.setExploringName);

    const navigate = useNavigate();

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;


    const _handleParentView = () => {
        onStateChange("edit");
    };

    const handleSaveContact = async (contactId, contactData) => {

        try {
            const result = await saveContact(contactId, contactData);
            return result.id
        } catch (error) {
            console.error('Error saving contact:', error);
        }
    };
    const handleSaveHistory = async (contactId, applicationId, historyData) => {
        try {
            const result = await updateHistory(contactId, applicationId, historyData);
            if (result) {
                return result.id;
            } else {
                console.error('Failed to save or update history.');
                return null;
            }
        } catch (error) {
            console.error('Error saving contact:', error);
            return null;
        }
    };

    const _handleParentClose = () => {
        if (confirmUnsavedChanges && !confirmUnsavedChanges())
            return;
        if (rollbackChanges)
            rollbackChanges();
        onStateChange("read");
    };
    const refreshEmployee = () => {
        return fetchEmployees()

    }
    const reloadData = (response) => {
        let urlDetail = exploringType === "professional" ? constant.PROFESSIONAL_DETAIL_PERSONAL : constant.EMPLOYEE_DETAIL_PERSONAL;
        global.fetchWrapper.get(urlDetail + response.id)
            .then(
                (data) => {
                    if (exploringType === "employees")
                        setEmployeeDetail(data);
                    else if (exploringType === "professional")
                        setProfessionalDetail(data);
                    if (data.contact === null)
                        setExploringName("undefined");
                    else
                        setExploringName(data.contact.firstName + " " + data.contact.lastName);
                },
                (rejects) => {
                    global.AjaxFailToast();
                }
            )
            .then(() => refreshEmployee())
            .finally(() => {
                endLoading();
                onStateChange("read");
            });
    }

    return (
        <>
            <div className={classes.header_1}>
                {exploringId !== -1 &&
                    <Typography className={baseClasses.title_2} variant="h6" component="span">
                        {exploringId !== -1 && headerTitle}
                    </Typography>
                }
                {exploringId !== -1 &&
                    <div>
                        {state === "read" ? (
                            <EditIcon
                                className={baseClasses.edit_icon}
                                onClick={_handleParentView}
                            />
                        ) : (
                            <CloseIcon
                                className={baseClasses.edit_icon}
                                onClick={_handleParentClose}
                            />
                        )}
                    </div>
                }
                {/* { propsMeta.parent_view ? <EditIcon className={classes.editIcon} onClick={_handleParentView} /> : <CloseIcon className={classes.editIcon} onClick={_handleParentClose} /> } */}
            </div>
            <Divider className={classes.separator}></Divider>
            {exploringId === -1 ? editDOM :
                state === "read" ? (
                    readDOM
                ) : (
                    editDOM
                )}
            {state === "edit" && (
                <Divider className={classes.separator} />
            )}
            {(exploringId === -1 || state === "edit") && (
                <div className={baseClasses.btnSave}>
                    <Button size="small" onClick={() => {
                        if (confirmUnsavedChanges && !confirmUnsavedChanges())
                            return;
                        if (rollbackChanges)
                            rollbackChanges();
                        onStateChange("read")
                        if (exploringId === -1)
                            onBackUrl();
                    }
                    }>
                        CANCEL
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            console.log(employeeDetail)
                            // onSave();
                            handleSaveContact(employeeDetail.contactId, employeeDetail.contact)
                            handleSaveHistory(employeeDetail.contactId, employeeDetail.id, employeeDetail)
                            // if (typeof onBeforeSave === 'function') {
                            //     let readyToSave = onBeforeSave()
                            //     if (!readyToSave)
                            //         return;
                            // }

                            // setLoading();
                            // global.log('the employee detail as seen from the save button ', employeeDetail)
                            // let url = `/api/application/savecontact/${employeeDetail.contactId}`;                            return global.fetchWrapper
                            //     .post(url + exploringId, employeeDetail)
                            //     .then(
                            //         (response) => {
                            //             if (exploringId === -1) {
                            //                 setExploringId(response.id);
                            //                 reloadData(response);
                            //                 navigate(`../employee/${response.id}/enrollment`, { replace: true })
                            //             }
                            //             else {
                            //                 reloadData(response);

                            //             }
                            //             if (response.responseCode === 200) {
                            //                 setClean();
                            //             }
                            //         },
                            //         (error) => {
                            //             endLoading();
                            //         }
                            //     );
                        }}
                        size="small"
                    >
                        Save
                    </Button>
                </div>
            )}
        </>
    );
};