import React from "react";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";

const DeleteProfessionalDialog = (props) => {
    return (
    <Dialog
      open={props.openDialog}
      onClose={props.onCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Are you sure you want to delete this Contact?"}
      </DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={props.onConfirm} color="primary">
          Yes
        </Button>
        <Button onClick={props.onCancel} color="primary" autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProfessionalDialog;
