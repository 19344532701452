import { accountService } from "../_services/account.service"

const roleContain = (roles) => {
    if (!accountService || !accountService.userValue)
        return false;

    var userRoles = accountService.userValue.roles;

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    if (!roles || roles.length === 0) {
        return true;
    }

    return userRoles.some((ur) => { return roles.some((ro) => { return ro != undefined && ro.toLowerCase() === ur.toLowerCase() }) })
};
const userHasRole = roleContain;

const isAdmin = () => {
    return roleContain(["Admin"]);
};

const roles = {
    admin: "Admin",
    intake: "Intake",
    office: "Office",
    financial: "Financial",
    careManager: "CareManager",
    careManagerSupervisor: "CareManagerSupervisor",
    meargen: "Meargen",
    roshMeargen: "RoshMeargen",
    yungerleitDept: "YungerleitDept",
    yungerleitAdmin: "YungerleitAdmin",
    menahel: "Menahel",
    professional: "Professional",
};

const userRoles = accountService.userValue?.roles;


export const authorization = {
    roles,
    userHasRole,
    isAdmin,
    userRoles
}