import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { clientFileTemplate, employeeFileTemplate } from '../../constants';
import UploadFileInput from './UploadFileInput';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getFileExtension } from '../../_helpers/file'

const UploadFile = ({ fileTemplate, label, primaryId, folderTemplate, file, setFile, saveOnChange, table, canModifyUploadDate, disabled }) => {
    const [amountOfFilesUploaded, setAmountOfFilesUploaded] = useState(0);
    const [templateFileId, setTemplateFileId] = useState(-1);
    const [fileTemplatesInUse, setFileTemplatesInUse] = useState(
        table == "clients" ? clientFileTemplate : table == "employees" ? employeeFileTemplate : {}
    );
    useEffect(() => {
        let templateIDs = table == "clients" ? clientFileTemplate : table == "employees" ? employeeFileTemplate : {};
        setTemplateFileId(templateIDs[fileTemplate]);
    }, [])

    return (
        <Grid item xs={12} style={{ padding: 5 }}>
            <span>{label}</span>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <div>
                        <UploadFileInput
                            data={{ templateFileId: templateFileId, primaryId: primaryId, folderTemplate: folderTemplate }}
                            label={file?.name}
                            onFileChange={(fileTemplate, fileArr, newFile) => {
                                setFile(newFile);
                                setAmountOfFilesUploaded(amountOfFilesUploaded + 1)
                            }}
                            saveOnChange={saveOnChange}
                            table={table}
                            disabled={disabled}
                        />
                        {file?.id &&
                            <>
                            <FileDownloadIcon
                                color={'primary'}
                                fontSize={'small'}
                                style={{ cursor: "pointer", marginTop: '5px', marginRight: '5px' }}
                                onClick={() => global.fetchWrapper.downloadFile(file.id)}
                            />
                            {getFileExtension(file?.name) === 'pdf' && 
                                <OpenInNewIcon
                                    color={'primary'}
                                    fontSize={'small'}
                                style={{ cursor: "pointer", marginTop: '5px', marginRight: '5px' }}
                                onClick={() => global.fetchWrapper.readFileInNewTab(file.id)}

                                />
                            }

                            </>
                        }

                    </div>
                </Grid>

                <Grid item xs={4}>
                    {file?.uploadedOn && 
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label={"Uploaded On"}
                                value={file?.uploadedOn}
                                onChange={(valDate) => {
                                    if (!valDate || !valDate.toDateString)
                                        return;
                                    if (new Date(file.uploadedOn).toDateString() == valDate.toDateString())
                                        return;
                                    setFile({ ...file, uploadedOn: valDate, state: file?.id ? "modified" : 'new' });
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    size="small"
                                    InputLabelProps={{shrink:true}}
                                />}
                                disabled={canModifyUploadDate == false}
                                minDate={new Date(2000, 1, 1)}
                                maxDate={new Date(2100, 1, 1)}
                                
                            />
                        </LocalizationProvider>
                    }
                    {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                    {/*    <KeyboardDatePicker*/}
                    {/*        id={fileTemplate + "_uploadedOn"}*/}
                    {/*        disableToolbar*/}
                    {/*        inputVariant="outlined"*/}
                    {/*        format="MM/dd/yyyy"*/}
                    {/*        label={"Uploaded On"}*/}
                    {/*        size="small"*/}
                    {/*        KeyboardButtonProps={{*/}
                    {/*            'aria-label': 'change date',*/}
                    {/*        }}*/}
                    {/*        value={new Date(file?.uploadedOn) < new Date(2000, 1, 1) ? null : file?.uploadedOn}  //value={moment(file?.uploadedOn).format("YYYY-MM-DD")}*/}
                    {/*        onChange={(event, date) => {*/}
                    {/*            console.log("");*/}
                    {/*            setFile({ ...file, uploadedOn: date, state: "modified" });*/}
                    {/*        }}*/}
                    {/*        disabled={canModifyUploadDate == false}*/}
                    {/*    />*/}
                    {/*</MuiPickersUtilsProvider>*/}
                </Grid>
            </Grid>
            {/*<a color="primary" style={{ textDecoration: "underline", color: "blue" }} download={file?.name} href={'data:application/octet-stream;base64,' + currentFileBytes}>{uploadedTemplateFile.name == "" ? <></> : "Download ↓"}</a>*/}
        </Grid>
    );
}

export default UploadFile;