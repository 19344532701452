import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Checkbox,
    FormControl,
    FormControlLabel, FormGroup, FormLabel,
    Input, InputAdornment,
    InputLabel, MenuItem,
    Select,
    Switch, TextField,
    Typography, Grid
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Chip } from '@mui/material';
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";

import {
    getClientDetailProgram,
    getClientDetailProgramLight, saveClientDetailProgram,
    saveClientDetailProgramLight, scheduleColumns
} from "../../../../api/clients";
import ContactSelectField from "../../../../components/ContactSelect/ContactSelectField";
import TextSingleWrapper from "../../../../utils/TextSingleWrapper";
import { scheduleRow } from "../../../data/clients";
import SelectContact from "../Dialog/SelectContact";
import ProgramLight from "./ProgramLight";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
import usePermission from "../../../../hooks/usePermission";
import ProtectedComponent from "../../../../components/ProtectedComponent/ProtectedComponent";
import ServiceDetails from "../../../../screens/client/edit_client/ServiceDetails/ServiceDetails";
import { numbers } from "../../../../_helpers/numbers";

export default function Program(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const theme = useTheme();

    const { clientData, clientProgramData, isEmployee } = props;
    const exploringId = useStoreState((state) => state.exploringId);

    const [open, setOpen] = React.useState(false);
    const [dlgSelectProvider, setDlgSelectProvider] = React.useState(false);
    const [maxWidth, setMaxWidth] = React.useState("sm");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [selectType, setSelectType] = React.useState(null);
    const [card1Status, setCard1Status] = React.useState("upToDate");
    const [isSaveEnable, setIsSaveEnable] = React.useState(0); //1 : 2 - clients
    const [isSaveEnable2, setIsSaveEnable2] = React.useState(false); // clients
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const [assigneeContact, setAssigneeContact] = React.useState("");
    const [assigneePerError, setAssigneePerError] = React.useState("");

    const [contactSelectField, setContactSelectField] = React.useState(null);
    const [contactSelectField2, setContactSelectField2] = React.useState(null);
    const [contactSelectField3, setContactSelectField3] = React.useState(null);

    const isLoading = useStoreState((state) => state.isLoading);
    const setLoading = useStoreActions((action) => action.setLoading);
    const endLoading = useStoreActions((action) => action.endLoading);
    const [isLoadingLocal, setLoadingLocal] = React.useState(true);
    const { permissions } = props;
    const programsPermission = usePermission(permissions.programs);
    const programChPermission = usePermission(permissions.programComfortHealth);
    const programP3Permission = usePermission(permissions.programP3);
    const programLightPermission = usePermission(permissions.programLight);

    useEffect(() => {
        if (isLoadingLocal)
            setLoading();
        else
            endLoading();
    }, [isLoadingLocal]);

    const clientDetail = useStoreState((state) => state.clients.detail);
    const clientDetailSet = useStoreActions(
        (actions) => actions.clients.setDetail
    );
    const clientDetailSave = useStoreActions(
        (actions) => actions.clients.saveDetail
    );
    const [p3Agency, setP3Agency] = useState(clientDetail.p3Agency);

    const fields = useStoreState((state) => state.clients.fields);
    const fieldsLight = useStoreState((state) => state.clients.fieldsLight);
    const setFields = useStoreActions((actions) => actions.clients.setFields);
    const setFieldsLight = useStoreActions((actions) => actions.clients.setFieldsLight);

    const [programStatus, setProgramStatus] = React.useState({
        comfortHealth: fields.comfortHealth_status,
        p3: fields.p3_status
    })

    useEffect(() => {
        setEnrollStatus([
            fields.comfortHealth_enrolledOn ? true : false,
            fields.p3_enrolledOn ? true : false,
            fields.light_enrolledOn ? true : false,
        ]);
        // setYeshivasList(value);
        setIsSaveEnable(0);
        setIsSaveEnable2(false);
    }, []);

    useEffect(() => {
        if (isLoadingLocal == true) setIsSaveEnable(0);
        setLoadingLocal(false);
    }, [fields]);

    const handleFields = (event) => {
        const { name, value, type } = event.target;
        if (type === "checkbox") {
            setFields({ ...fields, [name]: event.target.checked });
        } else {
            setFields({ ...fields, [name]: value });
        }
        props.programChange();
        updateSaveEnable(1);
    };

    const updateSaveEnable = (mode) => {
        setIsSaveEnable(mode);
    };

    const openSelectProvider = () => {
        setDlgSelectProvider(true);
    };

    const openAddSchedule = () => {
        global.log("add schedule...");
        handleClickOpen();
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeSelectProvider = () => {
        setDlgSelectProvider(false);
    };

    const onSave = () => {
        if (fieldsLight.monthlyAgreedAmount && !numbers.isWholeInt(fieldsLight.monthlyAgreedAmount))
            return
        global.log(fieldsLight);
        global.log(p3Agency)
        global.log(isSaveEnable);
        if (isSaveEnable != 0)
            savePrograms();
        if (isSaveEnable2 == true) {
            const newClientDetail = {
                ...clientDetail,
                ["p3Agency"]: p3Agency,
            }
            clientDetailSave(newClientDetail);
            clientDetailSet(newClientDetail);
            setIsSaveEnable2(false);
        }
        saveClientDetailProgramLight({
            ...fieldsLight,
            paymentMethod: {
                paymentMethod: fieldsLight.paymentMethod,
                ccNumber: fieldsLight.ccNumber,
                nameOnCard: fieldsLight.nameOnCard,
                expMonth: fieldsLight.expMonth,
                expYear: fieldsLight.expYear,
                cvv: fieldsLight.cvv,
                billingZip: fieldsLight.billingZip,
                note: fieldsLight.note,
                isPrimaryPaymentMethod: fieldsLight.isPrimaryPaymentMethod
            }
        });
        setIsSaveEnable(0);
    };

    const _setRowAndColumnSchedule = () => {
        return {
            // row: clientScheduleData,
            rows: scheduleRow,
            columns: scheduleColumns,
            screen: "client_schedule",
        };
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const savePrograms = () => {
        setLoadingLocal(true);
        saveClientDetailProgram(exploringId, fields).then((value) => {
            setLoadingLocal(false);
            if (value != true) {
                global.AjaxFailToast();
            }
            else {
                global.AjaxSaved();
                props.programClean();
                props.programDetailClean();
            }
            //reload data to set new status
            getClientDetailProgram(exploringId).then((value) => {
                setFields(value);
                setProgramStatus({
                    comfortHealth: value.comfortHealth_status,
                    p3: value.p3_status,
                })
            });
        });
    };

    const handlerEnableSaveButton = (mode) => {
        setIsSaveEnable(mode);
    }

    const [enrollStatus, setEnrollStatus] = React.useState([false, false, false]);
    const [isSaveEnableTop, setIsSaveEnableTop] = React.useState(false);
    const GridListCheckmark = () => {
        return (
            <div
                style={{
                    textAlign: "center",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    marginBottom: "20px",
                    marginTop: "23px",
                    backgroundColor: "white",
                }}
            >
                <div
                    className={baseClasses.CheckBoxRow}
                    style={{
                        borderBottom: "1px solid rgba(0,0,0,0.12)",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                    }}
                >
                    <div className={baseClasses.grow} style={{ lineHeight: "46px" }}>

                    </div>
                    <div className={baseClasses.grow} style={{ lineHeight: "46px" }}>

                    </div>
                    <div className={baseClasses.grow} style={{ lineHeight: "46px" }}>

                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                setIsSaveEnableTop(false);
                                if (clientData == null) return;
                                savePrograms();
                            }}
                            className={baseClasses.capitalized}
                            color="primary"
                            variant="outlined"
                            disabled={!isSaveEnableTop}
                            style={{
                                float: "right",
                                marginRight: 24,
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div className={baseClasses.CheckBoxRow}>
                    <div className={baseClasses.CheckBoxFlexGrow}>Comfort Health</div>
                    <CheckBoxFlexGrow
                        checked={enrollStatus[0]}
                        onChange={() => {
                            setCheckStatus(0);
                            props.programChange();
                        }}
                    ></CheckBoxFlexGrow>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {global._DateField(fields.comfortHealth_enrolledOn)}
                    </div>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {(programStatus.comfortHealth === 'Inactive' || programStatus.comfortHealth === 'On Hold') &&
                            <Chip
                                color={programStatus.comfortHealth === 'Inactive' ? "error" : 'info'}
                                label={programStatus.comfortHealth}
                                size={"small"}
                                variant={"outlined"}
                            />
                        }
                    </div>
                </div>
                <div className={baseClasses.CheckBoxRow}>
                    <div className={baseClasses.CheckBoxFlexGrow}>P3</div>
                    <CheckBoxFlexGrow
                        checked={enrollStatus[1]}
                        onChange={() => {
                            setCheckStatus(1);
                            props.programChange();
                        }}
                    ></CheckBoxFlexGrow>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {global._DateField(fields.p3_enrolledOn)}
                    </div>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {programStatus.p3 == 'Inactive' &&
                            <Chip
                                color={"error"}
                                label={"Inactive"}
                                size={"small"}
                                variant={"outlined"}
                            />
                        }
                    </div>
                </div>
                <div className={baseClasses.CheckBoxRow}>
                    <div className={baseClasses.CheckBoxFlexGrow}>Cash</div>
                    <CheckBoxFlexGrow
                        checked={enrollStatus[2]}
                        onChange={() => {
                            setCheckStatus(2);
                            props.programChange();
                        }}
                    ></CheckBoxFlexGrow>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {global._DateField(fields.light_enrolledOn)}
                    </div>
                    <div className={baseClasses.CheckBoxFlexGrow}></div>
                </div>
            </div>
        );
    };

    const CheckBoxFlexGrow = (props) => {
        const { checked, onChange } = props;
        const styles = {
            "&.Mui-checked+.MuiSwitch-track": {
                backgroundColor: "red",
                color: "red",
            },
            "&.MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
                backgroundColor: "red",
                color: "red",
            },
        }

        return (
            <div className={baseClasses.grow}>
                <FormControl>
                    <Switch checked={checked} onChange={onChange} />
                </FormControl>
            </div>
        );
    };

    const CompFormStatus = ({ label, status, marginTop }) => {
        return (
            <div style={{ marginTop: marginTop }}>
                <FormControl
                    component="fieldset"
                    className={clsx(baseClasses.marginSpaceOne)}
                >
                    <FormGroup>
                        <FormControlLabel
                            value={`${label}:`}
                            control={
                                <FormLabel
                                    className={clsx(baseClasses.marginSpaceOne)}
                                    style={{ marginLeft: 10 }}
                                >
                                    {status == null || status == "" ? "Action needed" : status}
                                </FormLabel>
                            }
                            label={`${label}:`}
                            labelPlacement="start"
                        />
                    </FormGroup>
                </FormControl>
            </div>
        );
    };

    const IsEnrolledInProgram = () => {
        return !global.isEmpty(fields.comfortHealth_enrolledOn) || !global.isEmpty(fields.p3_enrolledOn) || !global.isEmpty(fields.light_enrolledOn)
    };

    const setCheckStatus = (row) => {
        setIsSaveEnableTop(true);

        setEnrollStatus([
            row == 0 ? !enrollStatus[0] : enrollStatus[0],
            row == 1 ? !enrollStatus[1] : enrollStatus[1],
            row == 2 ? !enrollStatus[2] : enrollStatus[2],
        ]);
        // global.log(fields);
        if (row == 0)
            setFields({
                ...fields,
                ["comfortHealth_enrolledOn"]: invertEnrollValue(
                    fields.comfortHealth_enrolledOn
                ),
            });
        else if (row == 1)
            setFields({
                ...fields,
                ["p3_enrolledOn"]: invertEnrollValue(fields.p3_enrolledOn),
            });
        else if (row == 2)
            setFields({
                ...fields,
                ["light_enrolledOn"]: invertEnrollValue(fields.light_enrolledOn),
            });
    };
    const invertEnrollValue = (value) => {
        if (value == "" || value == null)
            return global._DateTimeFieldDB(new Date()).toString();
        return "";
    };

    const CompSelectbox = ({ programType, label, name, value }) => {
        return (
            <FormControl fullWidth className={baseClasses.optionsContract}>
                <InputLabel id="demo-controlled-open-select-label-2">
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-controlled-open-select-label-2"
                    id="demo-controlled-open-select"
                    value={value}
                    name={name}
                    onChange={(event) => {
                        setIsSaveEnable(1);
                        setFieldsLight({ ...fieldsLight, [name]: event.target.value });
                    }}
                    className={baseClasses.selection}
                    style={{ marginLeft: 0 }}
                >
                    {name == "cans" &&
                        ["High", "Med", "Low"].map((column) => (
                            <MenuItem value={column} key={column}>
                                {column}
                            </MenuItem>
                        ))}
                    {name == "paymentMethod" &&
                        ["Cash", "Credit Card"].map((column) => (
                            <MenuItem value={column} key={column}>
                                {column}
                            </MenuItem>
                        ))}

                </Select>
            </FormControl>
        );
    };
    const CompInputSingle = ({ programType, label, name, value }) => {
        return (
            <FormControl fullWidth>
                <TextSingleWrapper
                    label={label}
                    value={value ? value : ""}
                    name={name}
                    onChange={(event) => {
                        if (value != event.target.value) {
                            if (name == "expiration") {
                                var numbers = event.target.value.split("/");
                                var mm = 0, yy = 0;
                                if (!global.isEmpty(numbers[0]))
                                    mm = parseInt(numbers[0]);
                                if (!global.isEmpty(numbers[1]))
                                    yy = parseInt(numbers[1]);
                                setFieldsLight({ ...fieldsLight, ['expMonth']: mm, ['expYear']: yy });
                                setIsSaveEnable(1);
                            }
                            else {
                                setIsSaveEnable(1);
                                setFieldsLight({ ...fieldsLight, [name]: event.target.value });
                            }
                        }
                    }}
                ></TextSingleWrapper>
            </FormControl>
        );
    };

    return (
        <>
            {isLoadingLocal ? (
                <div></div>
            ) : (
                <div
                    className={clsx(baseClasses.overview_main, classes.content)}>
                    <div className={baseClasses.grow}>
                        <ProtectedComponent permissions={permissions.programs}>
                            <GridListCheckmark></GridListCheckmark>
                        </ProtectedComponent>
                    </div>
                    <div className={classes.row} style={{ padding: 0, margin: 0 }}>
                        <div className={baseClasses.grow} style={{ padding: 0, margin: 0 }}></div>
                        {IsEnrolledInProgram() && (
                            <div className={baseClasses.button_program}>
                                <Button
                                    onClick={onSave}
                                    className={baseClasses.capitalized}
                                    color="primary"
                                    variant="outlined"
                                    disabled={!global.isEmpty(isSaveEnable) ? false : true}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                    <ProtectedComponent permissions={permissions.programComfortHealth}>
                        {!global.isEmpty(fields.comfortHealth_enrolledOn) && (
                            <div
                                className={baseClasses.row}
                                style={{ paddingTop: 9, marginTop: 4 }}
                            >
                                <div className="w-100">
                                    <Card className={classes.root}>
                                        <CardHeader
                                            color="primary"
                                            className={classes.cardHeader}
                                            //   title="Shrimp and Chorizo Paella"
                                            subheader={
                                                <>
                                                    <span>Comfort Health</span>
                                                    {
                                                        programStatus.comfortHealth == 'Inactive' ?
                                                            <span style={{ color: "#D32F2F" }}>  [Inactive]</span> : null}
                                                </>
                                            }
                                        />
                                        <CardContent
                                            className={clsx(baseClasses.row, "row", classes.cardSpaceStyle)}
                                        >
                                            <ProtectedComponent className={classes.columnFirst} allow canEdit={programChPermission.actions.editApprovalInfo}>
                                                <FormControl
                                                    className={clsx(
                                                        baseClasses.marginSpaceOne,
                                                        baseClasses.fullWidth
                                                    )}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        className={classes.columnHeder}
                                                    >
                                                        Services
                                                        <br />
                                                    </Typography>
                                                </FormControl>
                                                <ServiceDetails
                                                    key={fields.comfortHealth_comhab + "comhab"}
                                                    label="Com Hab : "
                                                    name="comfortHealth_comhab"
                                                    fieldsProgramDetail={fields}
                                                    setFieldsProgramDetail={setFields}
                                                    setIsSaveEnable={setIsSaveEnable}
                                                    classes={classes}
                                                />
                                                <ServiceDetails
                                                    key={fields.comfortHealth_respite + "respite"}
                                                    label="Repite : "
                                                    name="comfortHealth_respite"
                                                    fieldsProgramDetail={fields}
                                                    setFieldsProgramDetail={setFields}
                                                    setIsSaveEnable={setIsSaveEnable}
                                                    classes={classes}
                                                />
                                                <ServiceDetails
                                                    key={fields.comfortHealth_cfss + "cfss"}
                                                    label="CFSS : "
                                                    name="comfortHealth_cfss"
                                                    fieldsProgramDetail={fields}
                                                    setFieldsProgramDetail={setFields}
                                                    setIsSaveEnable={setIsSaveEnable}
                                                    classes={classes}
                                                />
                                                <ServiceDetails
                                                    key={fields.comfortHealth_preVoc + "preVoc"}
                                                    label="Pre-Voc : "
                                                    name="comfortHealth_preVoc"
                                                    fieldsProgramDetail={fields}
                                                    setFieldsProgramDetail={setFields}
                                                    setIsSaveEnable={setIsSaveEnable}
                                                    classes={classes}
                                                />
                                                <ServiceDetails
                                                    key={fields.comfortHealth_cats + "cats"}
                                                    label="CATS : "
                                                    name="comfortHealth_cats"
                                                    fieldsProgramDetail={fields}
                                                    setFieldsProgramDetail={setFields}
                                                    setIsSaveEnable={setIsSaveEnable}
                                                    classes={classes}
                                                />
                                                <Grid container xs={12}>
                                                    <br />
                                                    <TextField
                                                        variant={"outlined"}
                                                        style={{ marginLeft: "18px", marginRight: "15px" }}
                                                        label="Time Requirements"
                                                        name={"comfortHealthTimeRequirements"}
                                                        value={fields.comfortHealthTimeRequirements}
                                                        onChange={(event) => {
                                                            setFields({
                                                                ...fields,
                                                                comfortHealthTimeRequirements: event.target.value
                                                            })
                                                            setIsSaveEnable(true)
                                                        }}
                                                        fullWidth
                                                        multiline
                                                    ></TextField>
                                                </Grid>
                                                {/* <FormControl
                                                    className={clsx(
                                                        classes.marginSpaceOne,
                                                        classes.fullWidth
                                                    )}
                                                >
                                                    <FormControlLabel
                                                        //   value="Active"L
                                                        className={classes.columnFirstWidget}
                                                        control={
                                                            <TextField
                                                                label=""
                                                                id="schedule-repeat-cycle1"
                                                                type="number"
                                                                onChange={handleFields}
                                                                name="comfortHealth_comhab"
                                                                value={fields.comfortHealth_comhab}
                                                                //   className={clsx(classes.marginSpaceOne)}
                                                                onInput={(e) => {
                                                                    e.target.value = Math.max(
                                                                        0,
                                                                        parseInt(e.target.value)
                                                                    )
                                                                        .toString()
                                                                        .slice(0, 3);
                                                                }}
                                                                className={classes.hoursWidget}
                                                                InputProps={{
                                                                    maxLength: 2,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            &nbsp;Hours
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        }
                                                        label="Com Hab : "
                                                        labelPlacement="start"
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    className={clsx(
                                                        classes.marginSpaceOne,
                                                        classes.fullWidth
                                                    )}
                                                >
                                                    <FormControlLabel
                                                        value="Active"
                                                        className={classes.columnFirstWidget}
                                                        control={
                                                            <TextField
                                                                label=""
                                                                type="number"
                                                                onChange={handleFields}
                                                                name="comfortHealth_respite"
                                                                value={fields.comfortHealth_respite}
                                                                className={clsx(classes.marginSpaceOne)}
                                                                onInput={(e) => {
                                                                    e.target.value = Math.max(
                                                                        0,
                                                                        parseInt(e.target.value)
                                                                    )
                                                                        .toString()
                                                                        .slice(0, 3);
                                                                }}
                                                                className={classes.hoursWidget}
                                                                InputProps={{
                                                                    maxLength: 2,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            &nbsp;Hours
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        }
                                                        label="Repite : "
                                                        labelPlacement="start"
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    className={clsx(
                                                        classes.marginSpaceOne,
                                                        classes.fullWidth
                                                    )}
                                                >
                                                    <FormControlLabel
                                                        value="Active"
                                                        className={classes.columnFirstWidget}
                                                        control={
                                                            <TextField
                                                                label=""
                                                                type="number"
                                                                onChange={handleFields}
                                                                name="comfortHealth_cfss"
                                                                value={fields.comfortHealth_cfss}
                                                                className={clsx(classes.marginSpaceOne)}
                                                                onInput={(e) => {
                                                                    e.target.value = Math.max(
                                                                        0,
                                                                        parseInt(e.target.value)
                                                                    )
                                                                        .toString()
                                                                        .slice(0, 3);
                                                                }}
                                                                className={classes.hoursWidget}
                                                                InputProps={{
                                                                    maxLength: 2,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            &nbsp;Hours
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        }
                                                        label="CFSS : "
                                                        labelPlacement="start"
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    className={clsx(
                                                        classes.marginSpaceOne,
                                                        classes.fullWidth
                                                    )}
                                                >
                                                    <FormControlLabel
                                                        value="Active"
                                                        className={classes.columnFirstWidget}
                                                        control={
                                                            <TextField
                                                                label=""
                                                                type="number"
                                                                onChange={handleFields}
                                                                name="comfortHealth_cats"
                                                                value={fields.comfortHealth_cats}
                                                                className={clsx(classes.marginSpaceOne)}
                                                                onInput={(e) => {
                                                                    e.target.value = Math.max(
                                                                        0,
                                                                        parseInt(e.target.value)
                                                                    )
                                                                        .toString()
                                                                        .slice(0, 3);
                                                                }}
                                                                className={classes.hoursWidget}
                                                                InputProps={{
                                                                    maxLength: 2,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            &nbsp;Hours
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        }
                                                        label="CATS : "
                                                        labelPlacement="start"
                                                    />
                                                </FormControl> */}
                                            </ProtectedComponent>
                                            <div style={{ width: "25%" }}>
                                                <FormControl
                                                    className={clsx(
                                                        baseClasses.marginSpaceOne,
                                                        baseClasses.fullWidth
                                                    )}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        className={classes.columnHeder}
                                                        style={{ paddingLeft: 0 }}
                                                    >
                                                        Care Management
                                                        <br />
                                                    </Typography>
                                                </FormControl>
                                                <ProtectedComponent allow canEdit={programChPermission.actions.editCareManager}>
                                                    <ContactSelectField
                                                        userRole={"CareManager"}
                                                        label={"Care Manager"}
                                                        editData={fields.careManagerName}
                                                        onChange={(contact) => {
                                                            props.programChange();
                                                            setContactSelectField(contact);
                                                            if (contact) {
                                                                if (fields.careManagerName != contact.name)
                                                                    setIsSaveEnable(1);

                                                                setFields({
                                                                    ...fields,
                                                                    ["careManagerId"]: contact.id,
                                                                    ["careManagerName"]: contact.name,
                                                                });
                                                            } else {
                                                                if (fields.careManagerName != "")
                                                                    setIsSaveEnable(1);
                                                                setFields({
                                                                    ...fields,
                                                                    ["careManagerId"]: null,
                                                                    ["careManagerName"]: "",
                                                                });
                                                            }
                                                        }}
                                                    ></ContactSelectField>
                                                </ProtectedComponent>
                                                <ProtectedComponent allow canEdit={programChPermission.actions.editCareManagerSupervisor}>
                                                    <ContactSelectField
                                                        userRole={"CareManagerSupervisor"}
                                                        label={"Care Manager Supervisor"}
                                                        editData={fields.careManagerSupervisorName}
                                                        onChange={(contact) => {
                                                            props.programChange();
                                                            setContactSelectField2(contact);
                                                            if (contact) {
                                                                if (
                                                                    fields.careManagerSupervisorName != contact.name
                                                                )
                                                                    setIsSaveEnable(1);

                                                                setFields({
                                                                    ...fields,
                                                                    ["careManagerSupervisorId"]: contact.id,
                                                                    ["careManagerSupervisorName"]: contact.name,
                                                                });
                                                            } else {
                                                                if (fields.careManagerSupervisorName != "")
                                                                    setIsSaveEnable(1);
                                                                setFields({
                                                                    ...fields,
                                                                    ["careManagerSupervisorId"]: null,
                                                                    ["careManagerSupervisorName"]: "",
                                                                });
                                                            }
                                                        }}
                                                    ></ContactSelectField>
                                                </ProtectedComponent>
                                                <ProtectedComponent allow canEdit={programChPermission.actions.editHcbsCoordinator}>
                                                    <ContactSelectField
                                                        userRole={"HCBSCoordinator"}
                                                        label={"HCBS Coordinator"}
                                                        editData={fields.hcbsCoordinatorName}
                                                        onChange={(contact) => {
                                                            props.programChange();
                                                            setContactSelectField3(contact);
                                                            if (contact) {
                                                                if (
                                                                    fields.hcbsCoordinatorName != contact.name
                                                                )
                                                                    setIsSaveEnable(1);

                                                                setFields({
                                                                    ...fields,
                                                                    ["hcbsCoordinatorId"]: contact.id,
                                                                    ["hcbsCoordinatorName"]: contact.name,
                                                                });
                                                            } else {
                                                                if (fields.hcbsCoordinatorName != "")
                                                                    setIsSaveEnable(1);
                                                                setFields({
                                                                    ...fields,
                                                                    ["hcbsCoordinatorId"]: null,
                                                                    ["hcbsCoordinatorName"]: "",
                                                                });
                                                            }
                                                        }}
                                                    ></ContactSelectField>
                                                </ProtectedComponent>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", textAlign: "right" }}>
                                                <div>
                                                    <CompFormStatus
                                                        status={fields.comfortHealthClientId}
                                                        label={"Comfort Health ID"}
                                                    ></CompFormStatus>
                                                </div>
                                                <div>
                                                    <CompFormStatus
                                                        status={programStatus.comfortHealth}
                                                        marginTop={34}
                                                        label={"Status"}
                                                    ></CompFormStatus>
                                                </div>
                                                <ProtectedComponent
                                                    allow
                                                    permissions={global.permissions.clients.programs}
                                                >
                                                    <FormControl
                                                        component="fieldset"
                                                        className={clsx(baseClasses.marginSpaceOne)}
                                                    >
                                                        <FormGroup className="mt-4">
                                                            <FormControlLabel
                                                                value="Active"
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        onChange={handleFields}
                                                                        name="comfortHealth_active"
                                                                        checked={fields.comfortHealth_active}
                                                                    />
                                                                }
                                                                label="Active"
                                                                labelPlacement="start"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </ProtectedComponent>
                                            </div>

                                        </CardContent>
                                        <CardActions></CardActions>
                                    </Card>
                                </div>
                            </div>
                        )}
                    </ProtectedComponent>
                    <ProtectedComponent permissions={permissions.programP3}>
                        {!global.isEmpty(fields.p3_enrolledOn) && (
                            <div
                                className={baseClasses.row}
                                style={{ paddingTop: 9, marginTop: 4 }}
                            >
                                <div className="w-100">
                                    <Card className={classes.root}>
                                        <CardHeader
                                            color="primary"
                                            className={classes.cardHeader}
                                            subheader={(
                                                <>
                                                    <span>
                                                        {"P3"}
                                                    </span>
                                                    {
                                                        programStatus.p3 == 'Inactive' ?
                                                            <span style={{ color: "#D32F2F" }}>  [Inactive]</span> : null
                                                    }
                                                </>)
                                            }
                                        >

                                        </CardHeader>

                                        <CardContent
                                            className={clsx(baseClasses.row, "row", classes.cardSpaceStyle)}
                                        >
                                            <div className={classes.columnFirst}>
                                                <FormControl
                                                    component="fieldset"
                                                    className={clsx(baseClasses.marginSpaceOne, "w-100")}
                                                >
                                                    <FormGroup>
                                                        <FormControl
                                                            className={clsx(
                                                                baseClasses.marginSpaceOne,
                                                                baseClasses.fullWidth
                                                            )}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                component="p"
                                                                className={classes.columnHeder}
                                                            >
                                                                Services
                                                                <br />
                                                            </Typography>
                                                        </FormControl>
                                                        <ServiceDetails
                                                            key={fields.p3_p3service}
                                                            label="P3 Service : "
                                                            name="p3_p3service"
                                                            fieldsProgramDetail={fields}
                                                            setFieldsProgramDetail={setFields}
                                                            setIsSaveEnable={setIsSaveEnable}
                                                            classes={classes}
                                                        />
                                                        {/* <FormControl>
                                                            <FormControlLabel
                                                                className={classes.columnFirstWidget}
                                                                value="Active"
                                                                control={
                                                                    <TextField
                                                                        label=""
                                                                        type="number"
                                                                        onChange={handleFields}
                                                                        name="p3_p3service"
                                                                        value={fields.p3_p3service}
                                                                        className={clsx(classes.marginSpaceOne)}
                                                                        onInput={(e) => {
                                                                            e.target.value = Math.max(
                                                                                0,
                                                                                parseInt(e.target.value)
                                                                            )
                                                                                .toString()
                                                                                .slice(0, 3);
                                                                        }}
                                                                        className={classes.hoursWidget}
                                                                        InputProps={{
                                                                            maxLength: 2,
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    &nbsp;Hours
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                }
                                                                label="P3 Service : "
                                                                labelPlacement="start"
                                                            />
                                                        </FormControl> */}
                                                    </FormGroup>
                                                </FormControl>
                                            </div>
                                            <div style={{ width: "25%" }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="my-input">P3 Agency</InputLabel>
                                                    <Input
                                                        type="text"
                                                        value={p3Agency}
                                                        id="my-input"
                                                        onChange={(event) => {
                                                            props.programChange();
                                                            setP3Agency(event.target.value)
                                                            updateSaveEnable(1);
                                                            setIsSaveEnable2(true)
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", textAlign: "right" }}>
                                                <ProtectedComponent
                                                    allow
                                                    permissions={global.permissions.clients.programs}
                                                >
                                                    <FormControl
                                                        component="fieldset"
                                                        className={clsx(baseClasses.marginSpaceOne)}
                                                    >
                                                        <FormGroup className="mt-4">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        onChange={handleFields}
                                                                        name="p3_active"
                                                                        checked={fields.p3_active}
                                                                    />
                                                                }
                                                                label="Active"
                                                                labelPlacement="start"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </ProtectedComponent>
                                                <div>
                                                    <CompFormStatus
                                                        status={programStatus.p3}
                                                        marginTop={34}
                                                        label={"Status"}
                                                    ></CompFormStatus>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions></CardActions>
                                    </Card>
                                </div>
                            </div>
                        )}
                    </ProtectedComponent>
                    <ProtectedComponent permissions={permissions.programLight}>
                        {!global.isEmpty(fields.light_enrolledOn) && (
                            <div
                                className={baseClasses.row}
                                style={{ paddingTop: 9, marginTop: 4 }}
                            >
                                <div className="w-100">
                                    <ProgramLight
                                        savestatusaction={handlerEnableSaveButton}
                                        onFieldChange={() => props.programChange()}
                                    />
                                </div>
                            </div>
                        )}
                    </ProtectedComponent>
                </div>
            )
            }
        </>
    );
}
