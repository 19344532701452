import { createStore, action, thunk, computed } from "easy-peasy";
import cms from "./cms.js";
import clients from "./clients.js";
import employee from "./employee.js";
import professional from "./professionals"
import yeshiva from "./yeshiva";
import list from "./list";
import dashboard from "./dashbord";
import users from "./users";
import dataGridSettings from "./dataGridSettings"
import { contactCMSList } from "../api/clients";


const defaultStore = {
    theme: 'light',
    clients: clients,
    employee: employee,
    yeshiva: yeshiva,
    professional: professional,
    list: list,
    dashboard: dashboard,
    dataGridSettings: dataGridSettings,
    focusedInput: null,
    setFocusedInput: action((state, payload) => {
        state.focusedInput = payload;
    }),
    users: users,
    xPos: "300px",
    setXPos: action((state, payload) => {
        state.xPos = payload;
    }),
    yPos: "300px",
    setYPos: action((state, payload) => {
        state.yPos = payload;
    }),
    isWindowDirty: false,
    setWindowDirty: action((state, isDirty) => {
        state.isWindowDirty = isDirty;
    }),
    changedItems: [],
    setChangedItems: action((state, items) => {
        state.changedItems = items;
    }),
    isLoading: false,
    setLoading: action((state) => {
        state.isLoading = true;
    }),
    endLoading: action((state) => {
        state.isLoading = false;
    }),
    previousPath: "",
    setPreviousPath: action((state, payload) => {
        state.previousPath = payload;
    }),
    history: null,
    setHistory: action((state, payload) => {
        state.history = payload;
    }),
    exploringType: "clients",
    setExploringType: action((state, payload) => {
        state.exploringType = payload;
    }),
    exploringId: 0,
    setExploringId: action((state, payload) => {
        state.exploringId = payload;
    }),
    exploringMode: "detail",
    setExploringMode: action((state, payload) => {
        state.exploringMode = payload;
    }),
    exploringContactId: 0,
    setExploringContactId: action((state, payload) => {
        state.exploringContactId = payload;
    }),
    exploringName: 0,
    setExploringName: action((state, payload) => {
        state.exploringName = payload;
    }),
    contacts: [],
    contactsLoad: thunk((actions, payload) => {
        return new Promise((resolve, rejects) => {
            contactCMSList().then((response) => {
                var contactList = global.getNewList(response);
                actions.contactsSet([...contactList]);
                resolve(contactList);
            });
        });
    }),
    contactsSet: action((state, payload) => {
        state.contacts = payload;
    }),
    contactGetByName: action((state, payload) => {
        return 3000;
    }),

    // ui variable
    isSidebarOpened: true,
    setSidebarOpened: action((state, payload) => {
        state.isSidebarOpened = payload;
    }),
    confirm: {
        open: false,
        warnings: [],
        description: '',
        item: '',
        id: -1,
        action: ''
    },
    showConfirm: action((state, payload) => {
        state.confirm = {
            open: payload.open,
            warnings: payload.warnings,
            description: payload.description,
            item: payload.item,
            id: payload.id,
            action:payload.action
        }
    }),
    hideConfirm: action((state, payload) => {
        state.confirm = {
            open: false,
            warnings: [],
            description: '',
            item: '',
            id: -1,
            action: ''
        }
    })
};
export default defaultStore;
/*
// numberOfTodos: computed((state) => state.items.length),
    // saveTodo: thunk(async (actions, payload) => {
    //   // const { data } = await axios.post(url, payload);
    //   // actions.addTodo(data);
    // }),
    // add: action((state, payload) => {
    //   state.items.push(payload);
    // }),
    */
