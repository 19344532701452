import { makeStyles } from '@mui/styles';// '@mui/styles';   //
import { darken, lighten, styled } from '@mui/material/styles';

export const TitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px'
});
export const Title = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
export const ActionsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


export default makeStyles((theme) => ({
    root: {
    },
    container: {
        display: 'flex',
        textAlign: "center",
        // border: "0px",
        width: "100%",
        '& .hidden-header': {
            opacity: 0
          },
        '& .super-app-theme--Open': {
            background: (theme) =>
                "#90ee9063",//getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                background: theme.palette.background.open
            },
        },
        '& .super-app-theme--Filled': {
            background: (theme) => "#90ee9063",
            '&:hover': {
                background: theme.palette.background.filled
            }
        },
        '& .super-app-theme--PartiallyFilled': {
            bgcolor: (theme) =>
                "#90ee9063",//getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
            '&:hover': {
                background: theme.palette.background.partiallyFilled
            },
        },
        '& .super-app-theme--Rejected': {
            background: (theme) =>
                "#90ee9063",//getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            '&:hover': {
                background: theme.palette.background.rejected
            },
        },
    },
    table: {
        background: "#FFF",
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount': {
            visibility: 'hidden',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
            background: theme.palette.primary.main,
            color: "#FFF",
            padding: 0
        },
        '&.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered': {
            background: "red",
        },
        '&.MuiDataGrid-root .MuiDataGrid-row:hovered': {
            background: "red", //theme.palette.background.paper,
        },
        '&.icon-no-padding': {
            padding: '0px !important'
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none !important'
        }
    },
    'icon-no-padding': {
        padding: '0px !important'
    },
    overflowText: {
        textAlign: "left",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    noHeaderDivider: {
        '& > .MuiDataGrid-columnSeparator': {
            visibility: 'hidden !important',
        },
        "&.MuiDataGrid-columnSeparator--sideRight": {
            display: "none !important"
          }
    },
    quickFilterSelect: {
        border: '1px solid #1976d2',
        height: '25px',
        borderRadius: '5px',
        fontSize: '12px',
        marginRight: '2%',
        alignContent: 'center'
    },
    tableCardHeader: {
        '& .MuiDataGrid-columnHeaders': {
            background: '#e2eefa',
            color: "#000",
            padding: 0
        },
    }, 
    info: {
        display: 'flex',
    },
    hideToolbarText: {
        fontSize: '0px !important',
        minWidth: 'unset !important'
    },
    toolbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        margin: "12px 26px",
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        /*'& .MuiPaper-outlined': {
                        border: '0px',
                }*/
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
    },
    multiViewSelect: {
        '& .MuiSelect-select': {
            padding: '0px 15px',
            fontSize: '13px',
            color: theme.palette.primary.main,
            borderRadius: 13
        },
        '& .MuiSelect-outlined': {
            background: 'rgb(241, 247, 253) !important'
        },
        '& .MuiSelect-iconOutlined': {
            color: theme.palette.primary.main
        },
        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiPaper-root': {
            minWidth: '250px !important'
        }
    },
    mutilViewMenuItem: {
        width: 225,
        '& .css-10hburv-MuiTypography-root': {
            fontSize: '13px !important'
        },
        '& .MuiListItemIcon-root': {
            minWidth: '10px !important'
        }
    }
   
}), { index: 1 });
