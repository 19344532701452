import { alpha } from "@mui/material/styles";

const  cards = theme => ({
    card_view: {
        /*overflow: 'scroll',*/
        padding: "0px",
      },
    topCardTitles: {
        fontWeight: "bold"
      },
    card_title: {
        margin: "4px",
        fontSize: "13px",
    },
    card_icon: {
      flex: 1,
      cursor: "pointer",
      padding: 2,
    },  
    contentCard: {
      padding: "8px",
    },
    overview: {
        marginRight: "12px",
    },
    overview_main: {
      padding: '8px',
    },
    contentOverview: {
        width: "69%",
        padding: "12px",
    },
    contentOverview2: {
      width: "100%",
      paddingTop: "4px",
      paddingBottom: "12px",
    },
    // Document styles
    document_card_content: {
        padding: '0px',
    },
    document_btn_row: {
        display: 'flex',
        background: theme.palette.primary.main,
        marginBottom: "8px",
        flexDirectiontabRoot: 'row',
    },
})

export default cards