import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import {
  SectionComponent,
  NamesToStringComp,
} from "../../components/edit_explorer/employee/components";
import useStyles from "./HomeStyles";
import _ from "lodash";
import clsx from "clsx";
import baseStyles from '../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";


const PersonalInformationView = (props) => {
  const classes = useStyles();
  const baseClasses = baseStyles();
  const [employeeDetail, setEmployeeDetail] = useState(props.employee);
  const setSectionChanged1 = useStoreActions(
    (actions) => actions.employee.setSectionChanged1
  );
  const firstNameError = useStoreState(
    (state) => state.employee.firstNameError
  );
  const secondNameError = useStoreState(
    (state) => state.employee.secondNameError
  );
  const [selectedNeighborhood, setSelectedNeighborhood] = React.useState("");

  const neighborhoodValues = useStoreState(
    (state) => state.list.values.neighborhoods
  );
  const neighborhoodsIsFetched = useStoreState(
    (state) => state.list.values.neighborhoodsIsFetched
  );
  const fetchNeighborhoods = useStoreActions(
    (actions) => actions.list.values.fetchNeighborhoods
  );
  const handleNeighborhoodChange = (event) => {
    setSelectedNeighborhood(event.target.value);
    setEmployeeDetail2({
      ...employeeDetail,
      contact: { ...employeeDetail.contact, neighborhood: event.target.value },
    });
    setSectionChanged1(true);
  };
  const exploringType = useStoreState((state) => state.exploringType);
  const setExploringType = useStoreActions((actions) => actions.setExploringType);

  useEffect(() => {
    if (!neighborhoodsIsFetched) {
      fetchNeighborhoods();
    }
  }, []);

  useEffect(() => { setExploringType("professional") }, [])

  useEffect(() => {
    setEmployeeDetail(props.employee);
    setSelectedNeighborhood(props.employee.contact.neighborhood);
    setSectionChanged1(false);
  }, [props.employee]);

  useEffect(() => {
    setSectionChanged1(false);
  }, []);

  let sectionState2 = props.sectionState;
  const exploringId = useStoreState((state) => state.exploringId);

  const setEmployeeDetail2 = (detail) => {
    setEmployeeDetail(detail);
    setSectionChanged1(true);
  };

  const CompTextFieldContact = ({ xs, label, name }) => {
    const [value, setValue] = React.useState(employeeDetail.contact[name]);
    useEffect(() => {
      setValue(employeeDetail.contact[name]);
    }, [employeeDetail]);

    return (
      <Grid item xs={xs}>
        <div>
          <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Input
              type={name === "age" ? "number" : "text"}
              value={global.isEmpty(value) ? "" : value}
              // value={employeeDetail.contact[name]}
              onChange={(event) => setValue(event.target.value)}
              onBlur={(event) => {
                global.log(event.target.value);
                setEmployeeDetail2({
                  ...employeeDetail,
                  ["contact"]: {
                    ...employeeDetail.contact,
                    [name]: event.target.value,
                  },
                });
                setSectionChanged1(true);
              }}
              aria-describedby={"my-helper-text" + name}
            />
            {name !== "firstName" || global.isEmpty(firstNameError) ? null : (
              <FormHelperText
                className={baseClasses.error}
                id={"my-helper-text" + name}
              >
                {firstNameError}
              </FormHelperText>
            )}

            {name !== "lastName" || global.isEmpty(secondNameError) ? null : (
              <FormHelperText
                className={baseClasses.error}
                id={"my-helper-text" + name}
              >
                {secondNameError}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </Grid>
    );
  };

  return (
    <SectionComponent
      headerTitle="Personal"
      onSave={() => { }}
      onBackUrl={props.onBackUrl}
      employeeDetail={employeeDetail}
      state={sectionState2}
      onStateChange={props.setSectionState}
      readDOM={
        <Grid
          container
          className={baseClasses.grid}
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} className={baseClasses.fieldLine}>
            <div className={baseClasses.fieldtitle}>Name: </div>
            <NamesToStringComp
              nameList={["firstName", "lastName"]}
              employeeDetail={employeeDetail}
            />
          </Grid>

          <Grid item xs={12} className={baseClasses.fieldLine}>
            <div className={baseClasses.fieldtitle}>Hebrew Name:</div>
            <NamesToStringComp
              nameList={["firstNameHebrew", "lastNameHebrew"]}
              employeeDetail={employeeDetail}
            />
          </Grid>

          <Grid item xs={12} className={baseClasses.fieldLine}>
            <div className={baseClasses.fieldtitle}>Legal Name:</div>
            <NamesToStringComp
              nameList={["firstNameLegal", "middleNameLegal", "lastNameHebrew"]}
              employeeDetail={employeeDetail}
            />
          </Grid>

          <Grid item xs={12} className={baseClasses.fieldLine}>
            <div className={baseClasses.fieldtitle}>Address:</div>
            <NamesToStringComp
              nameList={["address1", "address2", "city", "state", "zip"]}
              employeeDetail={employeeDetail}
            />
          </Grid>
          <Grid item xs={12} className={baseClasses.fieldLine}>
            <div className={baseClasses.fieldtitle}>Neighborhood: </div>
            <NamesToStringComp
              nameList={["neighborhood"]}
              employeeDetail={employeeDetail}
            />
          </Grid>

          <Grid item xs={12} className={baseClasses.fieldLine}>
            <div className={baseClasses.fieldtitle}> Cell Number: </div>
            <NamesToStringComp
              nameList={["phone1"]}
              employeeDetail={employeeDetail}
            />
          </Grid>

          <Grid item xs={12} className={baseClasses.fieldLine}>
            <div className={baseClasses.fieldtitle}> Home Number: </div>
            <NamesToStringComp
              nameList={["phone2"]}
              employeeDetail={employeeDetail}
            />
          </Grid>

          {employeeDetail.positionId === props.yungermanId && (
            <Grid item xs={12} className={baseClasses.fieldLine}>
              <div className={baseClasses.fieldtitle}>Age: </div>
              <NamesToStringComp
                nameList={["age"]}
                employeeDetail={employeeDetail}
              />
            </Grid>
          )}

          {employeeDetail.positionId === props.yungermanId && (
            <Grid item xs={12} className={baseClasses.fieldLine}>
              <div className={baseClasses.fieldtitle}>
                Year Experience with Bucherim:{" "}
              </div>
              <div className={baseClasses.fieldvalue}>
                {global.isEmpty(employeeDetail["yearsOfExperienceWithBucherim"])
                  ? "-"
                  : employeeDetail["yearsOfExperienceWithBucherim"]}
              </div>
            </Grid>
          )}

          {employeeDetail.positionId === props.yungermanId && (
            <Grid item xs={12} className={baseClasses.fieldLine}>
              <div className={baseClasses.fieldtitle}>Rate : </div>
              <div className={baseClasses.fieldvalue}>
                {global.isEmpty(employeeDetail["rate"])
                  ? "0"
                  : employeeDetail["rate"]}
              </div>
            </Grid>
          )}

          {employeeDetail.positionId === props.yungermanId && (
            <Grid item xs={12} className={baseClasses.fieldLine}>
              <div className={baseClasses.fieldtitle}>Rate Note : </div>
              <div className={baseClasses.fieldvalue}>
                {global.isEmpty(employeeDetail["rateNote"])
                  ? "-"
                  : employeeDetail["rateNote"]}
              </div>
            </Grid>
          )}
        </Grid>
      }
      editDOM={
        <Grid
          container
          className={clsx(baseClasses.grid)}
          alignItems="flex-start"
          spacing={2}
        >
          {CompTextFieldContact({
            xs: 6,
            label: "First Name",
            name: "firstName",
          })}
          {CompTextFieldContact({
            xs: 6,
            label: "Last Name",
            name: "lastName",
          })}
          {CompTextFieldContact({
            xs: 6,
            label: "Hebrew First Name",
            name: "firstNameHebrew",
          })}
          {CompTextFieldContact({
            xs: 6,
            label: "Hebrew Last Name",
            name: "lastNameHebrew",
          })}
          {CompTextFieldContact({
            xs: 4,
            label: "Legal First Name",
            name: "firstNameLegal",
          })}
          {CompTextFieldContact({
            xs: 4,
            label: "Legal Middle Name",
            name: "middleNameLegal",
          })}
          {CompTextFieldContact({
            xs: 4,
            label: "Legal Last Name",
            name: "lastNameLegal",
          })}
          {CompTextFieldContact({ xs: 4, label: "Address", name: "address1" })}
          {CompTextFieldContact({ xs: 4, label: "Apt", name: "address2" })}
          <Grid item xs={4}>
            <FormControl fullWidth className={baseClasses.optionsContract}>
              <InputLabel htmlFor="neighborhood-select" id="neighborhood-label">
                Neighborhood
              </InputLabel>
              <Select
                labelId="neighborhood-label"
                id="neighborhood-select"
                value={selectedNeighborhood}
                className={baseClasses.selection}
                onChange={handleNeighborhoodChange}
              >
                {neighborhoodValues.map((neighborhood, index) => (
                  <MenuItem key={index} value={neighborhoodValues[index]}>
                    {neighborhood}
                  </MenuItem>
                ))}
              </Select>
              {<FormHelperText id="my-helper-text"></FormHelperText>}
            </FormControl>
          </Grid>
          {CompTextFieldContact({ xs: 4, label: "City", name: "city" })}
          {CompTextFieldContact({ xs: 4, label: "State", name: "state" })}
          {CompTextFieldContact({ xs: 4, label: "Zip", name: "zip" })}
          {CompTextFieldContact({
            xs: 6,
            label: "Cell Number",
            name: "phone1",
          })}
          {CompTextFieldContact({
            xs: 6,
            label: "Home Number",
            name: "phone2",
          })}
        </Grid>
      }
    ></SectionComponent>
  );
};

export default PersonalInformationView;
