import React from "react";
import { useEffect, useState } from "react";

import { Card } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import { makeStyles } from "@mui/styles";
import * as constant from "../../constants";
import _ from "lodash";
import PersonalInformationView from "./PersonalInformationView";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "70%",
    // marginTop: "12px",
    marginLeft: "15%",
    // backgroundColor: theme.palette.background.paper,
    "& .MuiInputLabel-animated": {
      fontSize: "14px",
    },
  },
}));
const ManageProfessionalView = (props) => {
  const classes = useStyles();
  const [positionValues, setPositionValues] = useState([{ id: 0, name: "" }]);
  const [specialtyValues, setSpecialtyValues] = useState([]);
  const [paymentValues, setPaymentValues] = useState([]);
  const [professionalTypes, setProfessionalTypes] = useState([]);
  const { onBackUrl } = props;

  const exploringId = useStoreState((state) => state.exploringId);

  const [yungermanId, setYungermanId] = useState();
  const [professionalId, setProfessionalId] = useState();
  const employeeDetail = useStoreState((state) => state.professional.detail);
  const setEmployeeDetail = useStoreActions(
    (actions) => actions.professional.setDetail
  );
  const [contact, setContact] = useState();

  const sectionState2 = useStoreState((state) => state.professional.sectionState2);

  const setSectionState2 = useStoreActions(
    (actions) => actions.professional.setSectionState2
  );

  useEffect(() => {
    setContact(employeeDetail.contact)
  }, [employeeDetail]);

  useEffect(() => {
    resetAllData();
    global.fetchWrapper
      .get(constant.BASE_URL + "api/hr/employeePositionValues")
      .then((data) => setPositionValues(data));
    global
      .getSystemListValuesListByName("Employee_Specialties")
      .then((data) => setSpecialtyValues(data));
    global
      .getSystemListValuesListByName("Professional_Types")
      .then((data) => setProfessionalTypes(data));
    global
      .getSystemListValuesListByName("Payment_Options")
      .then((data) => setPaymentValues(data));
    //global.fetchWrapper.get(constant.BASE_URL + 'api/hr/').then(data => setSpecialtyValues(data))
    // setPaymentValues;
    setSectionState2("read");
  }, []);

  const resetAllData = () => { };

  return (
    <div>
      <Card className={classes.root}>
        <PersonalInformationView
          sectionState={sectionState2}
          positionValues={positionValues}
          employee={employeeDetail}
          yungermanId={yungermanId}
          professionalId={professionalId}
          setSectionState={setSectionState2}
          specialtyValues={specialtyValues}
          paymentValues={paymentValues}
          professionalTypes={professionalTypes}
          onBackUrl={onBackUrl}
        />
      </Card>
    </div>
  );
};

export default ManageProfessionalView;
