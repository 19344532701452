import moment from "moment";
import { toast } from "react-toastify";
import windowsNotify from "./Notification";
import { accountService } from '../_services/account.service';

global.AjaxWarnToast = (msg) => {
    msg = msg ? msg : "Issue reported!";
    toast.warning(msg, {
        // Set to 15sec
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 1000,
    });
};

global.AjaxFailToast = (msg) => {
    msg = msg ? msg : "An error has occured!";
    toast.error(msg, {
        // Set to 15sec
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
    });
};

global.AjaxFailToast1 = (msg) => {
    msg = msg ? msg : "An error occurred while saving the information";
    toast.error(msg, {
        // Set to 15sec
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
    });
};

global.AjaxSaved1 = (msg) => {
    msg = msg ? msg : "Successfully saved!";
    toast.success(msg, {
        // Set to 15sec
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
    });
};

global.AjaxSaved = (msg) => {
    msg = msg ? msg : "Saved!";
    toast.success(msg, {
        // Set to 15sec
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
    });
};

export const isValidEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    global.log("em======", email, re.test(email));
    if (re.test(email)) {
        return true;
    }
    return false;
};

global._DateField = (datetime) => {
    if (datetime == null || datetime == "") return "-";
    return moment(datetime).format("MM/DD/YYYY");
};

global._TimeField = (datetime) => {
    return moment(datetime).format("hh:mm a");
};

global._DateTimeField = (datetime) => {
    return moment(datetime).format("MM/DD/YYYY hh:mm a");
};
global._DateFormat = (datetime) => {
    var formats = {
        sameDay: "hh:mm a",
        nextDay: "MMM DD",
        nextWeek: "MMM DD",
        lastDay: "[Yesterday]",
        lastWeek: "MMM DD",
        sameElse: "MMM DD",
    };

    var output = moment().calendar(datetime, formats);
    return output;
};

export const _calculateAge = (birthday) => {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};
global._calculateAge = _calculateAge;

global.getWeekday = (number) => {
    const weekNames = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    return weekNames[number];
};

global.isEmpty = (data) => {
    if (data == undefined) return true;
    if (data == null) return true;
    if (data == "") return true;
    if (data == 0) return true;

    return false;
};

global.toString = (data) => {
    if (global.isEmpty(data)) return "";
    return data;
};

global._DateTimeFieldDB = (datetime) => {
    if (datetime == null) datetime = new Date();
    return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
};
global._DateFieldDB = (datetime) => {
    return moment(datetime).format("YYYY-MM-DD");
};

global.getNewList = (response) => {
    var contactList = [];
    contactList.push({ id: null, name: "Add a Contact", address: "" });

    response.forEach((item) => {
        contactList.push({
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            address: `${item.address1}`,
        });
    });
    return contactList;
};

global.getNewList2 = (response) => {
    var contactList = [];
    contactList.push({ id: null, name: "Add a Contact", address: "" });

    response.forEach((item) => {
        contactList.push({
            id: item.id,
            name: `${item.name}`,
            address: `${item.address}`,
        });
    });
    return contactList;
};

global.getContactName = (contact) => {
    if (contact == null) return "-";
    if (global.isEmpty(contact.firstName) && global.isEmpty(contact.lastName))
        return "NA";
    var hebrewName = contact.firstName + " " + contact.lastName;
    return hebrewName;
};

global.getContactHebrewName = (contact) => {
    if (contact == null) return "-";
    if (global.isEmpty(contact.firstNameHebrew) && global.isEmpty(contact.lastNameHebrew))
        return "NA";
    var hebrewName = contact.firstNameHebrew + " " + contact.lastNameHebrew;
    return hebrewName;
};

global.getContactPhoneNumber = (contact) => {
    if (contact == null) return "-";
    var temp = contact.phone1;
    if (global.isEmpty(temp))
        return "NA";
    return temp;
};

global.roleContain = (roles) => {
    if (!global.account || !global.account.userValue)
        return false;

    var userRoles = global.account.userValue.roles;

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    if (!roles || roles.length === 0) {
        return true;
    }

    return userRoles.some((ur) => { return roles.some((ro) => { return ro != undefined && ro.toLowerCase() === ur.toLowerCase() }) })
};
global.userHasRole = global.roleContain;

global.isAdmin = () => {
    return global.roleContain(["Admin"]);
};

global.notifyWindows = windowsNotify;

global.log = (content1, content2) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') { // environment
        return console.log;
    } else {
        if (["error", "rejects"].includes(content1) || (content1 && content2)) { // is important error or has both paramaters
            return console.log;
        } else { // is in production, no important error, and only single message param
            return () => { };
        }
    }

    return console.log;
}

let secondMs = 1000;
let minuteMs = secondMs * 60;
let hourMs = minuteMs * 60;
const getMsToTime = (time) => {
    let now = new Date();
    let diffMs = time - now; // difference in milliseconds
    return diffMs;
}
global.runLogoutTimer = (ms) => {
    function startTimer() {
        window.clearTimeout(global.logoutTimerId);
        let now = new Date();
        let upcomingLogout = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 2, 0);
        //upcomingLogout = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 1, 0);
        if (now.getHours() >= upcomingLogout.getHours())
            upcomingLogout.setDate(now.getDate() + 1);
        ms = getMsToTime(upcomingLogout);
        global.logoutTimerId = setTimeout(() => accountService.logout('onfocus-redirect'), ms);
    }
    startTimer();
    return new Date();
}
global.stopLogoutTimer = () => {
    clearTimeout(global.logoutTimerId);
}