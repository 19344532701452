import React from 'react';
import { Tooltip, Dialog, DialogContent} from '@mui/material'
import { strings } from '../_helpers/strings'

const TextFormattingWrapper = (props) => {
    const {line, multilineEllipsis, tooltip, showInModal} = props;
    const richTextObj = strings.getRichTextObjFromString(props.value ? props.value: '');
    const [showDialog, setShowDialog] = React.useState(false);
    const style={
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": `${line}`,
        overflow: "hidden"
    }
    return (
        <>
            <Tooltip
                    title={tooltip ? richTextObj.value : ''}
                    onClick={() => {
                        if (showInModal)
                            setShowDialog(true)
                }}>
                    <span
                        dir={richTextObj?.formatting?.textDirection}
                        {...props}
                        style={ multilineEllipsis ? {...style} : {} }
                    >
                        {richTextObj.value}
                    </span>
            </Tooltip>
            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
            >
                <DialogContent>{richTextObj.value}</DialogContent>
            </Dialog>
        </>
    )
}
export default TextFormattingWrapper;