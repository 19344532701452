import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MoreVert from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getContactRelDetail } from "../../api/cms";
import { removeApplicationFromList } from "../../api/applications"
import { getClientDetails, getClientDetailProgram } from "../../api/clients";
import { getEmployeeDetails } from "../../api/employees";
import { getEmployeeApplicantDetails } from "../../api/applications";
import ContactRelSelect from "../ContactSelect/ContactRelSelect";
import { contactColumns } from "../data/clients";
import AddContact from "../explorer/Tabs/Dialog/AddContact";
import useStyles from "./styles";
import baseStyles from '../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";
import Employee from "./employeeApplicantPostCreate";
import Enrollment from "./employeeApplicantEnrollment";
import Summer from "./summer";
import UserInfo from '../../screens/users/UserInfo';
import * as constant from "../../constants";
import useUnsavedChangesWarn from "../../hooks/useUnsavedChangesWarn";
import { BoxContainer, DivContainer, FlexBetween, Title } from "../../assets/styles/styledComponents";
import FeaturedIcon from "../common/FeaturedIcon";
import { accountService } from "../../_services/account.service";
import MainSection from "../ClientSchedule/MainSection/MainSection";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

export default function EditEmployeeApplicantPage(props) {
    const navigate = useNavigate();
    const { applicantId } = useParams();
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [value, setValue] = React.useState("employee");
    // const [value, setValue] = React.useState("programs");
    // const [value, setValue] = React.useState("summerInformation");
    const [view, setView] = React.useState(true);
    const [parent_view, setParentView] = React.useState(true);
    const [education, setEducation] = React.useState(true);
    const [information, setInformation] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [userInfo, setUserInfo] = React.useState(null);
    const [tabvalue, setTabValue] = React.useState("client");
    const [type, setType] = React.useState(0);
    const [userInfoMode, setUserInfoMode] = React.useState('edit');
    const [submenuAnchorEl, setSubmenuAnchorEl] = React.useState(null);
    const [showAddNew, setShowAddNew] = React.useState(false);
    const [relatedContactRows, setRelatedContactRows] = React.useState({
        rows: [{}],
        columns: contactColumns,
        screen: "client_contact",
    });

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const clientSetDetail = useStoreActions((actions) => actions.clients.setDetail);
    const setFields = useStoreActions((actions) => actions.clients.setFields);
    const exploringId = useStoreState((state) => state.exploringId);
    const exploringType = useStoreState((state) => state.exploringType);
    const setExploringType = useStoreActions(
        (actions) => actions.setExploringType
    );
    const previousPath = useStoreState((state) => state.previousPath);
    const fetchEmployees = useStoreActions((actions) => actions.employee.fetchEmployees)
    const employeeDetail = useStoreState((state) => state.employee.detail);
    const applicantDetail = useStoreState((state) => state.em);
    const [clientScheduleData, setClientScheduleData] = React.useState(null);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);
    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );
    const exploringPath = useStoreState((state) => state);

    const [positionValues, setPositionValues] = React.useState([{ id: 0, name: "" }]);
    const [yungermanId, setYungermanId] = React.useState();

    const sectionChanged1 = useStoreState((state) => state.employee.sectionChanged1);
    const sectionState1 = useStoreState((state) => state.employee.sectionState1);
    const sectionState2 = useStoreState((state) => state.employee.sectionState2);
    const sectionState3 = useStoreState((state) => state.employee.sectionState3);
    const sectionState4 = useStoreState((state) => state.employee.sectionState4);
    const sectionState5 = useStoreState((state) => state.employee.sectionState5);
    const location = useLocation();
    const currentPath = location.pathname;

    const setFirstNameError = useStoreActions(
        (actions) => actions.employee.setFirstNameError
    );
    const setSecondNameError = useStoreActions(
        (actions) => actions.employee.setSecondNameError
    );

    const [rollbackAction_Programs, setRollbackAction_Programs] = React.useState(undefined);
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    useEffect(() => {
        global.fetchWrapper.get(constant.BASE_URL + 'api/hr/employeePositionValues').then(data => setPositionValues(data))
        setValue("employee");
    }, []);

    useEffect(() => {
        console.log(employeeDetail)
    }, []);

    useEffect(() => {
        console.log(employeeDetail)
    }, [employeeDetail]);
    useEffect(() => {
        console.log(employeeDetail.contact)
        positionValues.forEach(position => {
            if (position.name === 'Yungerman') {
                setYungermanId(position.id);
            }
        })
    }, [positionValues]);

    const loadApplicantPersonalDetail = async (id) => {
        try {
            const data = await global.fetchWrapper.get(constant.APPLICANT_DETAIL + id); // Use await directly here
            setEmployeeDetail(data.result);
            console.log("here")

            console.log(data)
            endLoading();

            if (data.contact === null) {
                setExploringName("undefined");
            } else {
                setExploringName(data.result.contact.firstName + " " + data.result.contact.lastName);
            }
        } catch (error) {
            endLoading();
            global.AjaxFailToast();
            console.error("Error loading applicant personal detail:", error);
        }
    };

    useEffect(async () => {
        console.log(applicantId)
        if (applicantId) {
            await loadApplicantPersonalDetail(applicantId);
        }
    }, [applicantId]);


    const loadEmployeeApplicantPersonalDetail = async (id) => {
        console.log("here in loadEmployeeApplicantPersonalDetail");
        try {
            const data = await global.fetchWrapper.get(constant.APPLICANT_DETAIL + id);
            endLoading();
            setEmployeeDetail(data);

            if (data.contact === null) {
                setExploringName("undefined");
            } else {
                setExploringName(`${data.contact.firstName} ${data.contact.lastName}`);
            }
        } catch (error) {
            endLoading();
            global.AjaxFailToast();
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        global.log("handleClose");
        setOpen(false);
    };
    const disagree = () => {
        setOpen(false);
    };

    const agree = () => {
        setOpen(false);
        setView(true);
        setParentView(true);
        setEducation(true);
        setInformation(true);
        setValue(tabvalue);
    };
    const handleChange = (event, newValue) => {
        if (newValue == value) {
            event.preventDefault();
            return;
        }
        if (hasChanges()) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                event.preventDefault();
                return;
            }
        }
        setClean();
        if (rollbackAction_Programs)
            rollbackAction_Programs();
        if (type == 1 && (!view || !parent_view || !education || !information)) {
            handleClickOpen();
            setTabValue(newValue);
        } else {
            setValue(newValue);
        }
    };

    const exploringMode = useStoreState((state) => state.exploringMode);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const setExploringId = useStoreActions(
        (actions) => actions.setExploringId
    );
    const history = useStoreState((state) => state.history);

    const handleCloseDrawer = () => {
        if (value !== "employee" ||
            (sectionState1 !== "read" || sectionState2 !== "read" || sectionState3 !== "read" || sectionState4 !== "read" || sectionState5 !== "read")) {
            if (!hasChanges() || wConfirm())
                goBack();
        }
        else
            goBack();
    };

    const goBack = () => {
        setExploringMode("detail");
        navigate(-1);

        // if (previousPath === "FROM EMPLOYEE DETAIL" && exploringId !== -1)
        //     history('/' + 'employee' + '/details/' + exploringId);
        // else {
        //     setExploringMode("detail");
        //     setExploringId(0);
        //     history('/' + 'employees');
        // }
        props.onClick();
    };

    const handleAddRelationshipClick = () => {
        setShowAddNew(true);
    };
    const handleModalClose = () => {
        setShowAddNew(false);
    };

    const handleAddedRelationshipSuccess = () => {
        alert("relatoinship added!");
        setShowAddNew(false);
    };

    const exploringName = useStoreState((state) => state.exploringName);

    const [isYungerman, setIsYungerman] = React.useState();
    const [isProfessional, setIsProfessional] = React.useState(false)
    const [isApplicant, setIsApplicant] = React.useState(false)

    const [dropdownValue, setDropdownValue] = React.useState('mamid');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [employeeType, setEmployeeType] = React.useState('');
    useEffect(() => {
        setIsYungerman(props.exType == "yungerman" || (!global.isEmpty(employeeDetail) && employeeDetail.positionId === yungermanId));
        setIsProfessional(employeeDetail?.isProfessional)

        if (employeeDetail?.isProfessional) {
            getUserInfo(employeeDetail.contactId)
        }
    }, [employeeDetail, employeeDetail.positionId])

    const getUserInfo = (contactId) => {
        accountService.getUserByContactId(contactId).then((res) => {
            if (!!res && res.id > 0) {
                let data = {
                    ...res,
                    contactId: res?.contact?.id,
                    userId: res.id,
                    isActive: res.isActive,
                }
                setUserInfoMode("read")
                setUserInfo(data)
            } else {
                setUserInfo(null)
            }
        })
    }

    const onCloseUserInfo = (reload) => {
        if (reload) {
            getUserInfo(employeeDetail.contactId)
        } else {
            setUserInfoMode('read')
        }
    }


    const handleChangeDropdown = (event, newValue) => {
        setDropdownValue(newValue);
    };

    const handleDeleteApplication = () => {
        removeApplicationFromList(employeeDetail.contact.id, employeeDetail.id)
    };

    const handleCreateEmployee = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleMenuItemClick = (type) => {
        setEmployeeType(type);
        setAnchorEl(null);
        console.log('Employee Type:', type);
        saveEmployeeOrProfessional(type, -1, employeeDetail)
    };


    // Open main menu
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Close main menu
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setSubmenuAnchorEl(null); // Ensure submenu closes too
    };

    // Open submenu for "Create Employee"
    const handleOpenSubmenu = (event) => {
        setSubmenuAnchorEl(event.currentTarget);
        setAnchorEl(null);
    };


    function saveEmployeeOrProfessional(exploringType, exploringId, employeeDetail) {
        console.log(employeeDetail)
        const url = exploringType === "professional" ? constant.CreateProfessionalFromApplicant : constant.CreateMamidFromApplicant;

        return global.fetchWrapper
            .post(url + exploringId, employeeDetail)
            .then(
                (response) => {
                    if (exploringId === -1) {


                        navigate(`../employee/${response.id}/enrollment`, { replace: true });
                    } else {
                        // If editing an existing record, just reload the data
                        // reloadData(response);
                    }

                    if (response.responseCode === 200) {
                        // Mark the form as clean

                    }
                },
                (error) => {
                    endLoading();
                }
            );
    }


    return (
        <div className={classes.content}>
            <div className={baseClasses.toolbar} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleCloseDrawer}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                {exploringName}
            </div>
            <Card variant="outlined">
                <CardContent classes={{ root: baseClasses.card_view }}>
                    <div className={classes.root}>
                        <AppBar className={baseClasses.appbarColor} position="static">
                            <Toolbar className={baseClasses.appbarColor}>
                                <Tabs value={value} onChange={handleChange} aria-label="scrollable auto tabs example">
                                    <Tab
                                        className={baseClasses.tabClassSelected}
                                        value="employee"
                                        label="Applicant"
                                        {...setRowAndTab(0)}
                                    />
                                    {exploringId !== -1 && isYungerman && (
                                        <Tab
                                            className={baseClasses.tabClassSelected}
                                            value="enrollment"
                                            label="Enrollment"
                                            {...setRowAndTab(1)}
                                        />
                                    )}
                                    {exploringId !== -1 && isYungerman && (
                                        <Tab
                                            className={baseClasses.tabClassSelected}
                                            value="schedule"
                                            label="Schedule"
                                            {...setRowAndTab(2)}
                                        />
                                    )}
                                    {exploringId !== -1 && isProfessional && (
                                        <Tab
                                            className={baseClasses.tabClassSelected}
                                            value="user"
                                            label="User"
                                            {...setRowAndTab(3)}
                                        />
                                    )}
                                </Tabs>

                                <IconButton
                                    style={{ marginLeft: 'auto' }}
                                    color="inherit"
                                    onClick={handleOpenMenu}
                                >
                                    <MoreVert />
                                </IconButton>

                                {/* Main Menu */}
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    {/* Delete Application Option */}
                                    <MenuItem
                                        onClick={() => {
                                            handleDeleteApplication();
                                            handleCloseMenu();
                                        }}
                                    >
                                        Delete Application
                                    </MenuItem>

                                    {/* Create Employee Option */}
                                    <MenuItem onClick={handleOpenSubmenu}>
                                        Create Employee
                                    </MenuItem>
                                </Menu>

                                {/* Submenu for Create Employee */}
                                <Menu
                                    anchorEl={submenuAnchorEl}
                                    open={Boolean(submenuAnchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={() => handleMenuItemClick('mamid')}>
                                        Mamid
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick('professional')}>
                                        Professional
                                    </MenuItem>
                                </Menu>

                                {/* Save Button */}
                                {/* {employeeType && (
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        color="inherit"
                                        onClick={handleSaveEmployee} // Calls save function with selected type
                                    >
                                        Save {employeeType.charAt(0).toUpperCase() + employeeType.slice(1)}
                                    </Button> */}
                                {/* )} */}
                            </Toolbar>
                        </AppBar>

                        <div className={classes.panelContent}>
                            <TabPanel
                                value={value}
                                index="employee"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                <Employee
                                    view={view}
                                    onView={setView}
                                    parent_view={parent_view}
                                    onParentView={setParentView}
                                    type={type}
                                    addClientSuccess={props.addClientSuccess}
                                    onBackUrl={handleCloseDrawer}
                                ></Employee>
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="enrollment"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                {exploringId !== -1 &&
                                    < Enrollment
                                        setRollbackAction={setRollbackAction_Programs}
                                    ></Enrollment>
                                }
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="schedule"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                {exploringId !== -1 && employeeDetail && employeeDetail.contactId !== 0 && (
                                    <React.Fragment>
                                        <MainSection
                                            isApplicant={true}
                                            contactId={employeeDetail.contactId}
                                            provider={employeeDetail}
                                            clientScheduleData={clientScheduleData}
                                            // isEmployee={isEmployee} // Uncomment if needed
                                            cards={{
                                                availability: {
                                                    // show: false, 
                                                    title: "Availability",
                                                    // need to fix the add
                                                    // actions: ['add', 'edit', 'delete'],
                                                    actions: ['edit', 'delete'],
                                                    columns: [
                                                        // { field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                                                        // { field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                                                        { field: 'time', headerName: 'Time', width: 120 },
                                                        { field: 'scheduleDisplayName', headerName: 'Schedule', value: 'schedule' },
                                                        { field: 'throughout', headerName: 'Throughout', width: 120 },
                                                        { field: "moreIcon", headerName: 'More Icon', hideHeaderName: true, enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                                                    ],
                                                    actionPermissions: true
                                                },

                                            }}
                                        />
                                    </React.Fragment>
                                )}
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="summerInformation"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                {exploringId !== -1 &&
                                    <Summer></Summer>
                                }
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="user"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                <DivContainer padding="10px" bgColor="#fff">
                                    <FlexBetween border>
                                        <Title>User Info</Title>
                                        {userInfoMode === "read" ? (
                                            <EditIcon
                                                className={baseClasses.edit_icon}
                                                onClick={() => {
                                                    setUserInfoMode('edit')
                                                }}
                                            />
                                        ) : (
                                            <CloseIcon
                                                className={baseClasses.edit_icon}
                                                onClick={() => {
                                                    setUserInfoMode('read')
                                                }}
                                            />
                                        )}
                                    </FlexBetween>


                                    {exploringId !== -1 && employeeDetail && employeeDetail.contact ? (
                                        <UserInfo
                                            userModule='professional'
                                            defaultValues={{
                                                firstName: employeeDetail.contact.firstName,
                                                lastName: employeeDetail.contact.lastName,
                                                email: employeeDetail.contact.email ?? employeeDetail.contact.email1 ?? employeeDetail.contact.email2,
                                                contactId: employeeDetail.contactId

                                            }}
                                            editData={!!userInfo ? { ...userInfo } : null}
                                            onClose={onCloseUserInfo}
                                            mode={userInfoMode}
                                        />
                                    ) : (
                                        <p>Loading...</p>
                                    )}

                                </DivContainer>
                            </TabPanel>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to cancel your changes?"}
                </DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={agree} color="primary">
                        Yes
                    </Button>
                    <Button onClick={disagree} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
