import {
    Divider, FormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText, Grid,
    Input, InputLabel, MenuItem,
    TextField,
    Autocomplete
} from "@mui/material";
import { Button, Radio, RadioGroup } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getClientDetails } from "../../../api/clients";
import { getContactRelByRelationship } from "../../../api/cms";
import { getContactDetail } from "../../../api/contact";
import {
    getMeargenList,
    getRoshHameargenList, getYeshivasList
} from "../../../api/yeshivas";
import * as constant from "../../../constants";
import ContactSelectField from "../../../components/ContactSelect/ContactSelectField";
import SelectContact from "../../../components/explorer/Tabs/Dialog/SelectContact";
import { _calculateAge } from "../../../utils/CommonServices";
import authHeader from "../../_helper/auth-header";
import ParentContact from "./parentContact";
import useStyles from "./postStyle";
import baseStyles from '../../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";
import { strings } from '../../../_helpers/strings';
import ProtectedComponent from '../../../components/ProtectedComponent/ProtectedComponent';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from '@date-io/date-fns';
import RichTextField from '../../../components/UI/RichTextField';
import TextFormattingWrapper from '../../../utils/TextFormattingWrapper';
import { useNavigate } from "react-router-dom";
import { TextFieldEditor } from "../../../components/common/UI";

export default function PostCreate(props) {
    // global.log('props', exploringId);
    const navigate = useNavigate();
    const classes = useStyles();
    const baseClasses = baseStyles();
    const fetchClients = useStoreActions(actions => actions.clients.fetchClients);
    const [parentInformation, setParentInformation] = React.useState("Father");
    const [relation1, setRelation1] = React.useState("select");
    const [relation2, setRelation2] = React.useState("select");
    const [yeshivas, setYeshivas] = React.useState(0);
    const [menahalim, setMenahalim] = React.useState(0);

    const [first_name, setFirstName] = React.useState("");
    const [last_name, setLastName] = React.useState("");
    const [main_concern, setMainConcern] = React.useState("");
    const [addition_info, setAdditionInfo] = React.useState("");
    const [insurance, setInsuranceInfo] = React.useState("");
    const [medication, setMedication] = React.useState("");
    const [diagnoses, setDiagnoses] = React.useState("");
    const [referredBy, setReferredBy] = React.useState("");
    const [organizationHead, setOrganizationHead] = React.useState(0);

    const [menahelId, setMenahelId] = React.useState(0);
    const [menahel, setMenahel] = React.useState(0);

    const [meargen, setMeargen] = React.useState(0);
    const [financialCoordinator, setFinancialCoordinator] = React.useState(0);
    const [stage, setStage] = React.useState();
    const [intakeInfo, setIntakeInfo] = React.useState();
    const [meargenId, setMeargenId] = React.useState(0);
    const [financialCoordinatorId, setFinancialCoordinatorId] = React.useState();
    const [fatherContactId, setFatherContactId] = React.useState(0);
    const [motherContactId, setMotherContactId] = React.useState(0);
    const [roshHameargen, setRoshHameargen] = React.useState(0);
    const [roshHameargenId, setRoshHameargenId] = React.useState(0);
    const [isSelectOpenMeargen, setIsSelectOpenMeargen] = React.useState(false);
    const [isSelectOpenRoshMeargen, setIsSelectOpenRoshMeargen] = React.useState(false);

    const [evaluation, setEvaluation] = React.useState("");
    const [herb_first_name, setHerbFirstName] = React.useState("");
    const [herb_last_name, setHerbLastName] = React.useState("");
    const [legal_first_name, setLegalFirstName] = React.useState("");
    const [legal_last_name, setLegalLastName] = React.useState("");
    const [legal_middle_name, setLegalMiddleName] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [apt, setApt] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zipcode, setZipcode] = React.useState("");
    const [home_phone, setHomePhone] = React.useState("");
    const [age, setAge] = React.useState("");
    const [grade, setGrade] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [email2, setEmail2] = React.useState("");
    const [birth_date, setBirthDate] = React.useState(null);
    const [enrolledOn, setEnrolledOn] = React.useState(null);
    const [disenrolledOn, setDisenrolledOn] = React.useState(null);
    const [reasonDisenrolled, setReasonDisenrolled] = React.useState(null);
    const [medicaid, setMedicaid] = React.useState(3);
    const [has_p3, setP3] = React.useState(false);
    const [approvalPocketState, setapprovalPocketState] = React.useState(false);
    const [yeshivasList, setYeshivasList] = React.useState([]);
    const [yeshivaPrevName, setYeshivaPrevName] = React.useState("");
    const [menahelList, setMenahelList] = React.useState([]);
    const [meargenList, setMeargenList] = React.useState([]);
    const [roshHameargenList, setRoshHameargenList] = React.useState([]);
    const [hasContact, setHasContact] = React.useState(false);
    const neighborhoodValues = useStoreState(state => state.list.values.neighborhoods);
    const neighborhoodsIsFetched = useStoreState(state => state.list.values.neighborhoodsIsFetched);
    const fetchNeighborhoods = useStoreActions(actions => actions.list.values.fetchNeighborhoods);
    useEffect(() => {
        if (!neighborhoodsIsFetched) {
            fetchNeighborhoods();
        }
    }, [])
    const { type, view, fatherView, setFatherView, motherView, setMotherView, education, information, enrollment } = props;
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    const exploringId = useStoreState((state) => state.exploringId);
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    global.personalDetailsHasInfo = () => {
        let personalDetailFields = `${first_name}${last_name}${herb_first_name}${herb_last_name}${legal_first_name}${legal_middle_name}${legal_last_name}${address}${apt}${city}${state}${zipcode}${home_phone}${age}`;
        //global.log("the personal detail fields '" + personalDetailFields + "'")
        return (personalDetailFields.length > 0);
    }
    const clientDetailSet = useStoreActions(
        (actions) => actions.clients.setDetail
    );

    const [canEditPersonalInfo, setCanEditPersonalInfo] = useState(false);
    useEffect(() => {
        setCanEditPersonalInfo(props.personalInfoPermission && props.personalInfoPermission.canEdit);
    }, [props.personalInfoPermission, props.personalInfoPermission.canEdit])

    // TODO: we need to move it the enum or constant folder in order clean component.
    const saveClientPayload = {
        // Contacts: [],
        Programs: [],
        id: 0,
        createdOn: "2021-02-10T06:22:53.264Z",
        isDeleted: true,
        firstName: "",
        lastName: "",
        firstNameHebrew: "",
        lastNameHebrew: "",
        firstNameLegal: "",
        lastNameLegal: "",
        middleNameLegal: "",
        address1: "",
        neighborhood: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone1: "",
        phone2: "",
        email1: "",
        email2: "",
        dob: "2021-02-10T06:22:53.264Z",
        age: 0,
        ssn: 0,
        contactId: 0,
        isActive: true,
        enrolledOn: "2021-02-10T06:22:53.264Z",
        father_ContactId: 0,
        mother_ContactId: 0,
        primaryContact_ContactId: null,
        legalGuardian_ContactId: null,
        yeshivaId: 0,
        menahel_ContactId: 0,
        mainConcern: "",
        additionalInfo: "",
        medication: "",
        evaluation: 0,
        diagnosis: "",
        hasMedicaid: true,
        healthInsuranceProvider: "",
        cin: "",
        hasP3: true,
        referredBy: "",
        isPayingTillApproval: true,
        cans: "",
        comfortHealthClientId: 0,
        comfortHealthCareManager_ContactId: 0,
        comfortHealthCareManagerSupervisor_ContactId: 0,
        meargen_ContactId: 0,
        roshHameargen_ContactId: 0,
        stage: null,
        intakeInfo: "",
        contacts: [
            {
                id: 0,
                createdOn: "2021-02-10T06:22:53.264Z",
                isDeleted: true,
                firstName: "",
                lastName: "",
                firstNameHebrew: "",
                lastNameHebrew: "",
                firstNameLegal: "",
                lastNameLegal: "",
                middleNameLegal: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                phone1: "",
                phone2: "",
                email1: "",
                email2: "",
                dob: "2021-02-10T06:22:53.265Z",
                age: 0,
                ssn: 0,
                contactId: 0,
                relationship: "",
            },
        ],
        programs: [
            {
                id: 0,
                createdOn: "2021-02-10T06:22:53.265Z",
                isDeleted: true,
                clientId: 0,
                client: {
                    id: 0,
                    createdOn: "2021-02-10T06:22:53.265Z",
                    isDeleted: true,
                    contactId: 0,
                    isActive: true,
                    enrolledOn: "2021-02-10T06:22:53.265Z",
                    father_ContactId: 0,
                    mother_ContactId: 0,
                    primaryContact_ContactId: null,
                    legalGuardian_ContactId: null,
                    yeshivaId: 0,
                    menahel_ContactId: 0,
                    mainConcern: "",
                    additionalInfo: "",
                    medication: "",
                    evaluation: 0,
                    diagnosis: "",
                    hasMedicaid: true,
                    healthInsuranceProvider: "",
                    cin: "",
                    hasP3: true,
                    referredBy: "",
                    isPayingTillApproval: true,
                    cans: "",
                    comfortHealthClientId: 0,
                    comfortHealthCareManager_ContactId: 0,
                    comfortHealthCareManagerSupervisor_ContactId: 0,
                    meargen_ContactId: 0,
                    roshHameargen_ContactId: 0,
                    stage: null,
                    intakeInfo: "",
                    contacts: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.265Z",
                            isDeleted: true,
                            clientId: 0,
                            contactId: 0,
                            contact: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.265Z",
                                isDeleted: true,
                                firstName: "",
                                lastName: "",
                                firstNameHebrew: "",
                                lastNameHebrew: "",
                                firstNameLegal: "",
                                lastNameLegal: "",
                                middleNameLegal: "",
                                address1: "",
                                address2: "",
                                city: "",
                                state: "",
                                zip: "",
                                phone1: "",
                                phone2: "",
                                email1: "",
                                email2: "",
                                dob: "2021-02-10T06:22:53.265Z",
                                age: 0,
                                ssn: 0,
                            },
                            table: "",
                            primaryId: 0,
                            relationship: "",
                        },
                    ],
                    contact: {
                        id: 0,
                        createdOn: "2021-02-10T06:22:53.265Z",
                        isDeleted: true,
                        firstName: "",
                        lastName: "",
                        firstNameHebrew: "",
                        lastNameHebrew: "",
                        firstNameLegal: "",
                        lastNameLegal: "",
                        middleNameLegal: "",
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zip: "",
                        phone1: "",
                        phone2: "",
                        email1: "",
                        email2: "",
                        dob: "2021-02-10T06:22:53.265Z",
                        age: 0,
                        ssn: 0,
                    },
                    programs: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.265Z",
                            isDeleted: true,
                            clientId: 0,
                            programId: 1,
                            program: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.265Z",
                                isDeleted: true,
                                programName: "",
                                programs: [""],
                            },
                            enrolledOn: "2021-02-10T06:22:53.265Z",
                            clientProgramStatusId: 1,
                            clientProgramStatus: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.265Z",
                                isDeleted: true,
                                listId: 0,
                                list: {
                                    id: 0,
                                    createdOn: "2021-02-10T06:22:53.265Z",
                                    isDeleted: true,
                                    listName: "",
                                    moduleId: 0,
                                    module: {
                                        id: 0,
                                        createdOn: "2021-02-10T06:22:53.266Z",
                                        isDeleted: true,
                                        moduleName: "",
                                        description: "",
                                    },
                                    listDescription: "",
                                    defaultValue: "",
                                },
                                value: 0,
                                valueDescription: "",
                            },
                            programService: [""],
                        },
                    ],
                    employees: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.266Z",
                            isDeleted: true,
                            clientId: 0,
                            employeeId: 0,
                            employee: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.266Z",
                                isDeleted: true,
                                contactId: 0,
                                contact: {
                                    id: 0,
                                    createdOn: "2021-02-10T06:22:53.266Z",
                                    isDeleted: true,
                                    firstName: "",
                                    lastName: "",
                                    firstNameHebrew: "",
                                    lastNameHebrew: "",
                                    firstNameLegal: "",
                                    lastNameLegal: "",
                                    middleNameLegal: "",
                                    address1: "",
                                    address2: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                    phone1: "",
                                    phone2: "",
                                    email1: "",
                                    email2: "",
                                    dob: "2021-02-10T06:22:53.266Z",
                                    age: 0,
                                    ssn: 0,
                                },
                                positionId: 0,
                                position: {
                                    id: 0,
                                    createdOn: "2021-02-10T06:22:53.266Z",
                                    isDeleted: true,
                                    name: "",
                                    description: "",
                                },
                                rate: 0,
                            },
                            rate: 0,
                            isActive: true,
                        },
                    ],
                    employeeSchedule: [],
                    contactSchedules: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.266Z",
                            isDeleted: true,
                            contactId: 0,
                            contact: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.266Z",
                                isDeleted: true,
                                firstName: "",
                                lastName: "",
                                firstNameHebrew: "",
                                lastNameHebrew: "",
                                firstNameLegal: "",
                                lastNameLegal: "",
                                middleNameLegal: "",
                                address1: "",
                                address2: "",
                                city: "",
                                state: "",
                                zip: "",
                                phone1: "",
                                phone2: "",
                                email1: "",
                                email2: "",
                                dob: "2021-02-10T06:22:53.266Z",
                                age: 0,
                                ssn: 0,
                            },
                            scheduleId: 0,
                            schedule: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.266Z",
                                isDeleted: true,
                                startTime: "2021-02-10T06:22:53.266Z",
                                endTime: "2021-02-10T06:22:53.266Z",
                                date: "2021-02-10T06:22:53.266Z",
                                endDate: "2021-02-10T06:22:53.266Z",
                                isRepeat: true,
                                everyXOfPeriods: 0,
                                periodicity: "",
                                days: "",
                            },
                        },
                    ],
                },
                programId: 1,
                program: {
                    id: 0,
                    createdOn: "2021-02-10T06:22:53.266Z",
                    isDeleted: true,
                    programName: "",
                    programs: [""],
                },
                enrolledOn: "2021-02-10T06:22:53.266Z",
                clientProgramStatusId: 1,
                clientProgramStatus: {
                    id: 0,
                    createdOn: "2021-02-10T06:22:53.266Z",
                    isDeleted: true,
                    listId: 0,
                    list: {
                        id: 0,
                        createdOn: "2021-02-10T06:22:53.266Z",
                        isDeleted: true,
                        listName: "",
                        moduleId: 0,
                        module: {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.266Z",
                            isDeleted: true,
                            moduleName: "",
                            description: "",
                        },
                        listDescription: "",
                        defaultValue: "",
                    },
                    value: "",
                    valueDescription: "",
                },
                programService: [
                    {
                        id: 0,
                        createdOn: "2021-02-10T06:22:53.266Z",
                        isDeleted: true,
                        clientProgramId: 0,
                        clientProgram: {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.266Z",
                            isDeleted: true,
                            clientId: 0,
                            programId: 1,
                            program: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.267Z",
                                isDeleted: true,
                                programName: "",
                                programs: [""],
                            },
                            enrolledOn: "2021-02-10T06:22:53.267Z",
                            clientProgramStatusId: 1,
                            clientProgramStatus: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.267Z",
                                isDeleted: true,
                                listId: 0,
                                list: {
                                    id: 0,
                                    createdOn: "2021-02-10T06:22:53.267Z",
                                    isDeleted: true,
                                    listName: "",
                                    moduleId: 0,
                                    module: {
                                        id: 0,
                                        createdOn: "2021-02-10T06:22:53.267Z",
                                        isDeleted: true,
                                        moduleName: "",
                                        description: "",
                                    },
                                    listDescription: "",
                                    defaultValue: "",
                                },
                                value: "",
                                valueDescription: "",
                            },
                            programService: [""],
                        },
                        serviceId: 0,
                        service: {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.267Z",
                            isDeleted: true,
                            serviceName: "",
                            programId: 0,
                            program: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.267Z",
                                isDeleted: true,
                                programName: "",
                                programs: [""],
                            },
                        },
                        hoursPerWeek: 0,
                        units: 0,
                        approvedOn: "2021-02-10T06:22:53.267Z",
                    },
                ],
            },
        ],
        schedules: [
            {
                id: 0,
                createdOn: "2021-02-10T06:22:53.267Z",
                isDeleted: true,
                startTime: "2021-02-10T06:22:53.267Z",
                endTime: "2021-02-10T06:22:53.267Z",
                date: "2021-02-10T06:22:53.267Z",
                endDate: "2021-02-10T06:22:53.267Z",
                isRepeat: true,
                everyXOfPeriods: 0,
                periodicity: "",
                days: "",
            },
        ],
        employeeSchedules: [
            {
                id: 0,
                createdOn: "2021-02-10T06:22:53.267Z",
                isDeleted: true,
                clientId: 0,
                client: {
                    id: 0,
                    createdOn: "2021-02-10T06:22:53.267Z",
                    isDeleted: true,
                    contactId: 0,
                    isActive: true,
                    enrolledOn: "2021-02-10T06:22:53.267Z",
                    father_ContactId: 0,
                    mother_ContactId: 0,
                    primaryContact_ContactId: null,
                    legalGuardian_ContactId: null,
                    yeshivaId: 0,
                    menahel_ContactId: 0,
                    mainConcern: "",
                    additionalInfo: "",
                    medication: "",
                    evaluation: 0,
                    diagnosis: "",
                    hasMedicaid: true,
                    healthInsuranceProvider: "",
                    cin: "",
                    hasP3: true,
                    referredBy: "",
                    isPayingTillApproval: true,
                    cans: "",
                    comfortHealthClientId: 0,
                    comfortHealthCareManager_ContactId: 0,
                    comfortHealthCareManagerSupervisor_ContactId: 0,
                    meargen_ContactId: 0,
                    roshHameargen_ContactId: 0,
                    stage: null,
                    contacts: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.267Z",
                            isDeleted: true,
                            clientId: 0,
                            contactId: 0,
                            contact: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.267Z",
                                isDeleted: true,
                                firstName: "",
                                lastName: "",
                                firstNameHebrew: "",
                                lastNameHebrew: "",
                                firstNameLegal: "",
                                lastNameLegal: "",
                                middleNameLegal: "",
                                address1: "",
                                address2: "",
                                city: "",
                                state: "",
                                zip: "",
                                phone1: "",
                                phone2: "",
                                email1: "",
                                email2: "",
                                dob: "2021-02-10T06:22:53.267Z",
                                age: 0,
                                ssn: 0,
                            },
                            table: "",
                            primaryId: 0,
                            relationship: "",
                        },
                    ],
                    contact: {
                        id: 0,
                        createdOn: "2021-02-10T06:22:53.267Z",
                        isDeleted: true,
                        firstName: "",
                        lastName: "",
                        firstNameHebrew: "",
                        lastNameHebrew: "",
                        firstNameLegal: "",
                        lastNameLegal: "",
                        middleNameLegal: "",
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zip: "",
                        phone1: "",
                        phone2: "",
                        email1: "",
                        email2: "",
                        dob: "2021-02-10T06:22:53.267Z",
                        age: 0,
                        ssn: 0,
                    },
                    programs: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.268Z",
                            isDeleted: true,
                            clientId: 0,
                            programId: 1,
                            program: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.268Z",
                                isDeleted: true,
                                programName: "",
                                programs: [""],
                            },
                            enrolledOn: "2021-02-10T06:22:53.268Z",
                            clientProgramStatusId: 1,
                            clientProgramStatus: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.268Z",
                                isDeleted: true,
                                listId: 0,
                                list: {
                                    id: 0,
                                    createdOn: "2021-02-10T06:22:53.268Z",
                                    isDeleted: true,
                                    listName: "",
                                    moduleId: 0,
                                    module: {
                                        id: 0,
                                        createdOn: "2021-02-10T06:22:53.268Z",
                                        isDeleted: true,
                                        moduleName: "",
                                        description: "",
                                    },
                                    listDescription: "",
                                    defaultValue: "",
                                },
                                value: "",
                                valueDescription: "",
                            },
                            programService: [""],
                        },
                    ],
                    employees: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.268Z",
                            isDeleted: true,
                            clientId: 0,
                            employeeId: 0,
                            employee: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.268Z",
                                isDeleted: true,
                                contactId: 0,
                                contact: {
                                    id: 0,
                                    createdOn: "2021-02-10T06:22:53.268Z",
                                    isDeleted: true,
                                    firstName: "",
                                    lastName: "",
                                    firstNameHebrew: "",
                                    lastNameHebrew: "",
                                    firstNameLegal: "",
                                    lastNameLegal: "",
                                    middleNameLegal: "",
                                    address1: "",
                                    address2: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                    phone1: "",
                                    phone2: "",
                                    email1: "",
                                    email2: "",
                                    dob: "2021-02-10T06:22:53.268Z",
                                    age: 0,
                                    ssn: 0,
                                },
                                positionId: 0,
                                position: {
                                    id: 0,
                                    createdOn: "2021-02-10T06:22:53.268Z",
                                    isDeleted: true,
                                    name: "",
                                    description: "",
                                },
                                rate: 0,
                            },
                            rate: 0,
                            isActive: true,
                        },
                    ],
                    employeeSchedule: [""],
                    contactSchedules: [
                        {
                            id: 0,
                            createdOn: "2021-02-10T06:22:53.268Z",
                            isDeleted: true,
                            contactId: 0,
                            contact: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.268Z",
                                isDeleted: true,
                                firstName: "",
                                lastName: "",
                                firstNameHebrew: "",
                                lastNameHebrew: "",
                                firstNameLegal: "",
                                lastNameLegal: "",
                                middleNameLegal: "",
                                address1: "",
                                address2: "",
                                city: "",
                                state: "",
                                zip: "",
                                phone1: "",
                                phone2: "",
                                email1: "",
                                email2: "",
                                dob: "2021-02-10T06:22:53.268Z",
                                age: 0,
                                ssn: 0,
                            },
                            scheduleId: 0,
                            schedule: {
                                id: 0,
                                createdOn: "2021-02-10T06:22:53.268Z",
                                isDeleted: true,
                                startTime: "2021-02-10T06:22:53.268Z",
                                endTime: "2021-02-10T06:22:53.268Z",
                                date: "2021-02-10T06:22:53.268Z",
                                endDate: "2021-02-10T06:22:53.268Z",
                                isRepeat: true,
                                everyXOfPeriods: 0,
                                periodicity: "",
                                days: "",
                            },
                        },
                    ],
                },
                employeeId: 0,
                employee: {
                    id: 0,
                    createdOn: "2021-02-10T06:22:53.268Z",
                    isDeleted: true,
                    contactId: 0,
                    contact: {
                        id: 0,
                        createdOn: "2021-02-10T06:22:53.268Z",
                        isDeleted: true,
                        firstName: "",
                        lastName: "",
                        firstNameHebrew: "",
                        lastNameHebrew: "",
                        firstNameLegal: "",
                        lastNameLegal: "",
                        middleNameLegal: "",
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zip: "",
                        phone1: "",
                        phone2: "",
                        email1: "",
                        email2: "",
                        dob: "2021-02-10T06:22:53.268Z",
                        age: 0,
                        ssn: 0,
                    },
                    positionId: 0,
                    position: {
                        id: 0,
                        createdOn: "2021-02-10T06:22:53.269Z",
                        isDeleted: true,
                        name: "",
                        description: "",
                    },
                    rate: 0,
                },
                serviceId: 0,
                service: {
                    id: 0,
                    createdOn: "2021-02-10T06:22:53.269Z",
                    isDeleted: true,
                    serviceName: "",
                    programId: 0,
                    program: {
                        id: 0,
                        createdOn: "2021-02-10T06:22:53.269Z",
                        isDeleted: true,
                        programName: "",
                        programs: [""],
                    },
                },
                scheduleId: 0,
                schedule: {
                    id: 0,
                    createdOn: "2021-02-10T06:22:53.269Z",
                    isDeleted: true,
                    startTime: "2021-02-10T06:22:53.269Z",
                    endTime: "2021-02-10T06:22:53.269Z",
                    date: "2021-02-10T06:22:53.269Z",
                    endDate: "2021-02-10T06:22:53.269Z",
                    isRepeat: true,
                    everyXOfPeriods: 0,
                    periodicity: "",
                    days: "",
                },
                note: "",
            },
        ],
    };

    // Parent Contact fields
    const [contactId, setContactId] = React.useState(0);
    const [contactFirstName, setContactFirstName] = React.useState("");
    const [contactLastName, setContactLastName] = React.useState("");
    const [contactHerbrewFirstName, setContactHerbrewFirstName] = React.useState("");
    const [contactHerbrewLastName, setContactHerbrewLastName] = React.useState("");
    const [contactLegalFirstName, setContactLegalFirstName] = React.useState("");
    const [contactLegealMiddleName, setContactLegalMiddleName] = React.useState("");
    const [contactLegalLastName, setContactLegalLastName] = React.useState("");
    const [contactPhone1, setContactPhone1] = React.useState("");
    const [contactPhone2, setContactPhone2] = React.useState("");
    const [contactEmail1, setContactEmail1] = React.useState("");
    const [contactEmail2, setContactEmail2] = React.useState("");
    const [contactAddress, setContactAddress] = React.useState("");
    const [contactApt, setContactApt] = React.useState("");
    const [contactCity, setContactCity] = React.useState("");
    const [contactState, setContactState] = React.useState("");
    const [contactZip, setContactZip] = React.useState("");
    const [isNewClient, setIsNewClient] = React.useState(false);

    // Errors selection
    const [firstNamePerError, setFirstNamePerError] = React.useState("");
    const [lastNamePerError, setLastNamePerError] = React.useState("");
    const [herbFirstNamePerError, setHerbFirstNamePerError] = React.useState("");
    const [herbLastNamePerError, setHerbLastNamePerError] = React.useState("");
    const [legalFirstNamePerError, setLegalFirstNamePerError] = React.useState("");
    const [legalLastNamePerError, setLegalLastNamePerError] = React.useState("");
    const [legalMiddleNamePerError, setLegalMiddleNamePerError] = React.useState("");
    const [selectedNeighborhood, setSelectedNeighborhood] = React.useState("");
    const [neighborhoodText, setNeighborhoodText] = React.useState("");
    const [id, setId] = React.useState("-1");
    const [dataOfClient, setDataOfClient] = React.useState(null);

    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );

    //loading
    const [loading, setLoading] = useState({});
    const setLoadingGlobal = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const setLoadingItem = (itemName, value) => {
        setLoading((prevState) => { return { ...prevState, [itemName]: value } });
    }
    useEffect(() => {
        let isCurrLoading = false;
        for (var key in loading) {
            if (loading.hasOwnProperty(key)) {
                if (loading[key])
                    isCurrLoading = true;
            }
        }
        if (isCurrLoading)
            setLoadingGlobal();
        else
            endLoading();
    }, [loading]);


    useEffect(() => {
        // global.log("effect");
        resetAllData();
        setLoadingItem("yeshivas", true);
        getYeshivasList().then((value) => {
            setYeshivasList(value);
            setLoadingItem("yeshivas");
        });
    }, []);

    const resetAllData = () => {
        // setContactFirstName("");
        // setContactLastName("");
        // setContactHerbrewFirstName("");
        // setContactHerbrewLastName("");
        // setContactLegalFirstName("");
        // setContactLegalMiddleName("");
        // setContactLegalLastName("");
        // setContactPhone1("");
        // setContactPhone2("");
        // setContactEmail1("");
        // setContactEmail2("");
        // setContactAddress("");
        // setContactApt("");
        // setContactCity("");
        // setContactState("");
        // setContactZip("");
        setFirstNamePerError("");
        setLastNamePerError("");
        setHerbFirstNamePerError("");
        setHerbLastNamePerError("");
        setLegalFirstNamePerError("");
        setLegalLastNamePerError("");
        setLegalMiddleNamePerError("");
    };

    const resetForm = () => {
        setFirstName("");
        setLastName("");
        setHerbFirstName("");
        setHerbLastName("");
        setLegalFirstName("");
        setLegalMiddleName("");
        setLegalLastName("");
        setSelectedNeighborhood("");
        setCity("");
        setAddress("");
        setHomePhone("");
        setApt("");
        setAge("");
        setGrade("");
        setState("");
        setZipcode("");
        setBirthDate(null);
        // address
        setContactFirstName("");
        setContactId();
        setContactLastName("");
        setContactHerbrewFirstName("");
        setContactHerbrewLastName("");
        setContactLegalFirstName("");
        setContactLegalMiddleName("");
        setContactLegalLastName("");
        setContactApt("");
        setContactAddress("");
        setContactCity("");
        setContactEmail1("");
        setContactEmail2("");
        setContactPhone1("");
        setContactPhone2("");
        setContactState("");
        setContactZip("");

        setMainConcern("");
        setAdditionInfo("");
        setInsuranceInfo("");
        setMedication("");
        setDiagnoses("");
        setEvaluation("");
        setId("-1");

        setFatherContactId(0);
        setMotherContactId(0);
        setYeshivas(0);

        setMenahelId(0);
        setMenahel("-");

        setMenahalim(0);

        setMedicaid(3);

        //Additional Info
        setMeargenId(0);
        setMeargen("-");
        setFinancialCoordinatorId(null);
        setFinancialCoordinator("-");
        setIntakeInfo("");
        setRoshHameargenId(0);
        setRoshHameargen("-");
        setStage(null);
        setP3(false);
        setapprovalPocketState(false);
    };

    useEffect(() => {
        if (exploringId == null) {
            setIsNewClient(true);
            resetForm();
        } else {
            setIsNewClient(false)
            handleGetClientDetail();
        }
    }, [exploringId]);

    const resetEducationDataToPrevious = () => {
        setYeshivas(dataOfClient.yeshivaId);
        setMenahelId(dataOfClient.menahel_ContactId);
        setMenahel("-");
        setMenahalim(dataOfClient.menahel_ContactId);
        setGrade(dataOfClient.grade ? dataOfClient.grade : "");
    }
    const resetEnrollmentDataToPrevious = () => {
        setEnrolledOn(dataOfClient.enrolledOn);
        setDisenrolledOn(dataOfClient.disenrolledOn);
        setReasonDisenrolled(dataOfClient.reasonDisenrolled);
    }
    const resetLhisalosDataToPrevious = () => {
        setMeargenId(dataOfClient.meargen_ContactId);
        setMeargen("-");
        setRoshHameargenId(dataOfClient.roshHameargen_ContactId);
        setRoshHameargen("-");
        setFinancialCoordinatorId(dataOfClient.financialCoordinator_ContactId);
        setFinancialCoordinator("-");
        setStage(dataOfClient.stage);
        setIntakeInfo(dataOfClient.intakeInfo);
    }

    const handleGetClientDetail = () => {
        if (!global.isEmpty(exploringId)) {
            setId(exploringId);
                setLoadingItem("meargenim", true);
            getMeargenList(exploringId).then((value) => {
                setMeargenList(value);
                setLoadingItem("meargenim");
            });
                setLoadingItem("roshMeargenim", true);
            getRoshHameargenList(exploringId).then((value) => {
                setRoshHameargenList(value);
                setLoadingItem("roshMeargenim");
            });
                setLoadingItem("client", true);
            getClientDetails(exploringId).then((response) => {
                setDataOfClient(response);
                setFirstName(response.firstName);
                setLastName(response.lastName);
                setNeighborhoodText(response.neighborhood);
                neighborhoodValues.forEach((neighborhood, index) => {
                    if (neighborhood === response.neighborhood) {
                        setSelectedNeighborhood(index);
                    }
                })
                setExploringName(response.firstName + " " + response.lastName);
                setHerbFirstName(
                    response.firstNameHebrew ? response.firstNameHebrew : ""
                );
                setHerbLastName(response.lastNameHebrew ? response.lastNameHebrew : "");
                setLegalFirstName(
                    response.firstNameLegal ? response.firstNameLegal : ""
                );
                setLegalMiddleName(
                    response.middleNameLegal ? response.middleNameLegal : ""
                );
                setLegalLastName(response.lastNameLegal ? response.lastNameLegal : "");
                setCity(response.city ? response.city : "");
                setAddress(response.address1 ? response.address1 : "");
                setApt(response.address2 ? response.address2 : "");
                setHomePhone(response.phone1 ? response.phone1 : "");
                setAge(response.age ? response.age : "");
                setState(response.state ? response.state : "");
                setZipcode(response.zip ? response.zip : "");
                setGrade(response.grade ? response.grade : "");
                setBirthDate(
                    response.dob ? moment(response.dob).format("YYYY-MM-DD") : ""
                );
                setEnrolledOn(response.enrolledOn);
                setDisenrolledOn(response.disenrolledOn);
                setReasonDisenrolled(response.reasonDisenrolled);
                if (response.contacts && response.contacts.length) setHasContact(true);
                else setHasContact(false);
                if (response.contacts && response.contacts.length) {
                    setContactFirstName(
                        response.contacts[0].firstName ? response.contacts[0].firstName : ""
                    );
                    setContactId(
                        response.contacts[0].contactId ? response.contacts[0].contactId : 0
                    );
                    setContactLastName(
                        response.contacts[0].lastName ? response.contacts[0].lastName : ""
                    );
                    setContactHerbrewFirstName(
                        response.contacts[0].firstNameHebrew
                            ? response.contacts[0].firstNameHebrew
                            : ""
                    );
                    setContactHerbrewLastName(
                        response.contacts[0].lastNameHebrew
                            ? response.contacts[0].lastNameHebrew
                            : ""
                    );
                    setContactLegalFirstName(
                        response.contacts[0].firstNameLegal
                            ? response.contacts[0].firstNameLegal
                            : ""
                    );
                    setContactLegalMiddleName(
                        response.contacts[0].middleNameLegal
                            ? response.contacts[0].middleNameLegal
                            : ""
                    );
                    setContactLegalLastName(
                        response.contacts[0].lastNameLegal
                            ? response.contacts[0].lastNameLegal
                            : ""
                    );
                    setContactApt(
                        response.contacts[0].address2 ? response.contacts[0].address2 : ""
                    );
                    setContactAddress(
                        response.contacts[0].address1 ? response.contacts[0].address1 : ""
                    );
                    setContactCity(
                        response.contacts[0].city ? response.contacts[0].city : ""
                    );
                    setContactEmail1(
                        response.contacts[0].email1 ? response.contacts[0].email1 : ""
                    );
                    setContactEmail2(
                        response.contacts[0].email2 ? response.contacts[0].email2 : ""
                    );
                    setContactPhone1(
                        response.contacts[0].phone1 ? response.contacts[0].phone1 : ""
                    );
                    setContactPhone2(
                        response.contacts[0].phone2 ? response.contacts[0].phone2 : ""
                    );
                    setContactState(
                        response.contacts[0].state ? response.contacts[0].state : ""
                    );
                    setContactZip(
                        response.contacts[0].zip ? response.contacts[0].zip : ""
                    );
                }

                setMainConcern(response.mainConcern);
                setAdditionInfo(response.additionalInfo);
                setInsuranceInfo(response.healthInsuranceProvider);
                setMedication(response.medication);
                setDiagnoses(response.diagnosis);
                setEvaluation(response.evaluation);
                setRelation1(response.legalGuardian_ContactId ?? 'select');
                setRelation2(response.primaryContact_ContactId ?? 'select');
                setFatherContactId(response.father_ContactId);
                setMotherContactId(response.mother_ContactId);

                setMenahelId(response.menahel_ContactId);
                setMenahel("-");
                if (response.menahel_ContactId) {
                    getContactDetail(response.menahel_ContactId).then((value) => {
                        if (value) setMenahel(value?.firstName + " " + value?.lastName);
                    });
                }

                setStage(response.stage);
                setMeargenId(response.meargen_ContactId);
                setMeargen("-");
                if (response.meargen_ContactId) {
                    getContactDetail(response.meargen_ContactId).then((value) => {
                        if (value) setMeargen(value?.firstName + " " + value?.lastName);
                    });
                }
                setFinancialCoordinatorId(response.financialCoordinator_ContactId);
                setFinancialCoordinator("-");
                if (response.financialCoordinator_ContactId) {
                    getContactDetail(response.financialCoordinator_ContactId).then((value) => {
                        if (value) setFinancialCoordinator(value?.firstName + " " + value?.lastName);
                    });
                }
                setIntakeInfo(response.intakeInfo)

                setRoshHameargenId(response.roshHameargen_ContactId);
                setRoshHameargen("-");
                if (response.roshHameargen_ContactId) {
                    getContactDetail(response.roshHameargen_ContactId).then((value) => {
                        if (value)
                            setRoshHameargen(value?.firstName + " " + value?.lastName);
                    });
                }

                setP3(response.hasP3);
                setMedicaid(
                    response.hasMedicaid == true
                        ? 1
                        : response.hasMedicaid == false
                            ? 2
                            : 3
                );
                setYeshivas(response.yeshivaId);
                getYeshivasList().then((value) => {
                    setYeshivasList(value);
                    var yeshivaItem = value.find((item) => item.id == response.yeshivaId);
                    setYeshivaPrevName(yeshivaItem ? yeshivaItem.name : "");
                });

                setMenahalim(response.menahel_ContactId);

                if (response.isPayingTillApproval === true) {
                    setapprovalPocketState(true);
                } else {
                    setapprovalPocketState(false);
                }
                // setMainConcern(response.)
                // global.log("Response++", response); response.dob ? response.dob.format("DD-MM-YYYY") :
                setLoadingItem("client");
            });
        }
    };

    const handleNeighborhoodChange = (event) => {
        setSelectedNeighborhood(event.target.value);
        setDirty();
        //global.log('the selected user neighborhood is now', selectedNeighborhood)
    }
    const handleChange1 = (event) => {
        setRelation1(event.target.value);
        if (event.target.value === 1) {
            setParentInformation("Mother");
        } else if (event.target.value === 0) {
            setParentInformation("Father");
        }
        setDirty();
    };
    const handleChange2 = (event) => {
        setRelation2(event.target.value);
        if (event.target.value === 1) {
            setParentInformation("Mother");
        } else if (event.target.value === 0) {
            setParentInformation("Father");
        }
        setDirty();
    };
    const handleChangeMenahalim = (event, newValue) => {
        setMenahalim(newValue?.id ?? 0);
        setDirty();
    };
    const handleChangeYeshivas = (event, newValue) => {
        setYeshivas(newValue?.id ?? 0);
        setDirty();
    };

    const [warningMsg, setWarningMsg] = React.useState({ id: -1, name: "" });
    useEffect(() => {
        if (yeshivas == null || yeshivas == 0) return;
        getContactRelByRelationship("Yeshivas", yeshivas, "Menahel").then(
            (value) => {
                if (typeof value === "boolean") return;
                setWarningMsg({ id: -1, name: "" });
                if (value.find((item) => item.id == menahalim) == null) {
                    var tempItem = menahelList.find((item) => item.id == menahalim);
                    if (tempItem) {
                        setWarningMsg({
                            id: tempItem.id,
                            name: "  Yeshiva : " + yeshivaPrevName + "!",
                        });
                        setMenahelList([...value, tempItem]);
                    } else setMenahelList(value);
                } else setMenahelList(value);
            }
        );
    }, [yeshivas]);

    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (first_name === "") {
            isValid = false;
            setFirstNamePerError("Please enter first name");
        }
        if (last_name === "") {
            isValid = false;
            setLastNamePerError("Please enter last name");
        } /*
    if (herb_first_name === "") {
      isValid = false;
      setHerbFirstNamePerError("Please enter herbrew first name");
    }
    if (herb_last_name === "") {
      isValid = false;
      setHerbLastNamePerError("Please enter herbrew last name");
    }
    if (legal_first_name === "") {
      isValid = false;
      setLegalFirstNamePerError("Please enter legal first name");
    }
    if (legal_middle_name === "") {
      isValid = false;
      setLegalMiddleNamePerError("Please enter legal middle name");
    }
    if (legal_last_name === "") {
      isValid = false;
      setLegalLastNamePerError("Please enter legal last name");
    }*/
        return isValid;
    };

    const saveClientInformation = (
        param_fatherContactId = fatherContactId,
        param_motherContactId = motherContactId
    ) => {
        const isValid = formValidationPersonalDetails();
        if (isValid) {
            if (exploringId == null) {

            } else {
                props.onInformation(true);
                props.onView(true);
            }
            saveClientPayload.firstName = first_name;
            saveClientPayload.lastName = last_name;
            saveClientPayload.firstNameHebrew = herb_first_name;
            saveClientPayload.lastNameHebrew = herb_last_name;
            saveClientPayload.firstNameLegal = legal_first_name;
            saveClientPayload.lastNameLegal = legal_last_name;
            saveClientPayload.middleNameLegal = legal_middle_name;
            saveClientPayload.address1 = address;
            saveClientPayload.neighborhood = neighborhoodValues[selectedNeighborhood];
            saveClientPayload.address2 = apt;
            saveClientPayload.city = city;
            saveClientPayload.state = state;
            saveClientPayload.zip = zipcode;
            saveClientPayload.phone1 = home_phone;
            saveClientPayload.dob = birth_date;
            saveClientPayload.age = age == "" ? 0 : age;
            saveClientPayload.grade = grade;
            saveClientPayload.comfortHealthCareManager_ContactId = 0;
            saveClientPayload.comfortHealthCareManagerSupervisor_ContactId = 0;
            saveClientPayload.createdOn = new Date();
            saveClientPayload.enrolledOn = isNewClient ? new Date() : enrolledOn;
            saveClientPayload.disenrolledOn = disenrolledOn;
            saveClientPayload.reasonDisenrolled = reasonDisenrolled;
            if (relation1 === 0) {
                saveClientPayload.legalGuardian_ContactId = 0;
                saveClientPayload.contacts[0].relationship = "Father";
            } else if (relation1 === 1) {
                saveClientPayload.legalGuardian_ContactId = 1;
                saveClientPayload.contacts[0].relationship = "Mother";
            }
            if (relation2 === 0) {
                saveClientPayload.primaryContact_ContactId = 0;
                saveClientPayload.contacts[0].relationship = "Father";
            } else if (relation2 === 1) {
                saveClientPayload.primaryContact_ContactId = 1;
                saveClientPayload.contacts[0].relationship = "Mother";
            }
            saveClientPayload.contacts[0].contactId = contactId;
            saveClientPayload.contacts[0].firstName = contactFirstName;
            saveClientPayload.contacts[0].lastName = contactLastName;
            saveClientPayload.contacts[0].firstNameHebrew = contactHerbrewFirstName;
            saveClientPayload.contacts[0].lastNameHebrew = contactHerbrewLastName;
            saveClientPayload.contacts[0].firstNameLegal = contactLegalFirstName;
            saveClientPayload.contacts[0].lastNameLegal = contactLegalLastName;
            saveClientPayload.contacts[0].middleNameLegal = contactLegealMiddleName;
            saveClientPayload.contacts[0].address1 = contactAddress;
            saveClientPayload.contacts[0].address2 = contactApt;
            saveClientPayload.contacts[0].city = contactCity;
            saveClientPayload.contacts[0].state = contactState;
            saveClientPayload.contacts[0].zip = contactZip;
            saveClientPayload.contacts[0].phone1 = contactPhone1;
            saveClientPayload.contacts[0].phone2 = contactPhone2;
            saveClientPayload.contacts[0].email1 = contactEmail1;
            saveClientPayload.contacts[0].email2 = contactEmail2;
            saveClientPayload.mainConcern = main_concern;
            saveClientPayload.additionalInfo = addition_info;
            saveClientPayload.hasMedicaid =
                medicaid == 1 ? true : medicaid == 2 ? false : null;
            saveClientPayload.healthInsuranceProvider = insurance;
            saveClientPayload.medication = medication;
            saveClientPayload.diagnosis = diagnoses;
            saveClientPayload.hasP3 = has_p3;
            saveClientPayload.referredBy = referredBy;
            saveClientPayload.evaluation = evaluation;
            saveClientPayload.isPayingTillApproval = approvalPocketState;

            //Education Section
            saveClientPayload.yeshivaId = yeshivas;
            // saveClientPayload.menahel_ContactId = menahelId;
            saveClientPayload.menahel_ContactId = menahalim;

            saveClientPayload.stage = parseInt(stage);
            saveClientPayload.intakeInfo = intakeInfo;
            saveClientPayload.meargen_ContactId = meargenId;
            saveClientPayload.financialCoordinator_ContactId = financialCoordinatorId;
            saveClientPayload.roshHameargen_ContactId = roshHameargenId;

            saveClientPayload.father_ContactId = param_fatherContactId;
            saveClientPayload.mother_ContactId = param_motherContactId;

            //global.log("onsave>>", saveClientPayload);
            setLoadingItem("savingClient", true)
            return global.fetchWrapper
                .post(constant.SAVE_CLIENT_DETAILS + id, saveClientPayload)
                .then(
                    (response) => {
                        if (response.responseCode === 200) {
                            setClean();
                            clientDetailSet(response.result);
                            resetForm();
                            if (id == -1) {
                                //global.log('the response of posting the new client is:', response)
                                setId(response.result.id);
                                setExploringId(response.result.id);
                                navigate(`../client/${response.result.id}/enrollment`, { replace: true })
                                setIsNewClient(false)
                                props.onView(true)
                                props.onEnrollment(true)
                                props.onEducation(true)
                                props.onInformation(true)
                                props.setType(1)
                                props.setIsNewClient(false)
                            } else {
                            }
                            props.addClientSuccess(true);
                            handleGetClientDetail(true);
                        }
                        setLoadingItem("savingClient")
                    },
                    (error) => {
                        setLoadingItem("savingClient", false)
                    }
                );
        }

    };
    const onSaveEducation = () => {
        if (exploringId == null) {
            props.onclick();
        } else {
            props.onEducation(true);
            saveClientInformation(fatherContactId, motherContactId);
            setClean();
        }
    };
    const [enrolledOnError, setEnrolledOnError] = useState("");
    useEffect(() => {
        if (!enrolledOn)
            setEnrolledOnError("Date enrolled can't be empty");
        else
            setEnrolledOnError("");

    }, [enrolledOn])
    const onSaveEnrollment = () => {
        if (exploringId == null) {
            props.onclick();
        } else {
            if (enrolledOnError || !global.dateTime.isValidDate(enrolledOn) || !global.dateTime.isValidDate(disenrolledOn))
                return;
            props.onEnrollment(true);
            saveClientInformation(fatherContactId, motherContactId);
            setClean();
        }
    };
    const getCurrentOpenSection = () => {
        if (isNewClient && global.personalDetailsHasInfo())
            return "personal";
        else if (!view) {
            return "personal";
        }
        else if (!fatherView) {
            return "father";
        }
        else if (!motherView) {
            return "mother";
        }
        else if (!education) {
            return "education"
        }
        else if (!information) {
            return "info"
        }
        else if (!enrollment) {
            return "enrollment"
        }
    }
    const [isSectionOpen, setIsSectionOpen] = useState(false);
    useEffect(() => {
        const isOpen = (type === 1 && (!view || !fatherView || !motherView || !education || !information)) || (isNewClient && global.personalDetailsHasInfo());
        setIsSectionOpen(isOpen);
        //if (isOpen) setDirty(); else setClean();
    }, [type, view, fatherView, motherView, education, information])
    const isSectionContainsChanges = () => {
        return hasChanges();
        // pass isDirty prop into parent-contact component, do so on each field change, clear on save or rollback
    }
    const isConfirmed = (e, checkFor, rollbackChanges) => {
        var sec = getCurrentOpenSection();
        if (rollbackChanges == undefined)
            rollbackChanges = rollbackChanges_dict[getCurrentOpenSection()];
        switch (checkFor) {
            case "open_section":
                if (isSectionOpen) {
                    if (wConfirm(e, rollbackChanges, "You are editing the \"" + strings.toTitleCase(getCurrentOpenSection()) + "\" section, discard its changes?"))
                        return true;
                    else
                        return false;
                }
                return true;
                break;
            case "cancel_section":
                if (isSectionContainsChanges(getCurrentOpenSection())) {
                    if (wConfirm(e, rollbackChanges, "You have unsaved changes, do you want to discard them?"))
                        return true;
                    else
                        return false;
                }
                return true;
                break;
            default:
                return false;
        }
        return false;
    }
    const _handleView = (e) => {
        if (!isConfirmed(e, "open_section")) {
            return;
        }
        // global.log("exploringId", exploringId, dataOfClient);
        if (exploringId == null) {
            props.onclick();
        } else {
            props.onView(false);
        }
    };
    const rollbackChanges_personal = () => {
        if (dataOfClient !== null) {
            resetAllDataWithPrevious();
        }
        setClean();
        props.onView(true);
    }
    const rollbackChanges_father = () => {
        if (dataOfClient !== null) {
            // resetAllParentDataWithPrevious();
        }
        setClean();
        setFatherView(true);
    }
    const rollbackChanges_mother = () => {
        if (dataOfClient !== null) {
            // resetAllParentDataWithPrevious();
        }
        setClean();
        setMotherView(true);
    }
    const rollbackChanges_education = () => {
        if (dataOfClient !== null) {
            resetEducationDataToPrevious();
        }
        setClean();
        props.onEducation(true);
    }
    const rollbackChanges_enrollment = () => {
        if (dataOfClient !== null) {
            resetEnrollmentDataToPrevious();
        }
        setClean();
        props.onEnrollment(true);
    }
    const rollbackChanges_lhisalosInfo = () => {
        if (dataOfClient !== null) {
            resetLhisalosDataToPrevious();
        }
        setClean();
        props.onInformation(true);
    }
    const rollbackChanges_dict = {
        personal: rollbackChanges_personal,
        father: rollbackChanges_father,
        mother: rollbackChanges_mother,
        education: rollbackChanges_education,
        enrollment: rollbackChanges_enrollment,
        info: rollbackChanges_lhisalosInfo
    }
    const onCancel = (e) => {
        if (!isConfirmed(e, "cancel_section"))
            return;
        if (isNewClient) {
            props.goBack();
        }
        else if (exploringId === null) {
            // global.log("props");
            // props.onclick();
        } else {
            props.onView(true);
            //props.onParentView(true);
        }
    };
    const onCancelEducation = (e) => {
        if (!isConfirmed(e, "cancel_section"))
            return;
        if (exploringId === null) {
            // global.log("props");
            props.onclick();
        } else {
            props.onEducation(true);
        }
    };
    const onCancelEnrollment = (e) => {
        if (!isConfirmed(e, "cancel_section"))
            return;
        if (exploringId === null) {
            // global.log("props");
            props.onclick();
        } else {
            props.onEnrollment(true);
        }
    };
    const _handleClose = (e) => {
        if (!isConfirmed(e, "cancel_section"))
            return;
        setClean();
        props.onView(true);
    };
    const resetAllParentDataWithPrevious = () => {
        if (dataOfClient.contacts && dataOfClient.contacts.length) {
            setContactFirstName(
                dataOfClient.contacts[0].firstName
                    ? dataOfClient.contacts[0].firstName
                    : ""
            );
            setContactLastName(
                dataOfClient.contacts[0].lastName
                    ? dataOfClient.contacts[0].lastName
                    : ""
            );
            setContactHerbrewFirstName(
                dataOfClient.contacts[0].firstNameHebrew
                    ? dataOfClient.contacts[0].firstNameHebrew
                    : ""
            );
            setContactHerbrewLastName(
                dataOfClient.contacts[0].lastNameHebrew
                    ? dataOfClient.contacts[0].lastNameHebrew
                    : ""
            );
            setContactLegalFirstName(
                dataOfClient.contacts[0].firstNameLegal
                    ? dataOfClient.contacts[0].firstNameLegal
                    : ""
            );
            setContactLegalMiddleName(
                dataOfClient.contacts[0].middleNameLegal
                    ? dataOfClient.contacts[0].middleNameLegal
                    : ""
            );
            setContactLegalLastName(
                dataOfClient.contacts[0].lastNameLegal
                    ? dataOfClient.contacts[0].lastNameLegal
                    : ""
            );
            setContactApt(
                dataOfClient.contacts[0].address2
                    ? dataOfClient.contacts[0].address2
                    : ""
            );
            setContactAddress(
                dataOfClient.contacts[0].address1
                    ? dataOfClient.contacts[0].address1
                    : ""
            );
            setContactCity(
                dataOfClient.contacts[0].city ? dataOfClient.contacts[0].city : ""
            );
            setContactEmail1(
                dataOfClient.contacts[0].email1 ? dataOfClient.contacts[0].email1 : ""
            );
            setContactEmail2(
                dataOfClient.contacts[0].email2 ? dataOfClient.contacts[0].email2 : ""
            );
            setContactPhone1(
                dataOfClient.contacts[0].phone1 ? dataOfClient.contacts[0].phone1 : ""
            );
            setContactPhone2(
                dataOfClient.contacts[0].phone2 ? dataOfClient.contacts[0].phone2 : ""
            );
            setContactState(
                dataOfClient.contacts[0].state ? dataOfClient.contacts[0].state : ""
            );
            setContactZip(
                dataOfClient.contacts[0].zip ? dataOfClient.contacts[0].zip : ""
            );
        }
    };

    const resetAllDataWithPrevious = () => {
        setFirstName(dataOfClient.firstName);
        setLastName(dataOfClient.lastName);
        setHerbFirstName(
            dataOfClient.firstNameHebrew ? dataOfClient.firstNameHebrew : ""
        );
        setHerbLastName(
            dataOfClient.lastNameHebrew ? dataOfClient.lastNameHebrew : ""
        );
        setLegalFirstName(
            dataOfClient.firstNameLegal ? dataOfClient.firstNameLegal : ""
        );
        setLegalMiddleName(
            dataOfClient.middleNameLegal ? dataOfClient.middleNameLegal : ""
        );
        setLegalLastName(
            dataOfClient.lastNameLegal ? dataOfClient.lastNameLegal : ""
        );

        setAddress(dataOfClient.address1 ? dataOfClient.address1 : "");
        setApt(dataOfClient.address2 ? dataOfClient.address2 : "");
        setSelectedNeighborhood(dataOfClient.neighborhood ? dataOfClient.neighborhood : "");
        setCity(dataOfClient.city ? dataOfClient.city : "");
        setState(dataOfClient.state ? dataOfClient.state : "");
        setZipcode(dataOfClient.zip ? dataOfClient.zip : "");
        setHomePhone(dataOfClient.phone1 ? dataOfClient.phone1 : "");
        setAge(dataOfClient.age ? dataOfClient.age : "");
        setGrade(dataOfClient.grade ? dataOfClient.grade : "");
        setBirthDate(dataOfClient.dob ? moment(dataOfClient.dob).format("YYYY-MM-DD") : "");

        setRelation1(dataOfClient.legalGuardian_ContactId ?? 'select');
        setRelation2(dataOfClient.primaryContact_ContactId ?? 'select');
    };

    const Comp_displayField = ({ fieldTitle, fieldValue, style}) => {
        return (
            <div className={baseClasses.fieldLine}>
                <div className={baseClasses.fieldtitle} style={{ ...style, minWidth: 310 }}>
                    {fieldTitle}{" "}
                </div>
                <div className={baseClasses.fieldvalue}>{fieldValue}</div>
            </div>
        );
    };

    return (
        <div>
            {!isNewClient && (<Card className={classes.root}>
                <div className={classes.header_1}>
                    <Typography className={baseClasses.title_2} variant="h6" component="span">
                        LHisalos Enrollment
                    </Typography>
                        {props.type == 0 || !props.enrollmentPermission || !props.enrollmentPermission.canEdit ? null : (
                        <div>
                            {props.enrollment ? (
                                <EditIcon
                                    className={baseClasses.edit_icon}
                                    onClick={(e) => isConfirmed(e, "open_section") && props.onEnrollment(false)}
                                />
                            ) : (
                                <CloseIcon
                                    className={baseClasses.edit_icon}
                                        onClick={(e) => isConfirmed(e, "cancel_section") && props.onEnrollment(true)}
                                />
                            )}
                        </div>
                    )}
                </div>
                <Divider className={classes.separator} />
                {props.enrollment ? (
                    <Grid
                        container
                        className={baseClasses.grid}
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            {/* TODO: Component name should be in PascalCase */}
                            <Comp_displayField
                                fieldTitle={"Enrollment Status :"}
                                fieldValue={
                                    (global.dateTime.isDatePast(disenrolledOn) ? `DISENROLLED   (${global.dateTime.getUSAFormattedDateString(enrolledOn)} - ${global.dateTime.getUSAFormattedDateString(disenrolledOn)})`
                                        : `ACTIVE   (Since: ${global.dateTime.getUSAFormattedDateString(enrolledOn)})`)
                                }
                            ></Comp_displayField>
                        </Grid>

                        {!global.isEmpty(disenrolledOn) && !global.dateTime.isDatePast(disenrolledOn) && (
                            <Grid item xs={12}>
                            <Comp_displayField
                                fieldTitle={"Disenrollment Date :"}
                                    fieldValue={global.dateTime.getUSAFormattedDateString(disenrolledOn)}
                            ></Comp_displayField>
                            </Grid>)
                        }
                        {!global.isEmpty(disenrolledOn) && (
                            <Grid item xs={12}>
                            <Comp_displayField
                                fieldTitle={"Reason Disenrolled :"}
                                fieldValue={global.isEmpty(reasonDisenrolled) ? "-" : reasonDisenrolled}
                            ></Comp_displayField>
                            </Grid>)
                        }
                    </Grid>
                ) : (
                    <Grid
                        container
                        className={baseClasses.grid}
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <div>
                                <FormControl fullWidth className={baseClasses.optionsContract}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                fullWidth
                                                id={"date-enrolled"}
                                                disableToolbar
                                                format="MM/dd/yyyy"
                                                label="Date Enrolled"
                                                size="small"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                value={enrolledOn}
                                                onChange={(value) => {
                                                    setEnrolledOn(value);
                                                    setDirty();
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                renderInput={(params) => <TextField 
                                                    {...params} 
                                                    />}
                                            />
                                            
                                        </LocalizationProvider>
                                        {enrolledOnError &&
                                            <p style={{ color: "red" }}>{ enrolledOnError }</p>
                                        }
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <FormControl fullWidth className={baseClasses.optionsContract}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                fullWidth
                                                id={"date-disenrolled"}
                                                disableToolbar
                                                format="MM/dd/yyyy"
                                                label="Date Disenrolled"
                                                size="small"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                value={disenrolledOn}
                                                onChange={(value) => {
                                                    setDisenrolledOn(value);
                                                    setDirty();
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                renderInput={(params) => <TextField 
                                                    {...params} 
                                                    />}
                                            />
                                        </LocalizationProvider>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="my-input-grade">Reason Disenrolled</InputLabel>
                                    <Input
                                        type="text"
                                        value={reasonDisenrolled}
                                        id="my-input-grade"
                                        onChange={(event) => {
                                            setReasonDisenrolled(event.target.value);
                                            setDirty();
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                )}

                {props.enrollment ? null : <Divider className={baseClasses.separator} />}
                {props.enrollment ? null : (
                    <div className={classes.save}>
                        <Button size="small" onClick={onCancelEnrollment}>
                            CANCEL
                        </Button>
                        <Button color="primary" onClick={onSaveEnrollment} size="small">
                            Save
                        </Button>
                    </div>
                )}
            </Card>)}
            {<div className={baseClasses.separate_div}></div>}
            <Card className={classes.root}>
                <CardContent>
                    <div className={classes.header_1}>
                        <Typography className={classes.title} variant="h6" component="span">
                            Personal details
                        </Typography>
                        <Typography
                            className={classes.date}
                            variant="subtitle1"
                            component="span"
                        >
                            Date Enrolled:
                            {dataOfClient
                                ? global._DateField(dataOfClient.enrolledOn)
                                : global._DateField()}
                        </Typography>
                        {props.type == 0 || !canEditPersonalInfo ? null : (
                            <div>
                                {props.view ? (
                                    <EditIcon
                                        className={baseClasses.edit_icon}
                                        onClick={_handleView}
                                    />
                                ) : (
                                    <CloseIcon
                                        className={baseClasses.edit_icon}
                                        onClick={_handleClose}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </CardContent>
                <Divider className={baseClasses.separator} />
                <div>
                    {/* TODO: Move this into the component personal detail like we already have employee */}
                    {props.view ? (
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}>Name: </div>
                                <div className={baseClasses.fieldvalue}>
                                    {first_name === "" ? "-" : first_name}{" "}
                                    {last_name === "" ? "-" : last_name}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}>Hebrew Name:</div>
                                <div className={baseClasses.fieldvalue}>
                                    {herb_first_name == "" ? "-" : herb_first_name}{" "}
                                    {herb_last_name == "" ? "" : herb_last_name}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}>Legal Name:</div>
                                <div className={baseClasses.fieldvalue}>
                                    {legal_first_name === "" ? "-" : legal_first_name}{" "}
                                    {legal_middle_name === "" ? "" : legal_middle_name}{" "}
                                    {legal_last_name === "" ? "" : legal_last_name}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}>Address:</div>
                                <div className={baseClasses.fieldvalue}>
                                    {address === "" ? "-" : address}{" "}
                                    {apt === "" ? "" : apt + ", "}{" "}
                                    {city === "" ? "" : city + ", "}{" "}
                                    {state === "" ? "" : state + "-"}{" "}
                                    {zipcode === "" ? "" : zipcode}
                                </div>
                            </Grid>
                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}> Neighborhood: </div>
                                <div className={baseClasses.fieldvalue}>
                                    {neighborhoodText !== "" ? neighborhoodText : "-"}
                                </div>
                            </Grid>
                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}> Home Phone: </div>
                                <div className={baseClasses.fieldvalue}>
                                    {home_phone === "" ? "-" : home_phone}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}>Age: </div>
                                <div className={baseClasses.fieldvalue}>
                                    {birth_date != ""
                                        ? _calculateAge(new Date(birth_date))
                                        : age == ""
                                            ? 0
                                            : age}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}> Date Of Birth:</div>
                                <div className={baseClasses.fieldvalue}>
                                    {birth_date === "" ? "-" : birth_date}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}>Legal Guardian: </div>
                                <div className={baseClasses.fieldvalue}>
                                    {relation1 == 0 ? "Father" : relation1 == 1 ? "Mother" : "-"}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={baseClasses.fieldLine}>
                                <div className={baseClasses.fieldtitle}> Primary Contact:</div>
                                <div className={baseClasses.fieldvalue}>
                                    {relation2 == 0 ? "Father" : relation2 == 1 ? "Mother" : "-"}
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        // TODO: Remove all props.view condition in this section, as props.view is always false here so no need to check here
                        <Grid
                            container
                            className={clsx(baseClasses.grid, classes.personalEditPanel)}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={props.view ? 12 : 6}>
                                {/* TODO: Why checking view again? Here view will always will be false here, we don't need to check it here */}
                                {props.view ? (
                                    <div>
                                        Name: {first_name === "" ? "-" : first_name}{" "}
                                        {last_name === "" ? "-" : last_name}
                                    </div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">First Name</InputLabel>
                                            <Input
                                                value={first_name}
                                                id="my-input"
                                                onChange={(event) => {
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setFirstNamePerError("");
                                                    }
                                                    setFirstName(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {/* TODO: render it with && condition - OR move it to the common component */}
                                            {firstNamePerError === "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {firstNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            {/* TODO: Why checking view again? Here view will always will be false here, we don't need to check it here */}
                            {props.view ? (
                                <div></div>
                            ) : (
                                // TODO: this props.view condition is useless view is always false here
                                <Grid item xs={props.view ? 12 : 6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Last Name</InputLabel>
                                            <Input
                                                value={last_name}
                                                id="my-input"
                                                onChange={(event) => {
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setLastNamePerError("");
                                                    }
                                                    setLastName(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {/* TODO: render it with && condition - OR move it to the common component */}
                                            {lastNamePerError === "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {lastNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}
                            {/* TODO: this props.view condition is useless view is always false here */}
                            <Grid item xs={props.view ? 12 : 6}>
                                {props.view ? (
                                    <div>
                                        Hebrew Name: {herb_first_name == "" ? "-" : herb_first_name}{" "}
                                        {herb_last_name == "" ? "" : herb_last_name}
                                    </div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Hebrew First Name
                                            </InputLabel>
                                            <Input
                                                value={herb_first_name}
                                                id="my-input"
                                                onChange={(event) => {
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setHerbFirstNamePerError("");
                                                    }
                                                    setHerbFirstName(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {herbFirstNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {herbFirstNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            {/* TODO: no condition needed  */}
                            {props.view ? (
                                <div></div>
                            ) : (
                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Hebrew Last Name
                                            </InputLabel>
                                            <Input
                                                value={herb_last_name}
                                                onChange={(event) => {
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setHerbLastNamePerError("");
                                                    }
                                                    setHerbLastName(event.target.value);
                                                    setDirty();
                                                }}
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {herbLastNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {herbLastNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}
                            
                            <Grid item xs={props.view ? 12 : 4}>
                                {props.view ? (
                                    <div>
                                        Legal Name:{" "}
                                        {legal_first_name === "" ? "-" : legal_first_name}{" "}
                                        {legal_middle_name === "" ? "" : legal_middle_name}{" "}
                                        {legal_last_name === "" ? "" : legal_last_name}
                                    </div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Legal First Name
                                            </InputLabel>
                                            <Input
                                                value={legal_first_name}
                                                onChange={(event) => {
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setLegalFirstNamePerError("");
                                                    }
                                                    setLegalFirstName(event.target.value);
                                                    setDirty();
                                                }}
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {legalFirstNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {legalFirstNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            {props.view ? (
                                <div></div>
                            ) : (
                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Legal Last Name
                                            </InputLabel>
                                            <Input
                                                value={legal_last_name}
                                                onChange={(event) => {
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setLegalLastNamePerError("");
                                                    }
                                                    setLegalLastName(event.target.value);
                                                    setDirty();
                                                }}
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {legalLastNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {legalLastNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}
                            {props.view ? (
                                <div></div>
                            ) : (
                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Legal Middle Name
                                            </InputLabel>
                                            <Input
                                                value={legal_middle_name}
                                                onChange={(event) => {
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setLegalMiddleNamePerError("");
                                                    }
                                                    setLegalMiddleName(event.target.value);
                                                    setDirty();
                                                }}
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {legalMiddleNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {legalMiddleNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}

                            <Grid item xs={props.view ? 12 : 4}>
                                {props.view ? (
                                    <div>
                                        Address: {address === "" ? "-" : address}
                                        {apt === "" ? "" : apt + ", "}{" "}
                                        {city === "" ? "" : city + ", "}{" "}
                                        {state === "" ? "" : state + "-"}{" "}
                                        {zipcode === "" ? "" : zipcode}
                                    </div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Address 1</InputLabel>
                                            <Input
                                                value={address}
                                                id="my-input"
                                                onChange={(event) => {
                                                    setAddress(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            {props.view ? (
                                <div></div>
                            ) : (
                                // <div>Apartment: {apt === "" ? "-" : apt}</div>
                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Apt</InputLabel>
                                            <Input
                                                value={apt}
                                                id="my-input"
                                                onChange={(event) => {
                                                    setApt(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={props.view ? 12 : 4}>
                                {props.view ? (
                                    <div>
                                        Neighborhood: {neighborhoodText !== "" ? neighborhoodText : "-"}
                                    </div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth className={baseClasses.optionsContract}>
                                            <InputLabel htmlFor="neighborhood-select" id="neighborhood-label">Neighborhood</InputLabel>
                                            <Select
                                                labelId="neighborhood-label"
                                                id="neighborhood-select"
                                                value={selectedNeighborhood}
                                                className={baseClasses.selection}
                                                onChange={handleNeighborhoodChange}
                                            >
                                                {neighborhoodValues.map((neighborhood, index) =>
                                                    <MenuItem value={index}>{neighborhood}</MenuItem>
                                                )}
                                            </Select>
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            {props.view ? (
                                <div></div>
                            ) : (
                                // <div>City: {city === "" ? "-" : city}</div>
                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">City</InputLabel>
                                            <Input
                                                value={city}
                                                id="my-input"
                                                onChange={(event) => {
                                                    setCity(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}
                            {props.view ? (
                                <div></div>
                            ) : (
                                // <div>State: {state === "" ? "-" : state}</div>
                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">State</InputLabel>
                                            <Input
                                                value={state}
                                                id="my-input"
                                                onChange={(event) => {
                                                    setState(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}
                            {props.view ? (
                                <div></div>
                            ) : (
                                // <div>Zip: {zipcode === "" ? "-" : zipcode}</div>
                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Zip</InputLabel>
                                            <Input
                                                value={zipcode}
                                                id="my-input"
                                                onChange={(event) => {
                                                    setZipcode(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                {props.view ? (
                                    <div>Home Phone: {home_phone === "" ? "-" : home_phone}</div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Home Phone</InputLabel>
                                            <Input
                                                value={home_phone}
                                                id="my-input"
                                                onChange={(event) => {
                                                    setHomePhone(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={props.view ? 12 : 6}>
                                {props.view ? (
                                    <div>Age: {age == "" ? 0 : age}</div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Age</InputLabel>
                                            <Input
                                                type="number"
                                                value={age}
                                                inputProps={{ min: "0" }}
                                                id="my-input"
                                                onChange={(event) => {
                                                    setAge(event.target.value);
                                                    setDirty();
                                                }}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={props.view ? 12 : 6}>
                                {props.view ? (
                                    <div>
                                        Date Of Birth: {birth_date === "" ? "-" : birth_date}
                                    </div>
                                ) : (
                                    <div className={baseClasses.paddingTop16}>
                                        <FormControl fullWidth>
                                            {/* <InputLabel htmlFor="my-input">Date Of Birth</InputLabel> */}
                                            <TextField
                                                id="date"
                                                label="Date of Birth"
                                                type="date"
                                                value={birth_date}
                                                onChange={(event) => {
                                                    setBirthDate(event.target.value);
                                                    setDirty();
                                                }}
                                                className={baseClasses.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={props.view ? 12 : 6}>
                                {props.view ? (
                                    <div>
                                        Legal Guardian:
                                        {!relation1 || relation1 == "select"
                                            ? "-"
                                            : relation1 == 0
                                                ? "Father"
                                                : "Mother"}
                                    </div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth className={baseClasses.optionsContract}>
                                            <InputLabel id="demo-controlled-open-select-label">
                                                Legal Guardian
                                            </InputLabel>
                                            <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                value={relation1}
                                                className={baseClasses.selection}
                                                onChange={handleChange1}
                                            >
                                                <MenuItem value={"select"}>Select Legal Guardian</MenuItem>
                                                <MenuItem value={0}>Father</MenuItem>
                                                <MenuItem value={1}>Mother</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={props.view ? 12 : 6}>
                                {props.view ? (
                                    <div>
                                        Primary Contact:
                                        {!relation2 || relation2 == ""
                                            ? "-"
                                            : relation2 == 0
                                                ? "Father"
                                                : "Mother"}
                                    </div>
                                ) : (
                                    <div>
                                        <FormControl fullWidth className={baseClasses.optionsContract}>
                                            <InputLabel id="demo-controlled-open-select-label-2">
                                                Primary Contact
                                            </InputLabel>
                                            <Select
                                                labelId="demo-controlled-open-select-label-2"
                                                id="demo-controlled-open-select"
                                                value={relation2}
                                                className={baseClasses.selection}
                                                onChange={handleChange2}
                                            >
                                                <MenuItem value={"select"}>Select Primary Contact</MenuItem>

                                                <MenuItem value={0}>Father</MenuItem>
                                                <MenuItem value={1}>Mother</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </div>

                {/* TODO: put && condition to avoid return null */}
                {props.view ? null : <Divider className={baseClasses.separator} />}
                {props.view ? null : (
                    <div className={classes.save}>
                        <Button size="small" onClick={onCancel}>
                            CANCEL
                        </Button>
                        <Button
                            color="primary"
                            onClick={() =>
                                saveClientInformation(fatherContactId, motherContactId)
                            }
                            size="small"
                        >
                            Save
                        </Button>
                    </div>
                )}

            </Card>


            {isNewClient ? null : (<>
                {<div className={baseClasses.separate_div}></div>}
                <Card className={classes.root}>
                    <ParentContact
                        propsMeta={props}
                        confirmOpen={(e) => { return isConfirmed(e, "open_section") }}
                        confirmCancel={(e) => { return isConfirmed(e, "cancel_section") }}
                        contactId={fatherContactId}
                        headerTitle={"Father"}
                        setClean={setClean}
                        setDirty={setDirty}
                        setView={setFatherView}
                        view={fatherView}
                        onChange={(contactId) => {
                            setFatherContactId(contactId);
                            setDirty();
                        }}
                        saveParentContact={(contactId) => {
                            setFatherContactId(contactId);
                            saveClientInformation(contactId, motherContactId);
                            setClean();
                        }}
                        canEdit={props.personalInfoPermission && props.personalInfoPermission.canEdit}
                    ></ParentContact>
                </Card>
                {<div className={baseClasses.separate_div}></div>}
                <Card className={classes.root}>
                    <ParentContact
                        propsMeta={props}
                        confirmOpen={(e) => { return isConfirmed(e, "open_section") }}
                        confirmCancel={(e) => { return isConfirmed(e, "cancel_section") }}
                        contactId={motherContactId}
                        headerTitle={"Mother"}
                        setClean={setClean}
                        setDirty={setDirty}
                        setView={setMotherView}
                        view={motherView}
                        onChange={(contactId) => {
                            setMotherContactId(contactId);
                            setDirty();
                        }}
                        saveParentContact={(contactId) => {
                            setMotherContactId(contactId);
                            saveClientInformation(fatherContactId, contactId);
                            setClean();
                        }}
                        canEdit={props.personalInfoPermission && props.personalInfoPermission.canEdit}
                    ></ParentContact>
                </Card>
                {<div className={baseClasses.separate_div}></div>}
                <Card className={classes.root}>
                    <div className={classes.header_1}>
                        <Typography className={baseClasses.title_2} variant="h6" component="span">
                            Education
                        </Typography>
                        {props.type == 0 || !canEditPersonalInfo ? null : (
                            <div>
                                {props.education ? (
                                    <EditIcon
                                        className={baseClasses.edit_icon}
                                        onClick={(e) => isConfirmed(e, "open_section") && props.onEducation(false)}
                                    />
                                ) : (
                                    <CloseIcon
                                        className={baseClasses.edit_icon}
                                        onClick={(e) => isConfirmed(e, "cancel_section") && props.onEducation(true)}
                                    />
                                )}
                            </div>
                        )}
                        {/* {props.education ? <EditIcon className={classes.editIcon} onClick={() => props.onEducation(false)} /> : <CloseIcon className={classes.editIcon} onClick={() => props.onEducation(true)} />} */}
                    </div>
                    <Divider className={baseClasses.separator} />
                    {props.education ? (
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Comp_displayField
                                    fieldTitle={"ישיבה :"}
                                    fieldValue={
                                        global.isEmpty(yeshivas)
                                            ? "-"
                                            : !_.isEmpty(yeshivasList) &&
                                            yeshivasList.map((column) =>
                                                column.id == yeshivas ? column.name : ""
                                            )
                                    }
                                ></Comp_displayField>
                            </Grid>

                            {/* <Grid item xs={12} className={baseClasses.fieldLine}>
              <div className={baseClasses.fieldtitle}> ישיבה: </div>
              <div className={baseClasses.fieldvalue}>
                {!_.isEmpty(yeshivasList) &&
                  yeshivasList.map((column) =>
                    column.id == yeshivas ? column.name : ""
                  )}
              </div>
            </Grid> */}

                            <Grid item xs={12}>
                                <Comp_displayField
                                    fieldTitle={"מנהל :"}
                                    fieldValue={
                                        <>
                                            <>
                                                {!_.isEmpty(menahelList) &&
                                                    menahelList.map((column) => (
                                                        <span key={column.id}>
                                                            {warningMsg.id == column.id ? (
                                                                <>
                                                                    {column.name} &nbsp;&nbsp;&nbsp;
                                                                    <span style={{ color: "red" }}>
                                                                        {warningMsg.name}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>{column.id == menahalim && column.name}</>
                                                            )}
                                                        </span>
                                                    ))}
                                            </>
                                            <>{menahelList.find(e => e.id != menahalim) ? "" : "-"}</>
                                        </>
                                    }
                                ></Comp_displayField>
                            </Grid>
                            {/*             
            <Grid item xs={12}>
              <Comp_displayField
                fieldTitle={"מנהל :"}
                fieldValue={menahel == 0 || menahel == "" ? "-" : menahel}
              ></Comp_displayField>
            </Grid> */}

                            <Grid item xs={12}>
                                <Comp_displayField
                                    fieldTitle={"כיתה :"}
                                    fieldValue={global.isEmpty(grade) ? "-" : grade}
                                ></Comp_displayField>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="yeshiva-select"
                                    sx={{ width: '100%' }}
                                    options={yeshivasList ?? []}
                                    autoHighlight
                                    value={yeshivasList.find((item) => item.id ===yeshivas)}
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleChangeYeshivas}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        label="ישיבה"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                <Autocomplete
                                    id="menahel-select"
                                    sx={{ width: '100%' }}
                                    options={menahelList ?? []}
                                    autoHighlight
                                    value={menahelList.find((item) => item.id === menahalim)}
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleChangeMenahalim}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        label="מנהל"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldEditor
                                    label="Grade"
                                    name="grade"
                                    margin="0px"
                                    onChange={(value, name) => {
                                        setGrade(value);
                                        setDirty();
                                    }}
                                    value={grade}
                                    fullWidth
                                />
    
                            </Grid>
                        </Grid>
                    )}

                    {props.education ? null : <Divider className={baseClasses.separator} />}
                    {props.education ? null : (
                        <div className={classes.save}>
                            <Button size="small" onClick={onCancelEducation}>
                                CANCEL
                            </Button>
                            <Button color="primary" onClick={onSaveEducation} size="small">
                                Save
                            </Button>
                        </div>
                    )}
                </Card>
                {<div className={baseClasses.separate_div}></div>}
                <Card className={classes.root}>
                    <div className={classes.header_1}>
                        <Typography className={baseClasses.title_2} variant="h6" component="span">
                            LHisalos Info
                        </Typography>
                        {(props.type == 0 || !props.lhisalosInfoPermission || !props.lhisalosInfoPermission.canEdit) ? null : (
                            <div>
                                {props.information ? (
                                    <EditIcon
                                        className={baseClasses.edit_icon}
                                        onClick={(e) => isConfirmed(e, "open_section") && props.onInformation(false)}
                                    />
                                ) : (
                                    <CloseIcon
                                        className={baseClasses.edit_icon}
                                        onClick={(e) => isConfirmed(e, "cancel_section") && props.onInformation(true)}
                                    />
                                )}
                            </div>
                        )}
                        {/* {props.information ? <EditIcon className={classes.editIcon} onClick={() => props.onInformation(false)} /> : <CloseIcon className={classes.editIcon} onClick={() => props.onInformation(true)} />} */}
                    </div>
                    <Divider className={baseClasses.separator} />

                    <Grid
                        container
                        className={baseClasses.grid}
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            {props.information ? (
                                <Comp_displayField
                                    fieldTitle={"Stage :"}
                                    fieldValue={stage ? stage : "-"}
                                ></Comp_displayField>
                            ) : (
                                <div>
                                    <ProtectedComponent permissions={global.permissions.clients.lhisalos.stage} allow>

                                        <FormControl>
                                            <FormLabel labelPlacment="start" style={{ fontSize: "12px" }}>Stage</FormLabel>
                                            <RadioGroup
                                                id={"stage-select"}
                                                row aria-label="position" name="position" value={stage?.toString()} size="small" className="justify">
                                                <FormControlLabel
                                                    id={1}
                                                    style={{ fontSize: '0.8rem' }}
                                                    value={"1"}
                                                    control={<Radio color="primary" />}
                                                    label="1"
                                                    labelPlacement="start"
                                                    size="small"
                                                    onChange={(event) => {
                                                        setStage(event.target.value)
                                                        setDirty()
                                                    }} />
                                                <FormControlLabel
                                                    id={2}
                                                    value={"2"}
                                                    control={<Radio color="primary" />}
                                                    label="2"
                                                    labelPlacement="start"
                                                    size="small"
                                                    onChange={(event) => {
                                                        setStage(event.target.value)
                                                        setDirty()
                                                    }} />
                                                <FormControlLabel
                                                    id={3}
                                                    value={"3"}
                                                    control={<Radio color="primary" />}
                                                    label="3"
                                                    labelPlacement="start"
                                                    size="small"
                                                    onChange={(event) => {
                                                        setStage(event.target.value)
                                                        setDirty()
                                                    }}
                                                />
                                                <FormControlLabel
                                                    id={4}
                                                    value={"4"}
                                                    control={<Radio color="primary" />}
                                                    label="4"
                                                    labelPlacement="start"
                                                    size="small"
                                                    onChange={(event) => {
                                                        setStage(event.target.value)
                                                        setDirty()
                                                    }}
                                                />
                                                <FormControlLabel
                                                    id={5}
                                                    value={"5"}
                                                    control={<Radio color="primary" />}
                                                    label="5"
                                                    labelPlacement="start"
                                                    size="small"
                                                    onChange={(event) => {
                                                        setStage(event.target.value)
                                                        setDirty()
                                                    }}

                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </ProtectedComponent>

                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {props.information ? (
                                <Comp_displayField
                                    fieldTitle={"מארגן :"}
                                    fieldValue={meargen == 0 || meargen == "" ? "-" : meargen}
                                ></Comp_displayField>
                            ) : (
                                <div>
                                    <ProtectedComponent permissions={global.permissions.clients.lhisalos.meargenRoshMeargen} allow>
                                        <ContactSelectField
                                            userRole={"Meargen"}
                                            label={"מארגן"}
                                            editData={meargen}
                                            onChange={(contact) => {
                                                if (!contact) {
                                                    setMeargenId(0);
                                                    setMeargen("-");
                                                } else {
                                                    setMeargenId(contact.id);
                                                    setMeargen(contact.name);
                                                }
                                                setDirty();
                                            }}
                                        ></ContactSelectField>
                                    </ProtectedComponent>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {props.information ? (
                                <Comp_displayField
                                    fieldTitle={"ראש המארגן :"}
                                    fieldValue={
                                        roshHameargen == 0 || roshHameargen == ""
                                            ? "-"
                                            : roshHameargen
                                    }
                                ></Comp_displayField>
                            ) : (
                                <div>
                                        <ProtectedComponent permissions={global.permissions.clients.lhisalos.meargenRoshMeargen} allow>
                                        <ContactSelectField
                                            userRole={"RoshMeargen"}
                                            label={"ראש המארגן "}
                                            editData={roshHameargen}
                                            onChange={(contact) => {
                                                if (!contact) {
                                                    setRoshHameargenId(0);
                                                    setRoshHameargen("-");
                                                } else {
                                                    setRoshHameargenId(contact.id);
                                                    setRoshHameargen(contact.name);
                                                }
                                                setDirty();
                                            }}
                                        ></ContactSelectField>
                                    </ProtectedComponent>
                                    {/* <FormControl fullWidth className={baseClasses.optionsContract}>
                  <InputLabel id="demo-controlled-open-select-label-2">
                    ראש המארגן :
                  </InputLabel>
                  <Input
                    value={roshHameargen}
                    id="my-input"
                    onFocus={() => {
                      setIsSelectOpenRoshMeargen(true);
                    }}
                    aria-describedby="my-helper-text"
                  />
                </FormControl> */}
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {props.information ? (
                                <Comp_displayField
                                    fieldTitle={"Billing Coordinator :"}
                                    fieldValue={financialCoordinator == 0 || financialCoordinator == "" ? "-" : financialCoordinator}
                                ></Comp_displayField>
                            ) : (
                                <div>
                                    <ProtectedComponent permissions={global.permissions.clients.lhisalos.meargenRoshMeargen} allow>
                                        <ContactSelectField
                                                userRole={"FinancialCoordinator"}
                                            label={"Billing Coordinator"}
                                                editData={financialCoordinator}
                                            onChange={(contact) => {
                                                if (!contact) {
                                                    setFinancialCoordinatorId(null);
                                                    setFinancialCoordinator("-");
                                                } else {
                                                    setFinancialCoordinatorId(contact.id);
                                                    setFinancialCoordinator(contact.name);
                                                }
                                                setDirty();
                                            }}
                                        ></ContactSelectField>
                                    </ProtectedComponent>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {props.information ? (
                                <Comp_displayField
                                    fieldTitle={"Intake Info :"}
                                    fieldValue={intakeInfo ? (<TextFormattingWrapper value={intakeInfo} /> ) : "-"}
                                ></Comp_displayField>
                            ) : (
                                    <div>

                                    <FormControl fullWidth>
                                            <RichTextField
                                                label={"Intake info"}
                                            multiline
                                            rows={4}
                                            value={intakeInfo}
                                            id="my-input-intake-notes"
                                            onChange={() => {
                                                setDirty();
                                                }}
                                                setState={setIntakeInfo}
                                            aria-describedby="my-helper-text" />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            )}
                        </Grid>

                        {/*      FROM HERE UNTIL END OF THE PAGE WAS COMMENTED OUT DUE TO CHANGES NEEDED FOR THIS PART AND WOULDN'T BE USED FOR NOW 
                   *      BUT WILL BE NEEDED AT LATER TIME*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Main Concern/Issue about client :"}*/}
                        {/*              fieldValue={main_concern === "" ? "-" : main_concern}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl className="w-100">*/}
                        {/*                  <TextField*/}
                        {/*                      className="w-100"*/}
                        {/*                      label="Main Concern/Issue about client"*/}
                        {/*                      multiline*/}
                        {/*                      rows={4}*/}
                        {/*                      value={main_concern}*/}
                        {/*                      onChange={(event) => setMainConcern(event.target.value)}*/}
                        {/*                  />*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Additional Info :"}*/}
                        {/*              fieldValue={addition_info === "" ? "-" : addition_info}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl className="w-100">*/}
                        {/*                  <TextField*/}
                        {/*                      className="w-100"*/}
                        {/*                      label="Additional Info"*/}
                        {/*                      multiline*/}
                        {/*                      rows={4}*/}
                        {/*                      value={addition_info}*/}
                        {/*                      onChange={(event) => setAdditionInfo(event.target.value)}*/}
                        {/*                  />*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}

                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Has Medicaid:"}*/}
                        {/*              fieldValue={"No"}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl fullWidth className={baseClasses.optionsContract}>*/}
                        {/*                  <InputLabel id="demo-controlled-open-select-label-2">*/}
                        {/*                      Has Medicaid*/}
                        {/*</InputLabel>*/}
                        {/*                  <Select*/}
                        {/*                      labelId="demo-controlled-open-select-label-2"*/}
                        {/*                      id="demo-controlled-open-select"*/}
                        {/*                      value={medicaid}*/}
                        {/*                      className={baseClasses.selection}*/}
                        {/*                      onChange={handleChangeMedicaid}*/}
                        {/*                  >*/}
                        {/*                      <MenuItem value={1}>Yes</MenuItem>*/}
                        {/*                      <MenuItem value={2}>No</MenuItem>*/}
                        {/*                      <MenuItem value={3}>NA</MenuItem>*/}
                        {/*                  </Select>*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Insurance :"}*/}
                        {/*              fieldValue={insurance === "" ? "-" : insurance}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl fullWidth>*/}
                        {/*                  <InputLabel htmlFor="my-input">Insurance</InputLabel>*/}
                        {/*                  <Input*/}
                        {/*                      value={insurance}*/}
                        {/*                      id="my-input"*/}
                        {/*                      onChange={(event) => setInsuranceInfo(event.target.value)}*/}
                        {/*                      aria-describedby="my-helper-text"*/}
                        {/*                  />*/}
                        {/*                  {<FormHelperText id="my-helper-text"></FormHelperText>}*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Medication :"}*/}
                        {/*              fieldValue={medication === "" ? "-" : medication}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl fullWidth>*/}
                        {/*                  <InputLabel htmlFor="my-input">Medication</InputLabel>*/}
                        {/*                  <Input*/}
                        {/*                      value={medication}*/}
                        {/*                      id="my-input"*/}
                        {/*                      onChange={(event) => setMedication(event.target.value)}*/}
                        {/*                      aria-describedby="my-helper-text"*/}
                        {/*                  />*/}
                        {/*                  {<FormHelperText id="my-helper-text"></FormHelperText>}*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Diagnoses :"}*/}
                        {/*              fieldValue={diagnoses === "" ? "-" : diagnoses}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl fullWidth>*/}
                        {/*                  <InputLabel htmlFor="my-input">Diagnoses</InputLabel>*/}
                        {/*                  <Input*/}
                        {/*                      value={diagnoses}*/}
                        {/*                      id="my-input"*/}
                        {/*                      onChange={(event) => setDiagnoses(event.target.value)}*/}
                        {/*                      aria-describedby="my-helper-text"*/}
                        {/*                  />*/}
                        {/*                  {<FormHelperText id="my-helper-text"></FormHelperText>}*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Evaluation :"}*/}
                        {/*              fieldValue={global.isEmpty(evaluation) ? "-" : evaluation}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl fullWidth>*/}
                        {/*                  <InputLabel htmlFor="my-input">Evaluation</InputLabel>*/}
                        {/*                  <Input*/}
                        {/*                      value={evaluation}*/}
                        {/*                      type="text"*/}
                        {/*                      id="my-input"*/}
                        {/*                      onChange={(event) => setEvaluation(event.target.value)}*/}
                        {/*                      aria-describedby="my-helper-text"*/}
                        {/*                  />*/}
                        {/*                  {<FormHelperText id="my-helper-text"></FormHelperText>}*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Has P3:"}*/}
                        {/*              fieldValue={has_p3 == true ? "Yes" : "No"}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <FormControlLabel*/}
                        {/*              control={*/}
                        {/*                  <Checkbox*/}
                        {/*                      checked={has_p3}*/}
                        {/*                      onClick={(event) => {*/}
                        {/*                          setP3(!has_p3);*/}
                        {/*                      }}*/}
                        {/*                      color="primary"*/}
                        {/*                  />*/}
                        {/*              }*/}
                        {/*              labelPlacement="start"*/}
                        {/*              label="Has P3"*/}
                        {/*              style={{ margin: 0 }}*/}
                        {/*          />*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Referred by : "}*/}
                        {/*              fieldValue={referredBy === "" ? "-" : referredBy}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <div>*/}
                        {/*              <FormControl fullWidth>*/}
                        {/*                  <InputLabel htmlFor="my-input">Referred by</InputLabel>*/}
                        {/*                  <Input*/}
                        {/*                      value={referredBy}*/}
                        {/*                      id="my-input"*/}
                        {/*                      onChange={(event) => setReferredBy(event.target.value)}*/}
                        {/*                      aria-describedby="my-helper-text"*/}
                        {/*                  />*/}
                        {/*                  {<FormHelperText id="my-helper-text"></FormHelperText>}*/}
                        {/*              </FormControl>*/}
                        {/*          </div>*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                        {/*  <Grid item xs={12}>*/}
                        {/*      {props.information ? (*/}
                        {/*          <Comp_displayField*/}
                        {/*              fieldTitle={"Paying of pocket till approval :"}*/}
                        {/*              fieldValue={approvalPocketState == true ? "Yes" : "No"}*/}
                        {/*          ></Comp_displayField>*/}
                        {/*      ) : (*/}
                        {/*          <FormControlLabel*/}
                        {/*              control={*/}
                        {/*                  <Checkbox*/}
                        {/*                      checked={approvalPocketState}*/}
                        {/*                      onChange={(event) => checkSelectedValue()}*/}
                        {/*                      name="approvalPocketState"*/}
                        {/*                      color="primary"*/}
                        {/*                  />*/}
                        {/*              }*/}
                        {/*              style={{ margin: 0 }}*/}
                        {/*              labelPlacement="start"*/}
                        {/*              label="Paying of pocket till approval : "*/}
                        {/*          />*/}
                        {/*      )}*/}
                        {/*  </Grid>*/}
                    </Grid>
                    {props.information ? null : <Divider className={classes.separator} />}
                    {props.information ? null : (
                        <CardActions className={classes.cardActions}>
                            <Button size="small"
                                onClick={(e) => isConfirmed(e, "cancel_section") && props.onInformation(true)}>CANCEL</Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    saveClientInformation(fatherContactId, motherContactId);
                                    setClean();
                                }}
                                size="small"
                            >Save</Button>
                        </CardActions>
                    )}
                </Card>
            </>)}

            {isSelectOpenMeargen && (
                <SelectContact
                    openModal={true}
                    handleClose={() => {
                        setIsSelectOpenMeargen(false);
                    }}
                    addYeshiva={(contact) => {
                        // saveYeshivasContact(contact);
                        // global.log(contact);
                        // saveClientPayload.meargen_ContactId = contact.id;
                        setMeargenId(contact.id);
                        // saveClientPayload.roshHameargen_ContactId = roshHameargenId;
                        setMeargen(contact.firstName + " " + contact.lastName);
                    }}
                ></SelectContact>
            )}

            {isSelectOpenRoshMeargen && (
                <SelectContact
                    openModal={true}
                    handleClose={() => {
                        setIsSelectOpenRoshMeargen(false);
                    }}
                    addYeshiva={(contact) => {
                        // saveYeshivasContact(contact);
                        // global.log(contact);
                        setRoshHameargenId(contact.id);
                        //saveClientPayload.roshHameargen_ContactId = contact.id;
                        setRoshHameargen(contact.firstName + " " + contact.lastName);
                    }}
                ></SelectContact>
            )}
        </div>
    );
}
