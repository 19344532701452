import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    IconButton,
    Typography,
    Autocomplete,
    Tooltip,
    Divider
} from "@mui/material";
import Button from "@mui/material/Button";
import Delete from '@mui/icons-material/Delete';
import { useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { getClientMonthlyServiceDetail, saveClientMonthlyServiceObjectives, getClientAllMonthlyObjectives } from '../../../../api/clients'
import { DivContainer, Text, BoldTitle, VerticalDivider, BoxContainer, FlexExactCenter, ScrollContainer, FlexBetween } from '../../../../assets/styles/styledComponents';
import { clientMonthlyServiceType, clientMonthlyServiceObjectivesDetailType, clientMonthlyServiceObjectiveType } from '../../../../assets/types';
import UploadFile from '../../../UploadFile/UploadFile'
import ConfirmDialog from '../../../common/ConfirmDialog';
import AnimLoadingState from '../../../common/AnimLoadingState';
import useBaseStyles from '../../../../assets/styles';
import useUnsavedChangesWarn from '../../../../hooks/useUnsavedChangesWarn';
import useStyles from './styles';
import { FlexContainer } from '../Progress/styles';
import useConfirm from '../../../../hooks/useConfirm';
import { MONTHLY_OBJECTIVE_STANDING_OPTIONS } from '../../../../constants'
import { strings } from '../../../../_helpers/strings';
import RichTextField from '../../../UI/RichTextField';

type EditMonthlyObjectiveProps = {
    clientMonthlyService: clientMonthlyServiceType | null,
    hebMonthYear: string | Date,
    open: boolean,
    onCloseModal: Function
}

const objectivesConst = [
    {
        "objectiveName": "Some Objective",
        "missionName": "Some Mission",
        "objectiveId": 0,
        "missionId": 0
    },
    {
        "objectiveName": "Some Objective",
        "missionName": "Some Mission",
        "objectiveId": 0,
        "missionId": 0
    }
];
const detailData = {
    "clientServiceId": 0,
    "hebMonthYear": "02-22-2022",
    "currentMonth": {
        "clientServiceMonthId": 0,
        "isTimesheetsApproved": true,
        "hebMonthYear": "02-22-2022",
        "objectives": [
            {
                "clientServiceMonthObjectiveId": 0,
                "objectiveId": 0,
                "objectiveName": "",
                "planNote": "",
                "standing": "",
                "outcomeNote": ""
            },

        ]
    },
    "upcomingMonth": {
        "clientServiceMonthId": 0,
        "hebMonthYear": "02-22-2022",
        "objectives": [
            {
                "clientServiceMonthObjectiveId": 0,
                "objectiveId": 0,
                "objectiveName": "",
                "planNote": ""
            },

        ]
    },
    "previousMonth": {
        "clientServiceMonthId": 0,
        "isTimesheetsApproved": true,
        "hebMonthYear": "02-22-2022",
        "objectives": [
            {
                "clientServicMonthObjectiveId": 0,
                "objectiveId": 0,
                "objectiveName": "Some objective",
                "planNote": "Some plan",
                "standing": "continuation",
                "outcomeNote": "Some outcome"
            }
        ]
    }
};

type EditCurrentMonthProps = {
    isError: boolean,
    objectivesData: clientMonthlyServiceObjectiveType[],
    currentObjective: clientMonthlyServiceObjectiveType,
    onDelete: Function,
    index: number,
    handleChange: Function
};

function isEmptyString(str: string) {
    return !strings.getRichTextObjFromString(str).value;
};

const EditCurrentMonth: React.FC<EditCurrentMonthProps> = ({ currentObjective, objectivesData, index, handleChange, onDelete, isError }) => {
    const classes: any = useStyles();
    const [currentDetail, setCurrentDetail] = useState<any>(currentObjective.planNote);
    const [currentObjectiveName, setCurrentObjectiveName] = useState<any>(currentObjective.objectiveName ? `${currentObjective.objectiveName} ${currentObjective.missionName ? `(Mission: ${strings.getRichTextObjFromString(currentObjective.missionName).value})` : ''}` : '');
    const [currentStanding, setCurrentStanding] = useState<any>(currentObjective.standing);
    const [currentHow, setCurrentHow] = useState<any>(currentObjective.outcomeNote);
    useEffect(() => {
        resetAllData()
    }, [currentObjective]);

    const resetAllData = (clean = false) => {
        setCurrentDetail('')
        setCurrentObjectiveName('');
        setCurrentStanding('');
        setCurrentHow('');

        if (!!currentObjective && !clean) {
            setCurrentDetail(currentObjective.planNote)
            setCurrentObjectiveName(currentObjective.objectiveName ? `${currentObjective.objectiveName} ${currentObjective.missionName ? `(Mission: ${strings.getRichTextObjFromString(currentObjective.missionName).value})` : ''}` : '');
            setCurrentStanding(currentObjective.standing);
            setCurrentHow(currentObjective.outcomeNote);
        }
    };

    return (
        <Grid container>
            <Grid item xs={1}>
                <FlexExactCenter>
                    <IconButton onClick={() => onDelete(currentObjective)}>
                        <Delete />
                    </IconButton>
                </FlexExactCenter>
            </Grid>
            <Grid item xs={5}>
                <BoxContainer margin="10px 0px">
                    <FormControl fullWidth>
                        <Autocomplete
                            disableClearable
                            options={objectivesData}
                            getOptionLabel={(option: any) => option.objectiveName ? `${strings.getRichTextObjFromString(option.objectiveName).value} (Mission: ${strings.getRichTextObjFromString(option.missionName).value})` : ''}
                            inputValue={strings.getRichTextObjFromString(currentObjectiveName).value}
                            onChange={(event: any, selectedOption: any) => handleChange(selectedOption.objectiveName, "objectiveName", index, true)}
                            className={classes.marginSpaceOne}
                            renderInput={(params) =>
                                <Tooltip
                                    title={strings.getRichTextObjFromString(currentObjectiveName).value}
                                >
                                    <TextField
                                        {...params}
                                        helperText={isError && isEmptyString(currentObjectiveName) && "This field should not be empty"}
                                        error={isError && isEmptyString(currentObjectiveName)}
                                        label="Plan Item"
                                    />
                                </Tooltip>
                            }
                        />
                        <RichTextField
                            disabled={isEmptyString(currentObjectiveName)}
                            label="Details"
                            error={isError && isEmptyString(currentDetail)}
                            helperText={isError && isEmptyString(currentDetail) && "This field should not be empty"}
                            multiline
                            maxRows={4}
                            value={currentDetail}
                            className={classes.marginSpaceOne}
                            setState={(val:any) => {
                                handleChange(val, "planNote", index, true)
                            }}
                        />
                    </FormControl>
                </BoxContainer>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
                <BoxContainer margin="10px 0px">
                    <FormControl fullWidth>
                        <TextField
                            disabled={isEmptyString(currentObjectiveName) || isEmptyString(currentDetail)}
                            select
                            label="Standing"
                            value={currentStanding}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value, "standing", index)}
                            className={classes.marginSpaceOne}
                        >
                            {MONTHLY_OBJECTIVE_STANDING_OPTIONS.map((option, index) => (
                                <MenuItem key={`${option}-${index}`} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <RichTextField
                            disabled={isEmptyString(currentStanding)}
                            label="How"
                            multiline
                            maxRows={4}
                            value={currentHow}
                            className={classes.marginSpaceOne}
                            error={isError && !isEmptyString(currentStanding) && isEmptyString(currentHow)}
                            helperText={isError && !isEmptyString(currentStanding) && isEmptyString(currentHow) && "This field should not be empty"}
                            setState={(val: any) => {
                                handleChange(val, "outcomeNote", index)
                            }}
                        />
                    </FormControl>
                </BoxContainer>
            </Grid>
        </Grid>
    )
};

const EditUpcomingMonth: React.FC<EditCurrentMonthProps> = ({ currentObjective, objectivesData, index, handleChange, onDelete, isError }) => {
    const classes: any = useStyles();
    const [upcomingDetail, setUpcomingDetail] = useState<any>(currentObjective.planNote);
    const [upcomingObjective, setUpcomingObjective] = useState<any>(currentObjective.objectiveName ? `${currentObjective.objectiveName} ${currentObjective.missionName ? `(Mission: ${strings.getRichTextObjFromString(currentObjective.missionName).value})` : ''}` : '');

    useEffect(() => {
        resetAllData()
    }, [currentObjective]);

    const resetAllData = (clean = false) => {
        setUpcomingDetail('');
        setUpcomingObjective('');

        if (!!currentObjective && !clean) {
            setUpcomingDetail(currentObjective.planNote);
            setUpcomingObjective(currentObjective.objectiveName ? `${currentObjective.objectiveName} ${currentObjective.missionName ? `(Mission: ${strings.getRichTextObjFromString(currentObjective.missionName).value})` : ''}` : '');
        }
    };

    return (
        <Grid container>
            <Grid item xs={1}>
                <FlexExactCenter>
                    <IconButton disabled={currentObjective.isContinued} onClick={() => onDelete(currentObjective)}>
                        <Delete />
                    </IconButton>
                </FlexExactCenter>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10}>
                <BoxContainer margin="10px 0px">
                    <DivContainer>
                        <FormControl fullWidth>
                            <Autocomplete
                                disabled={currentObjective.isContinued}
                                disableClearable
                                options={objectivesData}
                                getOptionLabel={(option: any) => option.objectiveName ? `${strings.getRichTextObjFromString(option.objectiveName).value} (Mission: ${strings.getRichTextObjFromString(option.missionName).value})` : ''}
                                inputValue={strings.getRichTextObjFromString(upcomingObjective).value}
                                onChange={(event: any, selectedOption: any) => handleChange(selectedOption.objectiveName, "objectiveName", index, true)}
                                className={classes.marginSpaceOne}
                                renderInput={(params) =>
                                    <Tooltip
                                        title={strings.getRichTextObjFromString(upcomingObjective).value}
                                    >
                                        <TextField
                                            {...params}
                                            helperText={isError && isEmptyString(upcomingObjective) && "This field should not be empty"}
                                            error={isError && isEmptyString(upcomingObjective)}
                                            label="Plan Item"
                                        />
                                    </Tooltip>
                                }
                            />
                        </FormControl>
                    </DivContainer>
                    <DivContainer>
                        <FormControl fullWidth>
                            <RichTextField
                                label="Details"
                                disabled={isEmptyString(upcomingObjective)}
                                multiline
                                maxRows={4}
                                value={upcomingDetail}
                                className={classes.marginSpaceOne}
                                error={isError && isEmptyString(upcomingDetail)}
                                helperText={isError && isEmptyString(upcomingDetail) && "This field should not be empty"}
                                setState={(val: any) => {
                                    handleChange(val, "planNote", index, true)
                                }}
                            />
                        </FormControl>
                    </DivContainer>
                </BoxContainer>
            </Grid>
        </Grid>
    )
};

const EditMonthlyObjective: React.FC<EditMonthlyObjectiveProps> = ({ clientMonthlyService, open, onCloseModal, hebMonthYear }) => {
    const globalScope: any = global;
    const baseClasses: any = useBaseStyles();
    const classes: any = useStyles();
    const emptyState = { value: '', error: '' };
    const [loading, setLoading] = useState<boolean>(false);
    const [changesHelper] = useUnsavedChangesWarn();
    const { setClean } = changesHelper;
    const { setDirty, isWindowDirty, wConfirm, hasChanges } = changesHelper;
    const [monthlyServiceDetail, setServiceMonthlyDetail] = useState<any>();
    const [timesheet, setTimesheet] = useState<any>({
        isApproved: false,
        hours: 0,
        minutes: 0,
        maxHours: 0,
        maxMinutes: 0,
    });
    const [objectiveList, setObjectiveList] = useState<any>([]);
    const [currentObjectives, setCurrentObjectives] = useState<clientMonthlyServiceObjectiveType[]>([]);
    const [upcomingObjectives, setUpcomingObjectives] = useState<clientMonthlyServiceObjectiveType[]>([]);
    const [isError, setIsError] = useState<boolean>(false)
    const clientName = useStoreState((state:any) => state.exploringName);
    const clientId = useStoreState((state:any) => state.exploringId);
    const {confirm} = useConfirm();
    const [timesheetFile, setTimesheetFile] = useState<any>({
        file: null,
        isNew: false
    });
    const userId = globalScope.account.userValue.id;

    const isMinutesValid = () => timesheet?.minutes ?
        (timesheet?.hours < timesheet?.maxHours ? timesheet?.minutes < 60 : timesheet?.minutes <= timesheet?.maxMinutes)
        : true
    const isHoursValid = () => timesheet?.hours ? (timesheet?.hours <= timesheet?.maxHours) : true

    useEffect(() => {
        open && loadMonthlyServiceDetail();
    }, [open]);

    const loadMonthlyServiceDetail = () => {
        setLoading(true);
        getClientMonthlyServiceDetail(clientMonthlyService?.clientServiceId, hebMonthYear).then((data: any) => {
            loadObjectives();
            setServiceMonthlyDetail(data);
            setTimesheet({
                ...data.timesheet,
                hours: Math.floor(data.timesheet.totalMinutes / 60),
                minutes: data.timesheet.totalMinutes % 60,
                maxHours: Math.floor(data.timesheet.maxTimeInMinutes / 60),
                maxMinutes: data.timesheet.maxTimeInMinutes % 60,
            });
            setTimesheetFile({ ...timesheetFile, file: data.timesheet?.file ?? null });
            setCurrentObjectives(data.currentMonth.objectives);
            setUpcomingObjectives(data.upcomingMonth?.objectives ?? []);
        });
    };

    const loadObjectives = () => {
        getClientAllMonthlyObjectives(clientMonthlyService?.clientServiceId).then((data: any) => {
            setLoading(false);
            setObjectiveList(data)
        });
    }

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>, reason: any) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        closeModal();
    };

    const closeModal = (reloadData = false) => {
        setTimesheetFile({
            file: null,
            isNew: false
        })
        setTimesheet({
            isApproved: false,
            hours: 0,
            minutes: 0,
            maxHours: 0,
            maxMinutes: 0,
        });
        onCloseModal(reloadData);
        setCurrentObjectives([]);
        setUpcomingObjectives([]);
        setLoading(false);
        setIsError(false);
    };

    const saveClientMonthlyObjectives = () => {
        const isValid = isObjectiveListValid([...currentObjectives, ...upcomingObjectives]) && isHoursValid() && isMinutesValid();
        if (!isValid) {
            setIsError(true);
            return;
        } else {
            setIsError(false);
        }

        let payload = {
            fileUpload: timesheetFile.isNew ? timesheetFile.file : null,
            monthLinkage: {
                ...monthlyServiceDetail,
                currentMonth: {
                    ...monthlyServiceDetail.currentMonth,
                    objectives: currentObjectives,
                },
                upcomingMonth: {
                    ...monthlyServiceDetail.upcomingMonth,
                    objectives: upcomingObjectives
                },
                timesheet: {
                    ...monthlyServiceDetail.timesheet,
                    totalMinutes: (parseInt(timesheet.hours) * 60) + parseInt(timesheet.minutes),
                }
            }
        }
        //setServiceMonthlyDetail(payload);
        setLoading(true);
        saveClientMonthlyServiceObjectives(payload, true).then((data) => {
            setLoading(false);
            closeModal(data);
            setClean();
            // TODO: Send Data back to save
        }).finally(() => {
            setLoading(false)
        });
    }

    const getNegativeId = (objectives: clientMonthlyServiceObjectiveType[]) => {
        let newItem = objectives.filter((obj: any) => obj.id < 0);
        return - newItem.length - 1;
    }

    const addNewObjective = (month: string) => {
        // TODO: we need to handle id now
        setIsError(false);
        switch (month) {
            case 'upcoming':
                setUpcomingObjectives(
                    [...upcomingObjectives, {
                        "id": getNegativeId(upcomingObjectives),
                        "objectiveId": -1,
                        "objectiveName": "",
                        "planNote": ""
                    }]
                );
                break;
            case 'current':
                setCurrentObjectives(
                    [...currentObjectives, {
                        "id": getNegativeId(currentObjectives),
                        "objectiveId": -1,
                        "objectiveName": "",
                        "outcomeNote": '',
                        "planNote": "",
                        "standing": ""
                    }]
                );
                break;
            default: return null
        }
    } ;

    const handleChangeUpcomingObjective = (value: any, field: string, index: number,) => {
        setIsError(false);
        !isWindowDirty && setDirty()
        let updateObjectives = [...upcomingObjectives];
        let targetObjective = updateObjectives[index];
        let objectiveId = targetObjective.objectiveId;
        if (field === 'objectiveName') {
            objectiveId = objectiveList.find((obj: clientMonthlyServiceObjectiveType) => obj.objectiveName === value)?.objectiveId
        }
        updateObjectives[index] = { ...targetObjective, objectiveId: objectiveId, [field]: value };
        setUpcomingObjectives(updateObjectives);

    };

    const handleChangeCurrentObjective = (value: any, field: string, index: number) => {
        setIsError(false);
        !isWindowDirty && setDirty() // ??
        let updateObjectives = [...currentObjectives];
        let targetObjective = updateObjectives[index];
        let objectiveId = targetObjective.objectiveId;
        if (field === 'objectiveName') {
            objectiveId = objectiveList.find((obj: clientMonthlyServiceObjectiveType) => obj.objectiveName === value)?.objectiveId
        }
        if (field === 'standing') {
            if (value.toLowerCase() === 'continuation') {
                setUpcomingObjectives([
                    ...upcomingObjectives,
                    {
                        "id": getNegativeId(currentObjectives),
                        "objectiveId": objectiveId,
                        "objectiveName": targetObjective.objectiveName,
                        "planNote": "",
                        isContinued: true,
                    }

                ])
            } else {
                setUpcomingObjectives(
                    upcomingObjectives.filter((obj: clientMonthlyServiceObjectiveType) => !obj.isContinued || obj.objectiveId !== objectiveId)
                )
            }
        }
        updateObjectives[index] = { ...targetObjective, objectiveId: objectiveId, [field]: value };
        setCurrentObjectives(updateObjectives);
    };

    // TODO: we need to optimize this to acheive best solution
    const isAnyFieldEmpty = (objectives: clientMonthlyServiceObjectiveType[], requiredFields: any = null) => {
        let isThereEmpty = false;
        if (requiredFields?.length) {
            requiredFields.map((field: string) => {
                objectives.map((obj: any) => {
                    if (obj[field] === "" || obj[field] === null) {
                        isThereEmpty = true;
                        return isThereEmpty
                    }
                })
            })
        } else {
            objectives.map((obj: clientMonthlyServiceObjectiveType) => {
                Object.values(obj).map((val: any) => {
                    if (val === null || (typeof (val) === 'string' && _.isEmpty(val))) {
                        isThereEmpty = true;
                        return isThereEmpty;
                    }
                })
            });
        }
        return isThereEmpty;
    };
    const isObjectiveListValid = (objectives: clientMonthlyServiceObjectiveType[]) => {
        let isValid = true;
        objectives.forEach((obj) => {
            if (!isObjectiveValid(obj))
                isValid = false;
        })
        return isValid;
    }
    const isObjectiveValid = (objective: clientMonthlyServiceObjectiveType): boolean => {
        let isValid = true;
        if (isEmptyString(objective.planNote ?? '') || isEmptyString(objective.objectiveName ?? ''))
            isValid = false;
        if (!isEmptyString(objective.standing ?? '') && isEmptyString(objective.outcomeNote ?? ''))
            isValid = false;
        return isValid;
    }

    const handleDeleteCurrentObjective = async (objective: clientMonthlyServiceObjectiveType) => {
        const isConfirmed = await confirm(true, [], '', 'objective', objective.id as number, 'delete');
        if (isConfirmed) {
            if (objective.standing?.toLowerCase() === 'continuation') {
                setUpcomingObjectives(upcomingObjectives.filter((obj: clientMonthlyServiceObjectiveType) => obj.objectiveId !== objective.objectiveId))
            }
            setCurrentObjectives(currentObjectives.filter((obj: clientMonthlyServiceObjectiveType) => obj.id !== objective.id))
        }
    }

    const handleDeleteUpcomingObjective = async (objective: clientMonthlyServiceObjectiveType) => {
        const isConfirmed = await confirm(true, [], '', 'objective', objective.id as number, 'delete');
        isConfirmed && setUpcomingObjectives(upcomingObjectives.filter((obj: clientMonthlyServiceObjectiveType) => obj.id !== objective.id))
    }

    const getFilterObjectiveList = (objectives: clientMonthlyServiceObjectiveType[], objectiveList: clientMonthlyServiceObjectiveType[]) => {
        let active = new Set(objectives.map(({ objectiveId }) => objectiveId));
        let filtered = objectiveList.filter(({ objectiveId }) => !active.has(objectiveId));
        return filtered;
    };

    const CompUploadFile = (comp_props: any) => {
        return (
            <UploadFile
                {...comp_props}
                table={"clients"}
                primaryId={clientId}
                file={timesheetFile.file}
                setFile={(file: any) => {
                    setTimesheetFile({file: {...file, fileBytes: file.file64Arr, createdBy_UserId: userId}, isNew: true })
                }}
            >
            </UploadFile>
        );
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            style={{ minHeight: '900px' }}
        >
            <AnimLoadingState loading={loading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                {clientName}
            </DialogTitle>
            <DialogContent>
                <DivContainer>
                    <FlexExactCenter>
                        <DivContainer>
                            <BoldTitle fontSize={14} bold={600}>Monthly Goals</BoldTitle>
                            <Text textAlign="center">{clientMonthlyService?.providerName}</Text>
                        </DivContainer>
                    </FlexExactCenter>
                </DivContainer>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <DivContainer margin="10px 0px">
                            <Grid container>
                                <Grid item xs={5.5}>
                                    <Text>Plan - Beginning of Month</Text>
                                </Grid>
                                <Grid item xs={1}/>
                                <Grid item xs={5.5}>
                                    <Text>Outcome - End of Month</Text>
                                </Grid>

                            </Grid>
                            <ScrollContainer maxHeight={600}>
                                {currentObjectives.length ?
                                    currentObjectives.map((obj: clientMonthlyServiceObjectiveType, index: number) => (
                                        <EditCurrentMonth
                                            isError={isError}
                                            currentObjective={obj}
                                            objectivesData={getFilterObjectiveList(currentObjectives, objectiveList)}
                                            index={index}
                                            handleChange={handleChangeCurrentObjective}
                                            onDelete={handleDeleteCurrentObjective}
                                        />
                                    )) :
                                    <BoxContainer>
                                        <FlexExactCenter minHeight={100}>
                                            <Typography className={baseClasses.no__data__typgoraphy}>
                                                No Goals Yet
                                            </Typography>
                                        </FlexExactCenter>
                                    </BoxContainer>

                                }
                            </ScrollContainer>
                        </DivContainer>
                        <Button disabled={isAnyFieldEmpty(currentObjectives, ["objectiveName", "planNote"])} onClick={() => addNewObjective("current")} fullWidth variant='contained' color="primary">
                            + Add New
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <FlexExactCenter>
                            <VerticalDivider />
                        </FlexExactCenter>
                    </Grid>
                    <Grid item xs={3}>
                        <DivContainer margin="10px 0px">
                            <Text>Upcoming Month - Plan</Text>
                            <ScrollContainer maxHeight={600}>
                                {upcomingObjectives.length ?
                                    upcomingObjectives.map((obj: clientMonthlyServiceObjectiveType, index: number) => (
                                        <EditUpcomingMonth
                                            isError={isError}
                                            currentObjective={obj}
                                            objectivesData={getFilterObjectiveList(upcomingObjectives, objectiveList)}
                                            index={index}
                                            handleChange={handleChangeUpcomingObjective}
                                            onDelete={handleDeleteUpcomingObjective}
                                        />
                                    )) :
                                    <BoxContainer>
                                        <FlexExactCenter minHeight={100}>
                                            <Typography className={baseClasses.no__data__typgoraphy}>
                                                No Goals Yet
                                            </Typography>
                                        </FlexExactCenter>
                                    </BoxContainer>
                                }
                            </ScrollContainer>
                        </DivContainer>
                        <Button disabled={isAnyFieldEmpty(upcomingObjectives, ["objectiveName", "planNote"])} onClick={() => addNewObjective("upcoming")} color="primary">
                            + Add New
                        </Button>
                    </Grid>
                    {monthlyServiceDetail?.timesheet?.isRequired &&
                        <>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                            <Grid item xs={8}>
                                <CompUploadFile
                                    label={"Timesheet"}
                                ></CompUploadFile>
                            </Grid>
                            <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        display={'inline'}
                                    >
                                        { 'Timesheet time logs: '}
                                    </Typography>
                                    <Typography
                                        fontSize={"12px"}
                                        display={'inline'}
                                    >
                                        {`(Max: ${timesheet.maxHours} hours and ${timesheet.maxMinutes} minutes.)`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        disabled={!timesheetFile.file}
                                        size={"small"}
                                        value={timesheet?.hours}
                                        label={"Hours"}
                                        type={"number"}
                                        onChange={(e) => {
                                            setTimesheet((prev: any) => {
                                                return {
                                                    ...prev,
                                                    hours: e.target.value ? e.target.value : 0,
                                                }
                                            });
                                            setServiceMonthlyDetail({
                                                ...monthlyServiceDetail,
                                                timesheet: {
                                                    ...monthlyServiceDetail.timesheet,
                                                    hours: e.target.value ? e.target.value : 0,
                                                }
                                            })
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={"Max hours: " + timesheet.maxHours ?? 0}
                                        error={!isHoursValid()}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        disabled={!timesheetFile.file}
                                    size={"small"}
                                    value={timesheet?.minutes}
                                    label={"Minutes"}
                                    type={"number"}
                                    onChange={(e) => {
                                        setTimesheet((prev: any) => {
                                            return {
                                                ...prev,
                                                minutes: e.target.value ? e.target.value : 0,
                                            }
                                        });
                                        setServiceMonthlyDetail({
                                            ...monthlyServiceDetail,
                                            timesheet: {
                                                ...monthlyServiceDetail.timesheet,
                                                minutes: e.target.value ? e.target.value : 0,
                                            }
                                        })
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                        }}
                                        helperText={"Max minutes: " + (timesheet.maxHours > timesheet.hours ? 59 : timesheet.maxMinutes)}
                                        error={!isMinutesValid()}
                                    />
                                </Grid>
                                </Grid>
                            
                        </Grid>

                            <Grid item xs={8}>
                                <FormControlLabel
                                    control={<Checkbox
                                    checked={timesheet?.isApproved}
                                        disabled={!timesheetFile.file}
                                        onChange={(e) => {
                                            setTimesheet((prev:any) => {
                                                return {
                                                    ...prev,
                                                    isApproved: e.target.checked
                                                }
                                            });
                                            setServiceMonthlyDetail({
                                                ...monthlyServiceDetail,
                                                timesheet: {
                                                    ...monthlyServiceDetail.timesheet,
                                                    isApproved: e.target.checked
                                                }
                                            })
                                        }} />}
                                    label="Were you able to approve the timesheet?"
                                    labelPlacement="start"
                                />
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={saveClientMonthlyObjectives} color="primary">
                    Save
                </Button>
                <Button onClick={(e) => handleClose(e, undefined)} color="primary">
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    )
};

export default EditMonthlyObjective;
