import React, { useState, useRef } from "react";
import { TextField } from "@mui/material";
import { memo } from "react";
import { useEffect } from "react";

//this wrapper is needed in some places where the text field loses focus every time user starts typing into it
export default memo(function TextFieldWrapper(props) {
    const [value, setValue] = useState(props.value);
    const textInput = useRef(null);
    const handleBlur = (e) => {
        //global.log('the on blur function ran...')
        if (typeof props.onBlur === "function") {
            props.onBlur(e)
        }
    };

    const handleChange = (e) => {
        //global.log('the on change function just ran away...')
        setValue(e.target.value)
        if (typeof props.onChange === "function") {
            props.onChange(e);
        }

    };
    //global.log('the text field function ran....')
    //useEffect(() => {global.log('the use effect in the text field wrapper ran');},[])
    return (
        <TextField
            {...props}
            ref={textInput}
            id={props.id}
            label={props.label != null ? props.label : ""}
            multiline={props.multiline == true ? true : false}
            rows={props.rows != null ? props.rows : 3}
            value={value}
            name={props.name}
            key={props.name}
            type={props.type ?? "text"}
            onChange={handleChange}
            onBlur={handleBlur}

            labelPlacement={props.labelPlacement}
            InputProps={props.InputProps}
        />
    );
})
