import {styled } from '@mui/system';

export const CalendarContainer: any = styled("div")((props: any) => ({
    padding: '0px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: props.width || '300px',
    height: props.height || '50px',
    border: `1px solid #d3d3d3`,
    borderRadius: '4px',
    position: 'relative'
}));
export const LeftArrow = styled('div')({
  cursor: 'pointer',
});

export const Label = styled('div')({
  position: 'absolute',
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.4375em',
  letterSpacing: '0.00938em',
  padding: 0

});

export const RightArrow = styled('div')({
    cursor: 'pointer',
});
export const CalendarContent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px'
});
export const Month = styled('div')({
  margin: '0px 10px'
});
export const Year = styled('div')({
  
});
