import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from "@mui/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ProgramData } from './data/ClientDetails';

const useRowStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        '& .MuiTableCell-root': {
            padding: '4px',
            fontSize: '12px'
        },
        '& .MuiTableCell-head': {
        }
    },
    header: {
        '& .MuiTableCell-root': {
            padding: '12px',
            color: '#FFFFFF',
        },
        backgroundColor: theme.palette.primary.main
    },
    tableProgram: {
        width: 'auto',
        '& .makeStyles-header-168 .MuiTableCell-root': {
            paddingLeft: "50px",
            paddingRight: "50px",
        },
        '& .MuiTableCell-body': {
            paddingLeft: "50px",
            paddingRight: "50px",
        }
    }
}));

function Row(props) {
    const { row, clientTabProgramData } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    const handleIconclickData = (data) => {
        props.onOpen();
        props.onEditData(data);
    };
    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {!_.isEmpty(row.programServices) && row.programServices[0].service}
                </TableCell>
                <TableCell>{row.enrolledOn && global._DateField(row.enrolledOn)}</TableCell>
                <TableCell>{row.status}</TableCell>
                {/* <TableCell>{row.me}</TableCell> */}
                {/* <TableCell>{row.care_manager}</TableCell> */}
                {/* <TableCell>{row.bcba}</TableCell> */}
                <TableCell>{row.isActive ? "1" : "0"}</TableCell>
                <TableCell>
                    <EditIcon onClick={() => handleIconclickData(row)} className="icon" />
                </TableCell>
            </TableRow>
            {!_.isEmpty(row.programServices) && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases" className={classes.tableProgram}>
                                    <TableHead className={classes.header}>
                                        <TableRow>
                                            <TableCell>Service</TableCell>
                                            <TableCell>Active Form</TableCell>
                                            <TableCell>Active To</TableCell>
                                            <TableCell>Units</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.programServices.map((item, index) => {
                                            global.log('row.programServices. ==', row.programServices);
                                            return (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        {item.service}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {global._DateField(item.createdOn)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {global._DateField(item.approvedOn)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {item.units}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        bcba: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

const rows = ProgramData;

export default function CollapsibleTable(props) {
    const classes = useRowStyles();
    const { rows, columns, clientTabProgramData, onEditData, onOpen } = props;
    global.log('clientTabProgramData =', clientTabProgramData);
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead className={classes.header}>
                    <TableRow>
                        <TableCell />
                        {columns && columns.map((item) => {
                            return (
                                <TableCell>{item.headerName}</TableCell>
                            );
                        })}
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!_.isEmpty(clientTabProgramData) && clientTabProgramData.map((row, it) => (
                        <Row key={it} row={row} onOpen={onOpen} onEditData={onEditData} />
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    );
}