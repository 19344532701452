import React, { memo } from 'react'
import { makeStyles } from "@mui/styles";
import {Menu} from '@mui/icons-material';
import {notificationStatus} from '../../constants';

const useStyles = makeStyles((theme) => ({
  baseStyle : {
    borderRadius: "5px",
    fontSize: "8px",
    textTransform: "capitalize",
    padding: "1px 4px",
    color: "#fff",
    outlined: "none",
    border: "none",
    fontWeight: "bold",
    margin: "0px 10px",

  },
  pending: {
    backgroundColor: "#808080",
  },
  progress: {
    backgroundColor: "#00b32d"
  },
  late: {
    backgroundColor: "#ff6633"
  },
  completed: {
    backgroundColor: "#1a8cff"
  }
}));
const NotificationStatus = ({status}) => {
  const classes = useStyles()
    
  return (
    <div className={
      `${classes.baseStyle} ${
        (status === notificationStatus.pending && classes.pending) || 
        (status === notificationStatus.progress && classes.progress) || 
        (status === notificationStatus.late && classes.late) || 
        (status === notificationStatus.completed && classes.completed)}

      }`
    }>
      {status}
    </div>
  )
}

export default memo(NotificationStatus);