import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: "5%",
    "& .MuiInputLabel-animated": {
      fontSize: "14px",
    },
  },
  
}));
