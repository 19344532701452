import React, { useState, useEffect } from "react";
import { authorization } from "../_services/authorization.service"
import { useStoreState, useStoreActions, action } from "easy-peasy";

const usePermission = (permissions, actionRoles, name) => {
    const { rolesCanDelete, rolesCanEdit, rolesCanRead, rolesCanCreate, rolesCanView } = permissions ? permissions : {};
    const [canView, setCanView] = React.useState(false);
    const [canRead, setCanRead] = React.useState(false);
    const [canCreate, setCanCreate] = React.useState(false);
    const [canEdit, setCanEdit] = React.useState(false);
    const [canDelete, setCanDelete] = React.useState(false);
    const [actions, setActions] = React.useState({});
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);

    const checkIfUserIsLoggedIn = () => {
        if (!authorization.userHasRole(rolesCanView)) {
            setTimeout(checkIfUserIsLoggedInAgain, 100);
        }
    }
    const checkIfUserIsLoggedInAgain = () => {
        if (!authorization.userHasRole(rolesCanView)) {
            setIsUserLoggedIn(false);
            setTimeout(checkIfUserIsLoggedInAgain, 100);
        } else {
            setIsUserLoggedIn(true);
        }
    }
    checkIfUserIsLoggedIn();
    /*  function setting actions based on a callback function
     * 
    const { actionPermissions } = actionRoles ? actionRoles.map((ar) => {
        // eventually might be used to return an object array for more details about the action permission
        ar.setCanPerform(authorization.userHasRole(ar.roles))
    }) : []; */

    const getActionPermissions = (obj) => {
        let actions = {};
        for (const [key, value] of Object.entries(obj)) {
            actions[key] = !Array.isArray(value) ? false : value.length < 1 ? true : authorization.userHasRole(value);
        }
        return actions;
    }

    const getCanRead = (pms) => pms && pms.rolesCanRead && authorization.userHasRole(pms.rolesCanRead) ? true : false;
    const getCanView = (pms) => {
        if (pms == undefined)
            return false;
        let result = pms && pms.rolesCanView && authorization.userHasRole(pms.rolesCanView) ? true : false;
        return result;
    }
    const getCanCreate = (pms) => pms && pms.rolesCanCreate && authorization.userHasRole(pms.rolesCanCreate) ? true : false;
    const getCanEdit = (pms) => pms && pms.rolesCanEdit && authorization.userHasRole(pms.rolesCanEdit) ? true : false;
    const getCanDelete = (pms) => pms && pms.rolesCanDelete && authorization.userHasRole(pms.rolesCanDelete) ? true : false;

    const refresh = () => {
        if (permissions) {
            setCanRead(rolesCanRead ? authorization.userHasRole(rolesCanRead) : false);
            setCanView(rolesCanView ? authorization.userHasRole(rolesCanView) : false);
            setCanCreate(rolesCanCreate ? authorization.userHasRole(rolesCanCreate) : false);
            setCanEdit(rolesCanEdit ? authorization.userHasRole(rolesCanEdit) : false);
            setCanDelete(rolesCanDelete ? authorization.userHasRole(rolesCanDelete) : false);
        }
        if (permissions?.actions) {
            setActions(permissions.actions ? getActionPermissions(permissions.actions) : {})
        }
        //setCanRead(rolesCanRead ? true : false);
        //setCanView(rolesCanView ? true : false);
        //setCanCreate(rolesCanCreate ? true : false);
        //setCanEdit(rolesCanEdit ? true : false);
        //setCanDelete(rolesCanDelete ? true : false);
        //global.log(name);
        //global.log(userRoles);
        //global.log(rolesCanRead)
        //global.log(rolesCanView)
        //global.log(rolesCanCreate)
        //global.log(rolesCanEdit)
        //global.log(rolesCanDelete)
    }

    useEffect(() => {
        refresh();
    }, [rolesCanDelete, rolesCanEdit, rolesCanRead, rolesCanCreate, rolesCanView, isUserLoggedIn, actionRoles]);

    const permissionsHelper = {
        canView, canRead, canCreate, canEdit, canDelete, actions, check: { canRead: getCanRead, canView: getCanView, canCreate: getCanCreate, canEdit: getCanEdit, canDelete: getCanDelete}
    }

    return permissionsHelper;
};

export default usePermission;