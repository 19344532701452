import { makeStyles } from "@mui/styles";

export default makeStyles((theme: any) => ({
    tabError: {
        '&.MuiTab-root': {
            color: 'red',
        },
        '&.Mui-selected':{
            color: 'red !important',
        },
        
    }
}))