import React, { useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    FormControlLabel,
    Checkbox,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { isValidEmail } from "../../../../utils/CommonServices";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
import _ from "lodash";

/***
 * props-variable: isOpenModal:boolean, editData: JSON object, ctlType:"create" or "edit"
 * props-function: onCloseModal, onSaveData
 */
export default function AddProgress(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [rows, setRow] = React.useState([]);
    const [loader, setLoader] = React.useState(true);

    const [note, setNote] = React.useState(null);

    // Errors selection
    const [notePerError, setNotePerError] = React.useState("");
    const [id, setId] = React.useState(null);

    useEffect(() => {
        // if (id) {
        //   setLoaderDialog(true);
        // } else {
        resetAllData();
        // }
    }, [props.isOpenModal, props.editData]);

    function resetAllData() {
        setNote("");
        setNotePerError(null);
        if (props.ctlType == "create") return;
        if (!_.isEmpty(props.editData)) {
            const edit = props.editData;
            setNote(edit.name);
        }
    }

    const saveProgress = () => {
        const isValid = formValidationPersonalDetails();
        if (isValid) {
            const saveProgressPayload = {
                note,
            };
            props.onSaveData(note);
        }
    };

    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (note === "") {
            isValid = false;
            setNotePerError("Please enter a valid note");
        } else {
            setNotePerError("");
        }
        return isValid;
    };

    const handleClose = () => {
        props.onCloseModal();
        resetAllData();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpenModal}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                New Folder
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    className={baseClasses.grid}
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="my-input">Name</InputLabel>
                                <Input
                                    type="text"
                                    value={note}
                                    id="my-input"
                                    onChange={(event) => {
                                        if (!_.isEmpty(event.target.value)) {
                                            setNotePerError("");
                                        }
                                        setNote(event.target.value);
                                    }}
                                    aria-describedby="my-helper-text"
                                />
                                {notePerError == "" ? null : (
                                    <FormHelperText className={baseClasses.error} id="my-helper-text">
                                        {notePerError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={saveProgress} color="primary">
                    Save
                </Button>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
