import React from 'react';
import usePermission from "../../hooks/usePermission";

const ProtectedComponent = (props) => {
    const permissions = usePermission(props.permissions, undefined, undefined, props.name);
    const canEdit = props.canEdit || permissions.canEdit;
    const canView = permissions.canView;
    //const canView, canEdit = false;
    React.useEffect(() => {
        //global.log(canEdit);
        //global.log(canView);
    }, [canView, canEdit]);

    const [enable, setEnable] = React.useState(false);
    React.useEffect(() => {
        setEnable(props.enableIfCanView && canView);
    }, [canView, props.enableIfCanView]);

    return (
        <>
            {(canView || props.allow) &&
                (
                    props.inline ?
                        <span className={props.className} style={{ pointerEvents: enable || canEdit ? "auto" : "none", opacity: enable || canEdit ? 1 : 0.6 }}>
                            {props.children ? props.children : props.element ? props.element : null}
                        </span> :
                        <div className={props.className} style={{ pointerEvents: enable || canEdit ? "auto" : "none", opacity: enable || canEdit ? 1 : 0.6 }}>
                            {props.children ? props.children : props.element ? props.element : null}
                        </div>
                )
            }
        </>
    );
}

export default ProtectedComponent