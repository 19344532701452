
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    gridColumn: {
        paddingRight: '10px',
    },
    mainPaddingSection: {
        padding: '10px'
    },
    colorDel: {
        color: 'red !important'
    }
});

export default useStyles