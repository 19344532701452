import React, { useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';
function useEllipsisActive(ref:any) {
    const [isEllipsis, setEllipsis] = useState(false);
    const [width, height] = useWindowSize();
    let ellipses ;
  useEffect(() => {
      if (ref?.current){
          let elem = window.getComputedStyle(ref?.current, null);
          ellipses  = elem.getPropertyValue("text-overflow");
          setEllipsis(ellipses === 'ellipsis');
      }
  }, [width, height]);
  return isEllipsis;
}
export default useEllipsisActive;