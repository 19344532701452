import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: "12px 0px 12px 0px",
    margin: "12px 26px",
    "& .MuiCardContent-root:last-child": {
        padding: "0px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      cursor: "pointer",
      fontSize: "12px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-root": {
      border: "0px",
    },
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
 
}));
