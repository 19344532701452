import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import { theme } from "../../../../../assets/theme/theme";


export const HourHeaderContainer: any = styled("div")((props:any) => ({
    display: props.display || 'block',
    width: '100%',
    background: props.background || 'unset',
    
}));
export const Gap: any = styled("div")((props:any) => ({
    display: 'block',
    minWidth: props.width || '0px',
}));

export const SlotLabel:any = styled("div")((props:any) => ({
    width: props.width,
    display: 'flex',
    borderRadius: 15,
    color: '#fff',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

}));
export default makeStyles((theme: any) => ({
    
}))
