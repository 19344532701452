import React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import TTextField from './TTextField';
import TTextFieldExpiredDateCreditCard from './TTextFieldExpiredDateCreditCard';
import TTextFieldCreditCard from './TTextFieldCreditCard';
import TTextFieldNumber from './TTextFieldNumber';


const ProgramLightCreditCard = (props) => {

    const {onChangeCreditCard, creditCardValue, setCreditCardValue} = props;

    const onChangeCardNumberHandler = (event) => {
        setCreditCardValue((prevState) => {
            let result = {
              ...prevState,
              ccNumber: event.target.value
              }
              onChangeCreditCardInfoHandler(result);
            return result;
        });
        
    }

    const onChangeCVVHandler = (event) => {
        setCreditCardValue((prevState) => {
          let result = {
            ...prevState,
            cvv: event.target.value
            }
            onChangeCreditCardInfoHandler(result);
          return result;          
        });
    }

    const onChangeExpiredDateHandler = (event) => {
        setCreditCardValue((prevState) => {
          let result = {
            ...prevState,
            cardExpiredDate: event.target.value
            }
            onChangeCreditCardInfoHandler(result);
          return result;
        });
    }
    const onChangeBillingZipHandler = (event) => {
        setCreditCardValue((prevState) => {
            let result = {
                ...prevState,
                billingZip: event.target.value
            }
            onChangeCreditCardInfoHandler(result);
            return result;
        });
    }
    const onChangeNameOnCardHandler = (event) => {
        var value = event.target.value;

        setCreditCardValue((prevState) => {
            let result = {
                ...prevState,
                nameOnCard: value
            }
            onChangeCreditCardInfoHandler(result);
            return result;
        });
    }

    const onChangeCreditCardInfoHandler = (value) => {
      onChangeCreditCard(value);
    }

  return (
    <>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <TTextFieldCreditCard
            label={"Number on Card"}
            fullWidth
            name="cnum"
            defaultValue={creditCardValue.ccNumber}
            value={creditCardValue.ccNumber}
            onChange={onChangeCardNumberHandler}
          />
        </Grid>
        {/*<Grid*/}
        {/*  container*/}
        {/*  direction="row"*/}
        {/*  xs={2}*/}
        {/*  justify="flex-end"*/}
        {/*  alignItems="center"*/}
        {/*>*/}
        <Grid item xs={6}>
          <TTextFieldExpiredDateCreditCard
            label="Exp date"
            name="cex"
            fullWidth
            defaultValue={creditCardValue.cardExpiredDate}
            value={creditCardValue.cardExpiredDate}
            onChange={onChangeExpiredDateHandler}
          />
        {/*</Grid>*/}
          
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={4}>
            <TTextFieldNumber
              label={"Security Code"}
              name="cvv"
              inputProps={{
                maxLength: 4,
              }}
              defaultValue={creditCardValue.cvv}
              value={creditCardValue.cvv}
              onChange={onChangeCVVHandler}
            />
          </Grid>
        <Grid item xs={4}>
            <TTextFieldNumber
                label={"Billing Zip Code"}
                name="billingZip"
                inputProps={{
                    maxLength: 5,
                }}
                defaultValue={creditCardValue.billingZip}
                value={creditCardValue.billingZip}
                onChange={onChangeBillingZipHandler}
            />
        </Grid>
        <Grid item xs={4}>
            <TTextField
                label={"Name on Card"}
                name="nameOnCard"
                
                defaultValue={creditCardValue.nameOnCard}
                value={creditCardValue.nameOnCard}
                onChange={onChangeNameOnCardHandler}
            />
        </Grid>

      </Grid>
    </>
  );
};

export default ProgramLightCreditCard;
