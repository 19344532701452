import { action, thunk, computed } from "easy-peasy";
import employees from "../api/employees";
import * as constant from "../constants";

const detail = {
    "contactId": 0,
    "contact": {
        "firstName": "",
        "lastName": "",
        "firstNameHebrew": "",
        "lastNameHebrew": "",
        "firstNameLegal": "",
        "lastNameLegal": "",
        "middleNameLegal": "",
        "address1": "",
        "address2": "",
        "city": "",
        "state": "",
        "zip": "",
        "phone1": "",
        "phone2": "",
        "email1": "",
        "email2": "",
        "dob": null,
        "age": 0,
        "ssn": null,
        "id": 0,
        "neighborhood": "",
    },
    "isEmployee": false,
    "isProfessional": false,
    "isLegal": false,
    "isYungerman": false,
    "isActive": true,
    "positionId": null,
    "position": null,
    "rate": 0.00,
    "professionalType": null,
    "yearOfBirth": null,
    "yearsOfExperienceWithBucherim": null,
    "rateNote": null,
    "prevYeshivas": null,
    "experience": null,
    "note": null,
    "lHisalosApprovalStatus": null,
    "parsonageLimit": null,
    "financialNotes": null,
    "planningToEnrollComfortHealth": null,
    "hasComfortHealthApplication": null,
    "hasBA": null,
    "hasID": null,
    "hasHighSchoolDiploma": null,
    "sentToComfortHealthOn": null,
    "fingerprintedOn": null,
    "comfortHealthApprovedOn": null,
    "catsApprovedOn": null,
    "amountCouldWork": null,
    "specialRequirements": null,
    "amountBilling": null,
    "isInTheMountainsSummer": null,
    "summerColony_ContactId": null,
    "summerColonyContact": {
        "firstName": "",
        "lastName": "",
        "firstNameHebrew": "",
        "lastNameHebrew": "",
        "firstNameLegal": "",
        "lastNameLegal": "",
        "middleNameLegal": "",
        "address1": "",
        "address2": "",
        "city": "",
        "state": "",
        "zip": "",
        "phone1": "",
        "phone2": "",
        "email1": "",
        "email2": "",
        "dob": null,
        "age": 0,
        "ssn": null,
        "id": 0,
    },
    "specialties": null,
    "paymentOptions": "",
    "credentials": null,
    "isExternal": true,
    "hasLicense": null,
    "hasCar": null,
    "HasCarAllYear": null,
    "LevelOfEducation": null,
    "RoshMaamid": null,
    "RoshMaamidId": null,
    "RoshMaamidNew": null,
    "id": 0,
};

const defaultStore = {
    detail: detail,
    initDetail: action((state, payload) => {
        state.detail = detail;
    }),
    mamidimPage: 0,
    setMamidimPage: action((state, page) => {
        state.mamidimPage = page;
    }),
    professionalPage: 0,
    setProfessionalPage: action((state, page) => {
        state.professionalPage = page;
    }),
    allPage: 0,
    setAllPage: action((state, page) => {
        state.allPage = page;
    }),

    queryAll: "",
    setQueryAll: action((state, data) => {
        state.queryAll = data;
    }),
    queryProfessional: "",
    setQueryProfessional: action((state, data) => {
        state.queryProfessional = data;
    }),
    queryMamidim: "",
    setQueryMamidim: action((state, data) => {
        state.queryMamidim = data;
    }),
    queryApplications: "",
    setQueryApplications: action((state, data) => {
        state.queryMamidim = data;
    }),
    setDetail: action((state, payload) => {
        state.detail = payload;
    }),
    saveDetail: thunk((actions, payload) => {
        if (payload == null) {
            global.AjaxFailToast();
            return;
        }
    }),
    isEditing: false,
    setIsEditing: action((state, payload) => {
        state.isEditing = payload;
    }),
    employees: [],
    updateEmployees: action((state, employees) => {
        state.employees = employees;
        state.employeesListFiltered = employees
    }),
    employeesListFiltered: [],
    setEmployeesListFiltered: action((state, employees) => {
        state.employeesListFiltered = employees;
    }),
    fetchEmployees: thunk(async actions => {
        let items = await employees();
        actions.updateEmployees(items);
        return items
    }),
    schedules: [],
    setSchedules: action((state, payload) => {
        state.schedules = payload;
    }),
    scheduleRowDaily: null,
    setScheduleRowDaily: action((state, payload) => {
        state.scheduleRowDaily = payload;
    }),
    scheduleRowProfessional: null,
    setScheduleRowProfessional: action((state, payload) => {
        state.scheduleRowProfessional = payload;
    }),
    scheduleRowAppointment: null,
    setScheduleRowAppointment: action((state, payload) => {
        state.scheduleRowAppointment = payload;
    }),
    sectionChanged1: false,
    setSectionChanged1: action((state, payload) => {
        state.sectionChanged1 = payload;
    }),
    sectionState1: "read",
    setSectionState1: action((state, payload) => {
        state.sectionState1 = payload;
    }),
    sectionState2: "read",
    setSectionState2: action((state, payload) => {
        state.sectionState2 = payload;
    }),
    sectionState3: "read",
    setSectionState3: action((state, payload) => {
        state.sectionState3 = payload;
    }),
    sectionState4: "read",
    setSectionState4: action((state, payload) => {
        state.sectionState4 = payload;
    }),
    sectionState5: "read",
    setSectionState5: action((state, payload) => {
        state.sectionState5 = payload;
    }),
    firstNameError: "",
    setFirstNameError: action((state, payload) => {
        state.firstNameError = payload;
    }),
    secondNameError: "",
    setSecondNameError: action((state, payload) => {
        state.secondNameError = payload;
    }),
};
export default defaultStore;
