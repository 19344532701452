import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import ClearIcon from '@mui/icons-material/Clear';
import useBaseStyles from './style';
import {
    ReactJewishDatePicker,
    BasicJewishDate
} from "react-jewish-datepicker";
import { IconButton } from '@mui/material';

type HebDatePickerType = {
    value: Date | BasicJewishDate | any,
    label: string,
    error?: boolean,
    enableClear?: boolean,
    helperText: string,
    isHebrew?: boolean,
    onChange: Function,
    onClear: Function
}

const HebDatePicker: React.FC<HebDatePickerType> = ({ value, onChange, label, error, helperText, isHebrew, onClear, enableClear }) => {
    const classes: any = useBaseStyles();
    const [dateVal, setDateVal] = useState(value);

    useEffect(() => {
        setDateVal(value || undefined);
    }, [value, label]);

    return (
        <div className={clsx(classes.hebDateContainer, {
            [classes.hebErrorState]: error,
            [classes.emptyHebDate]: !(!!value) && enableClear
        })}>
            <div>
                <div className={clsx(classes.font14, classes.colorLabel, {
                    [classes.error]: error
                })}>{label}</div>
                {enableClear && <IconButton sx={{ position: 'absolute' }} onClick={(e) => {
                    e.stopPropagation();
                    onClear()
                }} className={classes.customClearButton}>
                    <ClearIcon fontSize='small' />
                </IconButton>
                }
                <ReactJewishDatePicker
                    value={dateVal}
                    isHebrew={isHebrew}
                    onClick={(val: any) => onChange(val.date)}
                />
                <div className={clsx(classes.error, classes.font11)}>{helperText}</div>
            </div>
        </div>
    )
}

export default HebDatePicker