import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import table from './components/table';
import tabs from './components/tabs';
import cards from './components/cards';
import forms from './components/forms';
import buttons from './components/buttons';
import { theme } from "../theme/theme";

export const DivContainer = styled("div")({
    display: 'block'
});


export const ColumnHeader = styled("div")((props) =>({
    display: 'flex',
    backgroundColor: props.bgColor || theme.palette.primary.main,
    color: props.color || '#fff'
}));

export const outlinedInput = {
    '& .MuiOutlinedInput-input': {
        padding: '10px 10px',  
    },
}

export const ColumnItem = styled("div")((props) => ({
    padding: '5px',
    textAlign: 'start',
    width: props.width
}));

export default makeStyles((theme) => ({
    ...table(theme),
    ...buttons(theme),
    ...cards(theme),
    ...forms(theme),
    ...tabs(theme),
    p10: {
        padding: '10px'
    },
    chipLabel: {
        fontWeight:"400",
        backgroundColor: 'rgb(241, 247, 253) !important',
        color: '#1976d2 !important',
        border: "1px solid transparent !important",
        
    },
    activeChip: {
        border: "1px solid #1976d2 !important",
    },
    font14: {
        fontSize: '14px'
    },
    marginTop8: {
        marginTop: '8px'
    },
    hideMultiline: {
        "& .MuiOutlinedInput-root": {
            height: '37px'
        },
        "& .MuiAutocomplete-input": {
            display: 'none'
        }
    },

    appBarWhite: {
        "& .MuiTabs-flexContainer": {
            backgroundColor: '#fff !important'
        }
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mr10: {
        margin: '10px !important'
    },
    mr5: {
        margin: '5px !important'
    },
    mAuto: {
        margin: 'auto'
    },
    selection: {
        margin: "0px",
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    title_2: {
        flex: 1,
        fontSize: "18px",
        padding: "16px",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    row: {
        display: "flex",
        width: "100%",
        marginTop: "4px",
    },
    error: {
        color: "red",
    },
    errorWrapper: {
        marginBottom: "10px",
        marginTop: "10px",
    },
    root: {
        flex: 1,
        marginTop: "5%",
        "& .MuiInputLabel-animated": {
            fontSize: "14px",
        },
    },
    rootFlex: {
        flexGrow: 1,
        width: '100%',
    },
    header_1: {
        display: "flex",
        padding: "18px",
    },
    uploadText: {
        marginTop: 6,
    },
    colorGreen: {
        color: "green",
    },
    full_width: {
        width: "100%",
    },
    title: {
        marginTop: theme.spacing(2),
    },
    panelContent: {
        width: "70%",
        margin: "0 auto",
    },
    paddingTop16: {
        paddingTop: "16px",
    },
    paddingTop24: {
        paddingTop: "24px",
    },
    grid: {
        padding: "12px",
        fontSize: "14px",
    },
    save: {
        float: "right",
        padding: "8px 0px 0px 0px",
    },
    centerText: {
        textAlign: "center",
        marginTop: "5%"
    },
    download: {
        textAlign: "right",
    },

    loading: {
        height: 400,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    rowFlex: {
        display: "flex",
    },
    subTabs: {
        ".MuiTabs": {
            backgroundColor: "#FFF",
            border: "1px gray",
        }
    },
    tableWidth: {
        minWidth: 700,
    },

    tableContentMargin: {
        marginTop: "24px",
    },
    grow: {
        flex: 1
    },
    progress: {
        width: "30%",
        marginLeft: "16px",
    },
    appbarColor: {
        backgroundColor: `${theme.palette.primary.main} !important`
    },
    subTab: {
        backgroundColor: `${theme.palette.background.front} !important`
    },
    completed: {
        width: "100%",
        textAlign: "center",
    },
    client_name: {
        marginBottom: "16px",
        fontSize: "18px",
        fontWeight: 'bold',
    },

    searchIconFont: {
        width: "20px",
        height: "17px",
    },
    progress: {
        width: '30%',
        marginLeft: '16px',
    },

    progress_title: {
        fontSize: "13px",
    },
    progress_description: {
        fontSize: "10px",
        fontWeight: "bold",
    },
    progress_time: {
        fontSize: "10px",
        color: 'gray',
        marginRight: "12px",
    },
    viewAllTxt: {
        fontSize: "13px",
        cursor: "pointer",
    },
    row_time: {
        display: 'inline-flex',
        width: '100%',
        marginTop: "4px",
    },
    accordion: {
        borderRadius: "0px !important",
    },
    fullWidth: {
        width: "100%",
    },
    /* accordion style */
    schedule_title: {
        fontSize: "0.867rem",
        fontWeight: 400,
        paddingLeft: 5,
        paddingBottom: 7
    },
    heading: {
        fontWeight: "bold",
        fontSize: "12px",
        flexShrink: 0,
        paddingLeft: 20
    },
    schedule_text: {
        fontSize: "12px",
        paddingLeft: 20
    },
    schedule_time: {
        fontSize: "12px",
        textAlign: "center",
    },
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    accordion_detail_root: {
        padding: '4px 0px 0px 16px !important',
    },
    accordionItemLeftPadding: {
        paddingLeft: 34,
    },
    mrtop12: {
        marginTop: "12px",
    },
    mrTop24: {
        marginTop: "24px",
    },
    
    fns12: {
        fontSize: "12px !important",
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    column: {
        flex: 1,
        fontSize: "12px",
        paddingLeft: 20
    },
    description: {
        display: 'flex',
        width: '100%',
        margin: "4px 0px",
    },
    header: {
        fontWeight: 'bold',
        display: 'flex',
        width: '100%',
        marginBottom: "12px"
    },
    todo_label: {
        marginRight: "0px",
        marginBottom: "0px !important",
        '& span': {
            fontSize: '12px',
        },
    },
    CheckBoxFlexGrow: {
        flex: 1,
        lineHeight: "38px",
    },
    CheckBoxRow: {
        display: "flex",
        width: "100%",
    },
    row_todo: {
        width: "100%",
        display: 'flex',
        marginTop: "4px",
        background: "#dedede",
        padding: "0px 12px",
        borderRadius: "4px",
    },
    todo_background: {
        background: "#f3f3f3",
    },

    document_upload: {
        display: "flex",
        marginTop: 6,
    },
    capitalized: {
        textTransform: 'capitalize',
        backgroundColor: theme.palette.primary.main,
    },

    col_md_8: {
        flex: 2.5,
        padding: "8px",
    },
    col_md_4: {
        flex: 1.5,
        padding: "8px",
    },
    col_md_3: {
        flex: 1,
        padding: '8px'
    },
    history_title: {
        padding: "8px",
        fontSize: "14px",
    },
    dob: {
        fontSize: '12px',
        padding: '12px',
    },

    divider: {
        height: "24px",
        background: '#ffffff8c',
        margin: '6px 8px'
    },

    dialogTitle: {
        padding: '16px 24px 0px',
    },
    separate_div: {
        height: "20px",
        //background: theme.palette.primary.main,
        border: "1px solid transparent" //#1976d2",
    },

    ServicesList: {
        display: "flex",
        flexDirection: "row",
    },
    marginSpaceOne: {
        // margin: theme.spacing(1),
        margin: "2px 0px 2px 0px",
    },
    leftContentSection: {
        width: "69%",
    },
    fixHeight: {
        height: "60vh",
        marginBottom: "10px",
    },
    overflowAuto: {
        overflow: "auto"
    },
    marginB10: {
        marginBottom: "10px"
    },
    dFlex: {
        display: "flex"
    },
    drawerOpen: {
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarShift: {
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${240}px)`,
            marginLeft: 240,
        },
        [theme.breakpoints.down('lg')]: {
            width: `calc(100% - ${140}px)`,
            marginLeft: 140,
        },
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarShiftFullWidth: {

        marginLeft: 240,
        width: '100%',
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: 0,
        [theme.breakpoints.up("sm")]: {
            width: 0,
        },
    },
    accordion: {
        borderRadius: "0px !important",
    },
    accordionSummary: {
        minHeight: "18px !important",
        padding: 0,
        height: 23,
        display: "inline-flex",
    },
    accordionItemLeftPadding: {
        paddingLeft: 34
    },
    no__data__typgoraphy: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    no_data: {
        position: 'relative',
        marginTop: '20%',
        marginBottom: '20%',
        textAlign: 'center',
    },
    h100: {
        height: '100%'
    }
}));