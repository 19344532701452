import  * as constant from '../constants';
const globalScope: any = global;
export const getDashboardSchedules = (id:number) => {
    let globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.CLIENT_SCHEDULE_GET_LIST_BY_USER + id + '?scheduleStatus=seeking,onhold,financial,rejected')
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getDashboardActiveSchedules = () => {
    let globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.DASHBOARD_ACTIVE_SCHEDULES)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getDashboardCards = () => {
    let globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.DASHBOARD_CARDS)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getDashboardStatusStepDuration = () => {
    let globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.DASHBOARD_STATUS_STEP_DURATION_AVG)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getStatusStepDuration = () => {
    let globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.STATUS_STEP_DURATION)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getDashboardPaymentTypeSchedules = (paymentType: string, onGoingStatus: string) => {
    let globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.DASHBOARD_PAYMENT_TYPE_SCHEDULE + `paymentType=${paymentType}&onGoingStatus=${onGoingStatus}`)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getDashboardActiveClients = (clientWith: string) => {
    let globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.DASHBOARD_ACTIVE_CLIENT_SERVICE + clientWith)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getDashboardClientScheduleStatusList = () => {
    const globalScope: any = global;
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.DASHBOARD_CLIENT_SCHEDULE_STATUS)
            .then((data:any) => {
                if(!!data) {
                    const saveResult = data.map((item: any) => ({
                        ...item,
                        meargen: item.meargenName
                    }))
                    resolve(saveResult);
                }   
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}
