import React from "react";
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import useStyles from '../styles'
import Loader from "../../../common/Loader";
export default function DeleteModel(props) {
    const { del, handleDelete, handleDeleteClose, slcRow, loading } = props;
    const classes = useStyles();
    return (
        <Dialog onClose={handleDeleteClose} aria-labelledby="simple-dialog-title" open={del}  maxWidth="xs" fullWidth={true}>
            <Loader loading={loading} height="13%" width="13%" />
            <DialogContent>
                <h6 >Delete Schedule?</h6>
                <p className={classes.textDelete}>This will delete the schedule with all its previous sessions in history from the system.</p>
                <p className={classes.textDelete}>If you just want to end this schedule for future sessions from now on, select the 'End Schedule' option instead.</p>
            </DialogContent>
            <DialogActions>
                <Button className={classes.btnCancel} onClick={(event) => { handleDeleteClose(event, "cancel") }}>Cancel</Button>

                <Button className= {classes.btnDelete} onClick={() => { handleDelete(slcRow) }}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}