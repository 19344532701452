import { BASE_URL, HTTP_EXCEPTION } from "../constants";
import { accountService } from '../_services/account.service';
import { tryParseJSONObject } from '../_helpers/json';
import {base64ToBlob} from './file'
import { APP_VERSION } from '../constants';
import { toast } from "react-toastify";

export const fetchWrapper = {
    inProgress,
    get,
    post,
    put,
    delete: _delete,
    downloadFile,
    readFileInNewTab
}
global.fetchWrapper = fetchWrapper;

var inProgress = false;

function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(url)
    };
    inProgress = true;
    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    inProgress = true;
    return fetch(url, requestOptions).then((res) => handleResponse(res, body));
}

function put(url, body) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    inProgress = true;                                                           
    return fetch(url, requestOptions).then((res) => handleResponse(res, body));    
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(url)
    };
    inProgress = true;
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions
function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = accountService.userValue;
    const isLoggedIn = user && user.jwtToken;
    const isApiUrl = url.startsWith(BASE_URL);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${user.jwtToken}`, 'app-version':  APP_VERSION };
    } else {
        return {};
    }
}

function handleResponse(response, body=null) {
    return response.text().then(text => {
        // warning validation checker
        //if (response.status == 500) {
        //    global.AjaxWarnToast("500");
        //    console.log(response);
        //    inProgress = false;
        //    return Promise.reject(error);
        //}
        const defaultData = {
            message: "No data recieved",
            responseText: text
        };
        if (!text)
            global.AjaxWarnToast("data not available - " + text);
        let triedParseText = tryParseJSONObject(text);
        if (!triedParseText && triedParseText != "true" && triedParseText != "")
            global.AjaxWarnToast("data issue reported.");
        // process response handler
        const data = !triedParseText ? defaultData : text && JSON.parse(text);
        inProgress = false;
        if (!response.ok) {
            const error = (data && data.responseText) || (data && data.message) || response.status || response.statusText || response.responseCode;
             handleException(response.url, response.status, JSON.stringify(body), JSON.stringify(data), "")
            //NOTE: removed because of complains that it happens in middle of the day, see https://app.asana.com/0/1202037761309044/1203201810903743/f
            //if (([401].includes(response.status) || [401].includes(response.responseCode)) && accountService.userValue) {
            //    // auto logout if 401 Unauthorized response returned from api
            //    accountService.logout("logbackin-popup");
            //} else {
                global.AjaxFailToast(error);
            //}
            return Promise.reject(error);
        }
        return data;
    });
}

function handleException (url, responseCode, request, response, appState)  {
    const body = {
        "url": url,
        "responseCode":responseCode,
        "requestJson": request ?? "",
        "responseJson":response ?? "",
        "appStateJson": appState ?? "",
        "clientAppVersion": APP_VERSION
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    inProgress = true;
    fetch(HTTP_EXCEPTION, requestOptions).then((res) => {
        
    });
}

function downloadFile(fileId, fileName, errorCallback) {
    let url = BASE_URL + "api/CMS/Documents/Download/" + fileId;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        credentials: 'include',
        responseType: "blob", // important
    };
    inProgress = true;
    return fetch(url, requestOptions).then(handleResponse).then((response => {
        //const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = 'data:application/octet-stream;base64,' + response.fileBytes;
        link.setAttribute("download", response.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }))
};

function readFileInNewTab (fileId) {
    let url = BASE_URL + "api/CMS/Documents/Download/" + fileId;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/pdf', ...authHeader(url) },
        credentials: 'include',
        responseType: "blob", // important
    };
    inProgress = true;
    return fetch (url, requestOptions).then(handleResponse)
        .then((response)=> {
            const blob = base64ToBlob(response.fileBytes, 'application/pdf' );
            const url = URL.createObjectURL( blob );
            const pdfWindow = window.open("");
            pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
        })
        .catch((error) => {
        });
} 
