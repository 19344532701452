import React from 'react';
import { Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import Logo from '../../../../components/common/LogoSvg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100vh',
        paddingTop: '20px',
    },
    customBar: {
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        width: '700px',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: theme.spacing(2),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
    },
    logo_img: {
        height: 40,
        width: 'auto',
    },
}));

const SideBar = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.customBar}>
                <Toolbar className={classes.toolbar}>

                    <div className={classes.logo}>
                        <Logo className={classes.logo_img} />
                    </div>
                </Toolbar>
            </div>
        </div>
    );
};

export default SideBar;


