import React, {ReactNode, useState} from 'react';
import { Card, CardContent } from '@mui/material';
import {useStoreState} from 'easy-peasy';
import Button from '../../../../components/common/UI/Inputs/Buttons/Button';
import { FlexBetween, DivContainer, Title, FlexEnd } from '../../../../assets/styles/styledComponents';
import Loader from '../../../../components/common/Loader';
import FeaturedIcon from '../../../../components/common/FeaturedIcon';

interface SectionWrapperProps {
    hide?: boolean,
    mode: 'read' | 'edit',
    name: string,
    headerTitle: string,
    handleChangeMode: Function,
    handleSave: Function,
    tab: string,
    isLoading: boolean,
    disabledSave?: boolean,
    children: ReactNode
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({mode, name, headerTitle, handleChangeMode, handleSave, tab, isLoading, hide, disabledSave= false, children}) => {
    const exploringId = useStoreState((state: any) => state.exploringId);

  return (
    hide ? 
    <React.Fragment/> :
    <Card variant="outlined" sx={{ marginBottom: "20px", position: 'relative'}}>
        <Loader loading={isLoading} height="13%" width="4%" />
        <CardContent sx={{padding: '0px'}}>
            <FlexBetween style={{borderBottom: '1px solid #ddd'}} margin="0px" padding="20px">
                    <Title> {headerTitle} </Title>  
                    {exploringId !== -1 &&
                        <DivContainer>
                            {mode === "read" ? (
                                <FeaturedIcon
                                    icon="Edit"
                                    onClick={(e: React.MouseEvent) => handleChangeMode(tab, 'edit', name)}
                                />
    
                            ) : (
                                <FeaturedIcon
                                    icon="Cross"
                                    onClick={(e: React.MouseEvent) => handleSave('cancel', tab, name, 'read')}
                                />
                            )}
                        </DivContainer>
                    }
            </FlexBetween>
            <DivContainer padding="20px 20px">
                {children}
            </DivContainer>
            {(mode === "edit") && (
                <FlexEnd>
                    {exploringId !== -1 &&
                        <Button
                            size="small"
                            onClick={() => { handleSave('cancel', tab, name, 'read') }}
                            label="CANCEL"
                        />
                    }
                    <Button
                        size="small" 
                        onClick={() => { handleSave('save', tab, name, 'read') }}
                        disable={disabledSave}
                        label="Save"
                    />
                </FlexEnd>
            )}
        </CardContent>
    </Card>
  )
}

export default SectionWrapper;