
import React, {useRef} from 'react'
import { makeStyles, withStyles } from '@mui/styles';
import {TableCell, Tooltip } from '@mui/material';
import {StyledTableActionsRowProps} from './type'
import {Button} from '../../../components/common/UI'
import useActiveToolTip from '../../../hooks/useActiveToolTip';
import { DivContainer } from '../../../assets/styles/styledComponents';

export const StyledTableRowCell = (props:any) => {
    const { maxWidth, fontSize, paddingLeft } = props;
    const ref = useRef();
    const isOverflow = useActiveToolTip(ref);
    const Styled = withStyles((theme) => ({
        root: {
            fontSize: fontSize || 12,
            padding: '5px 5px !important',
            paddingLeft: (paddingLeft ? `${paddingLeft}px !important` : 'unset'),
            overflow: "hidden",
            maxWidth: (maxWidth && maxWidth.toString() + "px") || undefined,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }))(TableCell);
    return (
        <Tooltip title={isOverflow ? props.children: ''} placement="top">
            <Styled {...props} ref={ref}>
                {props.children}
            </Styled>
        </Tooltip>
    )
};

export const StyledTableActionsRow = (props: StyledTableActionsRowProps) => {
    const {  column, rowData } = props;
    const renderActionsInfo = () => {
        switch(column?.type) {
            case 'button': {
                return (
                    <Button
                        label = {column.title}
                        size = {column.fontSize || 'small'}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            column.onClick && column.onClick(e, rowData)
                        }}
                    />
                )
            }
        }
    }
    return (
        <DivContainer width={column.width} borderBottom="1px solid rgba(224, 224, 224, 1)">
            {renderActionsInfo()}
        </DivContainer>
    )
};