import { action, thunk } from "easy-peasy";
import * as constant from "../constants";
import { getAllRoles, getNotifications, updateNotification } from '../api/users'
const defaultStore = {
    users: [],
    userNotifications: [],
    userRoles: [],
    isLoading: false,
    updateUsers: action((state, clients) => {
        state.users = clients;
    }),
    fetchUsers: thunk(actions => {
        return users()
            .then(users => {
                actions.updateUsers(users)
                return Promise.resolve();
            });
    }),

    addUserNotification: action((state, notifications) => {
        state.userNotifications = notifications
    }),
    removeUserNotification: action((state, notification) => {
        state.userNotifications = state.userNotifications.filter((item) => item.id !== notification.id)
    }),
    setLoading: action((state) => {
        state.isLoading = !state.isLoading;
    }),

    setUserRoles: action((state, data) => {
        state.userRoles = data;
    }),

    fetchUserRoles: thunk((actions, payload) => {
        return getAllRoles().then((data) => {
            actions.setUserRoles(data);
            return Promise.resolve(data);
        })
    }),
    fetchUserNotifications: thunk((actions, payload) => {
        return getNotifications(payload).then((data) => {
            actions.addUserNotification(data)
            return Promise.resolve();
        })
    }),
    updateUserNotification: thunk((actions, payload) => {
        actions.setLoading();
        return updateNotification(payload.userId, payload.notificationId).then((data) => {
            actions.removeUserNotification(data);
            actions.setLoading();
            return Promise.resolve();
        })

    })
};
export default defaultStore;

export function users(role = null) {
    const url = role ? `${constant.USER_LIST}?specificRole=${encodeURIComponent(role)}` : constant.USER_LIST;

    return global.fetchWrapper.get(url)
        .then(users => {
            if (typeof users.map !== "function") return;
            let allUsersForTable = users.map(user => {
                return {
                    id: user.id,
                    name: user.firstName + " " + user.lastName,
                    email: user.email,
                    role: user.roles.join(', '),
                    editData: {
                        isActive: user.isActive,
                        userId: user.id,
                        userContactId: user.contact?.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phoneNumber: user.phoneNumber,
                        email: user.email,
                        roles: user.roles,
                        hebrewFirstName: user.contact?.firstNameHebrew,
                        hebrewLastName: user.contact?.lastNameHebrew,
                        Address: user.contact?.address,
                        city: user.contact?.city,
                        state: user.contact?.state,
                        zip: user.contact?.zip,
                        phone2: user.contact?.phone2,
                        email2: user.contact?.email2,
                        ssn: user.contact?.ssn,
                        dob: user.contact?.dob
                    }
                };
            });
            return allUsersForTable;
        });
};