import React from 'react';
import clsx from 'clsx';
import { Button, Chip, Tooltip } from '@mui/material';
import { BoldTitle, BoxContainer, FlexBetween, FlexContainer, Text, UnderlinedTitle, SpacingBlock } from '../../../../assets/styles/styledComponents';
import useStyles from './style';
import {InfoBoxProps, infoOptionsType, headerActionType} from './types';
import FeaturedIcon from '../../../common/FeaturedIcon';
import { dateTime } from '../../../../_helpers/datetime';

const InfoBox: React.FC<InfoBoxProps> = ({title, infoOptions, data={}, headerAction}) => {
    const globalConst: any = global;
    const classes = useStyles();
    const getPropertValue = (option: infoOptionsType) => {
        if (!!option.icon) {
            return (<Tooltip title={option?.hover ? 
                    option.type === 'date' ? !!data[option.property as keyof typeof data] && globalConst.dateTime.getUSAFormattedDateString(data[option.property as keyof typeof data]) :data[option.property as keyof typeof data]
                    : ''}
                    >
                <div>
                    {!!data[option.property as keyof typeof data] ? <FeaturedIcon style={{padding: "0px",}} color="primary" icon={option.icon}/>: <FeaturedIcon style={{padding: "0px",}} icon={"Cross"}/>}
                </div>
            </Tooltip>)
        } else if (!!option?.getValue) {
            return option.getValue(data)
        } else if (option.type === 'date') {
            return data[option.property as keyof typeof data] ? dateTime.getUSAFormattedDateString(data[option.property as keyof typeof data]) : "--";
        }else {
            return data[option.property as keyof typeof data] || "--";
        }
    }

    const getHeaderAction = (action: headerActionType) => {
        switch(action.type) {
            case 'button':
                return <Button onClick={(e) => action.onClick(e)}>{action.label}</Button>
            case 'chip': 
                return(
                    <Chip
                        size={'small'}
                        label={data[action?.property as keyof typeof data]}
                        className={clsx({
                            [classes.green]: data[action?.property  as keyof typeof data] === 'Approved',
                            [classes.yellow]: data[action?.property as keyof typeof data] === 'Pending',
                            [classes.red]: data[action?.property as keyof typeof data] === 'Not-Approved',
                        })}
                        onClick={() => {}}
                    />
                )
            default:
                return null
        }
    }
  return (
    <BoxContainer>
        <FlexBetween>
            <BoldTitle>{title}</BoldTitle>
            {!!headerAction && getHeaderAction(headerAction)}
        </FlexBetween>
        <BoxContainer padding="2px 5px">
            {infoOptions?.map((option: infoOptionsType) =>(
                option?.type === 'section' ?
                <UnderlinedTitle fontSize={15} fontWeight={400} >
                    <SpacingBlock top="20px"/>
                    {option.title}
                </UnderlinedTitle> :
                <FlexContainer alignItems="center">
                    <BoldTitle fontSize={13}>{option.title}:</BoldTitle>
                    <Text fontSize={13}>{getPropertValue(option)}</Text>
                </FlexContainer>
            ))}
        </BoxContainer>
    </BoxContainer>
  )
}

export default InfoBox