import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Autocomplete,
} from "@mui/material";
import Button from "@mui/material/Button";
import _ from "lodash";
import { useStoreState } from "easy-peasy";
import useStyles from "./styles";
import useBaseStyles from '../../../../assets/styles';
import useUnsavedChangesWarn from "../../../../hooks/useUnsavedChangesWarn";
import { getClientMissionService, saveClientMissionService } from "../../../../api/clients";
import AnimLoadingState from "../../../common/AnimLoadingState";
import {AddMissionServiceType, clientServiceListType} from './types'

const AddMissionService: React.FC<AddMissionServiceType> = (props) => {
    const {missionData, onCloseModal, isOpenCreateModal} = props;
    const baseClasses:any = useBaseStyles();
    const classes:any = useStyles();
    const [selectedService, setSelectedService] = useState <clientServiceListType | null>(null);
    const [selectedServiceError, setSelectedServiceError] = useState <string>("");
    const [clientServiceList, setClientServiceList] = useState([]);
    const [loaderDialog, setLoaderDialog] = React.useState(false);
    const exploringId = useStoreState((state:any) => state.exploringId);
    const [changesHelper] = useUnsavedChangesWarn();
    const {  setDirty, setClean, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    
    useEffect (() => {
        loadMissionServiceList()
    }, []);

    const loadMissionServiceList = () => {
        setLoaderDialog(true)
        getClientMissionService(exploringId).then((data:any) => {
            setClientServiceList(data)
            setLoaderDialog(false)
        })
    }

    const handleClose = (event:React.MouseEvent<HTMLButtonElement>, reason:any) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        closeModal();
    };

    const saveMissionService = () => {
        if (!!selectedService) {
             setLoaderDialog(true);
             saveClientMissionService(missionData.missionId, selectedService.id).then((data) =>  {
                closeModal(data.clientServices);
            })
         } else {
            setSelectedServiceError("Please select service before saving");
         }
    };

     const closeModal = (reloadData = false) => {
        props.onCloseModal(reloadData, 'service');
         setLoaderDialog(false);
         setSelectedService(null);
    };
    
    const defaultProps = {
        options: props?.missionData?.clientServices ?
            (clientServiceList.filter((listItem: any) => props?.missionData?.clientServices?.every((cs: any) => cs.id !== listItem.id) ?? [])) :
            clientServiceList,
        getOptionLabel: (option: clientServiceListType) => option.providerName,
    };

  return (
    <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={props.isOpenCreateModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
    >
        <AnimLoadingState loading={loaderDialog} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
        <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
            Add Service
        </DialogTitle>
        <DialogContent>
            <div>
                <FormControl fullWidth style={{margin: '10px 0px'}}>
                   {
                    clientServiceList.length > 0 &&
                        <Autocomplete
                            {...defaultProps}
                            className="select-button-drop"
                            fullWidth={true}
                            size="small"
                                //disabled={isTemplate}
                            value={selectedService}
                            renderInput={(params:any) => <TextField {...params} label={"Select Service"}/>}
                            onChange={(event:any, selectedOption:any, reason:any) => {
                                    setSelectedService(selectedOption)
                                }}
                            />
                   } 
                </FormControl>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={saveMissionService} color="primary">
                Save
            </Button>
            <Button onClick={(e) => handleClose(e, undefined)} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default AddMissionService;