import _ from 'lodash'
export const arrays = {
    joinWithOxfordComma,
    mergeArrRemoveDuplicates,
    groupByProperties,
    filterArrayWithProperty,
    getMaxObjectByProperty,
    getMinObjectByProperty
}

global.arrays = arrays;
/**
 * 
 * @param {Array} arr 
 * @returns {String}    Formatted string of all the items. Depending on array length - 
 * separated by "and" when the array length is 2,
 * separated by commas and an oxford comma at the end when array length is 3 or more
 */
function joinWithOxfordComma(arr){
    if(arr.length <= 1){
        return arr.join();
    }

    if(arr.length == 2){
        return arr.join(' and ');
    }

    return [
        arr.slice(0, -2).join(', '), 
        arr.slice(-2).join(', and ')
    ].join(', ');
}

function mergeArrRemoveDuplicates(array1, array2) {
    return [...new Set([...array1, ...array2])];
}

function groupByProperties ({ Group: data, By: fields }) {
    const getGroupedItems = (item) => {
        let returnArray = [];
        let i;
        for (i = 0; i < fields.length; i++) {
            returnArray.push(item[fields[i]]);
        }
        return returnArray;
    };

    let groups = {};
    let i;

    for (i = 0; i < data.length; i++) {
        const arrayRecord = data[i];
        const group = JSON.stringify(getGroupedItems(arrayRecord));
        groups[group] = groups[group] || [];
        groups[group].push(arrayRecord);
    }
    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};

function filterArrayWithProperty (array, property, field) {
    return array.filter((item) => item[property] === field)
}

function getMaxObjectByProperty(array, property) {
    return _.maxBy(array, function(o) {
        return o[property];
    });
}

function getMinObjectByProperty(array, property) {
    return _.minBy(array, function(o) {
        return o[property];
    });
}