import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import { DivContainer, FlexEnd } from '../../../../assets/styles/styledComponents'
import { columns } from '../../../../screens/client/CientPageList';
import {Button} from '../../../common/UI';
import { explorerTypesConst } from '../../../../constants'
import DataTable from '../../../DataTable/DataTable';
import AddContact from '../Dialog/AddContact';
import {getYeshivasContacts, addYeshivasContacts, deleteYeshivasContacts} from '../../../../api/yeshivas';
import { getUserDetail } from '../../../../api/users';
import { yeshivaContactResType } from '../../../../assets/types/yeshiva';
import Loader from '../../../common/Loader';
import UserForm from '../../../../screens/users/UserFormModal';
import { usersDataType } from '../../../../assets/types/users';

type ContactProps = {
    permissions?: any
}

const Contact = ({permissions}: ContactProps) => {
    const { id } = useParams();
    const exploringType = useStoreState((state: any) => state.exploringType);
    const exploringId = useStoreState((state: any) => state.exploringId);
    const [isOpenCreateModal, setIsOpenCreateModal] = React.useState(false);
    const [isOpenUserInfoModal, setIsOpenUserInfoModal] = React.useState(false);
    const [data, setData] = React.useState<yeshivaContactResType[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [editData, setEditData] = React.useState<yeshivaContactResType | null>(null);
    const [userDefualtValues, setUserDefaultValues] = React.useState<any>(null);
    useEffect(() => {
        switch (exploringType) {
            case explorerTypesConst.yeshivas:
            case explorerTypesConst.yeshiva: {
                loadContacts()
            }
        }
    }, [])

    const loadContacts = () => {
        setLoading(true)
        getYeshivasContacts(id)?.then((response: yeshivaContactResType[]) => {
            setData(response);
            setLoading(false)
        });
    }

    const setRowAndColumn = () => {
        return {
            rows: data,
            columns: [],
            screen: `${exploringType}_contact`
        }
    }

    const handleClickOpen = () => {

    }

    const handleAddButton = () => {
        setIsOpenCreateModal(true)
    }

    const handleCloseModal = (isReload: boolean) => {
        setIsOpenCreateModal(false);
        setEditData(null);
        if (isReload) {
            loadContacts()
        }
    };

    const handleCloseUserInfoModal = (isReload: boolean) => {
        setIsOpenUserInfoModal(false);
        setEditData(null);
        setUserDefaultValues(null);
        if (isReload) {
            loadContacts()
        }
    }

    const handleEdit = (param: any) => {
        setEditData(param.row);
        setIsOpenCreateModal(true)
    }

    const handleDelete = (param: any) => {
        setLoading(true)
        deleteYeshivasContacts(exploringId, param.row.contactId)?.then((data) => {
            loadContacts()
            setLoading(false)
        })
    }

    const onAddUser = (param: any) => {
        const row = param.row;
        
        let data: any = {
            contactId: row.contactId,
            userId: row.userAccountId,
            isActive: row.isActive,
            userRoles: row.userRoles,
            email: row.email,
            firstName: row.firstName,
            lastName: row.lastName,
            phone: row.phone,
        }
        if (row.userAccountId) {
            setLoading(true)
            getUserDetail(row.userAccountId).then((res: usersDataType) => {
                data['roles'] = res.contact.roles ?? res.roles;
                setEditData(data);
                setIsOpenUserInfoModal(true)
                setLoading(false)
            })
        } else {
            setUserDefaultValues({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                contactId: data.contactId
            });
            setIsOpenUserInfoModal(true)
        }
    } 

  return (
    <DivContainer width="100%" position="relative">
        <Loader loading={isLoading} />
        <FlexEnd>
            <Button
                variant="text"
                color="primary"
                onClick={handleAddButton}
                label={"Add"}
            />
        </FlexEnd>
        <DataTable
            title="Contact"
            onEdit={(params: any) => {
                handleEdit(params)
                // TODO: handle edit contant 
            }}
            onOpen={handleClickOpen}
            onAssignUser={onAddUser}
            data={setRowAndColumn()}
            onDelete={(params: any) => {
                handleDelete(params)
            }}
            permissions={permissions}
            canEdit={(params: any) => permissions.canEdit}
            canDelete={(params: any) => permissions.canDelete}
            multiView
        />
        <AddContact 
            title={!!editData ? 
                "Edit Contact" : 
                "Add Contact"
            }
            isOpenCreateModal={isOpenCreateModal}
            onCloseModal={handleCloseModal}
            editData={editData}
        />
        <UserForm
            isOpen={isOpenUserInfoModal}
            onClose={handleCloseUserInfoModal}
            editData={editData}
            defaultValues={userDefualtValues}
            userModule="yeshiva"
        />
    </DivContainer>
  )
}

export default Contact