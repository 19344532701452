import { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"


//credits: https://github.com/B3nnyL/react-zendesk


const canUseDOM = () => {
    if (typeof window === 'undefined' || !window.document || !window.document.createElement) {
        return false
    }
    return true
}

export const ZendeskAPI = (...args) => {
    if (canUseDOM && window.zE) {
        window.zE.apply(null, args)
    } else {
        console.warn("Zendesk is not initialized yet")
    }
}

const Freshdesk = (props) => {
    const { defer, widgetId, async, onLoaded, ...other } = props;

    const onScriptLoaded = () => {
        if (typeof onLoaded === 'function') {
            onLoaded();
        }
    }

    const insertScript = (widgetId, defer) => {
        const widgetScript = document.createElement('script')
        widgetScript.async = true;
        widgetScript.defer = true;
        widgetScript.type = 'text/javascript';
        widgetScript.src = `https://widget.freshworks.com/widgets/${widgetId}.js`
        //widgetScript.addEventListener('load', onScriptLoaded);

        const settingScript = document.createElement('script');
        settingScript.type = 'text/javascript';
        settingScript.text = `window.fwSettings={
	                            'widget_id':${widgetId}
	                            };
	                            !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

        document.body.appendChild(settingScript)
        document.body.appendChild(widgetScript)
    }
    useEffect(() => {
        if (canUseDOM && !window.FreshworksWidget) {
            insertScript(widgetId)
            //window.zESettings = other;
            window.FreshworksWidget('identify', 'ticketForm', {
                name: global.account.userValue.firstName + " " + global.account.userValue.lastName,
                email: global.account.userValue.email,
                company: "Lhisalos"
            })
            window.FreshworksWidget('prefill', 'ticketForm', {
                priority: 1,
                type: 'Problem',
            });
            window.FreshworksWidget('disable', 'ticketForm', ['email', 'name']);
            window.FreshworksWidget('hide', 'ticketForm', ['email', 'name']);
        }
        return () => {
            if (!canUseDOM || !window.FreshworksWidget) {
                return
            }
            delete window.FreshworksWidget
            delete window.fwSettings
        }
    }, [])

    return null;
}

export default Freshdesk;