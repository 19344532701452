import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { BoxContainer, FlexAlignCenter, DivContainer, FlexColExactCenter } from '../../assets/styles/styledComponents'
import useBaseStyles from '../../assets/styles';
import FeaturedIcon from '../../components/common/FeaturedIcon';
import Badge from '../../components/common/Badge'
import { DetailHeaderProps } from './types';

const DetailHeader: React.FC<DetailHeaderProps> = ({ boxIcon, boxLabel, badgeCount }) => {
  const baseClasses: any = useBaseStyles();
  const navigate = useNavigate();
  return (
    <DivContainer margin="15px 0px">
      <div className={baseClasses.toolbar}/>
          <FlexAlignCenter>
            <Tooltip title="Back to Dashboard">
                  <IconButton onClick={() => navigate('/dashboard')}>
                      <KeyboardBackspaceIcon />
                  </IconButton>
            </Tooltip>
            <Badge content={badgeCount} color="primary">
              <BoxContainer padding="0px 10px">
                  <FlexColExactCenter>
                    <FeaturedIcon icon={boxIcon} color="primary"/>
                    <DivContainer>
                      {boxLabel}
                    </DivContainer>
                  </FlexColExactCenter>
              </BoxContainer>
            </Badge>
          </FlexAlignCenter>
    </DivContainer>
  )
}

export default DetailHeader