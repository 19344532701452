import { makeStyles } from "@mui/styles";
import { maxWidth, styled } from '@mui/system';
export const CardTabsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: "12px 0px 12px 0px",
    margin: "12px 26px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiCardContent-root:last-child": {
        padding: "0px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      cursor: "pointer",
      fontSize: "12px",
    },
    /*'& .MuiPaper-outlined': {
            border: '0px',
        }*/
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-root": {
      border: "0px",
    },
  },
  selection: {
    margin: "12px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  row: {
    display: "flex",
    width: "100%",
    marginTop: "4px",
  },
  error: {
    color: "red !important",
  },
  card_view: {
    /*overflow: 'scroll',*/
    padding: "0px",
  },
  root: {
    flex: 1,
    marginTop: "12px",
    "& .MuiInputLabel-animated": {
      fontSize: "14px",
    },
  },
  grid: {
    padding: "12px",
    fontSize: "14px",
  },
  header_1: {
    display: "flex",
    padding: "18px",
  },
  title: {
    flex: 1,
    fontSize: "18px",
  },
  panelContent: {
    width: "70%",
    margin: "0 auto",
  },
  paddingTop16: {
    paddingTop: "16px",
  },
  save: {
    float: "right",
    padding: "8px 0px 0px 0px",
  },
}));
