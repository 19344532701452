import React, {useRef, useState} from 'react'
import {Typography, Tooltip, Dialog, DialogContent} from '@mui/material'
import { withStyles } from '@mui/styles';
import useActiveToolTip from '../../hooks/useActiveToolTip';

type DynamicTooltipTextProps = {
    maxWidth? : number | string,
    fontSize?: number,
    paddingLeft: number,
    title: string,
    placement: string,
    showInModal?: boolean,
    multiline?: number 
}

const DynamicTooltipText = (props:any) => {
    const { maxWidth, fontSize, paddingLeft, title, placement, showInModal, multiline } = props;
    const [showDialog, setShowDialog] = useState(false);
    const ref = useRef();
    const isOverflow = useActiveToolTip(ref);
    const multiLineEllipsis = () => {
        return !!multiline ?
        {
            display: "-webkit-box",
            "-webkit-line-clamp": multiline,
            "-webkit-box-orient": "vertical"
        } : {}
      }
    const Styled = withStyles((theme) => ({
      root: {
          fontSize: fontSize || 12,
          padding: '5px 5px !important',
          paddingLeft: (paddingLeft ? `${paddingLeft}px !important` : 'unset'),
          overflow: "hidden",
          maxWidth: (maxWidth && maxWidth.toString() + "px") || undefined,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          ...multiLineEllipsis()
      },
    }))(Typography);
    return (
      <>
            <Tooltip
                title={isOverflow ? title : ''}
                placement={placement}
                onClick={() => {
                    if (isOverflow && showInModal)
                        setShowDialog(true)
            }}>
            <Styled {...props} ref={ref} >
                {props.children}
            </Styled>
            </Tooltip>
            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
            >
                <DialogContent>{ title}</DialogContent>
            </Dialog>
        </>
  )
}

export default DynamicTooltipText