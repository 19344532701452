import React from "react";
import { makeStyles } from "@mui/styles";
import NavMenu from "../NavMenu";
import NavigationBar from "../navigation_bar/NavigationBar";
import * as constant from "../../constants";
import { Route, useNavigate, useLocation, Routes, BrowserRouter } from "react-router-dom";
import listOfMenuItems from "../navigation_bar/Routes";
import Search from "../search/Search";
import Home from "../Home";
import clsx from "clsx";
import ExplorerComponent from "../explorer/explorer";
import EditClientPage from "../../screens/client/edit_client/edit_client";
import EditEmployeePage from "../edit_explorer/index";
import EditEmployeeApplicantPage from "../edit_explorer/editEmployeeApplicant";
import ManageYeshivas from "../../screens/yeshivas/YeshivaManage";
import ProgamDetails from "../../screens/Programs/program-details";
import NotFound from '../../screens/NotFound';
import EmployeeDetailsPage from "../../screens/employees/EmployeeDetailsPage";
import ProfessionalDetailsPage from "../../screens/professionals/ProfessionalDetailsPage";
import { useStoreState, useStoreActions } from "easy-peasy";
import AuthLogin from "../../Auth/Login";
import "./loader.css";
import LoadingLogo from "../../assets/lhisalos-loading.gif";
import { accountService } from "../../_services/account.service";
import { useEffect, useState } from "react";
import ProtectedRoutes from "../../components/ProtectedRoutes/ProtectedRoutes";
import usePermission from "../../hooks/usePermission";
import { explorerTypesConst } from '../../constants'
import ZendeskWebWidget from "../../utils/zendesk/ZendeskWebWidget";
import FreshdeskWebWidget from "../../utils/freshdesk/FreshdeskWebWidget";
import Application from "../../screens/employees/EmployeeApplicationForm/Application";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        maxWidth: "100vw",
    },
    content: {
        flexGrow: 1,
    },
    padding_0: {
        paddingRight: "0px",
        paddingLeft: "0px",
    },
    greybackground: {
    },
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.3em",
            height: "0.3em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.4)",
            backgroundColor: "rgba(0,0,0,0.07)"
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.15)",
            borderRadius: "20px",
            border: "0px"
        },
    },
}));

export default function Layout() {
    const classes = useStyles();
    const location = useLocation();
    const isSidebarOpened = useStoreState((state) => state.isSidebarOpened);

    const setSidebarOpened = useStoreActions(
        (actions) => actions.setSidebarOpened
    );
    const setHistory = useStoreActions((actions) => actions.setHistory);
    const fetchClients = useStoreActions(actions => actions.clients.fetchClients);
    let navigate = useNavigate();
    setHistory(navigate);
    const isLoading = useStoreState((state) => state.isLoading);
    const handleDrawerOpen = () => {
        constant.setIsDrawerStatus(true);
        setSidebarOpened(true);
    };
    const setExploringType = useStoreActions(
        (actions) => actions.setExploringType
    );

    const handleDrawerClose = () => {
        constant.setIsDrawerStatus(false);
        setSidebarOpened(false);
    };
    const defaultUrl = 'dashboard';
    const pageTitles = {
        clients: "תלמידים",
        employees: "מעמידים",
        yeshivas: "ישיבות",
        professionals: "פראפעשינעלס",
        contacts: "Contacts",
        users: "Users",
        dashboard: "Dashboard",
        lists: "Lists",
        timesheets: 'Timesheets'
    }
    useEffect(() => {
        let extendedTitle = "";
        const currLoc = window.location.pathname.split('/')
        switch (currLoc[1]) {
            case explorerTypesConst.clients:
            case explorerTypesConst.client:
                extendedTitle = pageTitles.clients;
                setExploringType(explorerTypesConst.clients);
                break;
            case explorerTypesConst.employees:
            case explorerTypesConst.employee:
                extendedTitle = pageTitles.employees;
                setExploringType(explorerTypesConst.employees);
                break;
            case explorerTypesConst.yeshivas:
            case explorerTypesConst.yeshiva:
                extendedTitle = pageTitles.yeshivas;
                setExploringType(explorerTypesConst.yeshivas);
                break;
            case explorerTypesConst.professionals:
            case explorerTypesConst.professional:
                extendedTitle = pageTitles.professionals;
                setExploringType(explorerTypesConst.professionals);
                break;
            case explorerTypesConst.contacts:
            case explorerTypesConst.contact:
                extendedTitle = pageTitles.contacts;
                setExploringType(explorerTypesConst.contacts);
                break;
            case explorerTypesConst.users:
                extendedTitle = pageTitles.users;
                setExploringType(explorerTypesConst.users);
                break;
            case "dashboard":
                extendedTitle = pageTitles.dashboard;
                break;
            case "lists":
                extendedTitle = pageTitles.lists;
                break;
            case "timesheets":
                extendedTitle = pageTitles.timesheets;
                break;
            default:
                extendedTitle = "";
        }

        document.title = "לארגן" + (extendedTitle ? " ~ " + extendedTitle : "")
    })
    useEffect(() => {
        if (location.pathname === '/login' && accountService.userValue != null)
            navigate(defaultUrl);
        if (location.pathname === '/')
            navigate(defaultUrl)
        if (accountService.userValue == null && location.pathname !== '/EmployeeApplicationForm') {
            // Allow access to EmployeeApplicationForm without login
            navigate('/login');
        }
    }, [location.pathname, accountService.userValue, defaultUrl, navigate]);

    // Check if the current path is EmployeeApplicationForm
    const isEmployeeApplicationForm = location.pathname === '/EmployeeApplicationForm';

    // Render only the Application component for the EmployeeApplicationForm route


    const { check } = usePermission();
    const handleAddClientSuccess = (isSuccess) => {
        if (isSuccess) {
            global.AjaxSaved1("Client Saved Successfully!")
            fetchClients()
        } else {
            global.AjaxFailToast();
        }
    };

    if (isEmployeeApplicationForm) {
        return <Application />;
    }

    const canView = {
        client: check.canView(global.permissions.clients),
        employee: check.canView(global.permissions.employees),
        yeshiva: check.canView(global.permissions.yeshivas),
        user: check.canView(global.permissions.users),
        list: check.canView(global.permissions.lists),
    }
    const canEdit = {
        client: check.canEdit(global.permissions.clients),
        employee: check.canEdit(global.permissions.employees),
    }
    return (
        <>
            {/* {global.notifyWindows()} */}
            {isLoading &&
                <div>
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            zIndex: 5000,
                            opacity: 0.9,
                            // backgroundColor: "black",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {/*<div className="loader">Loading...</div>*/}
                        <img style={{ height: "80px", opacity: 0.9 }} src={LoadingLogo} alt="loading..." />
                    </div>

                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            zIndex: 4999,
                            opacity: 0.6,
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                    </div>
                </div>
            }

            <div className={classes.root}>
                {location.pathname == "EmployeeApplicationForm"
                    && (
                        <Application />
                    )}
                {location.pathname !== constant.LOGIN_ROUTER
                    && (
                        <NavMenu open={isSidebarOpened} onClick={handleDrawerOpen} />
                    )}
                {location.pathname !== constant.LOGIN_ROUTER
                    && (
                        <NavigationBar
                            open={isSidebarOpened}
                            anchor="left"
                            onClose={handleDrawerClose}
                        />
                    )}

                {location.pathname !== constant.LOGIN_ROUTER
                    && (
                        <FreshdeskWebWidget />
                    )}

                <div className={clsx(classes.content, classes.greybackground)}>
                    <div>
                        <Routes>
                            {/* <Route path="/EmployeeApplicationForm" element={<Application />} /> */}
                            <Route path="/login" element={<AuthLogin />} />
                            <Route path={constant.SEARCH_ROUTER} element={<Search />} />
                            {listOfMenuItems().map((text, index) => {
                                return (
                                    Array.isArray(text.link) ? // che checking if link is aary and pointing same component
                                        text.link.map((link, index) => (
                                            <Route
                                                key={`${link}-${index}-${text.id}`}
                                                exact
                                                path={link}
                                                element={!check.canView(text.permissions) ? <NotFound type={'restricted'} /> : {
                                                    ...text.component,
                                                    props: { ...text.component.props, name: "h" } // somehow not working
                                                }}
                                            />
                                        )) :
                                        <Route
                                            key={text.id}
                                            exact
                                            path={text.link}
                                            element={!check.canView(text.permissions) ? <NotFound type={'restricted'} /> : {
                                                ...text.component,
                                                props: { ...text.component.props, name: "h" } // somehow not working
                                            }}
                                        >
                                            {text.childs && text.childs.map((child) => (
                                                <Route
                                                    key={`${text.id} +${child.id}`}
                                                    path={child.link}
                                                    element={!check.canView(child.permissions) ? <NotFound type={'restricted'} /> : {
                                                        ...child.component,
                                                        props: { ...child.component.props, name: "h" } // somehow not working
                                                    }}
                                                />
                                            ))}
                                        </Route>
                                );
                            })}
                            <Route element={<ProtectedRoutes />}>
                            </Route>
                            <Route
                                path={constant.YESHIVAS_DETAILS_ROUTER}
                                element={canView.yeshiva ? <ExplorerComponent /> : <NotFound type='restricted' />}
                            />
                            <Route
                                path={constant.CLIENT_DETAILS_ROUTER}
                                element={canView.client ? <ExplorerComponent /> : <NotFound type='restricted' />}
                            />
                            <Route
                                path={constant.EMPLOYEE_DETAILS_ROUTER}
                                element={canView.employee ? <ExplorerComponent /> : <NotFound type='restricted' />}
                            />
                            {/*<Route*/}
                            {/*    path={constant.PROFESSIONAL_DETAILS_ROUTER}*/}
                            {/*    element={<ProfessionalDetailsPage />}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path={constant.PROGRAM_DETAILS_ROUTER}*/}
                            {/*    element={<ProgamDetails />}*/}
                            {/*/>*/}
                            <Route
                                path={constant.EDIT_CLIENT_DETAILS_ROUTER}
                                element={canEdit.client ? <EditClientPage addClientSuccess={handleAddClientSuccess} /> : <NotFound type='restricted' />}
                            />
                            <Route
                                path={constant.EDIT_EMPLOYEE_APPLICANT_DETAILS}
                                element={canEdit.employee ? <EditEmployeeApplicantPage exType="yungerman" /> : <NotFound type='restricted' />}
                            />
                            <Route
                                path={constant.EDIT_EMPLOYEE_DETAILS_ROUTER}
                                element={canEdit.employee ? <EditEmployeePage exType="yungerman" /> : <NotFound type='restricted' />}
                            />
                            <Route
                                path={constant.EDIT_YESHIVAS_DETAILS_ROUTER}
                                element={canEdit.employee ? <ManageYeshivas exType="yeshivas" /> : <NotFound type='restricted' />}
                            />
                            <Route
                                path="/*"
                                element={<NotFound />}
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}
