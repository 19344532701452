import { alpha } from "@mui/material/styles";

const tabs = theme => ({
    tabClass: {
        minWidth: "100px",
        textTransform: 'capitalize',
    },
    tabClassSelected: {
        minWidth: "100px",
        textTransform: "capitalize",
        "&.Mui-selected": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,
        },
    },
    chipTab: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
    },
    programTab: {
        paddingTop: "2rem",
        marginBottom: '3rem',
    },
    // Program tab
    tabRoot: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    cardContentHistory: {
        padding: "8px"
    },
})

export default tabs