import React, { useState, useEffect } from "react";
import {  Paper, TableContainer } from "@mui/material";
import { useStoreState } from 'easy-peasy';
import { clientScheduleStatus, periodicities, } from "../../../_helpers/constants/scheduleConstants";
import DataTable from "../../DataTable/DataTable";
import CardHead from './CardHead';
import CardBody from './CardBody';
import FRONTEND_ROUTE_CONSTANTS from '../../../frontend_route_constants';
import useStyles from './styles'
import LoadingBar from '../../LoadingBar/LoadingBar'
import { defaultFormatUtc } from "moment";
const openDetailsPageInNewTab = (event, route,routeTab, id) => {
    event.stopPropagation();
    const win = window.open(`${route}${id}${FRONTEND_ROUTE_CONSTANTS.DETAILS}`);
    win.focus();
}

export default function Card(props) {
    const { rows, handleMenuClick, scheType, isLoading, openNewModel, screen, cardType, title, headerMessage, columns, actions, actionPermissions, setEditShow, setSlcRow_Type, types,
        yungermanActionPermissions, yungermanRows, professionalRows, professionalActionPermissions, setSlcRowData, handleDeleteOpen, handleRowClick,
        clickableRows, routeTab, maxHeight, quickFilters, pagination, valueOptionsLists } = props;
    const classes = useStyles();
    const [dropDownScheduleType, setDropDownScheduleType] = React.useState(scheType);
    const [selectedRowClientScheduleId, setSelectedRowClientScheduleId] = useState(null);
    const [showSelectedRowPotentials, setShowSelectedRowPotentials] = useState(false);
    const exploringType = useStoreState((state) => state.exploringType);
    const [loading, setLoading] = useState(isLoading);

    useEffect(() => {
      setLoading(isLoading)
      return () => {
        setLoading(false)
      }
    }, [isLoading])
    
    const rowsPotentialSectionIsOpen = (clientScheduleId) => {
        return selectedRowClientScheduleId === clientScheduleId
            && showSelectedRowPotentials;
    }

    const handleClickExpand = (event, clientScheduleId) => {
        if (rowsPotentialSectionIsOpen(clientScheduleId)) {
            setSelectedRowClientScheduleId(null)
            setShowSelectedRowPotentials(false);
        } else {
            setSelectedRowClientScheduleId(clientScheduleId)
            setShowSelectedRowPotentials(true);
        }

        event.stopPropagation();
    };
    //filter actions based on the permissions
    const removeRestrictedActions = (actions) => {
        let restrictedActions = [];
        if (actionPermissions?.canAdd === false)
            restrictedActions.push("add")
        if (actionPermissions?.canEdit === false)
            restrictedActions.push("edit")
        if (actionPermissions?.canDelete === false)
            restrictedActions.push("delete")
        if (actionPermissions?.canEndSchedule === false)
            restrictedActions.push("end")
        return actions.filter(item => !restrictedActions.includes(item))
    };
    const removeSeekingRestrictedActions = (actions, permissions) => {
        let restrictedActions = [];
        if (permissions?.canEdit === false)
            restrictedActions.push("edit")
        if (permissions?.canDeleteSeeking === false)
            restrictedActions.push("delete")
        return actions.filter(item => !restrictedActions.includes(item))
    }
    const changeScheduleType = (event) => {
        var val = event.target.value;
        global.log(val);
        setDropDownScheduleType(val);
    }
    const getOriginalRow = (seekingRow) => {
        return yungermanRows[seekingRow.originalRowIndex];
        return; // no longer using professoinal rows
        if (seekingRow.type === "professional") {
            return professionalRows[seekingRow.originalRowIndex];
        }

        if (seekingRow.type === "yungerman") {
            return yungermanRows[seekingRow.originalRowIndex];
        }
    }
    

    const getFilteredSeekingRows = () => {
        let seekingRowsTemp = yungermanRows?.map((row, index) => {
            return {
                clientScheduleId: row.clientScheduleId,
                createdOn: row.createdOn,
                clientName: row.clientName,
                clientId: row.clientId,
                scheduleDisplayName: row.schedule,
                startTime: row.startTime,
                endTime: row.endTime,
                time: row.time,
                needs: row.specialties,
                meargen: row.meargen,
                type: row.type,
                status: row.status,
                clientSchedulePotentials: row.clientEmployeeSchedulePotentials?.map(p => ({
                    providerName: p.providerName,
                    status: p.status,
                })),
                originalRowIndex: index,
            }
        }).concat(professionalRows?.map((row, index) => {
            return {
                clientScheduleId: row.clientScheduleId,
                createdOn: row.createdOn,
                clientName: row.clientName,
                scheduleDisplayName: row.scheduleDisplayName,
                startTime: row.startTime,
                endTime: row.endTime,
                needs: row.professionalType,
                type: "professional",
                status: row.status,
                clientSchedulePotentials: row.clientProfessionalSchedulePotentials.map(p => ({
                    name: p.professionalName,
                    status: p.status,
                })),
                originalRowIndex: index,
            }
        }));
        seekingRowsTemp = seekingRowsTemp?.filter((row) =>
            (row?.status === undefined || row?.status === 'seeking' || row?.status === 'onhold') && row?.type !== 'appointment'
        )
        return seekingRowsTemp
    }
    
    const getFeaturedRow = () => cardType === 'seeking' ? getFilteredSeekingRows() : rows;

    const allowedSeekingAction = {
        yungerman: removeSeekingRestrictedActions(actions, yungermanActionPermissions),
        professional: removeSeekingRestrictedActions(actions, professionalActionPermissions)
    } 
    const allowedActions = removeRestrictedActions(actions);
    const allowedActionsYungerman = removeRestrictedActions(actions);

    const handleAddClick = () => {
        if (title === 'Appointment') {
            setSlcRow_Type("appointment");
            setEditShow(true) 
        } else {
            openNewModel(scheType);
        }
    }
    const _setRowAndColumn = () => {
            return { 
                rows: getFeaturedRow(), 
                columns: columns.map((col) => {
                    if(col.field === 'statusIcon' && col.enableTooltip) {
                        return {
                            ...col,
                            getTooltipTitle: (row) => {
                                return (
                                    <>
                                        <div>Status: {row.status === "seeking" ? clientScheduleStatus[row.type] : clientScheduleStatus[row.status]}</div>
                                        {row.status?.toLowerCase() === 'rejected' &&
                                            <div>Reason: {row.reasonForRejection}</div>
                                        }
                                        {row.dateApproved &&
                                             <div>Date Approved: {global.dateTime.getUSAFormattedDateString(row.dateApproved)}</div>
                                        }
                                    </>
                                );
                            },
                            getIcon: (row) => {
                                switch (row.status?.toLowerCase()) {
                                    case "active":
                                        return "CheckOutlined";
                                    case "financial":
                                        return "Warning";
                                    case "rejected":
                                        return "Clear";
                                    case 'seeking':
                                        return 'PersonSearch';
                                    case "onhold":
                                        return "Pause";
                                    default:
                                        return "InfoOutlined";
                                }
                            } 
                        
                        }
                    }
                    if (col.field === 'moreIcon') {
                        return {
                            ...col,
                            handleClick: (event, params) => {
                                const scheStatus = params.row.status;
                                const currAllowedActions = scheStatus === 'seeking' ? allowedSeekingAction[scheType] : allowedActions
                                handleMenuClick(event, params.row, currAllowedActions, scheType, true)
                            } 
                        }
                    }
                    return col
                }), 
                screen: screen ?? `${exploringType}_${title}` };
    };
    return (
        <>
            <TableContainer
                component={Paper}
                className={classes.card}
                responsive
                style={{ maxHeight: maxHeight}}
            >
                {(cardType === 'seeking' || cardType === 'history') && <CardHead
                    title = {title}
                    types = {types}
                    scheduleType = {dropDownScheduleType}
                    changeScheduleType = {changeScheduleType}
                    allowedActions = {allowedActions}
                    openNewModel = {openNewModel}
                    scheType={dropDownScheduleType}
                    setSlcRow_Type = {setSlcRow_Type}
                    setEditShow= {setEditShow}
                    quickFilters = {quickFilters}
                    
                />}
                {(cardType !== 'seeking' && cardType !== 'history') &&  <div>
                    {(
                        <DataTable
                            disablePagination={!pagination}
                            autoHeight={getFeaturedRow() && getFeaturedRow().length > 4 && !pagination ? true : false}
                            showColumns={true}
                            title={title}
                            headerMessage={headerMessage}
                            height={!pagination ? "300px" : undefined }
                            showAdd={allowedActions.includes('add')}
                            hideToolbarIconText={true}
                            hideHeaderDivider={true}
                            quickFilters={quickFilters}
                            changeScheduleType={changeScheduleType}
                            setSlcRow_Type={setSlcRow_Type}
                            setEditShow={setEditShow}
                            openNewModel={openNewModel}
                            handleRowClick={handleRowClick}
                            getOriginalRow={getOriginalRow}
                            clickableRows={clickableRows}
                            rowsPotentialSectionIsOpen={rowsPotentialSectionIsOpen}
                            handleClickExpand={handleClickExpand}
                            scheType={scheType}
                            handleAddClick={handleAddClick}
                            dropDownScheduleType={dropDownScheduleType}
                            id={"schedule-table"}
                            data={_setRowAndColumn()}
                            loading={isLoading}
                            LoadingOverlay={<LoadingBar />}
                            tableHeaderStyling={{ background: '#e2eefa', color: "#000", padding: 0 }}
                            onDelete={(params) => {
                                props.setSlcRowData(params.row, params.row?.type);
                                props.handleDeleteOpen()
                                // setId(params.row.id);
                                // popupDisplay();
                            }}
                            onEdit={(row) => {
                                // setExploringId(row.id);
                                // setExploringMode("edit");
                            }}
                            onOpen={() => {
                                // setEditType();
                            }}
                            onMoreOption={(event, row) => {
                                handleMenuClick(event, row, allowedActions, scheType, true)
                            }}
                            // searchQuery={query}
                            permissions={global.permissions.clients}
                            // valueOptionsLists={valueOptionsLists}
                            valueOptionsLists={valueOptionsLists}
                            hideHeaderColumnsSortIcon
                            multiView
                        />
                    )}
                </div>}
                { (cardType === 'seeking' || cardType === 'history')&& <CardBody
                    title = {title}
                    columns={columns}
                    allowedActions = {allowedActions}
                    allowedSeekingAction = {allowedSeekingAction}
                    scheType={dropDownScheduleType}
                    isLoading = {isLoading}
                    scheduleType = {dropDownScheduleType}
                    clickableRows = {clickableRows}
                    rowsPotentialSectionIsOpen = {rowsPotentialSectionIsOpen}
                    openDetailsPageInNewTab = {openDetailsPageInNewTab}
                    handleMenuClick = {handleMenuClick}
                    handleClick = {handleClickExpand}
                    handleDeleteOpen = {handleDeleteOpen}
                    setSlcRowData = {setSlcRowData}
                    rows={quickFilters?.type === 'select' ? getFeaturedRow()?.filter((row) => quickFilters?.dropdowns?.some((item) => item.value === row.type)) : getFeaturedRow()}
                    getOriginalRow = {getOriginalRow}
                    handleRowClick = {handleRowClick}
                    routeTab = {routeTab}
                />}
            </TableContainer>
        </>
    );
}
