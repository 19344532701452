import React, { useEffect } from "react";
import { Route } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Layout from "./components/layout/Layout";
import Home from "./components/Home";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider, } from '@mui/styles';
import ThemeProviderWrapper from './components/ThemeProviderWrapper';
import DynamicConfirmDialog from './components/common/ConfirmDialog/DynamicConfirmDialog'
import { theme } from "./assets/theme/theme";
import "./custom.css";

const App = () => {
    //   static displayName = App.name;
    // const theme = createTheme({
    //     palette: {
    //         primary: {
    //            main: "#1976d2",
    //         },
    //         background: {
    //             paper: "#F1F7FD",
    //             front: "#e2eefa"
    //         }
    //     },
    // });

    useEffect(() => {
        toast.configure({
            pauseOnFocusLoss: true,
        });
    }, [])
    return (
        <>
            <ThemeProviderWrapper>
                <div>

                    <Layout>
                        {/*<ToastContainer />*/}
                        {/*<Route exact path="/" component={Home} />*/}
                    </Layout>
                    <DynamicConfirmDialog/>
                </div>
            </ThemeProviderWrapper>
        </>
    );
};

export default App;


