import React, {useEffect} from 'react';
import moment from 'moment'
import { IconButton, Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/Upload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useStoreActions, useStoreState } from "easy-peasy";
import ProtectedComponent from '../../../components/ProtectedComponent/ProtectedComponent';
import usePermission from "../../../hooks/usePermission";
import { getFileExtension } from "../../../_helpers/file";
import { DivContainer } from '../../../assets/styles/styledComponents'
import DataTable from "../../../components/DataTable/DataTable";
import useGetValueOptionsLists from "../../../hooks/useGetValueOptionsLists";
import useConfirm from "../../../hooks/useConfirm";
import useInterval from '../../../hooks/useInterval';
import { approveTimesheet, getUnapprovedTimesheetList } from '../../../api/timesheet';
import { TIMESHEET_USAGE_START } from '../../../config/featuresUsageStartDates';
import DetailHeader from '../DetailHeader';

const Timesheet = () => {
  const globalConst: any = global;
  const timesheetPermissions = usePermission(globalConst.permissions.dashboard.timesheet);
  const setTimesheetData = useStoreActions((actions: any) => actions.dashboard.setTimesheet);
  const setLoading = useStoreActions((actions: any) => actions.dashboard.setLoading);
  
  const timesheetData = useStoreState((state: any) => state.dashboard.timesheets);
  const loading = useStoreState((state: any) => state.dashboard.loading);

  const timesheetValueOptionsList = useGetValueOptionsLists(['clientName', 'providerName']);
  const {confirm} = useConfirm();

  useEffect(() => {
    let date = moment(TIMESHEET_USAGE_START).format('MM/DD/YYYY');
    timesheetData.length <= 0 && loadTimesheetData(date, true);
  }, [])

  const loadTimesheetData = (date: string, loading: boolean = false) => {
    loading && setLoading(true);
    getUnapprovedTimesheetList(date).then((data: any) => {
        let tsheet = data.timesheets.map((row: any) => {
            let hebMonth = data.monthRanges.find((range: any) => row.monthIndex === range.monthIndex);
            return {
                ...row,
                hebMonthYear: hebMonth.hebMonthName + ' ' + hebMonth.hebYearName
            }
        })
        setTimesheetData(tsheet);
        loading && setLoading(false)
    })
  }
  
  /**
     * Refresh cards page info after every 10min
  */
   useInterval(() => {
    const date = moment(TIMESHEET_USAGE_START).format('MM/DD/YYYY');
    loadTimesheetData(date)
  }, 600000)

  const timesheetCols = [
    { field: "clientName", headerName: "Client", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect'},
    { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details", type: 'singleSelect' },
    { field: "hebMonthYear", headerName: "Month", width: 120, },
    { field: 'uploadedOn', headerName: 'Date Uploaded', type: 'date', width: 120 },
    { field: "download", enableTooltip: false, disableColumnMenu: true, 
        renderCell: (param: any) => (
            !!param.row.uploadedOn ? 
            <>
                <IconButton onClick={() => handleTimesheetActionClick("Download", param.row)}>
                    <CloudDownloadIcon/>
                    </IconButton>
                    {getFileExtension(param.row.fileName) === 'pdf' &&
                        <IconButton onClick={() => handleTimesheetActionClick("Open", param.row)}>
                            <OpenInNewIcon />
                        </IconButton>
                    }
            </>:
            null
        ), sortable: false, maxWidth: 100, minWidth: 30, cellClassName: 'icon-no-padding' },
    { field: "approve", enableTooltip: false, disableColumnMenu: true, 
        renderCell: (param: any) => (
            <Button color="primary"  sx={{textTransform: 'capitalize'}} disabled={!!param.row.isApproved} onClick={() => handleTimesheetApprove(param.row)}>
                Approve
            </Button>
            
        ), sortable: false, maxWidth: 100, minWidth: 30, cellClassName: 'icon-no-padding' },
  ];

  const _handleTimesheetRowSelect = () => {
  }

  const handleTimesheetActionClick = (action: string, row: any) => {
    switch(action) {
        case 'Download':
            globalConst.fetchWrapper.downloadFile(row.fileId)
            break;
        case 'Open':
          globalConst.fetchWrapper.readFileInNewTab(row.fileId);
            break;
        default:
            return null
    }
  }

  const handleTimesheetApprove = async (row: any) => {
    const isConfirmed = await confirm(true, [], '', 'Timesheet', row.timesheetId, 'approve');
    if (isConfirmed) {
      setLoading(true);
        approveTimesheet(row.timesheetId).then((data) => {
          setLoading(false);
            setTimesheetData(timesheetData.filter((item: any) => item.timesheetId !== row.timesheetId)) // remove timesheet from list
        })
    }
  }

  return (
    <DivContainer>
      <DetailHeader boxLabel='Timesheet' boxIcon='AccessTime' badgeCount={timesheetData.length} />
      <ProtectedComponent allow={timesheetPermissions.canView} canEdit>
        <DivContainer>
          <DataTable
              title="Timesheet"
              data={{
                  rows: timesheetData,
                  columns: timesheetCols,
                  screen: "dashboard_timesheet",
              }}
              onRowClick={_handleTimesheetRowSelect}
              rowHeight={30}
              loading={loading}
              valueOptionsLists={timesheetValueOptionsList}
              hideToolbarIconText
              hideHeaderColumnsSortIcon
              multiView
          />
       </DivContainer>
      </ProtectedComponent>
    </DivContainer>
  )
}

export default Timesheet