import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        margin: "12px 26px",
        '& .MuiCardContent-root:last-child': {
            padding: '0px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-row': {
            cursor: 'pointer',
            fontSize: '12px'
        },
        '& .MuiDataGrid-root .MuiDataGrid-colCellWrapper': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none !important',
        },
        '& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount': {
            visibility: 'hidden'
        },
        '& .MuiDataGrid-root': {
            border: "0px"
        }
    },
    overview_main: {
        padding: '8px',
        paddingBottom: "39px",
    },
    
    middle: {
        maxHeight: "25px",
        maxWidth: "100px"
    },
    
    no__data__typgoraphy: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    no_data: {
        position: 'relative',
        marginTop: '20%',
        marginBottom: '20%',
        textAlign: 'center',
    },
    searchIconFont: {
        width: "20px",
        height: "17px",
    },
    progress_time: {
        fontSize: "12px",
        color: 'gray',
        marginRight: "12px",
    },
    heading: {
        // fontSize: theme.typography.pxToRem(15),
        // flexBasis: '33.33%',
        fontWeight: "bold",
        fontSize: "12px",
        flexShrink: 0,
        paddingLeft: 20
    },
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    header: {
        fontWeight: 'bold',
        display: 'flex',
        width: '100%',
        marginBottom: "12px"
    },
    todo_label: {
        marginRight: "0px",
        marginBottom: "0px !important",
        '& span': {
            fontSize: '12px',
        },
    },
    search: {
        position: 'relative',
        display: "inline-flex",
        borderRadius: "20px",
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginTop: "5px",
        marginLeft: 0,
        // marginBottom: theme.spacing(2),
        border: "1px solid #d3d3d3",
        width: '100%',
    },
    searchIcon: {
        padding: "0px 4px 0px 12px",
        /*height: '100%',*/
        cursor: "pointer",
        position: 'relative',
        /*pointerEvents: 'none',*/
        color: "#808080",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    inputInput: {
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        fontSize: "12px",
    },
    inputRoot: {
        color: 'inherit',
        flex: 1,
        //maxWidth: 'fit-content',
        width: "100%",
        overflow: "hidden",
        fontSize: "12px",
    },
    
}));
