import React, {memo} from 'react'
import style from './styles';
const AnimLoadingState = ({loading, color}) => {
    const classes = style();
  return (
    <div style ={{
        width:'100%',
        height: '100%',
        position: 'absolute',
        display: loading ? "block" : "none",
        top: '0px',
        left: '0px',
        zIndex: 1000
    }}
    className={classes.anim}
    >
      <div
        style ={{
          width:'100%',
          height: '4px',
          position: 'absolute',
          background: `${color}`,
          top: '0px',
          left: '0px'
        }}
      >
      </div>
    </div>
  )
}

export default memo(AnimLoadingState)