import { makeStyles } from "@mui/styles";

export default makeStyles((theme: any) => ({
    customClearButton: { 
        zIndex: 101,
        top: '22px !important',
        position: "absolute",
    },
    emptyHebDate: {
        '& .selectedDate': {
            fontSize: '0px',
        },
    },
    hebDateContainer: {
        position: 'relative',
        '& .selectedDate': {
            height: "40px",
            padding: '8px 5px',
        border: '1px solid rgba(0, 0, 0, 0.6)',
        '& svg': {
            fontSize: '26px'
        }
        },
        '& .monthWrapper': {
        zIndex: 100,
        
        },
        '& .weekdayWrapper': {
            backgroundColor: `${theme.palette.primary.main} !important`
        
        }
    },       
    colorLabel: {
        color: 'rgba(0, 0, 0, 0.6)'
    },
    hebErrorState: {
        '& .selectedDate': {
            border: '1px solid #d32f2f',
            color: '#d32f2f'
        },
    },
    error: {
        color: '#d32f2f'
    },
    font11: {
        fontSize: '11px'
    },
    font14: {
        fontSize: '14px'
    }
}))