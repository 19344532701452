import React, { useEffect } from "react";
import {
    Checkbox, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel, FormHelperText,
    Grid,
    Input,
    InputLabel,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import _ from "lodash";

import * as constant from "../../../../constants";
import authHeader from "../../../../screens/_helper/auth-header";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';

export default function AddSchedule(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    // set the new yeshivas form control
    const [periodicity, setPeriodicity] = React.useState("");
    const [days, setDays] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [startTime, setStartTime] = React.useState(null);
    const [endTime, setEndTime] = React.useState(null);
    const [everyXOfPeriods, setEveryXOfPeriods] = React.useState("");
    const [isRepeat, setIsRepeat] = React.useState(false);
    // Errors selection
    const [periodicityPerError, setPeriodicityError] = React.useState("");
    const [everyXOfPeriodsPerError, setEveryXOfPeriodsPerError] = React.useState("");
    const [endDatePerError, setEndDatePerError] = React.useState("");
    const [startDatePerError, setStartDatePerError] = React.useState("");
    const [endTimePerError, setEndTimePerError] = React.useState("");
    const [startTimePerError, setStartTimePerError] = React.useState("");
    const [daysPerError, setDaysPerError] = React.useState("");
    const [loaderDialog, setLoaderDialog] = React.useState(false);

    useEffect(() => {
        resetAllData();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(props.editData)) {
            const edit = props.editData;
            setPeriodicity(edit.periodicity ? edit.periodicity : "");
            setDays(edit.days ? edit.days : "");
            setStartDate(global._DateField(edit.date));
            setEndDate(global._DateField(edit.endDate));
            setStartTime(global._DateField(edit.startTime));
            setEndTime(global._DateField(edit.endTime));
            setEveryXOfPeriods(edit.everyXOfPeriods ? edit.everyXOfPeriods : "");
            setIsRepeat(edit.isRepeat);
        }
    }, [props.editData]);

    const resetAllData = () => {
        setPeriodicity("");
        setDays("");
        setStartDate("");
        setEndDate("");
        setPeriodicityError("");
        setStartDatePerError(null);
        setEndDatePerError(null);
        setStartTime("");
        setEndTime("");
        setStartTimePerError(null);
        setEndTimePerError(null);
        setDaysPerError(null);
        setEveryXOfPeriods("");
        setDaysPerError(null);
        setEveryXOfPeriodsPerError(null);
        setIsRepeat(false);
    }

    const saveSchedule = () => {
        const isValid = formValidationPersonalDetails();
        if (isValid) {
            const saveSchedulePayload = {
                createdOn: new Date(),
                periodicity,
                days,
                date: startDate,
                endDate,
                isRepeat,
                everyXOfPeriods,
                startTime,
                endTime,
            };
            global.log("submit data", saveSchedulePayload, props.editData);
            const id = !_.isEmpty(props.editData) ? props.editData.id : -1;
            return global.fetchWrapper
                .post(constant.SAVE_SCHEDULE_PROGRAM + id, saveSchedulePayload, {
                    headers: authHeader(),
                })
                .then(
                    (response) => {
                        handleClose(true);
                    },
                    (error) => {
                        handleClose();
                        global.AjaxFailToast();
                    }
                );
        }
    }

    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (periodicity === "") {
            isValid = false;
            setPeriodicityError("Please enter periodicity");
        } else {
            setPeriodicityError("");
        }
        if (days === "") {
            isValid = false;
            setDaysPerError("Please enter days");
        } else {
            setDaysPerError("");
        }
        if (startDate === "") {
            isValid = false;
            setStartDatePerError("Please enter start date");
        } else {
            setStartDatePerError("");
        }
        if (endDate === "") {
            isValid = false;
            setEndDatePerError("Please enter end date");
        } else {
            setEndDatePerError("");
        }
        if (startTime === "") {
            isValid = false;
            setStartTimePerError("Please enter start time");
        } else {
            setStartTimePerError("");
        }
        if (endTime === "") {
            isValid = false;
            setEndTimePerError("Please enter end time");
        } else {
            setEndTimePerError("");
        }
        if (everyXOfPeriods === "") {
            isValid = false;
            setEveryXOfPeriodsPerError("Please enter every x of periods");
        } else {
            setEveryXOfPeriodsPerError("");
        }
        return isValid;
    };

    const handleClose = (value) => {
        props.onCloseModal(value);
        resetAllData();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpenCreateModal}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                {props.editData ? "Edit" : "Add"} Schedule
      </DialogTitle>
            <DialogContent>
                {loaderDialog ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >

                            <Grid item xs={6}>
                                <div className={baseClasses.paddingTop16}>
                                    Start Time
                <FormControl fullWidth>
                                        <TextField
                                            id="enrolledOn"
                                            type="time"
                                            name="enrolledOn"
                                            value={startTime}
                                            onChange={(event) => {
                                                if (!_.isEmpty(event.target.value)) {
                                                    setStartTimePerError("");
                                                }
                                                setStartTime(event.target.value)
                                            }}
                                            //   defaultValue={fields"}
                                            className={baseClasses.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        {startTimePerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {startTimePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>


                            <Grid item xs={6}>
                                <div className={baseClasses.paddingTop16}>
                                    End Time
                <FormControl fullWidth>
                                        <TextField
                                            id="enrolledOn"
                                            type="time"
                                            name="enrolledOn"
                                            value={endTime}
                                            onChange={(event) => {
                                                if (!_.isEmpty(event.target.value)) {
                                                    setEndTimePerError("");
                                                }
                                                setEndTime(event.target.value)
                                            }}
                                            className={baseClasses.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        {endTimePerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {endTimePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>


                            <Grid item xs={6}>
                                <div className={baseClasses.paddingTop16}>
                                    Start Date
                   <FormControl fullWidth>
                                        <TextField
                                            id="enrolledOn"
                                            type="date"
                                            name="enrolledOn"
                                            value={startDate}
                                            onChange={(event) => {
                                                if (!_.isEmpty(event.target.value)) {
                                                    setStartDatePerError("");
                                                }
                                                setStartDate(event.target.value)
                                            }}
                                            className={baseClasses.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        {startDatePerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {startDatePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={baseClasses.paddingTop16}>
                                    End Date
                <FormControl fullWidth>
                                        <TextField
                                            id="enrolledOn"
                                            type="date"
                                            name="enrolledOn"
                                            value={endDate}
                                            onChange={(event) => {
                                                if (!_.isEmpty(event.target.value)) {
                                                    setEndDatePerError("");
                                                }
                                                setEndDate(event.target.value)
                                            }}
                                            //   defaultValue={fields"}
                                            className={baseClasses.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        {endDatePerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {endDatePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Periodicity</InputLabel>
                                        <Input
                                            value={periodicity}
                                            id="my-input"
                                            onChange={(event) => {
                                                if (!_.isEmpty(event.target.value)) {
                                                    setPeriodicityError("");
                                                }
                                                setPeriodicity(event.target.value);
                                            }}
                                            aria-describedby="my-helper-text"
                                        />
                                        {periodicityPerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {periodicityPerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Days</InputLabel>
                                        <Input
                                            type="text"
                                            value={days}
                                            id="my-input"
                                            onChange={(event) => {
                                                if (!_.isEmpty(event.target.value)) {
                                                    setDaysPerError("");
                                                }
                                                setDays(event.target.value);
                                            }}
                                            aria-describedby="my-helper-text"
                                        />
                                        {daysPerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {daysPerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>


                            <Grid item xs={12}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Every X Of Periods</InputLabel>
                                        <Input
                                            type="number"
                                            value={everyXOfPeriods}
                                            inputProps={{ min: "0" }}
                                            id="my-input"
                                            onChange={(event) => {
                                                if (!_.isEmpty(event.target.value)) {
                                                    setEveryXOfPeriodsPerError("");
                                                }
                                                setEveryXOfPeriods(event.target.value)
                                            }}
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        {everyXOfPeriodsPerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {everyXOfPeriodsPerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRepeat}
                                            onChange={() => setIsRepeat(!isRepeat)}
                                            name="approvalPocketState"
                                            color="primary"
                                        />
                                    }
                                    label="Is Repeat"
                                />
                            </Grid>

                        </Grid>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={saveSchedule} color="primary">
                    Save
        </Button>
                <Button onClick={handleClose} color="primary">
                    Close
        </Button>
            </DialogActions>
        </Dialog>
    );
}
