import  * as constant from '../constants';
import {timesheetForServiceMonthType, timesheetClientListType, timesheetUnapprovedListType} from '../assets/types';
import { allTimesheetProviderRes, allTimesheetScheduleRes } from '../assets/types/timesheet';
import { TimesheetEntriesType } from '../assets/types';
const globalScope: any = global;

export const getAllTimesheetProviders = () => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.TIMESHEET + "provider")
            .then((data:  allTimesheetProviderRes[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getAllTimesheetClientsByProvider = <T> (id:T) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.TIMESHEET + "clientByProvider" + id)
            .then((data:  allTimesheetProviderRes[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getAllTimesheetSchedules = <X, Y, Z>(clientId: X , providerId: Y, weekDate: Z) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.TIMESHEET + `schedule/client/${clientId}/provider/${providerId}/date/${weekDate}`)
            .then((data:  allTimesheetScheduleRes) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const saveAllTimesheetEntries = (payload: any) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.post(constant.TIMESHEET + "saveTimesheetEntries", payload)
            .then(
                (data:  any[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
} 

export const getUnapprovedTimesheetList = (date: Date | string) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.GET_UNAPPROVED_TIMESHEET + date)
            .then(
                (data:  timesheetUnapprovedListType[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}
export const approveTimesheet = (id: string) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.post(constant.APPROVE_TIMESHEET + id)
            .then(
                (data:  any[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getTimesheetEntries = (id: string, startDate: string | Date, endDate: string | Date) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.GET_TIMESHEET_ENTRIES  + `?startDate=${startDate}&endDate=${endDate}&clientScheduleId=${id}`)
            .then(
                (data:TimesheetEntriesType[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const saveTimesheetEntries = (payload: any, id: number) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.post(constant.SAVE_TIMESHEET_ENTRIES + `?clientScheduleId=${id}`, payload)
            .then(
                (data:  any[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getTimesheetClientServiceList = (date: Date | string) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.GET_TIMESHET_CLIENT_SERVICE + date)
            .then(
                (data:  timesheetClientListType[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getTimesheetClientList = (date: Date | string) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.GET_TIMESHEET_CLIENT_LIST + date)
            .then(
                (data:  timesheetClientListType[]) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getTimesheetClientService = (clientId: number, date: Date | string) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.GET_TIMESHEET_CLIENT_SERVICES + clientId + "?hebMonthYear=" + date)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const getTimesheetForServiceMonth = (serviceId: number, date: Date | string) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.get(constant.GET_TIMESHEET_SERVICES_MONTH + serviceId + "?hebMonthYear=" + date)
            .then(
                (data:any) => {
                    resolve(data);
                },
                (rejects:any) => {
                    globalScope.log("rejects");
                    globalScope.AjaxFailToast();
                    resolve(false);
                }
            );
    });
}

export const saveTimesheetForServiceMonth = (payload: timesheetForServiceMonthType) => {
    return new Promise((resolve:any, rejects:any) => {
        globalScope.fetchWrapper.put(constant.SAVE_TIMESHEET_CLIENT_SERVICE_MONTH, payload)
            .then(
                (data:any) => {
                    resolve(data);
                    globalScope.AjaxSaved(payload.fileUpload ? "Successfully Uploaded" : 'Successfully saved');
                },
                (onError:any) => {
                    globalScope.log("rejects");
                    rejects(false);
                }
            );
    });
}