/**
 * React functional component for switching Hebrew month and years. 
 * @from @to a range of year will be passed to this component- so it will only date years betwwen the range
 * will show 12 or 13 month based on selected year is leap or not
 * @prop {boolean} isFutureDate with this field, decide either to show future month or not. By defult it is true, user can see all month. If the value passed 'false' then only display current and previous months
 * @prop {date} currentMonth field is the dymamic current hebrew month being passed to display current and previous month
 * 
 */
import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { HDate, gematriya } from '@hebcal/core';
import {
    CalendarContainer,
    LeftArrow,
    RightArrow,
    CalendarContent,
    Month,
    Year
} from '../styles';
import DatePopover from '../../DatePopover';
import AnimLoadingState from '../../AnimLoadingState';
import { HebMonthYearNumType } from '../../../explorer/Tabs/Progress/types';
import { hebrewMonthNames, HebrewMonthNamesLeapYear } from '../../../../_helpers/hebrewDate/constants';

type HebrewMonthSwitcherProps = {
    onChange: Function,
    width?: string,
    isLoading: boolean,
    from: HebMonthYearNumType, 
    to: HebMonthYearNumType,
    currentMonth?: any,
    isFutureDate?: boolean 
}

type dateObjectType = {
    label: number | string,
    value: number | string
}

const HebrewMonthSwitcher: React.FC<HebrewMonthSwitcherProps> = ({onChange, isLoading, from, to, isFutureDate/** field for checking to allow future date are not */, currentMonth, width }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | any>(null);
    const [months, setMonths] = useState<dateObjectType[]>([]);
    const [years, setYears] = useState<dateObjectType[]>([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState<any>({
        month: !!currentMonth ? hebrewMonthNames.find((mon) => mon.month === currentMonth) : hebrewMonthNames[0],
        year: {
            value: from.year,
            label: gematriya(from.year)
        }
    });
    const [calendarView, setCalendarView] = React.useState('');
    /**
     * Helper function for returning month on the bases of allowing future date or not
     * @param hebMonths 
     * @returns hebMonths
     */
    const getMonthsHelper = (hebMonths: any) => {
        if (!isFutureDate && currentMonth) {
            return hebMonths.filter((item: any) => item.month <= currentMonth)
        } else {
            return hebMonths
        }
        
    }

    // Calculating Years from and to year
    useEffect(() => {
        let yearTemp: dateObjectType[] = [];
        for (let i = from.year; i <= to.year; i++) {
            let year: any = i;
            year = gematriya(year)
            yearTemp.push({
                label: year,
                value: i
            })
        }
        const year = new HDate(1, 1, from.year);
        const isLeap = year.isLeapYear();
        setYears(yearTemp);
        setMonths(isLeap ? getMonthsHelper(HebrewMonthNamesLeapYear) : getMonthsHelper(hebrewMonthNames));
    }, [])

    useEffect(() => {
        const currHebDate = new HDate(new Date());
        let currMonthNames: any;
        if (currHebDate.isLeapYear()) {
            currMonthNames = HebrewMonthNamesLeapYear;
        } else {
            currMonthNames = hebrewMonthNames;
        }
        // Not allowing future dates
        let monthTemp = currentMonth ? currMonthNames.filter((mon: any) => mon.month <= currentMonth) : currMonthNames[currHebDate.getTishreiMonth() - 1].month
        goToMonthYear(monthTemp, currHebDate.getFullYear());
    }, [years]);

    const goToMonthYear = (month: number, year: number) => {
        if (years.some((y: any) => y.value === year)) {
            const yearObj = new HDate(1, 1, year);
            if (typeof (yearObj.isLeapYear() ? HebrewMonthNamesLeapYear : hebrewMonthNames)[month - 1] !== 'undefined')
                //handleSelect(hebrewMonthNames[month - 1], "month");
                handleSelect({
                    month: hebrewMonthNames[month - 1],
                    year: {
                        value: year,
                        label: gematriya(year)
                    }
                });
        }
        else
            return;
    }

    const handleSwitchMonth = (direction: string) => {
        let newDate: any = null;
        let index: number = months.findIndex(month => month?.label === selectedMonthYear.month?.label);
        if (direction === 'left') {
            if (index === 0) {
                if (currentMonth && !isFutureDate) {
                    goToMonthYear(currentMonth, selectedMonthYear.year.value - 1);
                } else {
                    goToMonthYear(12, selectedMonthYear.year.value - 1);
                }
                return;
            };
            newDate = {
                ...selectedMonthYear,
                month: months[index - 1]
            }
        } else if (direction === 'right') {
            if (index === months.length - 1) {
                goToMonthYear(1, selectedMonthYear.year.value + 1);
                return;
            }
            newDate = {
                ...selectedMonthYear,
                month: months[index + 1]
            }

        }
        onChange(newDate)
        setSelectedMonthYear(newDate);
    }

    /**
     * handleSelect - Setting date from Popover in Local State
     * @param data - Selected Date from Popover
     */
    const handleSelect = (date: any, calendar: string = calendarView) => {
        let newDate: any = null;
        if (date['month']) {
            newDate = {
                ...selectedMonthYear,
                month: date.month
            }
        } if (date['year']) {
            const year = new HDate(1, 1, date.year.value);
            const isLeap = year.isLeapYear();
            const isFirstYear = year.getFullYear() === years[0].value;
            const isLastYear = year.getFullYear() === years[years.length - 1].value;
            let currMonthNames: dateObjectType[];
            if (isLeap) {
                currMonthNames = HebrewMonthNamesLeapYear;
            } else {
                currMonthNames = hebrewMonthNames;
            }
            //remove months out of the from-to range
            currMonthNames = isFirstYear ? currMonthNames.slice(from.month - 1) : isLastYear ? currMonthNames.slice(0, to.month) : currMonthNames
            setMonths(getMonthsHelper(currMonthNames));
            let month = newDate?.month || currMonthNames.filter((m: any) => {
                return m.chodesh === selectedMonthYear.month.chodesh ||
                    (!currMonthNames.some((mo: any) => mo.chodesh === selectedMonthYear.month.chodesh) && m.month === selectedMonthYear.month.month);
            })[0] || currMonthNames[0];
            newDate = {
                ...selectedMonthYear,
                month: month,
                year: date.year
            }
        }
        onChange(newDate)
        setSelectedMonthYear(newDate);
    }

    /**
     * Setting poper view on the basis of click reference
     * @param event - Html Element reference
     * @param view - Month Or Year
     */
    const handleClick = (event: React.MouseEvent<HTMLDivElement>, view: string) => {
        setAnchorEl(event.currentTarget.parentNode);
        setCalendarView(view)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const calendarData = () => {
        if (calendarView === 'month') {
            let result:any = []
            months.forEach((m: any) => { result.push({ label: m.label, month: { ...m } }) })
            return result
        }
        else if (calendarView === 'year') {
            let result: any = []
            years.forEach((y: any) => { result.push({ label: y.label, year: { ...y } }) })
            return result
        }
        else {
            return null
        }
    }

    return (
        <CalendarContainer width={width}>
            <AnimLoadingState loading={isLoading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
            <LeftArrow onClick={() => {
                handleSwitchMonth('left')
            }} >
                <ChevronLeftIcon color='primary' />
            </LeftArrow>
            <CalendarContent>
                <Month onClick={(e) => {
                    handleClick(e, 'month')
                }}>
                    {
                        selectedMonthYear.month.label
                    }
                </Month>
                <Year onClick={(e) => {
                    handleClick(e, 'year')
                }}>
                    {selectedMonthYear.year.label}
                </Year>
            </CalendarContent>
            <RightArrow onClick={() => {
                handleSwitchMonth('right')
            }}>
                <ChevronRightIcon color='primary' />
            </RightArrow>
            <DatePopover
                selected={calendarView === 'month' ? selectedMonthYear.month : selectedMonthYear.year}
                title={calendarView === 'month' ? 'Months' : 'Years'}
                open={Boolean(anchorEl)}
                data={calendarData()}
                id={`calendar-${calendarView}`}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleSelect={(date: any) => {
                    handleSelect(date);
                    setAnchorEl(null);
                }}
                verticalPos="bottom"
                horizontalPos="center"
            />
        </CalendarContainer>
    )
}

export default HebrewMonthSwitcher