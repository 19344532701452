import React from "react";
import { useState } from "react";
import clsx from "clsx";
import {
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import ProgramSelectBox from "./ProgramSelectbox";
import ProgramLightCreditCard from "./ProgramLightCreditCard";
import TTextField from "./TTextField";
import TTextFieldNumber from "./TTextFieldNumber";
import baseStyles from '../../assets/styles'
import { numbers } from "../../_helpers/numbers";
const PaymentInfo = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles ();
    const fields = useStoreState((state) => state.clients.fields);
    const fieldsLight = useStoreState((state) => state.clients.fieldsLight);
    const setFields = useStoreActions((actions) => actions.clients.setFields);

    const padLeadingZeros = (num, size) => {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    };

    const creditCardInfo = {
        ccNumber: fieldsLight.ccNumber,
        cvv: fieldsLight.cvv,
        cardExpiredDate: `${padLeadingZeros(fieldsLight.expMonth, 2)}${padLeadingZeros(fieldsLight.expYear, 2)}`,
        billingZip: fieldsLight.billingZip,
        nameOnCard: fieldsLight.nameOnCard,
        note: fieldsLight.note,
        monthlyAgreedAmount: fieldsLight.monthlyAgreedAmount,
        responsibleForPayment: fieldsLight.responsibleForPayment,
    };

    const [creditCardValue, setCreditCardValue] = useState({
        ccNumber: creditCardInfo.ccNumber,
        cvv: creditCardInfo.cvv,
        cardExpiredDate: creditCardInfo.cardExpiredDate,
        billingZip: creditCardInfo.billingZip,
        nameOnCard: creditCardInfo.nameOnCard,
        note: creditCardInfo.note,
        responsibleForPayment: creditCardInfo.responsibleForPayment,
        monthlyAgreedAmount: creditCardInfo.monthlyAgreedAmount,
    });

    const onChangeHandler = (mode) => {
        props.savestatusaction(1);
        props.onFieldChange();
    };

    const onChangeCreditCardHandler = (ccValue) => {
        fieldsLight.ccNumber = ccValue.ccNumber;
        fieldsLight.cvv = ccValue.cvv;
        fieldsLight.expMonth = ccValue.cardExpiredDate.toString().slice(0, 2);
        fieldsLight.expYear = ccValue.cardExpiredDate.toString().slice(2, 4);
        fieldsLight.billingZip = ccValue.billingZip;
        fieldsLight.nameOnCard = ccValue.nameOnCard;
        fieldsLight.note = ccValue.note;
        fieldsLight.responsibleForPayment = ccValue.responsibleForPayment;
        fieldsLight.monthlyAgreedAmount = ccValue.monthlyAgreedAmount;
        props.savestatusaction(1);
        props.onFieldChange();
    };

    const onChangeFieldHandler = (event) => {
        var value = event.target.value;
        props.onFieldChange();
        let name = event.target.name.substring(event.target.name.lastIndexOf('_') + 1)
        setCreditCardValue((prevState) => {
            let result = {
                ...prevState,
                [name]: value
            }
            onChangeCreditCardHandler(result);
            return result;
        });
    }

    return (
        <div>
            <Grid
                container
                className={baseClasses.grid}
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item xs={6} direction="row">
                    <div>
                        <TTextField label={"Responsible for payment"}
                            name="responsibleForPayment"
                            value={creditCardValue.responsibleForPayment}
                            onChange={onChangeFieldHandler}
                            fullWidth
                        >
                        </TTextField>
                    </div>
                </Grid>

                    <Grid item xs={6} direction="row">
                    <div>
                        <TTextFieldNumber label={"Monthly Agreed Amount"}
                            name="monthlyAgreedAmount"
                            value={creditCardValue.monthlyAgreedAmount}
                            onChange={onChangeFieldHandler}
                            fullWidth
                            adornment={"$"}
                            error={creditCardValue.monthlyAgreedAmount && !numbers.isWholeInt(creditCardValue.monthlyAgreedAmount)}
                            helperText={creditCardValue.monthlyAgreedAmount && !numbers.isWholeInt(creditCardValue.monthlyAgreedAmount) && 'please enter a whole number'}
                        >
                        </TTextFieldNumber>
                    </div>
                </Grid>
                <Grid item xs={12} direction="row">
                    <div>
                        <ProgramSelectBox
                            programType="Light"
                            label={"Payment type"}
                            name="paymentMethod"
                            value={fieldsLight.paymentMethod}
                            savestatusaction={onChangeHandler}
                        />
                    </div>
                </Grid>

                {fieldsLight.paymentMethod == "Credit Card" && (
                    <>
                        <ProgramLightCreditCard
                            onChangeCreditCard={onChangeCreditCardHandler}
                            creditCardValue={creditCardValue}
                            setCreditCardValue={setCreditCardValue}
                        />
                    </>
                )}

                <Grid item xs={12} direction="row">
                    <TTextField label={"Note"}
                        name="note"
                        value={creditCardValue.note}
                        onChange={onChangeFieldHandler}
                        fullWidth multiline
                    >
                    </TTextField>
                </Grid>
            </Grid>
        </div>
    );
};

export default PaymentInfo;