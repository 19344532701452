import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";

import {
    GetClientOneTimeAppointmentsSchedules, GetClientProfessionalsSchedules, GetClientServiceSchedules, getDaysOfWeekStr
} from "../../../../api/clients";
import { yeshivasDetails } from "../../../../api/yeshivas";
import AddToDo from "../ToDo/AddToDo";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles'
import { dateTime } from "../../../../_helpers/datetime";
import usePermissions from "../../../../hooks/usePermission";
import TextFormattingWrapper from '../../../../utils/TextFormattingWrapper'
import ViewDetailsDialog from '../../../UI/ViewDetailsDialog';
import ProfessionalGeneralTab from './ProfessionalGeneralTab';
import YungermanGeneralTab from './YungermanGeneralTab';
import { explorerTypesConst } from '../../../../constants'
import GeneralSchedule from "./GeneralSchedule";
import { DivContainer } from "../../../../assets/styles/styles";
import Flex from "../../../Calendar/Flex";
import { BoldTitle, Text } from "../../../../assets/styles/styledComponents";
import { FlexContainer } from "../Progress/styles";

const FlexTitleValuePaired = ({ title, value }) => {
    return (
        <FlexContainer>
            <BoldTitle>{title}</BoldTitle>
            <Text fontSize="12px">{value}</Text>
        </FlexContainer>
    )
}

export default function General(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const {
        clientData,
        clientProgramData,
        clientNotesData,
        clientScheduleData,
        clientToDoData,
        isEmployee,
        goToTabProgress,
        goToTabTodo,
    } = props;
    const [expanded, setExpanded] = React.useState(false);
    const [filterClientToDo, setFilterClientToDo] = React.useState(clientToDoData);
    const [query, setquery] = React.useState("");
    const [isOpenCreateModal, setIsOpenCreateModal] = React.useState(false);
    const [editData, setEditData] = React.useState("");

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    const [fatherContact, setFatherContact] = React.useState(null);
    const [menahelContact, setMenahelContact] = React.useState(null);
    const [meargenContact, setMeargenContact] = React.useState(null);

    //tooltip state
    const [isToolTip, setIsToolTip] = React.useState(null);
    const [xPos, setXPos] = React.useState("300px");
    const [yPos, setYPos] = React.useState("300px");
    const [tooltipData, setTooltipData] = React.useState("");

    const scheduleRowDaily = useStoreState((state) => state.clients.scheduleRowDaily);
    const scheduleRowProfessional = useStoreState((state) => state.clients.scheduleRowProfessional);
    const scheduleRowAppointment = useStoreState((state) => state.clients.scheduleRowAppointment);
    const [yeshivaName, setYeshivaName] = React.useState("");

    const exploringType = useStoreState((state) => state.exploringType);
    const exploringId = useStoreState((state) => state.exploringId);

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    const progressPermissions = usePermissions(global.permissions.clients.progress);
    const schedulePermissions = usePermissions(isEmployee ? global.permissions.employees.schedules : global.permissions.clients.schedules);
    const todosPermissions = usePermissions(isEmployee ? global.permissions.employees.todos : global.permissions.clients.todos);

    useEffect(() => {
        resetAllData();
    }, [clientData]);

    useEffect(() => {
        setFilterClientToDo(clientToDoData);
    }, [clientToDoData]);

    // const isEmployee = props.data;
    const handleChangeExpanded = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const resetAllData = () => {
        if (exploringType === explorerTypesConst.yeshivas) return;
        if (!props.clientData) return;
        yeshivasDetails(props.clientData.yeshivaId ?? 0)
            .then((response) => {
                if (response.length > 0) setYeshivaName(response[0].name);
                return true;
            })
            .then(() => {
                global.contactAPI
                    .getContactDetail(props.clientData.father_ContactId)
                    .then((response) => {
                        setFatherContact(response);
                    });
            })
            .then(() => {
                global.contactAPI
                    .getContactDetail(props.clientData.menahel_ContactId)
                    .then((response) => {
                        setMenahelContact(response);
                    });
            })
            .then(() => {
                global.contactAPI
                    .getContactDetail(props.clientData.meargen_ContactId)
                    .then((response) => {
                        setMeargenContact(response);
                    });
            });
    };

    const handleTodoRowClick = () => {
        setIsOpenCreateModal(true);
    };

    const handleTodoRowEditData = (data) => {
        // global.log("explorer/handle edit data");
        // global.log(data);
        setEditData(data);
    };

    const handleCloseModal = (isReload) => {
        setIsOpenCreateModal(false);
        setEditData([]);
        // global.log("isreload --", isReload);
        // if (isReload === true) {
        //   handleReloadApi(value);
        // }
    };

    const _handleClearButton = () => {
        setquery("");
        const filteredData = clientToDoData.filter((item) => {
            return item;
        });
        setFilterClientToDo(filteredData);
    };

    const handleSearchFilter = (e) => {
        const searchQuery = e.target.value;
        setquery(searchQuery);
        const filteredData = clientToDoData.filter((item) => {
            return item["title"].toLowerCase().includes(searchQuery.toLowerCase());
        });
        setFilterClientToDo(filteredData);
    };

    const getContactHebrewName = (contact) => {
        return global.getContactHebrewName(contact);
    };

    const getContactName = (contact) => {
        return global.getContactName(contact);
    };

    const getContactPhoneNumber = (contact) => {
        return global.getContactPhoneNumber(contact);
    };

    const getEmployeeGeneralTable = () => {
        return (<YungermanGeneralTab
            employeeInfo={clientData}
            scheduleInfo={props.scheduleInfo}
        />);
    }


    const getYeshivaGeneralTab = () => {
        return (
            <DivContainer>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Card className={baseClasses.contentOverview2}>
                            <CardContent sx={{ padding: '0px 10px' }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FlexTitleValuePaired
                                            title="Name:"
                                            value={clientData?.name ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="Legal Name:"
                                            value={clientData?.legalName ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="Description:"
                                            value={clientData?.description ?? '-'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FlexTitleValuePaired
                                            title="Address:"
                                            value={clientData?.mainAddress?.address1 ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="State:"
                                            value={clientData?.mainAddress?.state ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="City:"
                                            value={clientData?.mainAddress?.city ?? '-'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FlexTitleValuePaired
                                            title="Legal Address:"
                                            value={clientData?.legalAddress?.address1 ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="Legal State:"
                                            value={clientData?.legalAddress?.state ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="City:"
                                            value={clientData?.legalAddress?.city ?? '-'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FlexTitleValuePaired
                                            title="Summer Address:"
                                            value={clientData?.summerAddress?.address1 ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="Summer State:"
                                            value={clientData?.summerAddress?.state ?? '-'}
                                        />
                                        <FlexTitleValuePaired
                                            title="Summer City:"
                                            value={clientData?.summerAddress?.city ?? '-'}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={baseClasses.contentOverview2}>
                            <CardContent classes={{ root: baseClasses.contentCard }}>
                                <Grid
                                    container
                                    alignItems="center"
                                    style={{ height: 120 }}
                                    className={classes.root}
                                >
                                    <div className={baseClasses.grow}>
                                        <div component={"span"} className={baseClasses.card_title}>
                                            <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 100 }}>
                                                Early Morning:
                                            </span>
                                            {dateTime.formatStartAndEndTime(clientData?.yeshivaSedarim?.earlyMorning?.startTime, clientData?.yeshivaSedarim?.earlyMorning?.endTime)}
                                        </div>
                                        <div component={"span"} className={baseClasses.card_title}>
                                            <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 100 }}>
                                                Morning:
                                            </span>
                                            {dateTime.formatStartAndEndTime(clientData?.yeshivaSedarim?.morning?.startTime, clientData?.yeshivaSedarim?.morning?.endTime)}
                                        </div>
                                        <div component={"span"} className={baseClasses.card_title}>
                                            <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 100 }}>
                                                Afternoon:
                                            </span>
                                            {dateTime.formatStartAndEndTime(clientData?.yeshivaSedarim?.afternoon?.startTime, clientData?.yeshivaSedarim?.afternoon?.endTime)}
                                        </div>
                                        <div component={"span"} className={baseClasses.card_title}>
                                            <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 100 }}>
                                                Night:
                                            </span>
                                            {dateTime.formatStartAndEndTime(clientData?.yeshivaSedarim?.night?.startTime, clientData?.yeshivaSedarim?.night?.endTime)}
                                        </div>
                                    </div>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DivContainer>
        )
    }

    const getClientGeneralTab = () => {
        return (
            <div
                className={baseClasses.overview_main}
                onMouseMove={(e) => {
                    setXPos(`${e.pageX + 15}px`);
                    setYPos(`${e.pageY + 5}px`);
                }}
            >
                {/* <ContactSelectInput></ContactSelectInput> */}
                <div className={baseClasses.row} style={{ paddingTop: 9 }}>
                    <div className={baseClasses.leftContentSection}>
                        {!isEmployee ? (
                            <Card className={baseClasses.contentOverview2}>
                                <CardContent classes={{ root: baseClasses.contentCard }}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{ height: 120 }}
                                        className={classes.root}
                                    >
                                        <div className={baseClasses.grow}>
                                            <div component={"span"} className={baseClasses.card_title}>
                                                <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 75 }}>
                                                    Yeshiva:
                                                </span>
                                                {yeshivaName}
                                            </div>
                                            <div component={"span"} className={baseClasses.card_title}>
                                                <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 75 }}>
                                                    Age:
                                                </span>
                                                {dateTime.getAge(clientData)}
                                            </div>
                                            <div component={"span"} className={baseClasses.card_title}>
                                                <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 75 }}>
                                                    כיתה:
                                                </span>
                                                {clientData && clientData.grade ? clientData.grade : "-"}
                                            </div>
                                        </div>
                                        <Divider orientation="vertical" flexItem />
                                        <div className={baseClasses.grow} >
                                            <div component={"span"} className={baseClasses.card_title}>
                                                <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 75 }}>
                                                    Stage:
                                                </span>
                                                {clientData && clientData.stage ? clientData.stage : "-"}
                                            </div>
                                            <div
                                                component={"span"}
                                                className={baseClasses.card_title}
                                                onClick={() => {
                                                    setDialogContent(clientData && clientData.intakeInfo ? clientData.intakeInfo : "")
                                                    setIsDialogOpen(true)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <span className={baseClasses.topCardTitles} style={{ display: "inline-block", width: 75 }}>
                                                    Intake Info:
                                                </span>
                                                <Tooltip
                                                    title={clientData && clientData.intakeInfo ? (<TextFormattingWrapper value={clientData.intakeInfo} />) : ""}
                                                >
                                                    <p className={classes.middle}>
                                                        {clientData && clientData.intakeInfo ? (<TextFormattingWrapper value={clientData.intakeInfo} />) : "-"}
                                                    </p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <Divider orientation="vertical" flexItem />
                                        <div className={baseClasses.grow} style={{ paddingLeft: 8 }}>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr className={baseClasses.card_title}>
                                                        <td>{getContactPhoneNumber(fatherContact)}</td>
                                                        <td>{getContactName(fatherContact)}</td>
                                                        <td className={baseClasses.topCardTitles} style={{ width: 40 }}>טאטע</td>
                                                    </tr>
                                                    <tr className={baseClasses.card_title}>
                                                        <td>{getContactPhoneNumber(menahelContact)}</td>
                                                        <td>{getContactName(menahelContact)}</td>
                                                        <td className={baseClasses.topCardTitles} >מנהל</td>
                                                    </tr>
                                                    <tr className={baseClasses.card_title}>
                                                        <td>{getContactPhoneNumber(meargenContact)}</td>
                                                        <td>{getContactName(meargenContact)}</td>
                                                        <td className={baseClasses.topCardTitles} >מארגן</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ) : null}
                        {schedulePermissions.canView &&
                            <GeneralSchedule
                                isEmployee={isEmployee}
                                scheduleInfo={props.scheduleInfo}
                            />
                        }

                        <div className={clsx(baseClasses.row, baseClasses.mrtop12)}>
                            <Card className={baseClasses.contentOverview2}>
                                <div
                                    className={baseClasses.schedule_title}
                                    component="span"
                                    variant="h6"
                                >
                                    Programs
                                </div>
                                <div className={baseClasses.row}>
                                    <Divider
                                        className={baseClasses.fullWidth}
                                        orientation="horizontal"
                                    />
                                </div>
                                <CardContent
                                    classes={{ root: baseClasses.contentCard }}
                                    className="p-0"
                                >
                                    <div style={{ maxHeight: "22%", overflow: "auto" }}>
                                        <div className={baseClasses.table}>
                                            <div className={baseClasses.row}>
                                                <div className={baseClasses.header}>
                                                    <div className={baseClasses.column}>Program</div>
                                                    <div className={baseClasses.column}>Status</div>
                                                    <div className={baseClasses.column}>Care Manager</div>
                                                </div>
                                            </div>
                                            <div className={baseClasses.row}>
                                                <Divider
                                                    className={baseClasses.fullWidth}
                                                    orientation="horizontal"
                                                />
                                            </div>
                                            {!_.isEmpty(clientProgramData) ? (
                                                clientProgramData.map((item, index) => (
                                                    <div key={index}>
                                                        <div className={baseClasses.row}>
                                                            <div className={baseClasses.description}>
                                                                <div
                                                                    className={clsx(baseClasses.column, baseClasses.fns12)}
                                                                >
                                                                    {item.programName === 'Light' ? 'Cash' : item.programName}
                                                                </div>
                                                                <div
                                                                    className={clsx(baseClasses.column, baseClasses.fns12)}
                                                                >
                                                                    {item.status}
                                                                </div>
                                                                <div
                                                                    className={clsx(baseClasses.column, baseClasses.fns12)}
                                                                >
                                                                    {item.care_manager}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={baseClasses.row}>
                                                            <Divider
                                                                className={baseClasses.fullWidth}
                                                                orientation="horizontal"
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={classes.no_data}>
                                                    <Typography className={classes.no__data__typgoraphy}>
                                                        NO DATA
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>

                    <div className={baseClasses.progress}>
                        {!isEmployee && progressPermissions.canView ? (
                            <Card className="w-100">
                                <CardContent
                                    classes={{ root: baseClasses.contentCard }}
                                    className="p-0"
                                >
                                    <div>
                                        <Grid container alignItems="center" className={classes.root}>
                                            <div
                                                className={baseClasses.row}
                                                style={{
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    paddingTop: 10,
                                                }}
                                            >
                                                <div
                                                    component={"span"}
                                                    className={baseClasses.progress_title}
                                                    variant="p"
                                                >
                                                    Progress
                                                </div>
                                                <div className={baseClasses.grow} />

                                                <Button
                                                    variant="contained"
                                                    className={baseClasses.button}
                                                    onClick={goToTabProgress}
                                                    endIcon={
                                                        <ArrowForwardIosIcon className={baseClasses.fontIcons} />
                                                    }
                                                    style={{
                                                        color: "gray",
                                                        fontSize: 12,
                                                        boxShadow: "unset",
                                                        textTransform: "none",
                                                        padding: "1px 18px",
                                                        paddingRight: "8px",
                                                        backgroundColor: "transparent",
                                                    }}
                                                >
                                                    View All
                                                </Button>
                                            </div>
                                            <div className={baseClasses.row}>
                                                <Divider
                                                    className={baseClasses.fullWidth}
                                                    style={{ marginBottom: 8 }}
                                                    orientation="horizontal"
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    maxHeight: 150,
                                                    overflow: "auto",
                                                    marginBottom: 13,
                                                    padding: "0px 4px 3px 14px",
                                                    cursor: "text",
                                                    width: "100%",
                                                }}
                                            >
                                                {!_.isEmpty(clientNotesData) ? (
                                                    clientNotesData.map((item, index) => (
                                                        <Tooltip
                                                            placement={'top-start'}
                                                            title={<TextFormattingWrapper
                                                                style={{ cursor: "pointer" }}
                                                                value={item.noteDescription}
                                                                onClick={() => {
                                                                    setDialogContent(item.noteDescription)
                                                                    setIsDialogOpen(true)
                                                                }}
                                                            />}
                                                        >
                                                            <div
                                                                key={index}
                                                                className={baseClasses.row_time}
                                                            //onMouseEnter={() => {
                                                            //    setIsToolTip(true);
                                                            //    setTooltipData(item.noteDescription);
                                                            //}}
                                                            //onMouseLeave={() => {
                                                            //    setIsToolTip(false);
                                                            //    setTooltipData(item.noteDescription);
                                                            //}}
                                                            >
                                                                <div
                                                                    component={"span"}
                                                                    className={baseClasses.progress_description}
                                                                    variant="p"
                                                                    style={{
                                                                        width: "calc(100%-100px)",
                                                                        height: "18px",
                                                                        overflow: "hidden",
                                                                    }}
                                                                >
                                                                    <TextFormattingWrapper
                                                                        value={item.noteDescription}
                                                                    />
                                                                </div>
                                                                {/* <div className={classes.grow} /> */}
                                                                {/* <div component={'span'} className={classes.progress_time}>{item.noteDescription}</div> */}
                                                                <div
                                                                    component={"span"}
                                                                    className={baseClasses.progress_time}
                                                                    style={{ width: "80px", marginLeft: "20px" }}
                                                                >
                                                                    {global._DateField(item.noteDate)}
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    ))
                                                ) : (
                                                    <div className={classes.no_data}>
                                                        <Typography className={classes.no__data__typgoraphy}>
                                                            NO DATA
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        ) : null}
                        {todosPermissions.canView && <div className={clsx(baseClasses.row, baseClasses.mrtop12)}>
                            <Card className="w-100">
                                <CardContent
                                    classes={{ root: baseClasses.contentCard }}
                                    className="p-0"
                                >
                                    <div
                                        style={{
                                            paddingBottom: "0.85rem",
                                        }}
                                    >
                                        <Grid container alignItems="center" className={classes.root}>
                                            <div
                                                className={baseClasses.row}
                                                style={{
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    paddingTop: 10,
                                                }}
                                            >
                                                <div
                                                    component={"span"}
                                                    className={baseClasses.progress_title}
                                                    variant="p"
                                                >
                                                    To Do
                                                </div>
                                                <div className={baseClasses.grow} />
                                                <Button
                                                    variant="contained"
                                                    className={baseClasses.button}
                                                    onClick={goToTabTodo}
                                                    endIcon={
                                                        <ArrowForwardIosIcon className={baseClasses.fontIcons} />
                                                    }
                                                    style={{
                                                        color: "gray",
                                                        fontSize: 12,
                                                        boxShadow: "unset",
                                                        textTransform: "none",
                                                        padding: "1px 18px",
                                                        paddingRight: "8px",
                                                        backgroundColor: "transparent",
                                                    }}
                                                >
                                                    View All
                                                </Button>
                                            </div>
                                            <div className={baseClasses.row}>
                                                <Divider
                                                    className={baseClasses.fullWidth}
                                                    orientation="horizontal"
                                                />
                                            </div>

                                            <div
                                                className="w-100"
                                                style={{
                                                    maxHeight: 220,
                                                    overflow: "auto",
                                                    marginBottom: 5,
                                                    //maxHeight: 400,
                                                    cursor: "text",
                                                    padding: "5px 13px 5px 13px",
                                                }}
                                            >
                                                <div className={classes.search}>
                                                    <div className={classes.searchIcon}>
                                                        <SearchIcon className={classes.searchIconFont} />
                                                    </div>
                                                    <InputBase
                                                        placeholder="Filter"
                                                        value={query}
                                                        onChange={handleSearchFilter}
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput,
                                                        }}
                                                        inputProps={{ "aria-label": "search" }}
                                                    />
                                                    {query === "" ? null : (
                                                        <div
                                                            onClick={_handleClearButton}
                                                            className={classes.searchIcon}
                                                        >
                                                            <HighlightOffIcon />
                                                        </div>
                                                    )}
                                                </div>

                                                <div
                                                    className={classes.row_todo}
                                                    style={{ marginTop: "9px", marginBottom: "20px" }}
                                                >
                                                    <FormControl component="fieldset" className="w-100">
                                                        {
                                                            !_.isEmpty(filterClientToDo) &&
                                                            filterClientToDo.map((item, index) => (
                                                                <div
                                                                    key={index}
                                                                    onClick={() => {
                                                                        //handleTodoRowClick();
                                                                        //handleTodoRowEditData(item);
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={baseClasses.row}
                                                                        onClick={() => {
                                                                            // onOpen = { handleAddButton };
                                                                            // onEditData = { handleEditData };
                                                                        }}
                                                                    >
                                                                        <div className={baseClasses.description}>
                                                                            <TextFormattingWrapper
                                                                                style={{ width: "25%" }}
                                                                                className={clsx(
                                                                                    baseClasses.columnTodo,
                                                                                    baseClasses.fns12
                                                                                )}
                                                                                value={item.title}
                                                                            />
                                                                            <div
                                                                                style={{ width: "50%" }}
                                                                                className={clsx(
                                                                                    baseClasses.columnTodo,
                                                                                    baseClasses.fns12
                                                                                )}
                                                                            >
                                                                                {item.assignee}
                                                                            </div>
                                                                            <div
                                                                                className={clsx(
                                                                                    baseClasses.columnTodo,
                                                                                    baseClasses.fns12
                                                                                )}
                                                                                style={{ width: "calc(100%-100px" }}
                                                                            >
                                                                                {item.due_date}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={baseClasses.row}>
                                                                        <Divider
                                                                            className={baseClasses.fullWidth}
                                                                            orientation="horizontal"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))

                                                            // filterClientToDo.map((item2, index2) => (
                                                            //   <div key={index2}>
                                                            //     <FormGroup
                                                            //       aria-label="position"
                                                            //       row
                                                            //       className="baseClasses.todo_background"
                                                            //     >
                                                            //       <FormControlLabel
                                                            //         value="end"
                                                            //         className={baseClasses.todo_label}
                                                            //         control={<Checkbox color="primary" />}
                                                            //         label={item2.title}
                                                            //         labelPlacement="end"
                                                            //       />
                                                            //     </FormGroup>
                                                            //   </div>
                                                            // ))
                                                        }
                                                    </FormControl>
                                                </div>
                                                {false && (
                                                    <div
                                                        className={clsx(baseClasses.completed, baseClasses.mrtop12)}
                                                    >
                                                        <Button
                                                            className={baseClasses.task_button}
                                                            color="primary"
                                                            variant="contained"
                                                        >
                                                            View Completed Task
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                        }
                    </div>
                </div>

                <AddToDo
                    isOpenCreateModal={isOpenCreateModal}
                    onCloseModal={handleCloseModal}
                    editData={editData}
                />
                {isToolTip && (
                    <div
                        className="menu-container"
                        style={{
                            top: yPos,
                            left: xPos,
                            position: "absolute",
                            width: 200,
                            backgroundColor: "#707070",
                            borderRadius: 5,
                            padding: "2px 7px 4px 7px",
                            color: "white",
                            fontSize: "12px",
                        }}
                    >
                        {tooltipData}
                    </div>
                )}
                <ViewDetailsDialog
                    open={isDialogOpen}
                    handleClose={() => { setIsDialogOpen(false) }}
                    value={dialogContent}
                />
            </div>
        )
    }

    return (
        <DivContainer>
            {exploringType === explorerTypesConst.yeshivas && getYeshivaGeneralTab()}
            {exploringType === explorerTypesConst.employees && clientData && getEmployeeGeneralTable()}
            {exploringType === explorerTypesConst.clients && getClientGeneralTab()}
        </DivContainer>
    )
}
