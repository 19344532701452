import { action, thunk } from "easy-peasy";
import { getYeshivasList} from "../api/yeshivas";


const defaultStore = {
    
    yeshivas: [],
    currentPage: 0,
    setCurrentPage: action((state, page) => {
        state.currentPage = page;
    }),
    updateYeshivas: action((state, yeshivas) => {
        state.yeshivas = yeshivas;
        state.yeshivasListFiltered = yeshivas
    }),
    query: "",
    setQuery: action((state,data) => {
        state.query = data;
     }),
    yeshivasListFiltered: [],
    setFilteredYeshivas: action((state, yeshivas) => {
        state.yeshivasListFiltered = yeshivas;
    }),
    fetchYeshivas: thunk(actions => {
        getYeshivasList()
        .then(yeshivas => actions.updateYeshivas(yeshivas));
    })
};
export default defaultStore;
