import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Stack, Grid, Button, Tooltip, Accordion, AccordionSummary, AccordionDetails, FormControl, TextField, Select, InputLabel, MenuItem} from '@mui/material';
import { DivContainer, FlexContainer, FlexJustifyCenter, FlexJustifyEnd, FlexBetween, Flex } from '../../assets/styles/styledComponents';
import { dateTime } from '../../_helpers/datetime';
import WeekSwitcher from '../../components/common/DateSwitchers/WeekSwitcher';
import SelectEditor from '../../components/common/UI/Inputs/Select/SelectEditor';
import TimeEditor from '../../components/common/UI/Inputs/TimeDate/TimeEditor';
import { weekDays } from '../../constants';
import useBaseStyle from '../../assets/styles';
import { outlinedInput } from '../../assets/styles/styles';
import { TimeRangeValidator } from '../../_helpers/schedule/validators/client-schedule'; 
import { WeekSwitcherWeekRange } from '../../assets/types'
import FeaturedIcon from '../../components/common/FeaturedIcon';
import useConfirm from '../../hooks/useConfirm';
import { getAllTimesheetClientsByProvider, getAllTimesheetProviders, getAllTimesheetSchedules } from '../../api/timesheet';
import useUnsavedChangesWarn from '../../hooks/useUnsavedChangesWarn';

type AllTimesheetProps  = {
    layout?: string
}

const compLayout = {
    column: 'column',
    accordion: 'accordion'
}

const entries = {
    Sunday: [
       
    ],
    Monday: [
      
    ],
    Tuesday: [

    ],
    Wednesday: [

    ],
    Thursday: [

    ],
    Friday: [

    ],
    Saturday: [

    ]
}

const dayEntryStructure = {
    data: null,
    clockIn: null,
    clockOut: null,
    serviceId: null,
    scheduleId: null,
    timeError: null,
    entryError: null,
    clockInMode: 'edit',
    clockOutMode: 'edit',
    serviceMode: 'edit'
}

const providerListConst = [
    {
        providerName: 'mjan',
        id: '1'
    },
    {
        providerName: 'mayer',
        id: '2'
    },
    {
        providerName: 'john',
        id: '3'
    },
    {
        providerName: 'pete',
        id: '4'
    },
    {
        providerName: 'smith',
        id: '5'
    },

]

const clientListConst = [
    {
        clientName: 'mjan',
        providerId: '4',
        id:'12'
    },
    {
        clientName: 'mayer',
        providerId: '2',
        id:'13'
    },
    {
        clientName: 'john',
        providerId: '3',
        id:'14'
    },
    {
        clientName: 'pete',
        providerId: '2',
        id:'15'
    },
    {
        clientName: 'smith',
        providerId: '2',
        id:'16'
    },
]

type clientProviderType = {
    clientName?: string,
    providerName?: string,
    providerId?: string,
    clientId?: string,
    id: string 
}

type DayEntryProps = {
    day: string,
    entriesIndex: number,
    entry: any,
    layout: string,
    handleChangeField: Function,
    handleRemoveEntry: Function,
    onModeChange: Function
}

type entryType = {
    clockIn: string,
    clockOut: string,

}

const DayEntry = ({day, entriesIndex, entry, handleChangeField, handleRemoveEntry, layout, onModeChange}: DayEntryProps) => {
    return (
        <Grid container sx={{background: '#eaeaea', padding:"2px", marginBottom: '5px', borderRadius: '4px', border: entry.entryError ? '1px solid red' : 'unset'}}>
            { 
                <Grid item xs={12}>
                    <FlexJustifyEnd>
                       {entry.entryError && <Tooltip title={entry.entryError}>
                            <DivContainer>
                                <FeaturedIcon
                                    icon="Info"
                                    color="error"
                                />
                            </DivContainer>
                        </Tooltip>}
                        <FeaturedIcon
                            icon="Cross"
                            onClick={() => {
                                handleRemoveEntry(day, entriesIndex)
                            }}
                        />
                    </FlexJustifyEnd>
                </Grid>
            }
            <Grid item xs={layout === compLayout.column ? 12 : 6}>
                <TimeEditor
                    margin="10px 2px"
                    label="In"
                    name="clockIn"
                    mode={entry.clockInMode}
                    style={outlinedInput}
                    onChange={(newValue: string , name: string) =>  handleChangeField(newValue, name, day, entriesIndex)}
                    value={entry.clockIn}
                    error={!!entry.error}
                    helperText={entry.error?.startTime}
                    handleMode={(mode: 'edit' | 'read') => onModeChange(mode, entriesIndex, day, "clockInMode")}
                    editable={true}
                    disablePicker
                    fullWidth
                />
            </Grid>
            <Grid item xs={layout === compLayout.column ? 12 : 6}>
                <TimeEditor
                    margin="10px 2px"
                    label="Out"
                    mode={entry.clockOutMode}
                    name="clockOut"
                    editable={true}
                    style={outlinedInput}
                    onChange={(newValue: string , name: string) =>  handleChangeField(newValue, name, day, entriesIndex)}
                    value={entry.clockOut}
                    error={!!entry.error}
                    helperText={entry.error?.endTime}
                    handleMode={(mode: 'edit' | 'read') => onModeChange(mode, entriesIndex, day, "clockOutMode")}
                    fullWidth
                    disablePicker
                />
            </Grid>
            <Grid item xs={12}>
                <SelectEditor
                    label="Service"
                    margin="10px 2px"
                    name="service"
                    mode={entry.serviceMode}
                    options={[
                        {label: 'abc', value: 'abc'}
                    ]}
                    handleMode={(mode: 'edit' | 'read') => onModeChange(mode, entriesIndex, day, "serviceMode")}
                    style={outlinedInput}
                    value={entry.service}
                    onChange={(newValue: string , name: string) =>  handleChangeField(newValue, name, day, entriesIndex)}
                    fullWidth
                    editable
                />
            </Grid>
        </Grid>
    )
}

const AllTimesheet: React.FC<AllTimesheetProps> = ({layout=compLayout.column}) => {
    const baseClasses: any = useBaseStyle();
    const {confirm} = useConfirm();
    const [changesHelper] = useUnsavedChangesWarn();
    const { setClean, hasChanges } = changesHelper;
    const [weekRanges, setWeekRanges] = useState([]);
    const [clientList, setClientList] = useState<clientProviderType[]>([]);
    const [providerList, setProviderList] = useState<clientProviderType[]>([]);
    const [dateRange, setDateRange] = useState({
        minDate: new Date('12/12/2022'),
        maxDate: new Date()
    })
    const [selectedDate, setSelectedDate] = useState<string | Date>(new Date().toISOString());
    const [selectedWeek, setSelectedWeek] = useState<any>({
        label:null,
        start: new Date(),
        end: new Date()
    });
    const [selectedClient, setSelectedClient] = useState<clientProviderType>();
    const [selectedProvider, setSelectedProvider] = useState<clientProviderType>();
    const [selectedEntries, setSelectedEntries] = useState(entries);

    useEffect(() => {
        const ranges: any = dateTime.getWeekRanges(new Date(), dateRange.minDate, dateRange.maxDate)
        setWeekRanges(ranges ?? [])
    }, [])

    /**
     * On changing month, regenrating month ranges and selecting them to state
     * @param slcMoYr 
     */
    const handleOnChangeMonth = (slcMoYr: string | Date) => {
        if (hasChanges()) {
            if (!window.confirm("You have some unsaved changes, do you want to discard them?")) {
                return;
            }
        }
        setSelectedDate(slcMoYr);
        const weeksTemp: any = dateTime.getWeekRanges(slcMoYr, dateRange.minDate, dateRange.maxDate)
        setWeekRanges(weeksTemp)
        const week = weeksTemp.findLast((week: WeekSwitcherWeekRange) => dateTime.isWeekActive(week,  dateRange.minDate, dateRange.maxDate) && week.label !== selectedWeek?.label)
        setSelectedWeek(week ?? null)
        setClean()
    }

     /**
     * 
     * @param dir 
     * @returns 
     */
    const handleWeekSwitch = (dir: string) => {
        const indexOfSelected = weekRanges.findIndex((week: WeekSwitcherWeekRange) => week.label === selectedWeek?.label)
        switch (dir) {
            case 'left':
                if (indexOfSelected === 0 && selectedWeek.start > new Date(dateRange.minDate)) {
                    handleOnChangeMonth?.(selectedWeek.start)
                    return 
                } 
                dateTime.isWeekActive(weekRanges[indexOfSelected - 1],  dateRange.minDate, dateRange.maxDate) && handleChangeWeek(weekRanges[indexOfSelected - 1])
                break;
            case 'right':
                if(indexOfSelected + 1 >= weekRanges.length && !handleDisableSwitch('right')) {
                    handleOnChangeMonth?.(selectedWeek.end)
                    return
                }
                dateTime.isWeekActive(weekRanges[indexOfSelected + 1],  dateRange.minDate, dateRange.maxDate) && handleChangeWeek(weekRanges[indexOfSelected + 1])
                break;
            default:
                break;
        }
    }

    /**
     * 
     * @param week active week
     */
    const handleChangeWeek = (week: WeekSwitcherWeekRange) => {
        let checkDate = new Date(selectedDate)
        if (week?.start.getMonth() < checkDate.getMonth() ) {
            handleOnChangeMonth(week.start)
        }
        if (week?.end?.getMonth() > checkDate.getMonth()) {
            handleOnChangeMonth(week.end)
        }
        setSelectedWeek(week)
        
    }

    const handleSelectClient = (value: clientProviderType) => {
        setSelectedClient(value)
    }

    const handleSelectProvider = (value: clientProviderType) => {
        setSelectedProvider(value)
    }

    const handleDisableSwitch = (dir: string) => {
        if (!selectedWeek) return true
        switch(dir) {
            case 'left': {
               let d = new Date(selectedWeek?.end);
               d.setDate(d.getDate() - 6);
               return d < new Date(dateRange.minDate) ? true : false
            }
            case 'right': {
               let d = new Date(selectedWeek?.start);
               let end = new Date(dateRange.maxDate) > new Date() ? new Date() : dateRange.maxDate
               d.setDate(d.getDate() + 6);
               return d > new Date(end) ? true : false
            }
            default: return false;
        }
    }

    const handleChangeEntries = (value: string, name: string, day: string, entriesIndex: number) => {
        let valResult: any = null;
        if (name === 'clockIn' || name=== 'clockOut') {
            valResult = validateTimeFields({startTime: name === 'clockIn' ? value : selectedEntries[day as keyof typeof selectedEntries][entriesIndex]['clockIn'], endTime: name === 'clockOut' ? value : selectedEntries[day as keyof typeof selectedEntries][entriesIndex]['clockOut']}, false, false);
        }
        setSelectedEntries({
            ...selectedEntries,
            [day]: selectedEntries[day as keyof typeof selectedEntries].map((entry: any, index: number) => {
                if (index === entriesIndex) {
                    return {
                        ...entry,
                        [name]: value,
                        error: !!valResult && !valResult?.isValid ? valResult?.fieldMessages : ''
                    }
                }
                return entry
            })
        })
        //TODO: Save entries here
    }

    const validateTimeFields = (timeFields: any, requireStartTime=true, requireEndTime=true) => {
        const timeRangeValidator = new TimeRangeValidator(timeFields.startTime, timeFields.endTime, {
            requireStartTime: requireStartTime,
            requireEndTime: requireEndTime
        });
        timeRangeValidator.validate();;
        return timeRangeValidator;
    }

    const handleAddingNewEntry = (day: string) => {
        setSelectedEntries({
            ...selectedEntries,
            [day]: [
                ...selectedEntries[day as keyof typeof selectedEntries],
                dayEntryStructure
            ]
        })
    }

    const isAddNewDisabled = (day: string) => {
        if (selectedEntries[day as keyof typeof selectedEntries].length < 1) return false;
        return selectedEntries[day as keyof typeof selectedEntries].some((entry: any) => !entry.clockIn && !entry.clockOut && !entry.error && !entry.service)
    }

    const handleRemoveEntry = async (day: keyof typeof selectedEntries, entriesIndex: number) => {
        const isConfirmed = await confirm(true, [], '', `${day} Entry`, entriesIndex, 'delete');
        if (isConfirmed) {
            setSelectedEntries({
                ...selectedEntries,
                [day]: selectedEntries[day].filter((entry, index: number) => index !== entriesIndex)
            })
        }
    }

    // const handleSaveEntries = () => {
    //     setMode('read')
    // }

    const loadProviders = () => {
        getAllTimesheetProviders().then((data) => {
            setProviderList(providerListConst)
        })
    }

    const loadClients = () => {
        getAllTimesheetClientsByProvider(selectedProvider?.id).then((data) => {
            setClientList(clientListConst)
        })
    }

    const loadProvider = () => {
        getAllTimesheetProviders().then((data) => {
            setClientList(providerListConst)
        })
    }

    const loadSchedules = () => {
        getAllTimesheetSchedules(selectedClient?.id, selectedProvider?.id, selectedWeek).then((data) => {

        })
    }

    const getClientList = () => {
       return  clientList.filter((client: clientProviderType) => client.providerId === selectedProvider?.id).map((client: clientProviderType) => {
            return {
                label: client.clientName,
                value: client.clientId
            }
        })
    }

    const onModeChange = (mode: 'edit' | 'read', entriesIndex: number, day: string, field: string) => {
        setSelectedEntries({
            ...selectedEntries,
            [day]: selectedEntries[day as keyof typeof selectedEntries].map((entry: any, index: number) => {
                if (index === entriesIndex  &&  ( field === 'serviceMode' || ((field === 'clockInMode' || field === 'clockOutMode')  && !entry.error))) {
                    return {
                        ...entry,
                        [field]: mode
                    }
                }
                return entry
            })
        })
    }

  return (
    <DivContainer padding="10px 10px">
        <div className={baseClasses.toolbar} />
        <DivContainer>Timesheets</DivContainer>
        <FlexBetween width="100%">
            <Flex width="30%">
                <FormControl fullWidth sx={{margin: '0px 2px'}}>
                    <InputLabel>Provider</InputLabel>
                        <Select
                            value={selectedProvider}
                            label={"Provider"}
                            onChange={(event) => {handleSelectProvider(event.target.value as clientProviderType)}
                        }
                            fullWidth={true}
                            sx={{...outlinedInput, }}
                        >
                            {
                                providerList?.length > 0 &&
                                providerList.map((option: any) => (
                                        <MenuItem value={option}>{option.providerName}</MenuItem>
                                ))
                            }
                        </Select>
                </FormControl>
                <FormControl fullWidth sx={{margin: '0px 2px'}}>
                    <InputLabel>Client</InputLabel>
                        <Select
                            value={selectedClient}
                            label={"Client"}
                            onChange={(event) => {
                                handleSelectClient(event.target.value as clientProviderType)
                            }
                        }
                            fullWidth={true}
                            sx={{...outlinedInput}}
                        >
                            {
                            clientList?.length > 0 &&
                            clientList.map((option: any) => (
                                    <MenuItem value={option}>{option.clientName}</MenuItem>
                                ))
                            }
                        </Select>
                </FormControl>
            </Flex>
            <Flex width="30%">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Select Month"
                                value={selectedDate}
                                views={["year", "month"]}
                                openTo="month"
                                minDate={dateRange.minDate.toISOString()}
                                maxDate={dateRange.maxDate.toISOString()}
                                onChange={(newValue: string | null) => {
                                    handleOnChangeMonth(newValue as string);
                                }}
                                renderInput={(params) => <TextField sx={{...outlinedInput}} {...params} />}
                            />
                        </Stack>
                </LocalizationProvider>
                <WeekSwitcher label="Select Week"  height="45px" style={{margin: '0px 5px'}} handleWeekSwitch={handleWeekSwitch} disableSwitch={{ left: handleDisableSwitch('left'), right: handleDisableSwitch('right') }} handleChangeWeek={handleChangeWeek} selectedWeek={selectedWeek} minDate={new Date()} maxDate={new Date()} weeks={weekRanges ?? []}/>
            </Flex>
        </FlexBetween>
        {/* <FlexJustifyEnd>
                {
                    mode === 'edit' ?
                    <Button onClick={handleSaveEntries}>Save</Button> :
                    <FeaturedIcon
                        icon="Edit"
                        onClick={() => {
                            setMode('edit')                    
                        }}
                    />
                }
        </FlexJustifyEnd> */}
        <DivContainer style={{height: '700px'}}>
            <Grid container spacing={layout === compLayout.column ? .2 : 2}>
            {layout === compLayout.column ? 
                <React.Fragment>
                    {weekDays.map((day: string) => {
                        return (
                            <Grid item xs={1.7}>
                                <FlexContainer justify='center' alignItem="center">{day}</FlexContainer>
                                    {selectedEntries[day as keyof typeof selectedEntries].map((entry: any, index) => {
                                        return ( 
                                            <DayEntry onModeChange={onModeChange} layout={layout} day={day} entry={entry} entriesIndex={index} handleRemoveEntry={handleRemoveEntry} handleChangeField={handleChangeEntries} />
                                        )
                                    })}
                                <FlexJustifyCenter>
                                    <Button disabled={isAddNewDisabled(day)} onClick={() => handleAddingNewEntry(day)}>
                                        Add New 
                                    </Button>
                                </FlexJustifyCenter>
                            </Grid>
                        )
                    })}
                </React.Fragment> :
                <React.Fragment>
                    {weekDays.map((day: string) => {
                        return (
                            <Grid item xs={4}>
                                <Accordion>
                                    <AccordionSummary>
                                        <FlexContainer justify='center' alignItem="center">{day}</FlexContainer>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                            {selectedEntries[day as keyof typeof selectedEntries].map((entry: any, index) => {
                                                return ( 
                                                    <DayEntry onModeChange={onModeChange} day={day} layout={layout} entry={entry} entriesIndex={index} handleRemoveEntry={handleRemoveEntry} handleChangeField={handleChangeEntries} />
                                                )
                                            })}
                                        <FlexJustifyCenter>
                                            <Button disabled={isAddNewDisabled(day)} onClick={() => handleAddingNewEntry(day)}>
                                                Add New 
                                            </Button>
                                        </FlexJustifyCenter>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )
                    })}
                </React.Fragment>
            }
            </Grid>
        </DivContainer>
    </DivContainer>
  )
}

export default AllTimesheet
