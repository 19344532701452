import {
    SYSTEM_AUDITLOG_DETAIL, SYSTEM_AUDITLOG_LIST,
    SYSTEM_LISTS_DETAIL, SYSTEM_LISTS_LIST,
    SYSTEM_LISTS_SAVE,
    SYSTEM_LISTVALUES_LIST,
    SYSTEM_LISTVALUES_LIST2,
    SYSTEM_LISTVALUES_SAVE,
    //SYSTEM_SETTINGS_LIST,
    SYSTEM_SETTINGS_SAVE,
    GET_SYSTEM_SETTINGS_BY_GROUP,
    GET_DATA_GRID_VIEW_LIST,
    ADD_DATA_GRID_VIEW,
    UPDATE_DATA_GRID_VIEW,
    DELETE_DATA_GRID_VIEW,
    BASE_URL
} from "../constants";

export let getAuditLogs = (refTable, refId) => {
    let yeshivas_data = [];
    const audits = [];
    let refTable2 = refTable.slice(0, refTable.length - 1);

    return new Promise((resolve, rejects) => {
        let url = SYSTEM_AUDITLOG_LIST.replace("{table}", refTable2);
        global.fetchWrapper.get(url + refId)
            .then(
                (data) => {
                    yeshivas_data = data;
                    yeshivas_data.forEach((item, index) => {
                        audits.push({
                            id: item.id,
                            description: item.description,
                            oldJsonData: item.oldJsonData,
                            newJsonData: item.newJsonData,
                            eventType: item.eventType,
                            auditDate: item.auditDate,
                            refTable: item.refTable,
                            refId: item.refId,
                            userName: item.userName, //item.user ? item.user.userName : "",
                        });
                    });
                    resolve(audits);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let getAuditLogDetail = (id) => {
    let detailOfyeshivas;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(SYSTEM_AUDITLOG_DETAIL + id)
            .then(
                (data) => {
                    global.log(data);
                    resolve(true);
                    //   if (data.responseCode === 200) {
                    //     resolve(data.result);
                    //   } else {
                    //     resolve(true);
                    //   }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    resolve(true);
                }
            );
    });
};

export let getSystemLists = () => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(SYSTEM_LISTS_LIST)
            .then(
                (data) => {
                    resolve(data.result);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let getSystemListDetail = (id) => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(SYSTEM_LISTS_DETAIL + id)
            .then(
                (data) => {
                    resolve(data.result);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let saveSystemList = (id, data) => {
    return global.fetchWrapper.post(SYSTEM_LISTS_SAVE + id, data).then(
        (response) => {
            global.log("response", response);
        },
        (error) => {
            global.log("error", error);
            global.AjaxFailToast();
        }
    );
};
export let getSystemListValuesListByName = (name) => {
    const destArray = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(SYSTEM_LISTVALUES_LIST2 + name)
            .then(
                (data) => {
                    data.result.forEach((item, index) => {
                        destArray.push(item.value);
                    });

                    resolve(destArray);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let getSystemListValuesListByName2 = (name) => {
    const destArray = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(SYSTEM_LISTVALUES_LIST2 + name)
            .then(
                (data) => {
                    data.result.forEach((item, index) => {
                        destArray.push({ id: item.id, name: item.value });
                    });

                    resolve(destArray);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

global.getSystemListValuesListByName = getSystemListValuesListByName2;

export let getSystemListValuesList = (id) => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(SYSTEM_LISTVALUES_LIST + id)
            .then(
                (data) => {
                    resolve(data.result);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let saveSystemListValuesList = (data) => {
    return global.fetchWrapper.post(SYSTEM_LISTVALUES_SAVE, data).then(
        (response) => {
        },
        (error) => {
            global.log("error", error);
            global.AjaxFailToast();
        }
    );
};

//export let getSystemSettingList = () => {
//    return new Promise((resolve, rejects) => {
//        global.fetchWrapper.get(SYSTEM_SETTINGS_LIST)
//            .then(
//                (data) => {
//                    resolve(data.result);
//                },
//                (rejects) => {
//                    global.AjaxFailToast();
//                    global.log("rejects");
//                }
//            );
//    });
//};

//export let saveSystemSettingList = (data) => {
//    return global.fetchWrapper.post(SYSTEM_SETTINGS_SAVE, data).then(
//        (response) => {
//        },
//        (error) => {
//            global.log("error", error);
//            global.AjaxFailToast();
//        }
//    );
//};

export const saveSetting = (data) => {
    return global.fetchWrapper.post(SYSTEM_SETTINGS_SAVE, data).then(
        (response) => {
            global.log("response", response);
            global.AjaxSaved(response.message)
        },
        (error) => {
            global.log("error", error);
            global.AjaxFailToast();
        }
    );
};

export let getSettingByGroup = (group) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_SYSTEM_SETTINGS_BY_GROUP + "?group=" + group)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.AjaxFailToast();
                    global.log("rejects");
                }
            );
    });
};

/**
 * 
 * @param {string} dataGridName 
 * @returns 
 */
export const getDataGridViewList = (dataGridName) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_DATA_GRID_VIEW_LIST + dataGridName)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const addDataGridView = (payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(ADD_DATA_GRID_VIEW, payload)
            .then((data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const updateDataGridView= (payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .put(UPDATE_DATA_GRID_VIEW, payload)
            .then((data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const deleteDataGridView = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .delete(DELETE_DATA_GRID_VIEW + id)
            .then((data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const testCall = () => {
    global.fetchWrapper.get(BASE_URL + 'api/dashboardcards/scheduleStatusAverage')
        .then(
            (data) => {
                global.log(data);
            },
            (rejects) => {
                global.log(rejects);
            }
        );
}