import React, { useEffect } from "react";
import {useStoreState} from 'easy-peasy';
import {
    Checkbox, Dialog, FormControl,
    FormControlLabel, TextField, Fade, Menu, MenuItem
} from "@mui/material";
import _ from 'lodash';
import {Button} from '../../UI'
import useBaseStyles from '../../../../assets/styles';
import {AddNewViewType} from './types';
import { Bold, BoldTitle, DivContainer, FlexAlignCenter, FlexJustifyEnd } from "../../../../assets/styles/styledComponents";
import { SYSTEM_VIEW_STRUCT, defaultQuickFilters } from '../../../../constants'
import { dataGridViewType } from '../../../../assets/types';
import useStyles from './style';

const AddNewView: React.FC<AddNewViewType> = ({openModal, currentView, gridName, handleDeleteView, handleClose}) => {
    const classes = useStyles();
    const baseClasses: any = useBaseStyles();
    const [fields, setFields] = React.useState({
        viewName: '',
        isDefault: false,
        dataGridName: ''
      });
    const DBDataGridViews = useStoreState((state: any) => state.dataGridSettings.DBDataGridViews);
    const dataGridViews = useStoreState((state: any) => state.dataGridSettings.dataGridViews);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const isMainView = currentView?.viewName === SYSTEM_VIEW_STRUCT.viewName

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopup = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setFields({
            dataGridName: gridName,
            isDefault: currentView?.isDefault,
            viewName: isMainView ? '' : currentView?.viewName
        })

    }, [openModal])
    
    const getDefaultView = () => {
        const defaultView = dataGridViews[gridName]?.find((ele: dataGridViewType) => ele.isDefault)
        return defaultView ? defaultView?.viewName : SYSTEM_VIEW_STRUCT.viewName
    }

    const resetFields = () => {
        setFields({
            viewName: '',
            isDefault: false,
            dataGridName: ""
        });
        setNameError('');
        setAnchorEl(null)
    }

    const [nameError, setNameError] = React.useState<string>("")

    const handleFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;
        if (type === "checkbox") {
            setFields({ ...fields, [name]: event.target.checked });
        } else {
            setFields({ ...fields, [name]: value });
        }
    };

    const handleCloseModal = (data: dataGridViewType | null = null) => {
        resetFields();
        handleClose(data);
    }

    const hasChanges = (changes:any, mode: string) => {
        let originView = DBDataGridViews?.find((view: dataGridViewType) => view.viewName === currentView?.viewName && view.dataGridName === gridName)
        switch(mode) {
          case 'filter': {
            let filterSettings = {...changes, items: changes?.items.map((item: any) => {
              return {
                columnField: item.columnField,
                operator: item.operator ?? item.operatorValue,
                id: item.id,
                value: item.value,
                dataType: item.dataType,
                dataGridViewSettingId: item.dataGridViewSettingId,
                guidId: item.guidId,
                createdOn: item.createdOn,
                isDeleted: item.isDeleted,
              }
            })
            }
            return !_.isEqual(originView?.filterSettings, filterSettings)
          }
          case 'column': {
            return !_.isEqual(originView?.columnSettings ,  changes)
          } 
          case 'quickFilter': {
            return !_.isEqual(!!originView ? originView.quickFilters : defaultQuickFilters[gridName as keyof typeof defaultQuickFilters],  changes)
          }
          default: return null;   
        }
        
    }

    const submitFormData = (newView = false) => {
        const viewId = newView ? 0 : currentView.dataGridViewSettingId

        if (fields.viewName === "") {
            setNameError("Name can not be empty!");
            return
        }
        if (fields.viewName.toLowerCase() === "main") {
            setNameError(`${fields.viewName} is reserved for system defualt view, please change name and save again`);
            return;
        }

        // if (newView && fields.viewName !== currentView.viewName ) { // When saving as new view but didn't change the view name
        //     setNameError("New View name should be unique");
        //     return;
        // }

        // Checking if View Name already exists 
        if (currentView?.viewName !== fields.viewName &&
            dataGridViews[gridName]?.filter((eachView: dataGridViewType) => eachView.dataGridViewSettingId !== viewId).some((eachView: dataGridViewType) => eachView.viewName === fields.viewName)
            ) {
                setNameError("Name should be unique");
                return;
        }

        handleCloseModal({ ...currentView, ...fields, dataGridViewSettingId: viewId });
        resetFields()
        
    };

  return(
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={openModal}
            onClose={() => handleCloseModal()}
            aria-labelledby="max-width-dialog-title"
        
        >
                <div style={{ padding: 20 }}>
                    <div>
                        <DivContainer margin="20px 0px">
                            <BoldTitle fontSize = "16px" >{`${currentView?.viewName}`}</BoldTitle>
                        </DivContainer>
                        <FormControl
                           fullWidth 
                        >
                            <TextField
                                value={fields.viewName}
                                label={'Name'}
                                name="viewName"
                                onChange={handleFields}
                                error={!!nameError}
                                helperText={nameError}
                            />
                        </FormControl>
                        <FlexAlignCenter>
                            <FormControl
                            
                            >
                                <FormControlLabel
                                    style={{ padding: 0, margin: 0, marginLeft: -10 }}
                                    control={
                                        <Checkbox
                                            checked={fields.isDefault}
                                            onChange={handleFields}
                                            name="isDefault"
                                            color="primary"
                                        />
                                    }
                                    label="Set as Default"
                                />
                            </FormControl>
                            <DivContainer margin="0px 5px 0px 10px">Current: <Bold>{getDefaultView()} </Bold></DivContainer>
                        </FlexAlignCenter>

                        <FlexJustifyEnd>
                            <Button 
                                style={{color: '#000'}}
                                label ="Close"
                                size="medium"
                                onClick={(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleCloseModal()}
                            />
                            <Button 
                                label="SAVE"
                                id="fade-button"
                                size="medium"
                                disable={ fields.viewName === currentView?.viewName && 
                                          fields.isDefault === currentView?.isDefault && 
                                          !hasChanges(currentView?.columnSettings, "column") && 
                                          !hasChanges(currentView?.filterSettings, "filter") &&
                                          !hasChanges(currentView?.quickFilters, 'quickFilter')
                                        }
                                         
                                ariaControls={open ? 'fade-menu' : undefined}
                                ariaHaspopup="true"
                                ariaExpanded={open ? 'true' : undefined}
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>  isMainView || fields.viewName === currentView.viewName ? submitFormData(fields.viewName !== currentView.viewName) :  handleClick(e)}
                            />
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClosePopup}
                                TransitionComponent={Fade}
                            >
                                <MenuItem onClick={() => submitFormData()}>Save</MenuItem>
                                <MenuItem onClick={() => submitFormData(true)}>Save as New</MenuItem>
                            </Menu>
 
                            {!isMainView && 
                            <Button
                                label="Delete"
                                color="error"
                                size="medium"
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    handleDeleteView(currentView)
                                    resetFields();
                                }}
                             />
                            }
                            
                           
                        </FlexJustifyEnd>
                    </div>
                </div>
        </Dialog>
  )
}

export default AddNewView