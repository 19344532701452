import React from 'react';
import { useStoreState  } from 'easy-peasy';
import ToDo from '../../../components/explorer/Tabs/ToDo/ToDo';
import explorerStyles from "../../../components/explorer/styles";
import DetailHeader from '../DetailHeader';
import { DivContainer } from '../../../assets/styles/styles';
const TodoTask = () => {
  const todos = useStoreState((state: any) => state.dashboard.todos);
  const explorerClasses = explorerStyles();
  return (
    <DivContainer>
      <DetailHeader boxLabel='Tasks' boxIcon='ListAlt' badgeCount={todos.length} />
        <ToDo
          screen={'dashboard_task'}
          classes={explorerClasses}
          showFilterBy
        />
    </DivContainer>
  )
}

export default TodoTask