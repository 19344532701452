import React, {useEffect, useState} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Grid, Stack, TextField } from '@mui/material'
import { monthShortNames, weekDays, timesheetDaysTimeFrameConst } from '../../../constants'
import { getTimesheetEntries } from '../../../api/timesheet';

import {WeekSwitcherWeekRange, TimesheetEntriesType } from '../../../assets/types'
import WeekSwitcher from '../../common/DateSwitchers/WeekSwitcher';
import { TimeRangeValidator } from '../../../_helpers/schedule/validators/client-schedule';
import { SpacingBlock, DivContainer, FlexContainer, FlexExactCenter } from '../../../assets/styles/styledComponents';
import { TimesheetProps } from './type';
import TimeEditor from '../../common/UI/Inputs/TimeDate/TimeEditor';
import FeaturedIcon from '../../common/FeaturedIcon';
import Loader from '../../common/Loader';
import useUnsavedChangesWarn from '../../../hooks/useUnsavedChangesWarn';

const Timesheet: React.FC<TimesheetProps> = (props) => {
    const { loading, clientScheduleId, selectedDate, minDate, maxDate, weeksRange, selectedWeek, daysTimeFrame, setDaysTimeFrame, setSelectedWeek, setSelectedDate, setWeeksRange,activeDays} = props;
    const [changesHelper] = useUnsavedChangesWarn();
    const { setDirty, setClean, hasChanges } = changesHelper;
    const globalConst: any = global;
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        loadTimesheetEntires(clientScheduleId, selectedWeek?.start, selectedWeek?.end ?? new Date())
    }, [clientScheduleId, selectedWeek])

    useEffect(() => {
        if (!!selectedDate) {
            const weeksTemp = getWeekRanges(selectedDate);
            const week = weeksTemp.findLast((week: WeekSwitcherWeekRange) => isWeekActive(week))
            setSelectedWeek( week ?? null)
        }
    }, [])

    function hasTimesheetEntries () {
        for (const property in daysTimeFrame) {
            if (daysTimeFrame[property].startTime || daysTimeFrame[property].endTime) {
                return true;
            }
        }
        return false
    }

    function lastSunday(year: number, month: number) {
        const date = new Date(year,month,1,12);
        let weekday = date.getDay();
        let dayDiff = weekday===0 ? 7 : weekday;
        date.setDate(date.getDate() - dayDiff);
        return date
    }

    function addDays(theDate: Date, days: number) {
        return new Date(theDate.getTime() + days*24*60*60*1000);
    }

    function isLastDayOfMonth(date = new Date()) {
        // 👇️              ms    sec  min   hour
        const oneDayInMs = 1000 * 60 * 60 * 24;
      
        return new Date(date.getTime() + oneDayInMs).getDate() === 1;
    }

    const validateTimeFields = (timeFields: any, requireStartTime=true, requireEndTime=true) => {
        const timeRangeValidator = new TimeRangeValidator(timeFields.startTime, timeFields.endTime, {
            requireStartTime: requireStartTime,
            requireEndTime: requireEndTime
        });
        timeRangeValidator.validate();;
        return timeRangeValidator;
    }

    const isWeekActive = (week: WeekSwitcherWeekRange) => {
        let minTemp: Date = new Date(minDate);
        let maxTemp: Date = new Date(maxDate)
        if (maxTemp > new Date()) {
            maxTemp = new Date()
        }
        const start = new Date(week?.start.getFullYear(), week?.start.getMonth(), week?.start.getDate());
        const end = new Date(week?.end.getFullYear(), week?.end.getMonth(), week?.end.getDate());
        minTemp = new Date(minTemp.getFullYear(), minTemp.getMonth(), minTemp.getDate());
        maxTemp = new Date(maxTemp.getFullYear(), maxTemp.getMonth(), maxTemp.getDate());

        if (end < minTemp || start > maxTemp) return false;
        return true
    }

    /**
     * Generating Weeks of the selected month and setting them in state.
     * @param slcDate Date selected from month siwtch
     * @returns 
     */
    const getWeekRanges = (slcDate: Date | string | null) => {
        let date = new Date(slcDate as Date);
        let weeks: WeekSwitcherWeekRange[] | any = [];
        [1,2,3,4,5,6].map((eachWeek: number, index: number) => {
            let weekStart: string, weekEnd: string, d1: Date, d2: Date
            if (index === 0 ){ /* Handling previouse month last week*/
                d1 = lastSunday(date.getFullYear(), date.getMonth());
                d2 = addDays(d1, 6)
                weekStart = `${monthShortNames[(d1.getMonth()) < 0 ? monthShortNames.length - 1 : d1.getMonth()]} ${d1.getDate()}`;
                weekEnd   = `${monthShortNames[d2.getMonth()]} ${d2.getDate()}`
                const saveWeek = {
                    start: d1,
                    end: d2,
                    label: `${weekStart} - ${weekEnd}`,
                }
                weeks.push({
                    ...saveWeek,
                    disabled: !isWeekActive(saveWeek)
                })
                return null
            }

            d1 = addDays(weeks.at(-1).end, 1)
            d2 = addDays(d1, 6);
            if(d1.getMonth() > date.getMonth()) return null;
            weekStart = `${monthShortNames[d1.getMonth()]} ${d1.getDate()}`;
            weekEnd   = `${monthShortNames[d2.getMonth()]} ${d2.getDate()}`;
            const saveWeek = {
                start: d1,
                end: d2,
                label: `${weekStart} - ${weekEnd}`,
            }
            weeks.push({
                ...saveWeek,
                disabled: !isWeekActive(saveWeek)
            })
            return null
        })
        setWeeksRange(weeks)
        return weeks;
    }

    /**
     * On changing month, regenrating month ranges and selecting them to state
     * @param slcMoYr 
     */
    const handleOnChangeMonth = (slcMoYr: Date | string | null) => {
        if (hasChanges()) {
            if (!window.confirm("You have some unsaved changes, do you want to discard them?")) {
                return;
            }
        }
        setSelectedDate(slcMoYr);
        const weeksTemp = getWeekRanges(slcMoYr);
        const week = weeksTemp.findLast((week: WeekSwitcherWeekRange) => isWeekActive(week) && week.label !== selectedWeek?.label)
        setSelectedWeek(week ?? null)
        setClean()
    }

    const isDateWithinRange = (date: string | Date) => {
        const currentDate = new Date (date);
        const start = new Date(minDate);
        const end = maxDate ? new Date(maxDate) : new Date();
        if (currentDate >= start && currentDate <= end) return true
        return false
    }

    /**
     * 
     * @param week active week
     */
    const handleChangeWeek = (week: WeekSwitcherWeekRange) => {
        if (hasChanges()) {
            if (!window.confirm("You have some unsaved changes, do you want to discard them?")) {
                return;
            }
        }
        setDaysTimeFrame(timesheetDaysTimeFrameConst)
        let checkDate = new Date(selectedDate as Date)
        if (week?.start.getMonth() < checkDate.getMonth() && isDateWithinRange(week.start)) {
            handleOnChangeMonth(week.start)
        }
        if (week?.end?.getMonth() > checkDate.getMonth() && isDateWithinRange(week.end)) {
            handleOnChangeMonth(week.end)
        }
        setSelectedWeek(week)
        setClean()
    }
    
    const changeTimeFrom = (value: string | Date | null, timeField: string, dayField: string) => {
        setDirty()
        let timeSchedule: any = daysTimeFrame[dayField];
        timeSchedule = {
            ...timeSchedule,
            [timeField]: value
        }
        const valResult = validateTimeFields({startTime: timeSchedule.startTime, endTime:timeSchedule.endTime}, false, false);
        setDaysTimeFrame({
            ...daysTimeFrame,
            [dayField]: {
                ...timeSchedule,
                error: !valResult.isValid ? valResult.fieldMessages : ''
            }
        })
    }

    const inValidDay = (dayIndex: number) => {
        const end = new Date(maxDate) > new Date() ? new Date() : maxDate
        if (selectedWeek?.start < new Date(minDate)) {
            if (dayIndex < new Date(minDate)?.getDay()) {
                return true
            }
        }
        if (selectedWeek?.end > new Date(end)) {
            if (dayIndex > new Date(end)?.getDay()) {
                return true
            }
        }
        return false
    }

    /**
     * 
     * @param dir 
     * @returns 
     */
    const handleWeekSwitch = (dir: string) => {
        const indexOfSelected = weeksRange.findIndex((week: WeekSwitcherWeekRange) => week.label === selectedWeek?.label)
        switch (dir) {
            case 'left':
                if (indexOfSelected === 0 && selectedWeek.start > new Date(minDate)) {
                    handleOnChangeMonth?.(selectedWeek.start)
                    return 
                } 
                isWeekActive(weeksRange[indexOfSelected - 1]) && handleChangeWeek(weeksRange[indexOfSelected - 1])
                break;
            case 'right':
                if(indexOfSelected + 1 >= weeksRange.length && !handleDisableSwitch('right')) {
                    handleOnChangeMonth?.(selectedWeek.end)
                    return
                }
                isWeekActive(weeksRange[indexOfSelected + 1]) && handleChangeWeek(weeksRange[indexOfSelected + 1])
                break;
            default:
                break;
        }
    }

    /**
     * 
     * @param dir 
     * @returns 
     */
    const handleDisableSwitch = (dir: string) => {
        if (!selectedWeek) return true
        switch(dir) {
            case 'left': {
               let d = new Date(selectedWeek?.end);
               d.setDate(d.getDate() - 6);
               return d < new Date(minDate) ? true : false
            }
            case 'right': {
               let d = new Date(selectedWeek?.start);
               let end = new Date(maxDate) > new Date() ? new Date() : maxDate
               d.setDate(d.getDate() + 6);
               return d > new Date(end) ? true : false
            }
            default: return false;
        }
    }
    
    const loadTimesheetEntires = (id: number | string | undefined, d1: string | Date, d2: string | Date) => {
        if (!id || !d1) return 
        setIsLoading(true)
        getTimesheetEntries(id as string, globalConst.dateTime.getUSAFormattedDateString(d1), globalConst.dateTime.getUSAFormattedDateString(d2)).then((data: any) => {
            const dataToSave: any = {...timesheetDaysTimeFrameConst}
            data?.map((item: TimesheetEntriesType) => {
                const {clockIn, clockOut, date} = item;
                if (!clockIn && !clockOut) return
                let [clocInHours, clockInMinutes] = clockIn?.split(':') ?? [];
                let [clocOutHours, clockOutMinutes] = clockOut?.split(':') ?? [];
                const startTime = globalConst.dateTime.setTimeInDate(date, clocInHours, clockInMinutes);
                const endTime = globalConst.dateTime.setTimeInDate(date, clocOutHours, clockOutMinutes);
                
                dataToSave[weekDays[new Date(item.date).getDay()]] = {
                    ...dataToSave[weekDays[new Date(item.date).getDay()]],
                    startTime: !clockIn ? null : startTime.toISOString(),
                    endTime: !clockOut ? null : endTime.toISOString()
                }
            })
            setDaysTimeFrame(dataToSave)
            setIsLoading(false)
        })
    }

   return (
    <Grid container spacing={2}>
        <Loader loading= {isLoading || loading} />
        {
        !minDate ? 
            <FlexExactCenter>
                Enter Start-Date to edit timesheets
            </FlexExactCenter> :
            <React.Fragment>
                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Select Month"
                                value={selectedDate}
                                views={["year", "month"]}
                                openTo="month"
                                minDate={minDate}
                                maxDate={new Date(maxDate) > new Date() ? new Date().toISOString() : maxDate}
                                onChange={(newValue: string | null) => {
                                    handleOnChangeMonth(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                        {/* <HebrewMonthSwitcher
                            width='200px'
                            from={{
                                month: fromDate.month,
                                year: fromDate.year,
                            }}
                            to={{
                                month: toDate.month,
                                year:toDate.year,
                            }}
                            onChange={handleOnChangeMonth}
                            isLoading = {loading}
                        /> */}
                    
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid  item xs={5}>
                    <WeekSwitcher handleWeekSwitch={handleWeekSwitch} disableSwitch={{ left: handleDisableSwitch('left'), right: handleDisableSwitch('right') }} handleChangeWeek={handleChangeWeek} selectedWeek={selectedWeek} minDate={minDate} maxDate={maxDate} weeks={weeksRange}/>
                </Grid>
                <SpacingBlock marginTop="20px" />
                <Grid item xs={12}>
                    <DivContainer>
                        {weekDays.map((day: string, index:number) => {
                            let isDisable = activeDays.some(e => e === index)
                            let isInvalid = inValidDay(index) || !selectedWeek;
                            const disableClear = !isDisable || isInvalid || (!daysTimeFrame[day as keyof typeof daysTimeFrame]?.startTime && !daysTimeFrame[day as keyof typeof daysTimeFrame]?.endTime)
                            return(
                                <FlexContainer margin="2px 0px" alignItems='center'>
                                    <DivContainer width="150px" >
                                        {day }:
                                    </DivContainer>
                                    <FeaturedIcon
                                        icon="Cross"
                                        onClick={(() => {
                                                if(disableClear) return
                                                setDaysTimeFrame({
                                                    ...daysTimeFrame,
                                                    [day]: {
                                                        ...daysTimeFrame[day],
                                                        startTime: null,
                                                        endTime: null,
                                                        error: ''
                                                    }
                                                })
                                            })}
                                        color={!!disableClear ? '': "error"}
                                    />
                                    <TimeEditor
                                        margin='3px 3px'
                                        label="Time From"
                                        name="startTime"
                                        style={{...style.outlinedInput}}
                                        disabled={!isDisable || isInvalid }
                                        onChange={(newValue: string , name: string) =>  changeTimeFrom(newValue, name, day)}
                                        value={daysTimeFrame[day as keyof typeof daysTimeFrame]?.startTime}
                                        error={!!daysTimeFrame[day as keyof typeof daysTimeFrame]?.error}
                                        helperText={daysTimeFrame[day as keyof typeof daysTimeFrame]?.error?.startTime}
                                        fullWidth
                                    />
                                    <TimeEditor
                                        margin='3px 3px'
                                        label="Time To"
                                        name="endTime"
                                        style={{...style.outlinedInput}}
                                        disabled={!isDisable || isInvalid}
                                        onChange={(newValue: string | any, name: string) =>  changeTimeFrom(newValue, name, day)}
                                        value={daysTimeFrame[day as keyof typeof daysTimeFrame]?.endTime}
                                        error={!!daysTimeFrame[day as keyof typeof daysTimeFrame]?.error}
                                        helperText={daysTimeFrame[day as keyof typeof daysTimeFrame]?.error?.endTime}
                                        fullWidth
                                    />
                                </FlexContainer>
                            )
                             
                        })}
                    </DivContainer>
                </Grid>
            </React.Fragment>
        }
    </Grid>
  )
}

export default Timesheet

const style = {
    outlinedInput: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 10px',  
        },
    }
  }