import React from 'react'
import {
    Card,
    CardHeader,
    CardContent,
    Tooltip
} from "@mui/material";
import clsx from 'clsx';
import Badge from '../../Badge';
import { CardTabProps } from '../../../../assets/types';
import useBaseStyles from '../../../../assets/styles'
import useStyles, { SubTitleRow } from '../../styles';
import { DivContainer, FlexContainer } from '../../../../assets/styles/styledComponents';
import FeaturedIcon from '../../FeaturedIcon';
import Loader from '../../Loader';
const CardTab: React.FC<CardTabProps> = (props) => {
    const { title, subTitles, activeTab, value, handleTabClick, defaultText, loading, alignToBottom } = props;
    const classes = useStyles();
    const baseClasses: any = useBaseStyles();
    return (
        <Card
            variant="outlined"
            className={clsx(classes.cardTab, {
                [classes.activeCardTab]: activeTab === value
            })}
            onClick={() => handleTabClick(value)}
            style={{ display: 'flex', flexDirection: 'column', }}
        >

            <DivContainer margin="0px" padding="0px" >
            <Loader loading={loading} height="13%" width="13%" />
                {props.children}
                <CardHeader
                    className={classes.cardHeader}
                    color="primary"
                    subheader={
                        <>
                            <span>{title}</span>

                        </>
                    }
                />

                <CardContent style={{ marginTop: alignToBottom ? 'auto' : 0 }}>
                    {
                        subTitles?.length > 0 ? subTitles.map((subTitle: any, index: number) => (
                                Array.isArray(subTitle) ?
                                <FlexContainer margin="0px">
                                    {
                                    subTitle.map((item) => (
                                        <SubTitleRow key={`${item.label}-${index}`}>
                                        <div className={clsx(baseClasses.card_title, baseClasses.dFlex)}>
                                            <div className={baseClasses.topCardTitles}>
                                                {item.label}:
                                            </div>
                                            <div className={classes.marRight10}>
                                                {
                                                    item.value.map((eachValue: string, index: number) => {
                                                        return <span key={`${eachValue}-${index}`}> {eachValue} </span>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </SubTitleRow>
                                    ))
                                }
                                </FlexContainer>:
                                <SubTitleRow key={`${subTitle.label}-${index}`}>
                                <div className={clsx(baseClasses.card_title, baseClasses.dFlex, classes.noMargin)}>
                                    <div className={baseClasses.topCardTitles}>
                                        {subTitle.label}:
                                    </div>
                                    <div className={classes.marRight10}>
                                        {
                                            subTitle.value.map((eachValue: string, index: number) => {
                                                return <span key={`${eachValue}-${index}`}> {eachValue} </span>
                                            })
                                        }
                                    </div>
                                    <DivContainer>
                                        {subTitle.badges?.length > 0 && subTitle.badges.map((badge: any, index: number) => {
                                            return <span key={`${badge.label}-${index}`} className={baseClasses.p10}>
                                                <Badge content={badge.value} color="primary">
                                                    <Tooltip title={badge.label}>
                                                        <div>
                                                            <FeaturedIcon style={{ padding: '0px 5px', }} icon={badge.icon} />
                                                        </div>
                                                    </Tooltip>
                                                </Badge>
                                            </span>
                                        })
                                        }
                                    </DivContainer>

                                </div>
                            </SubTitleRow>
            
                        )) : defaultText ? <div className={classes.defaultText}>{defaultText}</div> : ''
                    }
                </CardContent>
            </DivContainer>
            
        </Card>
    )
}

export default CardTab