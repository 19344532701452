import { HebrewDate } from "./hebrewDate"

class HebrewMonthDateRange {
    monthIndex: number
    dateFrom: Date
    dateTo: Date
    hebMonthName: string
    hebYearName: string
    hebYear: number
    monthInYear: number
    constructor(hebDate: HebrewDate, index:number) {
        this.monthIndex = index;
        this.dateFrom = hebDate.monthStart;
        this.dateTo = hebDate.monthEnd;
        this.hebMonthName = hebDate.hebMonthName;
        this.hebYearName = hebDate.hebYearName;
        this.hebYear = hebDate.hebYear;
        this.monthInYear = hebDate.monthInYear;
    }
}
export { HebrewMonthDateRange }