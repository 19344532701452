import {
    FormControl,
    FormHelperText,
    Grid,
    Button,
    Input,
    TextField,
    InputLabel,
    MenuItem, Select,
    Card, CardContent, Typography, Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useStyles from "./styles";
import baseStyles from '../../assets/styles'
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from '../../components/common/Loader'
import * as constants from "../../constants";
import Section4 from "./employee/ProfessionalSection"
import Section2 from "./employee/personalSection";
import Section3 from "./employee/thirdSection";
import Section5 from "./employee/financialSection";
import useUnsavedChangesWarn from "../../hooks/useUnsavedChangesWarn";
import { strings } from '../../_helpers/strings';

export default function PostCreate(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [positionValues, setPositionValues] = useState([{ id: 0, name: "" }]);
    const [specialtyValues, setSpecialtyValues] = useState([])
    const [paymentValues, setPaymentValues] = useState([])
    const [professionalTypes, setProfessionalTypes] = useState([])
    const [combineLoading, setCombineLoading] = useState({});
    const { onBackUrl } = props;
    const setLoadingItem = (itemName, value) => {
        setCombineLoading((prevState) => { return { ...prevState, [itemName]: value } });
    }
    const exploringId = useStoreState((state) => state.exploringId);

    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );
    const setExploringId = useStoreActions(
        (actions) => actions.setExploringId
    );

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const loading = useStoreState((state) => state.loading);
    const [yungermanId, setYungermanId] = useState()
    const [professionalId, setProfessionalId] = useState();
    const employeeDetail = useStoreState((state) => state.employee.detail);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);
    const setEmployeeInitDetail = useStoreActions((actions) => actions.employee.initDetail);
    const [contact, setContact] = useState(employeeDetail.contact);

    const sectionState1 = useStoreState((state) => state.employee.sectionState1);
    const sectionState2 = useStoreState((state) => state.employee.sectionState2);
    const sectionState3 = useStoreState((state) => state.employee.sectionState3);
    const sectionState4 = useStoreState((state) => state.employee.sectionState4);
    const sectionState5 = useStoreState((state) => state.employee.sectionState5);

    const setSectionState1 = useStoreActions((actions) => actions.employee.setSectionState1);
    const setSectionState2 = useStoreActions((actions) => actions.employee.setSectionState2);
    const setSectionState3 = useStoreActions((actions) => actions.employee.setSectionState3);
    const setSectionState4 = useStoreActions((actions) => actions.employee.setSectionState4);
    const setSectionState5 = useStoreActions((actions) => actions.employee.setSectionState5);

    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    useEffect(() => {
        return () => {
            //setEmployeeInitDetail();
        };
    }, [])

    useEffect(() => setContact(employeeDetail.contact), [employeeDetail]);

    useEffect(() => {
        resetAllData();
        setLoading()
        const positiveValueRes = global.fetchWrapper.get(constants.BASE_URL + 'api/hr/employeePositionValues')
        const specialtyValuesRes = global.getSystemListValuesListByName("Employee_Specialties")
        const professionalTypeRs = global.getSystemListValuesListByName("Professional_Types")
        const paymentType = global.getSystemListValuesListByName("Payment_Options")
        Promise.all([positiveValueRes, specialtyValuesRes, professionalTypeRs, paymentType]).then(results => {
            setPositionValues(results[0]);
            setSpecialtyValues(results[1]);
            setProfessionalTypes(results[2])
            setPaymentValues(results[3])
            endLoading()
        })
        //global.fetchWrapper.get(constants.BASE_URL + 'api/hr/').then(data => setSpecialtyValues(data))
        // setPaymentValues;
        setSectionState1("read");
        setSectionState2("read");
        setSectionState3("read");
        setSectionState4("read");
        setSectionState5("read");


    }, []);


    const resetAllData = () => {
    };

    useEffect(() => {
        positionValues?.forEach(position => {
            if (position.name === 'Yungerman') {
                setYungermanId(position.id);
                if (global.isEmpty(employeeDetail.positionId) && exploringId === -1)
                    setEmployeeDetail({ ...employeeDetail, ['positionId']: position.id })
            }
            if (position.name === 'Professional') {
                setProfessionalId(position.id)
            }
        })
    }, [positionValues]);

    useEffect(() => {
        if (global.isEmpty(employeeDetail.positionId) && exploringId === -1)
            setEmployeeDetail({ ...employeeDetail, ['positionId']: yungermanId })
    }, [employeeDetail]);

    const getCurrentOpenSection = () => {
        if (sectionState2 === "edit") {
            return "personal";
        }
        else if (sectionState3 === "edit") {
            return "ruchnios"
        }
        else if (sectionState5 === "edit") {
            return "financial"
        }
        else if (sectionState4 === "edit") {
            return "professional"
        }
    }
    const [isSectionOpen, setIsSectionOpen] = useState(false);
    useEffect(() => {
        const isOpen = (sectionState1 === "edit" ||
            sectionState2 === "edit" ||
            sectionState3 === "edit" ||
            sectionState4 === "edit" ||
            sectionState5 === "edit");
        setIsSectionOpen(isOpen);
        //if (isOpen) setDirty(); else setClean();
    }, [sectionState1, sectionState2, sectionState3, sectionState4, sectionState5])
    const isConfirmed = (e, checkFor, rollbackChanges) => {
        var sec = getCurrentOpenSection();
        if (rollbackChanges == undefined)
            rollbackChanges = rollbackChanges_dict[sec];
        switch (checkFor) {
            case "open_section":
                if (isSectionOpen) {
                    if (wConfirm(e, rollbackChanges, "You are editing the \"" + strings.toTitleCase(sec) + "\" section, discard its changes?"))
                        return true;
                    else
                        return false;
                }
                return true;
                break;
            case "cancel_section":
                if (isDirty) {
                    if (wConfirm(e, rollbackChanges, "You have unsaved changes, do you want to discard them?"))
                        return true;
                    else
                        return false;
                }
                return true;
                break;
            default:
                return false;
        }
        return false;
    }
    const reloadData = (setModeState) => {
        setLoading();
        global.fetchWrapper.get(constants.EMPLOYEE_DETAIL_PERSONAL + exploringId)
            .then(
                (data) => {
                    endLoading();
                    if (setModeState)
                        setModeState("read");
                    setEmployeeDetail(data);
                    if (data.contact === null)
                        setExploringName("undefined");
                    else
                        setExploringName(data.contact.firstName + " " + data.contact.lastName);
                },
                (rejects) => {
                    endLoading();
                    global.AjaxFailToast();
                    if (setModeState)
                        setModeState("read");
                }
            );
    }
    const rollbackChanges_personal = () => {
        //if (dataOfClient !== null) {
        //    resetPersonalToPrevious();
        //}
        if (hasChanges())
            reloadData();
        setClean();
        setSectionState2("read");
    }
    const rollbackChanges_ruchnios = () => {
        //if (dataOfClient !== null) {
        //    resetRuchniosToPrevious();
        //}
        if (hasChanges())
            reloadData();
        setClean();
        setSectionState3("read");
    }
    const rollbackChanges_financial = () => {
        //if (dataOfClient !== null) {
        //    resetFinancialToPrevious();
        //}
        if (hasChanges())
            reloadData();
        setClean();
        setSectionState5("read");
    }
    const rollbackChanges_professional = () => {
        //if (dataOfClient !== null) {
        //    resetFinancialToPrevious();
        //}
        if (hasChanges())
            reloadData();
        setClean();
        setSectionState4("read");
    }
    const rollbackChanges_dict = {
        personal: rollbackChanges_personal,
        ruchnios: rollbackChanges_ruchnios,
        financial: rollbackChanges_financial,
        professional: rollbackChanges_professional,
    }

    const confirmNoOpenDirtySections = (e) => isConfirmed(e, "open_section");

    const NameToString = (nameList) => {
        var names = "";
        for (var i = 0; i < nameList.length; i++)
            names += global.isEmpty(contact[nameList[i]]) ? "" : " " + contact[nameList[i]];
        return names;
    }

    return (
        <div>
            <Loader loading={loading} />
            {exploringId === -1 &&
                <Card className={classes.rootFlexCenter}>
                    <Section2
                        loading
                        sectionState={sectionState2}
                        positionValues={positionValues}
                        employee={employeeDetail}
                        yungermanId={yungermanId}
                        professionalId={professionalId}
                        setSectionState={setSectionState2}
                        specialtyValues={specialtyValues}
                        paymentValues={paymentValues}
                        professionalTypes={professionalTypes}
                        onBackUrl={onBackUrl}
                        rollbackChanges={rollbackChanges_dict.personal}
                        confirmUnsavedChanges={confirmNoOpenDirtySections}
                    />
                </Card>
            }
            {exploringId !== -1 &&
                <>
                    <Card className={classes.rootFlexCenter}>
                        {/* <Section1 /> */}

                        {/* <div className={classes.separate_div}></div> */}
                        <Section2
                            sectionState={sectionState2}
                            positionValues={positionValues}
                            employee={employeeDetail}
                            yungermanId={yungermanId}
                            professionalId={professionalId}
                            setSectionState={setSectionState2}
                            specialtyValues={specialtyValues}
                            paymentValues={paymentValues}
                            professionalTypes={professionalTypes}
                            onBackUrl={onBackUrl}
                            rollbackChanges={rollbackChanges_dict.personal}
                            confirmUnsavedChanges={confirmNoOpenDirtySections}
                        />
                    </Card>
                    <Divider style={{ height: "20px", backgroundColor: "transparent", border: 0 }} />
                    {employeeDetail.isProfessional &&
                        <>
                            <Card className={classes.rootFlexCenter}>
                                <Section4
                                    employee={employeeDetail}
                                    sectionState={sectionState4}
                                    setSectionState={setSectionState4}
                                    rollbackChanges={rollbackChanges_dict.professional}
                                    confirmUnsavedChanges={confirmNoOpenDirtySections}
                                    onBackUrl={onBackUrl}
                                // setClean={setClean}
                                // setDirty={setDirty}
                                // rollbackChanges={() => { }}
                                // confirmUnsavedChanges={() => { }} 
                                />
                            </Card>
                            <Divider style={{ height: "20px", backgroundColor: "transparent", border: 0 }} />
                        </>
                    }
                    <Card className={classes.rootFlexCenter}>
                        <Section3
                            sectionState={sectionState3}
                            positionValues={positionValues}
                            employee={employeeDetail}
                            yungermanId={yungermanId}
                            professionalId={professionalId}
                            setSectionState={setSectionState3}
                            specialtyValues={specialtyValues}
                            paymentValues={paymentValues}
                            professionalTypes={professionalTypes}
                            rollbackChanges={rollbackChanges_dict.ruchnios}
                            confirmUnsavedChanges={confirmNoOpenDirtySections}
                        />
                    </Card>
                    <Divider style={{ height: "20px", backgroundColor: "transparent", border: 0 }} />
                    <Card className={classes.rootFlexCenter}>
                        <Section5
                            sectionState={sectionState5}
                            employee={employeeDetail}
                            yungermanId={yungermanId}
                            professionalId={professionalId}
                            setSectionState={setSectionState5}
                            positionValues={positionValues}
                            specialtyValues={specialtyValues}
                            paymentValues={paymentValues}
                            professionalTypes={professionalTypes}
                            setClean={setClean}
                            setDirty={setDirty}
                            rollbackChanges={rollbackChanges_dict.financial}
                            confirmUnsavedChanges={confirmNoOpenDirtySections}
                        />
                    </Card>
                </>
            }
        </div >
    );
}