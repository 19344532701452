import React from 'react';
import { MUIButton } from '../../../../../assets/MUI/mui.components'

type ButtonProps = {
  id?: string | number | any
  label: string,
  variant?: string | any,
  disable?: boolean,
  color?: string | any,
  size?: string | any,
  ariaControls?: string | any,
  ariaHaspopup? : string | any,
  ariaExpanded? : string | any,
  onClick: Function,
  style?: any
}

const Button: React.FC<ButtonProps> = (props) => {
  const {label, id, variant, disable, color, size, ariaControls, ariaHaspopup, ariaExpanded, onClick, style} = props;
  return (
    <MUIButton sx={{...style}} id={id} aria-controls={ariaControls} aria-haspopup={ariaHaspopup} aria-expanded={ariaExpanded} variant={variant} disabled={disable} size={size} color={color} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(e)}>{label}</MUIButton>
  )
}

export default Button;