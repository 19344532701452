import React, { useEffect, useState } from "react";
import {
    FormControl,
    FormHelperText,
    Grid,
    FormControlLabel,
    Checkbox,
    InputLabel,
    MenuItem, Select,
} from "@mui/material";
import { SectionComponent, CompTextField } from "./components";
import baseStyles from '../../../assets/styles'
import _ from "lodash";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";




const ProfessionalSection = (props) => {
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    
    const baseClasses = baseStyles();
    const { employee, sectionState, setSectionState, rollbackChanges, confirmUnsavedChanges } = props;
    const [employeeDetail, setEmployeeDetail] = useState(employee);

    //professional types
    const [selectedProfessionalType, setSelectedProfessionalType] = React.useState("");
    const professionalTypesValues = useStoreState(state => state.list.values.professionalTypes);
    const professionalTypesIsFetched = useStoreState(state => state.list.values.professionalTypesIsFetched);
    const fetchProfessionalTypes = useStoreActions(actions => actions.list.values.fetchProfessionalTypes);
    useEffect(() => {
        if (!professionalTypesIsFetched) {
            fetchProfessionalTypes();
        }
    }
        , [])
    const handleProfessionalTypeChange = (event) => {
        setSelectedProfessionalType(event.target.value);
        setEmployeeDetail({ ...employeeDetail, "professionalType": event.target.value });
        setDirty();
    }

    useEffect(() => {
        setSelectedProfessionalType(employee.professionalType);
    }, [employee.professionalType]);
    useEffect(() => { setEmployeeDetail(employee); }, [employee]);


    return (
        <SectionComponent
            headerTitle="Professional"
            onSave={() => { }}
            employeeDetail={employeeDetail}
            state={sectionState}
            onStateChange={setSectionState}
            rollbackChanges={rollbackChanges}
            confirmUnsavedChanges={confirmUnsavedChanges}
            readDOM={
                <Grid
                    container
                    className={baseClasses.grid}
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Professional Type : </div>
                        <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['professionalType']) ? "-" : employeeDetail['professionalType']}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Credentials : </div>
                        <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['credentials']) ? "-" : employeeDetail['credentials']}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>LHisalos In-House : </div>
                        <div className={baseClasses.fieldvalue}>
                                {employeeDetail['isExternal'] ? "No" : "Yes"}
                        </div>
                    </Grid>
                </Grid>
            }
            editDOM={
                <Grid
                    container
                    className={clsx(baseClasses.grid)}
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <FormControl fullWidth className={baseClasses.optionsContract}>
                            <InputLabel htmlFor="professionalType-select" id="professionalType-label">Professional Type</InputLabel>
                            <Select
                                labelId="professionalType-label"
                                id="professionalType-select"
                                value={selectedProfessionalType}
                                className={baseClasses.selection}
                                onChange={handleProfessionalTypeChange}
                            >
                                {professionalTypesValues.map((professionalType, index) =>
                                    <MenuItem key={index} value={professionalTypesValues[index]}>{professionalType}</MenuItem>
                                )}
                            </Select>
                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControlLabel style={{paddingLeft: "20px"}}
                control={
                    <Checkbox
                        checked={!employeeDetail.isExternal}
                        onChange={() => {
                            setEmployeeDetail({ ...employeeDetail, isExternal: !employeeDetail.isExternal })
                            setDirty()
                        }}
                        name="isExternal"
                        color="primary" />
                }
                label="LHisalos In-House"
            />
                    </Grid>
                    {/*<CompSelectbox label="Professional Type" name={"professionalType"} value={employeeDetail.professionalType} />*/}
                    <CompTextField xs={12} label="Credentials" name="credentials" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail}/>

                </Grid>
            }
        ></SectionComponent>
    )
};

export default ProfessionalSection