import React from 'react'
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CustomMenuProps } from '../../../assets/types'
import ProtectedComponent from '../../ProtectedComponent/ProtectedComponent';
const CustomMenu = (props: CustomMenuProps) => {
    const { anchorEl, handleCloseMenu, yPos, xPos, open, options, ITEM_HEIGHT } = props;

    return (
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => handleCloseMenu()}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT ? ITEM_HEIGHT * 4.5 : 'unset',
                    minWidth: "20ch",
                },
            }}
            sx={{
                top: yPos,
                //bottom: yPos,
                left: xPos,
                //inset: "250px, 0px, 0px, 877px"
            }}>
            {
                options.length > 0 && options.map((option: any, index: number) => {
                    return (
                        <ProtectedComponent allow={option.disabled != true} canEdit={option.disabled != true}>
                            <MenuItem>
                                <ListItemText onClick={() => option.handleClick(option)}>
                                    {
                                        option.title
                                    }
                                </ListItemText>
                            </MenuItem>
                        </ProtectedComponent>
                    )
                })
            }
        </Menu>
    )
}

export default CustomMenu