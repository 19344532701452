import React, { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Divider, Grid } from '@mui/material';
import useStyles from './styles'
import baseStyles from '../../assets/styles'

const OverviewCard = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    return ( <Card className={baseClasses.contentOverview2}>
        <CardContent classes={{ root: baseClasses.contentCard }}>
            <Grid
                container
                alignItems="center"
                style={{ height: 120 }}
                className={baseClasses.rootFlex}
            >
                <OverviewSection data={props.section1}/>
                <Divider orientation="vertical" flexItem />
                <OverviewSection data={props.section2}/>
            </Grid>
        </CardContent>
    </Card> );
}
 
export default OverviewCard;

const OverviewSection = ({data}) => {
    const classes = useStyles();
    const baseClasses = baseStyles();

 return ( 
 <div className={baseClasses.grow} style={{padding: "2px"}}>
    {data.map(row => {
            global.log('the row from the overview card', row)
            global.log('the key value from the overview card:', row._key)
            return (
                <OverviewRow _key={row._key} value={row.value}/>
            )
                
    })}
</div>)
}

const OverviewRow = ({_key, value}) => {
    const classes = useStyles()
    const baseClasses = baseStyles();
    global.log(
        'the key and value as seen from the overview component', _key
    )
    return (
        <div component={"span"} className={baseClasses.card_title} >
            <span style={{ display: "inline-block", width: 150, fontWeight: "bold" }}>
                    {_key}:
        </span>
        {value}
            </div>
            )
}




