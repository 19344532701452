import React from 'react';
import { Tooltip } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useStyles from '../Modals/styles';
import { daysOfWeek } from '../../../_helpers/datetime';
import { getScheduleStringForProfessional } from '../../../_helpers/schedule';
import {  ScheduleAvailabilityStatus } from '../../../_helpers/schedule/validators/client-schedule';

const ProviderAvailabilityStatusTooltip: React.FC<any> = ({
    isExternalProvider,
    availabilityForClientSchedule,
    conflictingSchedules,
    conflictingPotentialSchedules,
    durationBasedFilterConflicts,
    employeeRateIsGreaterThanRangeMax,
    employeeRateIsLessThanRangeMin,
    missingSpecialties,
    neighborhoodMismatch,
    unableToCalculateNeighborhoodAvailability,
    warningLevel
}) => {
    const classes: any = useStyles();
    const scheduleIssues = availabilityForClientSchedule.availabilityStatus != ScheduleAvailabilityStatus.FullAvailability ||
        (conflictingSchedules && conflictingSchedules.length > 0) || (conflictingPotentialSchedules && conflictingPotentialSchedules.length > 0)
        || (durationBasedFilterConflicts && durationBasedFilterConflicts.length > 0);

    if (!scheduleIssues && !employeeRateIsGreaterThanRangeMax && !employeeRateIsLessThanRangeMin && !(missingSpecialties && missingSpecialties.length > 0) && !neighborhoodMismatch) {
        return isExternalProvider || unableToCalculateNeighborhoodAvailability ?
            (
                <Tooltip
                    title={
                        <ul>
                            {isExternalProvider && <li>External professional, schedule not available</li>}
                            {unableToCalculateNeighborhoodAvailability && <li>Provider neighborhood not available</li>}
                        </ul>
                    }
                    placement="left"
                    classes={{ tooltip: classes.tooltip }}
                >
                    <InfoOutlinedIcon style={{ color: 'gray' }} />
                </Tooltip>
            ) :
            (
                <Tooltip title="Available in  the selected time" placement="left" classes={{ tooltip: classes.tooltip }}>
                    <CheckOutlinedIcon style={{ color: 'green' }} />
                </Tooltip>
            );
    }

    return (
        <Tooltip
            placement="left"
            classes={{ tooltip: classes.tooltip }}
            title={<ProviderAvailabilityErrors
                availabilityForClientSchedule={availabilityForClientSchedule}
                conflictingSchedules={conflictingSchedules}
                conflictingPotentialSchedules={conflictingPotentialSchedules}
                durationBasedFilterConflicts={durationBasedFilterConflicts}
                employeeRateIsGreaterThanRangeMax={employeeRateIsGreaterThanRangeMax}
                employeeRateIsLessThanRangeMin={employeeRateIsLessThanRangeMin}
                missingSpecialties={missingSpecialties}
                neighborhoodMismatch={neighborhoodMismatch}
            />}
        >
            {(availabilityForClientSchedule.availabilityStatus != ScheduleAvailabilityStatus.FullAvailability || conflictingSchedules.length > 0) ?
                <WarningOutlinedIcon style={{ color: 'red' }} /> : <WarningOutlinedIcon style={{ color: 'orange' }} />
            }
        </Tooltip>
    );
}

const ProviderAvailabilityErrors: React.FC <any> = ({
    availabilityForClientSchedule,
    conflictingSchedules,
    conflictingPotentialSchedules,
    durationBasedFilterConflicts,
    employeeRateIsGreaterThanRangeMax,
    employeeRateIsLessThanRangeMin,
    missingSpecialties,
    neighborhoodMismatch,
}) => {
    const classes = useStyles();
    const globalConst: any = global;
   const daysOfWeekConst: any = daysOfWeek;
    function getConflictingScheduleMessage(conflictingSchedule: any) {
        let errorMessage = null;

        let formattedProviderScheduleTime = `between ${globalConst.dateTime.formatTimeAMPM(conflictingSchedule.providerSchedule.startTime)} and ${globalConst.dateTime.formatTimeAMPM(conflictingSchedule.providerSchedule.endTime)}`;

        if (conflictingSchedule.repeating) {
            errorMessage = getScheduleStringForProfessional(
                conflictingSchedule.repeating.days,
                conflictingSchedule.repeating.everyXOfPeriods,
                conflictingSchedule.repeating.periodicity
            ) + ` ${formattedProviderScheduleTime}`;
        }

        const hasExampleDates = conflictingSchedule.exampleDates && conflictingSchedule.exampleDates.length > 0;

        if (conflictingSchedule.repeating && hasExampleDates) {
            errorMessage += `, starting on ${globalConst.dateTime.getUSAFormattedDateString(conflictingSchedule.exampleDates[0])}`
        }

        if (!conflictingSchedule.repeating && hasExampleDates) {
            const formattedDates = conflictingSchedule.exampleDates.map((date: Date) => {
                return globalConst.dateTime.getUSAFormattedDateString(date);
            });

            errorMessage = `On ${globalConst.arrays.joinWithOxfordComma(formattedDates)} ${formattedProviderScheduleTime}`
        }

        return errorMessage;
    }

    return (
        <div>
            {availabilityForClientSchedule.availabilityStatus != ScheduleAvailabilityStatus.FullAvailability && (
                <div>
                    <h3>Availability Conflicts</h3>
                    <ul>
                        {availabilityForClientSchedule.fullAvailabilityGap && availabilityForClientSchedule.fullAvailabilityGap.repeating &&
                            availabilityForClientSchedule.fullAvailabilityGap.repeating.days.map((day: number) => (
                                <li key={day}>
                                    Not available on {daysOfWeekConst[day]}s, in the selected time
                                </li>
                            ))
                        }
                        {availabilityForClientSchedule.fullAvailabilityGap && availabilityForClientSchedule.fullAvailabilityGap.exampleDates &&
                            availabilityForClientSchedule.fullAvailabilityGap.exampleDates.map((date: Date) => {
                                const formattedDate = globalConst.dateTime.getUSAFormattedDateString(date);

                                return (
                                    <li key={formattedDate}>
                                        No availability on {formattedDate}
                                    </li>
                                );
                            })
                        }
                        {availabilityForClientSchedule.partialAvailabilityGap && availabilityForClientSchedule.partialAvailabilityGap.repeating &&
                            availabilityForClientSchedule.partialAvailabilityGap.repeating.days.map((day: number) => (
                                <li key={day}>
                                    Only partial availability on {daysOfWeekConst[day]}s
                                </li>
                            ))
                        }
                        {availabilityForClientSchedule.partialAvailabilityGap && availabilityForClientSchedule.partialAvailabilityGap.exampleDates &&
                            availabilityForClientSchedule.partialAvailabilityGap.exampleDates.map((date: Date) => {
                                const formattedDate = globalConst.getUSAFormattedDateString(date);

                                return (
                                    <li key={formattedDate}>
                                        Only partial availability on {formattedDate}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            )}
            {conflictingSchedules.length > 0 && (
                <div>
                    <h3>Conflicting Schedules</h3>
                    <ul>
                        {conflictingSchedules.map((conflictingSchedule: string) => {
                            const conflictMessage = getConflictingScheduleMessage(conflictingSchedule);

                            return conflictMessage ? (
                                <li>{conflictMessage}</li>
                            ) : null;
                        })}
                    </ul>
                </div>
            )}
            {conflictingPotentialSchedules.length > 0 && (
                <div>
                    <h3>Conflicting Potential Schedules</h3>
                    <ul>
                        {conflictingPotentialSchedules.map((conflictingSchedule: string) => {
                            const conflictMessage = getConflictingScheduleMessage(conflictingSchedule);

                            return conflictMessage ? (
                                <li>{conflictMessage}</li>
                            ) : null;
                        })}
                    </ul>
                </div>
            )}
            {durationBasedFilterConflicts.length > 0 && (
                <div>
                    <h3>Duration filter conflicts</h3>
                    <ul>
                        {durationBasedFilterConflicts.map((conflictingSchedule: string) => {
                            const conflictMessage = conflictingSchedule;

                            return conflictMessage ? (
                                <li>{conflictMessage}</li>
                            ) : null;
                        })}
                    </ul>
                </div>
            )}
            {employeeRateIsGreaterThanRangeMax && (
                <div>
                    <h3>The employee rate is greater than the rate you selected</h3>
                </div>
            )}
            {employeeRateIsLessThanRangeMin && (
                <div>
                    <h3>The employee rate is less than the rate you selected</h3>
                </div>
            )}
            {missingSpecialties && missingSpecialties.length > 0 && (
                <div>
                    <h3>Missing specialties</h3>
                    <ul>
                        {missingSpecialties.map((specialty: string) => (
                            <li>{specialty}</li>
                        ))}
                    </ul>
                </div>
            )}
            {neighborhoodMismatch && (
                <div>
                    <h3>Neighborhood mismatch</h3>
                </div>
            )}
        </div>
    );
}


export default ProviderAvailabilityStatusTooltip