import React from 'react';
import {Button} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { FlexColExactCenter,  Text} from '../../assets/styles/styledComponents';
type EmptyComponentProps = {
    onClick: Function,
    btnText: string,
    displayText: string
}

const EmptyComponent: React.FC<EmptyComponentProps> = (props) => {
    const {onClick, btnText, displayText} = props;
  return (
    <FlexColExactCenter>
        <Text>
            {displayText}
        </Text>
        <Button
            onClick={() => onClick()}
            color="primary"
            variant='text'
        >
                {btnText}
            <AddRoundedIcon fontSize="small" style={{ color: "primary" }} />
        </Button>
    </FlexColExactCenter>
  )
}

export default EmptyComponent