export const EMPLOYEE_ROUTE = '/employee/';
export const PROFESSIONAL_ROUTE = '/professional/';
export const CLIENT_ROUTE = '/client/';
export const YESHIVA_ROUTE = '/yeshivas/';
export const DETAILS = '/details';
export const ENROLEMENT = '/enrollment';
export const EMPLOYEE_APPLICANT_ENROLEMENT = '/employee_applicant_enrollment';
export const MANAGE = '/manage';
export const APPLICATIONS = '/applications/';

const FRONTEND_ROUTE_CONSTANTS = {
    EMPLOYEE_ROUTE,
    PROFESSIONAL_ROUTE,
    CLIENT_ROUTE,
    YESHIVA_ROUTE,
    DETAILS,
    MANAGE,
    ENROLEMENT,
    EMPLOYEE_APPLICANT_ENROLEMENT,
    APPLICATIONS
};

export default FRONTEND_ROUTE_CONSTANTS;