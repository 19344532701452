import React from 'react';
import { Grid, FormControl, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, Tooltip, TextField, Divider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DivContainer, Title, BoxContainer, FlexAlignCenter } from '../../../assets/styles/styledComponents';
import ProtectedComponent from '../../ProtectedComponent/ProtectedComponent';
import {dateTime} from '../../../_helpers/datetime'
import useBaseStyles from '../../../assets/styles/styles';
import useStyles from './style';
const FinancialCheckList = (props: any) => {
    const { note, isChecked, show, label, noteLabel, handleChange, checkboxName, noteName } = props;

    const classes = useStyles();
    if (!show) return null;
    return (
        <Grid>
            <Grid item xs={12}>
                <Tooltip title={label}>
                    <FormControlLabel
                        value={isChecked}
                        control={
                            <Checkbox
                                size="small"
                                checked={isChecked}
                                onChange={(e) => handleChange(e.target.checked, checkboxName)}
                            />
                        }
                        label={<div style={{ fontSize: '12px' }}>{label}</div>}
                        labelPlacement="start"
                        className={classes.checkboxes}
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    size={"small"}
                    variant={"outlined"}
                    label={noteLabel}
                    id={"note"}
                    value={note}
                    onChange={(event) => {
                        handleChange(event.target.value, noteName);
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        </Grid>
    )
}

const FinancialInfo = (props: any) => {
    const { actionPermissions, modalId, paymentType, setPaymentType, financeStatusList, handleFinancialStatus, modalFields, scheduleType, setDirty, contextData, setContextData, isConfirmedSchedule, scheduleTypeTitles, setFieldErrors, fieldErrors, canConfirmYungermanSchedule, } = props;
    const baseClasses: any = useBaseStyles();
    const globalConst: any = global;
    return (
        <DivContainer>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Grid >
                        {modalFields.status.includes(scheduleType) &&
                            <div>
                                <TextField fullWidth={true} className={baseClasses.marginTop8}
                                    id={modalId + "pvd-sts"} select label="Status" variant="outlined" size="small"
                                    value={contextData?.status ?? "seeking"}
                                    onChange={(event) => {
                                        setDirty();
                                        let value = event.target.value;
                                        if (value !== "active" || actionPermissions?.canActivateSchedule)
                                            setContextData((prev: any) => {
                                                let dateApproved = value === 'active' ? globalConst.dateTime.getDateOnly(new Date()) : null;
                                                let newClientSchedule = { ...prev, status: value, dateApproved: dateApproved }
                                                return newClientSchedule;
                                            })
                                    }}
                                    helperText={contextData.statusLastChangedOn && "Last changed:" + dateTime.getUSAFormattedDateString(contextData.statusLastChangedOn)}
                                >
                                    <MenuItem
                                        key="seeking"
                                        value="seeking"
                                        disabled={isConfirmedSchedule() || !actionPermissions?.canEditHoldStatus}
                                    >
                                        Pending {scheduleTypeTitles[scheduleType]}
                                    </MenuItem>
                                    <MenuItem
                                        key="onhold"
                                        value="onhold"
                                        disabled={!actionPermissions?.canEditHoldStatus}
                                    >
                                        On Hold
                                    </MenuItem>
                                    <MenuItem
                                        key="financial"
                                        value="financial"
                                        disabled={!isConfirmedSchedule()}

                                    >
                                        Pending Financial
                                    </MenuItem>
                                    <MenuItem
                                        key="active"
                                        value="active"
                                        //TODO: currently we only handle it by disableing the active button, which might cause a way out by setting the correct values to enable the 'active' option
                                        //but then rolling it back before saving
                                        disabled={!isConfirmedSchedule() || !actionPermissions?.canActivateSchedule || (scheduleType === 'yungerman' && !canConfirmYungermanSchedule())}
                                    >
                                        Active
                                    </MenuItem>
                                    <MenuItem
                                        key="financial-rejected"
                                        value="rejected"
                                        disabled={!isConfirmedSchedule() || !actionPermissions?.canActivateSchedule}>
                                        Financially Rejected
                                    </MenuItem>
                                </TextField>
                                <div className={baseClasses.marginTop8}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                        <DatePicker
                                            label="Date Approved"
                                            inputFormat="MM/dd/yyyy"
                                            disabled={!actionPermissions?.canEditDateApproved || contextData?.status !== 'active'}
                                            value={contextData?.dateApproved ?? null}
                                            onChange={(newValue) => {
                                                setContextData((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        dateApproved: newValue
                                                    }
                                                })
                                                if (!globalConst.dateTime.isValidDate(newValue))
                                                    setFieldErrors({ ...fieldErrors, dateApproved: 'Invalid date' })
                                                else
                                                    setFieldErrors({ ...fieldErrors, dateApproved: null })
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                error={fieldErrors.dateApproved != null}
                                                helperText={fieldErrors.dateApproved}
                                                size={'small'}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {contextData?.status === 'rejected' &&
                                    <div className={baseClasses.marginTop8}>
                                        <TextField
                                            id={modalId + "rsnfrrjc"}
                                            label="Reason for rejection"
                                            variant="outlined"
                                            size="small"
                                            value={contextData?.reasonForRejection}
                                            onChange={(event) => {
                                                setDirty();
                                                setContextData((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        reasonForRejection: event.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                }

                            </div>
                        }

                    </Grid>
                </Grid>
                <Divider className={baseClasses.mr5} orientation="vertical" variant="middle" flexItem />
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                    >
                        <Select
                            disabled={!actionPermissions?.canActivateSchedule}
                            id={modalId + "payment-type"}
                            labelId="financial-type-select-label"
                            displayEmpty={true}
                            native={false}
                            value={paymentType}
                            renderValue={() => paymentType}
                            onChange={(event) => {
                                setPaymentType(event.target.value);
                            }}
                        >
                            {["yungerman"].includes(scheduleType) && <MenuItem value="Comfort">Comfort  </MenuItem>}
                            {["yungerman", "professional"].includes(scheduleType) && <MenuItem value="Cash">Cash  </MenuItem>}
                            {["professional"].includes(scheduleType) && <MenuItem value="ProfessionalGrant">Professional Grant  </MenuItem>}
                        </Select>
                    </FormControl>
                    <ProtectedComponent
                        allow
                        canEdit={actionPermissions?.canActivateSchedule}
                        inline
                    >
                        <FinancialCheckList
                            checkboxName="isConfirmedFunding"
                            noteName="fundingNote"
                            show={paymentType === 'Comfort'}
                            label="Has Available Services:"
                            noteLabel="Note"
                            isChecked={financeStatusList.isConfirmedFunding}
                            note={financeStatusList.fundingNote}
                            handleChange={handleFinancialStatus}
                        />
                        <FinancialCheckList
                            checkboxName="isCashConfirmedWithParent"
                            noteName="cashParentNote"
                            show={paymentType === 'Cash'}
                            label="Confirmed with parents:"
                            noteLabel="Note"
                            isChecked={financeStatusList.isCashConfirmedWithParent}
                            note={financeStatusList.cashParentNote}
                            handleChange={handleFinancialStatus}
                        />
                        <FinancialCheckList
                            checkboxName="isComfortConfirmedWithParent"
                            noteName="comfortParentNote"
                            show={paymentType === 'Comfort'}
                            label="Confirmed with parents:"
                            noteLabel="Note"
                            isChecked={financeStatusList.isComfortConfirmedWithParent}
                            note={financeStatusList.comfortParentNote}
                            handleChange={handleFinancialStatus}
                        />
                        <FinancialCheckList
                            checkboxName="isConfirnedWithEmployee"
                            noteName="employeeNote"
                            show={paymentType === 'Comfort'}
                            label="Confirmed with Employee:"
                            noteLabel="Note"
                            isChecked={financeStatusList.isConfirnedWithEmployee}
                            note={financeStatusList.employeeNote}
                            handleChange={handleFinancialStatus}
                        />
                        <FinancialCheckList
                            checkboxName="isEnteredInDragon"
                            noteName="dragonNote"
                            show={paymentType === 'Comfort'}
                            label="Entered in dragon:"
                            noteLabel="Note"
                            isChecked={financeStatusList.isEnteredInDragon}
                            note={financeStatusList.dragonNote}
                            handleChange={handleFinancialStatus}
                        />
                        <FinancialCheckList
                            checkboxName="isOnProfessionalGrantSheet"
                            noteName="professionalGrantSheetNote"
                            show={paymentType === 'ProfessionalGrant'}
                            label="On Professional Grant Sheet:"
                            noteLabel="Note"
                            isChecked={financeStatusList.isOnProfessionalGrantSheet}
                            note={financeStatusList.professionalGrantSheetNote}
                            handleChange={handleFinancialStatus}
                        />
                    </ProtectedComponent>
                </Grid>
            </Grid>
        </DivContainer>
    )
}

export default FinancialInfo