import { HebrewDate } from "./hebrewDate"
import { HebrewMonthDateRange } from "./hebrewMonthDateRange"

const getHebrewMonthRanges = (start: Date, end: Date | undefined) => {
    let monthRangeArray: HebrewMonthDateRange[] = []
    if (!end)
        end = new Date()
    let currMonth = new HebrewDate(end);
    let firstMonth = new HebrewDate(start);
    let index = 0;
    while (currMonth.monthStart.getTime() >= firstMonth.monthStart.getTime()) {
        monthRangeArray.push(new HebrewMonthDateRange(currMonth, index))
        currMonth = currMonth.previousMonth();
        index++;
    }
    return monthRangeArray;
}
export { getHebrewMonthRanges }