import React, { useEffect, useState } from "react";
import {
    Grid,
    Checkbox,
    FormControlLabel,
    Button
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import * as constant from "../../constants";
import useStyles from "../styles";
import baseStyles from '../../assets/styles';
import { strings } from '../../_helpers/strings';
import { accountService } from "../../_services/account.service";
import _ from "lodash";
import { fetchWrapper } from "../../_helpers/fetch-wrapper";
import { useStoreActions, useStoreState } from "easy-peasy";
import { getAllYeshivasName } from "../../api/yeshivas";
import TextFieldEditor from "../../components/common/UI/Inputs/TextField/TextFieldEditor";
import SelectEditor from "../../components/common/UI/Inputs/Select/SelectEditor";
import { DivContainer, FlexJustifyEnd } from "../../assets/styles/styledComponents";
import DateEditor from "../../components/common/UI/Inputs/TimeDate/DateEditor";
import CheckBoxEditor from "../../components/common/UI/Inputs/CheckBox/CheckBoxEditor";

interface GridFormFieldProps {
    mode: 'read' | 'edit',
    id?: string,
    label: string,
    value: string | number | string[] | any,
    name: string,
    errorValue: string,
    onChange: Function,
    hide?: boolean,
    disabled?: boolean,
    columnSize?: number,
    hideArrows?: boolean,
    type?: "text" | 'number' | 'password' | 'email',
}

export const GridFormField: React.FC<GridFormFieldProps> = ({ mode, hideArrows = false, label, value, type = "text", name, errorValue, onChange, hide, disabled, columnSize }) => {
    if (hide) {
        return null
    }
    return (
        <Grid item xs={columnSize}>
            <TextFieldEditor
                mode={mode}
                value={value}
                label={label}
                type={type}
                hideArrows={hideArrows}
                name={name}
                disabled={disabled}
                error={!!errorValue}
                helperText={errorValue}
                fullWidth={true}
                onChange={(value: string | number, name: string) => onChange(value, name)}
            />
        </Grid>
    )
}

const userFormDefinitions = {
    firstName: { value: '', error: '', isRequired: true },
    lastName: { value: '', error: '', isRequired: true },
    hebrewFirstName: { value: '', error: '' },
    hebrewLastName: { value: '', error: '' },
    phoneNumber: { value: '', error: '' },
    address: { value: '', error: '' },
    city: { value: '', error: '' },
    state: { value: '', error: '' },
    email: { value: '', error: '', isRequired: true },
    dob: { value: '', error: '', isFocused: false },
    ssn: { value: '', error: '' },
    apt: { value: '', error: '' },
    zip: { value: '', error: '' },
    phone2: { value: '', error: '' },
    email2: { value: '', error: '' },
    roles: { value: [], error: '', isRequired: true },
    password: { value: '', error: '' },
    userModuleRole: { value: '', error: '' },
    isActive: { value: false, error: '' }
}

interface UserInfoProps {
    mode?: 'read' | 'edit',
    editData?: any,
    userModule?: string,
    defaultValues?: any,
    onClose: Function,
}

const UserInfo: React.FC<UserInfoProps> = ({ editData, mode = 'edit', userModule, defaultValues, onClose }) => {
    const globalConst: any = global;
    const [userForm, setUserForm] = useState(userFormDefinitions);
    const [moreContactInfo, setMoreContactInfo] = React.useState(false);
    const setLoading = useStoreActions((actions: any) => actions.setLoading);
    const endLoading = useStoreActions((actions: any) => actions.endLoading);
    const fetchUsers = useStoreActions((actions: any) => actions.users.fetchUsers);
    const fetchUserRoles = useStoreActions((actions: any) => actions.users.fetchUserRoles);
    const roleValues = useStoreState((state: any) => state.users.userRoles);
    const [userModuleRoles, setUserModuleRoles] = useState<any>();

    useEffect(() => {
        if (!!defaultValues) {
            let tempForm: any = { ...userForm }
            for (var key of Object.keys(tempForm)) {
                if (!!defaultValues?.[key]) {
                    tempForm[key] = {
                        ...tempForm[key],
                        value: defaultValues[key]
                    }
                }
            }
            setUserForm(tempForm)
        }
    }, [defaultValues])

    useEffect(() => {
        if (!!editData) {
            let tempForm: any = { ...userForm }
            for (var key of Object.keys(tempForm)) {
                if (!!editData?.[key]) {
                    tempForm[key] = {
                        ...tempForm[key],
                        value: editData[key]
                    }
                }
            }
            setUserForm(tempForm)
        }
    }, [editData])

    useEffect(() => {
        roleValues.length <= 0 && fetchUserRoles();
        loadYeshivasName()
    }, [])

    const loadYeshivasName = () => {
        getAllYeshivasName().then((data) => {
            setUserModuleRoles(
                {
                    ...userModuleRoles,
                    [userModule as keyof typeof userModuleRoles]: data
                }
            )
        })
    }

    function resetAllData() {
        setUserForm(userFormDefinitions);
    }

    function saveUser() {
        if (!formIsValid()) {
            globalConst.log('invalid form')
            return;
        }

        let user: any = {
            title: "MR",
            firstName: userForm.firstName.value,
            lastName: userForm.lastName.value,
            roles: userForm.roles.value,
            email: userForm.email.value,
            password: userForm.password.value,
            confirmPassword: userForm.password.value,
            contact: {
                firstName: userForm.firstName.value,
                lastName: userForm.lastName.value,
                firstNameHebrew: userForm.hebrewFirstName.value,
                lastNameHebrew: userForm.hebrewLastName.value,
                address1: userForm.address.value,
                city: userForm.city.value,
                state: userForm.state.value,
                zip: userForm.zip.value,
                phone1: userForm.phoneNumber.value,
                phone2: userForm.phone2.value,
                email1: userForm.email.value,
                email2: userForm.email2.value,
                dob: userForm.dob.value,
                ssn: userForm.ssn.value
            }
        }

        if (!!userModule) {
            user['isActive'] = userForm.isActive.value;
            user['contact']['id'] = defaultValues?.contactId;
            user['contact']['roles'] = userForm.roles.value;
        } else if (!editData) {
            user['isActive'] = true
        }
        if (!!editData) {
            delete user.password
            user = {
                ...user,
                isActive: userForm.isActive.value,
                contact: {
                    ...editData,
                    ...user['contact'],
                    id: editData?.userContactId ?? editData?.contactId,
                }
            }
        }
        if (isModuleRoleSelected() && !userForm.userModuleRole.value && !userModule) {
            setUserForm({
                ...userForm,
                'userModuleRole': {
                    ...userForm[`userModuleRole`],
                    error: `${userModule} Roles should not be empty`
                }
            })
            return;
        }
        //   if (!!selectedYeshivas) {
        //     user['yeshiva'] = selectedYeshivas
        // }
        setLoading()
        if (!editData) {
            fetchWrapper.post(constant.BASE_URL + 'accounts/create', user).then(
                (response) => {
                    fetchUsers()
                    endLoading()
                    handleClose(true)
                    globalConst.AjaxSaved1("User Saved!")
                },
                (error) => {
                    endLoading()
                    handleClose()
                    globalConst.log("error", error);
                    globalConst.AjaxFailToast();
                }
            )
        } else {
            accountService.update(editData?.userId, user).then(() => {
                fetchUsers();
                endLoading()
                handleClose(true);
                globalConst.AjaxSaved1("Successfully updated!");
            });
        }
    };

    function formIsValid() {
        let isValid = true;
        let tempForm: any = { ...userForm };
        for (var key of Object.keys(tempForm)) {
            if (tempForm[key].isRequired && !tempForm[key].value) {
                isValid = false;
                tempForm[key] = { ...tempForm[key], error: `*${key} is required` }
            }
            if (key === 'email' && !strings.validateEmail(tempForm[key].value).isValid) {
                isValid = false;
                tempForm[key] = { ...tempForm[key], error: strings.validateEmail(tempForm[key].value).message }
            }
            if (!editData && key === 'password' && !!strings.validatePassword(tempForm[key].value)) {
                isValid = false;
                tempForm[key] = { ...tempForm[key], error: strings.validatePassword(tempForm[key].value) }
            }
        }
        setUserForm(tempForm)
        return isValid;
    }

    function handleClose(reload = false) {
        resetAllData();
        if (typeof onClose === 'function') {
            onClose(reload);
        }
    };

    const filterRoles = () => {
        if (!!userModule) {
            return roleValues.filter((role: string) => role.toLowerCase().startsWith(userModule))
        }
        return roleValues
    }

    const isModuleRoleSelected = () => {
        return false;
        // return role.some((item) => item.startsWith('Yeshiva_'))
    }

    const handleChangeField = (value: string | number | boolean | string[], field: string) => {
        setUserForm({
            ...userForm,
            [field]: { ...userForm[field as keyof typeof userForm], value: value, error: '' }
        })
    }

    return (
        <DivContainer bgColor="#fff" margin="10px">
            <Grid
                container
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item xs={12}>
                    <CheckBoxEditor
                        label="Can Login"
                        onChange={handleChangeField}
                        value={userForm.isActive.value}
                        hide={!(!!userModule)}
                        name="isActive"
                        mode={mode}
                        error={!!userForm.isActive.error}
                        helperText={userForm.isActive.error}
                    />
                </Grid>

                <GridFormField
                    id="first-name"
                    mode={mode}
                    columnSize={mode !== 'read' ? 6 : 12}
                    hide={!!userModule && mode !== 'read'}
                    label={"First Name"}
                    name={"firstName"}
                    value={userForm.firstName.value}
                    onChange={handleChangeField}
                    errorValue={userForm.firstName.error}
                />
                <GridFormField
                    id="last-name"
                    columnSize={mode !== 'read' ? 6 : 12}
                    mode={mode}
                    hide={!!userModule && mode !== 'read'}
                    label={"Last Name"}
                    name="lastName"
                    value={userForm.lastName.value}
                    onChange={handleChangeField}
                    errorValue={userForm.lastName.error}
                />
                <GridFormField
                    id="phone-number"
                    columnSize={mode !== 'read' ? 6 : 12}
                    mode={mode}
                    hide={!!userModule}
                    name="phoneNumber"
                    type="number"
                    hideArrows={true}
                    label={"Phone Number"}
                    value={userForm.phoneNumber.value}
                    errorValue={userForm.phoneNumber.error}
                    onChange={handleChangeField}
                />
                <GridFormField
                    id="email"
                    columnSize={mode !== 'read' && !userModule ? 6 : 12}
                    label={"Email"}
                    value={userForm.email.value}
                    name={'email'}
                    mode={mode}
                    onChange={handleChangeField}
                    errorValue={userForm.email.error}
                />
                {!userModule && <Grid item xs={12}>
                    <Button color="primary" onClick={() => setMoreContactInfo(!moreContactInfo)}>{moreContactInfo ? <>Hide</> : <>More Contact Info</>}</Button>
                </Grid>}

                {moreContactInfo && !userModule ? (
                    <>
                        <GridFormField
                            id="hebrew-first-name"
                            columnSize={6}
                            mode={mode}
                            label={"Hebrew First Name"}
                            name="hebrewFirstName"
                            value={userForm.hebrewFirstName.value}
                            onChange={handleChangeField}
                            errorValue={userForm.hebrewFirstName.error}
                        />
                        <GridFormField
                            id="hebrew-last-name"
                            columnSize={6}
                            mode={mode}
                            label={"Hebrew Last Name"}
                            value={userForm.hebrewLastName.value}
                            name="hebrewLastName"
                            onChange={handleChangeField}
                            errorValue={userForm.hebrewLastName.error}
                        />
                        <GridFormField
                            id="address"
                            columnSize={6}
                            mode={mode}
                            name="address"
                            label={"Address"}
                            value={userForm.address.value}
                            onChange={handleChangeField}
                            errorValue={userForm.address.error}
                        />
                        <GridFormField
                            id="apt"
                            mode={mode}
                            columnSize={6}
                            label={"Apt"}
                            name="apt"
                            value={userForm.apt.value}
                            onChange={handleChangeField}
                            errorValue={userForm.apt.error}
                        />
                        <GridFormField
                            id="apt"
                            columnSize={4}
                            label={"City"}
                            mode={mode}
                            name="city"
                            value={userForm.city.value}
                            onChange={handleChangeField}
                            errorValue={userForm.city.error}
                        />
                        <GridFormField
                            id="state"
                            name="state"
                            columnSize={4}
                            mode={mode}
                            label={"State"}
                            value={userForm.state.value}
                            onChange={handleChangeField}
                            errorValue={userForm.state.error}
                        />
                        <GridFormField
                            id="zip"
                            columnSize={4}
                            mode={mode}
                            label={"ZIP"}
                            name="zip"
                            value={userForm.zip.value}
                            onChange={handleChangeField}
                            errorValue={userForm.zip.error}
                        />
                        <GridFormField
                            id="phone2"
                            columnSize={6}
                            label={"Phone 2"}
                            mode={mode}
                            name="phone2"
                            value={userForm.phone2.value}
                            onChange={handleChangeField}
                            errorValue={userForm.phone2.error}
                        />
                        <GridFormField
                            id="email2"
                            columnSize={6}
                            label={"Email 2"}
                            mode={mode}
                            name="email2"
                            value={userForm.email2.value}
                            onChange={handleChangeField}
                            errorValue={userForm.email2.error}
                        />
                        <GridFormField
                            id="ssn"
                            columnSize={6}
                            mode={mode}
                            name="ssn"
                            label={"SSN"}
                            value={userForm.ssn.value}
                            onChange={handleChangeField}
                            errorValue={userForm.ssn.error}
                        />
                        <Grid item xs={6}>
                            <DateEditor
                                mode={mode}
                                label="Date of Birth"
                                name="dob"
                                fullWidth={true}
                                value={userForm.dob.value}
                                error={!!userForm.dob.error}
                                helperText={userForm.dob.error}
                                onChange={handleChangeField}
                            />
                        </Grid>
                    </>) : null}

                <Grid item xs={12}>
                    <SelectEditor
                        label="Roles"
                        id="user-role-select"
                        multiple={true}
                        fullWidth={true}
                        name="roles"
                        mode={mode}
                        value={userForm.roles.value}
                        onChange={handleChangeField}
                        selectedVariant="chip"
                        options={filterRoles().map((item: string) => {
                            return {
                                label: item, value: item
                            }
                        })}
                        error={!!userForm.roles.error}
                        helperText={userForm.roles.error}
                    />
                </Grid>
                {isModuleRoleSelected() && !userModule &&
                    <Grid item xs={12}>
                        <SelectEditor
                            label="Yeshivas"
                            id="user-role-select"
                            fullWidth={true}
                            name="userModuleRole"
                            mode={mode}
                            value={userForm.userModuleRole.value}
                            onChange={handleChangeField}
                            options={userModuleRoles?.[userModule as keyof typeof userModuleRoles].map((item: any) => {
                                return {
                                    label: item.name, value: item
                                }
                            })}
                            error={!!userForm.userModuleRole.error}
                            helperText={userForm.userModuleRole.error}
                        />
                    </Grid>
                }
                <GridFormField
                    id="password"
                    columnSize={12}
                    label={"Password"}
                    mode={mode}
                    hide={!!editData || mode === 'read'}
                    name="password"
                    type="password"
                    value={userForm.password.value}
                    onChange={handleChangeField}
                    errorValue={userForm.password.error}
                />
            </Grid>
            {mode !== 'read' &&
                <FlexJustifyEnd>
                    <Button onClick={saveUser} color="primary">
                        Save
                    </Button>
                    <Button onClick={() => handleClose()} color="primary">
                        Close
                    </Button>
                </FlexJustifyEnd>
            }
        </DivContainer>
    );
}

export default UserInfo;
