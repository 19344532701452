import { createTheme } from '@mui/material/styles';
import typography from './typography';
import palette from './palette';
import breakpoints from './breakpoints';

export const theme = createTheme({
    palette: palette,
    // typography: typography,
    // breakpoints: breakpoints,
    // define any thing you want to override
})
