import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useStyles from "./styles";
import baseStyles from '../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";

const ProgramSelectBox = (props) => {
  const classes = useStyles();
  const baseClasses = baseStyles();
  const setFieldsLight = useStoreActions(
    (actions) => actions.clients.setFieldsLight
  );
  const fieldsLight = useStoreState((state) => state.clients.fieldsLight);

  return (
    <FormControl fullWidth className={classes.formControl} >
      <InputLabel id="demo-controlled-open-select-label-2">
        {props.label}
      </InputLabel>
      <Select
        labelId="demo-controlled-open-select-label-2"
        id="demo-controlled-open-select"
        value={props.value}
        name={props.name}
        onChange={(event) => {
          props.savestatusaction(1);
          setFieldsLight({ ...fieldsLight, [props.name]: event.target.value });
        }}
        className={baseClasses.selection}
        style={{ marginLeft: 0 }}
      >
        {props.name == "cans" &&
          ["High", "Med", "Low"].map((column) => (
            <MenuItem value={column} key={column}>
              {column}
            </MenuItem>
          ))}
        {props.name == "paymentMethod" &&
          ["Cash", "Credit Card"].map((column) => (
            <MenuItem value={column} key={column}>
              {column}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ProgramSelectBox;
