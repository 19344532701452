/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Paper, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';

const ContactInfo = ({ setContactData, handleNext, handlePrev, defaultContactData }) => {
    const [contactData, setFormData] = useState({
        FirstName: defaultContactData?.FirstName ?? '',
        LastName: defaultContactData?.LastName ?? '',
        FirstNameHebrew: defaultContactData?.FirstNameHebrew ?? '',
        LastNameHebrew: defaultContactData?.LastNameHebrew ?? '',
        FirstNameLegal: defaultContactData?.FirstNameLegal ?? '',
        MiddleNameLegal: defaultContactData?.MiddleNameLegal ?? '',
        LastNameLegal: defaultContactData?.LastNameLegal ?? '',
        Address1: defaultContactData?.Address1 ?? '',
        Address2: defaultContactData?.Address2 ?? '',
        City: defaultContactData?.City ?? '',
        State: defaultContactData?.State ?? '',
        Zip: defaultContactData?.Zip ?? '',
        neighborhood: defaultContactData?.neighborhood ?? '',
        Phone1: defaultContactData?.Phone1 ?? '',
        Phone2: defaultContactData?.Phone2 ?? '',
        Email1: defaultContactData?.Email1 ?? '',
        Age: defaultContactData?.Age ?? 0,
        shverName: defaultContactData?.shverName ?? '',
        hasCar: defaultContactData?.hasCar ?? false,
    });

    useEffect(() => {
    }, [defaultContactData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleNextClick = () => {
        setContactData(contactData);
        handleNext(contactData);
    };

    const fieldStyle = {
        marginBottom: '8px',
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: '94vh',
            padding: '10px',
            backgroundColor: '#f5f5f5'
        }}>
            <Paper style={{ padding: 30, maxWidth: '700px', width: '700px', minHeight: '94vh' }}>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '16px',
                    marginBottom: '20px'
                }}>
                    <TextField
                        label="First Name"
                        name="FirstName"
                        fullWidth
                        value={contactData.FirstName || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Last Name"
                        name="LastName"
                        fullWidth
                        value={contactData.LastName || ''}
                        onChange={handleChange}
                    />
                </div>

                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '16px',
                    marginBottom: '20px'
                }}>
                    <TextField
                        label="Legal First Name"
                        name="FirstNameLegal"
                        fullWidth
                        value={contactData.FirstNameLegal || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Legal Middle Name"
                        name="MiddleNameLegal"
                        fullWidth
                        value={contactData.MiddleNameLegal || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Legal Last Name"
                        name="LastNameLegal"
                        fullWidth
                        value={contactData.LastNameLegal || ''}
                        onChange={handleChange}
                    />
                </div>

                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '16px',
                    marginBottom: '20px'
                }}>
                    <TextField
                        label="Address 1"
                        name="Address1"
                        fullWidth
                        style={{ gridColumn: '1 / 4' }}
                        value={contactData.Address1 || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="City"
                        name="City"
                        fullWidth
                        value={contactData.City || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="State"
                        name="State"
                        fullWidth
                        value={contactData.State || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Zip"
                        name="Zip"
                        fullWidth
                        value={contactData.Zip || ''}
                        onChange={handleChange}
                    />
                </div>

                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '16px',
                    marginBottom: '20px'
                }}>
                    <TextField
                        label="Cell"
                        name="Phone1"
                        fullWidth
                        value={contactData.Phone1 || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Home Phone"
                        name="Phone2"
                        fullWidth
                        value={contactData.Phone2 || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Email"
                        name="Email1"
                        fullWidth
                        value={contactData.Email1 || ''}
                        onChange={handleChange}
                    />
                </div>

                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '16px'
                }}>
                    <TextField
                        label="Shver Name"
                        name="shverName"
                        fullWidth
                        value={contactData.shverName || ''}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth style={{ gridColumn: '2 / 3' }}>
                        <InputLabel>Neighborhood</InputLabel>
                        <Select
                            name="neighborhood"
                            value={contactData.neighborhood || ''}
                            onChange={handleChange}
                        >
                            <MenuItem value="Brooklyn">Brooklyn</MenuItem>
                            <MenuItem value="Spring Valley">Spring Valley</MenuItem>
                            <MenuItem value="Monsey">Monsey</MenuItem>
                            <MenuItem value="Williamsburg">Williamsburg</MenuItem>
                            <MenuItem value="Borough Park">Borough Park</MenuItem>
                            <MenuItem value="Staten Island">Staten Island</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="hasCar"
                                checked={contactData.hasCar}
                                onChange={handleChange}
                            />
                        }
                        label="Do you have a car?"
                        style={{ gridColumn: '3 / 4', marginLeft: '8px' }}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleNextClick}
                    >
                        Next
                    </Button>
                </div>
            </Paper>
        </div>
    );
};

export default ContactInfo;
