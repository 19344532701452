import React, { useEffect } from "react";
import { InputBase, Paper } from "@mui/material";
import useStyles from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import ContactTable from "./contact_table";
import contact, { columnsContacts } from "../../api/contact";
import { useStoreState, useStoreActions } from "easy-peasy";
import baseStyles from '../../assets/styles'
let programs = false;

export default function Contacts() {
  const classes = useStyles();
  const baseClasses = baseStyles();
  const [query, setQuery] = React.useState("");
  const [rows, setRow] = React.useState([]);
  const [columns, setColumns] = React.useState(columnsContacts);
  const [loader, setLoader] = React.useState(true);
  const selectedRow = null;

  const setLoading = useStoreActions((actions) => actions.setLoading);
  const endLoading = useStoreActions((actions) => actions.endLoading);

  useEffect(() => {
    if (rows.length == 0 && !programs) {
      setLoading();
      contact().then((value) => {
        if (value.length == 0) {
          programs = true;
        }
        setLoader(false);
        endLoading();
        setRow(value);
      });
      return;
    } else {
      setLoader(false);
    }
  }, []);
  const setDataOfFilter = (rows) => {
    return rows.filter(
      (row) => row.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };
  const _setRowAndColumn = () => {
    return {
      rows: setDataOfFilter(rows),
      columns: columnsContacts,
      screen: "contacts",
    };
  };
  return (
    <main className={classes.content}>
      <div className={baseClasses.toolbar} />
      <div className={baseClasses.row}>
        {/* <Typography className={classes.title}>Clients</Typography>*/}
        <div className={baseClasses.search}>
          <div className={baseClasses.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            classes={{
              root: baseClasses.inputRoot,
              input: baseClasses.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <div className={baseClasses.grow}></div>
        {/*<div>
                    <Button variant="contained" className={classes.new_button} color="primary">
                        New
                    </Button>
                </div>*/}
      </div>
      <Paper>
        {loader ? (
          <div className={baseClasses.loading}>Loading....</div>
        ) : (
          <ContactTable data={_setRowAndColumn()} />
        )}
      </Paper>
    </main>
  );
}
