export const  base64ToBlob = ( base64: String | any, type:String | any = "application/octet-stream" ) => {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
}

export const getFileExtension = (fileName: String) => {
    return fileName.split('.').pop();
}