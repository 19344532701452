import React, { useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';

function useActiveToolTip(ref: any) {
    const [isOverflow, setOverFlow] = useState<boolean>(false);
    const [width, height] = useWindowSize();
  useEffect(() => {
    setOverFlow(ref?.current?.offsetWidth < ref?.current?.scrollWidth)
  }, [width, height, isOverflow]);
  return isOverflow;
}
export default useActiveToolTip;