import _ from "lodash";
import {
    PROFESSIONAL_DETAIL, PROFESSIONAL_LIST, PROFESSIONAL_REMOVE
// ,PROFESSIONAL_REMOVE
} from "../constants";
import authHeader from "../screens/_helper/auth-header";

export default function professionals() {
    let employees_data = [];
    const professionals = [];
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(PROFESSIONAL_LIST)
            .then(
                (data) => {
                    employees_data = data.professionals;
                    employees_data.forEach((item, index) => {
                        if (!_.isEmpty(item.contact)) {
                            professionals.push({
                                id: item.id,
                                firstName: item.contact.firstName,
                                lastName: item.contact.lastName,
                                phone1: item.contact.phone1,
                                address1: item.contact.address1,
                                age: item.contact.age,
                                city: item.contact.city,
                                contactId: item.contactId,
                                type: item.type,
                                email1: item.contact.email1,
                                firstHebrewName: item.contact.firstNameHebrew,
                                lastHebrewName: item.contact.lastNameHebrew,
                                state: item.contact.state,
                                zip: item.contact.zip,
                                phone2: item.contact.phone2,
                                email2: item.contact.email2,
                                ssn: item.contact.ssn,
                                dob: item.contact.dob,
                                isExternal: item.isExternal
                            });
                        }
                    });
                    resolve(professionals);
                },
                (rejects) => {
                    global.log("rejects", rejects);
                }
            );
    });
}

export let getProfessionalDetails = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(PROFESSIONAL_DETAIL + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    resolve(true);
                }
            );
    });
};

export let removeProfessionalFromList = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(PROFESSIONAL_REMOVE + id, {
                headers: authHeader(),
            })
            .then(
                (response) => {
                    if (response.responseCode === 200) {
                        resolve(true);
                    } else {
                        global.AjaxFailToast();
                        resolve(false);
                    }
                },
                (error) => {
                    global.log("error", error);
                    global.AjaxFailToast();
                    resolve(false);
                }
            );
    });
};
export const columnsEmployees = [
    { field: "firstName", headerName: "First Name" },
    { field: "lastName", headerName: "Last Name", width: 200, sortable: false },
    { field: "phone1", headerName: "Phone Number", width: 200, sortable: false },
    { field: "address1", headerName: "Address", width: 200, sortable: false },
    { field: "city", headerName: "City", width: 200, sortable: false },
];

export const yungermanBusySchedule = [
    { field: "startTime", headerName: "From", flex: 1 },
    { field: "endTime", headerName: "To", flex: 1 },
    { field: "clientName", headerName: "Client", flex: 1 },
    { field: "daysOfWeek", headerName: "Schedule", flex: 1 },
    { field: "note", headerName: "Note", flex: 1 },
];

export const yungermanAvailableSchedule = [
    { field: "startTime", headerName: "From", width: 110 },
    { field: "endTime", headerName: "To", width: 110 },
    { field: "schedule", headerName: "Schedule", width: 140 },
];
