import { makeStyles } from "@mui/styles";

export default makeStyles((theme: any) => ({
    error: {
        color: "red !important",
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        position: 'relative'
    },
    noPadding: {
      padding: '0px !important'
    }
}))