import React, { useState, useEffect } from 'react';
import {
    useLocation
} from "react-router-dom";
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DetailHeader from '../DetailHeader';
import { DivContainer, FlexContainer } from '../../../assets/styles/styledComponents'
import ProtectedComponent from '../../../components/ProtectedComponent/ProtectedComponent';
import CustomCard from '../../../components/ClientSchedule/Card/Card';
import usePermission from '../../../hooks/usePermission';
import useGetValueOptionsLists from "../../../hooks/useGetValueOptionsLists";
import useFetcher from "../../../hooks/useFetcher";
import * as constant from '../../../constants';
import { getProfessionalNames } from '../../../api/employees';
import { getDashboardSchedules } from '../../../api/dashboard';
import YungermanModal from '../../../components/ClientSchedule/Modals/YungermanModel/YungermanModal';
import AppointmentModal from '../../../components/ClientSchedule/Modals/Appointment/Appointment';
import DeleteModel from '../../../components/ClientSchedule/Modals/DeleteModel/DeleteModel';
import { clientScheduleStatus, periodicities } from "../../../_helpers/constants/scheduleConstants";
import SCHEDULE_PERIODICITY_CONSTANTS from '../../../_helpers/constants/schedule_periodicity_constants';
import { getScheduleStringForProfessional } from '../../../_helpers/schedule';
import useStyles from '../style';
import FeaturedIcon from '../../../components/common/FeaturedIcon';
import FilterButtonGroup from '../../../components/common/FilterButtonGroup';
import useScheduleEditDataManager from '.././../../hooks/useScheduleEditDataManager';
import useInterval from '../../../hooks/useInterval';

const includedStatusPerTab = {
    [constant.tabsConst.financialSchedule]: ['financial'],
    [constant.tabsConst.onHoldSchedule]: ['onhold'],
    [constant.tabsConst.rejectedSchedule]: ['rejected'],
    [constant.tabsConst.seekingSchedule]: ['seeking'],
    [constant.tabsConst.activeSchedule]: ['active'],
}

const defaultRow_Provider = {
    clientScheduleId: -1,
    specialties: [], clientProviderSchedulePotentials: [], status: "seeking",
    schedule: { date: new Date(), days: "", periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS }
};

const ITEM_HEIGHT = 48;

const PaymentInfoIcon = (props: any) => {
    const { note, iconType, value, title } = props;
    return (<Tooltip title={
        <>
            <div style={{ fontWeight: "bolder" }}>{title}</div>
            {note && <div>{"Note: " + note}</div>}
        </>
    }>
        <div>
            <FeaturedIcon icon={iconType} style={{ padding: '0px', }} color={value ? "success" : note ? "warning" : "default"} />
        </div>
    </Tooltip>)
}

const Schedules = () => {
    const globalConst: any = global;
    const classes: any = useStyles();
    const { pathname } = useLocation();
    const [scheduleContext] = useScheduleEditDataManager();
    const permissions = globalConst.permissions.clients.schedules;
    const userId = globalConst.account.userValue.id;
    const [scheduleData, yungermenLoading, yungermenError] = useFetcher(constant.CLIENT_SCHEDULE_GET_LIST_BY_USER + `${userId}?scheduleStatus=seeking,onhold,financial,rejected`)
    const schedulePermissions = usePermission(globalConst.permissions.dashboard.schedules.seeking);
    const financialPermissions = usePermission(globalConst.permissions.dashboard.schedules.financial);
    const currPermissions = {
        [constant.tabsConst.financialSchedule]: financialPermissions,
        [constant.tabsConst.onHoldSchedule]: schedulePermissions,
        [constant.tabsConst.rejectedSchedule]: financialPermissions,
        [constant.tabsConst.seekingSchedule]: schedulePermissions,
        [constant.tabsConst.activeSchedule]: schedulePermissions,
        [constant.tabsConst.paymentTypeSchedule]: schedulePermissions,
        [constant.tabsConst.clientScheduleStatus]: schedulePermissions,
        [constant.tabsConst.statusStepDuration]: schedulePermissions,
    }
    const generalPermissions = usePermission(permissions);
    const yungermanPermissions = usePermission(permissions.yungerman);
    const professionalPermissions = usePermission(permissions.professional);
    const exploringId = useStoreState((state: any) => state.exploringId);
    const exploringType = useStoreState((state: any) => state.exploringType);

    const getStatusStepDurationData = useStoreActions((actions: any) => actions.dashboard.getStatusStepDurationData);
    const statusStepDurationData = useStoreState((state: any) => state.dashboard.statusStepDuration)
    const getPaymentTypeSchedules = useStoreActions((actions: any) => actions.dashboard.getPaymentTypeSchedules);
    const paymentTypeSchedules = useStoreState((state: any) => state.dashboard.paymentTypeSchedules);
    const clientScheduleStatusList = useStoreState((state: any) => state.dashboard.clientScheduleStatusList);
    const getActiveSchedules = useStoreActions((actions: any) => actions.dashboard.getDashboardActiveSchedules);
    const getClientScheduleStatusList = useStoreActions((actions: any) => actions.dashboard.getClientScheduleStatusList);
    const activeScheduleList = useStoreState((state: any) => state.dashboard.activeShedules);
    const [activeSchedules, setActiveSchedules] = React.useState(activeScheduleList);

    const [scheduleType, setScheduleType] = React.useState<string>(pathname.split('/').pop() as string)
    const [scheduleRows, setScheduleRows] = React.useState([]);
    const [filteredScheduleRows, setFilteredScheduleRows] = React.useState([]);
    const [scheduleFilterValue, setScheduleFilterValue] = React.useState("yungerman");
    const [isYungermenLoading, setYungermenLoading] = React.useState(false);
    const [professionals, setProfessionals] = useState([]);
    const [menu, setMenu] = React.useState(['edit', 'delete']);
    const [xPos, setXPos] = useState<any>("0px");
    const [yPos, setYPos] = useState<any>("0px");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [row_Provider, setRow_Provider] = React.useState(defaultRow_Provider);
    const [slcRow_Provider, setSlcRow_Provider] = React.useState<any>(null);
    const [slcRow_Type, setSlcRow_Type] = React.useState<any>(null);
    const [show_ProviderRow, setShow_ProviderRow] = React.useState(false);
    const [isLoading_ProviderRow, setIsLoading_ProviderRow] = React.useState(false);
    const [del, setdel] = React.useState(false);
    const [paymenFilterBy, setPaymentFilterBy] = React.useState(constant.DASHBAORD_ACTIVE_SCHEDULES_PAYEMENT_FILTERS[0].value);
    const [activeFilterBy, setActiveFilterBy] = React.useState(constant.DASHBAORD_ACTIVE_SCHEDULES_ACTIVE_FILTERS[0].value);
    const seekingValueOptionsList = useGetValueOptionsLists(['yeshiva', 'meargen', 'clientName', 'statusIcon']);
    const financialValueOptionsList = useGetValueOptionsLists(['meargen', 'clientName', 'providerName', 'financialCoordinator', 'statusIcon']);

    const filterDefaultDateValuesFromSchedule = (schedule: any) => {
        return {
            date: globalConst.dateTime.filterDefaultDate(schedule.date),
            endDate: globalConst.dateTime.filterDefaultDate(schedule.endDate),
            startTime: globalConst.dateTime.filterDefaultDate(schedule.startTime),
            endTime: globalConst.dateTime.filterDefaultDate(schedule.endTime),
        }
    }

    const getFilteredRows = (scheType: any, includeStatuses: any) => {
        return scheduleRows
            .sort((a: any, b: any) => globalConst.dateTime.getDateObject(b.startDate) - globalConst.dateTime.getDateObject(a.startDate))
            .filter((row: any) =>
                (!scheType || scheType === row.type)
                && (!includeStatuses || includeStatuses.length < 1 || (row.status !== undefined && includeStatuses.includes(row.status)))
            )
    }
    const getFilteredActiveScheduleRows = (scheType: any, rows: any[]) => {
        return rows.filter((x) => !scheType || x.type === scheType)
    }

    useEffect(() => {
        activeScheduleList.length <= 0 && getActiveSchedules();
        clientScheduleStatusList.length <= 0 && getClientScheduleStatusList();
        statusStepDurationData.length <= 0 && getStatusStepDurationData();
        paymentTypeSchedules.length <= 0 && getPaymentTypeSchedules({ paymentType: constant.PAYMENT_TYPE.all, onGoingStatus: constant.ON_GOING_STATUS.all });
    }, [])


    /**
     * Refresh cards page info after every 10min
     */
    useInterval(() => {
        getActiveSchedules();
        getClientScheduleStatusList();
        getStatusStepDurationData();
        getPaymentTypeSchedules({ paymentType: constant.PAYMENT_TYPE.all, onGoingStatus: constant.ON_GOING_STATUS.all });
    }, 600000)

    useEffect(() => {
        //filter rows every time the filter drop-down value is changed
        setActiveSchedules(getFilteredActiveScheduleRows(scheduleFilterValue, activeScheduleList))
    }, [scheduleFilterValue, activeScheduleList])



    useEffect(() => {
        var scheType = pathname.split('/').pop()
        setScheduleType(scheType as string);
    }, [pathname])

    useEffect(() => {
        getProfessionalNames().then((data) => {
            setProfessionals(data || [])
        });
    }, []);

    useEffect(() => {
        if (scheduleData?.length) {
            let schedules = scheduleData.map((d: any) => {
                return yungermenDataMapping(d);
            });
            setScheduleRows(schedules)
            setFilteredScheduleRows(schedules)
        }
        setYungermenLoading(yungermenLoading);
    }, [scheduleData, yungermenLoading]);

    useEffect(() => {
        //filter rows by status, type every time the tab, or the filter drop-down value is changed
        setFilteredScheduleRows(getFilteredRows(scheduleFilterValue, includedStatusPerTab[scheduleType]))
    }, [scheduleFilterValue, scheduleRows])

    const formatDate = (dateTime: string) => {
        return globalConst.dateTime.getUSAFormattedDateString(dateTime);
    }

    const getRateValue = (params: any) => {
        return params.row.rate ? `$${params.row.rate}${periodicities[params.row.ratePeriod as keyof typeof periodicities] ? `/${periodicities[params.row.ratePeriod as keyof typeof periodicities]}` : ""}` : ""
    }

    const getWeeklyRates = (params: any) => {
        if (params.row.ratePeriod === 'MONTHS') return '-';
        return params.row.totalDollarsPerWeek
    }

    const getTimeValue = (params: any) =>
        (params.row.startTime || params.row.endTime) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(params.row.startTime), globalConst.dateTime.filterDefaultDate(params.row.endTime)) : "-"

    const getDatesValue = (params: any) =>
        `${params.row.startDate ? formatDate(globalConst.dateTime.filterDefaultDate(params.row.startDate)) : "-"} - ${params.row.endDate ? formatDate(globalConst.dateTime.filterDefaultDate(params.row.endDate)) : "-"}`

    const getPotentialValue = (params: any) => {
        return params.row.clientEmployeeSchedulePotentials?.map((x: any) => x.providerName + ' - ' + x.status).join(", \n") ?? ""
    }

    const getStatusValue = (params: any) => {
        if (params.row.status === 'seeking') {
            return clientScheduleStatus[params.row.type as keyof typeof clientScheduleStatus];
        }
        return clientScheduleStatus[params.row.status as keyof typeof clientScheduleStatus];
    };
    const getPotentialValueTooltip = (row: any) => {
        return row.clientEmployeeSchedulePotentials?.map((x: any) => x.providerName + ' - ' + x.status).join(", \n") ?? ""
    }

    const loadYungermanEditData = (clientScheduleId: number) => {
        setIsLoading_ProviderRow(true);
        scheduleContext.loadEntity(clientScheduleId, "yungerman")?.then((result: any) => {
            setRow_Provider(result)
            setIsLoading_ProviderRow(false);
        })
    };

    const loadAppointmentEditData = (clientScheduleId: number) => {
        if (clientScheduleId === undefined) return;
        scheduleContext.loadEntity(clientScheduleId, slcRow_Type, slcRow_Provider)?.then((result: any) => {
            setRow_Provider(result)
        })
    }

    const handleEditOpen = (row: any, scheType: string) => {
        if (row.clientScheduleId !== undefined)
            switch (scheType) {
                case "yungerman":
                    loadYungermanEditData(row.clientScheduleId);
                    break;
                case "professional":
                    loadYungermanEditData(row.clientScheduleId);
                    break;
                case "appointment":
                    loadAppointmentEditData(row.clientScheduleId);
                    break;
                default:
            }
        else
            setRow_Provider(defaultRow_Provider);
        setShow_ProviderRow(true);
        setAnchorEl(null);
    };

    const getWeekdaysString = (weekdays: any, includeAndStringBetweenLastDays = false) => {
        return globalConst.dateTime.getWeekdaysString(weekdays, includeAndStringBetweenLastDays);
    }

    const getAdditionalColumns = (type: string) => {
        let cols: any = [];
        switch (type) {
            case constant.tabsConst.financialSchedule:
                if (scheduleFilterValue === 'yungerman') {
                    cols = [
                        {
                            field: "isConfirmedFunding", headerName: 'Financial Info', disableColumnMenu: true, renderCell: (param: any) => {
                                return (
                                    param.row.paymentType === constant.PAYMENT_TYPE.comfort ?
                                        <>
                                            <PaymentInfoIcon
                                                title={"Has Available Services"}
                                                value={param.row.isConfirmedFunding}
                                                iconType={"Money"}
                                                note={param.row.fundingNote}
                                            />
                                            <PaymentInfoIcon
                                                title={"Confirmed with Parent"}
                                                value={param.row.isComfortConfirmedWithParent}
                                                iconType={"Group"}
                                                note={param.row.comfortParentNote}
                                            />
                                            <PaymentInfoIcon
                                                title={"Confirmed with Employee"}
                                                value={param.row.isConfirmedWithEmployee}
                                                iconType={"Employee"}
                                                note={param.row.employeeNote}
                                            />
                                            <PaymentInfoIcon
                                                title={"Entered In Dragon"}
                                                value={param.row.isEnteredInDragon}
                                                iconType={"Portal"}
                                                note={param.row.dragonNote}
                                            />

                                        </> :
                                        param.row.paymentType === constant.PAYMENT_TYPE.cash ?

                                            <PaymentInfoIcon
                                                title={"Confirmed with Parent"}
                                                value={param.row.isCashConfirmedWithParent}
                                                iconType={"Group"}
                                                note={param.row.cashParentNote}
                                            /> :
                                            ""
                                )
                            }, sortable: false, maxWidth: 135, cellClassName: 'icon-no-padding'
                        },
                    ]

                }
        }
        return cols
    }

    const getFeaturedScheduleColumns = () => {
        return {
            [constant.tabsConst.clientScheduleStatus]: {
                title: "Talmidim - Mamidim Status",
                cardTitle: "Talmidim - Mamidim Status",
                icon: "WorkHistory",
                show: true,
                actions: ['edit', 'delete'],
                columns: [
                    { field: "clientName", headerName: "Talmid", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "seekingCt", headerName: "Seeking Schdeules", width: 120, type: 'number' },
                    { field: "pendingFinancialCt", headerName: "Pending Financial Schdeules", width: 120, type: 'number' },
                    { field: "activeCt", headerName: "Active Schdeules", width: 120, type: 'number' },
                    { field: "sumCt", headerName: "Total Schedules", windth: 120, type: 'number' },
                    { field: "meargen", headerName: "Meargen", windth: 120, type: 'singleSelect' },
                    { field: "comfortHealthStatus", headerName: "Approval", width: 120 },
                ],
            },
            [constant.tabsConst.seekingSchedule]: {
                quickFilters: {
                    type: 'select',
                    dropdowns: [{
                        field: 'type',
                        value: scheduleFilterValue,
                        handler: (event: any) => {
                            setScheduleFilterValue(event?.target?.value)
                        },
                        options: [
                            {
                                label: 'Yungerman',
                                value: 'yungerman'
                            },
                            {
                                label: 'Professional',
                                value: 'professional'
                            },
                        ]

                    }],
                },
                title: "Seeking Schedules",
                cardTitle: "Seeking",
                icon: "WorkHistory",
                show: true,
                actions: ['edit', 'delete'],
                columns: [
                    { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", width: 120, sortable: false, getStringValue: getTimeValue, valueGetter: getTimeValue },
                    { field: "createdOn", headerName: "Requested On", width: 120, type: "date" },
                    { field: "meargen", headerName: "Meargen", width: 120, type: 'singleSelect' },
                    { field: "yeshiva", headerName: "Yeshiva", width: 120, type: 'singleSelect' },
                    { field: "type", headerName: "Type", width: 120, hide: true },
                    { field: "note", headerName: "Note", width: 120, enableTooltip: true, enableDialog: true, },
                    {
                        field: "potentials",
                        headerName: "Potentials",
                        width: 120, getStringValue: getPotentialValue,
                        getTooltipTitle: getPotentialValueTooltip,
                        enableTooltip: true,
                        enableDialog: true,
                        valueGetter: getPotentialValue,
                    },
                    { field: "statusIcon", headerName: "Status Icon", headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding', valueGetter: getStatusValue, type: 'singleSelect' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }

                ],


                options: [
                    { label: "Yungerliet", value: "yungerman" },
                    { label: "Professional", value: "professional" },
                    { label: "All", value: "" },
                ],
            },
            [constant.tabsConst.onHoldSchedule]: {
                quickFilters: {
                    type: 'select',
                    selectedValue: scheduleFilterValue,
                    dropdowns: [{
                        field: 'type',
                        value: scheduleFilterValue,
                        handler: (event: any) => {
                            setScheduleFilterValue(event?.target?.value)
                        },
                        options: [
                            {
                                label: 'Yungerman',
                                value: 'yungerman'
                            },
                            {
                                label: 'Professional',
                                value: 'professional'
                            },
                        ]
                    }],
                },
                title: "On-Hold Schedules",
                icon: 'Pause',
                cardTitle: "On-Hold",
                show: true,
                actions: ['edit', 'delete'],
                columns: [
                    { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", width: 120, sortable: false, getStringValue: getTimeValue, valueGetter: getTimeValue },
                    { field: "createdOn", headerName: "Requested On", width: 120, type: "date" },
                    { field: "meargen", headerName: "Meargen", width: 120, type: 'singleSelect' },
                    { field: "yeshiva", headerName: "Yeshiva", width: 120, type: 'singleSelect' },
                    { field: "type", headerName: "Type", width: 120, hide: true },
                    { field: "note", headerName: "Note", width: 120, enableTooltip: true, enableDialog: true, },
                    {
                        field: "potentials",
                        headerName: "Potentials",
                        width: 120, getStringValue: getPotentialValue,
                        getTooltipTitle: getPotentialValueTooltip,
                        enableTooltip: true,
                        enableDialog: true,
                        valueGetter: getPotentialValue,
                    },
                    { field: "statusIcon", headerName: "Status Icon", headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding', valueGetter: getStatusValue, type: 'singleSelect' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }

                ],
                options: [
                    { label: "Yungerliet", value: "yungerman" },
                    { label: "Professional", value: "professional" },
                    { label: "All", value: "" },
                ],
            },
            [constant.tabsConst.financialSchedule]: {
                show: true,
                title: "Pending Financial Schedules",
                cardTitle: "Financial",
                icon: 'Bussiness',
                actions: ['edit', 'delete'],
                quickFilters: {
                    type: 'select',
                    selectedValue: scheduleFilterValue,
                    dropdowns: [{
                        field: 'type',
                        value: scheduleFilterValue,
                        handler: (event: any) => {
                            setScheduleFilterValue(event?.target?.value)
                        },
                        options: [
                            {
                                label: 'Yungerliet',
                                value: 'yungerman'
                            },
                            {
                                label: 'Professional',
                                value: 'professional'
                            },
                            {
                                label: 'Appointments',
                                value: 'appointment'
                            },
                            {
                                label: 'All',
                                value: ''
                            },
                        ]
                    }],
                },
                columns: [
                    //{ field: "startTime", headerClassName: 'noHeaderDivider', headerName: "Start Time", width: 120, hide: true, type: "dateTime" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "dateTime" },
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", width: 120, sortable: false, getStringValue: getTimeValue, valueGetter: getTimeValue },
                    { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details", type: 'singleSelect' },
                    { field: "paymentType", headerName: "Payment Type", width: 120 },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "startDate", headerName: "Start Date", width: 120, hide: true, type: "date" },
                    { field: "endDate", headerName: "End Date", width: 120, hide: true, type: "date" },
                    { field: "throughout", headerName: "Dates", width: 120, getStringValue: getDatesValue, valueGetter: getDatesValue },
                    { field: "rateFull", headerName: "Rate", width: 120, getStringValue: getRateValue, valueGetter: getRateValue },
                    { field: "rate", headerName: "Rate (number)", width: 120, hide: true, type: "number" },
                    { field: "ratePeriod", headerName: "Rate Period", width: 120, hide: true, getStringValue: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities], valueGetter: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities] },
                    { field: "meargen", headerName: "Meargen", width: 120, type: 'singleSelect' },
                    { field: "financialCoordinator", headerName: "Billing Coordinator", width: 120, type: 'singleSelect' },
                    ...getAdditionalColumns(constant.tabsConst.financialSchedule),
                    { field: "comfortHealthStatus", headerName: "Approval", width: 120, hide: true },
                    { field: "statusIcon", headerName: "Status Icon", headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding', valueGetter: getStatusValue, type: 'singleSelect' },
                    { field: "moreIcon", headerName: "More Icon", headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ],
                options: [
                    { label: "Yungerliet", value: "yungerman" },
                    { label: "Professional", value: "professional" },
                    { label: "Appointments", value: "appointment" },
                    { label: "All", value: "" },
                ]
            },
            [constant.tabsConst.activeSchedule]: {
                show: true,
                title: "Active Schedules",
                cardTitle: "Active Schedules",
                icon: "PlayCircle",
                actions: ['edit', 'delete'],
                quickFilters: {
                    type: 'select',
                    dropdowns: [{
                        field: 'type',
                        value: scheduleFilterValue,
                        handler: (event: any) => {
                            setScheduleFilterValue(event?.target?.value)
                        },
                        options: [
                            {
                                label: 'Yungerman',
                                value: 'yungerman'
                            },
                            {
                                label: 'Professional',
                                value: 'professional'
                            },
                        ]

                    }],
                },
                columns: [
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", width: 120, sortable: false, getStringValue: getTimeValue },
                    { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details", type: 'singleSelect' },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "startDate", headerName: "Start Date", width: 120, hide: true, type: "date" },
                    { field: "endDate", headerName: "End Date", width: 120, hide: true, type: "date" },
                    { field: "throughout", headerName: "Dates", width: 120, getStringValue: getDatesValue, valueGetter: getDatesValue },
                    { field: "rateFull", headerName: "Rate", width: 120, getStringValue: getRateValue, valueGetter: getRateValue },
                    { field: "rate", headerName: "Rate (number)", width: 120, hide: true, type: "number" },
                    { field: "hoursPerWeek", headerName: "Hours per Week", width: 120, type: "number" },
                    { field: "totalDollarsPerWeek", headerName: "Price per Week", width: 120, type: "number" },
                    { field: "ratePeriod", headerName: "Rate Period", width: 120, hide: true, getStringValue: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities], valueGetter: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities] },
                    { field: "meargen", headerName: "Meargen", width: 120, type: 'singleSelect' },
                    { field: "statusIcon", headerName: "Status Icon", headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding', valueGetter: getStatusValue, type: 'singleSelect' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ],
                options: [
                    { label: "Yungerliet", value: "yungerman" },
                    { label: "Professional", value: "professional" },
                    { label: "All", value: "" },
                ]
            },
            [constant.tabsConst.statusStepDuration]: {
                show: true,
                title: "Status Step Duration",
                cardTitle: "Status Step Duration",
                icon: "PlayCircle",
                actions: ['edit', 'delete'],
                columns: [
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", width: 120, sortable: false, getStringValue: getTimeValue },
                    { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details", type: 'singleSelect' },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "startDate", headerName: "Start Date", width: 120, hide: true, type: "date" },
                    { field: "endDate", headerName: "End Date", width: 120, hide: true, type: "date" },
                    { field: "createdOn", headerName: "Created On", width: 120, hide: true, type: "date" },
                    {
                        field: "confirmed", headerName: "Confirmed", width: 120, enableTooltip: true, disableColumnMenu: true, renderCell: (param: any) => (
                            <Tooltip title={param.confirmed ? param.dateFinancail : ''}>
                                <DivContainer>
                                    {param.confirmed}
                                </DivContainer>
                            </Tooltip>
                        ), sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding'
                    },
                    {
                        field: "active", headerName: "Active", width: 120, enableTooltip: true, disableColumnMenu: true, renderCell: (param: any) => (
                            <Tooltip title={param.active ? param.dateActive : ''}>
                                <DivContainer>
                                    {param.active}
                                </DivContainer>
                            </Tooltip>
                        ), sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding'
                    },
                    { field: "throughout", headerName: "Dates", width: 120, getStringValue: getDatesValue, valueGetter: getDatesValue },
                    { field: "rateFull", headerName: "Rate", width: 120, getStringValue: getRateValue, valueGetter: getRateValue },
                    { field: "rate", headerName: "Rate (number)", width: 120, hide: true, type: "number" },
                    { field: "hoursPerWeek", headerName: "Hours per Week", width: 120, type: "number" },
                    { field: "totalDollarsPerWeek", headerName: "Price per Week", width: 120, type: "number" },
                    { field: "ratePeriod", headerName: "Rate Period", width: 120, hide: true, getStringValue: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities], valueGetter: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities] },
                    { field: "meargen", headerName: "Meargen", width: 120, type: 'singleSelect' },
                    { field: "statusIcon", headerName: "Status Icon", headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding', valueGetter: getStatusValue, type: 'singleSelect' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ],
                options: [
                    { label: "Yungerliet", value: "yungerman" },
                    { label: "Professional", value: "professional" },
                    { label: "All", value: "" },
                ]
            },
            [constant.tabsConst.paymentTypeSchedule]: {
                show: true,
                title: "Schedules by Payment Type",
                cardTitle: "Schedules by Payment Type",
                icon: "Payment",
                actions: ['edit', 'delete'],
                columns: [
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", width: 120, sortable: false, getStringValue: getTimeValue },
                    { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details", type: 'singleSelect' },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "startDate", headerName: "Start Date", width: 120, hide: true, type: "date" },
                    { field: "endDate", headerName: "End Date", width: 120, hide: true, type: "date" },
                    { field: "throughout", headerName: "Dates", width: 120, getStringValue: getDatesValue, valueGetter: getDatesValue },
                    { field: "rateFull", headerName: "Rate", width: 120, getStringValue: getRateValue, valueGetter: getRateValue },
                    { field: "rate", headerName: "Rate (number)", width: 120, hide: true, type: "number" },
                    { field: "paymentType", headerName: "Payment Type", width: 120 },
                    { field: "hoursPerWeek", headerName: "Hours per Week", minWidth: 120, maxWidth: 150, type: "number" },
                    { field: "totalDollarsPerWeek", headerName: "Price per Week", valueGetter: getWeeklyRates, width: 120, minWidth: 120, maxWidth: 150, type: "number" },
                    { field: "ratePeriod", headerName: "Rate Period", width: 120, hide: true, getStringValue: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities], valueGetter: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities] },
                    { field: "meargen", headerName: "Meargen", width: 120, type: 'singleSelect' },
                    { field: "statusIcon", headerName: "Status Icon", headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding', valueGetter: getStatusValue, type: 'singleSelect' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ],
            },
            [constant.tabsConst.rejectedSchedule]: {
                show: true,
                title: "Rejected Schedules",
                cardTitle: "Rejected",
                icon: 'CancelSchedule',
                actions: ['edit', 'delete'],
                columns: [
                    //{ field: "startTime", headerClassName: 'noHeaderDivider', headerName: "Start Time", width: 120, hide: true, type: "dateTime" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "dateTime" },
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", width: 120, sortable: false, getStringValue: getTimeValue },
                    { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
                    { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details", type: 'singleSelect' },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "startDate", headerName: "Start Date", width: 120, hide: true, type: "date" },
                    { field: "endDate", headerName: "End Date", width: 120, hide: true, type: "date" },
                    { field: "throughout", headerName: "Dates", width: 120, getStringValue: getDatesValue, valueGetter: getDatesValue },
                    { field: "rateFull", headerName: "Rate", width: 120, getStringValue: getRateValue, valueGetter: getRateValue },
                    { field: "rate", headerName: "Rate (number)", width: 120, hide: true, type: "number" },
                    { field: "ratePeriod", headerName: "Rate Period", width: 120, hide: true, getStringValue: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities], valueGetter: (params: any) => periodicities[params.row.ratePeriod as keyof typeof periodicities] },
                    { field: "meargen", headerName: "Meargen", width: 120, type: 'singleSelect' },
                    { field: "financialCoordinator", headerName: "Billing Coordinator", width: 120, type: 'singleSelect' },
                    { field: "reasonForRejection", headerName: "Reason", width: 120, hide: true },
                    { field: "comfortHealthStatus", headerName: "Approval", width: 120, hide: true },
                    { field: "statusIcon", headerName: "Status Icon", headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding', valueGetter: getStatusValue, type: 'singleSelect' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ],
                options: [
                    { label: "Yungerliet", value: "yungerman" },
                    { label: "Professional", value: "professional" },
                    { label: "Appointments", value: "appointment" },
                    { label: "All", value: "" },
                ]
            },
        }
    }

    const cards = getFeaturedScheduleColumns();

    const refreshScheduleData = (id: number) => {
        getDashboardSchedules(id).then((data: any) => {
            let schedules = data.map((d: any) => {
                return yungermenDataMapping(d);
            });
            setScheduleRows(schedules);
        })
        getClientScheduleStatusList();
        getPaymentTypeSchedules({ paymentType: constant.PAYMENT_TYPE.all, onGoingStatus: constant.ON_GOING_STATUS.all });
    }

    const yungermenDataMapping = (d: any) => {
        return {
            clientScheduleId: d.clientScheduleId,
            createdOn: d.createdOn,
            startTime: globalConst.dateTime.filterDefaultDate(d.startTime),
            endTime: globalConst.dateTime.filterDefaultDate(d.endTime),
            providerName: d.providerName,//
            clientName: d.clientName,//
            providerId: d.providerId,//
            clientId: d.clientId,//
            schedule: d.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ? getWeekdaysString(d.days) : "", //
            scheduleDisplayName: getScheduleStringForProfessional(d.days, d.everyXOfPeriods, d.periodicity),
            startDate: globalConst.dateTime.filterDefaultDate(d.startDate),
            endDate: globalConst.dateTime.filterDefaultDate(d.endDate),
            rate: d.rate,
            ratePeriod: d.ratePeriod,
            type: d.type,
            note: d.note,
            reasonScheduleEnded: d.reasonScheduleEnded,
            reasonForRejection: d.reasonForRejection,
            comfortHealthStatus: d.comfortHealthStatus,
            matura: "",
            meargen: d.meargenName,
            financialCoordinator: d.financialCoordinatorName,
            yeshiva: d.yeshiva,
            status: d.status,
            paymentType: d.paymentType,
            dateApproved: d.dateApproved,
            specialties: d.specialties,
            professionalType: d.professionalType,
            fundingNote: d.fundingNote,
            employeeNote: d.employeeNote,
            dragonNote: d.dragonNote,
            cashParentNote: d.cashParentNote,
            comfortParentNote: d.comfortParentNote,
            isCashConfirmedWithParent: d.isCashConfirmedWithParent,
            isEnteredInDragon: d.isEnteredInDragon,
            isComfortConfirmedWithParent: d.isComfortConfirmedWithParent,
            isConfirmedWithEmployee: d.isConfirmedWithEmployee,
            isConfirmedFunding: d.isConfirmedFunding,
            isOnProfessionalGrantSheet: d.isOnProfessionalGrantSheet,
            professionalGrantSheetNote: d.professionalGrantSheetNote,

            clientEmployeeSchedulePotentials: !d.clientEmployeeSchedulePotentials ? [] : d.clientEmployeeSchedulePotentials.map((cesp: any) => ({
                providerName: cesp.providerName,
                status: cesp.status,
            })),
        }
    }

    const handleYungermanRowDelete = (row: any) => {
        setYungermenLoading(true);
        scheduleContext.deleteEntity(row.clientScheduleId, 'yungerman')?.then((data) => {
            if (!!data) {
                let newTableData = scheduleRows;
                let index = newTableData.findIndex((cs: any) => cs.clientScheduleId === row.clientScheduleId);
                if (index > -1) {
                    newTableData.splice(index, 1);
                }
                setScheduleRows(newTableData);
            }
            refreshScheduleData(userId)
            setYungermenLoading(false);
            setdel(false);
        })
    };

    const handleRowDelete = () => {
        if (slcRow_Provider?.clientScheduleId !== undefined) {
            if (slcRow_Type === "appointment") {

            }
            //if (slcRow_Type === "yungerman")
            if (slcRow_Type === "availability") {
                // handleAvailabilityRowDelete(slcRow_Provider);
            }
            else {
                handleYungermanRowDelete(slcRow_Provider);
            }
            //else
            //    handleProfessionalRowDelete(slcRow_Provider);
        }
        else
            globalConst.log("Can't find client schedule with ID " + slcRow_Provider?.clientScheduleId);
    }

    const handleDeleteClose = (event: any, reason: any) => {
        if (reason != "cancel") {

        }
        setdel(false);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (event: any, row: any, menuItems: any, scheType: any, isPos: boolean = false) => {
        setSlcRow_Type(row.type);
        setSlcRow_Provider(row);
        if (menuItems)
            setMenu(menuItems.filter((item: any) => ['edit', 'delete'].includes(item)));
        else return;
        if (isPos) {
            setXPos(event.pageX - 172.5 - window.pageXOffset);
            setYPos(event.pageY - window.pageYOffset)
        } else {
            setXPos("0px");
            setYPos("0px");
        }
        setAnchorEl(event.currentTarget);
    };

    const handleDeleteOpen = () => {
        setdel(true);
        setAnchorEl(null);
    };

    const setSlcRowData = (row: any, scheType: any) => {
        setSlcRow_Type(scheType);
        setSlcRow_Provider(row);
    }

    const getScheduleCount = (scheType: string) => {
        switch (scheType) {
            case constant.tabsConst.activeSchedule:
                return activeScheduleList.length
            case constant.tabsConst.paymentTypeSchedule:
                return paymentTypeSchedules.length
            case constant.tabsConst.clientScheduleStatus:
                return clientScheduleStatusList.length
            case constant.tabsConst.statusStepDuration:
                return statusStepDurationData.length
            default:
                return filteredScheduleRows.length
        }
    }

    const saveYungermanRow = (row: any) => {
        setYungermenLoading(true);
        scheduleContext?.saveEntity(row, "yungerman")?.then((data: any) => {
            setYungermenLoading(false);
            setShow_ProviderRow(false);
            refreshScheduleData(userId)
        })
    }

    const saveAppointmentRow = (row: any) => {
        setIsLoading_ProviderRow(true);
        scheduleContext.saveEntity(row, "appointment")?.then((data) => {
            setIsLoading_ProviderRow(false);
            setShow_ProviderRow(false);
            refreshScheduleData(userId);
        })
    }

    const handleEditSave = (row: any) => {
        switch (slcRow_Type) {
            case "yungerman":
            case "professional":
                saveYungermanRow(row);
                break;
            case "appointment":
                saveAppointmentRow(row);
                break;
            default:
        }
    };

    const handleClose = () => {
        setShow_ProviderRow(false);
        setRow_Provider(defaultRow_Provider);
    };

    const handleClickFilter = (value: string, type: string) => {
        switch (type) {
            case 'payment':
                setPaymentFilterBy(value)
                break;
            case 'active':
                setActiveFilterBy(value);
                break
            default: return null
        }

    }

    const getFilteredPaymentTypeRows = (paymentType: string, status: string) => {
        if (scheduleFilterValue !== 'yungerman') return paymentTypeSchedules
        switch (status) {
            case constant.ON_GOING_STATUS.active:
                return paymentTypeSchedules.filter((item: any) => (paymentType === constant.PAYMENT_TYPE.all || item.paymentType.toLowerCase() === paymentType) && item.status.toLowerCase() === status && !item.endDate)
            case constant.ON_GOING_STATUS.ended:
                return paymentTypeSchedules.filter((item: any) => (paymentType === constant.PAYMENT_TYPE.all || item.paymentType.toLowerCase() === paymentType) && !!item.endDate)
            case constant.ON_GOING_STATUS.financial:
                console.log("in finance")
                return paymentTypeSchedules.filter((item: any) => (paymentType === constant.PAYMENT_TYPE.all || item.paymentType.toLowerCase() === paymentType) && item.status.toLowerCase() === status && !item.endDate)
            default:
                return paymentTypeSchedules.filter((item: any) => paymentType === constant.PAYMENT_TYPE.all || item.paymentType.toLowerCase() === paymentType)
        }
    }

    const getFilteredFinancialRows = () => {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        return filteredScheduleRows.filter((item: any) =>

            (!item.endDate || new Date(item.endDate) >= sevenDaysAgo)
        );
    };

    const getSchedulesRows = (type: string) => {
        switch (type) {
            case constant.tabsConst.activeSchedule:
                console.log("this 1")
                return activeSchedules
            case constant.tabsConst.paymentTypeSchedule:
                console.log("this 2")
                return getFilteredPaymentTypeRows(paymenFilterBy, activeFilterBy)
            case constant.tabsConst.clientScheduleStatus:
                console.log("this 3")
                return clientScheduleStatusList
            case constant.tabsConst.statusStepDuration:
                console.log("this 4")
                return statusStepDurationData
            case constant.tabsConst.financialSchedule:
                return getFilteredFinancialRows()
            default:
                console.log("this 5")
                return filteredScheduleRows
        }
    }

    const [rowId, setRowId] = React.useState(-1);
    useEffect(() => {
        if (row_Provider && row_Provider.clientScheduleId !== rowId)
            setRowId(row_Provider != undefined ? row_Provider.clientScheduleId : -1);
    }, [row_Provider])

    return (
        <DivContainer>
            <DetailHeader
                boxLabel={cards[scheduleType]['cardTitle']}
                boxIcon={cards[scheduleType]['icon']}
                badgeCount={getScheduleCount(scheduleType)}
            />
            {scheduleFilterValue === 'yungerman' && scheduleType === constant.tabsConst.paymentTypeSchedule &&
                <FlexContainer>
                    <DivContainer margin="0px 10px">
                        <DivContainer>
                            Payment Type:
                        </DivContainer>
                        <FilterButtonGroup
                            variant="outlined"
                            selectedVariant="contained"
                            filterOptions={constant.DASHBAORD_ACTIVE_SCHEDULES_PAYEMENT_FILTERS}
                            onClick={(value: string) => handleClickFilter(value, 'payment')}
                            style={{ textTransform: 'capitalize' }}
                            selected={paymenFilterBy}
                        />
                    </DivContainer>
                    <DivContainer margin="0px 10px">
                        <DivContainer>
                            Status:
                        </DivContainer>
                        <FilterButtonGroup
                            variant="outlined"
                            selectedVariant="contained"
                            filterOptions={
                                constant.DASHBAORD_ACTIVE_SCHEDULES_ACTIVE_FILTERS
                            }
                            onClick={(value: string) => handleClickFilter(value, 'active')}
                            style={{ textTransform: 'capitalize' }}
                            selected={activeFilterBy}
                        />
                    </DivContainer>
                </FlexContainer>
            }
            <ProtectedComponent allow={currPermissions[scheduleType].canView} canEdit>
                <CustomCard
                    rows={getSchedulesRows(scheduleType)}
                    isLoading={isYungermenLoading}
                    handleMenuClick={handleMenuClick}
                    handleDeleteOpen={handleDeleteOpen}
                    setSlcRowData={setSlcRowData}
                    scheType={scheduleFilterValue}
                    routeTab='schedule'
                    screen={`dashboard_${scheduleType}`}
                    title={cards[scheduleType]['title']}
                    columns={cards[scheduleType]['columns']}
                    actions={cards[scheduleType]['actions']}
                    actionPermissions={generalPermissions.actions}
                    yungermanActionPermissions={{
                        ...generalPermissions.actions,
                        ...yungermanPermissions.actions
                    }}
                    professionalActionPermissions={{
                        ...generalPermissions.actions,
                        ...professionalPermissions.actions
                    }}
                    quickFilters={cards[scheduleType]['quickFilters'] ? cards[scheduleType]['quickFilters'] : null}
                    valueOptionsLists={scheduleType === 'Seeking' ? seekingValueOptionsList : financialValueOptionsList}
                    pagination
                />
            </ProtectedComponent>
            <YungermanModal
                show={show_ProviderRow && ["yungerman", "professional", "availability"].includes(slcRow_Type)}
                handleEdit={handleEditSave}
                handleClose={handleClose}
                clientScheduleId={rowId}
                scheduleContext={scheduleContext}
                clientSchedule={row_Provider}
                isSeekingProvider={!slcRow_Provider || slcRow_Provider.status === 'seeking' || slcRow_Provider.status === 'onhold'}
                setClientSchedule={setRow_Provider}
                isLoading={isLoading_ProviderRow}
                setIsLoading={setIsLoading_ProviderRow}
                scheduleType={slcRow_Type}
                actionPermissions={slcRow_Type === "yungerman" ? {
                    ...generalPermissions.actions,
                    ...yungermanPermissions.actions
                } : slcRow_Type === "professional" ? {
                    ...generalPermissions.actions,
                    ...professionalPermissions.actions
                } : undefined
                }
            />
            <DeleteModel
                del={del}
                loading={isYungermenLoading}
                handleDelete={handleRowDelete}
                handleDeleteClose={handleDeleteClose}
                slcRow={slcRow_Provider} />
            <AppointmentModal
                show={show_ProviderRow && slcRow_Type === "appointment"}
                handleEdit={handleEditSave}
                handleClose={handleClose}
                clientScheduleId={slcRow_Provider != undefined ? slcRow_Provider.clientScheduleId : -1}
                clientSchedule={row_Provider}
                providers={professionals}
            />
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
                sx={{
                    top: yPos,
                    left: xPos,
                }}
            >
                {menu.includes("edit") ? <MenuItem onClick={(event) => {
                    handleEditOpen(slcRow_Provider, slcRow_Type);
                    event.stopPropagation();
                }}>Edit </MenuItem> : null}
                {menu.includes("delete") ? <MenuItem className={classes.error} onClick={handleDeleteOpen}>Delete{" "}</MenuItem> : null}
            </Menu>
        </DivContainer>
    )
}

export default Schedules