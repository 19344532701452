import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: "12px 0px 12px 0px",
    margin: "12px 26px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiCardContent-root:last-child": {
        padding: "0px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      cursor: "pointer",
      fontSize: "12px",
    },
    /*'& .MuiPaper-outlined': {
            border: '0px',
        }*/
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-root": {
      border: "0px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    "& .MuiInputBase-input": {
      fontWeight: 500,
      fontSize: 12,
      fontFamily: "Roboto Helvetica Arial sans-serif",
    },
  },

}));
