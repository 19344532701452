import { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

import SCHEDULE_PERIODICITY_CONSTANTS from '../_helpers/constants/schedule_periodicity_constants.js';
import { getScheduleStringForProfessional, getDaysArrayFromDaysString } from "../_helpers/schedule";
import { getNextAppointmentString } from "../_helpers/schedule/validators/client-schedule";
import { defaultDates } from "../_helpers/datetime";
import * as constant from '../constants';

const defaultRow_Provider = {
    specialties: [], clientProviderSchedulePotentials: [], status: "seeking",
    schedule: { date: undefined, days: "", periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS }
};
const useScheduleEditDataManager = () => {
    const contactId: number | undefined = undefined; // Default to undefined
    const globalConst: any = global
    const [entity, setEntity] = useState<any>(defaultRow_Provider);
    const [loading, setLoading] = useState<boolean>(false);

    const exploringId = useStoreState((state: any) => state.exploringId);
    const exploringType = useStoreState((state: any) => state.exploringType);

    const filterDefaultDateValuesFromSchedule = (schedule: any) => {
        return {
            date: globalConst.dateTime.filterDefaultDate(schedule.date),
            endDate: globalConst.dateTime.filterDefaultDate(schedule.endDate),
            startTime: globalConst.dateTime.filterDefaultDate(schedule.startTime),
            endTime: globalConst.dateTime.filterDefaultDate(schedule.endTime),
        }
    }

    const formatDate = (dateTime: string) => {
        return globalConst.dateTime.getUSAFormattedDateString(dateTime);
    }

    const getThroughoutSting = (startDate: string, endDate: string) => `${startDate ? formatDate(globalConst.dateTime.filterDefaultDate(startDate)) : "-"} - ${endDate ? formatDate(globalConst.dateTime.filterDefaultDate(endDate)) : "-"}`

    const getWeekdaysString = (weekdays: any, includeAndStringBetweenLastDays = false) => {
        return globalConst.dateTime.getWeekdaysString(weekdays, includeAndStringBetweenLastDays);
    }

    const defaultPromise = new Promise((resolve, reject) => { });

    const loadAppointmentData = () => {
        let endpointUrl: string = '';
        switch (exploringType) {
            case "clients":
                endpointUrl = constant.CLIENT_SCHEDULE_GET_APPOINTMENT_SCHEDULE;
                break;
            case "employees":
            //endpointUrl = constant.CLIENT_SCHEDULE_GET_APPOINTMENTS_BY_PROVIDER; -- not implemented
            default:
        }
        if (!endpointUrl) return;
        setLoading(true);
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper
                .get(endpointUrl + exploringId)
                .then((response: any) => {
                    globalConst.log("appt rows", response);
                    let ressult = response.appointmentSchedules.map((d: any) => {
                        return {
                            clientScheduleId: d.id,
                            date: globalConst.dateTime.filterDefaultDate(d.date),
                            startTime: globalConst.dateTime.filterDefaultDate(d.startTime),
                            endTime: globalConst.dateTime.filterDefaultDate(d.endTime),
                            time: (d.startTime || d.endTime) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(d.startTime), globalConst.dateTime.filterDefaultDate(d.endTime)) : "-",
                            providerName: d.providerName,
                            clientName: d.clientName,
                            provider_contactId: d.provider_contactId,
                            providerId: d.providerId,
                            note: d.note,
                        }
                    })
                    resolve(ressult)
                    setLoading(false);
                })
        })
    }

    const loadAvailabilityData = () => {
        let idCounter = 1;
        const actionUrl = "EmployeeDaily"; //props.entityType === "employee" ? "EmployeeDaily" : "ProfessionalDaily";
        setLoading(true);
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.get(constant.BASE_URL + 'api/cms/Schedules/' + actionUrl + '/' + exploringId)
                .then((response: any) => {
                    const availRows = response.map((schedule: any) => {
                        return {
                            idInc: idCounter++,
                            clientScheduleId: schedule.id,
                            startTime: schedule.timeFrom,// new Date(schedule.timeFrom).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                            endTime: schedule.timeTo, // new Date(schedule.timeTo).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                            startDate: schedule.startDate,
                            endDate: schedule.endDate,
                            time: (schedule.timeFrom || schedule.timeTo) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(schedule.timeFrom), globalConst.dateTime.filterDefaultDate(schedule.timeTo)) : "-",
                            between: globalConst.dateTime.formatStartAndEndDate(globalConst.dateTime.filterDefaultDate(schedule.startDate), globalConst.dateTime.filterDefaultDate(schedule.endDate)),
                            scheduleDisplayName: schedule.days ? getWeekdaysString(schedule.days) : "",
                            throughout: getThroughoutSting(schedule.startDate, schedule.endDate),
                            schedule: {
                                days: schedule.days.join(","), //schedule.days.map(day => day.slice(0, 3)).join(", "),
                                startTime: schedule.timeFrom,// new Date(schedule.timeFrom).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                                endTime: schedule.timeTo, // new Date(schedule.timeTo).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                                date: schedule.startDate,
                                endDate: schedule.endDate,
                                periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS,
                                everyXOfPeriods: schedule.everyXOfPeriods
                            },
                            contactScheduleId: "put the contact schedule id here"
                        };
                    })
                    resolve(availRows);
                    setLoading(false);
                })
        })
    }
    // const loadAvailabilityData = (isApplicant: boolean, contactId?: number) => {
    //     let idCounter = 1; // Initialize an ID counter for unique keys
    //     setLoading(true); // Set loading state to true

    //     return new Promise((resolve, reject) => {
    //         // Determine the action URL based on whether it's for an applicant
    //         const actionUrl = isApplicant && contactId
    //             ? `${constant.APPLICANT_SCHEDULES}${contactId}` // URL for applicant schedules
    //             : "EmployeeDaily"; // Default action URL for availability

    //         // Fetch the data using the determined action URL
    //         globalConst.fetchWrapper.get(constant.BASE_URL + 'api/cms/Schedules/' + actionUrl + '/' + exploringId)
    //             .then((response: any) => {
    //                 // Map the response to the desired format
    //                 const rows = response.map((schedule: any) => {
    //                     return {
    //                         idInc: idCounter++, // Incremental ID for each schedule
    //                         clientScheduleId: schedule.id, // Assuming `schedule.id` is the client schedule ID
    //                         startTime: schedule.timeFrom,
    //                         endTime: schedule.timeTo,
    //                         startDate: schedule.startDate,
    //                         endDate: schedule.endDate,
    //                         time: (schedule.timeFrom || schedule.timeTo) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(schedule.timeFrom), globalConst.dateTime.filterDefaultDate(schedule.timeTo)) : "-",
    //                         between: globalConst.dateTime.formatStartAndEndDate(globalConst.dateTime.filterDefaultDate(schedule.startDate), globalConst.dateTime.filterDefaultDate(schedule.endDate)),
    //                         scheduleDisplayName: schedule.days ? getWeekdaysString(schedule.days) : "",
    //                         throughout: getThroughoutSting(schedule.startDate, schedule.endDate),
    //                         schedule: {
    //                             days: schedule.days.join(","),
    //                             startTime: schedule.timeFrom,
    //                             endTime: schedule.timeTo,
    //                             date: schedule.startDate,
    //                             endDate: schedule.endDate,
    //                             periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS,
    //                             everyXOfPeriods: schedule.everyXOfPeriods
    //                         },
    //                         contactScheduleId: "put the contact schedule id here" // Update as needed
    //                     };
    //                 });

    //                 resolve(rows);
    //                 setLoading(false);
    //             })

    //     });
    // };



    const loadApplicantData = (contactId: number) => {
        let idCounter = 1;
        setLoading(true);

        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.get(constant.APPLICANT_SCHEDULES + contactId)
                .then((response: any) => {
                    console.log(response)
                    const scheduleRows = response.result.map((schedule: any) => {

                        return {
                            idInc: idCounter++,
                            clientScheduleId: schedule.id,
                            startTime: schedule.startTime,
                            endTime: schedule.endTime,
                            startDate: schedule.startDate,
                            endDate: schedule.endDate,
                            time: (schedule.startTime || schedule.endTime) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(schedule.startTime), globalConst.dateTime.filterDefaultDate(schedule.endTime)) : "-",
                            between: globalConst.dateTime.formatStartAndEndDate(globalConst.dateTime.filterDefaultDate(schedule.startDate), globalConst.dateTime.filterDefaultDate(schedule.endDate)),
                            scheduleDisplayName: schedule.days ? getWeekdaysString(schedule.days) : "",
                            throughout: getThroughoutSting(schedule.startDate, schedule.endDate),
                            schedule: {
                                // days: schedule.days.join(","), //schedule.days.map(day => day.slice(0, 3)).join(", "),
                                days: schedule.days,
                                startTime: schedule.timeFrom,// new Date(schedule.timeFrom).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                                endTime: schedule.timeTo, // new Date(schedule.timeTo).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                                date: schedule.startDate,
                                endDate: schedule.endDate,
                                periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS,
                                everyXOfPeriods: schedule.everyXOfPeriods
                            },
                            contactScheduleId: "put the contact schedule id here"
                        };
                    })
                    console.log('scheduleRows')
                    resolve(scheduleRows);
                    setLoading(false);
                })


        });
    };

    const loadYungermanData = (client: any, provider: any) => {
        let endpointUrl: string = "";
        switch (exploringType) {
            case "clients":
                endpointUrl = constant.CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE;
                break;
            case "employees":
                endpointUrl = constant.CLIENT_SCHEDULE_GET_BY_PROVIDER;
                break
            default:
        }
        if (!endpointUrl)
            return;
        setLoading(true);
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper
                .get(endpointUrl + exploringId)
                .then((response: any) => {
                    const schedules = response.map((d: any) => {
                        return {
                            clientScheduleId: d.clientScheduleId,
                            createdOn: globalConst.dateTime.getUSAFormattedDateString(d.createdOn),
                            startTime: globalConst.dateTime.filterDefaultDate(d.startTime),
                            endTime: globalConst.dateTime.filterDefaultDate(d.endTime),
                            time: (d.startTime || d.endTime) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(d.startTime), globalConst.dateTime.filterDefaultDate(d.endTime)) : "-",
                            throughout: getThroughoutSting(d.startDate, d.endDate),
                            providerName: !d.providerName && !!provider ? provider?.name : d.providerName,//
                            clientName: !d.clientName && !!client ? client?.name : d.clientName,//
                            providerId: !d.providerId && !!provider ? provider.id : d.providerId,//
                            clientId: !d.clientId && !!client ? client.id : d.clientId,//
                            schedule: d.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ? getWeekdaysString(d.days) : "", //
                            scheduleDisplayName: getScheduleStringForProfessional(d.days, d.everyXOfPeriods, d.periodicity),
                            startDate: globalConst.dateTime.filterDefaultDate(d.startDate),
                            endDate: globalConst.dateTime.filterDefaultDate(d.endDate),
                            rate: d.rate,
                            between: globalConst.dateTime.formatStartAndEndDate(globalConst.dateTime.filterDefaultDate(d.startDate), globalConst.dateTime.filterDefaultDate(d.endDate)),
                            ratePeriod: d.ratePeriod,
                            type: d.type,
                            note: d.note,
                            reasonScheduleEnded: d.reasonScheduleEnded,
                            reasonForRejection: d.reasonForRejection,
                            matura: "",
                            status: d.status,
                            dateApproved: d.dateApproved,
                            specialties: d.specialties,
                            //providerScheduleStatus: d.providerScheduleStatus, not really needed
                            nextSession: getNextAppointmentString({ ...d, date: d.startDate }), //might only be needed for professional schedules // not yet available
                            professionalType: d.professionalType,//
                            clientEmployeeSchedulePotentials: !d.clientEmployeeSchedulePotentials ? [] : d.clientEmployeeSchedulePotentials.map((cesp: any) => ({
                                providerName: cesp.providerName,
                                status: cesp.status,
                            })),
                            days: d.days,
                            periodicity: d.periodicity,
                            everyXOfPeriods: d.everyXOfPeriods,
                            totalDollarsPerWeek: d.totalDollarsPerWeek,
                            hoursPerWeek: d.hoursPerWeek,

                        }
                    });
                    setLoading(false);
                    resolve(schedules)
                });
        })
    }

    const loadYungermanEdit = (id: number) => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.get(constant.CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_DETAILS + id).then((response: any) => {
                globalConst.log("yungermanScheduleDetails", response);
                const tempProvRow = {
                    ...response.clientSchedule,
                    timeFrom_Filter: response.clientSchedule.timeFrom_Filter ? new Date(response.clientSchedule.timeFrom_Filter) : null,
                    timeTo_Filter: response.clientSchedule.timeTo_Filter ? new Date(response.clientSchedule.timeTo_Filter) : null,
                    duration_Filter: response.clientSchedule.duration_Filter ? new Date(response.clientSchedule.duration_Filter) : null,
                    schedule: {
                        ...response.clientSchedule.schedule,
                        ...filterDefaultDateValuesFromSchedule(response.clientSchedule.schedule),
                    },
                    clientScheduleId: id,
                    "specialties":
                        response.clientSchedule.specialties ?
                            response.clientSchedule.specialties.split(",").map((sp: any) => sp.trim()) : [],
                    "professionalType": response.clientSchedule.professionalType,
                    "clientProviderSchedulePotentials":
                        response.clientSchedule.clientProviderSchedulePotentials.map((p: any) => {
                            return {
                                id: p.id,
                                providerId: p.providerId,
                                providerName: p.providerName,
                                clientName: p.clientName,
                                status: p.status,
                                rate: p.rate,
                                ratePeriod: p.ratePeriod,
                                specialties: p.specialties,
                                professionalType: p.professionalType, // needed for professional
                                isExternal: p.isExternal,
                                comfortHealthStatus: p.comfortHealthStatus,
                                availabilities: p.availabilities,
                                schedules: p.schedules,
                                potentialSchedules: p.potentialSchedules,
                            };
                        })
                }
                setEntity(tempProvRow);
                setLoading(false);
                resolve(tempProvRow);
            });
        })
    }

    const loadAppointmentEdit = (id: number) => {
        return defaultPromise;
    }

    const loadAvailabilityEdit = (id: number) => {
        return defaultPromise;
    }

    const deleteYungerman = (id: number) => {
        if (!id) return;

        const url = constant.CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_DELETE;
        setLoading(true);
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.post(url + id)
                .then((response: any) => {
                    globalConst.log("save", response);
                    if (response.status === 200) {
                        // log and set states back to normal
                        globalConst.log("save", response);
                        resolve(response)
                        setLoading(false);
                    } else {
                        resolve(false)
                        setLoading(false);
                        globalConst.log("error", response);
                    }

                });
        })

    }

    const deleteAvailability = (id: number) => {
        if (!id) return;
        setLoading(true);
        const url = constant.CLIENT_SCHEDULE_GET_YUNGERMAN_AVAILABILITY_SCHEDULE_DELETE;
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.post(url + id)
                .then((response: any) => {
                    globalConst.log("save", response);
                    if (response.status === 200) {
                        resolve(response);
                        // log and set states back to normal
                        globalConst.log("save", response);
                        setLoading(false);
                    } else {
                        globalConst.log("error", response);
                        setLoading(false);
                    }
                });

        })
    }

    const deleteAppointment = (id: number) => {
        return defaultPromise;
    }

    const saveYungerman = (row: any, type: string) => {
        setLoading(true);
        const schedule = {
            ...row.schedule,
            date: globalConst.dateTime.replaceEmptyDatesWithDefault(row.schedule.date),
            startTime: globalConst.dateTime.replaceEmptyDatesWithDefault(row.schedule.startTime),
            endTime: globalConst.dateTime.replaceEmptyDatesWithDefault(row.schedule.endTime),
        };

        const request = {
            ClientSchedule: {
                ...row,
                schedule,
                clientScheduleId: row.clientScheduleId,
                specialties: row.specialties?.join(","),
                clientId: exploringId && exploringType === "clients" ? exploringId : row.clientId,
                employeeId: exploringId && exploringType === "employees" ? exploringId : row.providerId,
                type: type || row.type,
                professionalType: row.professionalType,
            },
            Schedule: schedule,
            clientProviderSchedulePotentials: row.clientProviderSchedulePotentials?.map((p: any) => {
                return {
                    id: p.id,
                    employeeId: exploringId && exploringType === "employees" ? exploringId : p.providerId,
                    status: p.status,
                };
            })
        };
        const clientScheduleId = row.clientScheduleId ?? -1;
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.post(
                constant.CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_SAVE + clientScheduleId, request)
                .then((response: any) => {
                    globalConst.log("save", response);
                    setLoading(false);
                    resolve(true)
                });
        })
    }

    const saveAvailability = (row: any, type: string) => {
        setLoading(true);
        const clientScheduleId = row.clientScheduleId ?? -1;
        const contactSchedule = {
            scheduleId: clientScheduleId,
            scheduleType: type,
            id: exploringId,
            date: row.schedule.date,
            endDate: row.schedule.endDate,
            startTime: row.schedule.startTime,
            endTime: row.schedule.endTime,
            time: (row.startTime || row.endTime) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(row.startTime), globalConst.dateTime.filterDefaultDate(row.endTime)) : "-",
            daysOfWeek: getDaysArrayFromDaysString(row.schedule.days),
            //daysOfMonth: days,
            //interval: scheduleToBeAdded.interval,
            //category: scheduleToBeAdded.category
        }
        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.post(constant.BASE_URL + 'api/cms/schedules/employee/addNew', contactSchedule
            ).then((response: any) => {
                resolve(response)
                setLoading(false);
            }, (error: any) => {
                resolve(false)
                setLoading(false);

            })
        })
    }

    const saveApplicationAvailability = (row: any, type: string, id: number) => {
        setLoading(true);
        const clientScheduleId = row.clientScheduleId ?? -1;
        const contactSchedule = {
            scheduleId: clientScheduleId,
            scheduleType: type,
            id: exploringId,
            date: row.schedule.date,
            endDate: row.schedule.endDate,
            startTime: row.schedule.startTime,
            endTime: row.schedule.endTime,
            time: (row.startTime || row.endTime) ? globalConst.dateTime.formatStartAndEndTime(globalConst.dateTime.filterDefaultDate(row.startTime), globalConst.dateTime.filterDefaultDate(row.endTime)) : "-",
            daysOfWeek: getDaysArrayFromDaysString(row.schedule.days),
            //daysOfMonth: days,
            //interval: scheduleToBeAdded.interval,
            //category: scheduleToBeAdded.category
        }

        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.post(
                `${constant.BASE_URL}api/cms/schedules/employee/addNew?applicantContactId=${id}`,
                contactSchedule
            ).then((response: any) => {
                resolve(response)
                setLoading(false);
            }, (error: any) => {
                resolve(false)
                setLoading(false);

            })
        })
    }

    useEffect(() => {

        console.log(contactId)
    }, []);

    const saveAppointment = (row: any, type: string) => {
        setLoading(true);
        const defaultDate = defaultDates.cSharp;
        const request = {
            ClientSchedule: {
                clientScheduleId: row.clientScheduleId,
                type: type,
                clientId: exploringId && exploringType === "clients" ? exploringId : row.clientId,
                employeeId: exploringId && exploringType === "employees" ? exploringId : row.providerId,
                rate: row.rate,
                status: row.status,
                reasonForRejection: row.reasonForRejection,
                note: row.note,
            },
            Schedule: {
                startTime: row.startTime ? row.startTime : defaultDate,
                endTime: row.endTime ? row.endTime : defaultDate,
                date: row.date ? row.date : defaultDate,
            },
        };
        const clientScheduleId = row.clientScheduleId ?? -1;
        globalConst.log("request appt", request);

        return new Promise((resolve, reject) => {
            globalConst.fetchWrapper.post(
                constant.CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_SAVE + clientScheduleId, request)
                .then((response: any) => {
                    globalConst.log("save", response);
                    setLoading(false);
                    resolve(response);

                });
        })
    }

    const load = (type: string, client = null, provider = null, contactId?: number, isApplicant?: boolean) => {
        switch (type) {
            case constant.SCHEDULE_TYPE.yungerman:
                return loadYungermanData(client, provider);
            case constant.SCHEDULE_TYPE.appointment:
                return loadAppointmentData();
            case constant.SCHEDULE_TYPE.availability:
                if (!isApplicant) {
                    return loadAvailabilityData();
                } else {
                    return null;
                }
            case constant.SCHEDULE_TYPE.applicant:
                if (contactId === undefined) {
                    return null;
                }
                return loadApplicantData(contactId);
            default:
                return null;
        }
    };

    const loadEntity = (id: number, type: string, data = null) => {
        if (!id) return;
        if (!!data) {
            return new Promise((res, rej) => {
                res(data)
                setEntity(data)
            })
        }
        switch (type) {
            case constant.SCHEDULE_TYPE.yungerman:
            case constant.SCHEDULE_TYPE.professional:
                return loadYungermanEdit(id);
            case constant.SCHEDULE_TYPE.appointment:
                return loadAppointmentEdit(id);
            case constant.SCHEDULE_TYPE.availability:
                return loadAvailabilityEdit(id);
            default: return null;
        }

    }

    const deleteEntity = (id: number, type: string) => {
        if (!id) return;
        switch (type) {
            case constant.SCHEDULE_TYPE.yungerman:
            case constant.SCHEDULE_TYPE.professional:
            case constant.SCHEDULE_TYPE.appointment:
                return deleteYungerman(id);
            case constant.SCHEDULE_TYPE.availability:
                return deleteAvailability(id);
            default: return null;
        }
    }

    const saveEntity = (row: any, type: string) => {
        switch (type) {
            case constant.SCHEDULE_TYPE.yungerman:
            case constant.SCHEDULE_TYPE.professional:
                return saveYungerman(row, type);
            case constant.SCHEDULE_TYPE.appointment:
                return saveAppointment(row, type);
            case constant.SCHEDULE_TYPE.availability:
                return saveAvailability(row, type);
        }
    }

    const saveApplicationSchedule = (row: any, type: string, id: number) => {
        saveApplicationAvailability(row, type, id);
    }

    return [
        {
            loading: loading,
            entity: entity,
            load: load,
            loadEntity: loadEntity,
            deleteEntity: deleteEntity,
            saveEntity: saveEntity,
            saveApplicationSchedule: saveApplicationSchedule,
        }
    ]
}

export default useScheduleEditDataManager