import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';


export const HourHeaderContainer: any = styled("div")((props:any) => ({
    display: props.display || 'block',
    width: '100%',
    background: props.background || 'unset'
    
}));
export const Gap: any = styled("div")((props:any) => ({
    display: 'block',
    minWidth: props.width || '0px',
}));

export const SlotLabel:any = styled("div")((props:any) => ({
    width: props.width,
    display: 'flex',
    borderRadius: 15,
    color: '#fff',
    justifyContent: 'center',
    background: useTheme().palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

}));
export default makeStyles((theme: any) => ({
    green: {
        background: 'rgba(0,128,0, 0.5) !important',
    },
    yellow: {
        background: 'rgba(255,255,0, 0.5) !important'
    },
    red: {
        background: 'rgba(255,0,0, 0.5) !important'
    },
}))
