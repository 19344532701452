import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import usePermission from "../../hooks/usePermission";

const ProtectedRoutes = ({ component, user, permissions, name, ...rest }) => {
    //global.log("rest", rest);
    //global.log("name", name);
    //global.log("permissions", permissions);
    //global.log("component", component);
    const { canView } = usePermission(permissions);

    return (
        <Route
            {...rest}
            render={routeProps => (
                //<Component {...routeProps} />
                canView ? <Outlet/> : null
                //<Redirect to={
                //    {
                //        pathname: '/unauthorized',
                //        state: {
                //            from: routeProps.location
                //        }
                //    }
                //} />
            )}

            //children={
            //    canView ? Component :
            //        <Redirect to={
            //            {
            //                pathname: '/unauthorized',
            //                state: {
            //                    //from: routeProps.location
            //                }
            //            }
            //        } />
            //}

            //render={routeProps => (
            //    canView ? { element } :
            //        <Redirect to={
            //            {
            //                pathname: '/unauthorized',
            //                state: {
            //                    from: routeProps.location
            //                }
            //            }
            //        } />
            //)}
        />
    );
}

export default ProtectedRoutes;