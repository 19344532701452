import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Accordion,
    Grid,
    AccordionDetails,
    Divider,
    AccordionSummary,

} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from 'lodash';
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";

import baseStyles from '../../../../assets/styles';
import useStyles from "./styles";



const GeneralSchedule = ({ isEmployee, employeeData, scheduleInfo }) => {
    const nameProperty = isEmployee ? 'clientName' : 'providerName'
    const baseClasses = baseStyles();
    const classes = useStyles();
    const scheduleRowDaily = useStoreState((state) => isEmployee ? state.employee.scheduleRowDaily : state.clients.scheduleRowDaily);
    const scheduleRowProfessional = useStoreState((state) => isEmployee ? state.employee.scheduleRowProfessional : state.clients.scheduleRowProfessional);
    const scheduleRowAppointment = useStoreState((state) => isEmployee ? state.employee.scheduleRowAppointment : state.clients.scheduleRowAppointment);

    const yungermanRows = scheduleInfo?.yungerman;
    const professionalRows = scheduleInfo?.professional;
    const appointmentRows = scheduleInfo?.appointment;
    const AccordionDetailSchedule = ({ name, time, scheduleDisplayName, index }) => {
        return (
            <>
                <div className={classes.accordion_details} key={index}>
                    <AccordionDetails style={{ paddingBottom: '0px', paddingTop: '3px' }} className={classes.accordion_detail_root}>
                        <Grid container>
                            <Grid item xs={4}>
                                <div className={baseClasses.schedule_text}>{name}</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={baseClasses.schedule_text}>{time}</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={baseClasses.schedule_text}>{scheduleDisplayName}</div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <div className={baseClasses.row}>
                        <Divider className={baseClasses.fullWidth} orientation="horizontal" />
                    </div>
                </div>
            </>
        );
    }

    const AccordionDetailSchedule1 = ({ item, index, nameType }) => {
        return (
            <>
                <AccordionDetailSchedule
                    name={item[nameType]}
                    time={(item.startTime ? global._TimeField(item.startTime) : "") + "  -  " + (item.endTime ? global._TimeField(item.endTime) : "")}
                    scheduleDisplayName={item.schedule}
                    index={index}
                />
            </>
        );
    };

    const AccordionDetailSchedule2 = ({ item, index, nameType }) => {
        return (
            <>
                <AccordionDetailSchedule
                    name={item[nameType]}
                    time={(item.startTime ? global._TimeField(item.startTime) : "") + "  -  " + (item.endTime ? global._TimeField(item.endTime) : "")}
                    scheduleDisplayName={item.scheduleDisplayName}
                    //scheduleDisplayName={(item.date || item.startDate) && (global.getWeekday(new Date((item.date || item.startDate)).getDay()) + " " + global._DateField(new Date(item.date || item.startDate)))}
                    index={index}
                />
            </>
        );
    };

    const AccordionDetailSchedule3 = ({ item, index, nameType }) => {
        return (
            <>
                <AccordionDetailSchedule
                    name={item[nameType]}
                    time={(item.startTime ? global._TimeField(item.startTime) : "") + "  -  " + (item.endTime ? global._TimeField(item.endTime) : "")}
                    scheduleDisplayName={global.dateTime.getUSAFormattedDateString(item.startDate)}
                    index={index}
                />
            </>
        );
    };

    return (<div className={clsx(baseClasses.row, baseClasses.mrtop12)}>
        <Card className={baseClasses.contentOverview2}>
            <div
                className={baseClasses.schedule_title}
                component="span"
                variant="h6"
                style={{ fontSize: 13 }}
            >
                Schedule
            </div>
            <CardContent
                classes={{ root: baseClasses.contentCard }}
                className="p-0"
            >
                <div style={{ height: 250, overflow: "auto" }}>
                    {/* {!_.isEmpty(scheduleRowDaily) ? (
                        scheduleRowDaily.map((item, index) => ( */}
                    <div className={baseClasses.fullWidth}>
                        {(!isEmployee || employeeData?.isYungerman) &&
                            <Accordion
                                className={baseClasses.accordion}
                                //   expanded={expanded === "panel" + index}
                                defaultExpanded={true}
                            //   onChange={handleChangeExpanded("panel" + index)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    className={baseClasses.accordionSummary}
                                >
                                    <div component={"span"} className={classes.heading}>
                                        {"Yungerman"}
                                    </div>
                                </AccordionSummary>
                                <div className={baseClasses.row}>
                                    <Divider
                                        className={baseClasses.fullWidth}
                                        orientation="horizontal"
                                    />
                                </div>

                                {!_.isEmpty(yungermanRows) &&
                                    yungermanRows.map((item, index) => (
                                        <AccordionDetailSchedule1
                                            item={item}
                                            index={index}
                                            key={item.id}
                                            nameType={nameProperty}
                                        ></AccordionDetailSchedule1>
                                    ))}
                            </Accordion>
                        }
                        {(!isEmployee || employeeData?.isProfessional) &&
                            <>
                                <Accordion
                                    className={baseClasses.accordion}
                                    //   expanded={expanded === "panel" + index}
                                    defaultExpanded={true}
                                //   onChange={handleChangeExpanded("panel" + index)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className={baseClasses.accordionSummary}
                                    >
                                        <div component={"span"} className={classes.heading}>
                                            {"Professionals"}
                                        </div>
                                    </AccordionSummary>
                                    <div className={baseClasses.row}>
                                        <Divider
                                            className={baseClasses.fullWidth}
                                            orientation="horizontal"
                                        />
                                    </div>

                                    {!_.isEmpty(professionalRows) &&
                                        professionalRows.map((item, index) => (
                                            <AccordionDetailSchedule2
                                                item={item}
                                                index={index}
                                                key={item.id}
                                                nameType={nameProperty}
                                            ></AccordionDetailSchedule2>
                                        ))}
                                </Accordion>

                                <Accordion
                                    className={baseClasses.accordion}
                                    //   expanded={expanded === "panel" + index}
                                    defaultExpanded={true}
                                //   onChange={handleChangeExpanded("panel" + index)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className={baseClasses.accordionSummary}
                                    >
                                        <div component={"span"} className={classes.heading}>
                                            {"Appointments"}
                                        </div>
                                    </AccordionSummary>
                                    <div className={baseClasses.row}>
                                        <Divider
                                            className={baseClasses.fullWidth}
                                            orientation="horizontal"
                                        />
                                    </div>

                                    {!_.isEmpty(appointmentRows) &&
                                        appointmentRows.map((item, index) => (
                                            <AccordionDetailSchedule3
                                                item={item}
                                                index={index}
                                                key={item.id}
                                                nameType={nameProperty}
                                            ></AccordionDetailSchedule3>
                                        ))}
                                </Accordion>
                            </>
                        }

                    </div>
                    {/* )) */}
                    {/*                   
                        // <div
                        //   className={classes.no_data}
                        //   style={{ marginTop: 0, marginBottom: 0 }}
                        // >
                        //   <Typography
                        //     className={classes.no__data__typgoraphy}
                        //     style={{ lineHeight: "400px" }}
                        //   >
                        //     NO DATA
                        //   </Typography>
                        // </div> */}
                </div>
            </CardContent>
        </Card>
    </div>)
}

export default GeneralSchedule;