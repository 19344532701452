import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Popover, Typography, Menu, MenuItem} from '@mui/material';
import {WeekSwitcherWeekRange} from '../../../../assets/types'
import { WeekSwitcherProps } from './type';
import {
    CalendarContainer,
    LeftArrow,
    RightArrow,
    CalendarContent,
    Label,
    Month,
    Year
} from '../styles';


const WeekSwitcher: React.FC<WeekSwitcherProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);
    const {selectedWeek, weeks, disableSwitch, handleChangeWeek, handleWeekSwitch, width, style={}, label=""} = props;
    
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (week: WeekSwitcherWeekRange) => {
        handleChangeWeek(week);
        handleClose()
    }

  return (
    <CalendarContainer width={width || "200px"} height={props.height} style={{...style}}>
        <LeftArrow onClick={() => {
            if(disableSwitch['left']) return
            handleWeekSwitch?.('left')
        }}>
            <ChevronLeftIcon color={disableSwitch['left'] ? 'disabled' : 'primary'} />
        </LeftArrow>
        
        <CalendarContent onClick={(e) => handleClick(e)}>
            {selectedWeek?.label ??
                <Label>{label}</Label>
            }
        </CalendarContent>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            {
                weeks?.length > 0 && weeks.map((week: WeekSwitcherWeekRange, index: number) => {
                  return(
                      <MenuItem sx={{ p: 2 }} disabled={week.disabled} selected = {week.label === selectedWeek?.label} onClick={() => handleSelect(week)}>{week.label}</MenuItem>
                  )
                })
            }
        </Menu>
        <RightArrow onClick={() => {
            if(disableSwitch['right']) return
            handleWeekSwitch?.('right')
        }}>
            <ChevronRightIcon color={disableSwitch['right'] ? 'disabled' : 'primary'} />
        </RightArrow>
    </CalendarContainer>
  )
}

export default WeekSwitcher