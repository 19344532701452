import * as React from 'react';
import ConfirmDialog from "./../../../common/ConfirmDialog/ConfirmDialog";

export default function AlertDialog(props) {
    return (
        <ConfirmDialog
            action="delete"
            item="record"
            {...props}
        />
    );
}