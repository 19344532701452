import _ from "lodash";
import * as constants from "../constants";

export let getContactRelByRelationship = (table, id, relationship) => {
    return new Promise((resolve, rejects) => {
            global.fetchWrapper.get(constants.CONTACTREL_LIST + table + "/" + id)
            .then(
                (data) => {
                    let filtered_array = _.filter(data, function (o) {
                        return o.relationship === relationship;
                    });
                    resolve(filtered_array);
                },
                (rejects) => {
                    resolve([]);
                }
            );
    });
};

export let getDocumentTreeStructure = (contactId) => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.CMS_DOCUMENT_TREESTRUCTURE_GET + contactId)
            .then(
                (data) => {
                    yeshivas_data = data.result;
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                    resolve(null);
                }
            );
    });
};

export let getContactRelDetail = (table, id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.CONTACTREL_LIST + table + "/" + id)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

export let markTaskCompleted = (row, completedOn) => {
    if (new Date(row.startDate) > new Date(completedOn))
        return;
    let completedOnDT = global.dateTime.getDateTime(completedOn);
    return new Promise((resolve, err) => {
        global.fetchWrapper.post(constants.CMS_TODO_MARK_TASK_COMPLETED + row.id + "?completedOn=" + completedOnDT)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.AjaxFailToast();
                    err(rejects);
                }
            );
    });
};

export let removeContactRel = (id) => {
    return global.fetchWrapper.post(constants.CONTACTREL_REMOVE + id).then(
        (response) => {
            // global.log("response", response);
        },
        (error) => {
            global.AjaxFailToast();
            global.log("error", error);
        }
    );
};

// export let saveDocumentTreeNode = (id, name) => {
//   return axios.post(CMS_DOCUMENT_SAVE + id, data).then(
//     (response) => {
//       global.log("response", response);
//     },
//     (error) => {
//       global.log("error", error);
//     }
//   );
// };

export const saveFolder = (contactId, node, formData) => {
    const parentFolderId = node.parentFolderId ? node.parentFolderId : -1;
    const url = `${constants.CMS_DOCUMENT_FOLDER_SAVE}${contactId}/${node.primaryId}/${parentFolderId}/${node.name}`;

    return global.fetchWrapper.post(url, formData).then(
        (response) => {
            global.AjaxSaved1("Folder saved")
            return response;
        },
        (error) => {
            global.AjaxFailToast("An error occured while saving your folder")
            return -1;
        }
    );
}
export const editFileInfo = (contactId, node, formData) => {
    const parentFolderId = node.parentFolderId ? node.parentFolderId : -1;
    const url = `${constants.CMS_DOCUMENT_FILE_EDIT}${contactId}/${node.primaryId}/${parentFolderId}/${node.name}`;

    return global.fetchWrapper.post(url, formData).then(
        (response) => {
            global.AjaxSaved1("File information saved")
            return response;
        },
        (error) => {
            global.AjaxFailToast("An error occured while updating your file information")
            return -1;
        }
    );
}

export let uploadFile = (payload) => {
    return global.fetchWrapper.post(constants.CMS_FILE_UPLOAD, payload).then(
        (response) => {
            global.AjaxSaved1("File uploaded")
            // global.log("response", response);
        },
        (error) => {
            global.AjaxFailToast("An error occured while uploading your file")
            // global.log("error", error);
        }
    );
};
export const fetchFileInfos = (table, primaryId, setFiles) => {
    if (!primaryId) return;
    return global.fetchWrapper.get(constants.GET_FILE_TEMPLATES + table + "/" + primaryId)
        .then(data => {
            if (!data || !data.result) {
                return;
            }
            let files = {};
            for (const key in data.result) {
                var file = data.result[key];
                if (!file.templateFileId)
                    continue;
                files[key] = file;
            }
            setFiles(files);
        })
}
export let uploadTemplateFile = (primaryId, templateId, fileName, fileExtension, fileBytes, folderTemplate, table, file) => {
    var url = constants.UPLOAD_FILE;

    const payload = {
        id: primaryId, fileBytes: fileBytes, fileName: fileName, templateId: templateId,
        fileExtension: fileExtension, folderTemplate: folderTemplate, tableName: table,
        uploadedOn: file.uploadedOn, state: file.state, fileId: file.id
    }

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(url, payload).then(
            (response) => {
                resolve(response)
            },
            (error) => {
                resolve(error);
                global.AjaxFailToast();
            }
        )
    })
}

export const saveFile = (file, table, files, setFiles) => {
    return uploadTemplateFile(file.primaryId, file.templateFileId, file.name, "txt", file.file64Arr, file.folderTemplate, table, file).then((response) => {
        setFiles({ ...files, [response.file.templateName]: response.file })
    });
}

export let deleteDocumentTreeNode = (node) => {
    var url = "";
    if (node.type === "folder")
        url = constants.CMS_DOCUMENT_FOLDER_DELETE + node.primaryId;
    else
        url = constants.CMS_DOCUMENT_FILE_DELETE + node.primaryId;

    return global.fetchWrapper.post(url).then(
        (response) => {
            //return response;
            global.log("response", response);
        },
        (error) => {
            //return -1;
            global.log("error", error);
        }
    );
};

export let getAuditLogDetail = (id) => {
    let detailOfyeshivas;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.SYSTEM_AUDITLOG_DETAIL + id)
            .then(
                (data) => {
                    global.log(data);
                    resolve(true);
                    //   if (data.responseCode === 200) {
                    //     resolve(data.result);
                    //   } else {
                    //     resolve(true);
                    //   }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    resolve(true);
                }
            );
    });
};

export const dashboardColumns = [
    { field: "title", headerName: "Title", flex: 1, sortable: false, enableTooltip: true, enableDialog: true, },
    { field: "description", headerName: "Description", flex: 1.7, sortable: false, enableTooltip: true, enableDialog: true, },
    { field: "assignee", headerName: "Assignee", flex: 1.5 },
    { field: "startDate", headerName: "Started", flex: 0.5 },
    { field: "dueDate", headerName: "Due", flex: 0.5 },
    // { field: "approverd_hours", headerName: "Approved Hours", flex: 1 },
    { field: "completedDate", headerName: "Completed", flex: 0.8 },
    { field: "creator", headerName: "Created By", flex: 0.8 },
    { field: "client", headerName: "Bucher", flex: 0.8, navUrl: "client/{clientId}/details" },
];
