import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { theme } from '../../assets/theme/theme';
import useStyle from './styles'

const CalendarContainer = styled('div')({
  display: 'block'
});

const DateItem:any = styled('div')((props:any) =>({
  cursor:props.alreadySelected ? 'unset' : 'pointer',
  textAlign: 'center',
  padding: '5px 10px',
  margin: '10px 5px',
  borderRadius: 6,
  background:props.alreadySelected ? 'rgba(0, 0, 0, 0.04)': '#fff',
  color:'#000',
  minWidth: '55px',
  '&:hover': {
    background:'rgba(0, 0, 0, 0.04)'
  }
}));

const Title = styled('div')({
  textAlign: 'center',
  fontWeight: 700,
  background: theme.palette.primary.main,
  color: '#fff',
  padding: 10

});

type DatePopoverType = {
  id: string | undefined,
  title: string,
  open: boolean,
  selected: number | string | any,
  data: any;
  anchorEl: any;
  handleClose: any,
  handleSelect: Function,
  verticalPos: number | "center" | "top" | "bottom",
  horizontalPos: number | "center" | "left" | "right"
}
const DatePopover: React.FC<DatePopoverType> =({id,open, selected, title, data, anchorEl, handleClose, handleSelect, verticalPos, horizontalPos}) => {
  const classes = useStyle()
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference={anchorEl}
        anchorOrigin={{
          vertical: verticalPos,
          horizontal:horizontalPos ,
        }}
        className={classes.popoverRoot}
      >
        <CalendarContainer>
          {
            data?.length > 0 && data.map((item: any, index:number) => {
              return (
                <DateItem alreadySelected={selected.label === item.label} onClick={() => {
                  handleSelect(item)
                }}>
                  {
                    item.label 
                  }
                </DateItem>
              )
            })
          }
        </CalendarContainer>
      </Popover>
    </div>
  );
}

export default DatePopover