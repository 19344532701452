import React from 'react';
import { TextField, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import {Flex, DivContainer, BoldTitle, Text, BoxContainer} from '../../../../../assets/styles/styledComponents'

type TimeEditorProps = {
    label: string,
    mode?: 'read' | 'edit',
    variant?: 'outlined' | 'filled' | 'standard',
    name: string,
    value: string | number | any,
    error: boolean,
    disabled?:boolean,
    helperText?: string,
    fullWidth?: boolean,
    style?: any,
    margin?: string,
    editable? : boolean,
    disablePicker?: boolean,
    handleMode?: Function,
    onChange: Function
}

const TimeEditor: React.FC<TimeEditorProps> = ({label,style,variant="outlined", disablePicker=false, handleMode, editable=false, margin="10px", disabled=false, name, value, error, helperText, fullWidth, onChange, mode="edit"}) => {
  const globalConst: any = global
  return (
    <DivContainer margin={margin}>
        {mode === 'edit' && (
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopTimePicker
                        label={label}
                        value={!!value ? value : null}
                        onChange={(newValue) => {
                            onChange(globalConst.dateTime.getDateTime(newValue), name);
                        }}
                        disableOpenPicker={disablePicker}
                        disabled={disabled}
                        renderInput={({...props}) => <TextField {...props} onBlur={() => {
                            handleMode?.("read")
                        }}  sx={{...style}} error={error} helperText={helperText} fullWidth={fullWidth} variant={variant} />}
                    /> 
                </LocalizationProvider>
        )}
        { mode === 'read' && !editable && 
            <Flex width="100%">
                <DivContainer width="40%">
                    <BoldTitle >{label}:</BoldTitle>
                </DivContainer>
                <DivContainer width="60%" >
                    <Text>{!!value ? globalConst.dateTime.formatTimeAMPM(value) : '-'}</Text>
                </DivContainer>
            </Flex>
        } 

        { mode === 'read' && editable && 
            <Tooltip title="Click To Edit">
                <BoxContainer padding="10px" display="flex" width="100%" style={{borderRadius: '4px'}} onClick={() => handleMode?.('edit')}>
                    <DivContainer width="40%" >
                        <Text >{label}:</Text>
                    </DivContainer>
                    <DivContainer width="60%" >
                        <Text>{!!value ? globalConst.dateTime.formatTimeAMPM(value) : '-'}</Text>
                    </DivContainer>
                </BoxContainer>
            </Tooltip>
        }
    </DivContainer>
  )
}

export default TimeEditor