import { BehaviorSubject } from 'rxjs';
import { fetchWrapper } from '../_helpers/fetch-wrapper';
import { history } from '../_helpers/history'
import { BASE_URL } from "../constants";
import { useHistory } from 'react-router-dom'
import model from "../redux";
import { StoreProvider, createStore } from "easy-peasy";

const userSubject = new BehaviorSubject(null);
const baseUrl = `${BASE_URL}accounts`;
const store = createStore(model);

export const accountService = {
    login,
    logout,
    refreshToken,
    register,
    getUserByContactId,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    updatePassword,
    getAll,
    getById,
    getPermissions,
    create,
    update,
    delete: _delete,
    getUserDisplayName: getUserDisplayName,
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value }
};

global.account = accountService;

function login(email, password) {
    return fetchWrapper.post(`${baseUrl}/authenticate`, { email, password })
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            store.getActions().contactsLoad();
            global.logoutTimerStarted = global.runLogoutTimer();
            return user;
        });
}

function logout(nextAction) {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    fetchWrapper.post(`${baseUrl}/revoke-token`, {}).then((data) => {
        userSubject.next(null);
        stopRefreshTokenTimer();
        global.stopLogoutTimer();
        switch (nextAction) {
            case "onfocus-redirect":
                global.redirectToLoginOnWindowFocus = () => {
                    window.location.pathname = '/login';
                    global.redirectToLoginOnWindowFocus = undefined;
                };
                break;
            case "onfocus-popup":
                break;
            case "logbackin-popup":
                alert("Session logged out, you will get redirected to login.");
                window.location.pathname = '/login';
                break;
            case "redirect":
            default:
                window.location.pathname = '/login'
                break;
        }
    })
}

function refreshToken() {
    return fetchWrapper.post(`${baseUrl}/refresh-token`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });
}

function getPermissions() {
    return fetchWrapper.get(`${baseUrl}/get-permissions`).then((perm) => {
        global.permissions = perm;
    });
}

function register(params) {
    return fetchWrapper.post(`${baseUrl}/register`, params);
}

function verifyEmail(token) {
    return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email) {
    return fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
}

function validateResetToken(token) {
    return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword }) {
    return fetchWrapper.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
}

function getUserByContactId(contactId) {
    return fetchWrapper.get(`${baseUrl}/by-contact-id/${contactId}`);
}

function getAll() {
    return fetchWrapper.get(baseUrl);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function updatePassword(userId, password, confirmPassword) {
    return fetchWrapper.put(`${baseUrl}/update-password`, { userId, password, confirmPassword });
}
function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/update/${id}`, params)
        .then(user => {
            // update stored user if the logged in user updated their own record
            if (user.id === userSubject.value.id) {
                // publish updated user to subscribers
                user = { ...userSubject.value, ...user };
                userSubject.next(user);
            }
            return user;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/delete/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            if (id === userSubject.value.id) {
                logout();
            }
            return x;
        });
}

function getUserDisplayName() {
    if (!accountService.userValue)
        return "Sign In";
    else if (accountService.userValue.firstName || accountService.userValue.lastName)
        return accountService.userValue.firstName + " " + accountService.userValue.lastName;
    else
        return accountService.userValue.email;
}

// helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}
