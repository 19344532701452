import { action, thunk} from "easy-peasy";
import professionals from "../api/professional";

const newDetail = {
    "contactId": 0,
    "contact": {
        "firstName": "",
        "lastName": "",
        "firstNameHebrew": "",
        "lastNameHebrew": "",
        "firstNameLegal": "",
        "lastNameLegal": "",
        "middleNameLegal": "",
        "address1": "",
        "address2": "",
        "city": "",
        "state": "",
        "zip": "",
        "phone1": "",
        "phone2": "",
        "email1": "",
        "email2": "",
        "dob": null,
        "age": 0,
        "ssn": null,
        "id": 0,
        "neighborhood": "",
    },
    "positionId": null,
    "position": null,
    "rate": 0.00,
    "professionalType": null,
    "yearOfBirth": null,
    "yearsOfExperienceWithBucherim": null,
    "rateNote": null,
    "prevYeshivas": null,
    "experience": null,
    "note": null,
    "lHisalosApprovalStatus": null,
    "parsonageLimit": null,
    "financialNotes": null,
    "planningToEnrollComfortHealth": null,
    "hasComfortHealthApplication": null,
    "hasBA": null,
    "sentToComfortHealthOn": null,
    "fingerprintedOn": null,
    "comfortHealthApprovedOn": null,
    "amountCouldWork": null,
    "specialRequirements": null,
    "amountBilling": null,
    "isInTheMountainsSummer": null,
    "summerColony_ContactId": null,
    "summerColonyContact": {
        "firstName": "",
        "lastName": "",
        "firstNameHebrew": "",
        "lastNameHebrew": "",
        "firstNameLegal": "",
        "lastNameLegal": "",
        "middleNameLegal": "",
        "address1": "",
        "address2": "",
        "city": "",
        "state": "",
        "zip": "",
        "phone1": "",
        "phone2": "",
        "email1": "",
        "email2": "",
        "dob": null,
        "age": 0,
        "ssn": null,
        "id": 0,
    },
    "specialties": null,
    "paymentOptions": "",
    "credentials": null,
    "hasLicense": null,
    "hasCar": null,
    "id": 0,
};

const defaultStore = {
    detail: newDetail,
    initDetail: action((state, payload) => {
        state.detail = newDetail;
    }),
    setDetail: action((state, payload) => {
        state.detail = payload;
    }),
    saveDetail: thunk((actions, payload) => {
        if (payload == null) {
            global.AjaxFailToast();
            return;
        }
    }),
    isEditing: false,
    setIsEditing: action((state, payload) => {
        state.isEditing = payload;
    }),
    professionals: [],
    updateProfessionals: action((state, professionals) => {
        state.professionals = professionals;
    }),
    fetchProfessionals: thunk(async actions => {
        let items = await professionals();
        actions.updateProfessionals(items);
    }),
    sectionChanged1: false,
    setSectionChanged1: action((state, payload) => {
        state.sectionChanged1 = payload;
    }),
    sectionState1: "read",
    setSectionState1: action((state, payload) => {
        state.sectionState1 = payload;
    }),
    sectionState2: "read",
    setSectionState2: action((state, payload) => {
        state.sectionState2 = payload;
    }),
    sectionState3: "read",
    setSectionState3: action((state, payload) => {
        state.sectionState3 = payload;
    }),
    sectionState4: "read",
    setSectionState4: action((state, payload) => {
        state.sectionState4 = payload;
    }),
    sectionState5: "read",
    setSectionState5: action((state, payload) => {
        state.sectionState5 = payload;
    }),
    firstNameError: "",
    setFirstNameError: action((state, payload) => {
        state.firstNameError = payload;
    }),
    secondNameError: "",
    setSecondNameError: action((state, payload) => {
        state.secondNameError = payload;
    }),
};



export default defaultStore;
