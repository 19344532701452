import React from 'react';
import TInputNumber from "./TInputNumber";

import { TextField } from "@mui/material";

const TTextField = (props) => {
    const { onChange, ...other } = props;
    const [values, setValues] = React.useState({
        inputValue: props.value,
    });

    const handleChange = (event) => {
        var value = event.target.value;
        setValues((prevState) => {
            return {
                ...prevState,
                inputValue: value
            }
        });

        onChange(event);
    };

    return (
        <TextField
            {...other}
            id={`id__textfield__${props.name}`}
            name={`textfield__${props.name}`}
            label={props.label}
            placeholder={props.placeholder}
            helperText={props.helperText}
            value={values.inputValue}
            multiline={props.multiline}
            fullWidth={props.fullWidth}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
        />
    );
}

export default TTextField;