export const daysInMonth = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
];
export const hebrewMonthNames = [
    {
        value: "Tishrei",
        label: "תשרי",
        month: 1,
        chodesh: 7,
    },
    {
        value: "Cheshvan",
        label: "חשון",
        month: 2,
        chodesh: 8,
    },
    {
        value: "Kislev",
        label: "כסליו",
        month: 3,
        chodesh: 9,
    },
    {
        value: "Tevet",
        label: "טבת",
        month: 4,
        chodesh: 10,
    },
    {
        value: "Shevat",
        label: "שבט",
        month: 5,
        chodesh: 11,
    },
    {
        value: "Adar",
        label: "אדר",
        month: 6,
        chodesh: 12,
    },
    {
        value: "Nissan",
        label: "ניסן",
        month: 7,
        chodesh: 1,
    },
    {
        value: "Iyar",
        label: "אייר",
        month: 8,
        chodesh: 2,
    },
    {
        value: "Sivan",
        label: "סיוון",
        month: 9,
        chodesh: 3,
    },
    {
        value: "Tamuz",
        label: "תמוז",
        month: 10,
        chodesh: 4,
    },
    {
        value: "Av",
        label: "אב",
        month: 11,
        chodesh: 5,
    },
    {
        value: "Elul",
        label: "אלול",
        month: 12,
        chodesh: 6,
    },
];
export const HebrewMonthNamesLeapYear = [
    {
        value: "Tishrei",
        label: "תשרי",
        month: 1,
        chodesh: 7,
    },
    {
        value: "Cheshvan",
        label: "חשון",
        month: 2,
        chodesh: 8,
    },
    {
        value: "Kislev",
        label: "כסליו",
        month: 3,
        chodesh: 9,
    },
    {
        value: "Tevet",
        label: "טבת",
        month: 4,
        chodesh: 10,
    },
    {
        value: "Shevat",
        label: "שבט",
        month: 5,
        chodesh: 11,
    },
    {
        value: "Adar I",
        label: "אדר א׳",
        month: 6,
        chodesh: 12,
    },
    {
        value: "Adar II",
        label: "אדר ב׳",
        month: 6,
        chodesh: 13,
    },
    {
        value: "Nissan",
        label: "ניסן",
        month: 7,
        chodesh: 1,
    },
    {
        value: "Iyar",
        label: "אייר",
        month: 8,
        chodesh: 2,
    },
    {
        value: "Sivan",
        label: "סיוון",
        month: 9,
        chodesh: 3,
    },
    {
        value: "Tamuz",
        label: "תמוז",
        month: 10,
        chodesh: 4,
    },
    {
        value: "Av",
        label: "אב",
        month: 11,
        chodesh: 5,
    },
    {
        value: "Elul",
        label: "אלול",
        month: 12,
        chodesh: 6,
    },
];
