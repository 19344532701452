import * as React from 'react';
import {
    Button, Dialog,
    DialogActions,
    DialogTitle,
    DialogContent, DialogContentText,
} from '@mui/material';
import { DialogProps } from '../../../assets/types';
import { useTheme } from "@mui/styles";
import { strings } from "../../../_helpers/strings";

const ConfirmDialog: React.FC<DialogProps> = (props) => {
    const { open, onClose, onConfirm, canConfirm, warnings, description, item, id, action } = props;
    return (
        <div>
            <Dialog
                open={open}
                onClose={(event, reason) => { onClose(event, reason) }}
            >
                <DialogTitle>
                    {
                        `${strings.firstLetterUpperCase(item)} ${id} `
                        //`${warnings.length} warnings found`
                    }
                </DialogTitle>
                <DialogContent>
                    {warnings && warnings.map((i) => (
                        <>
                            <h6>{i.title}</h6>
                            <p>{i.description}</p>
                        </>
                    ))}
                    <DialogContentText>
                        <h6 style={{ color: warnings ? 'red' : 'black' }}>
                            {description ||
                                canConfirm ? (action ? "Are you sure you want to " + action + " this " + item + "?" : 'Are you sure you want to continue?') :
                                'Please contact your administrator to perform this action.'
                            }
                        </h6>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                >
                    <Button onClick={() => onClose()} color="primary" autoFocus>
                        Cancel
                    </Button>
                    {canConfirm &&
                        <Button
                            autoFocus
                            onClick={() => {
                                onConfirm();
                                onClose()
                            }}
                            color={warnings ? 'error' : 'primary'}
                        >

                            Confirm
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default ConfirmDialog;