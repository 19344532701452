import React, { useEffect } from 'react';
import useStyles from './styles';
import DataTable from '../../components/DataTable/DataTable';
import { DUMMY_API } from '../../constants';
import program, { columnsProgram } from '../../api/program';
import { Paper } from '@mui/material';
import AddProgram from '../../components/explorer/Tabs/Dialog/Program/AddProgram';
import Button from "@mui/material/Button";
import baseStyles from '../../assets/styles'
let programs;

export default function Programs() {
    const classes = useStyles();
    const baseClasses = baseStyles();
    // const [rows, setRow] = React.useState([]);
    // const [columns, setColumns] = React.useState(columnsProgram);
    // const [loader, setLoader] = React.useState(true);
    // const selectedRow = null;
    // const [isOpenCreateModalProgram, setIsOpenCreateModalProgram] = React.useState(false);
    // const [editProgramData, setEditProgramData] = React.useState(null);
    // const [errorMessage, setErrorMessage] = React.useState(null);
    // const [successMessage, setSuccessMessage] = React.useState(null);
   
    
//   const handleAddProgramSuccess = (isSuccess) => {
//     handleGetApi();
//     if (isSuccess) {
//       setSuccessMessage("Success !!!");
//       handleGetApi();
//     } else {
//       setErrorMessage("An error has occured !");
//     }
//     setTimeout(() => {
//       setSuccessMessage("");
//       setErrorMessage("");
//     }, 3000);
//   };

//     const handleAddButtonProgram = () => {
//         setIsOpenCreateModalProgram(true);
//     }
//     const handleCloseModalProgram = () => {
//         setIsOpenCreateModalProgram(false);
//         handleGetApi();
//     }
//     const handleGetApi = () => {
//         if (rows.length == 0 && !programs) {
//             program().then((value) => {
//                 if (value.length == 0) {
//                     programs = true;
//                 }
//                 setLoader(false);
//                 setRow(value);
//             });
//             return;
//         } else {
//             setLoader(false);
//         }
//       };
//     useEffect(() => {
//         handleGetApi();
//     }, []);
//     const _setRowAndColumn = () => {
//         return { rows: rows, columns: columns, screen: 'programs'};
//     }
    return (
        <main className={classes.content}>
            <div className={baseClasses.toolbar} />
            <div className={baseClasses.programTab}>
                Coming soon...
                {/* <div className={classes.button_program}>
                    <Button
                    className={classes.capitalized}
                    color="primary"
                    variant="contained"
                    // onClick={handleAddButtonProgram}
                    >
                    Add
                    </Button>
                </div> */}
            </div>
            {/* <Paper>
                {loader ? <div className = { classes.loading}>Loading....</div> : <DataTable data={_setRowAndColumn()} />}
            </Paper>
            
         <AddProgram 
         isOpenCreateModal={isOpenCreateModalProgram}
         onCloseModal={handleCloseModalProgram}
         addProgramSuccess={handleAddProgramSuccess}
         editProgramData={editProgramData}
         /> */}
        </main>
    );
}