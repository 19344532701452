import _ from "lodash";
import React from 'react';
import {
    CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE,
    CLIENT_SCHEDULE_GET_BY_PROVIDER,
    CLIENT_SCHEDULE_GET_APPOINTMENT_SCHEDULE,
    CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_SAVE,
} from "../constants";
import SCHEDULE_PERIODICITY_CONSTANTS from '../_helpers/constants/schedule_periodicity_constants.js';
import { weekNames } from "../components/data/clients";
import { dateTime, defaultDates } from "../_helpers/datetime";
import { getScheduleStringForProfessional, getDaysArrayFromDaysString } from "../_helpers/schedule";

const getWeekdaysString = (weekdays, includeAndStringBetweenLastDays = false) => {
    return global.dateTime.getWeekdaysString(weekdays, includeAndStringBetweenLastDays);
}
function replaceNull(someObj, replaceValue = "***") {
    const replacer = (key, value) =>
        String(value) === "null" || String(value) === "undefined"
            ? replaceValue
            : value;
    //^ because you seem to want to replace (strings) "null" or "undefined" too

    return JSON.parse(JSON.stringify(someObj, replacer));
}

export let getProviderSchedules = (exploringType, exploringId, client, provider) => {
    let endpointUrl;
    switch (exploringType) {
        case "clients":
            endpointUrl = CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE;
            break;
        case "employees":
            endpointUrl = CLIENT_SCHEDULE_GET_BY_PROVIDER;
        default:
    }
    if (!endpointUrl)
        return;

    let data = [];
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(endpointUrl + exploringId)
            .then((response) => {
                data = response.map((d) => {
                    return {
                        clientScheduleId: d.clientScheduleId,
                        startTime: global.dateTime.filterDefaultDate(d.startTime),
                        endTime: global.dateTime.filterDefaultDate(d.endTime),
                        providerName: !d.providerName && provider ? provider.name : d.providerName,//
                        clientName: !d.clientName && client ? client.name : d.clientName,//
                        providerId: !d.providerId && provider ? provider.id : d.providerId,//
                        clientId: !d.clientId && client ? client.id : d.clientId,//
                        schedule: d.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ? getWeekdaysString(d.days) : "", //
                        scheduleDisplayName: getScheduleStringForProfessional(d.days, d.everyXOfPeriods, d.periodicity),
                        startDate: global.dateTime.filterDefaultDate(d.startDate),
                        endDate: global.dateTime.filterDefaultDate(d.endDate),
                        rate: d.rate,
                        ratePeriod: d.ratePeriod,
                        type: d.type,
                        note: d.note,
                        reasonScheduleEnded: d.reasonScheduleEnded,
                        matura: "",
                        status: d.status,
                        dateApproved: d.dateApproved,
                        specialties: d.specialties,
                        //providerScheduleStatus: d.providerScheduleStatus, not really needed
                        //nextSession: d.nextSession, //might only be needed for professional schedules // not yet available
                        professionalType: d.professionalType,//
                        clientEmployeeSchedulePotentials: !d.clientEmployeeSchedulePotentials ? [] : d.clientEmployeeSchedulePotentials.map(cesp => ({
                            providerName: cesp.providerName,
                            status: cesp.status,
                        })),
                    }
                })
                resolve(data);
            },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};
export let getAppointmentSchedules = (exploringType, exploringId) => {
    let endpointUrl;
    switch (exploringType) {
        case "clients":
            endpointUrl = CLIENT_SCHEDULE_GET_APPOINTMENT_SCHEDULE;
            break;
        case "employees":
        //endpointUrl = constant.CLIENT_SCHEDULE_GET_APPOINTMENTS_BY_PROVIDER; -- not implemented
        default:
    }
    if (!endpointUrl)
        return;

    let data = [];
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(endpointUrl + exploringId)
            .then((response) => {
                data = response.appointmentSchedules.map((d) => {
                    return {
                        clientScheduleId: d.id,
                        date: global.dateTime.filterDefaultDate(d.date),
                        startTime: global.dateTime.filterDefaultDate(d.startTime),
                        endTime: global.dateTime.filterDefaultDate(d.endTime),
                        providerName: d.providerName,
                        clientName: d.clientName,
                        provider_contactId: d.provider_contactId,
                        providerId: d.providerId,
                        note: d.note,
                        clientName: d.clientName,
                        type: "appointment"
                    }
                })
                resolve(data);
            },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};
export const saveAppointmentRow = (row, exploringType, exploringId) => {
    const defaultDate = defaultDates.cSharp;
    var request = {
        ClientSchedule: {
            clientScheduleId: row.clientScheduleId,
            type: "appointment",
            clientId: exploringId && exploringType === "clients" ? exploringId : row.clientId,
            employeeId: exploringId && exploringType === "employees" ? exploringId : row.providerId ? row.providerId : row.selectedProfessional.providerId,
            note: row.note,
            rate: row.rate,
            status: row.status,
            reasonForRejection: row.reasonForRejection,
        },
        Schedule: {
            startTime: row.startTime ? row.startTime : defaultDate,
            endTime: row.endTime ? row.endTime : defaultDate,
            date: row.date ? row.date : defaultDate,
        },
    };
    let clientScheduleId = row.clientScheduleId != undefined ? row.clientScheduleId : -1;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_SAVE + clientScheduleId, request)
            .then((response) => {
                resolve(response);
            }, (rejects) => {
                global.log("rejects");
                global.AjaxFailToast();
                resolve([]);
            });
    });
}

export const saveSchedule = async (schedules) => {
    try {
        const response = await fetch('/api/application/saveschedules', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(schedules),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const newScheduleIds = await response.json();
        return newScheduleIds;
    } catch (error) {
        console.error('Error saving schedules:', error);
        return null;
    }
};

export const saveContactSchedule = async (scheduleIds, contactId) => {
    try {
        const requestBody = {
            ContactId: contactId,
            ScheduleIds: scheduleIds,
        };

        const response = await fetch('/api/application/saveContactSchedules', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const createdContactSchedules = await response.json();
        return createdContactSchedules;
    } catch (error) {
        console.error('Error creating contact schedules:', error);
        return null;
    }
};