import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { alpha } from "@mui/material/styles";
import { Paper, Typography } from "@mui/material";
import employees, { columnsEmployees } from "../../api/employees";
import DataTable from "../DataTable/DataTable";
import search, { columnsSearch } from "../../api/search";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "16px 0px",
        margin: "12px 26px",
        '& .MuiTabs-indicator': {
            backgroundColor: '#FFFFFF',
        },
        '& .MuiCardContent-root:last-child': {
            padding: '0px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-row': {
            cursor: 'pointer',
            fontSize: '12px'
        },
        '& .MuiDataGrid-root .MuiDataGrid-colCellWrapper': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none !important',
        },
        '& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount': {
            visibility: 'hidden'
        },
        '& .MuiDataGrid-root': {
            border: "0px"
        },
        '& .icon': {
            padding: 3,
        }
    },
    grow: {
        flex: 1
    },
    row: {
        display: 'flex',
    },
    table: {
        minWidth: 700,
    },
    tableContent: {
        marginTop: "24px",
    },
    download: {
        textAlign: 'right',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    icon: {
        cursor: "pointer"
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        marginBottom: theme.spacing(2),
        border: "1px solid #d3d3d3",
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            /*marginLeft: theme.spacing(3),*/
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        /*pointerEvents: 'none',*/
        color: "#808080",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    new_button: {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
    },
    title: {
        marginTop: theme.spacing(2),
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    paddingBottom: {
        paddingBottom: "16px",
    },
    loading: {
        height: 400,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));


let programs = false;

export default function Search(props) {
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [query, setQuery] = React.useState('');
    const [rows, setRow] = React.useState([]);
    const [columns, setColumns] = React.useState(columnsSearch);
    const [loader, setLoader] = React.useState(true);

    useEffect(() => {
        if (rows.length == 0 && !props.programsProps) {
            search(props.searchString).then((value) => {
                if (value.length == 0) {
                    // programs = true;
                    props.setprops();
                }
                setLoader(false);
                setRow(value);
            });
            return;
        } else {
            setLoader(false);
        }
    }, []);

    const setDataOfFilter = (rows) => {
        return rows.filter((row) => row.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    }

    const _setRowAndColumn = () => {
        return { rows: setDataOfFilter(rows), click: props.click, columns: columns, screen: 'employee', type: 'popup' };
    }

    return (
        <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <Typography className={classes.paddingBottom}>Search results of "{props.searchString == '' ? 'All' : props.searchString}"</Typography>
            <Paper>
                    {loader ? <div className={classes.loading}>Loading....</div> : <DataTable data={_setRowAndColumn()} />}
            </Paper>
        </main>
    );
}

/*
 const Search = ({ parentCallback }) => {
    
    //text input here
    const [evalue, setEvalue] = React.useState('');
    const [filterd,setFilterd]= React.useState(rows);

    const style = useStyles();

    React.useEffect(() => {
        global.log("Effect has been run!")
      }, [])

    //search in the table
    const handleClick = (event) => {
        setEvalue(event.target.value);
        setFilterd( rows.filter( row => row.fullName.includes(evalue)));
        global.log(filterd)
        parentCallback(filterd);
  }


    return (
        <div className={style.allSearch}>
            <Paper component="form" className={style.root}>
            <div className="srch">
                <Input
                    disableUnderline={true} 
                    type="search"
                    className={style.inputSrch}
                    id="input-with-icon-adornment"
                    placeholder="Search"
                    aria-label="Search"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon  />
                        </InputAdornment>
                    }
                    onChange={handleClick}
                />    
            </div>
            </Paper>
        </div>
    )
}


export default Search;

const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: '-10rem',
        borderRadius: 'unset',
        padding: '2px  4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    allSearch: {
        padding: '0.4rem',
    },
    inputSrch:{
        width: '22rem',
    }
  }));*/