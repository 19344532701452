
import React, { useEffect, useState, Component } from "react";
//import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    List,
    ListItem,
} from "@mui/material";
import { getImportData } from "../../api/clients";
import Button from "@mui/material/Button";
import program, { columnsProgram } from '../../api/program';
import * as constant from "../../constants";
import authHeader from "../_helper/auth-header";
import baseStyles from '../../assets/styles'
import _ from "lodash";
import { ServiceList } from "../../api/program";
let programs = false;

export default function ImportData(props) {
    const baseClasses = baseStyles();
    // set the new yeshivas form control
    const [ImportDataPerError, setImportDataPerError] = React.useState("");
    const [selectedProgramPerError, setselectedProgramPerError] = React.useState("");

    const [id, setId] = React.useState(null);
   
    const [loaderDialog, setLoaderDialog] = React.useState(false);
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [ImportData, setImportData] = React.useState("");
    const [selectedProgram, setSelectedProgram] = useState("");
    const [programList] = React.useState([]);
    const [countrySel] = React.useState("");
    const [file, setfile] = useState();
    const [fileName, setfileName] = useState();
    useEffect(() => {
        resetAllData();
        getProgramsApi();
    }, []);

    useEffect(() => {
        const { editEmployeeData } = props;
        if (!_.isEmpty(editEmployeeData)) {

            setImportData(editEmployeeData.ImportData);
        } else {
            resetAllData();
            setId(-1);
        }
    }, [props.editEmployeeData]);
   
    class App extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                selectedOption: '',
                clearable: true,
                cities: [],
                file: '',           
           
            };    
        }
    };

    //choose file functionality//
    const SaveFile = (e) => {
        global.log(e.target.files[0]);
        setfile(e.target.files[0]);
        setfileName(e.target.files[0].name);

    };


    function servicelist() {
        return global.fetchWrapper.get(constant.SERVICE_LIST).then(function (response) {
            return response;
        })
    }

    function resetAllData() {
        setfileName("");
        setSelectedProgram("");
    }

  
    const saveImportData = () => {
        if (!formValidationPersonalDetails()) {
            return;
        }      
        const formdata = new FormData();

        formdata.append("formfile", file);
        formdata.append("filename", fileName);
        try {
            const res =  global.fetchWrapper.post(constant.SAVE_FILE, formdata)
            global.log(res);
        }
        catch (ex) {
            global.log(ex);

        }
      
        const employeeId = id ? id : -1;
        const encoded = encodeURI(fileName);
        const programId = (selectedProgram);
       
        const saveEmployeePayload = {
            id: employeeId,
            createdOn: new Date(),

            data: fileName,
            programs: programId,
            
        };
        global.log("submit data", props.editEmployeeData, saveEmployeePayload, fileName, programId);
        const pathIdString = encoded + ":" + programId;
        return global.fetchWrapper
            .post(constant.SAVE_IMPORTFILE + pathIdString, {

                headers: authHeader(),
            })
            .then(
                (response) => {
                    if (response.responseCode === 200) {
                        handleClose();

                        props.addEmployeeSuccess(true);
                    }
                },
                (error) => {
                    global.log("error", error);
                }
            );       
    };
    //validation
    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (fileName === "") {
            isValid = false;
            setImportDataPerError("Please upload csv file");
        }
        if (selectedProgram === "") {
            isValid = false;
            setselectedProgramPerError("Please select program ");
        }
        return isValid;
    };

    const handleClose = () => {
        resetAllData();
        props.onCloseEmployeemodal();
    };

  
 
    //programlist binding
    function handleCountrySelect(e) {

        global.log("Selected Program", e.target.value);
        const countrySel = e.target.value;
        setSelectedProgram(countrySel);

    }
//api 
    const getProgramsApi = () => {
        if (!programs) {
            program().then((programValues) => {

                if (programValues.length == 0) {
                    programs = true;
                }
                programValues.forEach((item, index) => {
                    programList.push({
                        Key: item.id,
                        Value: item.programName
                    });
                });
            });
            return;
        }
    };
 

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpenEmployeeModal}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                {!_.isEmpty(props.editEmployeeData) ? "Edit" : "Add"} File
      </DialogTitle>
            <DialogContent>
                <div>
                    <FormControl fullWidth>
                        <label htmlFor="upload-button">
                            {image.preview ? (
                                <img src={image.preview} alt="dummy" width="300" height="300" />
                            ) : (
                                    <>
                                        <span className="fa-stack fa-2x mt-3 mb-2">
                                            <i className="fas fa-circle fa-stack-2x" />
                                            <i className="fas fa-store fa-stack-1x fa-inverse" />
                                        </span>




                                    </>
                                )}
                        </label>
                    

                            

                        <input
                            type="file"
                            id="upload-button"
                            accept=".csv"
                            style={{ display: "border:black; border-width:5px; border-style:solid;" }}
                            onChange={(SaveFile) => {
                                if (!_.isEmpty(SaveFile.target.value)) {
                                    setImportDataPerError("");
                                }
                                global.log('e===', SaveFile.target.value, !_.isEmpty(SaveFile.target.value))
                                setfile(SaveFile.target.files[0])
                                setfileName(SaveFile.target.files[0].name)
                            }}
                        />
                        {ImportDataPerError === "" ? null : (
                            <FormHelperText
                                className={baseClasses.error}
                                id="my-helper-text"
                            >
                                {ImportDataPerError}
                            </FormHelperText>
                        )}                     
                    </FormControl>
                  
                </div>

                <div>
                    <FormControl fullWidth>
                       
                        <select name="Program"

                            onChange={(e) => {
                                if (!_.isEmpty(e.target.value)) {
                                    setselectedProgramPerError("");
                                }
                                global.log('e===', e.target.value, !_.isEmpty(e.target.value))
                                setSelectedProgram(selectedProgram)
                                { handleCountrySelect(e) }
                            }} value={selectedProgram} >
                         
                            <option >Select Program</option>
                            
                            {programList && programList.map((e, key) => (
                                <option key={key} value={e.Key}>{e.Value}</option>
                            )                                 
                        )
                           
                    }

                
                        </select>
                        {selectedProgramPerError === "" ? null : (
                            <FormHelperText
                                className={baseClasses.error}
                                id="my-helper-text"
                            >
                                {selectedProgramPerError}
                            </FormHelperText>
                        )}
                        <br />
                    </FormControl>
                </div>


            </DialogContent>
            <DialogActions>
                <Button onClick={saveImportData} color="primary">
                    Save
        </Button>
                <Button onClick={handleClose} color="primary">
                    Close
        </Button>
            </DialogActions>
        </Dialog>
    );
}




