import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/Upload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import { HDate } from '@hebcal/core';
import { Button, FormControl, Card, CardContent, IconButton, Switch } from '@mui/material';
import HebrewMonthSwitcher from '../../components/common/DateSwitchers/HebrewMonthSwitcher';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { TIMESHEET_FILTER_OPTIONS } from '../../constants'
import { getTimesheetClientServiceList } from '../../api/timesheet'
import baseStyles from '../../assets/styles'
import { getISODate } from '../../_helpers/datetime'
import AddTimesheet from '../../components/common/Dialogs/TimesheetUploader';
import { FlexBetween, FlexExactCenter } from '../../assets/styles/styledComponents';
import FilterButtonGroup from '../../components/common/FilterButtonGroup';
import DataTable from '../../components/DataTable/DataTable';
import { DivContainer } from '../../assets/styles/styles';
import { hebrewMonthNames } from '../../_helpers/hebrewDate/constants';
import { getHebrewMonthRanges } from '../../_helpers/hebrewDate/hebrewDateHelpers';
import { TIMESHEET_USAGE_START } from '../../config/featuresUsageStartDates';
import { HebrewDate, hebrewToGreg } from '../../_helpers/hebrewDate/hebrewDate'
import { HebrewMonthDateRange } from '../../_helpers/hebrewDate/hebrewMonthDateRange';
import { getFileExtension } from '../../_helpers/file';
import useGetValueOptionsLists from '../../hooks/useGetValueOptionsLists';
const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function Timesheets() {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [query, setQuery] = React.useState("");
    const [openUploader, setOpenUploader] = React.useState(false);
    const [filterBy, setFilterBy] = React.useState(TIMESHEET_FILTER_OPTIONS[0].value)
    const [isLoading, setLoading]= React.useState(false);
    const [timesheetData, setTimesheetData]= React.useState([]);
    const [editData, setEditData] = React.useState();
    const [hebMonthRanges, setHebMonthRanges] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState();

    const valueOptions = useGetValueOptionsLists(['clientName', 'providerName'])
    useEffect(() => {
        setHebMonthRanges(getHebrewMonthRanges(TIMESHEET_USAGE_START))
    }, [])
     
    useEffect(() => {
        setSelectedDate(hebMonthRanges.find((range) => range.monthIndex === 0))
    }, [hebMonthRanges]);

    const handleNewTimesheet = () => {
        setOpenUploader(true);
    };

    const handleClose = (reloadData = null) => {
        setOpenUploader(false);
        if (!!reloadData) {
            loadTimesheetData(selectedDate.dateFrom);
        }
        setEditData(null)
    };

    const onMonthChange = (slcHebMoYr) => {
        let date = hebrewToGreg(slcHebMoYr.month.value, slcHebMoYr.year.value); // Converting Selected Hebrew Month and Year into
        let dateRange = hebMonthRanges.find((x) => global.dateTime.isDateOnlyEqual(x.dateFrom, date))
        setSelectedDate(dateRange);
        loadTimesheetData(dateRange.dateFrom)
    }

    const handleClickFilter = (filter) => {
        setFilterBy(filter)
    }

    const _handleRowSelect = () => {

    }

    const loadTimesheetData = (hebMonth) => {
        setLoading(true)
        let isoDate = getISODate(hebMonth);
        getTimesheetClientServiceList(isoDate).then((data) => {
            setTimesheetData(data)
            setLoading(false);
        })

    }

    const handleClick = (action, row) => {
        switch(action) {
            case 'Download':
                global.fetchWrapper.downloadFile(row.timesheetFileId)
                return;
            case'Upload':
                setOpenUploader(true);
                let edit = {
                    month: selectedDate,
                    client: {
                        clientName: row.clientName,
                        clientId: row.clientId,
                    },
                    service: {
                        providerName: row.providerName,
                        id: row.clientServiceId
                    },
                    file: {
                        timesheetFileId: row.timesheetFileId,
                        timesheetFileName: row.timesheetFileName,
                        uploadedOn: row.uploadedOn
                    }
                }
                setEditData(edit);
                return;
            default: // open
                global.fetchWrapper.readFileInNewTab(row.timesheetFileId)
                return
        }
    } 

    const timesheetCols = [
        { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
        { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details", type: 'singleSelect' },
        { field: 'uploadedOn', headerName: 'Date Uploaded', width: 120, type: "date" },
        {
            field: 'totalMinutes', headerName: 'Time', width: 120,
            renderCell: (param) => {
                let value = param.row.totalMinutes;
                let hours = value === 0 ? 0 : Math.floor(value / 60);
                let minutes = value === 0 ? 0 : value % 60;
                return value ? `${hours}:${minutes.toString().padStart(2, '00')}` : '';
            },
        },
        { field: 'isApproved', headerName: 'Approved', width: 120, type: "boolean" },
        { field: "action", enableTooltip: false, disableColumnMenu: true, 
            renderCell: (param) => (
                
                    <>
                    {!!param.row.uploadedOn ? 
                        <IconButton
                            onClick={() => handleClick("Download", param.row)}
                            style={{width: '40px'} }
                        >
                            <CloudDownloadIcon />
                        </IconButton> :
                        <IconButton
                            style={{ width: '40px' }}
                        />
                    }

                    {!!param.row.uploadedOn && getFileExtension(param.row.timesheetFileName) === 'pdf' ?
                        <IconButton
                            onClick={() => handleClick("Open", param.row)}
                            style={{ width: '40px' }}
                        >
                                <OpenInNewIcon />
                        </IconButton> :
                        <IconButton
                            style={{ width: '40px' }}
                        />
                    }
                    {!!param.row.uploadedOn ?
                        <IconButton
                            onClick={() => handleClick("Upload", param.row)}
                            style={{ width: '40px' }}
                        >
                            <EditIcon />
                        </IconButton> :
                        <IconButton
                            onClick={() => handleClick("Upload", param.row)}
                            style={{ width: '40px' }}
                        >
                            <CloudUploadIcon />
                        </IconButton>
                    }
                </>
            ), sortable: false, maxWidth: 150, minWidth: 30, cellClassName: 'icon-no-padding' }
    ];

    const getFilteredData = () => {
        switch(filterBy) {
            case 'notUploaded': 
                return timesheetData.filter((data) => data.timesheetFileId <= 0)
            case 'uploaded':
                return timesheetData.filter((data) => data.timesheetFileId > 0)
            default:
                return timesheetData
        }
    }

    const getMonthYears = (field) => {
        let date = getHebrewMonthRanges(TIMESHEET_USAGE_START);
        switch(field) {
            case 'from':
                return {
                    month:global.arrays.getMaxObjectByProperty(date, "monthIndex").monthInYear,
                    year: global.arrays.getMaxObjectByProperty(date, "monthIndex").hebYear
                }
            case 'to':
                return {
                    month:global.arrays.getMinObjectByProperty(date, "monthIndex").monthInYear,
                    year: global.arrays.getMinObjectByProperty(date, "monthIndex").hebYear
                }
            default: 
                return null
        }
    }

    return (
        <main className={classes.content}>
            <div className={baseClasses.toolbar} />
            <FlexExactCenter>
                <Card className={classes.contentCenter}>
                    <CardContent classes={{ root: classes.cardPadding }}  >
                        <HebrewMonthSwitcher
                            from={getMonthYears("from")}
                            to={getMonthYears("to")}
                            onChange={onMonthChange}
                            isLoading = {isLoading}
                            // currentMonth={ new HDate().getMonth()}
                            // isFutureDate={false}/** by defualt we allowed future date in this component */
                        />
                    </CardContent>
                </Card>
            </FlexExactCenter>
            <FlexBetween>
                <DivContainer>
                    <DivContainer>
                        Filter By:
                    </DivContainer>
                    <FilterButtonGroup 
                        variant="outlined" 
                        selectedVariant="contained"
                        filterOptions={TIMESHEET_FILTER_OPTIONS}
                        onClick={handleClickFilter}
                        style={{textTransform: 'capitalize'}}
                        selected={filterBy}
                    />
                </DivContainer>
                <Button
                    variant="text"
                    onClick={handleNewTimesheet}
                    color="primary"
                    sx={{
                        textTransform: 'capitalize' 
                    }}
                >
                    Add Timesheet<AddRoundedIcon fontSize="small" />
                </Button>
            </FlexBetween>
            <DataTable
                data={{
                    rows: getFilteredData(),
                    columns: timesheetCols,
                    screen: "timesheet",
                }}
                onRowClick={_handleRowSelect}
                rowHeight={30}
                loading={isLoading}
                title="Timesheet"
                valueOptionsLists={valueOptions}
                hideToolbarIconText
                multiView
            />
            {openUploader &&
                <AddTimesheet
                    onCloseModal={handleClose}
                    editData={editData}
                    isOpenCreateModal={openUploader}
                    hebrewMonthRanges={hebMonthRanges}
                />
            }
        </main>
    );
}