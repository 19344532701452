import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from 'react-router-dom';
import baseStyles from '../../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";
import GeneralDetails from './Sections/GeneralDetails';
import SederDetails from './Sections/SederDetails';
import FRONTEND_ROUTE_CONSTANTS from '../../../frontend_route_constants'
import { DivContainer, FlexAlignCenter } from '../../../assets/styles/styledComponents';
import { TimeRangeValidator } from '../../../_helpers/schedule/validators/client-schedule';
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";
import Tabs from '../../../components/common/UI/Navigation/Tabs/Tabs';
import TabPanel from '../../../components/common/UI/Navigation/Tabs/TabPanel';
import { YESHIVAS_MANAGE_TABS } from '../../../constants';
import { yeshivasAddressType, yeshivasBasicInfoType, yeshivasSederResType } from '../../../assets/types/yeshiva';
import { 
     getYeshivasSedar,
     getYeshivas, 
     addYeshivas, 
     updateYeshivas, 
     addYeshivasAddress, 
     updateYeshivasAddressType, 
     addYeshivasAddressType, 
     addYeshivasSedarType, 
     getYeshivasAddress, 
     getYeshivasSedarType, 
     addYeshivasSedar,
     updateYeshivasSedar
} from '../../../api/yeshivas';
import {YESHIVAS_GENERAL_DETAILS_STATE, YESHIVAS_SCHEDULE_DETAILS_STATE } from '../../../constants';

type YeshivaManageProps = {

}

export default function YeshivaManage({}: YeshivaManageProps) {
    const navigate = useNavigate();
    const {yeshivasId} = useParams();
    const ID = parseInt(yeshivasId as string);
    const baseClasses: any = baseStyles();
    const [generalDetail, setGeneralDetail] = useState<any>(YESHIVAS_GENERAL_DETAILS_STATE);
    const [scheduleInfo, setScheduleInfo] = useState<any>(YESHIVAS_SCHEDULE_DETAILS_STATE);
    const [selectedTab, setSelectedTab] = React.useState(YESHIVAS_MANAGE_TABS[0].value);
    const yeshivasList = useStoreState((state: any) => state.yeshiva.yeshivas);
    const updateYeshivasList = useStoreActions((action: any) => action.yeshiva.updateYeshivas);
    const exploringId = useStoreState((state: any) => state.exploringId);
    const setExploringMode = useStoreActions((actions: any) => actions.setExploringMode);
    const setExploringId = useStoreActions((actions: any) => actions.setExploringId);
    const setExploringName = useStoreActions((actions: any) => actions.setExploringName);
    const [tabs, setTabs] = React.useState<any>(YESHIVAS_MANAGE_TABS);
    const history = useStoreState((state: any) => state.history);
    const [changesHelper]: any = useUnsavedChangesWarn();
    setExploringId(ID);
    const { setDirty } = changesHelper;

    const addressMapping = (item: yeshivasAddressType) => {
        return {
            ...item,
            address1: { value: item?.address1, error: '',isRequired: true},
            address2: { value: item?.address2, error: ''},
            aptPOBox: { value: item?.aptPOBox, error: '' },
            city: { value: item?.city, error: '' },
            state: { value: item?.state, error: '' },
            zip: { value: item?.zip, error: '' },
            neighborhood: { value: item?.neighborhood, error: '' },
            mode: 'read',
            isLoading: false
        }
    }

    const basicInfoMapping = (data: yeshivasBasicInfoType) => {
        return {
            name: {value: data.name, error: '', isRequired: true},
            legalName: {value: data.legalName, error: '', isRequired: true},
            description: {value: data.description, error: ''},
            yeshivaId: data.yeshivaId,
            mode: 'read',
            isLoading: false
        }
    }

    const scheduleInfoMapping = (data: any) => {
        return {
            ...data,
            startTime: data.startTime,
            endTime: data.endTime,
            yeshivaId: data.yeshivaId,
            error: '',
            mode: 'read',
            isLoading: false
        }
    }
    
    useEffect(() => {
        if(ID <= 0) {
            setToEditMode();
            setTabs(YESHIVAS_MANAGE_TABS.filter((item) => item.value === 'general'))
        } else {
            setTabs(YESHIVAS_MANAGE_TABS)
            loadGeneralInitialDetail();
            loadYeshivaSedar();
        }
    },[yeshivasId])

    const loadGeneralInitialDetail = async () => {
        let generalInfoTemp = {...generalDetail};
        Object.keys(generalInfoTemp).forEach(function(key, index) {
            generalInfoTemp[key] = {
                ...generalInfoTemp[key],
                isLoading: true
            }
        });
        setGeneralDetail(generalInfoTemp);
        const address = await getYeshivasAddress(ID);
        const basicInfo = await getYeshivas(ID);
        const addressResponse: any = { ...generalDetail};
        address.map((item: yeshivasAddressType) => {
            if (!!item) {
                addressResponse[item.addressType] = {
                    ...addressMapping(item)
                }
            }
        })
        generalInfoTemp = {...generalInfoTemp, ...addressResponse, basicInfo: basicInfoMapping(basicInfo)}
        setGeneralDetail(generalInfoTemp)
    }

    const getUpdateObjectProperty = (obj: any, field: string, value: string | boolean | number) => {
        const objTemp = {...obj};
        Object.keys(objTemp).forEach(function(key, index) {
            objTemp[key] = {
                ...objTemp[key],
                [field]: value
            }
        });
        return objTemp;
    }

    const loadYeshivaSedar = () => {
        setScheduleInfo(getUpdateObjectProperty(scheduleInfo, `isLoading`, true))
        getYeshivasSedar(ID)?.then((data: yeshivasSederResType[]) => {
            const scheduleTemp = {...scheduleInfo}
            data.map((item: yeshivasSederResType) => {
                scheduleTemp[item.name] = {
                    ...scheduleTemp[item.name],
                    ...item,
                   startTime: item?.startTime ?? '',            
                   endTime: item?.endTime ?? '',
                   isLoading: false
                }
            })
            setScheduleInfo(getUpdateObjectProperty(scheduleTemp, `isLoading`, false))
        })
    }

    const loadYeshivasAddress = () => {
        setGeneralDetail({
            ...generalDetail,
            mainAddress: {
                ...generalDetail.mainAddress,
                isLoading: true
            },
            legalAddress: {
                ...generalDetail.legalAddress,
                isLoading: true
            },
            summerAddress: {
                ...generalDetail.summerAddress,
                isLoading: true
            },

        })

        getYeshivasAddress(ID)?.then((data) => {
            const saveResponse: any = { ...generalDetail};
            data.map((item: any) => {
                if (!!item) {
                    saveResponse[item.addressType] = {
                        ...addressMapping(item)
                    }
                }
            })
            setGeneralDetail(saveResponse);
        })
    }

    const loadYeshivasMainInformation = () => {
        setGeneralDetail({
            ...generalDetail,
            basicInfo: {
                ...generalDetail.basicInfo,
                isLoading: true
            }
        })
        getYeshivas(ID)?.then((data) => {
            if (!!data) {
                setGeneralDetail({
                    ...generalDetail,
                    basicInfo: {...basicInfoMapping}
                })
            }
        })
    }

    const loadYeshivasAddressType = () => {

    }

    const setToEditMode = () => {
        handleChangeMode('general', 'edit', null);
        handleChangeMode('schedule', 'edit', null);
    }

    const handleClickBack = () => {
            goBack();
    };

    const goBack = () => {
        setExploringMode("detail");
        navigate(-1);
    };

    const exploringName = useStoreState((state: any) => state.exploringName);

    const handleChange = (value: string) => {
        setSelectedTab(value)
    }

    /**
     * Function that is responsible to switch each section on 'edit' and 'read' mode
     *@param  {String} tab active tab
    * @param  {string} mode mode we want switch either 'read' or 'edit'
    * @param {string} section section of the tab we want to change mode, if section is null this means we want change mode all sections
    * @returns {void}
    */
    const handleChangeMode = (tab: string, mode: string, section: string | null) => {
        switch(tab) {
            case 'general': {
                const generalInfoTemp = {...generalDetail};
                Object.keys(generalInfoTemp).forEach(function(key, index) {
                    if (!section) {
                        generalInfoTemp[key] = {
                            ...generalInfoTemp[key],
                            mode: mode
                        }
                    } else if (key === section) {
                        generalInfoTemp[key] = {
                            ...generalInfoTemp[key],
                            mode: mode
                        }
                    }
                  });
                setGeneralDetail(generalInfoTemp);
                break;
            };
            case 'schedule': {
                const scheduleInfoTemp = {...scheduleInfo};
                Object.keys(scheduleInfoTemp).forEach(function(key, index) {
                    if (!section) {
                        scheduleInfoTemp[key] = {
                            ...scheduleInfoTemp[key],
                            mode: mode
                        }
                    } else if (key === section) {
                        scheduleInfoTemp[key] = {
                            ...scheduleInfoTemp[key],
                            mode: mode
                        }
                    }
                  });
                  setScheduleInfo(scheduleInfoTemp)
                  break;
            }
            default: 
                return null
        }
    }

    const handleChangeGeneralDetail = (event: any, name: string) => {
        setDirty()
        setGeneralDetail({
            ...generalDetail,
            [name] : {
                value: event.target.value,
                error: ''
            }
        })
    }

    const validateTimeFields = (timeFields: any, requireStartTime=true, requireEndTime=true) => {
        const timeRangeValidator = new TimeRangeValidator(timeFields.startTime, timeFields.endTime, {
            requireStartTime: requireStartTime,
            requireEndTime: requireEndTime
        });

        timeRangeValidator.validate();;
        return timeRangeValidator;
    }

    const handleChangeSchedule = (value: any, name: string, field: string) => {
        setDirty()
        let schedule: any = scheduleInfo[name];
        schedule = {
            ...schedule,
            [field]: value
        }
        const valResult = validateTimeFields(schedule, false, false);
        schedule = {
            ...schedule,
            error: valResult.isValid ? '' : valResult.fieldMessages
        }
        
        setScheduleInfo({
            ...scheduleInfo,
            [name] : schedule
        })
    }

    const resetState = () => {
        setGeneralDetail(YESHIVAS_GENERAL_DETAILS_STATE);
        setScheduleInfo(YESHIVAS_SCHEDULE_DETAILS_STATE);
    }

    const rollbackChanges = () => {
        if(exploringId === -1 || yeshivasId === '-1') {
            resetState()
        }
    }
    
    /**
     * 
     * @param value 
     * @param isRequired 
     * @returns
     */
    const getErrorMessage = (value: string, isRequired: boolean | undefined) => {
        if (isRequired && !value) {
            return 'This Field is required!'
        }
        return ''
    }

    /**
     * This function will do validation before saving data
     */
    const validateGeneralFields = (payload: any, section: string) => {
        let errorMessage = '', isValid = true, generalTemp = {...generalDetail}
        Object.keys(payload).forEach(function(key) {
            errorMessage = getErrorMessage(payload[key], generalDetail[section]?.[key]?.[`isRequired`]);
            if (!!errorMessage) {
                isValid = false
            }
            generalTemp = {
                ...generalTemp,
                [section]: {
                    ...generalTemp[section],
                    [key]: {
                        ...generalTemp[section][key],
                        value: payload[key],
                        error: errorMessage,
                    }
                }
            }
        });
           
        setGeneralDetail(generalTemp)
        return isValid
    }

    /**
     * This function will handle saving and canceling of edit section.
     * @param data - data that needs be savedf
     * @param type - save OR cancel
     * @param tab - Active Tab
     * @param section - Active Section
     * @param mode - Current Mode
     */
    const handleSave = (data: any, type: string, tab: string,  section: string, mode: string ) => {
        if (type === 'cancel') {
            rollbackChanges()
            handleChangeMode(tab, mode, section)
            return
        }
        let payload: any = {...data, yeshivaId: ID};
        delete payload.mode;
        delete payload.isLoading;
        Object.keys(payload).forEach(function(key) {
            payload[key] = payload[key]?.value ?? payload[key]
        });
        switch(section) {
            case 'basicInfo': {
                if(!validateGeneralFields(payload, 'basicInfo')) {
                    return
                }
                setGeneralDetail({
                    ...generalDetail,
                    [section]: {    
                        ...generalDetail[section],
                        ...data,
                        isLoading: true
                    }
                })
                if (ID > 0) {
                    updateYeshivas(ID, payload).then((res: yeshivasBasicInfoType) => {
                        setGeneralDetail({
                            ...generalDetail,
                            [section]: {    
                                ...basicInfoMapping(res)
                            }
                        })
                    })
                } else {
                    addYeshivas(payload).then((res) => {
                        setExploringId(res.yeshivaId);
                        setExploringName(res.name);
                        updateYeshivasList([...yeshivasList, {...res, id: res.yeshivaId}])
                        history(FRONTEND_ROUTE_CONSTANTS.YESHIVA_ROUTE + res.yeshivaId + FRONTEND_ROUTE_CONSTANTS.MANAGE)
                        setGeneralDetail({
                            ...generalDetail,
                            [section]: {    
                                ...basicInfoMapping(res)
                            }
                        })
                    })
                }
                break;
            }
            case 'mainAddress':
            case 'legalAddress':
            case 'summerAddress': {
                if(!validateGeneralFields(payload, section)) {
                    return
                }
                setGeneralDetail({
                    ...generalDetail,
                    [section]: {
                        ...generalDetail[section],
                        ...data,
                        isLoading: true
                    }
                })
                addYeshivasAddressType(ID, section, {...payload, addressType: section}).then((res) => {
                    setGeneralDetail({
                        ...generalDetail,
                        [section]: {    
                            ...addressMapping(res)
                        }
                    })
                })
                break;
            }
            case 'earlyMorning':
            case 'morning':
            case 'afternoon':
            case 'night':
             {
                setScheduleInfo({
                    ...scheduleInfo,
                    [section]: {
                        ...scheduleInfo[section],
                        ...data,
                        isLoading: true
                    }
                })
                if (payload.sederId > 0 ) {
                    updateYeshivasSedar(ID, payload.sederId, {...payload, name: section, isLoading: false}).then((res) => {
                        setScheduleInfo({
                            ...scheduleInfo,
                            [section]: {
                                ...scheduleInfoMapping(res)
                            }
                        })
                    })
                } else {
                    addYeshivasSedar(ID, {...payload, name: section}).then((res) => {
                        setScheduleInfo({
                            ...scheduleInfo,
                            [section]: {
                                ...scheduleInfoMapping(res)
                            }
                        })
                    })
                }
            }
        }
    }

    /**
     * Change Tab
     * @param event 
     * @param newValue 
     */
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue)
    }

    return (
        <div className={baseClasses.content}>
            <div className={baseClasses.toolbar}/>
            <FlexAlignCenter>
                <IconButton onClick={handleClickBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                {exploringName}
            </FlexAlignCenter>
            <Card variant="outlined">
                <Tabs
                    tabs={tabs}
                    value={selectedTab}
                    variant="standard"
                    aria-label="yeshiva-manage"
                    onChange={handleChangeTab}
                />
                {YESHIVAS_MANAGE_TABS[0] && <TabPanel value={selectedTab} index={YESHIVAS_MANAGE_TABS[0].value}>
                    <GeneralDetails handleSave = {handleSave}  generalDetail={generalDetail}/>
                </TabPanel>}
                {YESHIVAS_MANAGE_TABS[1] && <TabPanel value={selectedTab} index={YESHIVAS_MANAGE_TABS[1].value}>
                    <SederDetails handleSave={handleSave} scheduleInfo={scheduleInfo} />
                </TabPanel>}
            </Card>
        </div>
    );
}
