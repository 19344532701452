import { useEffect, useState } from 'react';
import { getClientNames } from '../api/clients';
import { getEmployeeNames } from '../api/employees';
import { getYeshivasList } from '../api/yeshivas';
import { CLIENT_COMFORT_HEALTH_STATUS_LIST, EMPLOYEE_COMFORT_HEALTH_STATUS_LIST, explorerTypesConst, LHISALOS_APPROVAL_STATUS } from '../constants';
import { clientScheduleStatus } from '../_helpers/constants/scheduleConstants';

function useGetValueOptionsLists(listNames, module) {
    const getLists = {
        yeshiva: async () => {
            return (await getYeshivasList()).map((m) => m.name);
        },
        organizer: async () => {
            return (await global.contactAPI.getContactsByUserRole('Meargen')).map((m) => m.name);
        },
        meargen: async () => {
            return (await global.contactAPI.getContactsByUserRole('Meargen')).map((m) => m.name);
        },
        providerName: async () => {
            return (await getEmployeeNames()).map((m) => m.name);
        },
        clientName: async () => {
            return (await getClientNames()).map((m) => m.name);
        },
        roshMeargen: async () => {
            return (await global.contactAPI.getContactsByUserRole('RoshMeargen')).map((m) => m.name);
        },
        financialCoordinator: async () => {
            return (await global.contactAPI.getContactsByUserRole('FinancialCoordinator')).map((m) => m.name);
        },
        careManager: async () => {
            return (await global.contactAPI.getContactsByUserRole('CareManager')).map((m) => m.name);
        },
        careManagerSupervisor: async () => {
            return (await global.contactAPI.getContactsByUserRole('CareManagerSupervisor')).map((m) => m.name);
        },
        hcbsCoordinator: async () => {
            return (await global.contactAPI.getContactsByUserRole('HCBSCoordinator')).map((m) => m.name);
        },
        comfortHealthStatus: async () => {
            return module === explorerTypesConst.client ? CLIENT_COMFORT_HEALTH_STATUS_LIST : module === explorerTypesConst.employee ? EMPLOYEE_COMFORT_HEALTH_STATUS_LIST : [];
        },
        statusIcon: async () => {
            return Object.values(clientScheduleStatus);
        },
        neighborhood: async () => {
            return (await global.getSystemListValuesListByName('Neighborhoods')).map((i) => i.name);
        },
        lHisalosApprovalStatus: async () => {
            return LHISALOS_APPROVAL_STATUS;
        },
        paymentOptions: async () => {
            return (await global.getSystemListValuesListByName('Payment_Options')).map((i) => i.name);
        },
        professionalType: async () => {
            return (await global.getSystemListValuesListByName('Professional_Types')).map((i) => i.name);
        },
    }
    const [lists, setLists] = useState({})
    useEffect(() => {
        const getAllLists = async () => {
            let result = {};
            for (const name of listNames) {
                if (typeof getLists[name] === 'function') {
                    result[name] = { values: await getLists[name]() }
                }
            }
            setLists(result)
        }
        getAllLists();
    }, [])

    return lists;
}
export default useGetValueOptionsLists;