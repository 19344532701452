import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "react-toastify/dist/ReactToastify.css";
import "./web.config";
import model from "./redux";
import { StoreProvider, createStore } from "easy-peasy";
import { accountService } from "./_services/account.service";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const store = createStore(model);
// attempt silent token refresh before startup


accountService.getPermissions().then(() => {
    accountService.refreshToken().finally(startApp);
});

function startApp() {
    ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <StoreProvider store={store}>
                <App />
            </StoreProvider>
        </BrowserRouter>,
        rootElement
    );
    accountService.userValue && store.getActions().contactsLoad();
  }
// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
registerServiceWorker();

(function (history) {
    var pushState = history.pushState;
    history.pushState = function (state) {
        return pushState.apply(history, arguments);
    };
})(window.history);

window.addEventListener("focus", () => {
    if (global.redirectToLoginOnWindowFocus)
        global.redirectToLoginOnWindowFocus();
}, false);