import React, { useContext, useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
// import { useHistory } from 'react-router';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useStoreState, useStoreActions} from 'easy-peasy'
import baseStyle from '../assets/styles';
import useStyle from './styles'
import { accountService } from '../_services/account.service';
// import { default as logo } from '../assets/logo.svg';
import Logo from '../components/common/LogoSvg'
import { Typography } from '@mui/material';


export default function Login({ location }) {
    const history = useNavigate();
    const baseClasses = baseStyle();
    const classes = useStyle()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("");
    const emailField = useRef(null)
    const passwordField = useRef(null)
    const [emailErr, setEmailErr] = useState('')
    const [passwordErr, setPasswordErr] = useState('')
    const isLoading = useStoreState((state) => state.users.isLoading)
    const setLoading = useStoreActions((actions) => actions.users.setLoading)
    function onSubmit() {
        let emailVal = email || emailField.current.value;
        let passwordVal = password || passwordField.current.value;
        if (emailVal.length < 1) {
            setEmailErr("please enter your email")
        }
        if (passwordVal.length < 1) {
            setPasswordErr("please enter your password")
        }
        if (emailVal.length < 1 || passwordVal.length < 1)
            return
        // TODO: We need to move service call to redux
        setLoading(isLoading)
        accountService.login(emailVal, passwordVal)
            .then(() => {
                setLoading(isLoading)
                history('/')
            })
            .catch(error => {
                setLoading(isLoading)
                alert("error")
            });
    }
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                onSubmit();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    useEffect(() => {
        if (emailErr) {
            setEmailErr(!email ? "please enter your email" : "")
        }
    }, [email])
    useEffect(() => {
        if (passwordErr) {
            setPasswordErr(!password ? "please enter your password" : "")
        }
    }, [password])
    //useEffect(() => {
    //    let interval = setInterval(() => {
    //        if (emailField.current && emailField.current.value !== email && passwordField.current && passwordField.current.value !== password) {
    //            setEmail(emailField.current.value)
    //            setPassword(passwordField.current.value)
    //            //do the same for all autofilled fields
    //            clearInterval(interval)
    //        }
    //    }, 100)
    //})
    return (
        <div >
            <div className={baseClasses.centerText}>
                <Logo style={{ height: 90 }} className={classes.imgLogo}/>
                {/* <img style={{ height: 90 }} className={classes.imgLogo} src={logo} /> */}
            </div>
            <Container className={classes.root} component="main" maxWidth="xs">
                <div className={baseClasses.paper}>
                    <div className={baseClasses.formContainer}>
                        <TextField
                            inputRef={emailField}
                            id="email"
                            label="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            type="email"
                            error={emailErr}
                            helperText={emailErr}
                            fullWidth />
                    </div>
                    <br />
                    <br />
                    <div className={baseClasses.formContainer}>
                        <TextField
                            inputRef={passwordField}
                            value={password}
                            label="Password"
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                            id="password"
                            error={passwordErr}
                            helperText={passwordErr}
                            fullWidth />
                    </div>
                    <br />
                    <br />
                    <div>
                        {
                            isLoading ? <div className={baseClasses.centerText}><CircularProgress size={30} /></div> :
                                <Button
                                    //disabled={
                                    //    email?.length === 0 || password?.length === 0
                                    //}
                                    onClick={onSubmit}
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    className={classes.submit} fullWidth>
                                    Log In
                                </Button>
                        }
                    </div>


                </div>

            </Container>
            <Typography
                style={{position: 'absolute', left: 20, bottom: 20}}
                fontSize={12}
            >
                Trouble logging in?
                <br />
                {"Please contact us at: "}
                <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=support@techonsoft.com"
                    target={"_blank"}
                >
                    {"support@techonsoft.com"}
                </a>
            </Typography>
        </div>
    )

}

export { Login };