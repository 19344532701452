import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { NavLink, useLocation } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import listOfMenuItems from './Routes';
import { accountService } from '../../_services/account.service';
import { useStoreActions, useStoreState } from "easy-peasy";
import useUnsavedChangesWarn from "../../hooks/useUnsavedChangesWarn";
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
        },
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.down('lg')]: {
            width: `${drawerWidth - 100}px !important` ,
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: 0,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function NavigationBar(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const location = useLocation();
    const subMenuURL = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    var pathArray = window.location.pathname.split('/');
    const history = useStoreState((state) => state.history);
    let { open, onClose } = props;
    const isWindowDirty = useStoreState((state) => state.isWindowDirty);
    const [openSubMenu, setOpenSubMenu] = React.useState('');
    const setWindowDirty = useStoreActions((actions) => actions.setWindowDirty);
    const auth = useStoreState((state) => state.auth);
    //const setWindowDirty = useStoreActions((actions) => actions.setWindowDirty);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const setExploringId = useStoreActions(
        (actions) => actions.setExploringId
    );
    //const roles = [
    //    permissions.roles.admin,
    //    permissions.roles.financial,
    //    permissions.roles.intake,
    //    permissions.roles.Menahel,
    //    permissions.roles.OfficeDirector
    //];
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    
    const handleClickItem = (item) => {
        openSubMenu !== item.label && history(Array.isArray(item.link) ? item.subMenu[0].link : item.link)
        item.subMenu && setOpenSubMenu(openSubMenu === item.label ? "" :item.label)
    }

    const handleExpandCollapse = (e, item) => {
        setOpenSubMenu(item);
        e.preventDefault();
        e.stopPropagation();
    }
    
    return (
        <Drawer
            variant="permanent"
            anchor={props.anchor}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            sx={{
                width: open ? drawerWidth : 0,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: open ? drawerWidth : 0, boxSizing: 'border-box' },
            }}>
            <div className={classes.toolbar}>
                <IconButton onClick={props.onClose}>
                    {theme.direction === 'rtl' ? <MenuOpenIcon /> : <MenuOpenIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                {listOfMenuItems().map((item, index) => {
                    if (item.permissions && !global.roleContain(item.permissions.canView)) {
                        return <></>
                    }
                    if(item.hideMenu) return <></>
                    return (
                        <ProtectedComponent permissions={item.permissions} enableIfCanView={true}>
                            <div id={"nav-" + location.pathname.replace("/", "")} style={{ textDecoration: 'none' }} key={item.id}>
                                <ListItemButton
                                    selected={
                                        //match all subpages
                                        (typeof item.link === 'string' && item.link.substring(1).startsWith(pathArray[1])) ||
                                        //by employee when on a subpage show main employee menu selected (not the submenu)
                                        (Array.isArray(item.link) && item.link[0].substring(1).startsWith(pathArray[1]) &&
                                            /* exclude when on the main employee page it shouldn't show the main menu just the submenu*/item.link.every(i => i !== location.pathname))}
                                    button
                                    key={item.id}
                                    onClick={(event, newValue) => {
                                    handleClickItem(item)
                                    if (isWindowDirty) {
                                        if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                                            event.preventDefault();
                                            return;
                                        } else {
                                            setClean();
                                        }
                                    }
                                    if (item.label === "Talmidim" || item.label === "Employees")
                                        setExploringMode("detail");
                                    setExploringId(0);
                                }}>
                                    <ListItemText primary={item.label} />
                                
                                
                                
                                {item.subMenu && openSubMenu === item.label ? <ExpandLess onClick={(e) =>handleExpandCollapse(e ,"")} /> : item.subMenu && <ExpandMore onClick={(e) =>handleExpandCollapse(e, item.label)} />}
                                </ListItemButton>
                                {item.subMenu && item.subMenu.map((subMenu, index) => (
                                    <Collapse in={openSubMenu === item.label} key={`${index}-${subMenu.label}-navbar`} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding onClick={(e) => {
                                            e.stopPropagation();
                                            history(subMenu.link);
                                            if (item.label === "Talmidim" || item.label === "Employees")
                                                setExploringMode("detail");
                                            setExploringId(0);
                                        }}
                                        >
                                            <ListItemButton selected={subMenu.value === subMenuURL} sx={{ pl: 4 }}>
                                                <ListItemText primary={subMenu.label} />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                ))}
                            </div>
                        </ProtectedComponent>
                    )
                }
                )}
            </List>
        </Drawer >
    );
}
function RoleHasPermission(label) {
    var roles = accountService.userValue?.roles;

    if (roles === null) {
        return false;
    }

    if (roles.includes("Admin")) {
        return true;
    }

    for (let i = 0; i < roles.length; i++) {
        if (roleTabs[roles[i]] && roleTabs[roles[i]].includes(label)) {
            return true;
        }
    }

    return false;
}



const roleTabs = {
    Intake: ['Employees', 'Yeshivas',],
    Financial: ['Finance', 'Billing', 'Timesheets']
}