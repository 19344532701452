import { action, thunk, computed } from "easy-peasy";


const defaultEmployeeColumnsAndFilters = {
    columnsVisibility: {
        isYungerman: false,
        isProfessional: false,
        isLegal: false,
        isEmployee: false,
        inactive: false,
        id: false,
        fullName: false,
        firstNameHebrew: false,
        lastNameHebrew: false,
        firstNameLegal: false,
        lastNameLegal: false,
        middleNameLegal: false,
        hasText: false,
        address1: false,
        age: false,
        city: false,
        email1: false,
        state: false,
        zip: false,
        dob: false,
        rate: false,
        prevYeshivas: false,
        experience: false,
        note: false,
        parsonageLimit: false,
        financialNotes: false,
        paymentOptions: false,
        planningToEnrollComfortHealth: false,
        hasComfortHealthApplication: false,
        hasBA: false,
        hasHighSchoolDiploma: false,
        sentToComfortHealthOn: false,
        fingerprintedOn: false,
        comfortHealthApprovedOn: false,
        catsApprovedOn: false,
        specialRequirements: false,
        amountCouldWork: false,
        isInTheMountainsSummer: false,
        hasLicense: false,
        hasCar: false,
        summerColony: false,
        amountBilling: false,
        professionalType: false,
        isLhisalosInHouse: false,
        uploadedIdOn: false,
        uploadedParsonageFormOn: false,
        uploadedHighSchoolDiplomaOn: false,
        uploadedBAOn: false,
        baFileName: false,
    },
    filterModel: {
        items: []
    },
}

const defaultStore = {
    home: {
        columnsVisibility: {
            id: false,
            fullName: false,
            firstNameHebrew: false,
            lastNameHebrew: false,
            roshMeargen: false,
            careManager: false,
            careManagerSupervisor: false,
            hcbsCoordinator: false,
            neighborhood: false,
            phone1: false,
            age: false,
            dob: false,
            dateEnrolledComfort: false,
            dateApprovedComfort: false,
            isPrevEnrolledInCH: false,
            isSeeingTherapist: false,
            comfortNote: false,
            sentDocsToParentsOn: false,
            pendedOn: false,
            reactivatedOn: false,
            insuranceCompany: false,
            sentBinderToComfortHealthOn: false,
            careManagerAssignedOn: false,
            intakeMeetingDate: false,
            cans_CompletedOn: false,
            crisis_CompletedOn: false,
            poc_CompletedOn: false,
            comprehensiveAssessment_CompletedOn: false,
            loc_CompletedOn: false,
            packetSentOn: false,
            comfortHealthTimeRequirements: false,
            sentIepToNewAgencyOn: false,
            dateEnrolledP3: false,
            dateApprovedP3: false,
            hoursPerWeekP3: false,
            dateEnrolledLight: false,
            enrolledOn: false,
            disenrolledOn: false,
            uploadedInsuranceOn: false,
            uploadedConsentFormOn: false,
            uploadedSedOn: false,
            uploadedIepOn: false,
        },
        filterModel: {
            items: []
        }
    },
    employee: defaultEmployeeColumnsAndFilters,
    employee_mamidim: defaultEmployeeColumnsAndFilters,
    employee_professionals: defaultEmployeeColumnsAndFilters,
    employee_applications: defaultEmployeeColumnsAndFilters,
    setColumns: action((state, payload) => {
        const grid = payload.grid;
        if (!state[grid])
            state[grid] = {}
        state[grid]["columnsVisibility"] = payload.model;
    }),
    setFilters: action((state, payload) => {
        const grid = payload.grid;
        if (!state[grid])
            state[grid] = {}
        state[grid]["filterModel"] = payload.model;
    }),
    setDbState: action((state, payload) => {
        state.dbState = payload;
    }),
    setDbStatePerGrid: action((state, payload) => {
        const grid = payload.grid;
        if (!state.dbState)
            state.dbState = {};
        state.dbState[grid] = payload.model;
    }),
    setGrid: action((state, payload) => {
        const grid = payload.grid;
        state[grid] = payload.model;
    }),
    currentView: {
    },
    setCurrentView: action((state, payload) => {
        const { data, grid } = payload;
        state.currentView[grid] = data;
    }),
    dataGridViews: {

    },
    DBDataGridViews: [],
    setDataGridView: action((state, payload) => {
        const { data, grid } = payload;
        state.dataGridViews[grid] = data;
    }),
    setDBDataGridViews: action((state, payload) => {
        state.DBDataGridViews = payload;
    }),
};
export default defaultStore;