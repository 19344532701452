import React, { forwardRef, useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogTitle, MenuItem, Typography, Menu
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MoreVert from "@mui/icons-material/MoreVert";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LockIcon from "@mui/icons-material/Lock";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { TreeItem, TreeView } from "@mui/lab";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import {
    deleteDocumentTreeNode, uploadFile, getDocumentTreeStructure, editFileInfo, saveFolder
} from "../../../../api/cms";
import AddFile from "../Dialog/AddFile";
import AddFolder from "../Dialog/AddFolder";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
import usePermission from "../../../../hooks/usePermission";
import { authorization } from "../../../../_services/authorization.service"
import { strings } from "../../../../_helpers/strings"
import { arrays } from "../../../../_helpers/arrays"
import {getFileExtension} from '../../../../_helpers/file'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    InsertDriveFileIcon: forwardRef((props, ref) => (
        <InsertDriveFileIcon {...props} ref={ref} />
    )),
};

export const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        backgroundColor: "#FFF",
        "&:hover > $content": {
            backgroundColor: 'none',// theme.palette.action.hover,
        },
        "&:focus > $content, &$selected > $content": {
            backgroundColor: 'none',//`var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: "var(--tree-view-color)",
        },
        "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
            backgroundColor: 'none',//"transparent",
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "$expanded > &": {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        "& $content": {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: "inherit",
        color: "inherit",
    },
    labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: "inherit",
        flexGrow: 1,
    },
}));

export default function Document(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [itemSelected, setItemSelected] = useState("");
    const [nodeSelected, setNodeSelected] = useState(null);
    const [xPos, setXPos] = useState("0px");
    const [yPos, setYPos] = useState("0px");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const showMenu = Boolean(anchorEl);

    const [addFolderIsOpen, setAddFolderIsOpen] = useState(false);
    const [addFolderIsCreate, setAddFolderIsCreate] = useState("create");

    const [addFileIsOpen, setAddFileIsOpen] = useState(false);
    const [addFileIsCreate, setAddFileIsCreate] = useState("create");
    const [dlgDeleteFile, setDlgDeleteFile] = useState(false);
    const exploringType = useStoreState((state) => state.exploringType);
    const exploringId = useStoreState((state) => state.exploringId);

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    const treeData = useStoreState((state) => state.clients.treeData);
    const setTreeData = useStoreActions((actions) => actions.clients.setTreeData);
    var formData = "";
    useEffect(() => {
    }, []);

    const resetData = () => {
        if (props.clientData == null) return;
        getDocumentTreeStructure(props.clientData.contactId).then((value) => {
            if (value != null)
                setTreeData(value);
        });
    };
    const { canEdit: userCanEditGeneral } = usePermission(global.permissions.clients.documents.general);//authorization.userHasRole(documentsPermissions.canEdit)

    const renderTree = (parentNode) => (

        <StyledTreeItem
            key={parentNode.id}
            nodeId={parentNode.id}
            labelText={parentNode.name}
            labelIcon={parentNode.type === "file" ? InsertDriveFileIcon : FolderIcon}
            color="#1a73e8"
            bgColor="#e8f0fe"
            node={parentNode}
        >
            {Array.isArray(parentNode.children)
                ? parentNode.children.map((childNode) => renderTree(childNode))
                : null}
        </StyledTreeItem>
        /*<TreeItem key={parentNode.id} nodeId={parentNode.id} label={parentNode.name}>                                                                                                                                                                                                                                                </TreeItem>*/
    );

    const combinePermissions = (permArr) => {
        let permissions = {
            rolesCanCreate: [],
            rolesCanRead: [],
            rolesCanView: [],
            rolesCanEdit: [],
            rolesCanDelete: [],
        }
        if (Array.isArray(permArr)) {
            for (const obj of permArr) {
                permissions.rolesCanCreate = arrays.mergeArrRemoveDuplicates(permissions.rolesCanCreate, obj.rolesCanCreate);
                permissions.rolesCanRead = arrays.mergeArrRemoveDuplicates(permissions.rolesCanRead, obj.rolesCanRead);
                permissions.rolesCanView = arrays.mergeArrRemoveDuplicates(permissions.rolesCanView, obj.rolesCanView);
                permissions.rolesCanEdit = arrays.mergeArrRemoveDuplicates(permissions.rolesCanEdit, obj.rolesCanEdit);
                permissions.rolesCanDelete = arrays.mergeArrRemoveDuplicates(permissions.rolesCanDelete, obj.rolesCanDelete);
            }
        }
        return permissions;
    }

    function StyledTreeItem(props) {
        const classes = useTreeItemStyles();
        const {
            nodeId,
            labelText,
            labelIcon: LabelIcon,
            labelInfo,
            color,
            bgColor,
            node,
            ...other
        } = props;

        const handleMoreVertClick = (event) => {
            setXPos(event.pageX - 10);
            setYPos(event.pageY - 10)
            setAnchorEl(event.currentTarget);
            setItemSelected(nodeId);
            setNodeSelected(node);
        };

        const permissions = combinePermissions(node.inheritedPermissions?.map(p => strings.firstLetterLowerCase(p)).map(property => global.permissions.clients.documents[property]));
        const { canEdit } = usePermission(permissions)

        return (
            <TreeItem
                label={
                    <div className={classes.labelRoot}>
                        {
                            //props.node.isRestricted ? <LockIcon color="disabled" className={classes.labelIcon}/> :
                            <LabelIcon color={props.node.isRestricted ? 'disabled' : 'inherit'} className={classes.labelIcon} />
                        }
                        <Typography variant="body2" className={classes.labelText} color={props.node.isRestricted ? 'disabled' : 'inherit'}>
                            {labelText}
                            {props.node.isRestricted ?
                                <>
                                    <span>{" "}</span>
                                    <LockIcon color="disabled" fontSize={'small'} className={classes.labelIcon} />
                                </>
                                : null}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                        <MoreVert
                            color={canEdit || node.type === "file" ? 'inherit' : 'disabled'}
                            onClick={canEdit || node.type === "file" ? handleMoreVertClick : () => { }}
                        />

                    </div>
                }
                node={node}
                key={nodeId}
                nodeId={nodeId}
                onLabelClick={_handleSingleItemClick}
                style={{
                    "--tree-view-color": color,
                    "--tree-view-bg-color": bgColor,
                    paddingLeft: 20,
                }}
                classes={{
                    root: classes.root,
                    content: classes.content,
                    expanded: classes.expanded,
                    selected: classes.selected,
                    group: classes.group,
                    label: classes.label,
                }}
                //onContextMenu={(event) => {
                //    setItemSelected(nodeId);
                //    setNodeSelected(node);
                //    event.preventDefault();
                //    event.stopPropagation();
                //    rightClickMenuPopup(event);
                //}}
                {...other}
            />
        );
    }

    StyledTreeItem.propTypes = {
        bgColor: PropTypes.string,
        color: PropTypes.string,
        labelIcon: PropTypes.elementType.isRequired,
        labelInfo: PropTypes.string,
        labelText: PropTypes.string.isRequired,
    };

    const _handleTreeSelection = (event, value) => {
        event.preventDefault();
        setItemSelected(value);
    };
    const _handleSingleItemClick = (event) => {
        event.preventDefault();
    };

    function downloadDocument(node) {
        if (node.type !== "file" || !node.primaryId)
            return;
        global.fetchWrapper.downloadFile(node.primaryId)
    }
    function openDocumentInBrowser(node) {
        if (node.type !== "file" || !node.primaryId)
            return;
        global.fetchWrapper.readFileInNewTab(node.primaryId);
    }

    const CustomMenuItem = ({ content, startIcon, onClick, style }) => (
        //<li
        //    style={{
        //        cursor: "pointer",
        //        borderBottom: "1px solid rgba(1,1,1,0.12)",
        //        paddingLeft: 12,
        //        paddingRight: 12,
        //        paddingTop: 0,
        //        paddingBottom: 0,
        //        fontSize: 14,
        //    }}
        //>
        <MenuItem
            color="default"
            className={useStyles().Menubutton}
            startIcon={startIcon}
            onClick={onClick}
            style={style}
        >
            {content}
            <span style={{marginLeft: 'auto'}}>
                {startIcon}
            </span>
        </MenuItem>
        //</li>
    );

    const GetMenuItems = (handleClose) => {
        const permissions = combinePermissions(nodeSelected?.inheritedPermissions?.map(p => strings.firstLetterLowerCase(p)).map(property => global.permissions.clients.documents[property]));
        const { canEdit } = usePermission(permissions)
        return !nodeSelected ? <><></></> : nodeSelected.type === "folder" ? (
            <>
                {
                    !nodeSelected.isSystemFolder &&
                    <CustomMenuItem
                        content={"Edit Name"}
                        onClick={() => {
                            setAddFolderIsCreate("edit");
                            setAddFolderIsOpen(true);
                            handleClose();
                        }}
                    />
                }
                <CustomMenuItem
                    content={"New Folder"}
                    onClick={() => {
                        setAddFolderIsCreate("create");
                        setAddFolderIsOpen(true);
                        handleClose();

                    }}
                />
                <CustomMenuItem
                    content={"New File"}
                    onClick={() => {
                        setAddFileIsCreate("create");
                        setAddFileIsOpen(true);
                        handleClose();
                    }}
                />
                {
                    !nodeSelected.isSystemFolder &&
                    <CustomMenuItem
                        content={"Delete"}
                        onClick={() => {
                            setAddFolderIsCreate("delete");
                            setDlgDeleteFile(true);
                            handleClose();
                        }}
                        style={{ color: "red" }}
                    />
                }
            </>
        ) : (
                <>
                    {getFileExtension(nodeSelected.name) === 'pdf' &&
                        <CustomMenuItem
                            content={'Open'}
                        startIcon={<OpenInNewIcon />}
                            onClick={() => {
                                //setAddFileIsCreate("edit");
                                //setAddFileIsOpen(true);
                                openDocumentInBrowser(nodeSelected);
                                handleClose();
                            }}
                        />
                    }

                    <CustomMenuItem
                    content={'Download'}
                    onClick={() => {
                        //setAddFileIsCreate("edit");
                        //setAddFileIsOpen(true);
                        downloadDocument(nodeSelected);
                        handleClose();
                    }}
                />
                {canEdit &&
                    (<>
                        <CustomMenuItem
                            content={"Edit Filename"}
                            onClick={() => {
                                setAddFileIsCreate("edit");
                                setAddFileIsOpen(true);
                                handleClose();
                            }}
                        />
                        <CustomMenuItem
                            content={"Delete"}
                            onClick={() => {
                                setAddFileIsCreate("delete");
                                setDlgDeleteFile(true);
                                handleClose();
                            }}
                            style={{ color: "red" }}
                        />
                    </>)
                }
            </>
        )
    }

    const FolderMenu = ({ open, handleClose, xPos, yPos }) => {
        const MenuItems = GetMenuItems(handleClose);

        // set position of menu in case screen is not perfectly sized
        if (MenuItems.props.children && MenuItems.props.children.length > 0) {
            const itemHeight = 23.99 + 12;
            const menuTotalPadding = 16;
            const itemsCt = MenuItems.props.children?.filter(c => c).length; // filtering only non null children
            const menuTotal = (itemHeight * itemsCt) + menuTotalPadding;
            const bottomOfMenu = menuTotal + yPos;
            const menuToBottomSum = window.innerHeight - bottomOfMenu;
            global.log("menuToBottomSum", menuToBottomSum);
            if (menuToBottomSum < 100)
                yPos = yPos - (menuTotal + 15);
        }
        return (
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    top: yPos,
                    //bottom: yPos,
                    left: xPos,
                    position: "absolute",
                    overflow: "show",
                    //inset: "250px, 0px, 0px, 877px"
                }}
                PopoverClasses={"paper"}
            >
                {MenuItems}
            </Menu >
        )
    }

    const addNewFolder = () => {
        const tempTree = cloneDeep(treeData);

        tempTree.push({
            id: "11",
            name: "new folder",
            type: "folder",
        });

        setTreeData(tempTree);
    };

    function findNode(nodeId, name, node, newNodeId, type) {
        if (node.id == nodeId) {
            if (nodeSelected.type == "folder" && addFolderIsCreate == "edit") {
                node.name = name;
                saveFolder(props.clientData.contactId, node, formData);
                setAddFileIsCreate("create");
                setAddFolderIsCreate("create");
                return;
            }
            if (nodeSelected.type == "file" && addFileIsCreate == "edit") {
                node.name = name;
                editFileInfo(props.clientData.contactId, node, formData);
                setAddFileIsCreate("create");
                setAddFolderIsCreate("create");
                return;
            }
            if ((nodeSelected.type == "file" && addFileIsCreate === "delete") || (nodeSelected.type == "folder" && addFolderIsCreate === "delete")) {
                deleteDocumentTreeNode(node).then((response) => {
                    resetData();
                    setAddFileIsCreate("create");
                    setAddFolderIsCreate("create");
                });
                /*delete node.id;
                delete node.name;
                delete node.type;
                delete node.children;
                delete node.parentFolderId;
                delete node.primaryId;
                node = undefined;*/
                return;
            }

            if (type == "folder") {
                var new_node = {
                    primaryId: -1, //newNodeId + (node.children.length + 1),
                    parentFolderId: node.primaryId,
                    name: name,
                    type: type,
                    children: [],
                };
                saveFolder(
                    props.clientData.contactId,
                    new_node,
                    formData
                ).then((response) => {
                    resetData();
                    setAddFileIsCreate("create");
                    setAddFolderIsCreate("create");
                });
            } else {
                const payload = {
                    primaryId: props.clientData.contactId,
                    fileBytes: formData == null ? "" : formData,
                    name: name,
                    description: "",
                    fileTemplateId: null,
                    folderId: node.primaryId,
                    createdBy_UserId: global.account.userValue.id
                };

                uploadFile(payload).then((response) => {
                    resetData();
                    setAddFileIsCreate("create");
                    setAddFolderIsCreate("create");
                });
            }
        } else if (node.type == "folder" && node.children) {
            for (var i = 0; i < node.children.length; i++) {
                findNode(nodeId, name, node.children[i], newNodeId + node.id, type);
            }
        }
    }

    const SaveFolder = (name) => {
        const tempTree = cloneDeep(treeData);
        findNode(itemSelected, name, tempTree, itemSelected, "folder");
        setTreeData(tempTree);
    };

    const SaveFile = (name, file) => {
        formData = file;

        const tempTree = cloneDeep(treeData);
        findNode(itemSelected, name, tempTree, itemSelected, "file");
        setTreeData(tempTree);
    };

    // Save File Previous function
    const SaveFileOld = (name, file) => {
        formData = new FormData();
        if (file != null) formData.append("file", file, file.name);

        const tempTree = cloneDeep(treeData);
        findNode(itemSelected, name, tempTree, itemSelected, "file");
        setTreeData(tempTree);
    };

    function deleteNode(node) {
        for (var i = 0; i < node.children.length; i++) {
            if (
                node.children[i] == null ||
                node.children[i] == undefined ||
                Object.keys(node.children[i]).length == 0
            ) {
                node.children.splice(i, 1);
                return;
            } else deleteNode(node.children[i]);
        }
    }

    const DeleteFile = () => {
        const tempTree = cloneDeep(treeData);
        findNode(itemSelected, "", tempTree, itemSelected, "file");
        /*const strTree = JSON.stringify(tempTree);
        const newTempTree = JSON.parse(strTree);
        deleteNode(newTempTree);
        setTreeData(newTempTree);*/
    };
    const hanldeNewFolderButtonClick = () => {
        setItemSelected(treeData.id)
        setNodeSelected(treeData)
        setAddFolderIsOpen(true)
    }

    return (
        <>
            <div className={classes.document} style={{ width: "50%" }} >
                <div style={{ alignContent: "right", marginBottom: "10px", textAlign: "right" }}>
                    <Button
                        variant="text"
                        onClick={hanldeNewFolderButtonClick}
                        color="primary"
                        disabled={!userCanEditGeneral}
                    >

                        <b style={{ fontSize: "14px", paddingRight: "4px", color: "#1976d2" }}>
                            {"Add Folder  "}</b> <AddRoundedIcon fontSize="small" style={{ color: "#1976d2" }} />
                    </Button>
                </div>

                <Card style={{ maxHeight: "58vh", overflow: "auto" }}>
                    <CardContent className={baseClasses.document_card_content}>
                        <div className={baseClasses.row}>
                            <div className="col-md-12">
                                <TreeView
                                    className={classes.root}
                                    defaultExpanded={["1"]}
                                    defaultCollapseIcon={<ArrowDropDownIcon />}
                                    defaultExpandIcon={<ArrowRightIcon />}
                                    onNodeSelect={_handleTreeSelection}
                                    defaultEndIcon={<div style={{ width: 24 }} />}
                                >
                                    {
                                        //renderTree(treeData)
                                        treeData.children && Array.isArray(treeData.children) && treeData.children.length > 0 ?
                                            treeData.children.map((child) => renderTree(child))
                                            : <div style={{ textAlign: "center" }}>No Folders</div>
                                    }
                                </TreeView>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <FolderMenu
                    open={showMenu}
                    handleClose={() => {
                        setAnchorEl(null);
                        //setShowMenu(false)
                    }}
                    xPos={xPos}
                    yPos={yPos}
                />
                <AddFolder
                    editData={nodeSelected}
                    ctlType={addFolderIsCreate}
                    isOpenModal={addFolderIsOpen}
                    onCloseModal={() => {
                        setAddFolderIsOpen(false);
                    }}
                    onSaveData={(name) => {
                        SaveFolder(name);
                        setAddFolderIsOpen(false);
                    }}
                ></AddFolder>
                <AddFile
                    isOpenModal={addFileIsOpen}
                    editData={nodeSelected}
                    ctlType={addFileIsCreate}
                    onCloseModal={() => {
                        setAddFileIsOpen(false);
                    }}
                    onSaveData={(name, file) => {
                        SaveFile(name, file);
                        setAddFileIsOpen(false);
                    }}
                ></AddFile>
                <Dialog
                    fullScreen={false}
                    maxWidth={"sm"}
                    open={dlgDeleteFile}
                    onClose={() => {
                        setDlgDeleteFile(false);
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure you want to delete this file " +
                            (nodeSelected ? nodeSelected.name : "") +
                            "?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={() => {
                                DeleteFile();
                                setDlgDeleteFile(false);
                            }}
                            color="primary"
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={() => {
                                setDlgDeleteFile(false);
                            }}
                            color="primary"
                            autoFocus
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
