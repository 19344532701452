import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import useBaseStyles from '../../../../assets/styles';
import { tabsConst } from '../../../../constants';
import AllProgress from './AllProgress';
import MonthlyProgress from './MonthlyProgress';
import Mission from './Mission'
import { ChipTabProps, ProgressPropsType } from './types'
import ChipTab from '../../../common/Tabs/ChipTab/ChipTab';
import usePermission from '../../../../hooks/usePermission';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const Progress: React.FC<ProgressPropsType> = ({ handleAddButton, handleEditData, onDelete, rowsAndCols, progressNoteEditData, setProgressPage, templateNotes, setTemplateNotes }) => {
    const baseClasses: any = useBaseStyles();
    const [value, setValue] = useState<string>('all');
    const glob: any = global;
    const monthlyPermissions = usePermission(glob.permissions.clients.progress.monthly);

    useEffect(() => {
        setProgressPage(value)
    }, [value])

    /**
     * 
     * @param event 
     * @param newValue 
     * @returns 
     */
    const handleChange = (event: any, newValue: string) => {
        if (newValue === value) {
            return
        }
        setValue(newValue)
    }

    const setRowAndTab = (index: number) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            "aria-controls": `scrollable-auto-tabpanel-${index}`,
        };
    };

    return (
        <div>
            <Card variant="outlined">
                <CardContent>
                    <AppBar
                        position="static"
                        elevation={0}
                        className={baseClasses.appBarWhite}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <ChipTab
                                value={tabsConst.all}
                                activeTab={value}
                                label="All"
                                {...setRowAndTab(0)}
                            />
                            {monthlyPermissions.canView && 
                                <ChipTab
                                    value={tabsConst.monthly}
                                    label="Monthly"
                                    activeTab={value}
                                    {...setRowAndTab(1)}
                                />
                            }

                        </Tabs>
                    </AppBar>
                    <TabPanel
                        value={value}
                        index={tabsConst.all}
                        style={{ backgroundColor: (theme: any) => theme.palette.background.paper }}
                        className={baseClasses.tabRoot}
                    >
                        <AllProgress
                            handleAddButton={handleAddButton}
                            handleEditData={handleEditData}
                            onDelete={(params: any) => { onDelete(params) }}
                            rowsAndCols={rowsAndCols}
                        />
                    </TabPanel>
                    {monthlyPermissions.canView && 
                        <TabPanel
                            value={value}
                            index={tabsConst.monthly}
                            style={{ backgroundColor: (theme: any) => theme.palette.background.paper }}
                            className={baseClasses.tabRoot}
                        >
                            <MonthlyProgress
                                progressNoteEditData={progressNoteEditData}
                                handleAddButton={handleAddButton}
                                handleEditData={handleEditData}
                                templateNotes={templateNotes}
                                setTemplateNotes={setTemplateNotes}
                            />

                        </TabPanel>
                    }

                </CardContent>
            </Card>
        </div>
    )
}

export default Progress