import * as React from "react";
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
// import useStyles from "./data_table_styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { columnsEmployees } from "../../api/employees";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@mui/material";

/**
 *
 * @param {label, onFileChange} props
 * @display {Label, Name, Upload Document control, Document Icon}
 */

export default function DocumentUpload(props) {
  //   const classes = useStyles();
    const { setFileExt } = props;
  const [note, setNote] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState("Upload your file");

  function getB64Str(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

  return (
        <div className="input-group">
          {/* <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroupFileAddon01">
              Upload
            </span>
          </div> */}
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              name="file"
                  onChange={(e) => {
                      const fileName = e.target.files[0].name;
                      setSelectedFile(fileName);
                      if (typeof setFileExt === 'function')
                          setFileExt(fileName.substring(fileName.lastIndexOf('.')))
                      if (e.target.files.length == 0)
                          props.onFileChange(props.label, "");
                      else {
                          var reader = new FileReader();
                          reader.onload = function (event) {
                              props.onFileChange(props.label, getB64Str(event.target.result));
                          };
                          reader.readAsArrayBuffer(e.target.files[0]);
                      }
                  }}
            />
            <label className="custom-file-label" htmlFor="inputGroupFile01">
              {selectedFile}
            </label>
          </div>
        
        {/* <Grid item xs={6}>
                                                            <div className={classes.uploadText}>Document upload</div>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                variant="contained"
                                                                component="label">
                                                                Upload File
                                                               <input type="file" hidden />
                                                            </Button>
                                                        </Grid>*/}
      {/* 
      <Grid item xs={12} className={classes.document_upload}>
        <div className="input-group">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              name="supportDocument"
              onChange={(e) =>
                setSelectedFile({
                  ...selectedFile,
                  [e.target.name]: e.target.files[0].name,
                })
              }
              aria-describedby="inputGroupFileAddon01"
            />
            <label className="custom-file-label" htmlFor="inputGroupFile01">
              {selectedFile.supportDocument}
            </label>
          </div>
        </div>
      </Grid> */}
      </div>
  );
}
