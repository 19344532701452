import * as React from 'react';
import { useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import _ from 'lodash';
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useStoreActions, useStoreState } from "easy-peasy";
import clsx from 'clsx';
import useStyles, {TitleContainer, Title, DateSection, Status, NotesContainer, Note, Icon} from './styles';
import useBaseStyles from '../../../../assets/styles';
import HebrewMonthSwitcher from '../../../common/DateSwitchers/HebrewMonthSwitcher';
import {templateNotes as templateNotesConst, CLIENT_MISSION_SERVICE_MONTHLY_OBJECTIVES, MONTHLY_OBJECTIVE_STANDING_OPTIONS} from '../../../../constants';
import {dateTime, getMonthRangeFromHebrew} from '../../../../_helpers/datetime';
import { getTemplateNotes, getClientMissionServiceMonthlyObjective  } from '../../../../api/clients';
import { TemplateNoteType, MonthlyPropsType, HebMonthYearType} from './types';
import { clientMonthlyServiceObjectiveType, clientMonthlyServiceType } from '../../../../assets/types';
import LoadingBar from '../../../LoadingBar/LoadingBar'
import useFetcher from '../../../../hooks/useFetcher'
import TextFormattingWrapper from '../../../../utils/TextFormattingWrapper';
import { strings } from '../../../../_helpers/strings';
import { HDate } from '@hebcal/core';
import {DivContainer, FlexBetween, Block, TextButton, FlexContainer, Text, VerticalDivider, BoxContainer, FlexExactCenter} from '../../../../assets/styles/styledComponents';
import EditMonthlyObjective from '../Dialog/EditMonthlyObjective';
import usePermission from '../../../../hooks/usePermission';
import { hebrewMonthNames } from '../../../../_helpers/hebrewDate/constants';

type MonthlyMissionObjectiveProps = {
    clientServiceMonths: clientMonthlyServiceType[],
    handleManageService: Function,
    isLoading: boolean
}

const MonthlyMissionObjective: React.FC<MonthlyMissionObjectiveProps> = (props) => {
    const { clientServiceMonths, handleManageService, isLoading } = props;
    const classes: any = useStyles();
    const baseClasses: any = useBaseStyles();
    return (
        <Card sx={{
            minHeight: 500
        }}>
            {isLoading && <LoadingBar/>}
            {clientServiceMonths.length > 0 ?
                clientServiceMonths.map((data:clientMonthlyServiceType) => {
                    return (
                        <DivContainer padding={10}>
                            <BoxContainer>
                                <FlexBetween>
                                    <Block>{data.providerName}</Block>
                                    <TextButton color="primary" onClick={() => handleManageService(data)}>Manage</TextButton>
                                </FlexBetween>
                                {
                                    data.objectives.length ? 
                                        data.objectives.map((obj:clientMonthlyServiceObjectiveType) => {
                                            return (
                                                <BoxContainer>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={5.5}>
                                                            <FlexContainer>
                                                                <Text width="200"><span className={classes.textBold}> Plan Item:</span>
                                                                    <TextFormattingWrapper
                                                                        value={obj.objectiveName} 
                                                                    />
                                                                    (
                                                                        <span className={classes.textBold}>Mission:</span>
                                                                            <TextFormattingWrapper
                                                                                value={obj.missionName}
                                                                            />
                                                                    )
                                                                </Text>
                                                            </FlexContainer>
                                                            <FlexContainer>
                                                                <Text width="200"> <span className={classes.textBold}>Details:</span>
                                                                    <TextFormattingWrapper
                                                                        value={obj.planNote}
                                                                    />
                                                                </Text>
                                                            </FlexContainer>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <FlexExactCenter>
                                                                <VerticalDivider/>
                                                            </FlexExactCenter>
                                                        </Grid>
                                                        <Grid item xs={5.5}>
                                                            <FlexContainer>
                                                                <Text><span className={classes.textBold}>Standing: </span>{MONTHLY_OBJECTIVE_STANDING_OPTIONS.find(x => x.value === obj.standing)?.label}</Text>
                                                            </FlexContainer>
                                                            <FlexContainer>
                                                                <Text> <span className={classes.textBold}>Details: </span>
                                                                    <TextFormattingWrapper
                                                                        value={obj.outcomeNote}
                                                                    />
                                                                </Text>
                                                            </FlexContainer>
                                                        </Grid>
                                                    </Grid>
                                                </BoxContainer>
                                            )
                                        }):
                                        <BoxContainer>
                                            <FlexExactCenter minHeight={100}>
                                                <Typography className={baseClasses.no__data__typgoraphy}>
                                                    No Goals Yet
                                                </Typography>
                                            </FlexExactCenter>
                                        </BoxContainer>

                                }
                            </BoxContainer>
                        </DivContainer>
                    )
                }) : 
                <BoxContainer>
                    <FlexExactCenter minHeight={100}>
                        <Typography className={baseClasses.no__data__typgoraphy}>
                            No Active Schedules for this month
                        </Typography>
                    </FlexExactCenter>
                </BoxContainer>
            }
        </Card>
    )
} 

const clientServiceMonths = [
    {
        "clientServiceMonthId": 0,
        "providerName": "Chaim Brown",
        "providerId": 0,
        "objectives": [
            {
                "objectiveId": 0,
                "objectiveName": "Some Objective",
                "missionName": "Some Mission",
                "planNote": "Some note",
                "standing": "Achieved",
                "outcomeNote": "Some note"
            }
        ]
    },
    {
        "clientServiceMonthId": 0,
        "providerName": "Chaim Brown",
        "providerId": 0,
        "objectives": []
    },
    {
        "clientServiceMonthId": 0,
        "providerName": "Chaim Brown",
        "providerId": 0,
        "objectives": [
            {
                "objectiveId": 0,
                "objectiveName": "Some Objective",
                "missionName": "Some Mission",
                "planNote": "Some note",
                "standing": "Achieved",
                "outcomeNote": "Some note"
            }
        ]
    }
]

const MonthlyProgress: React.FC<MonthlyPropsType> = ({ handleAddButton, handleEditData, progressNoteEditData, templateNotes, setTemplateNotes}) => {
    const classes : any = useStyles();
    const baseClasses: any = useBaseStyles();
    const [isLoading, setLoading] = React.useState <boolean>(false);
    const [dateRange, setDateRange] = React.useState<any>();
    const [editMonthlyObjective, setEditMonthlyObjective] = React.useState<clientMonthlyServiceType | null>(null);
    const [clientMonthlyServiceData,  setClientMonthlyServiceData] = React.useState<clientMonthlyServiceType[]>([]);
    const exploringId = useStoreState((state:any) => state.exploringId);

    const glob: any = global;
    const goalsPermission = usePermission(glob.permissions.clients.progress.monthly.mission)

    const loadMonthlyServiceObjective = (date:string | Date) => {
        setLoading(true);
        getClientMissionServiceMonthlyObjective(exploringId, date).then((data:clientMonthlyServiceType[]) => {
            setLoading(false)
            setClientMonthlyServiceData(data)
        })
    };

    useEffect(() => {
        // Handling Adding and Editing Monthly Notes
        if(progressNoteEditData) {
            let templateNotesTemp: TemplateNoteType[] = [];
            if (templateNotes.some((note:TemplateNoteType) => note.noteTemplateId === progressNoteEditData.noteTemplateId)) {
                templateNotesTemp = templateNotes?.map((note:any) => {
                    return note.noteTemplateId === progressNoteEditData.noteTemplateId ? progressNoteEditData:note
                })
                setTemplateNotes(templateNotesTemp)
            } else {
                setTemplateNotes([...templateNotes, progressNoteEditData])
            }
        }
    }, [progressNoteEditData])

    const getMonthlyProgressNotes = (slcHebMoYr: HebMonthYearType) => {
        const { toDate, fromDate } = getMonthRangeFromHebrew(slcHebMoYr);
        setDateRange({
            toDate: toDate,
            fromDate: fromDate
        })
        loadMonthlyServiceObjective(fromDate);
        setLoading(true);
        getTemplateNotes({dateTo: toDate, dateFrom: fromDate}, "1,2,3,4,5", exploringId).then((data) => {
            let templateNotesTemp = templateNotesConst?.map((note:any) => {
                let dataPoint = data.find((dataItem:any) => {
                   return note.noteTemplateId === dataItem.noteTemplateId
                })
                if(dataPoint) {
                    return {
                        ...dataPoint,
                        title: note.title,
                        NoteDescription: dataPoint.noteDescription,
                        noteDate: dataPoint.noteDate,
                    }
                }
            })
            setTemplateNotes(_.compact(templateNotesTemp));
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    };

    const renderMonthlyNotes = (notesTemplate: TemplateNoteType[]) => {
        return notesTemplate.map((note:TemplateNoteType ) => {
            const templateNote: TemplateNoteType | undefined = templateNotes?.find((dataItem:any) => {
                return note.noteTemplateId === dataItem.noteTemplateId
             })
            return (
                <Card className={classes.mr10}>
                    <CardContent classes={{ root: classes.cardPadding }}>
                        <TitleContainer>
                            <Title completed={!!templateNote} >
                                <TextFormattingWrapper
                                    value={note.title}
                                />
                            </Title>
                            <DateSection>
                                {
                                    dateTime.getUSAFormattedDateString(templateNote?.noteDate)
                                }
                            </DateSection>
                        </TitleContainer>
                        <NotesContainer>
                            <Note>
                                <div>
                                    <TextFormattingWrapper
                                        value={templateNote?.noteDescription}
                                    />
                                </div>
                                {strings.getRichTextObjFromString(templateNote?.nextStep)?.value &&
                                    <div>
                                    { "Next Step: "}
                                        <TextFormattingWrapper
                                            value={templateNote?.nextStep}
                                        />
                                    </div>
                                }
                            </Note>
                            <Icon>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => {
                                        handleEditData({
                                            ...templateNote,
                                            title: note.title,
                                            noteTemplateId: note.noteTemplateId
                                        }, dateRange)
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Icon>
                        </NotesContainer>

                    </CardContent>
                </Card>
            )
        })
    };

    const handleManageClientService = (clientService: clientMonthlyServiceType) => {
        setEditMonthlyObjective(clientService);
    };

    const handleCloseMonthlyServiceModal = (data: any) => {
        if (!!data) {
            let services = clientMonthlyServiceData.map((service: clientMonthlyServiceType) => {
                return service.clientServiceId === data.currentMonth.clientServiceId ? data.currentMonth : service
            })
            setClientMonthlyServiceData(services);
        }
        setEditMonthlyObjective(null);
    };

  return (
      <div>
        <Card className={classes.contentCenter}>
            <CardContent classes={{ root: classes.cardPadding }}>
                <HebrewMonthSwitcher
                      from={{
                          month: hebrewMonthNames[11].month,
                          year: 5781,
                      }}
                      to={{
                          month: new HDate().getTishreiMonth(),
                          year:new HDate().getFullYear(),
                      }}
                    onChange={getMonthlyProgressNotes}
                    isLoading = {isLoading}
                />
            </CardContent>
        </Card>  
        <div
            className={baseClasses.row}
            style={{ paddingTop: 9, marginTop: 4 }}
            >
                <div className="w-100">
                    <Card className={classes.root}>
                        <CardHeader
                            color="primary"
                            className={classes.cardHeader}
                            subheader= ""
                        >
                        </CardHeader>

                        <CardContent
                            className={clsx(baseClasses.row, "row", classes.cardSpaceStyle)}
                        >
                            <Grid container spacing={1}>
                                <Grid xs={8}>
                                    <Card>
                                        <CardHeader
                                        color="primary"
                                        className={classes.cardHeader}
                                        subheader= "Goals"
                                      />

                                      <CardContent>
                                          {goalsPermission.canView ? 
                                              <MonthlyMissionObjective
                                                  clientServiceMonths={clientMonthlyServiceData}
                                                  handleManageService={handleManageClientService}
                                                  isLoading={isLoading}
                                              /> :
                                              <Card
                                                  className={classes.mr10}
                                                  style={{ height: '450px' }}
                                              >
                                                  <Typography>
                                                      Coming soon...
                                                  </Typography>
                                              </Card>
                                          }

                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid xs={4}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                        color="primary"
                                        className={classes.cardHeader}
                                        subheader= "Task"
                                        />
                                        {renderMonthlyNotes(templateNotesConst)}
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions></CardActions>
                    </Card>
                </div>
        </div>
        <EditMonthlyObjective
            open={!!editMonthlyObjective}
            clientMonthlyService={editMonthlyObjective}
            onCloseModal={handleCloseMonthlyServiceModal}
            hebMonthYear={dateRange?.toDate}
        /> 
      </div>
  )
}

export default MonthlyProgress