import { ErrorMessagesType, IValidator } from './shared'
import { dateTime as dateTimeHelper } from '../../datetime';
import { daysOfWeek } from '../../datetime';
import { numbers as numbersHelper } from '../../numbers';
import SCHEDULE_PERIODICITY_CONSTANTS from '../../constants/schedule_periodicity_constants';
import { periodicities } from '../../constants/scheduleConstants';
import { Schedule, ScheduleTimeFilterModel } from '../shared/types';
import { getNextSession } from '../../../_helpers/schedule';
import { forEach } from 'lodash';
import { dateTime } from '../../datetime'

type DateRangeOptionsType = {
    requireStartDate?: boolean,
    requireEndDate?: boolean,
    requireStartTime?: boolean,
    requireEndTime?: boolean,
}

export class DateRangeValidator implements IValidator {
    date: any;
    endDate: any;
    requireStartDate: boolean;
    requireEndDate: boolean;
    isValid: boolean = true;
    fieldMessages: ErrorMessagesType = {
        date: null,
        endDate: null,
    };

    constructor(date: any, endDate: any, { requireStartDate = true, requireEndDate = false }: DateRangeOptionsType) {
        this.date = date;
        this.endDate = endDate;
        this.requireStartDate = requireStartDate;
        this.requireEndDate = requireEndDate;
    }

    validate() {
        if (this.validateStartDate() && this.validateEndDate()) {
            this.validateStartDateBeforeEndDate();
        }

        return this.isValid;
    }

    addErrorMessage(field: string, message: string) {
        this.isValid = false;
        this.fieldMessages[field] = message;
    }

    validateStartDate() {
        if (this.requireStartDate && !this.date) {
            this.addErrorMessage('date', 'Date is required for a confirmed schedule');
            return false;
        } else if (this.date && (new Date(this.date) as unknown) == 'Invalid Date') {
            this.addErrorMessage('date', 'Must be a valid date');
            return false;
        }

        return true;
    }

    validateEndDate() {
        if (this.requireEndDate && !this.endDate) {
            this.addErrorMessage('endDate', 'End Date is required for a confirmed schedule');
            return false;
        } else if (this.endDate && (this.endDate == 'Invalid Date' || (new Date(this.endDate) as unknown)) == 'Invalid Date') {
            this.addErrorMessage('endDate', 'Must be a valid date');
            return false;
        }

        return true;
    }

    validateStartDateBeforeEndDate() {
        if ((this.endDate && this.date) && (this.endDate < this.date)) {
            let errorMessage = "Start date must be before end date";
            this.addErrorMessage('date', errorMessage);
            this.addErrorMessage('endDate', errorMessage);
            return false;
        }

        return true;
    }
}

export class TimeRangeValidator implements IValidator {
    startTime: any;
    endTime: any;
    requireStartTime: boolean;
    requireEndTime: boolean;
    isValid: boolean = true;
    fieldMessages: ErrorMessagesType = {
        startTime: null,
        endTime: null,
    };

    constructor(startTime: any, endTime: any, { requireStartTime = false, requireEndTime = false }: DateRangeOptionsType) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.requireStartTime = requireStartTime;
        this.requireEndTime = requireEndTime;
    }

    validate() {
        const validStartTime = this.validateStartTime();
        const validEndTime = this.validateEndTime();
        if (validStartTime && validEndTime) {
            this.validateStartTimeBeforeEndTime();
        }

        return this.isValid;
    }

    addErrorMessage(field: string, message: string) {
        this.isValid = false;
        this.fieldMessages[field] = message;
    }

    validateStartTime() {
        if (this.requireStartTime && !this.startTime) {
            this.addErrorMessage('startTime', 'Start time is required');
            return false;
        }
        else if (this.startTime && ((new Date(this.startTime) as unknown) == 'Invalid Date')) {
            this.addErrorMessage('startTime', "A valid time is required");
            return false;
        }

        return true;
    }

    validateEndTime() {
        if (this.requireEndTime && !this.endTime) {
            this.addErrorMessage('endTime', 'End time is required');
            return false;
        }
        else if (this.endTime && ((new Date(this.endTime) as unknown) == 'Invalid Date')) {
            this.addErrorMessage('endTime', "A valid time is required");
            return false;
        }

        return true;
    }

    validateStartTimeBeforeEndTime() {
        if ((this.endTime && this.startTime) && ((new Date(this.endTime)) < (new Date(this.startTime)))) {
            let errorMessage = "Start time must be before end time";
            this.addErrorMessage('startTime', errorMessage);
            this.addErrorMessage('endTime', errorMessage);
            return false;
        }

        return true;
    }
}

export function validateDaySelections(requireSelectedDay: boolean, periodicity: string, days: any[]): IValidator {
    let validationResult = {
        isValid: true,
        fieldMessages: {
            [periodicity]: null,
        }
    } as IValidator;

    const filteredDays = days.filter(day => {
        if (typeof day === 'number') {
            return true;
        }

        if ((typeof day === 'string' || day instanceof String)) {
            return day.trim().length > 0;
        }

        return false;
    });

    if (requireSelectedDay && filteredDays.length == 0) {
        validationResult.fieldMessages[periodicity] = "At least 1 day selection is required";
        validationResult.isValid = false;
    }

    return validationResult;
}

export function validateProfessionalTypeSelection(scheduleType: string, professionalType: any): IValidator {
    const validationResult = {
        isValid: true,
        fieldMessages: {
            professionalType: null,
        },
    } as IValidator;

    if (scheduleType === 'professional' && !professionalType) {
        validationResult.isValid = false;
        validationResult.fieldMessages.professionalType = "Professional type is required";
    }

    return validationResult;
}

export function validateRatePeriod(ratePeriod: string, isConfirmedSchedule: boolean): IValidator {
    const validationResult = {
        isValid: true,
        fieldMessages: {
            ratePeriod: null,
        },
    } as IValidator;

    if (ratePeriod !== 'HOURS' && ratePeriod !== 'MONTHS' && ratePeriod !== 'SESSION' && isConfirmedSchedule) {
        validationResult.isValid = false;
        validationResult.fieldMessages.ratePeriod = "Rate period is required for confirmed schedules";
    }

    return validationResult;
}

export function validateRate(rate: number | null, isConfirmedSchedule: boolean): IValidator {
    const validationResult = {
        isValid: true,
        fieldMessages: {
            rate: null,
        },
    } as IValidator;

    if (!rate && isConfirmedSchedule) {
        validationResult.isValid = false;
        validationResult.fieldMessages.rate = "Rate is required for confirmed schedules";
    }

    return validationResult;
}

type ScheduleConflict = {
    repeating?: {
        days: number[],
        everyXOfPeriods: number,
        periodicity: string,
    },
    exampleDates?: Date[],
    clientSchedule: Schedule,
    providerSchedule: Schedule,
}

type DateTimeInterval = {
    start: Date,
    end: Date,
}

export enum ScheduleAvailabilityStatus {
    FullAvailability = "Full Availability",
    PartialAvailability = "Partial Availability",
    NoAvailability = "No Availability",
}

type ScheduleAvailabilityGap = {
    repeating?: {
        days: number[],
    },
    exampleDates?: Date[],
}

type ScheduleAvailabilityResponse = {
    availabilityStatus: ScheduleAvailabilityStatus,
    partialAvailabilityGap?: ScheduleAvailabilityGap,
    fullAvailabilityGap?: ScheduleAvailabilityGap,
}

export class ScheduleAvailabilityCalculator {
    schedulesHaveOverlappingDateTimeRanges(clientSchedule: Schedule, providerSchedule: Schedule) {
        const datesOverlap = dateTimeHelper.dateTimeRangeOverlaps(
            clientSchedule.startDate,
            clientSchedule.endDate,
            providerSchedule.startDate,
            providerSchedule.endDate,
        );

        const clientTimeInterval = {
            start: new Date(1, 0, 1, clientSchedule.startTime.getHours(), clientSchedule.startTime.getMinutes()),
            end: new Date(1, 0, 1, clientSchedule.endTime.getHours(), clientSchedule.endTime.getMinutes())
        };

        if (clientTimeInterval.start > clientTimeInterval.end) {
            clientTimeInterval.end.setDate(clientTimeInterval.start.getDate() + 1);
        }

        const providerTimeInterval = {
            start: new Date(1, 0, 1, providerSchedule.startTime.getHours(), providerSchedule.startTime.getMinutes()),
            end: new Date(1, 0, 1, providerSchedule.endTime.getHours(), providerSchedule.endTime.getMinutes())
        };

        // add another day to the date if start time appears to be after the end time
        if (providerTimeInterval.start > providerTimeInterval.end) {
            providerTimeInterval.end.setDate(providerTimeInterval.start.getDate() + 1);
        }

        // add minute to start, and remove minute from end, to avoid same time start & end to conflict
        providerTimeInterval.start.setTime(providerTimeInterval.start.getTime() + 1000 * 60);
        providerTimeInterval.end.setTime(providerTimeInterval.end.getTime() - 1000 * 60);

        const timesOverlap = dateTimeHelper.dateTimeRangeOverlaps(
            clientTimeInterval.start,
            clientTimeInterval.end,
            providerTimeInterval.start,
            providerTimeInterval.end
        );

        return datesOverlap && timesOverlap;
    }

    isDurationAtOrMoreThanXMinutes(window: DateTimeInterval, filter: ScheduleTimeFilterModel) {
        let start: Date = window.start;
        let end: Date = window.end;
        let filterStart = new Date(1, 0, 1, filter.startTime.getHours(), filter.startTime.getMinutes());
        let filterEnd = new Date(1, 0, 1, filter.endTime.getHours(), filter.endTime.getMinutes())

        if (filter.durationMins === -1) { // minutes duration not factor, but require full start to end, not just duration within it
            return start <= filterStart && end >= filterEnd; // need the window to fill the whole between-timing, not just duration of minutes within it
        }

        // if open-window is larger than the required between-timing, then shrink it to at least that window, or even smaller if it was originally smaller.
        if (start < filterStart)
            start = filterStart;
        if (end > filterEnd)
            end = filterEnd;

        // check if remaining window has that amount of minutes duration required
        let diffMinutes: number = Math.floor(((end.valueOf() - start.valueOf()) / 1000) / 60);

        return filter.durationMins <= diffMinutes;
    }

    getConflictingWindows(filter: ScheduleTimeFilterModel, providerSchedules: Schedule[], providerAvailabilities: Schedule[], potentialSchedules: Schedule[]) {
        if (filter.durationMins < 1)
            return [];
        let dayIsOpen = this.isOpenWindowDuringTimeOfDay(filter, providerSchedules, providerAvailabilities);
        let dayIsOpenWithPotentials = this.isOpenWindowDuringTimeOfDay(filter, [...providerSchedules, ...potentialSchedules], providerAvailabilities);
        let scheduleConflicts: string[] = [];

        dayIsOpenWithPotentials.filter((dop: any) => dop.openWindows && dop.openWindows.length === 0 && filter.weekDays.includes(dop.day)).forEach((dOpen: any) => {
            //check if it would be conflicting without potential schedules, to create the error message more severe
            let openWindowWithoutPotentials = dayIsOpen.find((x: any) => x.day === dOpen.day);
            let isConflictWithoutPotentials = openWindowWithoutPotentials && openWindowWithoutPotentials.openWindows && openWindowWithoutPotentials.openWindows.length === 0;

            //generate error message
            let startStr = filter.startTime.toLocaleTimeString();
            let endStr = filter.endTime.toLocaleTimeString();
            let day = (dOpen.day).toString();
            scheduleConflicts.push((isConflictWithoutPotentials ? "Can't find required timing on " : "Potential schedule might conflict with timing on ") + (daysOfWeek as any)[day] + " between " + startStr + " and " + endStr);
        });
        return scheduleConflicts;
    }
    // returns all days where no window fulfilling the need
    isOpenWindowDuringTimeOfDay(filter: ScheduleTimeFilterModel, providerSchedules: Schedule[], providerAvailabilities: Schedule[]) {
        let openWindowsByDays: any = this.getProviderOpenWindows(providerSchedules, providerAvailabilities);
        //let daysIsOpen: any = [
        //    { day: 0, openWindows: openWindowsByDays[0] ? openWindowsByDays[0].filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter)) : [] },
        //    { day: 1, openWindows: openWindowsByDays[1] ? openWindowsByDays[1].filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter)) : [] },
        //    { day: 2, openWindows: openWindowsByDays[2] ? openWindowsByDays[2].filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter)) : [] },
        //    { day: 3, openWindows: openWindowsByDays[3] ? openWindowsByDays[3].filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter)) : [] },
        //    { day: 4, openWindows: openWindowsByDays[4] ? openWindowsByDays[4].filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter)) : [] },
        //    { day: 5, openWindows: openWindowsByDays[5] ? openWindowsByDays[5].filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter)) : [] },
        //    { day: 6, openWindows: openWindowsByDays[6] ? openWindowsByDays[6].filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter)) : [] },
        //];
        let daysIsOpen = openWindowsByDays.map((x: any) => {
            return {
                day: x.day,
                openWindows: x.openWindows.filter((ow: any) => this.isDurationAtOrMoreThanXMinutes(ow, filter))
            }
        })
        return daysIsOpen;
    }

    // will currently only be reliable for statically weekly schedules - not bi-weekly or monthly, meaning, it will determine its schedule on a weekly basis, checking open-windows by weekday
    // getProviderOpenWindows(providerSchedules: Schedule[], providerAvailabilities: Schedule[]) {
    //     let openWindowsByDays: any = {}; // { 1: [{}], 2: [{}], 3: [{}], 4: [{}], 5: [{}], 6: [{}], 7: [{}] };
    //     const combinedAvailabilityIntervalsByWeekDay = this.getCombinedAvailabilityIntervalsByWeekDay(providerAvailabilities) as { [day: number]: DateTimeInterval[] };
    //     const combinedBusyScheduleIntervalsByWeekDay = this.getCombinedAvailabilityIntervalsByWeekDay(providerSchedules) as { [day: number]: DateTimeInterval[] };
    //     for (let wday = 0; wday < 7; wday++) {
    //         openWindowsByDays[wday] = dateTimeHelper.closeWindows(combinedAvailabilityIntervalsByWeekDay[wday], combinedBusyScheduleIntervalsByWeekDay[wday]);
    //     }
    //     let openWindowsByDaysObj: any = [
    //         { day: 0, openWindows: openWindowsByDays[0] ? openWindowsByDays[0] : [] },
    //         { day: 1, openWindows: openWindowsByDays[1] ? openWindowsByDays[1] : [] },
    //         { day: 2, openWindows: openWindowsByDays[2] ? openWindowsByDays[2] : [] },
    //         { day: 3, openWindows: openWindowsByDays[3] ? openWindowsByDays[3] : [] },
    //         { day: 4, openWindows: openWindowsByDays[4] ? openWindowsByDays[4] : [] },
    //         { day: 5, openWindows: openWindowsByDays[5] ? openWindowsByDays[5] : [] },
    //         { day: 6, openWindows: openWindowsByDays[6] ? openWindowsByDays[6] : [] },
    //     ];
    //     return openWindowsByDaysObj;
    // }
    getProviderOpenWindows(providerSchedules: Schedule[], providerAvailabilities: Schedule[]) {
        const openWindowsByDays: any = {};
        const combinedAvailabilityIntervalsByWeekDay = this.getCombinedAvailabilityIntervalsByWeekDay(providerAvailabilities) as { [day: number]: DateTimeInterval[] };
        const combinedBusyScheduleIntervalsByWeekDay = this.getCombinedAvailabilityIntervalsByWeekDay(providerSchedules) as { [day: number]: DateTimeInterval[] };

        for (let wday = 0; wday < 7; wday++) {
            openWindowsByDays[wday] = dateTimeHelper.closeWindows(
                combinedAvailabilityIntervalsByWeekDay[wday] || [],
                combinedBusyScheduleIntervalsByWeekDay[wday] || []
            );

        }

        const openWindowsByDaysObj = Array.from({ length: 7 }, (_, wday) => ({
            day: wday,
            openWindows: openWindowsByDays[wday] || [],
        }));

        return openWindowsByDaysObj;
    }

    getScheduleConflicts(clientSchedule: Schedule, providerSchedules: Schedule[]): ScheduleConflict[] {
        let scheduleConflicts: ScheduleConflict[] = [];

        let clientScheduleDaysLookup = {} as { [day: number]: boolean };
        clientSchedule.days.forEach(day => clientScheduleDaysLookup[day] = true);

        const today = new Date();
        const sessionSearchStart = clientSchedule.startDate > today ? clientSchedule.startDate : today;

        providerSchedules.forEach(providerSchedule => {
            if (!
                ((this.schedulesHaveOverlappingDateTimeRanges(clientSchedule, providerSchedule)) &&
                    this.schedulePeriodsConflict(clientSchedule, providerSchedule))
            ) {
                return;
            }
            let today = new Date();
            let defaultEndDate = new Date();
            defaultEndDate.setDate(today.getDate() + 500)
            if ((providerSchedule.endDate || defaultEndDate) < (clientSchedule.startDate || today))
                return;

            let conflictingDays = [];

            if (this.schedulePeriodsAlwaysAlign(clientSchedule, providerSchedule)) {
                conflictingDays = providerSchedule.days.filter(day => clientScheduleDaysLookup[day]);

                if (conflictingDays.length == 0) {
                    return;
                }

                scheduleConflicts.push({
                    clientSchedule,
                    providerSchedule,
                    repeating: {
                        days: conflictingDays,
                        everyXOfPeriods: providerSchedule.everyXOfPeriods,
                        periodicity: providerSchedule.periodicity,
                    },
                });
            } else if (clientSchedule.periodicity == providerSchedule.periodicity) {
                conflictingDays = providerSchedule.days.filter(day => clientScheduleDaysLookup[day]);

                if (conflictingDays.length == 0) {
                    return;
                }

                const scheduleWithBiggerPeriod = providerSchedule.everyXOfPeriods > clientSchedule.everyXOfPeriods ?
                    providerSchedule : clientSchedule;

                const nextConflictingDate = getNextSession({
                    ...scheduleWithBiggerPeriod,
                    days: conflictingDays,
                });

                scheduleConflicts.push({
                    clientSchedule,
                    providerSchedule,
                    repeating: {
                        days: conflictingDays,
                        everyXOfPeriods: scheduleWithBiggerPeriod.everyXOfPeriods,
                        periodicity: providerSchedule.periodicity,
                    },
                    exampleDates: nextConflictingDate ? [nextConflictingDate] : [],
                });
            } else {

                const providerSessionsInNextSixtyDays = this.getSessionDatesInNextXDays(providerSchedule, sessionSearchStart, 60);
                const conflictingDates = this.findMatchingDatesInSchedule(providerSessionsInNextSixtyDays, clientSchedule);

                if (conflictingDates.length == 0) {
                    return;
                }

                scheduleConflicts.push({
                    clientSchedule,
                    providerSchedule,
                    exampleDates: conflictingDates,
                });
            }
        });

        return scheduleConflicts;
    }
    getCombinedAvailabilityIntervalsByWeekDay(availabilities: Schedule[]) {
        //get available time intervals by day of week
        const availabilityIntervalsByWeekDay = {} as { [day: number]: DateTimeInterval[] };

        availabilities.forEach(availability => {
            const availabilityInterval: DateTimeInterval = {
                start: new Date(1, 0, 1, availability.startTime.getHours(), availability.startTime.getMinutes()),
                end: new Date(1, 0, 1, availability.endTime.getHours(), availability.endTime.getMinutes())
            }

            availability.days.forEach(day => {
                if (availabilityIntervalsByWeekDay[day]) {
                    availabilityIntervalsByWeekDay[day].push({ ...availabilityInterval });
                } else {
                    availabilityIntervalsByWeekDay[day] = [{ ...availabilityInterval }];
                }
            });
        });

        const combinedAvailabilityIntervalsByWeekDay = {} as { [day: number]: DateTimeInterval[] };

        for (let i = 0; i < 7; i++) {
            const weekDayIntervals = availabilityIntervalsByWeekDay[i];

            if (!weekDayIntervals || weekDayIntervals.length == 0) {
                continue;
            }

            combinedAvailabilityIntervalsByWeekDay[i] = dateTimeHelper.combineIntervals(weekDayIntervals);
        } return combinedAvailabilityIntervalsByWeekDay;
    }

    getAvailabilityForClientSchedule(clientSchedule: Schedule, providerAvailabilities: Schedule[]): ScheduleAvailabilityResponse {
        //ScheduleAvailabilityGap

        const today = new Date();
        const sessionSearchStart = clientSchedule.startDate > today ? clientSchedule.startDate : today;

        const combinedAvailabilityIntervalsByWeekDay = this.getCombinedAvailabilityIntervalsByWeekDay(providerAvailabilities) as { [day: number]: DateTimeInterval[] };


        //find gaps in clientSchedule where there is no provider availability
        //ScheduleAvailabilityGap

        const sessionDatesWithNoAvailability: Date[] = [];
        const sessionDatesWithPartialAvailability: Date[] = [];
        const daysWithNoAvailability: number[] = [];
        const daysWithPartialAvailability: number[] = [];

        if (clientSchedule.periodicity.toLowerCase() == SCHEDULE_PERIODICITY_CONSTANTS.MONTHS.toLowerCase()) {
            const clientSessionsInNextSixtyDays = this.getSessionDatesInNextXDays(clientSchedule, sessionSearchStart, 60);

            clientSessionsInNextSixtyDays.forEach(clientSessionDate => {
                const availabilitiesForSessionWeekday = combinedAvailabilityIntervalsByWeekDay[clientSessionDate.getDay()];
                const availabilityStatus = this.getAvailabilityStatusForWeekday(availabilitiesForSessionWeekday, clientSchedule);

                if (availabilityStatus == ScheduleAvailabilityStatus.NoAvailability) {
                    sessionDatesWithNoAvailability.push(clientSessionDate);
                }

                if (availabilityStatus == ScheduleAvailabilityStatus.PartialAvailability) {
                    sessionDatesWithPartialAvailability.push(clientSessionDate);
                }
            });
        } else if (clientSchedule.periodicity.toLowerCase() == SCHEDULE_PERIODICITY_CONSTANTS.WEEKS.toLowerCase()) {
            clientSchedule.days.forEach(dayOfWeek => {
                const availabilitiesForWeekday = combinedAvailabilityIntervalsByWeekDay[dayOfWeek];
                const availabilityStatus = this.getAvailabilityStatusForWeekday(availabilitiesForWeekday, clientSchedule);

                if (availabilityStatus == ScheduleAvailabilityStatus.NoAvailability) {
                    daysWithNoAvailability.push(dayOfWeek);
                }

                if (availabilityStatus == ScheduleAvailabilityStatus.PartialAvailability) {
                    daysWithPartialAvailability.push(dayOfWeek);
                }
            });
        }

        const scheduleAvailabilityResponse: ScheduleAvailabilityResponse = {
            availabilityStatus: ScheduleAvailabilityStatus.FullAvailability,
        }

        if (daysWithNoAvailability.length > 0 || sessionDatesWithNoAvailability.length > 0) {
            scheduleAvailabilityResponse.fullAvailabilityGap = {
                repeating: daysWithNoAvailability.length > 0 ? {
                    days: daysWithNoAvailability
                } : undefined,
                exampleDates: sessionDatesWithNoAvailability.length > 0 ? sessionDatesWithNoAvailability : undefined,
            };
        }

        if (daysWithPartialAvailability.length > 0 || sessionDatesWithPartialAvailability.length > 0) {
            scheduleAvailabilityResponse.fullAvailabilityGap = {
                repeating: daysWithPartialAvailability.length > 0 ? {
                    days: daysWithPartialAvailability
                } : undefined,
                exampleDates: sessionDatesWithPartialAvailability.length > 0 ? sessionDatesWithPartialAvailability : undefined,
            };
        }

        if (scheduleAvailabilityResponse.partialAvailabilityGap) {
            scheduleAvailabilityResponse.availabilityStatus = ScheduleAvailabilityStatus.PartialAvailability;
        } else if (scheduleAvailabilityResponse.fullAvailabilityGap) {
            scheduleAvailabilityResponse.availabilityStatus = ScheduleAvailabilityStatus.NoAvailability;
        }

        return scheduleAvailabilityResponse;
    }

    getAvailabilityStatusForWeekday(availabilitiesForWeekday: DateTimeInterval[], clientSchedule: Schedule): ScheduleAvailabilityStatus {
        const clientTimeInterval = {
            start: new Date(1, 0, 1, clientSchedule.startTime.getHours(), clientSchedule.startTime.getMinutes()),
            end: new Date(1, 0, 1, clientSchedule.endTime.getHours(), clientSchedule.endTime.getMinutes())
        }
        if (clientTimeInterval.start.getTime() === clientTimeInterval.end.getTime())
            return ScheduleAvailabilityStatus.FullAvailability;
        if (!availabilitiesForWeekday) {
            return ScheduleAvailabilityStatus.NoAvailability;
        }

        let hasAnyAvailability = false;
        let isOnlyPartiallyAvailable = false;


        if (clientTimeInterval.start >= clientTimeInterval.end) {
            clientTimeInterval.end.setDate(clientTimeInterval.start.getDate() + 1);
        }

        for (let i = 0; i < availabilitiesForWeekday.length; i++) {
            let availability = availabilitiesForWeekday[i];

            availability = {
                start: new Date(1, 0, 1, availability.start.getHours(), availability.start.getMinutes()),
                end: new Date(1, 0, 1, availability.end.getHours(), availability.end.getMinutes())
            };

            if (availability.start >= availability.end) {
                availability.end.setDate(availability.start.getDate() + 1);
            }

            const someSessionTimeAvailabilityOverlap = dateTimeHelper.dateTimeRangeOverlaps(
                availability.start,
                availability.end,
                clientTimeInterval.start,
                clientTimeInterval.end,
            );

            if (!someSessionTimeAvailabilityOverlap) {
                continue;
            }

            if (clientTimeInterval.start >= availability.start && clientTimeInterval.end <= availability.end) {
                hasAnyAvailability = true;
                isOnlyPartiallyAvailable = false;
                break;
            }

            hasAnyAvailability = true;
            isOnlyPartiallyAvailable = true;
        }

        if (!hasAnyAvailability) {
            return ScheduleAvailabilityStatus.NoAvailability;
        } else if (isOnlyPartiallyAvailable) {
            return ScheduleAvailabilityStatus.PartialAvailability;
        } else {
            return ScheduleAvailabilityStatus.FullAvailability;
        }
    }

    getSessionDatesInNextXDays(schedule: Schedule, startDate: Date, xDaysFromStartDate: number) {
        const dateInXDaysFromStartDate = new Date(startDate);
        dateInXDaysFromStartDate.setDate(dateInXDaysFromStartDate.getDate() + xDaysFromStartDate);

        let searchStartDate = new Date(startDate);
        const sessionDatesInNextSixtyDays = [];

        while (searchStartDate < dateInXDaysFromStartDate) {
            const nextSession = getNextSession(schedule, searchStartDate);

            if (nextSession == null) {
                break;
            }

            sessionDatesInNextSixtyDays.push(nextSession);
            searchStartDate = new Date(nextSession);
            searchStartDate.setDate(nextSession.getDate() + 1);
        }

        return sessionDatesInNextSixtyDays;
    }

    findMatchingDatesInSchedule(dates: Date[], schedule: Schedule) {
        const scheduleDaysLookup = {} as { [day: number]: boolean };
        schedule.days.forEach(day => scheduleDaysLookup[day] = true);

        return dates.filter(date => {
            let isDateAMatch = false;

            if (schedule.periodicity.toLowerCase() === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS.toLowerCase()) {
                isDateAMatch = this.isDateInWeekBasedSchedule(date, schedule, (day) => scheduleDaysLookup[day]);
            } else if (schedule.periodicity.toLowerCase() === SCHEDULE_PERIODICITY_CONSTANTS.MONTHS.toLowerCase()) {
                isDateAMatch = this.isDateInMonthBasedSchedule(date, schedule, (day) => scheduleDaysLookup[day]);
            }

            return isDateAMatch;
        });

        //date must be within schedule start / end date
        //schedule days must include either the monthDay or weekDay of the date (depending on schedule periodicity)
    }

    isDateInWeekBasedSchedule(date: Date, schedule: Schedule, isDayInScheduleDays: (day: number) => boolean): boolean {
        //date's weekday must match one of the schedule's weekdays
        const isDateWeekDayInScheduleWeekDays = isDayInScheduleDays(date.getDay());

        //date must be in range of the schedule start and end date
        const isDateInScheduleRange = dateTimeHelper.dateTimeRangeOverlaps(schedule.startDate, schedule.endDate, date, null);

        //date's week must be one of the schedule period's target weeks
        const weeksBetweenDateAndScheduleStartDate = dateTimeHelper.getNumberOfWeeksBetweenDates(
            dateTimeHelper.getBeginningOfWeek(schedule.startDate),
            dateTimeHelper.getBeginningOfWeek(date)
        );

        const isDateWeekAPeriodTarget = weeksBetweenDateAndScheduleStartDate % schedule.everyXOfPeriods == 0;

        return isDateWeekDayInScheduleWeekDays && isDateInScheduleRange && isDateWeekAPeriodTarget;
    }

    isDateInMonthBasedSchedule(date: Date, schedule: Schedule, isDayInScheduleDays: (day: number) => boolean): boolean {
        //date's monthday must match one of the schedule's weekdays
        const isMonthDayInScheduleMonthDays = isDayInScheduleDays(date.getDate());

        //date must be in range of the schedule start and end date
        const isDateInScheduleRange = dateTimeHelper.dateTimeRangeOverlaps(schedule.startDate, schedule.endDate, date, null);

        //date's month must be one of the schedule period's target months
        const monthsBetweenDateAndScheduleStartDate = dateTimeHelper.getNumberOfMonthsBetweenDates(
            dateTimeHelper.getBeginningOfMonth(schedule.startDate),
            dateTimeHelper.getBeginningOfMonth(date)
        );

        const isDateMonthAPeriodTarget = monthsBetweenDateAndScheduleStartDate % 0 == 0;

        return isMonthDayInScheduleMonthDays && isDateInScheduleRange && isDateMonthAPeriodTarget;
    }

    schedulePeriodsAlwaysAlign(clientSchedule: Schedule, providerSchedule: Schedule) {
        if (clientSchedule.periodicity != providerSchedule.periodicity) return false;
        if (clientSchedule.everyXOfPeriods != providerSchedule.everyXOfPeriods) return false;

        const numberOfDaysBetweenScheduleStartDates = dateTimeHelper.getNumberOfDaysBetweenDates(
            clientSchedule.startDate,
            providerSchedule.startDate,
        );

        return numberOfDaysBetweenScheduleStartDates % clientSchedule.everyXOfPeriods == 0;
    }

    schedulePeriodsConflict(clientSchedule: Schedule, providerSchedule: Schedule) {
        if (clientSchedule.periodicity != providerSchedule.periodicity) return true;
        if (providerSchedule.everyXOfPeriods == 1 || clientSchedule.everyXOfPeriods == 1) return true;
        if (!numbersHelper.largerNumberIsDevisableBySmallerNumber(providerSchedule.everyXOfPeriods, clientSchedule.everyXOfPeriods)) return true;

        const nextClientSession = getNextSession(clientSchedule);
        const nextProviderSession = getNextSession(clientSchedule);

        let timeBetweenNextSessions;

        if (nextClientSession == null || nextProviderSession == null) {
            return false;
        } else if (clientSchedule.periodicity.toLowerCase() === SCHEDULE_PERIODICITY_CONSTANTS.MONTHS.toLowerCase()) {
            timeBetweenNextSessions = dateTimeHelper.getNumberOfMonthsBetweenDates(
                dateTimeHelper.getBeginningOfMonth(nextClientSession),
                dateTimeHelper.getBeginningOfMonth(nextProviderSession)
            );
        } else {
            timeBetweenNextSessions = dateTimeHelper.getNumberOfWeeksBetweenDates(
                dateTimeHelper.getBeginningOfWeek(nextClientSession),
                dateTimeHelper.getBeginningOfWeek(nextProviderSession)
            );
        }

        const smallerPeriod = numbersHelper.getSmallestNumber([providerSchedule.everyXOfPeriods, clientSchedule.everyXOfPeriods]) ?? 1;

        return numbersHelper.aIsDivisibleByB(timeBetweenNextSessions, smallerPeriod);
    }

    clientScheduleIsWithinAvailability(clientSchedule: Schedule, providerAvailability: Schedule) {
        // ::::::::MUST:::::::
        //datetimes must overlap
        //clientSchedule within provider's target period, ie, must either
        //  (a) same periodicity, same x, aligned, within available days
        //  (b) same periodicity, different x, but client x > availability x, and some times aligned
        //  (c) otherwise match day-to-day for 60 days
        //::::::::MUST NOT::::::::
        //client x must not be smaller than availability x
        /*
         * availability: every week
         * clientSchedule: every 2 weeks
         */
        const scheduleWithinAvailabilityCalculator = new ScheduleWithinAvailabilityCalculator(clientSchedule, providerAvailability);
        return scheduleWithinAvailabilityCalculator.scheduleWithinAvailability();
    }
}

class ScheduleWithinAvailabilityCalculator {
    clientSchedule: Schedule
    providerAvailability: Schedule

    constructor(clientSchedule: Schedule, providerAvailability: Schedule) {
        this.clientSchedule = clientSchedule;
        this.providerAvailability = providerAvailability;
    }

    scheduleWithinAvailability() {
        const clientSchedule = this.clientSchedule;
        const providerAvailability = this.providerAvailability;

        const scheduleStartTimeWithinAvailability =
            clientSchedule.startTime >= providerAvailability.startTime && clientSchedule.startTime <= providerAvailability.endTime;

        const scheduleEndTimeWithinAvailability =
            clientSchedule.endTime >= providerAvailability.startTime && clientSchedule.endTime <= providerAvailability.endTime;

        return scheduleStartTimeWithinAvailability && scheduleEndTimeWithinAvailability &&
            this.scheduleStartDateWithinAvailability() && this.scheduleEndDateWithinAvailability();
    }

    scheduleStartDateWithinAvailability() {
        const clientSchedule = this.clientSchedule;
        const providerAvailability = this.providerAvailability;

        let scheduleStartDateWithinAvailability = true;

        // if(clientSchedule.startDate != null){
        //     scheduleStartDateWithinAvailability = 
        //         scheduleStartDateWithinAvailability && clientSchedule.startDate >= providerAvailability.startDate;
        // }

        // if(clientSchedule.startDate != null && clientSchedule.endDate != null){
        //     scheduleStartDateWithinAvailability = 
        //         scheduleStartDateWithinAvailability && clientSchedule.startDate <= providerAvailability.endDate;
        // }

        return scheduleStartDateWithinAvailability;
    }

    scheduleEndDateWithinAvailability() {
        const clientSchedule = this.clientSchedule;
        const providerAvailability = this.providerAvailability;

        let scheduleEndDateWithinAvailability = true;

        if (clientSchedule.endDate != null) {
            scheduleEndDateWithinAvailability = scheduleEndDateWithinAvailability && clientSchedule.endDate >= providerAvailability.startDate;
        }

        if (clientSchedule.endDate != null && providerAvailability.endDate != null) {
            scheduleEndDateWithinAvailability = scheduleEndDateWithinAvailability && clientSchedule.endDate <= providerAvailability.endDate;
        }

        return scheduleEndDateWithinAvailability;
    }
}

export const getNextAppointmentString = (schedule: any) => {
    let nextAppointmentString: any = null;

    if (schedule.date) {
        const nextSessionDate: any = getNextSession({
            ...schedule,
            startDate: schedule.date ? dateTime.getDateObject(schedule.date) : new Date(),
            endDate: schedule.endDate ? dateTime.getDateObject(schedule.endDate) : null,
            days: schedule.days ? schedule.days.split(',') : [],
            periodicity: schedule.periodicity,
            everyXOfPeriods: schedule.everyXOfPeriods,
        });

        nextAppointmentString = nextSessionDate ? dateTime.getUSAFormattedDateString(nextSessionDate) : null;
    }

    return nextAppointmentString;
}