import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import React from "react";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

export const clients = [
    {
        id: 0,
        name: "Spinka Michael",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 1,
        name: "Dorry Michael",
        phone: "1-664-563-6254",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 2,
        name: "George Michael",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 3,
        name: "Michael Michael",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 4,
        name: "Michale Michael",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 5,
        name: "Sophia Sury",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 6,
        name: "Harry potter",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 7,
        name: "Domnic torreto",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 8,
        name: "John chena",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
    {
        id: 9,
        name: "Andrew symond",
        phone: "1-664-563-6259",
        address: "6009 Wyoming street",
        city: "Kutch buruhasdf",
        state: "Greenholt Freeway",
        zip: "76772",
        icons: <EditIcon className="icon" />,
    },
];
export const dataOfDocumentsFolders = {
    id: "root",
    name: "Documents",
    children: [
        {
            id: "1",
            name: "Report",
            children: [
                {
                    id: "2",
                    name: "PDF",
                },
            ],
        },
        {
            id: "3",
            name: "Files",
            children: [
                {
                    id: "4",
                    name: "Excel",
                },
            ],
        },
        {
            id: "5",
            name: "Programs",
            children: [
                {
                    id: "6",
                    name: "Codes",
                },
                {
                    id: "7",
                    name: "Data",
                },
            ],
        },
    ],
};
export const columnsClient = [
    { id: "name", label: "Name", minWidth: 170, align: "left" },
    { id: "phone", label: "Phone", minWidth: 100, align: "center" },
    {
        id: "address",
        label: "Address",
        minWidth: 170,
        align: "center",
    },
    {
        id: "city",
        label: "City",
        minWidth: 170,
        align: "center",
    },
    {
        id: "state",
        label: "State",
        minWidth: 170,
        align: "center",
    },
    {
        id: "zip",
        label: "Zip",
        minWidth: 170,
        align: "right",
    },
    {
        id: "actions",
        label: "Actions",
        minWidth: 100,
        align: "right",
    },
];
export const todoRow = [
    {
        id: 1,
        title: "Parents very happy with new Yungerman",
        assignee: "John Smith",
        due_date: "21/01/2021",
    },
    {
        id: 2,
        title: "Yingerman For Afternoon",
        assignee: "John Smith",
        due_date: "21/01/2021",
    },
    {
        id: 3,
        title: "Apply for P3",
        assignee: "John Smith",
        due_date: "21/01/2021",
    },
    {
        id: 4,
        title: "Requires Social Worker",
        assignee: "John Smith",
        due_date: "21/01/2021",
    },
    {
        id: 5,
        title: "Set up meeting with parents",
        assignee: "John Smith",
        due_date: "21/01/2021",
    },
    {
        id: 6,
        title: "Set up gym in morning",
        assignee: "John Smith",
        due_date: "21/01/2021",
    },
    {
        id: 7,
        title: "Parents very happy with new Yungerman",
        assignee: "John Smith",
        due_date: "21/01/2021",
    },
];
export const todoColumns = [
    { field: "title", headerName: "Title", flex: 4, sortable: false },
    { field: "assignee", headerName: "Assignee", flex: 1 },
    { field: "due_date", headerName: "Due Date", flex: 1 },
];
export const scheduleRow = [
    {
        id: 1,
        provider: "Mosher Ber Moskowitz",
        service: "Com Hab",
        start_time: "08:45 AM",
        end_time: "10:15 AM",
    },
    {
        id: 2,
        provider: "Shmeil Smilowitz",
        service: "Respite",
        start_time: "10:15 AM",
        end_time: "11:15 AM",
    },
    {
        id: 3,
        provider: "Moshe Stern",
        service: "Com Hab",
        start_time: "01:00 PM",
        end_time: "02:00 PM",
    },
    {
        id: 4,
        provider: "Meyer Weiss",
        service: "Day Hab",
        start_time: "02:15 PM",
        end_time: "03:00 PM",
    },
];

export const scheduleColumns = [
    { field: "provider", headerName: "Provider", flex: 1 },
    { field: "service", headerName: "Service", flex: 1 },
    { field: "start_time", headerName: "Start Time", flex: 1 },
    { field: "end_time", headerName: "End Time", flex: 1 },
];

export const scheduleRowDaily = [
    {
        id: "1",
        from: "5:00 PM",
        to: "6:00 PM",
        provider: "Contact.Name",
        note: "Learn the new book",
        schedule: "Sun, Mon, Tue",
    },
    {
        id: "2",
        from: "5:00 PM",
        to: "6:00 PM",
        provider: "Contact.Name",
        note: "Learn the old book",
        schedule: "Sun, Mon, Tue",
    },
    {
        id: "3",
        from: "5:00 PM",
        to: "6:00 PM",
        provider: "Contact.Name",
        note: "Learn the new book",
        schedule: "Sun, Mon, Tue",
    },
];

export const scheduleRowProfessional = [
    {
        id: "1",
        from: "5:00 PM",
        to: "6:00 PM",
        provider: "Contact.Name",
        note: "Learn the new book",
        nextsession: "Next Session",
    },
    {
        id: "2",
        from: "5:00 PM",
        to: "6:00 PM",
        provider: "Contact.Name",
        note: "Learn the new book",
        nextsession: "Next Session",
    },
    {
        id: "3",
        from: "5:00 PM",
        to: "6:00 PM",
        provider: "Contact.Name",
        note: "Learn the new book",
        nextsession: "Next Session",
    },
];

export const scheduleColumnDaily = [
    {
        field: "clientProviderScheduleId",
        headerName: "clientProviderScheduleId",
        flex: 1,
    },
    { field: "startTime", headerName: "From", flex: 1 },
    { field: "endTime", headerName: "To", flex: 1 },
    { field: "providerName", headerName: "Provider", flex: 1 },
    { field: "note", headerName: "Note", flex: 1 },
    { field: "daysOfWeekStr", headerName: "Schedule", flex: 1 },
];
export const scheduleColumnProfessional = [
    {
        field: "clientProviderScheduleId",
        headerName: "clientProviderScheduleId",
        flex: 1,
    },
    { field: "endTime", headerName: "To", flex: 1 },
    { field: "providerName", headerName: "Provider", flex: 1 },
    { field: "note", headerName: "Note", flex: 1 },
    { field: "nextSession", headerName: "Next Session", flex: 1 },
];

export const scheduleColumnAppointment = [
    {
        field: "clientProviderScheduleId",
        headerName: "clientProviderScheduleId",
        flex: 1,
    },
    { field: "startTime", headerName: "From", flex: 1 },
    { field: "endTime", headerName: "To", flex: 1 },
    { field: "appointmentBy", headerName: "By", flex: 1 },
    { field: "note", headerName: "Note", flex: 1 },
    { field: "appointmentDate", headerName: "Date", flex: 1 },
];

export const scheduleRowAppointment = [
    {
        id: "1",
        from: "5:00 PM",
        to: "6:00 PM",
        by: "apointments.by ",
        note: "Learn the new book",
        date: "7:00 AM",
    },
];

export const scheduleColumnSeeking = [
    {
        field: "clientProviderScheduleId",
        headerName: "clientProviderScheduleId",
        flex: 1,
    },
    { field: "startTime", headerName: "From", width: 100, },
    { field: "endTime", headerName: "To", width: 100, },
    { field: "daysOfWeekStr", headerName: "Schedule", width: 150, },
];

export const scheduleColumnEmployeeAvailable = [
    { field: "from", headerName: "From", flex: 1 },
    { field: "to", headerName: "To", flex: 1 },
    { field: "schedule", headerName: "Schedule", flex: 1 },
];

export const scheduleRowSeeking = [
    {
        id: "1",
        from: "5:00 PM",
        to: "6:00 PM",
        schedule: "Sun, Mon, Tue",
    },
];

export const weekNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
export const lessons = ["DAILY", "WEEKLY", "MONTHLY"];
export const providerNames = [
    { title: "abefasd", year: 1994 },
    { title: "wpfkpxvcw", year: 1972 },
    { title: "wmwoefs", year: 1974 },
];
export const possibleSpecialties = ["ADHD", "Depression", "Reading"];

export const progressRow = [
    {
        id: 1,
        provider: "Mosher Ber Moskowitz",
        note_date: "21/01/2021",
        note: "Parents very happy with new Yungerman",
    },
    {
        id: 2,
        provider: "Shmeil Smilowitz",
        note_date: "21/01/2021",
        note: "Set up gym in morning",
    },
    {
        id: 3,
        provider: "Moshe Stern",
        note_date: "21/01/2021",
        note: "Apply for P3",
    },
    {
        id: 4,
        provider: "Meyer Weiss",
        note_date: "21/01/2021",
        note: "Requires Social Worker",
    },
];
export const progressColumns = [
    { field: "provider", headerName: "Provider", flex: 2, sortable: false },
    { field: "note_date", headerName: "Note Date", flex: 1 },
    { field: "note", headerName: "Note", flex: 2, sortable: false },
];
export const contactRow = [
    {
        id: 1,
        name: "Mosher Ber Moskowitz",
        phone: "(0256) 0113234354",
        email: "harrypotter@gmail.com",
        relationship: "Parent",
    },
    {
        id: 2,
        name: "Shmeil Smilowitz",
        phone: "(0256) 0113234357",
        email: "johncena@gmail.com",
        relationship: "Sibling",
    },
    {
        id: 3,
        name: "Moshe Stern",
        phone: "(0256) 0113234350",
        email: "johnnywelcome@gmail.com",
        relationship: "Legal Guardian",
    },
    {
        id: 4,
        name: "Meyer Weiss",
        phone: "(0256) 0113234313",
        email: "yanshingkashito@yahoo.com",
        relationship: "Other",
    },
];
export const contactColumns = [
    { field: "name", headerName: "Name", flex: 1.5 },
    { field: "phone", headerName: "Phone", flex: 1.5, sortable: false },
    { field: "email", headerName: "Email", flex: 1.5, sortable: false },
    {
        field: "relationship",
        headerName: "Relationship",
        flex: 1,
        sortable: false,
    },
];
export const historyColumns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "by_issued_name", headerName: "Issued By", width: 200 },
    { field: "issued_time", headerName: "Issued Time", width: 100 },
];
export const documentsColums = [
    {
        field: "",
        headerName: "Type",
        flex: 1,
        sortable: false,
        renderCell: () => {
            return <InsertDriveFileIcon />;
        },
    },
    { field: "name", headerName: "Name", flex: 3 },
    { field: "created_on", headerName: "Created On", flex: 2 },
    { field: "last_modified", headerName: "Last Modified", flex: 2 },
    { field: "uploaded_by", headerName: "Uploaded By", flex: 2 },
];
export const dataOfDocuments = [
    {
        id: 1,
        type: <InsertDriveFileIcon />,
        name: "File 1",
        created_on: "22 July 2020",
        last_modified: "11 Aug 2020",
        uploaded_by: "John Doe",
    },
    {
        id: 2,
        type: <InsertDriveFileIcon />,
        name: "File 2",
        created_on: "22 Jan 2017",
        last_modified: "11 Aug 2020",
        uploaded_by: "John Doe",
    },
    {
        id: 3,
        type: <InsertDriveFileIcon />,
        name: "File 3",
        created_on: "22 Jan 2017",
        last_modified: "11 Aug 2020",
        uploaded_by: "John Doe",
    },
];
export const data = [
    {
        id: "0",
        name: "Folder 1",
        type: "folder",
        children: [
            {
                id: "1",
                name: "File 1",
                type: "file",
            },
            {
                id: "2",
                name: "File 2",
                type: "file",
            },
            {
                id: "3",
                name: "File 3",
                type: "file",
            },
            {
                id: "4",
                name: "Folder 1",
                type: "folder",
                children: [
                    {
                        id: "5",
                        name: "File 1",
                        type: "file",
                    },
                ],
            },
        ],
    },
    {
        id: "6",
        name: "Folder 2",
        type: "folder",
        children: [
            {
                id: "7",
                name: "File 1",
                type: "file",
            },
            {
                id: "8",
                name: "File 2",
                type: "file",
            },
            {
                id: "9",
                name: "File 3",
                type: "file",
            },
        ],
    },
    {
        id: "10",
        name: "File 1",
        type: "file",
    },
];
export const dataOfHistory = [
    {
        id: 1,
        name: "Upload SEO Documents",
        by_issued_name: "by Moshe Rosen",
        issued_time: "12:30 PM",
        details: {
            dob: "10/05/2004",
            first_name: "Yossi",
            last_name: "Weiss",
        },
    },
    {
        id: 2,
        name: "Uploadated enrollment information",
        by_issued_name: "by Moshe Rosen",
        issued_time: "Yesterday",
        details: {
            dob: "10/05/1998",
            first_name: "John",
            last_name: "Weiss",
        },
    },
    {
        id: 3,
        name: "Enrolled New Indivisual",
        by_issued_name: "by Moshe Rosen",
        issued_time: "Dec 12",
        details: {
            dob: "10/05/1999",
            first_name: "Jonny",
            last_name: "Weiss",
        },
    },
];
export const usersRow = [
    {
        id: 1,
        user_name: "Mosher Ber Moskowitz",
        created_date: "21/01/2021",
        role: "Admin",
    },
    {
        id: 2,
        user_name: "Shmeil Smilowitz",
        created_date: "21/01/2021",
        role: "Admin",
    },
    {
        id: 3,
        user_name: "Moshe Stern",
        created_date: "21/01/2021",
        role: "Admin",
    },
    { id: 4, user_name: "Meyer Weiss", created_date: "21/01/2021", role: "View" },
];
export const usersColumns = [
    { field: "name", headerName: "Name", flex: 1, width: 100 },
    { field: "email", headerName: "Email", flex: 1, width: 300 },
    { field: "role", headerName: "Roles", width: 150, flex: 1.5},
];
export const listsColumns = [
    { field: "listName", headerName: "Name", flex: 1 },
    { field: "listDescription", headerName: "Description", flex: 2 },
    { field: "defaultValue", headerName: "Default", flex: 1 },
];
export const listValueColumns = [
    { field: "value", headerName: "Name", flex: 1 },
    { field: "valueDescription", headerName: "Description", flex: 2 },
];

export const settingColumns = [
    { field: "settingNumber", headerName: "Number", flex: 1 },
    { field: "property", headerName: "Property", flex: 2 },
    { field: "value", headerName: "Value", flex: 2 },
    { field: "description", headerName: "Description", flex: 2 },
    // { field: "DefaultValue", headerName: "DefaultValue", flex: 2 },
];

export const yeshivasDropDown = [
    { id: 0, name: "Yeshivas 1" },
    { id: 1, name: "Yeshivas 2" },
    { id: 2, name: "Yeshivas 3" },
    { id: 3, name: "Yeshivas 4" },
    { id: 4, name: "Yeshivas 5" },
    { id: 5, name: "Yeshivas 6" },
];

export const careManager = [
    { id: 0, value: "Manager 1" },
    { id: 1, value: "Manager 2" },
    { id: 2, value: "Manager 3" },
    { id: 3, value: "Manager 4" },
    { id: 4, value: "Manager 5" },
];

export const menahalimDropDown = [
    { id: 0, name: "Menahalim 1" },
    { id: 1, name: "Menahalim 2" },
    { id: 2, name: "Menahalim 3" },
    { id: 3, name: "Menahalim 4" },
    { id: 4, name: "Menahalim 5" },
    { id: 5, name: "Menahalim 6" },
];
