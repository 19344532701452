import {
    FormControl,
    FormHelperText, Grid,
    Input, InputLabel
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import * as constant from "../../constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import baseStyles from '../../assets/styles';
import useStyles from "./styles";
/**
 * props : handleClose(); handleAdded()
 * props - not required : editData
 */



export default function PanelNewContact(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    // Errors selection
    const [firstNamePerError, setFirstNamePerError] = React.useState("");
    const [lastNamePerError, setLastNamePerError] = React.useState("");
    const [herbFirstNamePerError, setHerbFirstNamePerError] = React.useState("");
    const [herbLastNamePerError, setHerbLastNamePerError] = React.useState("");
    const [legalFirstNamePerError, setLegalFirstNamePerError] = React.useState(
        ""
    );
    const [legalLastNamePerError, setLegalLastNamePerError] = React.useState("");
    const [legalMiddleNamePerError, setLegalMiddleNamePerError] = React.useState(
        ""
    );
    // Contacts Fields
    const [contactFirstName, setContactFirstName] = React.useState("");
    const [contactLastName, setContactLastName] = React.useState("");
    const [contactHerbrewFirstName, setContactHerbrewFirstName] = React.useState(
        ""
    );
    const [contactHerbrewLastName, setContactHerbrewLastName] = React.useState(
        ""
    );
    const [contactLegalFirstName, setContactLegalFirstName] = React.useState("");
    const [contactLegealMiddleName, setContactLegalMiddleName] = React.useState(
        ""
    );
    const [contactLegalLastName, setContactLegalLastName] = React.useState("");
    const [contactPhone1, setContactPhone1] = React.useState("");
    const [contactPhone2, setContactPhone2] = React.useState("");
    const [contactEmail1, setContactEmail1] = React.useState("");
    const [contactEmail2, setContactEmail2] = React.useState("");
    const [contactAddress, setContactAddress] = React.useState("");
    const [contactApt, setContactApt] = React.useState("");
    const [contactCity, setContactCity] = React.useState("");
    const [contactState, setContactState] = React.useState("");
    const [contactZip, setContactZip] = React.useState("");
    const [selectedContactTitle, setSelectedContactTitle] = React.useState(null);
    const [amountOfAddedContacts, setAmountOfAddedContacts] = React.useState(0);
    const [isOpenCreateModal, setIsOpenCreateModal] = React.useState(false);
    const [nameForModal, setNameForModal] = React.useState("");
    const [phoneForModal, setPhoneForModal] = React.useState("");
    const [emailForModal, setEmailForModal] = React.useState("");
    const [idForModal, setIdForModal] = React.useState("");
    const [cityForModal, setCityForModal] = React.useState("");

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);


    useEffect(() => {
        resetStateData();
    }, [props.editData]);

    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (contactFirstName === "") {
            isValid = false;
            setFirstNamePerError("Please enter first name");
        }
        if (contactLastName === "") {
            isValid = false;
            setLastNamePerError("Please enter last name");
        }
        return isValid;
    };

    const addNewContact = () => {
        if (!formValidationPersonalDetails()) {
            return;
        }

        var isADD = -1;
        if (props.editData && !_.isEmpty(props.editData)) {
            isADD = props.editData.id;
        }

        setLoading();
        global.fetchWrapper.post(constant.SAVE_CONTACT_CMS_LIST + isADD, {
            createdOn: new Date().toISOString(),
            firstName: contactFirstName,
            lastName: contactLastName,
            firstNameHebrew: contactHerbrewFirstName,
            lastNameHebrew: contactHerbrewLastName,
            firstNameLegal: contactLegalFirstName,
            lastNameLegal: contactLegalLastName,
            middleNameLegal: contactLegealMiddleName,
            address1: contactAddress,
            address2: contactApt,
            city: contactCity,
            state: contactState,
            zip: contactZip,
            phone1: contactPhone1,
            phone2: contactPhone2,
            email1: contactEmail1,
            email2: contactEmail2,
        }).then((response) => {
            endLoading();
            if (response.responseCode === 200) {
                resetStateData();
                props.handleAdded();
                //   setAmountOfAddedContacts(amountOfAddedContacts + 1);
                //   setSelectType(0);
                //   handleAddedRelationshipSuccess();
            } else {
                global.AjaxFailToast();
            }
        });
    };

    const resetStateData = () => {
        setContactFirstName("");
        setContactLastName("");
        setContactHerbrewFirstName("");
        setContactHerbrewLastName("");
        setContactLegalFirstName("");
        setContactLegalLastName("");
        setContactLegalMiddleName("");
        setContactAddress("");
        setContactApt("");
        setContactCity("");
        setContactEmail1("");
        setContactEmail2("");
        setContactState("");
        setContactZip("");
        setContactPhone1("");
        setContactPhone2("");

        if (!_.isEmpty(props.editData)) {
            const edit = props.editData;
            setContactFirstName(edit.firstName);
            setContactLastName(edit.lastName);
            setContactHerbrewFirstName(edit.firstNameHebrew);
            setContactHerbrewLastName(edit.lastNameHebrew);
            setContactLegalFirstName(edit.firstNameLegal);
            setContactLegalLastName(edit.lastNameLegal);
            setContactLegalMiddleName(edit.middleNameLegal);
            setContactAddress(edit.address1);
            setContactApt(edit.address2);
            setContactCity(edit.city);
            setContactEmail1(edit.email1);
            setContactEmail2(edit.email2);
            setContactState(edit.state);
            setContactZip(edit.zip);
            setContactPhone1(edit.phone1);
            setContactPhone2(edit.phone2);
        }
    };

    return (
        <>
            <div>
                <div>
                    <h5>Edit Contact</h5>

                    <div className={baseClasses.marginB10}>
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">First Name</InputLabel>
                                        <Input
                                            name="contactFirstName"
                                            value={contactFirstName}
                                            onChange={(e) => {
                                                if (!_.isEmpty(e.target.value)) {
                                                    setFirstNamePerError("");
                                                }
                                                global.log(
                                                    "e===",
                                                    e.target.value,
                                                    !_.isEmpty(e.target.value)
                                                );
                                                setContactFirstName(e.target.value);
                                            }}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {firstNamePerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {firstNamePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Last Name</InputLabel>
                                        <Input
                                            name="contactLastName"
                                            value={contactLastName}
                                            id="my-input"
                                            onChange={(e) => {
                                                if (!_.isEmpty(e.target.value)) {
                                                    setLastNamePerError("");
                                                }
                                                setContactLastName(e.target.value);
                                            }}
                                            aria-describedby="my-helper-text"
                                        />
                                        {lastNamePerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {lastNamePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">
                                            Hebrew First Name
                                        </InputLabel>
                                        <Input
                                            name="contactHerbrewFirstName"
                                            value={contactHerbrewFirstName}
                                            onChange={(e) => {
                                                if (!_.isEmpty(e.target.value)) {
                                                    setHerbFirstNamePerError("");
                                                }
                                                setContactHerbrewFirstName(e.target.value);
                                            }}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {herbFirstNamePerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {herbFirstNamePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Hebrew Last Name</InputLabel>
                                        <Input
                                            name="contactHerbrewFirstName"
                                            value={contactHerbrewLastName}
                                            onChange={(e) => {
                                                if (!_.isEmpty(e.target.value)) {
                                                    setHerbLastNamePerError("");
                                                }
                                                setContactHerbrewLastName(e.target.value);
                                            }}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {herbLastNamePerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {herbLastNamePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={4}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Legal First Name</InputLabel>
                                        <Input
                                            name="contactLegalFirstName"
                                            onChange={(e) => {
                                                if (!_.isEmpty(e.target.value)) {
                                                    setLegalFirstNamePerError("");
                                                }
                                                setContactLegalFirstName(e.target.value);
                                            }}
                                            value={contactLegalFirstName}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {legalFirstNamePerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {legalFirstNamePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Legal Last Name</InputLabel>
                                        <Input
                                            name="contactLegalLastName"
                                            onChange={(e) => {
                                                if (!_.isEmpty(e.target.value)) {
                                                    setLegalLastNamePerError("");
                                                }
                                                setContactLegalLastName(e.target.value);
                                            }}
                                            value={contactLegalLastName}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {legalLastNamePerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {legalLastNamePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">
                                            Legal Middle Name
                                        </InputLabel>
                                        <Input
                                            name="contactLegealMiddleName"
                                            onChange={(e) => {
                                                if (!_.isEmpty(e.target.value)) {
                                                    setLegalMiddleNamePerError("");
                                                }
                                                setContactLegalMiddleName(e.target.value);
                                            }}
                                            value={contactLegealMiddleName}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {legalMiddleNamePerError === "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {legalMiddleNamePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Phone1</InputLabel>
                                        <Input
                                            value={contactPhone1}
                                            onChange={(e) => setContactPhone1(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Phone2</InputLabel>
                                        <Input
                                            value={contactPhone2}
                                            onChange={(e) => setContactPhone2(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Email 1</InputLabel>
                                        <Input
                                            value={contactEmail1}
                                            onChange={(e) => setContactEmail1(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Email 2</InputLabel>
                                        <Input
                                            value={contactEmail2}
                                            onChange={(e) => setContactEmail2(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Address</InputLabel>
                                        <Input
                                            value={contactAddress}
                                            onChange={(e) => setContactAddress(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Apt</InputLabel>
                                        <Input
                                            value={contactApt}
                                            onChange={(e) => setContactApt(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={4}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">City</InputLabel>
                                        <Input
                                            value={contactCity}
                                            onChange={(e) => setContactCity(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">State</InputLabel>
                                        <Input
                                            value={contactState}
                                            onChange={(e) => setContactState(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="my-input">Zip</InputLabel>
                                        <Input
                                            value={contactZip}
                                            onChange={(e) => setContactZip(e.target.value)}
                                            id="my-input"
                                            aria-describedby="my-helper-text"
                                        />
                                        {<FormHelperText id="my-helper-text"></FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className="float-right">
                <Button color="primary" onClick={props.handleClose} className="mr-2">
                    Close
                </Button>
                <Button
                    className={classes.save}
                    color="primary"
                    size="medium"
                    onClick={addNewContact}
                >
                    Save
                </Button>
            </div>
        </>
    );
}
