import { makeStyles } from "@mui/styles";

const useStyles =  makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        '& .MuiTableCell-root': {
            padding: '4px',
            fontSize: '12px'
        },
        '& .MuiTableCell-head': {
        }
    },
    header: {
        '& .MuiTableCell-root': {
            padding: '12px',
            lineHeight: 1,
            color: '#FFFFFF',
        },
        backgroundColor: theme.palette.primary.main
    },
    cell: {
        fontSize: 12,
    },
    sub_header: {
        fontSize: 16,
        fontWeight: 'bold',
    }
    
}));
export default useStyles;

export const useContactTableStyle = makeStyles((theme) => ({
    
}));