import { makeStyles } from "@mui/styles";
import { maxWidth, styled } from '@mui/system';

export const SubTitleRow = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

export default makeStyles((theme) => ({
    // animation
    "@keyframes animLoading": {
        "0%": {
          backgroundPosition: '-720px 0'
        },
        "100%": {
            backgroundPosition: '720px 0'
        }
    },
    anim: {
        color: "transparent !important",
        "animation-duration": "1.5s",
        "animation-fill-mode": "forwards",
        "animation-iteration-count": "infinite",
        "animation-timing-function": "linear",
        "animation-name": '$animLoading',
    },
    popoverRoot: {
        '& .css-3bmhjh-MuiPaper-root-MuiPopover-paper': {
            left: '738px !important',
            width: '150px'
        }
    },
    defaultText: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '0.9rem'
    },
    noMargin: {
        margin: '1px'
    },
    subTabLabel: {
        fontWeight:"400",
        backgroundColor: 'rgb(241, 247, 253) !important',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        color: '#1976d2 !important',
        
    },
    cardHeader: {
        "&.MuiCardHeader-root": {
            padding: '5px',
        }
    },
    cardTab: {
        position: 'relative',
        "&.MuiCard-root": {
            width: "250px",
            height: "150px",
            cursor: 'pointer',
            margin: "10px 10px",
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
            
        },
        "& .MuiCardContent-root": {
            padding: '6px 6px !important'
        }
    },
    marRight10: {
        marginLeft: '10px'
    },
    activeCardTab: {
        borderBottom: "4px solid #1976d2 !important",
    },

    activeTab: {
        border: "1px solid #1976d2 !important",
    },
    subTab: {
        '&.MuiTab-root': {
            padding: '22px 6px !important',
            minWidth: 'unset !important',
            textTransform: 'unset !important',
            
        },
    },
    marginSpace: {
        margin: '16px 0px !important'
    }
    
}))