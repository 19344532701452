import React from 'react';
import { Card, CardContent,Divider,  Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx'
import _ from 'lodash';

/**
 * 
 * @param  title the title of the table
 * @param  columns a object array with key headerName and key fieldName the header name is displayed on top of the column, the field name is used to specify the fields for the rows
 * @param  rows a array of js objects, the key of a value must match the column field name
 * @returns Renders a table with the specified data
 */



const InfoTable = (props) => {

    const classes = useStyles();
    
    return ( 
        <div className={clsx(classes.row, classes.mrtop12)}>
        <Card className={classes.contentOverview2}>
            <InfoTableTitle title={props.title}/>
            <div className={classes.row}>
                <Divider
                    className={classes.fullWidth}
                    orientation="horizontal"
                />
            </div>
            <CardContent
                classes={{ root: classes.contentCard }}
                className="p-0"
            >
                <div style={{ maxHeight: "22%", overflow: "auto" }}>
                    <div className={classes.table}>
                        <InfoTableHeader columns={props.columns}/>
                        <div className={classes.row}>
                            <Divider
                                className={classes.fullWidth}
                                orientation="horizontal"
                            />
                        </div>
                        {!_.isEmpty(props.rows) ? (
                            <InfoTableRows columns={props.columns} rows={props.rows}/>
                        ) : (
                            <div className={classes.no_data}>
                                <Typography className={classes.no__data__typgoraphy}>
                                    NO DATA
        </Typography>
                            </div>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    </div>
    );
}

InfoTable.defaultProps = {
    height : "200px"
}

export default InfoTable;

const InfoTableTitle = (props) => {
    const classes = useStyles()

return (<div
    className={classes.table_title}
    component="span"
    variant="h6"
    >
    {props.title}
    </div>);
}


const InfoTableHeader = (props) => {
    const classes = useStyles();
    let reactKey = 0;
    return (
        <div className={classes.row}>
                            <div className={classes.header}>
                                {props.columns.map(column => (
                                    <div key={reactKey++} className={classes.column}>{column.headerName}</div>
                                ))}
                            </div>
                        </div>
    );
}

const InfoTableRows = (props) => {
    const classes = useStyles()
    const cellStyle = clsx(classes.column, classes.fns12);
    return (
        props.rows.map((item, index) => (
            <div key={index}>
                <div className={classes.row}>
                    <div className={classes.description}>
                        {props.columns.map(column => (
                            <div className={cellStyle}>
                                {item[column.fieldName]}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.row}>
                    <Divider
                        className={classes.fullWidth}
                        orientation="horizontal"
                    />
                </div>
            </div>
        ))
    )
} 

const useStyles = makeStyles((theme) => ({
    
    no__data__typgoraphy: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    no_data: {
        position: 'relative',
        marginTop: '20%',
        marginBottom: '20%',
        textAlign: 'center',
    },
    row: {
        display: 'flex',
        width: '100%',
        marginTop: "4px",
    },
    contentCard: {
        padding: "8px",
    },
    overview: {
        marginRight: "12px",
        marginLeft: "12px",
        height: 120
    },
    contentOverview: {
        width: "69%",
        padding: "12px",
    },
    contentOverview2: {
        width: "100%",
        paddingTop: "4px",
        paddingBottom: "12px",
    },
   
    fullWidth: {
        width: "100%",
    },
    /* accordion style */
    table_title: {
        fontSize: "0.867rem",
        fontWeight: 400,
        paddingLeft: 5,
        paddingBottom: 7
    },
    fns12: {
        fontSize: "12px !important",
        padding:0
    },
    column: {
        flex: 1,
        fontSize: "12px",
        paddingLeft: 20
    },
    description: {
        display: 'flex',
        width: '100%',
        margin: "0px 0px",
    },
    table: {
        width: '100%',
    },
    header: {
        fontWeight: 'bold',
        display: 'flex',
        width: '100%',
        marginBottom: "4px"
    },
    
}));
