import Home from "../Home";
import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import Yeshivas from "../../screens/yeshivas/Yeshivas";
import Programs from "../../screens/Programs/Programs";
import Contacts from "../../screens/contacts/contacts";
import Employees from "../../screens/employees/Employees";
import Professionals from "../../screens/professionals/Professionals"
import Timesheets from "../../screens/timesheets";
import AllTimesheet from "../../screens/AllTimesheets/AllTimesheets";
import Billing from "../../screens/billing/Billing";
import Users from '../../screens/users/Users';
import Lists from '../../screens/lists/lists';
import DashboardTask from '../../screens/Dashboard/TodoTask';
import DashboardProgress from '../../screens/Dashboard/Progress';
import DashboardTimesheet from '../../screens/Dashboard/Timesheet';
import DashboardSchedules from '../../screens/Dashboard/Schedules';
import DashboardClient from '../../screens/Dashboard/Client';
import Settings from '../../screens/settings/settings';
import Finance from '../../screens/finance/Finance';
import Dashboard from '../../screens/Dashboard';
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
import { accountService } from "../../_services/account.service";
import { tabsConst } from "../../constants";

const listOfMenuItems = () => {
    return [
        {
            id: '12',
            label: 'Dashboard',
            link: '/dashboard',
            icon: <PeopleIcon />,
            component: <Dashboard />,
            permissions: global.permissions.dashboard,

        },
        {
            id: '121',
            label: 'Task',
            hideMenu: true,
            link: '/dashboard/task',
            icon: <PeopleIcon />,
            component: <DashboardTask />,
            permissions: global.permissions.dashboard,
        },
        {
            id: '122',
            hideMenu: true,
            label: 'Monthly Progress',
            link: '/dashboard/progress',
            icon: <PeopleIcon />,
            component: <DashboardProgress />,
            permissions: global.permissions.dashboard.monthlyTasks
        },
        {
            id: '123',
            hideMenu: true,
            label: 'Timesheet',
            link: '/dashboard/timesheet',
            icon: <PeopleIcon />,
            component: <DashboardTimesheet />,
            permissions: global.permissions.dashboard.timesheet
        },
        {
            id: '124',
            hideMenu: true,
            label: 'Seeking Schedules',
            link: `/dashboard/${tabsConst.seekingSchedule}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.seeking
        },
        {
            id: '125',
            hideMenu: true,
            label: 'Financial Schedules',
            link: `/dashboard/${tabsConst.financialSchedule}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.financial
        },
        {
            id: '126',
            hideMenu: true,
            label: 'On Hold Schedules',
            link: `/dashboard/${tabsConst.onHoldSchedule}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.seeking
        },
        {
            id: '127',
            hideMenu: true,
            label: 'Active Schedules',
            link: `/dashboard/${tabsConst.activeSchedule}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.active
        },
        {
            id: '128',
            hideMenu: true,
            label: 'Rejected Schedules',
            link: `/dashboard/${tabsConst.rejectedSchedule}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.financial
        },
        {
            id: '129',
            hideMenu: true,
            label: 'Clients With Yungerleit',
            link: `/dashboard/${tabsConst.clientsWithYungerleit}`,
            icon: <PeopleIcon />,
            component: <DashboardClient />,
            permissions: global.permissions.dashboard.clientServices.yungerman
        },
        {
            id: '130',
            hideMenu: true,
            label: 'Clients With Professionals',
            link: `/dashboard/${tabsConst.clientsWithProfessionals}`,
            icon: <PeopleIcon />,
            component: <DashboardClient />,
            permissions: global.permissions.dashboard.clientServices.professional
        },
        {
            id: '131',
            hideMenu: true,
            label: 'Schedules by Payment Type',
            link: `/dashboard/${tabsConst.paymentTypeSchedule}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.active
        },
        {
            id: '132',
            hideMenu: true,
            label: 'Client Schedule Status',
            link: `/dashboard/${tabsConst.clientScheduleStatus}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.active
        },
        {
            id: '133',
            hideMenu: true,
            label: 'Status Step Duration',
            link: `/dashboard/${tabsConst.statusStepDuration}`,
            icon: <PeopleIcon />,
            component: <DashboardSchedules />,
            permissions: global.permissions.dashboard.schedules.active
        },
        {
            id: '11',
            label: 'Talmidim',
            link: '/clients',
            icon: <PeopleIcon />,
            component: <Home />,
            permissions: global.permissions.clients
        },
        {
            id: '1',
            label: 'Employees',
            link: ['/employees', '/employees/mamidim', '/employees/professionals', '/employees/all', '/employees/applications'],
            icon: <PeopleIcon />,
            component: <Employees />,
            permissions: global.permissions.employees,
            subMenu: [
                {
                    label: "Mamidim",
                    value: 'mamidim',
                    link: '/employees/mamidim'
                },
                {
                    label: "Professionals",
                    value: 'professionals',
                    link: '/employees/professionals'
                },
                {
                    label: "All",
                    value: 'all',
                    link: '/employees/all'
                },
                {
                    label: "Applications",
                    value: 'application',
                    link: '/employees/applications'
                },
            ]
        },
        {
            id: '3',
            label: 'Yeshivas',
            link: '/yeshivas',
            icon: <PeopleIcon />,
            component: <Yeshivas />,
            permissions: global.permissions.yeshivas
        },
        {
            id: '8',
            label: 'Users',
            link: '/users',
            icon: <PeopleIcon />,
            component: <Users />,
            permissions: global.permissions.users
        },
        {
            id: '9',
            label: 'Lists',
            link: '/lists',
            icon: <PeopleIcon />,
            component: <Lists />,
            permissions: global.permissions.lists
        },
        // { this is commented, because we want to hide it from the user in the current stage of building the app, we will uncomment this when this will be needed again
        //     id: '10',
        //     label: 'Settings',
        //     link: '/settings',
        //     icon: <PeopleIcon />,
        //     component: <Settings />,
        //     //permissions: global.permissions.settings
        // },
        {
            id: '13',
            label: 'Professionals',
            link: '/professionals',
            icon: <PeopleIcon />,
            component: <Professionals />,
            //permissions: global.permissions.professionals
        },
        {
            id: '2',
            label: 'Programs',
            link: '/programs',
            icon: <PeopleIcon />,
            component: <Programs />,
            //permissions: global.permissions.programs
        },
        {
            id: '4',
            label: 'Contacts',
            link: '/contacts',
            icon: <PeopleIcon />,
            component: <Contacts />,
            //permissions: global.permissions.contacts
        },
        {
            id: '5',
            label: 'Timesheets',
            link: '/timesheets',
            icon: <PeopleIcon />,
            component: <Timesheets />,
            permissions: global.permissions.timesheets
        },
        {
            id: '5',
            label: 'All Timesheets',
            link: '/allTimesheets',
            icon: <PeopleIcon />,
            component: <AllTimesheet />,
            permissions: global.permissions.timesheets
        },
        {
            id: '6',
            label: 'Billing',
            link: '/billing',
            icon: <PeopleIcon />,
            component: <Billing />,
            //permissions: global.permissions.billing
        },
        {
            id: '7',
            label: 'Finance',
            link: '/finance',
            icon: <PeopleIcon />,
            component: <Finance />,
            //permissions: global.permissions.finance
        },

    ];
}
export default listOfMenuItems;