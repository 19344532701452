import React, { useEffect } from "react";
import {
    Checkbox, Dialog, FormControl,
    FormControlLabel,
    FormHelperText, Input, InputLabel
} from "@mui/material";
import _ from "lodash";
import { Button } from "reactstrap";

import { contactCMSList } from "../../../../api/clients";
import ContactSelectNew from "../../../ContactSelect/ContactSelectNew";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
/**
 * editData:optional
 * props: openModal, handleClose(), Output:addYeshiva(selectedContact)
 */

const ContactRelSelect = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [openModal, setOpenModal] = React.useState(false);
    const [conatctName, setConatctName] = React.useState("");
    const [searchText, setSearchText] = React.useState("");
    const [contactList, setContactList] = React.useState([]);
    const [filterContactList, setFilterContactList] = React.useState([]);
    const [inputMode, setInputMode] = React.useState("selectExisting");
    const [relationship, setRelationship] = React.useState(null);
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [contactPerError, setContactPerError] = React.useState("");
    //   const [inputMode, setInputMode] = React.useState("createNewContact");
    const [fields, setFields] = React.useState({
        isAdmin: false,
        isActive: false,
    });

    // global.log("addyeshiva dialog" + props.openModal);
    useEffect(() => {
        if (!_.isEmpty(props.editData)) {
            const edit = props.editData;
            // global.log(edit);
            // setTitle(edit.title);
            setConatctName(edit.user_name);
            if (edit.role == "Admin") setFields({ isAdmin: true, isActive: false });
            else setFields({ isAdmin: false, isActive: true });
            // setDescription(edit.description);
            // setStartDate(edit.startDate);

            // setDueDate(moment(edit.due_date).format('MM/DD/YYYY'));
        }
    }, [props.editData]);

    useEffect(() => {
        setSelectedContact(null);
        setContactPerError("");
        setOpenModal(props.openModal);

        contactCMSList().then((response) => {
            setContactList(response);
            setFilterContactList(response);
        });
    }, []);

    const handInputMode = (e) => {
        setInputMode(e.target.value);
    };

    const handleClose = () => {
        setOpenModal(false);
        props.handleClose();
        // setSearchText("");
        // setFilterContactList(contactList);
    };

    const handleSelectRow = (contact) => {
        if (!contact) return;
        setSelectedContact(contact);
        setConatctName(`${contact.firstName} ${contact.lastName}`);
        // handleClose();
        // props.onSelectValue(contact.id);
    };

    const handleFields = (event) => {
        const { name, value, type } = event.target;
        if (type === "checkbox") {
            setFields({ ...fields, [name]: event.target.checked });
        } else {
            setFields({ ...fields, [name]: value });
        }
    };

    const submitFormData = () => {
        var isValid = true;

        if (conatctName == "") {
            isValid = false;
            setContactPerError("Please select contact");
        } else {
            setContactPerError("");
        }

        if (!isValid) return;
        props.handleClose();
        props.addYeshiva(fields, selectedContact);
    };

    const addNewRelationship = () => { };

    return (
        <>
            {/* <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label" htmlFor="name-multiple">
          {props.name ? props.name : "Contact"}
        </InputLabel>
        <Input
          value={conatctName}
          id="demo-simple-select-label"
          onClick={(event) => {
            setOpenModal(true);
          }}
          aria-describedby="my-helper-text"
        />
      </FormControl> */}
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openModal}
                // open={true}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            // style={{padding:20}}
            >
                <div style={{ padding: 20 }}>
                    <div className="mb-4">
                        <div>
                            <h5>{"Add a new user"}</h5>
                        </div>
                        <FormControl
                            className={classes.formControl}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <FormControlLabel
                                className="col-md-4"
                                style={{ padding: 0, margin: 0, marginLeft: -10 }}
                                control={
                                    <Checkbox
                                        checked={fields.isAdmin}
                                        onChange={handleFields}
                                        name="isAdmin"
                                        color="primary"
                                    />
                                }
                                label="Is Admin"
                            />
                            <FormControlLabel
                                // style={{ display: "inline-block", padding:0, margin:0 }}
                                control={
                                    <Checkbox
                                        checked={fields.isActive}
                                        onChange={handleFields}
                                        name="isActive"
                                        color="primary"
                                    />
                                }
                                label="Is Active"
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label" htmlFor="name-multiple">
                                {_.isEmpty(props.editData) &&
                                    "Select a contact below for a new user"}
                            </InputLabel>
                            <Input
                                value={conatctName}
                                id="demo-simple-select-label"
                                readOnly
                                onClick={(event) => {
                                    //   setOpenModal(true);
                                }}
                                aria-describedby="my-helper-text"
                            />
                            {contactPerError === "" ? null : (
                                <FormHelperText className={baseClasses.error} id="my-helper-text">
                                    {contactPerError}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <div className="float-right mt-3">
                            <Button
                                color="primary"
                                // onClick={props.handCloseRel}
                                onClick={() => {
                                    props.handleClose();
                                }}
                                className="mr-2"
                            >
                                Close
              </Button>
                            <Button
                                className={classes.save}
                                color="primary"
                                size="medium"
                                onClick={submitFormData}
                            >
                                {!_.isEmpty(props.editData) ? "Save" : "Add"}
                            </Button>
                        </div>
                    </div>
                    {_.isEmpty(props.editData) && (
                        <ContactSelectNew
                            handleClose={handleClose}
                            handleSelectRow={handleSelectRow}
                        ></ContactSelectNew>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default ContactRelSelect;
