import { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"


//credits: https://github.com/B3nnyL/react-zendesk


const canUseDOM = () => {
    if (typeof window === 'undefined' || !window.document || !window.document.createElement) {
        return false
    }
    return true
}

export const ZendeskAPI = (...args) => {
    if (canUseDOM && window.zE) {
        window.zE.apply(null, args)
    } else {
        console.warn("Zendesk is not initialized yet")
    }
}

const Zendesk = (props) => {
    const { defer, zendeskKey, onLoaded, ...other } = props;

    const onScriptLoaded = () => {
        if (typeof onLoaded === 'function') {
            onLoaded();
        }
    }

    const insertScript = (zendeskKey, defer) => {
        const script = document.createElement('script')
        if (defer) {
            script.defer = true
        } else {
            script.async = true
        }
        script.id = 'ze-snippet'
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`
        script.addEventListener('load', onScriptLoaded);
        document.body.appendChild(script)
    }
    useEffect(() => {
        if (canUseDOM && !window.zE) {
            insertScript(zendeskKey, defer)
            window.zESettings = other;
        }
        return () => {
            if (!canUseDOM || !window.zE) {
                return
            }
            delete window.zE
            delete window.zESettings
        }
    }, [])

    return null;
}

Zendesk.propTypes = {
    zendeskKey: PropTypes.string.isRequired,
    defer: PropTypes.bool
}

export default Zendesk;