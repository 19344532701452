import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    
    history_title: {
        paddingBottom: "8px",
        fontSize: "14px",
    },
    dob: {
        fontSize: "12px",
        padding: "12px",
    },
    cardContentHistory: {
        padding: "8px",
    },
    
}));
