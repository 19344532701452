import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: "12px 0px 12px 0px",
    margin: "12px 26px",
    "& .MuiCardContent-root:last-child": {
        padding: "0px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      cursor: "pointer",
      fontSize: "12px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-root": {
      border: "0px",
    },
  },

  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },

  marginSpaceOne: {
    // margin: theme.spacing(1),
    margin: "8px 0px 8px 0px",
  },

  flexRow: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  /* Schedule Style */

  overview_main: {
    padding: "8px",
    paddingBottom: "9px",
  },
  
  leftContentSection: {
    width: "69%",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  header: {
    fontWeight: "bold",
    display: "flex",
    width: "100%",
    marginBottom: "12px",
  },

}));
