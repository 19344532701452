import React from "react";
import ReactLoading from 'react-loading';
import { useTheme } from "@mui/styles";

export default function LoadingBar() {
    const theme = useTheme();
    return (
        <>
            <div >
                <div style={{ background: `rgb(255,255,255,0.6)`, position: 'relative', width: '100%', height: '100%', zIndex: '2' }}>
                    <div style={{ margin: '0', position: 'absolute', top: '100%', left: '43%', transform: `translate(50%, 170%)` }}>
                        <ReactLoading type={"bars"} color={theme.palette.primary.main} />
                    </div>
                </div>
            </div>
        </>
    )
}