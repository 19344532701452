import { alpha } from "@mui/material/styles";

const  buttons = theme => ({
    btnSave: {
        textAlign: "right",
        marginBottom: "12px",
        padding: "8px 12px",
    },
    new_button: {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
    },
    button_program: {
        float: 'right',
        paddingRight: '12px'
    },
    edit_icon: {
        margin: "5px",
        cursor: "pointer",
        padding: "2px",
    },
    icon: {
        cursor: "pointer",
    },

    task_button: {
        fontSize: "11px",
        textTransform: 'capitalize',
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
    },
    button: {
        textTransform: 'capitalize',
        color: '#FFFFFF',
      },
    fontIcons: {
        width: "12px",
        margin: "2px",
        height: "15px",
    },

    btnCancel: {
        background: 'rgba(0, 0, 0, 0.23)',
        fontSize: "11px",
        textTransform: 'capitalize',
        color: '#000',
    },

    btnDelete: {
        background: '#d32f2f !important',
        fontSize: "11px",
        textTransform: 'capitalize',
        color: '#FFFFFF',
    }
})

export default buttons