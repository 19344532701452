import * as React from 'react';
import {
    Button, Dialog,
    DialogActions,
    DialogTitle,
    DialogContent, DialogContentText,
} from '@mui/material';
import { strings } from "../../../_helpers/strings";
import useConfirm from '../../../hooks/useConfirm';

const DynamicConfirmDialog: React.FC = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();
    const { open, warnings, description, item, id, action } = confirmState;
    return (
        <div>
            <Dialog
                open={open}
                onClose={(event, reason) => { onCancel() }}
            >
                <DialogTitle>
                    {
                        `${strings.firstLetterUpperCase(item)} ${id} `
                        //`${warnings.length} warnings found`
                    }
                </DialogTitle>
                <DialogContent>
                    {warnings && warnings.map((i: any) => (
                        <>
                            <h6>{i.title}</h6>
                            <p>{i.description}</p>
                        </>
                    ))}
                    <DialogContentText>
                        <h6 style={{ color: warnings ? 'red' : 'black' }}>
                            {description ||
                                action ? "Are you sure you want to " + action + " this " + item + "?" : 'Are you sure you want to continue?'
                            }
                        </h6>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                >
                    <Button onClick={() => onCancel()} color="primary" autoFocus>
                        Cancel
                    </Button>
                    
                        <Button
                            autoFocus
                            onClick={() => {
                                onConfirm();
                                onCancel()
                            }}
                            color={warnings ? 'error' : 'primary'}
                        >

                            Confirm
                        </Button>
                    
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default DynamicConfirmDialog;