import React, {memo} from 'react';
import ReactLoading from 'react-loading';
import {loaderProps} from './common.types';
import { useTheme } from "@mui/styles";
const Loader: React.FC<loaderProps> = ({loading, type, color, height, width }) => {
    const theme: any = useTheme()
  return (
    <div style ={{
        width:'100%',
        height: '100%',
        background: 'rgba(256, 256, 256, 0.5)',
        position: 'absolute',
        display: loading ? "flex" : "none",
        justifyContent: 'center',
        alignItems: 'center',
        top: '0px',
        left: '0px',
        zIndex: 1000
    }}
    >
      <ReactLoading type={type ?? 'bars'} color={color ?? theme.palette.primary.main} height={height ?? undefined} width={width ?? undefined} />
    </div>
  )
}

export default memo(Loader)