import React, { useState, useEffect } from 'react';
import SideBar from './components/SideBar';
import ContactInfo from './ContactInfo';
import HistoryInfo from './HistoryInfo';
import AvailabilityInfo from './AvailabilityInfo';

const Application = () => {
    const [step, setStep] = useState(1);
    const [contactData, setContactData] = useState({});
    const [historyData, setHistoryData] = useState({});
    const [initialScheduleData, setInitialScheduleData] = useState([{}]);

    // const [availabilityData, setAvailabilityData] = useState({});

    const [availabilityData, setAvailabilityData] = useState([{

    }]);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const handlePrev = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = () => {
        console.log('Submitting form data...');
        console.log('Availability Data:', availabilityData);
    };

    useEffect(() => {
        console.log(contactData);
    }, [contactData]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {/* <SideBar /> */}
            <div style={{ flexGrow: 1, padding: '20px', backgroundColor: '#f5f5f5' }}>
                {step === 1 && (
                    <ContactInfo
                        setContactData={setContactData}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        defaultContactData={contactData}
                    />
                )}
                {step === 2 && (
                    <HistoryInfo
                        setHistoryData={setHistoryData}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        defaultHistoryData={historyData}
                    />
                )}
                {step === 3 && (
                    <AvailabilityInfo
                        initialScheduleData={initialScheduleData}
                        setInitialScheduleData={setInitialScheduleData}
                        availabilityData={availabilityData}
                        setAvailabilityData={setAvailabilityData}
                        handlePrev={handlePrev}
                        contactData={contactData}
                        historyData={historyData}
                    />
                )}
            </div>
        </div>
    );
};

export default Application;
