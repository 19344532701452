import { PROGRAM_GET_ID, PROGRAM_SAVE, SERVICE_LIST } from '../constants';

export default function program() {
    let programs_data = [];
    const programs = [];

    //return new Promise((resolve, rejects) => {
    //    fetch(PROGRAM_LIST)
    //    .then(response => response.json())
    //    .then(data => {
    //        programs_data = data;
    //        programs_data.forEach((item, index) => {
    //            programs.push({
    //                id: item.id,
    //                programName: item.programName,
    //                guidId: item.guidId,
    //                createdOn: item.createdOn,
    //            });
    //        });
    //        resolve(programs);
    //    }, (rejects) => {
    //        global.log('rejects');
    //    });
    //});
    return new Promise((resolve, rejects) => {
            global.fetchWrapper.get(SERVICE_LIST)
            .then(data => {
                programs_data = data;
                programs_data.forEach((item, index) => {
                    programs.push({
                        id: item.id,
                        programName: item.programName,
                        guidId: item.guidId,
                        createdOn: item.createdOn,
                    });
                });
                resolve(programs);
            }, (rejects) => {
                global.log('rejects');
            });
    });
}

export let ServiceList = () => {
    let detailOfContactsList;
    return new Promise((resolve, rejects) => {
            global.fetchWrapper.get(SERVICE_LIST)
            .then(data => {
                if (data.length) {
                    resolve(data);
                } else {
                    resolve([]);
                }
            }, (rejects) => {
                global.log('rejects');

                // rejects();
                resolve([]);
            });
    });
}
export let getprogramDetails = (id) => {
    let detailOfprogram;
    return new Promise((resolve, rejects) => {
            global.fetchWrapper.get(PROGRAM_GET_ID + id)
            .then(data => {
                if (data.responseCode === 200) {
                    resolve(data.result);
                } else {
                    resolve(true);
                }
            }, (rejects) => {
                global.log('rejects');
                // rejects();
                resolve(true);
            });
    });
}

export let addprogramDetails = (data, id) => {
    return global.fetchWrapper.post(PROGRAM_SAVE + '/' + id, data)
        .then((response) => {
            global.log('response', response);
        }, (error) => {
            global.log('error', error);
        });
}

export const columnsProgram = [
    { field: 'programName', headerName: 'Program Name', width: 200, sortable: false }

];