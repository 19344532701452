import React, { useState, useEffect } from 'react';
import _ from "lodash";
import ContactSelectField from "../../../ContactSelect/ContactSelectField";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RichTextField from '../../../UI/RichTextField';
import { strings } from '../../../../_helpers/strings'
import {
    FormControl,
    FormHelperText,
    Grid,
    TextField
} from "@mui/material";
import useStyles from "../Dialog/styles";
import baseStyles from '../../../../assets/styles';

export const validateAllFields = (item) => {
    let isValid = true;
    let newErrors = {};
    if (!strings.getRichTextObjFromString(item.title).value) {
        isValid = false;
        newErrors.title = "please enter title"
    }
    if (!item.assignee_ContactId) {
        isValid = false;
        newErrors.assignee_ContactId = "task assignee is required"
    }
    if (item.completedDate && new Date(item.completedDate) < new Date(item.startDate)) {
        isValid = false;
        newErrors.completedDate = "Completed date of a task cannot be before its start"
    }
    return { isValid, errors: newErrors };
}

const AddTodoForm = (props) => {
    const { todo, setTodo, setDirty, setValidator, isFromProgress, errors_prm, isTask } = props;
    const classes = useStyles();
    const baseClasses = baseStyles();

    useEffect(() => {
        setTodo((prev) => { return { ...prev, todo } });
    }, [])

    const [errors, setErrors] = useState({})
    useEffect(() => {
        if (!!errors_prm)
            setErrors(errors_prm);
        else {
            //kind of a hack should be changed
            if (typeof setValidator === 'function')
                setValidator(() => (item) => {
                    const validation = validateAllFields(item);
                    setErrors(validation.errors);
                    return validation.isValid;
                })
        }
    }, [errors_prm, setValidator]);

    const updateTodo = (name, value) => {
        setTodo((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const updateErrors = (name, errMsg) => {
        setErrors((prev) => {
            return {
                ...prev,
                [name]: errMsg
            }
        })
    }

    return (<div>
        {todo.creator ? (
            <div>
                <p>Created By: {todo.creator}</p>
            </div>
        ) : (<div></div>)
        }

        <Grid
            container
            className={baseClasses.grid}
            alignItems="flex-start"
            spacing={2}
        >
            {!isFromProgress &&
                <Grid item xs={12}>
                    <div>
                        <FormControl fullWidth>
                            <RichTextField
                                value={todo.title}
                                label={"Title"}
                                id="addtodo-title"
                                variant="outlined"
                                onChange={(event) => {
                                    if (typeof setDirty === 'function')
                                        setDirty();
                                    if (!_.isEmpty(event.target.value)) {
                                        updateErrors('title', '');
                                    }
                                }}
                                setState={(val) => { updateTodo('title', val) }}
                                aria-describedby="my-helper-text"
                            />
                            {errors.title && (
                                <FormHelperText
                                    className={baseClasses.error}
                                    style={{ color: 'red' }}
                                    id="my-helper-text"
                                >
                                    {errors.title}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </Grid>

            }


            <Grid item xs={12}>
                <div>
                    <ContactSelectField
                        label="Select Assignee"
                        userRole="any"
                        showUserRoles={true}
                        allowNew={false}
                        editData={todo.assignee_ContactId}
                        editDataType="id"
                        onChange={(contact) => {
                            if (typeof setDirty === 'function')
                                setDirty();
                            updateTodo('assignee_ContactId', contact?.id)
                        }}
                    ></ContactSelectField>
                    {errors.assignee_ContactId && (
                        <FormHelperText
                            className={baseClasses.error}
                            style={{ color: 'red' }}
                            id="my-helper-text"
                        >
                            {errors.assignee_ContactId}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            {/* <Grid item xs={12}>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="my-input">Assignee</InputLabel>
                      <Input
                        value={assignee}
                        id="my-input"
                        onFocus={() => {
                          _.isEmpty(todo) && setIsSelectOpen(true);
                        }}
                        aria-describedby="my-helper-text"
                      />
                      {assigneePerError === "" ? null : (
                        <FormHelperText
                          className={baseClasses.error}
                          id="my-helper-text"
                        >
                          {assigneePerError}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </Grid> */}
            {isTask &&
                <Grid item xs={12}>
                    <div>

                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label={"Start Date"}
                                    value={todo.startDate ?? null}
                                    onChange={(val) => {
                                        if (typeof setDirty === 'function')
                                            setDirty();
                                        if (!_.isEmpty(val)) {
                                            updateErrors('startDate', '');
                                        }
                                        updateTodo('startDate', val);
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" variant={"outlined"} fullWidth />}
                                />
                            </LocalizationProvider>
                            {errors.startDate && (
                                <FormHelperText
                                    className={baseClasses.error}
                                    style={{ color: 'red' }}
                                    id="my-helper-text"
                                >
                                    {errors.startDate}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </Grid>}
            {isTask &&
                <Grid item xs={12}>
                    <div>

                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    value={todo.dueDate ?? null}
                                    label={"Due Date"}
                                    onChange={(val) => {
                                        if (typeof setDirty === 'function')
                                            setDirty();
                                        if (!_.isEmpty(val)) {
                                            updateErrors('dueDate', '');
                                        }
                                        updateTodo('dueDate', val);
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" variant={"outlined"} fullWidth />}
                                />
                            </LocalizationProvider>
                            {errors.dueDate && (
                                <FormHelperText
                                    className={baseClasses.error}
                                    id="my-helper-text"
                                >
                                    {errors.dueDate}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </Grid>}

            {!isFromProgress && isTask &&
                <Grid item xs={12}>
                    <div>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    value={todo.completedDate ?? null}
                                    label={"Completed Date"}
                                    onChange={(val) => {
                                        if (typeof setDirty === 'function')
                                            setDirty();
                                        let value = val;
                                        updateTodo('completedDate', value);
                                        if (value && new Date(value) < new Date(todo.startDate)) {
                                            updateErrors('completedDate', "Completed date of a task cannot be before its start");
                                        } else {
                                            updateErrors('completedDate', "");
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" variant={"outlined"} fullWidth />}
                                />
                            </LocalizationProvider>
                            {errors.completedDate && (
                                <FormHelperText
                                    className={baseClasses.error}
                                    style={{ color: 'red' }}
                                    id="cmpldte-err"
                                >
                                    {errors.completedDate}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </Grid>
            }


            {/* <Grid item xs={12}>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="my-input">Approve Hours</InputLabel>
                      <Input
                        type="number"
                        value={approveHours}
                        id="my-input"
                        inputProps={{ min: "0" }}
                        onChange={(event) => {
                        if (typeof setDirty === 'function')
                          setDirty();
                          if (!_.isEmpty(event.target.value)) {
                            setApproveHoursPerError("");
                          }
                          setApproveHours(event.target.value);
                        }}
                        aria-describedby="my-helper-text"
                      />
                      {approveHoursPerError == "" ? null : (
                        <FormHelperText
                          className={baseClasses.error}
                          id="my-helper-text"
                        >
                          {approveHoursPerError}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </Grid> */}

            <Grid item xs={12}>
                <div>
                    <FormControl fullWidth>
                        <RichTextField
                            id="my-input"
                            label="Description"
                            multiline
                            rows={isFromProgress ? 2 : 4}
                            value={todo.description}
                            onChange={(event) => {
                                if (typeof setDirty === 'function')
                                    setDirty();
                                if (!_.isEmpty(event.target.value)) {
                                    updateErrors('description', "");
                                }

                            }}
                            setState={(val) => { updateTodo('description', val) }}
                        />

                        {/* 
                      <Input
                        value={description}
                        id="my-input"
                        onChange={(event) => {
                        if (typeof setDirty === 'function')
                          setDirty();
                          if (!_.isEmpty(event.target.value)) {
                            setDescriptionPerError("");
                          }
                          setDescription(event.target.value);
                        }}
                        aria-describedby="my-helper-text"
                      /> */}
                        {errors.description && (
                            <FormHelperText
                                className={baseClasses.error}
                                style={{ color: 'red' }}
                                id="my-helper-text"
                            >
                                {errors.description}
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
            </Grid>
        </Grid>
    </div>)
}

export default AddTodoForm;