import {
    Dialog,
    DialogActions, DialogTitle, FormControl,
    Grid,
    InputAdornment, InputLabel,
    List,
    ListItem,
    ListItemText, MenuItem,
    Select, Switch,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useTheme } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStoreActions, useStoreState } from "easy-peasy";
import _, { constant } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ContactSelectField from "../ContactSelect/ContactSelectField";
import { careManager } from "../data/clients";
import TextInputWrapper from "../../utils/TextInputWrapper";
import TextSingleWrapper from "../../utils/TextSingleWrapper";
import useStyles from "./programStyles";
import baseStyles from '../../assets/styles'
import * as constants from "../../constants";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

const CompTextlineInput =  ({ label, name, allowedDollar, handleSaveEnable }) => {
    const baseClasses = baseStyles();
    const employeeDetail = useStoreState((state) => state.employee.detail);
    const [value, setValue] = React.useState(employeeDetail.summerColonyContact[name]);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);

    return (
        <Grid
            item
            xs={12}
            className={baseClasses.document_upload}
            style={{ marginLeft: "-16px" }}
        >
            <FormControl className="w-100">
                <FormControlLabel
                    style={{ display: "flex", justifyContent: "space-between" }}
                    control={
                        <TextField
                            type={!allowedDollar ? "text" : "number"}
                            onBlur={(event) => {
                                setEmployeeDetail({
                                    ...employeeDetail,
                                    summerColonyContact: { ...employeeDetail.summerColonyContact, [name]: event.target.value }
                                });
                                handleSaveEnable(true);
                            }}
                            onChange={(event) => setValue(event.target.value)}
                            style={{ textAlign: "center", marginLeft: "5px !important", width: "calc(100% - 150px)" }}
                            name={name}
                            value={!global.isEmpty(value) ? value : allowedDollar ? 0 : ""}
                            // className={baseClasses.hoursWidget}
                            InputProps={
                                !allowedDollar ? {} : {
                                    endAdornment: (
                                        <InputAdornment position="end">&nbsp;$</InputAdornment>
                                    ),
                                }}
                        />
                    }
                    label={label}
                    labelPlacement="start"
                />
            </FormControl>
        </Grid>
    );
};

export default function Programs(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [isSaveEnable, setIsSaveEnable] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const exploringId = useStoreState((state) => state.exploringId);
    const employeeDetail = useStoreState((state) => state.employee.detail);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);
    const [tempSaveData, setTempSaveData] = React.useState({
        employeeDetail: employeeDetail,
    });

    const CompSelectbox = ({ label, name, value }) => {
        return (
            <FormControl fullWidth className={baseClasses.optionsContract}>
                <InputLabel id="demo-controlled-open-select-label-2">
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-controlled-open-select-label-2"
                    id="demo-controlled-open-select"
                    value={employeeDetail[name] == true ? "Yes" : employeeDetail[name] == false ? "No" : "Undecided"}
                    name={name}
                    onChange={(event) => {
                        var stringBool = event.target.value;
                        var dest = null;
                        if (stringBool == "Yes")
                            dest = true;
                        else if (stringBool == "No")
                            dest = false;
                        setEmployeeDetail({ ...employeeDetail, [name]: dest });
                        setIsSaveEnable(true);
                    }}
                    className={baseClasses.selection}
                    style={{ marginLeft: 0 }}
                >
                    {
                        ["Yes", "No", "Undecided"].map((column) => (
                            <MenuItem value={column} key={column}>
                                {column}
                            </MenuItem>
                        ))}

                </Select>
            </FormControl>
        );
    };

    const SectionHeading = ({ title }) => {
        return (
            <Typography
                className={baseClasses.card_title}
                // variant="h6"
                gutterBottom
            >
                {title}
            </Typography>
        );
    };

    const CompCheckbox = ({ label, name }) => {
        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={global.isEmpty(employeeDetail[name]) ? false : employeeDetail[name]}
                            onClick={(event) => {
                                var _checked = global.isEmpty(employeeDetail[name]) ? false : employeeDetail[name]

                                //enable save button
                                setIsSaveEnable(true);
                                setEmployeeDetail({
                                    ...employeeDetail, [name]: !_checked
                                })
                            }}
                            color="primary"
                        />
                    }
                    labelPlacement="end"
                    label={label}
                    style={{ margin: 0 }}
                />
            </div>
        );
    };
    const onSaveRightCard = () => {
        setIsSaveEnable(false);

        global.fetchWrapper.post(constants.EMPLOYEE_SAVE_SUMMER + exploringId, employeeDetail)
            .then(
                (data) => {
                    // global.log(data);
                },
                (rejects) => {
                    global.AjaxFailToast();
                }
            );
    };

    const handleSaveEnable = (value) => {
        setIsSaveEnable(value)
    }
    return (
        <>
            <Button
                onClick={onSaveRightCard}
                className={baseClasses.capitalized}
                color="primary"
                variant="contained"
                disabled={!isSaveEnable}
                style={{ float: "right", marginBottom: 10 }}
            >
                Save
            </Button>
            <br />
            <Card variant="outlined" style={{ width: "100%" }} >
                <CardContent classes={{ root: baseClasses.card_view }}>
                    <div>
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={5} style={{ marginLeft: "30px" }}>
                                <CompSelectbox
                                    label={"Is in the mountains summer"}
                                    name="isInTheMountainsSummer"
                                    value={employeeDetail.isInTheMountainsSummer}
                                />
                                {employeeDetail["isInTheMountainsSummer"] === true && <>
                                    <div className={baseClasses.row_section} style={{ marginTop: "30px" }}>
                                        <SectionHeading
                                            title={"Address"}
                                        ></SectionHeading>
                                    </div>
                                    <CompTextlineInput
                                        label="Bungalow Colony : "
                                        name="firstName"
                                        allowedDollar={false}
                                        handleSaveEnable={handleSaveEnable}
                                    />
                                    <CompTextlineInput
                                        label="Address : "
                                        name="address1"
                                        allowedDollar={false}
                                        handleSaveEnable={handleSaveEnable}
                                    />
                                    <CompTextlineInput
                                        label="PO Box : "
                                        name="address2"
                                        allowedDollar={false}
                                        handleSaveEnable={handleSaveEnable}
                                    />
                                    <CompTextlineInput
                                        label="Town : "
                                        name="city"
                                        allowedDollar={false}
                                        handleSaveEnable={handleSaveEnable}
                                    />
                                    <CompTextlineInput
                                        label="State : "
                                        name="state"
                                        allowedDollar={false}
                                        handleSaveEnable={handleSaveEnable}
                                    />
                                    <CompTextlineInput
                                        label="Zip : "
                                        name="zip"
                                        allowedDollar={false}
                                        handleSaveEnable={handleSaveEnable}
                                    />
                                </>
                                }
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={3} style={{ paddingTop: "90px" }}>
                                {employeeDetail["isInTheMountainsSummer"] === true && <>
                                    <CompCheckbox label="Has Licence" name="hasLicense"></CompCheckbox>
                                    <CompCheckbox label="Has Car" name="hasCar"></CompCheckbox>
                                </>}
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
            <Dialog
                fullScreen={fullScreen}
                open={openConfirm}
                onClose={() => {
                    setOpenConfirm(false);
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to cancel your changes?"}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setEmployeeDetail(tempSaveData.employeeDetail);
                            setOpenConfirm(false);
                            setIsSaveEnable(false);
                            setTempSaveData({
                                employeeDetail: employeeDetail,
                            });
                        }}
                        color="primary"
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenConfirm(false);
                        }}
                        color="primary"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};