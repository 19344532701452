import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    "& .MuiListItem-root .Mui-selected": {
      backgroundColor: "rgb(83, 109, 253)",
      color: "white",
      borderRadius: 3,
    },
  },
  content: {
    height: "100%",
    flexGrow: 1,
    padding: "12px 0px 12px 0px",
    margin: "12px 26px",
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
    },
    "& .MuiCardContent-root:last-child": {
        padding: "0px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
        cursor: "pointer",
        fontSize: "12px",
    },
    /*'& .MuiPaper-outlined': {
            border: '0px',
        }*/
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
        display: "none !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
        visibility: "hidden",
    },
    "& .MuiDataGrid-root": {
        border: "0px",
    },
},
  leftSection: {
      width: "100%",
    display: "inline-block",
    verticalAlign: "top",
    marginRight: 15,
    borderBottom: "solid 3px #dddddd",
    "& span": {
      fontSize: "13px !important",
    },
    "& .MuiListItem-root" : {
      borderRadius: "6px !important",
      padding: "22px 11px !important"
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      color: "#0056b3 !important",
    },
  },
  rightSection: {
    width: "100%",
    display: "inline-block",
    },
  sectionCard: {
    width: "100%",
  },
  
  // Programs
  contentOfaccordion: {
    width: "100%",
    padding: "4px 16px",
    margin: "6px 2px",
  },
  row_section: {
    display: "flex",
  },
  card_title: {
    flex: 14,
  },
  
}));
