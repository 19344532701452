export const viewSettingStructure = {
    columnSettings: {

    },
    filterModel: {
        items: []
    },

}

export const dataGridFiltersOperatorTypes = {
    'isAnyOf': {
        operator: 'isAnyOf',
        valueType: 'array',
    },
    'is empty': {
        operator: 'is empty',
        valueType: 'boolean',
    },
    'is not empty': {
        operator: 'is not empty',
        valueType: 'boolean',
    },
    "=": {
        operator: '=',
        valueType: 'integer',
    },
    "!=": {
        operator: '!=',
        valueType: 'integer',
    },
    ">": {
        operator: '>',
        valueType: 'integer',
    },
    "=>": {
        operator: '=>',
        valueType: 'integer',
    },
    "<": {
        operator: '<',
        valueType: 'integer',
    },
    "=<": {
        operator: '=<',
        valueType: 'integer',
    },
    "contains": {
        operator: 'contains',
        valueType: 'string',
    },
    "equals": {
        operator: 'equals',
        valueType: 'string',
    },
    "starts with": {
        operator: 'starts with',
        valueType: 'string',
    },
    "ends with": {
        operator: 'ends with',
        valueType: 'string',
    },
    "is": {
        operator: 'is',
        valueType: 'boolen',
    },
    "is not": {
        operator: 'is  not',
        valueType: 'string',
    },
    "is after": {
        operator: 'is after',
        valueType: 'string',
    },
    "is on or after": {
        operator: 'is on or after',
        valueType: 'string',
    },
    "is before": {
        operator: 'is before',
        valueType: 'string',
    },
    "is on or before": {
        operator: 'is on or before',
        valueType: 'string',
    },

}

export const defaultQuickFilters = {
    home: {
        "ShowDisenrolled": 'false'
    },
    employee: {
        "ShowInactive": 'false',
        "ShowPending": 'false',
        "ShowNon-approved": 'false'

    },
    employee_professionals: {
        "ShowInactive": 'false',
        "ShowPending": 'false',
        "ShowNon-approved": 'false'
    },
    employee_mamidim: {
        "ShowInactive": 'false',
        "ShowPending": 'false',
        "ShowNon-approved": 'false'
    },
    employee_applications: {
        "ShowInactive": 'false',
        "ShowPending": 'false',
        "ShowNon-approved": 'false'
    }
}

export const SYSTEM_VIEW_STRUCT = {
    viewName: 'Main',
    dataGridName: 'system',
    isDefault: false,
    columnSettings: [],
    filterSettings: []
}

export const defaultColumnsAndFilters = {
    'employee': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'employee_professionals': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'employee_mamidim': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'employee_applications': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'home': {
        columnSettings: {
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            roshMeargen: {
                isVisible: false
            },
            careManager: {
                isVisible: false
            },
            careManagerSupervisor: {
                isVisible: false
            },
            hcbsCoordinator: {
                isVisible: false
            },
            neighborhood: {
                isVisible: false
            },
            phone1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            dateEnrolledComfort: {
                isVisible: false
            },
            dateApprovedComfort: {
                isVisible: false
            },
            isPrevEnrolledInCH: {
                isVisible: false
            },
            comfortNote: {
                isVisible: false
            },
            pendedOn: {
                isVisible: false
            },
            isPended: {
                isVisible: false
            },
            sentDocsToParentsOn: {
                isVisible: false
            },
            insuranceCompany: {
                isVisible: false
            },
            sentBinderToComfortHealthOn: {
                isVisible: false
            },
            careManagerAssignedOn: {
                isVisible: false
            },
            intakeMeetingDate: {
                isVisible: false
            },
            cans_CompletedOn: {
                isVisible: false
            },
            crisis_CompletedOn: {
                isVisible: false
            },
            poc_CompletedOn: {
                isVisible: false
            },
            comprehensiveAssessment_CompletedOn: {
                isVisible: false
            },
            loc_CompletedOn: {
                isVisible: false
            },
            packetSentOn: {
                isVisible: false
            },
            comfortHealthTimeRequirements: {
                isVisible: false
            },
            sentIepToNewAgencyOn: {
                isVisible: false
            },
            dateEnrolledP3: {
                isVisible: false
            },
            dateApprovedP3: {
                isVisible: false
            },
            hoursPerWeekP3: {
                isVisible: false
            },
            dateEnrolledLight: {
                isVisible: false
            },
            enrolledOn: {
                isVisible: false
            },
            disenrolledOn: {
                isVisible: false
            },
            uploadedInsuranceOn: {
                isVisible: false
            },
            uploadedConsentFormOn: {
                isVisible: false
            },
            uploadedSedOn: {
                isVisible: false
            },
            uploadedIepOn: {
                isVisible: false
            },
            isSeeingTherapist: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
}