import React, { useEffect, useState } from 'react';
import { Chip, Grid, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useStoreState } from 'easy-peasy';
import { employeeInfoResponseType, clientInfoResponseType } from './types'
import { getEmployeeInfo } from '../../../../api/employees';
import { getClientInfo } from '../../../../api/clients';
import { explorerTypesConst } from '../../../../constants';
import { BoldTitle, BoxContainer, DivContainer, FlexContainer, SpacingBlock, Text } from '../../../../assets/styles/styledComponents';
import useBaseStyles from '../../../../assets/styles/styles';
import FeaturedIcon from '../../../common/FeaturedIcon';
import useStyles from './style';
import InfoBox from './InfoBox';
import AnimLoadingState from '../../../common/AnimLoadingState';

const Info = () => {
    const baseClasses: any = useBaseStyles();
    const classes: any = useStyles();
    const globalConst: any = global;
    const [loading, setLoading] = useState<boolean>(false);
    const [employeeInfo, setEmployeeInfo] = useState<employeeInfoResponseType>();
    const [clientInfo, setClientInfo] = useState<clientInfoResponseType>();
    const exploringId = useStoreState((state: any) => state.exploringId);
    const exploringType = useStoreState((state: any) => state.exploringType);

    useEffect(() => {
        loadInfoData(exploringId)
    }, [])

    const loadInfoData = (id: number) => {
        setLoading(true);
        if (exploringType === explorerTypesConst.employees)
            getEmployeeInfo(id).then((data: employeeInfoResponseType) => {
                setEmployeeInfo(data);
                setLoading(false);
            });
        if (exploringType === explorerTypesConst.clients) {
            getClientInfo(id).then((data) => {
                setClientInfo(data);
                setLoading(false);
            })
        }
    }

    const getIconDate = (data: employeeInfoResponseType, check: string, property: string) => {
        return (
            <Tooltip title={!!data[check as keyof typeof data] ?
                globalConst.dateTime.getUSAFormattedDateString(data[property as keyof typeof data])
                : ''}
            >
                <div>
                    {!!data[check as keyof typeof data] ? <FeaturedIcon style={{ padding: "0px", }} color="primary" icon={"check"} /> : <FeaturedIcon style={{ padding: "0px", }} icon={"Cross"} />}
                </div>
            </Tooltip>
        )
    }

    const getEmployeeInfoFields = () => {
        return {
            personal: [
                { title: 'Name', property: 'fullName' },
                { title: 'Hebrew Name', property: 'fullHebrewName' },
                { title: 'Legal Name', property: 'fullLegalName' },
                { title: 'Address', property: 'address' },
                { title: 'Neighborhood', property: 'neighborhood' },
                { title: 'Home Phone', property: 'homePhone' },
                { title: 'Cell phone', property: 'cellphone' },
                { title: 'Email', property: 'email' },
                { title: 'Has Text', property: 'hasText' },
                { title: 'Age', property: 'age' },
                { title: 'DOB', property: 'dob' },
            ],
            ruchniyos: [
                { title: 'Years of Experience with Bucherim', property: 'yearsOfExperienceWithBucherim' },
                { title: 'List of Yeahivas Previously worked', property: 'prevYeshivas' },
                { title: 'Experience', property: 'experience' },
                { title: 'Note', property: 'note' },
                { title: 'Specialties', property: 'specialties' },
                { title: 'Lhisalos Contract date uploaded', property: 'lhisalosContract' },
            ],
            financial: [
                { title: 'Rate', property: 'rate' },
                { title: 'Rate Note', property: 'rateNote' },
                { title: 'Parsonage limit', property: 'parsonageLimit' },
                { title: 'ID', property: 'id', icon: 'check', hover: true, type: 'date' },
                { title: 'Parsonage Form', property: 'parsonageForm', icon: 'check', hover: true },
                { title: 'Parsonage Form Expiration', property: 'parsonageFormExpiration' },
                { title: 'I9', property: 'i9', icon: 'check', hover: true },
                { title: 'Financial Notes', property: 'financialNotes' },
                { title: 'Payment Options', property: 'paymentOptions' },
                { title: 'Payment Delivery', property: 'paymentDelivery' },
            ],
            summer: [
                { title: 'Is in the mountains summer', property: 'isInTheMountainsSummer', type: 'bool', icon: 'check', hover: false },
                { title: 'Has Licence', property: 'hasCar', type: 'bool', icon: 'check', hover: false },
                { title: 'Has Licence', property: 'hasLicense', type: 'bool', icon: 'check', hover: false },
                { title: 'Address', property: 'summerColony_ContactId' },
            ],
            comfort: [
                { title: 'Enrollments', type: 'section', property: '' },
                { title: 'Planning to enroll', property: 'planningToEnrollComfortHealth', type: 'bool', icon: 'check' },
                { title: 'Completed application', property: 'hasComfortHealthApplication', type: 'bool', icon: 'check', },
                { title: 'ID', property: 'hasID', type: 'bool', icon: 'check', hover: true },
                { title: 'High school diploma', getValue: (data: employeeInfoResponseType) => { getIconDate(data, "hasHighSchoolDiploma", "highSchoolDiploma") }, property: '' },
                { title: 'Associate\'s Degree', getValue: (data: employeeInfoResponseType) => { getIconDate(data, "hasAssociatesDegree", "AssociatesDegree") }, property: '', },
                { title: 'BA', getValue: (data: employeeInfoResponseType) => { getIconDate(data, "hasAssociatesDegree", "BA") }, property: '', },
                { title: 'Dragon Profile', property: 'dragonProfileCreatedOn', type: 'date', icon: 'check', hover: true },
                { title: 'Sent to Comfort Health', property: 'dragonProfileCreatedOn', type: 'date', icon: 'check', hover: true },
                {
                    title: 'Fingerprint Appointment', getValue: (data: employeeInfoResponseType) => {
                        return (
                            <div>{!data["isFingerprinted"] && globalConst.dateTime.getUSAFormattedDateString(data["fingerprintingScheduleDate"])}</div>
                        )
                    }, property: '',
                },
                { title: 'Fingerprinted', property: 'fingerprintedOn', type: 'date', icon: 'check', hover: true },
                { title: 'Approved Comfort Health', property: 'comfortHealthApprovedOn', type: 'date', icon: 'check', hover: true },
                { title: 'Approved CATS', property: 'catsApprovedOn', type: 'date', icon: 'check', hover: true },
                { title: 'Approved Prevoc', property: 'prevocApprovedOn', type: 'date', icon: 'check', hover: true },
                { title: 'Info', type: 'section', property: '' },
                { title: 'Comfort Health ID', property: 'comfortHealthId', },
                { title: 'Amount could work', property: 'amountCouldWork', },
                { title: 'Amount billing', property: 'prevocApprovedOn' },
                { title: 'Special Requirements', property: 'specialRequirements' },
                { title: 'Hours Providing ', property: 'hoursProviding' },
                { title: 'Hours Could Work', property: 'hoursCouldWork' },
                { title: 'Receive schedule by', property: 'receivedScheduleBy' },
            ]
        }
    }

    const getClientInfoFields = () => {
        return {
            personal: [
                { title: 'Name', property: 'fullName' },
                { title: 'Hebrew Name', property: 'fullHebrewName' },
                { title: 'Legal Name', property: 'fullLegalName' },
                { title: 'Address', property: 'address' },
                { title: 'Age', property: 'age' },
                { title: 'DOB', property: 'dob', type: 'date' },
                { title: 'Legal Guardian', property: 'legalGuardian' },
                { title: 'Primary Contact', property: 'primaryContact' },
                { title: 'Insurance', property: 'insurance' },
                { title: 'CIN', property: 'cin' },
                { title: 'Insurance Id', property: 'insuranceId' },
            ],
            yeshiva: [
                { title: 'Yeshiva', property: 'yeshivaName' },
                { title: 'Grade', property: 'grade' },
            ],
            lHisalosInfo: [
                { title: 'Date Enrolled Lhisalos', property: 'dateEnrolledLhisalos', type: 'date' },
                { title: 'Date Disenrolled Lhislaos', property: 'dateDisenrolledLhisalos', type: 'date' },
                { title: 'Stage', property: 'stage' },

                { title: 'Meargen', type: 'section', property: '' },
                { title: 'Name', property: 'meargenName' },
                { title: 'Cell phone', property: 'meargenCellPhone' },
                { title: 'Email', property: 'meargenEmail' },

                { title: 'Rosh Meargen', type: 'section', property: '' },
                { title: 'Name', property: 'roshMeargenName' },
                { title: 'Cell phone', property: 'roshMeargenCellPhone' },
                { title: 'Email', property: 'roshMeargenEmail' },
            ],

            contactInfo: [
                { title: 'Home Phone', property: 'homePhone' },

                { title: 'Father', type: 'section', property: '' },
                { title: 'Name', property: 'fatherFullName' },
                { title: 'Cell phone', property: 'fatherCellPhone' },
                { title: 'Email', property: 'fatherEmail' },

                { title: 'Mother', type: 'section', property: '' },
                { title: 'Name', property: 'motherFullName' },
                { title: 'Cell phone', property: 'motherCellPhone' },
                { title: 'Email', property: 'motherEmail' },



            ],
            comfortEnrollment: [
                { title: 'Comfort ID', property: 'comfortId' },
                { title: 'Comfort approved on', property: 'comfortApprovedOn', type: 'date' },

                { title: 'Care Manager', type: 'section', property: '' },
                { title: 'Name', property: 'careManagerName' },
                { title: 'Cell phone', property: 'careManagerCellPhone' },
                { title: 'Email', property: 'careManagerEmail' },

                { title: 'Care Manager Supervisor', type: 'section', property: '' },
                { title: 'Name', property: 'careManagerSupervisorName' },
                { title: 'Cell phone', property: 'careManagerSupervisorCellPhone' },
                { title: 'Email', property: 'careManagerSupervisorEmail' },

                { title: 'HCBS Coordinator', type: 'section', property: '' },
                { title: 'Name', property: 'HCBSCoordinatorName' },
                { title: 'Cell phone', property: 'HCBSCoordinatorCellPhone' },
                { title: 'Email', property: 'HCBSCoordinatorEmail' },

            ],
        }
    }

    const infoEmpFields = getEmployeeInfoFields();
    const infoCliFields = getClientInfoFields();

    return (
        <BoxContainer position="relative" padding={10} background="#fff">
            <AnimLoadingState loading={loading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
            <FlexContainer display={exploringType === explorerTypesConst.employees ? 'flex' : 'none'}>
                <BoldTitle>Employee Type:</BoldTitle>
                <Text>
                    {employeeInfo?.isYungerman && <span>Yungerman</span>}
                    {employeeInfo?.isProfessional && <span>,Professional</span>}
                    {employeeInfo?.isLegal && <span>,Legal</span>}
                    {employeeInfo?.isEmployee && <span>,Employee</span>}
                </Text>
                <DivContainer>
                    <Chip
                        style={{ marginRight: '5px' }}
                        size={'small'}
                        label={employeeInfo?.isActive ? 'Active' : 'Inactive'}
                        className={clsx({
                            [classes.green]: employeeInfo?.isActive,
                            [classes.red]: !employeeInfo?.isActive,
                        })}
                    />
                    {employeeInfo?.isSummerOnly && <Chip
                        style={{ marginRight: '5px' }}
                        size={'small'}
                        label={'Summer-only'}
                        className={clsx(baseClasses.chipLabel, classes.yellow)}
                    />
                    }
                </DivContainer>
            </FlexContainer>
            <Grid container spacing={2}>
                <Grid item xs={4} lg={3}>
                    {exploringType === explorerTypesConst.employees &&
                        <InfoBox
                            title="Personal"
                            infoOptions={infoEmpFields.personal}
                            data={employeeInfo}
                        />
                    }
                    {exploringType === explorerTypesConst.clients &&
                        <InfoBox
                            title="Personal"
                            infoOptions={infoCliFields.personal}
                            data={clientInfo}
                        />
                    }
                    <SpacingBlock bottom="10px" />
                    {exploringType === explorerTypesConst.employees &&
                        <InfoBox
                            title="Ruchniyos"
                            headerAction={{ type: 'chip', property: 'lHisalosApprovalStatus' }}
                            infoOptions={infoEmpFields.ruchniyos}
                            data={employeeInfo}
                        />
                    }
                    {exploringType === explorerTypesConst.clients &&
                        <InfoBox
                            title="Yeshiva"
                            infoOptions={infoCliFields.yeshiva}
                            data={clientInfo}
                        />
                    }
                </Grid>
                <Grid item xs={4} lg={3}>
                    {exploringType === explorerTypesConst.employees &&
                        <InfoBox
                            title="Financial"
                            infoOptions={infoEmpFields.financial}
                            data={employeeInfo}
                        />
                    }
                    {exploringType === explorerTypesConst.clients &&
                        <InfoBox
                            title="LHisalos"
                            infoOptions={infoCliFields.lHisalosInfo}
                            data={clientInfo}
                        />
                    }
                    <SpacingBlock bottom="10px" />
                    {exploringType === explorerTypesConst.employees &&
                        <InfoBox
                            title="Summer"
                            infoOptions={infoEmpFields.summer}
                            data={employeeInfo}
                        />
                    }

                    {exploringType === explorerTypesConst.clients &&
                        <InfoBox
                            title="Contact Info"
                            infoOptions={infoCliFields.contactInfo}
                            data={clientInfo}
                        />
                    }
                </Grid>
                <Grid item xs={4} lg={3}>
                    {exploringType === explorerTypesConst.employees &&
                        <InfoBox
                            title="Comfort"
                            headerAction={{ type: 'chip', property: 'lHisalosApprovalStatus' }}
                            infoOptions={infoEmpFields.comfort}
                            data={employeeInfo}
                        />
                    }
                    {exploringType === explorerTypesConst.clients &&
                        <InfoBox
                            title="Comfort Enrollment"
                            infoOptions={infoCliFields.comfortEnrollment}
                            data={clientInfo}
                        />
                    }
                </Grid>
            </Grid>
        </BoxContainer>
    )
}

export default Info