import React, { useRef, useState, useEffect } from 'react';
import {useStoreState} from 'easy-peasy'
import { withStyles, useTheme } from "@mui/styles";
import clsx from 'clsx';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { 
  Button, Grid, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Box, Link, TableRow, InputAdornment,
  IconButton, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, Collapse, TablePagination, 
 } from '@mui/material';
import ReactLoading from 'react-loading';
import useStyles from '../Modals/styles';
import { getScheduleStringForProfessional, getNextSession, modalFields, getDaysArrayFromDaysString } from '../../../_helpers/schedule';
import { getNextAppointmentString, validateRatePeriod, validateRate, DateRangeValidator, TimeRangeValidator, validateDaySelections, validateProfessionalTypeSelection, ScheduleAvailabilityCalculator, ScheduleAvailabilityStatus } from '../../../_helpers/schedule/validators/client-schedule';
import CusTimePicker from '../../DateTimePickers/TimePicker/TimePicker';
import ClickableTableRow from '../../UI/ClickableTableRow';
import ProtectedComponent from '../../ProtectedComponent/ProtectedComponent';
import ProviderAvailabilityStatusTooltip from '../AvailabiltyFilter/ProviderAvailabilityStatusTooltip';
import useActiveToolTip from '../../../hooks/useActiveToolTip';
import { periodicities } from '../../../_helpers/constants/scheduleConstants';
import FRONTEND_ROUTE_CONSTANTS from "../../../frontend_route_constants";


  const getWarningLevel = (
    availabilityForClientSchedule: any,
    conflictingSchedules: any,
    conflictingPotentialSchedules: any,
    employeeRateIsGreaterThanRangeMax: any,
    missingSpecialties: any,
    neighborhoodMismatch: any,
) => {
    let warningLevel = 0;

    const scheduleIssues = availabilityForClientSchedule.availabilityStatus != ScheduleAvailabilityStatus.FullAvailability ||
        (conflictingSchedules && conflictingSchedules.length > 0) || (conflictingPotentialSchedules && conflictingPotentialSchedules.length > 0);

    if (!scheduleIssues && !employeeRateIsGreaterThanRangeMax && !(missingSpecialties && missingSpecialties.length > 0) && !neighborhoodMismatch)
        warningLevel = 0;
    else if (availabilityForClientSchedule.availabilityStatus != ScheduleAvailabilityStatus.FullAvailability || conflictingSchedules.length > 0)
        warningLevel = 2;
    else
        warningLevel = 1;

    return warningLevel;
  }

  function AvailableProviderTableRow({ scheduleType, isClickable, handleRowClick, children, ...rest }: any) {
    if (isClickable) {
        return <ClickableTableRow onClick={handleRowClick} {...rest}>{children}</ClickableTableRow>
    } else {
        return <TableRow {...rest}>{children}</TableRow>
    }
  }

  const FeaturedTableCell = (props: any) => {
    const { maxWidth, fontSize } = props;
    const ref = useRef();
    const isOverflow = useActiveToolTip(ref);
    const Styled = withStyles((theme) => ({
        root: {
            borderBottom: "0px",
            fontSize: fontSize || '12px',
            padding: '1px 2px !important',
            overflow: "hidden",
            maxWidth: (maxWidth && maxWidth.toString() + "px") || undefined,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }))(TableCell);

    return (
        <Tooltip title={isOverflow ? props.children : ''} placement="top">
            <Styled {...props} ref={ref}>
                {props.children}
            </Styled>
        </Tooltip>
    )
  };

  function AvailableProviderInfoAccordion({ isOpen, disabled, availabilities, schedules, potentialSchedules, row, modalId }: any) {
    const globalConst: any = global;
    const getAvailabilityString = (availability: any) => {
        const weekdaysString = globalConst.dateTime.getWeekdaysString(availability.days);
        const formattedStartTime = globalConst.dateTime.formatTimeAMPM(availability.startTime);
        const formattedEndTime = globalConst.dateTime.formatTimeAMPM(availability.endTime);
  
        return `${weekdaysString} ${formattedStartTime} - ${formattedEndTime}`;
    }
  
    const getClientString = (clientName: string, clientId: number) => {
        if (!clientName) {
            return `(Deleted clientId:${clientId})`
        } else {
            return clientName;
        }
    }
  
    const getScheduleString = (schedule: any) => {
        if (!schedule || !schedule.days || !schedule.everyXOfPeriods || !schedule.periodicity)
            return "";
        const schedulePeriodicityString = getScheduleStringForProfessional(schedule.days.split(',').filter((d: any) => d.trim().length != 0), schedule.everyXOfPeriods, schedule.periodicity);
  
        const formattedTimeDefault = '__:__';
        const formattedStartTime = schedule.startTime ? globalConst.dateTime.formatTimeAMPM(schedule.startTime) : formattedTimeDefault;
        const formattedEndTime = schedule.endTime ? globalConst.dateTime.formatTimeAMPM(schedule.endTime) : formattedTimeDefault;
  
        return `${schedulePeriodicityString} ${formattedStartTime} - ${formattedEndTime}`;
    }
  
    return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
                {availabilities.length > 0 && (
                    <div>
                        <p style={{ paddingBottom: 0, marginBottom: "5px", marginTop: "10px" }}><b>Availability:</b></p>
                        {availabilities.map((availability: any, index: number) => (
                            <p
                                id={modalId + "avlpvd-" + row.providerId + "-avl-" + index}
                                style={{ paddingBottom: 0, marginBottom: 0 }}
                            >
                                {getAvailabilityString(availability)}
                            </p>
                        ))}
                    </div>
                )}
                {schedules.length > 0 && (
                    <div>
                        <p style={{ paddingBottom: 0, marginBottom: "5px", marginTop: "10px" }}><b>Schedules:</b></p>
                        {schedules.map((schedule: any, index: number) => {
                            const clientString = getClientString(schedule.clientName, schedule.clientId);
                            const scheduleString = getScheduleString(schedule.schedule);
                            const nextAppointmentString = getNextAppointmentString(schedule.schedule);
  
                            return (
                                <p
                                    id={modalId + "avlpvd-" + row.providerId + "-sch-" + index}
                                    style={{ paddingBottom: 0, marginBottom: 0 }}
                                >
                                    {clientString}, {scheduleString}{nextAppointmentString ? `, ${nextAppointmentString}` : ''}
                                </p>
                            );
                        })}
                    </div>
                )}
                {potentialSchedules.length > 0 && (
                    <div>
                        <p style={{ paddingBottom: 0, marginBottom: "5px", marginTop: "10px" }}><b>Potential Schedules:</b></p>
                        {potentialSchedules.map((schedule: any, index: number) => {
                            const clientString = getClientString(schedule.clientName, schedule.clientId);
                            const scheduleString = getScheduleString(schedule.schedule);
  
                            return (
                                <p
                                    id={modalId + "avlpvd-" + row.providerId + "-ptl-" + index}
                                    style={{ paddingBottom: 0, marginBottom: 0 }}
                                >
                                    {clientString}, {scheduleString}
                                </p>
                            );
                        })}
                    </div>
                )}
            </Box>
        </Collapse>
    );
  }

const SearchProvider = (props: any) => {
    const classes: any = useStyles();
    const globalConst: any = global;

    const {actionPermissions, modalId, scheduleType, getEmployeeMissingSpecialties, contextData, availableProvidersRows, confirmedProviderExists, loadResults, isResultsLoading   } = props;

    const [availableProviderOpenRowId, setAvailableProviderOpenRowId] = React.useState(null);
    const [searchText, setSearchText] = React.useState("");
    const [query, setQuery] = React.useState("");
    const [filteredResultRows, setFilteredResultRows] = useState([]);

    const [ page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const providerDetailsRoute = FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE;

    useEffect(() => {
        const timer = setTimeout(() => {
            setQuery(searchText)
        }, 500)
        return () => { clearTimeout(timer) }
      }, [searchText])
    
      useEffect(() => {
            setFilteredResultRows(filterDataByQuery(availableProvidersRows, query, ["providerName"]));
      }, [availableProvidersRows, query]);
    
    const filterDataByQuery = (rows: any, query: any, fields: any) => {
    if (!rows || !query || !fields)
        return rows;
    return rows.filter(
        (row: any) => fields.some((field: any) => row[field].toString().toLowerCase().indexOf(query.toLowerCase()) > -1)
    );
    }

    const handleAvailableProviderRowClick = (row: any) => {
        if (row.providerId === availableProviderOpenRowId) {
            setAvailableProviderOpenRowId(null);
        } else {
            setAvailableProviderOpenRowId(row.providerId);
        }
      }

    const handleChangePage = (event: any, newPage: any) => {
    if (filteredResultRows.length > 0)
        setPage(newPage);
    else
        setPage(0);
    };

    return (
        <ProtectedComponent
              allow
              canEdit={actionPermissions?.canAddPotentials !== false}
          >
              <Grid container className={classes.datePaddingBox}>
                  <Grid item xs={6}>
                      <FormControl className={classes.formControl1} style={{ margin: 0, lineHeight: 'center' }}>
                          <TextField
                              label={
                                  <div style={{
                                      marginTop: "-3px"
                                  }}>
                                      <SearchIcon fontSize="small" />
                                      Search
                                  </div>
                              }
                              id={modalId + "srh"}
                              size="small"
                              onChange={(e) => setSearchText(e.target.value)}
                              value={searchText}
                          >
                          </TextField>
                      </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                      <Button
                          className={classes.loadResult}
                          disabled={confirmedProviderExists}
                          onClick={loadResults}
                      >
                          Load Results
                      </Button>
                  </Grid>
              </Grid>
              <div className={classes.availableResultContainer}>
                  {
                      isResultsLoading ?
                          <div style={{ maxWidth: '60%', maxHeight: '30%', transform: `translate(78%, 220%)` }}>
                              <ReactLoading type={"bars"} color={"#1976d2"} />
                          </div> :
                          <TableContainer component={Paper} className={classes.cardResults}>
                              <Table aria-label="simple table">
                                  <TableBody>
                                      {filteredResultRows?.length > 0 && filteredResultRows
                                          //.filter((row) =>
                                          //(row.availabilityForClientSchedule.availabilityStatus != "No Availability")
                                          //&& (!row.conflictingSchedules || row.conflictingSchedules.length < 1 || global.log(row.conflictingSchedules) || true)
                                          //)
                                          .sort((a: any, b: any) => {
                                              let levelA = getWarningLevel(
                                                  a.availabilityForClientSchedule,
                                                  a.conflictingSchedules,
                                                  a.conflictingPotentialSchedules,
                                                  a.rate > contextData?.rateTo,
                                                  scheduleType === "yungerman" ? getEmployeeMissingSpecialties(a.providerId, a.specialties, contextData?.specialties, true, true) : [],
                                                  props.clientProviderNeighborhoodMismatch(contextData?.neighborhood, a.neighborhood)
                                              );

                                              let levelB = getWarningLevel(
                                                  b.availabilityForClientSchedule,
                                                  b.conflictingSchedules,
                                                  b.conflictingPotentialSchedules,
                                                  b.rate > contextData?.rateTo,
                                                  scheduleType === "yungerman" ? getEmployeeMissingSpecialties(b.providerId, b.specialties, contextData?.specialties, true, true) : [],
                                                  props.clientProviderNeighborhoodMismatch(contextData?.neighborhood, b.neighborhood)
                                              );

                                              return levelA - levelB;
                                          })
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((row: any) => {
                                              let isSelected = contextData != undefined && contextData.clientProviderSchedulePotentials != undefined &&
                                                  contextData.clientProviderSchedulePotentials.some((pRow: any) => pRow.providerId === row.providerId)
                                              const expandAvailabilityAccordion = row.availabilities.length > 0 || row.schedules.length > 0 || row.potentialSchedules.length > 0;
                                              return (
                                                  <React.Fragment
                                                      key={modalId + "avlpvd-" + row.providerId}                                                                        >
                                                      <AvailableProviderTableRow
                                                          isClickable={expandAvailabilityAccordion}
                                                          handleRowClick={() => handleAvailableProviderRowClick(row)}
                                                          scheduleType={scheduleType}
                                                          key={row.providerId}
                                                      >
                                                          <FeaturedTableCell align="left" maxWidth={50}>
                                                              <ProviderAvailabilityStatusTooltip
                                                                  isExternalProvider={row.isExternal}
                                                                  availabilityForClientSchedule={row.availabilityForClientSchedule}
                                                                  conflictingSchedules={row.conflictingSchedules}
                                                                  conflictingPotentialSchedules={row.conflictingPotentialSchedules}
                                                                  durationBasedFilterConflicts={row.durationBasedFilterConflicts}
                                                                  employeeRateIsGreaterThanRangeMax={((contextData?.rateTo + contextData?.rateFrom) > 0) && row.rate > contextData.rateTo}
                                                                  employeeRateIsLessThanRangeMin={((contextData?.rateTo + contextData?.rateFrom) > 0) && row.rate < contextData.rateFrom}
                                                                  missingSpecialties={scheduleType === "yungerman" ? getEmployeeMissingSpecialties(row.providerId, row.specialties, contextData?.specialties, true, true) : []}
                                                                  neighborhoodMismatch={props.clientProviderNeighborhoodMismatch(contextData?.neighborhood, row.neighborhood)}
                                                                  unableToCalculateNeighborhoodAvailability={props.unableToCalculateNeighborhoodAvailability(contextData?.neighborhood, row.neighborhood)}
                                                              />
                                                          </FeaturedTableCell>
                                                          <FeaturedTableCell maxWidth={70}>
                                                              <span
                                                                  id={modalId + "avlpvd-" + row.providerId + "-nme"}
                                                                  className={classes.clickableText}
                                                                  onClick={(event) => props.openProviderDetailsPageInNewTab(event, providerDetailsRoute, row.providerId)}
                                                              >
                                                                  {row.providerName}
                                                              </span>
                                                          </FeaturedTableCell>
                                                          <FeaturedTableCell maxWidth={70} >{`$${row.rate}${periodicities[row.ratePeriod as keyof typeof periodicities] ? `\n/${periodicities[row.ratePeriod as keyof typeof periodicities]}` : ""}`}</FeaturedTableCell>
                                                          <FeaturedTableCell
                                                              id={modalId + "avlpvd-" + row.providerId + "-spc"}
                                                              maxWidth={70}>{scheduleType === "yungerman" ? row.specialties : row.professionalType}</FeaturedTableCell>
                                                          <FeaturedTableCell maxWidth={50} id={modalId + "avlpvd-" + row.providerId + "-cmhsts"}>{row.comfortHealthStatus}</FeaturedTableCell>

                                                          <FeaturedTableCell >
                                                              <ProtectedComponent
                                                                  allow
                                                                  canEdit={props.isPermittedToAddPotential(row, actionPermissions)}
                                                              >
                                                                  <Tooltip title={props.potentialLimitReach && !isSelected ? 'You can select max 3 Potentials' : ''} enterDelay={500} leaveDelay={2000} >

                                                                      <Button
                                                                          id={modalId + "avlpvd-" + row.providerId + "-slc"}
                                                                          className={classes.selectButton}
                                                                          disabled={confirmedProviderExists}
                                                                          onClick={(event) => {
                                                                              event.stopPropagation();
                                                                              props.handleFilter(row);
                                                                          }}>
                                                                          {
                                                                              isSelected ? "SELECTED" : "SELECT"
                                                                          }
                                                                      </Button>
                                                                  </Tooltip>
                                                              </ProtectedComponent>
                                                          </FeaturedTableCell>
                                                          <FeaturedTableCell align="right" >
                                                              <IconButton aria-label="expand row" size="small" disabled={!expandAvailabilityAccordion}>
                                                                  {availableProviderOpenRowId === row.providerId ?
                                                                      <KeyboardArrowUpIcon id={modalId + "avlpvd-" + row.providerId + "-expdup"} />
                                                                      :
                                                                      <KeyboardArrowDownIcon id={modalId + "avlpvd-" + row.providerId + "-expddn"} />
                                                                  }
                                                              </IconButton>
                                                          </FeaturedTableCell>
                                                      </AvailableProviderTableRow>
                                                      <TableRow>
                                                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                              <AvailableProviderInfoAccordion
                                                                  isOpen={availableProviderOpenRowId === row.providerId}
                                                                  disabled={!expandAvailabilityAccordion}
                                                                  availabilities={row.availabilities}
                                                                  schedules={row.schedules}
                                                                  potentialSchedules={row.potentialSchedules}
                                                                  row={row}
                                                              />
                                                          </TableCell>
                                                      </TableRow>
                                                  </React.Fragment>
                                              )
                                          })}
                                  </TableBody>
                              </Table>
                          </TableContainer>
                  }
                  <div style={{ marginTop: "auto" }}>
                      <TablePagination
                          id={modalId + "pgn"}
                          rowsPerPageOptions={[]}
                          component="div"
                          count={filteredResultRows?.length}
                          rowsPerPage={5}
                          page={page}
                          onPageChange={(e, np) => { globalConst.log(e); handleChangePage(e, np) }}
                      //onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                  </div>
              </div>
          </ProtectedComponent>
    )
}

export default SearchProvider