import React, { useState } from 'react';
import { Card, TextField, FormControl, InputLabel, Select, Autocomplete, MenuItem, Input, Chip, FormControlLabel, Switch, Tooltip, TextFieldProps } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { CardHeaderAction, Title, FlexContainer, TextButton, DivContainer, ColumnHeader, TableBodyContainer, ColumnItem, RowItem, FlexJustifyEnd, ColumnItemEditMode } from '../../../assets/styles/styledComponents';
import { dateTime } from '../../../_helpers/datetime';
import useBaseStyles from '../../../assets/styles'
import FeaturedIcon from '../FeaturedIcon';
import { DraggableGridTableProps } from '../../../assets/types';
import EmptyComponent from '../EmptyComponent';
import { theme } from '../../../assets/theme/theme';
import LoadingBar from '../../LoadingBar/LoadingBar';
import DynamicTooltipText from '../DynamicTooltipText';
import { isEmpty } from 'lodash';
import RichTextField from '../../UI/RichTextField';
import TextFormattingWrapper from '../../../utils/TextFormattingWrapper';

const assignee = [
    { providerName: 'abc', clientServiceId: 2 },
    { providerName: 'cde', clientServiceId: 3 },
];

const DraggableGridTable: React.FC<DraggableGridTableProps> = (props) => {
    const {
        mode,
        title,
        data,
        handleSaveChanges,
        handleCancelChanges,
        handleAddNewRow,
        handleEditRows,
        handleOnChange,
        handleDragEnd,
        loading,
        rowError,
        showCompleteFilter,
        showCompleted,
        handleShowCompleted,
        canEdit,
        getTooltipTitle
    } = props;
    const baseClasses: any = useBaseStyles();

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? theme.palette.background.paper : "unset",
    });

    const getFeaturedRowContent = (row: any, col: any, forTooltip = false) => {
        return col.type === 'date' ?
            dateTime.getUSAFormattedDateString(row[col.field]) :
            Array.isArray(row[col.field]) ? row[col.field].map((item: any) => {
                return item['providerName'] || '[NA]'
            }).toString() :
                col.type === 'icon' && !forTooltip && canEdit ?
                    <FeaturedIcon icon={col.getIcon ? col.getIcon(row.completedOn) : col.icon} onClick={(event: any) => col.handleClick(event, row)} fontSize="small" /> :
                    row[col.field] ? row[col.field] : ''

    };

    const handleTextChange = (event: any, row: any, col: any, rowIndex: any) => {
        let newRow = { ...row, [col.field]: event.target.value };
        handleOnChange?.(event.target.value, newRow, rowIndex, col)
    }
    const handleTextValueChange = (value: any, row: any, col: any, rowIndex: any) => {
        let newRow = { ...row, [col.field]: value };
        handleOnChange?.(value, newRow, rowIndex, col)
    }

    const FeaturedColumn = (row: any, rowIndex: number, col: any, provided: any) => {
        const textFieldProps: TextFieldProps = {
            fullWidth: true,
            error: col.required && isEmpty(row[col.field]) && !!rowError,
            size: 'small',
            label: col.headerName,
            value: row[col.field],
            className: baseClasses.hideMultiline,
            variant: "outlined"
        };

        if (mode === 'edit' || row.id < 0) {
            if (!col.readOnly) {
                switch (col.type) {
                    case 'text':
                        return (
                            <ColumnItemEditMode width={col.width}>
                                <FormControl fullWidth >
                                    {
                                        col.withRichText ?
                                            <RichTextField
                                                {...textFieldProps}
                                                setState={(value: any) => handleTextValueChange(value, row, col, rowIndex)}
                                            /> :
                                            <TextField
                                                {...textFieldProps}
                                                onChange={(event: any) => handleTextChange(event, row, col, rowIndex)}
                                            />
                                    }
                                </FormControl>
                            </ColumnItemEditMode>
                        );
                    case 'date':
                        return (
                            <ColumnItemEditMode width={col.width}>
                                <FormControl fullWidth style={{ margin: '0px 0px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label={col.headerName}
                                            value={row[col.field]}
                                            onChange={(valDate: any) => { handleOnChange?.(valDate, { ...row, [col.field]: valDate }, rowIndex, col) }}
                                            renderInput={(params: any) =>
                                                <TextField {...params} size="small" variant={"outlined"} fullWidth error={false} />
                                            }
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </ColumnItemEditMode>
                        );
                    case 'autocomplete':
                        return (
                            <ColumnItemEditMode width={col.width}>
                                <FormControl fullWidth style={{ margin: '0px 0px' }}>
                                    <Autocomplete
                                        disableClearable
                                        options={col.data}
                                        multiple={true}
                                        autoHighlight={true}
                                        limitTags={1}
                                        size="small"
                                        getOptionLabel={(option) => option[col.dataLabel]}
                                        value={row[col.field] || []}
                                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                        renderInput={(params) =>
                                            <TextField {...params} size={'small'} className={baseClasses.hideMultiline} label={col.headerName} />
                                        }
                                        onChange={(event, selectedOption, reason) => {
                                            handleOnChange?.(selectedOption, { ...row, [col.field]: selectedOption }, rowIndex, col);
                                        }}
                                    />
                                </FormControl>
                            </ColumnItemEditMode>
                        );
                    case 'select':
                        return (
                            <ColumnItemEditMode width={col.width}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">{col.headerName}</InputLabel>
                                    <Select
                                        className={baseClasses.hideMultiline}
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={row[col.field]}
                                        label={col.headerName}
                                        onChange={(event: any) => handleOnChange?.(event.target.value, { ...row, [col.field]: event.target.value }, rowIndex, col)}
                                    >
                                        {col.options.map((option: any) => {
                                            return <MenuItem value={option.value}>{option.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </ColumnItemEditMode>
                        );
                    case 'icon':
                        return (
                            col?.show?.includes('edit') && (
                                <div
                                    {...provided.dragHandleProps}
                                >
                                    <FeaturedIcon icon={col.icon} onClick={(event: any) => col.handleClick(event, row)} fontSize="small" />
                                </div>
                            )
                        );
                    default: return null;
                }
            }
        } else {
            return (
                <ColumnItem width={col.width} hover={true} hide={col?.show?.includes('edit')}>
                    {
                        col.tooltip ?
                            <DynamicTooltipText
                                title={<TextFormattingWrapper
                                    value={getFeaturedRowContent(row, col, true)}
                                />}
                                maxWidth={col.width}
                                showInModal
                            >
                                <TextFormattingWrapper
                                    value={getFeaturedRowContent(row, col)}
                                />
                            </DynamicTooltipText> :
                            getFeaturedRowContent(row, col)
                    }
                </ColumnItem>
            )
        };
    };

    const renderRow = (row: any, index: number, provided: any) => {
        return (
            <RowItem>
                {
                    data.columns.map((col: any, colIndex: number) => {
                        return FeaturedColumn(row, index, col, provided);
                    })
                }
            </RowItem>
        );
    };

    const getLoadingOverlay = () => {
        return <LoadingBar />
    };

    return (
        <Card>
            <CardHeaderAction justifyContent="space-between">
                <Title width={200} fontWeight={400}>{title}</Title>
                {mode === 'edit' || data?.rows?.some((row: any) => row.id < 0) ?
                    <FlexContainer>
                        <FlexContainer className={baseClasses.error}>
                            {rowError}
                        </FlexContainer>
                        <TextButton
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                handleCancelChanges?.(event)
                            }}
                            color="primary"
                        >
                            Cancel
                        </TextButton>
                        <TextButton
                            onClick={() => {
                                handleSaveChanges?.()
                            }}
                            color="primary"
                        >
                            Save
                        </TextButton>
                    </FlexContainer> :
                    <FlexContainer>
                        {showCompleteFilter &&
                            <FormControlLabel
                                control={
                                    <Switch checked={showCompleted} onChange={() => handleShowCompleted?.()} name="showcomp" color="primary" />
                                }
                                label="Show Completed"
                            />
                        }
                        {canEdit &&
                            <TextButton
                                onClick={() => {
                                    handleEditRows?.()
                                }}
                                color="primary"
                                disable={!(data?.rows?.length > 0)}
                            >
                                Edit
                            </TextButton>
                        }
                    </FlexContainer>
                }
            </CardHeaderAction>
            <DivContainer>
                {loading && getLoadingOverlay()}
                <ColumnHeader>
                    {data.columns.map((column: any) => {
                        return (
                            <ColumnItem width={column.width} hide={(column?.show?.includes('edit') && mode === 'read') || (mode === 'edit' && column.readOnly)}>
                                {column.headerName}
                            </ColumnItem>
                        )
                    })}
                </ColumnHeader>
                <TableBodyContainer>
                    {
                        data?.rows?.length > 0 ?
                            <DivContainer width='100%' height='100%'>
                                <DragDropContext onDragEnd={(result: any) => handleDragEnd?.(result)}>
                                    <Droppable droppableId="droppable">
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {
                                                    (data?.rows?.length > 0 || mode === 'edit') && data?.rows.map((row: any, index: number) => {
                                                        return (
                                                            <Draggable key={row.id} draggableId={`${row.id}`} index={index}>
                                                                {(provided: any, snapshot: any) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        snapshot={snapshot}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        {renderRow(row, index, provided)}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                                {canEdit &&
                                                    <FlexJustifyEnd>
                                                        <TextButton
                                                            onClick={() => {
                                                                handleAddNewRow?.()
                                                            }}
                                                            color="primary"
                                                            textAlign="end"
                                                        >
                                                            + Add New
                                                        </TextButton>
                                                    </FlexJustifyEnd>
                                                }
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </DivContainer> :
                            <EmptyComponent
                                btnText='Add New'
                                displayText='No Plan Items Yet'
                                onClick={handleAddNewRow as Function}
                            />
                    }
                </TableBodyContainer>
            </DivContainer>
        </Card>
    )
};

export default DraggableGridTable;