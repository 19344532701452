import * as React from "react";
import { uploadTemplateFile } from "../../api/cms";
/**
 *
 * @param {label, data, onFileChange} props
 * @display {Label, Name, Upload Document control, Document Icon}
 */

export default function UploadFileInput(props) {
    //   const classes = useStyles();
    const [note, setNote] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState("Upload your file");
    let fileName = "";

    function getB64Str(buffer) {
        var binary = "";
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    const onUploadFile = (file, fileName) => {
        uploadTemplateFile(props.data.primaryId, props.data.templateFileId, fileName, "txt", file, props.data.folderTemplate, props.table).then((response) => {
            props.onFileChange(props.label, getB64Str(file), response.file);
        });
    };

    return (
        <div className="input-group">
            <div className="custom-file">
                <input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile01"
                    name="file"
                    disabled={props.disabled ?? false}
                    onChange={(e) => {
                        global.log('props as seen from the file input:', props.data)
                        setSelectedFile(e.target.files[0].name);
                        if (e.target.files.length == 0) return;
                        else {
                            var reader = new FileReader();
                            reader.onload = function (event) {
                                if (props.saveOnChange) {
                                    onUploadFile(getB64Str(event.target.result), fileName);
                                } else {
                                    let newFile = {
                                        primaryId: props.data.primaryId,
                                        templateFileId: props.data.templateFileId,
                                        name: fileName,
                                        file64Arr: getB64Str(event.target.result),
                                        folderTemplate: props.data.folderTemplate,
                                        state: 'new',
                                        uploadedOn: new Date(),
                                    };
                                    props.onFileChange(fileName, newFile.file64Arr, newFile);
                                }
                            };
                            fileName = e.target.files[0].name;
                            reader.readAsArrayBuffer(e.target.files[0]);
                        }
                    }}
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                    {props.label != "" ? props.label : "Upload Your File"}
                </label>
            </div>
        </div>
    );
}