import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
  import Typography from '@mui/material/Typography';
  import Box from '@mui/material/Box';
  
import {customProgressTypes} from './common.types';

function CircularProgressBar(
    props:customProgressTypes
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }} style={{...props.style}}>
        <CircularProgress variant="determinate" value={props.progressPercent} />
        {props.showLabel &&(
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
            <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                    >{Number.isInteger(props.progressPercent) ? `${Math.round(props.progressPercent)}%` : 'N/A'}</Typography>
            </Box>)
        }
      </Box>
    );
}

function LinearProgressBar(props: customProgressTypes) {
    return (
      <Box sx={{  
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        position: props.position ? "absolute": "default",
        left: 0,
        top: props.position === "top" ? 0: "unset",
        bottom: props.position === "bottom" ? 0: "unset" }}>
        <Box sx={{
           width: '100%', 
           mr: props.showLabel ? 1:0 ,
           }}>
          <LinearProgress variant="determinate" value={props.progressPercent} />
        </Box>
        {props.showLabel &&(
            <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{Number.isInteger(props.progressPercent) ? `${Math.round(props.progressPercent)}%`: 'N/A'}</Typography>
            </Box>
        )}
      </Box>
    );
}

function FilledProgressBar (props:customProgressTypes) {
  return (
    <Box sx={(theme) => ({  
      width: `${props.progressPercent}%`, 
      height: '100%',
      opacity: 0.5,
      background: props.color ? props.color:theme.palette.primary.main, 
      display: 'flex', 
      alignItems: 'center', 
      position: props.position ? "absolute": "default",
      left: 0,
      top: props.position === "top" ? 0: "unset",
      bottom: props.position === "bottom" ? 0: "unset" })}/>
  )

}
const CustomProgressBar = (props:customProgressTypes) => {
    const {progressPercent, showLabel, color, progressType, position, style} = props;
    switch(progressType) {
        case 'circular':
          return <CircularProgressBar style={style} progressPercent={progressPercent} showLabel={showLabel} color={color} />
        case 'linear':
          return <LinearProgressBar progressPercent={progressPercent} showLabel={showLabel} color={color} position={position}/>
        case 'filled':
          return <FilledProgressBar progressPercent={progressPercent} showLabel={showLabel} color={color} position={position}/>
        default:
          return null
    }
}

export default CustomProgressBar