import * as constants from '../constants';
import _ from "lodash";

export let yeshivas = () => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.YESHIVAS_LIST)
            .then(data => {
                yeshivas_data = data.result;
                yeshivas_data.forEach((item, index) => {
                    yeshiva.push({
                        id: item.id,
                        name: item.name
                    });
                });
                resolve(yeshiva);
            }, (rejects) => {
                global.log('rejects');
            });
    });
}

export let yeshivasDetails = (id) => {
    let detailOfyeshivas;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.YESHIVA_ITEM_DETAILS + id)
            .then(data => {
                if (data.responseCode === 200) {
                    resolve(data.result);
                } else {
                    resolve([]);
                }
            }, (rejects) => {
                global.log('rejects');
                // rejects();
                resolve([]);
            });
    });
}

export const getUserDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.USER_DETAIL + id)
            .then(data => {
                global.log(data)
                resolve(data);
            }, (rejects) => {
                global.log('rejects');
                // rejects();
                resolve([]);
            });
    })
}

export const getAllRoles = () => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.GET_ALL_ROLES)
            .then(data => {
                global.log(data)
                resolve(data.roles);
            }, (rejects) => {
                global.log('rejects');
                // rejects();
                resolve([]);
            });
    })
}

/**
 * Get All user Notification
 * @function
 * @param {Number} id - user id
 * @param {string} fromDataTime - start data and time
 * @return {Promise} Promise object backend data
 */
export const getNotifications = (id, fromDataTime) => {
    // TODO: For Now return fake data
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(constants.USER_NOTIFICATIONS + id + "?fromDateTime=" + fromDataTime).then(
            (data) => {
                if (data.responseCode === 200) {
                    // Return all Data
                    resolve(data.result)
                } else {
                    resolve([constants.notificationsFakeData]);
                }
            },
            (rejects) => {
                global.AjaxFailToast();
                resolve(constants.notificationsFakeData);
            }

        )
    })
}

/**
 * Update user Notification
 * @function
 * @param {Number} id - user id
 * @param {Number} notificationId - id of notification that needs to be updated
 * @return {Promise} Promise object backend data
 */
export const updateNotification = (userId, notificationId) => {
    // TODO: For Now return fake data
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.put(constants.USER_UPDATE_NOTIFICATION + notificationId).then(
            (data, rejects) => {
                if (data.responseCode === 200) {
                    // Return all Data
                    resolve(constants.notificationsFakeData.find(item => item.id === notificationId))
                } else {
                    resolve(constants.notificationsFakeData.find(item => item.id === notificationId))
                }
            },
            (rejects) => {
                global.AjaxFailToast();
                resolve(constants.notificationsFakeData.find(item => item.id === notificationId))
            }
        )
    })
}