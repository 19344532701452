import { gematriya, HDate } from "@hebcal/core"
import { hebrewMonthNames, HebrewMonthNamesLeapYear } from "./constants"

export class HebrewDate {
    monthStart:Date
    monthEnd:Date
    hebMonthName:string
    hebYearName:string
    hebYear: number
    monthInYear: number
    hebDate: HDate
    date:Date

    constructor(date: Date) {
        this.hebDate = new HDate(date);
        this.date = date;
        this.monthStart = new HDate(1, this.hebDate.getMonth(), this.hebDate.getFullYear()).greg()// this.hebDate.subtract(this.hebDate.getDate() - 1, 'day').greg();
        this.monthEnd = new HDate(this.hebDate.daysInMonth(), this.hebDate.getMonth(), this.hebDate.getFullYear()).greg() //this.hebDate.add(this.hebDate.daysInMonth() - this.hebDate.getDate(), 'day').greg();
        this.hebMonthName = this.hebDate.isLeapYear() ?
            HebrewMonthNamesLeapYear[this.hebDate.getTishreiMonth() - 1].label : hebrewMonthNames[this.hebDate.getTishreiMonth() - 1].label;
        this.hebYearName = gematriya(this.hebDate.getFullYear());
        this.hebYear = this.hebDate.getFullYear();
        this.monthInYear = this.hebDate.getTishreiMonth()
    }
    nextMonth() {
        return new HebrewDate(this.hebDate.add(1, 'month').greg())
    }
    previousMonth() {
        return new HebrewDate(this.hebDate.subtract(1, 'month').greg())
    }
}


export const hebrewToGreg = (month: string, year: number) => {
    let date = new HDate(1, month, year);
    return date.greg();
}