import React, { useEffect, useState, useRef } from "react";
import {
    Dialog,
    DialogActions, DialogTitle, FormControl,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { Card, CardContent, CardHeader } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useTheme } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import PropTypes from "prop-types";
import * as constants from "../../constants";
import UploadFile from "../../components/UploadFile/UploadFile";
import useUnsavedChangesWarn from "../../hooks/useUnsavedChangesWarn";
import TInputNumber from "../../components/UI/TInputNumber";
import { getEmployeeTemplateDocuments } from '../../api/employees'
import { fetchFileInfos, saveFile } from "../../api/cms";
import TextInputWrapper from "../../utils/TextInputWrapper";
import useStyles from "./programStyles";
import baseStyles from '../../assets/styles';
import { CompSelectbox } from './employee/components'
import { format } from 'date-fns';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

const CompTextlineInput = (props) => {
    const baseClasses = baseStyles()
    const { label, name, dollar, onChange, labelPlacement, InputProps, type, employeeDetail, handleSetEmployeeDetail, handleSaveEnable } = props;
    const [value, setValue] = React.useState(employeeDetail[name]);

    return (
        <Grid
            item
            xs={12}
            className={baseClasses.document_upload}
        >
            <FormControl className="w-100">
                <TextInputWrapper
                    {...props}
                    id={props.id}
                    type={type ? type : dollar ? "number" : "text"}
                    onBlur={(event) => {
                        handleSetEmployeeDetail(event, name)
                        handleSaveEnable(true);
                    }}
                    onChange={(event) => {
                        onChange(event);
                        setValue(event.target.value)
                    }}
                    name={name}
                    value={!global.isEmpty(value) ? value : dollar ? 0 : ""}
                    // className={classes.hoursWidget}
                    InputProps={
                        InputProps ? InputProps :
                            !dollar ? {} : {
                                startAdornment: (<InputAdornment position="start"> $</InputAdornment>)
                            }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={label}
                    labelPlacement={labelPlacement || "start"}
                />
            </FormControl>
        </Grid>
    );
};

const SectionRightCard = ({ title, children, selectedItem }) => {
    const classes = useStyles();
    return (
        <div>
            {title === selectedItem && (
                <Card
                    variant="outlined"
                    className={classes.contentOfaccordion}>
                    {children}
                </Card>
            )}
        </div>
    );
};

const SectionRightCardBtn = (props) => {
    const baseClasses = baseStyles();
    const { onSaveRightCard, isSaveEnable } = props;
    return (
        <Button
            onClick={onSaveRightCard}
            className={baseClasses.capitalized}
            color="primary"
            variant="contained"
            disabled={!isSaveEnable}
            style={{ marginLeft: "auto" }}
        >
            Save
        </Button>
    );
};

const DateCheckbox = ({
    label,
    name,
    selectedDate,
    setSelectedDate,
    employeeDetail_Local = { employeeDetail_Local },
    setEmployeeDetail_Local = { setEmployeeDetail_Local },
    setIsSaveEnable = { setIsSaveEnable },
    onChange,
}) => {

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name]}
                        onClick={(event) => {
                        }}
                        color="primary"
                    />
                }
                labelPlacement="start"
                label={label}
                style={{ margin: 0 }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    label="Select Date"
                    value={selectedDate}
                    onChange={(newValue) => {
                        const formattedDate = format(newValue, 'yyyy-MM-dd HH:mm:ss.SSSSSSS');

                        setSelectedDate(newValue);

                        if (newValue) {
                            console.log(newValue);
                            setIsSaveEnable(true);

                            const _checked = global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name];

                            setIsSaveEnable(true);
                            setEmployeeDetail_Local({
                                ...employeeDetail_Local,
                                [name]: formattedDate,
                            });
                        }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
};

export default function Programs(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [value, setValue] = React.useState("comfort_health");
    const [view, setView] = React.useState(true);
    const [parent_view, setParentView] = React.useState(true);
    const [education, setEducation] = React.useState(true);
    const [information, setInformation] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState("Planning to enroll");
    const [selectedItemNext, setSelectedItemNext] = React.useState(null);
    const [isSaveEnable, setIsSaveEnable] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const exploringId = useStoreState((state) => state.exploringId);
    const employeeDetail = useStoreState((state) => state.employee.detail);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);
    const [employeeDetail_Local, setEmployeeDetail_Local] = React.useState(useStoreState((state) => state.employee.detail));
    const [canEdit, setCanEdit] = React.useState(false);
    const [fileTemplates, setFileTemplates] = React.useState({});
    const [
        completedOnCheckboxField,
        setcompletedOnCheckboxField,
    ] = React.useState({
        sentToComfortHealthOn: false,
        fingerprintedOn: false,
        comfortHealthApprovedOn: false,
        catsApprovedOn: false,
        prevocApprovedOn: false,
        dragonProfileCreatedOn: false,
        screenedOn: false,
        interviewedOn: false,
    });
    const [selectedScreenedDate, setSelectedScreenedDate] = useState(employeeDetail.screenedOn);
    const [interviewNotes, setInterviewNotes] = useState(employeeDetail.interviewNotes);
    const [interviewedByName, setInterviewedByName] = useState(employeeDetail.interviewedByName);
    const [selectedInterviewDate, setSelectedInterviewDate] = useState(employeeDetail.interviewedOn);
    const [screeningNotes, setScreeningNotes] = useState(employeeDetail.screeningNotes);
    const [screenedByName, setScreenedByName] = useState(employeeDetail.screenedByName);

    const [tempSaveData, setTempSaveData] = React.useState({
        employeeDetail: employeeDetail,
        completedOnCheckboxField: completedOnCheckboxField
    });

    const { setRollbackAction } = props;
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    const handleListItemClick = (item) => {
        if (onBeforeSelect && !onBeforeSelect()) {
            return;
        }
        setIsSaveEnable(false);
        setSelectedItem(item);
        setTempSaveData({
            employeeDetail: employeeDetail,
            completedOnCheckboxField: completedOnCheckboxField
        });
        return;
        if (isSaveEnable) {
            setSelectedItemNext(item);
            setOpenConfirm(true);
        }
        if (!isSaveEnable) {
            setIsSaveEnable(false);
            setSelectedItem(item);
            setTempSaveData({
                employeeDetail: employeeDetail,
                completedOnCheckboxField: completedOnCheckboxField
            });
        }
    };

    useEffect(() => {
        let roles = ["admin", "financial", "intake", "menahel", "office"];
        if (global.roleContain(roles)) {
            setCanEdit(true);
        }
        else {
            setCanEdit(false);
        }
    }, [global.userRole]);
    useEffect(() => {
        if (global.isEmpty(exploringId)) return;
        setcompletedOnCheckboxField({
            'sentToComfortHealthOn': initcompletedOnCheckboxField(employeeDetail_Local, "sentToComfortHealthOn"),
            'fingerprintedOn': initcompletedOnCheckboxField(employeeDetail_Local, "fingerprintedOn"),
            'comfortHealthApprovedOn': initcompletedOnCheckboxField(employeeDetail_Local, "comfortHealthApprovedOn"),
            'catsApprovedOn': initcompletedOnCheckboxField(employeeDetail_Local, "catsApprovedOn"),
            'prevocApprovedOn': initcompletedOnCheckboxField(employeeDetail_Local, "prevocApprovedOn"),
            'dragonProfileCreatedOn': initcompletedOnCheckboxField(employeeDetail_Local, "dragonProfileCreatedOn"),
            'screenedOn': initcompletedOnCheckboxField(employeeDetail_Local, "screenedOn"),
            'interviewedOn': initcompletedOnCheckboxField(employeeDetail_Local, "interviewedOn"),

        });
    }, [employeeDetail_Local]);

    const initcompletedOnCheckboxField = (objects, objectName, name) => {
        if (global.isEmpty(objects[objectName]))
            return false;
        return true;
    };

    const handlecompletedOnCheckboxField = (programType, name) => {
        if (completedOnCheckboxField[name] == false) {
            setcompletedOnCheckboxField({
                ...completedOnCheckboxField,
                [name]: true,
            });
            setEmployeeDetail_Local({
                ...employeeDetail_Local,
                [name]: global._DateTimeFieldDB(new Date()),
            });
        } else {
            setcompletedOnCheckboxField({
                ...completedOnCheckboxField,
                [name]: false,
            });
            setEmployeeDetail_Local({
                ...employeeDetail_Local,
                [name]: null,
            });
        }
    };

    const handleFields = (programType, event) => {
        const { name, value, type } = event.target;

        //enable save button
        setIsSaveEnable(true);

        if (type === "checkbox") {
            setEmployeeDetail_Local({
                ...employeeDetail_Local,
                [name]: event.target.checked,
            });
        } else {
            setEmployeeDetail_Local({ ...employeeDetail_Local, [name]: value });
        }
    };

    const listNameList = [
        // !global.isEmpty(fieldsP3.newAgency) && !global.isEmpty(fieldsP3.note),
        {
            Title: "Planning to enroll",
            CompletedUpon: employeeDetail_Local.planningToEnrollComfortHealth
        },
        {
            Title: "Completed Application",
            CompletedUpon: employeeDetail_Local.hasComfortHealthApplication
        },
        {
            Title: "ID",
            CompletedUpon: fileTemplates && fileTemplates["ID"] //fileTemplates[employeeFileTemplate.ID]
        },
        {
            Title: "High School Diploma",
            CompletedUpon: fileTemplates && fileTemplates["HighSchoolDiploma"]
        },
        {
            Title: "Associate's Degree",
            CompletedUpon: fileTemplates && fileTemplates["AssociateDegree"]
        },
        {
            Title: "BA",
            CompletedUpon: fileTemplates && fileTemplates["BA"]
        },
        {
            Title: "Dragon Profile",
            CompletedUpon: employeeDetail_Local.dragonProfileCreatedOn
        },
        {
            Title: "Sent to Comfort Health",
            CompletedUpon: completedOnCheckboxField.sentToComfortHealthOn
        },
        {
            Title: "Fingerprints",
            CompletedUpon: completedOnCheckboxField.fingerprintedOn
        },
        {
            Title: "Approval & Information",
            CompletedUpon: completedOnCheckboxField.comfortHealthApprovedOn
        },
        {
            Title: "Screened",
            CompletedUpon: completedOnCheckboxField.screenedOn
        },
        {
            Title: "Interviewed",
            CompletedUpon: completedOnCheckboxField.interviewedOn
        },
    ];
    const rollbackChanges = () => {
        setEmployeeDetail_Local(tempSaveData.employeeDetail);
        setcompletedOnCheckboxField(
            tempSaveData.completedOnCheckboxField
        );
        //setOpenConfirm(false);
        setIsSaveEnable(false);
        setSelectedItem(selectedItemNext);
        //setTempSaveData({
        //    employeeDetail: employeeDetail,
        //    completedOnCheckboxField: completedOnCheckboxField
        //});
    }
    const handleChange = (event, newValue) => {
        if (newValue == value) {
            event.preventDefault();
            return;
        }
        if (changedItemsIncludes("bottomContainer")) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                event.preventDefault();
                return;
            } else {
                removeChange("topContainer");
                removeChange("bottomContainer");
                setSelectedItemNext(newValue);
                rollbackChanges();
            }
        }
        if (!view || !parent_view || !education || !information) {
            handleClickOpen();
        } else {
            setValue(newValue);
        }
    };
    const onFieldChange_Bottom = (event) => {
        setIsSaveEnable(true);
        addChange("bottomContainer");
        if (event && event.target && event.target.focus) {
            event.target.focus() // not helping anything yet...
        }
    }
    const onFieldChange_Top = () => {
        setIsSaveEnable(true);
        addChange("topContainer");
    }
    const resetPrevData_Bottom = () => {
        removeChange("bottomContainer");
        rollbackChanges();
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const CareNameLeftListItem = ({ name, index, disabled, completed }) => {
        return (
            <ListItem
                button
                selected={selectedItem === name}
                style={{ height: 35 }}
                disabled={disabled}
                onClick={() => handleListItemClick(name)}
            >
                {completed ? (
                    <ListItemIcon style={{ color: "#19D2B3", maybe: "#19B4D1" }}>
                        <AssignmentTurnedInIcon />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                )}

                <ListItemText primary={name} />
            </ListItem>
        );
    };
    const SectionLeftList = () => {
        return (
            <div className={classes.leftSection}>
                <List component="nav" aria-label="secondary mailbox folder">
                    {
                        listNameList.map((item, index) => (
                            <CareNameLeftListItem
                                name={item.Title}
                                index={index}
                                key={index}
                                completed={item.CompletedUpon}
                                disabled={
                                    !employeeDetail_Local.planningToEnrollComfortHealth &&
                                        item.Title != "Planning to enroll"
                                        ? true
                                        : false
                                }
                            ></CareNameLeftListItem>
                        ))}
                </List>
            </div>
        );
    };

    const onSaveRightCard = () => {
        setEmployeeDetail(employeeDetail_Local);
        global.fetchWrapper.post(constants.EMPLOYEE_SAVE_PERSONAL + exploringId, employeeDetail_Local)
            .then(
                (data) => {
                    global.AjaxSaved1();
                    for (const file in fileTemplates) {
                        if (fileTemplates[file].state == "modified")
                            saveFile({ ...fileTemplates[file], file64Arr: undefined }, "employees", fileTemplates, setFileTemplates);
                        else if (fileTemplates[file].state == "new")
                            saveFile(fileTemplates[file], "employees", fileTemplates, setFileTemplates);
                    }
                    setClean();
                    setIsSaveEnable(false);
                },
                (rejects) => {
                    global.AjaxFailToast1();
                }
            );
    };

    const SectionHeading = ({ title }) => {
        return (
            <Typography
                className={baseClasses.card_title}
                // variant="h6"
                gutterBottom
            >
                {title}
            </Typography>
        );
    };
    const CompCheckbox = ({ label, name, onChange }) => {
        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name]}
                            onClick={(event) => {
                                onChange(event);

                                var _checked = global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name]

                                //enable save button
                                setIsSaveEnable(true);
                                setEmployeeDetail_Local({
                                    ...employeeDetail_Local, [name]: !_checked
                                })
                            }}
                            color="primary"
                        />
                    }
                    labelPlacement="start"
                    label={label}
                    style={{ margin: 0 }}
                />
            </div>
        );
    };

    useEffect(() => {
        fetchFileInfos("employees", exploringId, setFileTemplates);
    }, [])

    const CompUploadFile = (comp_props) => {
        return (
            <UploadFile
                {...comp_props}
                table={"employees"}
                primaryId={exploringId}
                file={fileTemplates[comp_props.fileTemplate]}
                setFile={(file) => {
                    setFileTemplates({ ...fileTemplates, [comp_props.fileTemplate]: file })
                    onFieldChange_Bottom();
                }}
            >
            </UploadFile>
        );
    }

    const CompCheckboxForDate = ({ programType, label, name, onChange }) => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={completedOnCheckboxField[name]}
                            onClick={(event) => {
                                onChange(event);
                                handlecompletedOnCheckboxField(programType, name);
                                setIsSaveEnable(true);
                            }}
                            color="primary"
                        />
                    }
                    labelPlacement="end"
                    label={label}
                    style={{ margin: 0 }}
                />
            </>
        );
    };
    const CompDateInput = ({ programType, label, name, value, disabled, onChange }) => {
        return (
            <form className={classes.container} noValidate>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                        label={label}
                        name={name}
                        value={value}
                        onChange={(value) => {
                            onChange();
                            setEmployeeDetail_Local({ ...employeeDetail_Local, [name]: value });
                            setIsSaveEnable(true);
                            //Set the checkbox true when the date fills
                            setcompletedOnCheckboxField({
                                ...completedOnCheckboxField,
                                [name]: true,
                            })
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        disableToolbar
                        minDate={new Date(2000, 1, 1)}
                        maxDate={new Date(2100, 1, 1)}
                    />
                </LocalizationProvider>
            </form>
        );
    };

    const onBeforeSelect = (e) => {
        return !changedItems.includes("bottomContainer") || wConfirm(e, resetPrevData_Bottom, "You have unsaved changes, do you want to discard them?");
    }
    useEffect(() => {
        if (global.isEmpty(employeeDetail_Local.comfortHealthApprovedOn))
            return;
        if (!global.isEmpty(employeeDetail_Local.fingerprintedOn))
            return;
        setEmployeeDetail_Local({ ...employeeDetail_Local, ["fingerprintedOn"]: employeeDetail_Local.comfortHealthApprovedOn })
        setcompletedOnCheckboxField({
            ...completedOnCheckboxField,
            ["fingerprintedOn"]: true,
        });
    }, [employeeDetail_Local.comfortHealthApprovedOn]);

    const handleSetEmployeeDetail = (event, name) => {
        setEmployeeDetail_Local({
            ...employeeDetail_Local,
            [name]: event.target.value,
        });
    }

    const handleSaveEnable = (value) => {
        setIsSaveEnable(value)
    }
    return (
        <>
            <div>
                <Card variant="outlined">
                    <CardHeader
                        color="primary"
                        style={{
                            borderBottom: "1px solid rgba(0,0,0,0.12)",
                        }}
                        subheader={
                            <>
                                <span>Comfort Health Enrollment</span>
                                {
                                    employeeDetail_Local.isComfortEnrollmentOnHold ?
                                        <span
                                            style={{
                                                color: "#1976d2"
                                            }}
                                        >
                                            {'   [On Hold]'}
                                        </span> : null
                                }
                            </>
                        }
                        action={<SectionRightCardBtn onSaveRightCard={onSaveRightCard} isSaveEnable={isSaveEnable} />}
                    />
                    <CardContent classes={{ root: baseClasses.card_view }}>
                        <div className={classes.root}>
                            {/*
                                <AppBar
                                    className={baseClasses.appbarColor}
                                    position="static"
                                    elevation={0}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="scrollable auto tabs example"
                                    >
                                        <Tab
                                            className={baseClasses.tabClass}
                                            value="comfort_health"
                                            label="Comfort Health"
                                            {...setRowAndTab(0)}
                                        />
                                    </Tabs>
                                </AppBar>
                            */}
                            <div>
                                <TabPanel
                                    value={value}
                                    index="comfort_health"
                                    className={classes.tabRoot}
                                >
                                    <Card
                                        variant="outlined"
                                        style={{ marginBottom: '15px', padding: '10px', border: 0, backgroundColor: theme.palette.background.paper }}
                                    >
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onClick={(event) => {
                                                                    onFieldChange_Top()
                                                                    setEmployeeDetail_Local({
                                                                        ...employeeDetail_Local,
                                                                        isComfortEnrollmentOnHold: event.target.checked

                                                                    })
                                                                }}
                                                                checked={employeeDetail_Local.isComfortEnrollmentOnHold}
                                                            />
                                                        }
                                                        label={"On hold"}
                                                        labelPlacement={'start'}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={"Enrollment Note"}
                                                        fullWidth
                                                        rows={3}
                                                        multiline
                                                        onChange={(event) => {
                                                            onFieldChange_Top()
                                                            setEmployeeDetail_Local({
                                                                ...employeeDetail_Local,
                                                                comfortEnrollmentNote: event.target.value
                                                            })
                                                        }}
                                                        value={employeeDetail_Local.comfortEnrollmentNote}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} md={5} className={classes.leftSection}>
                                            <SectionLeftList

                                            ></SectionLeftList>
                                        </Grid>
                                        <Grid item xs={3} md={1}>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <div className={classes.rightSection}>
                                                <div className={classes.sectionCard}>
                                                    <SectionRightCard title={"Planning to enroll"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"Planning to enroll"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckbox onChange={onFieldChange_Bottom} label="Planning to enroll" name="planningToEnrollComfortHealth"></CompCheckbox>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Completed Application"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"Completed Application"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckbox onChange={onFieldChange_Bottom} label="Completed Application" name="hasComfortHealthApplication"></CompCheckbox>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"ID"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"ID"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <CompUploadFile
                                                                        fileTemplate={"ID"}
                                                                        label={"ID"}
                                                                    />
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"High School Diploma"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"High School Diploma"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckbox onChange={onFieldChange_Bottom} label="Has High School Diploma" name="hasHighSchoolDiploma"></CompCheckbox>
                                                                    </Grid>
                                                                    {/* ID */}
                                                                    {employeeDetail_Local.hasHighSchoolDiploma &&
                                                                        <CompUploadFile
                                                                            fileTemplate={"HighSchoolDiploma"}
                                                                            label={"High School Diploma"}
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Associate's Degree"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"Associate's Degree"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckbox onChange={onFieldChange_Bottom} label="Has Associate's Degree" name="hasAssociateDegree"></CompCheckbox>
                                                                    </Grid>
                                                                    {/* ID */}
                                                                    {employeeDetail_Local.hasAssociateDegree &&
                                                                        <CompUploadFile
                                                                            fileTemplate={"AssociateDegree"}
                                                                            label={"Associate Degree"}
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"BA"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"BA"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckbox onChange={onFieldChange_Bottom} label="Has BA" name="hasBA"></CompCheckbox>
                                                                    </Grid>
                                                                    {/* ID */}
                                                                    {employeeDetail_Local.hasBA &&
                                                                        <CompUploadFile
                                                                            fileTemplate={"BA"}
                                                                            label={"BA"}
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Dragon Profile"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"Dragon Profile"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Dragon profile created"
                                                                            name={"dragonProfileCreatedOn"}
                                                                            onChange={onFieldChange_Bottom}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Created On"
                                                                            name="dragonProfileCreatedOn"
                                                                            value={
                                                                                employeeDetail_Local.dragonProfileCreatedOn
                                                                            }
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Sent to Comfort Health"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"Sent to Comfort Health"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Sent to Comfort Health"
                                                                            name={"sentToComfortHealthOn"}
                                                                            onChange={onFieldChange_Bottom}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Sent On"
                                                                            name="sentToComfortHealthOn"
                                                                            value={
                                                                                employeeDetail_Local.sentToComfortHealthOn
                                                                            }
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Fingerprints"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"Fingerprints"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Fingerprint Appointment"
                                                                            name="fingerprintingScheduleDate"
                                                                            value={
                                                                                employeeDetail_Local.fingerprintingScheduleDate
                                                                            }
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Fingerprinted"
                                                                            name={"fingerprintedOn"}
                                                                            onChange={onFieldChange_Bottom}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    {/*<Grid*/}
                                                                    {/*    item*/}
                                                                    {/*    xs={12}*/}
                                                                    {/*    className={baseClasses.document_upload}*/}
                                                                    {/*>*/}
                                                                    {/*    <CompDateInput*/}
                                                                    {/*        programType="comfortHealth"*/}
                                                                    {/*        label="Printed On"*/}
                                                                    {/*        name="fingerprintedOn"*/}
                                                                    {/*        value={*/}
                                                                    {/*            employeeDetail_Local.fingerprintedOn*/}
                                                                    {/*        }*/}
                                                                    {/*        onChange={onFieldChange_Bottom}*/}
                                                                    {/*    />*/}
                                                                    {/*</Grid>*/}
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Approval & Information"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={classes.row_section}>
                                                                            <SectionHeading
                                                                                title={"Approval & Information"}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Comfort Health Approved"
                                                                            name={"comfortHealthApprovedOn"}
                                                                            onChange={onFieldChange_Bottom}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Approved For CATS"
                                                                            name={"catsApprovedOn"}
                                                                            onChange={onFieldChange_Bottom}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Approved For Prevoc"
                                                                            name={"prevocApprovedOn"}
                                                                            onChange={onFieldChange_Bottom}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    {/*<Grid item xs={4} style={{ paddingTop: "18px" }}>*/}
                                                                    {/*    <span style={{ fontWeight: "bold" }}>*/}
                                                                    {/*        Pre-Voc:*/}
                                                                    {/*    </span>*/}
                                                                    {/*    <span>*/}
                                                                    {/*        {fileTemplates && (fileTemplates[employeeFileTemplate.AssociateDegree] || fileTemplates[employeeFileTemplate.BA]) ? " Approved" +*/}
                                                                    {/*            (fileTemplates[employeeFileTemplate.BA] ? " (BA)" : " (Associate's Degree)")*/}
                                                                    {/*            : " Not Approved"}*/}
                                                                    {/*    </span>*/}
                                                                    {/*</Grid>*/}

                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Date Approved"
                                                                            name="comfortHealthApprovedOn"
                                                                            value={employeeDetail_Local.comfortHealthApprovedOn}
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Date Approved CATS"
                                                                            name="catsApprovedOn"
                                                                            value={employeeDetail_Local.catsApprovedOn}
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Date Approved Prevoc"
                                                                            name="prevocApprovedOn"
                                                                            value={employeeDetail_Local.prevocApprovedOn}
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>

                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                    >
                                                                        <CompTextlineInput
                                                                            label="Comfort Health ID"
                                                                            labelPlacement="start"
                                                                            id="cmfrt-h-id"
                                                                            name="comfortHealthEmployeeId"
                                                                            value={employeeDetail_Local.comfortHealthEmployeeId}
                                                                            onChange={onFieldChange_Bottom}
                                                                            InputProps={{ inputComponent: TInputNumber }}
                                                                            employeeDetail={employeeDetail_Local}
                                                                            handleSetEmployeeDetail={handleSetEmployeeDetail}
                                                                            handleSaveEnable={handleSaveEnable}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                    >
                                                                        <CompTextlineInput
                                                                            label="Amount Could Work "
                                                                            name="amountCouldWork"
                                                                            id="amnt-cld-wrk"
                                                                            dollar={true}
                                                                            onChange={onFieldChange_Bottom}
                                                                            employeeDetail={employeeDetail_Local}
                                                                            handleSetEmployeeDetail={handleSetEmployeeDetail}
                                                                            handleSaveEnable={handleSaveEnable}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                    >
                                                                        <CompTextlineInput
                                                                            label="Amount Billing "
                                                                            name="amountBilling"
                                                                            dollar={true}
                                                                            onChange={onFieldChange_Bottom}
                                                                            employeeDetail={employeeDetail_Local}
                                                                            handleSetEmployeeDetail={handleSetEmployeeDetail}
                                                                            handleSaveEnable={handleSaveEnable}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <CompTextlineInput
                                                                        id="spcreq"
                                                                        label="Special Requirements "
                                                                        name="specialRequirements"
                                                                        dollar={false}
                                                                        onChange={onFieldChange_Bottom}
                                                                        employeeDetail={employeeDetail_Local}
                                                                        handleSetEmployeeDetail={handleSetEmployeeDetail}
                                                                        handleSaveEnable={handleSaveEnable}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                    >
                                                                        <CompTextlineInput
                                                                            label="Hours providing"
                                                                            name="hoursWillingToProvide"
                                                                            type={'number'}
                                                                            onChange={onFieldChange_Bottom}
                                                                            employeeDetail={employeeDetail_Local}
                                                                            handleSetEmployeeDetail={handleSetEmployeeDetail}
                                                                            handleSaveEnable={handleSaveEnable}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                    >
                                                                        <CompTextlineInput
                                                                            label="Hours Could Work"
                                                                            name="hoursCouldWork"
                                                                            onChange={onFieldChange_Bottom}
                                                                            type={'number'}
                                                                            disabled={!employeeDetail_Local.specialRequirements}
                                                                            employeeDetail={employeeDetail_Local}
                                                                            handleSetEmployeeDetail={handleSetEmployeeDetail}
                                                                            handleSaveEnable={handleSaveEnable}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <CompSelectbox variant="standard" label="Receive Schedule By" name={"receiveScheduleBy"}
                                                                            value={employeeDetail_Local.receiveScheduleBy}
                                                                            employeeDetail={employeeDetail_Local}
                                                                            setDetail={setEmployeeDetail_Local}
                                                                            setEmployeeDetail={setEmployeeDetail_Local}
                                                                            onChange={onFieldChange_Bottom}
                                                                            listValues={[{ id: 'mail', name: "Mail", }, { id: 'office', name: "Office", }, { id: 'email', name: "Email", }]}
                                                                        >
                                                                        </CompSelectbox>

                                                                    </Grid>

                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Screened"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="center"
                                                                // spacing={2}
                                                                >
                                                                    <Grid item >
                                                                        <DateCheckbox
                                                                            label="Screened"
                                                                            name="screenedOn"
                                                                            setSelectedDate={setSelectedScreenedDate}
                                                                            selectedDate={selectedScreenedDate}
                                                                            employeeDetail_Local={employeeDetail_Local}
                                                                            setEmployeeDetail_Local={setEmployeeDetail_Local}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>

                                                                        <Grid item xs={12} md={8} lg={5.5}>
                                                                            <TextField
                                                                                label={"Screened By:"}
                                                                                value={employeeDetail_Local.screenedByName || ''} // Safeguard against undefined
                                                                                fullWidth
                                                                                onChange={(event) => {
                                                                                    onFieldChange_Top();
                                                                                    setEmployeeDetail_Local({
                                                                                        ...employeeDetail_Local,
                                                                                        screenedByName: event.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>

                                                                        <Grid item xs={12} md={8} lg={5.5}>
                                                                            <TextField
                                                                                label={"Screening Notes:"}
                                                                                value={employeeDetail_Local.screeningNotes || ''} // Safeguard against undefined
                                                                                fullWidth
                                                                                rows={3}
                                                                                multiline
                                                                                onChange={(event) => {
                                                                                    onFieldChange_Top();
                                                                                    setEmployeeDetail_Local({
                                                                                        ...employeeDetail_Local,
                                                                                        screeningNotes: event.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Interviewed"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="center"
                                                                // spacing={2}
                                                                >
                                                                    <Grid item >
                                                                        <DateCheckbox
                                                                            label="Interviewed"
                                                                            name="interviewedOn"
                                                                            setSelectedDate={setSelectedInterviewDate}
                                                                            selectedDate={selectedInterviewDate}
                                                                            onChange={onFieldChange_Bottom}
                                                                            employeeDetail_Local={employeeDetail_Local}
                                                                            setEmployeeDetail_Local={setEmployeeDetail_Local}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                        />
                                                                    </Grid>
                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>

                                                                        {/* <Grid item xs={12} md={8} lg={5.5}>
                                                                            <TextField
                                                                                label={"Interviewed By:"}
                                                                                value={interviewedByName}
                                                                                fullWidth
                                                                                // onChange={handleScreenedByNameChange}
                                                                                onChange={(event) => {
                                                                                    onFieldChange_Top()
                                                                                    setEmployeeDetail_Local({
                                                                                        ...employeeDetail_Local,
                                                                                        screenedByName: event.target.value
                                                                                    })
                                                                                    console.log(employeeDetail_Local)
                                                                                }}



                                                                            />
                                                                        </Grid> */}
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8} lg={5.5}>
                                                                        <TextField
                                                                            label={"Interviewed By:"}
                                                                            value={employeeDetail_Local.interviewedByName || ''} // Safeguard against undefined
                                                                            fullWidth
                                                                            onChange={(event) => {
                                                                                onFieldChange_Top();
                                                                                setEmployeeDetail_Local({
                                                                                    ...employeeDetail_Local,
                                                                                    interviewedByName: event.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>
                                                                        <Grid container alignItems="center" sx={{ marginTop: 2 }}>
                                                                            <Grid item xs={12} md={8} lg={5.5}>
                                                                                <TextField
                                                                                    label={"Interview Notes:"}
                                                                                    value={employeeDetail_Local.interviewNotes || ''}
                                                                                    fullWidth
                                                                                    rows={3}
                                                                                    multiline
                                                                                    onChange={(event) => {
                                                                                        onFieldChange_Top();
                                                                                        setEmployeeDetail_Local({
                                                                                            ...employeeDetail_Local,
                                                                                            interviewNotes: event.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    )
};