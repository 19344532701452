import React from 'react';
import BadgeComponent  from '@mui/material/Badge';
import {BadgeProps} from './types';

const Badge: React.FC<BadgeProps> = ({content, color, children}) => {
  return (
    <BadgeComponent badgeContent={content} color={color ?? 'primary'} showZero>
        {children}
    </BadgeComponent>
  )
}

export default Badge