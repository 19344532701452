import React from 'react';
import { HourHeaderContainer, Gap } from './style';
import { hoursInDay } from '../../../../../constants';
import { DivContainer, FlexBetween, FlexColExactCenter, VerticalDivider, Flex } from '../../../../../assets/styles/styledComponents';
const HourHeader = () => {
  return (
    <HourHeaderContainer background="#e2eefa" display="flex">
      <Gap width="120px"/>
        <FlexBetween width="100%">
          { hoursInDay.map((hour: string) => (
            <Flex>
              <FlexColExactCenter>
                <DivContainer>
                  {hour}
                </DivContainer>
                <VerticalDivider height="15px"/>
              </FlexColExactCenter>
            </Flex>
          ))}
          <Gap width="43px"/>
        </FlexBetween>
    </HourHeaderContainer>
  
  )
}

export default HourHeader