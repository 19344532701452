import React, { useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { DialogContent, Dialog, Button, DialogActions, TextField, Grid, FormControl, TextareaAutosize } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
//import Dialog from '@mui/material/Dialog';
//import Button from '@mui/material/Button';
//import DialogActions from '@mui/material/DialogActions';
//import TextField from '@mui/material/TextField';
import useStyles from '../styles'

export default function EndSchedule(props) {
    const { end, handleEnd, handleClose, data, setData } = props;
    const [errorText, setErrorText] = useState(false);
    const classes = useStyles();
    const handleEndDateChange = (newValue) => {
        setData({ ...data, endDate: newValue })
        if (!newValue)
            setErrorText("End date is required");
        else if (new Date(newValue) < new Date(data.startDate))
            setErrorText("Schedule start date is " + global.dateTime.getUSAFormattedDateString(data.startDate));
        else
            setErrorText("");
    }
    const handleCloseAndEnd = (data) => {
        if (!data.endDate) {
            setErrorText("End date is required");
            return;
        }
        else if (new Date(data.endDate) < new Date(data.startDate)) {
            setErrorText("Schedule start date is " + global.dateTime.getUSAFormattedDateString(data.startDate));
            return;
        }
        handleEnd(data);
    }
    return (
        <Dialog onClose={handleCloseAndEnd} aria-labelledby="simple-dialog-title" open={end} maxWidth="xs" fullWidth={true}>
            <DialogContent>
                <h6 >End Schedule</h6>
                {/*<Grid xs={12}>*/}
                {/*<div className="text-delete">*/}
                {/*    <TextField*/}
                {/*        id="date"*/}
                {/*        label="Schedule End Date"*/}
                {/*        type="date"*/}
                {/*        className={classes.textField}*/}
                {/*        value={data.endDate}*/}
                {/*            onChange={(event) => { setData({ ...data, endDate: event.target.value} ) }}*/}
                {/*        InputLabelProps={{*/}
                {/*            shrink: true,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    </div>*/}
                {/*</Grid>*/}
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: "20px", paddingBottom: "0px", paddingTop:"30px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    id={"end-date"}
                    label="Schedule End Date"
                    format="MM/dd/yyyy"
                                style={{ marginRight: 4 }}
                                value={data.endDate}
                    size="small"
                     onChange={handleEndDateChange}
                    renderInput={(params) => <TextField 
                        {...params}
                        size={'small'}
                        />}
                />
                </LocalizationProvider>
                        {
                            errorText &&
                            <p style={{ color: "red" }}>{ errorText}</p>
                        }
                </Grid>
                    <Grid item xs={12} style={{ padding: "20px" }}>
                        <TextField
                            multiline
                            fullWidth
                            size={"small"}
                            variant={"outlined"}
                            label={"Reason stopped working"}
                            id={"reason-schedule-ended"}
                            aria-label="minimum height" placeholder=""
                            // className={classes.textArea}
                            //InputLabelProps={{
                            //    classes: {
                            //        focused: classes.textareafocused,
                            //    },
                            //}}
                            value={data.reasonScheduleEnded}
                            onChange={(event) => setData({...data, reasonScheduleEnded: event.target.value})}
                        />
                    </Grid>
                    </Grid>
            </DialogContent>
            <DialogActions>
                <Button className= {classes.btnCancel} onClick={() => { global.log(data); handleClose(); }}>Cancel</Button>

                <Button className= {classes.btnSave} onClick={() => handleCloseAndEnd(data)}>
                    End Schedule
                </Button>
            </DialogActions>
        </Dialog>
    )
}