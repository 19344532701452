import React from 'react'
import Freshdesk from './FreshdeskWidget'


const widgetId = 150000000317;
const FreshdeskWebWidget = () => {
    return global?.account?.userValue && <Freshdesk
        widgetId={widgetId}
    />
}

export default FreshdeskWebWidget;