import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    root: {
        "& .MuiListItem-root .Mui-selected": {
            backgroundColor: "rgb(83, 109, 253)",
            color: "white",
            borderRadius: 3,
        },
    },
    content: {
        height: "100%",
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        margin: "12px 26px",
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        /*'& .MuiPaper-outlined': {
                border: '0px',
            }*/
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .MuiTab-root": {
            color: "#FFFFFF"
        },
    },
    leftSection: {
        verticalAlign: "top",
        maxHeight: "500px",
        overflow: 'auto',
        direction: 'rtl',
        "& span": {
            fontSize: "13px !important",
        },
        "& .MuiListItem-root": {
            direction: 'ltr',
            borderRadius: "6px !important",
            padding: "18px 11px !important"
        },
        "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04) !important",
            color: "#0056b3 !important",
        },
    },
    rightSection: {
    },
    selection__autocomplete: {
        margin: "0px 12px",
        "& .MuiFormControl-marginNormal": {
            marginTop: "0px",
            marginBottom: "16px",
        },
    },
    accordionHeading: {},
    sectionCard: {
        width: "100%",
    },
    card_view: {
        /*overflow: 'scroll',*/
        padding: "0px",
    },
    tabClass: {
        minWidth: "100px",
        textTransform: "capitalize",
        "&.Mui-selected": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,
        },
    },
    card_title: {
        margin: "4px",
        fontSize: "13px",
    },
    panelContent: {
        width: "100%",
        margin: "0 auto",
        height: '80vh',
        overflow: 'auto'
    },

    // Programs
    collapseHeader: {
        flex: 2,
        fontWeight: "bold",
    },
    contentOfaccordion: {
        width: "100%",
        padding: "4px 16px",
        margin: "6px 2px",
    },
    date: {
        flex: 1,
        color: "#c3c3c3",
        marginTop: "6px",
        textAlign: "right",
        fontSize: 12,
    },
    card_title: {
        flex: 14,
    },
    
    

    
    
}));
