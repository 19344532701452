import * as React from "react";
import { EMPLOYEE_PROGRAM_DOCUMENT_UPLOAD } from "../../constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import AnimLoadingState from "../common/AnimLoadingState";

/**
 *
 * @param {label, data, onFileChange} props
 * @display {Label, Name, Upload Document control, Document Icon}
 */

export default function UploadFileComponent(props) {
    //   const classes = useStyles();
    const [note, setNote] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState("Upload your file");
    const [isLoading, setIsLoading] = React.useState(false);
    let fileName = "";
    const exploringId = useStoreState((state) => state.exploringId);

    function getB64Str(buffer) {
        var binary = "";
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    let uploadClientTemplateFile = (employeeId, templateId, fileName, fileExtension, file) => {
        var url = EMPLOYEE_PROGRAM_DOCUMENT_UPLOAD;

        const payload = { id: exploringId, fileBytes: file, fileName: fileName, templateId: templateId, fileExtension: fileExtension }
        global.log(payload);
        setIsLoading(true)
        return new Promise((resolve, rejects) => {
            global.fetchWrapper.post(url, payload).then(
                (response) => {
                    setIsLoading(false)
                    if (typeof props.onSuccessfulUpload === 'function')
                        props.onSuccessfulUpload();
                    resolve(response)
                },
                (error) => {
                    resolve(error);
                    global.AjaxFailToast();
                }
            )
        }

        )
    }

    const onUploadFile = (file, fileName) => {
        uploadClientTemplateFile(props.data.employeeId, props.data.templateId, fileName, "txt", file).then((response) => {
            props.onFileChange(props.label, getB64Str(file));
        });
    };

    return (
        <div className="input-group">
            <div className="custom-file" style={{position: 'relative'}}>
                <AnimLoadingState loading={props.loading || isLoading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
                <input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile01"
                    name="file"
                    onChange={(e) => {
                        global.log('props as seen from the file input:', props.data)
                        setSelectedFile(e.target.files[0].name);
                        if (e.target.files.length == 0) return;
                        else {
                            var reader = new FileReader();
                            reader.onload = function (event) {
                                onUploadFile(getB64Str(event.target.result), fileName);
                            };
                            fileName = e.target.files[0].name;
                            reader.readAsArrayBuffer(e.target.files[0]);
                        }
                    }}
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                    {props.label != "" ? props.label : "Upload Your File"}
                </label>
            </div>
        </div>
    );
}
