import React from 'react'
import {Button} from '@mui/material'
import useBaseStyles from '../../../assets/styles'
import useStyles from './styles';

const CardHead = (props) => {
    const classes = useStyles();
    const baseClasses = useBaseStyles();
    const {title, types, allowedActions, setSlcRow_Type, setEditShow, openNewModel, scheType, changeScheduleType, scheduleType, quickFilters} = props;
    return (

        <div className = {classes.cardHeader}>
            <div className = {classes.cardTitle}>{title === 'History' ? 'Schedule History (12 Months)' : title}</div>
            {scheduleType === "sessions" &&
                <div className = {classes.cardTitle} style={{color: "red"}}>Sessions History not ready yet</div>
            }
            {
                quickFilters?.type === 'select' && (
                    quickFilters.dropdowns?.map((dropdown) => (
                        <select
                            className={classes.selectScheduleType}
                            onChange={(e) => {
                                dropdown.handler(e)
                            }
                            }
                            value={dropdown.value}
                        >
                            {
                                dropdown?.options?.map((option, index) => {
                                    return <option value={option.value}>{option.label}</option>
                                })
                            }
                        </select>
                    ))
                    
                )

            }
            {allowedActions.includes('add') && 
                <Button className = {baseClasses.cursorPointer}
                        onClick={() => {
                        if (title === 'Appointment') {
                            setSlcRow_Type("appointment");
                            setEditShow(true) 
                        } else {
                            openNewModel(scheType);
                        }
                    }}>
                    + Add
                </Button>
            }
        </div>
    )
}

export default CardHead