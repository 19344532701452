import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "0 auto",
        width: "100%",
    },
    fixbar: {
        marginTop: "20px",
    },
    
}));
export default useStyles;