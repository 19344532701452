import React, { useState } from 'react';
import { Button, TextField, Paper, Select, MenuItem, InputLabel, FormControl, Grid } from '@mui/material';

const HistoryInfo = ({ setHistoryData, handleNext, handlePrev, defaultHistoryData }) => {
    const [historyData, setFormData] = useState({
        HasCar: defaultHistoryData?.HasCar ?? false,
        LevelOfEducation: defaultHistoryData?.LevelOfEducation ?? '',
        YearsOfExperienceInChinuch: defaultHistoryData?.YearsOfExperienceInChinuch ?? '',
        PrevYeshivas: defaultHistoryData?.PrevYeshivas ?? '',
        ExperienceDescription: defaultHistoryData?.ExperienceDescription ?? '',
        AdditionalNotes: defaultHistoryData?.AdditionalNotes ?? '',
        PreferredRate: defaultHistoryData?.PreferredRate ?? '',
        // ratePeriod: ''
    });

    const [fieldErrors, setFieldErrors] = useState({
        rate: null,
        ratePeriod: null
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleNextClick = () => {
        setHistoryData(historyData);
        handleNext();
    };

    const handlePrevClick = () => {
        setHistoryData(historyData);
        handlePrev();
    };

    const fieldStyle = {
        marginBottom: '16px',
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: '94vh',
            padding: '10px',
            backgroundColor: '#f5f5f5'
        }}>
            <Paper style={{ padding: 30, maxWidth: '700px', width: '700px', minHeight: '94vh', }}>
                <form noValidate autoComplete="off" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>

                    <FormControl fullWidth style={fieldStyle}>
                        <InputLabel>Level of Ed</InputLabel>
                        <Select
                            name="LevelOfEducation"
                            value={historyData.LevelOfEducation}
                            onChange={handleChange}
                        >
                            <MenuItem value="High school diploma">High school diploma</MenuItem>
                            <MenuItem value="BA">BA</MenuItem>
                            <MenuItem value="P3">P3</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField label="Yeshivos Worked" name="PrevYeshivas" fullWidth onChange={handleChange} style={fieldStyle} value={historyData.PrevYeshivas} />

                    <TextField label="Years of Experience" name="YearsOfExperienceInChinuch" fullWidth onChange={handleChange} style={fieldStyle} type="number" value={historyData.YearsOfExperienceInChinuch}
                    />

                    <TextField label="Desired Pay" name="PreferredRate" fullWidth onChange={handleChange} style={fieldStyle} type="number" value={historyData.PreferredRate} />

                    <TextField
                        label="Experience Descriptions"
                        name="ExperienceDescription"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={handleChange}
                        style={fieldStyle}
                        value={historyData.ExperienceDescription}
                    />

                    <TextField
                        label="Notes"
                        name="AdditionalNotes"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={handleChange}
                        style={fieldStyle}
                        value={historyData.AdditionalNotes}
                    />

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', justifyContent: 'flex-start' }}>
                        <Button variant="text" color="primary" onClick={handlePrevClick}>
                            Previous
                        </Button>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', justifyContent: 'flex-end' }}>

                        <Button variant="text" color="primary" onClick={handleNextClick}>
                            Next
                        </Button>
                    </div>
                </form>
            </Paper>
        </div>
    );
};

export default HistoryInfo;

