import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ProgramData } from '../../components/data/ClientDetails';
import { relationShipCollapseColumns, relationShipCollapseRows } from '../../api/contact';
import useStyles from './styles';


function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
   
    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell  component="th" scope="row">
                    {row.name}
                </TableCell> 
                <TableCell  align="center">{row.email}</TableCell>
                <TableCell  align="center">{row.phone}</TableCell>
                <TableCell  align="center">Client</TableCell>
                <TableCell  align="center">Yes</TableCell>
                <TableCell  align="center">0</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography className={classes.sub_header} variant="subtitle1" gutterBottom component="div">
                                Relationship
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {relationShipCollapseColumns.map((columns, index) => (
                                            <TableCell key={index} align={index == 0 ? "left" : "center"}>{columns.headerName}</TableCell>
                                        ))} 
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {relationShipCollapseRows.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell className={classes.cell} align="left" component="th" scope="row">
                                                {historyRow.name}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="center"> {historyRow.emailaddress}</TableCell>
                                            <TableCell className={classes.cell} align="center">
                                                {historyRow.phoneNumber}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="center"> {historyRow.relationship}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default function ContactTable(props) {
    const classes = useStyles();
    global.log('props..', props);
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead className={classes.header}>
                    <TableRow>
                        <TableCell />
                        {props.data.columns.map((column, index) => (
                            <TableCell key={index} align={index == 0 ? "left" : "center"}>{column.headerName}</TableCell>
                        ))}
                        {/* <TableCell>Services</TableCell>
                        <TableCell align="right">Enrolled On</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Me'argen</TableCell>
                        <TableCell align="right">Care Manager</TableCell>
                        <TableCell align="right">BCBA</TableCell>
                        <TableCell align="right">Active</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.rows.map((row, index) => (
                        <Row key={index} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}