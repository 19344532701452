import React, { useState, useEffect, useRef } from "react";
import ReactLoading from 'react-loading';
import {
    Button, FormControlLabel,
    Checkbox, Grid, Dialog, Tooltip,
    MenuItem, TextField, Box, DialogActions, DialogContent, Select, Link
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { useStoreActions, useStoreState } from "easy-peasy";

import * as constant from "../../../../constants";
import FRONTEND_ROUTE_CONSTANTS from "../../../../frontend_route_constants";
import { getNextAppointmentString, validateRatePeriod, validateRate, DateRangeValidator, TimeRangeValidator, validateDaySelections, validateProfessionalTypeSelection, ScheduleAvailabilityCalculator, ScheduleAvailabilityStatus } from '../../../../_helpers/schedule/validators/client-schedule';
import SCHEDULE_PERIODICITY_CONSTANTS from '../../../../_helpers/constants/schedule_periodicity_constants.js';
import { getScheduleStringForProfessional, getNextSession, modalFields, getDaysArrayFromDaysString } from '../../../../_helpers/schedule';
import { getMinutesFromMidnight } from '../../../../_helpers/dateHelper';
import { authorization } from "../../../../_services/authorization.service"; //permissions, userHasRole
import { periodicities } from '../../../../_helpers/constants/scheduleConstants';
import useUnsavedChangesWarn from "../../../../hooks/useUnsavedChangesWarn";
import ProtectedComponent from '../../../ProtectedComponent/ProtectedComponent';
import useActiveToolTip from '../../../../hooks/useActiveToolTip';
import useKeyTracker, { KeyEvent } from '../../../../hooks/useKeyTracker';
import useStyles from '../styles';
import ConfirmDialog from "../../../common/ConfirmDialog/ConfirmDialog";
import ProviderAvailabilityStatusTooltip from '../../AvailabiltyFilter/ProviderAvailabilityStatusTooltip';
import FinancialInfo from '../../FinancialInfo';
import AvailablityFilter from "../../AvailabiltyFilter";
import ScheduleForm from '../../ScheduleForm';
import Timesheet from '../../Timesheet';
import { MUITabs, TabPanel } from '../../../common/UI'
import { DivContainer, BoxContainer, FlexBetween, FlexAlignCenter, FlexEvenly, Title, FlexExactCenter } from "../../../../assets/styles/styledComponents";
import { getTimesheetEntries, saveTimesheetEntries } from '../../../../api/timesheet';
import { daysOfWeek } from "../../../../_helpers/datetime";


const filterDefaultDate = (dateValue) => {
    return global.dateTime.filterDefaultDate(dateValue, undefined);
}

const isDateDefaultValue = (dateValue) => {
    return global.dateTime.isDateDefaultValue(dateValue);
}
const defaultWeekset = "custom";

const openProviderDetailsPageInNewTab = (event, providerDetailsRoute, providerId) => {
    event.stopPropagation();
    const win = window.open(`${providerDetailsRoute}${providerId}${FRONTEND_ROUTE_CONSTANTS.DETAILS}`);
    win.focus();
}

const parseAvailableYungermanSchedules = (availabilities, schedules, potentialSchedules) => {
    const defaultYungermanPeriodicity = {
        periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS,
        everyXOfPeriods: 1,
    };

    const parseSchedule = (schedule) => ({
        days: schedule.days,
        startDate: schedule.startDate,
        endDate: schedule.endDate,
        startTime: schedule.startTime,
        endTime: schedule.endTime,
        ...defaultYungermanPeriodicity,
    });

    return {
        availabilities: availabilities.map(availability => ({
            ...availability,
            ...defaultYungermanPeriodicity,
        })),
        schedules: schedules.map(schedule => ({
            ...schedule,
            schedule: parseSchedule(schedule),
        })),
        potentialSchedules: potentialSchedules.map(schedule => ({
            ...schedule,
            schedule: parseSchedule(schedule),
        }))
    }
}

const scheduleTypeTitles = {
    yungerman: "Yungerman",
    professional: "Professional",
}

const ModelStaticInfo = (props) => {
    const { scheduleType, client, contextData, clientFields, providersAvailability, showSearchProvider, actionPermissions, setPotentialSectionOpened, clientScheduleId } = props;
    const classes = useStyles();
    return (
        <DivContainer margin="0px 0px 0px 0px" padding="0px, 0px">
            <DivContainer>
                <Title>{clientScheduleId === undefined ? "Add" : "Edit"} Schedule <i id={modalId + "id"}>{clientScheduleId}</i></Title>
            </DivContainer>
            <FlexAlignCenter wrap="wrap">
                <Grid>
                    <Grid item xs={12}>
                        {
                            modalFields.clientName.includes(scheduleType) &&
                            <div>
                                <p className={classes.infoTitle} style={{ fontSize: '14px' }}>Bucher:
                                    <span id={modalId + "cli"} className={classes.infoValue} style={{ fontSize: '14px' }}>
                                        {client ? client.name : contextData?.clientName !== undefined ? contextData?.clientName : "-"}
                                    </span>
                                </p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            modalFields.comfortStatus.includes(scheduleType) &&
                            <div style={{ display: 'block' }}>
                                <p className={classes.infoTitle}>Comfort Health Status:
                                    <span id={modalId + "cmhsts"} className={classes.infoValue}>
                                        {clientFields != undefined ? clientFields.comfortHealth_status : "comfortHealth_status"}
                                    </span>
                                </p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            contextData !== undefined && contextData.providerId > 0 ?
                                <div>
                                    <p className={classes.infoTitle}>{scheduleTypeTitles[scheduleType]}:
                                        <span id={modalId + "pvd"} className={classes.infoValue}>{contextData.providerName} </span>
                                        {providersAvailability &&
                                            <ProviderAvailabilityStatusTooltip
                                                isExternalProvider={false}
                                                availabilityForClientSchedule={providersAvailability.availabilityForClientSchedule}
                                                conflictingSchedules={providersAvailability.conflictingSchedules}
                                                conflictingPotentialSchedules={providersAvailability.conflictingPotentialSchedules}
                                                durationBasedFilterConflicts={providersAvailability.durationBasedFilterConflicts}
                                            />
                                        }
                                        {!showSearchProvider &&
                                            <ProtectedComponent
                                                canEdit={actionPermissions?.canSetEmployee}
                                                allow={actionPermissions?.canSetEmployee}
                                                inline
                                            >
                                                <Link onClick={() => setPotentialSectionOpened(true)} color="#212121" style={{ cursor: 'pointer' }} underline="always">Change</Link>
                                            </ProtectedComponent>
                                        }
                                    </p>
                                </div>
                                : null
                        }
                    </Grid>
                </Grid>
            </FlexAlignCenter>
        </DivContainer>
    )
}
const compElementId = "clidtl-sch-yunsch";
const modalId = compElementId + "-";

export default function YungermanModal(props) {
    const { show, handleClose, scheduleContext, handleEdit, clientSchedule, clientScheduleId, setClientSchedule, setIsLoading, scheduleType, isSeekingProvider, client, actionPermissions } = props;
    const [customDays, setCustomDays] = React.useState([]);
    const [schedule, setSchedule] = React.useState({ periodicitySchedule: 'weekly', periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS, everyXOfPeriods: 1, days: [], monthDays: [] });
    const defaultContextData = clientSchedule;
    const [contextData, setContextData] = useState(clientSchedule);
    const [customSchedule, setCustomSchedule] = React.useState({});
    const [potentialSectionOpened, setPotentialSectionOpened] = React.useState(false);
    const [isResultsLoading, setIsResultsLoading] = React.useState(false);
    const clientFields = useStoreState((state) => state.clients.fields);
    const [potentialsAvailabilityInfoByProviderId, setPotentialsAvailabilityInfoByProviderId] = React.useState({});
    const [providersAvailability, setProvidersAvailability] = React.useState(null);
    const [missingSpecialtiesByEmployeeId, setMissingSpecialtiesByEmployeeId] = React.useState({});
    const [scheduleRate, setScheduleRate] = React.useState({});
    const [isScheduleEdited, setIsScheduleEdited] = React.useState(false);
    const [isRateEdited, setIsRateEdited] = React.useState(false);
    const [showWarningModal, setShowWarningModal] = React.useState(false);
    const [warnings, setWarnings] = React.useState([]);
    const [potentialLimitReach, setPotentialLimitReach] = React.useState(false);
    const [calendarType, setCalendarType] = React.useState("heb")
    const [paymentType, setPaymentType] = React.useState("");
    const [financeStatusList, setFinanceStatusList] = React.useState({})
    const [tabError, setTabError] = React.useState({
        schedule: false,
        financialInfo: false,
        searchProvider: false
    })
    // Timesheet state
    const [timesheetSelectedDate, setTimesheetSelectedDate] = useState();
    const [timesheetWeeksRange, setTimesheetWeeksRange] = useState([]);
    const [timesheetSelectedWeek, setTimesheetSelectedWeek] = useState();
    const [timesheetDaysTimeFrame, setTimesheetDaysTimeFrame] = useState(constant.timesheetDaysTimeFrameConst)
    const timesheetTabShowShortcut = useKeyTracker({ keyEvents: [{ key: 'l', ctrlKey: true, shiftKey: true }] });

    const classes = useStyles();

    const [availableProvidersRows, setAvailableProvidersRows] = React.useState([]);
    // neighborhoods
    const neighborhoodsIsFetched = useStoreState(state => state.list.values.neighborhoodsIsFetched);
    const fetchNeighborhoods = useStoreActions(actions => actions.list.values.fetchNeighborhoods);
    const [endDate, setEndDate] = React.useState(false);
    const [weekset, setWeekset] = React.useState(defaultWeekset);
    const [fieldErrors, setFieldErrors] = React.useState({});
    const [tabs, setTabs] = useState(constant.YUNGERMAN_MODAL_TABS.filter((t => t.value !== "searchProvider" || scheduleType != 'availability'))) // remove search-provider at availability)

    const [selectedTab, setSelectedTab] = React.useState(tabs[0].value)

    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    const handleNeighborhoodChange = (event) => {
        setDirty();
        setContextData({ ...contextData, "neighborhood": event.target.value });
    }
    // professional types
    const professionalTypesValues = useStoreState(state => state.list.values.professionalTypes);
    const professionalTypesIsFetched = useStoreState(state => state.list.values.professionalTypesIsFetched);
    const fetchProfessionalTypes = useStoreActions(actions => actions.list.values.fetchProfessionalTypes);
    const handleProfessionalTypesChange = (event) => {
        setDirty();
        setTabs(tabs.map((tab) => (
            tab.value === 'schedule' ?
                { ...tab, isError: false } :
                tab
        )))
        const professionalTypeSelectionValidationResult = validateProfessionalTypeSelection(scheduleType, event.target.value);
        setContextData({ ...contextData, "professionalType": event.target.value });
        setFieldErrors({
            ...fieldErrors,
            ...professionalTypeSelectionValidationResult.fieldMessages,
        });
    }
    // 
    const specialtyValues = useStoreState(state => state.list.values.specialties);
    const specialtiesIsFetched = useStoreState(state => state.list.values.specialtiesIsFetched);
    const fetchSpecialties = useStoreActions(actions => actions.list.values.fetchSpecialties);

    const handleSpecialtiesChange = (event) => {
        setDirty();

        var value = event.target.value;
        let idArray = []
        let eventValuesToBeAdded = [];
        let valueToBeRemoved = 0;
        value.forEach(ev => {
            if (!idArray.includes(ev)) {
                idArray.push(ev)
            } else {
                valueToBeRemoved = ev
            }
        })
        value.forEach(ev => { if (ev !== valueToBeRemoved) eventValuesToBeAdded.push(ev); })
        //global.log("event value:", eventValuesToBeAdded)
        setContextData({ ...contextData, ['specialties']: eventValuesToBeAdded });
        updateEmployeesMissingSpecialties(eventValuesToBeAdded);
    }

    const handleRateChange = (event) => {
        setDirty();
        var value = event.target.value;
        if (value && Number.isInteger(parseFloat(value)) == false) // TODO: check it
            return;
        value = !value ? 0 : parseFloat(value);
        var rateFrom = event.target.id === "clidtl-sch-yunsch-rtefrm" ? value : contextData.rateFrom;
        var rateTo = event.target.id === "clidtl-sch-yunsch-rteto" ? value : contextData.rateTo;
        setContextData({ ...contextData, rateFrom: rateFrom, rateTo: rateTo });
    }

    const handleTimeChange = (value, field) => {
        setDirty();
        var timeFrom = field === "timeFrom_Filter" ? value : contextData.timeFrom_Filter;
        var timeTo = field === "timeTo_Filter" ? value : contextData.timeTo_Filter;
        const newSchedule = { ...contextData, timeFrom_Filter: timeFrom, timeTo_Filter: timeTo }
        setContextData(newSchedule);
        updateProviderAvailabilitiesInfo(newSchedule);
    }

    const handleDurationChange = (value, field) => {
        setDirty();
        const newSchedule = { ...contextData, duration_Filter: value }
        setContextData(newSchedule);
        updateProviderAvailabilitiesInfo(newSchedule);
    }

    const handleChange_OnlyLhisalosApproved = (event) => {
        setDirty();
        setOnlyLhisalosApproved(!onlyLhisalosApproved);
        setContextData({ ...contextData, onlyLhisalosApproved: !onlyLhisalosApproved });
    }

    const handleDayToggle = (value) => {
        setDirty();
        if (weekset === value)
            return;
        if (value === "custom") {
            setWeekset("custom");
            setDays(customDays);
        }
        else {
            if (value === "sunthu") {
                setDays([0, 1, 2, 3, 4]);
            }
            else if (value === "sunfri") {
                setDays([0, 1, 2, 3, 4, 5]);
            }
        }
    }

    const periodicityScheduleChanged = (periodicitySchedule) => {
        setDirty();
        if (periodicitySchedule) {
            let newSchedule;

            switch (periodicitySchedule) {
                case "weekly":
                    newSchedule = { ...schedule, periodicitySchedule: periodicitySchedule, everyXOfPeriods: 1, periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS };
                    break;
                case "bi-weekly":
                    newSchedule = { ...schedule, periodicitySchedule: periodicitySchedule, everyXOfPeriods: 2, periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS };
                    break;
                case "monthly":
                    newSchedule = { ...schedule, periodicitySchedule: periodicitySchedule, everyXOfPeriods: 1, periodicity: SCHEDULE_PERIODICITY_CONSTANTS.MONTHS };
                    break;
                case "custom":
                    newSchedule = {
                        ...schedule,
                        periodicitySchedule: periodicitySchedule,
                        everyXOfPeriods: schedule.everyXOfPeriods ?? customSchedule.everyXOfPeriods,
                        periodicity: schedule.periodicity ?? customSchedule.periodicity,
                    };
                    break;
                default:
            }
            if (newSchedule) {
                setSchedule(newSchedule);
                updateClientSchedule(newSchedule);
                updateProviderAvailabilitiesInfo(getUpdatedClientScheduleFromSchedule(newSchedule));
                setIsScheduleEdited(true)
            }
        }
    }

    const getPeriodicityInfoFromClientSchedule = () => {
        if (contextData != undefined && contextData.schedule != undefined) {
            switch (contextData.schedule.periodicity) {
                case SCHEDULE_PERIODICITY_CONSTANTS.WEEKS:
                    return {
                        periodicitySchedule:
                            contextData.schedule.everyXOfPeriods == 1 ? "weekly" : contextData.schedule.everyXOfPeriods == 2 ? "bi-weekly" : "custom",
                        everyXOfPeriods: contextData.schedule.everyXOfPeriods, periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS, days: contextData.schedule.days.split(',')
                    };
                case SCHEDULE_PERIODICITY_CONSTANTS.MONTHS:
                    return {
                        periodicitySchedule: contextData.schedule.everyXOfPeriods == 1 ? "monthly" : "custom",
                        everyXOfPeriods: contextData.schedule.everyXOfPeriods, periodicity: SCHEDULE_PERIODICITY_CONSTANTS.MONTHS, monthDays: contextData.schedule.days.split(',')
                    };
                default:
            }
        }
    }

    const getUpdatedClientScheduleFromSchedule = (schedule) => {
        return {
            ...contextData, schedule: {
                ...contextData.schedule,
                periodicity: schedule.periodicity,
                everyXOfPeriods: schedule.everyXOfPeriods,
                days: schedule.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ?
                    schedule.days != undefined ? schedule.days.join(",") : "" : schedule.monthDays != undefined ? schedule.monthDays.join(",") : ""
            }
        };
    }

    const updateClientSchedule = (schedule) => {
        setDirty();
        setContextData(getUpdatedClientScheduleFromSchedule(schedule));
    }

    const setMonthDays = (event, days) => {
        setDirty();
        setSchedule({ ...schedule, monthDays: days });

        const newClientSchedule = {
            ...contextData,
            schedule: {
                ...contextData.schedule, periodicity: schedule.periodicity, everyXOfPeriods: schedule.everyXOfPeriods,
                days: days.join(",")
            }
        };

        setContextData(newClientSchedule);
        updateProviderAvailabilitiesInfo(newClientSchedule);
        setIsScheduleEdited(true)
        const validationResult = validateDaySelections(fieldsValidation.daysSelection.includes(contextData.type), schedule.periodicity, days);
        if (validationResult.isValid) {
            setTabs(tabs.map((tab) => (
                tab.value === 'schedule' ?
                    { ...tab, isError: false } :
                    tab
            )))
        }
        setFieldErrors({
            ...fieldErrors,
            ...validationResult.fieldMessages,
        });
    }

    const setDays = (days, updateClientSchedule = true, updatedSchedule = null, onRender = false) => {
        if (!onRender)
            setDirty();
        if (days.join(",") != "0,1,2,3,4" && days.join(",") != "0,1,2,3,4,5")
            setCustomDays(days);

        if (updateClientSchedule) {
            const newClientSchedule = { ...contextData, schedule: { ...contextData.schedule, days: days.join(",") } };
            setContextData(newClientSchedule);
            updateProviderAvailabilitiesInfo(newClientSchedule);
            setIsScheduleEdited(true)
        }

        setSchedule({ ...(updatedSchedule ? updatedSchedule : schedule), days: days });
        setWeekset(getWeekset(days));
        const validationResult = validateDaySelections(fieldsValidation.daysSelection.includes(contextData.type), schedule.periodicity, days);
        if (validationResult.isValid) {
            setTabs(tabs.map((tab) => (
                tab.value === 'schedule' ?
                    { ...tab, isError: false } :
                    tab
            )))
        }
        setFieldErrors({
            ...fieldErrors,
            ...validationResult.fieldMessages,
        });
    }

    const getWeekset = (days) => {
        if (contextData != undefined && contextData.schedule != undefined) {
            var weekdayString = global.dateTime.getWeekdaysString(days);
            if (weekdayString == "Sun - Thu")
                return "sunthu";
            else if (weekdayString == "Sun - Fri")
                return "sunfri";
            else
                return "custom";
        } else {
            return "custom"
        }
    }

    const handleClear = (providerId) => {
        if (!contextData.clientProviderSchedulePotentials)
            return;
        setDirty();
        setPotentialLimitReach(false);
        var index = contextData.clientProviderSchedulePotentials.findIndex((r) => r.providerId === providerId);
        contextData.clientProviderSchedulePotentials.splice(index, 1);
        setContextData({ ...contextData, ['clientProviderSchedulePotentials']: contextData.clientProviderSchedulePotentials });
    }

    const [onlyLhisalosApproved, setOnlyLhisalosApproved] = useState(false);

    const handleClearFilters = () => {
        setDirty();
        setOnlyLhisalosApproved(false);
        setContextData({
            ...contextData,
            rateFrom: 0,
            rateTo: 0,
            neighborhood: "",
            specialties: [],
        })
    }

    const handleFilter = (row) => {
        if (!contextData.clientProviderSchedulePotentials)
            return;

        var existingProvider = contextData.clientProviderSchedulePotentials?.find((r) => r.providerId === row.providerId);
        if (existingProvider != undefined && existingProvider.providerId != undefined) {
            handleClear(row.providerId);
            return;
        }

        var addProvider = availableProvidersRows.find(r => r.providerId === row.providerId);
        if (addProvider == undefined)
            return;
        const newPotential = {
            id: undefined,
            providerId: addProvider.providerId,
            providerName: addProvider.providerName,
            rate: addProvider.rate,
            ratePeriod: addProvider.ratePeriod,
            specialties: addProvider.specialties != undefined ? addProvider.specialties.split(",").map((s) => s.trim()) : undefined,
            status: "pending call",
            comfortHealthStatus: addProvider.comfortHealthStatus,
            availabilities: addProvider.availabilities,
            schedules: addProvider.schedules,
            potentialSchedules: addProvider.potentialSchedules,
            isExternal: addProvider.isExternal,
        };

        //only allow one selected potential professional at a time (but allow multiple for yungerman)
        let potentials = [];
        if (scheduleType === "professional") {
            potentials = [newPotential]
        } else {
            if (contextData.clientProviderSchedulePotentials.length >= 3) {
                setPotentialLimitReach(true);
                // Set it false after 2 sec
                setTimeout(() => {
                    setPotentialLimitReach(false);
                }, 2000);
                potentials = [...contextData.clientProviderSchedulePotentials];
            } else {
                potentials = [...contextData.clientProviderSchedulePotentials, newPotential];
            }
        }
        const newClientSchedule = { ...contextData, ['clientProviderSchedulePotentials']: potentials }
        setContextData(newClientSchedule);
        updatePotentialsAvailabilityInfo(newClientSchedule);
    }

    const handleCloseState = (event, srcMethod, isCleanNow) => {
        if (srcMethod && srcMethod === "backdropClick")
            return;
        if (hasChanges() && !isCleanNow) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                return;
            }
            setClean();
        }
        setTabs(tabs.map((tab) => (
            { ...tab, isError: false }
        )))
        setTimesheetSelectedWeek(null)
        setTimesheetSelectedDate(null)
        setTimesheetDaysTimeFrame(constant.timesheetDaysTimeFrameConst)
        setClientSchedule({})
        setContextData(defaultContextData)
        setEndDate(false);
        setAvailableProvidersRows([]);
        setSchedule({ periodicitySchedule: 'weekly', periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS, everyXOfPeriods: 1, days: [], monthDays: [] });
        setCustomSchedule({});
        setCustomDays([]);
        setWeekset(defaultWeekset);
        setFieldErrors({});
        setPotentialSectionOpened(false);
        setScheduleRate({})
        setIsScheduleEdited(false)
        setIsRateEdited(false)
        setWarnings([])
        setPaymentType('');
        setFinanceStatusList({});
    }

    const handleChangePotentialStatus = (event, index, row) => {
        setDirty();
        let newTableData = contextData.clientProviderSchedulePotentials;
        let oldValue = newTableData[index]['status'];
        let newValue = event.target.value;
        newTableData[index]['status'] = newValue;

        const newClientSchedule = {
            ...contextData,
            clientProviderSchedulePotentials: newTableData,
            status: oldValue != newValue && newValue == 'confirmed' ? 'financial' : 'seeking',
            providerId: oldValue != newValue && newValue == 'confirmed' ? row.providerId : undefined,
            providerRegRate: oldValue != newValue && newValue == 'confirmed' ? row.rate : undefined,
            providerRegRatePeriod: oldValue != newValue && newValue == 'confirmed' ? row.ratePeriod : undefined,
            providerName: oldValue != newValue && newValue == 'confirmed' ? row.providerName : undefined,
            provider: oldValue != newValue && newValue == 'confirmed' ? contextData.provider : undefined,
        };
        const newRate = {
            ...scheduleRate,
            //auto fill the rate with the selected provider, and undo when diselecting 
            rate: oldValue !== newValue && newValue === 'confirmed' && !scheduleRate.rate ? row.rate
                : oldValue !== newValue && newValue !== 'confirmed' && scheduleRate.rate !== contextData.rate && contextData.providerRegRate === scheduleRate.rate ? contextData.rate : scheduleRate.rate,
            ratePeriod: oldValue != newValue && newValue == 'confirmed' && !scheduleRate.ratePeriod ? row.ratePeriod
                : oldValue !== newValue && newValue !== 'confirmed' && scheduleRate.ratePeriod !== contextData.ratePeriod && contextData.providerRegRate === scheduleRate.rate ? contextData.ratePeriod : scheduleRate.ratePeriod
        }

        setContextData(newClientSchedule);
        setScheduleRate(newRate)

        const validationResult = validateDateFields({
            date: newClientSchedule.schedule.date,
            endDate: newClientSchedule.schedule.endDate,
        }, !!newClientSchedule.providerId);

        setFieldErrors({
            ...fieldErrors,
            ...validationResult.fieldMessages,
        });
    }

    const getEmployeeMissingSpecialties = (employeeId, employeeSpecialties, clientSpecialties, useCache = true) => {
        if (useCache && missingSpecialtiesByEmployeeId[employeeId]) {
            return missingSpecialtiesByEmployeeId[employeeId];
        }

        clientSpecialties = clientSpecialties?.filter(s => s.trim() != "");

        if (!employeeSpecialties) {
            return clientSpecialties;
        }

        const employeeSpecialtiesArr = (Array.isArray(employeeSpecialties) ? (employeeSpecialties) : (employeeSpecialties.split(','))).filter(s => s.trim() != "");
        const missingSpecialties = clientSpecialties?.filter(clientSpecialty => !employeeSpecialtiesArr.includes(clientSpecialty));

        return missingSpecialties;
    }

    const getUpdatedMissingSpecialtiesForPotentials = (clientSpecialties) => {
        if (!contextData.clientProviderSchedulePotentials)
            return;

        const newMissingSpecialtiesByEmployeeId = {};

        contextData.clientProviderSchedulePotentials.forEach(potentialProvider => {
            newMissingSpecialtiesByEmployeeId[potentialProvider.providerId] =
                getEmployeeMissingSpecialties(potentialProvider.providerId, potentialProvider.specialties, clientSpecialties, false, true);
        });

        return newMissingSpecialtiesByEmployeeId;
    }

    const updateEmployeesMissingSpecialties = (clientSpecialties) => {
        let newMissingSpecialtiesByEmployeeId = {};

        availableProvidersRows.forEach(providerRow => {
            newMissingSpecialtiesByEmployeeId[providerRow.providerId] =
                getEmployeeMissingSpecialties(providerRow.providerId, providerRow.specialties, clientSpecialties, false, true);
        });

        newMissingSpecialtiesByEmployeeId = {
            ...newMissingSpecialtiesByEmployeeId,
            ...getUpdatedMissingSpecialtiesForPotentials(clientSpecialties)
        };

        if (contextData.provider) {
            newMissingSpecialtiesByEmployeeId[contextData.provider.id] =
                getEmployeeMissingSpecialties(contextData.provider.id, contextData.provider.specialties, clientSpecialties, false, true);
        }

        setMissingSpecialtiesByEmployeeId(newMissingSpecialtiesByEmployeeId);
    }

    useEffect(() => {
        if (tabs && tabs[0])
            setSelectedTab(tabs[0].value)
    }, [show])

    useEffect(() => {
        !neighborhoodsIsFetched && fetchNeighborhoods(); // fetch neighborhoods
        !specialtiesIsFetched && fetchSpecialties(); // fetch specialties
        !professionalTypesIsFetched && fetchProfessionalTypes(); //fetch professional types
    }, [])

    useEffect(() => {
        scheduleContext.loadEntity(clientScheduleId);
    }, [clientScheduleId])

    useEffect(() => {
        const entity = clientScheduleId ? scheduleContext.entity : defaultContextData;
        setContextData(entity);
        setPaymentType(entity.paymentType);
        setFinanceStatusList({
            isConfirmedFunding: entity.isConfirmedFunding,
            fundingNote: entity.fundingNote,
            isComfortConfirmedWithParent: entity.isComfortConfirmedWithParent,
            comfortParentNote: entity.comfortParentNote,
            isCashConfirmedWithParent: entity.isCashConfirmedWithParent,
            cashParentNote: entity.cashParentNote,
            isConfirnedWithEmployee: entity.isConfirnedWithEmployee,
            employeeNote: entity.employeeNote,
            isEnteredInDragon: entity.isEnteredInDragon,
            dragonNote: entity.dragonNote,
            isOnProfessionalGrantSheet: entity.isOnProfessionalGrantSheet,
            professionalGrantSheetNote: entity.professionalGrantSheetNote,
        })
        if (!!clientScheduleId) {
            let initialTimesheetDate = new Date()
            if (!!entity.schedule.endDate && new Date(entity.schedule.endDate) < initialTimesheetDate) {
                initialTimesheetDate = new Date(entity.schedule.endDate)
            }
            if (new Date(entity.schedule.date) > initialTimesheetDate) {
                initialTimesheetDate = null
            }
            setTimesheetSelectedDate(initialTimesheetDate)
        }
    }, [scheduleContext.entity, show, clientScheduleId]);

    // 
    useEffect(() => {
        if (!clientScheduleId) {
            return;
        }

        if (schedule.periodicitySchedule === "custom")
            setCustomSchedule(schedule);

        if (["yungerman", "professional"].includes(scheduleType))
            updatePotentialsAvailabilityInfo(contextData);

        if (scheduleType === "yungerman") {
            setMissingSpecialtiesByEmployeeId({
                ...missingSpecialtiesByEmployeeId,
                ...getUpdatedMissingSpecialtiesForPotentials(contextData.specialties)
            });
        }

        const periodicityInfo = getPeriodicityInfoFromClientSchedule();

        if (!periodicityInfo) {
            return;
        }

        if (periodicityInfo.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS) {
            setDays(getDaysOfWeekFromClientSchedule(contextData), false, { ...schedule, ...periodicityInfo }, true);
        } else if (periodicityInfo.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.MONTHS) {
            setSchedule({ ...schedule, ...periodicityInfo });
        }

    }, [contextData, clientScheduleId]);

    //load rate every time the popup opens
    useEffect(() => {
        if (contextData.id && clientScheduleId > 0) {
            setScheduleRate({
                rate: contextData.rate,
                ratePeriod: contextData.ratePeriod
            })
        }
    }, [contextData?.id, clientScheduleId])
    //set providers conflicts from potential conflicts
    useEffect(() => {
        if (contextData?.providerId > 0 && clientScheduleId > 0) {
            setProvidersAvailability(potentialsAvailabilityInfoByProviderId[contextData.providerId])
        }
    }, [contextData?.providerId, potentialsAvailabilityInfoByProviderId, clientScheduleId])

    const handleSaveTimesheetEntries = (payload) => {
        saveTimesheetEntries(payload, clientScheduleId).then((data) => {

        })
    }

    const getDaysOfWeekFromClientSchedule = (contextData) => {
        return contextData.schedule != undefined ?
            getDaysArrayFromDaysString(contextData.schedule.days) : [];
    }

    const loadResults = function () {
        const professionalTypeSelectionValidationResult = validateProfessionalTypeSelection(scheduleType, contextData.professionalType);
        if (!professionalTypeSelectionValidationResult.isValid) {
            setTabs(tabs.map((tab) => (
                tab.value === 'schedule' ?
                    { ...tab, isError: true } :
                    tab
            )));
            setSelectedTab(tabs[0].value)
        }
        setFieldErrors({
            ...fieldErrors,
            ...professionalTypeSelectionValidationResult.fieldMessages,
        });

        if (!professionalTypeSelectionValidationResult.isValid) {
            return;
        }

        var filters = {
            rateFrom: contextData.rateFrom,
            rateTo: contextData.rateTo,
            specialties: contextData.specialties ? contextData.specialties.join(",") : "",
            //startTime: contextData.schedule.startTime ? new Date(new Date(contextData.schedule.startTime).setSeconds(0)) : contextData.schedule.startTime ?? undefined,
            //endTime: contextData.schedule.endTime ? new Date(new Date(contextData.schedule.endTime).setSeconds(0)) : contextData.schedule.endTime ?? undefined,
            daysOfWeek: getDaysOfWeekFromClientSchedule(contextData),
            onlyLhisalosApproved: true,//contextData.onlyLhisalosApproved,
            neighborhood: contextData.neighborhood,
            professionalType: contextData.professionalType,
            scheduleType: scheduleType
        };
        //global.log("filters", filters);

        //if (scheduleType === "yungerman") //todo - need to remove once it's unified, which probably already is, and remove the 'else' as well
        //{
        setIsResultsLoading(true);
        global.fetchWrapper.post(constant.CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_FILTER, filters).then((response) => {
            setIsResultsLoading(false);
            //global.log("filters yungerman response", response);
            const newMissingSpecialtiesByEmployeeId = {};
            setAvailableProvidersRows(
                response.availableProviders.map((p) => {
                    const { availabilities, schedules, potentialSchedules } =
                        ["yungerman"].includes(scheduleType) ?
                            parseAvailableYungermanSchedules(p.availabilities, p.schedules, p.potentialSchedules) :
                            { availabilities: p.availabilities, schedules: p.schedules, potentialSchedules: p.potentialSchedules };

                    const calculatedAvailability = calculateProviderAvailability(contextData, availabilities, schedules, potentialSchedules);

                    if (["yungerman"].includes(scheduleType))
                        newMissingSpecialtiesByEmployeeId[p.employeeId] = getEmployeeMissingSpecialties(p.employeeId, p.specialties, contextData.specialties, false);

                    return {
                        id: p.id,
                        providerId: p.employeeId,
                        providerName: p.providerName,
                        rate: p.rate,
                        ratePeriod: p.ratePeriod,
                        isExternal: p.isExternal, //todo // need to add field to employee
                        professionalType: p.type, //todo // no need when using employee object specialties, employee object doesn't even have that field
                        specialties: p.specialties,
                        neighborhood: p.neighborhood,
                        comfortHealthStatus: p.comfortHealthStatus,
                        isLhisalosContractUploaded: p.isLhisalosContractUploaded,
                        availabilities: availabilities,
                        schedules: schedules,
                        potentialSchedules: potentialSchedules,
                        availabilityForClientSchedule: calculatedAvailability.availabilityForClientSchedule,
                        conflictingSchedules: calculatedAvailability.conflictingSchedules,
                        conflictingPotentialSchedules: calculatedAvailability.conflictingPotentialSchedules,
                        durationBasedFilterConflicts: calculatedAvailability.durationBasedFilterConflicts,
                    }
                })
            );
            if (["yungerman"].includes(scheduleType)) {
                setMissingSpecialtiesByEmployeeId({
                    ...missingSpecialtiesByEmployeeId,
                    ...newMissingSpecialtiesByEmployeeId
                });
            }
        });
    }

    const updatePotentialsAvailabilityInfo = (contextData) => {
        if (!contextData.clientProviderSchedulePotentials)
            return;

        const newPotentialsAvailabilityInfoByProviderId = {
            ...potentialsAvailabilityInfoByProviderId,
        };

        const parseSchedules = (availabilities, schedules, potentialSchedules) => {
            const parsedSchedules = parseAvailableYungermanSchedules(availabilities, schedules, potentialSchedules);

            return ["yungerman"].includes(scheduleType) ? parsedSchedules : {
                availabilities,
                schedules,
                potentialSchedules,
            };
        }

        contextData.clientProviderSchedulePotentials.forEach(cpsp => {
            const parsedSchedules = parseSchedules(cpsp.availabilities, cpsp.schedules, cpsp.potentialSchedules);
            const calculatedAvailability = calculateProviderAvailability(contextData, parsedSchedules.availabilities, parsedSchedules.schedules, parsedSchedules.potentialSchedules);

            newPotentialsAvailabilityInfoByProviderId[cpsp.providerId] = {
                availabilityForClientSchedule: calculatedAvailability.availabilityForClientSchedule,
                conflictingSchedules: calculatedAvailability.conflictingSchedules,
                conflictingPotentialSchedules: calculatedAvailability.conflictingPotentialSchedules,
                durationBasedFilterConflicts: calculatedAvailability.durationBasedFilterConflicts,
            }
        });

        setPotentialsAvailabilityInfoByProviderId(newPotentialsAvailabilityInfoByProviderId);
    }

    const updateProviderAvailabilitiesInfo = (contextData) => {
        if (["yungerman", "professional"].includes(scheduleType))
            updatePotentialsAvailabilityInfo(contextData);
        setAvailableProvidersRows(availableProvidersRows.map(availableProvidersRow => {
            const calculatedAvailability = calculateProviderAvailability(
                contextData,
                availableProvidersRow.availabilities,
                availableProvidersRow.schedules,
                availableProvidersRow.potentialSchedules
            );

            return {
                ...availableProvidersRow,
                availabilityForClientSchedule: calculatedAvailability.availabilityForClientSchedule,
                conflictingSchedules: calculatedAvailability.conflictingSchedules,
                conflictingPotentialSchedules: calculatedAvailability.conflictingPotentialSchedules,
                durationBasedFilterConflicts: calculatedAvailability.durationBasedFilterConflicts,
            }
        }));
    }

    const calculateProviderAvailability = (contextData, providerAvailabilities, providerSchedules, providerPotentialSchedules) => {
        const availCalculator = new ScheduleAvailabilityCalculator();

        const parseSchedule = (schedule) => {
            let startTime, endTime;
            if (!schedule?.startTime) {
                startTime = new Date(null);
                endTime = new Date(null);
            }
            else {
                startTime = schedule?.startTime ? global.dateTime.parseTimeToDate(global.dateTime.getTimeFromDateString(schedule.startTime)) : null;
                endTime = schedule?.endTime ? global.dateTime.parseTimeToDate(global.dateTime.getTimeFromDateString(schedule.endTime)) : null;
                if (!endTime)
                    endTime = new Date(new Date(startTime).setHours(startTime.getHours() + 12));
            }

            return {
                startTime,
                endTime,
                startDate: schedule?.date ? global.dateTime.getDateObject(schedule.date) : new Date(),
                endDate: schedule?.endDate ? global.dateTime.getDateObject(schedule.endDate) : null,
                days: schedule?.days ? schedule.days.split(',') : [],
                periodicity: schedule?.periodicity ? schedule.periodicity : SCHEDULE_PERIODICITY_CONSTANTS.WEEKS,
                everyXOfPeriods: schedule?.everyXOfPeriods,
            };
        }

        const parseClientSchedule = (schedule) => {
            const parsedSchedule = parseSchedule(schedule);

            return {
                ...parsedSchedule,
                startDate: schedule?.date ? parsedSchedule.startDate : new Date(),
            }
        }

        const pSchedules = providerSchedules.filter(sch => sch.id != contextData.id).map(schedule => parseSchedule(schedule.schedule));
        const pAvailabilities = providerAvailabilities.map(availability => parseSchedule(availability));
        const pPotenSchedules = providerPotentialSchedules.filter(sch => sch.id != contextData.id).map(schedule => parseSchedule(schedule.schedule));
        const cSchedule = parseClientSchedule(contextData.schedule);

        const timeFilter /*ts: : ScheduleTimeFilterModel*/ =
            (!contextData.timeFrom_Filter || !contextData.timeTo_Filter || !contextData.duration_Filter) ? undefined : {
                startTime: contextData.timeFrom_Filter,
                endTime: contextData.timeTo_Filter,
                durationMins: getMinutesFromMidnight(contextData.duration_Filter),
                weekDays: cSchedule.days.map((d) => parseInt(d, 10)),
            }
        const conflictingSchedules = availCalculator.getScheduleConflicts(cSchedule, pSchedules);
        const conflictingPotentialSchedules = availCalculator.getScheduleConflicts(cSchedule, pPotenSchedules);
        const availabilityForClientSchedule = availCalculator.getAvailabilityForClientSchedule(cSchedule, pAvailabilities);
        const durationBasedFilterConflicts = timeFilter ? availCalculator.getConflictingWindows(timeFilter, pSchedules, pAvailabilities, pPotenSchedules) : [];

        return {
            availabilityForClientSchedule,
            conflictingSchedules,
            conflictingPotentialSchedules,
            durationBasedFilterConflicts,
        };
    }

    const formatTime24 = (dateTime) => {
        return global.dateTime.formatTime24(new Date(Date.parse(dateTime)));
    }

    const applyTime = (dateTime, timeToApply) => {
        return global.dateTime.applyTime(dateTime, timeToApply);
    }

    const getYearDateFromJsDate = (dateTime) => {
        var d = global.dateTime.getDateTime(dateTime);
        return d;
    }

    const clearDate = () => {

    }

    const changeDate = (field, newValue) => {
        setDirty();
        const startDate = field === 'date' ? newValue : contextData.schedule.date;
        const endDate = field === 'endDate' ? newValue : contextData.schedule.endDate;
        let date = new Date();
        if (new Date(endDate) > date) {
            date = new Date();
        } else {
            date = new Date(endDate)
        }
        if (new Date(startDate) > date) {
            date = null
        }
        setTimesheetSelectedDate(date ?? new Date())
        setTimesheetSelectedWeek(null)

        const newClientSchedule = {
            ...contextData,
            schedule: {
                ...contextData.schedule,
                [field]: newValue
            }
        };

        setContextData(newClientSchedule);
        updateProviderAvailabilitiesInfo(newClientSchedule);

        const validationResult = validateDateFields({
            date: newClientSchedule.schedule.date ? new Date(newClientSchedule.schedule.date) : null,
            endDate: newClientSchedule.schedule.endDate ? new Date(newClientSchedule.schedule.endDate) : null,
            [field]: newValue,
        }, !!contextData.providerId);

        if (validationResult.isValid) {
            setTabs(tabs.map((tab) => (
                tab.value === 'schedule' ?
                    { ...tab, isError: false } :
                    tab
            )))
        }

        setFieldErrors({
            ...fieldErrors,
            ...validationResult.fieldMessages,
        });
    }

    const fieldsValidation = {
        daysSelection: ["yungerman", "availability", scheduleRate?.ratePeriod === "MONTHS" || !contextData.providerId ? "" : 'professional'],
        startTime: ["yungerman", "availability", scheduleRate?.ratePeriod === "MONTHS" || !contextData.providerId ? "" : 'professional'],
        endTime: ["yungerman", "availability", scheduleRate?.ratePeriod === "MONTHS" || !contextData.providerId ? "" : 'professional'],
        startDate: ["yungerman"],
        endDate: ["yungerman"],
    }

    const validateDateFields = (dateFields, providerConfirmed) => {
        const dateRangeValidator = new DateRangeValidator(dateFields.date, dateFields.endDate, {
            requireStartDate: providerConfirmed,
        });

        dateRangeValidator.validate();
        return dateRangeValidator;
    }

    const validateTimeFields = (timeFields, requireStartTime, requireEndTime) => {
        const timeRangeValidator = new TimeRangeValidator(timeFields.startTime, timeFields.endTime, {
            requireStartTime: requireStartTime,
            requireEndTime: requireEndTime
        });

        timeRangeValidator.validate();
        return timeRangeValidator;
    }

    const validateForm = (contextData) => {
        const validationResults = [
            validateDateFields({
                date: contextData.schedule.date,
                endDate: contextData.schedule.endDate,
            }, !!contextData.providerId),
            validateTimeFields({
                startTime: contextData.schedule.startTime,
                endTime: contextData.schedule.endTime,
            }, fieldsValidation.startTime.includes(scheduleType), fieldsValidation.endTime.includes(scheduleType)),
            validateDaySelections(fieldsValidation.daysSelection.includes(scheduleType), contextData.schedule.periodicity, contextData.schedule.days.split(',')),
            validateProfessionalTypeSelection(scheduleType, contextData.professionalType),
            validateRate(scheduleRate.rate, !!contextData.providerId),
            validateRatePeriod(scheduleRate.ratePeriod, !!contextData.providerId),
        ];

        let allValid = true;
        let newFieldErrors = {};

        validationResults.forEach(result => {
            if (result.isValid) return;
            setSelectedTab(tabs[0].value)
            setTabs(tabs.map((tab) => (
                tab.value === 'schedule' ?
                    { ...tab, isError: true } :
                    tab
            )))
            allValid = false;

            newFieldErrors = {
                ...newFieldErrors,
                ...result.fieldMessages,
            };
        });
        if (fieldErrors.dateApproved)
            allValid = false;

        setFieldErrors({
            ...fieldErrors, ...newFieldErrors
        });

        return allValid;
    }

    const handleSaveAndClose = (schedule, rate, overrideWarnings) => {
        const cs = getUpdatedClientScheduleFromSchedule(schedule);
        setPotentialLimitReach(false)
        const dataToSave = {
            ...cs,
            paymentType: paymentType,
            isConfirmedFunding: financeStatusList.isConfirmedFunding,
            fundingNote: financeStatusList.fundingNote,
            isComfortConfirmedWithParent: financeStatusList.isComfortConfirmedWithParent,
            comfortParentNote: financeStatusList.comfortParentNote,
            isCashConfirmedWithParent: financeStatusList.isCashConfirmedWithParent,
            cashParentNote: financeStatusList.cashParentNote,
            isConfirnedWithEmployee: financeStatusList.isConfirnedWithEmployee,
            employeeNote: financeStatusList.employeeNote,
            isEnteredInDragon: financeStatusList.isEnteredInDragon,
            dragonNote: financeStatusList.dragonNote,
            isOnProfessionalGrantSheet: financeStatusList.isOnProfessionalGrantSheet,
            professionalGrantSheetNote: financeStatusList.professionalGrantSheetNote,
            rate: rate.rate,
            ratePeriod: rate.ratePeriod,
            rateFrom: cs.rateFrom,
            rateTo: cs.rateTo,
            timeFrom_Filter: global.dateTime.getDateTime(cs.timeFrom_Filter),
            timeTo_Filter: global.dateTime.getDateTime(cs.timeTo_Filter),
            duration_Filter: global.dateTime.getDateTime(cs.duration_Filter),
            status: contextData.status
        };
        let newWarnings = [];
        if (validateForm(dataToSave)) {
            if (providersAvailability && isScheduleEdited && !overrideWarnings && (providersAvailability.availabilityForClientSchedule.availabilityStatus !== "Full Availability" || providersAvailability.conflictingSchedules?.length > 0)) {
                newWarnings.push({
                    title: 'Provider Not Available',
                    description: 'The selected provider is not available throughout the whole time.'
                })
            }
            if (dataToSave.status === 'active' && (isScheduleEdited || isRateEdited) && !overrideWarnings) {
                newWarnings.push({
                    title: 'Rate Change Warning',
                    description: 'Your changes will result financial updates, the date of this change is currently not being saved, additionally, this schedule will change status to \'pending finance\', consider creating a new schedule instead.'
                })
            }
            setWarnings(newWarnings)
            if (newWarnings.length > 0) {
                setShowWarningModal(true)
                return;
            }

            for (const property in timesheetDaysTimeFrame) {
                if (!!timesheetDaysTimeFrame[property].error) {
                    setSelectedTab('timesheet')
                    setTabs(tabs.map((tab) => (
                        tab.value === 'timesheet' ?
                            { ...tab, isError: true } :
                            tab
                    )))
                    return null
                }
            }
            const payload = []
            for (var d = new Date(timesheetSelectedWeek?.start); d <= new Date(timesheetSelectedWeek?.end); d.setDate(d.getDate() + 1)) {
                const startTimeTemp = timesheetDaysTimeFrame[constant.weekDays[d.getDay()]]?.startTime;
                const endTimeTemp = timesheetDaysTimeFrame[constant.weekDays[d.getDay()]]?.endTime
                if (!!startTimeTemp || !!endTimeTemp) {
                    payload.push({
                        clientScheduleId: clientScheduleId ?? 0,
                        date: new Date(d),
                        clockIn: !startTimeTemp ? null : global.dateTime.formatTime24(new Date(startTimeTemp)),
                        clockOut: !endTimeTemp ? null : global.dateTime.formatTime24(new Date(endTimeTemp))

                    })
                } else {
                    payload.push({
                        clientScheduleId: clientScheduleId ?? 0,
                        date: new Date(d),
                        clockIn: null,
                        clockOut: null

                    })
                }
            }
            handleSaveTimesheetEntries(payload)
            handleEdit(dataToSave);
            setClean();
            handleCloseState(undefined, undefined, true);
        }
    }

    const handleCancelClose = () => {
        setPotentialLimitReach(false)
        handleCloseState();
        handleClose()
    }

    const showPotentialsSection = (potentialSectionOpened || isSeekingProvider) && modalFields.potentialSection.includes(scheduleType);
    const showSearchProvider = scheduleType !== "availability";

    const gridSettings = {
        left: {
            md: showPotentialsSection ? 5 : 12,
            xs: 12,
        },
        right: {
            md: showPotentialsSection ? 7 : 0,
            xs: showPotentialsSection ? 12 : 0,
        },
    }

    const providerDetailsRoute = FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE;//scheduleType === 'yungerman' ? FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_DETAILS_ROUTE : FRONTEND_ROUTE_CONSTANTS.PROFESSIONAL_DETAILS_ROUTE;
    const confirmedProviderExists = contextData != null && !!contextData.providerId;

    const clientProviderNeighborhoodMismatch = (clientNeighborhood, providerNeighborhood) => {
        return providerNeighborhood != null && clientNeighborhood != null && providerNeighborhood.trim().toLowerCase() != clientNeighborhood.trim().toLowerCase();
    }

    const unableToCalculateNeighborhoodAvailability = (clientNeighborhood, providerNeighborhood) => {
        return clientNeighborhood != null && providerNeighborhood == null;
    }

    function validateHhMm(date) {
        const isValidTime = (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
        return isValidTime;
    }

    const isPermittedToAddPotential = (row, permissions) => {
        const isApprovedComfort = row.comfortHealthStatus === "Approved";
        const isAvailable = row.availabilityForClientSchedule.availabilityStatus === "Full Availability";
        const isNoConflicts = row.conflictingSchedules.length <= 0;
        const isLhisalosContractUploaded = row.isLhisalosContractUploaded;
        return (isApprovedComfort || permissions.canAddPotentialsNotApprovedComfort !== false) &&
            (isAvailable || permissions.canAddPotentialsWithNoAvailability !== false) &&
            (isNoConflicts || permissions.canAddPotentialsWithConflicts !== false) &&
            (isLhisalosContractUploaded || permissions.canAddPotentialsComfortWithNoContractUploaded !== false || row.comfortHealthStatus == "Not Enrolled");
    }

    const isPermittedToConfirmEmployee = (row, permissions) => {
        const isApprovedComfort = row.comfortHealthStatus === "Approved";
        const isAvailable = !potentialsAvailabilityInfoByProviderId[row.providerId] || potentialsAvailabilityInfoByProviderId[row.providerId]?.availabilityForClientSchedule?.availabilityStatus === "Full Availability"
        const isNoConflicts = !potentialsAvailabilityInfoByProviderId[row.providerId] || potentialsAvailabilityInfoByProviderId[row.providerId]?.conflictingSchedules.length <= 0;
        return permissions.canSetEmployee && (isApprovedComfort || permissions.canSetEmployeeNotApprovedComfort !== false) && (isAvailable || permissions.canSetEmployeeWithNoAvailability !== false) && (isNoConflicts || permissions.canSetEmployeeWithConflicts !== false)
    }

    const isConfirmedSchedule = () => contextData !== undefined && contextData.clientProviderSchedulePotentials &&
        contextData.clientProviderSchedulePotentials.some((p) => p.status === 'confirmed');
    const isAllStepsCompleted = () => {
        const isComfortStepsCompleted = financeStatusList.isConfirmedFunding && financeStatusList.isComfortConfirmedWithParent && financeStatusList.isConfirnedWithEmployee && financeStatusList.isEnteredInDragon;
        const isCashStepCompleted = financeStatusList.isCashConfirmedWithParent;
        const isProfessionalGrantStepsCompleted = financeStatusList.isOnProfessionalGrantSheet;
        return paymentType === 'Comfort' ? isComfortStepsCompleted :
            paymentType === 'Cash' ? isCashStepCompleted :
                paymentType === 'ProfessionalGrant' ? isProfessionalGrantStepsCompleted : false
    }
    const canConfirmYungermanSchedule = () => paymentType/*if empty even with permissions isn't allowed */ && (isAllStepsCompleted() || actionPermissions.canActivateScheduleWithMissingSteps)

    const PotentialListItem = ({ row, index }) => {
        const [checked, setChecked] = useState(row.status === 'confirmed')
        const ref = useRef();
        const refName = useRef();
        const isOverflow = useActiveToolTip(ref);
        const isOverFlowName = useActiveToolTip(refName)
        useEffect(() => {
            setChecked(row.status === 'confirmed')
        }, [row.status])
        return (
            <div className={classes.filterBox}>
                <Grid
                    id={modalId + "ptl-" + row.providerId}
                    container spacing={0} sx={{ alignItems: 'center' }}>
                    <Grid item xs={1}>
                        {potentialsAvailabilityInfoByProviderId[row.providerId] != null &&
                            <ProviderAvailabilityStatusTooltip
                                isExternalProvider={false}
                                availabilityForClientSchedule={potentialsAvailabilityInfoByProviderId[row.providerId].availabilityForClientSchedule}
                                conflictingSchedules={potentialsAvailabilityInfoByProviderId[row.providerId].conflictingSchedules}
                                conflictingPotentialSchedules={potentialsAvailabilityInfoByProviderId[row.providerId].conflictingPotentialSchedules}
                                durationBasedFilterConflicts={potentialsAvailabilityInfoByProviderId[row.providerId].durationBasedFilterConflicts}
                                employeeRateIsGreaterThanRangeMax={((contextData.rateTo + contextData.rateFrom) > 0) && row.rate > contextData.rateTo}
                                employeeRateIsLessThanRangeMin={((contextData.rateTo + contextData.rateFrom) > 0) && row.rate < contextData.rateFrom}
                                missingSpecialties={scheduleType === "yungerman" ? getEmployeeMissingSpecialties(row.providerId, row.specialties, contextData.specialties, true, true) : []}
                                neighborhoodMismatch={clientProviderNeighborhoodMismatch(contextData.neighborhood, row.neighborhood)}
                                unableToCalculateNeighborhoodAvailability={unableToCalculateNeighborhoodAvailability(contextData.neighborhood, row.neighborhood)}
                            />
                        }
                    </Grid>
                    <Grid item xs={scheduleType === 'professional' ? 3 : 6}>
                        <Tooltip title={isOverFlowName ? row.providerName : ''} placement="top">
                            <p
                                ref={refName}
                                id={modalId + "ptl-" + row.providerId + "-nme"}
                                className={classes.providerName}
                                style={{ paddingLeft: 10 }}
                                onClick={(event) => openProviderDetailsPageInNewTab(event, providerDetailsRoute, row.providerId)}
                            >
                                {row.providerName}
                            </p>
                        </Tooltip>
                        <Tooltip title={isOverflow ? scheduleType === "yungerman" ? row.specialties : row.professionalType : ''} placement="top">
                            <p ref={ref}
                                id={modalId + "ptl-" + row.providerId + "-rte-n-spc"}
                                className={classes.priceDollar}
                            >{`$${row.rate}${periodicities[row.ratePeriod] ? `/${periodicities[row.ratePeriod]}` : ""}`} | {scheduleType === "yungerman" ? row.specialties : row.professionalType}
                            </p>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id={modalId + "ptl-" + row.providerId + "-sts"}
                            disabled={confirmedProviderExists && row.providerId != contextData.providerId}
                            className={`${classes.selectButtonDrop}`}
                            select
                            label=""
                            variant="standard"
                            size="small"
                            value={row.status}
                            onChange={(event) => {
                                handleChangePotentialStatus(event, index, row);
                            }}
                        >
                            {scheduleType === "yungerman" ? [
                                <MenuItem key="pending call" value="pending call">Pending Call</MenuItem>,
                                <MenuItem key="negotiation" value="negotiation">Negotiation</MenuItem>,
                                <MenuItem key="awaiting answer" value="awaiting answer">Awaiting Answer</MenuItem>,
                                <MenuItem key="needs confirmation" value="needs confirmation">Needs Confirmation</MenuItem>,
                                <MenuItem key="probably not" value="probably not">Probably Not</MenuItem>,
                                <MenuItem key="confirmed" value="confirmed" disabled={!isPermittedToConfirmEmployee(row, actionPermissions)}>Confirmed</MenuItem>,
                            ] : [
                                <MenuItem key="pending call" value="pending call">Pending Call</MenuItem>,
                                <MenuItem key="confirmed" value="confirmed" disabled={!isPermittedToConfirmEmployee(row, actionPermissions)}>Confirmed</MenuItem>,
                            ]}

                        </TextField>
                    </Grid>
                    <Grid item xs={scheduleType === 'professional' ? 3 : 0}>
                        {scheduleType === 'professional' &&
                            <FormControlLabel
                                control={<Checkbox
                                    checked={checked}
                                    disabled={!isPermittedToConfirmEmployee(row, actionPermissions)}
                                    onChange={(event) => {
                                        let value = event.target.checked ? 'confirmed' : "pending call"
                                        handleChangePotentialStatus({ target: { value: value } }, index, row);
                                    }}
                                />}
                                label="Confirmed by Menahal" />
                        }


                    </Grid>
                    <Grid item xs={1}>
                        {!confirmedProviderExists && (
                            <span
                                id={modalId + "ptl-" + row.providerId + "-del"}
                                className={classes.clearIcon}
                                onClick={() => handleClear(row.providerId)}
                            >
                                <ClearIcon style={{ fontSize: 15 }} />
                            </span>
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }

    const handleFinancialStatus = (value, field) => {
        setFinanceStatusList({ ...financeStatusList, [field]: value })
    }

    //https://smartdevpreneur.com/how-to-add-a-clear-button-to-the-mui-select-component/
    const FeaturedSelect = (props) => {
        const { value, setValue } = props;
        const handleClearClick = () => {
            setValue(undefined);
        };

        return (
            <>
                <Select
                    endAdornment={<IconButton sx={{ display: value ? "" : "none" }} onClick={handleClearClick}><ClearIcon /></IconButton>}
                    sx={{ "& .MuiSelect-iconOutlined": { display: value ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
                    onChange={(event) => setValue(event.target.value)}
                    {...props}
                >
                    {props.children}
                </Select>
            </>
        )
    }

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const handleMoveStep = (direction) => {
        let tab = tabs.findIndex((item) => item.value = selectedTab);
        switch (direction) {
            case 'forward':
                setSelectedTab(tabs[tab + 1]?.value)
                break;
            case 'back':
                setSelectedTab(tabs[tab - 1]?.value)
                break;
            default: return null
        }
    }
    const dailogWidth = selectedTab === "searchProvider" && scheduleType !== 'availability' ? "sm" : "sm"
    return (
        <>
            <Dialog onClose={handleCloseState} aria-labelledby="simple-dialog-title" open={show} maxWidth={dailogWidth} fullWidth={true} classes={{ paper: classes.dialogPaper }}>
                <DialogContent>
                    {
                        (scheduleContext.loading) &&
                        <div>
                            <div style={{ background: `rgb(255,255,255,0.6)`, position: 'absolute', width: '100%', height: '100%', zIndex: '2' }}>
                                <div style={{ margin: '0', position: 'absolute', top: '40%', left: '42%', transform: `translate(50%, 50%)` }}>
                                    <ReactLoading type={"bars"} color={"#1976d2"} />
                                </div>
                            </div>
                        </div>
                    }
                    <ModelStaticInfo
                        scheduleType={scheduleType}
                        client={client}
                        clientScheduleId={clientScheduleId}
                        contextData={contextData}
                        clientFields={clientFields}
                        providersAvailability={providersAvailability}
                        showSearchProvider={showSearchProvider}
                        actionPermissions={actionPermissions}
                        setPotentialSectionOpened={setPotentialSectionOpened}
                    />

                    <MUITabs
                        tabs={tabs.filter(tab => tab.value != "timesheet" || ((timesheetTabShowShortcut).keyExecutions.length > 0 && !!clientScheduleId))}
                        value={selectedTab}
                        variant="standard"
                        aria-label="yungerman-modal"
                        onChange={handleChangeTab}
                        centered={true}
                    />
                    <BoxContainer height="500px" borderPx="0">
                        {tabs[0] && <TabPanel value={selectedTab} index={tabs[0].value}>
                            <ScheduleForm
                                actionPermissions={actionPermissions}
                                modalId={modalId}
                                showSearchProvider={showSearchProvider}
                                scheduleType={scheduleType}
                                setDirty={setDirty}
                                contextData={contextData}
                                setContextData={setContextData}
                                calendarType={calendarType}
                                setCalendarType={setCalendarType}
                                changeTimeFrom={changeTimeFrom}
                                setScheduleRate={setScheduleRate}
                                scheduleRate={scheduleRate}
                                setIsRateEdited={setIsRateEdited}
                                setFieldErrors={setFieldErrors}
                                fieldErrors={fieldErrors}
                                changeDate={changeDate}
                                setEndDate={setEndDate}
                                endDate={endDate}
                                professionalTypesValues={professionalTypesValues}
                                handleProfessionalTypesChange={handleProfessionalTypesChange}
                                periodicities={periodicities}
                                schedule={schedule}
                                periodicityScheduleChanged={periodicityScheduleChanged}
                                setSchedule={setSchedule}
                                customSchedule={customSchedule}
                                setCustomSchedule={setCustomSchedule}
                                updateClientSchedule={updateClientSchedule}
                                updateProviderAvailabilitiesInfo={updateProviderAvailabilitiesInfo}
                                setIsScheduleEdited={setIsScheduleEdited}
                                getUpdatedClientScheduleFromSchedule={getUpdatedClientScheduleFromSchedule}
                                weekset={weekset}
                                SCHEDULE_PERIODICITY_CONSTANTS={SCHEDULE_PERIODICITY_CONSTANTS}
                                handleDayToggle={handleDayToggle}
                                setDays={setDays}
                                setMonthDays={setMonthDays}
                            />
                        </TabPanel>}
                        {tabs[1] && <TabPanel value={selectedTab} index={tabs[1].value} disabled={scheduleType === 'availability'}>
                            <FinancialInfo
                                modalId={modalId}
                                actionPermissions={actionPermissions}
                                paymentType={paymentType}
                                setPaymentType={setPaymentType}
                                financeStatusList={financeStatusList}
                                handleFinancialStatus={handleFinancialStatus}
                                modalFields={modalFields}
                                scheduleType={scheduleType}
                                setDirty={setDirty}
                                contextData={contextData}
                                setContextData={setContextData}
                                isConfirmedSchedule={isConfirmedSchedule}
                                scheduleTypeTitles={scheduleTypeTitles}
                                setFieldErrors={setFieldErrors}
                                fieldErrors={fieldErrors}
                                canConfirmYungermanSchedule={canConfirmYungermanSchedule}

                            />
                        </TabPanel>}
                        {tabs[2] && <TabPanel value={selectedTab} index={tabs[2].value} disabled={scheduleType === 'availability'}>
                            <div>
                                <p style={{ fontSize: "12px", padding: 0, margin: 0 }}>Potential {scheduleType === "yungerman" ? "Yungeleit" : "Professionals"}:</p>
                                <ProtectedComponent
                                    className={classes.boxModel}
                                    allow
                                    canEdit={actionPermissions?.canEditPotential !== false}
                                >
                                    <div>
                                        {contextData != undefined && contextData.clientProviderSchedulePotentials !== undefined ?
                                            contextData.clientProviderSchedulePotentials.map((row, index) =>
                                                <PotentialListItem
                                                    row={row}
                                                    index={index}
                                                />
                                            ) : null}
                                    </div>
                                </ProtectedComponent>
                                <AvailablityFilter
                                    scheduleType={scheduleType}
                                    confirmedProviderExists={confirmedProviderExists}
                                    contextData={contextData}
                                    modalId={modalId}
                                    isResultsLoading={isResultsLoading}
                                    loadResults={loadResults}
                                    availableProvidersRows={availableProvidersRows}
                                    actionPermissions={actionPermissions}
                                    handleClearFilters={handleClearFilters}
                                    handleFilter={handleFilter}
                                    handleRateChange={handleRateChange}
                                    handleTimeChange={handleTimeChange}
                                    handleDurationChange={handleDurationChange}
                                    handleNeighborhoodChange={handleNeighborhoodChange}
                                    handleSpecialtiesChange={handleSpecialtiesChange}
                                    getEmployeeMissingSpecialties={getEmployeeMissingSpecialties}
                                    clientProviderNeighborhoodMismatch={clientProviderNeighborhoodMismatch}
                                    unableToCalculateNeighborhoodAvailability={unableToCalculateNeighborhoodAvailability}
                                    openProviderDetailsPageInNewTab={openProviderDetailsPageInNewTab}
                                    isPermittedToAddPotential={isPermittedToAddPotential}
                                    potentialLimitReach={potentialLimitReach}
                                />
                            </div>
                        </TabPanel>}
                        {timesheetTabShowShortcut.keyExecutions.length > 0 && !!clientScheduleId && tabs[3] && <TabPanel value={selectedTab} index={tabs[3].value} disabled={scheduleType === 'availability'}>
                            <div>
                                <Timesheet
                                    fieldErrors={fieldErrors}
                                    selectedDate={timesheetSelectedDate}
                                    weeksRange={timesheetWeeksRange}
                                    selectedWeek={timesheetSelectedWeek}
                                    daysTimeFrame={timesheetDaysTimeFrame}
                                    activeDays={customDays}
                                    clientScheduleId={clientScheduleId}
                                    minDate={contextData.schedule?.date}
                                    maxDate={contextData.schedule?.endDate ?? new Date()}
                                    setDaysTimeFrame={(data) => {
                                        setTabs(tabs.map((tab) => (
                                            tab.value === 'timesheet' ?
                                                { ...tab, isError: false } :
                                                tab
                                        )))
                                        setTimesheetDaysTimeFrame(data)
                                    }}
                                    setSelectedWeek={setTimesheetSelectedWeek}
                                    setSelectedDate={setTimesheetSelectedDate}
                                    setWeeksRange={setTimesheetWeeksRange}
                                />
                            </div>
                        </TabPanel>}
                    </BoxContainer>
                    {/* <FlexBetween>
                                    <FeaturedIcon onClick={() => handleMoveStep("forward")} fontSize="14" color="primary" icon="ArrowForward"/>
                            </FlexBetween> */}
                    {/* Financial Info Section */}

                </DialogContent>
                <DialogActions className={classes.actionButton}>
                    <Button className={classes.btnCancel} onClick={() => { handleCancelClose() }}>
                        Cancel
                    </Button>
                    <Button className={classes.btnSave} onClick={() => { handleSaveAndClose(schedule, scheduleRate) }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <ConfirmDialog
                open={showWarningModal}
                canConfirm={((providersAvailability?.conflictingSchedules?.length <= 0 || actionPermissions?.canEditScheduleCausingConflicts !== false) &&
                    (providersAvailability?.availabilityForClientSchedule?.availabilityStatus === "Full Availability" || actionPermissions?.canEditScheduleCausingNoAvailability !== false)) &&
                    (contextData.status !== 'active' || (!isScheduleEdited && !isRateEdited) || actionPermissions?.canEditConfirmedSchedules !== false)}
                onClose={() => setShowWarningModal(false)}
                onConfirm={() => {
                    setIsScheduleEdited(false);
                    handleSaveAndClose(schedule, scheduleRate, true);
                }}
                warnings={warnings}
                item={'schedule'}
                action={'save'}
                id={contextData?.id}
            />
        </>
    )

    function changeTimeFrom(newValue, field) {
        let newStartTime = field === "startTime" ? global.dateTime.getDateTime(newValue) : contextData.schedule?.startTime;
        let newEndTime = field === "endTime" ? global.dateTime.getDateTime(newValue) : contextData.schedule?.endTime;

        const validationResult = validateTimeFields({
            startTime: newStartTime,
            endTime: newEndTime,
        });
        setFieldErrors({
            ...fieldErrors,
            ...validationResult.fieldMessages,
        });

        if (contextData != undefined && validateHhMm(newValue)) {
            const newClientSchedule = { ...contextData, schedule: { ...contextData.schedule, startTime: newStartTime, endTime: newEndTime } };
            setDirty();
            setContextData(newClientSchedule);
            updateProviderAvailabilitiesInfo(newClientSchedule);
            setIsScheduleEdited(true);
        }
    }
}

