import React from 'react'
import clsx from "clsx";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

import useStyles from "./styles";
import baseStyles from '../../../../assets/styles'

const ProgramFormStatus = ({ status, marginTop }) => {
  const classes = useStyles();
  const baseClasses = baseStyles();
  return (
    <div style={{ marginTop: marginTop }}>
      <FormControl
        component="fieldset"
        className={clsx(baseClasses.marginSpaceOne)}
      >
        {/*<FormGroup>*/}
        {/*  <FormControlLabel*/}
        {/*    value="Status : "*/}
        {/*    control={*/}
        {/*      <FormLabel*/}
        {/*        className={clsx(classes.marginSpaceOne)}*/}
        {/*        style={{ marginLeft: 10 }}*/}
        {/*      >*/}
        {/*        {status == null || status == "" ? "Action needed" : status}*/}
        {/*      </FormLabel>*/}
        {/*    }*/}
        {/*    label="Status : "*/}
        {/*    labelPlacement="start"*/}
        {/*  />*/}
        {/*</FormGroup>*/}
      </FormControl>
    </div>
  );
};

export default ProgramFormStatus;
