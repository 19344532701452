
import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DivContainer } from '../../../../../assets/styles/styledComponents';
import { Flex, Text, BoldTitle } from '../../../../../assets/styles/styledComponents';

type DateEditorProps = {
    label: string,
    mode: 'read' | 'edit',
    variant?: 'outlined' | 'filled' | 'standard',
    name: string,
    value: string | number | any,
    error: boolean,
    helperText: string,
    fullWidth?: boolean,
    minDate?: string | Date,
    maxDate?: string | Date,
    onChange: Function
}

const DateEditor: React.FC<DateEditorProps> = ({label, variant="outlined", name, value, minDate, maxDate, error, helperText, fullWidth, onChange, mode="edit"}) => {
  return (
      <DivContainer margin="10px">
          {mode === 'edit' && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label={label}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(newValue) => {
                        onChange(newValue, name);
                    }}
                    renderInput={(params) => <TextField {...params} error={error} helperText={helperText} fullWidth={fullWidth} variant={variant} />}
                />
            </LocalizationProvider>
          )}
          {
            mode === 'read' && 
                <Flex width="100%">
                    <DivContainer width="40%" >
                        <BoldTitle>{label}:</BoldTitle>
                    </DivContainer>
                    <DivContainer width="60%">
                        <Text>{!!value ? value: '-'}</Text>
                    </DivContainer>
                </Flex>
            }
      </DivContainer>
  )
}

export default DateEditor