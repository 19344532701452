import { CLIENT_GET_CONTACT, CMS_CONTACT_BY_USER_ROLE, DUMMY_API } from '../constants';


export default function contact() {
    let contacts_data = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(DUMMY_API)
            .then(data => {
                contacts_data = data;
                resolve(contacts_data);
            }, (rejects) => {
                global.log('rejects');
            });
    });
}

export function getContactDetail(id) {
    let contacts_data = [];

    return new Promise((resolve, rejects) => {
        if (global.isEmpty(id)) {
            resolve(false);
            return;
        }

        global.fetchWrapper.get(CLIENT_GET_CONTACT + id)
            .then(data => {
                contacts_data = data;
                resolve(contacts_data.result);
            }, (rejects) => {
                global.log('rejects');
                resolve(false);
            });
    });
}

export const saveContact = async (contactId, contact) => {
    try {
        const response = await fetch(`/api/application/savecontact/${contactId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contact),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const savedContact = await response.json();
        return savedContact; // Return the saved contact data
    } catch (error) {
        console.error('Error saving contact:', error);
        return null; // Return null or handle the error as needed
    }
};

export async function getContactsByUserRole(userRole) {
    while (global.contactAPI.getContactsByUserRoleIsRunning) {
        await sleep(100);
    }
    global.contactAPI.getContactsByUserRoleIsRunning = true;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CMS_CONTACT_BY_USER_ROLE + userRole)
            .then(data => {
                resolve(data);
                global.contactAPI.getContactsByUserRoleIsRunning = false;
            }, (rejects) => {
                global.log('rejects');
                resolve([]);
            });
    });

}
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const relationShipCollapseRows = [
    { id: 0, name: 'Spinka Michael', relationship: 'Parents', phoneNumber: '(011111) 22121231', emailaddress: 'spinkaMichael222@gmail.com' },
    { id: 1, name: 'Dorry Michael', relationship: 'Siblings', phoneNumber: '(011111) 22121231', emailaddress: 'dorryMichael222@gmail.com' },
    { id: 2, name: 'Sophia Michael', relationship: 'Others', phoneNumber: '(011111) 22121231', emailaddress: 'sophiaMichael222@gmail.com' },
    { id: 3, name: 'Andrew Michael', relationship: 'Legal guardians', phoneNumber: '(011111) 22121231', emailaddress: 'andrewMichael222@gmail.com' },
];

export const relationShipCollapseColumns = [
    { id: 0, fields: 'name', headerName: 'Name' },
    { id: 1, fields: 'emailaddress', headerName: 'Email Address' },
    { id: 2, fields: 'phonenumber', headerName: 'Phone Number' },
    { id: 3, fields: 'relationship', headerName: 'Relationship' },
];

export const columnsContacts = [
    { id: 0, fields: 'name', headerName: 'Name' },
    { id: 1, fields: 'email', headerName: 'Email' },
    { id: 2, fields: 'phone', headerName: 'Phone' },
    { id: 3, fields: 'roles', headerName: 'Roles' },
    { id: 4, fields: 'is_user', headerName: 'Is User' },
    { id: 5, fields: 'is_active', headerName: 'Is Active' },
];

global.contactAPI = {
    getContactDetail: getContactDetail,
    getContactsByUserRole: getContactsByUserRole,
    getContactsByUserRoleIsRunning: false
};