import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip'
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import { DivContainer, Flex, Text, BoldTitle, BoxContainer } from '../../../../../assets/styles/styledComponents';
import { Typography } from '@mui/material';

type SelectEditorProps = {
    label: string,
    mode?: 'read' | 'edit',
    disabled?: boolean
    multiple?: boolean,
    hide?: boolean,
    selectedVariant?: 'text' | 'chip',
    id?: string,
    variant?: 'outlined' | 'filled' | 'standard',
    options: any,
    name: string,
    value: string | number | any,
    error?: boolean,
    helperText?: string,
    fullWidth?: boolean,
    style?: any,
    onChange: Function,
    margin?: string,
    editable?: boolean,
    handleMode?: Function
}

type selectOption = {
    value: string | number | any,
    label: string
}
const SelectEditor: React.FC<SelectEditorProps> = ({ label, style = {}, handleMode, editable, margin = "10px", variant = "outlined", name, value, error, helperText, id, fullWidth, onChange, options, multiple = false, disabled = false, hide = false, selectedVariant = "text", mode = "edit" }) => {

    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value, name);
    };

    const selectedValue = (value: string, index: number | null = null) => {
        switch (selectedVariant) {
            case 'text': {
                return <Typography key={value}>{value}</Typography>
            }
            case 'chip': {
                return <Chip key={value} label={value} />
            }
        }
    }

    if (hide) {
        return null
    } else {
        return (
            <DivContainer width={fullWidth ? '100%' : 'unset'} margin={margin}>
                {mode === 'edit' &&
                    <FormControl sx={{ minWidth: fullWidth ? '100%' : 120, ...style }}>
                        <InputLabel id={id}>{label}</InputLabel>
                        <Select
                            id={id}
                            value={value}
                            label={label}
                            onChange={handleChange}
                            fullWidth={fullWidth}
                            error={error}
                            disabled={disabled}
                            multiple={multiple}
                            variant={variant}
                            sx={{ ...style }}
                            onBlur={() => handleMode?.("read")}
                            renderValue={(selected: any) => (
                                multiple ?
                                    <div>
                                        {selected.map((value: string, index: number) => (
                                            selectedValue(value, index)
                                        ))}
                                    </div> :
                                    <DivContainer>
                                        {selectedValue(selected)}
                                    </DivContainer>
                            )}
                        >
                            {
                                options?.length > 0 &&
                                options.map((option: selectOption) => (
                                    <MenuItem value={option.value}>{option.label}</MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                }
                {
                    mode === 'read' && !editable &&
                    <Flex width="100%">
                        <DivContainer width="40%" >
                            <BoldTitle>{label}:</BoldTitle>
                        </DivContainer>
                        <DivContainer width="60%">
                            <Text>{!!value ? value : '-'}</Text>
                        </DivContainer>
                    </Flex>
                }
                {mode === 'read' && editable &&
                    <Tooltip title="Click To Edit">
                        <BoxContainer padding="10px" display="flex" width="100%" style={{ borderRadius: '4px' }} onClick={() => handleMode?.('edit')}>
                            <DivContainer width="40%" >
                                <Text >{label}:</Text>
                            </DivContainer>
                            <DivContainer width="60%">
                                <Text>{!!value ? value : '-'}</Text>
                            </DivContainer>
                        </BoxContainer>
                    </Tooltip>
                }
            </DivContainer>
        );
    }
}

export default SelectEditor