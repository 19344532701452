import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Checkbox,
    FormControlLabel,
    Autocomplete,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useParams } from "react-router-dom";

import RichTextField from '../../../UI/RichTextField'
import * as constant from "../../../../constants";
import authHeader from "../../../../screens/_helper/auth-header";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
import useUnsavedChangesWarn from "../../../../hooks/useUnsavedChangesWarn";
import usePermission from "../../../../hooks/usePermission";
import AddTodoForm from '../ToDo/AddTodoForm'
import { templateNotes as templateNotesConst } from '../../../../constants'
import { getHebrewMonthRangeFromRegDate } from "../../../../_helpers/datetime";
import { getTemplateNotes } from "../../../../api/clients";
import { strings } from "../../../../_helpers/strings";
import useKeyTracker, { KeyEvent } from '../../../../hooks/useKeyTracker';

export default function AddProgress(props) {
    const { setMonthlyProgressDateRange, dateRange, tab, reloadDashboardNotes, screen, refreshTemplateNoteList } = props;
    const minDate = dateRange?.fromDate ? global.dateTime.getDateOnly(new Date(props.dateRange.fromDate)) : undefined;
    const maxDate = dateRange?.toDate ? global.dateTime.getDateOnly(new Date(props.dateRange.toDate)) : undefined;
    const currDate = global.dateTime.getDateOnly(new Date());

    const progressMonthlyPermissions = usePermission(global.permissions.clients.progress.monthly);

    const classes = useStyles();
    const baseClasses = baseStyles();
    const exploringId = useStoreState((state) => state.exploringId);
    const exploringType = useStoreState((state) => state.exploringType);
    // set the new yeshivas form control
    const [provider, setProvider] = React.useState("dd");
    const [isTemplate, setIsTemplate] = React.useState(false);
    const [note, setNote] = React.useState(null);
    const [title, setTitle] = React.useState(null);
    const [nextStep, setNextStep] = React.useState(null);
    const [noteDate, setNoteDate] = React.useState(new Date());
    const [creatorRole, setCreatorRole] = React.useState("Public");
    const [todo, setTodo] = useState({ startDate: new Date(), dueDate: new Date() })
    const [isNextStepTodo, setIsNextStepTodo] = useState(false);
    const [templateNotes, setTemplateNotes] = useState(templateNotesConst);
    const [selectedNoteTemplate, setSelectedNoteTemplate] = useState(null);
    // Errors selection
    const [providerPerError, setProviderError] = React.useState("");
    const [noteDatePerError, setNoteDatePerError] = React.useState("");
    const [notePerError, setNotePerError] = React.useState("");
    const [titlePerError, setTitlePerError] = React.useState("");
    const [nextStepPerError, setNextStepPerError] = React.useState("");
    const [currentMonthTemplateNotes, setCurrentMonthTemplateNotes] = React.useState([]);
    const [id, setId] = React.useState(null);
    const [loaderDialog, setLoaderDialog] = React.useState(false);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    const urlParams = useParams();
    useEffect(() => {
    }, [title]);
    useEffect(() => {
        if (id) {
            setLoaderDialog(true);
        } else {
            resetAllData();
        }
    }, [props.isOpenCreateModal]);
    useEffect(() => {
        return () => {
            resetAllData(true);
        }
    }, [])
    useEffect(() => {
        if (tab === constant.tabsConst.all) {
            if (selectedNoteTemplate) {
                setMonthlyProgressDateRange(getHebrewMonthRangeFromRegDate(props.editData ? props.editData.noteDate : new Date()))
            }
            else {
                setMonthlyProgressDateRange(null)
            }
        }
    }, [selectedNoteTemplate, noteDate, tab])
    useEffect(() => {
        const { toDate, fromDate } = getHebrewMonthRangeFromRegDate(new Date());
        getTemplateNotes({ dateTo: toDate, dateFrom: fromDate }, "1,2,3,4,5", exploringId).then((data) => {
            setCurrentMonthTemplateNotes(data)
        })
    }, [])

    function resetAllData(clean = false) {
        //setProvider("dd");
        setIsTemplate(false);
        setNote("");
        setNextStep("");
        //setNoteDate(moment(new Date()).format("YYYY-MM-DD"));
        setProviderError("");
        //setNoteDatePerError(null);
        setNotePerError(null);
        setTitlePerError(null);
        setCreatorRole("Public");

        if (!_.isEmpty(props.editData) && !clean) {
            const edit = props.editData;
            const nDate = !minDate ? currDate :
                currDate < minDate ? minDate :
                    currDate > maxDate ? maxDate : currDate;
            // setProvider(edit.provider);
            setTitle(edit.title);
            setNote(edit.noteDescription);
            setNextStep(edit.nextStep);
            setNoteDate(edit.noteDate ? new Date(edit.noteDate) : nDate);
            setCreatorRole(edit.creatorRole ?? 'Public');
            if (edit.noteTemplateId) {
                setSelectedNoteTemplate(edit.noteTemplateId);
                setIsTemplate(true)
            }
        }
        setClean();
    }

    const saveProgress = () => {
        const isValid = formValidationPersonalDetails();
        if (isValid) {
            const payload = {
                Type: 1,
                Table: "Client_Clients",
                PrimaryId: props.clientData?.id ?? urlParams?.id,
                NoteDate: noteDate,
                Title: title,
                NoteDescription: note,
                NextStep: nextStep,
                creatorRole: creatorRole,
                noteTemplateId: selectedNoteTemplate,
            };
            global.log("Progress submit data", payload);
            var noteId = -1;
            if (props.editData.noteTemplateId) {
                payload.noteTemplateId = props.editData.noteTemplateId;
            }
            if (screen === 'dashboard') {
                payload.PrimaryId = props.editData.primaryId
            }
            if (!_.isEmpty(props.editData) && props.editData.id) {
                noteId = props.editData.id;
            }
            setLoading();
            return global.fetchWrapper
                .post(constant.CMS_NOTES_SAVE + noteId, payload, {
                    headers: authHeader(),
                })
                .then(
                    (response) => {
                        if (isNextStepTodo)
                            saveTodo(response.result.id)
                        if (screen === 'dashboard') {
                            reloadDashboardNotes();
                        }
                        if (tab === 'monthly' && typeof refreshTemplateNoteList === 'function') {
                            refreshTemplateNoteList(response.result);
                        }
                        setClean();
                        closeModal(true);
                        endLoading();
                    },
                    (error) => {
                        global.log("error", error);
                        //closeModal();
                        endLoading();
                    }
                );
        }
    };
    const timesheetTabShowShortcut = useKeyTracker({
        keyEvents: [{ key: 'Enter', ctrlKey: true /*, action: saveProgress*/ }]
    });
    useEffect(() => {
        if (timesheetTabShowShortcut.keyExecutions.length > 0) {
            saveProgress();
        }
    }, [timesheetTabShowShortcut.keyExecutions.length])

    const saveTodo = (sourceId) => {
        const saveToDoPayload = {
            title: nextStep,
            table: exploringType,
            assignee_ContactId: todo.assignee_ContactId,
            primaryId: todo.primaryId ? todo.primaryId : exploringId,
            description: todo.description,
            startDate: todo.startDate,
            dueDate: todo.dueDate,
            completedDate: todo.completedDate,
            sourceTable: 'Note',
            sourcePrimaryId: sourceId,
        };

        let todoId = -1;

        return global.fetchWrapper
            .post(constant.TODO_SAVE + todoId, saveToDoPayload)
            .then(
                (response) => {
                    reloadDashboardNotes()
                },
                (error) => {
                    global.log("error", error);
                    global.AjaxFailToast();
                }
            );
    };

    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (provider === "") {
            isValid = false;
            setProviderError("Please enter name");
        } else {
            setProviderError("");
        }
        if (!strings.getRichTextObjFromString(note).value) {
            isValid = false;
            setNotePerError("Progress note can't be empty");
        } else {
            setNotePerError("");
        }
        if (!strings.getRichTextObjFromString(title).value) {
            isValid = false;
            setTitlePerError("Progress title can't be empty");
        } else {
            setTitlePerError("");
        }
        if (!strings.getRichTextObjFromString(nextStep).value && isNextStepTodo) {
            isValid = false;
            setNextStepPerError("Next step is required to create a task");
        } else {
            setNextStepPerError("");
        }
        if (!noteDate) {
            isValid = false;
            setNoteDatePerError("Date can't be empty");
        } else if (!global.dateTime.isValidDate(noteDate)) {
            setNoteDatePerError("Please enter a valid date");
            isValid = false;
        } else if ((minDate && global.dateTime.getDateOnly(noteDate) < minDate) || (maxDate && global.dateTime.getDateOnly(noteDate) > maxDate)) {
            isValid = false;
            setNoteDatePerError("the date you selected isn't within the month you are currently adding the monthly progress");
        }
        else {
            setNoteDatePerError("");
        }
        return isValid;
    };

    const handleClose = (event, reason) => {

        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown"))
            return;
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        closeModal();
    };

    const closeModal = (reloadData = false) => {
        props.onCloseModal(reloadData);
        resetAllData(true);
        endLoading();
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpenCreateModal}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                {props.editData?.id == -1 ? "Add" : "Edit"} Progress
            </DialogTitle>
            <DialogContent>
                {loaderDialog ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            {/* <Grid item xs={12}>
                <div>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="my-input">Provider</InputLabel>
                    <Input
                      value={provider}
                      id="my-input"
                      onChange={(event) => {
                        setDirty();
                        if (!_.isEmpty(event.target.value)) {
                          setProviderError("");
                        }
                        setProvider(event.target.value);
                      }}
                      aria-describedby="my-helper-text"
                    />
                    {providerPerError === "" ? null : (
                      <FormHelperText
                        className={baseClasses.error}
                        id="my-helper-text"
                      >
                        {providerPerError}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </Grid> */}
                            <Grid item xs={12}>
                                <div>
                                    <FormControl fullWidth>
                                        {selectedNoteTemplate && props.editData?.id === -1 ?  // Only disable in add mode if a template is selected
                                            <TextField
                                                value={templateNotes.find(x => x.noteTemplateId === selectedNoteTemplate)?.title}
                                                label={'Title'}
                                                disabled
                                            /> :
                                            <RichTextField
                                                id="ttl"
                                                label={"Title"}
                                                multiline
                                                variant="outlined"
                                                value={title}  // Allow editing by using the state variable `title`
                                                type="text"
                                                onChange={(event) => {
                                                    setDirty();
                                                    if (!_.isEmpty(event.target.value)) {
                                                        setTitlePerError("");
                                                    }
                                                    setTitle(event.target.value); // Update the title state
                                                }}
                                                setState={(title) => {
                                                    setTitle(title);
                                                }}
                                            />
                                        }

                                        {titlePerError == "" ? null : (
                                            <FormHelperText
                                                className={classes.error}
                                                style={{ color: 'red' }}
                                                id="ttl-err"
                                            >
                                                {titlePerError}
                                            </FormHelperText>
                                        )}
                                        {props?.tab === constant.tabsConst.all && !props?.editData?.id && progressMonthlyPermissions.canEdit &&
                                            <FormControl fullWidth style={{ margin: '10px 0px' }}>
                                                <Autocomplete
                                                    options={templateNotes.map((note) => {
                                                        return { ...note, label: note.title, id: note.noteTemplateId }
                                                    })
                                                        //only return non-completed templates
                                                        .filter((item) => !currentMonthTemplateNotes.find((i) => i.noteTemplateId === item.id))}
                                                    className="select-button-drop"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    //disabled={isTemplate}
                                                    value={templateNotes.find(x => x.noteTemplateId === selectedNoteTemplate)?.title}
                                                    renderInput={(params) => <TextField {...params} label={"Select Monthly Progress"} />}
                                                    onChange={(event, selectedOption, reason) => {
                                                        setTitle(templateNotes.find(x => x.noteTemplateId === selectedOption?.id)?.title);
                                                        setSelectedNoteTemplate(selectedOption?.id);
                                                    }}
                                                >
                                                </Autocomplete>
                                            </FormControl>
                                        }
                                        <RichTextField
                                            id="nte"
                                            label={"Note"}
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            value={note}
                                            type="text"
                                            onChange={(event) => {
                                                setDirty();
                                                if (!_.isEmpty(event.target.value)) {
                                                    setNotePerError("");
                                                }
                                                //setNote(event.target.value);
                                            }}
                                            setState={setNote}
                                        />
                                        {notePerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                style={{ color: 'red' }}
                                                id="nte-err"
                                            >
                                                {notePerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div>
                                    <FormControl fullWidth>
                                        <RichTextField
                                            id="nxtstp"
                                            label={"Next Step"}
                                            multiline
                                            rows={2}
                                            variant="outlined"
                                            value={nextStep}
                                            type="text"
                                            onChange={(event) => {
                                                setDirty();
                                                //setNextStep(event.target.value);
                                            }}
                                            setState={setNextStep}
                                        />
                                        {nextStepPerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                style={{ color: 'red' }}
                                                id="nte-err"
                                            >
                                                {nextStepPerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>
                            {!props?.editData?.id && <>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isNextStepTodo}
                                                onChange={(event) => setIsNextStepTodo(!isNextStepTodo)}
                                                name="isNextStepTodo"
                                                color="primary"
                                            />
                                        }
                                        label="Is next step a task?"
                                    />
                                </Grid>
                                {isNextStepTodo &&
                                    <Grid item xs={12}>
                                        <div style={{ border: "1px solid lightgrey", borderRadius: 5, }}>
                                            <Typography style={{ fontSize: 14, marginTop: '10px', marginLeft: '10px' }} color="textPrimary">More task details</Typography>
                                            <AddTodoForm
                                                todo={todo}
                                                setTodo={setTodo}
                                                isFromProgress
                                            />
                                        </div>
                                    </Grid>
                                }
                            </>
                            }
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        label={"Note Date"}
                                        value={noteDate}
                                        onChange={(valDate) => {
                                            //if (!valDate || !valDate.toDateString)
                                            //    return;
                                            //if (new Date(noteDate).toDateString() == valDate.toDateString())
                                            //    return;
                                            setNoteDate(valDate);
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" variant={"outlined"} fullWidth />}
                                    />
                                </LocalizationProvider>
                                {noteDatePerError == "" ? null : (
                                    <FormHelperText
                                        className={baseClasses.error}
                                        style={{ color: 'red' }}
                                        id="my-helper-text"
                                    >
                                        {noteDatePerError}
                                    </FormHelperText>
                                )}
                            </Grid>
                            {(_.isEmpty(props.editData)) &&
                                <Grid item xs={12}>
                                    <TextField
                                        className="select-button-drop"
                                        select fullWidth
                                        label="Access Level"
                                        variant="outlined"
                                        size="small"
                                        value={creatorRole}
                                        onChange={(event) => {
                                            setDirty();
                                            setCreatorRole(event.target.value)
                                        }}
                                    >
                                        <MenuItem
                                            key={"Private"}
                                            value={"Private"}
                                        >
                                            {"Private"}
                                        </MenuItem>
                                        <MenuItem
                                            key={"Public"}
                                            value={"Public"}
                                        >
                                            {"Public"}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            }


                            {/*
                                                                    <Grid item xs={12}>
                                        <div className={baseClasses.paddingTop16}>
                                            Note Date
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="enrolledOn"
                                                    type="date"
                                                    name="enrolledOn"
                                                    value={noteDate}
                                                    onChange={(event) => {
                                                        setDirty();
                                                        if (!_.isEmpty(event.target.value)) {
                                                            setNoteDatePerError("");
                                                        }
                                                        setNoteDate(event.target.value);
                                                    }}
                                                    //   defaultValue={fields"}
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                {<FormHelperText id="my-helper-text"></FormHelperText>}
                                                {noteDatePerError == "" ? null : (
                                                    <FormHelperText
                                                        className={baseClasses.error}
                                                        id="my-helper-text"
                                                    >
                                                        {noteDatePerError}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    </Grid>
                                */}


                        </Grid>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={saveProgress} color="primary">
                    Save
                </Button>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
