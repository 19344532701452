/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import "./NavMenu.css";
import { useMediaQuery, FormControlLabel, Switch, IconButton, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from '@mui/icons-material/Warning';
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import clsx from "clsx";
import EditClientPage from "../screens/client/edit_client/edit_client";
import { useLocation } from "react-router-dom";
import DataTable from "./DataTable/DataTable";
import { clients, columnsClients } from "../api/clients";
import Dialog from "@mui/material/Dialog";
import { FormControl, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/lab/Alert";
import { toast } from "react-toastify";
import _, { filter } from "lodash";
import * as constant from ".././constants";
import authHeader from "../screens/_helper/auth-header";
import ImportData from "../screens/client/ImportData";
import AddAppointment from "./explorer/Tabs/Schedule/AddClientSchedule";
import { useStoreState, useStoreActions } from "easy-peasy";
import usePermission from "../hooks/usePermission";
import useInterval from "../hooks/useInterval";
import baseStyles from '../assets/styles';
import { FlexJustifyEnd } from '../assets/styles/styledComponents';
import useGetValueOptionsLists from '../hooks/useGetValueOptionsLists';
import FRONTEND_ROUTE_CONSTANTS from "../frontend_route_constants";
import { strings } from "../_helpers/strings";
import SearchInput from "./common/SearchInput/SearchInput";

const drawerWidth = 240;
let programs = false;

const useStylesHome = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "16px 0px",
        margin: "12px 26px",
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: "primary",
            color: "#FFFFFF",
            padding: "0 15px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-colCell": {
            // margin: "0 15px",
            padding: 0,
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell": {
            // margin: "0 15px",
            // padding: 0,
            borderBottom: "none",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .icon": {
            padding: 3,
        },
    },
    
}));

export default function Home(props) {
    const theme = useTheme();
    const classes = useStylesHome();
    const baseClasses = baseStyles(theme);
    const location = useLocation();
    const [data, setData] = React.useState([]);
    const [id, setId] = React.useState("");
    // const [query, setQuery] = React.useState(""); // Commenting because, in future we might need it
    const [searchText, setSearchText] = React.useState("");
    //   const [open, setOpen] = React.useState(true);
    const [showDisenrolled, setShowDisenrolled] = React.useState(false);
    const [selectedChipFilters, setSelectedChipFilters] = React.useState([{
        filter: strings.removeEmptySpaces(constant.clientQuickFilters.showDisenrolled),
        isActive: false
    }]);
    const [rows, setRow] = React.useState([]);
    // const [columns, setColumns] = React.useState(columnsClients);
    const [loader, setLoader] = React.useState(true);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [type, setType] = React.useState(null);
    const [popUp, showPopup] = React.useState(false);
    const [isOpenEmployeeModal, setIsOpenEmployeeModal] = React.useState(false);
    const [editEmployeeData, setEditEmployeeData] = React.useState(null);
    const query = useStoreState((state) => state.clients.query);
    const setQuery = useStoreActions((actions) => actions.clients.setQuery);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const clientList = useStoreState(state => state.clients.clients);
    const clientListFiltered = useStoreState(state => state.clients.clientListFiltered);
    const userNotification = useStoreState((state) => state.users.userNotification);
    const fetchClients = useStoreActions(actions => actions.clients.fetchClients);
    const fetchUserNotifications = useStoreActions(actions => actions.users.fetchUserNotifications);
    const updateClients = useStoreActions(actions => actions.clients.updateClients)
    const setClientListFiltered = useStoreActions(actions => actions.clients.setClientListFiltered)
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const exploringId = useStoreState((state) => state.exploringId);
    const currentPage = useStoreState((state) => state.clients.currentPage);
    const setCurrentPage = useStoreActions((actions) => actions.clients.setCurrentPage)
    const exploringMode = useStoreState((state) => state.exploringMode);
    const setExploringMode = useStoreActions((actions) => actions.setExploringMode);
    const setExploringType = useStoreActions((actions) => actions.setExploringType);
    const isSidebarOpened = useStoreState((state) => state.isSidebarOpened);
    const { canView, canCreate } = usePermission(global.permissions.clients);
    const valueOptionsLists = useGetValueOptionsLists([
        'yeshiva',
        'organizer',
        'roshMeargen',
        'financialCoordinator',
        'careManager',
        'careManagerSupervisor',
        'hcbsCoordinator',
        'comfortHealthStatus',
        'neighborhood'], constant.explorerTypesConst.client)
    const [isEditing, setIsEditing] = React.useState(false);
    const history = useStoreState((state) => state.history);
    useEffect(() => {
        setIsEditing(exploringMode === "edit" ? true : false);
    }, [exploringMode]);

    useEffect(() => {
        setClientListFiltered(setDataOfFilter(clientList))
    }, [query, selectedChipFilters, clientList])

    const setDataOfFilter = (rows) => {
        let tempRows = rows;
        if (selectedChipFilters.some((selectedFilter) => selectedFilter.filter === strings.removeEmptySpaces(constant.clientQuickFilters.showDisenrolled) && !selectedFilter.isActive)) {
            tempRows = tempRows.filter((row) => {
                return !isDisenrolled(row);
            })
        } 
        if (!query) {
            return tempRows;
        }
        return tempRows.filter(
            (row) =>
                row.last_name?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.first_name?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.legalName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.yeshiva?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.age?.toString().trim().indexOf(query.trim().toLowerCase()) > -1 ||
                row.organizer?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.city?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.father_name?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.id?.toString().trim().indexOf(query.trim().toLowerCase()) > -1 
        );


        //    .filter((row) => row.first_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    };
    //Todo: we don't need this
    useEffect(() => {
        setExploringType("clients");
    }, []);

    // useEffect(() => {
    //     if (rows.length == 0 && !programs) {
    //         getClientData();
    //         return;
    //     } else {
    //         setLoader(false);
    //     }
    // }, []);

    //delay search query by 500 ms
    useEffect(() => {
        const timer = setTimeout(() => {
            setQuery(searchText)
        }, 500)
        return () => { clearTimeout(timer) }
    }, [searchText])

    const handleAddClientSuccess = (isSuccess) => {
        if (isSuccess) {
            global.AjaxSaved1("Client Saved Successfully!")
            fetchClients()
        } else {
            global.AjaxFailToast();
        }
    };
    const handleAddEmployeeSuccess = (isSuccess) => {
        if (isSuccess) {
        } else {
            global.AjaxFailToast();
        }
    };

    const popupDisplay = () => {
        showPopup(true);
    };
    const setEditType = () => {
        setType(false);
    };
    const getClientData = () => {
        setLoader(true);
        setLoading();
        clients().then((value) => {
            if (value.length == 0) {
                programs = true;
            }
            setLoader(false);
            endLoading();
            global.log('the value of the clients list as seen from /clients', value)
            updateClients(value);
        });
    };
    const setImportData = () => {
        handleNewEmployee();
    };
    const handleDrawerClose = () => {
        //getClientData();
    };
    const handleNewEmployee = () => {
        setIsOpenEmployeeModal(true);
    };

    const handleCloseEmployeemodal = () => {
        setIsOpenEmployeeModal(false);
        setEditEmployeeData(null);
    };
    const isDisenrolled = (row) => {
        return row.disenrolledOn && !global.dateTime.isGreaterThanToday(row.disenrolledOn)
    }

    const loadClientsData = (refreshMs, showLoading = true) => {
        if (showLoading) {
            setLoader(true);
            setLoading();
        }
        // load
        fetchClients().then(() => {
            // set loading to false
            setLoader(false);
            endLoading();
        })
        if (refreshMs)
            setTimeout(() => loadClientsData(refreshMs, false), refreshMs);
    }

     /**
     * @function - to fetch user notification in every 60sec
     */
    useInterval(() => {
        fetchUserNotifications(1)
    }, 60000)  

    useEffect(() => {
        // Initial Call to get user notifications;
        if (!userNotification?.length) {
            fetchUserNotifications(1) // Todo: replace this 1 with user ID
        }
        if (clientList.length < 1) {
            // set timer to load data every 10 minutes
            let refreshMs = 1800000;
            loadClientsData(refreshMs);
        }
    }, []);


    //useEffect(() => {
    //    if(clientList.length > 0){
    //        setLoader(false);
    //        endLoading();
    //    }
    //},[clientList])

    const columnsClients = [
        { field: "id", headerName: "Client ID", width: 110, flex: 1, type: "number" },
        { field: "warning", enableTooltip: true, disableColumnMenu: true, renderCell: (param) => (
            isDisenrolled(param.row)  ? 
                <IconButton>
                    <Tooltip title="disenrolled">
                        <WarningIcon style={{ color: "red" }}/>
                    </Tooltip>
                </IconButton>:
            null
        ), sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' },
        { field: "fullName", headerName: "Full Name",  width: 110, flex: 1, },
        { field: "last_name", headerName: "Last Name", description: 'Last Name', width: 120, flex: 0.8 },
        { field: "first_name", headerName: "First Name", description: 'First Name', width: 150, flex: 1 },
        { field: "firstNameHebrew", headerName: "Hebrew First Name", width: 110, flex: 1, },
        { field: "lastNameHebrew", headerName: "Hebrew Last Name", width: 110, flex: 1, },
        { field: "legalName", headerName: "Legal Name", width: 110, flex: 1, hide: true },
        { field: "organizer", headerName: "מארגן", width: 150, flex: 1.2, type: 'singleSelect',},
        { field: "roshMeargen", headerName: "ראש מארגן", width: 150, flex: 1.2, type: 'singleSelect',},
        { field: "careManager", headerName: "Care Manager", width: 150, flex: 1.2, type: 'singleSelect',},
        { field: "careManagerSupervisor", headerName: "Care Manager Supervisor", description:'Care Manager Supervisor', width: 150, flex: 1.2, type: 'singleSelect',},
        { field: "hcbsCoordinator", headerName: "HCBS Coordinator", description:'HCBS Coordinator', width: 150, flex: 1.2, type: 'singleSelect',},
        { field: "financialCoordinator", headerName: "Billing Coordinator", description:'Billing Coordinator', width: 150, flex: 1.2, type: 'singleSelect',},
        { field: "yeshiva", headerName: "Yeshiva", width: 150, flex: 1, type: 'singleSelect',},
        { field: "stage", headerName: "Stage", width: 155, flex: 1 },
        { field: "comfortHealthStatus", headerName: "Comfort", width: 110, flex: 1, type: 'singleSelect',},
        { field: "neighborhood", headerName: "Neighborhood", width: 110, flex: 1, type: 'singleSelect',},
        { field: "employees", headerName: "Employees", description: 'Employees', width: 150, flex: 0.8, enableTooltip: true },
        { field: "employeeCount", headerName: "# of Employees", description: '# of Employees',  width: 150, flex: 0.8, type: "number" },
        { field: "schedulesPendingFinancial", headerName: "# Pending Financial Schedules", description: '# Pending Financial Schedules', width: 150, flex: 0.8, type: "number" },
        { field: "phone1", headerName: "Home Phone", width: 110, flex: 1, },
        { field: "age", headerName: "Age", width: 110, flex: 1, type: "number" },
        { field: "dob", headerName: "DOB", width: 110, flex: 0.5, type: "date" },
        { field: "dateEnrolledComfort", headerName: "Date Enrolled Comfort", description: 'Date Enrolled Comfort',  width: 110, flex: 0.8, type: "date" },
        { field: "dateApprovedComfort", headerName: "Date Approved Comfort", description: 'Date Approved Comfort',  width: 110, flex: 0.8, type: "date" },
        { field: "isPrevEnrolledInCH", headerName: "Is Prev Enrolled In CH", description: 'Is Prev Enrolled In CH', width: 110, flex: 0.8, type: "boolean" },
        { field: "isSeeingTherapist", headerName: "Seeing Therapist", description: 'Seeing Therapist', width: 110, flex: 0.5, type: "boolean" },
        { field: "comfortNote", headerName: "Comfort Note", description: 'Comfort Note', width: 110, flex: 1, enableTooltip: true },
        { field: "pendedOn", headerName: "Date Pended", width: 110, flex: 0.5, type: "date" },
        { field: "isPended", headerName: "Pended", width: 30, flex: 0.3, type: "date", hide: true },
        { field: "reactivatedOn", headerName: "Date Reactivated", description: 'Date Reactivated', width: 110, flex: 0.5, type: "date" },
        { field: "sentDocsToParentsOn", headerName: "Sent Docs To Parents On", description: 'Sent Docs To Parents On', width: 110, flex: 0.5, type: "date" },
        { field: "insuranceCompany", headerName: "Insurance Company", width: 110, flex: 1, },
        { field: "sentBinderToComfortHealthOn", headerName: "Sent Binder To Comfort Health On", description: 'Sent Binder To Comfort Health On', width: 110, flex: 0.5, type: "date" },
        { field: "careManagerAssignedOn", headerName: "Care Manager Assigned On", description: 'Care Manager Assigned On', width: 110, flex: 0.5, type: "date" },
        { field: "intakeMeetingDate", headerName: "Intake Meeting Date", description: 'Intake Meeting Date', width: 110, flex: 0.5, type: "date" },
        { field: "cans_CompletedOn", headerName: "CANS CompletedOn", description: 'CANS CompletedOn', width: 110, flex: 0.5, type: "date" },
        { field: "crisis_CompletedOn", headerName: "Crisis CompletedOn", description:'Crisis CompletedOn', width: 110, flex: 0.5, type: "date" },
        { field: "poc_CompletedOn", headerName: "POC CompletedOn", description: 'POC CompletedOn', width: 110, flex: 0.5, type: "date" },
        { field: "comprehensiveAssessment_CompletedOn", headerName: "ComprehensiveAssessment CompletedOn", description: 'ComprehensiveAssessment CompletedOn',width: 110, flex: 0.5, type: "date" },
        { field: "loc_CompletedOn", headerName: "LOC CompletedOn", description:'LOC CompletedOn', width: 110, flex: 0.5, type: "date" },
        { field: "packetSentOn", headerName: "Packet Sent On", description:'Packet Sent On', width: 110, flex: 0.5, type: "date" },
        { field: "comfortHealthTimeRequirements", headerName: "Time Requirements", description: 'Time Requirements', width: 110, flex: 1, enableTooltip: true },
        { field: "sentIepToNewAgencyOn", headerName: "Sent IEP On", description: 'Sent IEP On', width: 110, flex: 0.5, type: "date" },
        { field: "dateEnrolledP3", headerName: "Date Enrolled P3", description: 'Date Enrolled P3', width: 110, flex: 0.5, type: "date" },
        { field: "dateApprovedP3", headerName: "Date Approved P3", description: 'Date Approved P3', width: 110, flex: 0.5, type: "date" },
        { field: "hoursPerWeekP3", headerName: "Hours Per Week P3", description: 'Hours Per Week P3', width: 110, flex: 0.5, type: "number" },
        { field: "dateEnrolledLight", headerName: "Date Enrolled Light", description: 'Date Enrolled Light', width: 110, flex: 0.5, type: "date" },
        { field: "enrolledOn", headerName: "Enrolled LHisalos On", description: 'Enrolled LHisalos On', width: 110, flex: 0.5, type: "date" },
        { field: "disenrolledOn", headerName: "Disenrolled LHisalos On", description: 'Disenrolled LHisalos On', width: 110, flex: 0.5, type: "date" },
        { field: "uploadedInsuranceOn", type: "date", headerName: "Date Uploaded Insurance", description: 'Date Uploaded Insurance', width: 110, flex: 0.5, },
        { field: "uploadedConsentFormOn", type: "date", headerName: "Date Uploaded Consent Form", description: 'Date Uploaded Consent Form', width: 110, flex: 0.5, },
        { field: "uploadedSedOn", type: "date", headerName: "Date Uploaded SED", description: 'Date Uploaded SED', width: 110, flex: 0.5, },
        { field: "uploadedIepOn", type: "date", headerName: "Date Uploaded IEP", description: 'Date Uploaded IEP', width: 110, flex: 0.5, },
        //{ field: "city", headerName: "City", width: 110, flex: 0.7,},
        {
            field: "father_name",
            headerName: "Father Name",
            width: 150,
            flex: 1,
        },
        { field: "enrollmentColumn", headerName: "Enrollment Icon", type: 'action'},
        { field: "deleteColumn", headerName: "Delete Icon", type: 'action'},

    ];

    const _setRowAndColumn = () => {
        return { rows: clientListFiltered, columns: columnsClients, screen: "home" };
    };
    const popUpClose = () => {
        showPopup(false);
    };

    const agree = () => {
        showPopup(false);

        return global.fetchWrapper
            .post(constant.REMOVE_CLIENT + id)
            .then(
                (response) => {
                    //getClientData();
                    fetchClients();
                    global.AjaxSaved1("Client Deleted!");
                }
                ,
                (error) => {
                    if (_.has(error, "response.message")) {
                        global.AjaxFailToast();
                    }
                }
            );
    };

    const handleShowDisenrolled = () => {
        setShowDisenrolled(!showDisenrolled)
    }

    const handleChangeChipFilter = (value) => {
        if (Array.isArray(value)) { // When loading saved filters
            setSelectedChipFilters(value.length ? value : constant.clientChipFilterState)
            return;
        }
        setSelectedChipFilters(selectedChipFilters.map((item) => ( 
            item.filter === value.filter ? value : item )))
    }

    const disagree = () => {
        showPopup(false);
    };

    const handleSearch = (query) => {
        setSearchText(query)
    }

    const onSortingRows = (rows) => {
        setClientListFiltered(rows)
    }
    
    // global.AjaxFailToast();
    const options = {
        page: currentPage,
        onPageChange:(newPage) => {
          setCurrentPage(newPage)
        },
    }

    return (
        <>
            <main className={classes.content}>
                {/*<DocumentUploadInput label={"haha"}></DocumentUploadInput> */}
                <div className={baseClasses.toolbar} />
                <div>
                    <div className={baseClasses.rowFlex}>
                        <SearchInput query={query} getSearchQuery={handleSearch} />
                        <div className={baseClasses.grow}></div>
                        {
                            canCreate &&
                            <div>
                                <Button
                                    id={"new-client-button"}
                                    variant="text"
                                    onClick={() => {
                                        history(`${FRONTEND_ROUTE_CONSTANTS.CLIENT_ROUTE}${-1}${FRONTEND_ROUTE_CONSTANTS.ENROLEMENT}`)
                                        setExploringId(null)
                                        setExploringMode("edit");
                                    }}
                                    color="primary"
                                >    
                                    <b style={{ fontSize: "14px", paddingRight: "4px", color: "primary" }}>
                                        {"New  "}</b> <AddRoundedIcon fontSize="small" style={{ color: "primary" }} />
                                </Button>

                                {/*<Button*/}
                                {/*    id={"import-clients-button"}*/}
                                {/*    variant="contained"*/}
                                {/*    className={classes.new_button}*/}
                                {/*    onClick={setImportData}*/}
                                {/*    color="primary"*/}
                                {/*>*/}
                                {/*    Import*/}
                                {/*</Button>*/}
                            </div>
                        }
                    </div>
                    
                    <Paper>
                        {(
                            <DataTable
                                title="Talmidim"
                                showColumns={true}
                                id={"client-list-table"}
                                data={_setRowAndColumn()}
                                onDelete={(params) => {
                                    setId(params.row.id);
                                    popupDisplay();
                                }}
                                options={options}
                                onSortingRows={onSortingRows}
                                quickFilters={{
                                    type: "chip",
                                    options: [constant.clientQuickFilters.showDisenrolled],
                                    value: selectedChipFilters,
                                    handler: handleChangeChipFilter
                                }}
                                onEdit={(row) => {
                                    history(`${FRONTEND_ROUTE_CONSTANTS.CLIENT_ROUTE}${row.id}${FRONTEND_ROUTE_CONSTANTS.ENROLEMENT}`)
                                    setExploringId(row.id);
                                    setExploringMode("edit");
                                }}
                                onOpen={() => {
                                    setEditType();
                                }}
                                searchQuery={query}
                                permissions={global.permissions.clients}
                                valueOptionsLists={valueOptionsLists}
                                hideToolbarIconText
                                hideHeaderColumnsSortIcon
                                multiView
                            />
                        )}
                    </Paper>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [baseClasses.drawerOpen]: isEditing,
                            [baseClasses.drawerClose]: !isEditing,
                        })}
                        classes={{
                            paper: clsx({
                                [baseClasses.drawerOpen]: isEditing,
                                [baseClasses.appBarShift]: isSidebarOpened,
                                [baseClasses.appBarShiftFullWidth]: !isSidebarOpened,
                                [baseClasses.drawerClose]: !isEditing,
                            }),
                        }}
                        anchor="right"
                    >
                        {/* {isEditing ? <EditClientPage
                            
                        /> : null} */}
                    </Drawer>
                </div>
                <Dialog
                    fullScreen={fullScreen}
                    open={popUp}
                    onClose={popUpClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure you want to Delete the Client?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button autoFocus onClick={agree} color="primary">
                            Yes
                        </Button>
                        <Button onClick={disagree} color="primary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
                <ImportData
                    isOpenEmployeeModal={isOpenEmployeeModal}
                    onCloseEmployeemodal={handleCloseEmployeemodal}
                    addEmployeeSuccess={handleAddEmployeeSuccess}
                    editEmployeeData={editEmployeeData}
                />
            </main>
        </>
    );
}
