import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useStoreActions } from "easy-peasy";
import React from 'react';
import { toast } from "react-toastify";
import { accountService } from "../../_services/account.service";





const DeleteUser = ({userId, onClose}) => {
    const fetchUsers = useStoreActions(actions => actions.users.fetchUsers)
    const handleClose = () => {
        onClose();
    }
    const deleteUser = () => {
        accountService.delete(userId).then(() => {
                global.AjaxSaved1("User Deleted!");
                fetchUsers();
            handleClose();
        })
    }

    return ( 
    <Dialog
        disableBackdropClick
        open
        onClose={handleClose}
    >
        <DialogTitle>
            Delete User
        </DialogTitle>
            <DialogContent>
                Are You Sure you want to Delete the User?
            </DialogContent>
        <DialogActions>
            <Button
                color="primary"
                onClick={deleteUser}
            >
                Delete
            </Button>
            <Button 
                color="primary"
                autoFocus
                onClick={handleClose}
            >
                Cancel
            </Button>
        </DialogActions>
    </Dialog> 
    );
}
 
export default DeleteUser;