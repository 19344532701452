import _ from "lodash";
import {
    EMPLOYEE_AVAILABLE_PROVIDERS_LIST, EMPLOYEE_DETAIL, EMPLOYEE_LIST,
    EMPLOYEE_REMOVE,
    employeeFileTemplate,
    GET_EMPLOYEE_TEMPLATE_FILES,
    PROFESSIONAL_NAMES_LIST,
    EMPLOYEE_AVAILABILITY_SCHEDULE,
    EMPLOYEE_NAMES,
    EMPLOYEE_INFO
} from "../constants";
import authHeader from "../screens/_helper/auth-header";
import { periodicities } from '../_helpers/constants/scheduleConstants';

export default function employees() {
    let employees_data = [];
    const employees = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(EMPLOYEE_LIST)
            .then(
                (data) => {
                    employees_data = data.result;
                    // global.log("data =", data, employees_data);
                    employees_data.forEach((employee, index) => {
                        let item = employee.employee;
                        if (!_.isEmpty(item.contact)) {
                            employees.push({
                                id: item.id,
                                createdOn: item.createdOn,
                                firstName: item.contact.firstName,
                                lastName: item.contact.lastName,
                                fullName: item.contact.firstName + " " + item.contact.lastName,
                                firstNameHebrew: item.contact.firstNameHebrew,
                                lastNameHebrew: item.contact.lastNameHebrew,
                                firstNameLegal: item.contact.firstNameLegal,
                                lastNameLegal: item.contact.lastNameLegal,
                                middleNameLegal: item.contact.middleNameLegal,
                                legalName: item.contact.legalName,
                                clients: employee.clients.join(),
                                yeshivas: employee.yeshivas.join(),
                                meargenim: employee.meargenim.join(),
                                clientCount: employee.clientCount,
                                phone1: item.contact.phone1,
                                hasText: item.contact.hasText,
                                address1: item.contact.address1,
                                age: item.contact.age,
                                city: item.contact.city,
                                contactId: item.contactId,
                                positionId: item.positionId,
                                position: item.position !== null ? item.position.name : "",
                                email1: item.contact.email1,
                                state: item.contact.state,
                                zip: item.contact.zip,
                                phone2: item.contact.phone2,
                                email2: item.contact.email2,
                                ssn: item.contact.ssn,
                                dob: item.contact.dob,
                                isActive: item.isActive,
                                inactive: !item.isActive,
                                isEmployee: item.isEmployee,
                                isLegal: item.isLegal,
                                isSummerOnly: item.isSummerOnly,
                                isProfessional: item.isProfessional,
                                isYungerman: item.isYungerman,
                                isExternal: item.isExternal,
                                rateDisplay: (item.rate ? ("$" + item.rate.toString() + (periodicities[item.ratePeriod] ? ("/" + periodicities[item.ratePeriod]) : "")) : "-"),
                                rate: item.rate,
                                ratePeriod: item.ratePeriod,
                                prevYeshivas: item.prevYeshivas,
                                experience: item.experience,
                                note: item.note,
                                lHisalosApprovalStatus: item.lHisalosApprovalStatus,
                                parsonageLimit: item.parsonageLimit,
                                financialNotes: item.financialNotes,
                                paymentOptions: item.paymentOptions,
                                planningToEnrollComfortHealth: item.planningToEnrollComfortHealth,
                                hasComfortHealthApplication: item.hasComfortHealthApplication,
                                hasBA: item.hasBA,
                                hasHighSchoolDiploma: item.hasHighSchoolDiploma,
                                sentToComfortHealthOn: item.sentToComfortHealthOn,
                                fingerprintedOn: item.fingerprintedOn,
                                comfortHealthApprovedOn: item.comfortHealthApprovedOn,
                                catsApprovedOn: item.catsApprovedOn,
                                specialRequirements: item.specialRequirements,
                                amountCouldWork: item.amountCouldWork,
                                isInTheMountainsSummer: item.isInTheMountainsSummer,
                                hasLicense: item.hasLicense,
                                hasCar: item.hasCar,
                                summerColony: item.summerColonyContact?.firstName,
                                amountBilling: item.amountBilling,
                                neighborhood: item.contact.neighborhood,
                                comfortHealthStatus: item.comfortHealthStatus,
                                comfortHealthEmployeeId: item.comfortHealthEmployeeId,
                                specialties: item.specialties,
                                prevocApprovedOn: item.prevocApprovedOn,
                                professionalType: item.professionalType,
                                isLhisalosInHouse: item.isProfessional && !item.isExternal,
                                uploadedIdOn: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.ID)?.uploadedOn,
                                uploadedParsonageFormOn: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.ParsonageForm)?.uploadedOn,
                                uploadedHighSchoolDiplomaOn: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.HighSchoolDiploma)?.uploadedOn,
                                uploadedBAOn: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.BA)?.uploadedOn,
                                uploadedAssociatesDegreeOn: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.AssociateDegree)?.uploadedOn,
                                uploadedLhisalosContractOn: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.LhisalosContract)?.uploadedOn,
                                baFileName: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.BA)?.name,
                                uploadedI9On: item.contact.files.find(x => x.templateFileId === employeeFileTemplate.I9)?.uploadedOn,
                                hasCarAllYear: item.hasCarAllYear,
                                levelOfEducation: item.levelOfEducation,
                                roshMaamidId: item.roshMaamidId,
                                roshMaamidName: item.roshMaamidNew && item.roshMaamidNew.firstName && item.roshMaamidNew.lastName
                                    ? `${item.roshMaamidNew.firstName} ${item.roshMaamidNew.lastName}`
                                    : '',
                            });
                        }


                    });
                    resolve(employees);
                },
                (rejects) => {
                    global.log("rejects", rejects);
                }
            );
    });
}

export const getProfessionalNames = () => {

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(PROFESSIONAL_NAMES_LIST)
            .then(
                (data) => {
                    resolve(data.result);
                },
                (rejects) => {
                    global.log("rejects", rejects);
                }
            );
    });
}

export const getEmployeeNames = () => {
    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(EMPLOYEE_NAMES)
            .then(
                (data) => {
                    resolve(data)
                },
                (reason) => {
                    reject(reason);
                }
            )
    })
}

export const getEmployeeInfo = (id) => {
    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(EMPLOYEE_INFO + id)
            .then(
                (data) => {
                    resolve(data)
                },
                (reason) => {
                    reject(reason);
                }
            )
    })
}

export let getAvailableProviders = (
    filters,
    startTime,
    endTime,
    daysOfWeek
) => {
    var data = {
        rateFrom: filters.rateFrom,
        rateTo: filters.rateTo,
        specialties: filters.specialties.join(","),
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
    };

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(EMPLOYEE_AVAILABLE_PROVIDERS_LIST, data).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                global.log("error", error);
                global.AjaxFailToast();
                resolve([]);
            }
        );
    });
};

export let getEmployeeDetails = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(EMPLOYEE_DETAIL + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(data.responseCode);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    resolve(rejects);
                }
            );
    });
};

export let getEmployeeAvailabilty = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(EMPLOYEE_AVAILABILITY_SCHEDULE + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(data.responseCode);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    resolve(rejects);
                }
            );
    });
};

export let removeEmployeeFromList = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(EMPLOYEE_REMOVE + id, {
                headers: authHeader(),
            })
            .then(
                (response) => {
                    if (response.responseCode === 200) {
                        resolve(true);
                        global.AjaxSaved(response.message);
                    } else {
                        global.AjaxFailToast();
                        resolve(false);
                    }
                },
                (error) => {
                    global.log("error", error);
                    global.AjaxFailToast();
                    resolve(false);
                }
            );
    });
};
export let getEmployeeTemplateDocuments = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_EMPLOYEE_TEMPLATE_FILES + id)
            .then(
                (response) => {
                    if (response.responseCode === 200) {
                        resolve(response.result);
                    } else {
                        global.AjaxFailToast();
                        resolve(false);
                    }
                },
                (error) => {
                    global.log("error", error);
                    global.AjaxFailToast();
                    resolve(false);
                }
            );
    });
};

export const saveHistory = async (contactId, application) => {
    try {
        const response = await fetch(`/api/application/savehistory/${contactId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(application),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const savedApplication = await response.json();
        return savedApplication;
    } catch (error) {
        console.error('Error saving application:', error);
        return null;
    }
};

export const updateHistory = async (contactId, applicationId, application) => {
    try {
        const response = await fetch(`/api/application/updatehistory/${contactId}/${applicationId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(application),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const updatedApplication = await response.json();
        return updatedApplication;
    } catch (error) {
        console.error('Error updating application:', error);
        return null;
    }
};

export const columnsEmployees = [
    { field: "firstName", headerName: "First Name" },
    { field: "lastName", headerName: "Last Name", width: 200, sortable: false },
    { field: "phone1", headerName: "Phone Number", width: 200, sortable: false },
    { field: "address1", headerName: "Address", width: 200, sortable: false },
    { field: "city", headerName: "City", width: 200, sortable: false },
];

export const yungermanBusySchedule = [
    { field: "startTime", headerName: "From", flex: 1 },
    { field: "endTime", headerName: "To", flex: 1 },
    { field: "clientName", headerName: "Client", flex: 1 },
    { field: "daysOfWeek", headerName: "Schedule", flex: 1 },
    { field: "note", headerName: "Note", flex: 1 },
];

export const yungermanAvailableSchedule = [
    { field: "startTime", headerName: "From", width: 110 },
    { field: "endTime", headerName: "To", width: 110 },
    { field: "schedule", headerName: "Schedule", width: 140 },
];
