import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import UserInfo from "./UserInfo";

interface UserFormProps {
  onClose: Function,
  isOpen: boolean,
  userModule?: string,
  editData?: any,
  defaultValues?: any
}

const UserForm: React.FC<UserFormProps> = ({onClose, userModule, isOpen, defaultValues, editData}) => {
  
  function handleClose(reload: boolean = false ) {
    if (typeof onClose === 'function') {
      onClose(reload);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        {!_.isEmpty(editData) ? "Edit" : "Add"} User
      </DialogTitle>
      <DialogContent>
        <UserInfo 
            onClose={(reaload: boolean) => {
              handleClose(reaload)
            }}
            editData={editData}
            defaultValues={defaultValues}
            userModule={userModule}
        />
      </DialogContent>
    </Dialog>
  );
}

export default UserForm
