import { action, thunk, useStoreActions } from "easy-peasy";
import {getSystemLists, getSystemListValuesList} from "../api/system";


const defaultStore = {
    
    lists: [],
    updateLists: action((state, lists) => {
        state.lists = lists;
    }),
    fetchLists: thunk(actions => {
        return getSystemLists()
        .then(lists => {
            actions.updateLists(lists)
        });
    }),

    values: {
        neighborhoods: ["Boro Park", "Williamsburg"],
        neighborhoodsIsFetched: false,
        updateNeighborhoodsIsFetched: action((state) => {state.neighborhoodsIsFetched = true}),
        updateNeighborhoods: action((state, neighborhoods) => {state.neighborhoods = neighborhoods}),
        fetchNeighborhoods: thunk((actions, payload, {getState}) => {
            //fetch the lists
            //find the neighborhoods list
            //get the id
            //get the values by using the id
            //filter the values to only get the names
            //update the neighborhoods to conain all neccessary names
            let neighborhoodValueId;
            let neighborhoodNames = [];
            getSystemLists()
            .then(lists => {
                actions.updateLists(lists)
            })
            .then(() => {
                let lists = getState().lists;
                lists.forEach(list => {
                    if(list.listName === "Neighborhoods"){
                        neighborhoodValueId = list.id;
                    }
                });
            })
            .then(() => {
                //fetch the values by id
                //create array that only contains the names of the values, but not the descrition
                //finally, update the neigborhoods with those values
                getSystemListValuesList(neighborhoodValueId).then((values) => {
                    values.forEach(value => {
                        neighborhoodNames.push(value.value);
                    });
                })
                .then(() => {actions.updateNeighborhoods(neighborhoodNames)});
                
            });
        }),
        // specialties
        specialties: ["Boro Park", "Williamsburg"],
        specialtiesIsFetched: false,
        updatespecialtiesIsFetched: action((state) => { state.specialtiesIsFetched = true }),
        updateSpecialties: action((state, specialties) => { state.specialties = specialties }),
        fetchSpecialties: thunk((actions, payload, { getState }) => {
            //fetch the lists
            //find the specialties list
            //get the id
            //get the values by using the id
            //filter the values to only get the names
            //update the specialties to contain all neccessary names
            let specialtyValueId;
            let specialtiesNames = [];
            getSystemLists()
                .then(lists => {
                    actions.updateLists(lists)
                })
                .then(() => {
                    let lists = getState().lists;
                    lists.forEach(list => {
                        if (list.listName.toLowerCase() === "employee_specialties") {
                            specialtyValueId = list.id;
                        }
                    });
                })
                .then(() => {
                    //fetch the values by id
                    //create array that only contains the names of the values, but not the descrition
                    //finally, update the neigborhoods with those values
                    getSystemListValuesList(specialtyValueId).then((values) => {
                        values.forEach(value => {
                            specialtiesNames.push(value.value);
                        });
                    })
                        .then(() => { actions.updateSpecialties(specialtiesNames) });

                });
        }),
        // professional types
        professionalTypes: ["Therapy", "Keriah"],
        professionalTypesIsFetched: false,
        updateprofessionalTypesIsFetched: action((state) => { state.professionalTypesIsFetched = true }),
        updateProfessionalTypes: action((state, professionalTypes) => { state.professionalTypes = professionalTypes }),
        fetchProfessionalTypes: thunk((actions, payload, { getState }) => {
            //fetch the lists
            //find the professionalTypes list
            //get the id
            //get the values by using the id
            //filter the values to only get the names
            //update the professionalTypes to contain all neccessary names
            let professionalTypeValueId;
            let professionalTypesNames = [];
            getSystemLists()
                .then(lists => {
                    actions.updateLists(lists)
                })
                .then(() => {
                    let lists = getState().lists;
                    lists.forEach(list => {
                        if (list.listName.toLowerCase() === "professional_types") {
                            professionalTypeValueId = list.id;
                        }
                    });
                })
                .then(() => {
                    //fetch the values by id
                    //create array that only contains the names of the values, but not the descrition
                    //finally, update the neigborhoods with those values
                    getSystemListValuesList(professionalTypeValueId).then((values) => {
                        values.forEach(value => {
                            professionalTypesNames.push(value.value);
                        });
                    })
                        .then(() => { actions.updateProfessionalTypes(professionalTypesNames) });

                });
        }),
        updateLists: action((state, lists) => {
            state.lists = lists;
        }),
    },
};
export default defaultStore;