import React, { useState } from "react";
import clsx from "clsx";
import { listsColumns, listValueColumns } from "../../components/data/clients";
import Button from "@mui/material/Button";
import DataTable from "../../components/DataTable/DataTable";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import { Input } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Drawer from "@mui/material/Drawer";
import { useEffect } from "react";
import DlgAddUser from "../../components/explorer/Tabs/Dialog/AddUser";
import * as constant from "../../constants";
import authHeader from "../../screens/_helper/auth-header";
import { toast } from "react-toastify";
import useStyles from "./styles";
import baseStyles from '../../assets/styles'
import {
    getSystemLists,
    getSystemListDetail,
    saveSystemList,
    getSystemListValuesList,
    saveSystemListValuesList
} from "../../api/system";
import AddList from "../dialogs/AddList";
//import EditableTable from "../../components/DataTable/Editable";
// const EditTable = require('material-ui-table-edit');
import _ from "lodash";
import { useStoreState, useStoreActions } from "easy-peasy";
import useUpdateEffect from "../../utils/useUpdateEffect";

const drawerWidth = 240;

export default function Users(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [rows, setRows] = React.useState([]);
    const [listValues, setListValues] = React.useState([]);
    const [listSelectedId, setListSelectedId] = React.useState(0);
    const [isSaveValueEnabled, setIsSaveValueEnabled] = React.useState(false);
    const columns = listsColumns;
    const [query, setQuery] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [editUserData, setEditUserData] = React.useState(null);
    const [type, setType] = React.useState(null);

    const [editData, setEditData] = React.useState("");
    const [editDataList, setEditDataList] = React.useState("");
    const [isOpenAddList, setIsOpenAddList] = React.useState(false);

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const lists = useStoreState(state => state.list.lists);
    const fetchLists = useStoreActions(actions => actions.list.fetchLists);

    const _setRowAndColumn = () => {
        return {
            rows: setDataOfFilter(lists),
            columns: listsColumns,
            screen: "system_lists",
        };
    };

    useEffect(() => {
        if (lists.length < 1) {
            setLoading();
            fetchLists().then(() => {
                endLoading();
            });
        }
    }, []);







    const _setRowAndColumnListValue = () => {
        return {
            rows: setDataOfFilter(listValues),
            columns: listValueColumns,
            screen: "system_listvalues",
        };
    };

    const setDataOfFilter = (rows) => {
        return rows;
        return rows
            .filter
            //(row) => row.user_name.toLowerCase().indexOf(query.toLowerCase()) > -1
            ();
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleNewUser = () => {
        setIsOpenAddList(true);
        //handleDrawerOpen();
        //setEditUserData(null);
        setEditDataList(null);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (lists.length > 0) {//only fetch if there are elements in the list, if there are no elemnts it is being fetched in a different useEffect
            fetchLists();//reLoadData();

        }
    }, [openModal, isOpenAddList]);



    const reLoadData = () => {
        setListSelectedId(0);

        setLoading();
        getSystemLists()
            .then((data) => {
                setRows(data);

                return data;
            })
            .then((data) => {
                if (data.length == 0) return;
                initListValues(data[0].id);
                setListSelectedId(data[0].id);
            });
    };
    const initListValues = (id) => {
        getSystemListValuesList(id).then((data) => {
            if (data.length == 0) {
                setListValues([
                    {
                        listId: id,
                        id: 0,
                        value: "",
                        valueDescription: "",
                    },
                ]);
            }
            else {

                let tempValues = data;
                let length = tempValues.length;
                let lastRow = tempValues[length - 1];
                if (lastRow.value != "" || lastRow.valueDescription != "") {
                    tempValues.push({
                        listId: id,
                        id: 0,
                        value: "",
                        valueDescription: "",
                    });
                    setListValues(tempValues);
                }
                else
                    setListValues(tempValues);
            }
            endLoading();
        });
    };

    const handleFields = (event, index) => {
        const { name, value, type } = event.target;
        let tempValues = _.cloneDeep(listValues);
        tempValues[index][name] = value;
        let length = tempValues.length;
        let lastRow = tempValues[length - 1];
        if (lastRow.value != "" || lastRow.valueDescription != "")
            tempValues.push({
                listId: listSelectedId,
                id: 0,
                value: "",
                valueDescription: "",
            });
        setListValues(tempValues);
        setIsSaveValueEnabled(true);
    };
    const CompFormInput = (name, index) => {
        return (
            <div className="w-100 pl-3 pr-3">
                <Input
                    className="w-100"
                    value={listValues[index] && listValues[index][name]}
                    name={name}
                    onChange={(event) => {
                        handleFields(event, index);
                    }}
                />
            </div>
        );
    };

    const CompFormInput1 = (index) => CompFormInput("value", index);
    const CompFormInput2 = (index) => CompFormInput("valueDescription", index);

    const CompEditableTable = () => {

        return (
            <table className="w-100">
                <thead>
                    <tr style={{ height: 40, backgroundColor: "#1976d2", color: "white" }} className={baseClasses.tableHeaderFont}>
                        {listValueColumns.map((item, index) => (
                            <th style={{ paddingLeft: 17 }} key={index}>{item.headerName}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {listValues.map((item, index) => (
                        <tr style={{ height: 40 }} key={"item-" + index} className={baseClasses.tableBodyFont}>
                            <td>{CompFormInput1(index)}</td>
                            <td>{CompFormInput2(index)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const onSaveNewValue = () => {
        setIsSaveValueEnabled(false);
        saveSystemListValuesList(listValues).then(response => global.log(response));
    };

    const variableCompEditableTable = CompEditableTable();
    return (
        <>
            <main className={classes.content}>
                <div className={baseClasses.toolbar} />
                <div className="row">
                    <p style={{ color: "red" }}>{"WARNING!!! use this page ONLY to add new items to a list, if a name of an old item is changed it could affect the filters, if there is a need to change an existing name please contact customer support"}</p>

                    <div className="col-md-6 mb-3">
                        <div
                            className="row p-2"
                            style={{
                                display: "flex",
                                alignItems: "normal",
                                flexDirection: "column",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleNewUser}
                                className={baseClasses.new_button}
                                color="primary"
                            >
                                Add List Item
                            </Button>
                        </div>
                        <div className="row p-2">
                            <Card className=" w-100">
                                <CardContent className="p-0">
                                    <DataTable
                                        title="Lists"
                                        data={_setRowAndColumn()}
                                        onOpen={() => setIsOpenAddList(true)}
                                        onEdit={(params) => { setEditDataList(params.row) }}
                                        onClick={(id) => { initListValues(id); setListSelectedId(id); global.log('the list from the click', lists) }}
                                        multiView
                                    />
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 pl-3 pr-3" style={{ marginTop: 0 }}>
                        <div
                            className="row  mb-0 p-2"
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                flexDirection: "column",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={onSaveNewValue}
                                className={baseClasses.new_button}
                                color="primary"
                                disabled={!isSaveValueEnabled}
                            >
                                Save value
                            </Button>
                        </div>
                        <div className="row p-2">
                            <Card className=" w-100">
                                <CardContent className="p-0 pt-0 pb-3">
                                    {variableCompEditableTable}
                                    {/* <EditTable
        // onChange={onChange}
        rows={listValues}
        headerColumns={listValueColumns}
        enableDelete={true}
      /> */}
                                    {/* <DataTable
                    height={"419px"}
                    data={_setRowAndColumnListValue()}
                    onOpen={() => setOpenModal(true)}
                    onEdit={(params) => setEditData(params.row)}
                  /> */}
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
          <div className="col-md-4">
            
          </div>
          <div className="col-md-8">
            <Card>
              <CardContent className="p-0">
                <DataTable
                  data={_setRowAndColumn()}
                  onOpen={() => setOpenModal(true)}
                  onEdit={(params) => setEditData(params.row)}
                />
              </CardContent>
            </Card>
          </div> */}
            </main>
            {isOpenAddList && (
                <AddList
                    openModal={true}
                    editData={editDataList}
                    onCloseModal={() => {
                        setIsOpenAddList(false);
                    }}
                ></AddList>
            )}
        </>
    );
}
