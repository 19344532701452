import React from 'react'
import TaskIcon from '@mui/icons-material/Task';
import PeopleIcon from '@mui/icons-material/People';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import EmailIcon from '@mui/icons-material/Email';

import {notificationType} from '../../constants'
const NotificationIcons = ({type}) => {

  if (type === notificationType.task) {
     return  <TaskIcon  fontSize="small"/>
  }
  if (type === notificationType.appointment) {
     return  <PeopleIcon fontSize="small"/>
  }
  if (type === notificationType.meeting) {
     return  <MeetingRoomIcon fontSize="small"/>
  }
  if (type === notificationType.invitation) {
     return  <EmailIcon  fontSize="small"/>
  }
  return <div></div>
}

export default NotificationIcons