import React, { useEffect } from "react";
import {
    withStyles,
    makeStyles,
    useTheme,
} from "@mui/styles";
import moment from "moment";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    Typography,
} from "@mui/material";
import { FormControl, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from "@mui/material/Button";
import useStyles from "./styles";
import DataTable from "../../components/DataTable/DataTable";
import { yeshivas, yeshivasDetails } from "../../api/yeshivas";
import EditClientPage from "../client/edit_client/edit_client";
import clsx from "clsx";
import FRONTEND_ROUTE_CONSTANTS from '../../frontend_route_constants'
import * as constant from "../../constants";
import authHeader from "../_helper/auth-header";

import DlgAddYeshiva from "../../components/explorer/Tabs/Dialog/AddYeshiva";
import DlgCreateNewContract from "../../components/ContactSelect/PanelNewContact";
import usePermission from "../../hooks/usePermission";
import SearchInput from '../../components/common/SearchInput/SearchInput'
import { useStoreState, useStoreActions } from "easy-peasy";
import baseStyles from '../../assets/styles'

import { toast } from "react-toastify";
let programs = false;

export default function Yeshivas(props) {
    const classes = useStyles();
    const history = useStoreState((state) => state.history);
    const baseClasses = baseStyles();
    const [data, setData] = React.useState([]);
    // const [query, setQuery] = React.useState(""); Commenting For now, in future we might need it
    const [rows, setRow] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [loader, setLoader] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [editData, setEditData] = React.useState([]);

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("sm");
    const yeshivas = useStoreState(state => state.yeshiva.yeshivas);
    const query = useStoreState((state) => state.yeshiva.query);
    const setQuery = useStoreActions((actions) => actions.yeshiva.setQuery);
    const yeshivasListFiltered = useStoreState(state => state.yeshiva.yeshivasListFiltered);
    const fetchYeshivas = useStoreActions(actions => actions.yeshiva.fetchYeshivas);
    const setFilteredYeshivas = useStoreActions(actions => actions.yeshiva.setFilteredYeshivas);
    const setExploringType = useStoreActions((actions) => actions.setExploringType)
    const currentPage = useStoreState((state) => state.yeshiva.currentPage);
    const setCurrentPage = useStoreActions((actions) => actions.yeshiva.setCurrentPage)
    // set the new yeshivas form control
    const [first_name, setFirstName] = React.useState("");
    const [last_name, setLastName] = React.useState("");
    const [herb_first_name, setHerbFirstName] = React.useState("");
    const [herb_last_name, setHerbLastName] = React.useState("");
    const [legal_first_name, setLegalFirstName] = React.useState("");
    const [legal_last_name, setLegalLastName] = React.useState("");
    const [legal_middle_name, setLegalMiddleName] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [apt, setApt] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zipcode, setZipcode] = React.useState("");
    const [email1, setEmail1] = React.useState("");
    const [email2, setEmail2] = React.useState("");
    const [phone1, setContactPhone1] = React.useState("");
    const [phone2, setContactPhone2] = React.useState("");
    const [birth_date, setBirthDate] = React.useState(
        global._DateField(new Date())
    );
    const [selectedId, setSelectedId] = React.useState(null);
    const [age, setAge] = React.useState("");

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const setPreviousPath = useStoreActions(
        (actions) => actions.setPreviousPath
    );
    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const exploringMode = useStoreState((state) => state.exploringMode);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const { canView, canRead, canCreate, canEdit, canDelete } = usePermission(global.permissions.yeshivas);

    useEffect(() => {
        if (yeshivas.length < 1) {
            fetchYeshivas();
        }
    }, []);

    useEffect(() => {
        yeshivasListFiltered.length < 1 && setFilteredYeshivas(yeshivas);
    }, [yeshivas])

    useEffect(() => {
        setFilteredYeshivas(setDataOfFilter(yeshivas))
    }, [query])

    const handleClickOpen = () => {
        setOpen(true);
        // setOpenEditModal(true);

        //setOpen(true);
        // if (id === selectedId) {
        //   getDetailsOfYeshivas();
        // }
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Errors selection
    const [firstNamePerError, setFirstNamePerError] = React.useState("");
    const [lastNamePerError, setLastNamePerError] = React.useState("");
    const [herbFirstNamePerError, setHerbFirstNamePerError] = React.useState("");
    const [herbLastNamePerError, setHerbLastNamePerError] = React.useState("");
    const [legalFirstNamePerError, setLegalFirstNamePerError] = React.useState("");
    const [legalLastNamePerError, setLegalLastNamePerError] = React.useState("");
    const [legalMiddleNamePerError, setLegalMiddleNamePerError] = React.useState("");
    const [ageError, setAgeError] = React.useState("");
    const [id, setId] = React.useState(null);
    const [loaderDialog, setLoaderDialog] = React.useState(false);

    const [popUp, setPopUp] = React.useState(false);

    useEffect(() => {
        if (id) {
            setLoaderDialog(true);
            let detailsOfYeshivas = null;
            let contactDetailsOfYeshivas = null;
            yeshivasDetails(id).then((response) => {
                //global.log(response);
                detailsOfYeshivas = response[0];
                contactDetailsOfYeshivas = response[0].contact;
                setEditData(contactDetailsOfYeshivas);
                // global.log("response", detailsOfYeshivas);
                setFirstName(contactDetailsOfYeshivas.firstName);
                setLastName(contactDetailsOfYeshivas.lastName);
                setHerbFirstName(contactDetailsOfYeshivas.firstNameHebrew);
                setHerbLastName(contactDetailsOfYeshivas.lastNameHebrew);
                setLegalFirstName(contactDetailsOfYeshivas.firstNameLegal);
                setLegalMiddleName(contactDetailsOfYeshivas.lastNameLegal);
                setLegalLastName(contactDetailsOfYeshivas.middleNameLegal);
                setAddress(contactDetailsOfYeshivas.address1);
                setApt(contactDetailsOfYeshivas.address2);
                setState(contactDetailsOfYeshivas.state);
                setZipcode(contactDetailsOfYeshivas.zip);
                setCity(contactDetailsOfYeshivas.city);
                setBirthDate(global._DateField(contactDetailsOfYeshivas.dob));
                setContactPhone1(contactDetailsOfYeshivas.phone1);
                setContactPhone2(contactDetailsOfYeshivas.phone2);
                setEmail1(contactDetailsOfYeshivas.email1);
                setEmail2(contactDetailsOfYeshivas.email2);
                setAge(contactDetailsOfYeshivas.age);
                setLoaderDialog(false);
            });
        } else {
            resetAllData();
        }
    }, [openEditModal]);

    const getDetailsOfYeshivas = () => {
        setLoaderDialog(true);
        let detailsOfYeshivas = null;
        let contactDetailsOfYeshivas = null;
        yeshivasDetails(id).then((response) => {
            detailsOfYeshivas = response[0];
            contactDetailsOfYeshivas = response[0].contact;
            // global.log("response", detailsOfYeshivas);
            setFirstName(contactDetailsOfYeshivas.firstName);
            setLastName(contactDetailsOfYeshivas.lastName);
            setHerbFirstName(contactDetailsOfYeshivas.firstNameHebrew);
            setHerbLastName(contactDetailsOfYeshivas.lastNameHebrew);
            setLegalFirstName(contactDetailsOfYeshivas.firstNameLegal);
            setLegalMiddleName(contactDetailsOfYeshivas.lastNameLegal);
            setLegalLastName(contactDetailsOfYeshivas.middleNameLegal);
            setAddress(contactDetailsOfYeshivas.address1);
            setApt(contactDetailsOfYeshivas.address2);
            setState(contactDetailsOfYeshivas.state);
            setZipcode(contactDetailsOfYeshivas.zip);
            setCity(contactDetailsOfYeshivas.city);
            setBirthDate(global._DateField(contactDetailsOfYeshivas.dob));
            setContactPhone1(contactDetailsOfYeshivas.phone1);
            setContactPhone2(contactDetailsOfYeshivas.phone2);
            setEmail1(contactDetailsOfYeshivas.email1);
            setEmail2(contactDetailsOfYeshivas.email2);
            setAge(contactDetailsOfYeshivas.age);
            setLoaderDialog(false);
        });
    };

    function resetAllData() {
        setFirstName("");
        setLastName("");
        setHerbFirstName("");
        setHerbLastName("");
        setLegalFirstName("");
        setLegalMiddleName("");
        setLegalLastName("");
        setAddress("");
        setCity("");
        setAge("");
        setApt("");
        setBirthDate(global._DateField(new Date()));
        setContactPhone1("");
        setContactPhone2("");
        setState("");
        setZipcode("");
        setEmail1("");
        setEmail2("");

        // reset all error
        setFirstNamePerError("");
        setLastNamePerError("");
        setHerbFirstNamePerError("");
        setHerbLastNamePerError("");
        setLegalFirstNamePerError("");
        setLegalLastNamePerError("");
        setLegalMiddleNamePerError("");
        setAgeError("");
    }

    const setDataOfFilter = (rows) => {
        return rows.filter(
            (row) =>
                row.name?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row.id?.toString().trim().indexOf(query.trim().toLowerCase()) > -1
        );
    };

    const _setRowAndColumn = () => {
        return {
            rows: yeshivasListFiltered,
            columns: columns,
            screen: "yeshivas",
        };
    };

    const openAddYeshivaModal = () => {
        history(`${FRONTEND_ROUTE_CONSTANTS.YESHIVA_ROUTE}${-1}${FRONTEND_ROUTE_CONSTANTS.MANAGE}`)
        setExploringType("yeshiva");
        setExploringMode("edit");
        setExploringId(-1);
        setExploringName("New Yeshiva");
        setPreviousPath("FROM YESHIVA LIST");
    };

    const saveYeshivasContact = (contact, name) => {
        const saveYeshivaPayload = {
            contactId: 0,//contact.id,
            name: name,
            id: 0,
            createdOn: new Date().toISOString(),
            isDeleted: false,
        };
        // global.log("submit data", saveYeshivaPayload, id);
        // return;
        let submitId = -1;
        if (id) {
            submitId = id.id;
        } else {
            submitId = -1;
        }

        return global.fetchWrapper
            .post(constant.SAVE_YESHIVA_CONTACT + submitId, saveYeshivaPayload, {
                headers: authHeader(),
            })
            .then(
                (response) => {
                    if (response.responseCode === 200) {
                        handleClose();
                        // resetAllData();
                        fetchYeshivas();
                    }
                },
                (error) => {
                    global.log("error", error);
                    global.AjaxFailToast();
                }
            );
    };

    const saveYeshivas = () => {
        const isValid = formValidationPersonalDetails();
        if (isValid) {
            const saveYeshivaPayload = {
                id: 0,
                createdOn: new Date().toISOString(),
                isDeleted: false,
                firstName: first_name,
                lastName: last_name,
                firstNameHebrew: herb_first_name,
                lastNameHebrew: herb_last_name,
                firstNameLegal: legal_first_name,
                lastNameLegal: legal_last_name,
                middleNameLegal: legal_middle_name,
                address1: address,
                address2: apt,
                city: city,
                state: state,
                zip: zipcode,
                phone1: phone1,
                phone2: phone2,
                email1: email1,
                email2: email2,
                dob: new Date(birth_date).toISOString(),
                age: age,
                ssn: 0,
                name: first_name + " " + last_name,
                contactId: 0,
            };
            global.log("submit data", saveYeshivaPayload, id);
            let submitId = -1;
            if (id) {
                submitId = id;
            } else {
                submitId = -1;
            }
            // if ()
            return global.fetchWrapper
                .post(constant.SAVE_YESHIVA + submitId, saveYeshivaPayload, {
                    headers: authHeader(),
                })
                .then(
                    (response) => {
                        if (response.responseCode === 200) {
                            handleClose();
                            // resetAllData();
                            fetchYeshivas();
                        }
                    },
                    (error) => {
                        global.log("error", error);
                    }
                );
        }
    };

    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (first_name === "") {
            isValid = false;
            setFirstNamePerError("Please enter first name");
        }
        if (last_name === "") {
            isValid = false;
            setLastNamePerError("Please enter last name");
        }
        if (herb_first_name === "") {
            isValid = false;
            setHerbFirstNamePerError("Please enter herbrew first name");
        }
        if (herb_last_name === "") {
            isValid = false;
            setHerbLastNamePerError("Please enter herbrew last name");
        }
        if (legal_first_name === "") {
            isValid = false;
            setLegalFirstNamePerError("Please enter legal first name");
        }
        if (legal_middle_name === "") {
            isValid = false;
            setLegalMiddleNamePerError("Please enter legal middle name");
        }
        if (legal_last_name === "") {
            isValid = false;
            setLegalLastNamePerError("Please enter legal last name");
        }
        if (age === "") {
            isValid = false;
            setAgeError("Please enter age");
        }
        return isValid;
    };

    const handleSearch = (searchTerm) => {
        setQuery(searchTerm)
    }

    const onSortingRows = (rows) => {
        setFilteredYeshivas(rows)
    }

    const options = {
        page: currentPage,
        onPageChange: (newPage) => {
            setCurrentPage(newPage)
        },
    }

    return (
        <main className={classes.content}>
            <div className={baseClasses.toolbar} />
            <div>
                <div>
                    <div className={baseClasses.row}>
                        <SearchInput query={query} getSearchQuery={handleSearch} />
                    </div>
                    <div className={baseClasses.rowFlex}>
                        <div className={baseClasses.grow}></div>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={openAddYeshivaModal}
                        >
                            <b style={{ fontSize: "14px", paddingRight: "4px" }}>
                                {"New  "}</b> <AddRoundedIcon fontSize="small" />
                        </Button>
                    </div>
                </div>
                <Paper>
                    {(yeshivas.length < 1) ? (
                        <div className={baseClasses.loading}>Loading....</div>
                    ) : (
                        <DataTable
                            title="Yeshivas"
                            setSelectedId={setSelectedId}
                            onEdit={(params) => {
                                const id = params.row.id;
                                history(FRONTEND_ROUTE_CONSTANTS.YESHIVA_ROUTE + id + FRONTEND_ROUTE_CONSTANTS.MANAGE)
                                setExploringId(id);
                                setExploringMode("edit");
                                setExploringName(params.row.name)
                                setPreviousPath("FROM YESHIVAS LIST");
                            }}
                            onSortingRows={onSortingRows}
                            options={options}
                            onOpen={handleClickOpen}
                            data={_setRowAndColumn()}
                            onDelete={(params) => {
                                let id = params.row.id;
                                setId(id);
                                setPopUp(true);
                            }}
                            permissions={global.permissions.yeshivas}
                            canEdit={(params) => canEdit}
                            canDelete={(params) => canDelete}
                            multiView
                        />
                    )}
                </Paper>
            </div>

            {
                open && (
                    <DlgAddYeshiva
                        title={"Add Yeshiva"}
                        subtitle={"Please select a contact below for yeshiva"}
                        openModal={true}
                        handleClose={handleClose}
                        // editData={id == null ? id : id.contactId}
                        editData={id == null ? id : id}
                        addYeshiva={(contact, name) => {
                            saveYeshivasContact(contact, name);
                        }}
                    ></DlgAddYeshiva>
                )
            }

            {
                openEditModal && (
                    <Dialog
                        fullWidth={true}
                        maxWidth={"sm"}
                        open={true}
                        onClose={() => {
                            setOpenEditModal(false);
                        }}
                        aria-labelledby="max-width-dialog-title"
                    // style={{padding:20}}
                    >
                        <div style={{ padding: 20 }}>
                            <DlgCreateNewContract
                                editData={editData}
                                handleClose={() => {
                                    setOpenEditModal(false);
                                }}
                                handleAdded={() => {
                                    setOpenEditModal(false);
                                    fetchYeshivas()
                                }}
                            ></DlgCreateNewContract>
                        </div>
                    </Dialog>
                )
            }

            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={false}
                // open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle
                    className={baseClasses.dialogTitle}
                    id="max-width-dialog-title"
                >
                    Add Yeshivas
                </DialogTitle>
                <DialogContent>
                    {loaderDialog ? (
                        <div>Loading...</div>
                    ) : (
                        <div>
                            <Grid
                                container
                                className={baseClasses.grid}
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">First Name</InputLabel>
                                            <Input
                                                value={first_name}
                                                id="my-input"
                                                onChange={(event) => setFirstName(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {firstNamePerError === "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {firstNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Last Name</InputLabel>
                                            <Input
                                                value={last_name}
                                                id="my-input"
                                                onChange={(event) => setLastName(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {lastNamePerError === "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {lastNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Hebrew First Name
                                            </InputLabel>
                                            <Input
                                                value={herb_first_name}
                                                id="my-input"
                                                onChange={(event) =>
                                                    setHerbFirstName(event.target.value)
                                                }
                                                aria-describedby="my-helper-text"
                                            />
                                            {herbFirstNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {herbFirstNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Hebrew Last Name
                                            </InputLabel>
                                            <Input
                                                value={herb_last_name}
                                                onChange={(event) =>
                                                    setHerbLastName(event.target.value)
                                                }
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {herbLastNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {herbLastNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Legal First Name
                                            </InputLabel>
                                            <Input
                                                value={legal_first_name}
                                                onChange={(event) =>
                                                    setLegalFirstName(event.target.value)
                                                }
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {legalFirstNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {legalFirstNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Legal Last Name
                                            </InputLabel>
                                            <Input
                                                value={legal_last_name}
                                                onChange={(event) =>
                                                    setLegalLastName(event.target.value)
                                                }
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {legalLastNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {legalLastNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">
                                                Legal Middle Name
                                            </InputLabel>
                                            <Input
                                                value={legal_middle_name}
                                                onChange={(event) =>
                                                    setLegalMiddleName(event.target.value)
                                                }
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {legalMiddleNamePerError == "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {legalMiddleNamePerError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Address 1</InputLabel>
                                            <Input
                                                value={address}
                                                id="my-input"
                                                onChange={(event) => setAddress(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Apt</InputLabel>
                                            <Input
                                                value={apt}
                                                id="my-input"
                                                onChange={(event) => setApt(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">City</InputLabel>
                                            <Input
                                                value={city}
                                                id="my-input"
                                                onChange={(event) => setCity(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">State</InputLabel>
                                            <Input
                                                value={state}
                                                id="my-input"
                                                onChange={(event) => setState(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Zip</InputLabel>
                                            <Input
                                                value={zipcode}
                                                id="my-input"
                                                onChange={(event) => setZipcode(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Email 1</InputLabel>
                                            <Input
                                                value={email1}
                                                id="my-input"
                                                onChange={(event) => setEmail1(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {/* {herbFirstNamePerError == "" ? null : (
                      <FormHelperText
                        className={baseClasses.error}
                        id="my-helper-text"
                      >
                        {herbFirstNamePerError}
                      </FormHelperText>
                    )} */}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Email 2</InputLabel>
                                            <Input
                                                value={email2}
                                                onChange={(event) => setEmail2(event.target.value)}
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                            />
                                            {/* {herbLastNamePerError == "" ? null : (
                      <FormHelperText
                        className={baseClasses.error}
                        id="my-helper-text"
                      >
                        {herbLastNamePerError}
                      </FormHelperText>
                    )} */}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Phone 1</InputLabel>
                                            <Input
                                                value={phone1}
                                                id="my-input"
                                                type="number"
                                                inputProps={{ min: "0" }}
                                                onChange={(event) =>
                                                    setContactPhone1(event.target.value)
                                                }
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Phone 2</InputLabel>
                                            <Input
                                                value={phone2}
                                                id="my-input"
                                                type="number"
                                                inputProps={{ min: "0" }}
                                                onChange={(event) =>
                                                    setContactPhone2(event.target.value)
                                                }
                                                aria-describedby="my-helper-text"
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="my-input">Age</InputLabel>
                                            <Input
                                                value={age}
                                                id="my-input"
                                                type="number"
                                                inputProps={{ min: "0" }}
                                                onChange={(event) => setAge(event.target.value)}
                                                aria-describedby="my-helper-text"
                                            />
                                            {ageError === "" ? null : (
                                                <FormHelperText
                                                    className={baseClasses.error}
                                                    id="my-helper-text"
                                                >
                                                    {ageError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={baseClasses.paddingTop16}>
                                        <FormControl fullWidth>
                                            {/* <InputLabel htmlFor="my-input">Date Of Birth</InputLabel> */}
                                            <TextField
                                                id="date"
                                                type="date"
                                                value={birth_date}
                                                onChange={(event) => setBirthDate(event.target.value)}
                                                className={baseClasses.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveYeshivas} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                // fullScreen={fullScreen}
                open={popUp}
                onClose={() => setPopUp(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to cancel your changes?"}
                </DialogTitle>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => {
                            setPopUp(false);
                            setLoading()
                            return global.fetchWrapper.post(constant.DELETE_YESHIVA + id).then(
                                (response) => {
                                    setId(null);
                                    if (response.responseCode === 200 || !!response) {
                                        endLoading()
                                        fetchYeshivas();
                                    }
                                },
                                (error) => {
                                    setId(null);
                                    endLoading()
                                    global.AjaxFailToast();
                                }
                            );
                        }}
                        color="primary"
                    >
                        Yes
                    </Button>
                    <Button onClick={() => { setPopUp(false) }} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </main >
    );
}
