import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {FlexExactCenter, FlexColExactCenter, Text} from '../../assets/styles/styledComponents';
import { IconButton } from '@mui/material';
const NotFound = (props: any) => {
    const { type } = props;
  return (
    <FlexExactCenter style={{height: '100vh'}}>
        <FlexColExactCenter>
            <IconButton>
                <ErrorOutlineOutlinedIcon color="error" fontSize="large"/>
            </IconButton>
              {type !== 'restricted' && (<h1>Page Not Found</h1>)}
              <Text>{type === 'restricted' ?
                  "You don't have access to this page, please contact your adminastrator" :
                  'The page you looking for is not available'}
              </Text>
        </FlexColExactCenter>
    </FlexExactCenter>
  )
}

export default NotFound