import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const DAYS = [
    { key: "1", label: "1" },
    { key: "2", label: "2" },
    { key: "3", label: "3" },
    { key: "4", label: "4" },
    { key: "5", label: "5" },
    { key: "6", label: "6" },
    { key: "7", label: "7" },
    { key: "8", label: "8" },
    { key: "9", label: "9" },
    { key: "10", label: "10" },
    { key: "11", label: "11" },
    { key: "12", label: "12" },
    { key: "13", label: "13" },
    { key: "14", label: "14" },
    { key: "15", label: "15" },
    { key: "16", label: "16" },
    { key: "17", label: "17" },
    { key: "18", label: "18" },
    { key: "19", label: "19" },
    { key: "20", label: "20" },
    { key: "21", label: "21" },
    { key: "22", label: "22" },
    { key: "23", label: "23" },
    { key: "24", label: "24" },
    { key: "25", label: "25" },
    { key: "26", label: "26" },
    { key: "27", label: "27" },
    { key: "28", label: "28" },
    { key: "29", label: "29" },
    { key: "30", label: "30" },
    { key: "31", label: "31" },
];

const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
        margin: `${theme.spacing(1.5)} !important`,
        padding: `${theme.spacing(0, 1)} !important `,
        "&:not(:first-child)": {
            border: "1px solid",
            borderColor:  `${theme.palette.primary.main} !important`,
            borderRadius: "50% !important"
        },
        "&:first-child": {
            border: "1px solid",
            borderColor:  `${theme.palette.primary.main} !important`,
            borderRadius: "50% !important"
        }
    }
}))(ToggleButtonGroup);

const StyledToggle = withStyles(theme => ({
    root: {
        color: `${theme.palette.primary.main} !important`,
        "&$selected": {
            color: "white !important",
            background:  `${theme.palette.primary.main} !important`
        },
        "&:hover": {
            borderColor: "#d4dafc",
            background: "#d4dafc"
        },
        "&:hover$selected": {
            borderColor:  `${theme.palette.primary.main} !important`,
            background:  `${theme.palette.primary.main} !important`
        },
        minWidth: 25,
        maxWidth: 25,
        height: 25,
        textTransform: "unset",
        fontSize: "0.75rem",
        margin: '10 !important'
    },
    selected: {}
}))(ToggleButton);

const ToggleMonthDays = (props) => {
    const [daysCONST, setDaysCONST] = useState(props.days != undefined ? props.days.map(d => parseInt(d)) : []);
    const { setDays, errorText } = props;
    const curId = props.id + "-";
    return (
        <>
            <div id={props.id}>
                <StyledToggleButtonGroup
                    size="small" arial-label="Days of the month" value={daysCONST.filter(d => (Number.isInteger(d)))} onChange={(event, value) => { setDaysCONST(value); if (props.setDays != undefined) props.setDays(event, value); }}
                >
                    {DAYS.slice(0, 7).map((day, index) => (
                        <StyledToggle id={curId + "slcday-" + day.key} key={day.key} value={parseInt(day.key)} aria-label={day.key}>
                            {day.label}
                        </StyledToggle>
                    ))}
                </StyledToggleButtonGroup>
                <br></br>
                <StyledToggleButtonGroup
                    size="small" arial-label="Days of the month" value={daysCONST.filter(d => (Number.isInteger(d)))} onChange={(event, value) => { setDaysCONST(value); if (props.setDays != undefined) props.setDays(event, value); }}
                >
                    {DAYS.slice(7, 14).map((day, index) => (
                        <StyledToggle id={curId + "slcday-" + day.key} key={day.key} value={parseInt(day.key)} aria-label={day.key}>
                            {day.label}
                        </StyledToggle>
                    ))}
                </StyledToggleButtonGroup>
                <br></br>
                <StyledToggleButtonGroup
                    size="small" arial-label="Days of the month" value={daysCONST.filter(d => (Number.isInteger(d)))} onChange={(event, value) => { setDaysCONST(value); if (props.setDays != undefined) props.setDays(event, value); }}
                >
                    {DAYS.slice(14, 21).map((day, index) => (
                        <StyledToggle id={curId + "slcday-" + day.key} key={day.key} value={parseInt(day.key)} aria-label={day.key}>
                            {day.label}
                        </StyledToggle>
                    ))}
                </StyledToggleButtonGroup>
                <br></br>
                <StyledToggleButtonGroup
                    size="small" arial-label="Days of the month" value={daysCONST.filter(d => (Number.isInteger(d)))} onChange={(event, value) => { setDaysCONST(value); if (props.setDays != undefined) props.setDays(event, value); }}
                >
                    {DAYS.slice(21, 28).map((day, index) => (
                        <StyledToggle id={curId + "slcday-" + day.key} key={day.key} value={parseInt(day.key)} aria-label={day.key}>
                            {day.label}
                        </StyledToggle>
                    ))}
                </StyledToggleButtonGroup>
                <br></br>
                <StyledToggleButtonGroup
                    size="small" arial-label="Days of the month" value={daysCONST.filter(d => (Number.isInteger(d)))} onChange={(event, value) => { setDaysCONST(value); if (props.setDays != undefined) props.setDays(event, value); }}
                >
                    {DAYS.slice(28).map((day, index) => (
                        <StyledToggle id={curId + "slcday-" + day.key} key={day.key} value={parseInt(day.key)} aria-label={day.key}>
                            {day.label}
                        </StyledToggle>
                    ))}
                </StyledToggleButtonGroup>
            </div>
            {errorText && <div style={{color: 'red'}}>{errorText} </div>}
        </>
    );
};

export default ToggleMonthDays;
