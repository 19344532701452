import React, {useEffect, useState} from 'react';
import {Autocomplete, InputBase, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from '../../../hooks/useDebounce';
import { DivContainer } from '../../../assets/styles/styles';

type SearchInputProps = {
    getSearchQuery: Function,
    width?: string,
    autocomplete?: boolean,
    debounce?: number,
    isLoading?: boolean,
    searchResult?: any,
    setSearchResult?: any,
    module?: string,
    query?: string
}

const SearchInput = ({getSearchQuery, query="", width="230px", searchResult, setSearchResult, debounce = 500, isLoading, autocomplete=false}: SearchInputProps) => {
    const [searchTerm, setSearchTerm] = useState(query);

    useEffect(() => {
      setSearchTerm(query)
    }, [query])
    
    const debounceValue = useDebounce(searchTerm, debounce);
    
    useEffect(() => {
      getSearchQuery(debounceValue)
    }, [debounceValue])

    

  return (
    <Autocomplete
        fullWidth
        loading={isLoading}
        value={searchTerm}
        freeSolo
        sx={{
            width: width
        }}
        disableClearable
        id={`gloabal-search-${module}`}
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
                width: width,
                '& .MuiOutlinedInput-root': {
                  padding: '2px'
                },
                // '&.MuiInputLabel-root': {
                //     top: '-7px !important'
                // }
            }}
            size='small'
            onChange={(e) => {
                setSearchTerm(e.target.value);
            }}
            label={
              <DivContainer>
                  {<SearchIcon />}
                  Search
              </DivContainer>
            }
  
          />
        )}
      />
  )
}

export default SearchInput