import * as React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import {TableCell, TableCellProps} from '@mui/material'

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -30,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

export const StyledTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
      head: {
        backgroundColor: "#e2eefa",
        color: theme.palette.common.white,
        lineHeight: "0.4rem",
        fontSize: 12,
        minWidth: 20,
        padding: '10px 5px !important',
        overflow: "hidden",
        maxWidth: '70px',
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
  }));
export const StyledTableRowCell = styled(TableCell)<any>(({ theme }) => ({
    root: {
      padding: '5px 5px !important',
      fontSize: 12,
      width: 0,
      overflow: "hidden",
      maxWidth: '150px',
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
}));

export default makeStyles((theme) => ({
  accordionTable: {
      margin: '20px 0px'
      /* margin-left: 10px; */
  },
  table: {
    backgroundColor: "#e2eefa", 
  },
  completed: {
    background: (props:any) => props.complete ? 'green': ''
  }
}));