import React from "react";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';

const TInputExpiredDateForCreditCard = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##/##" 
      placeholder="MM/YY" 
      mask={['M', 'M', 'Y', 'Y']}
    />
  );
};

TInputExpiredDateForCreditCard.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TInputExpiredDateForCreditCard;
