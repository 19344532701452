import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select, FormLabel, FormGroup, FormControlLabel, Switch,
    Tooltip
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import _ from 'lodash'
import { useStoreActions, useStoreState } from "easy-peasy";
import { CLIENT_DETAILS_ROUTER, YESHIVAS_SCHEDULE_DETAILS_STATE } from '../../constants'
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { useTheme } from '@mui/styles';
import { ThemeProvider } from '@mui/styles';
import {
    columnsClientProgram,
    contactColumns, getClientDetails,
    getClientNotesList, getClientProgramDetail, getClientProgramTabDetail,
    getClientProgressDetail,
    getClientScheduleList, getClientToDoDetail, progressColumns,
    removeClientNote,
    removeClientTodo, saveClientToDo, scheduleColumns,
    todoColumns,
    GetClientServiceSchedules,
    GetClientProfessionalsSchedules,
    GetClientOneTimeAppointmentsSchedules,
    getClientDetailProgram,
    getClientDetailProgramLight,
} from "../../api/clients";
import { getYeshivasContacts, addYeshivasContacts, getYeshivaDetail, updateYeshivasSedar, addYeshivasSedar, getYeshivasSedar } from '../../api/yeshivas';
import { getContactRelDetail, removeContactRel, getDocumentTreeStructure, markTaskCompleted } from "../../api/cms";
import { getEmployeeDetails } from "../../api/employees";
import { yeshivasDetails } from "../../api/yeshivas";
import { getProviderSchedules, getAppointmentSchedules } from "../../api/schedules";
import {
    todoRow
} from "../data/clients";
import DataTable from "../DataTable/DataTable";
import useStyles from "./styles";
import baseStyles from '../../assets/styles';
import Client from "./Tabs/Client/client";
// import AddContact from "./Tabs/Dialog/AddContact";
import AddContact from "./Tabs/Dialog/AddContact";
import AddProgram from "./Tabs/Dialog/AddProgram";
import AddProgress from "./Tabs/Dialog/AddProgress";
import AddSchedule from "./Tabs/Dialog/AddSchedule";
//import AddToDo from "./Tabs/Dialog/AddToDo";
import DeletePopup from "./Tabs/Dialog/DeletePopup";
import ConfirmDialog from "../../components/common/ConfirmDialog/ConfirmDialog";
import Document from "./Tabs/Documents/document";
import General from "./Tabs/General/general";
import History from "./Tabs/History/history";
import Program from "./Tabs/Program/program";
import Contact from './Tabs/Contact'
import Schedule from "./Tabs/Schedule/schedule";
import MainSection from "../ClientSchedule/MainSection/MainSection";
import useUnsavedChangesWarn from "../../hooks/useUnsavedChangesWarn";
import usePermission from "../../hooks/usePermission";
import ToDo from './Tabs/ToDo/ToDo';
import Progress from './Tabs/Progress'
import { tabsConst, weekDays, explorerTypesConst, SCHEDULE_TYPE } from '../../constants';
import { TabContent } from "reactstrap";
import { progress } from "../data/ClientDetails";
import { periodicities } from "../../_helpers/constants/scheduleConstants";
import Mission from "./Tabs/Progress/Mission";
import FRONTEND_ROUTE_CONSTANTS from '../../frontend_route_constants'
import NetAvailability from "./Tabs/Schedule/NetAvailability";
import Info from "./Tabs/Info";
import SederDetails from "../../screens/yeshivas/YeshivaManage/Sections/SederDetails";
import { FlexBetween, TextButton } from "../../assets/styles/styledComponents";
import FeaturedIcon from "../common/FeaturedIcon";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

export default function ExplorerComponent() {
    const { id } = useParams();
    const data = {};
    const theme = useTheme();
    const classes = useStyles();
    const baseClasses = baseStyles();
    const location = useLocation();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const [value, setValue] = React.useState("general");
    // Component States
    const [expanded, setExpanded] = React.useState(false);
    const [query, setquery] = React.useState("");
    const [isProgramTab, setProgramTab] = React.useState(true);
    const [isEmployee, setIsEmployee] = React.useState(false);
    const [dropDownScheduleType, setDropDownScheduleType] = React.useState(SCHEDULE_TYPE.yungerman);

    const [rows, setRows] = React.useState(todoRow);
    const [clientData, setClientData] = React.useState(null);
    const [clientProgramData, setClientProgramData] = React.useState(null);
    const [clientNotesData, setClientNotesData] = React.useState(null);
    const [clientScheduleData, setClientScheduleData] = React.useState(null);
    const [clientScheduleList, setClientScheduleList] = React.useState([]);
    const [clientToDoData, setClientToDoData] = React.useState(null);
    const [clientProgressData, setClientProgressData] = React.useState(null);
    const [clientContactData, setClientContactData] = React.useState([]);
    const [clientHistoryData, setClientHistoryData] = React.useState(null);
    const [clientTabProgramData, setClientTabProgramData] = React.useState(null);
    const [Data, setData] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [sedersDetail, setSedersDetail] = useState(YESHIVAS_SCHEDULE_DETAILS_STATE)
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("sm");
    const [scheduleType, setScheduleType] = React.useState("service");
    const [personName, setPersonName] = React.useState([]);
    const [schedulePeriod, setSchedulePeriod] = React.useState("daily");
    const [isOpenCreateModal, setIsOpenCreateModal] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [monthlyProgressDateRange, setMonthlyProgressDateRange] = React.useState();
    const [templateNotes, setTemplateNotes] = React.useState([]);
    const [loading, setLoading] = useState({});
    const [isOpenDeletePopup, setIsOpenDeletePopup] = React.useState(-1);
    const [tooltipData, setTooltipData] = React.useState("");
    //tooltip state
    const [isToolTip, setIsToolTip] = React.useState(null);
    //breadcrumb
    const [menuName, setMenuName] = React.useState("");
    // not really using it directly, but currently need to just update a local state which trigger a render, which takes the latest redux data
    const [dailySchedule, setDailySchedule] = useState();
    const [seekingSchedule, setSeekingSchedule] = useState();
    const [professionalSchedule, setProfessionalSchedule] = useState();
    const [appointmentSchedule, setAppointmentSchedule] = useState();
    const [slcRow, setSlcRow] = useState({});
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [isBottomDirtyState, setBottomDirtyState] = React.useState(false);
    const [progressPage, setProgressPage] = React.useState(tabsConst.all);
    const [progressNoteEditData, setProgressNoteEditData] = React.useState();
    // Redux States and Actions
    const history = useStoreState((state) => state.history);
    const programPermissions = usePermission(global.permissions.clients.programs);
    const clientInfoPermissions = usePermission(global.permissions.clients.info);
    const progressPermissions = usePermission(global.permissions.clients.progress);
    const missionPermissions = usePermission(global.permissions.clients.mission);
    const todosPermissions = usePermission(isEmployee ? global.permissions.employees.todos : global.permissions.clients.todos);
    const schedulePermissions = usePermission(isEmployee ? global.permissions.employees.schedules : global.permissions.clients.schedules);
    const historyPermissions = usePermission(isEmployee ? global.permissions.employees.history : global.permissions.clients.history);
    const contactsPermissions = usePermission(global.permissions.clients.contacts);
    const employeePermissions = usePermission(global.permissions.employees);
    const clientPermissions = usePermission(global.permissions.clients);
    const canAccessEnrollment = isEmployee ? employeePermissions.canEdit : clientPermissions.canEdit;
    //const value = useStoreState((state) => state.clients.activeTab);
    const setExploringType = useStoreActions(
        (actions) => actions.setExploringType
    );
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const setExploringContactId = useStoreActions(
        (actions) => actions.setExploringContactId
    );
    //const setActiveTab = useStoreActions((actions) => actions.clients.setActiveTab)
    const exploringType = useStoreState((state) => state.exploringType);
    const exploringId = useStoreState((state) => state.exploringId);
    setExploringId(id);

    const exploringName = useStoreState((state) => state.exploringName);
    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );
    const exploringMode = useStoreState((state) => state.exploringMode);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const setPreviousPath = useStoreActions(
        (actions) => actions.setPreviousPath
    );
    const setLoadingGlobal = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const setLoadingItem = (itemName, value) => {
        setLoading((prevState) => { return { ...prevState, [itemName]: value } });
    }
    const clientSetDetail = useStoreActions((actions) => actions.clients.setDetail);
    const setXPos = useStoreActions((actions) => actions.setXPos);
    const setYPos = useStoreActions((actions) => actions.setYPos);
    const xPos = useStoreState((state) => state.xPos);
    const yPos = useStoreState((state) => state.yPos);
    const setSchedules_store = useStoreActions((actions) => actions.clients.setSchedules);
    const schedules_store = useStoreState((state) => state.clients.schedules);
    const setScheduleRowDaily = useStoreActions((actions) => actions.clients.setScheduleRowDaily);
    const setScheduleRowSeeking = useStoreActions((actions) => actions.clients.setScheduleRowSeeking);
    const setScheduleRowProfessional = useStoreActions((actions) => actions.clients.setScheduleRowProfessional);
    const setScheduleRowAppointment = useStoreActions((actions) => actions.clients.setScheduleRowAppointment);
    const employee_setSchedules_store = useStoreActions((actions) => actions.employee.setSchedules);
    const employee_schedules_store = useStoreState((state) => state.employee.schedules);
    const employee_setScheduleRowDaily = useStoreActions((actions) => actions.employee.setScheduleRowDaily);
    const employee_setScheduleRowProfessional = useStoreActions((actions) => actions.employee.setScheduleRowProfessional);
    const employee_setScheduleRowAppointment = useStoreActions((actions) => actions.employee.setScheduleRowAppointment);
    const setFields = useStoreActions((actions) => actions.clients.setFields);
    const setFieldsLight = useStoreActions((actions) => actions.clients.setFieldsLight);
    const setTreeData = useStoreActions((actions) => actions.clients.setTreeData);
    const clientListFiltered = useStoreState((state) => state.clients.clientListFiltered);
    const employeesListFiltered = useStoreState((state) => state.employee.employeesListFiltered);
    const yeshivasListFiltered = useStoreState((state) => state.yeshiva.yeshivasListFiltered);
    const fetchEmployees = useStoreActions((actions) => actions.employee.fetchEmployees)
    const fetchClients = useStoreActions(actions => actions.clients.fetchClients);
    const fetchYeshivas = useStoreActions((actions) => actions.yeshiva.fetchYeshivas)
    const employeeList = useStoreState(state => state.employee.employees);
    const clientList = useStoreState(state => state.clients.clients);
    const yeshivaList = useStoreState(state => state.yeshiva.yeshivas);
    const isTaskStarted = (compDate, startDate) => !compDate && new Date() > new Date(startDate);
    const isAssigneeOnTask = (contactId) => contactId && contactId === global.account.userValue.contact.id;
    const isCreatorOnTask = (contactId) => contactId && contactId === global.account.userValue.contact.id;

    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    const rollbackChanges = () => {
        loadProgramData();
        //loadProgramDetailData();
        removeChange("bottomContainer");
        removeChange("topContainer");
        setClean();
        //setIsSaveEnable(false);
    }
    useEffect(() => {
        setBottomDirtyState(changedItems.includes("bottomContainer"));
    }, [changedItems]);

    useEffect(() => {
        // Resetting Active Tab to general
        setValue(tabsConst.general);
        setExploringId(id);
        if (location.pathname.includes(`/${explorerTypesConst.client}`)) {
            initClientDetail();
            setIsEmployee(false);
        } else if (location.pathname.includes(`/${explorerTypesConst.employee}`)) {
            setIsEmployee(true);
            initEmployeeDetail();
        } else if (location.pathname.includes(`${explorerTypesConst.yeshivas}`)) {
            initYeshivasDetail();
        } else if (location.pathname.includes(`/${explorerTypesConst.users}`)) setMenuName('users');
        else setMenuName("Side Menu");
        return () => {
            endLoading()
        };
    }, [location.pathname, id]);

    useEffect(() => {
        let isCurrLoading = false;
        for (var key in loading) {
            if (loading.hasOwnProperty(key)) {
                if (loading[key])
                    isCurrLoading = true;
            }
        }
        if (isCurrLoading)
            setLoadingGlobal();
        else
            endLoading();
    }, [loading]);

    useEffect(() => {
        if (value === tabsConst.general) {
            if ((isEmployee && employee_schedules_store) || (!isEmployee && schedules_store)) {
                updateAllSchedules(isEmployee ? employee_schedules_store : schedules_store);
            }
        }
    }, [value]);

    useEffect(
        function () {
            if (location.pathname.includes(`yeshivas/${id}/details`)) {
                setLoadingGlobal();
                loadYeshivaSedar();
                if (yeshivaList.length < 1) fetchYeshivas()
                yeshivasDetails(id).then((response) => {
                    endLoading();
                    if (response.length > 0) {
                        setExploringContactId(response[0].contactId);
                        setExploringName(response[0].name);
                    }
                });
                //Initialization
                getYeshivasContacts(id)?.then((response) => {
                    setClientContactData(response);
                });
            } else if (location.pathname.includes(`client/${id}/details`)) {
                setProgramTab(true);
                if (clientList.length < 1) fetchClients()
                setIsEmployee(false);

                // getClientScheduleDetail(id).then((response) => {
                //   setClientScheduleData(response);
                //   setLoadingGlobal(false);
                // });

                // saveClientToDo(data, id).then(() => {
                //     //setClientToDoData(response);
                // });

            } else {
                if (employeeList.length < 1) fetchEmployees()
                setIsEmployee(true);
                setProgramTab(false);
                if (id) {
                    setLoadingGlobal();
                    getEmployeeDetails(id).then((response) => {
                        setClientData(response);
                        endLoading();
                        // setExploringName(response.firstName + response.lastName);
                    });
                }
            }
        },
        [isOpenCreateModal, exploringId, id]
    );

    const getUpdateObjectProperty = (obj, field, value) => {
        const objTemp = { ...obj };
        Object.keys(objTemp).forEach(function (key, index) {
            objTemp[key] = {
                ...objTemp[key],
                [field]: value
            }
        });
        return objTemp;
    }
    const loadYeshivaSedar = () => {
        setSedersDetail(getUpdateObjectProperty(sedersDetail, `isLoading`, true))
        getYeshivasSedar(exploringId)?.then((data) => {
            const scheduleTemp = { ...sedersDetail }
            data.map((item) => {
                scheduleTemp[item.name] = {
                    ...scheduleTemp[item.name],
                    ...item,
                    startTime: item?.startTime ?? '',
                    endTime: item?.endTime ?? '',
                    isLoading: false
                }
            })
            setSedersDetail(getUpdateObjectProperty(scheduleTemp, `isLoading`, false))
        })
    }

    const changeScheduleType = (event) => {
        var val = event.target.value;
        global.log(val);
        setDropDownScheduleType(val);
    }

    const handleChange = (event, newValue) => {
        if (value != newValue && hasChanges() && !wConfirm(event, rollbackChanges))
            return;
        setValue(newValue);
    };

    const goToTab = (tabValue) => {
        setValue(tabValue);
    };

    const handleChangeService = (event) => {
        setPersonName(event.target.value);
    };

    const handleSchedulePeriod = (event) => {
        setSchedulePeriod(event.target.value);
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleScheduleType = (event) => {
        setScheduleType(event.target.value);
        setPersonName([]);
    };

    const updateAllSchedules = (data) => {
        var listDaily = [];
        var listSeeking = [];
        var listProfessionals = [];
        var listAppointments = [];
        data.forEach((sch) => {
            let schedule = {
                ...sch,
                id: sch.clientScheduleId,
            }
            switch (schedule.type) {
                case "yungerman":
                    if (schedule.status === "seeking")
                        listSeeking.push(schedule);
                    else if (!schedule.endDate || !global.dateTime.isDatePast(schedule.endDate))
                        listDaily.push(schedule);
                    break;
                case "professional":
                    if (schedule.status === "seeking")
                        listSeeking.push(schedule);
                    else if (!schedule.endDate || !global.dateTime.isDatePast(schedule.endDate))
                        listProfessionals.push(schedule);
                    break;
                case "appointment":
                    if (!global.dateTime.isDatePast(global.dateTime.addDays(schedule.startDate, 30)))
                        listAppointments.push(schedule);
                    break;
                default:
                    break;
            }
        });
        // update redux
        if (isEmployee) {
            employee_setScheduleRowDaily(listDaily);
            employee_setScheduleRowProfessional(listProfessionals);
            employee_setScheduleRowAppointment(listAppointments);
        }
        else {
            setScheduleRowDaily(listDaily);
            setScheduleRowSeeking(listSeeking);
            setScheduleRowProfessional(listProfessionals);
            setScheduleRowAppointment(listAppointments);
        }

        // update local state
        setDailySchedule(listDaily);
        setSeekingSchedule(listSeeking);
        setProfessionalSchedule(listProfessionals);
        setAppointmentSchedule(listAppointments);
    }

    const initClientDetail = () => {
        var exploringType = explorerTypesConst.clients;
        setMenuName("Clients");
        setExploringMode("detail");
        setLoadingGlobal();
        let contactId;
        setLoadingItem("client", true);
        getClientDetails(id).then((response) => {
            setClientData(response);
            contactId = response.contactId;
            clientSetDetail(response);
            setExploringName(response?.firstName + " " + response?.lastName);
            setLoadingItem("client");
        })
            .then(() => {
                setLoadingItem("clientServiceSchedules", true);
                setLoadingItem("clientProfessionalSchedules", true);
                setLoadingItem("clientAppointmentSchedules", true);
                getProviderSchedules("clients", id).then((data) => {
                    setSchedules_store(data);
                    updateAllSchedules(data);
                    // set loaders
                    setLoadingItem("clientServiceSchedules");
                    setLoadingItem("clientProfessionalSchedules");
                    setLoadingItem("clientAppointmentSchedules");
                })
            })
            .then(() => {
                setLoadingItem("todo", true);
                getClientToDoDetail({ module: 'clients', primaryId: id }).then((response) => {
                    setClientToDoData(response);
                    setLoadingItem("todo");
                });
            })
            .then(() => {
                setLoadingItem("clientProgramDetails", true);
                getClientProgramDetail(id).then((response) => {
                    setClientProgramData(response);
                    setLoadingItem("clientProgramDetails");
                });
            })
            .then(() => {
                setLoadingItem("clientNotes", true);
                getClientNotesList(id, global.getContactName(global.account.userValue)).then((response) => {
                    setClientNotesData(response);
                    setLoadingItem("clientNotes");
                });
            })
            .then(() => {
                setLoadingItem("clientDetailProgram", true);
                getClientDetailProgram(id).then((value) => {
                    setFields(value);
                    setLoadingItem("clientDetailProgram");
                })
            })
            .then(() => {
                setLoadingItem("clientDetailProgramLight", true);
                getClientDetailProgramLight(id).then((value) => {
                    let light = { ...value, ...value.paymentMethod, id: value.id, };
                    setFieldsLight(light);
                    setLoadingItem("clientDetailProgramLight");
                });
            })
            .then(() => {
                setLoadingItem("clientDocuments", true);
                getDocumentTreeStructure(contactId).then((value) => {
                    if (value != null)
                        setTreeData(value);
                    setLoadingItem("clientDocuments");
                });
            })
            .then(() => {
                setLoadingItem("clientContacts", true);
                getContactRelDetail("clients", id).then((response) => {
                    setClientContactData(response);
                    setLoadingItem("clientContacts");
                });
            })
            .then(() => {
                setLoadingItem("clientProgramsTabDetails", true);
                getClientProgramTabDetail(id).then((response) => {
                    setClientTabProgramData(response);
                    setLoadingItem("clientProgramsTabDetails");
                });
            })
            .then(() => {
            })
            .catch(() => {
                setLoadingItem({

                })
            })
    };

    const loadProgramData = () => {
        getClientProgramDetail(id).then((response) => {
            setClientProgramData(response);
        })
            .then(() => {
                getClientDetailProgram(id).then((value) => {
                    setFields(value);
                })
            })
            .then(() => {
                getClientDetailProgramLight(id).then((value) => {
                    let light = { ...value, ...value.paymentMethod, id: value.id, };
                    setFieldsLight(light);
                });
            })
            .then(() => {
                getClientProgramTabDetail(id).then((response) => {
                    setClientTabProgramData(response);
                });
            })
    }

    const initEmployeeDetail = () => {
        getEmployeeDetails(id).then((response) => {
            setExploringId(id);
            setClientData(response);
            setExploringName(response?.contact?.firstName + " " + response?.contact?.lastName);
            getProviderSchedules("employees", id).then((data) => {
                employee_setSchedules_store(data);
                updateAllSchedules(data);
                setLoading(false);
            })
        });
        setMenuName("Employees");
        setExploringMode("detail");
    };

    const initYeshivasDetail = () => {
        getYeshivaDetail(id).then((response) => {
            if (!!response) {
                setClientData(response)
                setExploringContactId(response.contactId);
                setExploringName(response.name);
            }
        });
        setMenuName("yeshivas");
    };

    const _setRowAndColumnProgress = () => {
        return {
            // rows: clientProgressData,
            rows: clientNotesData,
            columns: progressColumns.concat(["accessLevelColumn", "editColumn", "deleteColumn"]),
            screen: "client_progress",
        };
    };

    const _setRowAndColumnContact = () => {
        return {
            // rows: clientHistoryData,
            rows: clientContactData,
            columns: contactColumns,
            screen: "client_contact",
        };
    };

    const _handleData = () => {
        return isProgramTab;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddButton = () => {
        setIsOpenCreateModal(true);
        setMonthlyProgressDateRange(null)
    };

    const handleCloseModal = (isReload) => {
        ;
        setIsOpenCreateModal(false);
        setEditData([]);
        if (isReload) {
            handleReloadApi(value);
        }
    };
    const handleReloadApi = (params) => {
        if (params === "schedule" || params === "all") {
            setLoading({ ...loading, [params]: true });
            getClientScheduleList(id).then((response) => {
                setClientScheduleList(response);
                setLoading({ ...loading, [params]: false });
            });
        }

        if (params === "todo" || params === "all") {
            getClientToDoDetail({ module: 'clients', primaryId: id }).then((response) => {
                setClientToDoData(response);
            });
        }

        if (params === "progress" || params === "all") {
            setLoading({ ...loading, [params]: true });
            getClientNotesList(id, global.getContactName(global.account.userValue)).then((response) => {
                setClientNotesData(response);
                setLoadingItem(params);
            });
        }
    };

    const refreshTemplateNoteList = (note) => {
        setTemplateNotes((prev) => {
            let filteredList = prev.filter(x => x.id !== note.id);
            const newTemplateNoteList = [
                ...filteredList,
                note,
            ]
            return newTemplateNoteList;
        })
    }

    const getProgressNoteEditData = (data) => {
        setProgressNoteEditData(data);
    }

    const handleEditData = (data, dateRange) => {
        setIsOpenCreateModal(true);
        setEditData(data);
        setMonthlyProgressDateRange(dateRange);
    };

    /**
     * @returns client scheduled cards
     */
    const getRateValue = (params) => {
        return params.row.rate ? `$${params.row.rate}${periodicities[params.row.ratePeriod] ? `/${periodicities[params.row.ratePeriod]}` : ""}` : ""
    }

    const getClientScheduledCards = () => {
        return {
            schedules: {
                show: true,
                title: "Yungeleit",
                actions: ['add', 'edit', 'end', 'delete'],
                columns: [
                    { field: "clientScheduleId", headerName: 'Schedule #', flex: .5, sortable: false },
                    //{ field: "startTime", headerClassName: 'noHeaderDivider', headerName: "Start Time", width: 120, hide: true, type: "dateTime" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "dateTime" },
                    { field: "time", headerClassName: 'noHeaderDivider', headerName: "Time", flex: .5, sortable: false },
                    { field: "providerName", headerName: "Provider", flex: .5, navUrl: "employee/{providerId}/details" },
                    { field: "schedule", headerName: "Schedule", flex: .5, sortable: false },
                    { field: "startDate", headerName: "Start Date", flex: .5, hide: true, type: "date" },
                    { field: "endDate", headerName: "End Date", flex: .5, hide: true, type: "date" },
                    { field: "throughout", headerName: "Throughout", flex: .5 },
                    { field: "rate", headerName: "Rate", flex: .3, getStringValue: getRateValue },
                    { field: "hoursPerWeek", headerName: "Hours /wk", flex: .3 },
                    { field: "totalDollarsPerWeek", headerName: "Price /wk", flex: .3 },
                    { field: "statusIcon", headerName: 'Status Icon', headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ]
            },
            professionals: {
                show: true,
                title: "Professional",
                actions: ['add', 'edit', 'end', 'delete'],
                columns: [
                    { field: "clientScheduleId", headerName: 'Schedule #', width: 30, sortable: false },
                    //{ field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                    { field: "time", headerName: "Time", width: 120, sortable: false },
                    { field: "providerName", headerName: "Provider", width: 120, navUrl: "employee/{providerId}/details" },
                    { field: "schedule", headerName: "Schedule", width: 120, sortable: false },
                    { field: "startDate", headerName: "Start Date", width: 120, hide: true, type: "date" },
                    { field: "endDate", headerName: "End Date", width: 120, hide: true, type: "date" },
                    { field: "throughout", headerName: "Throughout", width: 120 },
                    { field: 'nextSession', headerName: 'Upcoming', width: 120 },
                    { field: 'rate', headerName: 'Rate', width: 120, getStringValue: getRateValue },
                    { field: "statusIcon", headerName: 'Status Icon', headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ]
            },
            appointments: {
                show: true,
                title: "Appointments",
                actions: ['add', 'edit', 'delete'],
                columns: [
                    { field: "clientScheduleId", headerName: 'Schedule #', width: 30, sortable: false },
                    { field: 'providerName', headerName: 'Professional', width: 120, navUrl: "employee/{providerId}/details" },
                    { field: 'startDate', headerName: 'Date', type: 'date', width: 120 },
                    //{ field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                    { field: "time", headerName: "Time", width: 120, sortable: false },
                    { field: 'note', headerName: 'Note', width: 120 },
                    { field: 'rate', headerName: 'Rate', width: 120, getStringValue: getRateValue },
                    { field: "statusIcon", headerName: 'Status Icon', headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' },
                    { field: "moreIcon", headerName: 'More Icon', headerClassName: 'hidden-header', enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ]
            },
            seeking: {
                show: true,
                title: "Seeking",
                actions: ['edit', 'delete'],
                columns: [
                    { field: 'type', title: 'Type', value: 'type', type: 'type', sortable: true, order: 'asc' },
                    { field: 'schedule', title: 'Schedule', type: 'schedule', value: 'schedule' },
                    { field: 'time', title: 'Time', type: 'time', value: 'time', sortable: true, order: 'asc' },
                    { field: 'createdon', title: 'Requested On', type: 'createdon', value: 'createdon' },
                    { field: 'specialties', title: 'Specialties', type: 'specialties', value: 'specialties' },
                    { field: 'status', title: 'status', type: 'statusIcon' },
                    { field: 'expand', title: 'expand', type: 'expandIcon' },
                    { field: 'delete', title: 'delete', type: 'deleteIcon' },
                ]
            },
            history: {
                show: true,
                title: "History",
                actions: ['edit', 'delete'],
                quickFilters: {
                    type: 'select',
                    selectedValue: dropDownScheduleType,
                    dropdowns: [{
                        type: 'type',
                        handler: (value) => changeScheduleType(value),
                        value: dropDownScheduleType,
                        options: [
                            {
                                label: 'Yungerleit',
                                value: 'yungerman'
                            },
                            {
                                label: 'Professional',
                                value: 'professional'
                            },
                            {
                                label: 'Appointment',
                                value: 'appointment'
                            }
                        ]
                    }],
                },
                columns: {
                    yungerman: [
                        { field: 'provider', title: 'Yungerman', type: 'name', value: 'providerName' },
                        { field: 'between', title: 'Between', type: 'between', value: 'between' },
                        { field: 'time', title: 'Time', type: 'time', value: 'time' },
                        { field: 'schedule', title: 'Schedule', type: 'schedule', value: 'schedule' },
                        { field: 'edit', title: 'edit', type: 'editIcon' }
                    ],
                    professional: [
                        { field: 'provider', title: 'Professional', type: 'name', value: 'providerName' },
                        { field: 'between', title: 'Between', type: 'between', value: 'between' },
                        { field: 'time', title: 'Time', type: 'time', value: 'time', sortable: true, order: 'asc' },
                        { field: 'schedule', title: 'Schedule', type: 'schedule', value: 'schedule' },
                        { field: 'edit', title: 'edit', type: 'editIcon' }
                    ],
                    appointment: [
                        { field: 'provider', title: 'Professional', type: 'name', value: 'providerName' },
                        { field: 'date', title: 'Date', type: 'date', value: 'startDate' },
                        { field: 'time', title: 'Time', type: 'time', value: 'time', sortable: true, order: 'asc' },
                        { field: 'edit', title: 'edit', type: 'editIcon' }
                    ],
                },
                types: ["yungerman", "professional", "appointment"]
            },

        }
    }

    /**
     * @returns employee scheduled cards
     */

    const getEmployeeScheduledCards = () => {
        return {
            availability: {
                show: true,
                title: "Availability",
                actions: ['add', 'edit', 'delete'],
                columns: [
                    //{ field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                    { field: 'time', headerName: 'Time', width: 120 },
                    { field: 'scheduleDisplayName', headerName: 'Schedule', value: 'schedule' },
                    { field: 'throughout', headerName: 'Throughout', width: 120 },
                    { field: "moreIcon", headerName: 'More Icon', hideHeaderName: true, enableTooltip: false, disableColumnMenu: true, icon: "MoreVert", sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding' }
                ]
            },
            schedules: clientData && clientData.isYungerman && {
                show: true,
                title: "Schedules",
                actions: [],
                columns: [
                    //{ field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                    { field: 'time', headerName: 'Time', width: 120 },
                    { field: 'clientName', headerName: 'Bucher', width: 120, navUrl: "client/{clientId}/details" },
                    { field: 'schedule', headerName: 'Schedule', },
                    { field: 'throughout', headerName: 'Throughout', width: 120 },
                    { field: 'rate', headerName: 'Rate', getStringValue: getRateValue },
                    { field: "hoursPerWeek", headerName: "Hours /wk", width: 120, type: "number" },
                    { field: "totalDollarsPerWeek", headerName: "Price /wk", width: 120, type: "number" },
                    { field: "statusIcon", headerName: 'Status Icon', headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 50, minWidth: 50, cellClassName: 'icon-no-padding' },
                ]
            },
            professionals: clientData && clientData.isProfessional && {
                show: true,
                title: "Professional",
                actions: [],
                columns: [
                    //{ field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                    { field: 'time', headerName: 'Time', width: 120 },
                    { field: 'clientName', headerName: 'Bucher', width: 120, navUrl: "client/{clientId}/details" },
                    { field: 'schedule', headerName: 'Schedule', },
                    { field: 'throughout', headerName: 'Throughout', width: 120 },
                    { field: 'nextSession', headerName: 'Upcoming', },
                    { field: 'rate', headerName: 'Rate', getStringValue: getRateValue },
                    { field: "statusIcon", headerName: 'Status Icon', headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 50, minWidth: 50, cellClassName: 'icon-no-padding' },
                ]
            },
            appointments: clientData && clientData.isProfessional && {
                show: true,
                title: "Appointments",
                actions: [],
                columns: [
                    { field: 'clientName', headerName: 'Bucher', width: 120, navUrl: "client/{clientId}/details" },
                    { field: 'date', headerName: 'Date', type: 'date', },
                    //{ field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                    { field: 'time', headerName: 'Time', width: 120 },
                    { field: 'note', headerName: 'Note', width: 120 },
                    { field: 'rate', headerName: 'Rate', type: 'number' },
                    { field: "statusIcon", headerName: 'Status Icon', headerClassName: 'hidden-header', disableColumnMenu: true, enableTooltip: true, sortable: false, maxWidth: 50, minWidth: 50, cellClassName: 'icon-no-padding' },
                ]
            },
            potentials: {
                show: true,
                title: "Potentials",
                actions: [],
                columns: [
                    { field: 'clientName', headerName: 'Bucher', width: 120, navUrl: "client/{clientId}/details" },
                    //{ field: "startTime", headerName: "Start Time", width: 120, hide: true, type: "date" },
                    //{ field: "endTime", headerName: "End Time", width: 120, hide: true, type: "date" },
                    { field: 'schedule', headerName: 'Schedule', width: 120 },
                    { field: 'time', headerName: 'Time', width: 120 },
                    { field: 'status', headerName: 'Status', },
                ]
            },
            history: {
                show: true,
                title: "History",
                quickFilters: {
                    type: 'select',
                    selectedValue: dropDownScheduleType,
                    dropdowns: [{
                        type: 'type',
                        handler: (value) => changeScheduleType(value),
                        value: dropDownScheduleType,
                        options: [
                            {
                                label: 'Yungerleit',
                                value: 'yungerman'
                            },
                            {
                                label: 'Professional',
                                value: 'professional'
                            },
                            {
                                label: 'Appointment',
                                value: 'appointment'
                            },
                        ]
                    }],
                },
                actions: [],
                columns: {
                    yungerman: [
                        { field: 'client', title: 'Bucher', type: 'name', value: 'clientName' },
                        { field: 'between', title: 'Between', type: 'between', value: 'between' },
                        { field: 'time', title: 'Time', type: 'time', value: 'time', sortable: true, order: 'asc' },
                        { field: 'schedule', title: 'Schedule', type: 'schedule', value: 'schedule' },
                        { field: 'edit', title: 'edit', type: 'editIcon' }
                    ],
                    professional: [
                        { field: 'client', title: 'Bucher', type: 'name', value: 'clientName' },
                        { field: 'between', title: 'Between', type: 'between', value: 'between' },
                        { field: 'time', title: 'Time', type: 'time', value: 'time', sortable: true, order: 'asc' },
                        { field: 'schedule', title: 'Schedule', type: 'schedule', value: 'schedule' },
                        { field: 'edit', title: 'edit', type: 'editIcon' }
                    ],
                    appointment: [
                        { field: 'client', title: 'Bucher', type: 'name', value: 'clientName' },
                        { field: 'date', title: 'Date', type: 'date', value: 'startDate' },
                        { field: 'time', title: 'Time', type: 'time', value: 'time', sortable: true, order: 'asc' },
                        { field: 'edit', title: 'edit', type: 'editIcon' }
                    ],
                },
                types: ["yungerman", "sessions"]
            }
        }
    }

    /**
     * dynamically handle render of scheduled cards. 
     */
    const getFeaturedScheduleCard = () => {
        if (exploringType === explorerTypesConst.clients) {
            return getClientScheduledCards()
        } else if (exploringType === explorerTypesConst.employees) {
            return getEmployeeScheduledCards();
        }
    }

    const handleSaveSeder = (data, type, tab, section, mode) => {
        if (type === 'cancel') {
            const scheduleInfoTemp = { ...sedersDetail };
            Object.keys(scheduleInfoTemp).forEach(function (key, index) {
                if (!section) {
                    scheduleInfoTemp[key] = {
                        ...scheduleInfoTemp[key],
                        mode: mode
                    }
                } else if (key === section) {
                    scheduleInfoTemp[key] = {
                        ...scheduleInfoTemp[key],
                        mode: mode
                    }
                }
            });
            setSedersDetail(scheduleInfoTemp)
            return
        }
        let payload = { ...data, yeshivaId: exploringId };
        delete payload.mode;
        delete payload.isLoading;
        Object.keys(payload).forEach(function (key) {
            payload[key] = payload[key]?.value ?? payload[key]
        });
        setSedersDetail({
            ...sedersDetail,
            [section]: {
                ...sedersDetail[section],
                ...data,
                isLoading: true
            }
        })
        if (payload.sederId > 0) {
            updateYeshivasSedar(exploringId, payload.sederId, { ...payload, name: section }).then((res) => {
                setSedersDetail({
                    ...sedersDetail,
                    [section]: {
                        ...res,
                        startTime: data.startTime,
                        endTime: data.endTime,
                        yeshivaId: data.yeshivaId,
                        error: '',
                        mode: 'read',
                        isLoading: false
                    }
                })
            })
        } else {
            addYeshivasSedar(exploringId, { ...payload, name: section }).then((res) => {
                setSedersDetail({
                    ...sedersDetail,
                    [section]: {
                        ...res,
                        startTime: data.startTime,
                        endTime: data.endTime,
                        yeshivaId: data.yeshivaId,
                        error: '',
                        mode: 'read',
                        isLoading: false
                    }
                })
            })
        }

    }

    const featuredScheduleCard = getFeaturedScheduleCard();

    const getNavigationId = (lists, dir) => {
        const currentIndex = lists.findIndex((list) => {
            return list.id.toString() === exploringId?.toString()
        });
        switch (dir) {
            case 'previous': {
                return lists[currentIndex - 1]?.id ?? null;
            }
            case 'next': {
                return lists[currentIndex + 1]?.id ?? null;
            }
            default: return null
        }
    }

    const handleNavigateExplorer = (dir) => {
        switch (exploringType) {
            case explorerTypesConst.client:
            case explorerTypesConst.clients: {
                const id = getNavigationId(clientListFiltered, dir);
                if (!!id) history(FRONTEND_ROUTE_CONSTANTS.CLIENT_ROUTE + id + FRONTEND_ROUTE_CONSTANTS.DETAILS);
                break
            }
            case explorerTypesConst.employee:
            case explorerTypesConst.employees: {
                const id = getNavigationId(employeesListFiltered, dir);
                if (!!id) history(FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE + id + FRONTEND_ROUTE_CONSTANTS.DETAILS);
                break
            }
            case explorerTypesConst.yeshiva:
            case explorerTypesConst.yeshivas: {
                const id = getNavigationId(yeshivasListFiltered, dir);
                if (!!id) history(FRONTEND_ROUTE_CONSTANTS.YESHIVA_ROUTE + id + FRONTEND_ROUTE_CONSTANTS.DETAILS);
                break
            }
            default: return null
        }
    }

    const disabledNavigation = (dir) => {
        switch (exploringType) {
            case explorerTypesConst.client:
            case explorerTypesConst.clients: {
                return getNavigationId(clientListFiltered, dir);
            }
            case explorerTypesConst.employee:
            case explorerTypesConst.employees: {
                return getNavigationId(employeesListFiltered, dir);
                break;
            }
            case explorerTypesConst.yeshiva:
            case explorerTypesConst.yeshivas: {
                return getNavigationId(yeshivasListFiltered, dir);
                break;
            }
            default: return null
        }
    }

    return (
        <>
            <main className={classes.content} style={{ position: 'relative' }}>
                <div className={baseClasses.toolbar} />
                {
                    <div>
                        {/* <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{ marginBottom: 13, marginLeft: 0, cursor: "pointer" }}
            >
              <Link
                color="inherit"
                style={{ fontSize: 14 }}
                onClick={() => {
                  history.push(`/${menuName}`);
                }}
              >
                {menuName.toUpperCase()}
              </Link>
              <Link color="inherit" style={{ fontSize: 14 }}>
                {value.toUpperCase()}
              </Link>
              <Typography style={{ fontSize: 14 }} color="textPrimary">
                {exploringName}
              </Typography>
            </Breadcrumbs> */}
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div
                                style={{
                                    fontSize: 14,
                                    marginLeft: 0,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    cursor: "pointer",
                                }}
                            >
                                {exploringName}
                            </div>
                            <FlexBetween>
                                <FeaturedIcon onClick={() => handleNavigateExplorer('previous')} color={!disabledNavigation('previous') ? 'default' : "primary"} fontSize='small' icon='ArrowBack' />
                                <FeaturedIcon onClick={() => handleNavigateExplorer('next')} color={!disabledNavigation('next') ? 'default' : "primary"} fontSize='small' icon='ArrowForward' />
                                {(exploringType === explorerTypesConst.clients || isEmployee || exploringType === explorerTypesConst.yeshivas) && <Button
                                    onClick={() => {
                                        if (hasChanges()) {
                                            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                                                return;
                                            }
                                        }
                                        setExploringMode("edit");
                                        if (exploringType === explorerTypesConst.clients) {
                                            history(`${FRONTEND_ROUTE_CONSTANTS.CLIENT_ROUTE}${exploringId}${FRONTEND_ROUTE_CONSTANTS.ENROLEMENT}`)
                                            global.navigatedToClientsFromClientEdit = true;
                                        } else if (exploringType === explorerTypesConst.yeshivas) {
                                            history(`${FRONTEND_ROUTE_CONSTANTS.YESHIVA_ROUTE}${exploringId}${FRONTEND_ROUTE_CONSTANTS.MANAGE}`)
                                        }
                                        else
                                            history(`${FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE}${exploringId}${FRONTEND_ROUTE_CONSTANTS.ENROLEMENT}`)
                                        setPreviousPath("FROM EMPLOYEE DETAIL");

                                    }}
                                    //color="primary"
                                    variant="text"
                                    disabled={!canAccessEnrollment}
                                >
                                    <b style={{ fontSize: "14px", paddingRight: "4px", /*color: "#1976d2"*/ }}>
                                        {"Enrollment  "}
                                    </b>
                                    <ArrowCircleRightRoundedIcon
                                        fontSize="small"
                                    //style={{ color: "#1976d2" }}
                                    />
                                </Button>}
                            </FlexBetween>
                        </div>
                        <Card variant="outlined">
                            <CardContent classes={{ root: baseClasses.card_view }}>
                                <div className={classes.root}>
                                    <AppBar
                                        className={baseClasses.appbarColor}
                                        position="static"
                                        elevation={0}
                                    >
                                        {exploringType === explorerTypesConst.yeshivas ? (
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                            >
                                                <Tab
                                                    className={baseClasses.tabClassSelected}
                                                    value={tabsConst.general}
                                                    label="General"
                                                    {...setRowAndTab(0)}

                                                />
                                                {/*<Tab*/}
                                                {/*    className={baseClasses.tabClassSelected}*/}
                                                {/*    label="Contacts"*/}
                                                {/*    value="contacts"*/}
                                                {/*    {...setRowAndTab(6)}*/}
                                                {/*/>*/}
                                                <Tab
                                                    className={baseClasses.tabClassSelected}
                                                    label="Clients"
                                                    value={tabsConst.clients}
                                                    {...setRowAndTab(8)}
                                                />
                                                <Tab
                                                    className={baseClasses.tabClassSelected}
                                                    label="Contacts"
                                                    value={tabsConst.contacts}
                                                    {...setRowAndTab(12)}
                                                />
                                                <Tab
                                                    className={baseClasses.tabClassSelected}
                                                    label="Seders"
                                                    value={tabsConst.seders}
                                                    {...setRowAndTab(13)}
                                                />
                                                {/*<Tab*/}
                                                {/*    className={baseClasses.tabClassSelected}*/}
                                                {/*    label="History"*/}
                                                {/*    value="history"*/}
                                                {/*    {...setRowAndTab(7)}*/}
                                                {/*/>*/}
                                            </Tabs>
                                        ) : (
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                            >
                                                <Tab
                                                    className={baseClasses.tabClassSelected}
                                                    value={tabsConst.general}
                                                    label="General"
                                                    {...setRowAndTab(0)}
                                                />
                                                {(isEmployee || !programPermissions.canView) ? null : (
                                                    <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        value={tabsConst.program}
                                                        label="Programs"
                                                        {...setRowAndTab(1)}
                                                    />
                                                )}
                                                {todosPermissions.canView && (< Tab
                                                    className={baseClasses.tabClassSelected}
                                                    label="To Do"
                                                    value={tabsConst.todo}
                                                    {...setRowAndTab(2)}
                                                />)
                                                }

                                                {schedulePermissions.canView && (<Tab
                                                    className={baseClasses.tabClassSelected}
                                                    label="Schedule"
                                                    value={tabsConst.schedule}
                                                    {...setRowAndTab(3)}
                                                />)}
                                                {(isEmployee || !missionPermissions.canView) ? null : (
                                                    <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        label="Mission"
                                                        value={tabsConst.mission}
                                                        {...setRowAndTab(4)}
                                                    />
                                                )

                                                }
                                                {isProgramTab && progressPermissions.canView ? (
                                                    <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        label="Progress"
                                                        value={tabsConst.progress}
                                                        {...setRowAndTab(5)}
                                                    />
                                                ) : null}
                                                {isEmployee ? null : (
                                                    <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        label="Documents"
                                                        value={tabsConst.documents}
                                                        {...setRowAndTab(6)}
                                                    />
                                                )}
                                                {isEmployee || !contactsPermissions.canView ? null : (
                                                    <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        label="Contacts"
                                                        value={tabsConst.contacts}
                                                        {...setRowAndTab(7)}
                                                    />
                                                )}
                                                {isEmployee ? (
                                                    <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        label="Programs"
                                                        value="employeePrograms"
                                                        {...setRowAndTab(8)}
                                                    />
                                                ) : null}
                                                {
                                                    historyPermissions.canView && <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        label="History"
                                                        value={tabsConst.history}
                                                        {...setRowAndTab(9)}
                                                    />
                                                }
                                                {
                                                    (isEmployee || clientInfoPermissions.canView) && <Tab
                                                        className={baseClasses.tabClassSelected}
                                                        label="Info"
                                                        value={tabsConst.info}
                                                        {...setRowAndTab(10)}
                                                    />
                                                }

                                            </Tabs>
                                        )}
                                    </AppBar>
                                    <div className={classes.panelContent}>
                                        <TabPanel
                                            value={value}
                                            index={tabsConst.general}
                                            style={{ backgroundColor: theme.palette.background.paper }}
                                            className={baseClasses.tabRoot}
                                        >
                                            <General
                                                clientData={clientData}
                                                clientToDoData={clientToDoData}
                                                clientScheduleData={clientScheduleData}
                                                clientProgramData={clientProgramData}
                                                clientNotesData={clientNotesData}
                                                goToTabProgress={() => goToTab(tabsConst.progress)}
                                                goToTabTodo={() => goToTab(tabsConst.todo)}
                                                isEmployee={isEmployee}
                                                data={_handleData()}
                                                scheduleInfo={{
                                                    yungerman: dailySchedule,
                                                    professional: professionalSchedule,
                                                    appointment: appointmentSchedule,
                                                }}
                                            />
                                        </TabPanel>
                                        {programPermissions.canView && (
                                            <TabPanel
                                                value={value}
                                                index={tabsConst.program}
                                                style={{ backgroundColor: theme.palette.background.paper }}
                                                className={baseClasses.tabRoot}
                                            >
                                                <Program
                                                    clientData={clientData}
                                                    clientProgramData={clientProgramData}
                                                    isEmployee={isEmployee}
                                                    data={_handleData()}
                                                    programChange={() => { addChange("topContainer"); }}
                                                    programDetailChange={() => { addChange("bottomContainer"); }}
                                                    programClean={() => removeChange("topContainer")}
                                                    programDetailClean={() => removeChange("bottomContainer")}
                                                    permissions={global.permissions.clients}
                                                />
                                            </TabPanel>
                                        )

                                        }

                                        {todosPermissions.canView &&
                                            <TabPanel
                                                value={value}
                                                index={tabsConst.todo}
                                                className={baseClasses.tabRoot}
                                            >
                                                <ToDo
                                                    screen={isEmployee ? 'employee_task' : 'client_task'}
                                                    classes={classes}
                                                    todoData={clientToDoData}
                                                    handleReloadApi={isEmployee ? null : handleReloadApi}
                                                    showAddButton
                                                />
                                            </TabPanel>
                                        }
                                        {schedulePermissions.canView &&
                                            <TabPanel
                                                value={value}
                                                index={tabsConst.schedule}
                                                className={baseClasses.tabRoot}
                                                style={{ backgroundColor: theme.palette.background.paper }}
                                            >
                                                <React.Fragment>
                                                    <MainSection
                                                        client={!isEmployee && clientData}
                                                        provider={isEmployee && clientData}
                                                        clientScheduleData={clientScheduleData}
                                                        isEmployee={isEmployee}
                                                        cards={{
                                                            availability: featuredScheduleCard?.availability,
                                                            schedules: featuredScheduleCard?.schedules,
                                                            professionals: featuredScheduleCard?.professionals,
                                                            appointments: featuredScheduleCard?.appointments,
                                                            potentials: featuredScheduleCard?.potentials,
                                                            seeking: featuredScheduleCard?.seeking,
                                                            history: featuredScheduleCard?.history,
                                                        }}
                                                    />
                                                </React.Fragment>
                                            </TabPanel>
                                        }
                                        {missionPermissions.canView &&
                                            <TabPanel
                                                value={value}
                                                index={tabsConst.mission}
                                                className={baseClasses.tabRoot}
                                            >
                                                <Mission />
                                            </TabPanel>
                                        }
                                        {
                                            <TabPanel
                                                value={value}
                                                index={tabsConst.info}
                                                className={baseClasses.tabRoot}
                                            >
                                                <Info />
                                            </TabPanel>
                                        }


                                        {progressPermissions.canView &&

                                            <TabPanel
                                                value={value}
                                                index={tabsConst.progress}
                                                className={baseClasses.tabRoot}
                                            >
                                                <Progress
                                                    progressNoteEditData={progressNoteEditData}
                                                    handleAddButton={handleAddButton}
                                                    rowsAndCols={_setRowAndColumnProgress()}
                                                    handleEditData={(params, date) => {
                                                        handleEditData(params, date)
                                                    }}
                                                    onDelete={(params) => setIsOpenDeletePopup(params.row.id)}
                                                    setProgressPage={setProgressPage}
                                                    templateNotes={templateNotes}
                                                    setTemplateNotes={setTemplateNotes}
                                                />
                                                {/* <div className={classes.programTab}>
                                                    <div className={baseClasses.button_program}>
                                                        <Button
                                                            className={baseClasses.capitalized}
                                                            color="primary"
                                                            variant="outlined"
                                                            onClick={handleAddButton}
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="p-0">
                                                    <DataTable
                                                        data={_setRowAndColumnProgress()}
                                                        onOpen={handleAddButton}
                                                        onEdit={(params) => { handleEditData(params.row) }}
                                                        onRowEnter={(row) => {
                                                            //setIsToolTip(true);
                                                            //setTooltipData(row.noteDescription);
                                                        }}
                                                        onRowLeave={(row) => {
                                                            //setIsToolTip(false);
                                                        }}
                                                        onDelete={(params) => {
                                                            setIsOpenDeletePopup(params.row.id);
                                                        }}
                                                        permissions={global.permissions.clients}
                                                        canEdit={(params) =>
                                                            params.row.createdById === global.account.userValue.id || global.isAdmin()
                                                        }
                                                        canDelete={(params) =>
                                                            params.row.createdById === global.account.userValue.id || global.isAdmin()
                                                        }
                                                    />
                                                </div> */}
                                            </TabPanel>
                                        }
                                        <TabPanel
                                            value={value}
                                            index={tabsConst.documents}
                                            style={{ backgroundColor: theme.palette.background.paper }}
                                        >
                                            <Document clientData={clientData} />
                                        </TabPanel>
                                        <TabPanel
                                            value={value}
                                            index={tabsConst.seders}
                                            style={{ backgroundColor: theme.palette.background.paper }}
                                        >
                                            <SederDetails handleSave={handleSaveSeder} scheduleInfo={sedersDetail} />
                                        </TabPanel>
                                        {contactsPermissions && <TabPanel
                                            value={value}
                                            index={tabsConst.contacts}
                                            className={baseClasses.tabRoot}
                                            style={{ backgroundColor: theme.palette.background.paper }}
                                        >
                                            <Contact
                                                data={clientContactData ?? []}
                                                permissions={contactsPermissions}
                                            />
                                            {/* <div className={classes.programTab}>
                                                <div className={baseClasses.button_program}>
                                                    <Button
                                                        className={baseClasses.capitalized}
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={handleAddButton}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                                <div style={{ textAlign: "center", color: "red" }}>Not ready yet, coming soon...</div>
                                            </div> */}
                                            {/* <DataTable
                                                data={_setRowAndColumnContact()}
                                                onOpen={handleAddButton}
                                                onEdit={(params) => { handleEditData(params.row) }}
                                                onDelete={(params) => {
                                                    let index = params.row.id;
                                                    setIsOpenDeletePopup(index);
                                                }}
                                                multiView
                                            /> */}
                                        </TabPanel>

                                        }
                                        {true && <TabPanel
                                            value={value}
                                            index={tabsConst.history}
                                            className={baseClasses.tabRoot}
                                        >
                                            <History
                                                refTable={exploringType}
                                                refId={id}
                                            />
                                        </TabPanel>}

                                        <TabPanel
                                            value={value}
                                            index={tabsConst.clients}
                                            className={baseClasses.tabRoot}
                                            style={{ backgroundColor: theme.palette.background.paper }}
                                        >
                                            <Client />
                                        </TabPanel>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                }
                {/*{value === tabsConst.todo && isOpenCreateModal && (*/}
                {/*    <AddToDo*/}
                {/*        isOpenCreateModal={isOpenCreateModal}*/}
                {/*        onCloseModal={handleCloseModal}*/}
                {/*        editData={editData}*/}
                {/*        clientData={clientData}*/}
                {/*    />*/}
                {/*)}*/}
                {value === tabsConst.contacts && (
                    <AddContact
                        isOpenCreateModal={isOpenCreateModal}
                        onCloseModal={handleCloseModal}
                        editData={editData}
                        clientData={clientData}
                    />
                )}
                {value === tabsConst.progress && isOpenCreateModal && (
                    <AddProgress
                        isOpenCreateModal={isOpenCreateModal}
                        onCloseModal={handleCloseModal}
                        editData={editData}
                        clientData={clientData}
                        dateRange={monthlyProgressDateRange}
                        getEditData={getProgressNoteEditData}
                        tab={progressPage}
                        setMonthlyProgressDateRange={setMonthlyProgressDateRange}
                        refreshTemplateNoteList={refreshTemplateNoteList}
                    />
                )}
                {value === tabsConst.schedule && (
                    <AddSchedule
                        isOpenCreateModal={isOpenCreateModal}
                        onCloseModal={handleCloseModal}
                        editData={editData}
                    />
                )}
                {/*TODO: This dialog will never open. DO we need this code here? */}
                <Dialog
                    maxWidth={maxWidth}
                    fullWidth={fullWidth}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle
                        className={baseClasses.dialogTitle}
                        id="max-width-dialog-title"
                    >
                        Add Schedule
                    </DialogTitle>
                    <DialogContent>
                        <div fullWidth>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Schedule Type
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    value={scheduleType}
                                    onChange={handleScheduleType}
                                >
                                    <MenuItem value="service">Service</MenuItem>
                                    <MenuItem value="lesson">Lesson</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {scheduleType === "service" ? (
                            <div>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-mutiple-checkbox-label">
                                        Service
                                    </InputLabel>
                                    <Select
                                        labelId="demo-mutiple-checkbox-label"
                                        id="demo-mutiple-checkbox"
                                        multiple
                                        value={personName}
                                        onChange={handleChangeService}
                                        input={<Input />}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {weekDays.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={personName.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        ) : (
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Lesson
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    value={schedulePeriod}
                                    onChange={handleSchedulePeriod}
                                >
                                    <MenuItem value="daily">Daily</MenuItem>
                                    <MenuItem value="weekly">Weekly</MenuItem>
                                    <MenuItem value="yearly">Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Save
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* { */}
                {/* {isToolTip && global.log(xPos)} */}
                {/* TODO: This section of code will never execute  */}
                {false && (
                    <div
                        className="menu-container"
                        style={{
                            top: yPos,
                            left: xPos,
                            position: "absolute",
                            width: 200,
                            backgroundColor: "#707070",
                            borderRadius: 5,
                            padding: "2px 7px 4px 7px",
                            color: "white",
                            fontSize: "12px",
                        }}
                    >
                        {"this is a tooltip"}
                    </div>
                )}
                <DeletePopup
                    open={isOpenDeletePopup != -1}
                    onClose={setIsOpenDeletePopup.bind(this, -1)}
                    onConfirm={() => {
                        if (value === tabsConst.todo) {
                            removeClientTodo(isOpenDeletePopup).then(() => {
                                getClientToDoDetail({ module: 'clients', primaryId: id }).then((response) => {
                                    setClientToDoData(response);
                                });
                            });
                        } else if (value === tabsConst.progress) {
                            removeClientNote(isOpenDeletePopup).then(() => {
                                var clientName = _.has(clientData, "firstName")
                                    ? clientData?.firstName + " " + clientData?.lastName
                                    : "";
                                getClientNotesList(id, clientName).then((response) => {
                                    setClientNotesData(response);
                                });
                            });
                        } else if (value === tabsConst.contacts) {
                            removeContactRel(isOpenDeletePopup).then(() => {
                                if (exploringType === explorerTypesConst.yeshivas) {
                                    getContactRelDetail(explorerTypesConst.yeshivas, id).then((response) => {
                                        setClientContactData(response);
                                    });
                                } else {
                                    getContactRelDetail(explorerTypesConst.clients, exploringId).then(
                                        (response) => {
                                            setClientContactData(response);
                                        }
                                    );
                                }
                            });
                        }
                        setIsOpenDeletePopup(-1);
                    }}
                    canConfirm={true}
                    id={id}
                    item={value}
                    action="remove"
                />

                {/*</ThemeProvider>*/}
            </main>
        </>
    );
}