import { makeStyles } from "@mui/styles";


export default makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        '& .MuiTabs-indicator': {
            backgroundColor: '#FFFFFF',
        },
        '& .MuiCardContent-root:last-child': {
            padding: '0px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-row': {
            cursor: 'pointer',
            fontSize: '12px'
        },
        '& .MuiDataGrid-root .MuiDataGrid-colCellWrapper': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none !important',
        },
        '& .MuiDataGrid-root': {
            border: "0px"
        }
    },
    
}));

