import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from "easy-peasy";
import { Typography, Grid } from "@mui/material";
import moment from 'moment';

import useStyles from "../styles";
import usePermission from "../../hooks/usePermission";
import { fetchOutstandingNotes } from "../../api/clients";
import { TIMESHEET_USAGE_START } from '../../config/featuresUsageStartDates';
import useFetcher from '../../hooks/useFetcher';
import useInterval from '../../hooks/useInterval'
import * as constant from '../../constants';
import baseStyles from '../../assets/styles';
import SCHEDULE_PERIODICITY_CONSTANTS from '../../_helpers/constants/schedule_periodicity_constants';
import { getScheduleStringForProfessional } from '../../_helpers/schedule';
import { getClientToDoDetail, getMeargenWithMonthlyStatus } from '../../api/clients';
import { getDashboardSchedules, getDashboardCards } from '../../api/dashboard';
import { getUnapprovedTimesheetList } from '../../api/timesheet';
import CardTab from "../../components/common/Tabs/CardTab/CardTab";
import { HDate } from '@hebcal/core';
import CustomProgressBar from "../../components/common/CustomProgressBar";
import { hebrewMonthNames, HebrewMonthNamesLeapYear } from "../../_helpers/hebrewDate/constants";
import Loader from "../../components/common/Loader";
import { numbers } from "../../_helpers/numbers";

const getWeekdaysString = (weekdays, includeAndStringBetweenLastDays = false) => {
    return global.dateTime.getWeekdaysString(weekdays, includeAndStringBetweenLastDays);
}

const yungermenDataMapping = (d) => {
    return {
        clientScheduleId: d.clientScheduleId,
        createdOn: d.createdOn,
        startTime: global.dateTime.filterDefaultDate(d.startTime),
        endTime: global.dateTime.filterDefaultDate(d.endTime),
        providerName: d.providerName,//
        clientName: d.clientName,//
        providerId: d.providerId,//
        clientId: d.clientId,//
        schedule: d.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ? getWeekdaysString(d.days) : "", //
        scheduleDisplayName: getScheduleStringForProfessional(d.days, d.everyXOfPeriods, d.periodicity),
        startDate: global.dateTime.filterDefaultDate(d.startDate),
        endDate: global.dateTime.filterDefaultDate(d.endDate),
        rate: d.rate,
        ratePeriod: d.ratePeriod,
        type: d.type,
        note: d.note,
        reasonScheduleEnded: d.reasonScheduleEnded,
        reasonForRejection: d.reasonForRejection,
        matura: "",
        meargen: d.meargenName,
        yeshiva: d.yeshiva,
        status: d.status,
        dateApproved: d.dateApproved,
        specialties: d.specialties,
        //providerScheduleStatus: d.providerScheduleStatus, not really needed
        //nextSession: d.nextSession, //might only be needed for professional schedules // not yet available
        professionalType: d.professionalType,//
        clientEmployeeSchedulePotentials: !d.clientEmployeeSchedulePotentials ? [] : d.clientEmployeeSchedulePotentials.map(cesp => ({
            providerName: cesp.providerName,
            status: cesp.status,
        })),
    }
}

const includedStatusPerTab = {
    [constant.tabsConst.financialSchedule]: ['financial', 'rejected'],
    [constant.tabsConst.seekingSchedule]: ['seeking', 'onhold'],
}

export default function Dashboard(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const baseClasses = baseStyles();
    const userId = global.account.userValue.id;
    const [progressData, setProgressData] = React.useState([]);
    const [meargensData, setMeargensData] = React.useState([]);
    const [progressLoading, setProgressLoading] = React.useState(false);
    const [timesheetLoading, setTimesheetLoading] = React.useState(false);
    const [progressGroupData, setProgressGroupData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [scheduleData, yungermenLoading, yungermenError] = useFetcher(constant.CLIENT_SCHEDULE_GET_LIST_BY_USER + `${userId}?scheduleStatus=seeking,onhold,financial,rejected`);
    const [clientToDoData, setClientToDoData] = React.useState([]);
    const [tabValue, setTabValue] = React.useState();
    const exploringType = useStoreState((state) => state.exploringType);
    const [timesheetData, setTimesheetData] = React.useState([]);
    const [dateRanges, setDateRanges] = React.useState([]);
    const activeScheduleList = useStoreState((state) => state.dashboard.activeShedules);
    const paymentTypeSchedules = useStoreState((state) => state.dashboard.paymentTypeSchedules);
    const clientWithYungerleitServiceList = useStoreState((state) => state.dashboard.clientWithYungerleitList);
    const clientWithProfessionalList = useStoreState((state) => state.dashboard.clientWithProfessionalList);
    const clientScheduleStatusList = useStoreState((state) => state.dashboard.clientScheduleStatusList);

    const exploringId = useStoreState((state) => state.exploringId);
    const todos = useStoreState((state) => state.dashboard.todos);
    const setTodos = useStoreActions((actions) => actions.dashboard.setTodos);
    const getDashboardCardsInfo = useStoreActions((actions) => actions.dashboard.getDashboardCardsInfo);
    const getDashboardStatusStpDurationAvg = useStoreActions((actions) => actions.dashboard.getDashboardStatusStpDurationAvg);
    const getStatusStepDurationData = useStoreActions((actions) => actions.dashboard.getStatusStepDurationData);
    const statusStepDurationData = useStoreState((state) => state.dashboard.statusStepDuration)
    const [statusStpLoading, setStatusStpLoading] = useState(false);
    const dashbaordCards = useStoreState((state) => state.dashboard.dashboardCards);
    const scheduleStatusAverage = useStoreState((state) => state.dashboard.statusStepDurationAvg);
    const cardsLoading = useStoreState((state) => state.dashboard.loading);
    const getClientServiceList = useStoreActions((actions) => actions.dashboard.getDashboardActiveClient);
    const getActiveSchedules = useStoreActions((actions) => actions.dashboard.getDashboardActiveSchedules);
    const getPaymentTypeSchedules = useStoreActions((actions) => actions.dashboard.getPaymentTypeSchedules);
    const getClientScheduleStatusList = useStoreActions((actions) => actions.dashboard.getClientScheduleStatusList);
    const [filteredScheduleRows, setFilteredScheduleRows] = React.useState([]);
    const [scheduleFilterValue, setScheduleFilterValue] = React.useState("yungerman");
    const [filterBy, setFilterBy] = useState("assignee");
    const [showCompleted, setShowCompleted] = useState(false);
    const [scheduleRows, setScheduleRows] = React.useState([]);

    const schedulePermissions = usePermission(global.permissions.dashboard.schedules.seeking);
    const financialPermissions = usePermission(global.permissions.dashboard.schedules.financial);
    const activePermissions = usePermission(global.permissions.dashboard.schedules.active);
    const yungermanServicePermissions = usePermission(global.permissions.dashboard.clientServices.yungerman);
    const professionalServicePermissions = usePermission(global.permissions.dashboard.clientServices.professional);
    const monthlyTasksPermissions = usePermission(global.permissions.dashboard.monthlyTasks);
    const timesheetPermissions = usePermission(global.permissions.dashboard.timesheet);

    const getMonthHebName = (month, isLeapYear, useTishreiMonths = false) => {
        let HebMonths = isLeapYear ? HebrewMonthNamesLeapYear : hebrewMonthNames
        let monthObj = useTishreiMonths ? HebMonths[month - 1] : HebMonths.find(object => object.chodesh === month);

        return monthObj.label;
    }

    const sortMonthlyProgress = (arr1, arr2) => {
        //sort first by client name
        if (arr1[0].clientName > arr2[0].clientName)
            return 1;
        else if (arr1[0].clientName < arr2[0].clientName)
            return -1;
        // if the same, sort by month
        if (arr1[0].monthIndex > arr2[0].monthIndex)
            return -1;
        else if (arr1[0].monthIndex < arr2[0].monthIndex)
            return 1
        else// nothing to split them
            return 0;
    }

    const loadProgressData = () => {
        setProgressLoading(true)
        getMeargenWithMonthlyStatus().then((data) => {
            setProgressData(data);
            setProgressLoading(false);
        })
        let startDate = new Date(2022, 4, 2);

        // fetchOutstandingNotes(startDate).then((response) => {
        //     let data = response.notes.map(note => {
        //         return {
        //             ...note,
        //             hebMonthName: response.hebrewMonthRanges[note.monthIndex]?.hebMonthName,
        //             hebYearName: response.hebrewMonthRanges[note.monthIndex]?.hebYearName
        //         }
        //     })

        //     // setProgressData(data);
        //     setMeargensData(response.meargens);

        //     // Filtering data according with meargens
        //     data = global.arrays.filterArrayWithProperty(data, "meargen_ContactId", response.meargens[0].assignee_ContactId)
        //     setProgressLoading(false);
        //     setProgressGroupData(global.arrays.groupByProperties({ Group: data, By: ["clientId", 'monthIndex'] }).sort((a, b) => sortMonthlyProgress(a, b)))
        // })
        // Implement Api call here
    }

    /**
     * For loading unaproved timeshet data
     * @param {*} date
     */
    const loadTimesheetData = (date) => {
        setTimesheetLoading(true);
        getUnapprovedTimesheetList(date).then((data) => {
            let tsheet = data.timesheets.map((row) => {
                let hebMonth = data.monthRanges.find((range) => row.monthIndex === range.monthIndex);
                return {
                    ...row,
                    hebMonthYear: hebMonth.hebMonthName + ' ' + hebMonth.hebYearName
                }
            })
            setTimesheetData(tsheet);
            setDateRanges(data.monthRanges);
            setTimesheetLoading(false)
        })
    }

    useEffect(() => {
        loadProgressData();
        !dashbaordCards && loadDashbaordCards()
        !scheduleStatusAverage && loadSatusStepDurationAvg()
        //TODO: commenting intentionally
        // let date = moment(TIMESHEET_USAGE_START).format('MM/DD/YYYY');
        // loadTimesheetData(date);
    }, [])

    const loadDashbaordCards = () => {
        setIsLoading(true)
        getDashboardCardsInfo().then((data) => {
            setIsLoading(false)
        })
    }

    const loadSatusStepDurationAvg = () => {
        setStatusStpLoading(true)
        getDashboardStatusStpDurationAvg().then((data) => {
            setStatusStpLoading(false)
        })
    }

    const refreshDasboardCards = () => {
        getDashboardCardsInfo();
        getDashboardStatusStpDurationAvg()
    }

    const getFilteredRows = (scheType, includeStatuses) => {
        return scheduleRows
            .sort((a, b) => global.dateTime.getDateObject(b.startDate) - global.dateTime.getDateObject(a.startDate))
            .filter((row) =>
                (!scheType || scheType === row.type)
                && (!includeStatuses || includeStatuses.length < 1 || (row.status !== undefined && includeStatuses.includes(row.status)))
            )
    }

    // useEffect(() => {
    //     if (scheduleData?.length) {
    //         let schedules = scheduleData.map((d) => {
    //             return yungermenDataMapping(d);
    //         });
    //         setScheduleRows(schedules);

    //     }
    // }, [scheduleData, yungermenLoading]);

    // useEffect(() => {
    //     //filter rows by status, type every time the tab, or the filter drop-down value is changed
    //     setFilteredScheduleRows(getFilteredRows(scheduleFilterValue, includedStatusPerTab[tabValue]))
    // }, [scheduleFilterValue, scheduleRows, tabValue])

    useEffect(() => {
        todos?.length <= 0 && loadData();
        // clientWithYungerleitServiceList.length <= 0 && getClientServiceList("yungerman");
        // clientWithProfessionalList.length <= 0 && getClientServiceList("professional");
        // activeScheduleList.length <= 0 && getActiveSchedules();
        // clientScheduleStatusList.length <= 0 && getClientScheduleStatusList();
        // paymentTypeSchedules.length <= 0 && getPaymentTypeSchedules({paymentType:constant.PAYMENT_TYPE.all, onGoingStatus:constant.ON_GOING_STATUS.all});
    }, [])
    /**
     * 
     * @param {*} id - User Id
     */

    const refreshScheduleData = (id) => {
        getDashboardSchedules(id).then((data) => {
            let schedules = data.map((d) => {
                return yungermenDataMapping(d);
            });
        })
    }

    const loadData = () => {
        let module;
        let options = {
            module: module,
            primaryId: exploringId,
            userId: userId,
            showCompleted: showCompleted,
            filterBy: filterBy,
        }
        // setLoading()
        getClientToDoDetail(options).then((response) => {
            setTodos(response);
            // endLoading()
        });
    }

    /**
     * Refresh schedule data after every 5 min
     */
    // useInterval(() => {
    //     refreshScheduleData(userId)
    // }, 300000)

    /**
     * Refresh cards info after every after 10min
     */
    useInterval(() => {
        refreshDasboardCards()
    }, 600000)

    /**
 * @returns client scheduled cards
 */

    const handleTabClick = (tab) => {
        navigate(`/dashboard/${tab}`)
        setTabValue(tab);
    }

    const getScheduleCount = (scheStatus, scheType) => {
        return scheduleRows?.length > 0 ? scheduleRows.filter((row) => scheStatus.some(st => st === row.status) && row.type === scheType).length : 0;
    }

    const hasSchedules = (scheStatus, scheType) => getScheduleCount(scheStatus, scheType) > 0;

    const getDueTaskCount = (dueType) => {
        // TODO: Commenting with reason, we might need this in feature
        // const tasks = dashbaordCards?.tasks
        // if (!!tasks)
        //     return tasks[dueType]

        let dueTasks = 0;

        switch (dueType) {
            case 'today':
                dueTasks = todos?.filter(x => global.dateTime.isToday(new Date(x.dueDate))).length;
                break;
            case 'week':
                dueTasks = todos?.filter(x => global.dateTime.isCurrentWeek(new Date(x.dueDate))).length;
                break;
            case 'overdue':
                dueTasks = todos?.filter(x => global.dateTime.isDatePast(x.dueDate)).length;
                break;
            default:
        }
        return dueTasks;
    }

    const getProgressCount = () => {
        // TODO: commenting for future use
        //return dashbaordCards?.progress

        return progressData.length;
    }

    const getTimesheetCount = () => {
        return dashbaordCards?.unapprovedTimesheet;
        // TODO: commenting for future use
        // return timesheetData.length;
    }

    const getSeekingScheValues = () => {
        return [
            { label: 'Yungerleit', value: [dashbaordCards?.seekingSchedules['yungerleit']] },
            { label: 'Professionals', value: [dashbaordCards?.seekingSchedules['professionals']] }
        ]

        //let values = [];
        // if (hasSchedules(['seeking'], 'yungerman'))
        //     values.push({ label: 'Yungerleit', value: [getScheduleCount(['seeking'], 'yungerman')] })
        // if (hasSchedules(['seeking'], 'professional'))
        //     values.push({ label: 'Professionals', value: [getScheduleCount(['seeking'], 'professional')] })
        // return values;
    }

    const getHoldScheValues = () => {
        return [
            { label: 'Yungerleit', value: [dashbaordCards?.onHoldSchedules['yungerleit']] }
        ]
        // let values = []
        // if (hasSchedules(['onhold'], 'yungerman'))
        //     values.push({ label: 'Yungerleit', value: [getScheduleCount(['onhold'], 'yungerman')] })
        // if (hasSchedules(['onhold'], 'professional'))
        //     values.push({ label: 'Professionals', value: [getScheduleCount(['onhold'], 'professional')] })
        // return values;
    }

    const getFinancialScheValues = () => {
        return [
            { label: 'Yungerleit', value: [dashbaordCards?.pendingFinancialSchedules['yungerleit']] },
            { label: 'Professionals', value: [dashbaordCards?.pendingFinancialSchedules['professionals']] },
            { label: 'Appointments', value: [dashbaordCards?.pendingFinancialSchedules['appointments']] }
        ]
        // let values = []
        // if (hasSchedules(['financial'], 'yungerman'))
        //     values.push({ label: 'Yungerleit', value: [getScheduleCount(['financial'], 'yungerman')] })
        // if (hasSchedules(['financial'], 'professional'))
        //     values.push({ label: 'Professionals', value: [getScheduleCount(['financial'], 'professional')] })
        // if (hasSchedules(['financial'], 'appointment'))
        //     values.push({ label: 'Appointments', value: [getScheduleCount(['financial'], 'appointment')] })
        // return values;
    }

    const getRejectedScheValues = () => {
        return [
            { label: 'Yungerleit', value: [dashbaordCards?.rejectedSchedules['yungerleit']] },
            { label: 'Professionals', value: [dashbaordCards?.rejectedSchedules['professionals']] },
            { label: 'Appointments', value: [dashbaordCards?.rejectedSchedules['appointments']] }
        ]
        // let values = []
        // if (hasSchedules(['rejected'], 'yungerman'))
        //     values.push({ label: 'Yungerleit', value: [getScheduleCount(['rejected'], 'yungerman')] })
        // if (hasSchedules(['rejected'], 'professional'))
        //     values.push({ label: 'Professionals', value: [getScheduleCount(['rejected'], 'professional')] })
        // if (hasSchedules(['rejected'], 'appointment'))
        //     values.push({ label: 'Appointments', value: [getScheduleCount(['rejected'], 'appointment')] })
        // return values;
    }

    const getActiveScheduleCount = (type) => {
        return dashbaordCards?.activeSchedules[type]
        // return activeScheduleList.filter(x => !type || x.type === type).length
    }

    const getActiveSchePaymentTypeCount = (type) => {
        return dashbaordCards?.schedulesByPaymentType[type]
        // return paymentTypeSchedules.filter(x => !type ||  x.paymentType.toLowerCase() === type).length
    }

    const getClientScheduleStatusCount = (filter) => {
        return dashbaordCards?.talmidimMamidimStatus[filter]
        // let count = 0;
        // switch (filter) {
        //     case 'clients':
        //         count = clientScheduleStatusList.length
        //         break;
        //     case 'withSchedules':
        //         count = clientScheduleStatusList?.filter(x => x.sumCt > 0).length;
        //         break;
        //     case 'withOutSchedules':
        //         count = clientScheduleStatusList?.filter(x => x.sumCt < 1).length;
        //         break;
        //     default:
        // }
        // return count;
    }

    const getClientWithProfessionalCount = (filter) => {
        return dashbaordCards?.talmidimWithProfessional[filter]
        // let count = 0;
        // switch (filter) {
        //     case 'clients':
        //         count = clientWithProfessionalList.length
        //         break;
        //     case 'withProfessionals':
        //         count = clientWithProfessionalList?.filter(x => x.servicesCount > 0).length;
        //         break;
        //     case 'withOutProfessionals':
        //         count = clientWithProfessionalList?.filter(x => x.servicesCount < 1).length;
        //         break;
        //     default:
        // }
        // return count;
    }

    const getClientWithYungerleitCount = (filter) => {
        return dashbaordCards?.talmidimWithYungerleit[filter]
        // let count = 0;
        // switch (filter) {
        //     case 'clients':
        //         count = clientWithYungerleitServiceList.length;
        //         break;
        //     case 'withYungerleit':
        //         count = clientWithYungerleitServiceList.filter(x => x.servicesCount > 0).length;
        //         break;
        //     case 'withOutYungerleit':
        //         count = clientWithYungerleitServiceList.filter(x => x.servicesCount < 1).length;
        //         break;
        //     default:
        //}
        //return count;
    }

    const getMonthlyProgressPercent = () => {
        let months = []

        if (progressData.length) {
            months = progressData[0].monthlyStatuses?.map((month) => {
                let sum = 0;
                progressData.forEach((m) => {
                    sum += (m.monthlyStatuses.find((item) => item.monthIndex/*TODO*/ === month.monthIndex).percentCompleted * 100) ?? 0;
                })
                sum = Math.round(sum / meargensData.length);
                return { monthIndex: month.monthIndex, year: month.year, percentCompleted: sum }
            })

        }
        return months || [];
    }

    const getStatusStepDuration = (field) => {
        if (!scheduleStatusAverage?.result?.statusStepDurationAverages) return `0 days`;
        let days = scheduleStatusAverage.result.statusStepDurationAverages[field];
        days = numbers.roundNumber(days ?? 0, 1);
        return `${days} days`
    }

    const xl = 2.3;
    const md = 3;
    const sm = 6;
    const xs = 12;

    return (
        <main className={classes.content}>
            <div className={baseClasses.toolbar} style={{ position: 'relative' }} />
            {/* <Loader loading={cardsResLoading} /> */}
            <Grid container xs={12}>
                <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                    <CardTab
                        handleTabClick={handleTabClick}
                        value={constant.tabsConst.task}
                        activeTab={tabValue}
                        loading={isLoading}
                        title="Tasks"
                        subTitles={[
                            { label: 'Overdue', value: [` ${getDueTaskCount('overdue') ?? '-'} tasks`] },
                            { label: 'Due today', value: [` ${getDueTaskCount('today') ?? '-'} tasks`] },
                            { label: 'Due this week', value: [` ${getDueTaskCount('week') ?? '-'} tasks`] },
                        ]}
                    />
                </Grid>


                {activePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.activeSchedule}
                            activeTab={tabValue}
                            title="Active Schedules"
                            loading={isLoading}
                            subTitles={[
                                { label: 'Yungerliet', value: [getActiveScheduleCount('yungerman')] },
                                { label: 'Professionals', value: [getActiveScheduleCount('professional')] },
                                { label: 'Total', value: [getActiveScheduleCount('total')] },
                            ]}
                            defaultText={"No active schedules"}
                        />
                    </Grid>
                )}
                {activePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.statusStepDuration}
                            activeTab={tabValue}
                            title="Status Step Duration"
                            loading={statusStpLoading}
                            subTitles={[
                                { label: 'Seeking', value: [getStatusStepDuration('seeking')] },
                                { label: 'Financial', value: [getStatusStepDuration('financial')] },
                                [
                                    { label: 'Comfort', value: [getStatusStepDuration('financialComfort')] },
                                    { label: 'Cash', value: [getStatusStepDuration('financialCash')] },
                                ],
                            ]}
                            defaultText={"No Status Step Duration"}
                        />
                    </Grid>
                )}
                {activePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.paymentTypeSchedule}
                            activeTab={tabValue}
                            title="Schedules by Payment Type"
                            loading={isLoading}
                            subTitles={[
                                { label: 'Cash', value: [getActiveSchePaymentTypeCount(constant.PAYMENT_TYPE.cash)] },
                                { label: 'Comfort', value: [getActiveSchePaymentTypeCount(constant.PAYMENT_TYPE.comfort)] },
                                { label: 'Total', value: [getActiveSchePaymentTypeCount('total')] },
                            ]}
                            defaultText={"No active schedules"}
                        />
                    </Grid>
                )}
                {yungermanServicePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.clientsWithYungerleit}
                            activeTab={tabValue}
                            title="Talmidim with yungetleit"
                            loading={isLoading}
                            subTitles={[
                                { label: 'Talmidim', value: [getClientWithYungerleitCount("talmidim")] },
                                { label: 'Talmidim with Yungerleit', value: [getClientWithYungerleitCount("talmidimWithYungerleit")] },
                                { label: 'Talmidim without Yungerleit', value: [getClientWithYungerleitCount("talmidimWithoutYungerleit")] },
                            ]}
                            defaultText={"No Talmidim"}
                        />
                    </Grid>
                )}

                {professionalServicePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.clientsWithProfessionals}
                            activeTab={tabValue}
                            title="Talmidim with professionals"
                            loading={isLoading}
                            subTitles={[
                                { label: 'Talmidim', value: [getClientWithProfessionalCount("talmidim")] },
                                { label: 'Talmidim with Professionals', value: [getClientWithProfessionalCount("talmidimWithProviders")] },
                                { label: 'Talmidim without Professionals', value: [getClientWithProfessionalCount("talmidimWithoutProviders")] },
                            ]}
                            defaultText={"No Talmidim"}
                        />
                    </Grid>
                )}

                {professionalServicePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.clientScheduleStatus}
                            activeTab={tabValue}
                            title="Talmidim - Mamidim Status"
                            loading={isLoading}
                            subTitles={[
                                { label: 'Talmidim', value: [getClientScheduleStatusCount("talmidim")] },
                                { label: 'Talmidim with schedules', value: [getClientScheduleStatusCount("talmidimWithSchedules")] },
                                { label: 'Talmidim without schedules', value: [getClientScheduleStatusCount("talmidimWithoutSchedules")] },
                            ]}
                            defaultText={"No Talmidim"}
                        />
                    </Grid>
                )}

                {schedulePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.seekingSchedule}
                            activeTab={tabValue}
                            title="Seeking Schedules"
                            loading={isLoading}
                            subTitles={getSeekingScheValues()}
                            defaultText={"No seeking schedules"}
                        />
                    </Grid>
                )}
                {schedulePermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.onHoldSchedule}
                            activeTab={tabValue}
                            title="On-Hold Schedules"
                            loading={isLoading}
                            subTitles={getHoldScheValues()}
                            defaultText={"No on-hold schedules"}
                        />
                    </Grid>
                )}

                {financialPermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.financialSchedule}
                            activeTab={tabValue}
                            loading={isLoading}
                            title="Pending Financial Schedules"
                            subTitles={getFinancialScheValues()}
                            defaultText={"No pending financial schedules"}
                        />
                    </Grid>
                )}
                {financialPermissions.canView && (
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.rejectedSchedule}
                            activeTab={tabValue}
                            loading={isLoading}
                            title="Rejected Schedules"
                            subTitles={getRejectedScheValues()}
                            defaultText={"No rejected schedules"}
                        />
                    </Grid>
                )}
                {/*  */}
                {monthlyTasksPermissions.canView &&
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.progress}
                            activeTab={tabValue}
                            loading={progressLoading}
                            title="Progress"
                            subTitles={[
                                { label: 'Pending', value: [` ${getProgressCount()} Tasks remaining`] }
                            ]}
                            alignToBottom
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                position: "absolute",
                                right: "10px",
                                bottom: "10px",
                            }}>
                                {getMonthlyProgressPercent().map((i) => (
                                    <div style={{
                                        paddingRight: '10px',
                                        paddingLeft: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        <Typography fontSize={12}>{getMonthHebName(i.monthIndex, new HDate(1, 1, i.year).isLeapYear(), true)}</Typography>
                                        <CustomProgressBar
                                            showLabel={true}
                                            progressPercent={i.percentCompleted}
                                            color="#fff"
                                            progressType='circular'
                                        />
                                    </div>

                                ))

                                }
                            </div>
                        </CardTab>
                    </Grid>
                }

                {timesheetPermissions.canView &&
                    <Grid item xs={xs} sm={sm} md={md} xl={xl}>
                        <CardTab
                            handleTabClick={handleTabClick}
                            value={constant.tabsConst.timesheet}
                            activeTab={tabValue}
                            loading={isLoading}
                            title="Timesheet"
                            subTitles={[
                                { label: 'Pending', value: [` ${getTimesheetCount()} Timesheets remaining`] }
                            ]}
                            alignToBottom
                        />
                    </Grid>
                }
            </Grid>
        </main>
    )
}

