import React from "react";
import TInputCreditCard from "./TInputCreditCard";
import TextField from "@mui/material/TextField";

const TTextFieldCreditCard = (props) => {
    const [values, setValues] = React.useState({
        creditCardValue: props.value,
    });

    const handleChange = (event) => {
        setValues((prevState) => {
            return {
                ...prevState,
                creditCardValue: event.target.value
            }
        });
        props.onChange(event);
    };

    return (
        <TextField
            label={props.label}
            value={values.creditCardValue}
            fullWidth={props.fullWidth}
            onChange={handleChange}
            name={`cc__${props.name}`}
            id={`id_cc__${props.name}`}
            InputProps={{
                inputComponent: TInputCreditCard,
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
};

export default TTextFieldCreditCard;
