import {
    Dialog, FormControl,
    Input
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { contactCMSList } from "../../api/clients";
import DlgCreateNewContract from "./PanelNewContact";
import baseStyles from '../../assets/styles';
import useStyles from "./styles";
/**
 * props : handleClose(); handleAdded()
 */


const ContactSelectInput = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [searchText, setSearchText] = React.useState("");
    const [contactList, setContactList] = React.useState([]);
    const [filterContactList, setFilterContactList] = React.useState([]);
    const [inputMode, setInputMode] = React.useState("selectExisting");
    //   const [inputMode, setInputMode] = React.useState("createNewContact");
    const [isOpenNewContact, setIsOpenNewContact] = React.useState(false);

    useEffect(() => {
        reloadContactList();
    }, []);

    const reloadContactList = () => {
        contactCMSList().then((response) => {
            setContactList(response);
            setFilterContactList(response);
        });
    };

    const handInputMode = (e) => {
        setInputMode(e.target.value);
    };

    const handleSelectRow = (contact) => {
        props.handleSelectRow(contact);
    };

    const handleSearchFilter = (e) => {
        const searchQuery = e.target.value;
        setSearchText(searchQuery);
        const filteredData = contactList.filter((item) => {
            const contactName = `${item.firstName} ${item.lastName}`;
            return contactName.toLowerCase().includes(searchQuery.toLowerCase());
        });
        setFilterContactList(filteredData);
        e.target.focus();
    };

    const handleClose = () => {
        setSearchText("");
        setFilterContactList(contactList);
        props.handleClose();
    };

    const ComSearchInput = () => {
        return (
            <Input
                type="search"
                name="search"
                id="exampleSearch"
                value={searchText}
                autoFocus
                onChange={handleSearchFilter}
                placeholder="Please input to search a contact"
            />
        );
    };

    const DlgSelectExisting = () => {
        return (
            <>
                <div className={clsx(baseClasses.fixHeight,baseClasses.overflowAuto )} style={{ paddingRight: "20px" }}>
                    <div>
                        <h5>Available Contacts</h5>
                        <div style={{ display: "flex" }}>
                            <FormControl
                                className={clsx(classes.fixbar, classes.formControl)}
                                style={{ width: "70%" }}
                            >
                                {/* <FormControl> */}

                                <ComSearchInput />
                            </FormControl>
                            <div style={{ flex: 1 }}></div>
                            <Button
                                className={classes.save}
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    setIsOpenNewContact(true);
                                }}
                            >
                                Add a contact
              </Button>
                        </div>
                        <div className={classes.contactRowWrapper}>
                            {!_.isEmpty(filterContactList) ? (
                                filterContactList.map((contact, index) => {
                                    if (
                                        !_.isEmpty(contact.firstName) &&
                                        contact.firstName !== null
                                    ) {
                                        return (
                                            <Card
                                                className={classes.contactRow}
                                                key={index}
                                                onClick={() => handleSelectRow(contact)}
                                            >
                                                <Col>{`${contact.firstName} ${contact.lastName}`}</Col>
                                            </Card>
                                        );
                                    }
                                    return null;
                                })
                            ) : (
                                <Row className={classes.textCenter}>No Data Found</Row>
                            )}
                        </div>
                    </div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={"sm"}
                        open={isOpenNewContact}
                        onClose={() => {
                            setIsOpenNewContact(false);
                        }}
                        aria-labelledby="max-width-dialog-title"
                    // style={{padding:20}}
                    >
                        <div style={{ padding: 20 }}>
                            <DlgCreateNewContract
                                handleClose={() => {
                                    setIsOpenNewContact(false);
                                }}
                                handleAdded={() => {
                                    setIsOpenNewContact(false);
                                    reloadContactList();
                                }}
                            ></DlgCreateNewContract>
                        </div>
                    </Dialog>
                </div>
            </>
        );
    };

    return (
        <>
            <div style={{ width: 560 }}>
                <FormControl
                    className={clsx(classes.fixbar, classes.formControl, "mb-3")}
                ></FormControl>
                <DlgSelectExisting></DlgSelectExisting>
                {/* <FormControl
          className={clsx(classes.fixbar, classes.formControl, "mb-3")}
        >
          <h6>Input Mode</h6>

          <Select
            id="schedule-specialty-title"
            value={inputMode}
            onChange={handInputMode}
          >
            <MenuItem value="selectExisting">Select Existing</MenuItem>
            <MenuItem value="createNewContact">Create New Contact</MenuItem>
          </Select>
        </FormControl>
        {inputMode == "selectExisting" ? (
          <DlgSelectExisting></DlgSelectExisting>
        ) : (
          <DlgCreateNewContract
            handleClose={handleClose}
          ></DlgCreateNewContract>
        )} */}
            </div>
        </>
    );
};

export default ContactSelectInput;
