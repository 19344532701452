import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Tooltip, IconButton, Button, TextField, Typography, FormControlLabel, Chip, Switch, Divider, Select, MenuItem, FormControl, ListItemIcon, ListItemText, Menu, Fade, InputLabel, Dialog, DialogContent, Box, Autocomplete } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import clsx from 'clsx';
import { DataGridPro, GridOverlay, GridToolbar, GridToolbarContainer, GridRowModes, GridToolbarExport, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton, getGridStringOperators, getRowsToExport, getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import { makeStyles } from "@mui/styles";
import { darken, lighten, styled } from '@mui/material/styles';
import useStyles, { TitleContainer, Title, ActionsContainer } from "./data_table_styles";
import useBaseStyles from '../../assets/styles';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PauseIcon from '@mui/icons-material/Pause';
import CloudDownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/Upload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ArticleIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import AdjustIcon from '@mui/icons-material/Adjust';
import LinearProgress from '@mui/material/LinearProgress';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { useStoreState, useStoreActions } from "easy-peasy";
import _ from "lodash";
import useConfirm from '../../hooks/useConfirm';
import { strings } from '../../_helpers/strings';

import FRONTEND_ROUTE_CONSTANTS from "../../frontend_route_constants";
import { clientScheduleStatus, periodicities, } from "../../_helpers/constants/scheduleConstants";
import usePermission from "../../hooks/usePermission";
import LoadingBar from '../LoadingBar/LoadingBar'
import { saveSetting, getSettingByGroup } from '../../api/system';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddIcon from '@mui/icons-material/Add';
import TextFormattingWrapper from '../../utils/TextFormattingWrapper';
import ViewDetailsDialog from '../../components/UI/ViewDetailsDialog';
import DynamicTooltipText from '../common/DynamicTooltipText';
import useActiveToolTip from '../../hooks/useActiveToolTip';
import useEllipsisActive from '../../hooks/useEllipsisActive';
import { employeesScreensArr, SYSTEM_VIEW_STRUCT, defaultQuickFilters, dataGridViewScreens } from "../../constants";
import AddNewView from '../common/Dialogs/AddNewView';
import useDataGridViewHandler from '../../hooks/useDataGridViewHandler';
import { DivContainer } from "../../assets/styles/styles";
import AnimLoadingState from "../common/AnimLoadingState";
import { Person } from "@mui/icons-material";
import { parse } from "date-fns";
LicenseInfo.setLicenseKey(
    '10acb35aa2e374fd9cc8cd62a722e71eT1JERVI6MzY4MzksRVhQSVJZPTE2NzUwMDgyNDEwMDAsS0VZVkVSU0lPTj0x',
);

const ITEM_HEIGHT = 48;

const FeaturedIcon = (props) => {
    const { icon, params, field, onClick, disableClick, screen, fontSize } = props;
    const Icon = () => {
        switch (icon) {
            case "check":
                return <CheckIcon {...props} />;
            case "VpnKey":
                return <VpnKeyIcon {...props} />;
            case "Edit":
                return <EditIcon {...props} />;
            case "Delete":
                return <DeleteIcon {...props} />;
            case "Block":
                return <BlockIcon {...props} />;
            case "Article":
                return <ArticleIcon {...props} />;
            case "MoreVert":
                return <MoreVertIcon {...props} />
            case "CheckOutlined":
                return <CheckOutlinedIcon style={{ color: "green" }} {...props} />
            case "Warning":
                return <WarningIcon style={{ color: "red" }} {...props} />
            case "Clear":
                return <ClearIcon style={{ color: "red" }} {...props} />
            case "Person":
                return <Person {...props} />
            case "InfoOutlined":
                return <InfoOutlinedIcon style={{ opacity: 0.54 }} {...props} />
            case "ExpandMore":
                return <KeyboardArrowDownIcon style={{ opacity: 0.54 }} {...props} />
            case "ExpandLess":
                return <KeyboardArrowUpIcon style={{ opacity: 0.54 }} {...props} />
            case "Download":
                return <CloudDownloadIcon style={{ opacity: 0.54 }} {...props} />
            case "Upload":
                return <CloudUploadIcon style={{ opacity: 0.54 }} {...props} />
            case "Open":
                return <OpenInNewIcon style={{ opacity: 0.54 }} {...props} />
            case "Pause":
                return <PauseIcon style={{ color: "#1976d2" }} {...props} />
            case "PersonSearch":
                return <PersonSearchIcon style={{ color: "#1976d2" }} {...props} />
            default:
                return <AdjustIcon {...props} />;
        }
    }
    const disabledClickStyles = {
        ml: 1,
        "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent"
        },
    };
    return (
        <span>
            <IconButton
                key={params.row.id + "-" + field.toLowerCase()}
                onClick={onClick}
                className="icon"
                fontSize={fontSize}
                style={{ padding: "5px" }}
                elevation={disableClick ? 0 : 1}
                disableRipple={disableClick}
                sx={disableClick && disabledClickStyles}
            >
                <div>
                    <Icon />
                </div>
            </IconButton>
        </span>
    );
}

function CustomLoadingOverlay(props) {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                {
                    props.LoadingOverlay ? props.LoadingOverlay : <LinearProgress />
                }
            </div>
        </GridOverlay>
    );
}

const AutoCompleteInputValue = (props) => {
    const { item, applyValue, focusElementRef } = props;

    const ratingRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            ratingRef.current
                .querySelector(`input[value="${Number(item.value) || ''}"]`)
                .focus();
        },
    }));

    const handleFilterChange = (event, newValue) => {
        applyValue({ ...item, value: newValue });
    };
    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 48,
                pl: '20px',
            }}
        >
            <Autocomplete
                options={item}
                getOptionLabel={(option) => option}
                inputValue={item.value}
                onChange={(event, selectedOption) => handleFilterChange(event, selectedOption)}
                renderInput={(params) => <TextField {...params} />}
            />
        </Box>
    );
}

export default function DataTable(props) {
    const { hideToolbarIconText, title, headerMessage, showAdd, changeScheduleType, showEdit, handleEditClick, handleAddClick, quickFilters, autoHeight, disablePagination } = props;
    const history = useStoreState((state) => state.history);
    const { confirm } = useConfirm();
    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        table: {
        },
        content: {
        },
    }));
    const classes = useStyles();
    const baseClasses = useBaseStyles();
    const [dataGridKey, setDataGridKey] = React.useState(Math.random() * 1000);
    const [anchorElSave, setAnchorElSave] = React.useState(null);
    const openSaveMenu = Boolean(anchorElSave);
    const [xPos, setXPos] = useState("0px");
    const [yPos, setYPos] = useState("0px");
    //columns
    // const columnVisibility = useStoreState((state) => state.dataGridSettings[props.data.screen]?.columnsVisibility)
    const setColumns = useStoreActions((action) => action.dataGridSettings.setColumns);

    //DB state settings
    const dbSettingState = useStoreState((state) => state.dataGridSettings.dbState);
    const setDbStateSettingPerGrid = useStoreActions((action) => action.dataGridSettings.setDbStatePerGrid);
    const setGridSettings = useStoreActions((action) => action.dataGridSettings.setGrid);
    //filters
    // const filterModel = useStoreState((state) => state.dataGridSettings[props.data.screen]?.filterModel)
    const setFilterModel = useStoreActions((action) => action.dataGridSettings.setFilters)
    const [userUpdatedFilters, setUserUpdatedFilters] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAddNewViewOpen, setIsAddNewViewOpen] = useState(false);
    const [editViewData, setEditViewData] = useState(null);
    const [dialogContent, setDialogContent] = useState("");
    const { canView, canRead, canCreate, canEdit, canDelete } = usePermission(props.permissions);
    const ref = useRef();
    const isOverflow = useActiveToolTip(ref);
    const applicants = useStoreState(state => state.applicationDetail);
    const setApplications = useStoreActions((action) => action.applicationRow);

    // View handler hool
    const [dataGridViews, currentView, columnVisibility, filterModel, viewLoading, { systemDefaultView, saveGridView, hanleViewChange, handleChangeColAndFilter, handleDeleteGridView }] = useDataGridViewHandler(props.data.screen, props.data.columns)
    //updates key to remount the component when the search query is changed, to reset the page number
    useEffect(() => {
        setDataGridKey(Math.random() * 1000)
    }, [props.searchQuery, props.data.screen])

    useEffect(() => {
        if (props.multiView) {
            let selectFilter, chipFilters = [];
            for (const property in currentView?.quickFilters) {
                if (currentView.quickFilters[property] === 'true') {
                    chipFilters.push({ filter: property, isActive: true })
                } else if (currentView.quickFilters[property] === 'false') {
                    chipFilters.push({ filter: property, isActive: false })
                } else if (typeof currentView.quickFilters[property] === 'string') {
                    selectFilter = currentView.quickFilters[property]
                }
            }
            switch (quickFilters?.type) {
                case 'select': {
                    quickFilters.dropdowns.map((item, index) => {

                        !!selectFilter && item?.handler(selectFilter, index)
                    })
                    break;
                }
                case 'chip': {
                    quickFilters?.handler(chipFilters)

                    break;
                }
                default: return null
            }
        }
    }, [currentView?.viewName])

    const getDataGridSettings = () => {
        getSettingByGroup('grid').then((list) => {
            list.forEach((item) => {
                const settings = JSON.parse(item.value)
                setDbStateSettingPerGrid({ grid: item.name, model: settings })
                setGridSettings({ grid: item.name, model: settings })
            })
        })
    }

    const getDataGridViewSettings = () => {
        getSettingByGroup('grid').then((list) => {
            list.forEach((item) => {
                const settings = JSON.parse(item.value)
                setDbStateSettingPerGrid({ grid: item.name, model: settings })
                setGridSettings({ grid: item.name, model: settings })
            })
        })
    }

    useEffect(() => {
        if (!dbSettingState) {
            getDataGridSettings();
        }
    }, [])

    useEffect(() => {
        console.log(applicants)
    }, [applicants])

    const handleFilterChange = (model) => {
        let filterModal = { ...model };
        filterModal = {
            ...filterModal,
            items: filterModal.items.map((item) => {
                let tempItem = { ...item, operatorValue: item.operator ?? item.operatorValue, value: item.value ?? undefined }
                delete tempItem.operator;
                return tempItem
            })
        }
        handleChangeColAndFilter(filterModal, 'filter');
        //***Update 5/18/22*** due to update the next if statment isn't necessary anymore
        //currently onFilterModelChange is called on the initial render - only when the filter model has previous filter - with an empty filter array
        //this if statment prevent him from clearing the filters on initial render
        //if (!userUpdatedFilters /*initial render*/ && model.items.length === 0 /*only if currently clearing the filter*/) {
        //    setUserUpdatedFilters(true);
        //    return;
        //}
        //check if this datatable has controlled filters
        if (typeof setFilterModel === 'function') {
            setFilterModel({ filterModal, grid: props?.data?.screen })
        }
    }

    const saveDataGridSettings = (isAddNew = false) => {
        // TODO: commenting this we might enable save menu in future
        // setXPos('0px');
        // setYPos("0px")
        // setAnchorElSave(null)
        if (!isAddNew) {
            saveGridView({ ...currentView, quickFilters: quickFilterToSave() })

        } else {
            const settings = {
                columnSettings: columnVisibility,
                filterSettings: filterModel,
            }
            const data = {
                ...systemDefaultView,
                ...settings
            }
            setIsAddNewViewOpen(isAddNew);
            setEditViewData(data)
        }

    }
    //             setApplications(event.row.data)


    const handleRowClick = (event) => {
        console.log(props.data.screen)
        if (props.data.type == "popup") {
            props.data.click();
        }

        // I save the client Id, when press Ok
        global.clientId = event.row.id;
        if (props.data.screen === "programs") {
            history(`/program/details/${event.row.id}`);
        }
        else if (props.data.screen.trim().toLowerCase() === "applications") {
            console.log("here")
            console.log("Row data:", event.row);

            // setApplications(event.row);
            history(`${FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE}${event.row.id}${FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_APPLICANT_ENROLEMENT}`);


            console.log(applicants)
            // history(`${FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE}${event.row.id}${'/employee_applicant_enrollment'}`);

            // props.onClick(event.row.id);
        }
        else if (props.data.screen === "home") {
            history(`${FRONTEND_ROUTE_CONSTANTS.CLIENT_ROUTE}${event.row.id}${FRONTEND_ROUTE_CONSTANTS.DETAILS}`);
        } else if (props.data.screen === "yeshivas_clients") {
            const location = {
                pathname: `${FRONTEND_ROUTE_CONSTANTS.CLIENT_ROUTE}${event.row.id}${FRONTEND_ROUTE_CONSTANTS.DETAILS}`,
                state: null
            }
            history(location);
            // history.push(`/client/details/${event.row.id}`);
            // window.location.reload();
        } else if (employeesScreensArr.includes(props.data.screen)) {
            history(`${FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE}${event.row.id}${FRONTEND_ROUTE_CONSTANTS.DETAILS}`);
        } else if (props.data.screen === "professionals") {
            history(`${FRONTEND_ROUTE_CONSTANTS.PROFESSIONAL_ROUTE}${event.row.id}${FRONTEND_ROUTE_CONSTANTS.DETAILS}`);
        } else if (props.data.screen === "yeshivas") {
            history(`${FRONTEND_ROUTE_CONSTANTS.YESHIVA_ROUTE}${event.row.id}${FRONTEND_ROUTE_CONSTANTS.DETAILS}`);
        } else if (props.data.screen === "system_lists") {
            props.onClick(event.row.id);
        }
    };

    const editColumn = {
        field: "edit",
        enableTooltip: false,
        icon: "Edit",
        headerName: 'Edit Icon',
        headerClassName: 'hidden-header',
        show: (params) => {
            let allow = (props.canEdit ? props.canEdit(params) : canEdit);
            return allow;
        },
        handleClick: (event, params) => {
            props.onOpen();
            props.onEdit(params);
        },
        sortable: false,
    };

    const moreColumn = {
        field: "moreIcon", disableColumnMenu: true, enableTooltip: false, icon: "MoreVert", sortable: false, maxWidth: 50, type: 'icon',
        handleClick: (event, params) => {
            props.onDelete(params);
        },
    }

    const deleteColumn = {
        field: "delete",
        headerName: 'Delete Icon',
        headerClassName: 'hidden-header',
        enableTooltip: false,
        icon: "Delete",
        sortable: false,
        show: (params) => {
            let allow = (props.canDelete ? props.canDelete(params) : canDelete);
            return allow;
        },
        handleClick: (event, params) => {
            props.onDelete(params);
        },
    };

    const userColumn = {
        field: "assignUser",
        label: "Assign User",
        enableTooltip: false,
        icon: "Person",
        sortable: false,
        handleClick: (event, params) => {
            props.onAssignUser(params);
        },
        show: (params) => {
            let allow = (props.canEdit ? props.canEdit(params) : canEdit);
            return allow;
        },
    }

    const enrollmentColumn = {
        field: "enrollment",
        label: "Enrollment",
        headerName: 'Enrollment Icon',
        headerClassName: 'hidden-header',
        enableTooltip: false,
        icon: "Article",
        sortable: false,
        handleClick: (event, params) => {
            props.onOpen();
            props.onEdit(params);
            event.stopPropagation();
        },
        show: (params) => {
            let allow = (props.canEdit ? props.canEdit(params) : canEdit);
            return allow;
        },
    };
    const changePasswordColumn = {
        field: "changePassword",
        headerName: 'Change Password Icon',
        headerClassName: 'hidden-header',
        disableClickEventBubbling: true,
        show: (params) => canEdit,
        icon: "VpnKey",
        sortable: false,
        handleClick: (event, params) => {
            props.onChangePassword(params);
        },
    };
    const accessLevelColumn = {
        field: "creatorRole",
        headerName: 'Access Icon',
        headerClassName: 'hidden-header',
        label: "Restricted",
        show: (params) => params.row.creatorRole,
        icon: "Block",
        sortable: false,
    }
    const getNavUrl = (navUrl, row) => {
        if (!navUrl || !row)
            return;
        let paths = navUrl.split('/').map(x => {
            if (x.indexOf('{') < 0)
                return x;
            let fieldName = x.substring(
                x.indexOf("{") + 1,
                x.lastIndexOf("}")
            );
            return row[fieldName];
        });
        return paths.join('/');
    }
    const [showTooltip, setShowTooltip] = useState("");

    //const columnValueGetter = (obj) => {
    //    if (obj.colDef.type === 'date')
    //        return global.dateTime.getUSAFormattedDateString(obj.value)
    //    else
    //        return obj.value
    //}
    const getSingleSelectFilters = () => {
        let singleSelectOp = getGridSingleSelectOperators();
        let stringOp = getGridStringOperators().filter(x => x.value === 'isEmpty' || x.value === 'isNotEmpty');
        let filters = [...singleSelectOp, ...stringOp]
        return filters;
    }

    const getActionColumn = (field) => {
        switch (field) {
            case 'enrollmentColumn':
                return { ...enrollmentColumn }
            case 'deleteColumn':
                return { ...deleteColumn }
            case 'editColumn':
                return editColumn;
            case 'changePasswordColumn':
                return changePasswordColumn;
            case 'accessLevelColumn':
                return accessLevelColumn;
            default:
                return null
        }
    }

    const FeaturedColumn = (columnDef) => {
        //const spanEl = useRef(null);
        //const divEl = useRef(null);

        let column = { ...columnDef };
        if (columnDef.type === 'action') {
            column = { ...getActionColumn(columnDef.field), ...column }
        }

        if (!column || !column.field)
            return column
        const fieldName = column.field;

        //var divScrollWidth = divEl.current?.scrollWidth;
        //var divOffsetWidth = divEl.current?.offsetWidth;
        //var divClientWidth = divEl.current?.clientWidth;
        //var spanScrollWidth = spanEl.current?.scrollWidth;
        //var spanOffsetWidth = spanEl.current?.offsetWidth;

        //TODO: Commenting this code intensionally, we might need to get dynamic ellipsis in future
        const isEllipsisActive = () => {
            return true;
            //     let clsElm= document.querySelector(".overflowText");
            //     let elem, ellipses;
            //     if (!!clsElm) {
            //         elem = window.getComputedStyle(clsElm, null);
            //        ellipses  = elem.getPropertyValue("text-overflow");
            //     }
            //     return ellipses==="ellipsis";
            //     //return (divEl.current.offsetWidth < spanEl.current.offsetWidth);
            //     //return (spanEl.current.offsetWidth < spanEl.current.scrollWidth);
        }
        const styles = {
            textAlign: "left",
            maxWidth: "100%",
            display: "inline-block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        };
        const { field, label, handleClick, icon, show, navUrl, hide, type, getStringValue, getTooltipTitle, getIcon, fontSize, renderCell } = column;
        const headerName = column.headerName || " ";
        const width = column.width || 110;
        const flex = column.flex || 0.08;
        const sortable = column.sortable === false ? false : true;
        const enableTooltip = column.enableTooltip != undefined ? column.enableTooltip : icon ? true : false;
        const showDetailsInDialog = column.enableDialog;
        const disableClickEventBubbling = column.disableClickEventBubbling != undefined ? column.disableClickEventBubbling : true;

        let customColumn = {
            ...column,
            field: field,
            headerName: headerName,
            description: column.description ?? headerName,
            label: label,
            width: width,
            flex: flex,
            hide: hide,
            sortable: sortable,
            handleClick,
            icon,
            getIcon,
            show,
            navUrl,
            renderCell,
            enableTooltip: enableTooltip,
            getTooltipTitle,
            disableClickEventBubbling: disableClickEventBubbling,
            //valueGetter: (obj) => columnValueGetter(obj),
            onCellClick: showDetailsInDialog ? () => { setIsDialogOpen(true) } : () => { },
            renderFilterDropdown: (filterValue, setFilter) => (
                <select
                    className={classes.dropdown}
                    value={filterValue}
                    onChange={event => { setFilter(event.target.value) }}
                >
                    <option value="">All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            ),
            // filterOperators: getGridStringOperators()
            //     .map((operator) => {
            //         return{
            //     ...operator,
            //     InputComponent: !!operator.InputComponent
            //         ? <div>
            //             ABC
            //         </div>
            //         : undefined,
            //     }}),
        };

        if (column.navUrl)
            customColumn.cellClassName = "style-url--cell";
        //single select drop-down values 
        if (type === 'singleSelect') {
            customColumn.valueOptions = props.valueOptionsLists
                && props.valueOptionsLists[field]
                && Array.isArray(props.valueOptionsLists[field]['values']) ?
                props.valueOptionsLists[field]['values'] : [];
            customColumn.filterOperators = getSingleSelectFilters();
        }
        if ((icon || getIcon || enableTooltip || !type || type === 'string' || type === "date" || type === 'singleSelect') && !renderCell) {
            customColumn.renderCell = (params) => {
                const onClick = (event) => {
                    if (handleClick && typeof handleClick === "function") {

                        handleClick(event, params);
                        event.stopPropagation();
                    }
                    else if (navUrl) {
                        window.open(getNavUrl(navUrl, params.row), "_blank");
                        event.stopPropagation();
                    }
                };
                const tooltipShowQuery = `${params.row.id}-${field}`;
                let iconValue = !!getIcon ? getIcon(params.row) : icon;
                return (
                    <>
                        {
                            (!show || show === true || show(params)) && //canEdit &&
                            <Tooltip
                                placement="top"
                                title={enableTooltip || isOverflow ? (
                                    <span>
                                        {
                                            typeof getTooltipTitle === "function" ?
                                                getTooltipTitle(params.row) :
                                                <TextFormattingWrapper
                                                    style={{ cursor: 'pointer' }}
                                                    value={params.row[field] || label || field || null}
                                                    onClick={() => {
                                                        setDialogContent(params.row[field])
                                                        setIsDialogOpen(true)
                                                    }}
                                                />
                                        }
                                    </span>) : typeof getStringValue === 'function' ? getStringValue(params) ?? '' : params.row[fieldName] ?? ''}
                            >
                                <div
                                    style={{ ...styles }}
                                    onClick={onClick}
                                    ref={ref}
                                    className={classes.overflowText}
                                >
                                    {
                                        !!iconValue ?
                                            <FeaturedIcon
                                                fontSize={fontSize || "small"}
                                                params={params}
                                                icon={iconValue}
                                                field={field}
                                                onClick={onClick}
                                                disableClick={!handleClick && !navUrl}
                                                screen={props.data.screen}
                                            /> :
                                            <TextFormattingWrapper
                                                value={typeof getStringValue === 'function' ? getStringValue(params) : params.row[fieldName]}
                                            />
                                    }
                                </div>
                            </Tooltip>
                        }
                    </>
                );

            };
        }
        if (type === 'date') {
            customColumn.renderCell = (params) => global.dateTime.getUSAFormattedDateString(params.row[fieldName])
        }
        if (type === 'dateTime') {
            customColumn.renderCell = (params) => global.dateTime.getFormattedDateTime(params.row[fieldName])
        }
        return customColumn;
    }

    const columnsYeshivasContact = [
        { field: "firstName", headerName: "First Name", flex: .5, width: 150 },
        { field: "lastName", headerName: "Last Name", flex: .5, width: 120 },
        { field: "phone", headerName: "Phone", flex: .5, sortable: false },
        { field: "email", headerName: "Email", flex: .5, sortable: false },
        { field: "relation", headerName: "Relation", flex: .5, sortable: false },
        {
            field: "userAccountId", enableTooltip: false, disableColumnMenu: true,
            renderCell: (param) => (
                <Tooltip title={param.row.userName ?? ''}>
                    <IconButton color={param.row.isActive ? "primary" : "default"} onClick={() => props.onAssignUser(param)}>
                        <Person />
                    </IconButton>
                </Tooltip>

            ), sortable: false, maxWidth: 100, minWidth: 30, cellClassName: 'icon-no-padding'
        },
        editColumn,
        deleteColumn,
    ];

    const columnsYeshivas = [
        { field: "name", headerName: "Yeshiva Name", flex: 5, sortable: false },
        editColumn,
        deleteColumn,
    ];

    const yeshivaClientsColumns = [
        { field: "last_name", headerName: "Last Name", width: 120 },
        { field: "first_name", headerName: "First Name", width: 150 },
        { field: "yeshiva", headerName: "Yeshiva", width: 90, sortable: false },
        { field: "age", headerName: "Age", width: 60, sortable: false, type: 'number' },
        { field: "organizer", headerName: "מארגן", width: 90, sortable: false },
        { field: "city", headerName: "City", width: 150, sortable: false },
        {
            field: "father_name",
            headerName: "Father Name",
            width: 150,
            sortable: false,
        },
    ];

    const columnsClients = [
        { field: "id", headerName: "Client ID", width: 110, flex: 1, type: "number" },
        { field: "fullName", headerName: "Full Name", width: 110, flex: 1, },
        { field: "last_name", headerName: "Last Name", width: 120, flex: 0.8 },
        { field: "first_name", headerName: "First Name", width: 150, flex: 1 },
        { field: "firstNameHebrew", headerName: "Hebrew First Name", width: 110, flex: 1, },
        { field: "lastNameHebrew", headerName: "Hebrew Last Name", width: 110, flex: 1, },
        { field: "organizer", headerName: "מארגן", width: 150, flex: 1.2, type: 'singleSelect', },
        { field: "roshMeargen", headerName: "ראש מארגן", width: 150, flex: 1.2, type: 'singleSelect', },
        { field: "careManager", headerName: "Care Manager", width: 150, flex: 1.2, type: 'singleSelect', },
        { field: "careManagerSupervisor", headerName: "Care Manager Supervisor", width: 150, flex: 1.2, type: 'singleSelect', },
        { field: "hcbsCoordinator", headerName: "HCBS Coordinator", width: 150, flex: 1.2, type: 'singleSelect', },
        { field: "financialCoordinator", headerName: "Billing Coordinator", width: 150, flex: 1.2, type: 'singleSelect', },
        { field: "yeshiva", headerName: "Yeshiva", width: 150, flex: 1, type: 'singleSelect', },
        { field: "stage", headerName: "Stage", width: 55, flex: 0.5, type: "number" },
        { field: "comfortHealthStatus", headerName: "Comfort", width: 110, flex: 1, type: 'singleSelect', },
        { field: "neighborhood", headerName: "Neighborhood", width: 110, flex: 1, type: 'singleSelect', },
        { field: "employees", headerName: "Employees", width: 150, flex: 0.8, enableTooltip: true },
        { field: "employeeCount", headerName: "# of Employees", width: 150, flex: 0.5, type: "number" },
        { field: "schedulesPendingFinancial", headerName: "# Pending Financial Schedules", width: 150, flex: 0.5, type: "number" },
        { field: "phone1", headerName: "Home Phone", width: 110, flex: 1, },
        { field: "age", headerName: "Age", width: 110, flex: 1, type: "number" },
        { field: "dob", headerName: "DOB", width: 110, flex: 0.5, type: "date" },
        { field: "dateEnrolledComfort", headerName: "Date Enrolled Comfort", width: 110, flex: 0.5, type: "date" },
        { field: "dateApprovedComfort", headerName: "Date Approved Comfort", width: 110, flex: 0.5, type: "date" },
        { field: "isPrevEnrolledInCH", headerName: "Is Prev Enrolled In CH", width: 110, flex: 0.5, type: "boolean" },
        { field: "comfortNote", headerName: "Comfort Note", width: 110, flex: 1, enableTooltip: true },
        { field: "sentDocsToParentsOn", headerName: "Sent Docs To Parents On", width: 110, flex: 0.5, type: "date" },
        { field: "insuranceCompany", headerName: "Insurance Company", width: 110, flex: 1, },
        { field: "sentBinderToComfortHealthOn", headerName: "Sent Binder To Comfort Health On", width: 110, flex: 0.5, type: "date" },
        { field: "careManagerAssignedOn", headerName: "Care Manager Assigned On", width: 110, flex: 0.5, type: "date" },
        { field: "intakeMeetingDate", headerName: "Intake Meeting Date", width: 110, flex: 0.5, type: "date" },
        { field: "cans_CompletedOn", headerName: "CANS CompletedOn", width: 110, flex: 0.5, type: "date" },
        { field: "crisis_CompletedOn", headerName: "Crisis CompletedOn", width: 110, flex: 0.5, type: "date" },
        { field: "poc_CompletedOn", headerName: "POC CompletedOn", width: 110, flex: 0.5, type: "date" },
        { field: "comprehensiveAssessment_CompletedOn", headerName: "ComprehensiveAssessment CompletedOn", width: 110, flex: 0.5, type: "date" },
        { field: "loc_CompletedOn", headerName: "LOC CompletedOn", width: 110, flex: 0.5, type: "date" },
        { field: "packetSentOn", headerName: "Packet Sent On", width: 110, flex: 0.5, type: "date" },
        { field: "comfortHealthTimeRequirements", headerName: "Time Requirements", width: 110, flex: 1, enableTooltip: true },
        { field: "sentIepToNewAgencyOn", headerName: "Sent IEP On", width: 110, flex: 0.5, type: "date" },
        { field: "dateEnrolledP3", headerName: "Date Enrolled P3", width: 110, flex: 0.5, type: "date" },
        { field: "dateApprovedP3", headerName: "Date Approved P3", width: 110, flex: 0.5, type: "date" },
        { field: "hoursPerWeekP3", headerName: "Hours Per Week P3", width: 110, flex: 0.5, type: "number" },
        { field: "dateEnrolledLight", headerName: "Date Enrolled Light", width: 110, flex: 0.5, type: "date" },
        { field: "enrolledOn", headerName: "Enrolled LHisalos On", width: 110, flex: 0.5, type: "date" },
        { field: "disenrolledOn", headerName: "Disenrolled LHisalos On", width: 110, flex: 0.5, type: "date" },
        { field: "uploadedInsuranceOn", type: "date", headerName: "Date Uploaded Insurance", width: 110, flex: 0.5, },
        { field: "uploadedConsentFormOn", type: "date", headerName: "Date Uploaded Consent Form", width: 110, flex: 0.5, },
        { field: "uploadedSedOn", type: "date", headerName: "Date Uploaded SED", width: 110, flex: 0.5, },
        { field: "uploadedIepOn", type: "date", headerName: "Date Uploaded IEP", width: 110, flex: 0.5, },
        //{ field: "city", headerName: "City", width: 110, flex: 0.7,},
        {
            field: "father_name",
            headerName: "Father Name",
            width: 150,
            flex: 1,
        },
        enrollmentColumn,
        deleteColumn
    ];

    const columnsEmployee = [
        { field: "id", type: "number", headerName: "Employee Id", width: 110, flex: 0.5, },
        { field: "fullName", headerName: "Full Name", width: 110, flex: 1.4, },
        { field: "lastName", headerName: "Last Name", width: 120, flex: 0.8 },
        { field: "firstName", headerName: "First Name", width: 150, flex: 0.8 },
        { field: "createdOn", type: "date", headerName: "Date Created", width: 110, flex: 0.5, },
        { field: "phone1", headerName: "Phone Number", width: 120, flex: 0.8 },
        //{ field: "address1", headerName: "Address", width: 150, flex: 1},
        { field: "rateDisplay", headerName: "Rate", width: 110, flex: 0.8 },
        { field: "neighborhood", headerName: "Neighborhood", width: 110, flex: 0.8, type: 'singleSelect', },
        { field: "comfortHealthStatus", headerName: "Comfort", width: 110, flex: 0.8, type: 'singleSelect', },
        { field: "specialties", headerName: "Specialties", width: 110, flex: 1.4 },
        { field: "clients", headerName: "Clients", width: 150, flex: 0.8, enableTooltip: true },
        { field: "yeshivas", headerName: "Yeshivas", width: 150, flex: 0.8, enableTooltip: true },
        { field: "meargenim", headerName: "Meargenim", width: 150, flex: 0.8, enableTooltip: true },
        { field: "clientCount", headerName: "# of Clients", width: 150, flex: 0.5, type: "number" },
        { field: "isYungerman", type: "boolean", headerName: "Yungerman", width: 110, flex: 0.5, },
        { field: "isProfessional", type: "boolean", headerName: "Professional", width: 110, flex: 0.5, },
        { field: "isLegal", type: "boolean", headerName: "Legal", width: 110, flex: 0.5, },
        { field: "isEmployee", type: "boolean", headerName: "Employee", width: 110, flex: 0.5, },
        { field: "inactive", type: "boolean", headerName: "Inactive", width: 110, flex: 0.5, },
        { field: "isSummerOnly", type: "boolean", headerName: "Summer Only", width: 110, flex: 0.5, },
        { field: "firstNameHebrew", headerName: "Hebrew First Name", width: 110, flex: 0.8, },
        { field: "lastNameHebrew", headerName: "Hebrew Last Name", width: 110, flex: 0.5, },
        { field: "firstNameLegal", headerName: "Legal First Name", width: 110, flex: 0.8, },
        { field: "lastNameLegal", headerName: "Legal Last Name", width: 110, flex: 0.8, },
        { field: "middleNameLegal", headerName: "Legal Last Name", width: 110, flex: 0.8, },
        { field: "hasText", type: "boolean", headerName: "Has Text", width: 110, flex: 0.5, },
        { field: "address1", headerName: "Address", width: 110, flex: 1, },
        { field: "age", type: "number", headerName: "Age", width: 110, flex: 0.5, },
        { field: "city", headerName: "City", width: 110, flex: 0.8, },
        { field: "email1", headerName: "Email", width: 110, flex: 0.8, },
        { field: "state", headerName: "State", width: 110, flex: 0.8, },
        { field: "zip", headerName: "Zip", width: 110, flex: 0.8, },
        { field: "dob", type: "date", headerName: "DOB", width: 110, flex: 0.5, },
        { field: "rate", type: "number", headerName: "Rate (number)", width: 110, flex: 0.5, },
        { field: "prevYeshivas", headerName: "Yeshivas Previously Worked", width: 110, flex: 1, enableTooltip: true },
        { field: "experience", headerName: "Experience", width: 110, flex: 1, enableTooltip: true },
        { field: "note", headerName: "Note", width: 110, flex: 1, enableTooltip: true },
        { field: "lHisalosApprovalStatus", headerName: "LHisalos Approval Status", width: 110, flex: 0.7, type: 'singleSelect', },
        { field: "parsonageLimit", type: "number", headerName: "Parsonage Limit", width: 110, flex: 0.6, },
        { field: "financialNotes", headerName: "Financial Notes", width: 110, flex: 1, enableTooltip: true },
        { field: "paymentOptions", headerName: "Payment Options", width: 110, flex: 1, enableTooltip: true, type: 'singleSelect', },
        { field: "planningToEnrollComfortHealth", type: "boolean", headerName: "Planning To Enroll CH", width: 110, flex: 0.5, },
        { field: "hasComfortHealthApplication", type: "boolean", headerName: "Has CH Application", width: 110, flex: 0.5, },
        { field: "hasBA", type: "boolean", headerName: "Has BA", width: 110, flex: 0.5, },
        { field: "hasHighSchoolDiploma", type: "boolean", headerName: "Has High School Diploma", width: 110, flex: 0.5, },
        { field: "sentToComfortHealthOn", type: "date", headerName: "Date Sent Application To CH", width: 110, flex: 0.5, },
        { field: "fingerprintedOn", type: "date", headerName: "Date Fingerprinted", width: 110, flex: 0.5, },
        { field: "comfortHealthApprovedOn", type: "date", headerName: "Date Approved CH", width: 110, flex: 0.5, },
        { field: "catsApprovedOn", type: "date", headerName: "Date Approved CATS", width: 110, flex: 0.5, },
        { field: "prevocApprovedOn", type: "date", headerName: "Date Approved Prevoc", width: 110, flex: 0.5, },
        { field: "specialRequirements", headerName: "Special Requirements", width: 110, flex: 1, enableTooltip: true },
        { field: "amountCouldWork", type: "number", headerName: "Amount Could Work", width: 110, flex: 0.6, },
        { field: "isInTheMountainsSummer", type: "boolean", headerName: "In The Mountains Summer", width: 110, flex: 0.5, },
        { field: "hasLicense", type: "boolean", headerName: "Has License", width: 110, flex: 0.5, },
        { field: "hasCar", type: "boolean", headerName: "Has Car", width: 110, flex: 0.5, },
        { field: "summerColony", headerName: "Summer Colony", width: 110, flex: 0.8, },
        { field: "amountBilling", type: "number", headerName: "Amount Billing", width: 110, flex: 0.7, },
        { field: "professionalType", headerName: "professionalType", width: 110, flex: 0.8, type: 'singleSelect', },
        { field: "isLhisalosInHouse", type: "boolean", headerName: "Is Lhisalos In House", width: 110, flex: 0.5, },
        { field: "uploadedIdOn", type: "date", headerName: "Date Uploaded Id", width: 110, flex: 0.5, },
        { field: "uploadedParsonageFormOn", type: "date", headerName: "Date Uploaded Parsonage Form", width: 110, flex: 0.5, },
        { field: "uploadedHighSchoolDiplomaOn", type: "date", headerName: "Date Uploaded Diploma", width: 110, flex: 0.5, },
        { field: "uploadedBAOn", type: "date", headerName: "Date Uploaded BA", width: 110, flex: 0.5, },
        { field: "uploadedAssociatesDegreeOn", type: "date", headerName: "Date Uploaded Associate's Degree", width: 110, flex: 0.5, },
        { field: "baFileName", headerName: "BA File Name", width: 110, flex: 0.8, },


        //{ field: "position", headerName: "Position", width: 130, sortable: true },
        enrollmentColumn,
        deleteColumn,
    ];

    const columnsProfessional = [
        { field: "lastName", headerName: "Last Name", width: 120, flex: 1, sortable: false },
        { field: "firstName", headerName: "First Name", width: 150, flex: 1 },
        { field: "phone1", headerName: "Phone Number", width: 120, flex: 1, sortable: false },
        { field: "address1", headerName: "Address", width: 150, flex: 1, sortable: false },
        { field: "city", headerName: "City", width: 110, flex: 1, sortable: false },
        { field: "type", headerName: "Type", width: 110, flex: 1, sortable: true },
        editColumn,
        deleteColumn,
    ];

    const usersActionsColumns = [
        ...props.data.columns,
        editColumn,
        changePasswordColumn,
        deleteColumn
    ]

    const actionColumns = {
        editColumn,
        changePasswordColumn,
        deleteColumn,
        accessLevelColumn,
    }

    const [columnsData, setColumnsData] = useState([]);

    useEffect(() => {
        setColumnsData(getFeaturedColumns());
    }, [props, props.data, props.data.screen, props.data.columns, props.data.columns]);

    const getFeaturedColumns = () => {
        return getColumns().map((column, index) => FeaturedColumn(column));
    }

    const getColumns = () => {
        let columns = [];
        if (props.data.columns) {
            for (var i = 0; i < props.data.columns.length; i++) {
                //columns.push(window[props.data.columns[i]]);

                let col = props.data.columns[i];
                if (!col)
                    continue;
                if (col.field) {
                    let customColumn = col;//FeaturedColumn(col);
                    columns.push(customColumn);
                    continue;
                } else {
                    let actionCol = actionColumns[col];
                    if (actionCol)
                        columns.push(actionCol);
                }
            }
        }
        if (props.data.screen === 'mission') {
            return props.data.columns
        }
        if (props.data.screen === 'timesheet') {
            return props.data.columns
        }

        if (dataGridViewScreens.includes(props.data.screen)) {
            return props.data.columns
        }
        if (props.data.screen === 'dasboard-client') return props.data.columns;
        if (props.data.screen === "home") {
            return [
                ...props.data.columns,
            ];
        } else if (employeesScreensArr.includes(props.data.screen)) {
            return [
                ...props.data.columns,
            ];
        } else if (props.data.screen === "professionals") {
            return columnsProfessional;
        } else if (props.data.screen === "yeshivas") {
            return columnsYeshivas;
        } else if (props.data.screen === 'yeshivas_contact') {
            return columnsYeshivasContact;
        } else if (
            props.data.screen === "system_lists" ||
            props.data.screen === "system_listvalues"
        ) {
            return [
                ...props.data.columns,
                editColumn
            ];
        }
        else if (props.data.screen == "users") {
            return usersActionsColumns;
        }
        else if (
            props.data.screen === "users"
        ) {
            return [
                ...props.data.columns,
                editColumn,
                enrollmentColumn,
                deleteColumn
            ];
            //} else if (props.data.screen === "client_progress") { return []; }
        } else if (
            //props.data.screen === "client_todo" ||
            props.data.screen === "client_contact" ||
            props.data.screen === "client_schedule" ||
            props.data.screen === "employee_schedule" ||
            props.data.screen === "employee_schedule_availability"
        ) {
            return [
                ...props.data.columns,
                editColumn,
                deleteColumn,
            ];
        } else if (props.data.screen === "yeshivas_clients") {
            // global.log(yeshivaClientsColumns);
            return yeshivaClientsColumns;
        } else if (props.data.screen === "client_progress" ||
            props.data.screen === "client_todo" ||
            props.data.screen === "assigned_todo") {
            return columns;
        } else {
            return [
                ...props.data.columns,
                ...columns,
            ];
        }


    };

    const handleClickSaveSettings = (event) => {
        setXPos(event.pageX - 50 - window.pageXOffset);
        setYPos(event.pageY - window.pageYOffset)
        setAnchorElSave(event.currentTarget)
    }

    const handleCloseSaveMenu = () => {
        setXPos('0px');
        setYPos("0px");
        setAnchorElSave(null)
    }

    /**
     * Function, that is loading previous values of chip and sending to view with updated value
     * @param {string} label 
     */
    const handleChipFilterClick = (label) => {
        const isActive = quickFilters.value.some((item) => strings.removeEmptySpaces(item.filter) === strings.removeEmptySpaces(label) && item.isActive) ? false : true
        const filterLabel = strings.removeEmptySpaces(label);
        const saveQuickFilters = {};
        quickFilters.value.map((option) => {
            saveQuickFilters[option.filter] = `${option.isActive}`
        })
        const addQuickFilterToView = {
            ...saveQuickFilters,
            [strings.removeEmptySpaces(label)]: quickFilters.value.some((item) => strings.removeEmptySpaces(item.filter) === strings.removeEmptySpaces(label) && item.isActive) ? 'false' : 'true'
        }
        quickFilters.handler({ filter: filterLabel, isActive: isActive }) // saving filter of view in component state
        handleChangeColAndFilter(addQuickFilterToView, 'quickFilter'); // saving filter inside view object
    }

    function CustomToolbar() {
        // const hasChanged = !_.isEqual(cols, columnVisibility) || !_.isEqual(filters, filterModel)
        // (columnVisibility && filterModel && !dbSettingState)
        // ||
        // (dbSettingState !== undefined &&
        //     (!_.isEqual(dbSettingState[props.data.screen]?.columnsVisibility, columnVisibility) ||
        //         !_.isEqual(dbSettingState[props.data.screen]?.filterModel, filterModel)));
        return (
            <GridToolbarContainer className={classes.toolbarContainer}>
                <TitleContainer>
                    {title &&
                        <Title>
                            {title}
                        </Title>
                    }
                    {props.multiView &&
                        <>
                            <Divider className={baseClasses.mr10} orientation="vertical" variant="middle" flexItem />
                            <DivContainer position="relative" style={{ marginRight: '10px' }}>
                                <AnimLoadingState loading={viewLoading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
                                <FormControl fullWidth sx={{ m: 1, minWidth: 80 }}>
                                    <Select
                                        onChange={(e) => {
                                            hanleViewChange(e.target.value)
                                        }}
                                        value={currentView}
                                        renderValue={(item) => item.viewName}
                                        className={classes.multiViewSelect}
                                    >
                                        <MenuItem className={classes.mutilViewMenuItem} id={"system datagrid"} value={systemDefaultView}>
                                            <ListItemText>{systemDefaultView.viewName}</ListItemText>
                                            <ListItemIcon onClick={(e) => {
                                                e.stopPropagation();
                                                setEditViewData(systemDefaultView)
                                            }}
                                            >
                                                {/* <MoreVertIcon fontSize="small" /> */}
                                            </ListItemIcon>
                                        </MenuItem>
                                        {dataGridViews?.length > 0 && dataGridViews.map((view) => (
                                            <MenuItem selected={true} className={classes.mutilViewMenuItem} id={view.viewName + "datagrid-" + view.dataGridName} key={view.viewName + "datagrid-" + view.dataGridName} value={view}>
                                                <ListItemText>{view.viewName}</ListItemText>
                                                <ListItemIcon onClick={(e) => {
                                                    e.stopPropagation();
                                                    setEditViewData(view.viewName === currentView.viewName ? currentView : view)
                                                }}
                                                >
                                                    <MoreVertIcon fontSize="small" />
                                                </ListItemIcon>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </DivContainer>
                            <Tooltip
                                title={"Add New View"}
                            >
                                <Button
                                    startIcon={<AddIcon />}
                                    size={'small'}
                                    id="save-view-settings"
                                    onClick={(e) => saveDataGridSettings(true)}
                                    style={{ minWidth: 0 }}
                                >
                                    {hideToolbarIconText ? '' : 'Add View'}
                                </Button>
                            </Tooltip>
                            <Divider className={baseClasses.mr10} orientation="vertical" variant="middle" flexItem />
                        </>
                    }
                </TitleContainer>
                {<div>
                    {headerMessage}
                </div>}
                <ActionsContainer>
                    {quickFilters &&
                        <>
                            {quickFilters.type === "switch" &&
                                <FormControlLabel
                                    control={
                                        <Switch checked={quickFilters.value} onChange={quickFilters.handler} name="showcomp" color="primary" />
                                    }
                                    label={quickFilters.label}
                                />

                            }
                            {quickFilters.type === "chip" &&
                                quickFilters.options && quickFilters.options?.map((label) => (
                                    <Chip
                                        style={{ marginRight: '5px' }}
                                        size={'small'}
                                        fontSize={'small'}
                                        label={label}
                                        className={clsx(baseClasses.chipLabel, {
                                            [baseClasses.activeChip]: quickFilters.value.some((item) => strings.removeEmptySpaces(item.filter) === strings.removeEmptySpaces(label) && item.isActive)
                                        })}
                                        onClick={() => handleChipFilterClick(label)}
                                    />

                                ))
                            }
                            <Divider className={baseClasses.mr10} orientation="vertical" variant="middle" flexItem />
                        </>
                    }
                    {props.showColumns &&
                        <Tooltip title="Show Columns">
                            <GridToolbarColumnsButton
                                className={clsx({ [classes.hideToolbarText]: hideToolbarIconText })}
                                style={{ color: "primary" }}
                            />
                        </Tooltip>
                    }
                    <Tooltip title="Show Filter">
                        <GridToolbarFilterButton
                            className={clsx({ [classes.hideToolbarText]: hideToolbarIconText })}
                            style={{ color: "primary" }}
                        />
                    </Tooltip>
                    <Tooltip title="Export">
                        <GridToolbarExport
                            style={{ color: "primary" }}
                            className={clsx({ [classes.hideToolbarText]: hideToolbarIconText })}
                            printOptions={{
                                //disableToolbarButton: true,
                                hideToolbar: true,
                                hideFooter: true,

                            }}
                        />
                    </Tooltip>

                    {props.multiView &&
                        <>
                            <Divider className={baseClasses.mr10} orientation="vertical" variant="middle" flexItem />
                            <Tooltip
                                title={(currentView.hasChanges) && "Save current view"}
                            >
                                <Button
                                    startIcon={<SaveOutlinedIcon />}
                                    size={'small'}
                                    id="save-settings"
                                    onClick={(e) => saveDataGridSettings(currentView.viewName === systemDefaultView.viewName)}
                                    style={{ minWidth: 0 }}
                                    disabled={!currentView.hasChanges}

                                >
                                    {hideToolbarIconText ? '' : 'Save Settings'}
                                </Button>
                            </Tooltip>
                            {/* TODO: commenting this might use dropdown for save and save as in future */}
                            {/* <Menu
                            id="fade-menu"
                            MenuListProps={{
                            'aria-labelledby': 'save-settings',
                            }}
                            anchorEl={anchorElSave}
                            open={openSaveMenu}
                            onClose={handleCloseSaveMenu}
                            TransitionComponent={Fade}
                            sx={{
                                top: yPos,
                                left: xPos,
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <MenuItem onClick={() => saveDataGridSettings()}>Save</MenuItem>
                            <MenuItem onClick={() => saveDataGridSettings(true)}>Save as</MenuItem>
                        </Menu> */}
                        </>
                    }
                    {showEdit &&
                        <IconButton
                            onClick={handleEditClick}
                            className="icon"
                            fontSize="small"
                            color="primary"
                        >
                            <EditIcon />
                        </IconButton>
                    }
                    {showAdd &&
                        <Button className={baseClasses.cursorPointer}
                            onClick={() => handleAddClick()}>
                            + Add
                        </Button>
                    }

                    {quickFilters && quickFilters.type === "select" && (
                        quickFilters.dropdowns.map((dropdown) => (
                            <select
                                className={classes.quickFilterSelect}
                                onChange={dropdown.handler}
                                value={dropdown.value}
                            >
                                {
                                    dropdown?.options?.map((option, index) => {
                                        return <option value={option.value}>{option.label}</option>
                                    })
                                }
                            </select>
                        ))
                    )
                    }
                </ActionsContainer>
            </GridToolbarContainer>
        );
    }

    const handleCancelClick = (id) => () => {
        props.setRowModesModel({
            ...props.rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = props.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            props.setRows(props.rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        props.setRows(props.rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        console.log({ updatedRow })
        return updatedRow;
    };

    const quickFilterToSave = (editFilter = null) => {
        if (editFilter) {
            return strings.parseObjToString(editFilter, "&", "=")
        }
        const saveQuickFilters = {};
        let convertToString = "";
        if (!!quickFilters) {
            if (quickFilters.type === 'select') {
                quickFilters.dropdowns.map((dropdown) => {
                    saveQuickFilters[dropdown.field] = dropdown.value
                })
            }
            if (quickFilters.type === 'chip') {
                quickFilters.options.map((option) => {
                    saveQuickFilters[strings.removeEmptySpaces(option)] = quickFilters.value.some((item) => strings.removeEmptySpaces(option) === item.filter && item.isActive)
                })
            }
            if (quickFilters.type === 'switch') {
                saveQuickFilters[quickFilters.field] = quickFilters.value
            }
        }
        if (!!saveQuickFilters) {
            convertToString = strings.parseObjToString(saveQuickFilters, "&", "=")
        }
        return convertToString
    }

    const handleAddNewView = (data) => {
        setIsAddNewViewOpen(false);
        setEditViewData(null)

        if (data == null) return;
        const settings = data.dataGridViewSettingId === 0 ? {
            columnSettings: columnVisibility,
            filterSsettingss: filterModel,
            quickFilters: quickFilterToSave()
        } : {
            quickFilters: quickFilterToSave(data?.quickFilters)
        }
        const view = {
            ...data,
            ...settings
        }
        saveGridView({ ...view })
    }

    const handleDeleteView = async (view) => {
        setIsAddNewViewOpen(false);
        setEditViewData(null)
        const isConfirmed = await confirm(true, [], '', 'Delete view', '', 'delete');
        if (isConfirmed)
            handleDeleteGridView(view.dataGridViewSettingId)
    }

    const csvProps = {
        onCellExport: (value, column, row) => {
            if (typeof value === 'string') return strings.parseStringMetaData(value)
            return value
        },
    };

    const onColumnWidthChange = (column) => {

        handleChangeColAndFilter(column, 'columnSize')
    }

    const getColumnsVisibility = () => {
        let visibilty = {};
        for (const property in columnVisibility) {
            visibilty[property] = columnVisibility[property].isVisible
        }
        return visibilty;
    }

    const getTableCols = () => {
        const totalWidth = columnsData.reduce((sum, col) => sum + (col.width || 150), 0);
        return columnsData.map((column) => {
            if (!!columnVisibility[column.field] && !!columnVisibility[column.field]?.widthFlex) {
                return { ...column, flex: columnVisibility[column.field]?.widthFlex / totalWidth }
            }
            return { ...column }
        })
    }
    const dataGridRef = useRef(null);
    const handleSortModelChange = (newSortModel) => {
        let sortedRows = []
        setTimeout(() => {
            if (dataGridRef.current) {
                sortedRows = dataGridRef.current.getSortedRows({ sortModel: newSortModel });
            }
            props.onSortingRows(sortedRows)
        }, 0);

    };

    return (
        <div
            className={classes.container}
            style={{
                height: autoHeight ? null : props.height,
            }}
        >
            <DataGridPro
                apiRef={dataGridRef}
                className={clsx(classes.table)}
                sx={(theme) => ({
                    '&.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered': {
                        background: "red",
                    },
                    '& .MuiDataGrid-iconButtonContainer': {
                        display: props.hideHeaderColumnsSortIcon ? "none" : "unset",
                    },
                    '& .MuiDataGrid-menuIconButton': {
                        padding: "0px",
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-row:hovered': {
                        background: "red", //theme.palette.background.paper,
                    },
                    '&.MuiDataGridPro-columnHeader': {
                        background: '#000 !important'
                    },
                    '& .MuiDataGrid-columnHeaders': props.tableHeaderStyling || {},


                    '& .icon-no-padding': {
                        padding: '0px !important'
                    },
                    '& .style-url--cell': {
                        '&:hover': {
                            textDecoration: 'underline',
                            color: theme.palette.primary.main,
                            cursor: 'pointer'
                        }
                    }
                })}
                rows={props.data?.rows?.length > 0 && props.data?.rows?.map((row, index) => {
                    return {
                        ...row,
                        key: row.key || row.id || index,
                        id: row.id || row.key || index,
                    }
                })}
                onSortModelChange={handleSortModelChange}
                onColumnWidthChange={onColumnWidthChange}
                csvProps={csvProps}
                processRowUpdate={processRowUpdate}
                columns={columnsData}
                columnVisibilityModel={getColumnsVisibility() ?? undefined}
                onColumnVisibilityModelChange={(model) => {
                    handleChangeColAndFilter(model, 'column');
                    if ((typeof setColumns === 'function') && columnVisibility)
                        setColumns({ model, grid: props?.data?.screen })
                }}

                editMode="row"
                onRowEditStart={props.handleRowEditStart}
                onRowEditStop={props.handleRowEditStop}
                filterModel={filterModel ?? undefined}
                onFilterModelChange={filterModel ? handleFilterChange : undefined}
                headerHeight={40}
                rowHeight={36}
                hideFooterSelectedRowCount={true}
                onRowClick={handleRowClick}
                key={dataGridKey}
                autoPageSize
                autoHeight={autoHeight}
                page={props.options?.page}
                onPageChange={
                    props.options?.onPageChange
                }
                pagination={(autoHeight || disablePagination) ? false : true}
                getRowClassName={(params) => {
                    return params.row && props.completed && `super-app-theme--${"Filled"}`
                }}
                csvOptions={{
                    getRowsToExport: rows => {
                        const modifiedRows = rows.map(row => {
                            for (const cell in row) {
                                if (typeof cell === 'string') {
                                    row[cell] = strings.parseStringMetaData(cell)
                                }
                            }
                            return row;
                        });
                        return modifiedRows;
                    }
                }}
                componentsProps={{
                    //toolbar: { printOptions: { disableToolbarButton: true } },
                    toolbar: {
                        csvOptions: {
                            getRowsToExport: rows => {
                                const modifiedRows = rows.map(row => {
                                    for (const cell in row) {
                                        if (typeof cell === 'string') {
                                            row[cell] = strings.parseStringMetaData(cell)
                                        }
                                    }
                                    return row;
                                });
                                return modifiedRows;
                            }
                        }
                    },
                    panel: {
                        sx: {
                            "& .MuiDataGrid-root": {
                                //color: "#1976d2",
                            },
                            "& .MuiDataGrid-filterForm": {
                                //color: "#1976d2"
                            }
                        }
                    }
                }}
                components={{
                    LoadingOverlay: () => CustomLoadingOverlay(props),
                    Toolbar: CustomToolbar
                }}
                onCellClick={(params) => {
                    params.colDef.onCellClick()
                    setDialogContent(params.value || params.colDef.getStringValue(params))
                }}
                {...props}
            />
            <ViewDetailsDialog
                open={isDialogOpen}
                handleClose={() => {
                    setDialogContent("")
                    setIsDialogOpen(false)
                }}
                value={dialogContent}
            />
            <AddNewView
                openModal={!!editViewData}
                currentView={editViewData ?? currentView}
                gridName={props.data.screen}
                handleClose={(data) => handleAddNewView(data)}
                handleDeleteView={handleDeleteView}

            />
            {/*{tooltipText && (*/}
            {/*    <div*/}
            {/*        className="menu-container"*/}
            {/*        style={{*/}
            {/*            top: yPos,*/}
            {/*            left: xPos,*/}
            {/*            position: "absolute",*/}
            {/*            width: 200,*/}
            {/*            backgroundColor: "#707070",*/}
            {/*            borderRadius: 5,*/}
            {/*            padding: "2px 7px 4px 7px",*/}
            {/*            color: "white",*/}
            {/*            fontSize: "12px",*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {tooltipText}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
}

DataTable.defaultProps = {
    height: "70vh",
};

const onMouseMove = (e) => {
    //if (columnsData.some((col) => col.enableTooltip)) {
    //    setXPos(`${e.pageX + 15}px`);
    //    setYPos(`${e.pageY + 5}px`);
    //}
}
////tooltip state
//const setXPos = useStoreActions((actions) => actions.setXPos);
//const setYPos = useStoreActions((actions) => actions.setYPos);
//const xPos = useStoreState((state) => state.xPos);
//const yPos = useStoreState((state) => state.yPos);
//const [tooltipText, setTooltipText] = useState("");
//const getTooltipText = (fieldName) => {
//    let cols = columnsData.filter((col) => "col.field" == fieldName && col.enableTooltip);
//    if (!cols || cols.length < 1 || cols.length > 1)
//        return;
//    if (props.data.screen == "client_progress") {
//        let value = "";//event.row[fieldName];
//        return value;
//    }
//}


