// Define multiple themes here.
import React from 'react'
import { ThemeProvider, } from '@mui/styles';
import { useStoreActions, useStoreState } from "easy-peasy";
import {theme} from '../../assets/theme/theme'



const themeMap = {
    lightTheme: theme,
    darkTheme: theme,
    default: theme
}

const ThemeProviderWrapper = (props) => {
    const {children} = props;
    const theme = useStoreState((state) => state.theme);
    const applicationTheme = themeMap[theme] ? themeMap[theme] : themeMap['default'];
    return <ThemeProvider theme={applicationTheme}>{children}</ThemeProvider>
    
}
export default ThemeProviderWrapper