import React, { useEffect, useState } from 'react';
import OverviewCard from '../../../UI/OverviewCard';
import InfoTable from '../../../DataTable/InfoTable';
import { BASE_URL } from '../../../../constants';
import SCHEDULE_PERIODICITY_CONSTANTS from '../../../../_helpers/constants/schedule_periodicity_constants';


const ProfessionalGeneralTab = (props) => {
    const section1Info = [{_key:"Name", value: props.employeeInfo?.contact?.firstName + " " + props.employeeInfo?.contact?.lastName},
         {_key:"Hebrew Name", value: (props.employeeInfo?.contact?.firstNameHebrew && props.employeeInfo?.contact?.lastNameHebrew) ? 
            `${props.employeeInfo?.contact?.firstNameHebrew} ${props.employeeInfo?.contact?.lastNameHebrew}` : ""},
         {_key: "Phone", value: props.employeeInfo?.contact?.phone1}]
    const section2Info = [{_key: "Professioanl Type:", value: props.employeeInfo?.professionalType}];
    const repeatingScheduleColumns = [{fieldName: "client", headerName: "Client"}, {fieldName: "time", headerName: "Time"},{fieldName: "days", headerName: "Days"},{fieldName: "interval", headerName: "Interval"}
            ,{fieldName: "intervalType", headerName: "Interval Type"},{fieldName: "note", headerName: "Note"},{fieldName: "nextSession", headerName: "Next Session"}];
    const [repeatingScheduleRows, setRepeatingScheduleRows] = useState([{}]);
    const oneTimeScheduleColumns = [{fieldName: "client", headerName: "Client"}, {fieldName: "time", headerName: "Time"},{fieldName: "date", headerName: "Date"},{fieldName: "note", headerName: "Note"}]
    const [oneTimeScheduleRows, setOneTimeScheduleRows] = useState([]);
    const getRepeatingScheduleInfo = () => {
        global.fetchWrapper.get(BASE_URL + "api/hr/getRepeatingProfessionalSchedule/" + props.employeeInfo?.id)
        .then(data => {
            global.log('The data of repeating schedule from the fetch function', data);
            const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; 
            let rows = data.map(info => {
                const row = {
                    client: info.clientName,
                    time: `${formatJSONDataToReadableTime(info.startTime)} - ${formatJSONDataToReadableTime(info.endTime)}`,
                    interval: info.interval,
                    intervalType: info.periodicity,
                    nextSession: formatJSONDataToReadableDate(info.nextSession),
                    days: info.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ? info.days.split(',').map(x => dayOfWeek[x]).join(", ") : info.days
                }
                return row;
            })
            setRepeatingScheduleRows(rows);
    })} 

    const getOneTimeScheduleInfo = () => {
        global.fetchWrapper.get(BASE_URL + "api/hr/GetOneTimeEmployeeSchedules/" + props.employeeInfo?.id)
        .then(data => {
            global.log('The data of one time schedule from the fetch function', data);
            let rows = data.map(info => {
                const row = {
                    client: info.appointmentWith,
                    time: `${formatJSONDataToReadableTime(info.startTime)} - ${formatJSONDataToReadableTime(info.endTime)}`,
                    date: formatJSONDataToReadableDate(info.appointmentDate),
                    note: info.note
                }
                return row;
            })
            setOneTimeScheduleRows(rows);
    })} 

    

    useEffect(() => {
        if(props.employeeInfo?.id == null) return;
        getRepeatingScheduleInfo()
        getOneTimeScheduleInfo()
    },[props.employeeInfo]);

    return ( <div>
        <OverviewCard section1={section1Info} section2={section2Info}/>
        <div>
        <InfoTable columns={repeatingScheduleColumns} rows={repeatingScheduleRows} title={"Schedule"}/>
        </div>
        <div>
        <InfoTable columns={oneTimeScheduleColumns} rows={oneTimeScheduleRows} title={"Appointments"}/>
        </div>
    </div> );
}

function formatJSONDataToReadableTime(date){
   return  new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true  })
}

function formatJSONDataToReadableDate(date){
   const dateObj = new Date(date);
   const month = dateObj.getMonth();
   const day = dateObj.getDate();
   const year = dateObj.getFullYear()
   return `${month}/${day}/${year}`;
}
 
export default ProfessionalGeneralTab;