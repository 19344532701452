import { action, thunk, computed } from "easy-peasy";
import { clients } from "../api/clients";
import * as constant from "../constants";
import index from "./index.js";

const defaultStore = {
    detail: {
        createdOn: "2021-02-10T06:22:53.264Z",
        isDeleted: true,
        firstName: "",
        lastName: "",
        firstNameHebrew: "",
        lastNameHebrew: "",
        firstNameLegal: "",
        lastNameLegal: "",
        middleNameLegal: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone1: "",
        phone2: "",
        email1: "",
        email2: "",
        dob: "2021-02-10T06:22:53.264Z",
        age: 0,
        ssn: 0,
        contactId: 0,
        isActive: true,
        enrolledOn: "2021-02-10T06:22:53.264Z",
        father_ContactId: 0,
        mother_ContactId: 0,
        primaryContact_ContactId: 0,
        legalGuardian_ContactId: 0,
        yeshivaId: 0,
        menahel_ContactId: 0,
        mainConcern: "",
        additionalInfo: "",
        medication: "",
        evaluation: 0,
        diagnosis: "",
        hasMedicaid: true,
        healthInsuranceProvider: "",
        cin: "",
        hasP3: true,
        referredBy: "",
        isPayingTillApproval: true,
        cans: "",
        comfortHealthClientId: null,
        comfortHealthCareManager_ContactId: 0,
        comfortHealthCareManagerSupervisor_ContactId: 0,
        comfortHealthHCBSCoordinator_ContactId: 0,
        comfortHealthTimeRequirements: "",
        meargen_ContactId: 0,
        roshHameargen_ContactId: 0,
        isSeeingTherapist: false,
    },
    currentPage: 0,
    setCurrentPage: action((state, page) => {
        state.currentPage = page;
    }),
    query:"",
    setQuery:action((state, data)=>{
       state.query= data;
    }),
    activeTab:"general",
    setDetail: action((state, payload) => {
        state.detail = payload;
    }),
    setActiveTab: action((state, payload) => {
        state.activeTab = payload;
    }),
    saveDetail: thunk((actions, payload) => {
        if (payload == null) {
            global.AjaxFailToast();
            return;
        }
        return global.fetchWrapper
            .post(constant.UPDATE_CLIENT_DETAILS + payload.id, payload)
            .then(
                (response) => {
                    if (response.responseCode !== 200) {
                        global.AjaxFailToast();
                    }
                    clients().then(clients => actions.updateClients(clients));
                },
                (error) => {
                    global.log("error", error);
                    global.AjaxFailToast();
                }
            );
    }),
    schedules: [],
    setSchedules: action((state, payload) => {
        state.schedules = payload;
    }),
    scheduleRowDaily: [],
    setScheduleRowDaily: action((state, payload) => {
        state.scheduleRowDaily = payload;
    }),
    scheduleRowSeeking: [],
    setScheduleRowSeeking: action((state, payload) => {
        state.scheduleRowSeeking = payload;
    }),
    scheduleRowProfessional: [],
    setScheduleRowProfessional: action((state, payload) => {
        state.scheduleRowProfessional = payload;
    }),
    scheduleRowAppointment: [],
    setScheduleRowAppointment: action((state, payload) => {
        state.scheduleRowAppointment = payload;
    }),
    fields: {
        careManagerId: 0,
        careManagerName: "",
        careManagerSupervisorId: 0,
        careManagerSupervisorName: "",
        hcbsCoordinatorId: 0,
        hcbsCoordinatorName: "",
        comfortHealthClientId: null,
        comfortHealthTimeRequirements: "",
        comfortHealth_status: "",
        comfortHealth_active: false,
        comfortHealth_enrolledOn: null,
        comfortHealth_comhab_hoursPerWeek: 0,
        comfortHealth_respite_hoursPerWeek: 0,
        comfortHealth_cfss_hoursPerWeek: 0,
        comfortHealth_cats_hoursPerWeek: 0,
        comfortHealth_comhab_approvedOn: null,
        comfortHealth_respite_approvedOn: null,
        comfortHealth_cfss_approvedOn: null,
        comfortHealth_cats_approvedOn: null,
        p3_status: "",
        p3_active: false,
        p3_enrolledOn: null,
        p3_p3service: 1,
        light_status: "",
        light_active: false,
        light_enrolledOn: null,
        light_payment: "Method 3",
    },
    setFields: action((state, payload) => {
        state.fields = payload;
    }),
    fieldsLight: {
        "paymentMethod": null,
        "contactId": 0,
        "contact": "",
        "ccNumber": null,
        "nameOnCard": null,
        "expMonth": null,
        "expYear": null,
        "cvv": null,
        "billingZip": null,
        "note": null,
        "responsibleForPayment": null,
        "monthlyAgreedAmount": null,
        "isPrimaryPaymentMethod": true,
        "id": 0,
        "guidId": "0a34445f-28ac-4c7e-b234-8ab6c8d8887f",
        "createdOn": "2021-05-09T17:35:03.9233333",
        "isDeleted": false
    },
    setFieldsLight: action((state, payload) => {
        state.fieldsLight = payload;
    }),
    treeData: {
        id: "1",
        primaryId: 0,
        parentFolderId: 0,
        name: "Folder 1",
        type: "folder",
        children: [],
    },
    setTreeData: action((state, payload) => {
        state.treeData = payload;
    }),
    clients: [],
    clientListFiltered: [],
    setClientListFiltered: action((state, filterdData) => {
        state.clientListFiltered = filterdData;
    }),
    updateClients: action((state, clients) => {
        state.clients = clients;
        state.clientListFiltered = clients
    }),
    fetchClients: thunk(async actions => {
        let items = await clients();
        actions.updateClients(items);
        return items
        //clients().then(clients => actions.updateClients(clients));
    })
};
export default defaultStore;
