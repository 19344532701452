import React from 'react'
import {Table, TableHead, TableRow, TableBody, TableCell, Collapse, Box, Tooltip, IconButton} from '@mui/material';
import ClickableTableRow from '../../UI/ClickableTableRow';
import EditIcon from '@mui/icons-material/Edit';
import * as constant from '../../../constants'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles, {StyledBadge, StyledTableCell} from './styles';
import {StyledTableRowCell, StyledTableActionsRow} from './StyledTableRowCell'
import {AccordionTableProps} from './AccordionTable.types';
import clsx from 'clsx';
const AccordionTable: React.FC<AccordionTableProps> = (props) => {
  const {title, count, columns, data, handleEditProgressNotes} = props;
  const classes = useStyles({completed:true});
  return (
    <div>
      <Accordion className={classes.accordionTable}>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          >
            <StyledBadge badgeContent={count} color="primary">
              <Typography>{title}</Typography>
            </StyledBadge>
          </AccordionSummary>
          <AccordionDetails>
              <Table aria-label="simple table">
                     <TableHead className={classes.table}>
                         <TableRow>
                             {
                                 columns && columns.map((column:any) => {
                                    return <StyledTableCell>{["edit", "statusIcon", "expandIcon", "deleteIcon", 'button'].includes(column.field)? '': column.title}</StyledTableCell>
                                 })
                             }    
                         </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          data.map((item:any) => (
                            <ClickableTableRow
                              onClick={(event) => { handleEditProgressNotes(item)}} 
                              className={clsx({[classes.completed]: item.noteId ? true: false})}>
                                { 
                                  columns.map((column:any) => { 
                                    if(!column.type || column.type === 'string' || column.type === 'number' || column.type === 'date') {
                                      return (
                                        <StyledTableRowCell maxWidth="150" >
                                          {item[column.field]}
                                        </StyledTableRowCell>
                                      )
                                    } else {
                                      return (
                                        <StyledTableActionsRow
                                          column={column}
                                          rowData={item}
                                        />
                                      )
                                    }
                                  })
                                }
                            </ClickableTableRow>
                          ))
                        }
                      </TableBody>
                </Table>
          </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AccordionTable