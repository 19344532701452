import React, { useEffect } from "react";
import { useParams } from "react-router";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import DataTable from "../../components/DataTable/DataTable"
import ProfessionalGeneralTab from "./ProfessionalGeneralTab.jsx";
import {
    todoColumns
} from "../../api/clients";
import baseStyles from '../../assets/styles'
import { professionals, getProfessionalDetails } from "../../api/professional"
import EmployeeSchedule from "../../components/explorer/Tabs/Schedule/EmployeeSchedule";
import Program from "../../components/explorer/Tabs/Program/program";
import History from "../../components/explorer/Tabs/History/history";
import _ from "lodash";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useTheme } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        margin: "12px 26px",
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        /*'& .MuiPaper-outlined': {
                border: '0px',
            }*/
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .MuiTab-root": {
            color: "#FFFFFF"
        },
    },
    root: {
        flex: 1,
        width: "70%",
        // marginTop: "12px",
        marginLeft: "15%",
        // backgroundColor: theme.palette.background.paper,
        "& .MuiInputLabel-animated": {
            fontSize: "14px",
        },
    },

}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

export default function ExplorerComponent(props) {
    const { id } = useParams();

    const classes = useStyles();
    const baseClasses = baseStyles();
    const theme = useTheme();

    const history = useStoreState((state) => state.history);
    const [value, setValue] = React.useState("general");
    const [isProgramTab, setProgramTab] = React.useState(false);
    const [isEmployee, setIsEmployee] = React.useState(false);
    const [columns, setColumns] = React.useState(todoColumns);
    const [isLoading, setLoading] = React.useState(true);
    const [clientData, setClientData] = React.useState(null);
    const [clientProgramData, setClientProgramData] = React.useState(null);
    const [clientNotesData, setClientNotesData] = React.useState(null);
    const [clientScheduleData, setClientScheduleData] = React.useState(null);
    const [clientToDoData, setClientToDoData] = React.useState(null);
    const [isYungerman, setIsYungerman] = React.useState(false);
    const [isOpenCreateModal, setIsOpenCreateModal] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const exploringName = useStoreState((state) => state.exploringName);
    const exploringType = useStoreState((state) => state.exploringType);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const setPreviousPath = useStoreActions(
        (actions) => actions.setPreviousPath
    );
    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );
    const setExploringId = useStoreActions((actions) => actions.setExploringId);

    //breadcrumb
    const [menuName, setMenuName] = React.useState("Professionals");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const goToTabProgress = () => {
        setValue("progress");
    };

    const goToTabTodo = () => {
        setValue("todo");
    };
    useEffect(
        () => {
            getProfessionalDetails(id).then((response) => {
                setLoading(false);
                setExploringId(id);
                setClientData(response);
                setExploringName(response.contact.firstName + " " + response.contact.lastName);
            });
        },
        [isOpenCreateModal]
    );

    // not needed as we divided this to serve Professional object only
    //useEffect(() => {
    //    professionals().then(value => {
    //        for (let i = 0; i < value.length; i++) {
    //            if (value[i].id == id) {
    //                setIsYungerman(value[i].position == "Yungerman")
    //            }
    //        }
    //    });
    //}, [isOpenCreateModal])

    const _setRowAndColumnTodo = () => {
        return {
            rows: clientToDoData,
            columns: columns,
            screen: "client_todo",
        };
    };

    const _handleData = () => {
        return isProgramTab;
    };
    const handleAddButton = () => {
        setIsOpenCreateModal(true);
    };
    const handleEditData = (data) => {
        setEditData(data);
    };

    return (
        <>
            <main className={classes.content}>
                <div className={baseClasses.toolbar} />
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        {/* <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{ marginBottom: 13, marginLeft: 0, cursor: "pointer" }}
            >
              <Link
                color="inherit"
                style={{ fontSize: 14 }}
                onClick={() => {
                  history(`/${menuName}`);
                }}
              >
                {menuName.toUpperCase()}
              </Link>
              <Link color="inherit" style={{ fontSize: 14 }}>
                {value.toUpperCase()}
              </Link>
              <Typography style={{ fontSize: 14 }} color="textPrimary">
                {_.has(clientData, "firstName")
                  ? clientData.firstName + " " + clientData.lastName
                  : ""}
              </Typography>
            </Breadcrumbs> */}
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div
                                style={{
                                    fontSize: 14,
                                    marginLeft: 0,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    cursor: "pointer",
                                }}
                            >
                                {exploringName}
                            </div>
                            {<Button
                                onClick={() => {
                                    setExploringMode("edit");
                                    history('/professionals');
                                    setPreviousPath("FROM PROFESSIONAL DETAIL");
                                }}
                                className={baseClasses.capitalized}
                                color="primary"
                                variant="contained"
                                style={{
                                    float: "right",
                                    marginTop: 5,
                                    marginBottom: 5,
                                }}
                            >
                                Edit
                            </Button>}
                        </div>
                        <Card variant="outlined">
                            <CardContent classes={{ root: baseClasses.card_view }}>
                                <div className={classes.root}>
                                    <AppBar
                                        className={baseClasses.appbarColor}
                                        position="static"
                                        color="primary"
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            <Tab
                                                className={baseClasses.tabClass}
                                                value="general"
                                                label="General"
                                                {...setRowAndTab(0)}
                                            />
                                            <Tab
                                                className={baseClasses.tabClass}
                                                label="Schedule"
                                                value="schedule"
                                                {...setRowAndTab(3)}
                                            />
                                            <Tab
                                                className={baseClasses.tabClass}
                                                label="To Do"
                                                value="To Do"
                                                {...setRowAndTab(8)}
                                            />
                                            <Tab
                                                className={baseClasses.tabClass}
                                                label="Programs"
                                                value="Programs"
                                                {...setRowAndTab(9)}
                                            />
                                            <Tab
                                                className={baseClasses.tabClass}
                                                label="History"
                                                value="history"
                                                {...setRowAndTab(7)}
                                            />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel
                                        value={value}
                                        index="general"
                                        style={{ backgroundColor: theme.palette.background.paper }}
                                        className={baseClasses.tabRoot}
                                    >
                                        <ProfessionalGeneralTab
                                            employeeInfo={clientData}
                                            clientToDoData={clientToDoData}
                                            clientScheduleData={clientScheduleData}
                                            clientProgramData={clientProgramData}
                                            clientNotesData={clientNotesData}
                                            isYungerman={isYungerman}
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index="program"
                                        style={{ backgroundColor: theme.palette.background.paper }}
                                        className={baseClasses.tabRoot}
                                    >
                                        <Program
                                            clientData={clientData}
                                            clientProgramData={clientProgramData}
                                            isEmployee={isEmployee}
                                            data={_handleData()}
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index="todo"
                                        className={baseClasses.tabRoot}
                                    >
                                        <div className={baseClasses.programTab}>
                                            <div className={baseClasses.button_program}>
                                                <Button
                                                    className={baseClasses.capitalized}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={handleAddButton}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                        <DataTable
                                            data={_setRowAndColumnTodo()}
                                            onOpen={handleAddButton}
                                            onEdit={(params) => { handleEditData(params.row) }}
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index="schedule"
                                        className={baseClasses.tabRoot}
                                    >
                                        <EmployeeSchedule
                                            clientData={clientData}
                                            clientScheduleData={clientScheduleData}
                                            isEmployee={isEmployee}
                                            isYungerman={isYungerman}
                                            data={_handleData()}
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index="history"
                                        className={baseClasses.tabRoot}
                                    >
                                        <History clientData={clientData} />
                                    </TabPanel>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </main>
        </>
    );
}
