import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        // margin: "12px 26px",
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .MuiFormControlLabel-labelPlacementStart": {
            marginRight: "0px",
        },
    },
    
    // End switch

    columnHeder: {
        textAlign: "left",
        paddingLeft: 16,
        fontSize: 17,
        paddingBottom: 13,
    },
    columnFirst: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-between",
        width: "50%",
    },
    hoursWidget: { textAlign: "center", marginLeft: 25, width: "70%" },
    columnFirstWidget: {
        display: "flex",
        justifyContent: "space-between",
    },
    cardSpaceStyle: {
        padding: "10px 20px",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },

    marginSpaceOne: {
        // margin: theme.spacing(1),
        // margin : "8px 0px 8px 0px"
        margin: "0px",
    },

    flexRow: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },

    /* Card Style */
    cardHeader: {
        textTransform: "capitalize",
        backgroundColor: "#FFF",
        minHeight: 39,
        height: 39,
        "& .MuiTypography-colorTextSecondary": {
            color: "rgba(0, 0, 0, 0.54)",
        },
    },

    /* Schedule Style */

    overview_main: {
        padding: "8px",
        paddingBottom: "39px",
    },
    
    
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: "#FFF"
    },
    title: {
        marginTop: theme.spacing(2),
    },
    header: {
        fontWeight: "bold",
        display: "flex",
        width: "100%",
        marginBottom: "12px",
    },
    row_todo: {
        width: "100%",
        display: "flex",
        marginTop: "4px",
        background: "#f3f3f3",
        padding: "0px 12px",
        borderRadius: "4px",
    },

}));
