import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import baseStyles from '../../assets/styles'
import _ from "lodash";
import { toast } from "react-toastify";
import { saveSystemList } from "../../api/system";

import moment from "moment";

export default function AddProgress(props) {
  const baseClasses =  baseStyles();
  const [rows, setRow] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  // Errors selection
  const [providerPerError, setProviderError] = React.useState("");
  const [noteDatePerError, setNoteDatePerError] = React.useState("");
  const [notePerError, setNotePerError] = React.useState("");
  const [id, setId] = React.useState(null);
  const [loaderDialog, setLoaderDialog] = React.useState(false);

  const [fields, setFields] = React.useState({
    ListName: "",
    ListDescription: "",
    DefaultValue: "",
  });

  useEffect(() => {}, []);

  useEffect(() => {
    if (id) {
      setLoaderDialog(true);
    } else {
      resetAllData();
    }
  }, [props.openModal]);

  function resetAllData() {
    var newfields = fields;
    newfields.ListName = "";
    newfields.ListDescription = "";
    newfields.DefaultValue = "";
    
    setProviderError("");
    setNoteDatePerError(null);
    setNotePerError(null);

    if (!_.isEmpty(props.editData)) {
      const edit = props.editData;
      newfields.ListName = edit.listName;
      newfields.ListDescription = edit.listDescription;
      newfields.DefaultValue = edit.defaultValue;
    }
    setFields(newfields);
  }

  const handleFields = (event) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      setFields({ ...fields, [name]: event.target.checked });
    } else {
      setFields({ ...fields, [name]: value });
    }
  };

  const saveProgress = () => {
    //const isValid = formValidationPersonalDetails();
    //global.log(fields);
    var id = -1;
    if (!_.isEmpty(props.editData)) {
        id = props.editData.id;
      }global.log(fields);
    saveSystemList(id, fields).then((data) => {
      global.log(data);
    });
    
    handleClose();
  };

  const formValidationPersonalDetails = () => {
    let isValid = true;

    // if (note === "") {
    //   isValid = false;
    //   setNotePerError("Please enter a valid note");
    // } else {
    //   setNotePerError("");
    // }
    return isValid;
  };

  const handleClose = () => {
    props.onCloseModal();
    resetAllData();
  };

  const CompFormInput = (label, name) => {
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="my-input">{label}</InputLabel>
        <Input
          value={fields[name]}
          name={name}
          //   autoFocus
          onChange={(event) => {
            handleFields(event);
          }}
          id="my-input"
          aria-describedby="my-helper-text"
        />
        {
          <FormHelperText
            className={baseClasses.error}
            id="my-helper-text"
          ></FormHelperText>
        }
      </FormControl>
    );
  };

  const CompFormInput1 = CompFormInput("Name", "ListName");
  const CompFormInput2 = CompFormInput("Description", "ListDescription");
  const CompFormInput3 = CompFormInput("Default", "DefaultValue");

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.openModal}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
        Add a list item
      </DialogTitle>
      <DialogContent>
        {loaderDialog ? (
          <div>Loading...</div>
        ) : (
          <div>
            <Grid
              container
              className={baseClasses.grid}
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <div>{CompFormInput1}</div>
              </Grid>

              <Grid item xs={12}>
                <div>{CompFormInput2}</div>
              </Grid>
              <Grid item xs={12}>
                <div>{CompFormInput3}</div>
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={saveProgress} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
