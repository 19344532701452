import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/styles';
import {
    Button, Chip, FormControl,
    Dialog, DialogContent,
    FormHelperText, Grid, Input,
    InputAdornment, InputLabel,
    MenuItem, OutlinedInput, Select,
    TextField, TextareaAutosize,
    DialogActions,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Autocomplete from '@mui/lab/Autocomplete';
import { authorization } from "../../../../_services/authorization.service"; //permissions, userHasRole
import useUnsavedChangesWarn from "../../../../hooks/useUnsavedChangesWarn";
import {TimeRangeValidator} from '../../../../_helpers/schedule/validators/client-schedule'
import useStyles from '../styles';
import usePermission from '../../../../hooks/usePermission';

export default function Appointment(props) {
    const { show, handleClose, handleEdit, clientSchedule, client, providers } = props;

    //global.log("providers", providers);

    const classes = useStyles();
    const theme = useTheme();

    const [appointment, setAppointment] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});

    const { actions } = usePermission(global.permissions.clients.schedules);
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    useEffect(() => {
        //let slcProvider = null;
        //if (providers != undefined && providers.length > 0)
        //    slcProvider = providers.find((p) => p.fullName == clientSchedule.providerName);
        let newAppt = {
            ...clientSchedule,
            date: clientSchedule.date || clientSchedule.startDate,
            selectedProfessional: clientSchedule.providerId ?
                {
                    providerId: clientSchedule.providerId,
                    providerName: clientSchedule.providerName,
                } : null,
            status: !clientSchedule.status || clientSchedule.status === 'seeking' ? 'financial' : clientSchedule.status,
        };
        setAppointment(newAppt);
    }, [clientSchedule, providers]);


    const handleSaveAndClose = () => {
        const providerId = appointment.selectedProfessional ? appointment.selectedProfessional.providerId : null;

        if (validateForm(appointment)) {
            var savePromise = handleEdit({
                ...clientSchedule,
                ...appointment,
                ...appointment.selectedProfessional,
                providerId,
            });
            if (savePromise && savePromise.then)
                savePromise.then((data) => {
                    closeModal(data);
                });
            else {
                closeModal();
            }
        }
    }

    const handleCloseState = (event, srcMethod) => {
        if (srcMethod && srcMethod == "backdropClick")
            return;
        if (hasChanges()) {
            if (!window.confirm("You have some unsaved changes, do you want to discard them?")) {
                return;
            }
        }
        closeModal();
    }
    const closeModal = (data) => {
        setClean();
        setAppointment({status: 'financial'});
        setFieldErrors({});
        handleClose(data);
    }

    const validateDate = (date) => {
        let validationResult = {
            isValid: true,
            fieldMessages: {
                date: null,
            }
        };

        const addErrorMessage = (field, message) => {
            validationResult.isValid = false;
            validationResult.fieldMessages[field] = message;
        }

        if (date == null || date == '') {
            addErrorMessage('date', 'Date is required');
            return validationResult;
        }

        if (date == 'Invalid Date') {
            addErrorMessage('date', 'A valid date is required');
            return validationResult;
        }

        return validationResult;
    }

    const validateStartTime = (startTime) => {
        let validationResult = {
            isValid: true,
            fieldMessages: {
                startTime: null,
            }
        };

        const addErrorMessage = (field, message) => {
            validationResult.isValid = false;
            validationResult.fieldMessages[field] = message;
        }

        if (startTime == null || startTime == '') {
            addErrorMessage('startTime', 'Time from is required');
            return validationResult;
        }

        if (startTime == 'Invalid Date') {
            addErrorMessage('startTime', 'A valid time is required');
            return validationResult;
        }

        return validationResult;
    }

    const validateEndTime = (endTime) => {
        let validationResult = {
            isValid: true,
            fieldMessages: {
                endTime: null,
            }
        };

        const addErrorMessage = (field, message) => {
            validationResult.isValid = false;
            validationResult.fieldMessages[field] = message;
        }

        if (endTime == null || endTime == '') {
            addErrorMessage('endTime', 'Time to is required');
            return validationResult;
        }

        if (endTime == 'Invalid Date') {
            addErrorMessage('endTime', 'A valid time is required');
            return validationResult;
        }

        return validationResult;
    }

    const validateTimeFields = (timeFields, requireStartTime, requireEndTime) => {
        const timeRangeValidator = new TimeRangeValidator(timeFields.startTime, timeFields.endTime, {
            requireStartTime: requireStartTime,
            requireEndTime: requireEndTime
        });

        timeRangeValidator.validate();
        return timeRangeValidator;
    }

    const validateSelectedProfessional = (selectedProfessional) => {
        let validationResult = {
            isValid: true,
            fieldMessages: {
                selectedProfessional: null,
            }
        };

        if (selectedProfessional == null || selectedProfessional.providerId == null) {
            validationResult.isValid = false;
            validationResult.fieldMessages.selectedProfessional = "A professional must be selected";
            return validationResult;
        }

        return validationResult;
    }

    const validateForm = (appointment) => {
        const validationResults = [
            validateDate(global.dateTime.getDateObject(appointment.date)),
            validateStartTime(global.dateTime.getDateObject(appointment.startTime)),
            validateEndTime(global.dateTime.getDateObject(appointment.endTime)),
            validateTimeFields({
                startTime: appointment.startTime,
                endTime: appointment.endTime,
            }, validateStartTime(global.dateTime.getDateObject(appointment.startTime)),
            validateEndTime(global.dateTime.getDateObject(appointment.endTime)),),
            validateSelectedProfessional(appointment.selectedProfessional),
        ];

        let allValid = true;
        let newFieldErrors = {};

        validationResults.forEach(result => {
            if (result.isValid) return;

            allValid = false;

            newFieldErrors = {
                ...newFieldErrors,
                ...result.fieldMessages,
            };
        });

        setFieldErrors(newFieldErrors);

        return allValid;
    }

    const compElementId = "clidtl-sch-apptsch";
    const modalId = compElementId + "-";

    return (
        <Dialog onClose={handleCloseState} aria-labelledby="simple-dialog-title" open={show} >
            <DialogContent>
                <h4>{appointment.clientScheduleId ? "Edit" : "Add"} Appointment <i id={modalId + "id"}>{appointment.clientScheduleId ?? ''}</i></h4>
                <div >
                    <div>
                        <p className = {classes.pChaim}>Bucher:
                            <span className = {classes.boldModel}>
                                {client ? client.name : clientSchedule.clientName != undefined ? clientSchedule.clientName : "-"}
                            </span>
                        </p>
                    </div>
                    <Grid container className={classes.form}>
                        <Grid xs={5.8}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <Autocomplete
                                    options={providers?.map(p => ({ id: p.employeeId, name: p.name }))}
                                    getOptionLabel={(professional) => `${professional.name}`}
                                    value={appointment.selectedProfessional ? {
                                        id: appointment.selectedProfessional.providerId,
                                        name: appointment.selectedProfessional.providerName
                                    } : null}
                                    autoComplete
                                    onChange={(event, selectedProfessional) => {
                                        setDirty();
                                        const validationResult = validateSelectedProfessional(selectedProfessional ? {
                                            providerId: selectedProfessional.id, providerName: selectedProfessional.name
                                        } : {});

                                        setFieldErrors({
                                            ...fieldErrors,
                                            ...validationResult.fieldMessages,
                                        });

                                        let newAppt = {
                                            ...appointment,
                                            selectedProfessional: selectedProfessional ? {
                                                providerId: selectedProfessional.id,
                                                providerName: selectedProfessional.name
                                            } : null,
                                        };
                                        setAppointment(newAppt);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            required {...params}
                                            label="Professional"
                                            size="small"
                                            variant="outlined"
                                            error={fieldErrors.selectedProfessional}
                                            helperText={fieldErrors.selectedProfessional}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={0.4}></Grid>
                        <Grid xs={5.8}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        id="date"
                                        label="Date"
                                        format="MM/dd/yyyy"
                                        style={{ marginRight: 4 }}
                                        size="small"
                                        error={fieldErrors.date != null}
                                        value={appointment.date != undefined ? new Date(appointment.date) : null}
                                        onChange={(newValue) => {
                                            setDirty();
                                            const validationResult = validateDate(newValue);
                                            setFieldErrors({
                                                ...fieldErrors,
                                                ...validationResult.fieldMessages,
                                            });
                                            let newAppt = { ...appointment, date: global.dateTime.getDateTime(newValue) };
                                            setAppointment(newAppt);
                                        }}
                                        renderInput={(params) => <TextField 
                                            {...params} 
                                            error={fieldErrors.date}
                                            helperText={fieldErrors.date}
                                            size={'small'}
                                            />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.form}>
                        <Grid item xs={5.8}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        id="time"
                                        required
                                        label="Time From"
                                        inputVariant="outlined"
                                        size="small"
                                        style={{ marginRight: 4 }}
                                        value={appointment.startTime != undefined ? appointment.startTime : null}
                                        onChange={(newValue) => {
                                            setDirty();
                                            const validationResult = validateStartTime(newValue);
                                            setFieldErrors({
                                                ...fieldErrors,
                                                ...validationResult.fieldMessages,
                                            });
                                            let newAppt = { ...appointment, startTime: global.dateTime.getDateTime(newValue) };
                                            setAppointment(newAppt);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            error={fieldErrors.startTime != null}
                                            helperText={fieldErrors.startTime}
                                            size={'small'}
                                        />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={0.4}></Grid>
                        <Grid item xs={5.8}>
                            <FormControl variant="outlined" className={classes.formControl} >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        required
                                        id="time"
                                        label="Time To"
                                        inputVariant="outlined"
                                        size="small"
                                        style={{ marginRight: 4 }}
                                        value={appointment.endTime != undefined ? appointment.endTime : null}
                                        onChange={(newValue) => {
                                            setDirty();
                                            const validationResult = validateEndTime(newValue);

                                            setFieldErrors({
                                                ...fieldErrors,
                                                ...validationResult.fieldMessages
                                            });

                                            let newAppt = { ...appointment, endTime: global.dateTime.getDateTime(newValue) };
                                            setAppointment(newAppt);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            error={fieldErrors.endTime}
                                            helperText={fieldErrors.endTime}
                                            size={'small'}
                                        />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.form}>
                        <Grid item xs={5.8}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <TextField
                                    id={modalId + "rte"}
                                    label="Rate"
                                    variant="outlined"
                                    size="small"
                                    value={appointment && appointment.rate ? appointment.rate : 0}
                                    onChange={(event) => {
                                        setDirty();
                                        let newAppt = { ...appointment, rate: event.target.value };
                                        setAppointment(newAppt);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={0.4}></Grid>
                        <Grid item xs={5.8}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <TextField fullWidth={true}
                                    id={modalId + "pvd-sts"} select label="Status" variant="outlined" size="small"
                                    value={appointment != undefined && appointment.status != undefined ? appointment.status : ""}
                                    onChange={(event) => {
                                        setDirty();
                                        let value = event.target.value;
                                            setAppointment({ ...appointment, status: value })
                                    }}
                                >
                                    {/*<MenuItem key="seeking" value="seeking" disabled>Pending Professional </MenuItem>*/}
                                    <MenuItem key="financial" value="financial">Pending Financial </MenuItem>
                                    <MenuItem key="active" value="active" disabled={!actions.canActivateSchedule}>Active </MenuItem>
                                    <MenuItem key="financial-rejected" value="rejected" disabled={!actions?.canActivateSchedule}>Financially Rejected </MenuItem>

                                </TextField>
                                {appointment.dateApproved &&
                                    <>
                                        <span
                                            id={modalId + "pvd-dtapv"}
                                           
                                            style={{ fontSize: '14px' }}
                                        >
                                            {"Approved On " + global.dateTime.getUSAFormattedDateString(appointment.dateApproved)}
                                        </span>
                                    </>
                                }
                                {appointment?.status === 'rejected' &&
                                    <div>
                                        <TextField
                                            fullwidth
                                            id={modalId + "rsnfrrjc"}
                                            label="Reason for rejection"
                                            variant="outlined"
                                            size="small"
                                            style={{ marginTop: 8, width: "100%" }}
                                            value={clientSchedule?.reasonForRejection}
                                            onChange={(event) => {
                                                setDirty();
                                                setAppointment((prev) => {
                                                    return {
                                                        ...prev,
                                                        reasonForRejection: event.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                <label >Note</label>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    rowsMin={3}
                                    placeholder=""
                                    value={appointment.note}
                                    onChange={(event) => {
                                        setDirty();
                                        let newAppt = { ...appointment, note: event.target.value };
                                        setAppointment(newAppt);
                                    }}
                                    style={{height: "100px", border: "1px solid lightgrey"}}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className = {classes.btnCancel} onClick={handleCloseState}>Cancel</Button>

                <Button className= {classes.btnSave} onClick={handleSaveAndClose}>
                    Save
                </Button>
            </DialogActions>

        </Dialog >
    )
}