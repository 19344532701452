import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material";

export default makeStyles((theme) => ({
    formControl1: {
        minWidth: '97%',
        margin: '25px 0px',
        padding: '10px 0px',
    },
    formControl: {
        width: '100%',
    },
    checkboxes: {
        "& .MuiCheckbox-root": {
            padding: '0px'
        },
        "&.MuiFormControlLabel-root": {
            margin: '2px'
        },
        "& .MuiFormControlLabel-label": {
            fontSize: '14px',
            width: '150px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    form: {
        margin: 0,
        "&.MuiGrid-root": {
            margin: 0,
            marginTop: 0,
            marginRight: 0,
            marginBottom: 10,
            marginLeft: 0,
        }
    },
    marginTop8: {
        marginTop: '8px'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    textField: {
        marginLeft: 70,
        width: 150,
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 30
    },
    textDelete: {
        fontSize: '14px',
        marginTop: '5%'
    },
    loadDel: {
        backgroundColor: 'transparent !important',
        float: 'right',
        marginTop: '20px',
        borderRadius: '5px',
        color: 'black !important',
        fontSize: '13px',
        width: '100px',
        height: '30px',
        border: '1px solid #1976d2 !important',
        marginRight: '20px'
    },
    btnDelete: {
        backgroundColor: 'transparent !important',
        float: 'right',
        marginTop: '30px',
        borderRadius: '5px',
        color: 'black !important',
        fontSize: '13px',
        width: '100px',
        height: '30px',
        border: '1px solid red !important',
        marginRight: '10px !important',
        marginBottom: '20px !important',
    },
    btnSave: {
        backgroundColor: '#1976d2 !important',
        float: 'right',
        marginTop: '30px',
        borderRadius: '5px',
        color: 'white !important',
        fontSize: '11px !important',
        width: '100px',
        height: '30px',
        border: 'none',
        marginRight: '20px !important',
        marginBottom: '20px !important',
    },
    btnCancel: {
        backgroundColor: 'transparent !important',
        float: 'right',
        marginTop: '30px',
        borderRadius: '5px',
        fontSize: '11px !important',
        width: '100px',
        height: '30px',
        color: '#000 !important',
        border: '1px solid #1976d2 !important',
        marginRight: '10px !important',
        marginBottom: '20px !important'
    },
    leftModelYounger: {
        borderRight: '1px solid #E0E0E0',
        padding: '10px',
    },
    flexModel: {
        display: 'flex',
    },
    pChaim: {
        fontSize: '12px',
        padding: '0px !important',
        margin: '5px',
    },
    selectModell: {
        border: '1px solid lightgray',
        height: '25px',
        borderRadius: '4px',
        fontSize: '12px',
        marginLeft: '5%',
        width: '50%',
    },
    selectProfess: {
        border: '1px solid lightgray',
        height: '25px',
        borderRadius: '4px',
        fontSize: '12px',
        marginLeft: '5%',
        width: '100%',
    },
    radioModel: {
        marginLeft: '35%',
        marginTop: '-10px',
    },
    radioLabel: {
        fontSize: '12px',
        padding: '5px',
    },
    boxModel1: {
        minHeight: '200px',
        backgroundColor: 'white',
    },
    inputModel: {
        height: '30px',
        fontSize: '12px !important',
        marginBottom: '10px',
    },
    pModelDate: {
        fontSize: '12px',
        paddingTop: '10px',
        marginBottom: '0px',
    },
    startDate1: {
        fontSize: '11px',
        marginBottom: '0px',
        paddingLeft: '7px',
    },
    available1: {
        borderBottom: '1px solid #DADEF0',
        fontSize: '12px',
        paddingBottom: '10px',
    },
    selectModel: {
        border: 'none !important',
        borderBottom: '1px solid #DADEF0 !important',
        width: '100%',
        marginTop: '20px',
    },
    loadResult: {
        backgroundColor: '#1976d2 !important',
        float: 'right',
        marginTop: '30px',
        borderRadius: '5px',
        color: 'white !important',
        fontSize: '11px !important',
        width: '100px',
        height: '30px',
        border: 'none',
        marginRight: '20px !important',
        marginBottom: '20px !important',
    },
    loadProfessional: {
        backgroundColor: '#1976d2 !important',
        float: 'right',
        marginTop: '-40px',
        borderRadius: '5px',
        color: 'white !important',
        fontSize: '13px',
        width: '100px',
        height: '30px',
    },
    selectedProf: {
        padding: '30px',
        fontSize: '15px',
    },
    innerBoxProfessional: {
        border: '1px solid #DADEF0',
        borderRadius: '5px',
        height: '380px !important',
        padding: '20px !important',
        margin: '12px',
    },
    loadResultProfessional: {
        backgroundColor: '#1976d2 !important',
        float: 'right',
        marginTop: '-5% !important',
        borderRadius: '5px',
        color: 'white !important',
        fontSize: '11px !important',
        width: '100px',
        height: '30px',
        border: 'none',
        marginBottom: '20px !important',
        marginRight: '10px !important',
    },
    Status: {
        width: '45% !important',
        marginLeft: '35px !important',
        fontSize: '9px',
    },
    boxModelTable: {
        width: '250px',
        minHeight: '100px',
        border: '1px solid blue',
        borderRadius: '5px !important',
        marginTop: '15%',
    },
    TypeProfessional: {
        width: '100% !important',
        marginTop: '20px !important',

    },
    dateWidth: {
        width: '100% !important',
    },
    datePaddingBox: {
        marginTop: '10px !important',
    },
    actionButton: {
        marginRight: '10px !important',
    },
    timeTo: {
        width: '100% !important',
        marginLeft: '4% !important',
    },
    sunday: {
        textAlign: 'center',
        color: 'blue',
        borderBottom: '1px solid blue',
        marginTop: '5px',
        marginBottom: '5px',
        cursor: 'pointer',
        height: '20px',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: ' 12px',
    },
    MuiInputLabelOutlinedMuiInputLabelShrink: {
        transform: 'translate(14px, -6px) scale(0.75)',
        color: '#1976d2 !important',
    },
    clientScheduleHeader: {

        margin: 0,
    },

    daysPicker: {
        alignContent: 'center',
        textAlign: 'center',
        paddingBottom: '20px',
    },
    boldModel: {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: 0,
    },

    pChaim1: {
        fontSize: '18px',
    },
    boxModel: {
        minHeight: '140px !important',
        backgroundColor: 'white',
    },

    availableResultContainer: {
        height: '290px',
        display: "flex",
        flexDirection: "column"
    },
    limitWarning: {
        color: 'red',
    },
    pModelDate1: {
        fontSize: '12px',
        paddingTop: '10% !important',
        marginBottom: '0px',
        textAlign: 'end',
        color: '#1976d2',
    },

    startDate: {
        fontSize: '14px !important',
        marginBottom: '0px',
        marginLeft: '-5px',
    },

    spanConfirm: {
        fontSize: '12px',
        fontWeight: 400,
    },

    innerBox: {
        border: '0px solid #DADEF0',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        height: '490px !important',
        padding: '10px !important',
    },

    available: {
        borderBottom: '1px solid #DADEF0',
        fontSize: '12px',
        paddingBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    availableText: {
        fontSize: '12px',
    },
    clearFilters: {
        fontSize: '12px',
        textAlign: 'end',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#1976d2',
    },
    pointer: {
        cursor: 'pointer'
    },
    switchDate: {
        fontSize: '12px',
        textAlign: 'end',
        textDecoration: 'underline',
        color: '#1976d2',
    },

    selectButton: {
        textAlign: 'center',
        height: '20px !important',
        backgroundColor: 'transparent',
        fontSize: '8px !important',
        border: '1px solid #1976d2 !important',
    },

    leftJj: {
        padding: '10px',
        fontSize: '14px',
    },

    cardResults: {
        height: '100%',
        overflowX: 'hidden', /* Hide horizontal scrollbar */
    },

    inputModelRight: {
        paddingLeft: '20px',
        border: '1px solid #E0E0E0',
        borderBottom: '1px solid #DADEF0 !important',
    },
    loadResultYoungerman: {
        backgroundColor: '#1976d2 !important',
        float: 'right',
        marginTop: '5% !important',
        borderRadius: '5px',
        color: 'white !important',
        fontSize: '11px !important',
        width: '100px',
        height: '30px',
        border: 'none',
        marginBottom: '20px !important',
    },

    loadResultYoungermanDisabled: {
        color: 'rgba(0, 0, 0, 0.26) !important',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },

    specialities: {
        width: '76% !important',
        float: 'right !important',
    },
    MuiTypographyBody1: {
        fontSize: '0.8rem !important',
    },

    dateEndSchedule: {
        textAlign: 'center',
        marginTop: '5% !important',
    },

    loadResultSchedule: {
        backgroundColor: ' #1976d2 !important',
        float: 'right',
        marginTop: '30px',
        borderRadius: '5px',
        color: 'white !important',
        fontSize: '11px !important',
        width: '130px',
        height: '30px',
        border: 'none',
        marginRight: '20px !important',
        marginBottom: '20px !important',
    },

    tableStyleCard: {
        marginTop: '15%',
        width: '100% !important',
        maxHeight: '300px',
        overflowX: 'hidden', /* Hide horizontal scrollbar */
        overflowY: 'scroll',
    },

    justify: {
        justifyContent: 'center !important',
    },

    loadSelect: {
        backgroundColor: 'transparent !important',
        float: 'right',
        marginTop: '3px',
        borderRadius: '5px',
        color: '#1976d2 !important',
        fontSize: '9px',
        height: '15px',
        border: '1px solid #1976d2 !important',
    },

    mosheBlue: {
        textDecoration: 'underline',
        color: '#1976d2',
    },

    boroPark: {
        width: '76% !important'
    },

    rateTo: {
        float: 'right !important',
    },

    firstRow: {
        color: 'blue !important',
        textDecoration: 'underline',
    },

    boxModelTableCustom: {
        width: '250px',
        minHeight: '100px',
        border: '1px solid blue',
        borderRadius: '5px !important',
        marginTop: '5%',
        marginBottom: '5%',
        marginLeft: '25%',
    },
    tableDay: {
        textAlign: 'center',
        color: 'white',
        marginTop: '0px',
        marginBottom: '0px',
        height: '20px',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '12px',
        cursor: 'pointer',
    },
    borderB: {
        borderBottom: '1px solid #1976d2',
    },
    monday: {
        textAlign: 'center',
        color: 'white',
        borderBottom: '1px solid #1976d2',
        marginTop: '0px',
        marginBottom: '0px',
        height: '20px',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '12px',
        cursor: 'pointer',
    },
    tuesday: {
        textAlign: 'center',
        color: 'white',
        marginTop: '0px',
        marginBottom: '0px',
        height: '20px',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '12px',
        cursor: 'pointer',
    },

    filterBox: {
        borderTop: '1px solid #DADEF0 !important',
        minHeight: '40px',
        width: '98%',
        padding: "0px 0px",
        margin: '0px 0px'
    },

    priceDollar: {
        fontSize: '10px',
        paddingLeft: '12px',
        margin: '0px !important',
        overflow: "hidden",
        maxWidth: 200,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },

    crossDiv: {
        margin: 0,
        marginLeft: '10px',
        display: 'flex',
        padding: 0,
    },

    selectButtonDrop: {
        border: '1px solid #dadef0',
        marginTop: '3px',
        width: '170px',
        backgroundColor: theme.palette.background.paper
    },

    clearIcon: {
        color: 'red',
        margin: '10px',
        cursor: 'pointer',
    },
    modalGrid: {
        height: '100%',
        paddingRight: '0px'
    },
    dialogPaper: {
        minHeight: '85%',
        maxHeight: '85%',
        overflow: 'hidden !important'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    field: {
        margin: 2,
        marginTop: 10,
        width: '95%',
    },
    width95: {
        width: '95%',
    },
    multiSelect: {
        '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
            top: '-8px !important'
        }
    },
    textarea: {
        fontSize: '14px',
        borderColor: '#c4c4c4',
        borderRadius: "5px",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: "20px",
                borderColor: "#c4c4c4"
            },
            "&.Mui-focused fieldset": {
                borderWidth: "2px"
            }
        },
    },
    statusSelect: {
        marginRight: 0,
        padding: 0,
        width: '100%',
        pointerEvents: 'auto'
    },

    infoTitle: {
        fontSize: '14px',
        margin: '5px',
        padding: '0px'
    },
    infoValue: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 0,
        paddingLeft: 4
    },
    clickableText: {
        color: 'blue',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    providerName: {
        color: theme.palette.primary.main,
        overflow: "hidden",
        maxWidth: 200,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: '0px !important',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    tooltip: {
        fontSize: "10px",
        padding: "8px",
        '& h3': {
            fontSize: "12px",
        },
    },
    availabilityErrors: {
        fontSize: "10px",
        padding: "0px",
        '& h3': {
            fontSize: "12px",
        },
    },
    dateApproved: {
        marginTop: '5px',
        marginRight: '4px'
    }


}))