import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from "lodash";
import moment from "moment";
import { useStoreState } from "easy-peasy";

import RichTextField from '../../../UI/RichTextField'
import authHeader from "../../../../screens/_helper/auth-header";
import useStyles from "./styles";
import useBaseStyles from '../../../../assets/styles';
import useUnsavedChangesWarn from "../../../../hooks/useUnsavedChangesWarn";
import { getHebrewMonthRangeFromRegDate } from "../../../../_helpers/datetime";
import { getTemplateNotes, saveClientMission } from "../../../../api/clients";
import AnimLoadingState from '../../../common/AnimLoadingState'
import { strings } from "../../../../_helpers/strings";
import { GridRichTextWrapperType, AddMissionType } from './types'

const GridRichTextWrapper = (props: GridRichTextWrapperType) => {
    const { label, value, setDirty, setState, error, type, variant, fullWidth, gridSX } = props;
    const baseClasses: any = useBaseStyles();
    return (
        <Grid item xs={gridSX}>
            <FormControl fullWidth={fullWidth}>
                <RichTextField
                    {...props}
                    label={label}
                    multiline
                    variant={variant}
                    value={value}
                    type={type}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setDirty();
                    }}
                    setState={(value: string) => {
                        setState(value)
                    }}
                />
                {!!error ?
                    <FormHelperText
                        className={baseClasses.error}
                        style={{ color: 'red' }}
                        id="my-helper-text"
                    >
                        {error}
                    </FormHelperText> :
                    null
                }
            </FormControl>
        </Grid>
    )
};
const errorsDefaultState = {
    goal: '',
    title: '',
    problem: '',
    concerns: '',
    problemDescription: '',
    planForConcern: '',
    estimateCompletion: ''
}
const AddMission: React.FC<AddMissionType> = (props) => {
    const globalScope: any = global;
    const baseClasses: any = useBaseStyles();
    const classes: any = useStyles();
    const [goal, setGoal] = useState<string>("");
    const [problem, setProblem] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [concerns, setConcerns] = useState<string>("");
    const [problemDescription, setProblemDescription] = useState<string>("");
    const [planForConcern, setPlanForConcern] = useState<string>("");
    const [completedOn, setCompletedOn] = useState<Date | string>(new Date());
    const [error, setError] = useState<any>(errorsDefaultState);
    const [loaderDialog, setLoaderDialog] = React.useState(false);
    const exploringId = useStoreState((state: any) => state.exploringId);
    const [changesHelper] = useUnsavedChangesWarn();
    const { setDirty, setClean, wConfirm, hasChanges } = changesHelper;

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>, reason: any) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        closeModal();
    };

    const resetAllData = (clean = false) => {
        setTitle("");
        setGoal("");
        setProblem("");
        setConcerns("");
        setProblemDescription("");
        setPlanForConcern("");
        setCompletedOn("");
        setError(errorsDefaultState);

        if (!_.isEmpty(props.editData) && !clean) {
            const edit = props.editData;
            setTitle(edit.title);
            setGoal(edit.goal);
            setProblem(edit.problem);
            setProblemDescription(edit.problemDescription);
            setConcerns(edit.concerns);
            setPlanForConcern(edit.planForConcern);
            setCompletedOn(edit.completedOn)
        }
        setClean();
    };

    const errorMessages: any = {
        title: "Title can't be empty",
        problem: "Problem can't be empty",
        goal: "Goal can't be empty",

    }

    const getFieldsWithErrors = (): any[] => {
        let errorFields = []
        if (!strings.getRichTextObjFromString(goal).value) {
            errorFields.push("goal");
        }
        if (!strings.getRichTextObjFromString(problem).value) {
            errorFields.push("problem");

        }
        if (!strings.getRichTextObjFromString(title).value) {
            errorFields.push("title");
        }
        return errorFields;
    };

    const formValidation = () => {
        const errorFields = getFieldsWithErrors();
        let errors: any = {};
        if (errorFields.length > 0) {
            errorFields.forEach((x: any) => errors[x] = errorMessages[x])
            setError(errors);
            return false;
        }
        return true
    }

    const saveMission = () => {
        const isValid = formValidation();
        const id = props?.editData?.missionId || -1;
        if (isValid) {
            const payload = {
                id: id,
                title: title,
                problem: problem,
                problemDescription: problemDescription,
                goal: goal,
                createdOn: id === -1 ? new Date() : props?.editData.createdOn,
                concerns: concerns,
                planForConcern: planForConcern,
                objectiveSortOrderNote: "",
                completedOn: completedOn,
                clientId: exploringId
            };
            setLoaderDialog(true);
            saveClientMission(id, payload).then((data: any) => {
                setLoaderDialog(false);
                closeModal(data);
            });
        };
    };

    const closeModal = (reloadData = false) => {
        props.onCloseModal(reloadData, 'mission');
        resetAllData(true);
        setLoaderDialog(false)
    };

    useEffect(() => {
        resetAllData()
    }, [props.isOpenCreateModal]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpenCreateModal}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <AnimLoadingState loading={loaderDialog} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                {_.isEmpty(props.editData) ? "Add" : "Edit"} Mission
            </DialogTitle>
            <DialogContent>
                <div>
                    <Grid
                        container
                        className={baseClasses.grid}
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <GridRichTextWrapper
                            fullWidth={true}
                            gridSX={12}
                            type="text"
                            label="Title"
                            variant="outlined"
                            value={title}
                            setState={(val: string) => {
                                setTitle(val)
                            }}
                            setDirty={setDirty}
                            error={error.title}
                        />
                        <GridRichTextWrapper
                            fullWidth={true}
                            gridSX={12}
                            type="text"
                            label="Problem"
                            variant="outlined"
                            value={problem}
                            setState={(val: string) => {
                                setProblem(val)
                            }}
                            setDirty={setDirty}
                            error={error.problem}
                        />

                        <GridRichTextWrapper
                            fullWidth={true}
                            gridSX={12}
                            type="text"
                            label="More Details"
                            variant="outlined"
                            value={problemDescription}
                            rows={3}
                            setState={(val: string) => {
                                setProblemDescription(val)
                            }}
                            setDirty={setDirty}
                            error={error.problemDescription}
                        />
                        <GridRichTextWrapper
                            fullWidth={true}
                            gridSX={12}
                            type="text"
                            label="Long-Term Goal"
                            variant="outlined"
                            value={goal}
                            setState={(val: string) => {
                                setGoal(val)
                            }}
                            setDirty={setDirty}
                            error={error.goal}
                        />
                        <GridRichTextWrapper
                            fullWidth={true}
                            gridSX={12}
                            type="text"
                            label="Concern"
                            variant="outlined"
                            value={concerns}
                            setState={(val: string) => {
                                setConcerns(val)
                            }}
                            setDirty={setDirty}
                            error={error.concerns}
                        />
                        <GridRichTextWrapper
                            fullWidth={true}
                            gridSX={12}
                            type="text"
                            label="Plan For Concern"
                            variant="outlined"
                            value={planForConcern}
                            setState={(val: string) => {
                                setPlanForConcern(val)
                            }}
                            setDirty={setDirty}
                            error={error.problemDescription}
                        />
                        {
                            completedOn &&
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Completion Date"
                                        value={completedOn}
                                            onChange={(valDate: any) => {
                                                setCompletedOn(valDate);
                                        }}
                                        renderInput={(params:any) => <TextField {...params} size="medium" variant={"outlined"} fullWidth />}
                                    />
                                </LocalizationProvider>
                                {!!error.completedOn ?
                                    <FormHelperText
                                        className={baseClasses.error}
                                        style={{ color: 'red' }}
                                        id="my-helper-text"
                                    >
                                        {error.completedOn}
                                    </FormHelperText> :
                                    null
                                }
                            </Grid>
                        }
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={saveMission} color="primary">
                    Save
                </Button>
                <Button onClick={(e) => handleClose(e, undefined)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddMission;