/**
 * Functional Component - Dialog for uploading timesheet data and file
 * @property {boolean} isOpenCreateModal - for opening modal
 * @property {Function} onCloseModal - for closing model and sending back data after saving.
 * @property {*} editData - for passing data from parent component, to fill fields with already exited values
 */

import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Autocomplete,
    Typography,
    Grid
} from "@mui/material";
import _ from 'lodash';
import { HDate, gematriya } from '@hebcal/core';
import Button from "@mui/material/Button";
import {getISODate } from '../../../../_helpers/datetime';
import { timesheetClientListType, timesheetInfoType } from '../../../../assets/types'
import { useStoreState } from "easy-peasy";
import { hebrewMonthNameType, timesheetForServiceMonthType, timesheetFileType } from '../../../../assets/types'
import AnimLoadingState from '../../../common/AnimLoadingState'
import useUnsavedChangesWarn from "../../../../hooks/useUnsavedChangesWarn";
import useStyles from "../../styles";
import UploadFile from '../../../UploadFile/UploadFile'
import { DivContainer} from '../../../../assets/styles/styledComponents'
import useBaseStyles from '../../../../assets/styles';
import { getTimesheetClientList, getTimesheetClientService, getTimesheetForServiceMonth, saveTimesheetForServiceMonth } from "../../../../api/timesheet";
import { hebrewMonthNames, HebrewMonthNamesLeapYear } from "../../../../_helpers/hebrewDate/constants";
import { HebrewMonthDateRange } from "../../../../_helpers/hebrewDate/hebrewMonthDateRange";

  type TimesheetUploaderType = {
      isOpenCreateModal: boolean,
      onCloseModal: Function,
      editData: any,
      hebrewMonthRanges: HebrewMonthDateRange[]
  }
const TimesheetUploader: React.FC<TimesheetUploaderType> = (props) => {
    const { hebrewMonthRanges } = props;
    const globalScope: any = global;
    const baseClasses: any = useBaseStyles();
    const classes: any = useStyles();
    const [hebMonths, setHebMonths] = useState<HebrewMonthDateRange[]>([]);
    const [services, setServices] = useState<any>([]);
    const [clients, setClients] = useState<timesheetClientListType[]>([]);

    const [inputValue, setInputValue] = React.useState('');
    const [selectedMonth, setSelectedMonth] = useState<HebrewMonthDateRange | null>();
    const [selectedService, setSelectedService] = useState<any>();
    const [selectedClient, setSelectedClient] = useState<any>();
    const [timesheetFile, setTimesheetFile] = useState<timesheetFileType | null>(null);
    const [timesheetInfo, setTimesheetInfo] = useState<timesheetInfoType | null>(null);
    const [isNewFile, setisNewFile] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [changesHelper] = useUnsavedChangesWarn();
    const { setDirty, setClean, wConfirm, hasChanges } = changesHelper;
    const userId = globalScope.account.userValue.id;

    const isMinutesValid = () => timesheetInfo?.minutes ?
        (timesheetInfo?.hours < timesheetInfo?.maxHours ? timesheetInfo?.minutes < 60 : timesheetInfo?.minutes <= timesheetInfo?.maxMinutes)
        : true
    const isHoursValid = () => timesheetInfo?.hours ? (timesheetInfo?.hours <= timesheetInfo?.maxHours) : true
    
    /**
     * Initall call with current for loading client data, setting hebrew months
     */
    useEffect(() => {
        if (!props.editData) {
            const currentMonth = hebrewMonthRanges.find(range => range.monthIndex === 0)
            if (currentMonth) {
                setSelectedMonth(currentMonth);
                loadClientList(currentMonth)
            }
            setHebMonths(hebrewMonthRanges);
        }
    }, [hebrewMonthRanges]);

    //reload file after service or month changes
    useEffect(() => {
        if (!props.editData) {
            if (selectedMonth && selectedService)
                loadTimesheetFile(selectedMonth as HebrewMonthDateRange, selectedService.id)
            else //insufficint data to load file
            {
                setTimesheetInfo(null);
                setTimesheetFile(null);
            }
        }
    }, [selectedMonth, selectedService])

    //reload client list after every month change
    useEffect(() => {
        if (!props.editData) {
            if (selectedMonth)
                loadClientList(selectedMonth);
            else {
                setSelectedClient(null)
                setSelectedService(null)
                setTimesheetInfo(null);
                setTimesheetFile(null);            }
        }
    }, [selectedMonth])

    //reload service list after each client change & clear file/service
    useEffect(() => {
        if (!props.editData) {
            if (selectedMonth && selectedClient)
                loadMissionServiceList(selectedClient, selectedMonth);
            setSelectedService(null)
            setTimesheetInfo(null);
            setTimesheetFile(null);        }
    }, [selectedClient])


    const handleClose = (event: React.MouseEvent<HTMLButtonElement>, reason: any) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        closeModal();
    };

    /**
     * For reseting and assigning already existing values in the state
     * @param clean 
     */
    const resetAllData = (clean = false) => {
        let edit = props.editData;
        setSelectedMonth(null);
        setSelectedClient("");
        setSelectedService("");
        setTimesheetFile(null);
        setTimesheetInfo(null);
        if (!!edit && !clean) {
            setSelectedMonth(edit.month);
            setSelectedClient(edit.client);
            setSelectedService(edit.service)
            setTimesheetFile(edit.timesheetFileName);
            loadTimesheetFile(edit.month, edit.service.id)
        }
        setClean();
    };
    
    const saveTimesheet = () => {
        if (!isHoursValid() || !isMinutesValid())
            return;
        let fromDate = selectedMonth?.dateFrom;
        let isoDate = getISODate(fromDate);
        let hours = timesheetInfo?.hours || 0
        let minutes = timesheetInfo?.minutes || 0
        let payload: timesheetForServiceMonthType = {
            clientServiceId: selectedService.id,
            hebMonthYear: isoDate,
            fileUpload: isNewFile ? timesheetFile as timesheetFileType : null,
            providerName: selectedService.providerName,
            totalMinutes: (parseInt(hours as string) * 60) + parseInt(minutes as string),
        }
        setLoading(true);
        saveTimesheetForServiceMonth(payload).then((data) => {
            setLoading(false);
            closeModal(true)
        }).finally(() => {
            setLoading(false);
        })
        // TODO: handling saving
    };

    const closeModal = (reloadData = false) => {
        props.onCloseModal(reloadData);
        resetAllData(true);
        setLoading(false)
    };

    /**
     * This is a api handler for getting client services list and set services state
     * @param client - Selected client
     * @param month - Selected Month
     */
    const loadMissionServiceList = (client: any, month: HebrewMonthDateRange) => {
        if (!client || !month)
            return;
        setLoading(true);
        let fromDate = month.dateFrom;
        let isoDate = getISODate(fromDate);
        getTimesheetClientService(client.clientId, isoDate).then((data:any) => {
            setServices(data.clientServices);
            setLoading(false);
            //clear service if not in list
            if (selectedService && !data.find((s: any) => s.id === selectedService.id)) {
                setSelectedService(null);
                setTimesheetInfo(null);
                setTimesheetFile(null);            }
        })
    }

    const loadTimesheetFile = (month: HebrewMonthDateRange, serviceId: number) => {
        if (!serviceId || !month)
            return;
        let fromDate = month.dateFrom;
        let isoDate = getISODate(fromDate);
        setLoading(true);
        getTimesheetForServiceMonth(serviceId, isoDate).then((data:any) => {
            setLoading(false);
            setTimesheetInfo({
                ...data.fileInfo,
                hours: Math.floor(data.fileInfo.totalMinutes / 60),
                minutes: data.fileInfo.totalMinutes % 60,
                maxHours: Math.floor(data.fileInfo.maxTimeInMinutes / 60),
                maxMinutes: data.fileInfo.maxTimeInMinutes % 60,
            });
            setTimesheetFile({ name: data.fileInfo.name } as timesheetFileType);
        })
    }
    /**
     * This is an api handler for getting all available client with this month.
     * @param month - Selected month
     */
    const loadClientList = (month: HebrewMonthDateRange) => {
        if (!month)
            return;
        setLoading(true);
        let fromDate = month.dateFrom;
        let isoDate = getISODate(fromDate);
        getTimesheetClientList(isoDate).then((data: any) => {
            setClients(data);
            setLoading(false);
            //clear client if not in list
            if (selectedClient && !data.find((c: any) => c.clientId === selectedClient.clientId)) {
                setSelectedClient(null)
                setSelectedService(null);
                setTimesheetInfo(null);
                setTimesheetFile(null);
            }
            //reload services
            else if (selectedClient && selectedMonth) {
                loadMissionServiceList(selectedClient, selectedMonth as HebrewMonthDateRange);
            }
        });
    }


    useEffect(() => {
        resetAllData()
    }, [props.isOpenCreateModal]);

    useEffect(() => {
    }, [props.isOpenCreateModal]);

    const handleChange = (value: any, field: string) => {
        setDirty();
        switch (field) {
            case 'month': {
                setSelectedMonth(value);
                return;
            }
            case 'service': 
                setSelectedService(value);
                return;
            case 'client': 
                setSelectedClient(value);
                return;
            default: 
                return null

        }
    }

    const handleFileChange = (file: timesheetFileType) => {
        setDirty();
        let fileObj = {
            primaryId: file.primaryId,
            fileBytes: file.file64Arr as string,
            name: file.name,
            description: file.description,
            fileTemplateId: file.fileTemplateId,
            folderId: file.folderId,
            createdBy_UserId: file.createdBy_UserId,
        }
        // TODO: handle logic here
        setTimesheetFile(fileObj);
        setisNewFile(true);
    }

    //Following function added by Usher on 11/30/22
    const handleFocus = (event: any) => event.target.select();

    const CompUploadFile = (comp_props: any) => {
        return (
            <UploadFile
                {...comp_props}
                table={"clients"}
                primaryId={selectedClient?.clientId}
                file={timesheetFile}
                setFile={(file: timesheetFileType) => {
                    handleFileChange({...file, createdBy_UserId: userId})
                }}
                disabled={!selectedClient || !selectedMonth || !selectedService }
            >
            </UploadFile>
        );
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpenCreateModal}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <AnimLoadingState loading={loading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                Upload Timesheet
            </DialogTitle>
            <DialogContent>
                <DivContainer>
                    <FormControl fullWidth className={classes.marginSpace}>
                        <Autocomplete
                            disabled={!!props.editData}
                            options={hebMonths}
                            getOptionLabel={(option) => option.hebMonthName + ' ' + option.hebYearName}
                            inputValue={selectedMonth ? selectedMonth.hebMonthName + ' ' + selectedMonth.hebYearName : ''}
                            onChange={(event: any, selectedOption: any) => handleChange(selectedOption, "month")}
                            className={classes.marginSpaceOne}
                            renderInput={(params) => <TextField {...params}  label="Hebrew Month" />}
                        /> 
                    </FormControl>
                    <FormControl fullWidth className={classes.marginSpace}>
                        <Autocomplete
                            disabled={!!props.editData || !selectedMonth}
                            options={clients.sort((a:timesheetClientListType , b: timesheetClientListType) => (a.clientName.toLowerCase() > b.clientName.toLowerCase()) ? 1 : -1)}
                            getOptionLabel={(option) => option.clientName}
                            value={selectedClient ?? null}
                            isOptionEqualToValue={(option: any, value: any) => option.clientId === value.clientId}
                            onChange={(event: any, selectedOption: any) => handleChange(selectedOption, "client")}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                            }}
                            className={classes.marginSpaceOne}
                            renderInput={(params) => <TextField {...params} label="Clients" />}
                        /> 
                    </FormControl>
                    <FormControl fullWidth className={classes.marginSpace}>
                        <Autocomplete
                            disabled={!selectedMonth || !selectedClient || !!props.editData}
                            options={services?.length ? services : []}
                            getOptionLabel={(option: any) => option.providerName}
                            inputValue={selectedService ? selectedService?.providerName : ''}
                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                            onChange={(event: any, selectedOption: any) => handleChange(selectedOption, "service")}
                            className={classes.marginSpaceOne}
                            renderInput={(params) => <TextField {...params} label="Servics" />}
                        /> 
                    </FormControl>
                            <CompUploadFile
                                label={"Timesheet"}
                    ></CompUploadFile>
                    {

                    }
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography
                                display={'inline'}
                            >
                                {'Timesheet time logs: '}
                            </Typography>
                            {timesheetInfo &&
                                <Typography
                                    fontSize={"12px"}
                                    display={'inline'}
                                >
                                    {`(Max: ${timesheetInfo.maxHours} hours and ${timesheetInfo.maxMinutes} minutes.)`}
                                </Typography>
                            }

                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                            size={"small"}
                            value={timesheetInfo?.hours}
                            label={"Hours"}
                            type={"number"}
                            onChange={(e) => {
                                setTimesheetInfo((prev: any) => {
                                    return {
                                        ...prev,
                                        hours: e.target.value ? e.target.value : "",
                                    }
                                });
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            helperText={timesheetInfo ? ("Max hours: " + timesheetInfo?.maxHours ?? 0) : ''}
                            error={!isHoursValid()}
                            onFocus={handleFocus}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                            size={"small"}
                            value={timesheetInfo?.minutes}
                            label={"Minutes"}
                            type={"number"}
                            onChange={(e) => {
                                setTimesheetInfo((prev: any) => {
                                    return {
                                        ...prev,
                                        minutes: e.target.value ? e.target.value : "",
                                    }
                                });
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                                helperText={timesheetInfo ? ("Max minutes: " + ((timesheetInfo?.maxHours && timesheetInfo?.maxHours > timesheetInfo?.hours) ? 59 : timesheetInfo?.maxMinutes)) : ''}
                                error={!isMinutesValid()}
                                onFocus={handleFocus}
                            />
                            </Grid>
                        </Grid>
                </DivContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={saveTimesheet}
                    color="primary"
                    disabled={!timesheetFile?.name}
                >
                    { isNewFile ? 'Upload' : 'Save'}
                </Button>
                <Button onClick={(e) => handleClose(e, undefined)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TimesheetUploader;