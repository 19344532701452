//  define theme palette here, import colors from variables
import colors from './utils/colors'

const palette = {
    mode: "light",
    primary: {
        main: colors.primary
    },
    background: {
            paper: "#F1F7FD",
            front: "#e2eefa"
    },
    text: {
        primary: colors.regularFont
    }

}
export default palette;