import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import TextFormattingWrapper from '../../utils/TextFormattingWrapper';
import { strings } from '../../_helpers/strings'


const ViewDetailsDialog = ({ value, open, handleClose }) => {
    const stringVal = strings.getRichTextObjFromString(value).value;
    return (
        <Dialog open={open && stringVal} onClose={() => { handleClose() }}>
            <DialogContent>
                <p>
                    <TextFormattingWrapper
                        value={value}
                    />
                </p>
            </DialogContent>
        </Dialog>
    )
}

export default ViewDetailsDialog;