// Move React import to top, separate absolute import and relative import. Also remove unused imports

import {
    Dialog,
    DialogActions, DialogTitle, FormControl,
    InputAdornment, InputLabel,
    List,
    ListItem,
    ListItemText, MenuItem,
    Select, Switch,
    TextField,
    Typography,
    useMediaQuery,
    FormLabel
} from "@mui/material";
import { Chip } from '@mui/material';
import AccordionSummary from "@mui/material/AccordionSummary";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useTheme } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStoreActions, useStoreState } from "easy-peasy";
import _, { constant } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    getClientDetailProgram,
    getClientDetailProgramComfortHealth, getClientDetailProgramLight, getClientDetailProgramP3,
    GetClientAppointment, getClientProgramLists, saveClientDetailProgram,
    saveClientDetailProgramComfortHealth, saveClientDetailProgramLight, saveClientDetailProgramP3
} from "../../../api/clients";
import { fetchFileInfos, saveFile } from "../../../api/cms";
import ContactSelectField from "../../../components/ContactSelect/ContactSelectField";
import { careManager } from "../../../components/data/clients";
import AddAppointment from "../../../components/explorer/Tabs/Schedule/AddClientSchedule";
import TextInputWrapper from "../../../utils/TextInputWrapper";
import TextSingleWrapper from "../../../utils/TextSingleWrapper";
import useStyles from "./styles";
import UploadFileComponent from "./UploadFileComp";
import { fileTemplate, GET_FILE_TEMPLATES } from '../../../constants'
import SCHEDULE_PERIODICITY_CONSTANTS from '../../../_helpers/constants/schedule_periodicity_constants.js';
import UploadFile from "../../../components/UploadFile/UploadFile";
import CreditCardInformation from "../../../components/UI/CreditCardInformation";
import PaymentInfo from "../../../components/UI/PaymentInfo";
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";
import usePermission from "../../../hooks/usePermission";
import ProtectedComponent from "../../../components/ProtectedComponent/ProtectedComponent";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TTextFieldNumber from "../../../components/UI/TTextFieldNumber"
import ServiceDetails from './ServiceDetails/ServiceDetails'
import AppointmentModal from "../../../components/ClientSchedule/Modals/Appointment/Appointment";
import { saveAppointmentRow } from "../../../api/schedules";
import { getProfessionalNames } from "../../../api/employees";
import baseStyles from '../../../assets/styles'
import { numbers } from "../../../_helpers/numbers";
const padLeadingZeros = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

export default function Programs(props) {
    // TODO: remove unused and commented states.
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [value, setValue] = React.useState(false);
    const [view, setView] = React.useState(true);
    const [parent_view, setParentView] = React.useState(true);
    const [education, setEducation] = React.useState(true);
    const [information, setInformation] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [note, setNote] = React.useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [openConfirm, setOpenConfirm] = React.useState(false);
    // FormControl
    const [image, setImage] = React.useState("");
    const [fieldsDocuments, setFieldsDocuments] = React.useState({
        Basc: false,
        IepForP3: false,
        ConsentForm: false,
        InsuranceCard: false,
        Sed: false,
        SedReport: false,
        Lpha: false,
        ContractWithComfortHealth: false,
    });
    const [send_document_parent, setSendDocumentParent] = React.useState(true);
    const [selectedFile, setSelectedFile] = React.useState({
        Basc: "Document Upload",
        IepForP3: "Document Upload",
        ConsentForm: "Document Upload",
        InsuranceCard: "Document Upload",
        Sed: "Document Upload",
        SedReport: "Document Upload",
        Lpha: "Document Upload",
        ContractWithComfortHealth: "Document Upload",
    });
    const FileTemplate = {
        IepForP3: 1,
        InsuranceCard: 2,
        ConsentForm: 3,
        Basc: 4,
        Sed: 5,
        SedReport: 6,
        Lpha: 7,
        ContractWithComfortHealth: 8,
    };
    const [isAddAppointment, setIsAddAppointment] = React.useState(false);
    const [checkComfort, setCheckComfort] = React.useState([true, true, true]);
    const [checkP3, setCheckP3] = React.useState([true, true, true]);
    const [checkLight, setCheckLight] = React.useState([true, true, true]);
    const [enrollStatus, setEnrollStatus] = React.useState([false, false, false]);

    const isWindowDirty = useStoreState((state) => state.isWindowDirty);
    const setWindowDirty = useStoreActions((actions) => actions.setWindowDirty);

    const exploringId = useStoreState((state) => state.exploringId);
    const clientDetailRedux = useStoreState((state) => state.clients.detail);
    const [clientDetail, clientDetailSetLoc] = React.useState(clientDetailRedux);
    const clientDetailSet = (data) => {
        clientDetailSetLoc(data);
    }
    const clientDetailSetRedux = useStoreActions(
        (actions) => actions.clients.setDetail
    );
    const clientDetailSaveRedux = useStoreActions(
        (actions) => actions.clients.saveDetail
    );
    const clientDetailSave = (data) => {
        return clientDetailSaveRedux(data).then(() => {
            clientDetailSetRedux(data);
        });
    }

    //loading
    const [loading, setLoading] = useState({});
    const setLoadingGlobal = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const setLoadingItem = (itemName, value) => {
        setLoading((prevState) => { return { ...prevState, [itemName]: value } });
    }
    useEffect(() => {
        let isCurrLoading = false;
        for (var key in loading) {
            if (loading.hasOwnProperty(key)) {
                if (loading[key])
                    isCurrLoading = true;
            }
        }
        if (isCurrLoading)
            setLoadingGlobal();
        else
            endLoading();
    }, [loading]);

    const [completedOnCheckboxField, setcompletedOnCheckboxField] = React.useState({
        sentDocsToParentsOn: false,
        hasPrevSed: false,
        poC_CompletedOn: false,
        sentBinderToComfortHealthOn: false,
        canS_CompletedOn: false,
        isSeeingTherapist: false,
        crisis_CompletedOn: false,
        comprehensiveAssessment_CompletedOn: false,
        loC_CompletedOn: false,
        packetSentOn: false,
        mcoNotificationSentOn: false,
        comfortHealth_approvedOn: false,
        p3_approvedOn: false,
        sentIepToNewAgencyOn: false,
        approvedOn: false,
        pendedOn: false,
        isPended: false,
    });

    const [
        completedOnContactSelectField,
        setCompletedOnContactSelectField,
    ] = React.useState({
        assignCareManager: false,
        assignCareManagerSupervisor: false,
    });
    const defDate = "1900-01-01T00:00:00.00";
    const [fieldsComfortHealth, setFieldsComfortHealth] = React.useState({
        clientProgramId: 0,
        clientProgram: {
            contactId: null,
            contact: null,
            clientId: 0,
            programId: 0,
            program: {
                programName: "Comfort Health",
                id: 0,
                isDeleted: false,
            },
            enrolledOn: null,
            clientProgramStatusId: null,
            clientProgramStatus: null,
            isActive: true,
            status: "",
            programServices: null,
            id: 0,
            isDeleted: false,
        },
        appointment_ClientProviderScheduleId: null,
        clientProviderSchedule: null,
        isPrevEnrolledInCH: false,
        sentDocsToParentsOn: defDate,
        hasPrevSed: false,
        sentBinderToComfortHealthOn: defDate,
        careManagerSupervisorAssignedOn: defDate,
        careManagerAssignedOn: defDate,
        hcbsCoordinatorAssignedOn: defDate,
        intakeMeetingDate: defDate,
        canS_CompletedOn: defDate,
        crisis_CompletedOn: defDate,
        poC_CompletedOn: defDate,
        comprehensiveAssessment_CompletedOn: defDate,
        loC_CompletedOn: defDate,
        packetSentOn: defDate,
        isPended: false,
        pendedOn: defDate,
        reactivatedOn: null,
        note: null,
        insuranceCompany: null,
        id: 0,
        isDeleted: false,
    });
    const [orig_ComfortHealth, setOrig_ComfortHealth] = React.useState();

    const [fieldsP3, setFieldsP3] = React.useState({
        clientProgramId: 0,
        clientProgram: {
            contactId: null,
            contact: null,
            clientId: 0,
            programId: 0,
            program: {
                programName: "P3",
                id: 0,
                isDeleted: false,
            },
            enrolledOn: null,
            clientProgramStatusId: null,
            clientProgramStatus: null,
            isActive: true,
            status: "",
            programServices: null,
            id: 37,
            isDeleted: false,
        },
        isPrevInAgency: false,
        prevAgency: "",
        newAgency: "",
        sentIepToNewAgencyOn: null,
        approvedOn: null,
        note: null,
        hoursAvailablePerWeek: null,
        id: 0,
        isDeleted: false,
    });

    const [fieldsLight_Local, setFieldsLight_Local] = useState({
        clientProgramId: 0,
        clientProgram: {
            contactId: null,
            contact: null,
            clientId: 0,
            programId: 0,
            program: {
                programName: "Light",
                id: 0,
                isDeleted: false,
            },
            enrolledOn: null,
            clientProgramStatusId: null,
            clientProgramStatus: null,
            isActive: true,
            status: "",
            programServices: null,
            id: 37,
            isDeleted: false,
        },
        paymentMethod: {
            paymentMethod: "Cash",
            ccNumber: "",
            cvv: "",
            expMonth: "",
            expYear: "",
            billingZip: "",
            nameOnCard: "",
            note: "",
        },
        id: 0,
        isDeleted: false,
    });
    const fieldsLight = useStoreState((state) => state.clients.fieldsLight);
    const setFieldsLight = (ccValue) => {
        if (ccValue === "")
            return;
        fieldsLight.id = ccValue.id;
        fieldsLight.paymentMethod = ccValue.paymentMethod;
        fieldsLight.ccNumber = ccValue.ccNumber;
        fieldsLight.cvv = ccValue.cvv;
        fieldsLight.expMonth = ccValue.expMonth == undefined ? ccValue.cardExpiredDate.toString().slice(0, 2) : ccValue.expMonth;
        fieldsLight.expYear = ccValue.expYear == undefined ? ccValue.cardExpiredDate.toString().slice(2, 4) : ccValue.expYear;
        fieldsLight.billingZip = ccValue.billingZip;
        fieldsLight.nameOnCard = ccValue.nameOnCard;
        fieldsLight.note = ccValue.note;
        fieldsLight.responsibleForPayment = ccValue.responsibleForPayment;
        fieldsLight.monthlyAgreedAmount = ccValue.monthlyAgreedAmount;
        setIsSaveEnable(false);
    };

    const [fieldsProgramDetail, setFieldsProgramDetail] = React.useState({
        careManagerId: 0,
        careManagerName: "",
        comfortHealth_status: "",
        comfortHealth_active: false,
        comfortHealth_enrolledOn: null,
        comfortHealth_approvedOn: null,
        comfortHealth_comhab: 0,
        comfortHealth_respite: 0,
        comfortHealth_cfss: 0,
        comfortHealth_cats: 0,
        p3_status: "",
        p3_active: false,
        p3_enrolledOn: null,
        p3_approvedOn: null,
        p3_p3service: 1,
        light_status: "",
        light_active: false,
        light_enrolledOn: null,
        light_approvedOn: null,
        light_payment: "Method 3",
    });

    const [isSaveEnableTop, setIsSaveEnableTop] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState("Basic");
    const [selectedItemNext, setSelectedItemNext] = React.useState(null);
    const [isSaveEnable, setIsSaveEnable] = React.useState(false);
    const [tempSaveData, setTempSaveData] = React.useState({
        fieldsFromClient: clientDetail,
        fieldsComfortHealth: fieldsComfortHealth,
        fieldsP3: fieldsP3,
        fieldsLight: fieldsLight,
        completedOnCheckboxField: completedOnCheckboxField,
        enrollStatus: [false, false, false],
    });
    useEffect(() => {
        global.log(fieldsComfortHealth);
    }, [fieldsComfortHealth.note]);
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    const resetPrevData = () => {
        removeChange("bottomContainer");
        runRollbackAction(rollbackChanges);
    }
    // TODO: move this to top where all state are defined
    const [isBottomDirtyState, setBottomDirtyState] = React.useState(false);
    useEffect(() => {
        setBottomDirtyState(changedItems.includes("bottomContainer"));
    }, [changedItems]);
    const handleListItemClick = (item, onBeforeSelect) => {
        if (onBeforeSelect != undefined && !onBeforeSelect()) {
            //setSelectedItemNext(item);
            //setOpenConfirm(true);
            return;
        }
        //setIsSaveEnable(false);
        setSelectedItem(item);
        setTempSaveData({
            fieldsFromClient: clientDetail,
            fieldsComfortHealth: fieldsComfortHealth,
            fieldsP3: fieldsP3,
            fieldsLight: fieldsLight,
            completedOnCheckboxField: completedOnCheckboxField,
            enrollStatus: enrollStatus,
        });
    };

    // TODO: Move all hooks call to top
    const { permissions } = props;
    const programsPermission = usePermission(permissions.programs);
    const programChPermission = usePermission(permissions.programComfortHealth);
    const programP3Permission = usePermission(permissions.programP3);
    const programLightPermission = usePermission(permissions.programLight);

    useEffect(() => {
        setSelectedItem("Basic");
    }, [value]);

    const defaultRow_Provider = {
        specialties: [], clientProviderSchedulePotentials: [], status: "seeking",
        schedule: { days: "", periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS },
        clientName: clientDetail.firstName + " " + clientDetail.lastName,
        clientId: props.clientId,
    };
    // TODO: move this to top where all state are defined
    const [appointmentSchedule, setAppointmentSchedule] = React.useState(defaultRow_Provider);
    useEffect(() => {
        setFieldsComfortHealth({ ...fieldsComfortHealth, appointment_ClientProviderScheduleId: appointmentSchedule.clientScheduleId });
    }, [appointmentSchedule]);
    // TODO: move this to top where all state are defined
    const [professionals, setProfessionals] = useState([]);
    useEffect(() => {
        setLoadingItem("professionalNames", true)
        getProfessionalNames().then((data) => {
            setProfessionals(data || [])
            setLoadingItem("professionalNames")
        });
    }, []);

    const reloadProgramDetailData = () => {
        if (global.isEmpty(exploringId)) return;
        // setLoading();
        setTempSaveData((tempSaveData) => {
            return {
                ...tempSaveData,
                fieldsFromClient: clientDetail,
            }
        });
        setLoadingItem("clientDetailProgram", true)
        getClientDetailProgram(exploringId)
            .then((data) => {
                setEnrollStatus([
                    data.comfortHealth_enrolledOn ? true : false,
                    data.p3_enrolledOn ? true : false,
                    data.light_enrolledOn ? true : false,
                ]);
                setFieldsProgramDetail(data);
                initcompletedOnCheckboxField(
                    data,
                    "comfortHealth_approvedOn",
                    "comfortHealth_approvedOn"
                );
                initcompletedOnCheckboxField(
                    data,
                    "p3_approvedOn",
                    "p3_approvedOn"
                );
                setTempSaveData((tempSaveData) => {
                    return {
                        ...tempSaveData,
                        enrollStatus: [
                            data.comfortHealth_enrolledOn ? true : false,
                            data.p3_enrolledOn ? true : false,
                            data.light_enrolledOn ? true : false,
                        ],
                        completedOnCheckboxField: completedOnCheckboxField,
                    }
                });
                // Update tabpanel status
                if (!value) {
                    if (data.comfortHealth_enrolledOn) setValue("comfort_health");
                    else if (data.p3_enrolledOn) setValue("p3");
                    else if (data.light_enrolledOn) setValue("light");
                    else setValue(false);
                }
                setLoadingItem("clientDetailProgram")
            })
    }

    const reloadData = () => {
        let clientDet = {
            isSeeingTherapist: false,
            p3Agency: null,
        };
        if (global.isEmpty(exploringId)) return;
        // setLoading();
        setTempSaveData((tempSaveData) => {
            return {
                ...tempSaveData,
                fieldsFromClient: clientDetail,
            }
        });
        setLoadingItem("clientDetailProgram", true)
        getClientDetailProgram(exploringId)
            .then((data) => {
                setEnrollStatus([
                    data.comfortHealth_enrolledOn ? true : false,
                    data.p3_enrolledOn ? true : false,
                    data.light_enrolledOn ? true : false,
                ]);
                //initcompletedOnCheckboxField(
                //    data,
                //    "isSeeingTherapist",
                //    "isSeeingTherapist"
                //);
                clientDet.isSeeingTherapist = data.isSeeingTherapist;
                setFieldsProgramDetail(data);
                initcompletedOnCheckboxField(
                    data,
                    "comfortHealth_approvedOn",
                    "comfortHealth_approvedOn"
                );
                initcompletedOnCheckboxField(
                    data,
                    "p3_approvedOn",
                    "p3_approvedOn"
                );
                setTempSaveData((tempSaveData) => {
                    return {
                        ...tempSaveData,
                        enrollStatus: [
                            data.comfortHealth_enrolledOn ? true : false,
                            data.p3_enrolledOn ? true : false,
                            data.light_enrolledOn ? true : false,
                        ],
                        completedOnCheckboxField: completedOnCheckboxField,
                    }
                });
                // Update tabpanel status
                if (!value) {
                    if (data.comfortHealth_enrolledOn) setValue("comfort_health");
                    else if (data.p3_enrolledOn) setValue("p3");
                    else if (data.light_enrolledOn) setValue("light");
                    else setValue(false);
                }
                setLoadingItem("clientDetailProgram")
            })
            .then(() => {
                setLoadingItem("clientDetailProgramP3", true)
                getClientDetailProgramP3(exploringId).then((data) => {
                    setFieldsP3(data);
                    clientDet.p3Agency = data.p3Agency;
                    setTempSaveData((tempSaveData) => {
                        return {
                            ...tempSaveData,
                            fieldsP3: data,
                            completedOnCheckboxField: completedOnCheckboxField,
                        }
                    });
                    initcompletedOnCheckboxField(
                        data,
                        "sentIepToNewAgencyOn",
                        "sentIepToNewAgencyOn"
                    );
                    initcompletedOnCheckboxField(data, "approvedOn", "approvedOn");
                    setLoadingItem("clientDetailProgramP3")
                });
            })
            .then(() => {
                setLoadingItem("clientDetailProgramLight", true)
                getClientDetailProgramLight(exploringId).then((data) => {
                    let light = { ...data, ...data.paymentMethod, id: data.id, };
                    setFieldsLight(light);
                    setTempSaveData((tempSaveData) => {
                        return {
                            ...tempSaveData,
                            fieldsLight: light,
                            completedOnCheckboxField: completedOnCheckboxField,
                        }
                    });
                    setLoadingItem("clientDetailProgramLight")
                });
            })

            .then(() => {
                setLoadingItem("clientDetailProgramComfortHealth", true)
                getClientDetailProgramComfortHealth(exploringId).then((data) => {
                    setFieldsComfortHealth(data);
                    setOrig_ComfortHealth(data);
                    //global.log('the values of comfort health after fetch', data)
                    initcompletedOnCheckboxField(
                        data,
                        "isPended",
                        "isPended"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "sentDocsToParentsOn",
                        "sentDocsToParentsOn"
                    );
                    initcompletedOnCheckboxField(data, "hasPrevSed", "hasPrevSed");
                    initcompletedOnCheckboxField(
                        data,
                        "sentBinderToComfortHealthOn",
                        "sentBinderToComfortHealthOn"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "canS_CompletedOn",
                        "canS_CompletedOn"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "crisis_CompletedOn",
                        "crisis_CompletedOn"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "comprehensiveAssessment_CompletedOn",
                        "comprehensiveAssessment_CompletedOn"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "loC_CompletedOn",
                        "loC_CompletedOn"
                    );
                    initcompletedOnCheckboxField(data, "packetSentOn", "packetSentOn");
                    initcompletedOnCheckboxField(data, "mcoNotificationSentOn", "mcoNotificationSentOn");
                    initcompletedOnCheckboxField(
                        data,
                        "poC_CompletedOn",
                        "poC_CompletedOn"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "poC_CompletedOn",
                        "poC_CompletedOn"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "pendedOn",
                        "pendedOn"
                    );
                    initcompletedOnCheckboxField(
                        data,
                        "reactivatedOn",
                        "reactivatedOn"
                    );
                    setAppointmentScheduleData(data.appointment_ClientProviderScheduleId);
                    setTempSaveData((tempSaveData) => {
                        return {
                            ...tempSaveData,
                            fieldsComfortHealth: data,
                            completedOnCheckboxField: completedOnCheckboxField,
                        }
                    });
                    // endLoading();
                    setLoadingItem("clientDetailProgramComfortHealth")
                });
            }).then(() => {
                setLoadingItem("files", true)
                fetchFileInfos("clients", props.clientId, setFileTemplates).then(() => {
                    setLoadingItem("files")
                });
            }).then(() => {
                const newClientDet = { ...clientDetail, p3Agency: clientDet.p3Agency, isSeeingTherapist: clientDet.isSeeingTherapist };
                clientDetailSet(newClientDet);
            })
    }
    useEffect(() => {
    }, [fieldsP3.p3Agency])
    useEffect(() => {
        reloadData();
    }, []);

    const setAppointmentScheduleData = (id) => {
        if (global.isEmpty(id)) {
            setAppointmentSchedule(defaultRow_Provider);
        } else {
            if (!exploringId) return;
            setLoadingItem("sedAppointment", true)
            GetClientAppointment(id).then((appt) => {
                setAppointmentSchedule(appt);
                setLoadingItem("sedAppointment")
            });
        }
    };
    // TODO: variable name should be in camelCase
    const initcompletedOnCheckboxField = (objects, objectName, name) => {
        if (global.isEmpty(objects[objectName])) {
            let temp = completedOnCheckboxField;
            temp[name] = false;
            setcompletedOnCheckboxField(temp);
        } else {
            let temp = completedOnCheckboxField;
            temp[name] = true;
            setcompletedOnCheckboxField(temp);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        if (newValue == value) {
            //event.preventDefault();
            return;
        }
        if (changedItemsIncludes("bottomContainer")) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                //event.preventDefault();
                return;
            } else {
                removeChange("topContainer");
                removeChange("bottomContainer");
                setSelectedItemNext(newValue);
                runRollbackAction(rollbackChanges);
            }
        }
        if (!view || !parent_view || !education || !information) {
            handleClickOpen();
        } else {
            setValue(newValue);
        }
    };

    // TODO: variable name should be in camelCase
    const SaveP3Fields = () => {
        setLoadingItem("saveP3", true)
        saveClientDetailProgramP3(fieldsP3)
            .then((value) => {
                setLoadingItem("saveP3")
                return value;
            })
            .then((value) => {
                for (const file in fileTemplates) {
                    if (fileTemplates[file].state == "modified") {
                        setLoadingItem("saveFiles", true)
                        saveFile({ ...fileTemplates[file], file64Arr: undefined }, "clients", fileTemplates, setFileTemplates).then(() => {
                            setLoadingItem("saveFiles")
                        });
                    }
                    else if (fileTemplates[file].state == "new") {
                        setLoadingItem("saveFiles", true)
                        saveFile({ ...fileTemplates[file] }, "clients", fileTemplates, setFileTemplates).then(() => {
                            setLoadingItem("saveFiles")
                        });
                    }
                }
                if (value !== true)
                    global.AjaxFailToast1();
                else {
                    global.AjaxSaved1();
                    setIsSaveEnable(false);
                    removeChange("bottomContainer");
                    setTempSaveData((tempSaveData) => {
                        return {
                            ...tempSaveData,
                            fieldsP3: fieldsP3,
                            completedOnCheckboxField: completedOnCheckboxField,
                        }
                    });
                }
                setLoadingItem("saveClientDetail", true)
                clientDetailSave(clientDetail).then(() => {
                    setLoadingItem("saveClientDetail")
                })
            });
    };
    // TODO: variable name should be in camelCase
    const SaveLightFields = () => {
        //if (eval("typeof setLoading === 'function'"))
        if (fieldsLight.monthlyAgreedAmount && !numbers.isWholeInt(fieldsLight.monthlyAgreedAmount))
            return;
        setLoadingItem("saveLight", true)
        global.log(fieldsLight);
        saveClientDetailProgramLight({
            ...fieldsLight,
            paymentMethod: {
                paymentMethod: fieldsLight.paymentMethod,
                ccNumber: fieldsLight.ccNumber,
                nameOnCard: fieldsLight.nameOnCard,
                expMonth: fieldsLight.expMonth,
                expYear: fieldsLight.expYear,
                cvv: fieldsLight.cvv,
                billingZip: fieldsLight.billingZip,
                note: fieldsLight.note,
                isPrimaryPaymentMethod: fieldsLight.isPrimaryPaymentMethod
            }
        })
            .then((value) => {
                for (const file in fileTemplates) {
                    if (fileTemplates[file].state == "modified")
                        saveFile({ ...fileTemplates[file], file64Arr: undefined }, "clients", fileTemplates, setFileTemplates);
                    else if (fileTemplates[file].state == "new")
                        saveFile({ ...fileTemplates[file] }, "clients", fileTemplates, setFileTemplates);
                }
                setLoadingItem("saveLight")
                if (value !== true)
                    global.AjaxFailToast1();
                else {
                    global.AjaxSaved1();
                    setIsSaveEnable(false);
                    removeChange("bottomContainer");
                    //let light = { ...fieldsLight.paymentMethod, id: fieldsLight.id, };
                    setTempSaveData((tempSaveData) => {
                        return {
                            ...tempSaveData,
                            fieldsLight: fieldsLight,
                            completedOnCheckboxField: completedOnCheckboxField,
                        }
                    });
                }
                return value;
            });
    };
    // TODO: variable name should be in camelCase
    const SaveComfortHealth = () => {
        setLoadingItem("saveComfortHealth", true)
        //global.log('the save comfort health');
        const dataToSave_ch = {
            ...fieldsComfortHealth,
            isPended: completedOnCheckboxField.isPended // doing this here so we don't mess with the original value, read comments at the field render
        };
        saveClientDetailProgramComfortHealth(dataToSave_ch)
            .then((value) => {
                setOrig_ComfortHealth(dataToSave_ch);
                for (const file in fileTemplates) {
                    if (fileTemplates[file].state == "modified")
                        saveFile({ ...fileTemplates[file], file64Arr: undefined }, "clients", fileTemplates, setFileTemplates);
                    else if (fileTemplates[file].state == "new")
                        saveFile({ ...fileTemplates[file] }, "clients", fileTemplates, setFileTemplates);
                }
                setLoadingItem("saveComfortHealth")
                if (value !== true)
                    global.AjaxFailToast1();
                else {
                    global.AjaxSaved1();
                    setIsSaveEnable(false);
                    removeChange("bottomContainer");
                    setTempSaveData((tempSaveData) => {
                        return {
                            ...tempSaveData,
                            fieldsComfortHealth: dataToSave_ch,
                            completedOnCheckboxField: completedOnCheckboxField,
                        }
                    });
                }
                clientDetailSave(clientDetail)
            });
    };
    const saveProgramEnrollment = () => {
        if (exploringId == null) return;
        setLoadingItem("saveEnrollment", true)
        // global.log(fieldsProgramDetail);
        saveClientDetailProgram(
            exploringId,
            fieldsProgramDetail
        ).then((value) => {
            setLoadingItem("saveEnrollment")
            if (value !== true) global.AjaxFailToast1();
            else {
                global.AjaxSaved1();
                setIsSaveEnableTop(false);
                removeChange("topContainer");
                setTempSaveData((tempSaveData) => {
                    return {
                        ...tempSaveData,
                        enrollStatus: enrollStatus,
                    }
                });
            }
        });
    }
    // TODO: variable name should be in camelCase
    const rollbackChanges = () => {
        reloadData();

        setOpenConfirm(false);
        setIsSaveEnable(false);
        if (selectedItemNext != null)
            setSelectedItem(selectedItemNext);
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                // global.log("imageee" + JSON.parse(e.target));
                setImage(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    // TODO: variable name should be in camelCase
    const CheckBoxFlexGrowPrev = (props) => {
        const { checked, onChange } = props;

        return (
            <div className={baseClasses.grow}>
                <FormControl>
                    <Checkbox
                        checked={checked}
                        // checked={true}
                        onChange={onChange}
                        color="primary"
                        size="small"
                    // spacing={0}
                    // padding="0"
                    // inputProps={{ "padding": "0" }}
                    />
                </FormControl>
            </div>
        );
    };
    const CheckBoxFlexGrow = (props) => {
        const { checked, onChange, classes } = props;

        return (
            <div className={baseClasses.grow}>
                <FormControl>
                    <Switch
                        checked={checked}
                        onChange={onChange}
                        color="primary"
                    // name="checkedA"
                    // inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </FormControl>
            </div>
        );
    };

    const CustomAccordionSummary = ({ title }) => {
        return (
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
            >
                <Typography className={classes.accordionHeading}>{title}</Typography>
            </AccordionSummary>
        );
    };

    const SectionHeading = ({ title, classes }) => {
        return (
            <Typography
                className={classes.card_title}
                // variant="h6"
                gutterBottom
            >
                {title}
            </Typography>
        );
    };

    const onEnrollmentChange = () => {
        setIsSaveEnableTop(true);
        addChange("topContainer");
    };

    const onFieldChange = () => {
        setIsSaveEnable(true);
        addChange("bottomContainer");
    };
    const handleFields = (programType, event) => {
        const { name, value, type, checked } = event.target;
        //global.log('the event target', event.target)

        onFieldChange();

        if (programType == "fromClient") {
            if (type === "checkbox") {
                clientDetailSet({ ...clientDetail, [name]: checked });
            } else {
                clientDetailSet({ ...clientDetail, [name]: value });
            }
        } else if (programType == "P3") {
            if (type === "checkbox") {
                setFieldsP3({ ...fieldsP3, [name]: checked });
            } else {
                setFieldsP3({ ...fieldsP3, [name]: value });
            }
        } else if (programType == "fieldsProgramDetail") {
            if (type === "checkbox") {
                setFieldsProgramDetail({
                    ...fieldsProgramDetail,
                    [name]: event.target.checked,
                });
            } else {
                setFieldsProgramDetail({ ...fieldsProgramDetail, [name]: value });
            }
        } else {
            //comfortHealth
            if (type === "checkbox") {
                setFieldsComfortHealth({
                    ...fieldsComfortHealth,
                    [name == "ComfortHealthApprovedOn" ? "approvedOn" : name]: checked,
                });
            } else {
                setFieldsComfortHealth({
                    ...fieldsComfortHealth,
                    [name == "ComfortHealthApprovedOn" ? "approvedOn" : name]: value,
                });
            }
        }
    };

    const handleComfortHealthNoteBlured = (event) => {
        //global.log('the comfort health blur event fired')
        const { name, value } = event.target;
        setFieldsComfortHealth({
            ...fieldsComfortHealth,
            [name == "ComfortHealthApprovedOn" ? "approvedOn" : name]: value,
        });
    }
    const handleP3NoteBlured = (event) => {
        //global.log('the p3 blur event fired')
        const { name, value } = event.target;
        setFieldsP3({
            ...fieldsP3,
            "note": value,
        });
    }

    const CompDateInput = useCallback(({ programType, label, name, value, disabled, handleFields, setcompletedOnCheckboxField, classes, updateCheckOnDate }) => {
        return (
            <form className={classes.container} noValidate>
                <TextField
                    label={label}
                    type="date"
                    name={name}
                    disabled={disabled == true}
                    value={moment(value).format("YYYY-MM-DD")}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        handleFields(programType, event);
                        //Set the checkbox true when the date fills
                        if (updateCheckOnDate)
                            setcompletedOnCheckboxField({
                                ...completedOnCheckboxField,
                                [name]: true,
                            });
                    }}
                />
            </form>
        );
    }, []);

    const CompCheckbox = ({ programType, label, name, value }) => {
        return (
            <FormControlLabel
                className={classes.collapseHeader}
                aria-label="Acknowledge"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                control={<Checkbox />}
                label={label}
                name={name}
                value={value}
            />
        );
    };

    const isFieldNull = (programType, name) => {
        if (
            programType == "comfortHealth" &&
            _.isEmpty(fieldsComfortHealth[name]) == null
        )
            return true;
        if (programType == "P3" && _.isEmpty(fieldsP3[name]) == null) return true;

        return false;
    };

    const handlecompletedOnCheckboxField = (programType, name, completedOnCheckboxField, fieldsP3, fieldsProgramDetail, fieldsComfortHealth, updateDateOnChange = true) => {

        if (!completedOnCheckboxField[name]) {
            if (programType == "P3") {
                setFieldsP3({
                    ...fieldsP3,
                    [name]: global._DateTimeFieldDB(new Date()),
                });
            } else if (programType == "fieldsProgramDetail") {
                setFieldsProgramDetail({
                    ...fieldsProgramDetail,
                    [name]: global._DateTimeFieldDB(new Date()),
                });
            } else {
                setFieldsComfortHealth({
                    ...fieldsComfortHealth,
                    [name == "ComfortHealthApprovedOn"
                        ? "approvedOn"
                        : name]: global._DateTimeFieldDB(new Date()),
                });

                if (name === "hasPrevSed") {
                    setFieldsComfortHealth({
                        ...fieldsComfortHealth,
                        [name]: true,
                    });
                }
            }
        } else {
            if (programType == "P3") {
                setFieldsP3({ ...fieldsP3, [name]: null });
            } else if (programType == "fieldsProgramDetail") {
                setFieldsProgramDetail({ ...fieldsProgramDetail, [name]: null });
            } else {
                if (updateDateOnChange)
                    setFieldsComfortHealth({
                        ...fieldsComfortHealth,
                        [name == "ComfortHealthApprovedOn" ? "approvedOn" : name]: null,
                    });
                if (name === "hasPrevSed") {
                    setFieldsComfortHealth({
                        ...fieldsComfortHealth,
                        [name]: false,
                    });
                }
            }
        }

        setcompletedOnCheckboxField({
            ...completedOnCheckboxField,
            [name]: !completedOnCheckboxField[name],
        });
    };

    const CompCheckboxForDate = useCallback(({ programType, label, key, name, completedOnCheckboxField, handlecompletedOnCheckboxField, setIsSaveEnable, fieldsP3, fieldsProgramDetail, fieldsComfortHealth, labelPlacement, updateDateOnChange }) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={completedOnCheckboxField[key || name]}
                        onClick={(event) => {
                            handlecompletedOnCheckboxField(programType, name, completedOnCheckboxField, fieldsP3, fieldsProgramDetail, fieldsComfortHealth, updateDateOnChange);
                            onFieldChange();
                        }}
                        color="primary"
                    />
                }
                labelPlacement={labelPlacement || "end"}
                label={label}
                style={{ margin: 0 }}
            />
        );
    }, [])

    const CompInput = ({ programType, label, name, value }) => {
        return (
            <FormControl fullWidth>
                <TextInputWrapper
                    label={label}
                    name={name}
                    onChange={(event) => {
                        if (value != event.target.value) handleFields(programType, event);
                    }}
                ></TextInputWrapper>
            </FormControl>
        );
    };

    const CompInputSingle = (programType, label, name, value) => {
        return (
            <FormControl fullWidth>
                <TextSingleWrapper
                    label={label}
                    value={value ? value : ""}
                    name={name}
                    onChange={(event) => {
                        if (value != event.target.value) {
                            if (name == "expiration") {
                                var numbers = event.target.value.split("/");
                                var mm = 0, yy = 0;
                                if (!global.isEmpty(numbers[0]))
                                    mm = parseInt(numbers[0]);
                                if (!global.isEmpty(numbers[1]))
                                    yy = parseInt(numbers[1]);
                                onFieldChange();
                            }
                            else
                                handleFields(programType, event);
                        }
                    }}
                ></TextSingleWrapper>
            </FormControl>
        );
    };

    const CompSelectbox = ({ programType, label, name, value }) => {
        return (
            <FormControl fullWidth className={baseClasses.optionsContract}>
                <InputLabel id="demo-controlled-open-select-label-2">
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-controlled-open-select-label-2"
                    id="demo-controlled-open-select"
                    value={value}
                    name={name}
                    onChange={(event) => {
                        handleFields(programType, event);
                    }}
                    className={baseClasses.selection}
                    style={{ marginLeft: 0 }}
                >
                    {name == "comfortHealthCareManagerSupervisor_ContactId" &&
                        careManager.map((column) => (
                            <MenuItem value={column.id} key={column.id}>
                                {column.value}
                            </MenuItem>
                        ))}
                    {name == "comfortHealthCareManager_ContactId" &&
                        careManager.map((column) => (
                            <MenuItem value={column.id} key={column.id}>
                                {column.value}
                            </MenuItem>
                        ))}
                    {name == "cans" &&
                        ["High", "Med", "Low"].map((column) => (
                            <MenuItem value={column} key={column}>
                                {column}
                            </MenuItem>
                        ))}
                    {name == "paymentMethod" &&
                        ["Cash", "Credit Card"].map((column) => (
                            <MenuItem value={column} key={column}>
                                {column}
                            </MenuItem>
                        ))}

                </Select>
            </FormControl>
        );
    };

    const CompInputkbox = ({ programType, label, name, value }) => {
        return (
            <FormControl fullWidth>
                <TextInputWrapper
                    label={label}
                    value={global.isEmpty(value) ? "" : value}
                    name={name}
                    onChange={(event) => {
                        handleFields(programType, event);
                    }}
                ></TextInputWrapper>
            </FormControl>
        );
    };
    const [fileTemplates, setFileTemplates] = useState({});
    useEffect(() => {
        setLoadingItem("files", true)
        fetchFileInfos("clients", props.clientId, setFileTemplates).then(() => {
            setLoadingItem("files")
        });
    }, [])
    const CompUploadFile = (comp_props) => {
        return (
            <UploadFile
                {...comp_props}
                table={"clients"}
                primaryId={props.clientId}
                file={fileTemplates[comp_props.fileTemplate]}
                setFile={(file) => {
                    setFileTemplates({ ...fileTemplates, [comp_props.fileTemplate]: file })
                    onFieldChange();
                }}
            >
            </UploadFile>
        );
    }

    useEffect(() => {
        global.log(enrollStatus);
        global.log(value);
        if (
            (enrollStatus[0] == false && value == "comfort_health") ||
            (enrollStatus[1] == false && value == "p3") ||
            (enrollStatus[2] == false && value == "light")
        ) {
            updateTabs();
        }
    }, [enrollStatus]);

    const countTrueEnroll = () => {
        var count = 0;
        for (var i = 0; i < 3; i++) if (enrollStatus[i]) count++;
        return count;
    };

    const canDisableEnroll = (row) => {
        if (countTrueEnroll() == 1 && enrollStatus[row] == true) return false;
        return true;
    };

    const setCheckStatus = (row) => {
        setIsSaveEnableTop(true);
        if (countTrueEnroll(row) == 0) {
            if (row == 0) setValue("comfort_health");
            else if (row == 1) setValue("p3");
            else if (row == 2) setValue("light");
        } else {
            if (
                (enrollStatus[0] == true && value == "comfort_health") ||
                (enrollStatus[1] == true && value == "p3") ||
                (enrollStatus[2] == true && value == "light")
            ) {
                updateTabRow(row);
            }
        }
        setEnrollStatus([
            row == 0 ? !enrollStatus[0] : enrollStatus[0],
            row == 1 ? !enrollStatus[1] : enrollStatus[1],
            row == 2 ? !enrollStatus[2] : enrollStatus[2],
        ]);
        // global.log(fieldsProgramDetail);
        if (row == 0)
            setFieldsProgramDetail({
                ...fieldsProgramDetail,
                ["comfortHealth_enrolledOn"]: invertEnrollValue(
                    fieldsProgramDetail.comfortHealth_enrolledOn
                ),
            });
        else if (row == 1)
            setFieldsProgramDetail({
                ...fieldsProgramDetail,
                ["p3_enrolledOn"]: invertEnrollValue(fieldsProgramDetail.p3_enrolledOn),
            });
        else if (row == 2)
            setFieldsProgramDetail({
                ...fieldsProgramDetail,
                ["light_enrolledOn"]: invertEnrollValue(
                    fieldsProgramDetail.light_enrolledOn
                ),
            });
    };

    const invertEnrollValue = (value) => {
        if (value == "" || value == null)
            return global._DateTimeFieldDB(new Date()).toString();
        return "";
    };

    const setCheckStatusPrev = (row, col) => {
        if (row == 0) {
            if (checkComfort[0] == true && value == "comfort_health") {
                updateTabs();
                setCheckComfort([
                    col == 0 ? !checkComfort[0] : checkComfort[0],
                    col == 1 ? !checkComfort[1] : checkComfort[1],
                    col == 2 ? !checkComfort[2] : checkComfort[2],
                ]);
            }
        } else if (row == 1) {
            if (checkP3[0] == true && value == "p3") {
                updateTabs();
                setCheckP3([
                    col == 0 ? !checkP3[0] : checkP3[0],
                    col == 1 ? !checkP3[1] : checkP3[1],
                    col == 2 ? !checkP3[2] : checkP3[2],
                ]);
            }
        } else if (row == 2) {
            if (checkLight[0] == true && value == "light") {
                updateTabs();
            }
            setCheckLight([
                col == 0 ? !checkLight[0] : checkLight[0],
                col == 1 ? !checkLight[1] : checkLight[1],
                col == 2 ? !checkLight[2] : checkLight[2],
            ]);
        }
    };

    const updateTabRow = (row) => {
        if (row != 0 && enrollStatus[0]) setValue("comfort_health");
        else if (row != 1 && enrollStatus[1]) setValue("p3");
        else if (row != 2 && enrollStatus[2]) setValue("light");
        else setValue("");
    };

    const updateTabs = () => {
        if (enrollStatus[0]) setValue("comfort_health");
        else if (enrollStatus[1]) setValue("p3");
        else if (enrollStatus[2]) setValue("light");
        else setValue("");
    };

    const GridListCheckmark = ({ classes, setIsSaveEnableTop, saveClientDetailProgram }) => {
        return (
            <div
                // className={}
                style={{
                    //   height: "161px",
                    textAlign: "center",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    marginBottom: "20px",
                    backgroundColor: "white",
                }}
            >
                <div
                    className={baseClasses.CheckBoxRow}
                    style={{
                        borderBottom: "1px solid rgba(0,0,0,0.12)",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                    }}
                >
                    <div className={baseClasses.grow} style={{ lineHeight: "46px" }}>
                        Program Name
                    </div>
                    <div className={baseClasses.grow} style={{ lineHeight: "46px" }}>
                        Enroll
                    </div>
                    <div className={baseClasses.grow} style={{ lineHeight: "46px" }}>
                        Enroll Started
                    </div>
                    <div className={baseClasses.grow}>
                        <Button
                            onClick={() => { saveProgramEnrollment() }}
                            className={baseClasses.capitalized}
                            color="primary"
                            variant="contained"
                            disabled={!isSaveEnableTop}
                            style={{
                                float: "right",
                                marginRight: 24,
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div
                    className={baseClasses.CheckBoxRow}
                    onClick={(e) => { enrollStatus[0] && handleChange(e, "comfort_health"); }}
                    style={{ backgroundColor: value == "comfort_health" ? theme.palette.background.front : "white" }}
                >
                    <div className={baseClasses.CheckBoxFlexGrow}>Comfort Health</div>
                    <CheckBoxFlexGrow
                        checked={enrollStatus[0]}
                        onChange={() => {
                            onEnrollmentChange();
                            setCheckStatus(0);
                        }}
                        classes={classes}
                    ></CheckBoxFlexGrow>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {global._DateField(fieldsProgramDetail.comfortHealth_enrolledOn)}
                    </div>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {(fieldsProgramDetail.comfortHealth_status === 'Inactive' || fieldsProgramDetail.comfortHealth_status === 'On Hold') &&
                            <Chip
                                color={fieldsProgramDetail.comfortHealth_status === 'Inactive' ? "error" : 'info'}
                                label={fieldsProgramDetail.comfortHealth_status}
                                size={"small"}
                                variant={"outlined"}
                            />
                        }
                    </div>
                </div>
                <div
                    className={baseClasses.CheckBoxRow}
                    onClick={(e) => { enrollStatus[1] && handleChange(e, "p3"); }}
                    style={{ backgroundColor: value == "p3" ? theme.palette.background.front : "white" }}
                >
                    <div className={baseClasses.CheckBoxFlexGrow}>P3</div>
                    <CheckBoxFlexGrow
                        checked={enrollStatus[1]}
                        onChange={() => {
                            onEnrollmentChange();
                            setCheckStatus(1);
                        }}
                        classes={classes}
                    ></CheckBoxFlexGrow>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {global._DateField(fieldsProgramDetail.p3_enrolledOn)}
                    </div>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {fieldsProgramDetail.p3_enrolledOn && !fieldsProgramDetail.p3_active &&
                            <Chip
                                color={"error"}
                                label={"Inactive"}
                                size={"small"}
                                variant={"outlined"}
                            />
                        }
                    </div>
                </div>
                <div
                    className={baseClasses.CheckBoxRow}
                    onClick={(e) => { enrollStatus[2] && handleChange(e, "light"); }}
                    style={{ backgroundColor: value == "light" ? theme.palette.background.front : "white" }}
                >
                    <div className={baseClasses.CheckBoxFlexGrow}>Cash</div>
                    <CheckBoxFlexGrow
                        checked={enrollStatus[2]}
                        onChange={() => {
                            onEnrollmentChange();
                            setCheckStatus(2);
                        }}
                        classes={classes}
                    ></CheckBoxFlexGrow>
                    <div className={baseClasses.CheckBoxFlexGrow}>
                        {global._DateField(fieldsProgramDetail.light_enrolledOn)}
                    </div>
                    <div className={baseClasses.CheckBoxFlexGrow}></div>
                </div>
                {/*           
        <DataGrid
          className="info"
          // rows={props.data.rows}
          columns={progressColumns}
          rows={progressRow}
          //   columns={props.data.screen === 'home' ? columnsClients : props.data.columns}
          //   columns={columnsEmployee}
          headerHeight={40}
          rowHeight={36}
          disableColumnResize={true}
          //   pageSize={10}
          hideFooterSelectedRowCount="true"
          hideFooter="true"
          //   onRowClick={handleRowClick}
        /> */}
            </div>
        );
    };

    const CareNameLeftListItem = ({ name, index, disabled, completed, selectedItem, onBeforeSelect }) => {
        return (
            <ListItem
                button
                selected={selectedItem === name}
                style={{ height: 35 }}
                disabled={disabled}
                onClick={(e) => {
                    handleListItemClick(name, onBeforeSelect)
                }}
            >
                {completed ? (
                    <ListItemIcon style={{ color: "rgb(66, 186, 150)" }}>
                        <AssignmentTurnedInIcon />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                )}

                <ListItemText primary={name} />
            </ListItem>
        );
    };
    /*
    !global.isEmpty()
    */
    const isUploaded = (index) => {
        return fileTemplates[index];
        //return fieldsDocuments[index];
    };
    const getV = () => {
        return !global.isEmpty(fieldsComfortHealth.note);
    }
    const listNameList1 = [
        {
            Title: "Basic",
            CompletedUpon: getV(),
        },
        {
            Title: "Send documents to parents",
            CompletedUpon: completedOnCheckboxField["sentDocsToParentsOn"],
        },
        {
            Title: "Insurance",
            CompletedUpon:
                !global.isEmpty(fieldsComfortHealth.insuranceCompany) &&
                !global.isEmpty(clientDetail) &&
                !global.isEmpty(clientDetail.cin) &&
                isUploaded("InsuranceCard"),
        },
        { Title: "Consent Form", CompletedUpon: isUploaded("ConsentForm") },
        { Title: "Supporting Documents", CompletedUpon: false },
        { Title: "BASC", CompletedUpon: isUploaded("Basc") },
        {
            Title: "SED",
            CompletedUpon: isUploaded("SedReport") && isUploaded("Sed") && isUploaded("Lpha"),
        },
        {
            Title: "Send Binder to Comfort Health",
            CompletedUpon: completedOnCheckboxField["sentBinderToComfortHealthOn"],
        },
        {
            Title: "Assign Care Manager Supervisor",
            CompletedUpon:
                !global.isEmpty(clientDetail) &&
                !global.isEmpty(
                    clientDetail.comfortHealthCareManagerSupervisor_ContactId
                ),
        },
        {
            Title: "Assign Care Manager",
            CompletedUpon:
                !global.isEmpty(clientDetail) &&
                !global.isEmpty(clientDetail.comfortHealthCareManager_ContactId),
        },
        {
            Title: "Intake Meeting",
            CompletedUpon: !global.isEmpty(fieldsComfortHealth.intakeMeetingDate),
        },
        {
            Title: "CANS",
            CompletedUpon: completedOnCheckboxField["canS_CompletedOn"] &&
                !global.isEmpty(clientDetail) &&
                !global.isEmpty(clientDetail.cans),
        },
        //{
        //    Title: "Crisis",
        //    CompletedUpon: completedOnCheckboxField["crisis_CompletedOn"],
        //},
        {
            Title: "Plan of Care",
            CompletedUpon: completedOnCheckboxField["poC_CompletedOn"],
        },
        //{
        //    Title: "Comprehensive Assessment",
        //    CompletedUpon:
        //        completedOnCheckboxField["comprehensiveAssessment_CompletedOn"],
        //},
        {
            Title: "LOC",
            CompletedUpon: completedOnCheckboxField["loC_CompletedOn"],
        },
        {
            Title: "Assign HCBS Coordinator",
            CompletedUpon:
                !global.isEmpty(clientDetail) &&
                !global.isEmpty(clientDetail.comfortHealthHCBSCoordinator_ContactId),
        },
        {
            Title: "Packet",
            CompletedUpon: completedOnCheckboxField["packetSentOn"],
        }, {
            Title: "MCO Notification",
            CompletedUpon: completedOnCheckboxField["mcoNotificationSentOn"],
        },
        {
            Title: "Approval & Details",
            CompletedUpon: completedOnCheckboxField["comfortHealth_approvedOn"],
        },
        //{ Title: "Comfort Health Contract", CompletedUpon: isUploaded("ContractWithComfortHealth") },
    ];

    const listNameList2 = [
        {
            Title: "Basic",
            CompletedUpon:
                !global.isEmpty(clientDetail) && !global.isEmpty(clientDetail.p3Agency),
        },
        {
            Title: "IEP",
            CompletedUpon: isUploaded("IepForP3"),
        },
        {
            Title: "Send IEP to Agency",
            CompletedUpon: completedOnCheckboxField["sentIepToNewAgencyOn"],
        },
        {
            Title: "Approval & Details",
            CompletedUpon: completedOnCheckboxField["p3_approvedOn"],
        },
    ];

    const listNameList3 = ["Basic"];

    const SectionLeftList = useCallback(({ value, classes, listNameList1, listNameList2, listNameList3, fieldsComfortHealth, selectedItem, onBeforeSelect }) => {
        return (
            <div>
                <List component="nav" aria-label="secondary mailbox folder">
                    {/* TODO: Don't use index as key, also check strict !== */}
                    {value == "comfort_health" &&
                        listNameList1.map((item, index) => (
                            <CareNameLeftListItem
                                name={item.Title}
                                index={index}
                                key={index}
                                completed={item.CompletedUpon}
                                disabled={
                                    fieldsComfortHealth.isPrevEnrolledInCH &&
                                        item.Title !== "Basic" &&
                                        item.Title !== "Assign Care Manager Supervisor" &&
                                        item.Title !== "Assign Care Manager" &&
                                        item.Title !== "Comfort Health Contract" &&
                                        item.Title !== "Assign HCBS Coordinator" &&
                                        item.Title !== "Approval & Details" &&
                                        item.Title !== "Consent Form"
                                        ? true
                                        : false
                                }
                                selectedItem={selectedItem}
                                onBeforeSelect={onBeforeSelect}
                            ></CareNameLeftListItem>
                        ))}
                    {value == "p3" &&
                        listNameList2.map((item, index) => (
                            <CareNameLeftListItem
                                name={item.Title}
                                index={index}
                                completed={item.CompletedUpon}
                                key={index}
                                onBeforeSelect={onBeforeSelect}
                            ></CareNameLeftListItem>
                        ))}
                    {value == "light" &&
                        listNameList3.map((item, index) => (
                            <CareNameLeftListItem
                                name={item}
                                index={index}
                                completed={false}
                                key={index}
                                onBeforeSelect={onBeforeSelect}
                            ></CareNameLeftListItem>
                        ))}
                </List>
            </div>
        );
    }, []);

    const SectionRightCard = useCallback(({ title, children, selectedItem, classes }) => {
        return (
            <div>
                {title == selectedItem && (
                    <Card variant="outlined" className={classes.contentOfaccordion}>
                        {children}
                    </Card>
                )}
            </div>
        );
    }, [])

    const SectionRightCardBtn = useCallback(({ classes, isSaveEnable, onSaveRightCard }) => {
        return (
            <Button
                onClick={onSaveRightCard}
                className={baseClasses.capitalized}
                color="primary"
                variant="contained"
                disabled={!isSaveEnable}
                style={{ float: "right", marginBottom: 10 }}
            >
                Save
            </Button>
        );
    }, []);

    const onSaveRightCard = () => {
        //global.log('the save right card function was called right now, here are the fields comfort health details', fieldsComfortHealth)
        if (selectedItem == "Basic" && value === 'comfort_health') {
            setLoadingItem("saveClientDetailProgram", true)
            saveClientDetailProgram(
                exploringId,
                fieldsProgramDetail
            ).then(() => {
                setLoadingItem("saveClientDetailProgram")
                SaveComfortHealth();
                reloadProgramDetailData();
            })
        } else if (selectedItem == "Approval & Details") {
            if (exploringId == null) return;
            setLoadingItem("saveClientDetailProgramComfortHealth", true)
            saveClientDetailProgramComfortHealth(fieldsComfortHealth)
                .then((value) => {
                    setLoadingItem("saveClientDetailProgramComfortHealth")
                    if (value !== true)
                        global.AjaxFailToast1();
                    setLoadingItem("saveClientDetail", true)
                    clientDetailSave(clientDetail).then(() => {
                        setLoadingItem("saveClientDetail")
                    });
                    setIsSaveEnable(false);
                    removeChange("bottomContainer");
                    // CLEAR STATE CHANGES
                    return value;
                })
                .then((value) => {
                    if (value !== true)
                        return;
                    setLoadingItem("saveClientDetailProgram", true)
                    saveClientDetailProgram(
                        exploringId,
                        fieldsProgramDetail
                    ).then((value) => {
                        setLoadingItem("saveClientDetailProgram")
                        if (value !== true)
                            global.AjaxFailToast1();
                        else
                            global.AjaxSaved1();
                    });
                });
        } else if (value == "p3") SaveP3Fields();
        else if (value == "light") SaveLightFields();
        else SaveComfortHealth();
    };
    const handleDateFillForContact = (contact, name) => {
        if (contact) {
            setFieldsComfortHealth({
                ...fieldsComfortHealth,
                [name]: new Date(),
            })
        } else {
            setFieldsComfortHealth({
                ...fieldsComfortHealth,
                [name]: '',
            })
        }
    }

    return (
        <>
            <div className={classes.root}>
                <div style={{
                    pointerEvents: programsPermission.canEdit ? "auto" : "none", opacity: programsPermission.canEdit ? 1 : 0.6
                }}>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <GridListCheckmark
                                classes={classes}
                                setIsSaveEnableTop={setIsSaveEnableTop}
                                saveClientDetailProgram={saveClientDetailProgram}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {value === "comfort_health" && fieldsComfortHealth.note &&
                                <>
                                    <div style={{ fontSize: '12px' }}>
                                        Comfort Health Notes:
                                    </div>
                                    <div>
                                        {fieldsComfortHealth.note}
                                    </div>
                                </>
                            }
                            {value === "p3" && fieldsP3.note &&
                                <>
                                    <div style={{ fontSize: '12px' }}>
                                        P3 Notes:
                                    </div>
                                    <div>
                                        {fieldsP3.note}
                                    </div>
                                </>
                            }
                            {value === "light" && fieldsLight.note &&
                                <>
                                    <div style={{ fontSize: '12px' }}>
                                        Cash Notes:
                                    </div>
                                    <div>
                                        {fieldsLight.note}
                                    </div>
                                </>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>

            {(enrollStatus[0] || enrollStatus[1] || enrollStatus[2]) && (
                <Card variant="outlined">
                    <CardContent classes={{ root: baseClasses.card_view }}>
                        <div className={classes.root}>
                            <AppBar
                                className={baseClasses.appbarColor}
                                position="static"
                                color="primary"
                                elevation={0}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="scrollable auto tabs example"
                                >
                                    {enrollStatus[0] && programChPermission.canView && (
                                        <Tab
                                            className={baseClasses.tabClassSelected}
                                            value="comfort_health"
                                            label="Comfort Health"
                                            {...setRowAndTab(0)}
                                        />
                                    )}
                                    {enrollStatus[1] && programP3Permission.canView && (
                                        <Tab
                                            className={baseClasses.tabClassSelected}
                                            value="p3"
                                            label="P3"
                                            {...setRowAndTab(1)}
                                        />
                                    )}
                                    {enrollStatus[2] && programLightPermission.canView && (
                                        <Tab
                                            className={baseClasses.tabClassSelected}
                                            value="light"
                                            label="Cash"
                                            {...setRowAndTab(2)}
                                        />
                                    )}
                                </Tabs>
                            </AppBar>
                            <div>
                                <TabPanel
                                    value={value}
                                    index="comfort_health"
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} md={5} className={classes.leftSection}>
                                            <SectionLeftList
                                                value={value}
                                                listNameList1={listNameList1}
                                                listNameList2={listNameList2}
                                                listNameList3={listNameList3}
                                                fieldsComfortHealth={fieldsComfortHealth}
                                                selectedItem={selectedItem}
                                                onBeforeSelect={(e) => {
                                                    return !isBottomDirtyState || wConfirm(e, resetPrevData, "You have unsaved changes, do you want to discard them?")
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} md={1}>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <ProtectedComponent
                                                className={classes.rightSection}
                                                //permissions={permissions.programComfortHealth}
                                                allow={programChPermission.canView}
                                                canEdit={programChPermission.canEdit || (selectedItem === "MCO Notification" && programChPermission.actions.editMcoNotification)}

                                            >
                                                <SectionRightCardBtn
                                                    isSaveEnable={isSaveEnable}
                                                    classes={classes}
                                                    onSaveRightCard={onSaveRightCard}
                                                />
                                                <div className={classes.sectionCard}>
                                                    {"Basic" == selectedItem && (
                                                        <Card variant="outlined" className={classes.contentOfaccordion}>
                                                            <CardContent classes={{ root: baseClasses.card_view }}>
                                                                <div>
                                                                    <Grid
                                                                        container
                                                                        className={baseClasses.grid}
                                                                        alignItems="flex-start"
                                                                        spacing={2}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <div className={baseClasses.dFlex}>
                                                                                <SectionHeading
                                                                                    title={"Basic"}
                                                                                    classes={classes}
                                                                                ></SectionHeading>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <div>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                fieldsProgramDetail.comfortHealth_isOnHold
                                                                                            }
                                                                                            name={"comfortHealth_isOnHold"}
                                                                                            onClick={(event) => {
                                                                                                handleFields("fieldsProgramDetail", event);
                                                                                            }}
                                                                                            color="primary"
                                                                                        />
                                                                                    }
                                                                                    labelPlacement="start"
                                                                                    label="On hold"
                                                                                    style={{ margin: 0 }}
                                                                                />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <div>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                fieldsComfortHealth.isPrevEnrolledInCH
                                                                                            }
                                                                                            name={"isPrevEnrolledInCH"}
                                                                                            onClick={(event) => {
                                                                                                handleFields("ComfortHealth", event);
                                                                                            }}
                                                                                            color="primary"
                                                                                        />
                                                                                    }
                                                                                    labelPlacement="start"
                                                                                    label="Previously enrolled in Comfort Health"
                                                                                    style={{ margin: 0 }}
                                                                                />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <div>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                clientDetail.isSeeingTherapist
                                                                                            }
                                                                                            name={"isSeeingTherapist"}
                                                                                            onClick={(event) => {
                                                                                                handleFields("fromClient", event);
                                                                                            }}
                                                                                            color="primary"
                                                                                        />
                                                                                    }
                                                                                    labelPlacement="start"
                                                                                    label="Seeing a therapist"
                                                                                    style={{ margin: 0 }}
                                                                                />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={4}
                                                                            className={baseClasses.document_upload}
                                                                        >
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={completedOnCheckboxField.isPended}
                                                                                        onClick={(event) => {
                                                                                            // if pended-on is empty - never got pended, or it's now turning from OFF to ON
                                                                                            if (!completedOnCheckboxField.pendedOn || !completedOnCheckboxField.isPended) {
                                                                                                setFieldsComfortHealth({
                                                                                                    ...fieldsComfortHealth,
                                                                                                    pendedOn: global._DateTimeFieldDB(new Date()),
                                                                                                });
                                                                                            }
                                                                                            if (completedOnCheckboxField.isPended) // now being turned OFF
                                                                                            {
                                                                                                // if there was already a value before the user turned it ON, which means that it was once ON in the past,
                                                                                                // put that value back since that is what is saved in the database and nothing changed now
                                                                                                // in case the user really wants to update the pended-date, he can update it manually
                                                                                                setFieldsComfortHealth({
                                                                                                    ...fieldsComfortHealth,
                                                                                                    pendedOn: orig_ComfortHealth.pendedOn,
                                                                                                });
                                                                                            }
                                                                                            // swtich the check value
                                                                                            setcompletedOnCheckboxField({
                                                                                                ...completedOnCheckboxField,
                                                                                                isPended: !completedOnCheckboxField.isPended,
                                                                                            });
                                                                                            onFieldChange();
                                                                                        }}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                labelPlacement={"start"}
                                                                                label={"Pended"}
                                                                                style={{ margin: 0 }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid>
                                                                            
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={8}
                                                                            xl={4}
                                                                            className={baseClasses.document_upload}
                                                                        >
                                                                            <CompDateInput
                                                                                programType="comfortHealth"
                                                                                label="Date Pended"
                                                                                name="pendedOn"
                                                                                value={
                                                                                    fieldsComfortHealth.pendedOn
                                                                                }
                                                                                handleFields={handleFields}
                                                                                updateCheckOnDate={false}
                                                                                setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                                classes={classes}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            xl={4}
                                                                            className={baseClasses.document_upload}
                                                                        >
                                                                            <CompDateInput
                                                                                programType="comfortHealth"
                                                                                label="Date Reactivated"
                                                                                name="reactivatedOn"
                                                                                value={
                                                                                    fieldsComfortHealth.reactivatedOn
                                                                                }
                                                                                handleFields={handleFields}
                                                                                setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                                classes={classes}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <div>
                                                                                <FormControl fullWidth>
                                                                                    <TextInputWrapper
                                                                                                                                                                                                                 key={fieldsComfortHealth.note}
                                                                                        label={"Note"}
                                                                                        name={"note"}
                                                                                        value={fieldsComfortHealth.note}
                                                                                        onChange={onFieldChange}
                                                                                        onBlur={handleComfortHealthNoteBlured}

                                                                                    />
                                                                                </FormControl>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    )}


                                                    <SectionRightCard title={"Send documents to parents"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"Send documents to parents"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Sent Documents to Parents"
                                                                            name={"sentDocsToParentsOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Sent On"
                                                                            name="sentDocsToParentsOn"
                                                                            value={
                                                                                fieldsComfortHealth.sentDocsToParentsOn
                                                                            }
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    <SectionRightCard title={"Insurance"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"Insurance"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <FormControl fullWidth>
                                                                            <TextInputWrapper
                                                                                key={fieldsComfortHealth.insuranceCompany}
                                                                                label={"Insurance Company"}
                                                                                name={"insuranceCompany"}
                                                                                rows={1}
                                                                                value={fieldsComfortHealth.insuranceCompany}
                                                                                onChange={onFieldChange}
                                                                                onBlur={handleComfortHealthNoteBlured}

                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <FormControl fullWidth>
                                                                            <TextInputWrapper
                                                                                key={clientDetail.cin}
                                                                                label={"CIN #"}
                                                                                rows={1}
                                                                                name={"cin"}
                                                                                value={clientDetail.cin}
                                                                                onChange={onFieldChange}
                                                                                onBlur={(event) => { handleFields("fromClient", event) }}

                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <FormControl fullWidth>
                                                                            <TextInputWrapper
                                                                                key={clientDetail.healthInsuranceId}
                                                                                label={"Insurance Id"}
                                                                                rows={1}
                                                                                name={"healthInsuranceId"}
                                                                                value={clientDetail.healthInsuranceId}
                                                                                onChange={onFieldChange}
                                                                                onBlur={(event) => { handleFields("fromClient", event) }}

                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                <CompUploadFile
                                                                    fileTemplate={"InsuranceCard"}
                                                                    label={"Insurance Card"}
                                                                    folderTemplate="ComfortHealth"
                                                                />
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* Consent Form */}
                                                    <SectionRightCard title={"Consent Form"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading title={"Consent Form"} classes={classes}></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <CompUploadFile
                                                                    fileTemplate={"ConsentForm"}
                                                                    label={"Consent Form"}
                                                                    folderTemplate="ComfortHealth"
                                                                ></CompUploadFile>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* Supporting Documents */}
                                                    <SectionRightCard title={"Supporting Documents"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"Supporting Documents"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        {/*<FormControl fullWidth>*/}
                                                                        {/*    <TextInputWrapper*/}
                                                                        {/*        label={"Document Name"}*/}
                                                                        {/*        value={note}*/}
                                                                        {/*        name={"supporting_document_name_name"}*/}
                                                                        {/*    />*/}
                                                                        {/*</FormControl>*/}

                                                                    </div>
                                                                </Grid>
                                                                <CompUploadFile
                                                                    fileTemplate={"IepForP3"}
                                                                    label={"IEP"}
                                                                    folderTemplate="P3"
                                                                />
                                                                <CompUploadFile
                                                                    fileTemplate={"Plan504"}
                                                                    label={"504 Plan"}
                                                                    folderTemplate="ComfortHealth"
                                                                />
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* BASC */}
                                                    <SectionRightCard title={"BASC"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading title={"BASC"} classes={classes}></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <CompUploadFile
                                                                    fileTemplate={"Basc"}
                                                                    label={"BASC"}
                                                                    folderTemplate="ComfortHealth"
                                                                ></CompUploadFile>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* SED */}
                                                    <SectionRightCard title={"SED"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading title={"SED"} classes={classes}></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <Grid item xs={6}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Has Previously SED?"
                                                                            name={"hasPrevSed"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                {!completedOnCheckboxField["hasPrevSed"] && (
                                                                    <Card
                                                                        variant="outlined"
                                                                        className={classes.contentOfaccordion}
                                                                        style={{ padding: 13, marginTop: 0 }}
                                                                    >
                                                                        <CardContent
                                                                            classes={{ root: baseClasses.card_view }}
                                                                        >
                                                                            <Grid item xs={12}>
                                                                                <div className={baseClasses.row_section}>
                                                                                    <SectionHeading title="Appointment" classes={classes}></SectionHeading>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                className={baseClasses.document_upload}
                                                                            >
                                                                                <Grid item xs={6}>
                                                                                    <Button
                                                                                        variant="text"
                                                                                        component="label"
                                                                                        color="primary"
                                                                                        size="small"
                                                                                        onClick={() => {
                                                                                            setIsAddAppointment(true);
                                                                                        }}
                                                                                    >
                                                                                        {!appointmentSchedule.clientScheduleId
                                                                                            ? "Schedule Appointment"
                                                                                            : "Edit Appointment"}
                                                                                    </Button>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Typography
                                                                                        variant="subtitle1"
                                                                                        component="span"
                                                                                    >
                                                                                        Appointment Date :{" "}
                                                                                        {global.isEmpty(appointmentSchedule)
                                                                                            ? " - "
                                                                                            : " " +
                                                                                            global._DateField(
                                                                                                appointmentSchedule.date
                                                                                            )}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>
                                                                )}
                                                                <Card
                                                                    variant="outlined"
                                                                    className={classes.contentOfaccordion}
                                                                    style={{ padding: 13, marginTop: 0 }}
                                                                >
                                                                    <CardContent
                                                                        classes={{ root: baseClasses.card_view }}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <div className={baseClasses.dFlex}>
                                                                                <SectionHeading
                                                                                    title={"SED Documents Upload"}
                                                                                    classes={classes}
                                                                                ></SectionHeading>
                                                                                {/*{insurance ? <EditIcon className={baseClasses.card_icon} onClick={() => { setInsurance(false) }} /> : <Close className={baseClasses.edit_icon} onClick={() => { setInsurance(true) }} />}*/}
                                                                            </div>
                                                                        </Grid>
                                                                        <CompUploadFile
                                                                            fileTemplate={"Sed"}
                                                                            label={"SED"}
                                                                            folderTemplate="ComfortHealth"
                                                                        />
                                                                        <CompUploadFile
                                                                            fileTemplate={"SedReport"}
                                                                            label={"Sed Report"}
                                                                            folderTemplate="ComfortHealth"
                                                                        />
                                                                        <CompUploadFile
                                                                            fileTemplate={"Lpha"}
                                                                            label={"LPHA"}
                                                                            folderTemplate="ComfortHealth"
                                                                        />
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/*Send Binder to Comfort Health*/}
                                                    <SectionRightCard title={"Send Binder to Comfort Health"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"Send Binder to Comfort Health"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Sent to Comfort Health"
                                                                            name={"sentBinderToComfortHealthOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="small"
                                                                            component="label"
                                                                            disabled
                                                                        >
                                                                            Download Binder
                                                                        </Button>
                                                                        <div>Coming Soon...</div>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <CompDateInput
                                                                        programType="comfortHealth"
                                                                        label="Sent On"
                                                                        name="sentBinderToComfortHealthOn"
                                                                        value={
                                                                            fieldsComfortHealth.sentBinderToComfortHealthOn
                                                                        }
                                                                        handleFields={handleFields}
                                                                        setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                        classes={classes}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* Send Binder to Comfort Health over */}

                                                    {/* Assign care manager supervisor */}
                                                    <SectionRightCard
                                                        title={"Assign Care Manager Supervisor"}
                                                        selectedItem={selectedItem}
                                                        classes={classes}
                                                    >
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <ProtectedComponent allow canEdit={programChPermission.actions.editCareManagerSupervisor}>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"Assign Care Manager Supervisor"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            <ContactSelectField
                                                                                userRole={"CareManagerSupervisor"}
                                                                                label={"Assign Care Manager Supervisor"}
                                                                                editDataType="id"
                                                                                editData={
                                                                                    clientDetail.comfortHealthCareManagerSupervisor_ContactId
                                                                                }
                                                                                onChange={(contact) => {
                                                                                    handleDateFillForContact(contact, "careManagerSupervisorAssignedOn")
                                                                                    if (contact) {
                                                                                        // if (fields.careManagerName != contact.name)
                                                                                        //   setIsSaveEnable(true);
                                                                                        clientDetailSet({
                                                                                            ...clientDetail,
                                                                                            ["comfortHealthCareManagerSupervisor_ContactId"]:
                                                                                                contact.id,
                                                                                        });
                                                                                        setIsSaveEnable(true);
                                                                                    } else {
                                                                                        // if (fields.careManagerName != "")
                                                                                        setIsSaveEnable(true);

                                                                                        clientDetailSet({
                                                                                            ...clientDetail,
                                                                                            ["comfortHealthCareManagerSupervisor_ContactId"]: 0,
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            ></ContactSelectField>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Assigned On"
                                                                            name="careManagerSupervisorAssignedOn"
                                                                            disabled={global.isEmpty(
                                                                                clientDetail.comfortHealthCareManagerSupervisor_ContactId
                                                                            )}
                                                                            value={
                                                                                global.isEmpty(
                                                                                    clientDetail.comfortHealthCareManagerSupervisor_ContactId
                                                                                )
                                                                                    ? null
                                                                                    : fieldsComfortHealth.careManagerSupervisorAssignedOn
                                                                            }
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </ProtectedComponent>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* Assign care manager supervisor over*/}

                                                    {/* Assign care manager */}
                                                    <SectionRightCard title={"Assign Care Manager"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <ProtectedComponent allow canEdit={programChPermission.actions.editCareManager}>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"Assign Care Manager"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            <ContactSelectField
                                                                                userRole={"CareManager"}
                                                                                label={"Assign Care Manager"}
                                                                                editDataType="id"
                                                                                editData={
                                                                                    clientDetail.comfortHealthCareManager_ContactId
                                                                                }
                                                                                onChange={(contact) => {
                                                                                    handleDateFillForContact(contact, "careManagerAssignedOn")
                                                                                    if (contact) {
                                                                                        // if (fields.careManagerName != contact.name)
                                                                                        //   setIsSaveEnable(true);
                                                                                        clientDetailSet({
                                                                                            ...clientDetail,
                                                                                            ["comfortHealthCareManager_ContactId"]:
                                                                                                contact.id,
                                                                                        });
                                                                                        setIsSaveEnable(true);
                                                                                    } else {
                                                                                        // if (fields.careManagerName != "")
                                                                                        setIsSaveEnable(true);

                                                                                        clientDetailSet({
                                                                                            ...clientDetail,
                                                                                            ["comfortHealthCareManager_ContactId"]: 0,
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            ></ContactSelectField>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Sent On"
                                                                            name="careManagerAssignedOn"
                                                                            disabled={global.isEmpty(
                                                                                clientDetail.comfortHealthCareManager_ContactId
                                                                            )}
                                                                            value={
                                                                                global.isEmpty(
                                                                                    clientDetail.comfortHealthCareManager_ContactId
                                                                                )
                                                                                    ? null
                                                                                    : fieldsComfortHealth.careManagerAssignedOn
                                                                            }
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </ProtectedComponent>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* Assign care manager over*/}
                                                </div>
                                                {/* Second Accordion */}
                                                {/* Care manager */}
                                                <div className={baseClasses.full_width}>
                                                    <SectionRightCard title={"Intake Meeting"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"Intake Meeting"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        {
                                                                            // <Typography
                                                                            //   className={classes.date}
                                                                            //   variant="subtitle1"
                                                                            //   component="span"
                                                                            // >
                                                                        }
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Meeting Date"
                                                                            name="intakeMeetingDate"
                                                                            value={fieldsComfortHealth.intakeMeetingDate}
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    <SectionRightCard title={"CANS"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading title={"CANS"} classes={classes}></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Completed"
                                                                            name={"canS_CompletedOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <CompDateInput
                                                                        programType="Date Sent"
                                                                        label="Completed On"
                                                                        name="canS_CompletedOn"
                                                                        value={fieldsComfortHealth.canS_CompletedOn}
                                                                        handleFields={handleFields}
                                                                        setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                        classes={classes}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div className="mt-2 mb-2">
                                                                        <CompSelectbox
                                                                            programType="fromClient"
                                                                            label={"CANS Outcome"}
                                                                            name="cans"
                                                                            value={clientDetail.cans}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    {/*<SectionRightCard title={"Crisis"} selectedItem={selectedItem} classes={classes}>*/}
                                                    {/*    <CardContent classes={{ root: classes.card_view }}>*/}
                                                    {/*        <Grid*/}
                                                    {/*            container*/}
                                                    {/*            className={classes.grid}*/}
                                                    {/*            alignItems="flex-start"*/}
                                                    {/*            spacing={2}*/}
                                                    {/*        >*/}
                                                    {/*            <Grid item xs={12}>*/}
                                                    {/*                <div className={baseClasses.dFlex}>*/}
                                                    {/*                    <SectionHeading*/}
                                                    {/*                        title={"Crisis"}*/}
                                                    {/*                        classes={classes}*/}
                                                    {/*                    ></SectionHeading>*/}
                                                    {/*                </div>*/}
                                                    {/*            </Grid>*/}
                                                    {/*            <Grid item xs={12}>*/}
                                                    {/*                <div>*/}
                                                    {/*                    <CompCheckboxForDate*/}
                                                    {/*                        programType="comfortHealth"*/}
                                                    {/*                        label="Completed"*/}
                                                    {/*                        name={"crisis_CompletedOn"}*/}
                                                    {/*                        completedOnCheckboxField={completedOnCheckboxField}*/}
                                                    {/*                        handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}*/}
                                                    {/*                        setIsSaveEnable={setIsSaveEnable}*/}
                                                    {/*                        fieldsP3={fieldsP3}*/}
                                                    {/*                        fieldsProgramDetail={fieldsProgramDetail}*/}
                                                    {/*                        fieldsComfortHealth={fieldsComfortHealth}*/}
                                                    {/*                    ></CompCheckboxForDate>*/}
                                                    {/*                </div>*/}
                                                    {/*            </Grid>*/}
                                                    {/*            <Grid*/}
                                                    {/*                item*/}
                                                    {/*                xs={12}*/}
                                                    {/*                className={baseClasses.document_upload}*/}
                                                    {/*            >*/}
                                                    {/*                <CompDateInput*/}
                                                    {/*                    programType="comfortHealth"*/}
                                                    {/*                    label="Completed On"*/}
                                                    {/*                    name="crisis_CompletedOn"*/}
                                                    {/*                    value={fieldsComfortHealth.crisis_CompletedOn}*/}
                                                    {/*                    handleFields={handleFields}*/}
                                                    {/*                    setcompletedOnCheckboxField={setcompletedOnCheckboxField}*/}
                                                    {/*                    classes={classes}*/}
                                                    {/*                />*/}
                                                    {/*            </Grid>*/}
                                                    {/*        </Grid>*/}
                                                    {/*    </CardContent>*/}
                                                    {/*</SectionRightCard>*/}

                                                    <SectionRightCard title={"Plan of Care"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"Plan of Care"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Completed"
                                                                            name={"poC_CompletedOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <CompDateInput
                                                                        programType="comfortHealth"
                                                                        label="Completed On"
                                                                        name="poC_CompletedOn"
                                                                        value={fieldsComfortHealth.poC_CompletedOn}
                                                                        handleFields={handleFields}
                                                                        setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                        classes={classes}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    {/*<SectionRightCard title={"Comprehensive Assessment"} selectedItem={selectedItem} classes={classes}>*/}
                                                    {/*    <CardContent classes={{ root: classes.card_view }}>*/}
                                                    {/*        <Grid*/}
                                                    {/*            container*/}
                                                    {/*            className={classes.grid}*/}
                                                    {/*            alignItems="flex-start"*/}
                                                    {/*            spacing={2}*/}
                                                    {/*        >*/}
                                                    {/*            <Grid item xs={12}>*/}
                                                    {/*                <div className={baseClasses.dFlex}>*/}
                                                    {/*                    <SectionHeading*/}
                                                    {/*                        title={"Comprehensive Assessment"}*/}
                                                    {/*                        classes={classes}*/}
                                                    {/*                    ></SectionHeading>*/}
                                                    {/*                </div>*/}
                                                    {/*            </Grid>*/}
                                                    {/*            <Grid item xs={12}>*/}
                                                    {/*                <div>*/}
                                                    {/*                    <CompCheckboxForDate*/}
                                                    {/*                        programType="comfortHealth"*/}
                                                    {/*                        label="Completed"*/}
                                                    {/*                        name={"comprehensiveAssessment_CompletedOn"}*/}
                                                    {/*                        completedOnCheckboxField={completedOnCheckboxField}*/}
                                                    {/*                        handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}*/}
                                                    {/*                        setIsSaveEnable={setIsSaveEnable}*/}
                                                    {/*                        fieldsP3={fieldsP3}*/}
                                                    {/*                        fieldsProgramDetail={fieldsProgramDetail}*/}
                                                    {/*                        fieldsComfortHealth={fieldsComfortHealth}*/}
                                                    {/*                    ></CompCheckboxForDate>*/}
                                                    {/*                </div>*/}
                                                    {/*            </Grid>*/}
                                                    {/*            <Grid*/}
                                                    {/*                item*/}
                                                    {/*                xs={12}*/}
                                                    {/*                className={baseClasses.document_upload}*/}
                                                    {/*            >*/}
                                                    {/*                <CompDateInput*/}
                                                    {/*                    programType="comfortHealth"*/}
                                                    {/*                    label="Completed On"*/}
                                                    {/*                    name="comprehensiveAssessment_CompletedOn"*/}
                                                    {/*                    value={*/}
                                                    {/*                        fieldsComfortHealth.comprehensiveAssessment_CompletedOn*/}
                                                    {/*                    }*/}
                                                    {/*                    handleFields={handleFields}*/}
                                                    {/*                    setcompletedOnCheckboxField={setcompletedOnCheckboxField}*/}
                                                    {/*                    classes={classes}*/}
                                                    {/*                />*/}
                                                    {/*            </Grid>*/}
                                                    {/*        </Grid>*/}
                                                    {/*    </CardContent>*/}
                                                    {/*</SectionRightCard>*/}

                                                    <SectionRightCard title={"LOC"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading title={"LOC"} classes={classes}></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Completed"
                                                                            name={"loC_CompletedOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <CompDateInput
                                                                        programType="comfortHealth"
                                                                        label="Completed On"
                                                                        name="loC_CompletedOn"
                                                                        value={fieldsComfortHealth.loC_CompletedOn}
                                                                        handleFields={handleFields}
                                                                        setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                        classes={classes}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    <SectionRightCard title={"Packet"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"Packet"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Sent"
                                                                            name={"packetSentOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <CompDateInput
                                                                        programType="comfortHealth"
                                                                        label="Sent On"
                                                                        name="packetSentOn"
                                                                        value={fieldsComfortHealth.packetSentOn}
                                                                        handleFields={handleFields}
                                                                        setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                        classes={classes}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* Assign HCBS Coordinator */}
                                                    <SectionRightCard
                                                        title={"Assign HCBS Coordinator"}
                                                        selectedItem={selectedItem}
                                                        classes={classes}
                                                    >
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <ProtectedComponent allow canEdit={programChPermission.actions.editHcbsCoordinator}>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"Assign HCBS Coordinator"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            <ContactSelectField
                                                                                userRole={"HCBSCoordinator"}
                                                                                label={"Assign HCBS Coordinator"}
                                                                                editDataType="id"
                                                                                editData={
                                                                                    clientDetail.comfortHealthHCBSCoordinator_ContactId
                                                                                }
                                                                                onChange={(contact) => {
                                                                                    handleDateFillForContact(contact, "hcbsCoordinatorAssignedOn")
                                                                                    if (contact) {
                                                                                        // if (fields.careManagerName != contact.name)
                                                                                        //   setIsSaveEnable(true);
                                                                                        clientDetailSet({
                                                                                            ...clientDetail,
                                                                                            ["comfortHealthHCBSCoordinator_ContactId"]:
                                                                                                contact.id,
                                                                                        });
                                                                                        setIsSaveEnable(true);
                                                                                    } else {
                                                                                        // if (fields.careManagerName != "")
                                                                                        setIsSaveEnable(true);

                                                                                        clientDetailSet({
                                                                                            ...clientDetail,
                                                                                            ["comfortHealthHCBSCoordinator_ContactId"]: 0,
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            ></ContactSelectField>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="comfortHealth"
                                                                            label="Assigned On"
                                                                            name="hcbsCoordinatorAssignedOn"
                                                                            disabled={global.isEmpty(
                                                                                clientDetail.comfortHealthHCBSCoordinator_ContactId
                                                                            )}
                                                                            value={
                                                                                global.isEmpty(
                                                                                    clientDetail.comfortHealthHCBSCoordinator_ContactId
                                                                                )
                                                                                    ? null
                                                                                    : fieldsComfortHealth.hcbsCoordinatorAssignedOn
                                                                            }
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </ProtectedComponent>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    {/* Assign care manager supervisor over*/}
                                                    <SectionRightCard title={"MCO Notification"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"MCO Notification"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <div>
                                                                        <CompCheckboxForDate
                                                                            programType="comfortHealth"
                                                                            label="Notification sent"
                                                                            name={"mcoNotificationSentOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <CompDateInput
                                                                        programType="comfortHealth"
                                                                        label="Sent notification On"
                                                                        name="mcoNotificationSentOn"
                                                                        value={fieldsComfortHealth.mcoNotificationSentOn}
                                                                        handleFields={handleFields}
                                                                        setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                        classes={classes}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    <SectionRightCard title={"Approval & Details"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <Grid
                                                                container
                                                                className={baseClasses.grid}
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <div className={baseClasses.dFlex}>
                                                                        <SectionHeading
                                                                            title={"Approval & Details"}
                                                                            classes={classes}
                                                                        ></SectionHeading>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <ProtectedComponent allow canEdit={programChPermission.actions.editApprovalInfo}>
                                                                        <CompDateInput
                                                                            programType="fieldsProgramDetail"
                                                                            label="Approved On"
                                                                            name="comfortHealth_approvedOn"
                                                                            value={
                                                                                fieldsProgramDetail.comfortHealth_approvedOn
                                                                            }
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </ProtectedComponent>

                                                                </Grid>

                                                                <Grid item xs={4} style={{ marginTop: 16 }}>
                                                                    <ProtectedComponent allow canEdit={programChPermission.actions.editApprovalInfo}>
                                                                        <CompCheckboxForDate
                                                                            programType="fieldsProgramDetail"
                                                                            label="Approved"
                                                                            name={"comfortHealth_approvedOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </ProtectedComponent>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className={baseClasses.document_upload}
                                                                >
                                                                    <TTextFieldNumber
                                                                        label="Comfort Health ID"
                                                                        name={"comfortHealthClientId"}
                                                                        value={fieldsProgramDetail.comfortHealthClientId}
                                                                        onChange={(event) => handleFields("fieldsProgramDetail", event)}
                                                                    />
                                                                </Grid>
                                                                <ProtectedComponent allow canEdit={programChPermission.actions.editApprovalInfo}>
                                                                    <ServiceDetails
                                                                        key={fieldsProgramDetail.comfortHealth_comhab + "comhab"}
                                                                        label="Com Hab : "
                                                                        name="comfortHealth_comhab"
                                                                        fieldsProgramDetail={fieldsProgramDetail}
                                                                        setFieldsProgramDetail={setFieldsProgramDetail}
                                                                        setIsSaveEnable={setIsSaveEnable}
                                                                        classes={classes}
                                                                    />
                                                                    <ServiceDetails
                                                                        key={fieldsProgramDetail.comfortHealth_respite + "respite"}
                                                                        label="Repite : "
                                                                        name="comfortHealth_respite"
                                                                        fieldsProgramDetail={fieldsProgramDetail}
                                                                        setFieldsProgramDetail={setFieldsProgramDetail}
                                                                        setIsSaveEnable={setIsSaveEnable}
                                                                        classes={classes}
                                                                    />
                                                                    <ServiceDetails
                                                                        key={fieldsProgramDetail.comfortHealth_cfss + "cfss"}
                                                                        label="CFSS : "
                                                                        name="comfortHealth_cfss"
                                                                        fieldsProgramDetail={fieldsProgramDetail}
                                                                        setFieldsProgramDetail={setFieldsProgramDetail}
                                                                        setIsSaveEnable={setIsSaveEnable}
                                                                        classes={classes}
                                                                    />
                                                                    <ServiceDetails
                                                                        key={fieldsProgramDetail.comfortHealth_preVoc + "preVoc"}
                                                                        label="Pre-Voc : "
                                                                        name="comfortHealth_preVoc"
                                                                        fieldsProgramDetail={fieldsProgramDetail}
                                                                        setFieldsProgramDetail={setFieldsProgramDetail}
                                                                        setIsSaveEnable={setIsSaveEnable}
                                                                        classes={classes}
                                                                    />
                                                                    <ServiceDetails
                                                                        key={fieldsProgramDetail.comfortHealth_cats + "cats"}
                                                                        label="CATS : "
                                                                        name="comfortHealth_cats"
                                                                        fieldsProgramDetail={fieldsProgramDetail}
                                                                        setFieldsProgramDetail={setFieldsProgramDetail}
                                                                        setIsSaveEnable={setIsSaveEnable}
                                                                        classes={classes}
                                                                    />
                                                                </ProtectedComponent>
                                                                <Grid item xs={12}>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <ProtectedComponent allow canEdit={programChPermission.actions.editApprovalInfo}>
                                                                        <TextField
                                                                            variant={"outlined"}
                                                                            label="Time Requirements"
                                                                            name={"comfortHealthTimeRequirements"}
                                                                            value={fieldsProgramDetail.comfortHealthTimeRequirements}
                                                                            onChange={(event) => handleFields("fieldsProgramDetail", event)}
                                                                            fullWidth
                                                                            multiline
                                                                        ></TextField>
                                                                    </ProtectedComponent>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    {/*<SectionRightCard title={"Comfort Health Contract"} selectedItem={selectedItem} classes={classes}>*/}
                                                    {/*    <CardContent classes={{ root: classes.card_view }}>*/}
                                                    {/*        <Grid*/}
                                                    {/*            container*/}
                                                    {/*            className={classes.grid}*/}
                                                    {/*            alignItems="flex-start"*/}
                                                    {/*            spacing={2}*/}
                                                    {/*        >*/}
                                                    {/*            <Grid item xs={12}>*/}
                                                    {/*                <div className={baseClasses.dFlex}>*/}
                                                    {/*                    <SectionHeading*/}
                                                    {/*                        title={"Comfort Health Contract"}*/}
                                                    {/*                        classes={classes}*/}
                                                    {/*                    ></SectionHeading>*/}
                                                    {/*                </div>*/}
                                                    {/*            </Grid>*/}
                                                    {/*            <CompUploadFile*/}
                                                    {/*                fileTemplate={"ContractWithComfortHealth"}*/}
                                                    {/*                label={"Comfort Health Contract"}*/}
                                                    {/*                label={"ContractWithComfortHealth"}*/}
                                                    {/*                folderTemplate="ComfortHealth"*/}
                                                    {/*            />*/}
                                                    {/*        </Grid>*/}
                                                    {/*    </CardContent>*/}
                                                    {/*</SectionRightCard>*/}
                                                </div>
                                            </ProtectedComponent>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index="p3">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} md={5} className={classes.leftSection}>
                                            <SectionLeftList
                                                value={value}
                                                classes={classes}
                                                listNameList1={listNameList1}
                                                listNameList2={listNameList2}
                                                listNameList3={listNameList3}
                                                fieldsComfortHealth={fieldsComfortHealth}
                                                selectedItem={selectedItem}
                                                onBeforeSelect={(e) => {
                                                    return !isBottomDirtyState || wConfirm(e, resetPrevData, "You have unsaved changes, do you want to discard them?")
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} md={1}>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <ProtectedComponent className={classes.rightSection} permissions={permissions.programP3}>

                                                <SectionRightCardBtn
                                                    isSaveEnable={isSaveEnable}
                                                    classes={classes}
                                                    onSaveRightCard={onSaveRightCard}
                                                />
                                                <div className="w-100">
                                                    <SectionRightCard title={"Basic"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"Basic"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={fieldsP3.isPrevInAgency}
                                                                                        onClick={(event) => {
                                                                                            setFieldsP3(
                                                                                                { ...fieldsP3, isPrevInAgency: !fieldsP3.isPrevInAgency }
                                                                                            );
                                                                                            onFieldChange();
                                                                                        }}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                labelPlacement="start"
                                                                                label="Previously in Agency"
                                                                                style={{ margin: 0 }}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                    {fieldsP3.isPrevInAgency == true ? (
                                                                        <Grid item xs={12}>
                                                                            <div>
                                                                                <FormControl fullWidth>
                                                                                    <TextInputWrapper
                                                                                        key={fieldsP3.prevAgency}
                                                                                        label={"Previous Agency"}
                                                                                        name={"prevAgency"}
                                                                                        value={fieldsP3.prevAgency}
                                                                                        onChange={onFieldChange}
                                                                                        onBlur={(event) => { handleFields("P3", event) }}

                                                                                    />
                                                                                </FormControl>
                                                                            </div>
                                                                        </Grid>
                                                                    ) : (
                                                                        <div></div>
                                                                    )}
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            <FormControl fullWidth>
                                                                                <TextInputWrapper
                                                                                    key={clientDetail.p3Agency}
                                                                                    label={"P3 Agency"}
                                                                                    name={"p3Agency"}
                                                                                    value={clientDetail.p3Agency}
                                                                                    onChange={onFieldChange}
                                                                                    onBlur={(event) => { handleFields("fromClient", event) }}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            <FormControl fullWidth>
                                                                                <TextInputWrapper
                                                                                    key={fieldsP3.note}
                                                                                    label={"Note"}
                                                                                    name={"note"}
                                                                                    value={fieldsP3.note}
                                                                                    onChange={onFieldChange}
                                                                                    onBlur={(event) => { handleP3NoteBlured(event) }}

                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    <SectionRightCard title={"IEP"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"IEP Upload"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                            {/*<EditIcon className={baseClasses.card_icon} />*/}
                                                                        </div>
                                                                    </Grid>
                                                                    <CompUploadFile
                                                                        fileTemplate={"IepForP3"}
                                                                        label={"IEP"}
                                                                        folderTemplate="P3"
                                                                    />
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    <SectionRightCard title={"Send IEP to Agency"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"Send IEP to Agency"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompCheckboxForDate
                                                                            programType="P3"
                                                                            label="Sent to agency"
                                                                            name={"sentIepToNewAgencyOn"}
                                                                            completedOnCheckboxField={completedOnCheckboxField}
                                                                            handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                            setIsSaveEnable={setIsSaveEnable}
                                                                            fieldsP3={fieldsP3}
                                                                            fieldsProgramDetail={fieldsProgramDetail}
                                                                            fieldsComfortHealth={fieldsComfortHealth}
                                                                        ></CompCheckboxForDate>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="P3"
                                                                            label="Date Sent"
                                                                            name="sentIepToNewAgencyOn"
                                                                            value={fieldsP3.sentIepToNewAgencyOn}
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>

                                                    <SectionRightCard title={"Approval & Details"} selectedItem={selectedItem} classes={classes}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="flex-start"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div className={baseClasses.dFlex}>
                                                                            <SectionHeading
                                                                                title={"Approval & Details"}
                                                                                classes={classes}
                                                                            ></SectionHeading>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <Grid item xs={6}>
                                                                            <CompCheckboxForDate
                                                                                programType="fieldsProgramDetail"
                                                                                label="Approved"
                                                                                name={"p3_approvedOn"}
                                                                                completedOnCheckboxField={completedOnCheckboxField}
                                                                                handlecompletedOnCheckboxField={handlecompletedOnCheckboxField}
                                                                                setIsSaveEnable={setIsSaveEnable}
                                                                                fieldsP3={fieldsP3}
                                                                                fieldsProgramDetail={fieldsProgramDetail}
                                                                                fieldsComfortHealth={fieldsComfortHealth}
                                                                            ></CompCheckboxForDate>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        className={baseClasses.document_upload}
                                                                    >
                                                                        <CompDateInput
                                                                            programType="fieldsProgramDetail"
                                                                            label="Date Approved"
                                                                            name="p3_approvedOn"
                                                                            value={fieldsProgramDetail.p3_approvedOn}
                                                                            handleFields={handleFields}
                                                                            setcompletedOnCheckboxField={setcompletedOnCheckboxField}
                                                                            classes={classes}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            <FormControl fullWidth>
                                                                                <ServiceDetails
                                                                                    key={fieldsProgramDetail.p3_p3service + "p3service"}
                                                                                    label="P3 Service: "
                                                                                    name="p3_p3service"
                                                                                    fieldsProgramDetail={fieldsProgramDetail}
                                                                                    setFieldsProgramDetail={setFieldsProgramDetail}
                                                                                    setIsSaveEnable={setIsSaveEnable}
                                                                                    classes={classes}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                </div>
                                            </ProtectedComponent>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <ProtectedComponent permissions={permissions.programLight}>
                                    <TabPanel
                                        value={value}
                                        index="light"
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} md={5} className={classes.leftSection}>
                                                <SectionLeftList
                                                    value={value}
                                                    classes={classes}
                                                    listNameList1={listNameList1}
                                                    listNameList2={listNameList2}
                                                    listNameList3={listNameList3}
                                                    fieldsComfortHealth={fieldsComfortHealth}
                                                    selectedItem={selectedItem}
                                                    onBeforeSelect={(e) => {
                                                        return !isBottomDirtyState || wConfirm(e, resetPrevData, "You have unsaved changes, do you want to discard them?")
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={1}>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <div className={classes.rightSection}
                                                    style={{ pointerEvents: programLightPermission.canEdit ? "auto" : "none", opacity: programLightPermission.canEdit ? 1 : 0.6 }}>
                                                    <SectionRightCardBtn
                                                        isSaveEnable={isSaveEnable}
                                                        classes={classes}
                                                        onSaveRightCard={onSaveRightCard}
                                                    />
                                                    <div>
                                                        <SectionRightCard title={"Basic"} selectedItem={selectedItem} classes={classes}>
                                                            <CardContent classes={{ root: baseClasses.card_view }}>
                                                                <div>
                                                                    <Grid
                                                                        container
                                                                        className={baseClasses.grid}
                                                                        alignItems="flex-start"
                                                                        spacing={2}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <div className={baseClasses.dFlex}>
                                                                                <SectionHeading
                                                                                    title={"Basic"}
                                                                                    classes={classes}
                                                                                ></SectionHeading>
                                                                            </div>
                                                                        </Grid>
                                                                        <PaymentInfo savestatusaction=
                                                                            {() =>
                                                                                setIsSaveEnable(true)
                                                                            }
                                                                            onFieldChange={onFieldChange}
                                                                        ></PaymentInfo>
                                                                        {/**/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <div>*/}
                                                                        {/*        <FormControl fullWidth>*/}
                                                                        {/*            <TextInputWrapper*/}
                                                                        {/*                key={fieldsLight.note}*/}
                                                                        {/*                label={"Note"}*/}
                                                                        {/*                name={"note"}*/}
                                                                        {/*                value={fieldsLight.note}*/}
                                                                        {/*                onChange={onFieldChange}*/}
                                                                        {/*                onBlur={(event) => { handleFields("Light", event) }}*/}
                                                                        {/*            />*/}
                                                                        {/*        </FormControl>*/}
                                                                        {/*    </div>*/}
                                                                        {/*</Grid>*/}
                                                                    </Grid>
                                                                </div>
                                                            </CardContent>
                                                        </SectionRightCard>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </ProtectedComponent>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            )
            }

            {
                isAddAppointment &&
                <AppointmentModal
                    show={true}
                    handleEdit={(row) => saveAppointmentRow(row, "clients", props.clientId)}
                    handleClose={(data) => {
                        if (!data || !data.result) {
                            setIsAddAppointment(false);
                            return;
                        }
                        if (global.isEmpty(fieldsComfortHealth.appointment_ClientProviderScheduleId)) {
                            fieldsComfortHealth.appointment_ClientProviderScheduleId = data.result.id;
                            SaveComfortHealth();
                        }
                        setAppointmentScheduleData(fieldsComfortHealth.appointment_ClientProviderScheduleId);
                        setIsAddAppointment(false);
                    }}
                    clientSchedule={appointmentSchedule}
                    providers={professionals}
                />
            }

            <Dialog
                fullScreen={fullScreen}
                open={openConfirm}
                onClose={() => {
                    setOpenConfirm(false);
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to cancel your changes?"}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            rollbackChanges();
                        }}
                        color="primary"
                    >
                        Yes, Discard
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenConfirm(false);
                        }}
                        color="primary"
                    >
                        No, Keep changes
                    </Button>
                </DialogActions>
            </Dialog>
            {Prompt}
        </>
    );
}
