import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { useStoreActions, useStoreState, } from "easy-peasy";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from '../Card/Card';
import * as constant from "../../../constants";
import DeleteModel from "../Modals/DeleteModel/DeleteModel";
import EndSchedule from "../Modals/EndSchedule/EndSchedule";
import AppointmentModal from "../Modals/Appointment/Appointment";
import YungermanModal from "../Modals/YungermanModel/YungermanModal";
import SCHEDULE_PERIODICITY_CONSTANTS from '../../../_helpers/constants/schedule_periodicity_constants.js';
import usePermission from '../../../hooks/usePermission'
import { getProfessionalNames } from '../../../api/employees'
import useStyles from './styles';
import NetAvailability from "../../explorer/Tabs/Schedule/NetAvailability";
import useScheduleEditDataManager from '.././../../hooks/useScheduleEditDataManager';
import { CardSubtitle } from "reactstrap";

const ITEM_HEIGHT = 48;
const defaultRow_Provider = {
    specialties: [], clientProviderSchedulePotentials: [], status: "seeking",
    schedule: { date: undefined, days: "", periodicity: SCHEDULE_PERIODICITY_CONSTANTS.WEEKS }
};

export default function MainSection(props) {
    // data
    const exploringId = useStoreState((state) => state.exploringId);
    const exploringType = useStoreState((state) => state.exploringType);
    const { client, provider, cards, isApplicant, contactId } = props;
    //const { schedules, professionals, appointments, seeking, history, availability, potentials } = props.showCards;
    const classes = useStyles();
    const [scheduleContext] = useScheduleEditDataManager();

    const [professionals, setProfessionals] = useState([]);
    const [yungermenRows, setYungermenRows] = React.useState([]);
    const [professionalRows, setProfessionalRows] = React.useState([]);
    const [appointmentRows, setAppointmentRows] = React.useState([]);
    const [availabilityRows, setAvailabilityRows] = React.useState([]);
    const [professionalHistoryRows, setProfessionalHistoryRows] = React.useState([]);
    const [appointmentHistoryRows, setAppointmentHistoryRows] = React.useState([]);

    const [filteredRows_Yungerman, setFilteredRows_Yungerman] = React.useState([]);

    // redux schedules list
    const setSchedules_store = useStoreActions((actions) => actions.clients.setSchedules);

    // is loading rows
    const [isLoading_YungermenRows, setIsLoading_YungermenRows] = React.useState(false);
    const [isLoading_ProfessionalRows, setIsLoading_ProfessionalRows] = React.useState(false);
    const [isLoading_AppointmentRows, setIsLoading_AppointmentRows] = React.useState(false);
    const [isLoading_AvailabilityRows, setIsLoading_AvailabilityRows] = React.useState(false);

    // is loading row edit
    const [isLoading_ProviderRow, setIsLoading_ProviderRow] = React.useState(false);

    // show row edit dialog
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [xPos, setXPos] = useState("0px");
    const [yPos, setYPos] = useState("0px");
    const open = Boolean(anchorEl);
    const [show_ProviderRow, setShow_ProviderRow] = React.useState(false);
    const [end_ProviderRow, setEnd_ProviderRow] = React.useState(false);
    const [del, setdel] = React.useState(false);

    // edit row data
    const [row_Provider, setRow_Provider] = React.useState(defaultRow_Provider);

    // selected row
    const [slcRow_Provider, setSlcRow_Provider] = React.useState(null);
    const [slcRow_Type, setSlcRow_Type] = React.useState(null);

    // more actions
    const [endScheduleData, setEndScheduleData] = React.useState({});
    const [menu, setMenu] = React.useState(['edit', 'delete']);

    //schedule permissions, action based
    const permissions = global.permissions.clients.schedules;
    const generalPermissions = usePermission(permissions);
    const yungermanPermissions = usePermission(permissions.yungerman);
    const professionalPermissions = usePermission(permissions.professional);
    const appointmentPermissions = usePermission(permissions.appointment);
    //employee
    const employeePermission = usePermission(global.permissions.employees.schedules);

    const formatDate = (dateTime) => {
        return global.dateTime.getUSAFormattedDateString(dateTime);
    }

    const showEndSchedule = (show) => {
        setEnd_ProviderRow(show);
    }

    const saveYungermanRow = (row) => {
        setIsLoading_ProviderRow(true);
        scheduleContext?.saveEntity(row, slcRow_Type).then(() => {
            setShow_ProviderRow(false);
            loadYungermanData([slcRow_Type]);
        })
    }

    const saveAppointmentRow = (row) => {
        setIsLoading_ProviderRow(true);
        scheduleContext.saveEntity(row, slcRow_Type).then((data) => {
            setIsLoading_ProviderRow(false);
            setShow_ProviderRow(false);
            loadYungermanData([slcRow_Type]);
            loadAppointmentsData();//currently not in use really
        })
    }

    const handleEditSave = (row) => {
        switch (slcRow_Type) {
            case constant.SCHEDULE_TYPE.yungerman:
            case constant.SCHEDULE_TYPE.professional:
                saveYungermanRow(row);
                break;
            case constant.SCHEDULE_TYPE.appointment:
                saveAppointmentRow(row);
                break;
            case constant.SCHEDULE_TYPE.availability:
                saveAvailabilityRow(row);
                break;
            default:
        }
    };

    const handleRowDelete = () => {
        if (slcRow_Provider.clientScheduleId !== undefined) {
            if (slcRow_Type === constant.SCHEDULE_TYPE.appointment) {

            }
            //if (slcRow_Type === "yungerman")
            if (slcRow_Type === constant.SCHEDULE_TYPE.availability) {
                handleAvailabilityRowDelete(slcRow_Provider);
            }
            else {
                handleYungermanRowDelete(slcRow_Provider);
            }
            //else
            //    handleProfessionalRowDelete(slcRow_Provider);
        }
        else
            global.log("Can't find client schedule with ID " + slcRow_Provider.clientScheduleId);
    }

    const handleYungermanRowDelete = (row) => {
        setIsLoading_YungermenRows(true);
        scheduleContext.deleteEntity(row.clientScheduleId, slcRow_Type).then((data) => {
            if (!!data) {
                let newTableData = yungermenRows;
                let index = newTableData.findIndex((cs) => cs.clientScheduleId === row.clientScheduleId);
                if (index > -1) {
                    newTableData.splice(index, 1);
                }
                setYungermenRows(newTableData);
            }
            setIsLoading_YungermenRows(false);
            setdel(false);
        })
    };

    const handleAvailabilityRowDelete = (row) => {
        setIsLoading_AvailabilityRows(true);
        scheduleContext.deleteEntity(row.clientScheduleId, slcRow_Type).then((data) => {
            if (!!data) {
                let newTableData = availabilityRows;
                let index = newTableData.findIndex((cs) => cs.clientScheduleId === row.clientScheduleId);
                if (index > -1) {
                    newTableData.splice(index, 1);
                }
                setAvailabilityRows(newTableData ?? []);
            }
            setIsLoading_AvailabilityRows(false);
            setdel(false);
        })
    };

    const setLoading = (scheTypes, state) => {
        if (!scheTypes || scheTypes.includes(constant.SCHEDULE_TYPE.yungerman))
            setIsLoading_YungermenRows(state);
        if (!scheTypes || scheTypes.includes(constant.SCHEDULE_TYPE.professional))
            setIsLoading_ProfessionalRows(state);
        if (!scheTypes || scheTypes.includes(constant.SCHEDULE_TYPE.appointment))
            setIsLoading_AppointmentRows(state);
    }

    const getThroughoutSting = (startDate, endDate) => `${startDate ? formatDate(global.dateTime.filterDefaultDate(startDate)) : "-"} - ${endDate ? formatDate(global.dateTime.filterDefaultDate(endDate)) : "-"}`
    const loadYungermanData = (changesTypes) => {
        setLoading(changesTypes, true);
        global.log(exploringType);
        scheduleContext.load(constant.SCHEDULE_TYPE.yungerman, client, provider).then((schedules) => {
            setYungermenRows(schedules)
            setSchedules_store(schedules)
            setLoading(changesTypes, false);
        })

    }



    const loadApplicantData = () => {
        setIsLoading_AvailabilityRows(true);
        scheduleContext.load(constant.SCHEDULE_TYPE.applicant, client, provider, contactId, true).then(async (schedules) => {
            console.log(schedules)
            setAvailabilityRows(schedules);
            console.log(schedules)
            setIsLoading_AvailabilityRows(false);

        })

    }

    const loadAppointmentsData = () => {
        setIsLoading_AppointmentRows(true);
        scheduleContext.load(constant.SCHEDULE_TYPE.appointment)?.then((result) => {
            setAppointmentRows(result);
            setIsLoading_ProfessionalRows(false);

        })
    }

    // get current editing client schedule
    const loadYungermanEditData = (clientScheduleId, scheType) => {
        scheduleContext.loadEntity(clientScheduleId, slcRow_Type || scheType)?.then((result) => {
            setRow_Provider(result)
        })
    };

    const loadAppointmentEditData = (clientScheduleId, scheType) => {
        if (clientScheduleId == undefined) return;
        scheduleContext.loadEntity(clientScheduleId, slcRow_Type || scheType, slcRow_Provider)?.then((result) => {
            setRow_Provider(result)
        })
    }

    const loadAvailabilityData = () => {
        setIsLoading_AvailabilityRows(true);
        scheduleContext.load(constant.SCHEDULE_TYPE.availability)?.then((result) => {
            setAvailabilityRows(result);
            console.log(result)
            setIsLoading_AvailabilityRows(false);

        })
    };

    const saveAvailabilityRow = (row) => { // need to require days, start/end time
        setIsLoading_ProviderRow(true);
        if (isApplicant) {
            console.log(contactId)
            scheduleContext.saveApplicationSchedule(row, slcRow_Type, contactId)
        }
        else {
            scheduleContext.saveEntity(row, slcRow_Type).then((data) => {
                if (!!data) {
                    setIsLoading_ProviderRow(false);
                    setShow_ProviderRow(false);
                    loadAvailabilityData([slcRow_Type]);
                } else {
                    setIsLoading_ProviderRow(false);
                }

            }
            )
        }

    };

    // handleRowDelete
    const loadAvailabilityEditData = (scheduleId) => {
        if (scheduleId == undefined) return;
        scheduleContext.loadEntity(scheduleId, slcRow_Type, slcRow_Provider)?.then((result) => {
            setRow_Provider(result)
        })
    }

    const handleClose = () => {
        setShow_ProviderRow(false);
        setRow_Provider(defaultRow_Provider);
    };

    useEffect(() => {
        console.log(contactId)
    }, []);

    const handleDeleteClose = (event, reason) => {
        if (reason !== "cancel") {

        }
        setdel(false);
    }

    const handleDeleteOpen = () => {
        setdel(true);
        setAnchorEl(null);
    };

    const handleEnd = (data) => {
        if (global.dateTime.isValidDate(data.endDate) === false)
            return;
        let setIsLoadingCard, url, dataRows, setDataRows;
        url = constant.CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_END;//constant.CLIENT_SCHEDULE_GET_PROFESSIONAL_SCHEDULE_END;
        dataRows = yungermenRows;//professionalRows;
        //setDataRows = setYungermenRows;//setProfessionalRows;
        switch (slcRow_Type) {
            case constant.SCHEDULE_TYPE.yungerman:
                setIsLoadingCard = setIsLoading_YungermenRows;
                break;
            case constant.SCHEDULE_TYPE.professional:
                setIsLoadingCard = setIsLoading_ProfessionalRows;
                break;
            default:
        }
        setIsLoadingCard(true);
        var endDateDT = formatDate(data.endDate);
        let payload = { ...data, endDate: endDateDT }
        var endDateStr = data.endDate;//global.dateTime.getDateTime(data.endDate);
        if (slcRow_Provider.clientScheduleId !== undefined) {
            global.fetchWrapper.post(
                url + slcRow_Provider.clientScheduleId, payload)
                .then((response) => {
                    global.log("save", response);
                    if (response.status == 200) {
                        // update local data
                        let index = dataRows.findIndex((cs) => cs.clientScheduleId == slcRow_Provider.clientScheduleId);
                        dataRows[index] = {
                            ...dataRows[index],
                            endDate: data.endDate,
                            reasonScheduleEnded: data.reasonScheduleEnded,
                            throughout: getThroughoutSting(dataRows[index].startDate, data.endDate),
                        }
                        setYungermenRows(dataRows);
                    } else {
                    }
                    // log and set states back to normal
                    setEnd_ProviderRow(false);
                    setIsLoadingCard(false);
                }).then((error) => {
                    //setIsLoadingCard(false);
                });
        }
        else
            global.log("Can't find client schedule with ID " + slcRow_Provider.clientScheduleId);
    };

    const handleEditOpen = (row, scheType) => {
        if (row.clientScheduleId !== undefined)
            switch (scheType) {
                case constant.SCHEDULE_TYPE.yungerman:
                    loadYungermanEditData(row.clientScheduleId, scheType);
                    break;
                case constant.SCHEDULE_TYPE.professional:
                    loadYungermanEditData(row.clientScheduleId, scheType);
                    break;
                case constant.SCHEDULE_TYPE.appointment:
                    loadAppointmentEditData(row.clientScheduleId, scheType);
                    break;
                case constant.SCHEDULE_TYPE.availability:
                    loadAvailabilityEditData(row.clientScheduleId);
                    break;
                default:
            }
        else
            setRow_Provider(defaultRow_Provider);
        setShow_ProviderRow(true);
        setAnchorEl(null);
    };

    const handleMenuClick = (event, row, menuItems, scheType, isPos = false) => {
        setSlcRow_Type(row.type || scheType);
        setSlcRow_Provider(row);
        if (menuItems)
            setMenu(menuItems.filter(item => ['edit', 'end', 'delete'].includes(item)));
        else return;
        if (isPos) {
            setXPos(event.pageX - 10);
            setYPos(event.pageY - window.pageYOffset)
        } else {
            setXPos("0px");
            setYPos("0px");
        }
        setAnchorEl(event.currentTarget);
    };

    const openNewScheduleModel = (scheduleType) => {
        setSlcRow_Type(scheduleType);
        setSlcRow_Provider(undefined);
        handleEditOpen({}, scheduleType);
    };

    const setSlcRowData = (row, scheType) => {
        setSlcRow_Type(scheType);
        setSlcRow_Provider(row);
    };

    const handleRowClick = (row, scheType) => {
        setSlcRow_Type(scheType);
        setSlcRow_Provider(row);
        handleEditOpen(row, scheType);
    };

    const handleEndOpen = () => {
        var row = slcRow_Provider;
        global.log(row);
        setEndScheduleData({
            endDate: row.endDate,
            reasonScheduleEnded: row.reasonScheduleEnded,
            startDate: row.startDate,
        });
        setEnd_ProviderRow(true);
        setAnchorEl(null);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!isApplicant) {
            loadYungermanData();
            loadAppointmentsData();
            getProfessionalNames().then((data) => {
                setProfessionals(data || [])
            });
        }
        if (isApplicant) {
            loadApplicantData();
        }

        if (exploringType === "employees" && contactId == null) loadAvailabilityData();
    }, [])

    useEffect(() => {
        let yRows = getFilteredRows("yungerman", true, [], ["seeking", null, "onhold"], !!provider ? true : false);
        setFilteredRows_Yungerman(yRows);
    }, [yungermenRows, yungermenRows.length, end_ProviderRow])

    const isScheduleHistory = (row) => {
        return ([constant.SCHEDULE_TYPE.yungerman, constant.SCHEDULE_TYPE.professional].includes(row.type) && row.endDate && global.dateTime.isDatePast(row.endDate)) ||
            (row.type === 'appointment' && global.dateTime.isDatePast(row.startDate))
    }

    const isScheduleActive = (row) => {
        let scheTypeIsAny = [constant.SCHEDULE_TYPE.yungerman, constant.SCHEDULE_TYPE.professional].includes(row.type);
        let scheduleNotEnded = (!row.endDate || !global.dateTime.isDatePast(row.endDate));
        let isYungermanTypeAndIsEndedWithinPast30Days =
            (row.type === constant.SCHEDULE_TYPE.appointment && !global.dateTime.isDatePast(global.dateTime.addDays(row.startDate, 30)));
        return (scheTypeIsAny && scheduleNotEnded) || isYungermanTypeAndIsEndedWithinPast30Days;
    }

    const getFilteredRows = (scheType, excludePast, includeStatuses, excludeStatuses, providerIsSameAsRow, excludeActive) => {
        const filteredRows = yungermenRows
            .sort((a, b) => global.dateTime.getDateObject(b.startDate) - global.dateTime.getDateObject(a.startDate))
            .filter((row) =>
                (!scheType || scheType === row.type)
                && (!excludePast || (isScheduleActive(row)))
                && (!excludeActive || (isScheduleHistory(row)))
                && (!includeStatuses || includeStatuses.length < 1 || (row.status !== undefined && includeStatuses.includes(row.status)))
                && (!excludeStatuses || excludeStatuses.length < 1 || (row.status !== undefined && !excludeStatuses.includes(row.status)))
                && (!(!!provider) || !providerIsSameAsRow || provider?.contact?.name === row.providerName)
            )
        return filteredRows;
    }
    return (
        <React.Fragment>
            <Grid container spacing={0} className={classes.mainPaddingSection}>
                <YungermanModal
                    show={show_ProviderRow && [constant.SCHEDULE_TYPE.yungerman, constant.SCHEDULE_TYPE.professional, constant.SCHEDULE_TYPE.availability].includes(slcRow_Type)}
                    scheduleContext={scheduleContext}
                    handleEdit={handleEditSave}
                    handleClose={handleClose}
                    clientScheduleId={row_Provider != undefined ? row_Provider.clientScheduleId : -1}
                    clientSchedule={row_Provider}
                    isSeekingProvider={!slcRow_Provider || slcRow_Provider.status === 'seeking' || slcRow_Provider.status === 'onhold'}
                    setClientSchedule={setRow_Provider}
                    isLoading={isLoading_ProviderRow}
                    setIsLoading={setIsLoading_ProviderRow}
                    scheduleType={slcRow_Type}
                    client={client}
                    actionPermissions={slcRow_Type === constant.SCHEDULE_TYPE.yungerman ? {
                        ...generalPermissions.actions,
                        ...yungermanPermissions.actions
                    } : slcRow_Type === constant.SCHEDULE_TYPE.professional ? {
                        ...generalPermissions.actions,
                        ...professionalPermissions.actions
                    } : undefined
                    }
                />
                <AppointmentModal
                    show={show_ProviderRow && slcRow_Type === constant.SCHEDULE_TYPE.appointment}
                    handleEdit={handleEditSave}
                    handleClose={handleClose}
                    clientScheduleId={slcRow_Provider != undefined ? slcRow_Provider.clientScheduleId : -1}
                    clientSchedule={row_Provider}
                    providers={professionals}
                    client={client}
                />
                <DeleteModel
                    del={del}
                    loading={isLoading_AvailabilityRows || isLoading_AppointmentRows || isLoading_ProfessionalRows || isLoading_YungermenRows}
                    handleDelete={handleRowDelete}
                    handleDeleteClose={handleDeleteClose}
                    slcRow={slcRow_Provider} />
                <EndSchedule
                    end={end_ProviderRow}
                    data={endScheduleData}
                    setData={setEndScheduleData}
                    handleEnd={handleEnd}
                    handleClose={() => showEndSchedule(false)} />
                <Grid item lg={7} xs={12} className={classes.gridColumn}>
                    {
                        cards.availability &&
                        <Card
                            rows={availabilityRows}
                            isLoading={isLoading_AvailabilityRows}
                            handleMenuClick={handleMenuClick}
                            setEditShow={setShow_ProviderRow}
                            scheType={constant.SCHEDULE_TYPE.availability}
                            openNewModel={openNewScheduleModel}
                            title={cards.availability.title || "Availability Schedule"}
                            columns={cards.availability.columns}
                            actions={cards.availability.actions}
                            actionPermissions={{
                                ...employeePermission,
                                canAdd: employeePermission.canCreate,
                            }}
                        />
                    }
                    {
                        cards.schedules &&
                        <Card
                            rows={filteredRows_Yungerman}
                            isLoading={isLoading_YungermenRows}
                            handleMenuClick={handleMenuClick}
                            setEditShow={setShow_ProviderRow}
                            scheType={constant.SCHEDULE_TYPE.yungerman}
                            openNewModel={openNewScheduleModel}
                            title={cards.schedules.title || "Yungeleit"}
                            headerMessage={client.comfortHealthClientId && <p style={{ paddingTop: '10px' }}>For accurate session timing check in Dragon</p>}
                            columns={cards.schedules.columns}
                            actions={cards.schedules.actions}
                            actionPermissions={{
                                ...generalPermissions.actions,
                                ...yungermanPermissions.actions
                            }}
                        />
                    }
                    {
                        cards.professionals &&
                        <Card
                            rows={getFilteredRows("professional", true, [], ["seeking", null, "onhold"])}
                            isLoading={isLoading_ProfessionalRows}
                            handleMenuClick={handleMenuClick}
                            setEditShow={setShow_ProviderRow}
                            scheType={constant.SCHEDULE_TYPE.professional}
                            openNewModel={openNewScheduleModel}
                            title={cards.professionals.title || "Professional"}
                            columns={cards.professionals.columns}
                            actions={cards.professionals.actions}
                            actionPermissions={{
                                ...generalPermissions.actions,
                                ...professionalPermissions.actions
                            }}
                        />
                    }
                    {
                        cards.appointments &&
                        <Card
                            rows={getFilteredRows(constant.SCHEDULE_TYPE.appointment, true, [], [])}
                            isLoading={isLoading_AppointmentRows}
                            handleMenuClick={handleMenuClick}
                            setEditShow={setShow_ProviderRow}
                            setSlcRow_Type={setSlcRow_Type}
                            scheType={constant.SCHEDULE_TYPE.appointment}
                            columns={cards.appointments.columns}
                            title={cards?.appointment?.title || "Appointment"}
                            actions={cards.appointments.actions}
                            actionPermissions={{
                                ...generalPermissions.actions,
                                ...appointmentPermissions.actions
                            }}
                        />
                    }
                </Grid>
                <Grid item lg={5} xs={12}>
                    {
                        cards.seeking &&
                        <Card
                            yungermanRows={yungermenRows}
                            professionalRows={professionalRows}
                            isLoading={isLoading_YungermenRows}
                            handleRowClick={handleRowClick}
                            handleDeleteOpen={handleDeleteOpen}
                            setSlcRowData={setSlcRowData}
                            title={cards.seeking.title}
                            columns={cards.seeking.columns}
                            actions={cards.seeking.actions}
                            cardType="seeking"
                            clickableRows={true}
                            actionPermissions={generalPermissions.actions}
                            yungermanActionPermissions={{
                                ...generalPermissions.actions,
                                ...yungermanPermissions.actions
                            }}
                            professionalActionPermissions={{
                                ...generalPermissions.actions,
                                ...professionalPermissions.actions
                            }}
                        />
                    }
                    {
                        cards.potentials &&
                        <Card
                            rows={getFilteredRows("", true, ["seeking"], [], false)}
                            yungermanRows={yungermenRows.sort((row) => row.endDate)}
                            professionalRows={professionalRows}
                            isLoading={isLoading_YungermenRows}
                            handleRowClick={handleRowClick}
                            handleDeleteOpen={handleDeleteOpen}
                            setSlcRowData={setSlcRowData}
                            title={cards.potentials.title}
                            columns={cards.potentials.columns}
                            actions={cards.potentials.actions}
                        />
                    }
                    {
                        cards.history &&
                        <Card
                            rows={getFilteredRows(undefined, false, [], [], false, true)}
                            handleMenuClick={handleMenuClick}
                            scheType={slcRow_Type}
                            setEditShow={setShow_ProviderRow}
                            setSlcRow_Type={setSlcRow_Type}
                            professionalHistoryRows={professionalHistoryRows}
                            setProfessionalHistoryRows={setProfessionalHistoryRows}
                            appointmentHistoryRows={appointmentHistoryRows}
                            setAppointmentHistoryRows={setAppointmentHistoryRows}
                            title={cards.history.title}
                            cardType="history"
                            row={row_Provider}
                            setRow={setRow_Provider}
                            isLoading={isLoading_YungermenRows}
                            types={cards.history.types}
                            columns={cards.history.columns[cards.history.quickFilters.selectedValue]}
                            actions={cards.history.actions}
                            quickFilters={cards.history.quickFilters}
                            actionPermissions={{
                                ...generalPermissions.actions,
                                ...yungermanPermissions.actions
                            }}
                            professionalActionPermissions={{
                                ...generalPermissions.actions,
                                ...professionalPermissions.actions
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    {props.isEmployee && <NetAvailability availabilityRows={[...availabilityRows]} schedules={[...filteredRows_Yungerman]} />}
                </Grid>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleCloseMenu}
                    sx={{
                        top: yPos,
                        //bottom: yPos,
                        left: xPos,
                        //inset: "250px, 0px, 0px, 877px"
                    }}
                    PopoverClasses={"paper"}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                        },
                    }}
                >
                    {menu.includes("edit") ? <MenuItem onClick={(event) => {
                        handleEditOpen(slcRow_Provider, slcRow_Type);
                        event.stopPropagation();
                    }}>Edit </MenuItem> : null}
                    {menu.includes("end") ? <MenuItem onClick={handleEndOpen}>End Schedule </MenuItem> : null}
                    {menu.includes("delete") ? <MenuItem className={classes.colorDel} onClick={handleDeleteOpen}>Delete{" "}</MenuItem> : null}
                </Menu>
            </Grid>
        </React.Fragment>
    );
}
