import { AppBar, IconButton, useMediaQuery } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Badge from '@mui/material/Badge';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from "easy-peasy";
import { ListItemIcon, ListItemText } from '@mui/material';
import { CropSquareSharp } from '@mui/icons-material';

import { globalSearch } from '../../api/search'
import * as constants from '../../constants';
import '../NavMenu.css';
import { accountService } from '../../_services/account.service';
import Logo from '../common/LogoSvg'
import ChangePassword from "../../screens/users/ChangePassword";
import useDebounce from '../../hooks/useDebounce';
import AnimLoadingState from '../common/AnimLoadingState';
import NotificationIcons from '../common/NotificationIcons';
import NotificationStatus from '../common/NotificationStatus';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flex: 1
    },
    appbarColor: {
        backgroundColor: `${theme.palette.primary.main} !important`
    },
    appBar: {
        zIndex: `${theme.zIndex.drawer + 1} !important`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '& .MuiDialog-container': {
            padding: '50px !important',
        }
    },
    hide: {
        display: 'none !important'
    },
    searchDialog: {
        width: '511px',
        border: "1px solid #cbd4db",
        borderRadius: "16px",
    },
    search: {
        position: 'relative',
        borderRadius: "16px",
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
            // '&:focus': {
            //     width: '511px',
            // },
        },
    },
    appBarShift: {
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${240}px) !important`,
            marginLeft: 240,
        },
        [theme.breakpoints.down('lg')]: {
            width: `calc(100% - ${140}px) !important`,
            marginLeft: 140,
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    dialog_table: {
        padding: '50px !important',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    person: {
        color: "#a9a9a9"
    },
    person_icon: {
        paddingRight: "8px",
        width: "2rem",
    },
    accountPopOver: {
        padding: "4px",

    },
    iconModal: {
        marginLeft: "12px",
        marginRight: "12px",
        color: "#cbd4db"
    },
    increaseWidth: {
        width: '511px !important',
    },
    iconColor: {
        opacity: 0.35,
    },
    accountPopup: {
        width: 250,
    },
    name: {
        fontSize: "1.75rem",
        paddingLeft: "8px",
    },
    autoCompleteClass: {
        padding: '2px 10px !important',
        '&.Mui-focused': {
            width: '511px',
        }

    },
    notificationContainer: {
        maxHeight: "300px !important",
        overflowY: "scroll",
        "&.MuiList-padding": {
            padding: 'unset !important'
        }
    },
    notificationTime: {
        position: 'absolute',
        bottom: "2px",
        left: "40px",
        "&.MuiTypography-body1": {
            fontSize: "8px !important",

        },
        "& .css-821wyw-MuiSvgIcon-root": {
            fontSize: "8px !important",
            marginRight: "3px"
        }
    },
    notificationMenuitem: {
        "&.MuiMenuItem-root": {
            boxShadow: "0px 2px #eee",
            padding: "10px 16px",

        },
        "& .MuiTypography-body1": {
            minWidth: "110px",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        "& .css-ptiqhd-MuiSvgIcon-root": {
            width: "unset"
        },
        "& .css-tlelie-MuiListItemText-root": {
            marginLeft: "8px"
        },
        "& .css-cveggr-MuiListItemIcon-root": {
            minWidth: 'unset !important'
        },
    },
    crossIcon: {
        fontSize: "12px"
    },
    optionTitle: {
        color: 'gray',
        fontSize: "12px"
    },
    emailText: {
        margin: "0px",
        paddingLeft: "8px",
        color: theme.palette.primary.main,
        paddingBottom: "16px",
    },
    logout: {
        fontSize: "1rem",
        padding: "16px",
        color: theme.palette.primary.main
    },
    rowModal: {
        display: 'flex',
        flexWrap: 'nowrap',
        marginBottom: "16px",
    },
    buttons: {
        display: 'flex'
    },
    buttons_1: {
        flex: '1'
    },
    typography: {
        fontSize: "12px",
        lineHeight: "1",
        textTransform: "capitalize",
    },
    selectedButton: {
        background: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff !important"
    },
    /*#14aaf5*/
    buttons_attachment: {
        width: "60%",
    },
    tabOfModal: {
        marginTop: "16px",
        marginBottom: "12px"
    },
    modal_title_button: {
        width: '16.5%',
        fontSize: "12px",
        textTransform: "capitalize",
        marginTop: "10px"
    },
    modal_title: {
        marginTop: "12px",
        fontSize: "12px",
        textTransform: "capitalize",
        width: "20%"
    },
    span_dialog: {
        flex: 1,
        margin: "8px"
    },
    logo_img: {
        height: 40,
        width: "100%"
    }
}));

const filter = createFilterOptions();

export default function NavMenu(props) {
    const classes = useStyles();
    const theme = useTheme();
    let open = props.open;
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchLoading, setSearchLoading] = React.useState(false)
    const [popOverFlag, setOpen] = React.useState(false);
    const [seachFocus, setSearchFocus] = React.useState(false);
    const [popOverAccountFlag, setOpenAccount] = React.useState(false);
    const anchorRef = React.useRef(null);
    const anchorRefAccount = React.useRef(null);
    const [openModal, setModalOpen] = React.useState(false);
    const [openTableModal, setTableModalOpen] = React.useState(false);
    const [alignment, setAlignment] = React.useState('left');
    const [attachments, setAttachment] = React.useState('left');
    const [completion, setCompletion] = React.useState('left');
    const history = useStoreState((state) => state.history);
    const userNotifications = useStoreState((state) => state.users.userNotifications);
    const updateUserNotification = useStoreActions((actions) => actions.users.updateUserNotification);
    const setClientDetailTab = useStoreActions((actions) => actions.clients.setActiveTab);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const isLoading = useStoreState((state => state.users.isLoading));
    const [programsTable, setProgramTable] = React.useState(false);
    const [changePasswordModalVisible, setChangePasswordModalVisible] = React.useState(false);
    const [searchResult, setSearchResult] = React.useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const navigate = useNavigate();

    const debounceValue = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debounceValue?.length) {
            setSearchLoading(true);
            globalSearch(debounceValue).then((data) => {
                let updatedData = [];
                updatedData = data.map((row) => ({ ...row, title: `${row.firstName + " " + row.lastName}(${row.type})`, icon: <PersonIcon /> }))
                //updatedData.push({
                //    inputValue: debounceValue,
                //    title: `Search the L'\Hisalos Guide for "${debounceValue}"`,
                //    icon: <SearchIcon />
                //});
                //updatedData.push({
                //    inputValue: debounceValue,
                //    title: `View all results "${debounceValue}"`,
                //    icon: <SearchIcon />
                //});
                setSearchResult(updatedData)
                setSearchLoading(false);
            })
        }
    }, [debounceValue])

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleOpenTableModal = () => {
        setTableModalOpen(true);
    }

    const handleCloseTableModal = () => {
        setTableModalOpen(false);
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCompletion = (event, newCompletion) => {
        if (newCompletion !== null) {
            setCompletion(newCompletion);
        }
    }

    const handleAttachment = (event, newAttachment) => {
        if (newAttachment !== null) {
            setAttachment(newAttachment);
        }
    }

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const searchAutocompleteFields = [
        { title: 'Recently completed task', id: 1, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve assigned to others', id: 2, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve created', id: 3, icon: <NotificationsOutlinedIcon /> },
        { title: 'Recently completed task', id: 12, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve assigned to others', id: 22, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve created', id: 32, icon: <NotificationsOutlinedIcon /> },
        { title: 'Recently completed task', id: 13, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve assigned to others', id: 23, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve created', id: 33, icon: <NotificationsOutlinedIcon /> },
        { title: 'Recently completed task', id: 154, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve assigned to others', id: 25345, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve created', id: 3543, icon: <NotificationsOutlinedIcon /> },
        { title: 'Recently completed task', id: 153452, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve assigned to others', id: 224535, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve created', id: 334552, icon: <NotificationsOutlinedIcon /> },
        { title: 'Recently completed task', id: 45313, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve assigned to others', id: 3523, icon: <PersonIcon /> },
        { title: 'Tasks I\'ve created', id: 353533, icon: <NotificationsOutlinedIcon /> },
    ];

    const [value, setValue] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const [dialogValue, setDialogValue] = React.useState({
        title: '',
        year: '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setValue({
            title: dialogValue.title,
            year: parseInt(dialogValue.year, 10),
        });

        handleClose();
    };

    const handleToggle = () => {
        return;
        setOpen((prevOpen) => !prevOpen);
    };

    const handleToggleAccount = () => {
        setOpenAccount((preAccount) => !preAccount);
    }

    const signOut = () => {
        setLoading()
        accountService.logout()
    }
    /**
     * Need to handle when clicking to the notification
     * @void
     * @param {object} notification - notification object
     * 
     */
    const handleNotification = (notification) => {
        // TODO: handle Notification

        // we need to fetch id and route from notification; 
        history("client/details/2343");
        setClientDetailTab("todo")
    }

    /**
     * Dispatch action to remove notification
     * @void
     * @param {*} id - id of the notification to remove or discard
     * 
     */
    const removeNotification = (id) => {
        updateUserNotification({ userId: id, notificationId: id })
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        if (anchorRefAccount.current && anchorRefAccount.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        setOpenAccount(false);
    }

    function handleListKeyDown(event) {
        if (event.key === constants.TAB) {
            event.preventDefault();
            setOpen(false);
            setOpenAccount(false);
        }
    }

    const _handleKeyDown = (event) => {
        if (event.key === constants.ENTER) {
            global.log('event.key', event.key);
            handleOpenTableModal();
        } else {
            return;
        }
    }


    const handleSearch = (query) => {
        global.log('delays');
        let timerID;
        const WAIT_INTERVAL = 1000;
        clearTimeout(timerID);

        timerID = setTimeout(() => {
            global.log('some action after delay')
        }, WAIT_INTERVAL);
    }


    const prevOpen = React.useRef(popOverFlag);
    const preAccount = React.useRef(popOverAccountFlag);
    React.useEffect(() => {
        if (prevOpen.current === true && popOverFlag === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = popOverFlag;

    }, [popOverFlag]);

    React.useEffect(() => {
        if (preAccount.current === true && popOverFlag === false) {
            anchorRefAccount.current.focus();
        }
        preAccount.current = popOverAccountFlag;
    }, [popOverAccountFlag]);

    const handleStateOfProps = () => {
        setProgramTable(false);
    }

    const handleNavigation = (primaryId, type) => {
        if (type?.toLowerCase() === 'user') {
            navigate(`users`);
            return;
        }
        navigate(`${type?.toLowerCase()}/${primaryId}/details`);
        setSearchTerm('')
        setSearchResult([])
    }
    return (
        <ThemeProvider theme={theme}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                }, classes.appbarColor)}
            //sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.onClick}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}>
                        <MenuIcon />
                    </IconButton>
                    <NavLink to="/" style={{
                        textDecoration: 'none',
                        color: "white"
                    }} key="0">
                        <div className={classes.logo}>
                            <Logo className={classes.logo_img} />
                            {/* <img className={classes.logo_img} src={logo} /> */}
                        </div>
                    </NavLink>
                    <div className={classes.grow}></div>
                    <div className={classes.search} >

                        <Autocomplete
                            className={clsx(classes.autoCompleteClass)}
                            loading={searchLoading}
                            value={searchTerm}
                            onChange={(event, newValue) => {
                                if (newValue && newValue.primaryId) {
                                    handleNavigation(newValue.primaryId, newValue.type);
                                } else if (newValue && newValue.inputValue) {
                                    // handle when input value
                                }
                                // TODO: Commenting For as we are not using models here may we need this code later not
                                // if (typeof newValue === 'string') {
                                //     // timeout to avoid instant validation of the dialog's form.
                                //     setTimeout(() => {
                                //         setDialogValue({
                                //             title: newValue,
                                //             id: '',
                                //         });
                                //     });
                                // } else if (newValue && newValue.inputValue) {
                                //     /* toggleOpen(true);*/
                                //     setDialogValue({
                                //         title: newValue.inputValue,
                                //         id: '',
                                //     });
                                // } else {
                                //     setValue(newValue);
                                // }
                                // if (newValue && newValue.id === 0) {
                                //     handleOpenTableModal();
                                // } else {
                                //     handleOpenTableModal();
                                //     // history(constants.SEARCH_ROUTER);
                                // }
                            }}

                            // filterOptions={(options, params) => {
                            //     const filtered = filter(options, params);

                            //     if (params.inputValue !== '') {
                            //         filtered.push({
                            //             inputValue: params.inputValue,
                            //             title: `Search the L'\Hisalos Guide for "${params.inputValue}"`,
                            //             icon: <SearchIcon />
                            //         });
                            //         filtered.push({
                            //             inputValue: params.inputValue,
                            //             title: `View all results "${params.inputValue}"`,
                            //             icon: <SearchIcon />
                            //         });
                            //     }

                            //     return filtered.reverse();
                            // }}
                            id="free-solo-dialog-demo"
                            options={searchResult}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.title;
                            }
                            }
                            /*selectOnFocus*/
                            clearOnBlur
                            /*groupBy={(option) => option.id}*/
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                                <div {...props}>
                                    {option.icon} <span className={classes.optionTitle}>{option.title}</span>
                                </div>
                            )}
                            freeSolo
                            renderInput={(params) => (
                                <InputBase
                                    startAdornment={<SearchIcon className={classes.iconColor} />}
                                    ref={params.InputProps.ref}
                                    placeholder="Search"
                                    inputProps={params.inputProps}
                                    onKeyPress={_handleKeyDown}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    /*autoFocus*/
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    onBlur={() => {
                                        setSearchTerm("");
                                        setSearchResult([])
                                    }}
                                    fullWidth
                                />
                            )}
                        />
                        {/* <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                            <form>
                                <DialogContent>
                                    <div className={classes.searchDialog}>
                                        <div className={classes.searchIconDialog}>
                                            <SearchIcon className={classes.iconModal} />
                                            <InputBase
                                                placeholder="Contains the words"
                                                classes={{
                                                    root: classes.inputDialogRoot,
                                                    input: classes.inputDialogInput,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.tabOfModal}>
                                        <ToggleButtonGroup
                                            value={alignment}
                                            exclusive
                                            onChange={handleAlignment}
                                            className={classes.buttons}
                                            aria-label="text alignment">
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="left" aria-label="left aligned">
                                                <Typography className={classes.typography}> All </Typography>
                                            </ToggleButton>
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="center" aria-label="centered">
                                                <Typography className={classes.typography}> Tasks </Typography>
                                            </ToggleButton>
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="right" aria-label="right aligned">
                                                <Typography className={classes.typography}> Conversations </Typography>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    <div className={classes.rowModal}>
                                        <Typography className={classes.modal_title}>Assigned to</Typography>
                                        <div className={classes.grow}></div>
                                        <OutlinedInput
                                            autoFocus
                                            margin="dense"
                                            className={classes.input_row}
                                            id="name"
                                            type="text"
                                            variant="outline"
                                            fullWidth
                                        />
                                    </div>
                                    <div className={classes.rowModal}>
                                        <Typography className={classes.modal_title}>In projects</Typography>
                                        <div className={classes.grow}></div>
                                        <OutlinedInput
                                            autoFocus
                                            margin="dense"
                                            className={classes.input_row}
                                            id="name"
                                            type="text"
                                            variant="outline"
                                            fullWidth
                                        />
                                    </div>
                                    <div className={classes.rowModal}>
                                        <Typography className={classes.modal_title}>Collaborators</Typography>
                                        <div className={classes.grow}></div>
                                        <OutlinedInput
                                            autoFocus
                                            margin="dense"
                                            className={classes.input_row}
                                            id="name"
                                            type="text"
                                            variant="outline"
                                            fullWidth
                                        />
                                    </div>
                                    <div className={classes.rowModal}>
                                        <Typography className={classes.modal_title_button}>Attachments</Typography>

                                        <ToggleButtonGroup
                                            value={attachments}
                                            exclusive
                                            onChange={handleAttachment}
                                            className={classes.buttons_attachment}
                                            aria-label="text alignment">
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="left" aria-label="left aligned">
                                                <Typography className={classes.typography}> Has attachments </Typography>
                                            </ToggleButton>
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="center" aria-label="centered">
                                                <Typography className={classes.typography}> Any </Typography>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    <div className={classes.rowModal}>
                                        <Typography className={classes.modal_title_button}>Completion</Typography>

                                        <ToggleButtonGroup
                                            value={completion}
                                            exclusive
                                            onChange={handleCompletion}
                                            className={classes.buttons_attachment}
                                            aria-label="text alignment">
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="left" aria-label="left aligned">
                                                <Typography className={classes.typography}> Completed </Typography>
                                            </ToggleButton>
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="center" aria-label="centered">
                                                <Typography className={classes.typography}> Incomplete </Typography>
                                            </ToggleButton>
                                            <ToggleButton classes={{ root: classes.buttons_1, selected: classes.selectedButton }} value="right" aria-label="centered">
                                                <Typography className={classes.typography}> Any </Typography>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseModal} color="primary">
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog> */}
                        {/* Table Data */}
                        {/* <Dialog open={openTableModal}
                            fullScreen={fullScreen}
                            className={classes.dialog_table}
                            onClose={handleCloseTableModal}
                            aria-labelledby="form-dialog-title">
                            <DialogContent>
                                <Search click={handleCloseTableModal} setprops={handleStateOfProps} programsProps={programsTable} searchString={dialogValue.title} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseTableModal} color="primary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog> */}
                    </div>
                    <IconButton color="inherit" onClick={handleToggleAccount} ref={anchorRefAccount}
                        aria-controls={popOverAccountFlag ? 'menu-list-grow' : undefined}
                        aria-haspopup="true">
                        <Badge badgeContent={0} color="primary">
                            <h6>{accountService.getUserDisplayName()}</h6>
                        </Badge>
                    </IconButton>
                    <Popper open={popOverAccountFlag} anchorEl={anchorRefAccount.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <div className={classes.accountPopup}>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList className={classes.accountPopOver} autoFocusItem={popOverAccountFlag} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                <MenuItem
                                                    onClick={() => {
                                                        setChangePasswordModalVisible(true)
                                                        setOpenAccount(false)
                                                    }}
                                                    className={classes.person}>Change Password
                                                </MenuItem>
                                                <MenuItem onClick={signOut} className={classes.person}>Log Out </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </div>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <IconButton color="inherit"
                        onClick={handleToggle} ref={anchorRef}
                        aria-controls={popOverFlag ? 'menu-list-grow' : undefined}
                        aria-haspopup="true">
                        <Badge badgeContent={0 /*userNotifications?.length*/} color="primary">
                            <NotificationsOutlinedIcon className={classes.iconColor} />
                        </Badge>
                    </IconButton>
                    <Popper open={popOverFlag} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList style={{ minWidth: '200px' }} className={classes.notificationContainer} autoFocusItem={popOverFlag} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            <AnimLoadingState loading={isLoading} color="linear-gradient(to right, #4fabf5 8%, #4fabf5 18%, #87c0ef 31%)" />
                                            {
                                                userNotifications.map((item) => {
                                                    return (
                                                        <MenuItem key={item.id} className={classes.notificationMenuitem} onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleNotification(item);
                                                        }
                                                        }>

                                                            <ListItemIcon>
                                                                <NotificationIcons type={item.type} />
                                                            </ListItemIcon>
                                                            <ListItemText variant="inherit">
                                                                {item.title}
                                                            </ListItemText>
                                                            <NotificationStatus status={item.status} />
                                                            <CropSquareSharp className={classes.crossIcon} fontSize="8" onClick={(e) => {
                                                                e.stopPropagation();
                                                                removeNotification(item.id)
                                                            }
                                                            } />
                                                            <Typography className={classes.notificationTime}>
                                                                <AccessTimeIcon style={{ color: '#ddd' }} fontSize="4" />
                                                                {item.time}
                                                            </Typography>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    {changePasswordModalVisible ? <ChangePassword userId={global.account.userValue.id} onClose={() => setChangePasswordModalVisible(false)} /> : null}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

