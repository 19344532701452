import React, { useEffect, useState } from 'react'
import { Grid, FormControl, TextField, MenuItem, Link, Button, ToggleButtonGroup, ToggleButton, Typography, Paper, } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as constant from "../../../../constants";
import ToggleDays from '../../../../components/ToggleDays/ToggleDays';
import ToggleMonthDays from '../../../../components/ToggleMonthDays/ToggleMonthDays';
import ProtectedComponent from '../../../../components/ProtectedComponent/ProtectedComponent';
import CusTimePicker from '../../../../components/DateTimePickers/TimePicker/TimePicker';
import UniDatePicker from '../../../../components/common/UniDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { modalFields, getDaysArrayFromDaysString } from '../../../../_helpers/schedule';
import { validateRatePeriod, validateRate } from '../../../../_helpers/schedule/validators/client-schedule';
import useStyles from '../../../contacts/styles';
import AddIcon from '@mui/icons-material/Add';
import { width } from '@mui/system';
import { periodicities } from '../../../../_helpers/constants/scheduleConstants';



const ApplicationScheduleForm = ({ setScheduleData, defaultScheduleData = [] }) => {
    const [schedules, setSchedules] = useState(
        defaultScheduleData.length > 0
            ? defaultScheduleData.map(schedule => ({
                startTime: schedule.startTime || '',
                endTime: schedule.endTime || '',
                days: schedule.days2 || [],
                periodicity: "WEEKS",
                EveryXOfPeriods: 1
            }))
            : [{ startTime: '', endTime: '', days: [], periodicity: "WEEKS", EveryXOfPeriods: 1 }]
    );

    const [fieldErrors, setFieldErrors] = useState(
        schedules.map(() => ({
            startTime: null,
            endTime: null,
            days: null
        }))
    );

    useEffect(() => {
        const hasValidSchedule = schedules.some(schedule =>
            schedule.startTime !== '' &&
            schedule.endTime !== '' &&
            schedule.days.length > 0
        );

        setIsValid(hasValidSchedule);
    }, [schedules]);

    const [isValid, setIsValid] = useState(false);

    const handleDayChange = (index, newDays) => {
        const updatedSchedules = [...schedules];
        updatedSchedules[index].days = newDays;
        setSchedules(updatedSchedules);
        validateAndSend(updatedSchedules);
    };

    const handleTimeChange = (index, field, newValue) => {
        console.log('Original Value:', newValue);

        const easternTime = new Date(newValue).toLocaleString('en-US', {
            timeZone: 'America/New_York',
            hour12: false,
        });

        const updatedSchedules = [...schedules];
        updatedSchedules[index][field] = easternTime;
        setSchedules(updatedSchedules);

        validateAndSend(updatedSchedules);
    };

    const validateAndSend = (updatedSchedules) => {
        const isComplete = updatedSchedules.every(schedule =>
            schedule.startTime !== "" &&
            schedule.endTime !== "" &&
            schedule.days.length > 0
        );

        setIsValid(isComplete);

        if (isComplete) {
            const formattedSchedules = updatedSchedules.map(schedule => ({
                ...schedule,
                days: schedule.days.join(','),
                days2: schedule.days,
                periodicity: "WEEKS", EveryXOfPeriods: 1
            }));

            setScheduleData(formattedSchedules);
        }
    };
    const handleAddSchedule = () => {
        if (isValid) {
            setSchedules([...schedules, { startTime: '', endTime: '', days: [] }]);
            setIsValid(false);
        }
    };


    return (
        <div

            style={{
                maxWidth: '700px',
                width: '100%',

            }}
        >
            <div style={{

                padding: '10px',
                justifyContent: 'space-between',
                paddingBottom: '40px',
            }}>
                <Typography variant="h4">Schedule</Typography>
            </div>

            <div
            >
                {schedules.map((schedule, index) => (
                    <Paper
                        key={index}
                        elevation={0}
                        style={{
                            alignItems: 'center',
                            padding: 10,
                            marginBottom: '20px',

                        }}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                        }}>
                            <CusTimePicker
                                label="Time From"
                                inputVariant="outlined"
                                size="small"
                                value={schedule.startTime}
                                onChange={(newValue) => handleTimeChange(index, 'startTime', newValue)}
                                style={{ flex: 1, width: '170px' }}
                            />
                            <CusTimePicker
                                label="Time To"
                                inputVariant="outlined"
                                size="small"
                                value={schedule.endTime}
                                onChange={(newValue) => handleTimeChange(index, 'endTime', newValue)}
                                style={{ flex: 1, width: '170px' }}
                            />
                            <ToggleDays
                                days={schedule.days}
                                setDays={(newDays) => handleDayChange(index, newDays)}
                                showRadioDaysOfWeek={false}
                                style={{ flex: 1 }}
                            />
                        </div>
                    </Paper>
                ))}
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
            }}>
                <Button
                    variant="text"
                    color="primary"
                    onClick={handleAddSchedule}
                    disabled={!isValid}
                    endIcon={<AddIcon />}
                >
                    Add
                </Button>
            </div>
        </div>
    );
};

export default ApplicationScheduleForm;
