import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    root: {
      flex: 1,
      width: "70%",
      // marginTop: "12px",
      marginLeft: "15%",
      // backgroundColor: theme.palette.background.paper,
      "& .MuiInputLabel-animated": {
        fontSize: "14px",
      },
    },
    header_1: {
      display: "flex",
    },
    title: {
      flex: 1,
      fontSize: "18px",
    },
    date: {
      flex: 1,
      color: "#c3c3c3",
      marginTop: "6px",
      textAlign: "right",
      fontSize: 12,
    },
    editIcon: {
      margin: "18px",
      cursor: "pointer",
      padding: "2px",
    },
    
    save: {
      textAlign: "right",
      marginBottom: "12px",
      padding: "8px 12px",
    },
    cardActions: {
      float: "right"
    },
    personalEditPanel: {
      padding: "10px 20px",
      FormControl: {
        margin: "40px",
      },
      "& FormControl": {
        margin: "40px",
      },
      "& .MuiGrid-item": {
        padding: "20px !important",
      },
    },
}));