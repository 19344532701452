import React, { useEffect, useState, useRef } from "react";
import {
    Dialog,
    DialogActions, DialogTitle, FormControl,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { Card, CardContent, CardHeader } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useTheme } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import PropTypes from "prop-types";
import * as constants from "../../constants";
import UploadFile from "../../components/UploadFile/UploadFile";
import useUnsavedChangesWarn from "../../hooks/useUnsavedChangesWarn";
import TInputNumber from "../../components/UI/TInputNumber";
import { getEmployeeTemplateDocuments } from '../../api/employees'
import { fetchFileInfos, saveFile } from "../../api/cms";
import TextInputWrapper from "../../utils/TextInputWrapper";
import useStyles from "./programStyles";
import baseStyles from '../../assets/styles';
import { CompSelectbox } from './employee/components'
import { format } from 'date-fns';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

const CompTextlineInput = (props) => {
    const baseClasses = baseStyles()
    const { label, name, dollar, onChange, labelPlacement, InputProps, type, employeeDetail, handleSetEmployeeDetail, handleSaveEnable } = props;
    const [value, setValue] = React.useState(employeeDetail[name]);

    return (
        <Grid
            item
            xs={12}
            className={baseClasses.document_upload}
        >
            <FormControl className="w-100">
                <TextInputWrapper
                    {...props}
                    id={props.id}
                    type={type ? type : dollar ? "number" : "text"}
                    onBlur={(event) => {
                        handleSetEmployeeDetail(event, name)
                        handleSaveEnable(true);
                    }}
                    onChange={(event) => {
                        onChange(event);
                        setValue(event.target.value)
                    }}
                    name={name}
                    value={!global.isEmpty(value) ? value : dollar ? 0 : ""}
                    // className={classes.hoursWidget}
                    InputProps={
                        InputProps ? InputProps :
                            !dollar ? {} : {
                                startAdornment: (<InputAdornment position="start"> $</InputAdornment>)
                            }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={label}
                    labelPlacement={labelPlacement || "start"}
                />
            </FormControl>
        </Grid>
    );
};

const SectionRightCard = ({ title, children, selectedItem }) => {
    const classes = useStyles();
    return (
        <div>
            {title === selectedItem && (
                <Card
                    variant="outlined"
                    className={classes.contentOfaccordion}>
                    {children}
                </Card>
            )}
        </div>
    );
};

const SectionRightCardBtn = (props) => {
    const baseClasses = baseStyles();
    const { onSaveRightCard, isSaveEnable } = props;
    return (
        <Button
            onClick={onSaveRightCard}
            className={baseClasses.capitalized}
            color="primary"
            variant="contained"
            disabled={!isSaveEnable}
            style={{ marginLeft: "auto" }}
        >
            Save
        </Button>
    );
};

export default function Enrollment(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [value, setValue] = React.useState("comfort_health");
    const [view, setView] = React.useState(true);
    const [parent_view, setParentView] = React.useState(true);
    const [education, setEducation] = React.useState(true);
    const [information, setInformation] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState("Screened");
    const [selectedItemNext, setSelectedItemNext] = React.useState(null);
    const [isSaveEnable, setIsSaveEnable] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const exploringId = useStoreState((state) => state.exploringId);
    const employeeDetail = useStoreState((state) => state.employee.detail);
    const setEmployeeDetail = useStoreActions((actions) => actions.employee.setDetail);
    const [employeeDetail_Local, setEmployeeDetail_Local] = React.useState(useStoreState((state) => state.employee.detail));
    const [canEdit, setCanEdit] = React.useState(false);
    const [fileTemplates, setFileTemplates] = React.useState({});
    const [
        completedOnCheckboxField,
        setcompletedOnCheckboxField,
    ] = React.useState({
        sentToComfortHealthOn: false,
        fingerprintedOn: false,
        comfortHealthApprovedOn: false,
        catsApprovedOn: false,
        prevocApprovedOn: false,
        dragonProfileCreatedOn: false,
    });

    const [tempSaveData, setTempSaveData] = React.useState({
        employeeDetail: employeeDetail,
        completedOnCheckboxField: completedOnCheckboxField
    });
    const [selectedScreenedDate, setSelectedScreenedDate] = useState(employeeDetail.screenedOn);
    const [screenedByName, setScreenedByName] = useState(employeeDetail.screenedByName);
    const [interviewedByName, setInterviewedByName] = useState(employeeDetail.interviewedByName);
    const [screeningNotes, setScreeningNotes] = useState(employeeDetail.screeningNotes);

    const [selectedInterviewDate, setSelectedInterviewDate] = useState(employeeDetail.interviewedOn);
    // const [interviewedByName, setInterviewedByName] = useState(employeeDetail.interviewedByName);
    const [interviewNotes, setInterviewNotes] = useState(employeeDetail.interviewNotes);


    const { setRollbackAction } = props;
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    const handleListItemClick = (item) => {
        if (onBeforeSelect && !onBeforeSelect()) {
            return;
        }
        setIsSaveEnable(false);
        setSelectedItem(item);
        setTempSaveData({
            employeeDetail: employeeDetail,
            completedOnCheckboxField: completedOnCheckboxField
        });
        return;
        if (isSaveEnable) {
            setSelectedItemNext(item);
            setOpenConfirm(true);
        }
        if (!isSaveEnable) {
            setIsSaveEnable(false);
            setSelectedItem(item);
            setTempSaveData({
                employeeDetail: employeeDetail,
                completedOnCheckboxField: completedOnCheckboxField
            });
        }
    };

    const handleScreenedByNameChange = (event) => {
        const { value } = event.target;
        setEmployeeDetail_Local(prevState => ({
            ...prevState,
            screenedByName: value
        }));
    };

    const handleInterviewedByNameChange = (event) => {
        const { value } = event.target;
        setEmployeeDetail_Local(prevState => ({
            ...prevState,
            interviewedByName: value
        }));
    };

    useEffect(() => {
        let roles = ["admin", "financial", "intake", "menahel", "office"];
        if (global.roleContain(roles)) {
            setCanEdit(true);
        }
        else {
            setCanEdit(false);
        }
    }, [global.userRole]);
    useEffect(() => {
        if (global.isEmpty(exploringId)) return;
        setcompletedOnCheckboxField({
            'sentToComfortHealthOn': initcompletedOnCheckboxField(employeeDetail_Local, "sentToComfortHealthOn"),
            'fingerprintedOn': initcompletedOnCheckboxField(employeeDetail_Local, "fingerprintedOn"),
            'comfortHealthApprovedOn': initcompletedOnCheckboxField(employeeDetail_Local, "comfortHealthApprovedOn"),
            'catsApprovedOn': initcompletedOnCheckboxField(employeeDetail_Local, "catsApprovedOn"),
            'prevocApprovedOn': initcompletedOnCheckboxField(employeeDetail_Local, "prevocApprovedOn"),
            'dragonProfileCreatedOn': initcompletedOnCheckboxField(employeeDetail_Local, "dragonProfileCreatedOn"),

        });
    }, [employeeDetail_Local]);

    const initcompletedOnCheckboxField = (objects, objectName, name) => {
        if (global.isEmpty(objects[objectName]))
            return false;
        return true;
    };

    const handlecompletedOnCheckboxField = (programType, name) => {
        if (completedOnCheckboxField[name] == false) {
            setcompletedOnCheckboxField({
                ...completedOnCheckboxField,
                [name]: true,
            });
            setEmployeeDetail_Local({
                ...employeeDetail_Local,
                [name]: global._DateTimeFieldDB(new Date()),
            });
        } else {
            setcompletedOnCheckboxField({
                ...completedOnCheckboxField,
                [name]: false,
            });
            setEmployeeDetail_Local({
                ...employeeDetail_Local,
                [name]: null,
            });
        }
    };

    const handleFields = (programType, event) => {
        const { name, value, type } = event.target;

        setIsSaveEnable(true);

        if (type === "checkbox") {
            setEmployeeDetail_Local({
                ...employeeDetail_Local,
                [name]: event.target.checked,
            });
        } else {
            setEmployeeDetail_Local({ ...employeeDetail_Local, [name]: value });
        }
    };

    const listNameList = [
        // !global.isEmpty(fieldsP3.newAgency) && !global.isEmpty(fieldsP3.note),
        {
            Title: "Screened",
            CompletedUpon: employeeDetail_Local.screenedOn
        },
        {
            Title: "Interviewed",
            CompletedUpon: employeeDetail_Local.interviewedOn
        },

    ];
    const rollbackChanges = () => {
        setEmployeeDetail_Local(tempSaveData.employeeDetail);
        setcompletedOnCheckboxField(
            tempSaveData.completedOnCheckboxField
        );
        //setOpenConfirm(false);
        setIsSaveEnable(false);
        setSelectedItem(selectedItemNext);
        //setTempSaveData({
        //    employeeDetail: employeeDetail,
        //    completedOnCheckboxField: completedOnCheckboxField
        //});
    }
    const handleChange = (event, newValue) => {
        if (newValue == value) {
            event.preventDefault();
            return;
        }
        if (changedItemsIncludes("bottomContainer")) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                event.preventDefault();
                return;
            } else {
                removeChange("topContainer");
                removeChange("bottomContainer");
                setSelectedItemNext(newValue);
                rollbackChanges();
            }
        }
        if (!view || !parent_view || !education || !information) {
            handleClickOpen();
        } else {
            setValue(newValue);
        }
    };
    const onFieldChange_Bottom = (event) => {
        setIsSaveEnable(true);
        addChange("bottomContainer");
        if (event && event.target && event.target.focus) {
            event.target.focus()
        }
    }
    const onFieldChange_Top = () => {
        setIsSaveEnable(true);
        addChange("topContainer");
    }
    const resetPrevData_Bottom = () => {
        removeChange("bottomContainer");
        rollbackChanges();
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const CareNameLeftListItem = ({ name, index, disabled, completed }) => {
        return (
            <ListItem
                button
                selected={selectedItem === name}
                style={{ height: 35 }}
                disabled={disabled}
                onClick={() => handleListItemClick(name)}
            >
                {completed ? (
                    <ListItemIcon style={{ color: "#19D2B3", maybe: "#19B4D1" }}>
                        <AssignmentTurnedInIcon />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                )}

                <ListItemText primary={name} />
            </ListItem>
        );
    };
    const SectionLeftList = () => {
        return (
            <div className={classes.leftSection}>
                <List component="nav" aria-label="secondary mailbox folder">
                    {
                        listNameList.map((item, index) => (
                            <CareNameLeftListItem
                                name={item.Title}
                                index={index}
                                key={index}
                                completed={item.CompletedUpon}
                                disabled={
                                    !employeeDetail_Local.screenedOn &&
                                        item.Title != "Screened"
                                        ? true
                                        : false
                                }
                            ></CareNameLeftListItem>
                        ))}
                </List>
            </div>
        );
    };

    const onSaveRightCard = () => {
        // setEmployeeDetail(employeeDetail_Local);
        // console.log(employeeDetail_Local);
        // console.log(employeeDetail_Local.id);

        global.fetchWrapper.post(constants.EmployeeApplicant_SAVE_Enrollment + employeeDetail_Local.id, employeeDetail_Local)
            .then(
                (data) => {
                    global.AjaxSaved1();
                    for (const file in fileTemplates) {
                        if (fileTemplates[file].state == "modified")
                            saveFile({ ...fileTemplates[file], file64Arr: undefined }, "employees", fileTemplates, setFileTemplates);
                        else if (fileTemplates[file].state == "new")
                            saveFile(fileTemplates[file], "employees", fileTemplates, setFileTemplates);
                    }
                    setClean();
                    setIsSaveEnable(false);
                },
                (rejects) => {
                    global.AjaxFailToast1();
                }
            );
    };

    const SectionHeading = ({ title }) => {
        return (
            <Typography
                className={baseClasses.card_title}
                // variant="h6"
                gutterBottom
            >
                {title}
            </Typography>
        );
    };

    const DateCheckbox = ({
        label,
        name,
        selectedDate,
        setSelectedDate,
        onChange,
    }) => {

        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name]}
                            onClick={(event) => {
                            }}
                            color="primary"
                        />
                    }
                    labelPlacement="start"
                    label={label}
                    style={{ margin: 0 }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={(newValue) => {
                            const formattedDate = format(newValue, 'yyyy-MM-dd HH:mm:ss.SSSSSSS');

                            setSelectedDate(newValue);

                            if (newValue) {
                                console.log(newValue);
                                setIsSaveEnable(true);

                                const _checked = global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name];

                                setIsSaveEnable(true);
                                setEmployeeDetail_Local({
                                    ...employeeDetail_Local,
                                    [name]: formattedDate,
                                });
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
        );
    };



    const CompCheckbox = ({ label, name, onChange }) => {
        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name]}
                            onClick={(event) => {
                                onChange(event);

                                var _checked = global.isEmpty(employeeDetail_Local[name]) ? false : employeeDetail_Local[name]

                                setIsSaveEnable(true);
                                setEmployeeDetail_Local({
                                    ...employeeDetail_Local, [name]: !_checked
                                })
                            }}
                            color="primary"
                        />
                    }
                    labelPlacement="start"
                    label={label}
                    style={{ margin: 0 }}
                />
            </div>
        );
    };

    const ScreenedByInput = ({ value, onChange }) => {
        return (
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="subtitle1" style={{ marginRight: '10px' }}>
                        Screened By:
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        label="Name"
                        value={value}
                        onChange={onChange}
                        variant="outlined"
                        size="small"
                    />
                </Grid>
            </Grid>
        );
    };

    useEffect(() => {
        fetchFileInfos("employees", exploringId, setFileTemplates);
    }, [])

    const CompUploadFile = (comp_props) => {
        return (
            <UploadFile
                {...comp_props}
                table={"employees"}
                primaryId={exploringId}
                file={fileTemplates[comp_props.fileTemplate]}
                setFile={(file) => {
                    setFileTemplates({ ...fileTemplates, [comp_props.fileTemplate]: file })
                    onFieldChange_Bottom();
                }}
            >
            </UploadFile>
        );
    }


    const CompDateInput = ({ programType, label, name, value, disabled, onChange }) => {
        return (
            <form className={classes.container} noValidate>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                        label={label}
                        name={name}
                        value={value}
                        onChange={(value) => {
                            onChange();
                            setEmployeeDetail_Local({ ...employeeDetail_Local, [name]: value });
                            setIsSaveEnable(true);
                            setcompletedOnCheckboxField({
                                ...completedOnCheckboxField,
                                [name]: true,
                            })
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        disableToolbar
                        minDate={new Date(2000, 1, 1)}
                        maxDate={new Date(2100, 1, 1)}
                    />
                </LocalizationProvider>
            </form>
        );
    };

    const onBeforeSelect = (e) => {
        return !changedItems.includes("bottomContainer") || wConfirm(e, resetPrevData_Bottom, "You have unsaved changes, do you want to discard them?");
    }
    useEffect(() => {
        if (global.isEmpty(employeeDetail_Local.comfortHealthApprovedOn))
            return;
        if (!global.isEmpty(employeeDetail_Local.fingerprintedOn))
            return;
        setEmployeeDetail_Local({ ...employeeDetail_Local, ["fingerprintedOn"]: employeeDetail_Local.comfortHealthApprovedOn })
        setcompletedOnCheckboxField({
            ...completedOnCheckboxField,
            ["fingerprintedOn"]: true,
        });
    }, [employeeDetail_Local.comfortHealthApprovedOn]);

    const handleSetEmployeeDetail = (event, name) => {
        setEmployeeDetail_Local({
            ...employeeDetail_Local,
            [name]: event.target.value,
        });
    }

    const handleSaveEnable = (value) => {
        setIsSaveEnable(value)
    }
    return (
        <>
            <div>
                <Card variant="outlined">
                    <CardHeader
                        color="primary"
                        style={{
                            borderBottom: "1px solid rgba(0,0,0,0.12)",
                        }}
                        subheader={
                            <>
                                <span>Applicant Enrollment</span>
                                {
                                    employeeDetail_Local.isComfortEnrollmentOnHold ?
                                        <span
                                            style={{
                                                color: "#1976d2"
                                            }}
                                        >
                                            {'   [On Hold]'}
                                        </span> : null
                                }
                            </>
                        }
                        action={<SectionRightCardBtn onSaveRightCard={onSaveRightCard} isSaveEnable={isSaveEnable} />}
                    />
                    <CardContent classes={{ root: baseClasses.card_view }}>
                        <div className={classes.root}>
                            <div>
                                <TabPanel
                                    value={value}
                                    index="comfort_health"
                                    className={classes.tabRoot}
                                >
                                    {/* <Card
                                        variant="outlined"
                                        style={{ marginBottom: '15px', padding: '10px', border: 0, backgroundColor: theme.palette.background.paper }}
                                    >
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onClick={(event) => {
                                                                    onFieldChange_Top()
                                                                    setEmployeeDetail_Local({
                                                                        ...employeeDetail_Local,
                                                                        isComfortEnrollmentOnHold: event.target.checked

                                                                    })
                                                                }}
                                                                checked={employeeDetail_Local.isComfortEnrollmentOnHold}
                                                            />
                                                        }
                                                        label={"On hold"}
                                                        labelPlacement={'start'}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={"Enrollment Note"}
                                                        fullWidth
                                                        rows={3}
                                                        multiline
                                                        onChange={(event) => {
                                                            onFieldChange_Top()
                                                            setEmployeeDetail_Local({
                                                                ...employeeDetail_Local,
                                                                comfortEnrollmentNote: event.target.value
                                                            })
                                                        }}
                                                        value={employeeDetail_Local.comfortEnrollmentNote}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card> */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} md={5} className={classes.leftSection}>
                                            <SectionLeftList

                                            ></SectionLeftList>
                                        </Grid>
                                        <Grid item xs={3} md={1}>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <div className={classes.rightSection}>
                                                <div className={classes.sectionCard}>
                                                    <SectionRightCard title={"Screened"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="center"
                                                                // spacing={2}
                                                                >
                                                                    <Grid item >
                                                                        <DateCheckbox
                                                                            label="Screened"
                                                                            name="screenedOn"
                                                                            setSelectedDate={setSelectedScreenedDate}
                                                                            selectedDate={selectedScreenedDate}
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>

                                                                        <Grid item xs={12} md={8} lg={5.5}>
                                                                            <TextField
                                                                                label={"Screened By:"}
                                                                                value={employeeDetail_Local.screenedByName || ''} // Safeguard against undefined
                                                                                fullWidth
                                                                                onChange={(event) => {
                                                                                    onFieldChange_Top();
                                                                                    setEmployeeDetail_Local({
                                                                                        ...employeeDetail_Local,
                                                                                        screenedByName: event.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>

                                                                        <Grid item xs={12} md={8} lg={5.5}>
                                                                            <TextField
                                                                                label={"Screening Notes:"}
                                                                                value={employeeDetail_Local.screeningNotes || ''} // Safeguard against undefined
                                                                                fullWidth
                                                                                rows={3}
                                                                                multiline
                                                                                onChange={(event) => {
                                                                                    onFieldChange_Top();
                                                                                    setEmployeeDetail_Local({
                                                                                        ...employeeDetail_Local,
                                                                                        screeningNotes: event.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>
                                                    <SectionRightCard title={"Interviewed"} selectedItem={selectedItem}>
                                                        <CardContent classes={{ root: baseClasses.card_view }}>
                                                            <div>
                                                                <Grid
                                                                    container
                                                                    className={baseClasses.grid}
                                                                    alignItems="center"
                                                                // spacing={2}
                                                                >
                                                                    <Grid item >
                                                                        <DateCheckbox
                                                                            label="Interviewed"
                                                                            name="interviewedOn"
                                                                            setSelectedDate={setSelectedInterviewDate}
                                                                            selectedDate={selectedInterviewDate}
                                                                            onChange={onFieldChange_Bottom}
                                                                        />
                                                                    </Grid>
                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>

                                                                        {/* <Grid item xs={12} md={8} lg={5.5}>
                                                                            <TextField
                                                                                label={"Interviewed By:"}
                                                                                value={interviewedByName}
                                                                                fullWidth
                                                                                // onChange={handleScreenedByNameChange}
                                                                                onChange={(event) => {
                                                                                    onFieldChange_Top()
                                                                                    setEmployeeDetail_Local({
                                                                                        ...employeeDetail_Local,
                                                                                        screenedByName: event.target.value
                                                                                    })
                                                                                    console.log(employeeDetail_Local)
                                                                                }}



                                                                            />
                                                                        </Grid> */}
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8} lg={5.5}>
                                                                        <TextField
                                                                            label={"Interviewed By:"}
                                                                            value={interviewedByName}
                                                                            fullWidth
                                                                            onChange={(event) => {
                                                                                const value = event.target.value;
                                                                                setInterviewedByName(value);
                                                                                onFieldChange_Top();
                                                                                setEmployeeDetail_Local({
                                                                                    ...employeeDetail_Local,
                                                                                    interviewedByName: value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    <Grid container alignItems="center" sx={{ marginTop: 2 }}>

                                                                        <Grid item xs={12} md={8} lg={5.5}>
                                                                            <TextField
                                                                                label={"Interview Notes:"}
                                                                                value={interviewNotes}
                                                                                fullWidth
                                                                                rows={3}
                                                                                multiline
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value;
                                                                                    setInterviewNotes(value);
                                                                                    onFieldChange_Top();
                                                                                    setEmployeeDetail_Local({
                                                                                        ...employeeDetail_Local,
                                                                                        interviewNotes: value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </SectionRightCard>


                                                </div>
                                            </div>
                                        </Grid>


                                    </Grid>
                                </TabPanel>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    )
};   
