import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

export default makeStyles((theme) => ({
  formControl: {
    margin: "0 auto",
    width: "100%",
  },
  ServicesList: {
    display: "flex",
    flexDirection: "row",
  },
  serviceInput: {
    marginLeft: "20px",
    marginRight: "10px",
  },
  marginSpaceOne: {
    // margin: theme.spacing(1),
    margin: "10px 10px !important",
  },
  error: {
    color: "red",
  },
  selection: {
    margin: "12px",
  },
  content: {
    flexGrow: 1,
    padding: "16px 0px",
    margin: "12px 26px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiCardContent-root:last-child": {
        padding: "0px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      cursor: "pointer",
      fontSize: "12px",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-root": {
      border: "0px",
    },
    "& .icon": {
      padding: 3,
    },
  },
  grow: {
    flex: 1,
  },
  tableContent: {
    marginTop: "24px",
  },
  download: {
    textAlign: "right",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  icon: {
    cursor: "pointer",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginBottom: theme.spacing(2),
    border: "1px solid #d3d3d3",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      /*marginLeft: theme.spacing(3),*/
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    /*pointerEvents: 'none',*/
    color: "#808080",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  new_button: {
    background: theme.palette.primary.main,
    color: "#FFFFFF",
  },
  title: {
    marginTop: theme.spacing(2),
  },
  loading: {
    height: 400,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },


}));
