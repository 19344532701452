import 'date-fns';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';

const TimePicker = (props: any) => {
    const { id, label, fieldName, inputVariant, size, value, onChange, fieldErrors, ampm } = props;

    // time fields
    const [isTimeFromOpen, setIsTimeFromOpen] = React.useState(false);
    const [timeFromTrackValues, setTimeFromTrackValues] = React.useState({
        initialValue: new Date(), changedValue: new Date()
    });
    const [dtValue, setDtValue] = React.useState(new Date(value));
    //React.useEffect(() => {
    //    setDtValue(new Date(value));
    //}, [value]);

    const updateValue = (newValue: Date) => {
        setTimeFromTrackValues((prev) => { return { ...prev, changedValue: newValue }; });
        onChange(newValue);
    }

    return (
        <div id={id}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MuiTimePicker
                    {...props}
                    label={label}
                    value={value}
                    //onOpen={() => {
                    //    setIsTimeFromOpen(true);
                    //    let time = new Date(value);
                    //    setTimeFromTrackValues((prev) => { return { initialValue: time, changedValue: time }; });
                    //}}
                    //onClose={() => {
                    //    setIsTimeFromOpen(false);
                    //}}
                    //onAccept={(newValue) => {
                    //    if (isTimeFromOpen) {
                    //        let initV = timeFromTrackValues.initialValue;
                    //        let chngV = timeFromTrackValues.changedValue;
                    //        let acptV = newValue;
                    //        if (initV.getMinutes() === acptV.getMinutes()) {
                    //            updateValue(chngV);
                    //        }
                    //    }
                    //}}
                    //onChange={(newValue) => {
                    //    updateValue(newValue);
                    //}}
                    onChange={onChange}
                    InputAdornmentProps={{
                        'aria-label': 'change time',
                    }}
                    disableCloseOnSelect={false}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                value={dtValue}
                                inputProps={{
                                    ...params.inputProps,
                                    //value: dtValue
                                }}
                                variant={inputVariant || "outlined"}
                                size={size || "small"}
                                fullWidth
                                helperText={fieldErrors?.[fieldName]}
                                error={fieldErrors?.[fieldName] != null}
                                style={{ width: props?.style?.width }}
                            />
                        );
                    }}
                />
            </LocalizationProvider >
        </div>
    );
}

export default TimePicker;