import React, { useState, useEffect } from 'react';
import { Grid, Card, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { HDate } from '@hebcal/core';
import CustomProgressBar from '../../../components/common/CustomProgressBar';
import { fetchOutstandingNotes, dismissProgressTodo, getMeargenNotes, getMeargenWithMonthlyStatus } from "../../../api/clients";
import { hebrewMonthNames, HebrewMonthNamesLeapYear } from "../../../_helpers/hebrewDate/constants";
import AccordionTable from '../../../components/common/AccordionTable';
import AddProgress from "../../../components/explorer/Tabs/Dialog/AddProgress";
import DetailHeader from '../DetailHeader';
import { BoxContainer } from '../../../assets/styles/styledComponents';
import useBaseStyles from '../../../assets/styles';
import LoadingBar from '../../../components/LoadingBar/LoadingBar';
import useConfirm from '../../../hooks/useConfirm';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import { meagenWithStatusType, progressMonthlyStatusType } from '../../../assets/types'

const Progress = () => {
  const baseClasses: any = useBaseStyles();
  const globalConst: any = global;
  const { confirm } = useConfirm();
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [selectedRow, setSelectRow] = useState<any>(null)
  const [progressData, setProgressData] = useState([]);
  const [meargensData, setMeargensData] = useState<any>([]);
  const [selectedMeargen, setSelectedMeargen] = useState <number>(0);
  const [hebMonthDateRanges, setHebMonthDateRanges] = useState<any>([]);
  const [progressGroupData, setProgressGroupData] = useState<any>([]);
  const [progressLoading, setProgressLoading] = useState(false);
  const [editData, setEditData] = useState<any>("");
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);

  const getMonthHebName = (month: any, isLeapYear: any, useTishreiMonths = false) => {
    let HebMonths = isLeapYear ? HebrewMonthNamesLeapYear : hebrewMonthNames
    let monthObj: any = useTishreiMonths ? HebMonths[month - 1]: HebMonths.find(object => object.chodesh === month);

    return monthObj.label;
  }

  useEffect(() => {
    loadProgressData();
  }, [])

  const loadProgressData = () => {
      setProgressLoading(true)
    getMeargenWithMonthlyStatus().then((data: meagenWithStatusType[]) => {
        if (!!data) {
            loadPrgressDetail(data[0].assignee_ContactId)
        }
        setMeargensData(data);
    })
    //   fetchOutstandingNotes(startDate).then((response) => {
    //       setHebMonthDateRanges(response.hebrewMonthRanges);
    //       let data = response.notes.map((date: any) => {
    //               return {
    //                   ...date,
    //                   hebMonthName: response.hebrewMonthRanges[date.monthIndex]?.hebMonthName,
    //                   hebYearName: response.hebrewMonthRanges[date.monthIndex]?.hebYearName
    //               }
    //       })
    //       setProgressData(data);
    //       setMeargensData(response.meargens);
    //       // Filtering data according with meargens
    //       data = globalConst.arrays.filterArrayWithProperty(data, "meargen_ContactId",  response.meargens[0].assignee_ContactId )
    //       setProgressLoading(false);
    //       setSelectedMeargen(0);
    //       setProgressGroupData(globalConst.arrays.groupByProperties({ Group: data, By: ["clientId", 'monthIndex'] }).sort((a: any, b: any) => sortMonthlyProgress(a, b)))
    //   })
      // Implement Api call here
  }

  const loadPrgressDetail = (meargenId: number) => {
    let startDate = new Date(2022, 4, 2);
    setProgressLoading(true)
    getMeargenNotes(meargenId, startDate).then((response: any) => {
        setProgressLoading(false)
        setHebMonthDateRanges(response.hebrewMonthRanges);
        setProgressLoading(false);
        const data = globalConst.arrays.filterArrayWithProperty(response.notes, "meargen_ContactId",  meargenId )
        setProgressData(data);
        setProgressGroupData(globalConst.arrays.groupByProperties({ Group: data, By: ["clientId", 'monthIndex'] }).sort((a: any, b: any) => sortMonthlyProgress(a, b)))
    })
  }

  const reloadProgressData = () => {
      loadProgressData();
  }

  const getMonthlyProgressPercent = () => {
    let months = []
    if (meargensData.length) {
        months = meargensData[0]?.monthlyStatuses?.map((month: any) => {
            let sum = 0;
            meargensData.forEach((m: any) => {
                sum += (m.monthlyStatuses.find((item: any) => item.monthIndex/*TODO*/ === month.monthIndex).percentCompleted * 100) ?? 0;
            })
            sum = Math.round(sum / meargensData.length);
            return { monthIndex: month.monthIndex, year: month.year, percentCompleted: sum }
        })

    }
    return months || [];
  }

  const sortMonthlyProgress = (arr1: any, arr2: any) => {
    //sort first by client name
    if (arr1[0].clientName > arr2[0].clientName)
        return 1;
    else if (arr1[0].clientName < arr2[0].clientName)
        return -1;
    // if the same, sort by month
    if (arr1[0].monthIndex > arr2[0].monthIndex)
        return -1;
    else if (arr1[0].monthIndex < arr2[0].monthIndex)
        return 1
    else// nothing to split them
        return 0;
  }

  const handleListItemClick = (event: any, item: any, index: number) => {
    setSelectedMeargen(index);
    loadPrgressDetail(item.assignee_ContactId)
  }

  const handleEditData = (data: any) => {
    setEditData({
        primaryId: data.clientId,
        title: data.title,
        noteTemplateId: data.noteTemplateId,
        id: data.noteId,
        noteDescription: data.noteDescription,
        noteDate: data.noteDate,
        monthIndex: data.monthIndex,
    });
    setIsOpenCreateModal(true);
  };

  const handleCloseModal = (isReload: any) => {
    setIsOpenCreateModal(false);
    setEditData([]);
  };

  const getProgressCount = () => {
    return progressData.length;
  };

  const handleClickDismiss = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>, param: any, groupIdex: number) => {
    e.stopPropagation();
    setIsOpenConfirmDialog(true);
    setSelectRow({row: param, groupIdex: groupIdex})
  }
  return (
        <div>
          <DetailHeader boxLabel='Progress' boxIcon='ProgressChart' badgeCount={getProgressCount()} />
            {progressLoading && <LoadingBar/> }
            <BoxContainer margin="20px 10px">
              <Grid container spacing={2}>
              {meargensData.length>1 && (
                  <Grid item xs={3}>
                          <Card>
                              <List component="nav" aria-label="main mailbox folders">
                                  {meargensData.map((item: any, index: number) => {
                                      return (
                                          <ListItemButton
                                              divider
                                              selected={selectedMeargen === index}
                                              onClick={(event) => handleListItemClick(event, item, index)}
                                              style={{position: "relative"}}
                                          >
                                              <ListItemText primary={item.assigneeName} />
                                              {item?.monthlyStatuses?.map((i: any) => {
                                                  globalConst.log(i)
                                                  return (
                                                      <div
                                                          style={{
                                                              paddingRight: '10px',
                                                              paddingLeft: '10px',
                                                              display: 'flex',
                                                              flexDirection: 'column',
                                                              alignItems: 'center'

                                                          }}
                                                      >
                                                          <Typography fontSize={12}>{getMonthHebName(i.monthIndex, new HDate(1, 1, i.year).isLeapYear(), true)}</Typography>
                                                          <CustomProgressBar showLabel progressPercent={i.totalTasks > 0 ? Math.round(i.percentCompleted * 100) : null } progressType="circular" color="#1976d2" position="bottom" />
                                                          </div>
                                                  )
                                              }) 

                                              }
                                              
                                          </ListItemButton>
                                      )
                                  })}
                              </List>
                          </Card>
                  </Grid>
              )}
                  <Grid item xs={9}>
                      <Card>
                      {
                          progressGroupData.filter((g: any) => g.length).map((data: any, index: number) => {
                              return(
                                  <AccordionTable
                                      title={`${data[0].clientName}-${hebMonthDateRanges[data[0].monthIndex]?.hebMonthName}`}
                                      count={data.length}
                                      handleEditProgressNotes={handleEditData}
                                      columns={[
                                          {field: 'title', title:   'Title'},
                                          {field: 'clientName', title:   'Client'},
                                          {field: 'hebMonthName', title:'Month'},
                                          {field: 'hebYearName', title:'Year'},
                                          {field: 'button', title: 'Dismiss', type: 'button', maxWidth: '100px', onClick:(e: any, row: any) =>  handleClickDismiss(e, row, index)}
                                      ]}
                                      data={data}
                                  />)
                          })
                      }
                      </Card>
                  </Grid>
              </Grid> 
            </BoxContainer>
            <ConfirmDialog
                open={isOpenConfirmDialog}
                onClose={() => {
                    setIsOpenConfirmDialog(false);
                    setSelectRow(null)
                }}
                onConfirm={() => {
                    setProgressLoading(true)
                    dismissProgressTodo(selectedRow?.row?.taskId).then((data) => {
                        const filterData = progressData.filter((data: any) => data.taskId !== selectedRow?.row?.taskId);
                        setProgressData(filterData);
                        const filterGroupData = progressGroupData.map((groupData: any, index: number) => {
                            if(index === selectedRow.groupIdex) {
                                return groupData.filter((data: any) => data.taskId !== selectedRow?.row?.taskId)
                            }
                            return groupData
                        })
                        setProgressGroupData(filterGroupData);
                        setProgressLoading(false)
                        setIsOpenConfirmDialog(false);
                        setSelectRow(null)
                    })
                
                }}
                id={selectedRow?.row?.taskId}
                item="task"
                action="delete"
                canConfirm
            />
            <AddProgress
                isOpenCreateModal={isOpenCreateModal}
                onCloseModal={handleCloseModal}
                editData={editData}
                setMonthlyProgressDateRange={() => { }}
                reloadDashboardNotes={reloadProgressData}
                screen="dashboard"
                dateRange={{
                    fromDate: hebMonthDateRanges[editData?.monthIndex]?.dateFrom,
                    toDate: hebMonthDateRanges[editData?.monthIndex]?.dateTo
                }}
            /> 
        </div>
  )
}

export default Progress