import { withStyles } from "@mui/styles";
import TableRow from '@mui/material/TableRow';

const ClickableTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
    },
}))(TableRow);

export default ClickableTableRow;