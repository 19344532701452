import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import {defaultColumnsAndFilters, viewSettingStructure,  SYSTEM_VIEW_STRUCT, dataGridFiltersOperatorTypes, defaultQuickFilters} from '../constants/grid';
import { dataGridViewType } from '../assets/types';
import { columnVisibilityModelType, filterModelItemType } from './types';
import { getDataGridViewList, updateDataGridView, deleteDataGridView, addDataGridView } from '../api/system'; 
import {strings} from '../_helpers/strings' 
import useConfirm from './useConfirm';
const useDataGridViewHandler = (name: string, columns: any) => { 
    const dataGridViews = useStoreState((state: any) => state.dataGridSettings.dataGridViews);
    const DBDataGridViews = useStoreState((state: any) => state.dataGridSettings.DBDataGridViews);
    const currentView = useStoreState((state: any) => state.dataGridSettings.currentView);
    const setCurrentView = useStoreActions((action: any) => action.dataGridSettings.setCurrentView);
    const setDataGridView = useStoreActions((action: any) => action.dataGridSettings.setDataGridView);
    const setDBDataGridViews = useStoreActions((action: any) => action.dataGridSettings.setDBDataGridViews);
    const [loading, setLoading] = useState <boolean>(false);
    const location = useLocation();
    const {confirm} = useConfirm();
    // seting defualt as active view
    useEffect(() => {
      getInitialCurrentView();
      if (!dataGridViews[name] || dataGridViews[name]?.length < 1) {
        getGridViewsFromDB(name); // if view are not available in redux load from DB
      }
    },[location]);

    /**
     * Checking object is empty or not
     * @param obj 
     * @returns {boolean}
     */
    function isEmptyObject(obj: dataGridViewType | {}) {
      for(let i in obj) { return false; }
      return true;
    }

    function getOperatorValue (item: any) {
      switch(dataGridFiltersOperatorTypes[item.operator as keyof typeof dataGridFiltersOperatorTypes]?.valueType) {
        case 'array':
            return strings.convertStringToArr(item.value)
        default:
          return item.value
      }
    }

    function convertFilterSettingsStructure (filters: filterModelItemType[]) {
      return filters.map((item: filterModelItemType) => (
        {
          ...item,
          value: getOperatorValue(item)
        }
      )).filter((item: filterModelItemType) => verifyColumnsPresence(item.columnField))
    }

    const defaultColsFilters = {
      columnSettings: defaultColumnsAndFilters[name as keyof typeof defaultColumnsAndFilters]?.columnSettings ?? viewSettingStructure.columnSettings,
      filterSettings: defaultColumnsAndFilters[name as keyof typeof defaultColumnsAndFilters]?.filterModel ?? viewSettingStructure.filterModel,
    }

    /**
     * getting initail view for each data grid
     * @returns {void}
     */
    const getInitialCurrentView = () => {
      if (!isEmptyObject(currentView[name]) && 
          ( hasChanges(currentView[name].columnSettings, "column") ||
            hasChanges(currentView[name].filterSettings, 'filter') || 
            hasChanges(currentView[name].quickFilters, 'quickFilter')
          )) return; // when current view is not empty and has some changes

      const defualtView  = dataGridViews[name]?.length > 0 && dataGridViews[name].find((view: dataGridViewType) => view.isDefault);
      setCurrentView({data: !!defualtView ? defualtView : {...SYSTEM_VIEW_STRUCT, filterSettings: defaultColsFilters.filterSettings, columnSettings: defaultColsFilters.columnSettings}, grid: name})
    }
 
    const verifyColumnsPresence = (column: string) => {
      return !!columns.find((col: any) => col.field === column);
    }

    const getColumnVisibilityStructure = (visiblityCols: columnVisibilityModelType[]) => {
      let columnSettings: any = {}
      visiblityCols.map((colum: columnVisibilityModelType) => {
        if(verifyColumnsPresence(colum.columnName)) {
          columnSettings[colum.columnName] = {
            ...colum
          }
        }
      })
      return columnSettings
    }

   /**
    * Loading already saved views from backend database
    */
    const getGridViewsFromDB = (dataGridViewName: string, activeView: string = "") => {
      let gridViews: any = [];
      setLoading(true)
      getDataGridViewList(dataGridViewName).then((viewList: any) => {
        setLoading(false);
        gridViews = [...viewList];
        gridViews = gridViews?.filter((view: any) => view.dataGridName === name);
        gridViews = gridViews.map((view: any) => ({
          ...view,
          quickFilters: !!view.quickFilters ? strings.parseStringToObj(view.quickFilters, "&", "=") : null,
          columnSettings: getColumnVisibilityStructure(view.columnSettings),
          filterSettings: {
            linkOperator: view.linkOperator,
            items: convertFilterSettingsStructure(view.filterSettings) 
          }
        }));
        setDataGridView({data:gridViews, grid:name});
        let previouseDBView = DBDataGridViews.filter((view: dataGridViewType) => view.viewName !== name)
        setDBDataGridViews([...gridViews, ...previouseDBView]);
        const defualtView = gridViews.find((view: dataGridViewType) => view.isDefault);
        let updatedCurrentView = gridViews.find((view: any) => view.viewName === activeView);
        if (!!updatedCurrentView) {
          setCurrentView({data: getCurrentViewStructure(updatedCurrentView), grid: name})
        } else if (!!defualtView) {
          setCurrentView({data: getCurrentViewStructure(defualtView), grid: name})
        } else if (currentView[dataGridViewName]?.viewName !== SYSTEM_VIEW_STRUCT.viewName) {
          setCurrentView({data: {...SYSTEM_VIEW_STRUCT, filterSettings: defaultColsFilters.filterSettings, columnSettings: defaultColsFilters.columnSettings, quickFilters: {}}, grid: name})
        }
      });
    }

    const convertArrayToObject = (arr : any) => {
      return arr.reduce(function(result: any, item: any) {
        const key = Object.keys(item)[0]; 
        result[key] = {
          isVisible: item[key]
        }
        return result;
      }, {});
    }

    const getCurrentViewStructure = (view: any) => {
      return {
        ...view,
        columnSettings: Array.isArray(view.columnSettings) ? 
          convertArrayToObject(view.columnSettings): 
          {...view.columnSettings},
        filterSettings: Array.isArray(view.filterSettings) ? 
          {
            linkOperator: view.linkOperator,
            items: view.filterSettings.map((item: any) => {
              return {
                ...item,
                operatorValue: item.operator ?? item.operatorValue
              }
            })
          }: 
          {...view.filterSettings},
        
      }

    }

    const reloadGridViewFromDB = (activeView: string = "") => {
      getGridViewsFromDB(name, activeView)
    }
    /**
     * Saving views to the database
     * @param view - Views that we are saving
     */
    const handleSavingView = (view: dataGridViewType) => {
      const saveView = {...view, hasChanges: false}
        const isNewView = view.dataGridViewSettingId === 0 ? true : false
        let saveData =  {
        dataGridViewSettingId: saveView.dataGridViewSettingId,
        viewName: saveView.viewName,
        dataGridName: name,
        isDefault : saveView.isDefault ,
        quickFilters: saveView.quickFilters,
        filterSettings: saveView.filterSettings?.items?.map((item: any) => {
            delete item.id;
            return {
              operator: item.operatorValue,
              value: Array.isArray(item.value) ? strings.convertArrToString(item.value) : item.value,
              columnField: item.columnField
            };
        }),
        linkOperator: saveView.filterSettings?.items?.length > 1 ? saveView.filterSettings?.linkOperator ?? "and": undefined,
        columnSettings: columns.map((col: any) => {
            return {
              widthFlex: view.columnSettings[col.field]?.widthFlex ?? 0,
              columnName: col.field,
              isVisible: view.columnSettings[col.field]?.isVisible ?? true
            }
        })
      }
      if(isNewView) { // creating New
          setLoading(true);
          addDataGridView(saveData).then((data: any) => {
            setLoading(false);
            reloadGridViewFromDB(view.viewName)
          })
      } else { // updating already created view
        setLoading(true);
        updateDataGridView(saveData).then((data: any) => {
          setLoading(false)
          reloadGridViewFromDB()
          setDataGridView({data: dataGridViews[name].map((item: dataGridViewType) => 
            item.viewName === view.viewName ? saveView : item
            ), grid: name});
            reloadGridViewFromDB(view.viewName)
        })
      }
      
    }

    const handleViewChange =  (view: dataGridViewType) => {
        if (currentView[name].hasChanges && !window.confirm("You have unsaved changes in cureent view, please save changes before leaving view \n Do you want continue without saving?")) {
            return;
        }
        const data = {...view, columnSettings: view.viewName === SYSTEM_VIEW_STRUCT.viewName ? defaultColsFilters.columnSettings : view.columnSettings}
        setCurrentView({data: getCurrentViewStructure(data), grid:name})

    };

    const hasChanges = (changes:any, mode: string) => {
      let originView = DBDataGridViews?.find((view: dataGridViewType) => view.viewName === currentView[name].viewName && view.dataGridName === name)
      switch(mode) {
        case 'filter': {
          const currentfilterSettings = {...changes, items: changes?.items.map((item: any) => {
            return {
              columnField: item.columnField,
              operator: item.operator ?? item.operatorValue,
              value: item.value
            }
          }).filter((item: any) => !!item.value)
          }
          const alreadyFilters = !!originView ? { ...originView.filterSettings, 
            items: originView.filterSettings?.items.map((item: any) => {
              return {
                columnField: item.columnField,
                operator: item.operator ?? item.operatorValue,
                value: item.value
              }
            })
          }: {...defaultColsFilters.filterSettings}

          return !_.isEqual(alreadyFilters, currentfilterSettings)
        }
        case 'column': {
          return !_.isEqual(!!originView ? originView.columnSettings : defaultColsFilters.columnSettings,  changes)
        } 
        case 'columnSize': {
          return !_.isEqual(!!originView ? originView.columnSettings : defaultColsFilters.columnSettings,  changes)
        } 
        case 'quickFilter': {
          return !_.isEqual(!!originView ? originView.quickFilters : defaultQuickFilters[name as keyof typeof defaultQuickFilters],  changes)
        }
      
        default: return null;   
      }
      
    }

    const handleChanges = (changes: any, mode: string) => {
        switch(mode) {
          case 'filter': {
            const newChanges = {
              ...changes,
              items: [...changes.items]
            }

            let updateData = {
              ...currentView[name],
              hasChanges: hasChanges(newChanges, mode),
              filterSettings : [...newChanges.items],
              linkOperator: newChanges?.linkOperator ? 
                    newChanges?.linkOperator : 
                    currentView[name]?.linkOperator
            }
            setCurrentView({
              data: getCurrentViewStructure(updateData),
              grid: name
            });
            break;
          }
          case 'column': {
            let updatedColumns = {...changes};
            if (isEmptyObject(updatedColumns)) {
              columns.map((column: any) => {
                updatedColumns[column.field] = true
              })
            }
            let tempChange: any =  {...currentView[name].columnSettings}
            for (const property in updatedColumns) {
              tempChange[property] = {
                ...tempChange[property],
                isVisible: updatedColumns[property]
              }
            }
            setCurrentView({
              data: {
              ...currentView[name],
              hasChanges: hasChanges(tempChange, mode),
              columnSettings : tempChange,
              },
              grid: name
            });
            break;
          }
          case 'columnSize': {
            let tempChange: any = {...currentView[name].columnSettings}
            
                tempChange[changes.colDef.field] = {
                  ...tempChange[changes.colDef.field],
                  widthFlex: parseInt(changes.colDef.width),
                  isVisible: tempChange[changes.colDef.field]?.isVisible ?? true
                }
            setCurrentView({
              data: {
                ...currentView[name],
                hasChanges: hasChanges(changes, mode),
                columnSettings : tempChange,
              },
              grid: name
            });
            break;
          }
          case 'quickFilter': {
            setCurrentView({
              data: {
                ...currentView[name],
                hasChanges: hasChanges(changes, mode),
                quickFilters: changes,
              },
              grid: name
            });
            break;
          }

          default: return null;
        }
    }
    
    const handleDeleteView = (id: number) => {
      setLoading(true);
        deleteDataGridView(id).then((data: any) => {
          setLoading(false);
          reloadGridViewFromDB();
        })
    }

    const systemDefaultView = {...SYSTEM_VIEW_STRUCT, filterSettings: defaultColsFilters.filterSettings, columnSettings: defaultColsFilters.columnSettings, quickFilters: {}}

    return [
      dataGridViews[name],
      {...currentView[name]},
      currentView[name]?.columnSettings, // columnsVisibility
      currentView[name]?.filterSettings, // filterModel,
      loading,
      {
        systemDefaultView: systemDefaultView,
        saveGridView: handleSavingView,
        hanleViewChange: handleViewChange,
        handleChangeColAndFilter: handleChanges,
        handleDeleteGridView: handleDeleteView
      },
    ];
};

export default useDataGridViewHandler;

