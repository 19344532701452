import SCHEDULE_PERIODICITY_CONSTANTS from "../constants/schedule_periodicity_constants";
import { dateTime as dateTimeHelper } from "../datetime";
import { numbers as numbersHelper } from "../numbers";
import { Schedule } from "./shared/types";

export const getScheduleStringForProfessional = (() => {
    const getMonthlyScheduleString = (days: number[], everyXMonths: number) => {
        if(days.length == 0 || everyXMonths == 0){
            return '';
        }

        const formattedDays = dateTimeHelper.getMonthDaysString(days, true);

        if(everyXMonths == 1){
            return `Every ${formattedDays}`;
        }

        if(everyXMonths > 1){
            const formattedXMonths = numbersHelper.getOrdinalString(everyXMonths);
            return `Every ${formattedXMonths} month on the ${formattedDays}`;
        }
    }

    const getWeeklyScheduleString = (days: number[], everyXWeeks: number) => {
        if(days.length == 0 || everyXWeeks == 0){
            return '';
        }

        const formattedDays = dateTimeHelper.getWeekdaysString(days, true);

        if(everyXWeeks == 1){
            return `Every ${formattedDays}`;
        }

        if(everyXWeeks > 1){
            const formattedXWeeks = numbersHelper.getOrdinalString(everyXWeeks);
            return `Every ${formattedXWeeks} ${formattedDays}`
        }
    }

    return (days: number[], everyXOfPeriods: number, periodicity: string) => {
        if(!periodicity) return '';

        if(periodicity.toLowerCase() === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS.toLowerCase()){
            return getWeeklyScheduleString(days, everyXOfPeriods);
        }

        if(periodicity.toLowerCase() === SCHEDULE_PERIODICITY_CONSTANTS.MONTHS.toLowerCase()){
            return getMonthlyScheduleString(days, everyXOfPeriods)
        }

        return '';
    }
})();

export const getNextSession = (schedule: Schedule, searchStartDate: Date | null = null) => {
    if (!schedule || !schedule.periodicity)
        return null;

    if(!searchStartDate){
        const today = new Date();
        searchStartDate = searchStartDate ?? schedule.startDate > today ? schedule.startDate : today;
    }

    if(schedule.periodicity.toLowerCase() === SCHEDULE_PERIODICITY_CONSTANTS.MONTHS.toLowerCase()){
        return getNextMonthlySession(schedule, searchStartDate);
    } else if(schedule.periodicity.toLowerCase() === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS.toLowerCase()){
        return getNextWeeklySession(schedule, searchStartDate);
    } else {
        return null;
    }
}

export const getNextMonthlySession = (schedule: Schedule, searchStartDate: Date) => {
    
    const scheduleStartDate = schedule.startDate;

    const monthsSinceStart = dateTimeHelper.getNumberOfMonthsBetweenDates(
        dateTimeHelper.getBeginningOfMonth(searchStartDate),
        dateTimeHelper.getBeginningOfMonth(scheduleStartDate),
    );

    const monthsSinceLastPeriod = monthsSinceStart % schedule.everyXOfPeriods;
    const monthsUntilNextPeriod = schedule.everyXOfPeriods - monthsSinceLastPeriod;

    const closestMonthDay = schedule.days.reduce((closestMonthDay: number|null, possibleMonthDay) => {
        const daysUntilClosestMonthDay = closestMonthDay == null ? Infinity : dateTimeHelper.getDaysUntilNextMonthDay(searchStartDate, closestMonthDay);
        const daysUntilNextPossibleMonthDay = dateTimeHelper.getDaysUntilNextMonthDay(searchStartDate, possibleMonthDay);

        return daysUntilClosestMonthDay < daysUntilNextPossibleMonthDay ?
            closestMonthDay : possibleMonthDay;
    }, null);

    if(closestMonthDay == null){
        return null;
    }

    let nextSession = new Date(searchStartDate);
    nextSession.setDate(searchStartDate.getDate() + dateTimeHelper.getDaysUntilNextMonthDay(searchStartDate, closestMonthDay));

    if(monthsSinceLastPeriod == 0 && searchStartDate.getMonth() == nextSession.getMonth()){
        return nextSession;
    } else {
        nextSession = dateTimeHelper.getBeginningOfMonth(searchStartDate);
        nextSession.setMonth(searchStartDate.getMonth() + monthsUntilNextPeriod);
        nextSession.setDate(closestMonthDay);
        return nextSession;
    }
}

export const getNextWeeklySession = (schedule: Schedule, searchStartDate: Date) => {
    const scheduleStartDate = schedule.startDate;

    const weeksSinceStart = dateTimeHelper.getNumberOfWeeksBetweenDates(
        dateTimeHelper.getBeginningOfWeek(searchStartDate),
        dateTimeHelper.getBeginningOfWeek(scheduleStartDate)
    );

    const weeksSinceLastPeriod =  weeksSinceStart % schedule.everyXOfPeriods;
    const weeksUntilNextPeriod = schedule.everyXOfPeriods - weeksSinceLastPeriod;

    const daysUntilNextScheduleWeekday = schedule.days.reduce((daysUntilClosestWeekDay, possibleWeekDay) => {
        const daysUntilNextPossibleWeekDay = dateTimeHelper.getDaysUntilNextWeekday(searchStartDate.getDay(), possibleWeekDay);
        
        return daysUntilNextPossibleWeekDay < daysUntilClosestWeekDay ? 
            daysUntilNextPossibleWeekDay : daysUntilClosestWeekDay;
    }, Infinity);

    if(daysUntilNextScheduleWeekday === Infinity){
        return null;
    }

    let nextSessionDate: Date;
    if(weeksSinceLastPeriod == 0 && (searchStartDate.getDay() + daysUntilNextScheduleWeekday) < 7){
        nextSessionDate = new Date(searchStartDate);
        nextSessionDate.setDate(searchStartDate.getDate() + daysUntilNextScheduleWeekday);
    } else {
        nextSessionDate = dateTimeHelper.getBeginningOfWeek(searchStartDate);
        nextSessionDate.setDate(nextSessionDate.getDate() + 7*weeksUntilNextPeriod + daysUntilNextScheduleWeekday % 7)
    }

    return nextSessionDate;
}

export const getDaysArrayFromDaysString = (days: String) => {
    return days ? days.split(',').filter(n => Number.isInteger(parseInt(n, 10))).map(n => parseInt(n, 10)) : [];
}

export const modalFields = {
    rateFilter: ["yungerman", "professional"],
    timeFilter: ["yungerman", "professional"],
    neighborhoodFilter: ["yungerman", "professional"],
    specialtiesFilter: ["yungerman"],
    rate: ["yungerman", "professional"],
    daysOfWeekRadio: ["yungerman", "availability"],
    potentialSection: ["yungerman", "professional"],
    status: ["yungerman", "professional"],
    comfortStatus: ["yungerman"],
    clientName: ["yungerman", "professional"],
    professionalTypes: ["professional"],
    customizePeriodicity: ["professional"],
    days: ["yungerman", "professional", "availability"],
}