import React, { useEffect } from 'react';
import { Grid} from '@mui/material';
import { useParams } from 'react-router-dom';
import {useStoreState} from 'easy-peasy';
import { TextFieldEditor } from '../../../../components/common/UI';
import SelectEditor from '../../../../components/common/UI/Inputs/Select/SelectEditor';
import { DivContainer, Title } from '../../../../assets/styles/styledComponents';
import { borderTop } from '@mui/system';
import SectionWrapper from './SectionWrapper';
import useUnsavedChangesWarn from '../../../../hooks/useUnsavedChangesWarn';
import useGetValueOptionsLists from '../../../../hooks/useGetValueOptionsLists';
 type generalDetailsProps = {
  handleSave: Function,
  generalDetail: yeshivaGeneralDetailType;
 }

 type yeshivaGeneralDetailType = {
  basicInfo: {
    name: generalFieldType,
    legalName: generalFieldType,
    description: generalFieldType,
    mode: "read" | "edit"
    isLoading: boolean,
  },
  legalAddress: yeshivasAddressType,
  mainAddress: yeshivasAddressType,
  summerAddress: yeshivasAddressType,
}

 type yeshivasAddressType  = {
  address1: generalFieldType,
  address2: generalFieldType,
  mode: "read" | "edit"
  isLoading: boolean,
  aptPOBox: generalFieldType,
  city: generalFieldType,
  neighborhood: generalFieldType,
  state: generalFieldType,
  zip: generalFieldType,
}

type generalFieldType = {
  error: string,
  value: string ,
  isRequired: boolean,
}

type eventType = string



const GeneralDetails = ({handleSave, generalDetail}: generalDetailsProps) => {
    const {yeshivasId} = useParams();
    const ID = parseInt(yeshivasId as string);
    const [data, setData] = React.useState<any>(generalDetail);
    const exploringId = useStoreState((state: any) => state.exploringId);
    const [changesHelper]: any = useUnsavedChangesWarn();
    const neighborhood: any = useGetValueOptionsLists(['neighborhood'])
    const { setDirty, hasChanges, setClean} = changesHelper;
    useEffect(() => {
        setData(generalDetail) 
    }, [generalDetail])

    const isReadMode = (field: string) => {
      return data[field as keyof typeof data]?.mode === 'edit' ? false: true;
    }

    const handleChangeField = (value: string, name: string, section: string) => {
      setDirty()
      setData({
        ...data,
        [section] : {
          ...data[section as keyof typeof data],
          [name] : {...data[section][name], error: '', value: value}
        }
      })
    }

    const handleChangeMode = (tab: string, mode: string, section: string) => {
      setData({
        ...data,
        [section]: {
          ...data[section as keyof typeof data ],
          mode: mode
        }
      })
    }

    const saveSectionData = (type: string, tab: string,  section: string, mode: string ) => {
      switch (type) {
        case 'cancel': {
            if (hasChanges()) {
              if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                  return   
              }
            }
            setClean();
            handleChangeMode(tab,  mode, section )
            handleSave(null, type, tab,  section, mode )
            break;
        } 
        case 'save': {
          handleSave(data[section as keyof typeof data], type, tab,  section, mode )
          setClean();
        }
      }
    }
    
  return (
   <DivContainer>
        <SectionWrapper isLoading={data?.basicInfo?.isLoading} handleSave={saveSectionData} tab="general" headerTitle="Basic Information" name="basicInfo" mode={data?.basicInfo?.mode} handleChangeMode={handleChangeMode}>
          <Grid container>
            <Grid item xs={isReadMode("basicInfo") ? 12 : 6}>
                <TextFieldEditor
                  mode={data?.basicInfo?.mode}
                  label="Name"
                  name="name"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'basicInfo')}
                  value={data.basicInfo?.name?.value}
                  error={!!data.basicInfo?.name?.error}
                  helperText={data.basicInfo?.name?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("basicInfo") ? 12 : 6}>
              <TextFieldEditor
                mode={data?.basicInfo?.mode}
                label="Legal Name"
                name="legalName"
                onChange={(value:string, name: string) => handleChangeField(value, name, 'basicInfo')}
                value={data.basicInfo?.legalName?.value}
                error={!!data.basicInfo?.legalName?.error}
                helperText={data.basicInfo?.legalName?.error}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldEditor
                mode={data?.basicInfo?.mode}
                label="Description"
                name="description"
                onChange={(e: string, name: string) => handleChangeField(e, name, 'basicInfo')}
                value={data.basicInfo?.description?.value}
                error={!!data.basicInfo?.description?.error}
                helperText={data.basicInfo?.description?.error}
                fullWidth
              />
            </Grid>
          </Grid>
        </SectionWrapper>
        <SectionWrapper hide={ID <= 0} isLoading={data?.mainAddress?.isLoading} handleSave={saveSectionData} headerTitle="Address" tab="general" name="mainAddress" mode={data?.mainAddress?.mode} handleChangeMode={handleChangeMode}>
          <Grid container >  
            <Grid item xs={isReadMode("mainAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.mainAddress?.mode}
                  label="Address 1"
                  name="address1"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'mainAddress')}
                  value={data.mainAddress?.address1?.value}
                  error={!!data.mainAddress?.address1?.error}
                  helperText={data.mainAddress?.address1?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("mainAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.mainAddress?.mode}
                  label="Address 2"
                  name="address2"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'mainAddress')}
                  value={data.mainAddress?.address2?.value}
                  error={!!data.mainAddress?.address2?.error}
                  helperText={data.mainAddress?.address2?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("mainAddress")?  12 : 2}>
                <TextFieldEditor
                  mode={data.mainAddress?.mode}
                  label="APT Box"
                  name="aptPOBox"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'mainAddress')}
                  value={data.mainAddress?.aptPOBox?.value}
                  error={!!data.mainAddress?.aptPOBox?.error}
                  helperText={data.mainAddress?.aptPOBox?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("mainAddress") ? 12 : 2}>
                <TextFieldEditor
                  mode={data.mainAddress?.mode}
                  label="City"
                  name="city"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'mainAddress')}
                  value={data.mainAddress?.city?.value}
                  error={!!data.mainAddress?.city?.error}
                  helperText={data.mainAddress?.city?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("mainAddress")? 12 : 4}>
                <TextFieldEditor
                  mode={data.mainAddress?.mode}
                  label="State"
                  name="state"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'mainAddress')}
                  value={data.mainAddress?.state?.value}
                  error={!!data.mainAddress?.state?.error}
                  helperText={data.mainAddress?.state?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("mainAddress")? 12 : 4}>
                <TextFieldEditor
                  mode={data.mainAddress?.mode}
                  label="ZIP"
                  name="zip"
                  type="number"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'mainAddress')}
                  value={data.mainAddress?.zip?.value}
                  error={!!data.mainAddress?.zip?.error}
                  helperText={data.mainAddress?.zip?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("mainAddress")? 12 : 4}>
                <SelectEditor
                  mode={data.mainAddress?.mode}
                  id="neighborhood"
                  options={ neighborhood?.neighborhood?.values.map((item:string) => ({label: item, value: item}))}
                  label="Neighbourhood"
                  name="neighborhood"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'mainAddress')}
                  value={data.mainAddress?.neighborhood?.value}
                  error={!!data.mainAddress?.neighborhood?.error}
                  helperText={data.mainAddress?.neighborhood?.error}
                  fullWidth
                />
            </Grid>
          </Grid>
        </SectionWrapper>
        <SectionWrapper hide={ID <= 0} isLoading={data?.legalAddress?.isLoading} handleSave={saveSectionData} tab="general" headerTitle="Legal Address" name="legalAddress" mode={data?.legalAddress?.mode} handleChangeMode={handleChangeMode}>
          <Grid container>
            <Grid item xs={isReadMode("legalAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.legalAddress?.mode}
                  label="Address 1"
                  name="address1"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'legalAddress')}
                  value={data.legalAddress?.address1?.value}
                  error={!!data.legalAddress?.address1?.error}
                  helperText={data.legalAddress?.address1?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("legalAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.legalAddress?.mode}
                  label="Address 2"
                  name="address2"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'legalAddress')}
                  value={data.legalAddress?.address2?.value}
                  error={!!data.legalAddress?.address2?.error}
                  helperText={data.legalAddress?.address2?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("legalAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.legalAddress?.mode}
                  label="APT Box"
                  name="aptPOBox"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'legalAddress')}
                  value={data.legalAddress?.aptPOBox?.value}
                  error={!!data.legalAddress?.aptPOBox?.error}
                  helperText={data.legalAddress?.aptPOBox?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("legalAddress")? 12 : 3}>
                <TextFieldEditor
                  mode={data.legalAddress?.mode}
                  label="City"
                  name="city"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'legalAddress')}
                  value={data.legalAddress?.city?.value}
                  error={!!data.legalAddress?.city?.error}
                  helperText={data.legalAddress?.city?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("legalAddress")? 12 : 3}>
                <TextFieldEditor
                  mode={data.legalAddress?.mode}
                  label="State"
                  name="state"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'legalAddress')}
                  value={data.legalAddress?.state?.value}
                  error={!!data.legalAddress?.state?.error}
                  helperText={data.legalAddress?.state?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("legalAddress")? 12 : 4}>
                <SelectEditor
                  mode={data.legalAddress?.mode}
                  id="neighborhood"
                 options={neighborhood?.neighborhood?.values.map((item:string) => ({label: item, value: item}))}
                  label="Neighbourhood"
                  name="neighborhood"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'legalAddress')}
                  value={data.legalAddress?.neighborhood?.value}
                  error={!!data.legalAddress?.neighborhood?.error}
                  helperText={data.legalAddress?.neighborhood?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("legalAddress")? 12 : 2}>
                <TextFieldEditor
                  mode={data.legalAddress?.mode}
                  label="ZIP"
                  name="zip"
                  type="number"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'legalAddress')}
                  value={data.legalAddress?.zip?.value}
                  error={!!data.legalAddress?.zip?.error}
                  helperText={data.legalAddress?.zip?.error}
                  fullWidth
                />
            </Grid>
          </Grid>    
        </SectionWrapper>
        <SectionWrapper hide={ID <= 0} isLoading={data?.summerAddress?.isLoading} handleSave={saveSectionData} tab="general" headerTitle="Summer Address" name="summerAddress" mode={data?.summerAddress?.mode} handleChangeMode={handleChangeMode}>
        <Grid container>
            <Grid item xs={isReadMode("summerAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.summerAddress?.mode}
                  label="Address 1"
                  name="address1"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'summerAddress')}
                  value={data.summerAddress?.address1?.value}
                  error={!!data.summerAddress?.address1?.error}
                  helperText={data.summerAddress?.address1?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("summerAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.summerAddress?.mode}
                  label="Address 2"
                  name="address2"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'summerAddress')}
                  value={data.summerAddress?.address2?.value}
                  error={!!data.summerAddress?.address2?.error}
                  helperText={data.summerAddress?.address2?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("summerAddress") ? 12 : 4}>
                <TextFieldEditor
                  mode={data.summerAddress?.mode}
                  label="APT Box"
                  name="aptPOBox"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'summerAddress')}
                  value={data.summerAddress?.aptPOBox?.value}
                  error={!!data.summerAddress?.aptPOBox?.error}
                  helperText={data.summerAddress?.aptPOBox?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("summerAddress")? 12 : 3}>
                <TextFieldEditor
                  mode={data.summerAddress?.mode}
                  label="City"
                  name="city"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'summerAddress')}
                  value={data.summerAddress?.city?.value}
                  error={!!data.summerAddress?.city?.error}
                  helperText={data.summerAddress?.city?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("summerAddress")? 12 : 3}>
                <TextFieldEditor
                  mode={data.summerAddress?.mode}
                  label="State"
                  name="state"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'summerAddress')}
                  value={data.summerAddress?.state?.value}
                  error={!!data.summerAddress?.state?.error}
                  helperText={data.summerAddress?.state?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("summerAddress")? 12 : 4}>
                <SelectEditor
                  mode={data.summerAddress?.mode}
                  id="neighborhood"
                  options={neighborhood?.neighborhood?.values.map((item:string) => ({label: item, value: item}))}
                  label="Neighbourhood"
                  name="neighborhood"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'summerAddress')}
                  value={data.summerAddress?.neighborhood?.value}
                  error={!!data.summerAddress?.neighborhood?.error}
                  helperText={data.summerAddress?.neighborhood?.error}
                  fullWidth
                />
            </Grid>
            <Grid item xs={isReadMode("summerAddress")? 12 : 2}>
                <TextFieldEditor
                  mode={data.summerAddress?.mode}
                  label="ZIP"
                  name="zip"
                  type="number"
                  onChange={(e: string, name: string) => handleChangeField(e, name, 'summerAddress')}
                  value={data.summerAddress?.zip?.value}
                  error={!!data.summerAddress?.zip?.error}
                  helperText={data.summerAddress?.zip?.error}
                  fullWidth
                />
            </Grid>
        </Grid>  
        </SectionWrapper>
   </DivContainer>
  )
}

export default GeneralDetails;