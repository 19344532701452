import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, IconButton } from '@mui/material'
import { Clear } from '@mui/icons-material';
import HebDatePicker from '../HebDatePicker';
import { UniDatePickerType } from './types';
import { FlexContainer, DivContainer } from '../../../assets/styles/styledComponents';

const CustomActionBar = (props: any) => {
    return (
        <div>
            Hello
        </div>
    )
}
const UniDatePicker: React.FC<UniDatePickerType> = ({ calendarType, value, label, isHebrew, enableClear, format, helperText, error, onChange, id }) => {
    // try to convert value, which is a datetime in the format 2023-01-18T00:00:00.0000000 to a js date object
    let date = Date.parse(value?.toString() || "");
    if (!isNaN(date)) {
        value = new Date(date);
    }

    return calendarType === 'heb' ?
        <HebDatePicker
            label={label}
            value={value ?? undefined}
            onChange={(newValue: Date | string) => { onChange(newValue) }}
            isHebrew={isHebrew}
            enableClear={enableClear}
            error={error}
            helperText={helperText}
            onClear={() => onChange(null)}
        /> :
        <DatePicker
            label={label}
            value={value}
            onChange={onChange}
            toolbarPlaceholder={label}
            renderInput={(params) => (
                <DivContainer position="relative">
                    {!!value && enableClear && (
                        <IconButton sx={{
                            position: 'absolute',
                            zIndex: 1000
                        }}
                        onClick={() => {onChange(null)}}>
                           <Clear />
                        </IconButton>
                    )}
                    <TextField
                        {...params}
                        error={error}
                        helperText={helperText}
                        size={'small'}
                        sx={{
                            '& .MuiOutlinedInput-input': {
                                textAlign: 'center'
                            }
                        }}
                    />
                </DivContainer>
            )}
        />
}

export default React.memo(UniDatePicker);