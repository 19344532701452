import React, { useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { Row } from '../../../../../assets/styles/styledComponents';
import { SlotLabel, HourHeaderContainer } from './style'
import { DayRowProps, availableSlotType, openWindowsType } from './types';
import { MinsInDay } from '../../../../../constants';

const DayRow: React.FC<DayRowProps> = ({day, availability}) => {
    const globalConst: any = global;
    const [slots, setSlots] = React.useState <availableSlotType[]>([]);

    useEffect(() => {
        const slots = availability?.map((item: openWindowsType) =>{
            const startDate = new Date(item.start)
            const endDate = new Date(item.end)
            return getSlots(startDate, endDate)
        })
        setSlots(slots)
    }, [availability])

    const getPercentage = (value: number, total: number) => {
        return (value/total) * 100
    }

    /**
     * Function - Calculating and converting regualar date and time into minutes and returning slots for rendering
     * @param startDate - start time
     * @param endDate - end Time
     */
    const getSlots = (startDate: Date, endDate: Date) => {
        let startHours: number = startDate.getHours();
        let startMin: number = startDate.getMinutes();
        let endHours: number = endDate.getHours();
        let endMin: number = endDate.getMinutes();
        let start: number = (startHours * 60) + startMin;
        let end: number = (endHours * 60) + endMin;
        return {
            start: start,
            end: end,
            totalMins : end - start,
            percentage: getPercentage(end - start, MinsInDay),
            timeRange: `${globalConst.dateTime.formatTimeAMPM(startDate)}  - ${globalConst.dateTime.formatTimeAMPM(endDate)}`,
            isAvailable: true
        }
    }


  return (
      <Row display="flex">
        <div style={{width: '120px'}}>{day}</div>
        <HourHeaderContainer display="flex">
          {
           
              slots.map((slot: availableSlotType, index: number) => (
                  <>
                      {
                          slot.start !== 0 && index === 0 && <div style={{width: `${getPercentage(slot.start - 0, MinsInDay)}%`}}></div>}
                      <Tooltip title={slot.timeRange}>
                          <SlotLabel width= {slot.percentage + "%"}>
                              {slot.timeRange}
                          </SlotLabel>
                      </Tooltip>
                      {slots[index+1] && <div style={{width: `${getPercentage(slots[index+1].start - slot.end, MinsInDay) }%`}}></div>
                      }
                  </>

              ))
          }
            </HourHeaderContainer>
        </Row>

    )
}

export default DayRow;