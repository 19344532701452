import { makeStyles } from "@mui/styles";


export default makeStyles((theme) => ({
    checkboxes: {
        "& .MuiCheckbox-root": {
            padding: '0px'
        },
        "&.MuiFormControlLabel-root": {
            margin: '2px'
        },
        "& .MuiFormControlLabel-label": {
            fontSize: '14px',
            width: '150px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    }
}))