import React, { useEffect } from "react";
import {
    Card,
    CardContent,
    Grid
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useStoreState } from "easy-peasy";

import DataTable from "../../../DataTable/DataTable";
import {
    auditLogColumns,
    auditLogDetailColumns
} from "../../../../api/clients";
import { getAuditLogs } from "../../../../api/system";
import {
    dataOfHistory
} from "../../../data/clients";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles'
export default function History(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const theme = useTheme();
    const [selectedRow, setSelectedRow] = React.useState(
        // dataOfHistory[0].details
        {
            userName: "",
            oldJsonData: "",
            newJsonData: "",
        }
    );
    const [listData, setListData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const exploringContactId = useStoreState((state) => state.exploringContactId);
    const accountId = global.account.userValue.id;

    useEffect(() => {
        global.log("History init");
        if (!!props.refTable && !!props.refId) {
            setLoading(true)
            getAuditLogs(props.refTable, props.refId).then((value) => {
                setLoading(false)
                setListData(value);
                if (value.length > 0)
                    setSelectedRow(value[0]);
            }).finally(() => setLoading(false));
        }
    }, []);

    const _handleRowSelect = (row) => {
        global.log(row);
        setSelectedRow(row.row);
        // global.log(row);
    };

    return (
        <>
            <div style={{ padding: 10, backgroundColor: 'lightgray' }}>
                <Grid container spacing={1}>
                    <Grid item lg={6} xs={6}>
                        <Card>
                            <CardContent className={classes.cardContentHistory}>
                                <div className={classes.history_title}>
                                    History
                                </div>

                                <div
                                    style={{
                                        textAlign: "center",
                                        border: "0px",
                                    }}
                                >
                                    <DataTable
                                        // rows={dataOfHistory}
                                        data={{
                                            rows: listData,
                                            // columns={historyColumns},
                                            columns: auditLogColumns,
                                            screen: "client_todo",
                                        }}
                                        headerHeight={0}
                                        onRowClick={_handleRowSelect}
                                        rowHeight={30}
                                        loading={loading}
                                        hideHeaderColumnsSortIcon
                                        multiView
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                        <Card>
                            <CardContent className={classes.cardContentHistory}>
                                <div className={classes.history_title}>Details</div>
                                <div className={baseClasses.row}>
                                    <Divider
                                        className={baseClasses.fullWidth}
                                        orientation="horizontal"
                                    />
                                </div>
                                <div className={classes.details}>
                                    {/* <div className={classes.dob}>DOB: {selectedRow.dob}</div> */}
                                    {/*<div className={classes.dob}>*/}
                                    {/*    User: - {selectedRow.userName}*/}
                                    {/*</div>*/}
                                    <div className={classes.dob}>
                                        <b>Changes:</b>
                                        {
                                            selectedRow.description?.split("<nl>").map((c, i) => <><br />{c}</>)
                                        }
                                    </div>
                                    {/*<div className={classes.dob}>*/}
                                    {/*    New Data: - {selectedRow.newJsonData}*/}
                                    {/*</div>*/}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
