import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const DAYS = [
    {
        key: "0",
        label: "S"
    },
    {
        key: "1",
        label: "M"
    },
    {
        key: "2",
        label: "T"
    },
    {
        key: "3",
        label: "W"
    },
    {
        key: "4",
        label: "T"
    },
    {
        key: "5",
        label: "F"
    },
    {
        key: "6",
        label: "S"
    }
];

const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
        margin: theme.spacing(1.5),
        padding: theme.spacing(0, 1),
        "&:not(:first-child)": {
            border: "1px solid",
            borderColor: `${theme.palette.primary.main} !important`,
            borderRadius: "50% !important"
        },
        "&:first-child": {
            border: "1px solid",
            borderColor:  `${theme.palette.primary.main} !important`,
            borderRadius: "50% !important"
        }
    }
}))(ToggleButtonGroup);

const StyledToggle = withStyles(theme => ({
    root: {
        color: `${theme.palette.primary.main} !important`,
        "&$selected": {
            color: "white !important",
            background: `${theme.palette.primary.main} !important`
        },
        "&:hover": {
            borderColor: "#d4dafc",
            background: "#d4dafc"
        },
        "&:hover$selected": {
            borderColor:`${theme.palette.primary.main} !important`,
            background: `${theme.palette.primary.main} !important`
        },
        minWidth: 32,
        maxWidth: 32,
        height: 32,
        textTransform: "unset",
        fontSize: "0.75rem"
    },
    selected: {}
}))(ToggleButton);

const ToggleDays = (props) => {
    const {showRadioDaysOfWeek } = props;
    const [daysCONST, setDaysCONST] = useState([]);
    const days = props.days != undefined ? props.days : daysCONST;
    const setDays = props.setDays != undefined ? props.setDays : setDaysCONST;
    const { handleDayToggle, weekset, errorText } = props;
    const curId = props.id + "-";
    return (
        <>
            <div id={props.id}>
                {showRadioDaysOfWeek &&
                    <RadioGroup
                        id={curId + "wkset"}
                        style = {{
                            justifyContent: 'center'
                        }}
                        row aria-label="position" name="position" value={weekset} size="small" className="justify">
                        <FormControlLabel
                            id={curId + "sunthu"}
                            style={{ fontSize: '0.8rem' }}
                            value="sunthu"
                            control={<Radio color="primary" />}
                            label="Sun - Thu"
                            labelPlacement="top"
                            size="small"
                            onChange={(event) => handleDayToggle(event.target.value)}
                        />
                        <FormControlLabel
                            id={curId + "sunfri"}
                            value="sunfri"
                            control={<Radio color="primary" />}
                            label="Sun - Fri"
                            labelPlacement="top"
                            size="small"
                            onChange={(event) => handleDayToggle(event.target.value)}
                        />
                        <FormControlLabel
                            id={curId + "cus"}
                            value="custom"
                            control={<Radio color="primary" />}
                            label="Custom"
                            labelPlacement="top"
                            size="small"
                            onChange={(event) => handleDayToggle(event.target.value)}

                        />
                    </RadioGroup>
                }

                <StyledToggleButtonGroup
                    id={curId + "wkday"}
                    size="small"
                    arial-label="Days of the week"
                    value={days.filter(d => (Number.isInteger(d)))}
                    onChange={(event, value) => setDays(value)}
                >
                    {DAYS.map((day, index) => (
                        <StyledToggle id={curId + "slcday-" + day.key} key={day.key} value={index} aria-label={day.key}>
                            {day.label}
                        </StyledToggle>
                    ))}
                </StyledToggleButtonGroup>
            </div>
            {errorText && <div style={{color: 'red'}}>{errorText} </div>}
        </>
    );
};

export default ToggleDays;
