import { action, thunk, computed } from "easy-peasy";
import {
    getDashboardActiveSchedules,
    getDashboardActiveClients,
    getDashboardPaymentTypeSchedules,
    getDashboardClientScheduleStatusList,
    getDashboardCards,
    getStatusStepDuration,
    getDashboardStatusStepDuration
} from '../api/dashboard'
import SCHEDULE_PERIODICITY_CONSTANTS from "../_helpers/constants/schedule_periodicity_constants";
import { getScheduleStringForProfessional } from "../_helpers/schedule";


const getWeekdaysString = (weekdays, includeAndStringBetweenLastDays = false) => {
    return global.dateTime.getWeekdaysString(weekdays, includeAndStringBetweenLastDays);
}

const defaultStore = {
    loading: false,
    todos: [],
    dashboardCards: null,
    statusStepDurationAvg: null,
    clientWithYungerleitList: [],
    clientWithProfessionalList: [],
    progresses: [],
    timesheets: [],
    schedules: [],
    activeShedules: [],
    paymentTypeSchedules: [],
    clientScheduleStatusList: [],
    statusStepDuration: [],
    setLoading: action((state, loading) => {
        state.loading = loading;
    }),
    setTodos: action((state, todos) => {
        state.todos = todos;
    }),
    setDashboardCards: action((state, cardsInfo) => {
        state.dashboardCards = cardsInfo
    }),
    setDashboardStatusStepDuration: action((state, data) => {
        state.statusStepDurationAvg = data
    }),
    setProgress: action((state, progresses) => {
        state.progresses = progresses;
    }),
    setTimesheet: action((state, timehseets) => {
        state.timesheets = timehseets;
    }),

    setSchedules: action((state, schedules) => {
        state.schedules = schedules;
    }),

    setActiveSchedules: action((state, schedules) => {
        state.activeShedules = schedules;
    }),

    setPaymentTypeSchedules: action((state, schedules) => {
        state.paymentTypeSchedules = schedules;
    }),

    setClientScheduleStatus: action((state, schedules) => {
        state.clientScheduleStatusList = schedules;
    }),

    setClientWithYungerleitServiceList: action((state, clients) => {
        state.clientWithYungerleitList = clients;
    }),

    setClientWithProfessionalServiceList: action((state, clients) => {
        state.clientWithProfessionalList = clients;
    }),

    setStatusStepDuration: action((state, data) => {
        state.statusStepDuration = data;
    }),

    getStatusStepDurationData: thunk(async (actions) => {
        actions.setLoading(true);
        const result = await getStatusStepDuration();
        actions.setStatusStepDuration(result)
        actions.setLoading(false);
        return result;
    }),

    getDashboardCardsInfo: thunk(async (actions) => {
        actions.setLoading(true);
        const result = await getDashboardCards();
        actions.setDashboardCards(result)
        actions.setLoading(false);
        return result;
    }),

    getDashboardStatusStpDurationAvg: thunk(async (actions) => {
        actions.setLoading(true);
        const result = await getDashboardStatusStepDuration();
        actions.setDashboardStatusStepDuration(result)
        actions.setLoading(false);
        return result;
    }),

    getDashboardActiveClient: thunk(async (actions, clientWith) => {
        actions.setLoading(true);
        let items = await getDashboardActiveClients(clientWith);
        clientWith === 'professional' ?
            actions.setClientWithProfessionalServiceList(items) :
            actions.setClientWithYungerleitServiceList(items)
        actions.setLoading(false);
        return items;
    }),



    getDashboardActiveSchedules: thunk(async actions => {
        actions.setLoading(true);
        let items = (await getDashboardActiveSchedules()).map(d => {
            return {
                ...d,
                clientScheduleId: d.clientScheduleId,
                createdOn: d.createdOn,
                startTime: global.dateTime.filterDefaultDate(d.startTime),
                endTime: global.dateTime.filterDefaultDate(d.endTime),
                providerName: d.providerName,//
                clientName: d.clientName,//
                providerId: d.providerId,//
                clientId: d.clientId,//
                schedule: d.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ? getWeekdaysString(d.daysOfWeek) : "", //
                startDate: global.dateTime.filterDefaultDate(d.startDate),
                endDate: global.dateTime.filterDefaultDate(d.endDate),
                rate: d.rate,
                ratePeriod: d.ratePeriod,
                type: d.type,
                meargen: d.meargenName,
                status: d.status,
                dateApproved: d.dateApproved,
                hoursPerWeek: d.hoursPerWeek % 1 === 0 ? d.hoursPerWeek?.toFixed() : d.hoursPerWeek?.toFixed(1),
                totalDollarsPerWeek: d.totalDollarsPerWeek?.toFixed(),
            }
        });
        actions.setLoading(false);
        actions.setActiveSchedules(items);
        return items
    }),

    getPaymentTypeSchedules: thunk(async (actions, payload) => {
        const { paymentType, onGoingStatus } = payload
        actions.setLoading(true);
        let items = (await getDashboardPaymentTypeSchedules(paymentType, onGoingStatus)).map((d) => {
            return {
                ...d,
                meargen: d.meargenName,
                schedule: d.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ? getWeekdaysString(d.daysOfWeek) : "",
                hoursPerWeek: d.hoursPerWeek % 1 === 0 ? d.hoursPerWeek?.toFixed() : d.hoursPerWeek?.toFixed(1),
                totalDollarsPerWeek: d.totalDollarsPerWeek,
            }
        })
        actions.setLoading(false);
        actions.setPaymentTypeSchedules(items);
        return items
    }),

    getClientScheduleStatusList: thunk(async actions => {
        actions.setLoading(true);
        let items = await getDashboardClientScheduleStatusList()
        actions.setLoading(false);
        actions.setClientScheduleStatus(items);
        return items
    })
}
export default defaultStore;