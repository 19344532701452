import { alpha } from "@mui/material/styles";

const  buttons = theme => ({
    table: {
        width: '100%',
    },
    collapsableTable: {
        marginTop: '1rem',
    },
    tableHeaderFont: {
        fontWeight: 500,
        fontSize: 14,
        fontFamily: "Roboto Helvetica Arial sans-serif",
    },
      tableBodyFont: {
        fontWeight: 500,
        fontSize: 12,
        fontFamily: "Roboto Helvetica Arial sans-serif",
    },
    
})

export default buttons