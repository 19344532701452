import * as constant from '../constants'

export const strings = {
    toTitleCase,
    firstLetterLowerCase,
    firstLetterUpperCase,
    getRichTextObjFromString,
    getStringFromRichTextObj,
    parseStringToObj,
    parseObjToString,
    removeEmptySpaces,
    convertArrToString,
    convertStringToArr,
    validateEmail,
    validatePassword,
    parseStringMetaData,
    getShortenedName
}

function toTitleCase(str) {
    return str.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    //return str.replace(/\w\S*/g, function (txt) {
    //    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    //});
}

function firstLetterLowerCase(string) {
    if (!string)
        return string
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function firstLetterUpperCase(string) {
    if (!string)
        return string
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getRichTextObjFromString(str) {
    let result = {};
    if (!str) {
        result.value = ""
        result.formatting = {}
        return result;
    }
    const formattingEnd = str.toString().indexOf('*')

    if (formattingEnd < 0 || !IsJsonString(str.substring(0, formattingEnd))) {
        result.formatting = {};
        result.value = str
    }
    else {
        result.formatting = JSON.parse(str.substring(0, formattingEnd));
        result.value = str.substring(formattingEnd + 1)
    }
    return result;
}

function getStringFromRichTextObj(richtextObj) {
    const formattingStr = JSON.stringify(richtextObj.formatting)
    const value = richtextObj.value ? richtextObj.value : ''
    return formattingStr + '*' + value;
}

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function parseStringToObj (filterStr, propSep, valueSep) {
    let res = filterStr.split(propSep).reduce(function(obj, str, index) {
      let strParts = str.split(valueSep);
        if (strParts[0] && strParts[1]) { //<-- Make sure the key & value are not undefined
            obj[strParts[0].replace(/\s+/g, '')] = strParts[1].trim(); //<-- Get rid of extra spaces at beginnig of value strings
        }
        return obj;
        }, {});
    return res
}

function parseObjToString (filterObj, propSep, valueSep) {
    let strRes = ""
    for (const property in filterObj) {
      strRes  = !!strRes ? `${strRes + propSep}`.concat(`${property + valueSep}${filterObj[property]}`):
      "".concat(`${property}=${filterObj[property]}`)
      
    }
    return strRes;
}

function removeEmptySpaces (str) {
    return str.replace(/\s/gm, '');
}

function convertArrToString (arr, seprator=",") {
    return arr.join(seprator)
}

function convertStringToArr (str, seprator=",") {
    return str.split(seprator);
}

function validateEmail (email, isRequired = false) {
    if (!email && isRequired) {
        return {
            isValid : false,
            message: 'Email is Required'
        }
    }
    let checValid = constant.EMAIL_REGEX.test(email);
    if (!checValid) {
        return {
            isValid : false,
            message: constant.EMAIL_VALIDATION_MSG
        }
    }
    return  {
        isValid : true,
        message: ''
    }
};

function validatePassword(password) {
        let minLength = 8;
        let hasUpper = /[A-Z]/;
        let hasLower = /[a-z]/;
        let hasSpecial = /[!#$%&()*+-_,.:;<>=?@\[\]~`^{}]+/;
        let errors = "";
        if (password.length < minLength) {
            errors += "Password should be at least 8 characters long\n";
        }
        if (!hasUpper.test(password)) {
            errors += "Password should contain at least one uppercase letter\n";
        }
        if (!hasLower.test(password)) {
            errors += "Password should contain at least one lowercase letter\n";
        }
        if (!hasSpecial.test(password)) {
            errors += "Password should contain at least one special character\n";
        }
        let invalidChars = checkInvalidSpecialChars(password);
        if (invalidChars)
            errors += "Invalid characters: " + invalidChars;
        return errors;
}
    
function checkInvalidSpecialChars(password) {
    let invalidSpecialChars = "";
    let validSpecialChars = "!#$%&()*+-_,.:;<>=?@\[\]~`^{}";
    for (let i = 0; i < password.length; i++) {
        if (!validSpecialChars.includes(password[i]) && /\W/.test(password[i])) {
            invalidSpecialChars += password[i];
        }
    }
    return invalidSpecialChars;
}

 
function parseStringMetaData (str) {
    if(!str) return str;
    return getRichTextObjFromString(str).value
}


const SHORTENED_NAMES = {
    yeshiva: "ysv",
    client: "cli",
    employee: "ee",
    yungerman: "yun",
    professional: "pro",
    user: "usr",
    users: "usr",
    info: "ifo",
    list: "lis",
    first: "fst",
    last: "lst",
    name: "nme",
    schedule: "sch",
    role: "rle",
    select: "slc",
    date: "dte",
    text: "txt"
  };
  
  function getShortenedName (names){
      return names
          .map(name => {
              if (typeof name === "string") {
                  return SHORTENED_NAMES[name.toLowerCase()] || name;
              } else {
                  return name.map(n => SHORTENED_NAMES[n.toLowerCase()] || n.toLowerCase()).join("");
              }
          })
          .join("-");
  }
  