import React from "react";
import { useState } from "react";
import clsx from "clsx";
import {
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
} from "@mui/material";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";
import ProgramFormStatus from "./ProgramFormStatus";
import ProgramInputSingle from "./ProgramInputSingle";
import PaymentInfo from "../../../../components/UI/PaymentInfo"

const ProgramLight = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const fields = useStoreState((state) => state.clients.fields);
    const fieldsLight = useStoreState((state) => state.clients.fieldsLight);
    const setFields = useStoreActions((actions) => actions.clients.setFields);

    const padLeadingZeros = (num, size) => {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    };

    const handleFields = (event) => {
        const { name, value, type } = event.target;
        if (type === "checkbox") {
            setFields({ ...fields, [name]: event.target.checked });
        } else {
            setFields({ ...fields, [name]: value });
        }

        props.savestatusaction(1);
    };

    return (
        <Card className={baseClasses.rootFlex}>
            <CardHeader
                color="primary"
                className={classes.cardHeader}
                subheader="Cash"
            />
            <CardContent
                className={clsx(baseClasses.row, "row", classes.cardSpaceStyle)}
            >
                <PaymentInfo onFieldChange={() => props.onFieldChange()} savestatusaction={props.savestatusaction}/>
                <div>
                    <ProgramFormStatus
                        status={fields.light_status}
                        marginTop={9}
                    ></ProgramFormStatus>
                </div>
                {/*<div>*/}
                {/*    <FormControl*/}
                {/*        component="fieldset"*/}
                {/*        className={clsx(classes.marginSpaceOne)}*/}
                {/*    >*/}
                {/*        <FormGroup>*/}
                {/*            <FormControlLabel*/}
                {/*                control={*/}
                {/*                    <Checkbox*/}
                {/*                        color="primary"*/}
                {/*                        onChange={handleFields}*/}
                {/*                        name="light_active"*/}
                {/*                        checked={fields.light_active}*/}
                {/*                    />*/}
                {/*                }*/}
                {/*                label="Active"*/}
                {/*                labelPlacement="start"*/}
                {/*            />*/}
                {/*        </FormGroup>*/}
                {/*    </FormControl>*/}
                {/*</div>*/}
            </CardContent>
            <CardActions></CardActions>
        </Card>
    );
};

export default ProgramLight;
