import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CancelDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handlerOkClick = () => {
    props.onOk();
  };

  const handlerCancelClick = () => {
    props.onCancel();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.onOpen}
      onClose={handlerCancelClick}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Are you sure you want to cancel your changes?"}
      </DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handlerOkClick} color="primary">
          Yes
        </Button>
        <Button onClick={handlerCancelClick} color="primary" autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
