/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useStoreActions } from "easy-peasy";
import { makeStyles } from "@mui/styles";
import {
  getEmployeeDetails,
  removeEmployeeFromList,
} from "../../api/employees";
import DeleteProfessionalDialog from "./DeleteProfessionalDialog";
import ProfessionalView from "./ProfessionalView";
import baseStyles from '../../assets/styles'
const useStyles = makeStyles((theme) => ({
  content: {
      flexGrow: 1,
      padding: "12px 0px 12px 0px",
      margin: "12px 26px",
      "& .MuiTabs-indicator": {
          backgroundColor: "transparent",
      },
      "& .MuiCardContent-root:last-child": {
          padding: "0px",
      },
      "& .MuiDataGrid-root .MuiDataGrid-row": {
          cursor: "pointer",
          fontSize: "12px",
      },
      /*'& .MuiPaper-outlined': {
              border: '0px',
          }*/
      "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
          backgroundColor: theme.palette.primary.main,
          color: "#FFFFFF",
      },
      "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
          display: "none !important",
      },
      "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
          visibility: "hidden",
      },
      "& .MuiDataGrid-root": {
          border: "0px",
      },
      "& .MuiTab-root": {
          color: "#FFFFFF"
      },
  },
  
}));
export default function Professionals() {
  const classes = useStyles();
  const baseClasses = baseStyles();
  const [id, setId] = React.useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  
  const fetchProfessionals = useStoreActions((actions) => actions.professional.fetchProfessionals);

  const editEmployeeData = null;

  useEffect(() => {
    if (editEmployeeData == null || editEmployeeData == undefined) return;
    getEmployeeDetails(editEmployeeData).then((response) => {
      global.log(response);
    });
  }, [editEmployeeData]);

  const confirmDeleteHandler = () => {
    removeEmployeeFromList(id).then((response) => {
      if (response) fetchProfessionals();
    });
    setIsDeleteDialogOpen(false);
  };

  const cancelDeleteHandler = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <main className={classes.content}>
      <div className={baseClasses.toolbar} />
      <ProfessionalView onChangeId = {setId} onRefreshData = {() => fetchProfessionals()} />

      {isDeleteDialogOpen && 
        <DeleteProfessionalDialog
          openDialog={isDeleteDialogOpen}
          onConfirm={confirmDeleteHandler}
          onCancel={cancelDeleteHandler}
        />
      }
    </main>
  );
}
