import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { BoxContainer, Title } from '../../../../../assets/styles/styledComponents';
import { weekDays } from '../../../../../constants';
import DayRow from './DayRow';
import HourHeader from './HourHeader';
import { availabilityDataType } from './types';
import { getEmployeeAvailabilty } from '../../../../../api/employees';
import LoadingBar from '../../../../LoadingBar/LoadingBar';
import { ScheduleAvailabilityCalculator } from '../../../../../_helpers/schedule/validators/client-schedule';
import SCHEDULE_PERIODICITY_CONSTANTS from '../../../../../_helpers/constants/schedule_periodicity_constants';

const availability = [
    {
        "day": 0,
        "openWindows": [

            {
                "start": "Feb 19, 2020 4:20:30",
                "end": "Feb 19, 2020 19:15:30"
            },

        ]
    },
    {
        "day": 1,
        "openWindows": [
            {
                "start": "1901-01-01T12:00:00.000Z",
                "end": "1901-01-01T19:00:00.000Z"
            }
        ]
    },
    {
        "day": 2,
        "openWindows": [

            {
                "start": "Feb 19, 2020 12:15:30",
                "end": "Feb 19, 2020 15:15:30"
            },
            {
                "start": "Feb 19, 2020 16:15:30",
                "end": "Feb 19, 2020 20:15:30"
            },
        ]
    },
    {
        "day": 3,
        "openWindows": [
            {
                "start": "1901-01-01T14:00:00.000Z",
                "end": "1901-01-01T19:00:00.000Z"
            }
        ]
    },
    {
        "day": 4,
        "openWindows": [
            {
                "start": "1901-01-01T14:00:00.000Z",
                "end": "1901-01-01T19:00:00.000Z"
            },
            {
                "start": "1901-01-01T20:00:00.000Z",
                "end": "1901-01-01T22:00:00.000Z"
            }
        ]
    },
    {
        "day": 5,
        "openWindows": []
    },
    {
        "day": 6,
        "openWindows": []
    }
]


const parseSchedule = (schedule: any) => {
    const globalConst: any = global;
    let startTime, endTime;
    if (!schedule.startTime) {
        startTime = new Date();
        endTime = new Date();
    }
    else {
        startTime = schedule.startTime ? globalConst.dateTime.parseTimeToDate(globalConst.dateTime.getTimeFromDateString(schedule.startTime)) : null;
        endTime = schedule.endTime ?
            globalConst.dateTime.parseTimeToDate(globalConst.dateTime.getTimeFromDateString(schedule.endTime)) :
            new Date(new Date(startTime).setHours(startTime.getHours() + 12));
    }

    return {
        startTime,
        endTime,
        startDate: schedule.date ? globalConst.dateTime.getDateObject(schedule.date) : new Date(),
        endDate: schedule.endDate ? globalConst.dateTime.getDateObject(schedule.endDate) : null,
        days: schedule.days ? schedule.days.split(',') : [],
        periodicity: schedule.periodicity ? schedule.periodicity : SCHEDULE_PERIODICITY_CONSTANTS.WEEKS,
        everyXOfPeriods: schedule.everyXOfPeriods,
    };
}

const NetAvailability = (props: any) => {
    const { availabilityRows, schedules } = props;
    const [availabilityData, setAvailabilityData] = React.useState<availabilityDataType[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const exploringId = useStoreState((state: any) => state.exploringId);

    useEffect(() => {
        const openWindows: availabilityDataType[] = new ScheduleAvailabilityCalculator().getProviderOpenWindows(schedules.map((s: any) => parseSchedule(s)), availabilityRows.map((s: any) => parseSchedule(s.schedule)));
        setAvailabilityData(openWindows)
    }, [availabilityRows, schedules]);

    const loadAvailabiltyApi = (id: number) => {
        setLoading(true)
        getEmployeeAvailabilty(id).then((data: availabilityDataType[]) => {
            if (!!data) {
                setAvailabilityData([...data])
            }
            setLoading(false)
        })
    }

    return (
        <BoxContainer background="#fff">
            <Title padding="5px 10px">
                Net Availability
            </Title>
            {loading && <LoadingBar />}
            <BoxContainer>
                <HourHeader />
                {
                    availabilityData.length > 0 && availabilityData.map((item: availabilityDataType, index: number) => {

                        return <div>
                            {/*{JSON.stringify(item.openWindows)}*/}
                            <DayRow day={weekDays[item.day]} availability={item.openWindows} />
                        </div>
                    })
                }
            </BoxContainer>
        </BoxContainer >
    )
}

export default NetAvailability