import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Chip,

} from "@mui/material";
import Button from "@mui/material/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import * as constant from "../../../../constants";
import baseStyles from '../../../../assets/styles';
import useUnsavedChangesWarn from "../../../../hooks/useUnsavedChangesWarn";
import useKeyTracker, { KeyEvent } from '../../../../hooks/useKeyTracker';
import AddTodoForm, { validateAllFields } from './AddTodoForm';
import { strings } from '../../../../_helpers/strings'

export default function ToDo(props) {
    const [todo, setTodo] = useState({});
    const baseClasses = baseStyles();

    const [valdiator_AddToDoForm, setValidator_AddToDoForm] = useState();

    const [loaderDialog, setLoaderDialog] = React.useState(false);

    const [validator_AddToDoForm, setValidator] = useState(null);


    const exploringId = useStoreState((state) => state.exploringId);
    const exploringType = useStoreState((state) => state.exploringType);

    const [isTask, setIsTask] = useState(true); // State to track selection

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    useEffect(() => {
        resetAllData();
    }, []);


    const handleChipClick = (taskSelected) => {
        setIsTask(taskSelected);
    };

    function resetAllData() {
        setTodo({
            title: "",
            description: "",
            startDate: null,
            dueDate: null,
            completedDate: null,
            assignee_contactId: null,
        })

        if (!_.isEmpty(props.editData)) {
            setTodo(props.editData)
        }
        setClean();
    }
    useEffect(() => {
        if (!_.isEmpty(props.editData)) {
            setTodo(props.editData)
        }
    }, [props.editData])

    const [errors, setErrors] = useState({})
    const saveTodo = () => {
        const validate = validateAllFields(todo);
        const isValid = validate.isValid;
        setErrors(validate.errors);
        // import a hook that manages the state and validation of the form
        // pass that handle to the UI-form component so it can be used in both places at same
        // when validating here formState.validate() it will update the errors messages there
        if (isValid) {
            const saveToDoPayload = {

                title: todo.title,
                table: exploringType,
                assignee_ContactId: todo.assignee_ContactId,
                primaryId: todo.primaryId ? todo.primaryId : exploringId,
                description: todo.description,
                startDate: todo.startDate,
                dueDate: todo.dueDate,
                completedDate: todo.completedDate,
            };

            let todoId = todo.id ? todo.id : -1;
            if (global.isEmpty(todoId))
                todoId = -1;

            if (global.fetchWrapper.inProgress) {
                closeModal();
                return;
            }
            setLoading();
            return global.fetchWrapper
                .post(constant.TODO_SAVE + todoId, saveToDoPayload)
                .then(
                    (response) => {
                        setClean();
                        closeModal(true);
                    },
                    (error) => {
                        global.log("error", error);
                        //closeModal();
                        global.AjaxFailToast();
                    }
                );
        }
    };
    const timesheetTabShowShortcut = useKeyTracker({
        keyEvents: [{ key: 'Enter', ctrlKey: true /*, action: saveTodo*/ }]
    });
    useEffect(() => {
        if (timesheetTabShowShortcut.keyExecutions.length > 0) {
            saveTodo(todo);
        }
    }, [timesheetTabShowShortcut.keyExecutions.length])

    const handleClose = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown"))
            return;
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        closeModal();
    };
    const closeModal = (reloadData = false) => {
        props.onCloseModal();
        if (reloadData && typeof props.reloadData === 'function')
            props.reloadData();
        resetAllData();
        endLoading();
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.isOpenCreateModal}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle
                    className={baseClasses.dialogTitle}
                    id="max-width-dialog-title"
                >
                    {props.editData?.id > 0 ? "Edit" : "Add"} To Do
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" justifyContent="center" mb={2}>
                        <Chip
                            label="Task"
                            clickable
                            color={isTask ? "primary" : "default"}
                            onClick={() => handleChipClick(true)}
                        />
                        <Chip
                            label="Note"
                            clickable
                            color={!isTask ? "primary" : "default"}
                            onClick={() => handleChipClick(false)}
                            style={{ marginLeft: "8px" }}
                        />
                    </Box>
                    {loaderDialog ? (
                        <div>Loading...</div>
                    ) : (
                        <AddTodoForm
                            todo={todo}
                            setTodo={setTodo}
                            setDirty={setDirty}
                            setValidator={setValidator}
                            errors_prm={errors}
                            isTask={isTask}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveTodo} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
