
import React from 'react'
import {FormControlLabel, Checkbox} from '@mui/material';
import { DivContainer, Flex, Text, BoldTitle } from '../../../../../assets/styles/styledComponents';

interface CheckBoxEditorProps {
    label: string,
    lablePlacement?: 'start' | any,
    mode?: 'read' | 'edit',
    disabled?: boolean
    hide?:boolean, 
    variant?: 'outlined' | 'filled' | 'standard',
    name: string,
    value: string | number | any,
    error: boolean,
    helperText: string,
    fullWidth?: boolean,
    onChange: Function
}

const CheckBoxEditor: React.FC<CheckBoxEditorProps> = ({value, onChange, label, name, mode, lablePlacement = 'start', hide=false, }) => {
    if (hide) {
        return null
    } else {
        return (
            <DivContainer margin="10px">
                {
                    mode === 'edit' && 
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={value}
                                onChange={(e) => onChange(e.target.checked, name)}
                            />
                        } 
                        labelPlacement={lablePlacement}
                        label={label}
                    />
                }
                {
                    mode === 'read' && 
                        <Flex width="100%">
                            <DivContainer width="40%" >
                                <BoldTitle>{label}:</BoldTitle>
                            </DivContainer>
                            <DivContainer width="60%">
                                <Text>{!!value ? `Yes`: 'No'}</Text>
                            </DivContainer>
                        </Flex>
                }
            </DivContainer>
        )
    }
}

export default CheckBoxEditor