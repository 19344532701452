import { useState, useEffect } from 'react';

function useFetcher(url: String) {
  const [data, setData] = useState<Object | any>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const [error, setError] = useState<Object | any>(null);
  const globalScope:any = global;
  useEffect(() => {
      setLoading(true)
      setData(null);
      setError(null);
      if (!!url) {
        globalScope.fetchWrapper
              .get(url)
              .then((response:any) => {
                      setData(response);
                      setError(null);
                      setLoading(false)
              })
              .catch((error:any) => {
                  setError(null);
                  setLoading(false)
              });
      }
  }, [url])

  return [ data, loading, error ]
}
export default useFetcher;