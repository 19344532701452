import React, { useEffect, useState } from "react";
import {
    FormControl,
    FormHelperText,
    Grid,
    Button,
    Input,
    TextField,
    InputLabel,
    MenuItem, Select,
    Card, CardContent, Typography, Divider
} from "@mui/material";
import { SectionComponent, NamesToStringComp, CompSelectbox, CompTextField, CompTextMulti } from "./components";
import baseStyle from '../../../assets/styles';
import Loader from '../../../components/common/Loader';
import UploadEmployeeTemplateDocument from "../../UploadComponents/UploadEmployeeTemplateDocument";
import _ from "lodash";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import { DivContainer } from "../../../assets/styles/styledComponents";
import SelectEditor from "../../../components/common/UI/Inputs/Select/SelectEditor";
import { users } from "../../../redux/users"

const CompUploadDocument = ({ label, viewMode, setIsLoading }) => {
    const exploringId = useStoreState((state) => state.exploringId);
    return (
        <>
            {!global.isEmpty(exploringId) && <UploadEmployeeTemplateDocument
                employeeID={exploringId}
                label={label}
                viewMode={viewMode}
                setIsLoading={setIsLoading}
                setIsUploaded={(uploaded) => {
                    // if (fieldsDocuments[label] != uploaded)
                    //     setFieldsDocuments({ ...fieldsDocuments, [label]: uploaded })
                }}
            />
            }
        </>
    );
};

const ThirdSection = ({ sectionState, positionValues, employee, yungermanId, professionalId, setSectionState, specialtyValues, paymentValues, professionalTypes, rollbackChanges, confirmUnsavedChanges }) => {
    const baseClasses = baseStyle();
    const [options, setOptions] = useState([]);
    const [roshMaamidName, setRoshMaamidName] = useState('');

    const [employeeDetail, setEmployeeDetail] = useState(employee);
    const [loading, setLoading] = useState(false);
    useEffect(() => { setEmployeeDetail(employee); }, [employee]);

    let sectionState2 = sectionState;
    const exploringId = useStoreState((state) => state.exploringId);
    const lhisalosApprovalStatusList = [{ id: 1, name: "Approved" }, { id: 43, name: "Not Approved" }, { id: 54, name: "Pending" },]

    useEffect(() => {
        if (employeeDetail?.roshMaamidNew) {
            const fullName = `${employeeDetail.roshMaamidNew.firstName} ${employeeDetail.roshMaamidNew.lastName}`;
            setRoshMaamidName(fullName);
        }
    }, [employeeDetail?.roshMaamidNew]);

    useEffect(() => {
        users("RoshMaamid").then((fetchedUsers) => {
            const roleOptions = fetchedUsers.map((user) => ({
                label: user.name,
                value: user.id,
            }));
            setOptions(roleOptions);
        });
    }, []);




    return (
        <DivContainer position="relative">
            <Loader loading={loading} />
            <SectionComponent headerTitle="רוחניות" onSave={() => { }}
                employeeDetail={employeeDetail}
                state={sectionState}
                onStateChange={setSectionState}
                rollbackChanges={rollbackChanges}
                confirmUnsavedChanges={confirmUnsavedChanges}
                readDOM={
                    <Grid
                        container
                        className={baseClasses.grid}
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>List of Yeshivas Previously Worked: </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['prevYeshivas']) ? "-" : employeeDetail['prevYeshivas']}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Experience : </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['experience']) ? "-" : employeeDetail['experience']}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Notes : </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['note']) ? "-" : employeeDetail['note']}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Specialties : </div>
                            <div className={baseClasses.fieldvalue}>
                                {
                                    global.isEmpty(employeeDetail['specialties'])
                                        ? "-"
                                        : employeeDetail['specialties']
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>LHisalos Approved : </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['lHisalosApprovalStatus']) ? "-" : employeeDetail['lHisalosApprovalStatus']}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Rosh Maamid: </div>
                            <div className={baseClasses.fieldvalue}>
                                {global.isEmpty(employeeDetail['roshMaamidNew'])
                                    ? ''
                                    : `${employeeDetail['roshMaamidNew'].firstName} ${employeeDetail['roshMaamidNew'].lastName}`}
                            </div>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Lhisalos Contract : </div>
                            <div className={baseClasses.fieldvalue}>
                                <CompUploadDocument
                                    label={"LhisalosContract"}
                                    viewMode={"read"}
                                    setIsLoading={(isLoading) => setLoading(isLoading)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }
                editDOM={
                    <Grid
                        container
                        className={clsx(baseClasses.grid)}
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <CompTextField variant="standard" xs={12} label="List of Yeshivas Previously Worked" name="prevYeshivas" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail} />
                        <CompTextMulti variant="standard" xs={12} label="Experience" name="experience" employeeDetail={employeeDetail} setDetail={setEmployeeDetail} />
                        <CompTextMulti variant="standard" xs={12} label="Note" name="note" employeeDetail={employeeDetail} setDetail={setEmployeeDetail} />
                        <CompSelectbox variant="standard" label="Specialties" name={"specialties"}
                            value={global.isEmpty(employeeDetail.specialties) ? [] : employeeDetail.specialties.split(",")}
                            employeeDetail={employeeDetail}
                            setDetail={setEmployeeDetail}
                            setEmployeeDetail={setEmployeeDetail}
                            //positionValues={positionValues}
                            //specialtyValues={specialtyValues}
                            //paymentValues={paymentValues}
                            //professionalTypes={professionalTypes}
                            listValues={specialtyValues}
                        >
                        </CompSelectbox>
                        <CompSelectbox variant="standard" label="LHisalos Approved" name={"lHisalosApprovalStatus"}
                            value={employeeDetail.lHisalosApprovalStatus}
                            employeeDetail={employeeDetail}
                            setDetail={setEmployeeDetail}
                            setEmployeeDetail={setEmployeeDetail}
                            //positionValues={positionValues}
                            //specialtyValues={specialtyValues}
                            //paymentValues={paymentValues}
                            //professionalTypes={professionalTypes}
                            listValues={lhisalosApprovalStatusList}
                        >
                        </CompSelectbox>
                        <Grid item xs={4}>
                            <SelectEditor
                                label="Rosh Maamid"
                                id="rosh-maamid-select"
                                multiple={false}
                                fullWidth={true}
                                name="roshMaamid"
                                value={roshMaamidName}
                                onChange={(e) => {
                                    console.log(e);
                                    const selectedValue = e;

                                    if (selectedValue) {
                                        setEmployeeDetail({ ...employeeDetail, roshMaamidId: selectedValue });
                                        const selectedOption = options.find(option => option.value === selectedValue);

                                        if (selectedOption) {
                                            setRoshMaamidName(selectedOption.label);
                                        }
                                        setRoshMaamidName(selectedOption.label);
                                    } else {
                                        console.error("No value found in the event.");
                                    }
                                }}
                                selectedVariant="chip"
                                options={options}
                                error={!!employeeDetail.roshMaamid?.error}
                                helperText={employeeDetail.roshMaamid?.error || ''}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            {CompUploadDocument({
                                label: "LhisalosContract",
                                viewMode: "edit"
                            })}
                        </Grid>
                    </Grid>
                }
            ></SectionComponent>
        </DivContainer>
    )
};

export default ThirdSection