import React from 'react';
import { makeStyles } from "@mui/styles";
import baseStyles from '../../assets/styles'

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    
}));

export default function Finanace() {
    const classes = useStyles();
    const baseClasses = baseStyles();
    return (
        <main className={classes.content}>
            <div className={classes.baseClasses} />
            <h1>Finanace</h1>
        </main>
    );
}