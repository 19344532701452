import React, {useRef, useEffect} from 'react'

const useInterval = (callback, time) => {
    const saveReference = useRef();
    useEffect(() => {
        saveReference.current = callback;
    },[callback])
    useEffect(() => {
        const handler = (...arg) => saveReference.current(...arg);
        if (!!time) {
            const id = setInterval(handler, time);
            // Clear it
            return () => clearInterval(id);
        }
    }, [time])
};
export default useInterval;