import React, { useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    InputAdornment,
    TextField
} from '@mui/material';
import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from '@date-io/date-fns';
import useStyles from "../styles";
import { numbers } from "../../../../_helpers/numbers";
import baseStyles from '../../../../assets/styles'

const ServiceDetails = ({ label, name, fieldsProgramDetail, setFieldsProgramDetail, setIsSaveEnable }) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [value, setValue] = useState({
        activeStart: fieldsProgramDetail[(name + '_activeStart')],
        activeEnd: fieldsProgramDetail[(name + '_activeEnd')],
        hoursPerWeek: fieldsProgramDetail[(name + '_hoursPerWeek')],
        maxHoursPerDay: fieldsProgramDetail[(name + '_maxHoursPerDay')],
        maxSessionsPerWeek: fieldsProgramDetail[(name + '_maxSessionsPerWeek')],

    });

    useEffect(() => { global.log("this hour comonent MOUNTED") }, [])

    const inputHandler = (e) => {
        let eVal = e.target.value;
        let initialValue = e.target._wrapperState.initialValue;
        if (!numbers.isNumericOrEmpty(eVal))
            e.target.value = initialValue;
        else
            e.target.value = eVal
                .toString()
                .slice(0, 4);
    }

    //global.log('the hour component ran away...');
    return (
        <Grid
            container
            xs={12}
            columnSpacing={2}
            direction={'row'}
            className={baseClasses.document_upload}
            style={{ padding: "5px", margin: "15px", marginBottom: "5px", border: "0.5px solid rgba(0,0,0,0.2)", borderRadius: "5px" }}
        >
            <Grid item xs={12}>
                <FormLabel style={{ fontSize: "14px", minWidth: "100px" }}>
                    {label}
                </FormLabel>
            </Grid>
            {/* <TextField
                    type={"date"}
                    label={"Date Approved"}
                    onChange={(event) => {
                        setValue({
                            ...value,
                            activeStart: event.target.value
                        });
                        setIsSaveEnable(true);
                    }}
                    defaultValue={value.activeStart}
                    value={new Date(value.activeStart)}
                    onBlur={(event) => {
                        setFieldsProgramDetail({
                            ...fieldsProgramDetail,
                            [(name + "_activeStart")]: event.target.value,
                        });
                    }}
                /> */}
            {name.toLowerCase().startsWith("comforthealth") &&
                <>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                id={label + "active-start"}
                                disableToolbar
                                inputVariant="standard"
                                format="MM/dd/yyyy"
                                label="Active Date"
                                size="small"
                                //style={{ marginRight: 4 }}
                                //error={fieldErrors.date != null}
                                //helperText={fieldErrors.date}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={value.activeStart ? value.activeStart : null}
                                onChange={(val) => {
                                    setValue({
                                        ...value,
                                        activeStart: val
                                    });
                                    setFieldsProgramDetail({
                                        ...fieldsProgramDetail,
                                        [(name + "_activeStart")]: val,
                                    });
                                    setIsSaveEnable(true);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                id={label + "active-start"}
                                disableToolbar
                                inputVariant="standard"
                                format="MM/dd/yyyy"
                                label="Exp Date"
                                size="small"
                                //style={{ marginRight: 4 }}
                                //error={fieldErrors.date != null}
                                //helperText={fieldErrors.date}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={value.activeEnd ? value.activeEnd : null}
                                onChange={(val) => {
                                    setValue({
                                        ...value,
                                        activeEnd: val
                                    });
                                    setFieldsProgramDetail({
                                        ...fieldsProgramDetail,
                                        [(name + "_activeEnd")]: val,
                                    });
                                    setIsSaveEnable(true);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </>
            }

            <Grid item xs={2}>
                <TextField
                    type="text"
                    label="Hours /wk"
                    onChange={(event) => {
                        let val = event.target.value;
                        setValue({
                            ...value,
                            hoursPerWeek: val,
                        });
                        setFieldsProgramDetail({
                            ...fieldsProgramDetail,
                            [(name + "_hoursPerWeek")]: val,
                        });
                        setIsSaveEnable(true);
                    }}
                    //style={{ paddingTop: "13px", textAlign: "center", marginLeft: 25, width: "70%" }}
                    name={name}
                    value={value.hoursPerWeek}
                    onInput={inputHandler}
                    // className={classes.hoursWidget}
                    InputProps={{
                        maxLength: 2,
                        //endAdornment: (
                        //    <InputAdornment position="end">&nbsp;Hours</InputAdornment>
                        //),
                        inputMode: 'numeric', pattern: '[0-9]*'
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            {name.toLowerCase().startsWith("comforthealth") &&
                <>
                    <Grid item xs={2}>
                        <TextField
                            type="text"
                            label="Max Hrs /day"
                            onChange={(event) => {
                                setValue({
                                    ...value,
                                    maxHoursPerDay: event.target.value,
                                });
                                setFieldsProgramDetail({
                                    ...fieldsProgramDetail,
                                    [(name + "_maxHoursPerDay")]: event.target.value,
                                });
                                setIsSaveEnable(true);
                            }}
                            //style={{ paddingTop: "13px", textAlign: "center", marginLeft: 25, width: "70%" }}
                            name={name}
                            value={value.maxHoursPerDay}
                            onInput={inputHandler}
                            // className={classes.hoursWidget}
                            InputProps={{
                                maxLength: 2,
                                //endAdornment: (
                                //    <InputAdornment position="end">&nbsp;Hours</InputAdornment>
                                //),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ paddingRight: "16px" }}>
                        <TextField
                            type="number"
                            label="Sessions /wk"
                            onChange={(event) => {
                                setValue({
                                    ...value,
                                    maxSessionsPerWeek: event.target.value,
                                });
                                setFieldsProgramDetail({
                                    ...fieldsProgramDetail,
                                    [(name + "_maxSessionsPerWeek")]: event.target.value,
                                });
                                setIsSaveEnable(true);
                            }}
                            //style={{ paddingTop: "13px", textAlign: "center", marginLeft: 25, width: "70%" }}
                            name={name}
                            value={value.maxSessionsPerWeek}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 3);
                            }}
                            // className={classes.hoursWidget}
                            InputProps={{
                                maxLength: 2,
                                //endAdornment: (
                                //    <InputAdornment position="end">&nbsp;Hours</InputAdornment>
                                //),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>}
            {/*<FormControl className="w-100">*/}
            {/*    <FormControlLabel*/}
            {/*        style={{ display: "flex", justifyContent: "space-between" }}*/}
            {/*        control={*/}
            {/*            <TextField*/}
            {/*                type={"date"}*/}
            {/*                style={{ textAlign: "center", marginLeft: 25, width: "70%" }}*/}
            {/*                label={"Date Approved"}*/}
            {/*                onChange={(event) => {*/}
            {/*                    setValue(event.target.value);*/}
            {/*                    setIsSaveEnable(true);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        }*/}
            {/*        label={label}*/}
            {/*        labelPlacement="start"*/}
            {/*    />*/}
            {/*</FormControl>*/}
            {/*<FormControl className="w-100">*/}
            {/*    <TextField*/}
            {/*        type="number"*/}
            {/*        onBlur={(event) => {*/}
            {/*            //global.log('the name of the hour component is now: ', name);*/}
            {/*            setFieldsProgramDetail({*/}
            {/*                ...fieldsProgramDetail,*/}
            {/*                [name]: event.target.value,*/}
            {/*            });*/}
            {/*        }}*/}
            {/*        onChange={(event) => {*/}
            {/*            setValue(event.target.value);*/}
            {/*            setIsSaveEnable(true);*/}
            {/*        }}*/}
            {/*        style={{ textAlign: "center", marginLeft: 25, width: "70%" }}*/}
            {/*        name={name}*/}
            {/*        value={value}*/}
            {/*        onInput={(e) => {*/}
            {/*            e.target.value = Math.max(0, parseInt(e.target.value))*/}
            {/*                .toString()*/}
            {/*                .slice(0, 3);*/}
            {/*        }}*/}
            {/*        // className={classes.hoursWidget}*/}
            {/*        InputProps={{*/}
            {/*            maxLength: 2,*/}
            {/*            endAdornment: (*/}
            {/*                <InputAdornment position="end">&nbsp;Hours</InputAdornment>*/}
            {/*            ),*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</FormControl>*/}
        </Grid>

    );
};

export default ServiceDetails;