import { Grid } from '@mui/material';
import React from 'react';
import { useEffect, useState } from "react";
import { BASE_URL, employeeFileTemplate } from '../../constants';
import UploadFileComponent from './UploadEmployeeFileComp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getFileExtension } from '../../_helpers/file'
import AnimLoadingState from '../common/AnimLoadingState';

const UploadClientTemplateDocument = ({ label, employeeID, setIsUploaded, setIsLoading, viewMode, onSuccessfulUpload }) => {
    const [uploadedTemplateFile, setUploadedTemplateFile] = useState({ name: "", id: null });
    // const currentDocumentTemplate = fileTemplate[label.toUpperCase()];
    const currentDocumentTemplate = employeeFileTemplate[label];
    const [amountOfFilesUploaded, setAmountOfFilesUploaded] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // global.log("the second use effect in the upload document component")
        typeof(setIsLoading) === 'function' && setIsLoading(true)
        setLoading(true);
        global.fetchWrapper.get(BASE_URL + "api/CMS/Documents/GetEmployeeTemplateFile/" + employeeID + "?templateId=" + currentDocumentTemplate)
            .then(data => {
                if ((data == null || data.fileId == null)) {
                    setIsUploaded(false);
                    setLoading(false)
                    typeof(setIsLoading) === 'function' && setIsLoading(false)
                    return;
                }
                setLoading(false)
                setUploadedTemplateFile({ ...data ,name: data.name, id: data.fileId });
                setIsUploaded(true);
                typeof(setIsLoading) === 'function' && setIsLoading(false)
            })
    }, [currentDocumentTemplate, amountOfFilesUploaded]);

    return (
        <Grid item xs={12} style={{ padding: 5, position: 'relative' }}>
            <div>
                {viewMode === "edit" &&
                    <span>{label}</span>
                }
                {viewMode === "edit" &&
                    <UploadFileComponent
                    data={{ templateId: employeeFileTemplate[label], employeeID: employeeID, }}
                    label={uploadedTemplateFile?.name}
                    loading={loading}
                    onFileChange={(label, file) => {
                        setAmountOfFilesUploaded(amountOfFilesUploaded + 1)
                    }}
                    onSuccessfulUpload={onSuccessfulUpload}

                    />
                }
                {viewMode === "edit" ? "" : uploadedTemplateFile.name === "" ? "Not uploaded" : "Uploaded"}
            </div>
            {uploadedTemplateFile?.id &&
                <>
                    <FileDownloadIcon
                        color={'primary'}
                        fontSize={'small'}
                        style={{ cursor: "pointer", marginTop: '5px', marginRight: '5px' }}
                    onClick={() => global.fetchWrapper.downloadFile(uploadedTemplateFile.id)}
                    />
                {getFileExtension(uploadedTemplateFile?.name) === 'pdf' &&
                        <OpenInNewIcon
                            color={'primary'}
                            fontSize={'small'}
                            style={{ cursor: "pointer", marginTop: '5px', marginRight: '5px' }}
                    onClick={() => global.fetchWrapper.readFileInNewTab(uploadedTemplateFile.id)}

                        />
                    }

                </>
            }
        </Grid>
    );
}

export default UploadClientTemplateDocument;