import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { yeshivasClients } from "../../../../api/yeshivas";
import DataTable from "../../../DataTable/DataTable";
import useStyles from "../../styles";
import baseStyles from '../../../../assets/styles'
export default function Program(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [clientToDoData, setClientToDoData] = React.useState([]);
    const exploringType = useStoreState((state) => state.exploringType);
    const exploringId = useStoreState((state) => state.exploringId);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    useEffect(() => {
        // setLoading();
        yeshivasClients(exploringId).then((response) => {
            setClientToDoData(response);
            // endLoading();
        });
    }, []);

    const _setRowAndColumnTodo = () => {
        return {
            rows: clientToDoData,
            columns: [],
            screen: "yeshivas_clients",
        };
    };

    return (
        <>
            <div className={baseClasses.row} style={{ paddingTop: 15 }}>
                <DataTable
                    style={{ width: "100%" }}
                    data={_setRowAndColumnTodo()}
                    multiView
                // onOpen={handleAddButton}
                // onEdit={handleEditData}
                />
            </div>
        </>
    );
}
