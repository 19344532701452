import {
    applicationsSchedule,
    applicationsHistory,
    applicationsContactInfo,
    EMPLOYEE_AVAILABLE_PROVIDERS_LIST,
    EMPLOYEE_DETAIL,
    EMPLOYEE_LIST,
    employeeFileTemplate,
    GET_EMPLOYEE_TEMPLATE_FILES,
    PROFESSIONAL_NAMES_LIST,
    EMPLOYEE_AVAILABILITY_SCHEDULE,
    EMPLOYEE_NAMES,
    EMPLOYEE_INFO,
    APPLICATIONS_LIST,
    APPLICANT_DETAIL,
    APPLICANT_REMOVE
} from "../constants";

import authHeader from "../screens/_helper/auth-header";
import { periodicities } from '../_helpers/constants/scheduleConstants';

export const applications = async () => {
    let applications_data = [];
    const employees = [];

    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(APPLICATIONS_LIST)
            .then((data) => {
                applications_data = data.result;
                applications_data.forEach((application) => {
                    let item = application.employeeApplication;
                    // if (item.Contact) {
                    employees.push({
                        id: item.id, // Accessing employeeApplication.id
                        contact: item.contact,
                        createdOn: item.createdOn,
                        firstName: item.contact?.firstName || "",
                        lastName: item.contact?.lastName || "",
                        fullName: `${item.contact?.firstName || ""} ${item.contact?.lastName || ""}`.trim(),
                        firstNameHebrew: item.contact?.firstNameHebrew || "",
                        lastNameHebrew: item.contact?.lastNameHebrew || "",
                        firstNameLegal: item.contact?.firstNameLegal || "",
                        lastNameLegal: item.contact?.lastNameLegal || "",
                        middleNameLegal: item.contact?.middleNameLegal || "",
                        legalName: item.contact?.legalName || "",
                        // clients: item.clients.join() || "",
                        // yeshivas: item.yeshivas.join() || "",
                        // meargenim: item.meargenim.join() || "",
                        // clientCount: item.clientCount || 0,
                        phone1: item.contact?.phone1 || "",
                        hasText: item.contact?.hasText || null,
                        address1: item.contact?.address1 || "",
                        age: item.contact?.age || 0,
                        city: item.contact?.city || "",
                        ContactId: item.contactId, // Using contactId from employeeApplication
                        // positionId: item.positionId || null,
                        // position: item.position ? item.position.name : "",
                        email1: item.contact?.email1 || "",
                        state: item.contact?.state || "",
                        zip: item.contact?.zip || "",
                        phone2: item.contact?.phone2 || "",
                        email2: item.contact?.email2 || null,
                        ssn: item.contact?.ssn || null,
                        dob: item.contact?.dob || null,
                        screenedByName: item.screenedByName || "",
                        interviewedByName: item.interviewedByName || "",
                        // isActive: item.isActive || false,
                        // inactive: !item.isActive,
                        // isEmployee: item.isEmployee || false,
                        // isLegal: item.isLegal || false,
                        // isSummerOnly: item.isSummerOnly || false,
                        // isProfessional: item.isProfessional || false,
                        // isYungerman: item.isYungerman || false,
                        // isExternal: item.isExternal || false,
                        // rateDisplay: item.rate
                        //     ? `$${item.rate}${periodicities[item.ratePeriod] ? `/${periodicities[item.ratePeriod]}` : ""}`
                        //     : "-",
                        // rate: item.rate || 0,
                        // ratePeriod: item.ratePeriod || null,
                        prevYeshivas: item.prevYeshivas || "",
                        // experience: item.experience || "",
                        // note: item.note || "",
                        // lHisalosApprovalStatus: item.lHisalosApprovalStatus || null,
                        // parsonageLimit: item.parsonageLimit || null,
                        // financialNotes: item.financialNotes || "",
                        // paymentOptions: item.paymentOptions || "",
                        // planningToEnrollComfortHealth: item.planningToEnrollComfortHealth || null,
                        // hasComfortHealthApplication: item.hasComfortHealthApplication || false,
                        // hasBA: item.hasBA || false,
                        // hasHighSchoolDiploma: item.hasHighSchoolDiploma || false,
                        // sentToComfortHealthOn: item.sentToComfortHealthOn || null,
                        // fingerprintedOn: item.fingerprintedOn || null,
                        // comfortHealthApprovedOn: item.comfortHealthApprovedOn || null,
                        // catsApprovedOn: item.catsApprovedOn || null,
                        // specialRequirements: item.specialRequirements || "",
                        // amountCouldWork: item.amountCouldWork || 0,
                        isInTheMountainsSummer: item.isInTheMountainsSummer || false,
                        // hasLicense: item.hasLicense || false,
                        hasCar: item.hasCar || false,
                        // summerColony: item.summerColonyContact?.firstName || "",
                        // amountBilling: item.amountBilling || 0,
                        neighborhood: item.contact?.neighborhood || "",
                        applicationStatus: item.applicationStatus || "",
                        // comfortHealthStatus: item.comfortHealthStatus || "",
                        // comfortHealthEmployeeId: item.comfortHealthEmployeeId || null,
                        // specialties: item.specialties || "",
                        // prevocApprovedOn: item.prevocApprovedOn || null,
                        // professionalType: item.professionalType || "",
                        // isLhisalosInHouse: item.isProfessional && !item.isExternal,
                        // uploadedIdOn: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.ID)?.uploadedOn || null,
                        // uploadedParsonageFormOn: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.ParsonageForm)?.uploadedOn || null,
                        // uploadedHighSchoolDiplomaOn: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.HighSchoolDiploma)?.uploadedOn || null,
                        // uploadedBAOn: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.BA)?.uploadedOn || null,
                        // uploadedAssociatesDegreeOn: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.AssociateDegree)?.uploadedOn || null,
                        // uploadedLhisalosContractOn: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.LhisalosContract)?.uploadedOn || null,
                        // baFileName: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.BA)?.name || "",
                        // uploadedI9On: item.employeeApplication.contact?.files?.find(x => x.templateFileId === employeeFileTemplate.I9)?.uploadedOn || null,
                        roshMaamidId: item.roshMaamidId,
                        roshMaamidName: item.roshMaamidNew && item.roshMaamidNew.firstName && item.roshMaamidNew.lastName
                            ? `${item.roshMaamidNew.firstName} ${item.roshMaamidNew.lastName}`
                            : '',
                    });

                    // }
                });
                resolve(employees);
            })
            .catch((error) => {
                console.error("Error fetching applications:", error);
                reject(error);
            });
    });
}

// Other helper functions remain the same

export const getProfessionalNames = () => global.fetchWrapper.get(PROFESSIONAL_NAMES_LIST).then((data) => data.result);

export const getEmployeeNames = () => global.fetchWrapper.get(EMPLOYEE_NAMES);

// export const getApplicantInfo = (id) => global.fetchWrapper.get(APPLICANT_DETAIL_INFO + id);

export const getAvailableProviders = (filters, startTime, endTime, daysOfWeek) => {
    const data = {
        rateFrom: filters.rateFrom,
        rateTo: filters.rateTo,
        specialties: filters.specialties.join(","),
        startTime,
        endTime,
        daysOfWeek,
    };
    return global.fetchWrapper.post(EMPLOYEE_AVAILABLE_PROVIDERS_LIST, data);
};

// export const getApplicantDetails = (id) => global.fetchWrapper.get(EMPLOYEE_DETAIL + id);

export const getEmployeeAvailability = (id) => global.fetchWrapper.get(EMPLOYEE_AVAILABILITY_SCHEDULE + id);


export const getEmployeeTemplateDocuments = (id) => global.fetchWrapper.get(GET_EMPLOYEE_TEMPLATE_FILES + id);

// Employee table columns
export const columnsEmployees = [
    { field: "firstName", headerName: "First Name" },
    { field: "lastName", headerName: "Last Name", width: 200, sortable: false },
    { field: "phone1", headerName: "Phone Number", width: 200, sortable: false },
    { field: "address1", headerName: "Address", width: 200, sortable: false },
    { field: "city", headerName: "City", width: 200, sortable: false },
];

// Schedules table columns
export const yungermanBusySchedule = [
    { field: "startTime", headerName: "From", flex: 1 },
    { field: "endTime", headerName: "To", flex: 1 },
    { field: "clientName", headerName: "Client", flex: 1 },
    { field: "daysOfWeek", headerName: "Schedule", flex: 1 },
    { field: "note", headerName: "Note", flex: 1 },
];

export const yungermanAvailableSchedule = [
    { field: "startTime", headerName: "From", width: 110 },
    { field: "endTime", headerName: "To", width: 110 },
    { field: "schedule", headerName: "Schedule", width: 140 },
];

export const columnsApplication = [
    { field: "id", type: "number", headerName: "Employee Id", width: 110, },

    { field: "firstName", headerName: "First Name" },
    { field: "lastName", headerName: "Last Name", width: 200, sortable: false },
    { field: "phone1", headerName: "Phone Number", width: 200, sortable: false },
    { field: "address1", headerName: "Address", width: 200, sortable: false },
    { field: "city", headerName: "City", width: 200, sortable: false },
    // { field: "phone1", headerName: "Phone Number", width: 120, },

    { field: "createdOn", type: "date", headerName: "Date Created", width: 110, flex: 1, },
    { field: "deleteColumn", headerName: "Delete Icon", type: 'action' },

    // const columnsApplication = [
    //     { field: "fullName", headerName: "Full Name", width: 110, flex: 1.4, },
    //     { field: "lastName", headerName: "Last Name", width: 120, flex: 0.8 },
    //     { field: "firstName", headerName: "First Name", width: 150, flex: 0.8 },
    //     //{ field: "address1", headerName: "Address", width: 150, flex: 1},
    //     { field: "rateDisplay", headerName: "Rate", width: 110, flex: 0.8 },
    //     { field: "neighborhood", headerName: "Neighborhood", description: 'Neighborhood', width: 110, flex: 0.8, type: 'singleSelect', },
    //     { field: "specialties", headerName: "Specialties", width: 110, flex: 1.4 },
    //     { field: "isYungerman", type: "boolean", headerName: "Yungerman", width: 110, flex: 0.5, },
    //     { field: "isProfessional", type: "boolean", headerName: "Professional", width: 110, flex: 0.5, },
    //     { field: "isLegal", type: "boolean", headerName: "Legal", width: 110, flex: 0.5, },
    //     { field: "firstNameHebrew", headerName: "Hebrew First Name", description: 'Hebrew First Name', width: 110, flex: 0.8, },
    //     { field: "lastNameHebrew", headerName: "Hebrew Last Name", description: 'Hebrew Last Name', width: 110, flex: 0.5, },
    //     { field: "firstNameLegal", headerName: "Legal First Name", description: 'Legal First Name', width: 110, flex: 0.8, },
    //     { field: "lastNameLegal", headerName: "Legal Last Name", description: 'Legal Last Name', width: 110, flex: 0.8, },
    //     { field: "middleNameLegal", headerName: "Legal Last Name", description: 'Legal Last Name', width: 110, flex: 0.8, },
    //     { field: "legalName", headerName: "Legal Name", description: 'Legal Name', width: 110, flex: 0.8, hide: true },
    //     { field: "hasText", type: "boolean", headerName: "Has Text", width: 110, flex: 0.5, },
    //     { field: "address1", headerName: "Address", width: 110, flex: 1, },
    //     { field: "age", type: "number", headerName: "Age", width: 110, flex: 0.5, },
    //     { field: "city", headerName: "City", width: 110, flex: 0.8, },
    //     { field: "email1", headerName: "Email", width: 110, flex: 0.8, },
    //     { field: "state", headerName: "State", width: 110, flex: 0.8, },
    //     { field: "zip", headerName: "Zip", width: 110, flex: 0.8, },
    //     { field: "dob", type: "date", headerName: "DOB", width: 110, flex: 0.5, },
    //     { field: "rate", type: "number", headerName: "Rate (number)", description: 'Rate (number)', width: 110, flex: 0.5, },
    //     { field: "prevYeshivas", headerName: "Yeshivas Previously Worked", description: 'Yeshivas Previously Worked', width: 110, flex: 1, enableTooltip: true },
    //     { field: "experience", headerName: "Experience", width: 110, flex: 1, enableTooltip: true },
    //     { field: "note", headerName: "Note", width: 110, flex: 1, enableTooltip: true },
    //     { field: "lHisalosApprovalStatus", headerName: "LHisalos Approval Status", description: 'LHisalos Approval Status', width: 110, flex: 0.7, type: 'singleSelect', },
    //     { field: "parsonageLimit", type: "number", headerName: "Parsonage Limit", description: 'Parsonage Limit', width: 110, flex: 0.6, },
    //     { field: "parsonageFormExpiration", type: "date", headerName: "Parsonage Exp", description: 'Parsonage Expiration Date', width: 110, flex: 0.5, hide: true },
    //     { field: "financialNotes", headerName: "Financial Notes", description: 'Financial Notes', width: 110, flex: 1, enableTooltip: true },
    //     { field: "paymentOptions", headerName: "Payment Options", description: 'Payment Options', width: 110, flex: 1, enableTooltip: true, type: 'singleSelect', },
    //     { field: "planningToEnrollComfortHealth", type: "boolean", headerName: "Planning To Enroll CH", description: 'Planning To Enroll CH', width: 110, flex: 0.5, },
    //     { field: "hasComfortHealthApplication", type: "boolean", headerName: "Has CH Application", description: 'Has CH Application', width: 110, flex: 0.5, },
    //     { field: "hasBA", type: "boolean", headerName: "Has BA", width: 110, flex: 0.5, },
    //     { field: "hasHighSchoolDiploma", type: "boolean", headerName: "Has High School Diploma", description: 'Has High School Diploma', width: 110, flex: 0.8, },
    //     { field: "sentToComfortHealthOn", type: "date", headerName: "Date Sent Application To CH", description: 'Date Sent Application To CH', width: 110, flex: 0.8, },
    //     { field: "amountCouldWork", type: "number", headerName: "Amount Could Work", description: 'Amount Could Work', width: 110, flex: 0.6, },
    //     { field: "hasLicense", type: "boolean", headerName: "Has License", description: 'Has License', width: 110, flex: 0.5, },
    //     { field: "hasCar", type: "boolean", headerName: "Has Car", width: 110, flex: 0.5, },
    //     { field: "summerColony", headerName: "Summer Colony", width: 110, flex: 0.8, },
    //     { field: "enrollmentColumn", headerName: "Enrollment Icon", type: 'action' },
    // ];







];

export let getEmployeeApplicantDetails = (id) => {
    console.log("Fetching applicant details...");
    console.log(id);
    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(APPLICANT_DETAIL + id)
            .then((data) => {
                console.log("Data received:", data); // Log the raw data
                if (data.responseCode === 200) {
                    console.log("Data retrieved successfully");
                    resolve(data.result);
                } else {
                    console.error("Error in response:", data.error);
                    reject(new Error(data.error || "Unknown error occurred"));
                }
            })
    });
};

// delete a application 

export let removeApplicationFromList = (contactId, applicationId) => {
    console.log("here ", contactId, applicationId)
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(APPLICANT_REMOVE + contactId + "/" + applicationId, {
                headers: authHeader(),
            })
            .then(
                (response) => {
                    if (response.responseCode === 200) {
                        resolve(true);
                        global.AjaxSaved(response.message);
                    } else {
                        global.AjaxFailToast();
                        resolve(false);
                    }
                },
                (error) => {
                    global.log("error", error);
                    global.AjaxFailToast();
                    resolve(false);
                }
            );
    });
};