/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../../constants";
import InfoTable from "../../../DataTable/InfoTable";
import baseStyles from "../../../../assets/styles"
import OverviewCard from '../../../UI/OverviewCard';
import { periodicities } from '../../../../_helpers/constants/scheduleConstants';
import GeneralSchedule from "./GeneralSchedule";
import ScreeningInfo from "./ScreeningInfo";


export default function YungermanGeneralTab(props) {
    const baseClasses = baseStyles();
    const scheduleColumns = [{ headerName: "Client", fieldName: "client" }, { headerName: "Time", fieldName: "time" }, { headerName: "Days", fieldName: "days" }, { headerName: "Rate", fieldName: "rate" }]
    const [scheduleRows, setScheduleRows] = useState([{}]);
    const section1Info = [{ _key: "Name", value: props?.employeeInfo?.contact?.firstName + " " + props?.employeeInfo?.contact?.lastName },
    { _key: "Hebrew Name", value: props?.employeeInfo?.contact?.firstNameHebrew + " " + props?.employeeInfo?.contact?.lastNameHebrew },
    { _key: "Phone", value: props?.employeeInfo?.contact?.phone1 }, { _key: "LHisalos Status", value: props?.employeeInfo?.lHisalosApprovalStatus },]
    const section2Info = [{ _key: "Specialties", value: props?.employeeInfo?.specialties },
    { _key: "Rate", value: (props?.employeeInfo?.rate ? ("$" + props?.employeeInfo?.rate.toString() + (periodicities[props?.employeeInfo?.ratePeriod] ? ("/" + periodicities[props?.employeeInfo?.ratePeriod]) : "")) : "-") },
    { _key: "Comfort Health Status", value: comfortHealthStatus(props.employeeInfo) },
    { _key: "Comfort Health ID", value: props.employeeInfo?.comfortHealthEmployeeId ? props.employeeInfo?.comfortHealthEmployeeId : "-" }
    ]
    const getBusyScheduleInfo = () => {
        let keyForReactRow = 0;
        return global.fetchWrapper.get(BASE_URL + 'api/cms/schedules/employeeWeeklyClients/' + props.employeeInfo?.id)
            .then(data => data.map(schedule => {
                return ({
                    idInc: ++keyForReactRow,
                    id: schedule.id,
                    time: new Date(schedule.startTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                        + "-" + new Date(schedule.endTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                    note: schedule.message,
                    client: schedule.clientName,
                    days: schedule.daysOfWeekAsString.map(day => day.slice(0, 3)).join(", "),
                    rate: schedule.rate > 0 ? schedule.rate : "No Rate"
                });
            }))
    }

    useEffect(() => {
        getBusyScheduleInfo()
            .then(data => setScheduleRows(data))
    }, []);

    return (
        <div className={baseClasses.overview_main}>
            <div style={{ width: "65%" }}>
                <OverviewCard section1={section1Info} section2={section2Info} />
            </div>
            <div style={{ width: "65%" }}>
                <GeneralSchedule
                    isEmployee
                    employeeData={props.employeeInfo}
                    scheduleInfo={props.scheduleInfo}
                />

            </div>

            <div style={{ width: "65%", marginTop: "12px" }}>
                <ScreeningInfo screeningInfo={props.employeeInfo} />
            </div>

        </div>
    );
}

function comfortHealthStatus(employeeInfo) {
    if (employeeInfo?.comfortHealthApprovedOn != null) {
        return "Approved";
    }

    if (employeeInfo?.planningToEnrollComfortHealth === true) {
        return "Pending";
    }

    return "Not Enrolled"
}





// Under Costruction
//          for youngerman:
//             table showing his booked schedules
//             with the needed cols:
//                 client, time, days, rate
//         for professional:
//             table showing his booked repeating schedules:
//             with needed cols:
//                 client, time days, interval, next occurence
//             if the proffesianl has one time shcedules:
//                 there should be an appointments table with the needed cols
//                 client, date and time;
//             this can be a separate table;
//         I wanna have a table that takes a param cols and a param rows, and that should display the info, you should be able to add a event listener for row onClick
//         The datatable is technically usefull with some issuess


//create container component that has a title and rows looks like the accordian but without the many different sections
//create it 