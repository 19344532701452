import React, { useRef, useState, useEffect } from 'react';
import {useStoreState} from 'easy-peasy'
import { withStyles, useTheme } from "@mui/styles";
import clsx from 'clsx';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { 
  Button, Grid, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Box, Link, TableRow, InputAdornment,
  IconButton, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, Collapse, TablePagination, 
 } from '@mui/material';
import ReactLoading from 'react-loading';
import useStyles from '../Modals/styles';
import { getScheduleStringForProfessional, getNextSession, modalFields, getDaysArrayFromDaysString } from '../../../_helpers/schedule';
import { getNextAppointmentString, validateRatePeriod, validateRate, DateRangeValidator, TimeRangeValidator, validateDaySelections, validateProfessionalTypeSelection, ScheduleAvailabilityCalculator, ScheduleAvailabilityStatus } from '../../../_helpers/schedule/validators/client-schedule';
import CusTimePicker from '../../DateTimePickers/TimePicker/TimePicker';
import SearchProvider from '../SearchProvider';

function getStyles(number: string, specialtyVals: any, theme: any) {
  return {
      fontWeight:
          specialtyVals == undefined || specialtyVals.indexOf(number) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium
  };
}

const AvailablityFilter = (props: any) => {
  const globalConst: any = global;
  const { scheduleType, confirmedProviderExists, contextData, modalId, isResultsLoading, loadResults, actionPermissions, handleClearFilters, handleRateChange, handleTimeChange, handleDurationChange, handleNeighborhoodChange, handleSpecialtiesChange, getEmployeeMissingSpecialties, availableProvidersRows, clientProviderNeighborhoodMismatch, unableToCalculateNeighborhoodAvailability, openProviderDetailsPageInNewTab, isPermittedToAddPotential, potentialLimitReach, handleFilter } = props;
  const neighborhoodValues = useStoreState((state: any) => state.list.values.neighborhoods);
  const specialtyValues = useStoreState((state: any) => state.list.values.specialties);
  const classes: any = useStyles();
  const theme = useTheme();  

  return (
    <div className={classes.innerBox}>
        <div>
            <div className={classes.available}>
                <h6 className={classes.availableText} >Available {scheduleType === "yungerman" ? "Yungeleit" : "Professionals"}:</h6>
                <div id={modalId + "ldrsl-btn"} className={classes.clearFilters} onClick={handleClearFilters}>Clear filters</div>
            </div>
            {/*<label className="start-date">Filters:</label>*/}
            {modalFields.rateFilter.includes(scheduleType) &&
                <Grid container className={classes.datePaddingBox} spacing={0}>
                    <Grid item xs={6}>
                        <FormControl disabled={confirmedProviderExists} fullWidth className={classes.formControl1} variant="outlined">
                            <InputLabel htmlFor="rateFrom">Rate From</InputLabel>
                            <OutlinedInput
                                className={classes.width95}
                                id={modalId + "rtefrm"}
                                value={contextData != undefined ? contextData.rateFrom : null}
                                onChange={handleRateChange}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                style={{ height: 40 }}
                                error={contextData !== undefined && contextData.rateFrom > contextData.rateTo}
                                label={contextData !== undefined && contextData.rateFrom > contextData.rateTo ? "rate-to is less than the rate-from" : "Rate From"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl disabled={confirmedProviderExists} fullWidth className={classes.formControl1} variant="outlined">
                            <InputLabel htmlFor="rateTo">Rate To</InputLabel>
                            <OutlinedInput
                                className={classes.width95}
                                id={modalId + "rteto"}
                                value={contextData !== undefined ? contextData.rateTo : null}
                                onChange={handleRateChange}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                style={{ height: 40 }}
                                error={contextData !== undefined && contextData.rateFrom > contextData.rateTo}
                                label={contextData !== undefined && contextData.rateFrom > contextData.rateTo ? "rate-to is less than the rate-from" : "Rate To"}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            }
            {modalFields.timeFilter.includes(scheduleType) &&
                <Grid container spacing={1} className={classes.datePaddingBox}>
                    <Grid item xs={4}>
                        <CusTimePicker
                            id={modalId + "tmefrm_flt"}
                            label={"Time From"}
                            value={contextData?.timeFrom_Filter ?? null}
                            fieldName={"timeFrom_Filter"}
                            fieldErrors={
                                contextData !== undefined && contextData.timeFrom_Filter > contextData.timeTo_Filter ? { timeFrom_Filter: "time-to is less than the time-from" } : {}
                            }
                            onChange={(newValue: any) => {
                                handleTimeChange(newValue, "timeFrom_Filter");
                            }}
                            disabled={confirmedProviderExists}
                            style={{ width: '95%' }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CusTimePicker
                            id={modalId + "tmeto_flt"}
                            label={"Time To"}
                            value={contextData?.timeTo_Filter ?? null}
                            fieldName={"timeTo_Filter"}
                            fieldErrors={
                                contextData !== undefined && contextData.timeFrom_Filter > contextData.timeTo_Filter ? { timeTo_Filter: "time-to is less than the time-from" } : {}
                            }
                            onChange={(newValue: any) => {
                                handleTimeChange(newValue, "timeTo_Filter");
                            }}
                            disabled={confirmedProviderExists}
                            style={{ width: '95%' }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CusTimePicker
                            id={modalId + "tmedur_flt"}
                            label={"Duration"}
                            value={contextData?.duration_Filter ?? null}
                            fieldName={"duration_Filter"}
                            onChange={(newValue: any) => {
                                handleDurationChange(newValue, "timeDuration_Filter");
                            }}
                            fieldErrors={{}}
                            disabled={confirmedProviderExists}
                            ampm={false}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip
                            title={
                                `New Feature!
                                If you need the find for example an hour schedule between 3 & 6 pm,
                                you can enter 'Time from': 3:00pm ,'Time to': 6:00pm, 'Duration': 01:00
                                if the yungerman is not available, the warning will show up in the 'Duration Filter Conflict' section of the warnings

                                Please provide us feedback on how to improve this feature`
                            }
                        >
                            <InfoOutlinedIcon/>
                        </Tooltip>
                    </Grid>

                </Grid>
            }
            <Grid container className={classes.datePaddingBox} spacing={0}>
                {modalFields.neighborhoodFilter.includes(scheduleType) &&
                    <Grid xs={6}>
                        <TextField
                            disabled={confirmedProviderExists}
                            select
                            label="Neighborhood"
                            id={modalId + "nbh"}
                            variant="outlined"
                            size="small"
                            value={contextData !== undefined && contextData.neighborhood !== undefined ? contextData.neighborhood : ""}
                            className={classes.field}
                            onChange={handleNeighborhoodChange}
                        >
                            {neighborhoodValues.map((neighborhood: any, index: number) =>
                                <MenuItem key={index} value={neighborhoodValues[index]}>{neighborhood}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                }
                {modalFields.specialtiesFilter.includes(scheduleType) &&
                    <Grid item xs={6}>
                        <FormControl disabled={confirmedProviderExists} variant="outlined" className={clsx(classes.formControl1, classes.multiSelect)} style={{ margin: 0, lineHeight: 'center' }} fullWidth>
                            <InputLabel id="demo-mutiple-chip-label" style={{ paddingLeft: 6 }}>Specialties</InputLabel>
                            <Select
                                label="Specialties"
                                labelId="demo-mutiple-chip-label"
                                id={modalId + "spc"}
                                multiple
                                value={contextData != undefined && Array.isArray(contextData.specialties) ? contextData.specialties : []}
                                onChange={handleSpecialtiesChange}
                                input={<OutlinedInput id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.join(" ,")}
                                        {/*{selected.map((value) => (}
                            {/*    <Chip key={value} label={value} className={classes.chip} />*/}
                                        {/*))}*/}
                                    </div>
                                )}
                                className={classes.field}
                                style={{ height: 40, paddingLeft: 0, margin: 0 }}
                            >
                                {specialtyValues.map((name: string) => (
                                    <MenuItem id={modalId + "spc-" + name} key={name} value={name} style={getStyles(name, contextData != undefined ? contextData.specialties : [], theme)}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
            </Grid>
            <SearchProvider
                actionPermissions= {actionPermissions}
                modalId={modalId}
                scheduleType={scheduleType}
                getEmployeeMissingSpecialties={getEmployeeMissingSpecialties}
                contextData={contextData}
                availableProvidersRows={availableProvidersRows}
                confirmedProviderExists={confirmedProviderExists}
                loadResults={loadResults}
                isResultsLoading={isResultsLoading}
                clientProviderNeighborhoodMismatch={clientProviderNeighborhoodMismatch}
                unableToCalculateNeighborhoodAvailability={unableToCalculateNeighborhoodAvailability}
                openProviderDetailsPageInNewTab={openProviderDetailsPageInNewTab}
                isPermittedToAddPotential={isPermittedToAddPotential}
                potentialLimitReach={potentialLimitReach}
                handleFilter={handleFilter}
            />
        </div>
    </div>
  )
}

export default AvailablityFilter
