import React, { useState, useEffect } from "react";
// import { Prompt } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

const useUnsavedChangesWarn = (message = "You have unsaved changes, do you want to discard them?") => {
    const isWindowDirty = useStoreState((state) => state.isWindowDirty);
    const setWindowDirty = useStoreActions((actions) => actions.setWindowDirty);
    const changedItems = useStoreState((state) => state.changedItems);
    const setChangedItems = useStoreActions((actions) => actions.setChangedItems);

    const changedItemsIncludes = (changeName) => {
        let found = changedItems.includes(changeName);
        return found;
    }

    function run(action) {
        return new Promise(resolve => {
            action && action()
        });
    }
    async function asyncRun(action) {
        const result = await run(action);
    }

    const addChange = (changeName) => {
        const newArr_ChangedItems = changedItems.includes(changeName) ? changedItems : [...changedItems, changeName];
        setChangedItems(newArr_ChangedItems);
        updateWindowDirty(newArr_ChangedItems);
            //addChangedItem(changeName);
    }
    const removeChange = (changeName) => {
        if (changedItemsIncludes(changeName)) {
            const newArr_ChangedItems = changedItems.filter((item) => item != changeName);
            setChangedItems(newArr_ChangedItems);
            updateWindowDirty(newArr_ChangedItems);
        }
        //removeChangedItem(changeName);
    }
    const runRollbackAction = (rollbackAction) => {
        if (rollbackAction != undefined) {
            rollbackAction();
            if (isWindowDirty)
                setWindowDirty(false);
        }
    }
    const setDirty = () => {
        if (!isWindowDirty)
            setWindowDirty(true);
    }
    const setClean = () => {
        if (isWindowDirty)
            setWindowDirty(false);
        setChangedItems([]);
    }
    const windowConfirm = (event, rollbackAction, customMessage = message) => {
        //if (changedItems.length == 0)
        //    return true;
        let userResponse = window.confirm(customMessage);
        if (userResponse) {
            if (rollbackAction)
                rollbackAction();
            setClean();
        } else {
            if (event)
                event.preventDefault();
        }
        return userResponse;
    }
    const updateWindowDirty = (items) => {
        if (items.length > 0 && !isWindowDirty)
            setWindowDirty(true);
        if (!items.length && isWindowDirty)
            setWindowDirty(false);
    }

    // somehow this is not being called
    useEffect(() => {
        updateWindowDirty(changedItems);
    }, [changedItems])

    // somehow this is not being called
    // useEffect(() => {
    //     setWindowDirty(isWindowDirty);
    // }, [isWindowDirty])

    useEffect(() => {
        window.onbeforeunload = isWindowDirty && (() => message);

        return () => {
            window.onbeforeunload = null;
            if(isWindowDirty)
                setWindowDirty(false);
        };
    }, [isWindowDirty]);

    const hasChanges = () => {
        return isWindowDirty || changedItems.length > 0;
    }

    const routerPrompt =
        <div>
            {/*{isWindowDirty && isShowPopup ? <UnsavedChangesPopup></UnsavedChangesPopup> : null}*/}
            {/* <Prompt when={false} message={message} /> */}
            abc
        </div>;

    const changesHelper = {
        routerPrompt, setDirty, setClean, isWindowDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm: windowConfirm, hasChanges
    }

    return [changesHelper];
};

export default useUnsavedChangesWarn;