import React from 'react';
import { useTheme } from '@mui/styles';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import useBaseStyles from '../../../../assets/styles';
import DataTable from "../../../DataTable/DataTable";
import { AllProgressType } from './types';
import { getHebrewMonthRangeFromRegDate } from '../../../../_helpers/datetime';

const AllProgress: React.FC<AllProgressType> = ({ handleAddButton, handleEditData, onDelete, rowsAndCols}) => {
    const baseClasses: any = useBaseStyles();
    const globalScope:any = global;
    return (
        <div>
            <div>
                <div className={baseClasses.button_program}>
                    <Button
                        className={baseClasses.capitalized}
                        color="primary"
                        variant="outlined"
                        onClick={() => handleAddButton()}
                    >
                        Add
                    </Button>
                </div>
            </div>
            <div className="p-0">
                <DataTable
                    data={rowsAndCols}
                    onOpen={handleAddButton}
                    onEdit={(params:any) => { handleEditData(params.row) }}
                    onRowEnter={(row:any) => {
                        //setIsToolTip(true);
                        //setTooltipData(row.noteDescription);
                    }}
                    onRowLeave={(row:any) => {
                        //setIsToolTip(false);
                    }}
                    onDelete={(params:any) => {
                        onDelete(params);
                    }}
                    permissions={globalScope.permissions.clients}
                    canEdit={(params:any) =>
                        params.row.createdById === globalScope.account.userValue.id || globalScope.isAdmin()
                    }
                    canDelete={(params: any) =>
                        params.row.createdById === globalScope.account.userValue.id || globalScope.isAdmin()
                    }
                    hideHeaderColumnsSortIcon
                    multiView
                />
            </div>
        </div>
    )
}

export default AllProgress