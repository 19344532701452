import React, {useRef, useState} from 'react'
import { makeStyles, withStyles } from '@mui/styles';
import {TableCell, Tooltip} from '@mui/material'
import useActiveToolTip from '../../../hooks/useActiveToolTip';
import FeaturedIcon from '../../common/FeaturedIcon';

export const StyledTableCell = (props) => {
    const ref = useRef();
    const isOverflow = useActiveToolTip(ref);
    const Styled = withStyles((theme) => ({
        head: {
            backgroundColor: "#e2eefa",
            color: theme.palette.common.white,
            lineHeight: "0.4rem",
            fontSize: 12,
            minWidth: 20,
            padding: '10px 5px !important',
            overflow: "hidden",
            maxWidth: '70px',
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: props.sortOrder ? 'pointer' : 'unset'
        },
    }))(TableCell);
    return (
        <Tooltip title={isOverflow ? props.children: ''} placement="top">
            <Styled {...props} ref={ref}>
                {props.children}
                {props.sortOrder && <FeaturedIcon icon={props.sortOrder === 'asc' ? "ArrowUp" : 'ArrowDown'} fontSize="small" /> }
            </Styled>
        </Tooltip>
    );
}

export const StyledTableRowCell = (props) => {
    const { maxWidth, fontSize, paddingLeft } = props;
    const ref = useRef();
    const isOverflow = useActiveToolTip(ref);
    const Styled = withStyles((theme) => ({
        root: {
            fontSize: fontSize || 12,
            padding: '5px 5px !important',
            paddingLeft: (paddingLeft ? `${paddingLeft}px !important` : 'unset'),
            overflow: "hidden",
            maxWidth: (maxWidth && maxWidth.toString() + "px") || undefined,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }))(TableCell);
    return (
        <Tooltip title={isOverflow ? props.children: ''} placement="top">
            <Styled {...props} ref={ref}>
                {props.children}
            </Styled>
        </Tooltip>
    );
};

export const StyledTableRow = withStyles((theme) => ({
    root: {
        padding: '5px 5px !important',
        fontSize: 12,
        width: 0
    },
}))(TableCell);

export default makeStyles((theme) => ({
    card: {
        minHeight: '300px',
        borderRadius: '2px',
        marginBottom: '10px',
        paddingTop: '10px'
        /* margin-left: 10px; */
    },
    editOptions: {
        '.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
            padding: '0px !important',
        }
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '36px'
    },
    cardTitle: {
        fontSize: '14px',
        fontWeight: 'normal',
        paddingLeft: '15px',
    },
    selectScheduleType: {
        border: '1px solid #1976d2',
        height: '25px',
        borderRadius: '5px',
        fontSize: '12px',
        marginRight: '2%',
        alignContent: 'center'
    },
    table: {
        backgroundColor: "#e2eefa", 
    },
    tableBody: {
        height: '48px !important',
        '.css-1ex1afd-MuiTableCell-root' :{
            padding: '10px 0px !important'
        },
    },
    font: {
        color: "white",
    },
    clickableItem: {
        '&:hover': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    itemPadding: {
        padding: '10px 0px'
    }
}))