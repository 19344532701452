import { DUMMY_API, SEARCH_TEXT, GLOBALSEARCH } from '../constants';

export default function search(props) {
    let search_data = [];
    const search = [];

    global.log('propss', props);

    return new Promise((resolve, rejects) => {
            global.fetchWrapper.get(SEARCH_TEXT + props)
            .then(data => {
                search_data = data;
                search_data.result.forEach((item, index) => {
                    search.push({
                        id: item.id,
                        name: item.name,
                        phone: item.phone,
                        type: item.address.city,
                        position: item.address.zipcode,
                    });
                });
                resolve(search);
            }, (rejects) => {
                global.log('rejects');
            });
    });
}

export const globalSearch = (searchTerm) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
                    .get(GLOBALSEARCH + searchTerm)
                    .then((data) => {
                            if (data) {
                                resolve(data);
                            } else {
                                resolve(false);
                            }
                        },
                        (rejects) => {
                            resolve(false);
                        }
                    );
        })
}

export const columnsSearch = [
    { field: 'name', headerName: 'Name', width: 600 },
    { field: 'phone', headerName: 'Phone', width: 300, sortable: false },
    { field: 'type', headerName: 'Type', width: 100, sortable: false },
    { field: 'position', headerName: 'Position', width: 100, sortable: false },
];
