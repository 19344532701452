const clientScheduleStatus = {
    active: "Active",
    financial: "Pending Financial",
    yungerman: "Pending Yungerman",
    professional: "Pending Professional",
    rejected: "Financially Rejected",
    onhold: "On Hold",
}

const periodicities = {
    HOURS: "Hourly",
    WEEKS: "Weekly",
    MONTHS: "Monthly",
    SESSION: "Session",
}

export { clientScheduleStatus, periodicities };