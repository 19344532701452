import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from '../../../components/DataTable/DataTable';
import DetailHeader from '../DetailHeader';
import FilterButtonGroup from '../../../components/common/FilterButtonGroup';
import { DASHBOARD_CLIENT_WITH_PROFESSIONAL_FILTER_OPTIONS, DASHBOARD_CLIENT_WITH_YUNGERLEIT_FILTER_OPTIONS } from '../../../constants';
import {  DivContainer } from '../../../assets/styles/styledComponents';
import { dashboardClientListType, filterOptionType } from '../types';
import useStyles from '../style';
import useGetValueOptionsLists from '../../../hooks/useGetValueOptionsLists';
import useInterval from '../../../hooks/useInterval';
const Client = () => {
    const classes : any = useStyles();
    const { pathname } = useLocation();
    const currentRoute = pathname.split('/').pop() as string;
    const [clientList, setClientList] = useState<dashboardClientListType[]>([]);
    const [filterBy, setFilterBy] = useState<string>("all");
    const [filterOptions, setFilterOptions] = useState<filterOptionType[]>([])

    const getClientServiceList = useStoreActions((actions: any) => actions.dashboard.getDashboardActiveClient);
    const clientWithYungerleitServiceList = useStoreState((state: any) => state.dashboard.clientWithYungerleitList);
    const clientWithProfessionalList = useStoreState((state: any) => state.dashboard.clientWithProfessionalList);
    const loading = useStoreState((state: any) => state.dashboard.loading);
    const valueOptionsLists = useGetValueOptionsLists(['clientName']);

    useEffect(() => {
        clientWithYungerleitServiceList <= 0 &&  getClientServiceList("yungerman");
        clientWithProfessionalList <= 0 &&  getClientServiceList("professional");
        const currentRouteClients =  [... currentRoute === 'clientsWithYungerleit' ? clientWithYungerleitServiceList : clientWithProfessionalList]
        setClientList(currentRouteClients);

    }, [clientWithYungerleitServiceList, clientWithProfessionalList])

    useEffect(() => {
        let filters = currentRoute === 'clientsWithYungerleit' ? 
        DASHBOARD_CLIENT_WITH_YUNGERLEIT_FILTER_OPTIONS: 
        DASHBOARD_CLIENT_WITH_PROFESSIONAL_FILTER_OPTIONS
        setFilterOptions(filters);
    }, [pathname])
    const getProvidersString = (params:any) => {
        return params.row.providers.map((x:any) => x.providerName).join(',');
    }

     /**
     * Refresh cards page info after every 10min
     */
    useInterval(() => {
        getClientServiceList("yungerman");
        getClientServiceList("professional");
    }, 600000)
    

    const clientColDefination = [
        { field: "clientName", headerName: "Bucher", width: 120, navUrl: "client/{clientId}/details", type: 'singleSelect' },
        { field: 'servicesCount', headerName: 'Number of ' + (currentRoute === 'clientsWithYungerleit' ? 'Yungerleit' : 'Professionals'), width: 120, type: 'number' },
        { field: 'providersList', headerName: currentRoute === 'clientsWithYungerleit' ? 'Yungerleit' : 'Professionals', width: 300, valueGetter: getProvidersString, getStringValue: getProvidersString},
        { field: "comfortHealthStatus", headerName: "Approval", width: 120 },
    ];

    const getClientCount = () => {
        return clientList.length;
    }

    const getFilteredData = () => {
        switch(filterBy) {
            case 'all': 
                return clientList;
            case 'withYungerleit':
                return clientList.filter((list: any) => list.servicesCount > 0)
            case 'withoutYungerleit':
                return clientList.filter((list: any) => list.servicesCount < 1)
            case 'withProfessional':
                return clientList.filter((list: any) => list.servicesCount > 0)
            case 'withoutProfessional':
                return clientList.filter((list: any) => list.servicesCount < 1)
            default:
                return clientList
        }
    }

    const _handleRowSelect = () => {

    }

    const handleClickFilter = (filter: string) => {
        setFilterBy(filter)
    }

    const getHeaderTitle = () => {
        return currentRoute === 'clientsWithYungerleit' ? 'Talmidim With Yungerleit' : 'Talmidim with Professionals'
    }

  return (
    <main className={classes.content}>
        <DetailHeader boxLabel={getHeaderTitle()} boxIcon='Account' badgeCount={getClientCount()} />
        <DivContainer>
            <DivContainer>
                Filter By:
            </DivContainer>
            <FilterButtonGroup 
                variant="outlined" 
                selectedVariant="contained"
                filterOptions={
                    currentRoute === 'clientsWithYungerleit' ? 
                    DASHBOARD_CLIENT_WITH_YUNGERLEIT_FILTER_OPTIONS : 
                    DASHBOARD_CLIENT_WITH_PROFESSIONAL_FILTER_OPTIONS
                }
                onClick={handleClickFilter}
                style={{textTransform: 'capitalize'}}
                selected={filterBy}
            />
        </DivContainer>
        <DataTable
              data={{
                  rows: getFilteredData(),
                  columns: clientColDefination,
                  screen: `dashboard_${currentRoute}`,
              }}
              onRowClick={_handleRowSelect}
              rowHeight={30}
              loading={loading}
              title="Client"
              hideToolbarIconText
              valueOptionsLists={valueOptionsLists}
              multiView
        />
    </main>
  )
}

export default Client