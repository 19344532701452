import React, { useEffect, useState} from 'react'
import { Grid} from '@mui/material'
import TimeEditor from '../../../../components/common/UI/Inputs/TimeDate/TimeEditor';
import {Text} from '../../../../assets/styles/styledComponents';
import SectionWrapper from './SectionWrapper';
import useUnsavedChangesWarn from '../../../../hooks/useUnsavedChangesWarn';
import { DivContainer } from '../../../../assets/styles/styledComponents';
import { TimeRangeValidator } from '../../../../_helpers/schedule/validators/client-schedule';

type SederDetailsProps = {
  handleSave : Function,
  scheduleInfo: scheduleInfoType
}

type scheduleInfoType = {
  afternoon: eachSchedule,
  earlyMorning: eachSchedule,
  morning: eachSchedule,
  night: eachSchedule
}

type eachSchedule  = {
    description: string | null,
    endTime: string,
    error: string | any,
    isLoading: boolean,
    mode: 'read' | 'edit',
    name: string,
    sederId: number,
    startTime: string,
    yeshivaId: number
}

const SederDetails = ({scheduleInfo, handleSave }: SederDetailsProps) => {
  const [changesHelper]: any = useUnsavedChangesWarn();
  const { setDirty, hasChanges, setClean} = changesHelper;
  const [data, setData] = React.useState<scheduleInfoType>(scheduleInfo);
  useEffect(() => {
    setData(scheduleInfo)
  }, [scheduleInfo])

  const isReadMode = (field: string) => {
    return data[field as keyof typeof data].mode === 'edit' ? false : true
  } 

  const validateTimeFields = (timeFields: any, requireStartTime=true, requireEndTime=true) => {
    const timeRangeValidator = new TimeRangeValidator(timeFields.startTime, timeFields.endTime, {
        requireStartTime: requireStartTime,
        requireEndTime: requireEndTime
    });
    timeRangeValidator.validate();;
    return timeRangeValidator;
  }

  const handleChangeMode = (tab: string, mode: string, section: string) => {
    setData({
      ...data,
      [section]: {
        ...data[section as keyof typeof data],
        mode: mode
      }
    })
  }

  const saveSectionData = (type: string, tab: string,  section: string, mode: string ) => {
    switch (type) {
      case 'cancel': {
          if (hasChanges()) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                return   
            }
          }
          setClean();
          handleSave(null, type, tab,  section, mode )
          handleChangeMode(tab,  mode, section )
          break;
      } 
      case 'save': {
        handleSave(data[section  as keyof typeof data], type, tab,  section, mode )
        setClean();
      }
    }
  }

  const handleChangeField = (value: string, section: string, name: string) => {
    let schedule: any = data[section as keyof typeof data];
        schedule = {
            ...schedule,
            [name]: value
        }
        const valResult = validateTimeFields(schedule, false, false);
        schedule = {
            ...schedule,
            error: valResult.isValid ? '' : valResult.fieldMessages
      }
    setDirty()
    setData({
      ...data,
      [section] : {
        ...schedule
      }
    })
  }

  const isErrorInSection = (section: string) => {
    return !!data[section as keyof typeof data]['error']
  }

  return (
    <DivContainer>
      <SectionWrapper isLoading={data?.earlyMorning?.isLoading} disabledSave = {isErrorInSection("earlyMorning")} handleSave={saveSectionData} tab="schedule" headerTitle="Early Morning" name="earlyMorning" mode={data?.earlyMorning?.mode} handleChangeMode={handleChangeMode}>
        <Grid container>
            <Grid item xs={5}>
              <TimeEditor
                mode={data?.earlyMorning?.mode}
                label="Start Time"
                name="earlyMorning"
                onChange={(event: string, name: string) => handleChangeField(event, name, 'startTime')}
                value={data.earlyMorning?.startTime}
                error={!!data.earlyMorning?.error}
                helperText={data.earlyMorning?.error?.startTime}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TimeEditor
                mode={data?.earlyMorning?.mode}
                label="End Time"
                name="earlyMorning"
                onChange={(event: string, name: string) => handleChangeField(event, name, 'endTime')}
                value={data.earlyMorning?.endTime}
                error={!!data.earlyMorning?.error}
                helperText={data.earlyMorning?.error?.startTime}
                fullWidth
              />
            </Grid>
        </Grid>
      </SectionWrapper>

      <SectionWrapper isLoading={data?.morning?.isLoading} handleSave={saveSectionData} disabledSave = {isErrorInSection("morning")} tab="schedule" headerTitle="Morning" name="morning" mode={data?.morning?.mode} handleChangeMode={handleChangeMode}>
        <Grid container>
          <Grid item xs={5}>
            <TimeEditor
              mode={data?.morning?.mode}
              label="Start Time"
              name="morning"
              onChange={(event: string, name: string) => handleChangeField(event, name, 'startTime')}
              value={data.morning?.startTime}
              error={!!data.morning?.error}
              helperText={data.morning?.error?.startTime}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TimeEditor
              mode={data?.morning?.mode}
              label="End Time"
              name="morning"
              onChange={(event: string, name: string) => handleChangeField(event, name, 'endTime')}
              value={data.morning?.endTime}
              error={!!data.morning?.error}
              helperText={data.morning?.error?.endTime}
              fullWidth
            />
          </Grid>
        </Grid>
      </SectionWrapper>

      <SectionWrapper isLoading={data?.afternoon?.isLoading} handleSave={saveSectionData} disabledSave = {isErrorInSection("afternoon")} tab="schedule" headerTitle="Afternoon" name="afternoon" mode={data?.afternoon?.mode} handleChangeMode={handleChangeMode}>
        <Grid container>
          <Grid item xs={5}>
            <TimeEditor
              mode={data?.afternoon?.mode}
              label="Start Time"
              name="afternoon"
              onChange={(event: string, name: string) => handleChangeField(event, name, 'startTime')}
              value={data.afternoon?.startTime}
              error={!!data.afternoon?.error}
              helperText={data.morning?.error?.startTime}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TimeEditor
              mode={data?.afternoon?.mode}
              label="End Time"
              name="afternoon"
              onChange={(event: string, name: string) => handleChangeField(event, name, 'endTime')}
              value={data.afternoon?.endTime}
              error={!!data.afternoon?.error}
              helperText={data?.afternoon?.error?.endTime}
              fullWidth
            />
          </Grid>
        </Grid>
      </SectionWrapper>

      <SectionWrapper isLoading={data?.night?.isLoading} handleSave={saveSectionData} disabledSave = {isErrorInSection("night")} tab="schedule" headerTitle="Night" name="night" mode={data?.night?.mode} handleChangeMode={handleChangeMode}>
      <Grid container>
        <Grid item xs={5}>
          <TimeEditor
            mode={data?.night?.mode}
            label="Start Time"
            name="night"
            onChange={(event: string, name: string) => handleChangeField(event, name, 'startTime')}
            value={data.night?.startTime}
            error={!!data.night?.error}
            helperText={data.night?.error?.startTime}
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <TimeEditor
            mode={data?.night?.mode}
            label="End Time"
            name="night"
            onChange={(event: string, name: string) => handleChangeField(event, name, 'endTime')}
            value={data.night?.endTime}
            error={!!data.night?.error}
            helperText={data.night?.error?.endTime}
            fullWidth
          />
        </Grid>
      </Grid>
      </SectionWrapper>
    </DivContainer>
  )
}

export default SederDetails