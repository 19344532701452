import { alpha } from "@mui/material/styles";

const  forms = theme => ({
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
        grow: {
          flex: 1
        }
    },
    fieldLine: {
        display: "inline-flex",
      },
    fieldtitle: {
        // width: "15%",
        width: "320px",
        // textAlign: "right",
        // marginRight: "15px",
        // marginLeft: "50px"
    },
    fieldvalue: {
        width: "60%",
    },
    optionsContract: {
        padding: "16px 0px",
    },
    serviceInput: {
        marginLeft: "20px",
        marginRight: "10px",
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        marginBottom: theme.spacing(2),
        border: "1px solid #d3d3d3",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            /*marginLeft: theme.spacing(3),*/
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        /*pointerEvents: 'none',*/
        color: "#808080",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
    },
})

export default forms