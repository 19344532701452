import React, { useEffect, useState } from 'react'
import { Grid, FormControl, TextField, MenuItem, Link } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as constant from "../../../constants";
import ToggleDays from '../../ToggleDays/ToggleDays';
import ToggleMonthDays from '../../ToggleMonthDays/ToggleMonthDays';
import ProtectedComponent from '../../ProtectedComponent/ProtectedComponent';
import CusTimePicker from '../../DateTimePickers/TimePicker/TimePicker';
import UniDatePicker from '../../common/UniDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { modalFields, getDaysArrayFromDaysString } from '../../../_helpers/schedule';
import { validateRatePeriod, validateRate } from '../../../_helpers/schedule/validators/client-schedule';

import useStyles from '../Modals/styles';

const ScheduleForm = (props: any) => {
    const classes: any = useStyles()
    const {
        actionPermissions,
        modalId,
        showSearchProvider,
        scheduleType,
        setDirty,
        contextData,
        setContextData,
        calendarType,
        setCalendarType,
        changeTimeFrom,
        setScheduleRate,
        scheduleRate,
        setIsRateEdited,
        setFieldErrors,
        fieldErrors,
        changeDate,
        setEndDate,
        endDate,
        professionalTypesValues,
        handleProfessionalTypesChange,
        periodicities,
        schedule,
        periodicityScheduleChanged,
        setSchedule,
        customSchedule,
        setCustomSchedule,
        updateClientSchedule,
        updateProviderAvailabilitiesInfo,
        setIsScheduleEdited,
        getUpdatedClientScheduleFromSchedule,
        weekset,
        SCHEDULE_PERIODICITY_CONSTANTS,
        handleDayToggle,
        setDays,
        setMonthDays,

    } = props;

    // form data
    const defaultFormData: any = {
        startDate: undefined,
        endDate: undefined,
    };
    const [formData, setFormData] = useState(defaultFormData);
    const getUpdatedFormData = () => {
        const updatedFormData: any = contextData == undefined ? defaultFormData : {
            startDate: contextData != undefined && contextData.schedule != undefined && contextData.schedule.date != undefined
                ? new Date(contextData.schedule.date) : null,
            endDate: contextData != undefined && contextData.schedule != undefined && contextData.schedule.endDate != undefined
                ? new Date(contextData.schedule.endDate) : null,
        };
        return updatedFormData;
    }
    useEffect(() => {
        const updatedFormData: any = getUpdatedFormData();
        setFormData(updatedFormData);
    }, [contextData])

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    {
                        modalFields.professionalTypes.includes(scheduleType) &&
                        <Grid container spacing={0} className={classes.datePaddingBox}>
                            <Grid xs={12}>
                                <TextField
                                    required fullWidth={true}
                                    id={modalId + "pfstpe"}
                                    select
                                    label="Professional Type"
                                    variant="outlined"
                                    size="small"
                                    error={fieldErrors.professionalType && true}
                                    helperText={fieldErrors.professionalType}
                                    value={contextData != undefined && contextData.professionalType != undefined ? contextData.professionalType : ""}
                                    onChange={handleProfessionalTypesChange}
                                >
                                    {professionalTypesValues.map((professionalType: string, index: number) =>
                                        <MenuItem key={index} value={professionalTypesValues[index]}>{professionalType}</MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                        </Grid>
                    }
                </Grid>

            </Grid>

            <ProtectedComponent allow canEdit={contextData?.status !== 'active' || actionPermissions?.canEditConfirmedSchedules !== false}>
                {
                    modalFields.customizePeriodicity.includes(scheduleType) &&
                    <Grid container spacing={0} className={classes.datePaddingBox}>
                        <Grid xs={12}>
                            <TextField fullWidth={true}
                                id={modalId + "prdsch"} select
                                label="Periodicity Schedule" variant="outlined" size="small"
                                value={schedule.periodicitySchedule}
                                onChange={(event) => {
                                    var periodicitySchedule = event.target.value;
                                    periodicityScheduleChanged(periodicitySchedule);
                                }}
                            >
                                <MenuItem key="weekly" value="weekly">Weekly </MenuItem>
                                <MenuItem key="bi-weekly" value="bi-weekly">Bi Weekly </MenuItem>
                                <MenuItem key="monthly" value="monthly">Monthly </MenuItem>
                                <MenuItem key="custom" value="custom">Custom </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                }
                {
                    modalFields.customizePeriodicity.includes(scheduleType) && schedule.periodicitySchedule === "custom" &&
                    <Grid container spacing={0} className={classes.datePaddingBox}>
                        <Grid md={6} xs={6}>
                            <TextField fullWidth={true}
                                id={modalId + "prdsch-by"} select
                                label="By" variant="outlined" size="small"
                                value={schedule.periodicity}
                                onChange={(event) => {
                                    const newSchedule = { ...schedule, periodicity: event.target.value };

                                    setSchedule(newSchedule)
                                    setCustomSchedule({ ...customSchedule, periodicity: event.target.value })
                                    updateClientSchedule(newSchedule);
                                    updateProviderAvailabilitiesInfo(getUpdatedClientScheduleFromSchedule(newSchedule));
                                    setIsScheduleEdited(true)
                                }}
                            >
                                <MenuItem key="weeks" value={SCHEDULE_PERIODICITY_CONSTANTS.WEEKS}>Weeks </MenuItem>
                                <MenuItem key="months" value={SCHEDULE_PERIODICITY_CONSTANTS.MONTHS}>Months </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid md={6} xs={6} >
                            <TextField fullWidth={true}
                                id={modalId + "prdsch-rptevr"}
                                label="Repeat Every" variant="outlined" size="small"
                                style={{ marginLeft: 4 }}
                                value={schedule.everyXOfPeriods}
                                onChange={(event) => {
                                    var value: any = !event.target.value ? 0 : event.target.value;
                                    var integer = parseInt(value, 10);
                                    if (integer.toString() != "NaN") {
                                        const newSchedule = { ...schedule, everyXOfPeriods: integer };
                                        setSchedule(newSchedule)
                                        setCustomSchedule({ ...customSchedule, everyXOfPeriods: integer })
                                        updateClientSchedule(newSchedule);
                                        updateProviderAvailabilitiesInfo(getUpdatedClientScheduleFromSchedule(newSchedule));
                                    }
                                    else {
                                        setSchedule({ ...schedule, everyXOfPeriods: contextData.schedule.everyXOfPeriods })
                                    }
                                    setIsScheduleEdited(true)
                                }}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                }
                <p className={classes.infoTitle} style={{ marginBottom: 10, marginTop: 10 }}>Schedule:* </p>
                {
                    ["yungerman", "availability"].includes(scheduleType) || schedule.periodicitySchedule === "weekly" || schedule.periodicitySchedule === "bi-weekly" || schedule.periodicity === SCHEDULE_PERIODICITY_CONSTANTS.WEEKS ?
                        <div className={classes.daysPicker}>
                            <ToggleDays
                                id={modalId + "daypkr"}
                                weekset={weekset}
                                handleDayToggle={handleDayToggle}
                                days={
                                    contextData !== undefined && contextData.schedule !== undefined && contextData.schedule.days !== undefined ?
                                        getDaysArrayFromDaysString(contextData.schedule.days) : []
                                }
                                setDays={setDays}
                                errorText={fieldErrors[SCHEDULE_PERIODICITY_CONSTANTS.WEEKS]}
                                showRadioDaysOfWeek={modalFields.daysOfWeekRadio.includes(scheduleType)}
                            >
                            </ToggleDays>
                        </div>
                        :
                        <div className={classes.daysPicker}>
                            <ToggleMonthDays
                                id={modalId + "mntdaypkr"}
                                days={schedule.monthDays}
                                setDays={setMonthDays}
                                errorText={fieldErrors[SCHEDULE_PERIODICITY_CONSTANTS.MONTHS]}
                            >
                            </ToggleMonthDays>
                        </div>
                }
            </ProtectedComponent>
            {modalFields.rate.includes(scheduleType) ?
                <ProtectedComponent
                    allow
                    canEdit={actionPermissions?.canSetRate !== false && (contextData?.status !== 'active' || actionPermissions?.canEditConfirmedSchedules !== false)}
                >
                    <Grid container spacing={0}>
                        <Grid xs={6}>
                            <TextField
                                id={modalId + "rte"}
                                label="Rate"
                                variant="outlined"
                                size="small"
                                error={fieldErrors.rate != null}
                                helperText={fieldErrors.rate}
                                style={{ marginRight: 4, width: "100%" }}
                                value={scheduleRate && scheduleRate.rate ? scheduleRate.rate : 0}
                                onChange={(event) => {
                                    setDirty();
                                    setScheduleRate({ ...scheduleRate, rate: event.target.value })
                                    const validationResult = validateRate(parseInt(event.target.value), !!contextData.providerId);
                                    setFieldErrors({
                                        ...fieldErrors,
                                        ...validationResult.fieldMessages,
                                    });
                                    setIsRateEdited(true)
                                }}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextField
                                id={modalId + "rteprd"}
                                select
                                label="Rate Period"
                                variant="outlined"
                                size="small"
                                error={fieldErrors.ratePeriod != null}
                                helperText={fieldErrors.ratePeriod}
                                value={scheduleRate && scheduleRate.ratePeriod ? scheduleRate.ratePeriod : ""}
                                className={classes.field}
                                onChange={(event) => {
                                    setDirty();
                                    setScheduleRate({ ...scheduleRate, ratePeriod: event.target.value })
                                    const validationResult = validateRatePeriod(event.target.value, !!contextData.providerId);
                                    setFieldErrors({
                                        ...fieldErrors,
                                        ...validationResult.fieldMessages,
                                    });
                                    setIsRateEdited(true)
                                }}
                                style={{ margin: 0, marginLeft: 4, width: "100%" }}
                            >
                                <MenuItem key={1} value={"HOURS"}>{periodicities.HOURS}</MenuItem>
                                <MenuItem key={2} value={"MONTHS"}>{periodicities.MONTHS}</MenuItem>
                                <MenuItem key={3} value={"SESSION"}>{periodicities.SESSION}</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs={12}>
                            {contextData != undefined && contextData.providerId > 0
                                /*&& contextData.provider != undefined*/
                                ?
                                <div
                                    id={modalId + "pvd-regrte"}
                                    className={classes.pModelDate}>
                                    {contextData.providerName + "'s "} reg. rate:  {`$${contextData.providerRegRate}${periodicities[contextData.providerRegRatePeriod] ? `/${periodicities[contextData.providerRegRatePeriod]}` : ""}`}
                                </div>
                                : null}
                        </Grid>
                    </Grid>
                </ProtectedComponent>

                : null
            }
            <Grid container spacing={0} className={classes.datePaddingBox}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item md={6} xs={6}>
                        {formData.startDate ?
                            // need to separately render if it's null or not, due to a UniDatePicker bug,
                            // if you remove this, you'll see that the date picker will not have its set value when rendering it,
                            // as for some reason it's not rendering the value if it didn't have the value on first render,
                            // so I'm basically saying, when you get the value, just render a completely new JSX
                            // for the same reason we also must include the second one in a 'div' element
                            <UniDatePicker
                                calendarType={calendarType}
                                value={formData.startDate}
                                onChange={(newValue: string) => { changeDate('date', newValue) }}
                                label="Start Date"
                                isHebrew={true}
                                enableClear
                                error={fieldErrors.date != null}
                                helperText={fieldErrors.date}
                            />
                            :
                            <div> 
                                <UniDatePicker
                                    calendarType={calendarType}
                                    value={formData.startDate}
                                    onChange={(newValue: string) => { changeDate('date', newValue) }}
                                    label="Start Date"
                                    isHebrew={true}
                                    enableClear
                                    error={fieldErrors.date != null}
                                    helperText={fieldErrors.date}
                                />
                            </div>
                        }
                    </Grid>

                    <Grid item md={6} xs={6}>
                        <ProtectedComponent
                            allow
                            canEdit={actionPermissions?.canEditEndDate !== false}
                        >
                            {(formData.endDate) || endDate ?
                                <UniDatePicker
                                    calendarType={calendarType}
                                    value={formData.endDate}
                                    onChange={(newValue: string) => { changeDate('endDate', newValue) }}
                                    label="End Date"
                                    isHebrew={true}
                                    enableClear
                                    error={fieldErrors.date != null}
                                    helperText={fieldErrors.date}
                                />
                                :
                                <p className={classes.pModelDate1} style={{ cursor: 'pointer' }} onClick={() => setEndDate(true)}>Add End Date</p>
                            }
                        </ProtectedComponent>
                    </Grid>
                </LocalizationProvider>
            </Grid>

            <div className={classes.clearFilters} onClick={() => setCalendarType(calendarType === "heb" ? "eng" : "heb")} >{calendarType === "heb" ? "Switch To English" : "Switch To Hebrew"}</div>
            {contextData != undefined && contextData.schedule != undefined && contextData.schedule.endDate != null && new Date(contextData.schedule.endDate) <= new Date() &&
                (<Grid container spacing={0} className={classes.datePaddingBox}>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            fullWidth
                            size={"small"}
                            variant={"outlined"}
                            label={"Reason stopped working"}
                            id={"reason-schedule-ended"}
                            aria-label="minimum height"
                            value={contextData?.reasonScheduleEnded}
                            onChange={(event) => {
                                setContextData({ ...contextData, reasonScheduleEnded: event.target.value });
                                setDirty();
                            }}
                        />
                    </Grid>
                </Grid>)
            }
            <ProtectedComponent allow canEdit={contextData?.status !== 'active' || actionPermissions?.canEditConfirmedSchedules !== false}>
                <Grid container spacing={0} className={classes.datePaddingBox}>
                    <Grid item xs={6}>
                        <CusTimePicker
                            id={modalId + "strtim"}
                            label="Time From"
                            inputVariant="outlined"
                            size="small"
                            value={contextData?.schedule?.startTime ?? null}
                            fieldName={"startTime"}
                            fieldErrors={fieldErrors}
                            onChange={(newValue: string) => {
                                changeTimeFrom(newValue, "startTime");
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CusTimePicker
                            id={modalId + "endtim"}
                            label="Time To"
                            inputVariant="outlined"
                            size="small"
                            value={contextData?.schedule?.endTime ?? null}
                            fieldName={"endTime"}
                            fieldErrors={fieldErrors}
                            onChange={(newValue: string) => {
                                changeTimeFrom(newValue, "endTime");
                            }}
                        />
                    </Grid>
                </Grid>
            </ProtectedComponent>

            <Grid item xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                        style={{ marginTop: '20px' }}
                        multiline
                        fullWidth
                        size={"small"}
                        variant={"outlined"}
                        label={"Note"}
                        id={"note"}
                        value={contextData && contextData.note ? contextData.note : ""}
                        onChange={(event) => {
                            setDirty();
                            setContextData({ ...contextData, note: event.target.value });
                        }}
                    />
                </FormControl>
            </Grid>
        </div>
    )
}

export default ScheduleForm