
import React, { useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Autocomplete,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox
} from "@mui/material";
import Button from "@mui/material/Button";
import _ from "lodash";
import { getSystemListValuesListByName } from "../../../../api/system";
import ContactSelectField from "../../../../components/ContactSelect/ContactSelectField";
import * as constant from "../../../../constants";
import SelectContact from "./SelectContact";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles'
import { useStoreActions, useStoreState } from "easy-peasy";
import { TextFieldEditor } from "../../../common/UI";
import SelectEditor from '../../../common/UI/Inputs/Select/SelectEditor'
import { DivContainer } from "../../../../assets/styles/styledComponents";
import { addYeshivasContacts, updateYeshivasContacts } from "../../../../api/yeshivas";
import { strings } from "../../../../_helpers/strings";

const yeshivasContactFields = {
    relation: { value: '', error: '' },
    firstName: { value: '', error: '' },
    lastName: { value: '', error: '' },
    phone: { value: '', error: '' },
    email: { value: '', error: '' },
}

const YeshivaContactField = (props) => {
    const { handleChangeField, fieldData, roleValues } = props;
    return (
        <DivContainer>
            <TextFieldEditor
                label="First Name"
                name="firstName"
                onChange={handleChangeField}
                value={fieldData?.firstName?.value}
                error={!!fieldData?.firstName?.error}
                helperText={fieldData?.firstName?.error}
                fullWidth
            />
            <TextFieldEditor
                label="Last Name"
                name="lastName"
                onChange={handleChangeField}
                value={fieldData?.lastName?.value}
                error={!!fieldData?.lastName?.error}
                helperText={fieldData?.lastName?.error}
                fullWidth
            />
            <TextFieldEditor
                label="Phone Number"
                name="phone"
                type="number"
                hideArrows={true}
                onChange={handleChangeField}
                value={fieldData?.phone?.value}
                error={!!fieldData?.phone?.error}
                helperText={fieldData?.phone?.error}
                fullWidth
            />
            <TextFieldEditor
                label="Email"
                name="email"
                type="email"
                onChange={handleChangeField}
                value={fieldData?.email?.value}
                error={!!fieldData?.email?.error}
                helperText={fieldData?.email?.error}
                fullWidth
            />
            <SelectEditor
                id="relation"
                options={roleValues.map((role) => ({ label: role, value: role }))}
                label="Relation"
                name="relation"
                onChange={(value, name) => handleChangeField(value, name)}
                value={fieldData.relation?.value}
                error={!!fieldData.relation.error}
                helperText={fieldData.relation?.error}
                fullWidth
            />
        </DivContainer>
    )
}

export default function AddContact(props) {
    const { title, fieldData } = props;
    const classes = useStyles();
    const baseClasses = useStyles();

    // select contact
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const [assignee, setAssignee] = React.useState("");
    const [relationship, setRelationship] = React.useState(null);
    // select contact

    // Errors selection
    const [id, setId] = React.useState(null);
    const [loaderDialog, setLoaderDialog] = React.useState(false);
    const [contactSelectField, setContactSelectField] = React.useState(null);
    const exploringType = useStoreState((state) => state.exploringType);
    const exploringId = useStoreState((state) => state.exploringId);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const roleValues = useStoreState((state) => state.users.userRoles).filter((role) => role.startsWith('Yeshiva_'))
    const fetchUserRoles = useStoreActions(actions => actions.users.fetchUserRoles);

    const [relationshipOptions, setRelationshipOptions] = React.useState([]);

    const handleChangeField = (value, name) => {
        setContactSelectField({
            ...contactSelectField,
            [name]: {
                value: value,
                error: ''
            }
        })
    }

    useEffect(() => {
        roleValues.length <= 0 && fetchUserRoles();
    }, []);

    useEffect(() => {
        resetAllData();
        if (exploringType == "yeshivas") {
            setContactSelectField(yeshivasContactFields)
        } else {
            getSystemListValuesListByName("Client_Relationship").then((response) => {
                setRelationshipOptions(response);
            });
            // setRelationshipOptions(["Parent", "Sibling", "Legal Guardian", "Other"]);
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(props.editData)) {
            const edit = props.editData;
            setAssignee(edit.name);
            if (exploringType === "yeshivas") {
                setContactSelectField({
                    contactId: edit.contactId,
                    firstName: { value: edit.firstName ?? '', error: '' },
                    lastName: { value: edit.lastName ?? '', error: '' },
                    phone: { value: edit.phone ?? '', error: '' },
                    email: { value: edit.email ?? '', error: '' },
                    relation: { value: edit.relation ?? '', error: '' },
                })
            }
            setRelationship(edit.relationship);
        } else {
            if (exploringType === "yeshivas") {
                setContactSelectField(yeshivasContactFields)
            }
        }
    }, [props.isOpenCreateModal]);

    const getContactNameFromProp = () => {
        if (_.isEmpty(props.editData)) return "";
        return props.editData.name;
    };

    function resetAllData() {
        setAssignee("");
        setRelationship("");
    }

    const saveContacts = () => {
        global.log("add contact");
        if (contactSelectField == null) {
            return;
        }
        // TODO: Why needed if statement here when condition is always true? 
        if (true) {
            // global.log(tarContact.id);
            var id = -1;
            if (!_.isEmpty(props.editData)) {
                id = props.editData.id;
            }

            if (exploringType === constant.explorerTypesConst.yeshiva || exploringType === constant.explorerTypesConst.yeshivas) {
                let payload = { ...contactSelectField }
                let contactId = props.editData?.contactId ?? -1;
                Object.keys(payload).forEach(function (key) {
                    payload[key] = payload[key]?.value ?? payload[key]
                });
                if (!payload.firstName || !payload.lastName /*|| !strings.validateEmail(payload.email, true).isValid*/) {
                    setContactSelectField({
                        ...contactSelectField,
                        firstName: !payload.firstName ? { value: '', error: 'First Name is Required' } : { value: payload.firstName, error: '' },
                        lastName: !payload.lastName ? { value: '', error: 'Last Name is Required' } : { value: payload.lastName, error: '' },
                        email: { value: payload.email, /*error: strings.validateEmail(payload.email, true).message*/ }
                    })
                    return
                }
                setLoading();
                if (contactId > 0) {
                    updateYeshivasContacts(exploringId, payload, contactId).then((data) => {
                        if (!!data) {
                            endLoading();
                            handleClose(true);
                        }
                    })
                } else {
                    addYeshivasContacts(exploringId, payload).then((data) => {
                        if (!!data) {
                            endLoading();
                            handleClose(true);
                        }
                    })
                }
            } else {
                var url =
                    constant.SAVE_CLIENT_RELATIONSHIP +
                    id +
                    "/" +
                    exploringType +
                    "/" +
                    exploringId +
                    "/" +
                    contactSelectField.id +
                    "/" +
                    relationship;
                return global.fetchWrapper.post(url).then(
                    (response) => {
                        //TODO: We need to store response of every post call to store, so that we should not fetch update data again
                        handleClose();
                    },
                    (error) => {
                        global.AjaxFailToast();
                        handleClose();
                        global.log("error", error);
                    }
                );
            }
            // global.log("submit data", saveContactPayload);
        }
    };

    const handleClose = (isReload = false) => {
        endLoading();
        props.onCloseModal(isReload);
        resetAllData();
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.isOpenCreateModal}
                onClose={() => handleClose()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle
                    className={baseClasses.dialogTitle}
                    id="max-width-dialog-title"
                >
                    {title ?? "Add a relationship"}
                </DialogTitle>
                <DialogContent>
                    {loaderDialog ? (
                        <div>Loading...</div>
                    ) : (
                        <div>
                            {
                                exploringType === constant.explorerTypesConst.yeshivas ? (
                                    <YeshivaContactField
                                        handleChangeField={handleChangeField}
                                        fieldData={contactSelectField}
                                        roleValues={roleValues}
                                    />
                                ) :
                                    <div>
                                        <Grid
                                            container
                                            className={baseClasses.grid}
                                            alignItems="flex-start"
                                            spacing={2}
                                        >
                                            <Grid item xs={12}>
                                                <div>
                                                    <ContactSelectField
                                                        editData={getContactNameFromProp()}
                                                        onChange={(contact) => setContactSelectField(contact)}
                                                    ></ContactSelectField>
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                    <div>
                        <FormControl fullWidth>
                        <InputLabel htmlFor="my-input">Select Contact</InputLabel>
                        <Input
                            value={assignee}
                            id="my-input"
                            onFocus={() => {
                            _.isEmpty(props.editData) && setIsSelectOpen(true);
                            }}
                            aria-describedby="my-helper-text"
                        />
                        {assigneePerError === "" ? null : (
                            <FormHelperText
                            className={baseClasses.error}
                            id="my-helper-text"
                            >
                            {assigneePerError}
                            </FormHelperText>
                        )}
                        </FormControl>
                    </div>
                    </Grid> */}

                                            <Grid item xs={12}>
                                                <div>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            options={relationshipOptions}
                                                            getOptionLabel={(option) => option}
                                                            className={classes.selection__autocomplete}
                                                            openOnFocus
                                                            autoComplete
                                                            value={relationship}
                                                            onChange={(event, newValue) => {
                                                                setRelationship(newValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Relationship"
                                                                    margin="normal"
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                            }
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveContacts} color="primary">
                        Save
                    </Button>
                    <Button onClick={() => handleClose()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {isSelectOpen && (
                <SelectContact
                    openModal={true}
                    handleClose={() => {
                        setIsSelectOpen(false);
                    }}
                    addYeshiva={(contact) => {
                        // saveYeshivasContact(contact);
                        setAssignee(contact.firstName + " " + contact.lastName);
                    }}
                ></SelectContact>
            )}
        </>
    );
}