import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableRow, TableBody, TableCell, Collapse, Box, Tooltip, IconButton } from '@mui/material'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PauseIcon from '@mui/icons-material/Pause';
import useStyles, { StyledTableCell, StyledTableRowCell, StyledTableRow } from './styles'
import { clientScheduleStatus, periodicities, } from "../../../_helpers/constants/scheduleConstants";
import LoadingBar from '../../../components/LoadingBar/LoadingBar';
import ClickableTableRow from '../../../components/UI/ClickableTableRow';
import { scheduleCardMapping } from '../../../constants';
import { CLIENT_ROUTE, EMPLOYEE_ROUTE } from '../../../frontend_route_constants';
const ITEM_HEIGHT = 48;
const route = {
    provider: EMPLOYEE_ROUTE,
    client: CLIENT_ROUTE,
}
const CardBody = (props) => {
    const { scheduleType, isLoading, columns, title, rowsPotentialSectionIsOpen, rows, scheType, openDetailsPageInNewTab, allowedActions, allowedSeekingAction, handleMenuClick, handleClick, handleRowClick, getOriginalRow, clickableRows, routeTab } = props;
    const [filteredRows, setFilteredRows] = useState(rows);
    const [columnDef, setColumnDef] = useState(columns);

    const classes = useStyles();
    const formatDate = (dateTime) => {
        return global.dateTime.getUSAFormattedDateString(dateTime);
    }

    useEffect(() => {
        setFilteredRows(rows)
    }, [rows])

    /**
     * This is returning appropriate cell data for all Card Components - Switch condition is based on concatenation of ColumnType
     * comparison const for all cards is defined in constants file 
     * @function renderFeaturedTableCell
     * @param {*} column 
     * @param {*} row  
     * @returns StyledTableRowCell - React Component
     */
    const renderFeaturedTableCell = (column, row, title) => {
        const scheduleDisplay = (scheType === "yungerman" && row.schedule) ?
            row.schedule :
            ((scheType === "professional" || scheType === "availability") || row.scheduleDisplayName) ? row.scheduleDisplayName : "-";
        switch (column.type) {
            // Time 
            case scheduleCardMapping.time:
                return <StyledTableRowCell maxWidth={120} paddingLeft={10} >
                    {(row.startTime || row.endTime) ? global.dateTime.formatStartAndEndTime(row.startTime, row.endTime) : "-"}
                </StyledTableRowCell>

            // Provider || Client || Professional || Yungermen
            case scheduleCardMapping.name:
                return <StyledTableRowCell
                    className={classes.clickableItem}
                    maxWidth={100}
                    paddingLeft={10}
                    onClick={(event) => {
                        openDetailsPageInNewTab(event, route[column.field], routeTab, column.field === 'provider' ? row.providerId : row.clientId)
                    }}
                >
                    {row[`${column.value}`]}
                </StyledTableRowCell>
            // Schedule 
            case scheduleCardMapping.schedule:
                return <StyledTableRowCell maxWidth={140}>{scheduleDisplay}</StyledTableRowCell>

            // Upcoming
            case scheduleCardMapping.nextAppt:
                return <StyledTableRowCell maxWidth={120}>{row.nextSession ? formatDate(row.nextSession) : "-"}</StyledTableRowCell>

            // Date
            case scheduleCardMapping.date:
                return <StyledTableRowCell maxWidth={120}>{global.dateTime.getUSAFormattedDateString(row.date || row.startDate)}</StyledTableRowCell>

            // Note 
            case scheduleCardMapping.note:
                return <StyledTableRowCell maxWidth={140}>{row.note ? row.note : "-"}</StyledTableRowCell>
            // Meargen
            case scheduleCardMapping.meargen:
                return <StyledTableRowCell maxWidth={120}>{row.meargen ? row.meargen : "-"}</StyledTableRowCell>

            // Throughout
            case scheduleCardMapping.throughout:
                return <StyledTableRowCell maxWidth={120}>{row.startDate ? formatDate(row.startDate) : "-"} - {row.endDate ? formatDate(row.endDate) : "-"}</StyledTableRowCell>

            // Rate
            case scheduleCardMapping.rate:
                return <StyledTableRowCell maxWidth={120}>
                    {row.rate ? `$${row.rate}${periodicities[row.ratePeriod] ? `/${periodicities[row.ratePeriod]}` : ""}` : ""}
                </StyledTableRowCell>

            //Between    
            case scheduleCardMapping.between:
                return <StyledTableRowCell maxWidth={100}>{global.dateTime.formatStartAndEndDate(row.startDate, row.endDate)}</StyledTableRowCell>

            // Type
            case scheduleCardMapping.type:
                return <StyledTableRowCell paddingLeft={10} maxWidth={100}>{row?.type}</StyledTableRowCell>

            // Requested On
            case scheduleCardMapping.createdon:
                return <StyledTableRowCell maxWidth={100}>{global.dateTime.getUSAFormattedDateString(row.createdOn)}</StyledTableRowCell>

            // Status
            case scheduleCardMapping.status:
                return <StyledTableRowCell maxWidth={100}>{row?.status}</StyledTableRowCell>
            // Specialties
            case scheduleCardMapping.specialties:
                return <StyledTableRowCell maxWidth={100}>{row.needs}</StyledTableRowCell>

            // status
            case scheduleCardMapping.statusIcon:
                return <StyledTableRow style={{ padding: 0 }}>
                    {row.status &&
                        <Tooltip
                            placement="top"
                            title={(
                                <>
                                    <div>Status: {row.status === "seeking" ? clientScheduleStatus[row.type] : clientScheduleStatus[row.status]}</div>
                                    {row.status.toLowerCase() === 'rejected' &&
                                        <div>Reason: {row.reasonForRejection}</div>
                                    }
                                    {row.dateApproved &&
                                        <div>Date Approved: {global.dateTime.getUSAFormattedDateString(row.dateApproved)}</div>
                                    }
                                </>
                            )}
                        >
                            {row.status.toLowerCase() === "active" ?
                                <CheckOutlinedIcon style={{ color: "green" }} fontSize={"small"} />
                                : row.status.toLowerCase() === "financial" ?
                                    <WarningIcon style={{ color: "red" }} fontSize={"small"} /> :
                                    row.status.toLowerCase() === "rejected" ?
                                        <ClearIcon style={{ color: "red" }} fontSize={"small"} /> :
                                        row.status.toLowerCase() === "seeking" ?
                                            <PersonSearchIcon style={{ color: "#1976d2" }} {...props} /> :
                                            row.status.toLowerCase() === "onhold" ?
                                                <PauseIcon style={{ color: "#1976d2" }} {...props} /> :
                                                <InfoOutlinedIcon fontSize={"small"} style={{ opacity: 0.54 }} />}
                        </Tooltip>
                    }
                </StyledTableRow>

            // edit
            case scheduleCardMapping.editIcon:
                return <StyledTableRow style={{ padding: 0 }}>
                    {
                        allowedActions.length > 0 &&
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                                handleMenuClick(event, row, allowedActions, scheType)
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>

                    }
                </StyledTableRow>
            // expand
            case scheduleCardMapping.expandIcon:
                return <StyledTableRow align="right">
                    {row.clientSchedulePotentials && row.clientSchedulePotentials.length > 0 && <IconButton aria-label="expand row" size="small" onClick={(event) => handleClick(event, row.clientScheduleId)}>
                        {rowsPotentialSectionIsOpen(row.clientScheduleId) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    }
                </StyledTableRow>
            // delete
            case scheduleCardMapping.deleteIcon:
                return <StyledTableRow
                    style={{ cursor: allowedSeekingAction[row.type]?.includes('delete') ? 'pointer' : 'default' }}
                >
                    {allowedSeekingAction[row.type]?.includes('delete') && <DeleteIcon
                        fontSize="small"
                        onClick={(event) => {
                            props.setSlcRowData(row, row?.type);
                            props.handleDeleteOpen()
                            event.stopPropagation();
                        }} />}
                </StyledTableRow>
            default:
                return null
        }
    }

    const handleClickCell = (column) => {
        if (column.sortable) {
            let tempRows = filteredRows.sort((a, b) => {
                let val1 = a[column.field];
                let val2 = b[column.field];
                if (column.field === 'time') {
                     val1 = global.dateTime.getDateObject(a.startTime);
                     val2 = global.dateTime.getDateObject(b.startTime);
                }
                if (val1 === val2) return 0
                if (!val1) return 1
                if(!val2) return -1
                if (column.order === 'asc') {
                    setColumnDef(columnDef.map((item) => item.field === column.field ? {...column, order: 'desc'} : item))
                    if (val1 < val2) {
                        return -1;
                      }
                      if (val1 > val2) {
                        return 1;
                      }
                      return 0;
                } else {
                    setColumnDef(columnDef.map((item) => item.field === column.field ? {...column, order: 'asc'} : item))
                    if (val1 < val2) {
                        return 1;
                      }
                      if (val1 > val2) {
                        return -1;
                      }
                      return 0;
                }
               
            })
            setFilteredRows(tempRows) 
        }
        return
    }

    return (
        <React.Fragment>
            {
                isLoading ?
                    <LoadingBar />
                    : !columnDef ? <div>No columns defined to this component</div> :
                        <Table aria-label="simple table">
                            <TableHead className={classes.table}>
                                <TableRow>
                                    {
                                        columnDef && columnDef.map((column) => {
                                            return <StyledTableCell sortOrder={column.order} onClick={() => {handleClickCell(column)}}>{["editIcon", "statusIcon", "expandIcon", "deleteIcon"]?.includes(column.type) ? '' : column.title}</StyledTableCell>
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {filteredRows?.map((row) => {
                                    return (
                                        <React.Fragment>
                                            <span id={"status-test" + row.clientScheduleId} hidden style={{ height: "0px" }}></span>
                                            {
                                                clickableRows ?
                                                    <ClickableTableRow
                                                        onClick={(event) => {
                                                            if (!allowedSeekingAction[row.type])
                                                                return;
                                                            event.stopPropagation();
                                                            if (allowedSeekingAction[row.type]?.includes('edit'))
                                                                handleRowClick(getOriginalRow(row), row?.type);
                                                        }}
                                                        style={{ cursor: allowedSeekingAction[row.type]?.includes('edit') ? 'pointer' : 'default', height: ITEM_HEIGHT }}
                                                    >
                                                        {
                                                            columnDef.map((column) => {
                                                                return renderFeaturedTableCell(column, row, title);
                                                            })
                                                        }
                                                    </ClickableTableRow> :
                                                    <TableRow classes={classes.tableBody} key={row.clientScheduleId} style={{ height: ITEM_HEIGHT }} >
                                                        {
                                                            columnDef.map((column) => {
                                                                return renderFeaturedTableCell(column, row, title);
                                                            })
                                                        }
                                                    </TableRow>

                                            }

                                            {(columnDef.some((item) => item.type === "expandIcon")) &&
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={8}>
                                                        <Collapse in={rowsPotentialSectionIsOpen(row.clientScheduleId)} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <TableHead variant="subtitle2" gutterBottom component="div">
                                                                    <b>Potential Schedules</b>
                                                                </TableHead>
                                                                <Table size="small">
                                                                    <TableBody>
                                                                        {row.clientSchedulePotentials.map((csp) => (
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    {csp.providerName}
                                                                                </TableCell>
                                                                                <TableCell>{csp.status}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    )
                                })}

                            </TableBody>
                        </Table>
            }
        </React.Fragment>
    )
}

export default CardBody


