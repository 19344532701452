import { makeStyles } from "@mui/styles";
import { maxWidth, styled } from '@mui/system';
import { theme } from "../../../../assets/theme/theme";

export const DivContainer = styled("div")({
    display: 'block'
});

export const TableBodyContainer = styled("div")({
    height: '432px',
    overflowY: 'scroll'
});


export const ColumnHeader:any = styled("div")((props:any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: props.bgColor || theme.palette.primary.main,
    color: props.color || '#fff',
    padding: '5px'
}));

export const ColumnItem:any = styled("div")((props:any) => ({
    display: props.hide ? 'none': 'block',
    padding: '3px 5px',
    width: props.width,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    
}));

export const RowItem:any = styled("div")({
    padding: '3px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: `1px solid ${theme.palette.background.paper}`,
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)'
    }
});

export const MissionOverviewCardContainer= styled('div')({
    display: 'block',
});

export const CardHeaderAction:any = styled('div')((props:any) => ({
    padding: '0px 10px',
    display: 'flex',
    justifyContent: props.justifyContent || 'flex-end',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.12)'
}));

export const FlexContainer = styled('div')({
    display: 'flex',
    margin: '10px 3px'
});

export const TextButton = styled('div')((props:any) => ({
    color: props.color === 'primary' ? theme.palette.primary.main: '#000',
    padding: '10px 15px',
    cursor: 'pointer',
}));

export const TitleContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const Text:any = styled('div')((props:any) => ({
    wordBreak: 'break-word',
    fontSize: '14px',
    fontWeight: props.fontWeight || 400,
    marginRight: '10px',
    width: props.width || 'unset',
    color: props.color || '#000',
    marginBottom: '5px'
}))

export const Title:any = styled('div')((props:any) =>({
  color: props.completed ? theme.palette.success.main : `#000`,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: props.width ? `${props.width}px` : '300px',
  fontWeight: props.fontWeight ? props.fontWeight: 500

}));

export const DateSection = styled('div')({
  
});

export const Status = styled('div')({
  
});

export const NotesContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const Note = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

export const Icon = styled('div')({
  
});

export default makeStyles((theme:any) => ({
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        // margin: "12px 26px",
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .MuiFormControlLabel-labelPlacementStart": {
            marginRight: "0px",
        },
    },
    cardPadding: {
        padding: '8px !important'
    },
    marTop20: {
        marginTop: '40px !important'
    },
    textBold: {
        fontWeight: 500,
        paddingRight: '5px',
        fontSize: '15px'
    },
    contentCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    // End switch

    columnHeder: {
        textAlign: "left",
        paddingLeft: 16,
        fontSize: 17,
        paddingBottom: 13,
    },
    columnFirst: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-between",
        width: "50%",
    },
    hoursWidget: { textAlign: "center", marginLeft: 25, width: "70%" },
    columnFirstWidget: {
        display: "flex",
        justifyContent: "space-between",
    },
    cardSpaceStyle: {
        padding: "10px 20px",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },

    marginSpaceOne: {
        // margin: theme.spacing(1),
        // margin : "8px 0px 8px 0px"
        margin: "0px",
    },

    flexRow: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },

    /* Card Style */
    cardHeader: {
        textTransform: "capitalize",
        backgroundColor: "#FFF",
        minHeight: 39,
        height: 39,
        "& .MuiTypography-colorTextSecondary": {
            color: "rgba(0, 0, 0, 0.54)",
        },
    },
    /* Schedule Style */

    overview_main: {
        padding: "8px",
        paddingBottom: "39px",
    },
    
    
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: `${theme.palette.background.paper} !important`,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    header: {
        fontWeight: "bold",
        display: "flex",
        width: "100%",
        marginBottom: "12px",
    },
    row_todo: {
        width: "100%",
        display: "flex",
        marginTop: "4px",
        background: "#f3f3f3",
        padding: "0px 12px",
        borderRadius: "4px",
    },
    cardContainer: {
        position: 'relative',
        marginTop: '10px',
        width: '100%',
        padding: '5px',
        minHeight: '400px',
        background: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    editIcon: {
        display: 'none !important'
    },
    addNew: {
        display: 'flex !important',
        justifyContent: 'flex-end'
    },
    missionItemText: {
        overflow: "hidden",
        maxWidth: '120px',
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    missionItem: {
        borderLeft: '4px solid transparent !important',
        '&.Mui-selected': {
            backgroundColor: 'unset !important',
            borderLeft: `4px solid ${theme.palette.primary.main} !important`
        },
        '&:hover': {
            '& .MuiSvgIcon-root': {
                display: 'block !important'
            }
        }
    },
    missionCompleted: {
        color: '#2e7d32 !important',
        '&.Mui-selected': {
            backgroundColor: 'unset !important',
            borderLeft: `4px solid #2e7d32 !important`
        },
    },
    mr10: {
        margin: '10px 5px'
    },

}));