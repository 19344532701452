import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import {
    FormControl,
    FormHelperText,
    Grid,
    Button,
    Input,
    TextField,
    InputLabel,
    MenuItem, Select,
    Card, CardContent, Typography, Divider
} from "@mui/material";
import { SectionComponent, NamesToStringComp, EmployeeTypesToString, CompSelectbox, CompTextField } from "./employeeApplicantComponent";
import baseStyles from '../../../assets/styles';
import _ from "lodash";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";
import { periodicities } from "../../../_helpers/constants/scheduleConstants";

export default function PersonalSection({ sectionState, positionValues, employee, yungermanId, professionalId, setSectionState, specialtyValues, paymentValues, professionalTypes, onBackUrl, rollbackChanges, confirmUnsavedChanges }) {
    const baseClasses = baseStyles();
    const theme = useTheme();
    const [employeeDetail, setEmployeeDetail] = useState(employee);
    const setSectionChanged1 = useStoreActions((actions) => actions.employee.setSectionChanged1);
    const [selectedNeighborhood, setSelectedNeighborhood] = React.useState("");
    // const [selectedHasTextOption, setSelectedHasTextOption] = React.useState("");
    const [selectedRatePeriodOption, setSelectedRatePeriodOption] = React.useState("");
    const [neighborhoodText, setNeighborhoodText] = React.useState("");
    const neighborhoodValues = useStoreState(state => state.list.values.neighborhoods);
    const neighborhoodsIsFetched = useStoreState(state => state.list.values.neighborhoodsIsFetched);
    const fetchNeighborhoods = useStoreActions(actions => actions.list.values.fetchNeighborhoods);
    const [isValidEmployeeType, setIsValidEmployeeType] = useState(false)
    const [showEmployeeTypeError, setShowEmployeeTypeError] = useState(false)
    const [isFirstNameValid, setIsFirstNameValid] = useState(false)
    const [showFirstNameError, setShowFirstNameError] = useState(false)
    const [isLastNameValid, setIsLastNameValid] = useState(false)
    const [showLastNameError, setShowLastNameError] = useState(false)
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    const handleNeighborhoodChange = (event) => {
        setSelectedNeighborhood(event.target.value);
        setEmployeeDetail2({ ...employeeDetail, "contact": { ...employeeDetail.contact, "neighborhood": event.target.value } });
        setDirty();
    }
    // const handleHasTextChange = event => {
    //     setSelectedHasTextOption(event.target.value)
    //     setEmployeeDetail2({ ...employeeDetail, "contact": { ...employeeDetail.contact, "hasText": event.target.value === "null" ? null : event.target.value } });
    //     setDirty();
    // }
    const handleRatePeriodChange = event => {
        setSelectedRatePeriodOption(event.target.value)
        setEmployeeDetail2({ ...employeeDetail, "ratePeriod": event.target.value });
        setDirty();
    }

    useEffect(() => {
        if (!neighborhoodsIsFetched) {
            fetchNeighborhoods();
        }
    }
        , [])
    useEffect(() => {
        setEmployeeDetail(employee);
        // setSelectedNeighborhood(employee.contact.neighborhood);
        // setSelectedHasTextOption(employee.contact.hasText === null ? "null" : employee.contact.hasText);
        setSelectedRatePeriodOption(employee.ratePeriod);
        setClean();
    }, [employee]);
    useEffect(() => {
        setClean();
    }, []);


    let sectionState2 = sectionState;
    const exploringId = useStoreState((state) => state.exploringId);

    const setEmployeeDetail2 = (detail) => {
        setEmployeeDetail(detail);
        setDirty();
    }

    const validateBeforeSave = () => {
        const readyToSave = isFirstNameValid && isLastNameValid
        // setShowEmployeeTypeError(!isValidEmployeeType);
        setShowFirstNameError(!isFirstNameValid);
        setShowLastNameError(!isLastNameValid)
        return readyToSave;
    }

    const CompTextFieldContact = ({ xs, label, name, showError, setValid, employeeDetail, setEmployeeDetail2 }) => {
        const contact = employeeDetail?.contact || {};

        const [value, setValue] = React.useState(contact[name] || "");

        useEffect(() => {
            setValue(contact[name] || "");
        }, [employeeDetail]);

        useEffect(() => {
            if (typeof setValid === 'function') {
                setValid(value !== "");
            }
        }, [value, setValid]);

        const handleAge = (value) => {
            return isNaN(parseInt(value)) ? 0 : parseInt(value);
        };
        return (
            <Grid item xs={xs}>
                <div>
                    <FormControl fullWidth>
                        <InputLabel>{label}</InputLabel>
                        <Input
                            type={name === "age" ? "number" : "text"}
                            value={global.isEmpty(value) ? "" : value}
                            onChange={(event) => {
                                setValue(event.target.value);
                                setDirty();
                            }}
                            onBlur={(event) => {
                                global.log(event.target.value);
                                setEmployeeDetail2({
                                    ...employeeDetail,
                                    contact: {
                                        ...employeeDetail.contact,
                                        [name]: name === "age" ? handleAge(event.target.value) : event.target.value,
                                    },
                                });
                                setDirty();
                            }}
                            aria-describedby={"my-helper-text" + name}
                        />
                        {showError && (
                            <FormHelperText className={baseClasses.error} id={"my-helper-text" + name}>
                                required field
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
            </Grid>
        );
    };

    return (
        <SectionComponent
            headerTitle="Personal"
            onSave={() => { }}
            onBeforeSave={validateBeforeSave}
            onBackUrl={onBackUrl}
            employeeDetail={employeeDetail}
            state={sectionState2}
            onStateChange={setSectionState}
            rollbackChanges={rollbackChanges}
            confirmUnsavedChanges={confirmUnsavedChanges}
            readDOM={
                <Grid container className={baseClasses.grid} alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Employee Type:</div>
                        <EmployeeTypesToString employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Name:</div>
                        <NamesToStringComp nameList={["firstName", "lastName"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Hebrew Name:</div>
                        <NamesToStringComp nameList={["firstNameHebrew", "lastNameHebrew"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Legal Name:</div>
                        <NamesToStringComp nameList={["firstNameLegal", "middleNameLegal", "lastNameLegal"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Address:</div>
                        <NamesToStringComp nameList={["address1", "address2", "city", "state", "zip"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Cell Number:</div>
                        <NamesToStringComp nameList={["phone1"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Home Number:</div>
                        <NamesToStringComp nameList={["phone2"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Email:</div>
                        <NamesToStringComp nameList={["email1"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Age:</div>
                        <NamesToStringComp nameList={["age"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Year Experience with Bucherim:</div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail["yearsOfExperienceWithBucherim"])
                                ? "-"
                                : employeeDetail["yearsOfExperienceWithBucherim"]}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Rate:</div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail["rate"])
                                ? "-"
                                : `$${employeeDetail["rate"]} ${periodicities[employeeDetail["ratePeriod"]] ?? ""}`}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Rate Note:</div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail["rateNote"]) ? "-" : employeeDetail["rateNote"]}
                        </div>
                    </Grid>
                </Grid>
            }
            editDOM={
                <Grid container className={clsx(baseClasses.grid)} alignItems="flex-start" spacing={2}>
                    <Grid xs={12} style={{ background: "#ecf0f57a", padding: 10, paddingBottom: 0 }}>
                        {/* <EmployeeTypeSelector
                            employeeDetail={employeeDetail}
                            setEmployeeDetail={setEmployeeDetail}
                            setIsValidEmployeeType={setIsValidEmployeeType}
                            showError={showEmployeeTypeError}
                        /> */}
                    </Grid>
                    {CompTextFieldContact({ xs: 6, label: "First Name", name: "firstName", showError: showFirstNameError, setValid: setIsFirstNameValid, employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 6, label: "Last Name", name: "lastName", showError: showLastNameError, setValid: setIsLastNameValid, employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 6, label: "Hebrew First Name", name: "firstNameHebrew", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 6, label: "Hebrew Last Name", name: "lastNameHebrew", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Legal First Name", name: "firstNameLegal", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Legal Middle Name", name: "middleNameLegal", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Legal Last Name", name: "lastNameLegal", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Address", name: "address1", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Apt", name: "address2", employeeDetail, setEmployeeDetail2 })}
                    <Grid item xs={4}>
                        <FormControl fullWidth className={baseClasses.optionsContract}>
                            <InputLabel htmlFor="neighborhood-select" id="neighborhood-label">Neighborhood</InputLabel>
                            <Select
                                labelId="neighborhood-label"
                                id="neighborhood-select"
                                value={selectedNeighborhood}
                                className={baseClasses.selection}
                                onChange={handleNeighborhoodChange}
                            >
                                {neighborhoodValues.map((neighborhood, index) => (
                                    <MenuItem key={index} value={neighborhoodValues[index]}>{neighborhood}</MenuItem>
                                ))}
                            </Select>
                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                        </FormControl>
                    </Grid>
                    {CompTextFieldContact({ xs: 4, label: "City", name: "city", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "State", name: "state", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Zip", name: "zip", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 3, label: "Cell Number", name: "phone1", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Home Number", name: "phone2", employeeDetail, setEmployeeDetail2 })}
                    {CompTextFieldContact({ xs: 4, label: "Email", name: "email1", employeeDetail, setEmployeeDetail2 })}
                    {/* <CompTextFieldContact xs={4} label="Age" name="age" showError={showAgeError} setValid={setIsAgeValid} employeeDetail={employeeDetail} setEmployeeDetail2={setEmployeeDetail2} /> */}
                </Grid>
            }
        />
    );
}    