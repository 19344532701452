import { Divider, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import { useEffect } from "react";
import ContactSelectField from "../../../components/ContactSelect/ContactSelectField";
import PanelContact from "./PanelNewContact";
import useStyles from "./postStyle";
import baseStyles from '../../../assets/styles'
/**
 *
 * @param {headerTitle, contactId } props
 * @returns
 */
export default function DefaultComponent(props) {
    const { headerTitle, propsMeta, contactId, view, setView } = props;
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [mode, setMode] = React.useState("read");
    //   const [mode, setMode] = React.useState("edit");
    const [contact, setContact] = React.useState(null);

    useEffect(() => {
        resetData();
    }, [props.contactId]);
    useEffect(() => {
        setView(mode == "read");
    }, [mode]);
    useEffect(() => {
        if (view) {
            close();
        }
    }, [view]);

    const resetData = () => {
        global.contactAPI.getContactDetail(contactId).then((response) => {
            setContact(response);
        });
    };

    //   useEffect(() => {
    //     props.onChange(getContactId(contact));
    //   }, [contact]);

    const getContactId = (contact) => {
        let _id = contact ? contact.id : 0;
        return _id;
    };

    const _handleParentView = () => {
        let confirmed = props.confirmOpen();
        if (confirmed) {
            setMode("edit");
        }
    };
    const close = () => {
        setMode("read");
        props.setClean();
    }
    const _handleParentClose = () => {
        let confirmed = props.confirmCancel();
        if (confirmed) {
            close();
        }
    };

    return (
        <>
            <div className={classes.header_1}>
                <Typography className={baseClasses.title_2} variant="h6" component="span">
                    {headerTitle} Contact Information
                </Typography>

                {props.canEdit &&
                    <div>
                        {mode == "read" ? (
                            <EditIcon
                                className={baseClasses.edit_icon}
                                onClick={_handleParentView}
                            />
                        ) : (
                            <CloseIcon
                                className={baseClasses.edit_icon}
                                onClick={_handleParentClose}
                            />
                        )}
                    </div>
                }
                {/* { propsMeta.parent_view ? <EditIcon className={classes.editIcon} onClick={_handleParentView} /> : <CloseIcon className={classes.editIcon} onClick={_handleParentClose} /> } */}
            </div>
            <Divider className={classes.separator}></Divider>
                {/* TODO: Always do strict comparison with === not == */}
            {mode == "read" ? (
                // TODO: Read only data is repeating, create a common component that render field title, field value
                <Grid
                    container
                    className={baseClasses.grid}
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Name: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact)
                                ? "-"
                                : contact.firstName + " " + contact.lastName}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Hebrew Name:</div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact)
                                ? "-"
                                : contact.firstNameHebrew + " " + contact.lastNameHebrew}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Legal Name:</div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact)
                                ? "-"
                                : contact.firstNameLegal +
                                " " +
                                contact.middleNameLegal +
                                " " +
                                contact.lastNameLegal}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Cell Phone: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact) ? "-" : contact.phone1}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Work/Other Phone: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact) ? "-" : contact.phone2}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Email 1: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact) ? "-" : contact.email1}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Email 2: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact) ? "-" : contact.email2}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Address:</div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(contact)
                                ? "-"
                                : contact.city + " " + contact.state + " " + contact.zip}
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <div style={{ padding: 32, paddingTop: 0 }}>
                    <div className="mb-3">
                        <ContactSelectField
                            label={"Select existing"}
                            editData={getContactId(contact)}
                            editDataType={"id"}
                            allowNew={false}
                            onChange={(contact) => {
                                if (!contact) {
                                    setContact(null);
                                    props.onChange(0);
                                } else {
                                    props.onChange(getContactId(contact));
                                    global.contactAPI
                                        .getContactDetail(contact.id)
                                        .then((response) => {
                                            setContact(response);
                                        });
                                }
                            }}
                        ></ContactSelectField>
                    </div>
                    <PanelContact
                        editData={contact}
                        handleClose={() => {
                            _handleParentClose();
                        }}
                        handleAdded={(contactId) => {
                            if (contactId != null) {
                                props.saveParentContact(contactId);
                                resetData();
                                close();
                            }
                        }}
                    ></PanelContact>
                </div>
            )}
        </>
    );
}
