import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "16px 0px",
        margin: "12px 26px",
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            padding: "0 15px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-colCell": {
            // margin: "0 15px",
            padding: 0,
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell": {
            // margin: "0 15px",
            // padding: 0,
            borderBottom: "none",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .icon": {
            padding: 3,
        },
    },  
}));