import React from "react";
import TInputExpiredDateForCreditCard from "./TInputExpiredDateForCreditCard";
import TextField from "@mui/material/TextField";

const TTextFieldExpiredDateCreditCard = (props) => {
    const [values, setValues] = React.useState({
        ccmask: props.value,
    });

    const handleChange = (event) => {
        setValues((prevState) => {
            return {
                ...prevState,
                ccmask: event.target.value
            }
        });
        props.onChange(event);
    };

    return (
        <TextField
            label={props.label}
            fullWidth={props.fullWidth}
            value={values.ccmask}
            onChange={handleChange}
            name={`ccFormat__${props.name}`}
            id={`id_ccFormat__${props.name}`}
            InputProps={{
                inputComponent: TInputExpiredDateForCreditCard,
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
};

export default TTextFieldExpiredDateCreditCard;
