import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel
} from "@mui/material";
import Button from "@mui/material/Button";
import _ from "lodash";
import React, { useEffect } from "react";
import DocumentUploadInput from "../../../DocumentUploadInput";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
/***
 * props-variable: isOpenModal:boolean, editData: JSON object
 * props-function: onCloseModal, onSaveData
 */
export default function AddFile(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [rows, setRow] = React.useState([]);
    const [loader, setLoader] = React.useState(true);

    const [note, setNote] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [fileExt, setFileExt] = React.useState("");
    const [fileName, setFileName] = React.useState("");

    // Errors selection
    const [notePerError, setNotePerError] = React.useState("");
    const [id, setId] = React.useState(null);
    useEffect(() => {
        setFileName(note + fileExt)
    }, [note, fileExt])
    useEffect(() => {
        // if (id) {
        //   setLoaderDialog(true);
        // } else {
        resetAllData();
        // }
    }, [props.isOpenModal, props.editData]);

    const isCreate = () => {
        if (!_.isEmpty(props.editData) && props.ctlType == "edit") return false;
        return true;
    };

    function resetAllData() {
        setFile(null);
        setNote("");
        setNotePerError(null);
        if (!isCreate()) {
            const edit = props.editData;
            const name = edit.name ? edit.name.substring(0, edit.name.lastIndexOf('.')) : "";
            const fileExtention = edit.name ? edit.name.substring(edit.name.lastIndexOf('.')) : "";
            setNote(name);
            setFileExt(fileExtention);
        }
    }

    const saveProgress = () => {
        const isValid = formValidationPersonalDetails();
        if (isValid) {
            const saveProgressPayload = {
                note,
            };
            props.onSaveData(fileName, file);
        }
    };

    const formValidationPersonalDetails = () => {
        let isValid = true;
        if (note === "") {
            isValid = false;
            setNotePerError("Please enter a valid note");
        } else {
            setNotePerError("");
        }
        return isValid;
    };

    const handleClose = () => {
        props.onCloseModal();
        resetAllData();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpenModal}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                Please input file name.
      </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    className={baseClasses.grid}
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="my-input">Name</InputLabel>
                                <Input xs={10}
                                    type="text"
                                    value={note}
                                    id="my-input"
                                    onChange={(event) => {
                                        if (!_.isEmpty(event.target.value)) {
                                            setNotePerError("");
                                        }
                                        setNote(event.target.value);
                                    }}
                                    aria-describedby="my-helper-text"
                                />
                                {notePerError == "" ? null : (
                                    <FormHelperText className={baseClasses.error} id="my-helper-text">
                                        {notePerError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {!isCreate() ? (
                            null
                        ) : (
                            <DocumentUploadInput
                                label={"file"}
                                setFileExt={setFileExt}
                                onFileChange={(label, file) => {
                                    setFile(file);
                                }}
                            ></DocumentUploadInput>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={saveProgress} color="primary">
                    Save
        </Button>
                <Button onClick={handleClose} color="primary">
                    Close
        </Button>
            </DialogActions>
        </Dialog>
    );
}
