import React, { useEffect } from "react";
import {
    Dialog, FormControl,
    FormHelperText, Input, InputLabel
} from "@mui/material";
import { Button } from "reactstrap";
import { contactCMSList } from "../../../../api/clients";
import ContactSelectNew from "../../../ContactSelect/ContactSelectNew";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
/**
 * props: openModal, handleClose(), Output:addYeshiva(selectedContact)
 */


const ContactRelSelect = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [openModal, setOpenModal] = React.useState(false);
    const [conatctName, setConatctName] = React.useState("");
    const [searchText, setSearchText] = React.useState("");
    const [contactList, setContactList] = React.useState([]);
    const [filterContactList, setFilterContactList] = React.useState([]);
    const [inputMode, setInputMode] = React.useState("selectExisting");
    const [relationship, setRelationship] = React.useState(null);
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [contactPerError, setContactPerError] = React.useState("");
    //   const [inputMode, setInputMode] = React.useState("createNewContact");

    // global.log("addyeshiva dialog" + props.openModal);
    useEffect(() => {
        setSelectedContact(null);
        setContactPerError("");
        setOpenModal(props.openModal);

        contactCMSList().then((response) => {
            setContactList(response);
            setFilterContactList(response);
        });
    }, []);

    const handInputMode = (e) => {
        setInputMode(e.target.value);
    };

    const handleClose = () => {
        setOpenModal(false);
        props.handleClose();
        // setSearchText("");
        // setFilterContactList(contactList);
    };

    const handleSelectRow = (contact) => {
        if (!contact) return;
        setSelectedContact(contact);
        setConatctName(`${contact.firstName} ${contact.lastName}`);
        // handleClose();
        // props.onSelectValue(contact.id);
    };

    const addNewRelationship = () => { };

    return (
        <>
            {/* <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label" htmlFor="name-multiple">
          {props.name ? props.name : "Contact"}
        </InputLabel>
        <Input
          value={conatctName}
          id="demo-simple-select-label"
          onClick={(event) => {
            setOpenModal(true);
          }}
          aria-describedby="my-helper-text"
        />
      </FormControl> */}
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openModal}
                // open={true}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            // style={{padding:20}}
            >
                <div style={{ padding: 20 }}>
                    <div className="mb-4">
                        <div>
                            <h5>Select contact</h5>
                        </div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label" htmlFor="name-multiple">
                                {props.name ? props.name : "Please select a contact below"}
                            </InputLabel>
                            <Input
                                value={conatctName}
                                id="demo-simple-select-label"
                                readOnly
                                onClick={(event) => {
                                    //   setOpenModal(true);
                                }}
                                aria-describedby="my-helper-text"
                            />
                            {contactPerError === "" ? null : (
                                <FormHelperText className={baseClasses.error} id="my-helper-text">
                                    {contactPerError}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <div className="float-right mt-3">
                            <Button
                                color="primary"
                                // onClick={props.handCloseRel}
                                onClick={() => {
                                    props.handleClose();
                                }}
                                className="mr-2"
                            >
                                Close
              </Button>
                            <Button
                                className={classes.save}
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    var isValid = true;

                                    if (conatctName == "") {
                                        isValid = false;
                                        setContactPerError("Please select contact");
                                    } else {
                                        setContactPerError("");
                                    }
                                    if (!isValid) return;
                                    props.handleClose();
                                    props.addYeshiva(selectedContact);
                                }}
                            // onClick={addNewRelationship}
                            >
                                Select
              </Button>
                        </div>
                    </div>

                    <ContactSelectNew
                        handleClose={handleClose}
                        handleSelectRow={handleSelectRow}
                    ></ContactSelectNew>
                </div>
            </Dialog>
        </>
    );
};

export default ContactRelSelect;
