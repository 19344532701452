import React from "react";
import TInputNumber from "./TInputNumber";
import { TextField, InputAdornment } from "@mui/material";


const TTextFieldNumber = (props) => {
    const { onChange, adornment, ...other } = props;
  const [values, setValues] = React.useState({
    inputValue: props.value,
  });

  const handleChange = (event) => {
    setValues((prevState) => {
      return {
        ...prevState,
        inputValue: event.target.value
      }
    });
    
    onChange(event);
  };

  return (
    <TextField
      {...other}
      label={props.label}
      value={values.inputValue}
      onChange={handleChange}
      name={props.name}
      id={`id_control__${props.name}`}
      InputProps={{
          inputComponent: TInputNumber,
          startAdornment: (
              <InputAdornment position="start">{adornment }</InputAdornment>
          ),
          }}
    />
  );
};

export default TTextFieldNumber;
