import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    TextField,
    Select,
    MenuItem
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Button from "@mui/material/Button";
import * as constant from "../../constants";
import useStyles from "./styles";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import Chip from '@mui/material/Chip';
import { toast } from 'react-toastify'
import { useStoreActions } from "easy-peasy";
import baseStyles from '../../assets/styles'
const GridFormField = (props) => {

    return (
        <Grid item xs={props.columnSize}>
            <div>
                <FormControl fullWidth>
                    <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
                    <Input
                        value={props.value}
                        id={props.id}
                        onChange={(event) => typeof props.onChange === "function" ? props.onChange(event) : undefined}
                    />
                    {props.errorValue === "" ? null : (
                        <FormHelperText
                            className={props.errorClass ? props.errorClass : ""}
                            id="my-helper-text"
                        >
                            {props.errorValue}
                        </FormHelperText>
                    )}
                </FormControl>
            </div>
        </Grid>
    )
}

export default function NewEmployee(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [firstname, setFirstName] = React.useState("");
    const [lastname, setLastName] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [position, setPosition] = React.useState("");
    const [city, setCity] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [moreContactInfo, setMoreContactInfo] = React.useState(false);
    const [hebrewFirstName, setHebrewFirstName] = React.useState("");
    const [hebrewLastName, setHebrewLastName] = React.useState("");
    const [apt, setApt] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
    const [phone2, setPhone2] = React.useState("");
    const [email2, setEmail2] = React.useState("");
    const [ssn, setSsn] = React.useState("");
    const [dob, setDob] = React.useState("");
    const [dobIsFocused, setDobIsFocused] = useState(false);
    const [rate, setRate] = useState(0);
    const [specialty, setSpecialty] = useState([]);
    const [professionalType, setProfessionalType] = useState("");
    const [positionValues, setPositionValues] = useState([{ id: 0, name: "" }]);
    const [specialtyValues, setSpecialtyValues] = useState([])
    const [firstnamePerError, setFirstNamePerError] = React.useState("");
    const [lastnamePerError, setLastNamePerError] = React.useState("");
    const [phoneNumberPerError, setPhoneNumberPerError] = React.useState("");
    const [addressPerrError, setAddressPerError] = React.useState("");
    const [cityPerError, setCityPerError] = React.useState("");
    const [emailPerError, setEmailPerError] = React.useState("");
    const [loaderDialog, setLoaderDialog] = React.useState(false);
    const [hebrewFirstNamePerError, setHebrewFirstNamePerError] = useState("");
    const [hebrewLastNamePerError, setHebrewLastNamePerError] = useState("");
    const [aptPerError, setAptPerError] = useState("");
    const [statePerError, setStatePerError] = useState("");
    const [zipPerError, setZipPerError] = useState("");
    const [phone2PerError, setPhone2PerError] = useState("");
    const [email2PerError, setEmail2PerError] = useState("");
    const [ssnPerError, setSsnPerError] = useState("");
    const [dobPerError, setDobPerError] = useState("");
    const [ratePerError, setRatePerError] = useState("");
    const [specialtyPerError, setSpecialtyPerError] = useState("");
    const [yungermanId, setYungermanId] = useState()
    const [professionalId, setProfessionalId] = useState();
    const [positionPerError, setPositionPerError] = useState();
    const fetchEmployees = useStoreActions(actions => actions.employee.fetchEmployees);
    const theme = useTheme();

    useEffect(() => {
        resetAllData();
        global.fetchWrapper.get(constant.BASE_URL + 'api/hr/employeePositionValues').then(data => setPositionValues(data))
        global.fetchWrapper.get(constant.BASE_URL + 'api/hr/employeeSpecialtyValues').then(data => setSpecialtyValues(data))
    }, []);


    useEffect(() => {
        positionValues.forEach(position => {
            if (position.name === 'Yungerman') {
                setYungermanId(position.id)
            }
            if (position.name === 'Professional') {
                setProfessionalId(position.id)
            }
        })
    }, [positionValues])

    function resetAllData() {
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setAddress("");
        setCity("");
        setFirstNamePerError("");
        setLastNamePerError("");
        setPhoneNumberPerError("");
        setAddressPerError("");
        setCityPerError("");
    }

    function saveEmployee() {
        if (!formIsValid()) {
            return;
        }

        const newEmployeeObject = {
            firstName: firstname,
            lastName: lastname,
            phoneNumber: phoneNumber,
            email: email,
            hebrewFirstName: hebrewFirstName,
            hebrewLastName: hebrewLastName,
            address: address,
            city: city,
            state: state,
            ZIP: zip,
            phone2: phone2,
            email2: email2,
            ssn: ssn,
            dob: dob,
            position: position,
            rate: rate,
            specialties: specialty.map(element => element.id),
            professionalType: professionalType
        }

        global.fetchWrapper.post(constant.BASE_URL + 'api/hr/addEmployee',
            newEmployeeObject
        ).then(() => typeof props.onEmployeeAdded === 'function' && props.onEmployeeAdded(),
            () => global.AjaxFailToast())

        fetchEmployees();
        handleClose()
    };

    function formIsValid() {
        let isValid = true;
        if (firstname === "") {
            isValid = false;
            setFirstNamePerError("*Required");
        }
        if (lastname === "") {
            isValid = false;
            setLastNamePerError("*Required");
        }
        if (position === "") {
            isValid = false;
            setPositionPerError("*Required")
        }
        return isValid;
    }

    function handleClose() {
        resetAllData();
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <Dialog
            disableBackdropClick
            fullWidth={true}
            maxWidth={"sm"}
            open={props.isOpen}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle className={baseClasses.dialogTitle} id="max-width-dialog-title">
                {!_.isEmpty(props.editEmployeeData) ? "Edit" : "Add"} Employee
      </DialogTitle>
            <DialogContent>
                {loaderDialog ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        <Grid
                            container
                            className={baseClasses.grid}
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <GridFormField
                                id="first-name"
                                columnSize={6}
                                label={"First Name"}
                                value={firstname}
                                onChange={(event) => {
                                    if (!_.isEmpty(event.target.value)) {
                                        setFirstNamePerError("");
                                    }
                                    setFirstName(event.target.value);
                                }}
                                errorValue={firstnamePerError}
                                errorClass={baseClasses.error}
                            />
                            <GridFormField
                                id="last-name"
                                columnSize={6}
                                label={"Last Name"}
                                value={lastname}
                                onChange={(event) => {
                                    if (!_.isEmpty(event.target.value)) {
                                        setLastNamePerError("");
                                    }
                                    setLastName(event.target.value);
                                }}
                                errorValue={lastnamePerError}
                                errorClass={baseClasses.error}
                            />
                            <GridFormField
                                id="phone-number"
                                columnSize={6}
                                label={"Phone Number"}
                                value={phoneNumber}
                                onChange={(event) => {
                                    if (!_.isEmpty(event.target.value)) {
                                        setPhoneNumberPerError("");
                                    }
                                    setPhoneNumber(event.target.value);
                                }}

                            />
                            <GridFormField
                                id="email"
                                columnSize={6}
                                label={"Email"}
                                value={email}
                                onChange={(event) => {
                                    if (!_.isEmpty(event.target.value)) {
                                        setEmailPerError("");
                                    }
                                    setEmail(event.target.value);
                                }}
                                errorValue={emailPerError}
                                errorClass={baseClasses.error}
                            />
                            <Button display="block" color="primary" onClick={() => setMoreContactInfo(!moreContactInfo)}>{moreContactInfo ? <>Hide</> : <>More Contact Info</>}</Button>

                            {moreContactInfo ? (
                                <>
                                    <Grid item xs={6}>
                                        <div>

                                        </div>
                                    </Grid>
                                    <GridFormField
                                        id="hebrew-first-name"
                                        columnSize={6}
                                        label={"Hebrew First Name"}
                                        value={hebrewFirstName}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setHebrewFirstNamePerError("");
                                            }
                                            setHebrewFirstName(event.target.value);
                                        }}
                                        errorValue={hebrewFirstNamePerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="hebrew-last-name"
                                        columnSize={6}
                                        label={"Hebrew Last Name"}
                                        value={hebrewLastName}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setHebrewLastNamePerError("");
                                            }
                                            setHebrewLastName(event.target.value);
                                        }}
                                        errorValue={hebrewLastNamePerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="address"
                                        columnSize={6}
                                        label={"Address"}
                                        value={address}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setAddressPerError("");
                                            }
                                            setAddress(event.target.value);
                                        }}
                                        errorValue={addressPerrError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="apt"
                                        columnSize={6}
                                        label={"Apt"}
                                        value={apt}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setAptPerError("");
                                            }
                                            setApt(event.target.value);
                                        }}
                                        errorValue={aptPerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="apt"
                                        columnSize={4}
                                        label={"City"}
                                        value={city}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setCityPerError("");
                                            }
                                            setCity(event.target.value);
                                        }}
                                        errorValue={cityPerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="state"
                                        columnSize={4}
                                        label={"State"}
                                        value={state}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setStatePerError("");
                                            }
                                            setState(event.target.value);
                                        }}
                                        errorValue={statePerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="zip"
                                        columnSize={4}
                                        label={"ZIP"}
                                        value={zip}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setZipPerError("");
                                            }
                                            setZip(event.target.value);
                                        }}
                                        errorValue={zipPerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="phone2"
                                        columnSize={6}
                                        label={"Phone 2"}
                                        value={phone2}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setPhone2PerError("");
                                            }
                                            setPhone2(event.target.value);
                                        }}
                                        errorValue={phone2PerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="email2"
                                        columnSize={6}
                                        label={"Email 2"}
                                        value={email2}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setEmail2PerError("");
                                            }
                                            setEmail2(event.target.value);
                                        }}
                                        errorValue={email2PerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <GridFormField
                                        id="ssn"
                                        columnSize={6}
                                        label={"SSN"}
                                        value={ssn}
                                        onChange={(event) => {
                                            if (!_.isEmpty(event.target.value)) {
                                                setSsnPerError("");
                                            }
                                            setSsn(event.target.value);
                                        }}
                                        errorValue={ssnPerError}
                                        errorClass={baseClasses.error}
                                    />
                                    <Grid item xs={6}>
                                        <div>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="dob"
                                                    label="Date of Birth"
                                                    type={dobIsFocused || dob !== "" ? "date" : "text"}
                                                    value={dob}
                                                    onFocus={() => setDobIsFocused(true)}
                                                    onBlur={() => setDobIsFocused(false)}
                                                    onChange={(event) => {
                                                        if (!_.isEmpty(event.target.value)) {
                                                            setDobPerError("");
                                                        }
                                                        setDob(event.target.value);
                                                    }}
                                                />
                                                {dobPerError == "" ? null : (
                                                    <FormHelperText
                                                        className={baseClasses.error}
                                                        id="my-helper-text"
                                                    >
                                                        {dobPerError}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </>) : null}
                            <Grid item xs={12}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Position</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={position}
                                            style={{ width: "100%" }}
                                            onChange={(e) => { setPosition(e.target.value); setPositionPerError("") }}
                                        >
                                            {positionValues.map(value =>
                                                <MenuItem key={value.id} value={value.id} >{value.name}</MenuItem>)}
                                        </Select>
                                        {positionPerError == "" ? null : (
                                            <FormHelperText
                                                className={baseClasses.error}
                                                id="my-helper-text"
                                            >
                                                {positionPerError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </Grid>
                            {position === yungermanId ? (
                                <>
                                    <Grid item xs={6}>
                                        <div>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="my-input">Rate</InputLabel>
                                                <Input
                                                    type="number"
                                                    value={rate}
                                                    id="my-input"
                                                    onChange={(event) => {
                                                        if (!_.isEmpty(event.target.value)) {
                                                            setRatePerError("");
                                                        }
                                                        setRate(event.target.value);
                                                    }}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                                {ratePerError == "" ? null : (
                                                    <FormHelperText
                                                        className={baseClasses.error}
                                                        id="my-helper-text"
                                                    >
                                                        {ratePerError}
                                                    </FormHelperText>
                                                )}

                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <div>
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-mutiple-chip-label">Specialties</InputLabel>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    labelId="demo-mutiple-chip-label"
                                                    multiple
                                                    value={specialty}
                                                    onChange={(e) => {
                                                        let eventValue = e.target.value
                                                        setSpecialty(eventValue);
                                                        // let idArray = []
                                                        // let eventValuesToBeAdded = [];
                                                        // let valueToBeRemoved = 0;
                                                        // eventValue.forEach(ev => {
                                                        //     if (!idArray.includes(ev.id)) {
                                                        //         idArray.push(ev.id)
                                                        //     } else {
                                                        //         valueToBeRemoved = ev.id
                                                        //     }
                                                        // })
                                                        // eventValue.forEach(ev => { if (ev.id != valueToBeRemoved) eventValuesToBeAdded.push(ev); })
                                                        // global.log("event value:", eventValuesToBeAdded)
                                                        // setSpecialty(eventValuesToBeAdded)
                                                    }}
                                                >
                                                    {specialtyValues.map((item) => (
                                                        <MenuItem key={item.name} value={item.name} style={getStyles(item, specialty, theme)}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {/*                                         
                                            <Select
                                                labelId="demo-mutiple-chip-label"
                                                multiple
                                                value={specialty}
                                                onChange={(e) => {
                                                    let eventValue = e.target.value
                                                    let idArray = []
                                                    let eventValuesToBeAdded = [];
                                                    let valueToBeRemoved = 0;
                                                    eventValue.forEach(ev => {
                                                        if (!idArray.includes(ev.id)) {
                                                            idArray.push(ev.id)
                                                        } else {
                                                            valueToBeRemoved = ev.id
                                                        }
                                                    })
                                                    eventValue.forEach(ev => { if (ev.id != valueToBeRemoved) eventValuesToBeAdded.push(ev); })
                                                    global.log("event value:", eventValuesToBeAdded)
                                                    setSpecialty(eventValuesToBeAdded)
                                                }}
                                            >
                                                {specialtyValues.map((item) => (
                                                    <MenuItem key={item.id} value={{ id: item.id, name: item.name }} style={getStyles(item, specialty, theme)}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select> */}

                                            </FormControl>
                                        </div>
                                    </Grid>
                                </>
                            ) : null}
                            {position === professionalId ? (<Grid item xs={6}>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={professionalType}
                                            onChange={(e) => { setProfessionalType(e.target.value) }}
                                        >
                                            <MenuItem value={"emotionalTherapy"}>Emotional Therapy</MenuItem>
                                            <MenuItem value={"keriah"}>Keriah</MenuItem>
                                            <MenuItem value={"physicalTherapy"}>Physical Therapy</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>) : null}
                        </Grid>
                    </div>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={saveEmployee} color="primary">
                    Save
        </Button>
                <Button onClick={handleClose} color="primary">
                    Close
        </Button>
            </DialogActions>
        </Dialog>
    );
}
