import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import clsx from "clsx";
import { data, usersColumns, usersRow } from "../../components/data/clients";
import Button from "@mui/material/Button";
import { FormControl, TextField } from "@mui/material";
import DataTable from "../../components/DataTable/DataTable";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Drawer from "@mui/material/Drawer";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useStoreActions, useStoreState } from "easy-peasy";
import * as constant from "../../constants";
import { fetchWrapper } from "../../_helpers/fetch-wrapper";
import ChangePassword from "./ChangePassword";
import DeleteUser from "./DeleteUser";
import UserForm from "./UserFormModal";
import useUpdateEffect from "../../utils/useUpdateEffect";
import usePermission from "../../hooks/usePermission";
import baseStyles from '../../assets/styles';
import SearchInput from "../../components/common/SearchInput/SearchInput";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        margin: "12px 26px",
        "& .MuiCardContent-root": {
            padding: "0px",
        },
        /*'.MuiCardContent-root': {
                padding: '0px'
            },*/
        "& .MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        /*'& .MuiPaper-outlined': {
                border: '0px',
            }*/
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none !important",
        },
        "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
            visibility: "hidden",
        },
        "& .MuiDataGrid-root": {
            border: "0px",
        },
        "& .table": {
            height: "80vh !important",
        },
    },
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },

}));

export default function Users(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [rows, setRows] = React.useState([]);
    const columns = usersColumns;
    const [query, setQuery] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [addNewFormIsVisible, setAddNewFormIsVisible] = React.useState(false);
    const [editFormIsVisible, setEditFormIsVisible] = useState(false);
    const [userEditInfo, setUserEditInfo] = useState({});
    const [editUserData, setEditUserData] = useState({});
    const [deleteUserIsVisible, setDeleteUserIsVisible] = useState(false);
    const [editData, setEditData] = React.useState("");
    const [changePasswordIsVisible, setChangePasswordIsVisible] = useState(false);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const users = useStoreState(state => state.users.users);
    const fetchUsers = useStoreActions(actions => actions.users.fetchUsers);
    const { canView, canRead, canCreate, canEdit, canDelete } = usePermission(global.permissions.users);

    const _setRowAndColumn = () => {
        return {
            rows: setDataOfFilter(users),
            columns: columns,
            screen: "users",
        };
    };

    const setDataOfFilter = (rows) => {
        return rows.filter(
            (row) =>
                row?.email?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                row?.editData?.firstName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.editData?.lastName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.editData?.roles?.some(item => item.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1)
        );
    };

    const handleNewUser = () => {
        setAddNewFormIsVisible(true);
    };
    
    useEffect(() => {
        if (users.length < 1) {
            setLoading();
            fetchUsers()
                .then(() => {
                    endLoading();
                })
        }
    });

    const handleSearch = (searchTerm) => {
        setQuery(searchTerm)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={baseClasses.toolbar} />
                <div className={baseClasses.row}>
                    <SearchInput getSearchQuery={handleSearch} />
                    <div className={baseClasses.grow}></div>
                    <div>
                        <Button
                            variant="text"
                            onClick={handleNewUser}
                            color="primary"
                        >

                            <b style={{ fontSize: "14px", paddingRight: "4px" }}>
                                {"New  "}</b> <AddRoundedIcon fontSize="small" />
                        </Button>
                    </div>
                    <Drawer
                        variant="permanent"
                        className={clsx(baseClasses .drawer, {
                            [baseClasses.drawerOpen]: open,
                            [baseClasses.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [baseClasses.drawerOpen]: open,
                                [baseClasses.appBarShift]: open,
                                [baseClasses.drawerClose]: !open,
                            }),
                        }}
                        anchor="right"
                    >
                        <div className={baseClasses.panelContent}>
                        </div>
                    </Drawer>
                </div>
                <Card>
                    <CardContent>
                        <DataTable
                            title="Users"
                            data={_setRowAndColumn()}
                            onOpen={() => setEditFormIsVisible(true)}
                            onEdit={(params) => setEditUserData(params.row)}
                            onChangePassword={(params) => {
                                let data = params.row;
                                setChangePasswordIsVisible(true)
                                setEditUserData(data);
                            }
                            }
                            onDelete={(params) => {
                                let data = params.row;
                                setDeleteUserIsVisible(true);
                                setEditUserData(data);
                            }}
                            permissions={global.permissions.users}
                            canEdit={(params) => canEdit}
                            canDelete={(params) => canDelete}
                            hideHeaderColumnsSortIcon
                            multiView
                        />
                    </CardContent>
                </Card>
            </main>

            {addNewFormIsVisible ? <UserForm  isOpen={true} onClose={() => setAddNewFormIsVisible(false)}></UserForm> : null}
            {editFormIsVisible ? <UserForm editData={editUserData.editData} isOpen={true} onClose={() => setEditFormIsVisible(false)} /> : null}
            {changePasswordIsVisible ? <ChangePassword userId={editUserData.editData.userId} onClose={() => setChangePasswordIsVisible(false)} /> : null}
            {deleteUserIsVisible ? <DeleteUser userId={editUserData.editData.userId} onClose={() => setDeleteUserIsVisible(false)} /> : null}
        </>
    );
}
