import { makeStyles } from '@mui/styles';
import { alpha } from "@mui/material/styles";

export default makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: "12px 0px 12px 0px",
        margin: "12px 26px",
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiTabs-flexContainer": {
            backgroundColor: theme.palette.primary.main,
        },
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
        "& .MuiTab-root .MuiDataGrid-row": {
            cursor: "pointer",
            fontSize: "12px",
        },
        "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
        "& .MuiTab-root": {
            color: "#FFFFFF"
        },
    },
    overview_main: {
        padding: "8px",
    },

    panelContent: {
        height: '80vh',
        overflow: 'auto'
    },

    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    
    title: {
        marginTop: theme.spacing(2),
    },
    header: {
        fontWeight: "bold",
        display: "flex",
        width: "100%",
        marginBottom: "12px",
    },
    programTab: {
        paddingTop: "1rem",
        marginBottom: "1rem",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
}));
