import React from 'react'
import {MuiTextField} from '../../../../../assets/MUI/mui.components'
import {Flex, DivContainer, BoldTitle, Text} from '../../../../../assets/styles/styledComponents';

type TextFieldEditorProps = {
    label: string,
    hideArrows?: boolean,
    margin?: string,
    mode?: 'read' | 'edit',
    disabled?: boolean,
    hide?: boolean,
    variant?: 'outlined' | 'filled' | 'standard',
    name: string,
    value: string | number,
    type?: 'email' | 'password' | 'text' | 'number',
    error?: boolean,
    helperText?: string,
    fullWidth?: boolean,
    onChange: Function
}

const TextFieldEditor:React.FC<TextFieldEditorProps> = ({label, hideArrows=false, margin="10px", variant="outlined", name, value, type='text', disabled=false, hide=false, error, helperText, fullWidth, onChange, mode="edit"}) => {
    if (hide) {
        return null;
    } else {
        return (
        <DivContainer margin={margin}>
            {mode === 'edit' &&
                <MuiTextField
                    label={label}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value, name)}
                    name={name}
                    value={value}
                    type={type}
                    disabled={disabled}
                    variant={variant}
                    error={error}
                    sx={
                        hideArrows ? style.hideArrows : {}
                    }
                    helperText={helperText}
                    fullWidth={fullWidth} 
                />
            }
            {
                mode === 'read' && 
                    <Flex width="100%">
                        <DivContainer width="40%" >
                            <BoldTitle>{label}:</BoldTitle>
                        </DivContainer>
                        <DivContainer width="60%">
                            <Text>{!!value ? value: '-'}</Text>
                        </DivContainer>
                    </Flex>
            }
        </DivContainer>
        )
    }
}

const style = {
    hideArrows: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
          },
        }  
    }
}

export default TextFieldEditor