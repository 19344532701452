import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from 'react-router-dom';
import { getContactRelDetail } from "../../../api/cms";
import { getClientDetails, getClientDetailProgram } from "../../../api/clients";
import ContactRelSelect from "../../../components/ContactSelect/ContactRelSelect";
import { contactColumns } from "../../../components/data/clients";
import DataTable from "../../../components/DataTable/DataTable";
import AddContact from "../../../components/explorer/Tabs/Dialog/AddContact";
import PostCreate from "./posts";
import Programs from "./programs";
import useStyles from "./styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";
import usePermission from "../../../hooks/usePermission";
import baseStyles from '../../../assets/styles'
import AnimLoadingState from "../../../components/common/AnimLoadingState";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const setRowAndTab = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

export default function EditClientPage(props) {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const navigate = useNavigate();
    const {clientId} = useParams();
    const [value, setValue] = React.useState("client");
    //   const [value, setValue] = React.useState("program");
    const [view, setView] = React.useState(true);
    const [fatherView, setFatherView] = useState(true);
    const [motherView, setMotherView] = useState(true);
    const [education, setEducation] = React.useState(true);
    const [enrollment, setEnrollment] = React.useState(true);
    const [information, setInformation] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [backspaceConfirm, setBackspaceConfirm] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [tabvalue, setTabValue] = React.useState("client");
    const [type, setType] = React.useState(0);
    const [showAddNew, setShowAddNew] = React.useState(false);
    const [isNewClient, setIsNewClient] = React.useState(false);
    const fetchClients = useStoreActions(actions => actions.clients.fetchClients); // we need to fetch client if url is hit directly
    const [relatedContactRows, setRelatedContactRows] = React.useState({
        rows: [{}],
        columns: contactColumns,
        screen: "client_contact",
    });
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const clients = useStoreState((state) => state.clients.clients);
    const clientSetDetail = useStoreActions((actions) => actions.clients.setDetail);
    const setFields = useStoreActions((actions) => actions.clients.setFields);
    const exploringId = useStoreState((state) => state.exploringId);
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const setExploringName = useStoreActions((actions) => actions.setExploringName);
    const isWindowDirty = useStoreState((state) => state.isWindowDirty);
    const setWindowDirty = useStoreActions((actions) => actions.setWindowDirty);
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    const personalInfoPermission = usePermission(global.permissions.clients.personal);
    const lhisalosInfoPermission = usePermission(global.permissions.clients.lhisalos);
    const enrollmentPermission = usePermission(global.permissions.clients.enrollment);
    const programPermission = usePermission(global.permissions.clients.programs);

    useEffect(() => {
        if (clientId <= 0) {
            setIsNewClient(true);
            setExploringName("");
            setView(false);
            setFatherView(false);
            setMotherView(false);
            setEducation(false);
            setEnrollment(false);
            setInformation(false);
            setType(0);
        } else {
            setIsNewClient(false)
            setView(true);
            setFatherView(true);
            setMotherView(true);
            setEducation(true);
            setEnrollment(true);
            setInformation(true);
            setType(1);
            //  We need to check if explorer id is set or not.
            if (exploringId != clientId) {
                setLoading();
                getClientDetails(clientId)
                    .then((response) => {
                        global.log(response);
                        clientSetDetail(response);
                        if (!!response && response !== 404) { /* checking the url id is present in database and valid id */
                            setExploringName(response.name);
                            setExploringId(response.id);
                            setExploringMode("client")
                            loadContactRelDetail(response.id)
                        } else {
                            //TODO: handle if url id is not valid id.
                            global.AjaxFailToast(`Wrong id: ${clientId}. Re-directing to this clients `);
                            history('clients');
                        }
                        endLoading()
                    })
            }
            else {
                loadContactRelDetail(exploringId)
            }
        }
    }, []);

    /**
     * loading dependcies data of that client like, contact, clientDetail and program detail
     * @param {number} id - Client id
     */
    const loadContactRelDetail = (id) => {
        setLoading();
        getContactRelDetail("clients", id)
            .then((response) => {
                global.log(response);
                setRelatedContactRows({
                    rows: response,
                    columns: contactColumns,
                    screen: "client_contact",
                });
            })
            .then(() => {
                getClientDetails(id)
                    .then((response) => {
                        global.log(response);
                        clientSetDetail(response);
                    })
            })
            .then(() => {
                getClientDetailProgram(id).then((value) => {
                    global.log(value);
                    setFields(value);
                })
            })
            .then(() => endLoading());

    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        global.log("handleClose");
        setOpen(false);
    };
    const disagree = () => {
        setOpen(false);
    };

    const agree = () => {
        setOpen(false);
        setView(true);
        setFatherView(true);
        setMotherView(true);
        setEducation(true);
        setEnrollment(true);
        setInformation(true);
        setValue(tabvalue);
    };
    const handleChange = (event, newValue) => {
        if (newValue == value) {
            event.preventDefault();
            return;
        }
        if (hasChanges()) {
            if (!window.confirm("You have unsaved changes, do you want to discard them?")) {
                event.preventDefault();
                return;
            }
        }
        if (value == "client"){
            setView(true);
            setFatherView(true);
            setMotherView(true);
            setEducation(true);
            setEnrollment(true);
            setInformation(true);
        }
        setClean(false);
        setTabValue(newValue);
        setValue(newValue);
        //if (type == 1 && (!view || !fatherView || !motherView || !education || !information)) {
        //    handleClickOpen();
        //    setTabValue(newValue);
        //} else {
        //    setValue(newValue);
        //}
    };

    const exploringMode = useStoreState((state) => state.exploringMode);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const history = useStoreState((state) => state.history);

    const handleCloseDrawer = (e) => {
        if (value !== "client" ||
                (type === 1 && (!view || !fatherView || !motherView || !education || !information)) || (isNewClient && global.personalDetailsHasInfo())) 
        {
            /*setBackspaceConfirm(true);*/
            if (hasChanges()) {
                if (!wConfirm(e, undefined, "You have unsaved changes, do you want to discard them?")) {
                    return;
                }
            }
        }
        goBack();
    }

    const goBack = () => {
        navigate(-1) // one step backward
        //setValue("client");
        setExploringMode("detail");
        // if (global.navigatedToClientsFromClientEdit) {
        //     global.navigatedToClientsFromClientEdit = false;
        //     if (exploringId > 0)
        //         history('/client/' + exploringId + '/details');
        // }
        //props.onClick();
    };

    const handleAddRelationshipClick = () => {
        setShowAddNew(true);
    };
    const handleModalClose = () => {
        setShowAddNew(false);
    };

    const handleAddedRelationshipSuccess = () => {
        alert("relatoinship added!");
        setShowAddNew(false);
    };

    const exploringName = useStoreState((state) => state.exploringName);

    return (
        <div className={classes.content}>
            <div className={baseClasses.toolbar} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleCloseDrawer}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                {exploringName}
            </div>
            <Card variant="outlined" style={{position: 'relative'}}>
                <CardContent classes={{ root: baseClasses.card_view }}>
                    <div className={classes.root}>
                        <AppBar
                            className={baseClasses.appbarColor}
                            position="static"
                            color="primary"
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab
                                    className={classes.tabClass}
                                    value="client"
                                    label="Client"
                                    {...setRowAndTab(0)}
                                />
                                {/*<Tab*/}
                                {/*    className={classes.tabClass}*/}
                                {/*    value="contact"*/}
                                {/*    label="Contact"*/}
                                {/*    {...setRowAndTab(1)}*/}
                                {/*    disabled={isNewClient}*/}
                                {/*/>*/}
                                {programPermission.canView &&
                                    <Tab
                                        className={classes.tabClass}
                                        value="program"
                                        label="Programs"
                                        {...setRowAndTab(2)}
                                        disabled={isNewClient}
                                    />
                                }

                                {/*<Tab*/}
                                {/*    className={classes.tabClass}*/}
                                {/*    value="schedules"*/}
                                {/*    label="Schedules"*/}
                                {/*    {...setRowAndTab(3)}*/}
                                {/*    disabled={isNewClient}*/}
                                {/*/>*/}
                                {/*<Tab*/}
                                {/*    className={classes.tabClass}*/}
                                {/*    value="billing"*/}
                                {/*    label="Billing"*/}
                                {/*    {...setRowAndTab(4)}*/}
                                {/*    disabled={isNewClient}*/}
                                {/*/>*/}
                            </Tabs>
                        </AppBar>
                        <div className={classes.panelContent}>
                            <TabPanel
                                value={value}
                                index="client"
                                className={classes.tabRoot}
                                style={{ backgroundColor: theme.palette.background.paper }}
                            >
                                <PostCreate
                                    view={view}
                                    onView={setView}
                                    fatherView={fatherView}
                                    setFatherView={setFatherView}
                                    motherView={motherView}
                                    setMotherView={setMotherView}
                                    education={education}
                                    enrollment={enrollment}
                                    onEducation={setEducation}
                                    onEnrollment={setEnrollment}
                                    information={information}
                                    onInformation={setInformation}
                                    type={type}
                                    setType={setType}
                                    addClientSuccess={props.addClientSuccess}
                                    onclick={handleCloseDrawer}
                                    goBack={goBack}
                                    personalInfoPermission={personalInfoPermission}
                                    lhisalosInfoPermission={lhisalosInfoPermission}
                                    enrollmentPermission={enrollmentPermission}
                                    setIsNewClient={setIsNewClient}
                                />
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="contact"
                                className={classes.tabRoot}
                            >
                                <div className={baseClasses.programTab}>
                                    <div className={baseClasses.button_program}>
                                        <Button
                                            className={baseClasses.capitalized}
                                            color="primary"
                                            variant="contained"
                                            onClick={handleAddRelationshipClick}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                                {/* TODO: This will never run */}
                                {false && (
                                    <ContactRelSelect
                                        openModal={true}
                                        closeModal={() => {
                                            setShowAddNew(false);
                                        }}
                                    ></ContactRelSelect>
                                )}

                                {showAddNew && (
                                    <AddContact
                                        isOpenCreateModal={showAddNew}
                                        clientData={exploringId}
                                        editData={null}
                                        onCloseModal={() => {
                                            setShowAddNew(false);
                                        }}
                                    ></AddContact>
                                )}

                                {/*                 
                  // <Dialog open={true} onClose={handleModalClose}>
                  //   <div>
                  //     <ClientRelationshipAdder
                  //       clientContactId={
                  //         exploringId ? exploringId : -1
                  //       }
                  //       clientId={
                  //         exploringId ? exploringId : 0
                  //       }
                  //       onAddedRelationshipSuccess={
                  //         handleAddedRelationshipSuccess
                  //       }
                  //     />
                  //   </div>
                  // </Dialog> */}

                                <DataTable data={relatedContactRows} />
                            </TabPanel>
                            {programPermission.canView &&
                                <TabPanel
                                    value={value}
                                    index="program"
                                    className={classes.tabRoot}
                                    style={{ backgroundColor: theme.palette.background.paper }}
                                >
                                    <Programs permissions={global.permissions.clients} clientId={exploringId} />
                                </TabPanel>
                            }

                            <TabPanel
                                value={value}
                                index="schedules"
                                className={classes.tabRoot}
                            >
                                <div> Coming Soon </div>
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index="billing"
                                className={classes.tabRoot}
                            >
                                <div> Coming Soon </div>
                            </TabPanel>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to cancel your changes?"}
                </DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={agree} color="primary">
                        Yes, Discard
                    </Button>
                    <Button onClick={disagree} color="primary" autoFocus>
                        No, Keep changes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen={fullScreen}
                open={backspaceConfirm}
                onClose={() => { setBackspaceConfirm(false) }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to cancel your changes?"}
                </DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={() => { goBack(); setBackspaceConfirm(false) }} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => { setBackspaceConfirm(false) }} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
