import {
    CMS_CONTACT_RELATIONSHIP_LIST, YESHIVAS_CLIENT_LIST, YESHIVAS_LIST,
    YESHIVA_ITEM_DETAILS,
    YESHIVA_URL
} from "../constants";

export const getYeshivasContacts = (id) => {
    if (id <= 0) return;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + '/' + id + '/contacts')
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const getAllYeshivasName = () => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + '/names')
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const deleteYeshivasContacts = (yeshivaId, id) => {
    if (id <= 0) return;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.delete(YESHIVA_URL + '/' + yeshivaId + '/contacts/' + id)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const getYeshivaDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + '/' + id + '/details')
            .then(
                (data) => {
                    let res = { ...data };
                    res['yeshivaSedarim'] = Object.fromEntries(data.yeshivaSedarim.map(item => [item?.name, item]).filter(Boolean));
                    resolve(res);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });

    // const basicInfo = await getYeshivas(id);
    // let address = await getYeshivasAddress(id);
    // let seders = await getYeshivasSedar(id);
    //  address = Object.fromEntries(address.map(item => [item?.addressType, item]).filter(Boolean));
    //  seders = Object.fromEntries(seders.map(item => [item?.name, item]).filter(Boolean));
    // return {
    //     ...basicInfo,
    //     address,
    //     seders
    // }
}

export const addYeshivasContacts = (id, payload) => {
    console.log(payload);
    console.log(id);

    return new Promise((resolve, reject) => {
        global.fetchWrapper.post(YESHIVA_URL + '/' + id + '/contacts', payload)
            .then(
                (data) => {
                    // Assuming a successful response
                    resolve(data);
                }
            )
            .catch((error) => {
                // Handle errors here
                console.error("Error occurred:", error);
                reject(error); // Pass the error to the caller
            });
    });
};

export const updateYeshivasContacts = (yeshivaId, payload, id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.put(YESHIVA_URL + '/' + yeshivaId + '/contacts/' + id, payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const getYeshivasSedar = (id) => {
    if (id <= 0) return;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + '/' + id + '/seder')
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const addYeshivasSedar = (id, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(YESHIVA_URL + '/' + id + '/seder', payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const updateYeshivasSedar = (id, sederId, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.put(YESHIVA_URL + '/' + id + '/seder/' + sederId, payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const getYeshivasSedarType = (id, sederType) => {
    if (id <= 0) return;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + id + '/seder/' + sederType)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const addYeshivasSedarType = (id, sederType, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(YESHIVA_URL + '/' + id + '/seder/' + sederType, payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export const addYeshivas = (payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(YESHIVA_URL, payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const updateYeshivas = (id, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.put(YESHIVA_URL + '/' + id, payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const getYeshivas = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + '/' + id)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const addYeshivasAddress = (id, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(YESHIVA_URL + '/' + id + '/address', payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const getYeshivasAddress = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + '/' + id + '/address',)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const updateYeshivasAddressType = (id, addressType, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.put(YESHIVA_URL + '/' + id + '/address/' + addressType, payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const addYeshivasAddressType = (id, addressType, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(YESHIVA_URL + '/' + id + '/address/' + addressType, payload)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const getYeshivasAddressType = (id, addressType) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_URL + '/' + id + '/address' + addressType,)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
}

export const getYeshivasList = () => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVAS_LIST)
            .then(
                (data) => {
                    yeshivas_data = data.result;
                    yeshivas_data.forEach((item, index) => {
                        yeshiva.push({
                            id: item.id,
                            name: item.name,
                            contactId: item.contactId,
                        });
                    });
                    resolve(yeshiva);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let yeshivasClients = (id) => {
    var clients = [];
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVAS_CLIENT_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        var clients_data = data.result;
                        // global.log('data.result = ', data.result);
                        clients_data.forEach((item, index) => {
                            clients.push({
                                id: item.id ? item.id : index,
                                last_name: item.contact.lastName ? item.contact.lastName : "-",
                                first_name: item.contact.firstName
                                    ? item.contact.firstName
                                    : "-",
                                yeshiva: item.yeshivaName ? item.yeshivaName : "-",
                                age: item.contact.age ? item.contact.age : 0,
                                organizer: item.meargenName ? item.meargenName : "-",
                                client_id: item.contactId,
                                city: item.contact.city ? item.contact.city : "-",
                                father_name: item.fathersName,
                            });
                        });
                        resolve(clients);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let yeshivasDetails = (id) => {
    let detailOfyeshivas;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVA_ITEM_DETAILS + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let getMenahelList = (contactId) => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(
            CMS_CONTACT_RELATIONSHIP_LIST +
            "/Table=Yeshivas&Relationship=Menahel&ContactId=" +
            contactId
        )
            .then(
                (data) => {
                    yeshivas_data = data;
                    yeshivas_data.forEach((item, index) => {
                        yeshiva.push({
                            id: item.id,
                            name: item.name,
                        });
                    });
                    resolve(yeshiva);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let getMeargenList = (contactId) => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(
            CMS_CONTACT_RELATIONSHIP_LIST +
            "/Table=Clients&Relationship=Meargens&ContactId=" +
            contactId
        )
            .then(
                (data) => {
                    yeshivas_data = data;

                    yeshivas_data.forEach((item, index) => {
                        yeshiva.push({
                            id: item.id,
                            name: item.name,
                        });
                    });
                    resolve(yeshiva);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};

export let getRoshHameargenList = (contactId) => {
    let yeshivas_data = [];
    const yeshiva = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(
            CMS_CONTACT_RELATIONSHIP_LIST +
            "/Table=Clients&Relationship=RoshHaMeargens&ContactId=" +
            contactId
        )
            .then(
                (data) => {
                    yeshivas_data = data;

                    yeshivas_data.forEach((item, index) => {
                        yeshiva.push({
                            id: item.id,
                            name: item.name,
                        });
                    });
                    resolve(yeshiva);
                },
                (rejects) => {
                    global.log("rejects");
                }
            );
    });
};
