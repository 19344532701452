import React, { useEffect, useState } from 'react'
import {
    FormControlLabel,
    Switch,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material'
import DataTable from '../../../DataTable/DataTable'
import AddToDo from './AddToDo';
import { todoColumns, getClientToDoDetail, removeClientTodo } from '../../../../api/clients'
import { markTaskCompleted } from '../../../../api/cms'
import ConfirmDialog from '../../../common/ConfirmDialog/ConfirmDialog'
import { useParams } from "react-router";
import DeletePopup from "../../Tabs/Dialog/DeletePopup";
import { useStoreState, useStoreActions } from "easy-peasy";
import { accountService } from '../../../../_services/account.service';
import baseStyles from '../../../../assets/styles';



const ToDo = (props) => {
    const { classes, todoData, handleReloadApi, screen, showFilterBy, showAddButton, setDashboardTodoData } = props;

    const exploringId = useStoreState((state) => state.exploringId);
    const baseClasses = baseStyles();
    const setTodos = useStoreActions((actions) => actions.dashboard.setTodos);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [todoList, setTodoList] = useState(todoData ?? []);
    const [editData, setEditData] = React.useState({});
    const [slcRow, setSlcRow] = useState({});
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [showCompleted, setShowCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteRowId, setDeleteRowId] = useState(-1);
    const [filterBy, setFilterBy] = useState("assignee");

    const loadData = () => {
        let module, userId, filter;
        switch (screen) {
            case 'client_task':
                module = 'clients'
                break;
            case 'employee_task':
                module = 'employees'
                break;
            case 'dashboard_task':
                userId = accountService.userValue.id
                filter = filterBy === 'all' ? null: filterBy;
                break;
            default:
                return null
        }
        let options = {
            module: module,
            primaryId: exploringId,
            userId: userId,
            showCompleted: showCompleted,
            filterBy: filter,
        }
        setLoading(true)
        getClientToDoDetail(options).then((response) => {
            setTodoList(response);
            if (screen === 'dashboard_task') {
                setTodos(response)
               typeof setDashboardTodoData == 'function' &&  setDashboardTodoData(response)
            }
            setLoading(false)
        });
    }
    const reloadData = () => {
        loadData();
        if (typeof handleReloadApi === 'function')
            handleReloadApi('todo')
    }

    useEffect(() => {
        loadData();
    }, [showCompleted, filterBy])

    const isAssigneeOnTask = (contactId) => contactId && contactId === global.account.userValue.contact.id;
    const isCreatorOnTask = (contactId) => contactId && contactId === global.account.userValue.contact.id;
    const markCompletedColumn = {
        field: "markCompleted",
        label: "Mark As Completed",
        show: (params) => {
            return !params.row.completedDate && (isAssigneeOnTask(params.row.assignee_ContactId) || isCreatorOnTask(params.row.creator_ContactId));
        },
        handleClick: (event, params) => {
            if ((!isAssigneeOnTask(params.row.assignee_ContactId) && !isCreatorOnTask(params.row.creator_ContactId)))
                return;
            setSlcRow(params.row);
            setIsOpenConfirmDialog(true);
        },
        icon: "check",
    };

    const _setRowAndColumnTodo = () => {
        let columns = todoColumns;
        if (screen === 'dashboard_task')
            columns = columns.concat([{ field: "primaryName", headerName: "On", flex: 0.8, navUrl: "{module}/{primaryId}/details" }])
        return {
            rows: todoList,
            columns: columns.concat([markCompletedColumn, "editColumn", "deleteColumn"]),
            screen: "client_todo",
            //actionColumns: [markCompletedColumn, "editColumn", "deleteColumn"],
        };
    };

    const handleShowCompleted = () => {
        setShowCompleted(!showCompleted);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditData({})
    };

    const handleAddButton = () => {
        setIsModalOpen(true);
    };

    const handleEditData = (data) => {
        setEditData(data);
    };
    const handleFilterByChanged = (event) => {
        setFilterBy(event.target.value);
    };

    return (<div>


        <div className={classes.programTab}>
            <div className={baseClasses.button_program}>
                {showFilterBy &&
                    <FormControl
                        name="filterby"
                        color="primary"
                        autoWidth={false}
                        style={{ marginRight: '30px' }}
                    >
                        <InputLabel>Filter By</InputLabel>
                        <Select
                            style={{ height: "40px", fontSize: "medium" }}
                            value={filterBy}
                            label="Filter By"
                            onChange={handleFilterByChanged}
                        >
                            <MenuItem value={"assignee"}>Assigned to me</MenuItem>
                            <MenuItem value={"creator"}>Created by me</MenuItem>
                            { global.isAdmin() && <MenuItem value={"all"}>All</MenuItem> }
                        </Select>
                    </FormControl>
                }

                <FormControlLabel
                    control={
                        <Switch checked={showCompleted} onChange={handleShowCompleted} name="showcomp" color="primary" />
                    }
                    label="Show Completed"
                />
                {showAddButton &&
                    <Button
                        className={baseClasses.capitalized}
                        color="primary"
                        variant="outlined"
                        onClick={handleAddButton}
                    >
                        Add
                    </Button>
                }

            </div>
        </div>
        <DataTable
            data={_setRowAndColumnTodo()}
            onOpen={handleAddButton}
            onEdit={(params) => { handleEditData(params.row) }}
            onDelete={(params) => {
                let index = params.row.id;
                setDeleteRowId(index);
            }}
            title="Todo"
            completed={showCompleted}
            permissions={global.permissions.clients.todos}
            loading={loading}
            canEdit={(params) => params.row.creator_ContactId == global.account.userValue.contact.id || global.isAdmin()}
            canDelete={(params) => params.row.creator_ContactId == global.account.userValue.contact.id || global.isAdmin()}
            hideHeaderColumnsSortIcon
            multiView
        />

        {isModalOpen && (
            <AddToDo
                isOpenCreateModal={isModalOpen}
                onCloseModal={handleCloseModal}
                editData={editData}
                reloadData={reloadData}
            />
        )}
        <ConfirmDialog
            open={isOpenConfirmDialog}
            onClose={() => {
                setIsOpenConfirmDialog(false);
            }}
            onConfirm={(event) => {
                markTaskCompleted(slcRow, new Date()).then(() => {
                    reloadData();
                });
            }}
            id={slcRow.id}
            item="task"
            action="complete"
            canConfirm
        />
        <DeletePopup
            open={deleteRowId !== -1}
            onClose={setDeleteRowId.bind(this, -1)}
            canConfirm={true}
            onConfirm={() => {
                removeClientTodo(deleteRowId).then(() => {
                    reloadData()
                });
            }}
            id={slcRow.id}
            item="task"
            action="delete"
        />
    </div>)
}

export default ToDo;