import React, { useEffect } from "react";
import {
    Dialog, FormControl,
    Input, InputLabel
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { Button } from "reactstrap";

import { contactCMSList } from "../../../../api/clients";
import ContactSelectNew from "../../../ContactSelect/ContactSelectNew";
import useStyles from "./styles";
import baseStyles from '../../../../assets/styles';
/**
 * props: title, subtitle
 * props: openModal, handleClose(), Output:addYeshiva(selectedContact)
 */



const ContactRelSelect = (props) => {
    const classes = useStyles();
    const baseClasses = baseStyles();
    const [openModal, setOpenModal] = React.useState(false);
    const [conatctName, setConatctName] = React.useState("");
    const [searchText, setSearchText] = React.useState("");
    const [contactList, setContactList] = React.useState([]);
    const [filterContactList, setFilterContactList] = React.useState([]);
    const [inputMode, setInputMode] = React.useState("selectExisting");
    const [relationship, setRelationship] = React.useState(null);
    const [selectedContact, setSelectedContact] = React.useState(null);
    //   const [inputMode, setInputMode] = React.useState("createNewContact");
    const [contactSelectField, setContactSelectField] = React.useState(null);
    const [name, setName] = React.useState("");

    // global.log("addyeshiva dialog" + props.openModal);
    useEffect(() => {
        setSelectedContact(null);
        setOpenModal(props.openModal);

        contactCMSList().then((response) => {
            setContactList(response);
            setFilterContactList(response);
        });
    }, []);

    useEffect(() => {
        if (!_.isEmpty(contactSelectField))
            setName(contactSelectField.name);
    }, [contactSelectField]);

    useEffect(() => {
        if (!global.isEmpty(props.editData))
            setName(props.editData.name);
        else
            setName("");
    }, [props.editData]);

    const getContactIdFromProp = () => {

        if (props.editData == null)
            return 0;
        return props.editData;
    };

    const handInputMode = (e) => {
        setInputMode(e.target.value);
    };

    const handleClose = () => {
        setOpenModal(false);
        props.handleClose();
        // setSearchText("");
        // setFilterContactList(contactList);
    };

    const handleSelectRow = (contact) => {
        if (!contact) return;
        setSelectedContact(contact);
        setConatctName(`${contact.firstName} ${contact.lastName}`);
        // handleClose();
        // props.onSelectValue(contact.id);
    };

    const addNewRelationship = () => { };

    return (
        <>
            {/* <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label" htmlFor="name-multiple">
          {props.name ? props.name : "Contact"}
        </InputLabel>
        <Input
          value={conatctName}
          id="demo-simple-select-label"
          onClick={(event) => {
            setOpenModal(true);
          }}
          aria-describedby="my-helper-text"
        />
      </FormControl> */}
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openModal}
                // open={true}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            // style={{padding:20}}
            >
                <div style={{ padding: 20 }}>
                    <div className="mb-4">
                        <div>
                            <h5>{props.title}</h5>
                        </div>
                        {/* <ContactSelectField
              editData={getContactIdFromProp()}
              editDataType={"id"}
              onChange={(contact) => setContactSelectField(contact)}
            ></ContactSelectField> */}
                        <FormControl fullWidth>
                            <InputLabel htmlFor="my-input">Name</InputLabel>
                            <Input
                                type="text"
                                value={name}
                                id="my-input"
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                            />
                        </FormControl>
                        {/* <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label" htmlFor="name-multiple">
                {props.name
                  ? props.name
                  : props.subtitle}
              </InputLabel>
              <Input
                value={conatctName}
                id="demo-simple-select-label"
                readOnly
                onClick={(event) => {
                  //   setOpenModal(true);
                }}
                aria-describedby="my-helper-text"
              />
            </FormControl> */}
                        <div className="float-right mt-3">
                            <Button
                                color="primary"
                                // onClick={props.handCloseRel}
                                onClick={() => {
                                    props.handleClose();
                                }}
                                className="mr-2"
                            >
                                Close
              </Button>
                            <Button
                                
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    props.handleClose();
                                    props.addYeshiva(contactSelectField, name);
                                }}
                            // onClick={addNewRelationship}
                            >
                                Save
              </Button>
                        </div>
                    </div>
                    {false && (
                        <ContactSelectNew
                            handleClose={handleClose}
                            handleSelectRow={handleSelectRow}
                        ></ContactSelectNew>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default ContactRelSelect;
