import React from 'react';
import Chip from '@mui/material/Chip';
import Tab from "@mui/material/Tab";
import {ChipTabProps} from '../../../../assets/types';
import clsx from 'clsx'
import useStyles from '../../styles'
const ChipTabs:React.FC<ChipTabProps> = (props) => {
  const classes = useStyles(props);
  return (
      <Tab
        disableRipple
        className={classes.subTab}
          {...props}
          label={
              <Chip
                  label={props.label}
                  className={clsx(classes.subTabLabel, {
                    [classes.activeTab]: props.value === props.activeTab
                  })}
                  onClick={() => {}}
              />
          }
      />
  )
}

export default ChipTabs