/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { FormControl, TextField, FormControlLabel, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Person from "@mui/icons-material/Person";
// import { applications } from "/src/redux/application.js"
import { applications, columnsApplication } from "../../api/applications"
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Dialog, DialogTitle, DialogActions, IconButton } from "@mui/material";
import useStyles from "./styles";
import Drawer from "@mui/material/Drawer";
import WarningIcon from '@mui/icons-material/Warning';
import clsx from "clsx";
import employees, {
    columnsEmployees,
    getEmployeeDetails,
    removeEmployeeFromList,
} from "../../api/employees";
import application, {
    removeApplicationFromList,
} from "../../api/applications";
import DataTable from "../../components/DataTable/DataTable";
import NewEmployee from "./NewEmployee";
import EditEmployee from './EditEmployee'
import _ from "lodash";
import DlgCreateNewContract from "../../components/ContactSelect/PanelNewContact";
import { useStoreState, useStoreActions, action } from "easy-peasy";
import { FlexJustifyEnd } from '../../assets/styles/styledComponents';
import EditPage from "../../components/edit_explorer";
import usePermission from "../../hooks/usePermission";
import baseStyles from '../../assets/styles';
import useGetValueOptionsLists from '../../hooks/useGetValueOptionsLists'
import * as constant from '../../constants'
import FRONTEND_ROUTE_CONSTANTS from '../../frontend_route_constants';
import { employeeFilter, employeeQuickFilters, applicantQuickFilters, employeeChipFilterState } from '../../constants'
import { strings } from "../../_helpers/strings";
import SearchInput from '../../components/common/SearchInput/SearchInput';

const drawerWidth = 240;
let programs = false;

const styleProps = {
    drawerWidth: drawerWidth
}

export default function Employees() {
    const classes = useStyles();
    const baseClasses = baseStyles(styleProps);
    const location = useLocation();
    const subMenuURL = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const [id, setId] = React.useState("");
    const [query, setQuery] = React.useState("");
    const [searchText, setSearchText] = React.useState("");
    const [rows, setRow] = React.useState([]);
    const [rowToDelete, setRowToDelete] = React.useState([]);
    const [showInactive, setShowInactive] = React.useState(false);
    const [showApproved, setShowApproved] = React.useState(false);
    const [selectedChipFilters, setSelectedChipFilters] = React.useState(employeeChipFilterState);
    const [columns, setColumns] = React.useState(columnsEmployees);
    const [loader, setLoader] = React.useState(true);
    const [editData, setEditData] = React.useState([]);
    const [isOpenEmployeeModal, setIsOpenEmployeeModal] = React.useState(false);
    const [editEmployeeData, setEditEmployeeData] = React.useState(null);
    const [popUp, showPopup] = React.useState(false);
    const [applicationsData, setApplicationsData] = React.useState([]);
    const [originApplicationsData, setOriginApplicationsData] = React.useState([]);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const exploringType = useStoreState((state) => state.exploringType);
    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);
    const history = useStoreState((state) => state.history);
    const exploringId = useStoreState((state) => state.exploringId);
    const queryAll = useStoreState((state) => state.employee.queryAll);
    const queryApplications = useStoreState((state) => state.employee.applications);
    // const setQueryApplications = useStoreActions((actions) => actions.employee.setQueryApplications);

    const setQueryAll = useStoreActions((actions) => actions.employee.setQueryAll);
    const queryProfessional = useStoreState((state) => state.employee.queryProfessional);
    const setQueryProfessional = useStoreActions((actions) => actions.employee.setQueryProfessional);
    const queryMamidim = useStoreState((state) => state.employee.queryMamidim);
    const setQueryMamidim = useStoreActions((actions) => actions.employee.setQueryMamidim);
    const mamidimPage = useStoreState((state) => state.employee.mamidimPage);
    const applicationsPage = useStoreState((state) => state.employee.applicationsPage);

    const applicants = useStoreState(state => state.applicants);
    const setApplications = useStoreActions(actions => actions.updateApplications);

    const setMamidimPage = useStoreActions((actions) => actions.employee.setMamidimPage)
    const professionalPage = useStoreState((state) => state.employee.professionalPage);
    const setProfessionalPage = useStoreActions((actions) => actions.employee.setProfessionalPage)
    const setApplicationsPage = useStoreActions((actions) => actions.employee.setApplicationsPage)

    const allPage = useStoreState((state) => state.employee.allPage);
    const setAllPage = useStoreActions((actions) => actions.employee.setAllPage)
    const setExploringId = useStoreActions((actions) => actions.setExploringId);
    const exploringMode = useStoreState((state) => state.exploringMode);
    const setExploringMode = useStoreActions(
        (actions) => actions.setExploringMode
    );
    const setPreviousPath = useStoreActions(
        (actions) => actions.setPreviousPath
    );
    const setExploringType = useStoreActions(
        (actions) => actions.setExploringType
    );
    const setExploringName = useStoreActions(
        (actions) => actions.setExploringName
    );
    const initEmployeeDetail = useStoreActions((actions) => actions.employee.initDetail);

    const [type, setType] = React.useState(null);

    const isEditing = () => {
        return exploringMode === "edit";
    }
    //permissions
    const { canCreate } = usePermission(global.permissions.employees);

    const fetchEmployees = useStoreActions(actions => actions.employee.fetchEmployees);
    // const fetchApplications = useStoreActions(actions => actions.applications.fetchApplication);
    const employees = useStoreState(state => state.employee.employees);
    const setEmployeesListFiltered = useStoreActions(actions => actions.employee.setEmployeesListFiltered);
    const employeesListFiltered = useStoreState(state => state.employee.employeesListFiltered);
    const isSidebarOpened = useStoreState((state) => state.isSidebarOpened);

    useEffect(() => {
        console.log(applicants);
    }, [setApplications]);
    useEffect(() => {
        if (subMenuURL === employeeFilter.applications) {
            setApplicationsData(setDataOfFilterForApplications(originApplicationsData))
        }
        setEmployeesListFiltered(setDataOfFilter(employees))
    }, [query, subMenuURL, selectedChipFilters, employees])


    useEffect(() => {
        let isMounted = true; // Flag to track if the component is mounted

        const fetchData = async () => {
            try {
                switch (subMenuURL) {
                    case employeeFilter.mamidim: {
                        setQuery(queryMamidim);
                        break;
                    }
                    case employeeFilter.professionals: {
                        setQuery(queryProfessional);
                        break;
                    }
                    case employeeFilter.all: {
                        setQuery(queryAll);
                        break;
                    }
                    case employeeFilter.applications: {
                        const employees = await applications(); // Await the promise

                        if (isMounted) {
                            setApplicationsData(employees);
                            setOriginApplicationsData(employees)
                            setApplicationsData(setDataOfFilterForApplications(employees))

                        }
                        break;
                    }
                    default:
                        break;
                }
            } catch (error) {
                console.error('Error fetching applications:', error);
                if (isMounted) {
                    setQuery('');
                    console.log("is mounted")
                }
            }
        };
        fetchData();
        return () => {
            isMounted = false;
        };
    }, [subMenuURL, queryAll, queryProfessional, queryMamidim]);





    const valueOptionsLists = useGetValueOptionsLists([
        'comfortHealthStatus',
        'neighborhood',
        'lHisalosApprovalStatus',
        'paymentOptions',
        'professionalType',
    ], constant.explorerTypesConst.employee)

    // useEffect(() => { // reseting selected chip filter when main filter changed
    //     setSelectedChipFilters(employeeChipFilterState)
    // }, [subMenuURL])

    const columnsEmployee = [
        { field: "id", type: "number", headerName: "Employee Id", width: 110, flex: 0.5, },
        {
            field: "warning", enableTooltip: true, disableColumnMenu: true, renderCell: (param) => (
                !param.row.isActive ?
                    <IconButton>
                        <Tooltip title="inactive">
                            <WarningIcon style={{ color: "red" }} />
                        </Tooltip>
                    </IconButton> :
                    null
            ), sortable: false, maxWidth: 30, minWidth: 30, cellClassName: 'icon-no-padding'
        },
        { field: "fullName", headerName: "Full Name", width: 110, flex: 1.4, },
        { field: "lastName", headerName: "Last Name", width: 120, flex: 0.8 },
        { field: "firstName", headerName: "First Name", width: 150, flex: 0.8 },
        { field: "createdOn", type: "date", headerName: "Date Created", width: 110, flex: 1, },
        { field: "phone1", headerName: "Phone Number", width: 120, flex: 0.8 },
        //{ field: "address1", headerName: "Address", width: 150, flex: 1},
        { field: "rateDisplay", headerName: "Rate", width: 110, flex: 0.8 },
        { field: "neighborhood", headerName: "Neighborhood", description: 'Neighborhood', width: 110, flex: 0.8, type: 'singleSelect', },
        { field: "comfortHealthStatus", headerName: "Comfort", width: 110, flex: 0.8, type: 'singleSelect', },
        { field: "specialties", headerName: "Specialties", width: 110, flex: 1.4 },
        { field: "clients", headerName: "Clients", width: 150, flex: 0.8, enableTooltip: true },
        { field: "yeshivas", headerName: "Yeshivas", width: 150, flex: 0.8, enableTooltip: true },
        { field: "roshMaamidName", headerName: "Rosh Maamid", width: 150, flex: 0.8, enableTooltip: true },
        { field: "meargenim", headerName: "Meargenim", width: 150, flex: 0.8, enableTooltip: true },
        { field: "clientCount", headerName: "# of Clients", description: '# of Clients', width: 150, flex: 0.5, type: "number" },
        { field: "isYungerman", type: "boolean", headerName: "Yungerman", width: 110, flex: 0.5, },
        { field: "isProfessional", type: "boolean", headerName: "Professional", width: 110, flex: 0.5, },
        { field: "isLegal", type: "boolean", headerName: "Legal", width: 110, flex: 0.5, },
        { field: "isEmployee", type: "boolean", headerName: "Employee", width: 110, flex: 0.5, },
        { field: "inactive", type: "boolean", headerName: "Inactive", width: 110, flex: 0.5, },
        { field: "isSummerOnly", type: "boolean", headerName: "Summer Only", width: 110, flex: 0.5, },
        { field: "firstNameHebrew", headerName: "Hebrew First Name", description: 'Hebrew First Name', width: 110, flex: 0.8, },
        { field: "lastNameHebrew", headerName: "Hebrew Last Name", description: 'Hebrew Last Name', width: 110, flex: 0.5, },
        { field: "firstNameLegal", headerName: "Legal First Name", description: 'Legal First Name', width: 110, flex: 0.8, },
        { field: "lastNameLegal", headerName: "Legal Last Name", description: 'Legal Last Name', width: 110, flex: 0.8, },
        { field: "middleNameLegal", headerName: "Legal Last Name", description: 'Legal Last Name', width: 110, flex: 0.8, },
        { field: "legalName", headerName: "Legal Name", description: 'Legal Name', width: 110, flex: 0.8, hide: true },
        { field: "comfortHealthEmployeeId", headerName: "Comfort Health ID", description: 'Comfort Health ID', width: 110, flex: 0.8, hide: true },
        { field: "hasText", type: "boolean", headerName: "Has Text", width: 110, flex: 0.5, },
        { field: "address1", headerName: "Address", width: 110, flex: 1, },
        { field: "age", type: "number", headerName: "Age", width: 110, flex: 0.5, },
        { field: "city", headerName: "City", width: 110, flex: 0.8, },
        { field: "email1", headerName: "Email", width: 110, flex: 0.8, },
        { field: "state", headerName: "State", width: 110, flex: 0.8, },
        { field: "zip", headerName: "Zip", width: 110, flex: 0.8, },
        { field: "dob", type: "date", headerName: "DOB", width: 110, flex: 0.5, },
        { field: "rate", type: "number", headerName: "Rate (number)", description: 'Rate (number)', width: 110, flex: 0.5, },
        { field: "prevYeshivas", headerName: "Yeshivas Previously Worked", description: 'Yeshivas Previously Worked', width: 110, flex: 1, enableTooltip: true },
        { field: "experience", headerName: "Experience", width: 110, flex: 1, enableTooltip: true },
        { field: "note", headerName: "Note", width: 110, flex: 1, enableTooltip: true },
        { field: "lHisalosApprovalStatus", headerName: "LHisalos Approval Status", description: 'LHisalos Approval Status', width: 110, flex: 0.7, type: 'singleSelect', },
        { field: "parsonageLimit", type: "number", headerName: "Parsonage Limit", description: 'Parsonage Limit', width: 110, flex: 0.6, },
        { field: "parsonageFormExpiration", type: "date", headerName: "Parsonage Exp", description: 'Parsonage Expiration Date', width: 110, flex: 0.5, hide: true },
        { field: "financialNotes", headerName: "Financial Notes", description: 'Financial Notes', width: 110, flex: 1, enableTooltip: true },
        { field: "paymentOptions", headerName: "Payment Options", description: 'Payment Options', width: 110, flex: 1, enableTooltip: true, type: 'singleSelect', },
        { field: "planningToEnrollComfortHealth", type: "boolean", headerName: "Planning To Enroll CH", description: 'Planning To Enroll CH', width: 110, flex: 0.5, },
        { field: "hasComfortHealthApplication", type: "boolean", headerName: "Has CH Application", description: 'Has CH Application', width: 110, flex: 0.5, },
        { field: "hasBA", type: "boolean", headerName: "Has BA", width: 110, flex: 0.5, },
        { field: "hasHighSchoolDiploma", type: "boolean", headerName: "Has High School Diploma", description: 'Has High School Diploma', width: 110, flex: 0.8, },
        { field: "sentToComfortHealthOn", type: "date", headerName: "Date Sent Application To CH", description: 'Date Sent Application To CH', width: 110, flex: 0.8, },
        { field: "fingerprintedOn", type: "date", headerName: "Date Fingerprinted", description: 'Date Fingerprinted', width: 110, flex: 0.8, },
        { field: "comfortHealthApprovedOn", type: "date", headerName: "Date Approved CH", description: 'Date Approved CH', width: 110, flex: 0.8, },
        { field: "catsApprovedOn", type: "date", headerName: "Date Approved CATS", description: 'Date Approved CATS', width: 110, flex: 0.8, },
        { field: "prevocApprovedOn", type: "date", headerName: "Date Approved Prevoc", description: 'Date Approved Prevoc', width: 110, flex: 0.5, },
        { field: "specialRequirements", headerName: "Special Requirements", description: 'Special Requirements', width: 110, flex: 1, enableTooltip: true },
        { field: "amountCouldWork", type: "number", headerName: "Amount Could Work", description: 'Amount Could Work', width: 110, flex: 0.6, },
        { field: "isInTheMountainsSummer", type: "boolean", headerName: "In The Mountains Summer", description: 'In The Mountains Summer', width: 110, flex: 0.5, },
        { field: "hasLicense", type: "boolean", headerName: "Has License", description: 'Has License', width: 110, flex: 0.5, },
        { field: "hasCar", type: "boolean", headerName: "Has Car", width: 110, flex: 0.5, },
        { field: "summerColony", headerName: "Summer Colony", width: 110, flex: 0.8, },
        { field: "amountBilling", type: "number", headerName: "Amount Billing", description: 'Amount Billing', width: 110, flex: 0.7, },
        { field: "professionalType", headerName: "Professional Type", description: 'Professional Type', width: 110, flex: 0.8, type: 'singleSelect', },
        { field: "isLhisalosInHouse", type: "boolean", headerName: "Is Lhisalos In House", description: 'Is Lhisalos In House', width: 110, flex: 0.5, },
        { field: "uploadedIdOn", type: "date", headerName: "Date Uploaded Id", description: 'Date Uploaded Id', width: 110, flex: 0.5, },
        { field: "uploadedParsonageFormOn", type: "date", headerName: "Date Uploaded Parsonage Form", description: 'Date Uploaded Parsonage Form', width: 110, flex: 0.5, },
        { field: "uploadedHighSchoolDiplomaOn", type: "date", headerName: "Date Uploaded Diploma", description: 'Date Uploaded Diploma', width: 110, flex: 0.5, },
        { field: "uploadedBAOn", type: "date", headerName: "Date Uploaded BA", description: 'Date Uploaded BA', width: 110, flex: 0.5, },
        { field: "uploadedAssociatesDegreeOn", type: "date", headerName: "Date Uploaded Associate's Degree", description: 'Date Uploaded Associate\'s Degree', width: 110, flex: 0.5, },
        { field: "uploadedLhisalosContractOn", type: "date", headerName: "Date Uploaded LHisalos Contract", description: 'Date Uploaded LHisalos Contract', width: 110, flex: 0.5, },
        { field: "baFileName", headerName: "BA File Name", description: 'BA File Name', width: 110, flex: 0.8, },
        { field: "uploadedI9On", type: "date", headerName: "Date Uploaded I9", description: 'Date Uploaded I9', width: 110, flex: 0.8, },
        { field: "enrollmentColumn", headerName: "Enrollment Icon", type: 'action' },
        { field: "deleteColumn", headerName: "Delete Icon", type: 'action' },
    ];

    useEffect(() => {
        if (editEmployeeData == null || editEmployeeData == undefined) return;
        getEmployeeDetails(editEmployeeData).then((response) => {
            setEditData(response.contact);
            global.log(response);
        });
    }, [editEmployeeData]);
    //delay search query by 500 ms

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setQuery(searchText)
    //     }, 500)
    //     return () => { clearTimeout(timer) }
    // }, [searchText])


    const popupDisplay = () => {
        showPopup(true);
    };



    const handleNewEmployee = () => {
        //setIsOpenEmployeeModal(true);
        history(`${FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE}${-1}${FRONTEND_ROUTE_CONSTANTS.ENROLEMENT}`)
        setExploringType("employee");
        setExploringMode("edit");
        setExploringId(-1);
        setExploringName("New Employee");
        setPreviousPath("FROM EMPLOYEE LIST");
        initEmployeeDetail();
    };

    const checkFilterIsActive = (filter) => {
        return selectedChipFilters.some((item) => item.filter === strings.removeEmptySpaces(filter) && item.isActive)
    }

    const setDataOfFilter = (rows, applicants) => {
        let tempRows = rows;
        if (subMenuURL === employeeFilter.mamidim) {
            if (selectedChipFilters.every((item) => !item.isActive)) {
                tempRows = tempRows.filter((row) => (row.isYungerman && row.isActive && row.lHisalosApprovalStatus === 'Approved'))
            } else {
                tempRows = tempRows.filter((row) => (row.isYungerman && (
                    (row.lHisalosApprovalStatus === 'Approved' && checkFilterIsActive(employeeQuickFilters.showInactive)) ||
                    (row.lHisalosApprovalStatus === 'Approved' || (checkFilterIsActive(employeeQuickFilters.showPending) && row.lHisalosApprovalStatus === 'Pending')) ||
                    (row.lHisalosApprovalStatus === 'Approved' || (checkFilterIsActive(employeeQuickFilters.showNotApproved) && row.lHisalosApprovalStatus === 'Not Approved'))
                )))
            }
        } else if (subMenuURL === employeeFilter.professionals) {
            tempRows = tempRows.filter((row) => row.isProfessional
                && (row.isActive || checkFilterIsActive(employeeQuickFilters.showInactive)))

        }
        else { // when submenu is all
            if (!showInactive) {
                tempRows = tempRows.filter((row) => (row.isActive || checkFilterIsActive(employeeQuickFilters.showInactive)))
            }
        }
        return tempRows.filter(
            (row) =>
                row?.firstName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.lastName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.legalName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.comfortHealthEmployeeId?.toString().trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.phone1?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.address1?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.age?.toString().trim().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.city?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.id?.toString().trim().indexOf(query.trim().toLowerCase()) > -1
        );
    };


    const setDataOfFilterForApplications = (rows) => {
        let tempRows = rows;
        console.log(rows)
        // Apply filter for "Approved" if the filter is active
        if (checkFilterIsActive(applicantQuickFilters.showApproved)) {
            console.log("Rows before filtering:", tempRows);
            tempRows = tempRows.filter((row) => row.applicationStatus === "Approved");
            console.log("Rows after filtering (Approved only):", tempRows);
        } else {
            // Exclude rows with "Approved" without modifying the original data
            tempRows = rows.filter((row) => row.applicationStatus !== "Approved");
            console.log("Rows after filtering (excluding Approved):", tempRows);
        }

        // Update state with the filtered rows
        // setApplicationsData(tempRows);

        // Return the filtered rows for additional processing
        return tempRows.filter(
            (row) =>
                row?.firstName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.lastName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.legalName?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.comfortHealthEmployeeId?.toString().trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.phone1?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.address1?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.age?.toString().trim().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.city?.trim().toLowerCase().indexOf(query.trim().toLowerCase()) > -1 ||
                row?.id?.toString().trim().indexOf(query.trim().toLowerCase()) > -1
        );
    };



    const loadEmployeesData = (refreshMs, showLoading = true) => {
        if (showLoading)
            setLoading();
        fetchEmployees().then(() => {
            endLoading();
        });
        if (refreshMs)
            setTimeout(() => loadEmployeesData(refreshMs, false), refreshMs);
    }
    useEffect(() => {
        if (employees.length < 1) {
            // set timer to load data every 10 minutes
            let refreshMs = 1800000;
            loadEmployeesData(refreshMs);
        }
    }, []);

    const _setRowAndColumn = () => {
        return {
            rows: subMenuURL === employeeFilter.applications ? applicationsData : employeesListFiltered,
            columns: subMenuURL === employeeFilter.applications ? columnsApplication : columnsEmployee,
            screen: constant.employeeDataTableScreens[subMenuURL],
        };
    };

    const agree = () => {
        if (subMenuURL === employeeFilter.applications) {
            removeApplicationFromList(rowToDelete.contact.id, rowToDelete.id).then((response) => {
                //if (response) {
                //   employees = applications();
                //  setApplicationsData(employees);
                // }
                //         // fetchEmployees();
            });

            showPopup(false);
            // need to reload applications
        } else {
            removeEmployeeFromList(id).then((response) => {
                if (response)
                    fetchEmployees();
            });
        }

        showPopup(false);
    };

    const disagree = () => {
        showPopup(false);
    };

    const handleNewEmployeeClosed = () => {
        setIsOpenEmployeeModal(false)
    }

    const handleAddedEmployee = () => {
        fetchEmployees();
    }

    const handleDrawerClose = () => {

    };

    const handleAddClientSuccess = (isSuccess) => {
        handleDrawerClose();
        if (isSuccess) {
        } else {
            global.AjaxFailToast();
        }
    };

    const setEditType = () => {
        setType(false);
    };

    const handleSelectedChipFilter = (value) => {
        if (Array.isArray(value)) { // When loading saved filters
            setSelectedChipFilters(value.length ? value : employeeChipFilterState)
            return;
        }
        setSelectedChipFilters(selectedChipFilters.map((item) => (
            item.filter === value.filter ? value : item)))
        // if(!selectedChipFilters.includes(value)){          //checking weather array contain selected value
        //     setSelectedChipFilters([...selectedChipFilters, value]); //adding to array because value doesnt exists
        // } else{
        //     setSelectedChipFilters((selectedChipFilters.filter(label => label !== value)))
        // }
    }

    const getChipFilterOption = () => {
        switch (subMenuURL) {
            case employeeFilter.mamidim: {
                return [employeeQuickFilters.showInactive, employeeQuickFilters.showNotApproved, employeeQuickFilters.showPending]
            }
            case employeeFilter.professionals: {
                return [employeeQuickFilters.showInactive]
            }
            case employeeFilter.all: {
                return [employeeQuickFilters.showInactive]
            }
            case employeeFilter.applications: {
                return [applicantQuickFilters.showApproved]
            }
            default: return null
        }
    }

    const handleSearch = (searchQuery) => {
        switch (subMenuURL) {
            case employeeFilter.mamidim: {
                setQueryMamidim(searchQuery)
                break
            }
            case employeeFilter.professionals: {
                setQueryProfessional(searchQuery)
                break
            }
            case employeeFilter.all: {
                setQueryAll(searchQuery)
                break
            }
            default: setQuery(searchQuery)
        }
    }

    const onSortingRows = (rows) => {
        setEmployeesListFiltered(rows)
    }

    const getPage = () => {
        if (subMenuURL === employeeFilter.mamidim) return mamidimPage
        if (subMenuURL === employeeFilter.professionals) return professionalPage
        if (subMenuURL === employeeFilter.applications) return applicationsPage
        return allPage
    }

    const setPage = (newPage) => {
        if (subMenuURL === employeeFilter.mamidim) {
            setMamidimPage(newPage)
        } else if (subMenuURL === employeeFilter.professionals) {
            setProfessionalPage(newPage)
        } else {
            setAllPage(newPage)
        }
    }

    const options = {
        page: getPage(),
        onPageChange: (newPage) => {
            setPage(newPage)
        },
    }

    const getQuery = () => {
        switch (subMenuURL) {
            case employeeFilter.mamidim: {
                return queryMamidim
            }
            case employeeFilter.professionals: {
                return queryProfessional
            }
            case employeeFilter.all: {
                return queryAll
            }
            case employeeFilter.applications: {
                return query
            }
            default: return query
        }
    }

    return (
        <main className={classes.content}>
            <div className={baseClasses.toolbar} />
            <div>
                <div className={baseClasses.row}>
                    <SearchInput query={getQuery()} getSearchQuery={handleSearch} />
                    <div className={baseClasses.grow}></div>
                    {subMenuURL !== employeeFilter.applications && (
                        <div>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={handleNewEmployee}
                                disabled={!canCreate}
                            >
                                <b style={{ fontSize: "14px", paddingRight: "4px" }}>
                                    {"New  "}
                                </b>
                                <AddRoundedIcon fontSize="small" />
                            </Button>
                        </div>
                    )}
                </div>
                <Paper>
                    {(
                        <DataTable
                            title={constant.employeeDisplayNames[subMenuURL]}
                            showColumns={true}
                            data={_setRowAndColumn()}
                            onDelete={(params) => {
                                setId(params.row.id);
                                setRowToDelete(params.row)
                                popupDisplay();
                            }}
                            onSortingRows={onSortingRows}
                            quickFilters={{
                                type: "chip",
                                options: getChipFilterOption(),
                                value: selectedChipFilters,
                                handler: (value) => handleSelectedChipFilter(value)
                            }}

                            onEdit={(params) => {
                                let id = params.row.id;
                                history(FRONTEND_ROUTE_CONSTANTS.EMPLOYEE_ROUTE + id + FRONTEND_ROUTE_CONSTANTS.ENROLEMENT)
                                setExploringId(id);
                                setExploringMode("edit");
                                setPreviousPath("FROM EMPLOYEE LIST");
                            }}
                            //   onEditId={setEditEmployeeData}
                            onOpen={() => {
                                // setOpenEditModal(true);
                                setEditType();
                            }}
                            options={options}
                            searchQuery={query}
                            permissions={global.permissions.employees}
                            valueOptionsLists={valueOptionsLists}
                            hideToolbarIconText
                            hideHeaderColumnsSortIcon
                            multiView

                        />
                    )}
                </Paper>
                <Drawer
                    variant="permanent"
                    className={clsx(baseClasses.drawer, {
                        [baseClasses.drawerOpen]: isEditing(),
                        [baseClasses.drawerClose]: !isEditing(),
                    })}
                    classes={{
                        paper: clsx({
                            [baseClasses.drawerOpen]: isEditing(),
                            [baseClasses.appBarShift]: isSidebarOpened,
                            [baseClasses.appBarShiftFullWidth]: !isSidebarOpened,
                            [baseClasses.drawerClose]: !isEditing(),
                        }),
                    }}
                    anchor="right"
                >
                    {/* {isEditing() && <EditPage
                        onClick={handleDrawerClose}
                        addClientSuccess={handleAddClientSuccess}
                        type={type}
                        exType={"yungerman"}
                    />} */}
                </Drawer>
            </div>
            {isOpenEmployeeModal ? (
                <NewEmployee isOpen={isOpenEmployeeModal} onClose={handleNewEmployeeClosed} onEmployeeAdded={handleAddedEmployee} />
            ) : null}

            <Dialog
                open={popUp}
                onClose={() => {
                    showPopup(false);
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure you want to delete this employee?"}
                </DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={agree} color="primary">
                        Yes
                    </Button>
                    <Button onClick={disagree} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            {openEditModal && (
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={true}
                    onClose={() => {
                        setOpenEditModal(false);
                    }}
                    aria-labelledby="max-width-dialog-title"
                >
                    <div style={{ padding: 20 }}>
                        <DlgCreateNewContract
                            editData={editData}
                            handleClose={() => {
                                setOpenEditModal(false);
                            }}
                            handleAdded={() => {
                                setOpenEditModal(false);
                                fetchEmployees();
                            }}
                        ></DlgCreateNewContract>
                    </div>
                </Dialog>
            )}
        </main>
    );
}


