import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {FilterButtonGroupType, filterButtonOptionType} from '../../../assets/types';

const FilterButtonGroup: React.FC<FilterButtonGroupType> = ({ variant, filterOptions, onClick, style, selected, selectedVariant }) => {
  return (
    <ButtonGroup variant={variant}>
      {
        filterOptions.map((option: filterButtonOptionType) => (
          <Button variant={selected === option.value ? selectedVariant : variant} sx={{...style}} onClick={() => onClick(option.value)}>{option.label}</Button>
        ))
      }
    </ButtonGroup>
  )
}

export default FilterButtonGroup